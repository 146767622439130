import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Map, MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { toLonLat } from 'ol/proj';

import { setClusterNotificationLogsIds, removeClusterNotificationLogs } from 'reducers/notifications';
import { setChosenClusterTrackableUnitsInfo } from 'reducers/trackableUnits';
import { ChosenClusterTrackableUnitsInfo } from 'reducers/trackableUnits/interface';
import { setChosenClusterPoiList } from 'reducers/poi';

import { PoiMarkerProperties, SelectedNotificationMarker, TrackableUnitMarkerProperties } from '../map.types';

export function useClusterPointPopup(map: Map) {
  const dispatch = useDispatch();

  const [showClusterPopup, setShowClusterPopup] = useState({
    notification: false,
    trackableUnits: false,
    poi: false,
  });
  const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 }, mapCoords: { x: 0, y: 0 } });

  useEffect(() => {
    if (!showClusterPopup.notification) {
      dispatch(removeClusterNotificationLogs());
    }
  }, [dispatch, showClusterPopup]);

  // обработчик клика по кластерам
  const pointerUpHandler = useCallback(
    (evt: MapBrowserEvent<PointerEvent>) => {
      const { map, pixel } = evt;
      const feature = map.getFeaturesAtPixel(pixel)[0];
      const coordinates = toLonLat(evt.coordinate);
      const mouseX = evt.originalEvent.clientX;
      const mouseY = evt.originalEvent.clientY;

      setShowClusterPopup({
        notification: false,
        trackableUnits: false,
        poi: false,
      });

      if (feature) {
        const geometry = feature.getGeometry();

        if (geometry?.getType() === GeometryType.POINT) {
          const features = feature.get('features') as Feature<Point>[];
          const featuresLength = features?.length || 0;

          // клик по кластеру, а не точке (маркеру)
          if (featuresLength > 1) {
            const properties = features.map(feature => feature.getProperties());

            if (properties?.length) {
              const markerType = properties[0].type;

              setMarkerCoords({
                mouseCoords: { x: mouseX, y: mouseY },
                mapCoords: { x: coordinates[0], y: coordinates[1] },
              });

              // покажем попап в зависимости от типа точек в кластере
              switch (markerType) {
                // employees/transports
                case 'geoMarker':
                  evt.preventDefault(); // отменяем зумминг карты при клике на кластер

                  const chosenTrackableUnitsIds: ChosenClusterTrackableUnitsInfo[] = [];

                  (properties as TrackableUnitMarkerProperties[]).forEach(property => {
                    const { iconName, info } = property;

                    if (iconName === 'employeeIcon' && info.id) {
                      chosenTrackableUnitsIds.push({ employeeId: info.id, markerInfo: property });
                    }
                    if (iconName === 'transportIcon' && info.id) {
                      chosenTrackableUnitsIds.push({ transportId: info.id, markerInfo: property });
                    }
                  });

                  dispatch(setChosenClusterTrackableUnitsInfo(chosenTrackableUnitsIds));
                  setShowClusterPopup({
                    ...showClusterPopup,
                    trackableUnits: true,
                  });
                  break;

                // notifications
                case 'notificationMarker':
                  evt.preventDefault(); // отменяем зумминг карты при клике на кластер

                  const ids = (properties as SelectedNotificationMarker[])
                    .map(property => property.info.logId || 0)
                    .filter(id => !!id);

                  setShowClusterPopup({
                    ...showClusterPopup,
                    notification: true,
                  });
                  dispatch(setClusterNotificationLogsIds(ids));
                  break;

                // poi
                case 'poiMarker':
                  evt.preventDefault(); // отменяем зумминг карты при клике на кластер

                  dispatch(setChosenClusterPoiList(properties as PoiMarkerProperties[]));
                  setShowClusterPopup({
                    ...showClusterPopup,
                    poi: true,
                  });
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    },
    [dispatch, showClusterPopup]
  );

  useEffect(() => {
    map.on('click', pointerUpHandler);
    // map.on('pointerup', pointerUpHandler);

    return () => {
      map.un('click', pointerUpHandler);
      // map.un('pointerup', pointerUpHandler);
    };
  }, [map, pointerUpHandler]);

  return {
    markerCoords,
    showClusterPopup,
    onCancel: () =>
      setShowClusterPopup({
        notification: false,
        trackableUnits: false,
        poi: false,
      }),
  };
}
