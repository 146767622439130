export default {
  en: {
    'toast.monitoring.add-employee.error.text': 'Temporarily you cannot create more than 7 employees',
    'toast.monitoring.add-transport.error.text': 'Temporarily you cannot create more than 7 transport cards',
    'toast.monitoring.add-transport.error.year-no-number.text': 'Year of issue must be a number',
    'toast.monitoring.add-transport.error.driver-is-busy.text': 'this driver is attached to another car',
    'toast.monitoring.add-transport.error.empty-driver-list.text': 'List of drivers is empty',
  },
  ru: {
    'toast.monitoring.add-employee.error.text': 'Временно нельзя создавать больше 7 карточек сотрудников',
    'toast.monitoring.add-transport.error.text': 'Временно нельзя создавать больше 7 карточек транспорта',
    'toast.monitoring.add-transport.error.year-no-number.text': 'Год выпуска должен быть числом',
    'toast.monitoring.add-transport.error.driver-is-busy.text': 'данный водитель прикреплен к другому авто',
    'toast.monitoring.add-transport.error.empty-driver-list.text': 'Список водителей пуст',
  },
  zh: {
    'toast.monitoring.add-employee.error.text': '暂时您不能创建超过 7 张卡片',
    'toast.monitoring.add-transport.error.text': '暂时您不能创建超过 7 张卡片',
    'toast.monitoring.add-transport.error.year-no-number.text': '出产年应是数值',
    'toast.monitoring.add-transport.error.driver-is-busy.text': '该司机与其他运输工具链接',
    'toast.monitoring.add-transport.error.empty-driver-list.text': '司机清单为空',
  },
  ar: {
    'toast.monitoring.add-employee.error.text': 'مؤقتًا لا يمكنك إنشاء أكثر من 7 بطاقات',
    'toast.monitoring.add-transport.error.text': 'مؤقتًا لا يمكنك إنشاء أكثر من 7 بطاقات',
    'toast.monitoring.add-transport.error.year-no-number.text': 'يجب أن تكون سنة الإصدار رقمًا',
    'toast.monitoring.add-transport.error.driver-is-busy.text': 'هذا السائق متصل بوسيلة نقل أخرى',
    'toast.monitoring.add-transport.error.empty-driver-list.text': 'قائمة السائقين فارغة',
  },
  de: {
    'toast.monitoring.add-employee.error.text': 'Vorübergehend können Sie nicht mehr als 7 Karten erstellen',
    'toast.monitoring.add-transport.error.text': 'Vorübergehend können Sie nicht mehr als 7 Karten erstellen',
    'toast.monitoring.add-transport.error.year-no-number.text': 'Das Ausstellungsjahr muss eine Zahl sein',
    'toast.monitoring.add-transport.error.driver-is-busy.text': 'Dieser Fahrer ist einem anderen Fahrzeug zugeordnet',
    'toast.monitoring.add-transport.error.empty-driver-list.text': 'Fahrerliste ist leer',
  },
};
