import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as Warning } from 'assets/img/warning.svg';

import Button from 'components/common/button/button';

import { RootState } from 'reducers';
import { closeAlert, closeModal } from 'reducers/modal';
import { removeEmployee, removeChosenEmployee } from 'reducers/employees';

import styles from '../monitoring.module.scss';

const cx = classNames.bind(styles);

const DeleteEmployeeModalForm = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const onCancel = () => {
    dispatch(closeAlert());
  };
  const employeeID = useSelector((state: RootState) => state.employee.chosenEmployeeData?.id ?? '');

  const onDelete = () => {
    if (employeeID) {
      dispatch(removeEmployee(employeeID));
    }
    dispatch(removeChosenEmployee());
    dispatch(closeAlert());
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <div>{t('monitoring.unit-card.delete-employee-alert.header.label')}</div>
        <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={onCancel} />
      </div>
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('monitoring.unit-card.delete-employee-alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>
          {t('monitoring.unit-card.delete-employee-alert.warning-desc.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('monitoring.unit-card.delete-employee-alert.question.text')}</p>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('monitoring.unit-card.delete-employee-alert.btn.cancel.label')} onClick={onCancel} />
        <Button red text={t('monitoring.unit-card.delete-employee-alert.btn.delete.label')} onClick={onDelete} />
      </div>
    </Fragment>
  );
};

export default DeleteEmployeeModalForm;
