import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HANDBOOK_KEYS, HANDBOOK_NAMES } from 'components/handbooks/utils/consts';
import { RootState } from 'reducers';

import { getBindedHandbookKeysForChosenElements } from 'components/handbooks/utils/helpers';

import messages, { DEFAULT_LANGUAGES, getCurrentLocale, LanguagesKeysType } from 'translate';

import { reqHandlers } from 'utils/api';
import { GetFileInfo } from 'utils/api/interface';
import { downloadFileToDisk } from 'utils/downloadFiles';

import {
  HandbookName,
  HandbookElement,
  HandbookElementsImportingResponseJSONApi,
  HandbookJSONApi,
  HandbookState,
  HandbookData,
  HandbookDataJSONApi,
  HandbookNewElement,
  HandbookStatisticJSONApi,
  HandbookStatisticDataFilter,
  HandbookTranslatedFieldType,
  PostTransportDriversHandbookRequest,
  PutTransportDriversHandbookRequest,
} from './interface';
import {
  getHandbooksNames,
  getHandbooksData,
  importElements,
  exportElements,
  createElement,
  getHandbooksStatistic,
  exportHandbooksStatistic,
  putElement,
  deleteElements,
  getTransportDriversHandbookData,
  postTransportDriversHandbookData,
  putTransportDriversHandbookData,
} from './api';
import { FormFields as TransportDriversFormData } from 'components/handbooks/components/handbooksTable/components/handbooksCard/components/customHandbookCards/transportDriverCard/transportDriverCard';

const sliceName = 'handbooks';

const defaultStatisticFilter: HandbookStatisticDataFilter = {
  locale: DEFAULT_LANGUAGES,
};

const defaultTransportDriversState = {
  isLoading: false,
  data: [],
  updateCounter: 0,
  chosenElementId: null,
  copyElementId: null,
  showCard: false,
};

const initialState: HandbookState = {
  handbooksNames: [],
  handbooksNamesIsLoading: false,
  chosenHandbookName: null,

  data: {},
  isDataLoading: false,
  needUpdate: false,

  transportDriversHandbook: defaultTransportDriversState,

  isFileImporting: false,
  fileImportingResponse: null,

  isFileExporting: false,

  search: '',
  searchedHandbooksNames: [],

  elements: [],
  chosenElement: null,

  newElement: null,

  statistic: [],
  statisticIsLoading: false,
  statisticTotal: 0,
  statisticDataFilter: defaultStatisticFilter,

  statisticFileIsExporting: false,

  error: null,
};

export const fetchHandbooksNames = createAsyncThunk('handbooks/fetchHandbooksNames', async (_, thunkApi) => {
  const {
    user: {
      userPreferences: { locale },
    },
  } = thunkApi.getState() as RootState;
  const handbookNames = await getHandbooksNames();

  return { handbookNames, locale };
});

export const importElementsFromFile = createAsyncThunk(
  'handbooks/importElementsFromFile',
  async ({ key, file }: { key: string; file: File }) => await importElements(key, file)
);

export const exportElementsToFile = createAsyncThunk(
  'handbooks/exportElementsToFile',
  async (key: string) => await exportElements(key)
);

export const fetchHandbookData = createAsyncThunk(
  'handbooks/fetchHandbookData',
  async (keys: HANDBOOK_KEYS[], thunkApi) => {
    if (keys.length) {
      const { getState } = thunkApi;
      const state = getState() as RootState;
      const { chosenHandbookName } = state.handbooks;
      const primaryHandbooks: PromiseSettledResult<HandbookDataJSONApi>[] = await reqHandlers.allSettled(
        keys.map(key => ({
          promiseMethod: getHandbooksData,
          args: key,
        }))
      );

      // если находимся в справочниках (chosenHandbookName) и выбран
      // определенный справочник, то получим также связанные справочники
      if (!!primaryHandbooks && chosenHandbookName) {
        const currentBindedHandbookKeys = getBindedHandbookKeysForChosenElements(chosenHandbookName.keyName);

        if (currentBindedHandbookKeys.length) {
          const secondaryHandbooks: PromiseSettledResult<HandbookDataJSONApi>[] = await reqHandlers.allSettled(
            currentBindedHandbookKeys.map(key => ({
              promiseMethod: getHandbooksData,
              args: key,
            }))
          );

          if (!secondaryHandbooks || !secondaryHandbooks.length) {
            return { handbookData: primaryHandbooks, handbookKeys: keys };
          }
          return {
            handbookData: [...primaryHandbooks, ...secondaryHandbooks],
            handbookKeys: [...keys, ...currentBindedHandbookKeys],
          };
        }
      }
      return { handbookData: primaryHandbooks, handbookKeys: keys };
    }
    return { handbookData: [], handbookKeys: [] };
  }
);

export const fetchTransportDriversHandbookData = createAsyncThunk(
  `${sliceName}/fetchTransportDriversHandbookData`,
  async () => await getTransportDriversHandbookData()
);

export const addTransportDriversHandbookData = createAsyncThunk(
  `${sliceName}/addTransportDriversHandbookData`,
  async (formData: TransportDriversFormData) => {
    const data: PostTransportDriversHandbookRequest = {
      firstName: formData.firstName,
      secondName: formData.secondName,
      lastName: formData.lastName,
      organizations: [parseInt(formData.organization)],
      departments: [parseInt(formData.department)],
      positions: [parseInt(formData.position)],
    };

    return await postTransportDriversHandbookData(data);
  }
);

export const updateTransportDriversHandbookData = createAsyncThunk(
  `${sliceName}/updateTransportDriversHandbookData`,
  async ({ id, formData }: { id: number; formData: TransportDriversFormData }) => {
    const data: PutTransportDriversHandbookRequest = {
      firstName: formData.firstName,
      secondName: formData.secondName,
      lastName: formData.lastName,
      organizations: [parseInt(formData.organization)],
      departments: [parseInt(formData.department)],
      positions: [parseInt(formData.position)],
    };

    return await putTransportDriversHandbookData(id, data);
  }
);

export const deleteTransportDriversHandbookData = createAsyncThunk(
  `${sliceName}/deleteTransportDriversHandbookData`,
  async (id: number) => await deleteElements({ id, key: HANDBOOK_KEYS.transportDrivers })
);

export const addElement = createAsyncThunk(
  'handbooks/addElement',
  async ({ key, data }: { key: HANDBOOK_KEYS; data: HandbookNewElement }) => await createElement({ key, data })
);

export const updateElement = createAsyncThunk(
  'handbooks/updateElement',
  async ({ id, key, data }: { id: number; key: HANDBOOK_KEYS; data: HandbookNewElement }) =>
    await putElement({ id, key, data })
);

export const deleteElement = createAsyncThunk(
  'handbooks/deleteElement',
  async ({ id, key }: { id: number; key: HANDBOOK_KEYS }) => await deleteElements({ id, key })
);

export const fetchHandbookStatistic = createAsyncThunk('handbooks/fetchHandbookStatistic', async (_, thunkApi) => {
  const {
    handbooks,
    user: {
      userPreferences: { locale },
    },
  } = thunkApi.getState() as RootState;
  return await getHandbooksStatistic({ ...handbooks.statisticDataFilter, locale });
});

export const exportStatisticToFile = createAsyncThunk('handbooks/exportStatisticToFile', async (_, thunkApi) => {
  const {
    handbooks,
    user: {
      userPreferences: { locale },
    },
  } = thunkApi.getState() as RootState;

  return await exportHandbooksStatistic({ ...handbooks.statisticDataFilter, locale });
});

const handbooksSlice = createSlice({
  name: 'handbooksSlice',
  initialState,
  reducers: {
    setChosenHandbook: (state, { payload }: { payload: HandbookName | null }) => {
      state.chosenHandbookName = payload;
    },

    setElements: (state, { payload }: { payload: HandbookElement[] }) => {
      state.elements = payload;
    },
    setChosenElement: (state, { payload }: { payload: HandbookElement | null }) => {
      state.chosenElement = payload;
    },

    setSearch: (state, { payload }: { payload: string }) => {
      state.search = payload;
    },
    setSearchedHandbooks: (state, { payload }: { payload: HandbookName[] }) => {
      if (payload?.length) {
        state.searchedHandbooksNames = [...payload];
      } else {
        state.searchedHandbooksNames = [];
      }
    },

    clearFileImportingResponse: state => {
      state.fileImportingResponse = null;
    },

    setNewElement: (state, { payload }: { payload: HandbookNewElement | null }) => {
      state.newElement = payload;
    },
    setNewElementValue: (
      state,
      { payload }: { payload: { key: string; value: HandbookTranslatedFieldType | string | number[] } }
    ) => {
      const newElementValues: HandbookNewElement = {
        name: {
          ...(state.newElement?.name || { [DEFAULT_LANGUAGES]: '' }),
        },
      };

      switch (payload.key) {
        case 'name':
          if (state.newElement) {
            newElementValues.name = {
              ...state.newElement.name,
              ...(payload.value as HandbookTranslatedFieldType),
            };
          }
          break;

        default:
          newElementValues[payload.key] = payload.value;
          break;
      }
      state.newElement = {
        ...state.newElement,
        ...newElementValues,
      };
    },

    setFilterField: (state, { payload }: { payload: { key: string; value: unknown } }) => {
      state.statisticDataFilter = {
        ...state.statisticDataFilter,
        [payload.key]: payload.value,
      };
    },
    clearHistoryLogsFilter: state => {
      state.statisticDataFilter = defaultStatisticFilter;
    },
    setChosenTransportDriver: (state, action: PayloadAction<number | null>) => {
      state.transportDriversHandbook.chosenElementId = action.payload;
    },
    clearTransportDriversHadbook: state => {
      state.transportDriversHandbook = defaultTransportDriversState;
    },
    setShowTransportDriversCard: (state, action: PayloadAction<boolean>) => {
      state.transportDriversHandbook.showCard = action.payload;
    },
    setCopyTransportDriver: (state, action: PayloadAction<number | null>) => {
      state.transportDriversHandbook.copyElementId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchHandbooksNames.pending, state => {
        state.error = null;
        state.handbooksNamesIsLoading = true;
      })
      .addCase(
        fetchHandbooksNames.fulfilled,
        (state, { payload }: { payload: { handbookNames: HandbookJSONApi; locale: LanguagesKeysType } }) => {
          const locale = getCurrentLocale(payload.locale);

          state.handbooksNames = payload.handbookNames.data.map((key, i) => {
            return {
              id: i + 1,
              keyName: key,
              name: messages[locale][HANDBOOK_NAMES[key as keyof typeof HANDBOOK_NAMES]],
            } as HandbookName;
          });
          state.handbooksNamesIsLoading = false;
        }
      )
      .addCase(fetchHandbooksNames.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.handbooksNamesIsLoading = false;
      });
    builder
      .addCase(importElementsFromFile.pending, state => {
        state.error = null;
        state.isFileImporting = true;
      })
      .addCase(
        importElementsFromFile.fulfilled,
        (state, { payload }: { payload: HandbookElementsImportingResponseJSONApi }) => {
          state.fileImportingResponse = payload.data;
          state.isFileImporting = false;
          state.needUpdate = true;
        }
      )
      .addCase(importElementsFromFile.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isFileImporting = false;
      });
    builder
      .addCase(exportElementsToFile.pending, state => {
        state.error = null;
        state.isFileExporting = true;
      })
      .addCase(exportElementsToFile.fulfilled, (state, { payload }: { payload: GetFileInfo }) => {
        const additionalFields = payload.successResponseFields;

        if (state.chosenHandbookName && additionalFields.type.match('text/csv')) {
          downloadFileToDisk(payload.data, additionalFields.disposition || `${state.chosenHandbookName.keyName}.csv`);
        }
        state.isFileExporting = false;
      })
      .addCase(exportElementsToFile.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isFileExporting = false;
      });
    builder
      .addCase(fetchHandbookData.pending, state => {
        state.error = null;
        state.isDataLoading = true;
      })
      .addCase(
        fetchHandbookData.fulfilled,
        (
          state,
          {
            payload,
          }: { payload: { handbookData: PromiseSettledResult<HandbookDataJSONApi>[]; handbookKeys: HANDBOOK_KEYS[] } }
        ) => {
          const { handbookData, handbookKeys } = payload;

          if (handbookData.length === handbookKeys.length) {
            const newData: HandbookData = {};

            handbookData.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                const data = result.value.data;
                const key = handbookKeys[index];

                newData[key] = data;
              }
            });

            if (Object.keys(newData).length) {
              state.data = {
                ...state.data,
                ...newData,
              };
            }
          }

          state.needUpdate = false;
          state.isDataLoading = false;
        }
      )
      .addCase(fetchHandbookData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isDataLoading = false;
      });
    builder
      .addCase(addElement.pending, state => {
        state.error = null;
      })
      .addCase(addElement.fulfilled, state => {
        state.needUpdate = true;
        state.newElement = null;
      })
      .addCase(addElement.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateElement.pending, state => {
        state.error = null;
      })
      .addCase(updateElement.fulfilled, state => {
        state.chosenElement = null;
        state.needUpdate = true;
      })
      .addCase(updateElement.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(deleteElement.pending, state => {
        state.error = null;
      })
      .addCase(deleteElement.fulfilled, state => {
        state.chosenElement = null;
        state.needUpdate = true;
      })
      .addCase(deleteElement.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchHandbookStatistic.pending, state => {
        state.error = null;
        state.statisticIsLoading = true;
      })
      .addCase(fetchHandbookStatistic.fulfilled, (state, { payload }: { payload: HandbookStatisticJSONApi }) => {
        state.statisticIsLoading = false;
        state.statistic = payload.data;
        state.statisticTotal = payload.meta.total;
      })
      .addCase(fetchHandbookStatistic.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.statisticIsLoading = false;
      });
    builder
      .addCase(exportStatisticToFile.pending, state => {
        state.error = null;
        state.statisticFileIsExporting = true;
      })
      .addCase(exportStatisticToFile.fulfilled, (state, { payload }: { payload: GetFileInfo }) => {
        const additionalFields = payload.successResponseFields;

        if (additionalFields.type.match('text/csv')) {
          downloadFileToDisk(payload.data, additionalFields.disposition || 'statistic.csv');
        }
        state.statisticFileIsExporting = false;
      })
      .addCase(exportStatisticToFile.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.statisticFileIsExporting = false;
      });
    builder
      .addCase(fetchTransportDriversHandbookData.pending, state => {
        state.error = null;
        state.transportDriversHandbook.isLoading = true;
      })
      .addCase(fetchTransportDriversHandbookData.fulfilled, (state, { payload }) => {
        state.transportDriversHandbook.data = payload.data.map(data => ({
          id: data.id,
          organizationId: data.relationships.organizations.data[0].id,
          departmentId: data.relationships.departments.data[0].id,
          positionId: data.relationships.positions.data[0].id,
          ...data.attributes,
        }));
        state.transportDriversHandbook.isLoading = false;
      })
      .addCase(fetchTransportDriversHandbookData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.transportDriversHandbook.isLoading = false;
      });
    builder
      .addCase(addTransportDriversHandbookData.pending, state => {
        state.error = null;
      })
      .addCase(addTransportDriversHandbookData.fulfilled, state => {
        state.transportDriversHandbook.updateCounter += 1;
      })
      .addCase(addTransportDriversHandbookData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateTransportDriversHandbookData.pending, state => {
        state.error = null;
      })
      .addCase(updateTransportDriversHandbookData.fulfilled, state => {
        state.transportDriversHandbook.updateCounter += 1;
      })
      .addCase(updateTransportDriversHandbookData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(deleteTransportDriversHandbookData.pending, state => {
        state.error = null;
      })
      .addCase(deleteTransportDriversHandbookData.fulfilled, state => {
        state.transportDriversHandbook.chosenElementId = null;
        state.transportDriversHandbook.showCard = false;
        state.transportDriversHandbook.updateCounter += 1;
      })
      .addCase(deleteTransportDriversHandbookData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
  },
});

export const {
  setChosenHandbook,

  setElements,
  setChosenElement,

  setSearch,
  setSearchedHandbooks,

  clearFileImportingResponse,

  setNewElement,
  setNewElementValue,

  setFilterField,
  clearHistoryLogsFilter,

  setChosenTransportDriver,
  clearTransportDriversHadbook,
  setShowTransportDriversCard,
  setCopyTransportDriver,
} = handbooksSlice.actions;

export default handbooksSlice.reducer;
