export default {
  en: {
    'photo.upload.error': 'An error occurred while loading, please try again',
    'photo.upload.error.size.text': 'File size is more than 2 MB',
    'photo.header.text': 'Photo',
    'photo.upload.error.bad-img-size.text': 'File resolution is greater than 1024x1024 pixels',
  },
  ru: {
    'photo.upload.error': 'Произошла ошибка загрузки, попробуйте еще раз',
    'photo.upload.error.size.text': 'Файл больше 2 Мб',
    'photo.header.text': 'Фотография',
    'photo.upload.error.bad-img-size.text': 'Разрешение файла больше 1024x1024 пикселей',
  },
  zh: {
    'photo.upload.error': '加载时出错，请重试',
    'photo.upload.error.size.text': '文件大小超过2兆字节',
    'photo.header.text': '照片',
    'photo.upload.error.bad-img-size.text': '文件分辨率超过1024x1024像素',
  },
  ar: {
    'photo.upload.error': 'حدث خطأ أثناء التحميل ، يرجى المحاولة مرة أخرى',
    'photo.upload.error.size.text': 'حجم الملف أكثر من 2 ميغا بايت',
    'photo.header.text': 'الصورة',
    'photo.upload.error.bad-img-size.text': 'دقة الملف أكبر من 1024 × 1024 بكسل',
  },
  de: {
    'photo.upload.error': 'Beim Laden ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
    'photo.upload.error.size.text': 'Die Dateigröße beträgt mehr als 2 MB',
    'photo.header.text': 'Foto',
    'photo.upload.error.bad-img-size.text': 'Die Dateiauflösung ist größer als 1024 x 1024 Pixel',
  },
};
