import React, { CSSProperties, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as GearIcon } from 'assets/img/gear.svg';
import { ReactComponent as ClosedLockIcon } from 'assets/img/closed-lock.svg';
import { ReactComponent as ClosedOpenIcon } from 'assets/img/closed-open.svg';

import { ExpandButton } from 'components/common/expandButton/expandButton';

import { ExpandedGroupsState } from 'reducers/clientUserPreferences/interface';
import { setChosenAccount } from 'reducers/accounts';
import { showModal } from 'reducers/modal';

import { TreeGroupNode } from 'utils/treeGroupBy/treeGroupBy';
import { ACCOUNT } from 'utils/consts';

import { UsersListViewData } from '../../usersList';
import RolesView from './components/rolesView/rolesView';

import styles from './usersTree.module.scss';

type Props = {
  nodes: TreeGroupNode<UsersListViewData>[];
  expandState: ExpandedGroupsState;
  columns: (keyof UsersListViewData)[];
  nestLevel?: number;
  organizations: { [id: string]: string };
  departments: { [id: string]: string };
  positions: { [id: string]: string };
  handleExpandClick: (groupKey: string) => void;
};

type GroupRowProps = {
  isOpen: boolean;
  groupLabel: string;
  nestLevel: number;
  onClick: () => void;
};

type ItemRowProps = {
  item: UsersListViewData;
  columns: (keyof UsersListViewData)[];
  organizations: { [id: string]: string };
  departments: { [id: string]: string };
  positions: { [id: string]: string };
  nestLevel: number;
};

const messages = {
  entity: (entity: string) => `accounts.users-list-tree.entity-${entity}.text`,
};

function getPaddingLeft(nestLevel: number) {
  return `${nestLevel * 25 + 12}px`;
}

const GroupRow: React.FC<GroupRowProps> = ({ isOpen, groupLabel, nestLevel, onClick }) => (
  <tr>
    <td style={{ paddingLeft: getPaddingLeft(nestLevel) }}>
      <div className={styles.groupCell}>
        <ExpandButton isOpen={isOpen} onClick={onClick} style={styles.expandButton} />
        <div className={styles.groupLabel}>{groupLabel}</div>
      </div>
    </td>
  </tr>
);

const ItemRow: React.FC<ItemRowProps> = ({ item, columns, organizations, departments, positions, nestLevel }) => {
  const dispatch = useDispatch();

  const renderColumn = (column: keyof UsersListViewData) => {
    switch (column) {
      case 'organization':
        return item[column] ? organizations[item[column]!] : '';
      case 'department':
        return item[column] ? departments[item[column]!] : '';
      case 'position':
        return item[column] ? positions[item[column]!] : '';
      case 'roles':
        return <RolesView roles={item[column]} />;
      default:
        return item[column];
    }
  };

  const handleEditClick = () => {
    dispatch(setChosenAccount(item.id));
    dispatch(showModal({ type: ACCOUNT, which: '' }));
  };

  return (
    <tr key={item.id}>
      {columns.map((column, index) => {
        const style: CSSProperties = {};

        if (index === 0) {
          style.paddingLeft = getPaddingLeft(nestLevel);
        }

        return (
          <td style={style} key={column}>
            {renderColumn(column)}
          </td>
        );
      })}

      <td className={styles.itemIconsCell}>
        <div className={styles.itemIconsContainer}>
          {item.isBanned ? <ClosedLockIcon /> : <ClosedOpenIcon />}
          <GearIcon className={styles.editIcon} onClick={handleEditClick} />
        </div>
      </td>
    </tr>
  );
};

const UsersTree: React.FC<Props> = ({
  nodes,
  expandState,
  columns,
  organizations,
  departments,
  positions,
  nestLevel = 0,
  handleExpandClick,
}) => {
  const t = useFormatMessage();

  return (
    <>
      {nodes.map(node => {
        if (node.groupBy) {
          return Object.keys(node.children).map(group => {
            const groupKey = `${node.key}__${group}`;
            const groupEntity = t(messages.entity(node.groupBy!));

            let groupLabel = '';

            switch (node.groupBy) {
              case 'organization':
                groupLabel = `${groupEntity} "${organizations[group]}"`;
                break;
              case 'department':
                groupLabel = `${groupEntity} "${departments[group]}"`;
                break;
              case 'position':
                groupLabel = `${groupEntity} "${positions[group]}"`;
                break;
            }

            return (
              <Fragment key={groupKey}>
                <GroupRow
                  isOpen={expandState[groupKey]}
                  groupLabel={groupLabel}
                  onClick={() => handleExpandClick(groupKey)}
                  nestLevel={nestLevel}
                />

                {expandState[groupKey] && (
                  <UsersTree
                    nodes={node.children[group]}
                    expandState={expandState}
                    columns={columns}
                    nestLevel={nestLevel + 1}
                    organizations={organizations}
                    departments={departments}
                    positions={positions}
                    handleExpandClick={handleExpandClick}
                  />
                )}
              </Fragment>
            );
          });
        } else {
          return node.items.map(item => (
            <ItemRow
              key={item.id}
              item={item}
              columns={columns}
              organizations={organizations}
              departments={departments}
              positions={positions}
              nestLevel={nestLevel}
            />
          ));
        }
      })}
    </>
  );
};

export default UsersTree;
