import React, { useEffect, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { AiFillCloseCircle } from 'react-icons/ai';

import { ReactComponent as Warning } from 'assets/img/warning.svg';

import { PoiAnalyzedGases } from 'reducers/poi/interface';

import Input from 'components/common/input/input';
import Select, { Option } from 'components/common/select/select';
import Button from 'components/common/button/button';
import RangeInputs from 'components/common/rangeInputs/rangeInputs';

import styles from './analyzedGases.module.scss';

const cx = classNames.bind(styles);

interface Props {
  label: string;
  customStyle?: string;
  isRequired?: boolean;
  isValueError?: boolean;
  buttonText: string;
  values: PoiAnalyzedGases[];
  showAddButton: boolean;
  modelId: number;
  valuesForSelect: { gas: Option[]; measurement: Option[] };
  handleChangeValues: (values: PoiAnalyzedGases[]) => void;
}

const AnalyzedGases = (props: Props) => {
  const t = useFormatMessage();

  const [isFocused, setIsFocused] = useState(false);
  const [tableValues, setTableValues] = useState<PoiAnalyzedGases[]>([]);

  useEffect(() => {
    if (props.values?.length) {
      setTableValues(props.values);
    } else {
      setTableValues([]);
    }
  }, [props.values]);

  const wrapClasses = cx(styles.wrapper, props.customStyle, {
    [styles.wrapperFocused]: isFocused,
    [styles.wrapperWarning]: props.isValueError,
  });
  const labelClasses = cx(styles.label, { [styles.labelFocused]: isFocused });

  const sendValues = (values: PoiAnalyzedGases[]) => {
    setTableValues(values);
    props.handleChangeValues(values);
  };

  const handleClickAddValues = () => {
    if (!!props.modelId) {
      const values = [...tableValues];
      const foundNoUsedValueIndex = values.findIndex(value => !value.isUsed);

      if (foundNoUsedValueIndex > -1) {
        values[foundNoUsedValueIndex] = {
          ...values[foundNoUsedValueIndex],
          isUsed: true,
        };
        sendValues(values);
      }
    } else {
      sendValues([
        ...tableValues,
        {
          poiGasTypeId: 0,
          poiGasMeasurementId: 0,
          measureRangeFrom: 0,
          measureRangeTo: 0,
          measureLimit: 0,
          isUsed: true,
        },
      ]);
    }
  };

  const handleClickDeleteRow = (indexRow: number) => {
    let values = [...tableValues];

    if (!!props.modelId) {
      values[indexRow] = {
        ...values[indexRow],
        isUsed: false,
      };

      const valuesIsUsed = values.filter(value => value.isUsed);
      const valuesIsNoUsed = values.filter(value => !value.isUsed);

      values = [...valuesIsUsed, ...valuesIsNoUsed];
    } else {
      values.splice(indexRow, 1);
    }
    sendValues(values);
  };

  const handleChangeGasValue = (indexRow: number, value: number) => {
    const foundGas = props.valuesForSelect.gas.find(mD => mD.value === value);

    if (!foundGas) {
      return;
    }

    const values = [...tableValues];
    values[indexRow] = {
      ...values[indexRow],
      poiGasTypeId: Number(foundGas.data?.id ?? 0),
      isUsed: true,
    };
    sendValues(values);
  };

  const handleChangeMeasurementValue = (indexRow: number, value: number) => {
    const foundMeasure = props.valuesForSelect.measurement.find(mD => mD.value === value);

    if (!foundMeasure) {
      return;
    }

    const values = [...tableValues];

    values[indexRow] = {
      ...values[indexRow],
      poiGasMeasurementId: Number(foundMeasure.data?.id ?? 0),
    };
    sendValues(values);
  };

  const handleChangeRangeFromValue = (indexRow: number, value: string) => {
    const values = [...tableValues];
    const valueNumber = Number(value);

    if (isNaN(valueNumber)) {
      return;
    }
    values[indexRow] = {
      ...values[indexRow],
      measureRangeFrom: valueNumber,
    };
    sendValues(values);
  };

  const handleChangeRangeToValue = (indexRow: number, value: string) => {
    const values = [...tableValues];
    const valueNumber = Number(value);

    if (isNaN(valueNumber)) {
      return;
    }

    values[indexRow] = {
      ...values[indexRow],
      measureRangeTo: valueNumber,
    };
    sendValues(values);
  };

  const handleChangeLimitValue = (indexRow: number, value: string) => {
    const valueNumber = Number(value);

    if (isNaN(valueNumber)) {
      return;
    }

    const values = [...tableValues];

    values[indexRow] = {
      ...values[indexRow],
      measureLimit: Number(valueNumber),
    };
    sendValues(values);
  };

  return (
    <div
      className={wrapClasses}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseLeave={() => setIsFocused(false)}
    >
      {props.isRequired && <Warning className={styles.iconWarning} />}
      <div className={labelClasses}>{props.label}</div>
      <div className={styles.table}>
        {!!tableValues.length && (
          <div className={styles.tableContent}>
            {tableValues.map((tableValue, index) => {
              if (!tableValue.isUsed) {
                return null;
              }
              return (
                <div key={`table-row-${index}`} className={styles.tableRow}>
                  <div className={styles.tableCell}>
                    {index === 0 && (
                      <div className={styles.tableCellDescription}>
                        {t('poi.card.gazanalyzer.field.gazes.table.header.gaz.text')}
                      </div>
                    )}
                    <Select
                      options={props.valuesForSelect.gas}
                      label=""
                      placeholder="-"
                      isRequired={!tableValue.poiGasTypeId}
                      value={
                        props.valuesForSelect.gas.findIndex(val => val.data?.id === tableValue.poiGasTypeId) + 1 || ''
                      }
                      handleChange={value => handleChangeGasValue(index, Number(value))}
                      style={styles.tableSelect}
                      containerStyle={styles.tableEqualWidth}
                      optionsContainerStyle={styles.tableSelectOptions}
                      selectedOptionStyle={styles.selectedOptionStyle}
                      hideCloseIcon
                    />
                  </div>
                  <div className={styles.tableCell}>
                    {index === 0 && (
                      <div className={styles.tableCellDescription}>
                        {t('poi.card.gazanalyzer.field.gazes.table.header.measurement.text')}
                      </div>
                    )}
                    <Select
                      options={props.valuesForSelect.measurement}
                      label=""
                      placeholder="-"
                      isRequired={!tableValue.poiGasMeasurementId}
                      value={
                        props.valuesForSelect.measurement.findIndex(
                          val => val.data?.id === tableValue.poiGasMeasurementId
                        ) + 1 || ''
                      }
                      handleChange={value => handleChangeMeasurementValue(index, Number(value))}
                      style={styles.tableSelect}
                      containerStyle={styles.tableEqualWidth}
                      optionsContainerStyle={styles.tableSelectOptions}
                      selectedOptionStyle={styles.selectedOptionStyle}
                      hideCloseIcon
                    />
                  </div>
                  <div className={styles.tableCell}>
                    {index === 0 && (
                      <div className={styles.tableCellDescription}>
                        {t('poi.card.gazanalyzer.field.gazes.table.header.range.text')}
                      </div>
                    )}
                    <RangeInputs
                      placeholderFrom=""
                      placeholderTo=""
                      isRequired={false}
                      valueFrom={String(tableValue.measureRangeFrom)}
                      valueTo={String(tableValue.measureRangeTo)}
                      handleInputFromChange={value => handleChangeRangeFromValue(index, value)}
                      handleInputToChange={value => handleChangeRangeToValue(index, value)}
                      wrapperStyle={styles.range}
                      inputsWrapperStyle={styles.rangeInputsWrap}
                    />
                  </div>
                  <div className={styles.tableCell}>
                    {index === 0 && (
                      <div className={styles.tableCellDescription}>
                        {t('poi.card.gazanalyzer.field.gazes.table.header.limit.text')}
                      </div>
                    )}
                    <Input
                      label="-"
                      placeholder="-"
                      isRequired={false}
                      value={String(tableValue.measureLimit)}
                      handleInputChange={value => handleChangeLimitValue(index, value)}
                      customStyle={styles.tableInput}
                      maxLength={8}
                    />
                  </div>
                  <AiFillCloseCircle
                    color="#999999"
                    className={cx(styles.tableRowDelete, {
                      [styles.tableRowDeleteFirst]: index === 0,
                    })}
                    onClick={() => handleClickDeleteRow(index)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!props.showAddButton && <Button gray text={props.buttonText} onClick={handleClickAddValues} />}
    </div>
  );
};

export default AnalyzedGases;
