import React from 'react';
import { useSelector } from 'react-redux';

import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import { RootState } from 'reducers';

import TransportDriversHandbooksElements from './components/customHandbooksElements/transportDriversHandbooksElements';
import DefaultHandbooksElements from './components/defaultHandbooksElements/defaultHandbooksElements';

const HandbooksElements: React.VFC = () => {
  const { chosenHandbookName } = useSelector((state: RootState) => state.handbooks);

  if (!chosenHandbookName) {
    return null;
  }

  switch (chosenHandbookName.keyName) {
    case HANDBOOK_KEYS.transportDrivers:
      return <TransportDriversHandbooksElements />;
    default:
      return <DefaultHandbooksElements />;
  }
};

export default HandbooksElements;
