import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Alert } from 'components/common/alert/alert';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import HandbooksWithBind from './handbooksWithBind';
import HandbookElementData from './handbookElementData';
import HandbookNewElementData from './handbookNewElementData';

import useHandbookWithBind from '../../hooks/useHandbookWithBind';
import useHandbookElementData from '../../hooks/useHandbookElementData';
import useHandbookNewElementData from '../../hooks/useHandbookNewElementData';

import { RootState } from 'reducers';
import { HandbookEntity, HandbookTranslatedFieldType } from 'reducers/handbooks/interface';
import { addElement, setChosenElement, setNewElement, updateElement } from 'reducers/handbooks';
import { showAlert } from 'reducers/modal';

import AccessEntity from 'utils/accessEntity';
import { DELETE_HANDBOOKS_NOTE } from 'utils/consts';

import styles from './defaultHandbookCard.module.scss';
import CardButtons from '../cardButtons/cardButtons';

const cx = classNames.bind(styles);

const DefaultHandbooksCard: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const {
    chosenHandbookName,
    data: handbooksData,
    elements,
    chosenElement,
    newElement,
  } = useSelector((state: RootState) => state.handbooks);

  const [showLocalAlert, setShowLocalAlert] = useState(false);
  const [findedEntity, setFindedEntity] = useState<HandbookEntity | null>(null);

  const handbooksPermissions = useSelector((state: RootState) => state.user.permissions.handbooks);
  const handbooksAccess = useMemo(() => new AccessEntity(handbooksPermissions), [handbooksPermissions]);

  // получение выбранного элемента справочника
  useEffect(() => {
    if (!!chosenHandbookName && !!chosenElement) {
      const { keyName = '' } = chosenHandbookName;

      if (handbooksData[keyName]?.length) {
        const findedEntity = handbooksData[keyName].find(org => org.id === chosenElement.id);

        if (findedEntity) {
          setFindedEntity(findedEntity);
        }
      }
    } else {
      setFindedEntity(null);
    }
  }, [handbooksData, chosenElement, chosenHandbookName]);

  const {
    states: withBindStates,
    handlers: withBindHandlers,
    hasChanges: withBindHasChanges,
  } = useHandbookWithBind(findedEntity);
  const {
    states: elementDataStates,
    handlers: elementDataHandlers,
    hasChanges: elementDataHasChanges,
    invalidFields: elementDataInvalidFields,
    setInvalidFields: setElementDataSetInvalidFields,
    validateRequiredFields: elementDataValidateRequiredFields,
  } = useHandbookElementData(findedEntity);
  const {
    states: newElementStates,
    handlers: newElementHandlers,
    hasChanges: newElementHasChanges,
    invalidFields: newElementInvalidFields,
    setInvalidFields: setNewElementInvalidFields,
    validateRequiredFields: newElementValidateRequiredFields,
  } = useHandbookNewElementData();

  const handleCancel = () => {
    const hasElementDataChanges = elementDataHasChanges();
    const hasWithBindChanges = withBindHasChanges();
    const hasNewElementChanges = newElementHasChanges();

    if (!hasElementDataChanges && !hasWithBindChanges && !hasNewElementChanges) {
      dispatch(setChosenElement(null));
      dispatch(setNewElement(null));
      return;
    }
    return setShowLocalAlert(true);
  };

  const handleDelete = () => {
    dispatch(showAlert({ alertType: DELETE_HANDBOOKS_NOTE }));
  };

  const handleSave = () => {
    const validationElementDataResult = elementDataValidateRequiredFields();
    const validationNewElementResult = newElementValidateRequiredFields();

    if (!newElement && validationElementDataResult.length) {
      return setElementDataSetInvalidFields(validationElementDataResult);
    }
    if (validationNewElementResult.length) {
      return setNewElementInvalidFields(validationNewElementResult);
    }
    if (newElement?.name && elements.map(el => el.name).includes(newElement.name as HandbookTranslatedFieldType)) {
      return toast.error(t('toast.handbooks.element-card.name-existed.error.text'));
    }
    setElementDataSetInvalidFields([]);
    setNewElementInvalidFields([]);

    if (chosenHandbookName && Object.keys(HANDBOOK_KEYS).includes(chosenHandbookName.keyName)) {
      if (newElement && handbooksAccess.isAllowCreate()) {
        return dispatch(
          addElement({
            key: HANDBOOK_KEYS[chosenHandbookName.keyName as HANDBOOK_KEYS],
            data: newElement,
          })
        );
      }
      if (chosenElement && handbooksAccess.isAllowUpdate()) {
        const additionalFields = Object.keys(elementDataStates.attributesAdditionalFields).reduce((acc, key) => {
          const value = elementDataStates.attributesAdditionalFields[key];

          if (value) {
            return { ...acc, [key]: value };
          }
          return acc;
        }, {});

        return dispatch(
          updateElement({
            id: chosenElement.id,
            key: HANDBOOK_KEYS[chosenHandbookName.keyName as HANDBOOK_KEYS],
            data: {
              ...additionalFields,
              ...withBindStates.bindedHandbooks,
              name: elementDataStates.name,
            },
          })
        );
      }
    }
  };

  const handleAlertCancel = () => {
    setShowLocalAlert(false);
  };

  const handleAlertContinue = () => {
    dispatch(setChosenElement(null));
    dispatch(setNewElement(null));
    setShowLocalAlert(false);
  };

  const bindedHandbooks: string[] = [
    HANDBOOK_KEYS.organizations as string,
    HANDBOOK_KEYS.poiGasAnalyzerModels as string,
  ];

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {chosenElement && (
            <Fragment>
              <HandbookElementData
                states={elementDataStates}
                handlers={elementDataHandlers}
                invalidFields={elementDataInvalidFields}
              />
              {chosenHandbookName && bindedHandbooks.includes(chosenHandbookName.keyName) && (
                <Fragment>
                  <div className={cx(styles.row, styles.description)}>
                    {t('handbooks.column.elements-card.binded-handbook.text')}
                  </div>
                  <HandbooksWithBind states={withBindStates} handlers={withBindHandlers} />
                </Fragment>
              )}
            </Fragment>
          )}
          {newElement && (
            <HandbookNewElementData
              states={newElementStates}
              handlers={newElementHandlers}
              invalidFields={newElementInvalidFields}
            />
          )}
        </div>

        {(newElement || chosenElement) && (
          <CardButtons
            isEditMode={Boolean(chosenElement)}
            allowCreate={handbooksAccess.isAllowCreate()}
            allowUpdate={handbooksAccess.isAllowUpdate()}
            allowDelete={handbooksAccess.isAllowDelete()}
            handleCancelClick={handleCancel}
            handleSubmitClick={handleSave}
            handleDeleteClick={handleDelete}
          />
        )}
      </div>

      {showLocalAlert && (
        <Alert
          title={t('handbooks.column.elements-card.alert.header.label')}
          infoText={t('handbooks.column.elements-card.alert.info.text')}
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default DefaultHandbooksCard;
