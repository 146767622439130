import React, { useEffect, useRef } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { setAppFooterHeight } from 'reducers/common';

import { BUILD_VERSION } from 'utils/consts';

import { NotificationIcons } from './components/notificationIcons/notificationIcons';

import styles from './footer.module.scss';

const Footer = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const footerRef = useRef<HTMLDivElement>(null);

  const userCount = useSelector((state: RootState) => state.onlineCounter.userCount);

  useEffect(() => {
    if (footerRef.current) {
      dispatch(setAppFooterHeight(footerRef.current.getBoundingClientRect().height));
    }
  }, []);

  return (
    <div ref={footerRef} className={styles.footer}>
      <div className={styles.version}>
        <span>
          {'v: '}
          {BUILD_VERSION}
        </span>
      </div>
      <div className={styles.copyright}>&copy;&nbsp;{t('common-info.footer.copyright.text')}</div>
      <NotificationIcons />
    </div>
  );
};

export default Footer;
