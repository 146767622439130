import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'components/common/spinner/spinner';
import TrackerListHeader from './components/listHeader';
import TrackersListItems from './components/listItems';

import { RootState } from 'reducers';
import { fetchTrackers } from 'reducers/trackers';

import AccessEntity from 'utils/accessEntity';

const TrackersList: React.FC = () => {
  const dispatch = useDispatch();

  const { needUpdate, isLoading } = useSelector((state: RootState) => state.tracker);
  const permissions = useSelector((state: RootState) => state.user.permissions.trackers);

  const trackersPermission = useMemo(() => {
    return new AccessEntity(permissions);
  }, [permissions]);

  useEffect(() => {
    if (trackersPermission.isAllowRead() && needUpdate) {
      dispatch(fetchTrackers());
    }
  }, [dispatch, needUpdate, trackersPermission]);

  return (
    <div>
      <TrackerListHeader />
      {isLoading ? <Spinner /> : <TrackersListItems />}
    </div>
  );
};

export default React.memo(TrackersList);
