// функция преобразования данных объекта в csv-строку
// objToCsv - объект со строковыми полями, содержащие массивы строк
// separator - строковый разделитель
export function objToCsv(objData: JSON | Record<string, unknown>, separator: string) {
  try {
    const obj: Record<string, unknown> = typeof objData === 'string' ? JSON.parse(objData) : objData;
    const objKeys = Object.keys(obj);
    const objArrays = [objKeys];

    const maxArrLength = objKeys.reduce((acc, key) => {
      let values = obj[key] as string[];

      if (!Array.isArray(values)) {
        values = [JSON.stringify(values, null, 2)];
      }
      return Math.max(acc, values.length);
    }, 0);

    for (let i = 0; i < maxArrLength; i++) {
      const arr: string[] = [];

      objKeys.forEach(key => {
        let values = obj[key] as string[];

        if (!Array.isArray(values)) {
          values = [JSON.stringify(values, null, 2)];
        }
        arr.push(values[i].replace(',', ' ') || '');
      });
      objArrays.push(arr);
    }

    let csvString = '';

    objArrays.forEach(arr => {
      csvString += `${arr.join(separator)}\n`;
    });
    return csvString;
  } catch (error) {}
  return '';
}
