/*eslint no-throw-literal: "off"*/
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Cluster, OSM, Vector as VectorSource, XYZ } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { PoiFeatureProperties, PoiMarkerProperties, Styles } from './map.types';
import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import turfBuffer from '@turf/buffer';
import {
  COLOR_DANGER_LEVEL_1,
  COLOR_DANGER_LEVEL_2,
  COLOR_DANGER_LEVEL_3,
  HEATMAP,
  LAYERS,
  MAPBOX_API_TOKEN,
  MAPBOX_URL,
  MARKERS,
  brightGeozoneMarkerTextColors,
  MAP_LAYER_RENDER_BUFFER_VALUE,
} from 'utils/consts';

import OutdoorCamera from 'assets/img/outdoor_camera.png';
import EmployeeIcon from 'assets/img/markers/employee-middle.png';
import TransportIcon from 'assets/img/markers/transport-middle.png';
import DropArrow from 'assets/img/drop-arrow.png';

import SosIcon from 'assets/img/notifications/icons/alertIcon.svg';
import GeozoneIcon from 'assets/img/notifications/icons/geozoneIcon.svg';
import InterpositionIcon from 'assets/img/notifications/icons/interpositionIcon.svg';
import SensorIcon from 'assets/img/notifications/icons/sensorIcon.svg';
import VideoIcon from 'assets/img/notifications/icons/videoIcon.svg';

import PoiVideoCameraIcon from 'assets/img/poi/icons/videocamera/videocamera_marker.svg';
import PoiGazAnalyzerIcon from 'assets/img/poi/icons/gazanalyzer/gazanalyzer_marker.svg';
import PoiSensorIcon from 'assets/img/poi/icons/sensor/sensor_marker.svg';
import PoiEquipmentFirstAidKitIcon from 'assets/img/poi/icons/equipment/firstaidkit_marker.svg';
import PoiEquipmentStairsIcon from 'assets/img/poi/icons/equipment/stairs_marker.svg';
import PoiEquipmentFireExtinguisherIcon from 'assets/img/poi/icons/equipment/fire_extinguisher_marker.svg';
import PoiEquipmentFireCraneIcon from 'assets/img/poi/icons/equipment/fire_crane_marker.svg';
import PoiAccidentIcon from 'assets/img/poi/icons/accident/accident_marker.svg';
import PoiFreePointIcon from 'assets/img/poi/icons/freepoint/free_point_marker.svg';

import turfDestination from '@turf/destination';
import turfSector from '@turf/sector';

import { FeatureCollection } from 'geojson';

import CircleStyle from 'ol/style/Circle';
import { Draw, Modify } from 'ol/interaction';
import MultiPoint from 'ol/geom/MultiPoint';
import GeometryType from 'ol/geom/GeometryType';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Extent } from 'ol/extent';
import { fromLonLat, toLonLat } from 'ol/proj';
import { View } from 'ol';
import { Options as ModifyOptions } from 'ol/interaction/Modify';

import { HANDBOOK_POI_EQUIPMENT_TYPE_IDS } from 'components/handbooks/utils/consts';
import { NOTIFICATION_TYPES_ENUM } from 'components/notifications/utils/consts';
import { PoiTypesEnum } from 'components/poi/utils/consts';

import { TrackableUnitView } from 'reducers/user/interface';

import { stringDivider } from 'utils/stringDivider';

import { degToRad } from './helpers';
import { GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';
import getMainIconSVGMarker from './templates/mainIconSVGMarker';
import base64ImgToIconStyle from './templates/base64ImgToIconStyle';

/**
 * Utils only for map component
 */

export interface InfoProperties {
  id: number | undefined;
  fio: string | undefined;
  trackerId: number | undefined;
  organizationName: string | undefined;
  departmentName: string | undefined;
  positionOrDriver: string | undefined;
}

export interface NotificationInfoProperties {
  logId: number;
  text: string;
}

export interface PointProperties {
  type: string;
  info: InfoProperties;
  isHit?: boolean;
  flags: TrackableUnitView;
  geometry: Point;
  iconName?: string;
}

export interface PointNotificationProperties {
  type: string;
  info: NotificationInfoProperties;
  mapCoords: number[];
  geometry: Point;
  iconName?: string;
}

export function getTrackableUnitIcon(iconName: string) {
  return iconName === 'transportIcon' ? TransportIcon : EmployeeIcon;
}

export const pointStyleFunction = (resolution: number, properties: PointProperties) => {
  const imageSrc = getTrackableUnitIcon(properties.iconName || '');
  const image = getMainIconSVGMarker(properties, imageSrc);
  return new Style({ image });
};

export function getIconByNotificationType(type?: NOTIFICATION_TYPES_ENUM | null) {
  const { alert, geoZones, interceptionOfObjects, sensorValue, videoAnalytics } = NOTIFICATION_TYPES_ENUM;

  switch (type) {
    case alert:
      return 'alertIcon';
    case geoZones:
      return 'geozoneIcon';
    case interceptionOfObjects:
      return 'interpositionIcon';
    case sensorValue:
      return 'sensorIcon';
    case videoAnalytics:
      return 'videoIcon';
    default:
      return '';
  }
}

export const getNotificationIcon = (notificationType: string) => {
  switch (notificationType) {
    case 'alertIcon':
      return SosIcon;

    case 'geozoneIcon':
      return GeozoneIcon;

    case 'interpositionIcon':
      return InterpositionIcon;

    case 'sensorIcon':
      return SensorIcon;

    case 'videoIcon':
      return VideoIcon;

    default:
      return '';
  }
};

const pointNotificationStyleFunction = (resolution: number, properties: PointNotificationProperties) => {
  const imageSrc = getNotificationIcon(properties.iconName || '');

  return new Style({
    image: new Icon({
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: imageSrc,
      crossOrigin: 'Anonymous',
    }),
    text: new Text({
      font: 'bold 14px Calibri,sans-serif',
      fill: new Fill({
        color: '#0066FF',
      }),
      overflow: true,
      textAlign: 'center',
      text: stringDivider(properties.info.text || '', 20, '\n'), // https://openlayers.org/en/latest/examples/vector-labels.html
      offsetY: 20,
      stroke: new Stroke({
        color: 'white',
        width: 2.5,
      }),
      textBaseline: 'top',
    }),
  });
};

const styles: Styles = {
  route: new Style({
    stroke: new Stroke({
      width: 0,
      color: 'transparent',
    }),
  }),
  Point: (resolution, properties) => {
    const iconName = properties.iconName;

    switch (iconName) {
      case 'carIcon':
        return new Style({
          image: new Icon({
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
            src: 'https://img.icons8.com/color/2x/car-top-view.png',
            crossOrigin: 'Anonymous',
            scale: resolution < 10 ? 0.5 : 0.25,
          }),
        });

      case 'cameraIcon':
        return new Style({
          image: new Icon({
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
            src: OutdoorCamera,
            crossOrigin: 'Anonymous',
            scale: resolution < 30 ? 0.15 : 0,
          }),
        });

      default:
        return pointStyleFunction(resolution, properties);
    }
  },
  LineString: (resolution, properties, markerType) => {
    let color = '#23ec18';
    let width = 1.5 / (1.85 * resolution);
    if (markerType === 'dangerZone') {
      switch (parseInt(properties.level)) {
        case 1:
          color = COLOR_DANGER_LEVEL_1;
          width = 10 / (1.85 * resolution);
          break;
        case 2:
          color = COLOR_DANGER_LEVEL_2;
          width = 10 / (1.85 * resolution);
          break;
        case 3:
          color = COLOR_DANGER_LEVEL_3;
          width = 10 / (1.85 * resolution);
          break;

        case 4:
          color = COLOR_DANGER_LEVEL_1;
          width = 10 / (1.85 * resolution);
          break;

        default:
          break;
      }
    }
    if (markerType === 'route') {
      color = properties.properties?.color || 'transparent';
      width = 8;
    }
    return new Style({
      stroke: new Stroke({
        color,
        width,
      }),
    });
  },
  Polygon: (resolution, properties, markerType) => {
    let color = '#23ec18';
    let width = 1.5 / (1.85 * resolution);
    if (markerType === 'dangerZone') {
      switch (parseInt(properties.level)) {
        case 1:
          color = COLOR_DANGER_LEVEL_1;
          width = 10 / (1.85 * resolution);
          break;
        case 2:
          color = COLOR_DANGER_LEVEL_2;
          width = 10 / (1.85 * resolution);
          break;
        case 3:
          color = COLOR_DANGER_LEVEL_3;
          width = 10 / (1.85 * resolution);
          break;

        case 4:
          color = COLOR_DANGER_LEVEL_1;
          width = 10 / (1.85 * resolution);
          break;

        default:
          break;
      }
    }
    return new Style({
      stroke: new Stroke({
        color,
        width,
      }),
      fill: new Fill({
        color: 'transparent',
      }),
    });
  },
};

const styleFunction = function (feature: FeatureLike, resolution: number, markerType: string | null): Style {
  const geometry = feature.getGeometry();

  if (geometry) {
    const type: string = geometry.getType();

    if (type === 'Point' || type === 'LineString' || type === 'Polygon') {
      return styles[type](resolution, feature.getProperties(), markerType);
    }
  }

  return styles.route;
};

export const createVectorLayer = (
  source: VectorSource,
  zIndex = 0,
  markerType: string | null = null,
  withCluster?: boolean
): VectorLayer<VectorSource> =>
  new VectorLayer({
    source,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    style: (feature, resolution) => {
      if (withCluster) {
        const size = feature.get('features').length;
        if (size === 1) {
          return styleFunction(feature.get('features')[0], resolution, markerType);
        }
        return new Style({
          image: new CircleStyle({
            radius: 12,
            stroke: new Stroke({
              color: '#0066FF',
            }),
            fill: new Fill({
              color: '#FFFFFF',
            }),
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: '#000000',
            }),
            font: 'bold 12px sans-serif',
          }),
        });
      }
      return styleFunction(feature, resolution, markerType);
    },
    zIndex,
  });

export const createNotificationVectorLayer = (source: VectorSource, zIndex = 0): VectorLayer<VectorSource> =>
  new VectorLayer({
    source,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    style: (feature, resolution) => {
      const size = feature.get('features').length;

      if (size === 1) {
        return pointNotificationStyleFunction(resolution, feature.get('features')[0].getProperties());
      }

      const style = new Style({
        image: new CircleStyle({
          radius: 12,
          stroke: new Stroke({
            color: '#FFFFFF',
          }),
          fill: new Fill({
            color: '#FF8F00',
          }),
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#FFFFFF',
          }),
          font: 'bold 12px sans-serif',
        }),
      });
      return style;
    },
    zIndex,
  });

export const createClusterSource = (source: VectorSource) =>
  new Cluster({
    source,
    // distance: 20,
  });

export const createGeoMarker = (point: Coordinate): Feature =>
  new Feature({
    type: 'geoMarker',
    geometry: new Point(point),
  });

export const createGeoNotificationMarker = (point: Coordinate): Feature =>
  new Feature({
    type: 'notificationMarker',
    geometry: new Point(point),
  });

export type SelectParams = {
  value: number | LAYERS | MARKERS | HEATMAP;
  name: string;
  selOptions: Array<string | LAYERS | MARKERS | HEATMAP>[];
  wrapperStyle: string;
  listener: (val: string | LAYERS | MARKERS | HEATMAP) => void;
};

export const createSelectNode = (params: SelectParams) => {
  const { value, name, selOptions, wrapperStyle, listener } = params;

  const container = document.createElement('div');
  container.className = `select-box ol-control ${wrapperStyle}`;
  const list = document.createElement('ul');
  list.className = 'select-box__list';
  const current = document.createElement('div');
  current.className = 'select-box__current';
  current.tabIndex = 1;

  for (let i = 0; i < selOptions.length; i++) {
    const item = document.createElement('li');
    const label = document.createElement('label');
    label.className = 'select-box__option';
    label.htmlFor = selOptions[i][1];
    label.innerText = selOptions[i][0];
    item.appendChild(label);
    item.addEventListener('click', () => listener(selOptions[i][1]));
    list.appendChild(item);

    const currentItem = document.createElement('div');
    currentItem.className = 'select-box__value';

    const input = document.createElement('input');
    input.className = 'select-box__input';
    input.type = 'radio';
    input.name = name;
    input.value = selOptions[i][1];
    input.id = `${name}-${selOptions[i][1]}`;
    input.checked = String(value) === selOptions[i][1];
    currentItem.appendChild(input);

    const text = document.createElement('div');
    text.className = 'select-box__input-text';
    text.innerText = selOptions[i][0];
    currentItem.appendChild(text);

    current.appendChild(currentItem);
  }
  const img = document.createElement('img');
  img.className = 'select-box__icon';
  img.src = DropArrow;
  img.alt = 'Arrow Icon';
  current.appendChild(img);
  container.appendChild(current);
  container.appendChild(list);

  return container;
};

export function applyFlyViewAnimation(location: number[], view: View, cb?: () => void) {
  const isAnimating = view.getAnimating();
  const duration = 2000;
  const zoom = view.getZoom() ?? 1;

  if (isAnimating) {
    return;
  }

  view.animate({
    center: location,
    duration: duration,
  });
  view.animate(
    {
      zoom: zoom - 1,
      duration: duration / 2,
    },
    {
      zoom: zoom,
      duration: duration / 2,
    },
    () => {
      if (cb) {
        cb();
      }
    }
  );
}

export function createRouteLayerGEOJSON(currPath: number[][]) {
  const routeStyles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: 'red',
        }),
        stroke: new Stroke({ color: 'red', width: 1 }),
      }),
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: '#0066FF',
        width: 7,
      }),
    }),
  };

  const geojsonObject: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: currPath,
        },
        properties: {},
      },
    ],
  };

  currPath.forEach(coords => {
    geojsonObject.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: coords,
      },
      properties: {},
    });
  });

  const vectorSource = new VectorSource({
    features: new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(geojsonObject),
  });

  return new VectorLayer({
    source: vectorSource,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    style: feature => {
      const geometry = feature.getGeometry();
      if (geometry) {
        const featureType: string = geometry.getType();

        if (featureType === GeometryType.LINE_STRING || featureType === GeometryType.POINT) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          return routeStyles[featureType];
        }
      }
      throw 'error'; //TODO FIX IT
    },
  });
}

export function createDraw(source: VectorSource, drawType: GEOZONE_GEOMETRIC_TYPES, color: string): Draw {
  return new Draw({
    source,
    type: drawType,
    maxPoints: Infinity,
    style: [
      new Style({
        stroke: new Stroke({
          color,
          width: 3,
        }),
        fill: new Fill({
          color: color ? hexAToRGBA(color) : 'rgba(255, 255, 255, 0.5)',
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color,
          }),
        }),
      }),
      new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: 'transparent',
          }),
          stroke: new Stroke({
            color,
            width: 3,
          }),
        }),
        geometry: feature => {
          const geom = feature.getGeometry();
          if (geom) {
            const type = geom.getType();
            if (type !== GeometryType.POINT) {
              return geom;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            let coordinates = geom.getCoordinates();
            if (!coordinates.length) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return new MultiPoint() as MultiPoint;
            }
            if (coordinates.length < 2) {
              coordinates = [];
            }
            return new MultiPoint(coordinates) as MultiPoint;
          }
        },
      }),
    ],
    features: source.getFeaturesCollection() || undefined,
  });
}

export function createModify(source: VectorSource, color: string): Modify {
  return new Modify({
    source,
    style: [
      new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color,
          }),
        }),
      }),
    ],
  });
}

export function createDrawFeatureStyle(color: string, labelColor: string) {
  return [
    new Style({
      fill: new Fill({
        color: hexAToRGBA(color),
      }),
      text: new Text({
        font: '18px Calibri,sans-serif',
        fill: new Fill({
          color: labelColor,
        }),
        overflow: true,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: 'transparent',
        }),
        stroke: new Stroke({
          color: 'yellowgreen',
          width: 3,
        }),
      }),
    }),
    new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({
          color: 'transparent',
        }),
        stroke: new Stroke({
          color: color,
          width: 3,
        }),
      }),
      geometry: feature => {
        const geom = feature.getGeometry();
        if (geom) {
          const type = geom.getType();
          if (type !== GeometryType.POINT) {
            return geom;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const coordinates = geom.getCoordinates();
          if (!coordinates.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return new MultiPoint() as MultiPoint;
          }
          return new MultiPoint(coordinates) as MultiPoint;
        }
      },
    }),
  ];
}

export const getGeozoneMarkerStyle = ({
  labelColor,
  iconSrc,
  geozoneName,
}: {
  labelColor: string;
  iconSrc: string;
  geozoneName: string;
}) => {
  const image = base64ImgToIconStyle({
    src: iconSrc,
    width: 24,
    height: 24,
  });
  const text = new Text({
    font: 'bold 14px Calibri,sans-serif',
    fill: new Fill({
      color: labelColor,
    }),
    overflow: true,
    textAlign: 'center',
    text: stringDivider(geozoneName, 30, '\n'), // https://openlayers.org/en/latest/examples/vector-labels.html
    offsetY: 20,
    stroke: new Stroke({
      color: brightGeozoneMarkerTextColors.includes(labelColor) ? 'black' : 'white',
      width: 2.5,
    }),
    textBaseline: 'top',
  });

  return new Style({ image, text });
};

export const getSourceFromLayerId = (layer: LAYERS) => {
  switch (layer) {
    case LAYERS.MAPBOX:
      return new XYZ({
        url: `${MAPBOX_URL}${MAPBOX_API_TOKEN}`,
      });

    case LAYERS.OSM:
      return new OSM({
        //url:, //TODO Add our osm tile server url here
      });
  }
};

export const getTileLayerFromLayerId = (layer: LAYERS) => {
  return new TileLayer({ source: getSourceFromLayerId(layer) });
};

function hexAToRGBA(h: string) {
  const r = '0x' + h[1] + h[2];
  const g = '0x' + h[3] + h[4];
  const b = '0x' + h[5] + h[6];

  return `rgba(${+r},${+g},${+b},0.5)`;
}

export const geoJSON = new GeoJSON({ featureProjection: 'EPSG:3857' });

export function getOlRadius(center: number[], groundRadius: number): number {
  return (
    fromLonLat(turfDestination(toLonLat(center), groundRadius, 90, { units: 'meters' }).geometry.coordinates)[0] -
    center[0]
  );
}

/**
 * Returns common extent for array of features (Circle, LineString, Polygon)
 */
export function getFeaturesExtent(features: Feature[]) {
  // User-defined type guard for correct filter typings
  const extentIsNotNull = (extent: Extent | null): extent is Extent => {
    return Boolean(extent);
  };

  const extents = features.map(f => getSingleFeatureExtent(f)).filter(extentIsNotNull);

  return getCommonExtent(extents);
}

/**
 * Returns real extent for Circle, LineString, Polygon
 */
export function getSingleFeatureExtent(feature: Feature): Extent | null {
  const featureProps = feature.getProperties();
  let realFeature: Feature;

  if (featureProps.drawType === GeometryType.CIRCLE) {
    const radius = featureProps.circleRadius;
    const center = featureProps.circleCenter;
    const olRadius = getOlRadius(center, Number(radius));

    realFeature = new Feature(new Circle(center, olRadius));
  } else if (featureProps.drawType === GeometryType.LINE_STRING) {
    const lineWidth = featureProps.lineWidth;

    realFeature = geoJSON.readFeature(turfBuffer(geoJSON.writeFeatureObject(feature), lineWidth, { units: 'meters' }));
  } else if (featureProps.drawType === GeometryType.POLYGON) {
    realFeature = feature;
  } else {
    return null;
  }

  const geometry = realFeature.getGeometry();

  return geometry ? geometry.getExtent() : null;
}

export function getCommonExtent(extents: Extent[]) {
  if (extents.length) {
    const x0 = Math.min(...extents.map(extent => extent[0]));
    const y0 = Math.min(...extents.map(extent => extent[1]));
    const x1 = Math.max(...extents.map(extent => extent[2]));
    const y1 = Math.max(...extents.map(extent => extent[3]));

    return [x0, y0, x1, y1] as Extent;
  }
  return [0, 0, 0, 0] as Extent;
}

// POI

export function getPoiFeatureIcon(options: PoiFeatureProperties) {
  switch (options.poiType) {
    case PoiTypesEnum.poiVideocameraPoint:
      return PoiVideoCameraIcon;

    case PoiTypesEnum.poiGasAnalyzerPoint:
      return PoiGazAnalyzerIcon;

    case PoiTypesEnum.poiSensorPoint:
      return PoiSensorIcon;

    case PoiTypesEnum.poiEquipmentPoint:
      switch (options.poiSubTypeId) {
        case HANDBOOK_POI_EQUIPMENT_TYPE_IDS.FIRST_AID_KIT:
          return PoiEquipmentFirstAidKitIcon;
        case HANDBOOK_POI_EQUIPMENT_TYPE_IDS.STAIRS:
          return PoiEquipmentStairsIcon;
        case HANDBOOK_POI_EQUIPMENT_TYPE_IDS.FIRE_EXTINGUISHER:
          return PoiEquipmentFireExtinguisherIcon;
        case HANDBOOK_POI_EQUIPMENT_TYPE_IDS.FIRE_CRANE:
          return PoiEquipmentFireCraneIcon;
        default:
          return PoiEquipmentFirstAidKitIcon; // TODO: какой маркер указывать по-умолчанию для poi equipment?
      }

    case PoiTypesEnum.poiAccidentPoint:
      return PoiAccidentIcon;

    case PoiTypesEnum.poiFreePoint:
      return PoiFreePointIcon;

    default:
      return '';
  }
}

function getPoiStyle(properties: PoiMarkerProperties) {
  const {
    poiType = null,
    poiSubTypeId = 0,
    info = {
      id: 0,
      name: '',
    },
    direction = 0,
  } = properties;

  return new Style({
    image: new Icon({
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: getPoiFeatureIcon({ poiType, poiSubTypeId }),
      crossOrigin: 'anonymous',
      anchor: poiType === 'poiVideocameraPoint' ? [0.5, 0.5] : [0.5, 1],
      rotation: poiType === 'poiVideocameraPoint' ? degToRad(-90 + (direction ?? 0)) : 0,
    }),
    text: new Text({
      text: info.name,
      fill: new Fill({
        color: '#0066FF',
      }),
      font: 'bold 12px sans-serif',
      offsetY: 25 / 2, // 25 - средняя высота иконок poi
      stroke: new Stroke({
        color: '#FFFFFF',
        width: 3,
      }),
      textBaseline: 'top',
    }),
  });
}

function getDefaultPoiStyle(feature: FeatureLike) {
  return new Style({
    image: new CircleStyle({
      radius: 12,
      stroke: new Stroke({
        color: '#FFFFFF',
      }),
      fill: new Fill({
        color: '#FF8F00',
      }),
    }),
    text: new Text({
      text: feature.get('features').length.toString(),
      fill: new Fill({
        color: '#FFFFFF',
      }),
      font: 'bold 12px sans-serif',
    }),
  });
}

function stylePoiFunction(feature: FeatureLike) {
  const geometry = feature.getGeometry();

  if (geometry) {
    const type: string = geometry.getType();

    if (type === 'Point') {
      return getPoiStyle(feature.getProperties() as PoiMarkerProperties);
    }
  }
  return getDefaultPoiStyle(feature);
}

export const createPoiVectorLayer = (source: VectorSource, zIndex = 0): VectorLayer<VectorSource> =>
  new VectorLayer({
    source,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    style: feature => {
      const features = feature.get('features');

      const size = features?.length ?? 0;

      if (size === 1) {
        return stylePoiFunction(features[0]);
      }

      return getDefaultPoiStyle(feature);
    },

    zIndex,
  });

export function createDrawPoi(source: VectorSource, properties: PoiFeatureProperties): Draw {
  return new Draw({
    source,
    type: GeometryType.POINT,
    maxPoints: 1,
    style: [
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: getPoiFeatureIcon({ ...properties }),
          crossOrigin: 'anonymous',
        }),
      }),
    ],
  });
}

export function createModifyPoi(modifyOptions: ModifyOptions): Modify {
  return new Modify({
    ...modifyOptions,
    style: [
      new Style({
        image: new CircleStyle({
          radius: 1,
          fill: new Fill({
            color: 'transparent',
          }),
          stroke: new Stroke({
            color: '#FF8F00',
            width: 3,
          }),
        }),
      }),
    ],
  });
}

// for poi sectors
const poiSectorsStyles = {
  Polygon: (resolution: number) => {
    const width = 1.5 / (1.85 * resolution);
    const strokeColor = '#FF8F00';
    const fillColor = 'rgba(255, 143, 0, 0.4)';

    return new Style({
      stroke: new Stroke({
        color: strokeColor,
        width,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    });
  },
};

export function stylePoiSectorsFunction(resolution: number, feature: FeatureLike) {
  const geometry = feature.getGeometry();

  if (geometry) {
    const type: string = geometry.getType();

    if (type === 'Polygon') {
      return poiSectorsStyles[type](resolution);
    }
  }
  return [];
}

export function createPoiSectorsVectorLayer(source: VectorSource, zIndex = 0) {
  return new VectorLayer({
    source,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    style: (feature, resolution) => stylePoiSectorsFunction(resolution, feature),
    zIndex,
  });
}

// создание sector feature
export function createSectorFeature(params: {
  angle: number;
  direction: number;
  distance: number;
  coordinates: number[];
}) {
  const angleOffset = 0.0001; // смещение, необходимое для правильной отрисовки сектора при углах, равных [0, 90, 180, 270]
  const angle: number = [0, 90, 180, 270].some(a => a === params.angle) ? params.angle + angleOffset : params.angle;
  const bearing1 = -angle / 2 + params.direction;
  const bearing2 = angle / 2 + params.direction;
  const turfedSector = turfSector(params.coordinates, params.distance, bearing1, bearing2, { units: 'meters' });

  return geoJSON.readFeature(turfedSector);
}
