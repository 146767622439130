// https://openlayers.org/en/latest/examples/vector-labels.html
// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
export function stringDivider(str: string, width: number, spaceReplacer: string): string {
  if (str.length > width) {
    let p = width;

    while (p > 0 && ![' ', '-', ','].includes(str[p])) {
      p--;
    }
    if (p > 0) {
      let left;

      if (str.substring(p, p + 1) === '-') {
        left = str.substring(0, p + 1);
      } else {
        left = str.substring(0, p);
      }

      const right = str.substring(p + 1);

      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
}
