import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { PoiPopupInfoText } from './components/poiPopupInfoText';
import { PoiPopupCircleValue } from './components/poiPopupCircleValue';

import styles from '../poiPopup.module.scss';

// для internationalizations
const mocks = {
  indications: {
    text: 'MOCK DATA',
  },
  notifications: {
    text: 'MOCK DATA',
  },
};

export function PoiSensorPopup() {
  const t = useFormatMessage();

  return (
    <Fragment>
      <PoiPopupInfoText label={t('map-popup.poi.sensor.indication.text')} text={mocks.indications.text} />
      <div className={styles.valuesWrapper}>
        <PoiPopupCircleValue
          title={t('map-popup.poi.sensor.temperature.text')}
          measureUnit={'°C'}
          range={{ start: 0, end: 500 }}
          value={280}
          limit={400}
        />
      </div>
      <PoiPopupInfoText label={t('map-popup.poi.sensor.notifications.text')} text={mocks.notifications.text} />
    </Fragment>
  );
}
