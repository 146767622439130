import auth from './auth';
import monitoring from './monitoring';
import handbooks from './handbooks';
import notifications from './notifications';
import personalStatistic from './personalStatistic';
import records from './records';

export default {
  en: {
    ...auth.en,
    ...monitoring.en,
    ...handbooks.en,
    ...notifications.en,
    ...personalStatistic.en,
    ...records.en,
  },
  ru: {
    ...auth.ru,
    ...monitoring.ru,
    ...handbooks.ru,
    ...notifications.ru,
    ...personalStatistic.ru,
    ...records.ru,
  },
  zh: {
    ...auth.zh,
    ...monitoring.zh,
    ...handbooks.zh,
    ...notifications.zh,
    ...personalStatistic.zh,
    ...records.zh,
  },
  ar: {
    ...auth.ar,
    ...monitoring.ar,
    ...handbooks.ar,
    ...notifications.ar,
    ...personalStatistic.ar,
    ...records.ar,
  },
  de: {
    ...auth.de,
    ...monitoring.de,
    ...handbooks.de,
    ...notifications.de,
    ...personalStatistic.de,
    ...records.de,
  },
};
