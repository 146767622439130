export default {
  en: {
    'toast.notifications.conditions.geozone.less-limit-time.error.text':
      'The time limit for staying in controlled geofences is greater than or equal to the waiting time before the start of notification fixation',
  },
  ru: {
    'toast.notifications.conditions.geozone.less-limit-time.error.text':
      'Время лимита нахождения в контролируемых геозонах больше, либо равно времени ожидания перед началом фиксации уведомления',
  },
  zh: {
    'toast.notifications.conditions.geozone.less-limit-time.error.text':
      '处在控制地理区限制时间超过或等于通知固定以前等待时间',
  },
  ar: {
    'toast.notifications.conditions.geozone.less-limit-time.error.text':
      'الحد الزمني للبقاء في السياج الجغرافي الخاضع للرقابة أكبر من أو يساوي وقت الانتظار قبل بدء تثبيت الإخطارات',
  },
  de: {
    'toast.notifications.conditions.geozone.less-limit-time.error.text':
      'Das Zeitlimit für den Aufenthalt in kontrollierten Geofences ist größer oder gleich der Wartezeit vor Beginn der Fixierung einer Benachrichtigung',
  },
};
