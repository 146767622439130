import { createSlice } from '@reduxjs/toolkit';
import { OnlineCountEvent } from '../../contexts/interfaces';

export type initialStateInterface = {
  userCount: number;
};

const initialState: initialStateInterface = {
  userCount: 0,
};

const userCounterSlice = createSlice({
  name: 'UserCounter',
  initialState,
  reducers: {
    handleUserCountEvent: (state, { payload }: { payload: OnlineCountEvent }) => {
      state.userCount = payload.count;
    },
  },
});

export const { handleUserCountEvent } = userCounterSlice.actions;

export default userCounterSlice.reducer;
