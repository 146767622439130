import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { RecordsMainTabHandlers, RecordsMainTabStates } from 'components/records/utils/types';

import Input, { InputsTypesEnum } from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import { InfoElement } from 'components/common/infoElement/infoElement';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { Spinner } from 'components/common/spinner/spinner';
import Button from 'components/common/button/button';
import { getFileNameFromUid } from 'components/records/utils/helpers';

import styles from './mainTab.module.scss';

const cx = classNames.bind(styles);

interface Props {
  states: RecordsMainTabStates;
  handlers: RecordsMainTabHandlers;
  invalidFields: string[];
}

export const MainTab = ({ states, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();
  const { templateWindow, isGenerateReportTagsLoading, chosenTemplate } = useSelector(
    (state: RootState) => state.records
  );

  const fileNameValue = states.file?.name ?? getFileNameFromUid(chosenTemplate?.attributes.printableTemplateId);

  return (
    <div className={styles.content}>
      <div className={styles.column}>
        <Input
          label={t('records.card.template-create.general.field.name.label')}
          placeholder={t('records.card.template-create.general.field.name.placeholder')}
          isValueError={invalidFields.includes('mainName')}
          isRequired={true}
          value={states.name}
          customStyle={styles.row}
          disabled={false}
          handleInputChange={value => handlers.handleChangeName(value)}
        />
        <TextArea
          label={t('records.card.template-create.general.field.desc.label')}
          placeholder={t('records.card.template-create.general.field.desc.placeholder')}
          containerStyle={cx(styles.row, styles.rowFullWidth)}
          areaStyle={styles.areaStyle}
          handleChange={text => handlers.handleChangeDescription(text)}
          value={states.description}
          isRequired={false}
          autoSetHeight
        />
        {templateWindow.isPrintableTemplateFileLoading ? (
          <Spinner />
        ) : (
          <div className={cx(styles.row, styles.rowWithTooltip)}>
            <Input
              label={t('records.card.template-create.general.field.template.label')}
              placeholder={t('records.card.template-create.general.field.template.placeholder')}
              isValueError={invalidFields.includes('mainAttachedFile')}
              value={fileNameValue}
              customStyle={styles.rowInput}
              disabled={false}
              handleFileChange={file => handlers.handleChangePrintableTemplateFile(file)}
              inputType={InputsTypesEnum.FILE}
              withAttachFileIcon
              withClearBtn={!!states.file?.name}
              handleClear={handlers.handleClearPrintableTemplateFile}
            />
            <InfoElement
              id={'templateFormOutFile'}
              text={t('records.card.template-create.general.field.template.tooltip.text')}
              customStyle={styles.tooltip}
            />
          </div>
        )}
        <Button
          white
          text={t('records.card.template-create.general.field.new-template-pf.btn.text')}
          customStyle={styles.row}
          onClick={handlers.handleClickNewTemplatePF}
          disabled={isGenerateReportTagsLoading}
        />
      </div>
      <div className={styles.column}>
        <div className={cx(styles.row, styles.rowWithTooltip)}>
          <MultipleSelect
            options={states.chosenUsersForSelect}
            label={t('records.card.template-create.general.field.users.label')}
            placeholder={t('records.card.template-create.general.field.users.placeholder')}
            isRequired={false}
            withSearchIcon
            disabled={!states.chosenUsersForSelect.length}
            wrapperStyle={styles.multipleSelect}
            handleChangeMultiple={handlers.handleChangeUsers}
            values={states.chosenUsers.map((id, i) =>
              getValueForMultipleSelect({
                i,
                id,
                selectValues: states.chosenUsersForSelect,
              })
            )}
          />
          <InfoElement
            id={'usersChangingTemplate'}
            text={t('records.card.template-create.general.field.users.tooltip')}
            customStyle={styles.tooltip}
          />
        </div>
      </div>
    </div>
  );
};
