import React from 'react';
import styles from './radioButton.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface RadioButtonProps {
  handleCheck?: () => void;
  name: string;
  checked?: boolean;
  isNegative?: boolean;
  cursorDefault?: boolean;
  disabled?: boolean;
  wrapperStyle?: string;
}

export const RadioButton = ({
  handleCheck,
  name,
  checked,
  isNegative,
  cursorDefault,
  disabled,
  wrapperStyle,
}: RadioButtonProps) => {
  const inputClasses = cx({
    [styles.radioButtonInput]: !isNegative,
    [styles.negative]: isNegative,
    [styles.defaultCursor]: cursorDefault,
    [styles.disabled]: disabled,
  });

  return (
    <div className={cx(styles.radioButton, wrapperStyle)} onClick={handleCheck}>
      <input className={inputClasses} type="radio" checked={checked ?? false} name={name} readOnly />
      <span className={styles.radioButtonSpan} />
    </div>
  );
};
