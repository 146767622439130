const U_READ = 1 << 0;
const U_CREATE = 1 << 1;
const U_EDIT = 1 << 2;
const U_DELETE = 1 << 3;
const U_ALL = U_READ | U_CREATE | U_EDIT | U_DELETE;

export default class AccessEntity {
  mask: number;

  constructor(mask: string) {
    this.mask = parseInt(mask, 2);
  }

  isAllowRead() {
    return Boolean(this.mask & U_READ);
  }

  isAllowCreate() {
    return Boolean(this.mask & U_CREATE);
  }

  isAllowUpdate() {
    return Boolean(this.mask & U_EDIT);
  }

  isAllowDelete() {
    return Boolean(this.mask & U_DELETE);
  }

  isAllowAll() {
    return Boolean(this.mask & U_ALL);
  }
}
