import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as CoordinateIcon } from 'assets/img/notifications/pin_drop.svg';

import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';
import { fetchChosenNotificationLogs, removeChosenNotificationLogs } from 'reducers/notifications';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';

import styles from './tabObjectStat.module.scss';

const cx = classNames.bind(styles);

type TabObjectStatProps = {
  id: string;
};

export const TabObjectStat = ({ id }: TabObjectStatProps) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const isChosenNotificationLogsLoading = useSelector(
    (state: RootState) => state.notifications.isChosenNotificationLogsLoading
  );
  const chosenNotificationLogs = useSelector((state: RootState) => state.notifications.chosenNotificationLogs);

  useEffect(() => {
    dispatch(fetchChosenNotificationLogs(id));
    return () => {
      dispatch(removeChosenNotificationLogs());
    };
  }, [dispatch, id]);

  if (isChosenNotificationLogsLoading) {
    return <Spinner />;
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={cx(styles.tableHeader, styles.tableHeaderDate)}>
            {t('notifications.card.tabs.statistic.table.header.date.text')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderTime)}>
            {t('notifications.card.tabs.statistic.table.header.time.text')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderText)}>
            {t('notifications.card.tabs.statistic.table.header.text.text')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderGeozone)}>
            {t('notifications.card.tabs.statistic.table.header.geofence.text')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderCoordinates)}>
            {t('notifications.card.tabs.statistic.table.header.coords.text')}
          </th>
        </tr>
      </thead>
      <tbody>
        {chosenNotificationLogs.map(({ attributes }, index) => {
          const coords = attributes.coords.split(',').map(c => Number(c).toFixed(10));

          return (
            <tr key={`notification-logs-${index}`}>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{getFullDateFromStr(attributes.createdAt).date}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{getFullDateFromStr(attributes.createdAt).time}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{attributes.text}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{attributes.geozoneName}</div>
              </td>
              <td className={cx(styles.tableBodyCell, styles.tableBodyCellCoordinates)}>
                <CoordinateIcon className={styles.tableBodyCellIcon} />
                <div className={styles.tableContent}>
                  <div className={styles.latitudeCoords}>{coords[0]}</div>
                  <div className={styles.longitudeCoords}>{coords[1]}</div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
