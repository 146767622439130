export default {
  en: {
    'personal-statistic.no-data.text': 'no data',
    'personal-statistic.no.text': 'nothing',
    // auth

    'personal-statistic.auth.login-auth.type.text': 'User authorization',
    'personal-statistic.auth.login-auth.desc.text': 'Logged in to the system',
    'personal-statistic.auth.login-auth.desc.login.text': 'Login',
    'personal-statistic.auth.login-auth.desc.name.text': 'name',
    'personal-statistic.auth.login-auth.desc.ip.text': 'ip',

    // employee

    'personal-statistic.employee.create.type.text': 'Create employee',
    'personal-statistic.employee.create.desc.text': 'Employee created',
    'personal-statistic.employee.create.desc.name.text': 'Name',
    'personal-statistic.employee.create.desc.sim.text': 'sim number',

    'personal-statistic.employee.delete.type.text': 'Deleting an employee',
    'personal-statistic.employee.delete.desc.text': 'Deleted employee',
    'personal-statistic.employee.delete.desc.name.text': 'Name',
    'personal-statistic.employee.delete.desc.sim.text': 'sim number',

    'personal-statistic.employee.update.type.text': 'Changing employee data',
    'personal-statistic.employee.update.desc.text': 'Employee data changed',
    'personal-statistic.employee.update.desc.field.text': 'Field',
    'personal-statistic.employee.update.desc.old-value.text': 'Old value',
    'personal-statistic.employee.update.desc.new-value.text': 'new value',

    'personal-statistic.employee.assign-field.type.text': 'Create a custom data field for an employee',
    'personal-statistic.employee.assign-field.desc.text': 'A custom data field has been created for an employee',
    'personal-statistic.employee.assign-field.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.assign-field.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.assign-field.desc.field-name.text': 'Field name',
    'personal-statistic.employee.assign-field.desc.field-value.text': 'field value',

    'personal-statistic.employee.update-assign-field.type.text': 'Change names and values ​​of custom fields',
    'personal-statistic.employee.update-assign-field.desc.text':
      'The names and values ​​of custom fields for an employee have been changed',
    'personal-statistic.employee.update-assign-field.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.update-assign-field.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.update-assign-field.desc.added.text': 'added field',
    'personal-statistic.employee.update-assign-field.desc.deleted.text': 'deleted field',
    'personal-statistic.employee.update-assign-field.desc.changed.text': 'field changed',
    'personal-statistic.employee.update-assign-field.desc.old.text': 'old',
    'personal-statistic.employee.update-assign-field.desc.new.text': 'new',
    'personal-statistic.employee.update-assign-field.desc.label.text': 'field name',
    'personal-statistic.employee.update-assign-field.desc.value.text': 'field value',
    'personal-statistic.employee.update-assign-field.desc.visible.text': 'field visibility',

    'personal-statistic.employee.assign-work-object.type.text': 'Add to object group',
    'personal-statistic.employee.assign-work-object.desc.text': 'The employee was added to the object group',
    'personal-statistic.employee.assign-work-object.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.assign-work-object.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.assign-work-object.desc.work-obj-name.text': 'The name of the work object',

    'personal-statistic.employee.un-assign-work-object.type.text': 'Deleting from object group',
    'personal-statistic.employee.un-assign-work-object.desc.text': 'The employee was removed from the object group',
    'personal-statistic.employee.un-assign-work-object.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.un-assign-work-object.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text': 'Name of the work object',

    'personal-statistic.employee.assign-tracker.type.text': 'Assign tracker to employee',
    'personal-statistic.employee.assign-tracker.desc.text': 'The tracker is associated with an employee',
    'personal-statistic.employee.assign-tracker.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.assign-tracker.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.assign-tracker.desc.tracker-number.text': 'Tracker number',
    'personal-statistic.employee.assign-tracker.desc.tracker-model.text': 'tracker model',

    'personal-statistic.employee.un-assign-tracker.type.text': 'Unlink tracker from employee',
    'personal-statistic.employee.un-assign-tracker.desc.text': 'Tracker is unlinked from employee',
    'personal-statistic.employee.un-assign-tracker.desc.employee-name.text': 'Employee name',
    'personal-statistic.employee.un-assign-tracker.desc.employee-sim.text': 'sim number',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text': 'Tracker number',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text': 'tracker model',

    // geozones

    'personal-statistic.geozones.create.type.text': 'Create geofence',
    'personal-statistic.geozones.create.desc.text': 'Geofence created',
    'personal-statistic.geozones.create.desc.name.text': 'Geofence name',
    'personal-statistic.geozones.create.desc.geometric-type.text': 'geometry type',
    'personal-statistic.geozones.create.desc.gezone-type.text': 'geofence type',

    'personal-statistic.geozones.delete.type.text': 'Deleting geozones',
    'personal-statistic.geozones.delete.desc.text': 'Geofence deleted',
    'personal-statistic.geozones.delete.desc.name.text': 'Geofence name',
    'personal-statistic.geozones.delete.desc.geometric-type.text': 'geometry type',
    'personal-statistic.geozones.delete.desc.gezone-type.text': 'geofence type',

    'personal-statistic.geozones.update.type.text': 'Change geofence',
    'personal-statistic.geozones.update.desc.text': 'Geofence data changed',
    'personal-statistic.geozones.update.desc.field.text': 'Field',
    'personal-statistic.geozones.update.desc.old-value.text': 'Old value',
    'personal-statistic.geozones.update.desc.new-value.text': 'new value',

    'personal-statistic.geozone-groups.create.type.text': 'Create a geofence group',
    'personal-statistic.geozone-groups.create.desc.text': 'Geofence group created',
    'personal-statistic.geozone-groups.create.desc.name.text': 'Geofence name',
    'personal-statistic.geozone-groups.create.desc.geometric-type.text': 'parent group',

    'personal-statistic.geozone-groups.delete.type.text': 'Deleting a geozone group',
    'personal-statistic.geozone-groups.delete.desc.text': 'Deleted geozone group',
    'personal-statistic.geozone-groups.delete.desc.name.text': 'Geofence name',
    'personal-statistic.geozone-groups.delete.desc.geometric-type.text': 'parent group',

    'personal-statistic.geozone-groups.update.type.text': 'Change geozone group',
    'personal-statistic.geozone-groups.update.desc.text': 'Changed geozone group data',
    'personal-statistic.geozone-groups.update.desc.field.text': 'Field',
    'personal-statistic.geozone-groups.update.desc.old-value.text': 'Old value',
    'personal-statistic.geozone-groups.update.desc.new-value.text': 'new value',

    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text': 'Add a geozone to a geozone group',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text': 'Geofence added to geozone group',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text': 'Geofence name',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text': 'group',

    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text':
      'Removing a geofence from a group of geofences',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text':
      'Geofence has been removed from the geofence group',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text': 'Geofence name',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text': 'group',

    // handbooks

    'personal-statistic.handbooks.create.type.text': 'Create item in handbook',
    'personal-statistic.handbooks.create.desc.text': 'A new item has been created in the handbook',
    'personal-statistic.handbooks.create.desc.type.text': 'Handbook type',
    'personal-statistic.handbooks.create.desc.name.text': 'item name',

    'personal-statistic.handbooks.update.type.text': 'Changing item data in the directory',
    'personal-statistic.handbooks.update.desc.text': 'Changed item in handbook',
    'personal-statistic.handbooks.update.desc.type.text': 'Handbook type',
    'personal-statistic.handbooks.update.desc.field.text': 'Field',
    'personal-statistic.handbooks.update.desc.old-value.text': 'Old value',
    'personal-statistic.handbooks.update.desc.new-value.text': 'new value',

    // notifications

    'personal-statistic.notifications.added.type.text': 'Notification triggered',
    'personal-statistic.notifications.added.desc.text': 'Notification triggered',
    'personal-statistic.notifications.added.desc.type.text': 'Type of notification',
    'personal-statistic.notifications.added.desc.text.text': 'notification text',

    // poi

    'personal-statistic.poi.create.type.text': 'Create POI',
    'personal-statistic.poi.create.desc.text': 'POI created',
    'personal-statistic.poi.create.desc.name.text': 'POI Name',

    'personal-statistic.poi.delete.type.text': 'Deleting POI',
    'personal-statistic.poi.delete.desc.text': 'Deleted POI',
    'personal-statistic.poi.delete.desc.name.text': 'POI Name',

    'personal-statistic.poi.update.type.text': 'Change POI',
    'personal-statistic.poi.update.desc.text': 'POI data changed',
    'personal-statistic.poi.update.desc.field.text': 'Field',
    'personal-statistic.poi.update.desc.old-value.text': 'Old value',
    'personal-statistic.poi.update.desc.new-value.text': 'new value',

    // report

    'personal-statistic.report-template.create.type.text': 'Create report template',
    'personal-statistic.report-template.create.desc.text': 'Created report template',
    'personal-statistic.report-template.create.desc.name.text': 'Template name',

    'personal-statistic.report-template.delete.type.text': 'Deleting a report template',
    'personal-statistic.report-template.delete.desc.text': 'Deleted report template',
    'personal-statistic.report-template.delete.desc.name.text': 'Template name',

    'personal-statistic.report-template.update.type.text': 'Modifying report template data',
    'personal-statistic.report-template.update.desc.text': 'Changed report template data',
    'personal-statistic.report-template.update.desc.name.text': 'Template name',

    'personal-statistic.report-template.create-report.type.text': 'Generate report from template',
    'personal-statistic.report-template.create-report.desc.text': 'A report was generated using a template',
    'personal-statistic.report-template.create-report.desc.name.text': 'Template name',
    'personal-statistic.report-template.create-report.desc.number.text': 'report number',

    // roles

    'personal-statistic.role.create.type.text': 'Create role',
    'personal-statistic.role.create.desc.text': 'Role created',
    'personal-statistic.role.create.desc.name.text': 'Role name',

    'personal-statistic.role.delete.type.text': 'Deleting a role',
    'personal-statistic.role.delete.desc.text': 'Deleted role',
    'personal-statistic.role.delete.desc.name.text': 'Role name',

    'personal-statistic.role.update.type.text': 'Change role',
    'personal-statistic.role.update.desc.text': 'Role changed',
    'personal-statistic.role.update.desc.name.text': 'Role name',
    'personal-statistic.role.update.desc.field.text': 'Field',
    'personal-statistic.role.update.desc.old-value.text': 'Old value',
    'personal-statistic.role.update.desc.new-value.text': 'new value',

    // trackers

    'personal-statistic.trackers.create.type.text': 'Create a tracker',
    'personal-statistic.trackers.create.desc.text': 'Tracker created',
    'personal-statistic.trackers.create.desc.number.text': 'Tracker number',
    'personal-statistic.trackers.create.desc.model.text': 'tracker model',

    'personal-statistic.trackers.delete.type.text': 'Deleting a tracker',
    'personal-statistic.trackers.delete.desc.text': 'Deleted tracker',
    'personal-statistic.trackers.delete.desc.number.text': 'Tracker number',
    'personal-statistic.trackers.delete.desc.model.text': 'tracker model',

    'personal-statistic.trackers.update.type.text': 'Change tracker',
    'personal-statistic.trackers.update.desc.text': 'Changed tracker',
    'personal-statistic.trackers.update.desc.field.text': 'Field',
    'personal-statistic.trackers.update.desc.old-value.text': 'Old value',
    'personal-statistic.trackers.update.desc.new-value.text': 'new value',

    // transport

    'personal-statistic.transport.create.type.text': 'Create transport',
    'personal-statistic.transport.create.desc.text': 'Transport created',
    'personal-statistic.transport.create.desc.brand.text': 'Vehicle brand',
    'personal-statistic.transport.create.desc.model.text': 'model',
    'personal-statistic.transport.create.desc.number.text': 'registration number',

    'personal-statistic.transport.delete.type.text': 'Deleting a transport',
    'personal-statistic.transport.delete.desc.text': 'Deleted transport',
    'personal-statistic.transport.delete.desc.brand.text': 'Vehicle brand',
    'personal-statistic.transport.delete.desc.model.text': 'model',
    'personal-statistic.transport.delete.desc.number.text': 'registration number',

    'personal-statistic.transport.update.type.text': 'Modify data about transport',
    'personal-statistic.transport.update.desc.text': 'Data about transport has been changed',
    'personal-statistic.transport.update.desc.field.text': 'Field',
    'personal-statistic.transport.update.desc.old-value.text': 'Old value',
    'personal-statistic.transport.update.desc.new-value.text': 'new value',

    'personal-statistic.transport.assign-field.type.text': 'Create a custom data field',
    'personal-statistic.transport.assign-field.desc.text': 'Custom data field has been created for the transport',
    'personal-statistic.transport.assign-field.desc.brand.text': 'Vehicle brand',
    'personal-statistic.transport.assign-field.desc.model.text': 'model',
    'personal-statistic.transport.assign-field.desc.number.text': 'registration number',
    'personal-statistic.transport.assign-field.desc.field-name.text': 'Field name',
    'personal-statistic.transport.assign-field.desc.field-value.text': 'field value',

    'personal-statistic.transport.update-assign-field.type.text': 'Modify names and values ​​of custom fields',
    'personal-statistic.transport.update-assign-field.desc.text':
      'Names and values ​​of custom fields has been changed',
    'personal-statistic.transport.update-assign-field.desc.transport-brand.text': 'Vehicle brand',
    'personal-statistic.transport.update-assign-field.desc.transport-model.text': 'model',
    'personal-statistic.transport.update-assign-field.desc.transport-number.text': 'registration number',
    'personal-statistic.transport.update-assign-field.desc.added.text': 'added field',
    'personal-statistic.transport.update-assign-field.desc.deleted.text': 'deleted field',
    'personal-statistic.transport.update-assign-field.desc.changed.text': 'field changed',
    'personal-statistic.transport.update-assign-field.desc.old.text': 'old',
    'personal-statistic.transport.update-assign-field.desc.new.text': 'new',
    'personal-statistic.transport.update-assign-field.desc.label.text': 'field name',
    'personal-statistic.transport.update-assign-field.desc.value.text': 'field value',
    'personal-statistic.transport.update-assign-field.desc.visible.text': 'field visibility',

    'personal-statistic.transport.assign-tracker.type.text': 'Binding tracker',
    'personal-statistic.transport.assign-tracker.desc.text': 'The tracker is attached to a vehicle',
    'personal-statistic.transport.assign-tracker.desc.brand.text': 'Vehicle brand',
    'personal-statistic.transport.assign-tracker.desc.model.text': 'model',
    'personal-statistic.transport.assign-tracker.desc.number.text': 'registration number',
    'personal-statistic.transport.assign-tracker.desc.tracker-number.text': 'Tracker number',
    'personal-statistic.transport.assign-tracker.desc.tracker-model.text': 'tracker model',

    'personal-statistic.transport.un-assign-tracker.type.text': 'Unassign tracker',
    'personal-statistic.transport.un-assign-tracker.desc.text': 'Tracker is unlinked from the transport',
    'personal-statistic.transport.un-assign-tracker.desc.brand.text': 'Vehicle brand',
    'personal-statistic.transport.un-assign-tracker.desc.model.text': 'model',
    'personal-statistic.transport.un-assign-tracker.desc.number.text': 'registration number',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text': 'Tracker number',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text': 'tracker model',

    // user

    'personal-statistic.user.create.type.text': 'Create user',
    'personal-statistic.user.create.desc.text': 'A user has been created',
    'personal-statistic.user.create.desc.login.text': 'Login',
    'personal-statistic.user.create.desc.name.text': 'name',

    'personal-statistic.user.delete.type.text': 'Deleting a user',
    'personal-statistic.user.delete.desc.text': 'Deleted user',
    'personal-statistic.user.delete.desc.login.text': 'Login',
    'personal-statistic.user.delete.desc.name.text': 'name',

    'personal-statistic.user.update.type.text': 'Changing user data',
    'personal-statistic.user.update.desc.text': 'User data changed',
    'personal-statistic.user.update.desc.login.text': 'Login',
    'personal-statistic.user.update.desc.field.text': 'Field',
    'personal-statistic.user.update.desc.old-value.text': 'Old value',
    'personal-statistic.user.update.desc.new-value.text': 'new value',

    'personal-statistic.user.assign-role.type.text': 'Assigning a role to a user',
    'personal-statistic.user.assign-role.desc.text': 'Assigned role to user',
    'personal-statistic.user.assign-role.desc.login.text': 'Login',
    'personal-statistic.user.assign-role.desc.role-name.text': 'role name',

    'personal-statistic.user.un-assign-role.type.text': 'Deleting a user from a role',
    'personal-statistic.user.un-assign-role.desc.text': 'The user was excluded from the role',
    'personal-statistic.user.un-assign-role.desc.login.text': 'Login',
    'personal-statistic.user.un-assign-role.desc.role-name.text': 'role name',
  },
  ru: {
    'personal-statistic.no-data.text': 'нет данных',
    'personal-statistic.no.text': 'нет',
    // auth

    'personal-statistic.auth.login-auth.type.text': 'Авторизация пользователя',
    'personal-statistic.auth.login-auth.desc.text': 'Осуществлен вход в систему',
    'personal-statistic.auth.login-auth.desc.login.text': 'Логин',
    'personal-statistic.auth.login-auth.desc.name.text': 'имя',
    'personal-statistic.auth.login-auth.desc.ip.text': 'ip',

    // employee

    'personal-statistic.employee.create.type.text': 'Создание сотрудника',
    'personal-statistic.employee.create.desc.text': 'Создан сотрудник',
    'personal-statistic.employee.create.desc.name.text': 'Имя',
    'personal-statistic.employee.create.desc.sim.text': 'номер sim',

    'personal-statistic.employee.delete.type.text': 'Удаление сотрудника',
    'personal-statistic.employee.delete.desc.text': 'Удален сотрудник',
    'personal-statistic.employee.delete.desc.name.text': 'Имя',
    'personal-statistic.employee.delete.desc.sim.text': 'номер sim',

    'personal-statistic.employee.update.type.text': 'Изменение данных сотрудника',
    'personal-statistic.employee.update.desc.text': 'Изменены данные сотрудника',
    'personal-statistic.employee.update.desc.field.text': 'Поле',
    'personal-statistic.employee.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.employee.update.desc.new-value.text': 'новое значение',

    'personal-statistic.employee.assign-field.type.text': 'Создание произвольного поля данных для сотрудника',
    'personal-statistic.employee.assign-field.desc.text': 'Для сотрудника создано произвольное поле данных',
    'personal-statistic.employee.assign-field.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.assign-field.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.assign-field.desc.field-name.text': 'Имя поля',
    'personal-statistic.employee.assign-field.desc.field-value.text': 'значение поля',

    'personal-statistic.employee.update-assign-field.type.text': 'Изменение наименований и значений произвольных полей',
    'personal-statistic.employee.update-assign-field.desc.text':
      'Изменены наименования и значения произвольных полей сотрудника',
    'personal-statistic.employee.update-assign-field.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.update-assign-field.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.update-assign-field.desc.added.text': 'добавлено поле',
    'personal-statistic.employee.update-assign-field.desc.deleted.text': 'удалено поле',
    'personal-statistic.employee.update-assign-field.desc.changed.text': 'изменено поле',
    'personal-statistic.employee.update-assign-field.desc.old.text': 'старое',
    'personal-statistic.employee.update-assign-field.desc.new.text': 'новое',
    'personal-statistic.employee.update-assign-field.desc.label.text': 'название поля',
    'personal-statistic.employee.update-assign-field.desc.value.text': 'значение поля',
    'personal-statistic.employee.update-assign-field.desc.visible.text': 'видимость поля',

    'personal-statistic.employee.assign-work-object.type.text': 'Добавление в группу объектов',
    'personal-statistic.employee.assign-work-object.desc.text': 'Сотрудник добавлен в группу объектов',
    'personal-statistic.employee.assign-work-object.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.assign-work-object.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.assign-work-object.desc.work-obj-name.text': 'Название объекта выполнения работ',

    'personal-statistic.employee.un-assign-work-object.type.text': 'Удаление из группы объектов',
    'personal-statistic.employee.un-assign-work-object.desc.text': 'Сотрудник удален из группы объектов',
    'personal-statistic.employee.un-assign-work-object.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.un-assign-work-object.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text': 'Название объекта выполнения работ',

    'personal-statistic.employee.assign-tracker.type.text': 'Привязывание трекера к сотруднику',
    'personal-statistic.employee.assign-tracker.desc.text': 'Трекер привязан к сотруднику',
    'personal-statistic.employee.assign-tracker.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.assign-tracker.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.assign-tracker.desc.tracker-number.text': 'Номер трекера',
    'personal-statistic.employee.assign-tracker.desc.tracker-model.text': 'модель трекера',

    'personal-statistic.employee.un-assign-tracker.type.text': 'Отвязывание трекера от сотрудника',
    'personal-statistic.employee.un-assign-tracker.desc.text': 'Трекер отвязан от сотрудника',
    'personal-statistic.employee.un-assign-tracker.desc.employee-name.text': 'Имя сотрудника',
    'personal-statistic.employee.un-assign-tracker.desc.employee-sim.text': 'номер сим',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text': 'Номер трекера',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text': 'модель трекера',

    // geozones

    'personal-statistic.geozones.create.type.text': 'Создание геозоны',
    'personal-statistic.geozones.create.desc.text': 'Создана геозона',
    'personal-statistic.geozones.create.desc.name.text': 'Название геозоны',
    'personal-statistic.geozones.create.desc.geometric-type.text': 'тип геометрии',
    'personal-statistic.geozones.create.desc.gezone-type.text': 'тип геозоны',

    'personal-statistic.geozones.delete.type.text': 'Удаление геозоны',
    'personal-statistic.geozones.delete.desc.text': 'Удалена геозона',
    'personal-statistic.geozones.delete.desc.name.text': 'Название геозоны',
    'personal-statistic.geozones.delete.desc.geometric-type.text': 'тип геометрии',
    'personal-statistic.geozones.delete.desc.gezone-type.text': 'тип геозоны',

    'personal-statistic.geozones.update.type.text': 'Изменение геозоны',
    'personal-statistic.geozones.update.desc.text': 'Изменены данные геозоны',
    'personal-statistic.geozones.update.desc.field.text': 'Поле',
    'personal-statistic.geozones.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.geozones.update.desc.new-value.text': 'новое значение',

    'personal-statistic.geozone-groups.create.type.text': 'Создание группы геозон',
    'personal-statistic.geozone-groups.create.desc.text': 'Создана группа геозон',
    'personal-statistic.geozone-groups.create.desc.name.text': 'Название геозоны',
    'personal-statistic.geozone-groups.create.desc.geometric-type.text': 'родительская группа',

    'personal-statistic.geozone-groups.delete.type.text': 'Удаление группы геозон',
    'personal-statistic.geozone-groups.delete.desc.text': 'Удалена группа геозон',
    'personal-statistic.geozone-groups.delete.desc.name.text': 'Название геозоны',
    'personal-statistic.geozone-groups.delete.desc.geometric-type.text': 'родительская группа',

    'personal-statistic.geozone-groups.update.type.text': 'Изменение группы геозон',
    'personal-statistic.geozone-groups.update.desc.text': 'Изменены данные группы геозон',
    'personal-statistic.geozone-groups.update.desc.field.text': 'Поле',
    'personal-statistic.geozone-groups.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.geozone-groups.update.desc.new-value.text': 'новое значение',

    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text': 'Добавление геозоны в группу геозон',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text': 'Геозона добавлена в группу геозон',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text': 'Название геозоны',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text': 'группа',

    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text':
      'Удаление геозоны из группы геозон',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text':
      'Геозона удалена из группы геозон',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text': 'Название геозоны',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text': 'группа',

    // handbooks

    'personal-statistic.handbooks.create.type.text': 'Создание элемента в справочнике',
    'personal-statistic.handbooks.create.desc.text': 'Создан новый элемент в справочнике',
    'personal-statistic.handbooks.create.desc.type.text': 'Тип справочника',
    'personal-statistic.handbooks.create.desc.name.text': 'название элемента',

    'personal-statistic.handbooks.update.type.text': 'Изменение данных элемента в справочнике',
    'personal-statistic.handbooks.update.desc.text': 'Изменен элемент в справочнике',
    'personal-statistic.handbooks.update.desc.type.text': 'Тип справочника',
    'personal-statistic.handbooks.update.desc.field.text': 'Поле',
    'personal-statistic.handbooks.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.handbooks.update.desc.new-value.text': 'новое значение',

    // notifications

    'personal-statistic.notifications.added.type.text': 'Сработало уведомление',
    'personal-statistic.notifications.added.desc.text': 'Сработало уведомление',
    'personal-statistic.notifications.added.desc.type.text': 'Тип уведомления',
    'personal-statistic.notifications.added.desc.text.text': 'текст уведомления',

    // poi

    'personal-statistic.poi.create.type.text': 'Создание POI',
    'personal-statistic.poi.create.desc.text': 'Создана POI',
    'personal-statistic.poi.create.desc.name.text': 'Название POI',

    'personal-statistic.poi.delete.type.text': 'Удаление POI',
    'personal-statistic.poi.delete.desc.text': 'Удалена POI',
    'personal-statistic.poi.delete.desc.name.text': 'Название POI',

    'personal-statistic.poi.update.type.text': 'Изменение POI',
    'personal-statistic.poi.update.desc.text': 'Изменены данные POI',
    'personal-statistic.poi.update.desc.field.text': 'Поле',
    'personal-statistic.poi.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.poi.update.desc.new-value.text': 'новое значение',

    // report

    'personal-statistic.report-template.create.type.text': 'Создание шаблона отчета',
    'personal-statistic.report-template.create.desc.text': 'Создан шаблон отчета',
    'personal-statistic.report-template.create.desc.name.text': 'Название шаблона',

    'personal-statistic.report-template.delete.type.text': 'Удаление шаблона отчета',
    'personal-statistic.report-template.delete.desc.text': 'Удален шаблон отчета',
    'personal-statistic.report-template.delete.desc.name.text': 'Название шаблона',

    'personal-statistic.report-template.update.type.text': 'Изменение данных шаблона отчета',
    'personal-statistic.report-template.update.desc.text': 'Изменены данные шаблона отчета',
    'personal-statistic.report-template.update.desc.name.text': 'Название шаблона',

    'personal-statistic.report-template.create-report.type.text': 'Создание отчета по шаблону',
    'personal-statistic.report-template.create-report.desc.text': 'Создан отчет по шаблону',
    'personal-statistic.report-template.create-report.desc.name.text': 'Название шаблона',
    'personal-statistic.report-template.create-report.desc.number.text': 'номер отчета',

    // roles

    'personal-statistic.role.create.type.text': 'Создание роли',
    'personal-statistic.role.create.desc.text': 'Создана роль',
    'personal-statistic.role.create.desc.name.text': 'Название роли',

    'personal-statistic.role.delete.type.text': 'Удаление роли',
    'personal-statistic.role.delete.desc.text': 'Удалена роль',
    'personal-statistic.role.delete.desc.name.text': 'Название роли',

    'personal-statistic.role.update.type.text': 'Изменение роли',
    'personal-statistic.role.update.desc.text': 'Изменена роль',
    'personal-statistic.role.update.desc.name.text': 'Название роли',
    'personal-statistic.role.update.desc.field.text': 'Поле',
    'personal-statistic.role.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.role.update.desc.new-value.text': 'новое значение',

    // trackers

    'personal-statistic.trackers.create.type.text': 'Создание трекера',
    'personal-statistic.trackers.create.desc.text': 'Создан трекер',
    'personal-statistic.trackers.create.desc.number.text': 'Номер трекера',
    'personal-statistic.trackers.create.desc.model.text': 'модель трекера',

    'personal-statistic.trackers.delete.type.text': 'Удаление трекера',
    'personal-statistic.trackers.delete.desc.text': 'Удален трекер',
    'personal-statistic.trackers.delete.desc.number.text': 'Номер трекера',
    'personal-statistic.trackers.delete.desc.model.text': 'модель трекера',

    'personal-statistic.trackers.update.type.text': 'Изменение трекера',
    'personal-statistic.trackers.update.desc.text': 'Изменен трекер',
    'personal-statistic.trackers.update.desc.field.text': 'Поле',
    'personal-statistic.trackers.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.trackers.update.desc.new-value.text': 'новое значение',

    // transport

    'personal-statistic.transport.create.type.text': 'Создание транспорта',
    'personal-statistic.transport.create.desc.text': 'Создан транспорт',
    'personal-statistic.transport.create.desc.brand.text': 'Марка транспорта',
    'personal-statistic.transport.create.desc.model.text': 'модель',
    'personal-statistic.transport.create.desc.number.text': 'регистрационный номер',

    'personal-statistic.transport.delete.type.text': 'Удаление транспорта',
    'personal-statistic.transport.delete.desc.text': 'Удален транспорт',
    'personal-statistic.transport.delete.desc.brand.text': 'Марка транспорта',
    'personal-statistic.transport.delete.desc.model.text': 'модель',
    'personal-statistic.transport.delete.desc.number.text': 'регистрационный номер',

    'personal-statistic.transport.update.type.text': 'Изменение данных транспорта',
    'personal-statistic.transport.update.desc.text': 'Данные о транспорте были изменены',
    'personal-statistic.transport.update.desc.field.text': 'Поле',
    'personal-statistic.transport.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.transport.update.desc.new-value.text': 'новое значение',

    'personal-statistic.transport.assign-field.type.text': 'Создание произвольного поля данных',
    'personal-statistic.transport.assign-field.desc.text': 'Для транспорта создано произвольное поле данных',
    'personal-statistic.transport.assign-field.desc.brand.text': 'Марка транспорта',
    'personal-statistic.transport.assign-field.desc.model.text': 'модель',
    'personal-statistic.transport.assign-field.desc.number.text': 'регистрационный номер',
    'personal-statistic.transport.assign-field.desc.field-name.text': 'Имя поля',
    'personal-statistic.transport.assign-field.desc.field-value.text': 'значение поля',

    'personal-statistic.transport.update-assign-field.type.text':
      'Изменение наименований и значений произвольных полей',
    'personal-statistic.transport.update-assign-field.desc.text':
      'Изменены наименования и значения произвольных полей сотрудника',
    'personal-statistic.transport.update-assign-field.desc.transport-brand.text': 'Марка транспорта',
    'personal-statistic.transport.update-assign-field.desc.transport-model.text': 'модель',
    'personal-statistic.transport.update-assign-field.desc.transport-number.text': 'регистрационный номер',
    'personal-statistic.transport.update-assign-field.desc.added.text': 'добавлено поле',
    'personal-statistic.transport.update-assign-field.desc.deleted.text': 'удалено поле',
    'personal-statistic.transport.update-assign-field.desc.changed.text': 'изменено поле',
    'personal-statistic.transport.update-assign-field.desc.old.text': 'старое',
    'personal-statistic.transport.update-assign-field.desc.new.text': 'новое',
    'personal-statistic.transport.update-assign-field.desc.label.text': 'название поля',
    'personal-statistic.transport.update-assign-field.desc.value.text': 'значение поля',
    'personal-statistic.transport.update-assign-field.desc.visible.text': 'видимость поля',

    'personal-statistic.transport.assign-tracker.type.text': 'Привязывание трекера',
    'personal-statistic.transport.assign-tracker.desc.text': 'Трекер привязан к транспорту',
    'personal-statistic.transport.assign-tracker.desc.brand.text': 'Марка транспорта',
    'personal-statistic.transport.assign-tracker.desc.model.text': 'модель',
    'personal-statistic.transport.assign-tracker.desc.number.text': 'регистрационный номер',
    'personal-statistic.transport.assign-tracker.desc.tracker-number.text': 'Номер трекера',
    'personal-statistic.transport.assign-tracker.desc.tracker-model.text': 'модель трекера',

    'personal-statistic.transport.un-assign-tracker.type.text': 'Отвязывание трекера',
    'personal-statistic.transport.un-assign-tracker.desc.text': 'Трекер отвязан от транспорта',
    'personal-statistic.transport.un-assign-tracker.desc.brand.text': 'Марка транспорта',
    'personal-statistic.transport.un-assign-tracker.desc.model.text': 'модель',
    'personal-statistic.transport.un-assign-tracker.desc.number.text': 'регистрационный номер',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text': 'Номер трекера',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text': 'модель трекера',

    // user

    'personal-statistic.user.create.type.text': 'Создание пользователя',
    'personal-statistic.user.create.desc.text': 'Создан пользователь',
    'personal-statistic.user.create.desc.login.text': 'Логин',
    'personal-statistic.user.create.desc.name.text': 'имя',

    'personal-statistic.user.delete.type.text': 'Удаление пользователя',
    'personal-statistic.user.delete.desc.text': 'Удален пользователь',
    'personal-statistic.user.delete.desc.login.text': 'Логин',
    'personal-statistic.user.delete.desc.name.text': 'имя',

    'personal-statistic.user.update.type.text': 'Изменение данных о пользователе',
    'personal-statistic.user.update.desc.text': 'Изменены данные о пользователе',
    'personal-statistic.user.update.desc.login.text': 'Логин',
    'personal-statistic.user.update.desc.field.text': 'Поле',
    'personal-statistic.user.update.desc.old-value.text': 'Старое значение',
    'personal-statistic.user.update.desc.new-value.text': 'новое значение',

    'personal-statistic.user.assign-role.type.text': 'Назначение пользователю роли',
    'personal-statistic.user.assign-role.desc.text': 'Назначена роль пользователю',
    'personal-statistic.user.assign-role.desc.login.text': 'Логин',
    'personal-statistic.user.assign-role.desc.role-name.text': 'название роли',

    'personal-statistic.user.un-assign-role.type.text': 'Исключение пользователя из роли',
    'personal-statistic.user.un-assign-role.desc.text': 'Исключен пользователь из роли',
    'personal-statistic.user.un-assign-role.desc.login.text': 'Логин',
    'personal-statistic.user.un-assign-role.desc.role-name.text': 'название роли',
  },
  zh: {
    'personal-statistic.no-data.text': '无数据',
    'personal-statistic.no.text': '不是',
    // auth

    'personal-statistic.auth.login-auth.type.text': '用户授权',
    'personal-statistic.auth.login-auth.desc.text': '已登录',
    'personal-statistic.auth.login-auth.desc.login.text': '用户名',
    'personal-statistic.auth.login-auth.desc.name.text': '名字',
    'personal-statistic.auth.login-auth.desc.ip.text': 'ip',

    // employee

    'personal-statistic.employee.create.type.text': '创造工作人',
    'personal-statistic.employee.create.desc.text': '已创造工作人',
    'personal-statistic.employee.create.desc.name.text': '名字',
    'personal-statistic.employee.create.desc.sim.text': 'sim号',
    'personal-statistic.employee.delete.type.text': '消除工作人',
    'personal-statistic.employee.delete.desc.text': '已消除工作人',
    'personal-statistic.employee.delete.desc.name.text': '名字',
    'personal-statistic.employee.delete.desc.sim.text': 'sim号',
    'personal-statistic.employee.update.type.text': '变化工作人数据',
    'personal-statistic.employee.update.desc.text': '变化工作人数据',
    'personal-statistic.employee.update.desc.field.text': '域',
    'personal-statistic.employee.update.desc.old-value.text': '旧值',
    'personal-statistic.employee.update.desc.new-value.text': '新值',
    'personal-statistic.employee.assign-field.type.text': '创造工作人数据任意域',
    'personal-statistic.employee.assign-field.desc.text': '为工作人已创造数据任意域',
    'personal-statistic.employee.assign-field.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.assign-field.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.assign-field.desc.field-name.text': '域名',
    'personal-statistic.employee.assign-field.desc.field-value.text': '域值',
    'personal-statistic.employee.update-assign-field.type.text': '变化任意域的名称与值',
    'personal-statistic.employee.update-assign-field.desc.text': '变化任意域的名称与值',
    'personal-statistic.employee.update-assign-field.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.update-assign-field.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.update-assign-field.desc.added.text': '已添加的域',
    'personal-statistic.employee.update-assign-field.desc.deleted.text': '已消除的域',
    'personal-statistic.employee.update-assign-field.desc.changed.text': '已变化的域',
    'personal-statistic.employee.update-assign-field.desc.old.text': '旧值',
    'personal-statistic.employee.update-assign-field.desc.new.text': '新值',
    'personal-statistic.employee.update-assign-field.desc.label.text': '域名称',
    'personal-statistic.employee.update-assign-field.desc.value.text': '域值',
    'personal-statistic.employee.update-assign-field.desc.visible.text': '域外观',
    'personal-statistic.employee.assign-work-object.type.text': '添加到对象组',
    'personal-statistic.employee.assign-work-object.desc.text': '工作人已在对象组被添加',
    'personal-statistic.employee.assign-work-object.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.assign-work-object.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.assign-work-object.desc.work-obj-name.text': '执行工作对象名称',
    'personal-statistic.employee.un-assign-work-object.type.text': '从对象组消除',
    'personal-statistic.employee.un-assign-work-object.desc.text': '工作人从对象组已被消除',
    'personal-statistic.employee.un-assign-work-object.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.un-assign-work-object.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text': '执行工作对象名称',
    'personal-statistic.employee.assign-tracker.type.text': '将跟踪器链接到工作人',
    'personal-statistic.employee.assign-tracker.desc.text': '跟踪器与工作人链接',
    'personal-statistic.employee.assign-tracker.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.assign-tracker.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.assign-tracker.desc.tracker-number.text': '跟踪器号',
    'personal-statistic.employee.assign-tracker.desc.tracker-model.text': '跟踪器型号',
    'personal-statistic.employee.un-assign-tracker.type.text': '取消与工作人的跟踪器链接',
    'personal-statistic.employee.un-assign-tracker.desc.text': '跟踪器与工作人取消链接',
    'personal-statistic.employee.un-assign-tracker.desc.employee-name.text': '工作人姓名',
    'personal-statistic.employee.un-assign-tracker.desc.employee-sim.text': 'sim号',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text': '跟踪器号',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text': '跟踪器型号',

    // geozones

    'personal-statistic.geozones.create.type.text': '创造地理区',
    'personal-statistic.geozones.create.desc.text': '已创造地理区',
    'personal-statistic.geozones.create.desc.name.text': '地理区名称',
    'personal-statistic.geozones.create.desc.geometric-type.text': '几何种类',
    'personal-statistic.geozones.create.desc.gezone-type.text': '地理区种类',
    'personal-statistic.geozones.delete.type.text': '消除地理区',
    'personal-statistic.geozones.delete.desc.text': '已消除的地理区',
    'personal-statistic.geozones.delete.desc.name.text': '地理区名称',
    'personal-statistic.geozones.delete.desc.geometric-type.text': '几何种类',
    'personal-statistic.geozones.delete.desc.gezone-type.text': '地理区种类',
    'personal-statistic.geozones.update.type.text': '地理区变化',
    'personal-statistic.geozones.update.desc.text': '已变化地理区数据',
    'personal-statistic.geozones.update.desc.field.text': '域',
    'personal-statistic.geozones.update.desc.old-value.text': '旧值',
    'personal-statistic.geozones.update.desc.new-value.text': '新值',
    'personal-statistic.geozone-groups.create.type.text': '创造地理区组',
    'personal-statistic.geozone-groups.create.desc.text': ' 已创造的地理区组',
    'personal-statistic.geozone-groups.create.desc.name.text': '地理区名称',
    'personal-statistic.geozone-groups.create.desc.geometric-type.text': '母组',
    'personal-statistic.geozone-groups.delete.type.text': '消除地理区组',
    'personal-statistic.geozone-groups.delete.desc.text': '已消除的地理区组',
    'personal-statistic.geozone-groups.delete.desc.name.text': '地理区名称',
    'personal-statistic.geozone-groups.delete.desc.geometric-type.text': '母组',
    'personal-statistic.geozone-groups.update.type.text': '变化地理区组',
    'personal-statistic.geozone-groups.update.desc.text': '已变化地理区组的数据',
    'personal-statistic.geozone-groups.update.desc.field.text': '域',
    'personal-statistic.geozone-groups.update.desc.old-value.text': '旧值',
    'personal-statistic.geozone-groups.update.desc.new-value.text': '新值',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text': '把地理区添加到地理区组',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text': '地理区已在地理区组被添加',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text': '地理区名称',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text': '组',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text': '从地理区组消除地理区',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text': '地理区已从地理区组被消除',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text': '地理区名称',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text': '组',

    // handbooks

    'personal-statistic.handbooks.create.type.text': '在手册创造成分',
    'personal-statistic.handbooks.create.desc.text': '在手册新成分已被创造',
    'personal-statistic.handbooks.create.desc.type.text': '手册种类',
    'personal-statistic.handbooks.create.desc.name.text': '成分名称',
    'personal-statistic.handbooks.update.type.text': '变化手册中成分数据',
    'personal-statistic.handbooks.update.desc.text': '手册中成分已被变化',
    'personal-statistic.handbooks.update.desc.type.text': '手册种类',
    'personal-statistic.handbooks.update.desc.field.text': '域',
    'personal-statistic.handbooks.update.desc.old-value.text': '旧值',
    'personal-statistic.handbooks.update.desc.new-value.text': '新值',

    // notifications

    'personal-statistic.notifications.added.type.text': '动起来的通知',
    'personal-statistic.notifications.added.desc.text': '动起来的通知',
    'personal-statistic.notifications.added.desc.type.text': '通知种类',
    'personal-statistic.notifications.added.desc.text.text': '通知文',

    // poi

    'personal-statistic.poi.create.type.text': '创造POI',
    'personal-statistic.poi.create.desc.text': '已创造的POI',
    'personal-statistic.poi.create.desc.name.text': 'POI名称',
    'personal-statistic.poi.delete.type.text': '消除POI',
    'personal-statistic.poi.delete.desc.text': '已消除的POI',
    'personal-statistic.poi.delete.desc.name.text': 'POI名称',
    'personal-statistic.poi.update.type.text': 'POI变化',
    'personal-statistic.poi.update.desc.text': 'POI数据变化',
    'personal-statistic.poi.update.desc.field.text': '域 ',
    'personal-statistic.poi.update.desc.old-value.text': '旧值',
    'personal-statistic.poi.update.desc.new-value.text': '新值',

    // report

    'personal-statistic.report-template.create.type.text': '创造报告mu ban',
    'personal-statistic.report-template.create.desc.text': '已创造的报告模板',
    'personal-statistic.report-template.create.desc.name.text': '模板名称',
    'personal-statistic.report-template.delete.type.text': '消除报告模板',
    'personal-statistic.report-template.delete.desc.text': '已消除的报告模板',
    'personal-statistic.report-template.delete.desc.name.text': '模板名称',
    'personal-statistic.report-template.update.type.text': '变化报告模板数据',
    'personal-statistic.report-template.update.desc.text': '已变化报告模板的数据',
    'personal-statistic.report-template.update.desc.name.text': '模板名称',
    'personal-statistic.report-template.create-report.type.text': '按模板创造报告',
    'personal-statistic.report-template.create-report.desc.text': '已创造按模板的报告',
    'personal-statistic.report-template.create-report.desc.name.text': '模板名称',
    'personal-statistic.report-template.create-report.desc.number.text': '报告号',

    // roles

    'personal-statistic.role.create.type.text': '创造作用',
    'personal-statistic.role.create.desc.text': '已创造的作用',
    'personal-statistic.role.create.desc.name.text': '作用名称',
    'personal-statistic.role.delete.type.text': '消除作用',
    'personal-statistic.role.delete.desc.text': '已消除的作用',
    'personal-statistic.role.delete.desc.name.text': '作用名称',
    'personal-statistic.role.update.type.text': '变化作用',
    'personal-statistic.role.update.desc.text': '已变化的作用',
    'personal-statistic.role.update.desc.name.text': '作用名称',
    'personal-statistic.role.update.desc.field.text': '域 ',
    'personal-statistic.role.update.desc.old-value.text': '旧值',
    'personal-statistic.role.update.desc.new-value.text': '新值',

    // trackers

    'personal-statistic.trackers.create.type.text': '创造跟踪器',
    'personal-statistic.trackers.create.desc.text': '已创造的跟踪器',
    'personal-statistic.trackers.create.desc.number.text': '跟踪器号',
    'personal-statistic.trackers.create.desc.model.text': '跟踪器型号',
    'personal-statistic.trackers.delete.type.text': '消除跟踪器',
    'personal-statistic.trackers.delete.desc.text': '已消除的跟踪器',
    'personal-statistic.trackers.delete.desc.number.text': '跟踪器号',
    'personal-statistic.trackers.delete.desc.model.text': '跟踪器型号',
    'personal-statistic.trackers.update.type.text': '变化跟踪器',
    'personal-statistic.trackers.update.desc.text': '已变化的跟踪器',
    'personal-statistic.trackers.update.desc.field.text': '域',
    'personal-statistic.trackers.update.desc.old-value.text': '旧值',
    'personal-statistic.trackers.update.desc.new-value.text': '新值',

    // transport

    'personal-statistic.transport.create.type.text': '创造运输',
    'personal-statistic.transport.create.desc.text': '已创造的运输',
    'personal-statistic.transport.create.desc.brand.text': '运输牌',
    'personal-statistic.transport.create.desc.model.text': '型号',
    'personal-statistic.transport.create.desc.number.text': '注册号',
    'personal-statistic.transport.delete.type.text': '消除运输',
    'personal-statistic.transport.delete.desc.text': '已消除运输',
    'personal-statistic.transport.delete.desc.brand.text': '运输牌',
    'personal-statistic.transport.delete.desc.model.text': '型号',
    'personal-statistic.transport.delete.desc.number.text': '注册号',
    'personal-statistic.transport.update.type.text': '变化运输数据',
    'personal-statistic.transport.update.desc.text': '已变化的运输数据',
    'personal-statistic.transport.update.desc.field.text': '域 ',
    'personal-statistic.transport.update.desc.old-value.text': '旧值',
    'personal-statistic.transport.update.desc.new-value.text': '新值',
    'personal-statistic.transport.assign-field.type.text': '创造任意数据域',
    'personal-statistic.transport.assign-field.desc.text': '为运输已创造任意数据域',
    'personal-statistic.transport.assign-field.desc.brand.text': '运输牌',
    'personal-statistic.transport.assign-field.desc.model.text': '型号',
    'personal-statistic.transport.assign-field.desc.number.text': '注册号',
    'personal-statistic.transport.assign-field.desc.field-name.text': '域名',
    'personal-statistic.transport.assign-field.desc.field-value.text': '域值',
    'personal-statistic.transport.update-assign-field.type.text': '变化任意域的名称与值',
    'personal-statistic.transport.update-assign-field.desc.text': '已变化的工作人任意域名称域值',
    'personal-statistic.transport.update-assign-field.desc.transport-brand.text': '运输牌',
    'personal-statistic.transport.update-assign-field.desc.transport-model.text': '型号',
    'personal-statistic.transport.update-assign-field.desc.transport-number.text': '注册号',
    'personal-statistic.transport.update-assign-field.desc.added.text': '已添加的域',
    'personal-statistic.transport.update-assign-field.desc.deleted.text': '已消除的域',
    'personal-statistic.transport.update-assign-field.desc.changed.text': '已变化的域',
    'personal-statistic.transport.update-assign-field.desc.old.text': '旧',
    'personal-statistic.transport.update-assign-field.desc.new.text': '新 ',
    'personal-statistic.transport.update-assign-field.desc.label.text': '域名称',
    'personal-statistic.transport.update-assign-field.desc.value.text': '域值',
    'personal-statistic.transport.update-assign-field.desc.visible.text': '域外观',
    'personal-statistic.transport.assign-tracker.type.text': '跟踪器链接',
    'personal-statistic.transport.assign-tracker.desc.text': '跟踪器与运输已链接',
    'personal-statistic.transport.assign-tracker.desc.brand.text': '运输牌',
    'personal-statistic.transport.assign-tracker.desc.model.text': '型号',
    'personal-statistic.transport.assign-tracker.desc.number.text': '注册号',
    'personal-statistic.transport.assign-tracker.desc.tracker-number.text': '跟踪器号',
    'personal-statistic.transport.assign-tracker.desc.tracker-model.text': '跟踪器型号',
    'personal-statistic.transport.un-assign-tracker.type.text': '取消跟踪器的链接',
    'personal-statistic.transport.un-assign-tracker.desc.text': '已取消跟踪器与运输的链接',
    'personal-statistic.transport.un-assign-tracker.desc.brand.text': '运输牌',
    'personal-statistic.transport.un-assign-tracker.desc.model.text': '型号',
    'personal-statistic.transport.un-assign-tracker.desc.number.text': '注册号',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text': '跟踪器号',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text': '跟踪器型号',

    // user

    'personal-statistic.user.create.type.text': '创造用户',
    'personal-statistic.user.create.desc.text': '已创造的用户',
    'personal-statistic.user.create.desc.login.text': '用户名',
    'personal-statistic.user.create.desc.name.text': '名字',
    'personal-statistic.user.delete.type.text': '消除用户',
    'personal-statistic.user.delete.desc.text': '已消除的用户',
    'personal-statistic.user.delete.desc.login.text': '用户名',
    'personal-statistic.user.delete.desc.name.text': '名字',
    'personal-statistic.user.update.type.text': '变化用户数据',
    'personal-statistic.user.update.desc.text': '已变化用户数据',
    'personal-statistic.user.update.desc.login.text': '用户名',
    'personal-statistic.user.update.desc.field.text': '域',
    'personal-statistic.user.update.desc.old-value.text': '旧值',
    'personal-statistic.user.update.desc.new-value.text': '新值',
    'personal-statistic.user.assign-role.type.text': '指定用户作用',
    'personal-statistic.user.assign-role.desc.text': '已指定用户的作用',
    'personal-statistic.user.assign-role.desc.login.text': '用户名',
    'personal-statistic.user.assign-role.desc.role-name.text': '作用名称',
    'personal-statistic.user.un-assign-role.type.text': '从作用删除用户',
    'personal-statistic.user.un-assign-role.desc.text': '用户从作用已删除',
    'personal-statistic.user.un-assign-role.desc.login.text': '用户名',
    'personal-statistic.user.un-assign-role.desc.role-name.text': '作用名称',
  },
  ar: {
    'personal-statistic.no-data.text': 'لاتوجد بيانات',
    'personal-statistic.no.text': 'لا',
    // auth

    'personal-statistic.auth.login-auth.type.text': 'إذن المستخدم',
    'personal-statistic.auth.login-auth.desc.text': 'تسجيل الدخول للنظام',
    'personal-statistic.auth.login-auth.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.auth.login-auth.desc.name.text': 'الاسم',
    'personal-statistic.auth.login-auth.desc.ip.text': 'IP',

    // employee

    'personal-statistic.employee.create.type.text': 'أنشئ موظفًا',
    'personal-statistic.employee.create.desc.text': 'تم إنشاء الموظف',
    'personal-statistic.employee.create.desc.name.text': 'الاسم',
    'personal-statistic.employee.create.desc.sim.text': 'رقم sim',
    'personal-statistic.employee.delete.type.text': 'حذف الموظف',
    'personal-statistic.employee.delete.desc.text': 'تمت إزالة الموظف',
    'personal-statistic.employee.delete.desc.name.text': 'الاسم',
    'personal-statistic.employee.delete.desc.sim.text': 'رقم sim',
    'personal-statistic.employee.update.type.text': 'تغيير بيانات الموظف',
    'personal-statistic.employee.update.desc.text': 'تم تغيير بيانات الموظف',
    'personal-statistic.employee.update.desc.field.text': 'الحقل',
    'personal-statistic.employee.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.employee.update.desc.new-value.text': 'القيمة الجديدة',
    'personal-statistic.employee.assign-field.type.text': 'إنشاء حقل بيانات مخصص للموظف',
    'personal-statistic.employee.assign-field.desc.text': 'تم إنشاء حقل بيانات عشوائي لأحد الموظفين',
    'personal-statistic.employee.assign-field.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.assign-field.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.assign-field.desc.field-name.text': 'اسم الحقل',
    'personal-statistic.employee.assign-field.desc.field-value.text': 'قيمة الحقل',
    'personal-statistic.employee.update-assign-field.type.text': 'تغيير الأسماء والقيم للحقول المخصصة',
    'personal-statistic.employee.update-assign-field.desc.text': 'تم تغيير أسماء وقيم الحقول المخصصة للموظفين',
    'personal-statistic.employee.update-assign-field.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.update-assign-field.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.update-assign-field.desc.added.text': 'تمت إضافة الحقل',
    'personal-statistic.employee.update-assign-field.desc.deleted.text': 'تم حذف الحقل',
    'personal-statistic.employee.update-assign-field.desc.changed.text': 'تغير الحقل',
    'personal-statistic.employee.update-assign-field.desc.old.text': 'القديم',
    'personal-statistic.employee.update-assign-field.desc.new.text': 'الجديد',
    'personal-statistic.employee.update-assign-field.desc.label.text': 'اسم الحقل',
    'personal-statistic.employee.update-assign-field.desc.value.text': 'قيمة الحقل',
    'personal-statistic.employee.update-assign-field.desc.visible.text': 'رؤية الحقل',
    'personal-statistic.employee.assign-work-object.type.text': 'إضافة إلى مجموعة موضوعات',
    'personal-statistic.employee.assign-work-object.desc.text': 'تمت إضافة الموظف إلى مجموعة الموضوعات',
    'personal-statistic.employee.assign-work-object.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.assign-work-object.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.assign-work-object.desc.work-obj-name.text': 'اسم موضوع العمل المنفذ',
    'personal-statistic.employee.un-assign-work-object.type.text': 'تم الحذف من مجموعة الموضوعات',
    'personal-statistic.employee.un-assign-work-object.desc.text': 'تمت إزالة الموظف من مجموعة الموضوعات',
    'personal-statistic.employee.un-assign-work-object.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.un-assign-work-object.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text': 'اسم موضوع العمل',
    'personal-statistic.employee.assign-tracker.type.text': 'ربط التعقب بموظف',
    'personal-statistic.employee.assign-tracker.desc.text': 'المتعقب مرتبط بموظف',
    'personal-statistic.employee.assign-tracker.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.assign-tracker.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.assign-tracker.desc.tracker-number.text': 'رقم التعقب',
    'personal-statistic.employee.assign-tracker.desc.tracker-model.text': 'نموذج التعقب',
    'personal-statistic.employee.un-assign-tracker.type.text': 'تم فك ارتباط متعقب من موظف',
    'personal-statistic.employee.un-assign-tracker.desc.text': 'المتعقب غير مرتبط بالموظف',
    'personal-statistic.employee.un-assign-tracker.desc.employee-name.text': 'اسم الموظف',
    'personal-statistic.employee.un-assign-tracker.desc.employee-sim.text': 'رقم sim',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text': 'رقم التعقب',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text': 'نموذج التعقب',

    // geozones

    'personal-statistic.geozones.create.type.text': 'إنشاء سياج جغرافي',
    'personal-statistic.geozones.create.desc.text': 'تم إنشاء السياج الجغرافي',
    'personal-statistic.geozones.create.desc.name.text': 'اسم السياج الجغرافي',
    'personal-statistic.geozones.create.desc.geometric-type.text': 'نوع الهندسة',
    'personal-statistic.geozones.create.desc.gezone-type.text': 'نوع السياج الجغرافي',
    'personal-statistic.geozones.delete.type.text': 'حذف السياج الجغرافي',
    'personal-statistic.geozones.delete.desc.text': 'تمت حذف السياج الجغرافي',
    'personal-statistic.geozones.delete.desc.name.text': 'اسم السياج الجغرافي',
    'personal-statistic.geozones.delete.desc.geometric-type.text': 'نوع الهندسة',
    'personal-statistic.geozones.delete.desc.gezone-type.text': 'نوع السياج الجغرافي',
    'personal-statistic.geozones.update.type.text': 'تغيير السياج الجغرافي',
    'personal-statistic.geozones.update.desc.text': 'تغيرت بيانات السياج الجغرافي',
    'personal-statistic.geozones.update.desc.field.text': 'الحقل',
    'personal-statistic.geozones.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.geozones.update.desc.new-value.text': 'القيمة الجديدة',
    'personal-statistic.geozone-groups.create.type.text': 'إنشاء مجموعة سياج جغرافي',
    'personal-statistic.geozone-groups.create.desc.text': 'تم إنشاء مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.create.desc.name.text': 'اسم السياج الجغرافي',
    'personal-statistic.geozone-groups.create.desc.geometric-type.text': 'مجموعة الوالدين',
    'personal-statistic.geozone-groups.delete.type.text': 'حذف مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.delete.desc.text': 'تم حذف مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.delete.desc.name.text': 'اسم السياج الجغرافي',
    'personal-statistic.geozone-groups.delete.desc.geometric-type.text': 'مجموعة الوالدين',
    'personal-statistic.geozone-groups.update.type.text': 'تغيير مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.update.desc.text': 'تم تغيير بيانات مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.update.desc.field.text': 'الحقل',
    'personal-statistic.geozone-groups.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.geozone-groups.update.desc.new-value.text': 'القيمة الجديدة',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text':
      'إضافة سياج جغرافي لمجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text':
      'تمت إضافة السياج الجغرافي إلى مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text': 'أسم السياج الجغرافي',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text': 'المجموعة',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text':
      'حذف + C117: C200 سياج جغرافي من مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text':
      'تمت إزالة السياج الجغرافي من مجموعة السياج الجغرافي',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text': 'اسم السياج الجغرافي',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text': 'المجموعة',

    // handbooks

    'personal-statistic.handbooks.create.type.text': 'إنشاء عنصر في الدليل',
    'personal-statistic.handbooks.create.desc.text': 'إنشاء عنصر جديد في الدليل',
    'personal-statistic.handbooks.create.desc.type.text': 'نوع الدليل',
    'personal-statistic.handbooks.create.desc.name.text': 'اسم العنصر',
    'personal-statistic.handbooks.update.type.text': 'تغيير بيانات العنصر في الدليل',
    'personal-statistic.handbooks.update.desc.text': 'تغيير عنصر في الدليل',
    'personal-statistic.handbooks.update.desc.type.text': 'نوع الدليل',
    'personal-statistic.handbooks.update.desc.field.text': 'الحقل',
    'personal-statistic.handbooks.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.handbooks.update.desc.new-value.text': 'القيمة الجديدة',

    // notifications

    'personal-statistic.notifications.added.type.text': 'تم تشغيل الإخطار',
    'personal-statistic.notifications.added.desc.text': 'تم تشغيل الإخطار',
    'personal-statistic.notifications.added.desc.type.text': 'نوع الإخطار',
    'personal-statistic.notifications.added.desc.text.text': 'نص الإخطار',

    // poi

    'personal-statistic.poi.create.type.text': 'إنشاء POI',
    'personal-statistic.poi.create.desc.text': 'تم إنشاؤها بواسطة POI',
    'personal-statistic.poi.create.desc.name.text': 'اسم POI',
    'personal-statistic.poi.delete.type.text': 'حذف POI',
    'personal-statistic.poi.delete.desc.text': 'إزالة POI',
    'personal-statistic.poi.delete.desc.name.text': 'اسم POI',
    'personal-statistic.poi.update.type.text': 'تغيير POI',
    'personal-statistic.poi.update.desc.text': 'تم تغيير بيانات POI',
    'personal-statistic.poi.update.desc.field.text': 'الحقل',
    'personal-statistic.poi.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.poi.update.desc.new-value.text': 'القيمة الجديدة',

    // report

    'personal-statistic.report-template.create.type.text': 'إنشاء قالب تقرير',
    'personal-statistic.report-template.create.desc.text': 'تم إنشاء قالب التقرير',
    'personal-statistic.report-template.create.desc.name.text': 'اسم القالب',
    'personal-statistic.report-template.delete.type.text': 'حذف قالب تقرير',
    'personal-statistic.report-template.delete.desc.text': 'تم حذف قالب التقرير',
    'personal-statistic.report-template.delete.desc.name.text': 'اسم القالب',
    'personal-statistic.report-template.update.type.text': 'تغيير بيانات قالب التقرير',
    'personal-statistic.report-template.update.desc.text': 'تغيير بيانات قالب التقرير',
    'personal-statistic.report-template.update.desc.name.text': 'اسم القالب',
    'personal-statistic.report-template.create-report.type.text': 'نشاء تقرير من القالب',
    'personal-statistic.report-template.create-report.desc.text': 'تم إنشاء تقرير من القالب',
    'personal-statistic.report-template.create-report.desc.name.text': 'اسم القالب',
    'personal-statistic.report-template.create-report.desc.number.text': 'رقم التقرير',

    // roles

    'personal-statistic.role.create.type.text': 'أنشئ أدوار',
    'personal-statistic.role.create.desc.text': 'تم إنشاء الأدوار',
    'personal-statistic.role.create.desc.name.text': 'اسم الأدوار',
    'personal-statistic.role.delete.type.text': 'حذف الأدوار',
    'personal-statistic.role.delete.desc.text': 'حذف الدور',
    'personal-statistic.role.delete.desc.name.text': 'اسم الدور',
    'personal-statistic.role.update.type.text': 'تغيير الادوار',
    'personal-statistic.role.update.desc.text': 'تغير الدور',
    'personal-statistic.role.update.desc.name.text': 'اسم الادوار',
    'personal-statistic.role.update.desc.field.text': 'الحقل',
    'personal-statistic.role.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.role.update.desc.new-value.text': 'القيمة الجديدة',

    // trackers

    'personal-statistic.trackers.create.type.text': 'إنشاء متعقب',
    'personal-statistic.trackers.create.desc.text': 'تم إنشاء المتعقب',
    'personal-statistic.trackers.create.desc.number.text': 'رقم المتعقب',
    'personal-statistic.trackers.create.desc.model.text': 'نموذج التعقب',
    'personal-statistic.trackers.delete.type.text': 'حذف المتعقب',
    'personal-statistic.trackers.delete.desc.text': 'تم حذف المتعقب',
    'personal-statistic.trackers.delete.desc.number.text': 'رقم المتعقب',
    'personal-statistic.trackers.delete.desc.model.text': 'نموذج التعقب',
    'personal-statistic.trackers.update.type.text': 'تغيير المتعقب',
    'personal-statistic.trackers.update.desc.text': 'تغير التعقب',
    'personal-statistic.trackers.update.desc.field.text': 'الحقل',
    'personal-statistic.trackers.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.trackers.update.desc.new-value.text': 'القيمة الجديدة',

    // transport

    'personal-statistic.transport.create.type.text': 'إنشاء النقل',
    'personal-statistic.transport.create.desc.text': 'تم إنشاء النقل',
    'personal-statistic.transport.create.desc.brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.create.desc.model.text': 'نموذج',
    'personal-statistic.transport.create.desc.number.text': 'رقم التسجيل',
    'personal-statistic.transport.delete.type.text': 'حذف وسيلة نقل',
    'personal-statistic.transport.delete.desc.text': 'تم حذف وسيلة النقل',
    'personal-statistic.transport.delete.desc.brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.delete.desc.model.text': 'نموذج',
    'personal-statistic.transport.delete.desc.number.text': 'رقم التسجيل',
    'personal-statistic.transport.update.type.text': 'تغيير بيانات وسيلة النقل',
    'personal-statistic.transport.update.desc.text': 'تم تغيير بيانات وسيلة النقل',
    'personal-statistic.transport.update.desc.field.text': 'الحقل',
    'personal-statistic.transport.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.transport.update.desc.new-value.text': 'القيمة الجديدة',
    'personal-statistic.transport.assign-field.type.text': 'إنشاء حقل بيانات مخصص',
    'personal-statistic.transport.assign-field.desc.text': 'تم إنشاء حقل بيانات مخصص للنقل',
    'personal-statistic.transport.assign-field.desc.brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.assign-field.desc.model.text': 'نموذج',
    'personal-statistic.transport.assign-field.desc.number.text': 'رقم التسجيل',
    'personal-statistic.transport.assign-field.desc.field-name.text': 'اسم الحقل',
    'personal-statistic.transport.assign-field.desc.field-value.text': 'قيمة الحقل',
    'personal-statistic.transport.update-assign-field.type.text': 'تغيير أسماء وقيم الحقول المخصصة',
    'personal-statistic.transport.update-assign-field.desc.text': 'تم تغيير أسماء وقيم حقول الموظف المخصصة',
    'personal-statistic.transport.update-assign-field.desc.transport-brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.update-assign-field.desc.transport-model.text': 'نموذج',
    'personal-statistic.transport.update-assign-field.desc.transport-number.text': 'رقم التسجيل',
    'personal-statistic.transport.update-assign-field.desc.added.text': 'تمت إضافة الحقل',
    'personal-statistic.transport.update-assign-field.desc.deleted.text': 'تم حذف الحقل',
    'personal-statistic.transport.update-assign-field.desc.changed.text': 'تغير الحقل',
    'personal-statistic.transport.update-assign-field.desc.old.text': 'القديم',
    'personal-statistic.transport.update-assign-field.desc.new.text': 'الجديد',
    'personal-statistic.transport.update-assign-field.desc.label.text': 'اسم الحقل',
    'personal-statistic.transport.update-assign-field.desc.value.text': 'قيمة الحقل',
    'personal-statistic.transport.update-assign-field.desc.visible.text': 'رؤية الحقل',
    'personal-statistic.transport.assign-tracker.type.text': 'ربط المتعقب',
    'personal-statistic.transport.assign-tracker.desc.text': 'المتعقب مرتبط بوسيلة النقل',
    'personal-statistic.transport.assign-tracker.desc.brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.assign-tracker.desc.model.text': 'نموذج',
    'personal-statistic.transport.assign-tracker.desc.number.text': 'رقم التسجيل',
    'personal-statistic.transport.assign-tracker.desc.tracker-number.text': 'رقم المتعقب',
    'personal-statistic.transport.assign-tracker.desc.tracker-model.text': 'نموذج التعقب',
    'personal-statistic.transport.un-assign-tracker.type.text': 'فك ارتباط المتعقب',
    'personal-statistic.transport.un-assign-tracker.desc.text': 'المتعقب غير مرتبط بوسيلة النقل',
    'personal-statistic.transport.un-assign-tracker.desc.brand.text': 'ماركة وسيلة النقل',
    'personal-statistic.transport.un-assign-tracker.desc.model.text': 'نموذج',
    'personal-statistic.transport.un-assign-tracker.desc.number.text': 'رقم التسجيل',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text': 'رقم المتعقب',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text': 'نموذج التعقب',

    // user

    'personal-statistic.user.create.type.text': 'إنشاء مستخدم',
    'personal-statistic.user.create.desc.text': 'تم إنشاء مستخدم',
    'personal-statistic.user.create.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.user.create.desc.name.text': 'الاسم',
    'personal-statistic.user.delete.type.text': 'حذف مستخدم',
    'personal-statistic.user.delete.desc.text': 'تم حذف المستخدم',
    'personal-statistic.user.delete.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.user.delete.desc.name.text': 'الاسم',
    'personal-statistic.user.update.type.text': 'تغيير بيانات المستخدم',
    'personal-statistic.user.update.desc.text': 'تغيرت بيانات المستخدم',
    'personal-statistic.user.update.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.user.update.desc.field.text': 'الحقل',
    'personal-statistic.user.update.desc.old-value.text': 'القيمة القديمة',
    'personal-statistic.user.update.desc.new-value.text': 'القيمة الجديدة',
    'personal-statistic.user.assign-role.type.text': 'تعيين دور للمستخدم',
    'personal-statistic.user.assign-role.desc.text': 'تم تعيين الدور للمستخدم',
    'personal-statistic.user.assign-role.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.user.assign-role.desc.role-name.text': 'اسم الدور',
    'personal-statistic.user.un-assign-role.type.text': 'إزالة مستخدم من دور',
    'personal-statistic.user.un-assign-role.desc.text': 'تم حذف المستخدم من الدور',
    'personal-statistic.user.un-assign-role.desc.login.text': 'تسجيل الدخول',
    'personal-statistic.user.un-assign-role.desc.role-name.text': 'اسم الدور',
  },
  de: {
    'personal-statistic.no-data.text': 'keine Daten ',
    'personal-statistic.no.text': 'Nein',
    // auth

    'personal-statistic.auth.login-auth.type.text': 'Autorisierung des Benutzers',
    'personal-statistic.auth.login-auth.desc.text': 'Eingeloggt',
    'personal-statistic.auth.login-auth.desc.login.text': 'Benutzername',
    'personal-statistic.auth.login-auth.desc.name.text': 'Name',
    'personal-statistic.auth.login-auth.desc.ip.text': 'IP',

    // employee

    'personal-statistic.employee.create.type.text': 'Mitarbeiter erstellen',
    'personal-statistic.employee.create.desc.text': 'Mitarbeiter erstellt',
    'personal-statistic.employee.create.desc.name.text': 'Name',
    'personal-statistic.employee.create.desc.sim.text': 'SIM-Nummer',
    'personal-statistic.employee.delete.type.text': 'Mitarbeiter löschen',
    'personal-statistic.employee.delete.desc.text': 'Mitarbeiter gelöscht',
    'personal-statistic.employee.delete.desc.name.text': 'Name',
    'personal-statistic.employee.delete.desc.sim.text': 'SIM-Nummer',
    'personal-statistic.employee.update.type.text': 'Mitarbeiterdaten ändern',
    'personal-statistic.employee.update.desc.text': 'Mitarbeiterdaten geändert',
    'personal-statistic.employee.update.desc.field.text': 'Feld',
    'personal-statistic.employee.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.employee.update.desc.new-value.text': 'neuer Wert',
    'personal-statistic.employee.assign-field.type.text':
      'Erstellen eines benutzerdefinierten Datenfelds für einen Mitarbeiter',
    'personal-statistic.employee.assign-field.desc.text':
      'Für einen Mitarbeiter wurde ein benutzerdefiniertes Datenfeld erstellt',
    'personal-statistic.employee.assign-field.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.assign-field.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.assign-field.desc.field-name.text': 'Feldname',
    'personal-statistic.employee.assign-field.desc.field-value.text': 'Feldwert',
    'personal-statistic.employee.update-assign-field.type.text':
      'Ändern der Namen und Werte von benutzerdefinierten Feldern',
    'personal-statistic.employee.update-assign-field.desc.text':
      'Die Namen und Werte der benutzerdefinierten Mitarbeiterfelder wurden geändert',
    'personal-statistic.employee.update-assign-field.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.update-assign-field.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.update-assign-field.desc.added.text': 'Feld hinzugefügt',
    'personal-statistic.employee.update-assign-field.desc.deleted.text': 'Feld entfernt',
    'personal-statistic.employee.update-assign-field.desc.changed.text': 'Feld geändert',
    'personal-statistic.employee.update-assign-field.desc.old.text': 'alter',
    'personal-statistic.employee.update-assign-field.desc.new.text': 'neuer',
    'personal-statistic.employee.update-assign-field.desc.label.text': 'Feldname',
    'personal-statistic.employee.update-assign-field.desc.value.text': 'Feldwert',
    'personal-statistic.employee.update-assign-field.desc.visible.text': 'Feldsichtbarkeit',
    'personal-statistic.employee.assign-work-object.type.text': 'Hinzufügen zu einer Objektgruppe',
    'personal-statistic.employee.assign-work-object.desc.text': 'Mitarbeiter zur Objektgruppe hinzugefügt',
    'personal-statistic.employee.assign-work-object.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.assign-work-object.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.assign-work-object.desc.work-obj-name.text': 'Name des Arbeitsobjekts',
    'personal-statistic.employee.un-assign-work-object.type.text': 'Entfernen aus einer Gruppe von Objekten',
    'personal-statistic.employee.un-assign-work-object.desc.text': 'Mitarbeiter aus Objektgruppe entfernt',
    'personal-statistic.employee.un-assign-work-object.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.un-assign-work-object.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text': 'Name des Arbeitsobjekts',
    'personal-statistic.employee.assign-tracker.type.text': 'Tracker an einen Mitarbeiter binden',
    'personal-statistic.employee.assign-tracker.desc.text': 'Der Tracker ist an einen Mitarbeiter gebunden',
    'personal-statistic.employee.assign-tracker.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.assign-tracker.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.assign-tracker.desc.tracker-number.text': 'Tracker-Nummer',
    'personal-statistic.employee.assign-tracker.desc.tracker-model.text': 'Tracker-Modell',
    'personal-statistic.employee.un-assign-tracker.type.text': 'Tracker von einem Mitarbeiter abbinden',
    'personal-statistic.employee.un-assign-tracker.desc.text': 'Der Tracker ist vom Mitarbeiter abgebunden',
    'personal-statistic.employee.un-assign-tracker.desc.employee-name.text': 'Mitarbeitername',
    'personal-statistic.employee.un-assign-tracker.desc.employee-sim.text': 'SIM-Nummer',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text': 'Tracker-Nummer',
    'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text': 'Tracker-Modell',

    // geozones

    'personal-statistic.geozones.create.type.text': 'Geofence erstellen',
    'personal-statistic.geozones.create.desc.text': 'Geofence erstellt',
    'personal-statistic.geozones.create.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozones.create.desc.geometric-type.text': 'Geometrietyp',
    'personal-statistic.geozones.create.desc.gezone-type.text': 'Geofence-Typ',
    'personal-statistic.geozones.delete.type.text': 'Geofence löschen',
    'personal-statistic.geozones.delete.desc.text': 'Geofence gelöscht',
    'personal-statistic.geozones.delete.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozones.delete.desc.geometric-type.text': 'Geometrietyp',
    'personal-statistic.geozones.delete.desc.gezone-type.text': 'Geofence-Typ',
    'personal-statistic.geozones.update.type.text': 'Geofence ändern',
    'personal-statistic.geozones.update.desc.text': 'Geofence-Daten geändert',
    'personal-statistic.geozones.update.desc.field.text': 'Feld',
    'personal-statistic.geozones.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.geozones.update.desc.new-value.text': 'neuer Wert',
    'personal-statistic.geozone-groups.create.type.text': 'Geofence-Gruppe erstellen',
    'personal-statistic.geozone-groups.create.desc.text': 'Geofence-Gruppe erstellt',
    'personal-statistic.geozone-groups.create.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozone-groups.create.desc.geometric-type.text': 'Elterngruppe',
    'personal-statistic.geozone-groups.delete.type.text': 'Geofence-Gruppe löschen',
    'personal-statistic.geozone-groups.delete.desc.text': 'Geofence-Gruppe gelöscht',
    'personal-statistic.geozone-groups.delete.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozone-groups.delete.desc.geometric-type.text': 'Elterngruppe',
    'personal-statistic.geozone-groups.update.type.text': 'Ändern einer Geofence-Gruppe',
    'personal-statistic.geozone-groups.update.desc.text': 'Geofence-Gruppendaten geändert',
    'personal-statistic.geozone-groups.update.desc.field.text': 'Feld',
    'personal-statistic.geozone-groups.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.geozone-groups.update.desc.new-value.text': 'neuer Wert',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text':
      'Geofence zu einer Geofence-Gruppe hinzufügen',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text':
      'Geofence zur Geofence-Gruppe hinzugefügt',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text': 'Gruppe',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text':
      'Geofence aus einer Geofence-Gruppe löschen',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text':
      'Geofence aus der Geofence-Gruppe gelöscht',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text': 'Geofence-Name',
    'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text': 'Gruppe',

    // handbooks

    'personal-statistic.handbooks.create.type.text': 'Element im Verzeichnis erstellen',
    'personal-statistic.handbooks.create.desc.text': 'Ein neues Element im Verzeichnis erstellt',
    'personal-statistic.handbooks.create.desc.type.text': 'Verzeichnistyp',
    'personal-statistic.handbooks.create.desc.name.text': 'Elementname',
    'personal-statistic.handbooks.update.type.text': 'Elementdaten im Verzeichnis ändern',
    'personal-statistic.handbooks.update.desc.text': 'Element im Verzeichnis geändert',
    'personal-statistic.handbooks.update.desc.type.text': 'Verzeichnistyp',
    'personal-statistic.handbooks.update.desc.field.text': 'Feld',
    'personal-statistic.handbooks.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.handbooks.update.desc.new-value.text': 'neuer Wert',

    // notifications

    'personal-statistic.notifications.added.type.text': 'Benachrichtigung ausgelöst',
    'personal-statistic.notifications.added.desc.text': 'Benachrichtigung ausgelöst',
    'personal-statistic.notifications.added.desc.type.text': 'Benachrichtigungstyp',
    'personal-statistic.notifications.added.desc.text.text': 'Benachrichtigungstext',

    // poi

    'personal-statistic.poi.create.type.text': 'POI erstellen',
    'personal-statistic.poi.create.desc.text': 'POI erstellt',
    'personal-statistic.poi.create.desc.name.text': 'POI-Name',
    'personal-statistic.poi.delete.type.text': 'POI löschen',
    'personal-statistic.poi.delete.desc.text': 'POI gelöscht',
    'personal-statistic.poi.delete.desc.name.text': 'POI-Name',
    'personal-statistic.poi.update.type.text': 'POI ändern',
    'personal-statistic.poi.update.desc.text': 'POI-Daten geändert',
    'personal-statistic.poi.update.desc.field.text': 'Feld',
    'personal-statistic.poi.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.poi.update.desc.new-value.text': 'neuer Wert',

    // report

    'personal-statistic.report-template.create.type.text': 'Berichtsvorlage erstellen',
    'personal-statistic.report-template.create.desc.text': 'Berichtsvorlage erstellt',
    'personal-statistic.report-template.create.desc.name.text': 'Vorlagenname',
    'personal-statistic.report-template.delete.type.text': 'Berichtsvorlage löschen',
    'personal-statistic.report-template.delete.desc.text': 'Berichtsvorlage gelöscht',
    'personal-statistic.report-template.delete.desc.name.text': 'Vorlagenname',
    'personal-statistic.report-template.update.type.text': 'Berichtsvorlagendaten ändern',
    'personal-statistic.report-template.update.desc.text': 'Berichtsvorlagendaten geändert',
    'personal-statistic.report-template.update.desc.name.text': 'Vorlagenname',
    'personal-statistic.report-template.create-report.type.text': 'Einen Bericht aus einer Vorlage erstellen',
    'personal-statistic.report-template.create-report.desc.text': 'Einen Bericht aus einer Vorlage erstellt',
    'personal-statistic.report-template.create-report.desc.name.text': 'Vorlagenname',
    'personal-statistic.report-template.create-report.desc.number.text': 'Berichtsnummer',

    // roles

    'personal-statistic.role.create.type.text': 'Rolle erstellen ',
    'personal-statistic.role.create.desc.text': 'Rolle erstellt',
    'personal-statistic.role.create.desc.name.text': 'Rollenname',
    'personal-statistic.role.delete.type.text': 'Rolle löschen',
    'personal-statistic.role.delete.desc.text': 'Rolle gelöscht',
    'personal-statistic.role.delete.desc.name.text': 'Rollenname',
    'personal-statistic.role.update.type.text': 'Rolle ändern',
    'personal-statistic.role.update.desc.text': 'Rolle geändert',
    'personal-statistic.role.update.desc.name.text': 'Rollenname',
    'personal-statistic.role.update.desc.field.text': 'Feld',
    'personal-statistic.role.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.role.update.desc.new-value.text': 'neuer Wert',

    // trackers

    'personal-statistic.trackers.create.type.text': 'Tracker erstellen',
    'personal-statistic.trackers.create.desc.text': 'Tracker erstellt',
    'personal-statistic.trackers.create.desc.number.text': 'Tracker-Nummer',
    'personal-statistic.trackers.create.desc.model.text': 'Tracker-Modell',
    'personal-statistic.trackers.delete.type.text': 'Tracker löschen',
    'personal-statistic.trackers.delete.desc.text': 'Tracker gelöscht',
    'personal-statistic.trackers.delete.desc.number.text': 'Tracker-Nummer',
    'personal-statistic.trackers.delete.desc.model.text': 'Tracker-Modell',
    'personal-statistic.trackers.update.type.text': 'Tracker ändern',
    'personal-statistic.trackers.update.desc.text': 'Tracker geändert',
    'personal-statistic.trackers.update.desc.field.text': 'Feld',
    'personal-statistic.trackers.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.trackers.update.desc.new-value.text': 'neuer Wert',

    // transport

    'personal-statistic.transport.create.type.text': 'Transport erstellen',
    'personal-statistic.transport.create.desc.text': 'Transport erstellt',
    'personal-statistic.transport.create.desc.brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.create.desc.model.text': 'Modell',
    'personal-statistic.transport.create.desc.number.text': 'Registrierungsnummer',
    'personal-statistic.transport.delete.type.text': 'Transport löschen',
    'personal-statistic.transport.delete.desc.text': 'Transport gelöscht',
    'personal-statistic.transport.delete.desc.brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.delete.desc.model.text': 'Modell',
    'personal-statistic.transport.delete.desc.number.text': 'Registrierungsnummer',
    'personal-statistic.transport.update.type.text': 'Transportdaten ändern',
    'personal-statistic.transport.update.desc.text': 'Transportdaten geändert',
    'personal-statistic.transport.update.desc.field.text': 'Feld',
    'personal-statistic.transport.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.transport.update.desc.new-value.text': 'neuer Wert',
    'personal-statistic.transport.assign-field.type.text': ' Benutzerdefiniertes Datenfeld erstellen',
    'personal-statistic.transport.assign-field.desc.text': 'Benutzerdefiniertes Datenfeld für den Transport erstellt',
    'personal-statistic.transport.assign-field.desc.brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.assign-field.desc.model.text': 'Modell',
    'personal-statistic.transport.assign-field.desc.number.text': 'Registrierungsnummer',
    'personal-statistic.transport.assign-field.desc.field-name.text': 'Feldname',
    'personal-statistic.transport.assign-field.desc.field-value.text': 'Feldwert',
    'personal-statistic.transport.update-assign-field.type.text':
      'Namen und Werte von benutzerdefinierten Feldern ändern',
    'personal-statistic.transport.update-assign-field.desc.text':
      'Namen und Werte der benutzerdefinierten Mitarbeiterfelder wurden geändert',
    'personal-statistic.transport.update-assign-field.desc.transport-brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.update-assign-field.desc.transport-model.text': 'Modell',
    'personal-statistic.transport.update-assign-field.desc.transport-number.text': 'Registrierungsnummer',
    'personal-statistic.transport.update-assign-field.desc.added.text': 'Feld hinzugefügt',
    'personal-statistic.transport.update-assign-field.desc.deleted.text': 'Feld entfernt',
    'personal-statistic.transport.update-assign-field.desc.changed.text': 'Feld geändert',
    'personal-statistic.transport.update-assign-field.desc.old.text': 'alt',
    'personal-statistic.transport.update-assign-field.desc.new.text': 'Neu',
    'personal-statistic.transport.update-assign-field.desc.label.text': 'Feldname',
    'personal-statistic.transport.update-assign-field.desc.value.text': 'Feldwert',
    'personal-statistic.transport.update-assign-field.desc.visible.text': 'Feldsichtbarkeit',
    'personal-statistic.transport.assign-tracker.type.text': 'Tracker binden ',
    'personal-statistic.transport.assign-tracker.desc.text': 'Tracker an das Transport gebunden ',
    'personal-statistic.transport.assign-tracker.desc.brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.assign-tracker.desc.model.text': 'Modell',
    'personal-statistic.transport.assign-tracker.desc.number.text': 'Registrierungsnummer',
    'personal-statistic.transport.assign-tracker.desc.tracker-number.text': 'Tracker-Nummer',
    'personal-statistic.transport.assign-tracker.desc.tracker-model.text': 'Tracker-Modell',
    'personal-statistic.transport.un-assign-tracker.type.text': 'Tracker binden ab',
    'personal-statistic.transport.un-assign-tracker.desc.text': 'Der Tracker ist vom Transport abgebunden',
    'personal-statistic.transport.un-assign-tracker.desc.brand.text': 'Fahrzeugmarke',
    'personal-statistic.transport.un-assign-tracker.desc.model.text': 'Modell',
    'personal-statistic.transport.un-assign-tracker.desc.number.text': 'Registrierungsnummer',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text': 'Tracker-Nummer',
    'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text': 'Tracker-Modell',

    // user

    'personal-statistic.user.create.type.text': 'Benutzer erstellen',
    'personal-statistic.user.create.desc.text': 'Benutzer erstellt',
    'personal-statistic.user.create.desc.login.text': 'Benutzername',
    'personal-statistic.user.create.desc.name.text': 'Name',
    'personal-statistic.user.delete.type.text': 'Benutzer löschen',
    'personal-statistic.user.delete.desc.text': 'Benutzer gelöscht',
    'personal-statistic.user.delete.desc.login.text': 'Benutzername',
    'personal-statistic.user.delete.desc.name.text': 'Name',
    'personal-statistic.user.update.type.text': 'Benutzerdaten ändern',
    'personal-statistic.user.update.desc.text': 'Benutzerdaten geändert',
    'personal-statistic.user.update.desc.login.text': 'Benutzername',
    'personal-statistic.user.update.desc.field.text': 'Feld',
    'personal-statistic.user.update.desc.old-value.text': 'alter Wert',
    'personal-statistic.user.update.desc.new-value.text': 'neuer Wert',
    'personal-statistic.user.assign-role.type.text': 'Einem Benutzer eine Rolle zuweisen',
    'personal-statistic.user.assign-role.desc.text': 'Rolle einem Benutzer zugewiesen',
    'personal-statistic.user.assign-role.desc.login.text': 'Benutzername',
    'personal-statistic.user.assign-role.desc.role-name.text': 'Rollenname',
    'personal-statistic.user.un-assign-role.type.text': 'Benutzers von einer Rolle ausschliessen',
    'personal-statistic.user.un-assign-role.desc.text': 'Benutzer von der Rolle ausgeschlossen',
    'personal-statistic.user.un-assign-role.desc.login.text': 'Benutzername',
    'personal-statistic.user.un-assign-role.desc.role-name.text': 'Rollenname',
  },
};
