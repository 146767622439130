import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as EditIcon } from 'assets/img/gear.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';

import { RootState } from 'reducers';
import { toggleCheckedNotification } from 'reducers/notifications';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Switch from 'components/common/switch/switch';

import AccessEntity from 'utils/accessEntity';

import styles from './notificationItem.module.scss';

const cx = classNames.bind(styles);

type NotificationItemProps = {
  handleChange?: () => void;
  handleTurn?: () => void;
  paddingLeft?: number;
  isActive: boolean;
  checked: boolean;
  text: string;
  numberOfNotified: string;
  numberOfUnits: string;
  notificationId: string;
  disabledTurn: boolean;
  withDeleteIcon?: boolean;
};

export const NotificationItem = ({
  handleChange,
  handleTurn,
  paddingLeft = 0,
  isActive,
  checked,
  text,
  numberOfNotified,
  numberOfUnits,
  notificationId,
  disabledTurn,
  withDeleteIcon,
}: NotificationItemProps) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const notificationsPermissions = useSelector((state: RootState) => state.user.permissions.notifications);
  const notificationsAccess = useMemo(() => new AccessEntity(notificationsPermissions), [notificationsPermissions]);

  const notifiedCounts = Number(numberOfNotified) || 0;
  const numberOfUnitsCounts = Number(numberOfUnits) || 0;

  return (
    <div className={styles.notificationItem} style={{ paddingLeft: `${paddingLeft}px` }}>
      <div className={styles.notificationItemInfo}>
        <div className={styles.notificationItemCheckbox}>
          <Checkbox checked={checked} handleCheck={() => dispatch(toggleCheckedNotification(notificationId))} />
        </div>
        <div className={styles.notificationItemName}>{text}</div>
      </div>
      <div className={cx(styles.notificationButtons, styles.notificationButtonsItem)}>
        <div className={styles.notificationButtonsIcon}>
          <Switch checked={isActive} onChange={handleTurn} disabled={disabledTurn} />
        </div>
        <div className={styles.notificationButtonsIcon} title={notifiedCounts > 999 ? String(notifiedCounts) : ''}>
          {notifiedCounts > 999 ? '999+' : notifiedCounts}
        </div>
        <div
          className={styles.notificationButtonsIcon}
          title={numberOfUnitsCounts > 999 ? String(numberOfUnitsCounts) : ''}
        >
          {numberOfUnitsCounts > 999 ? '999+' : numberOfUnitsCounts}
        </div>
        <div
          className={cx(styles.notificationButtonsIcon, {
            [styles.notificationButtonsIconDisabled]: !notificationsAccess.isAllowUpdate(),
          })}
          onClick={handleChange}
        >
          {withDeleteIcon && (
            <>
              <DeleteIcon data-tip data-for="removeTestNotify" />
              <ReactTooltip
                className={styles.customTooltip}
                id="removeTestNotify"
                place="right"
                type="light"
                effect="solid"
              >
                <span>{t('notifications.list.item.tooltip.testNotification.remove.text')}</span>
              </ReactTooltip>
            </>
          )}
          {!withDeleteIcon && (
            <>
              <EditIcon data-tip data-for="editNotify" />
              {notificationsAccess.isAllowUpdate() && (
                <ReactTooltip
                  className={styles.customTooltip}
                  id="editNotify"
                  place="right"
                  type="light"
                  effect="solid"
                >
                  <span>{t('notifications.list.item.tooltip.settings.text')}</span>
                </ReactTooltip>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
