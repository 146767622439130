import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LANGUAGES_TO_TEXT_ENUM, LANGUAGES_TO_TEXT_TRANSLATES_ENUM } from 'translate';
import { CommonSettingsValues } from '../utils/types';

export function useCommonSettings() {
  const { userPreferences } = useSelector((state: RootState) => state.user);

  const data: CommonSettingsValues = {
    locale: userPreferences.locale,
    metricSystem: userPreferences.metricSystem,
    timezone: userPreferences.timezone,
    dateFormat: userPreferences.dateFormat,
    timeFormat: userPreferences.timeFormat,
    gpsFormat: userPreferences.gpsFormat,
  };

  const [locale, setInterfaceLanguage] = useState(data.locale);
  const [timezone, setTimezone] = useState(data.timezone);

  const metricSystem = data.metricSystem;
  const dateFormat = data.dateFormat;
  const timeFormat = data.timeFormat;
  const gpsFormat = data.gpsFormat;

  const interfaceLanguageForSelect = Object.keys(LANGUAGES_TO_TEXT_ENUM).map(lngKey => ({
    value: lngKey,
    label: LANGUAGES_TO_TEXT_TRANSLATES_ENUM[lngKey as keyof typeof LANGUAGES_TO_TEXT_TRANSLATES_ENUM],
  }));

  const handleChangeLng = (value: string) => {
    const foundLngOption = interfaceLanguageForSelect.find(option => option.value === value);

    if (foundLngOption) {
      setInterfaceLanguage(foundLngOption.value);
    }
  };

  const values: CommonSettingsValues = {
    locale,
    metricSystem,
    timezone,
    dateFormat,
    timeFormat,
    gpsFormat,
  };

  const additionalValues = {
    interfaceLanguageForSelect,
  };

  const handlers = {
    handleChangeLng,
    handleChangeTimezone: setTimezone,
  };

  const findUniqueValues = () => {
    const res: { [key: string]: string } = {};

    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key as keyof CommonSettingsValues] !== values[key as keyof CommonSettingsValues]
      ) {
        res[key] = values[key as keyof CommonSettingsValues];
      }
    }

    return res;
  };

  return { values, findUniqueValues, additionalValues, handlers };
}
