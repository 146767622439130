import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';

import { GeozoneConditionsFixingByType } from 'components/notifications/utils/types';
import {
  defaultConditions,
  NOTIFICATION_CONDITION_GEOZONE_FIXING_BY,
  NOTIFICATION_GEOZONE_ENTITY,
} from 'components/notifications/utils/consts';
import {
  MultipleSelectGroupOption,
  MultipleSelectGroupTranslate,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';

import { RootState } from 'reducers';
import { ConditionsType, GeozoneConditions } from 'reducers/notifications/interface';

import AccessEntity from 'utils/accessEntity';

const { geozone: defaults } = defaultConditions;

type Params = {
  isFoulFixing: boolean;
  setIsFoulFixing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useGeozoneInfo(params: Params) {
  const t = useFormatMessage();

  const geozonesPermission = useSelector((state: RootState) => state.user.permissions.geozones);
  const geozonesAccess = useMemo(() => new AccessEntity(geozonesPermission), [geozonesPermission]);
  const { geozonesShort, groups: geozoneGroups } = useSelector((state: RootState) => state.geozone);
  const { chosenNotification, chosenNotificationType } = useSelector((state: RootState) => state.notifications);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [fixingBy, setFixingBy] = useState(defaults.fixingBy);
  const [fixingTimeLimit, setFixingTimeLimit] = useState(defaults.fixingTimeLimit);
  const [geozoneEntities, setGeozoneEntities] = useState(defaults.geozones);
  const [geozoneStandingLimit, setGeozoneStandingLimit] = useState(defaults.geozoneStandingLimit);

  const [geozoneEntitiesTranslatesForSelect] = useState<MultipleSelectGroupTranslate[]>(
    Object.keys(NOTIFICATION_GEOZONE_ENTITY).map(key => ({
      name: key,
      translate: t(
        `notifications.card.tabs.conditions.field.geozone.geozone-list.translate.${NOTIFICATION_GEOZONE_ENTITY[
          key as keyof typeof NOTIFICATION_GEOZONE_ENTITY
        ].toLowerCase()}.text`
      ),
    }))
  );

  const geozoneEntitiesForSelect: MultipleSelectGroupOption[] = useMemo(() => {
    if (!geozonesAccess.isAllowRead()) {
      return [];
    }

    return [
      {
        name: NOTIFICATION_GEOZONE_ENTITY.GeozoneGroup,
        options: geozoneGroups.map(group => ({
          value: group.id,
          label: group.attributes.name,
          data: { ...group },
        })),
      },
      {
        name: NOTIFICATION_GEOZONE_ENTITY.Geozone,
        options: geozonesShort.map(geozone => ({
          value: geozone.id,
          label: geozone.attributes.name,
          data: { ...geozone },
        })),
      },
    ];
  }, [geozonesAccess, geozonesShort, geozoneGroups]);

  const geozoneStandingLimitRef = React.createRef();
  const fixingTimeLimitRef = React.createRef();

  useEffect(() => {
    let conditions: ConditionsType = null;

    if (chosenNotification && chosenNotificationType === chosenNotification.attributes.notificationType) {
      conditions = chosenNotification.attributes.conditions as GeozoneConditions;
    } else {
      conditions = defaults;
    }
    setFixingBy(conditions.fixingBy);
    setFixingTimeLimit(conditions.fixingTimeLimit);
    setGeozoneEntities(conditions.geozones);
    setGeozoneStandingLimit(conditions.geozoneStandingLimit);
  }, [chosenNotification, chosenNotificationType]);

  const handleCheckedFoulFixing = () => params.setIsFoulFixing(!params.isFoulFixing);
  const handleCheckedFixingBy = (type: GeozoneConditionsFixingByType) => setFixingBy(type);

  const handleChangeLocationLimitValue = (date: Date) => setGeozoneStandingLimit(date.getMinutes() * 60);
  const handleChangeFixingTimeLimitValue = (date: Date) => setFixingTimeLimit(date.getMinutes() * 60);

  const handleChangeGeozones = (values: MultipleSelectGroupOption[]) => {
    const newValues = values.map(groupValue => ({
      type: groupValue.name as NOTIFICATION_GEOZONE_ENTITY,
      entityIds: Array.from(new Set(groupValue.options.map(option => option.data?.id as string).filter(id => id))),
    }));

    setGeozoneEntities(newValues);
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!geozoneEntities.length) {
      newInvalidFields.push('geozoneEntities');
    }
    if (
      fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.longStaying &&
      (geozoneStandingLimit < defaults.geozoneStandingLimit ||
        (params.isFoulFixing && geozoneStandingLimit >= fixingTimeLimit))
    ) {
      newInvalidFields.push('longStayingGeozones');
    }
    if (
      fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.longStaying &&
      params.isFoulFixing &&
      geozoneStandingLimit >= fixingTimeLimit
    ) {
      toast.error(t('toast.notifications.conditions.geozone.less-limit-time.error.text'));
    }
    if (params.isFoulFixing && fixingTimeLimit < defaults.fixingTimeLimit) {
      newInvalidFields.push('foulFixingGeozones');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    const currentGeozoneConditions = (chosenNotification?.attributes.conditions as GeozoneConditions) || defaults;
    if (
      fixingBy !== currentGeozoneConditions.fixingBy ||
      fixingTimeLimit !== currentGeozoneConditions.fixingTimeLimit ||
      !isEqual(geozoneEntities, currentGeozoneConditions.geozones) ||
      geozoneStandingLimit !== currentGeozoneConditions.geozoneStandingLimit
    ) {
      return true;
    }
    return false;
  };

  return {
    states: {
      fixingBy,
      fixingTimeLimit,
      geozoneEntities,
      geozoneStandingLimit,
      geozoneEntitiesForSelect,
      geozoneEntitiesTranslatesForSelect,
    },
    handlers: {
      handleChangeGeozones,
      handleCheckedFoulFixing,
      handleCheckedFixingBy,
      handleChangeLocationLimitValue,
      handleChangeFixingTimeLimitValue,
    },
    refs: {
      geozoneStandingLimitRef,
      fixingTimeLimitRef,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
