import React, { Fragment, useState, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TurnAllOn } from 'assets/img/notifications/turn_on.svg';
import { ReactComponent as Quantity } from 'assets/img/notifications/refresh.svg';
import { ReactComponent as People } from 'assets/img/notifications/people.svg';
import { ReactComponent as EditIconHead } from 'assets/img/tool.svg';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { NotificationSortingType } from 'reducers/notifications/interface';
import { NOTIFICATION_SORTING_BY } from 'components/notifications/utils/consts';
import {
  turnAllNotificationsInList,
  toggleCheckedAllNotificationGroups,
  setSort,
  toggleExpandAllNotificationGroups,
} from 'reducers/notifications';
import { showModal } from 'reducers/modal';
import { RootState } from 'reducers';
import { NOTIFICATIONS_GROUPING } from 'utils/consts';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import { ButtonIconSorting } from './components/buttonIconSorting/buttonIconSorting';
import classNames from 'classnames/bind';
import AccessEntity from 'utils/accessEntity';
import styles from './listHeader.module.scss';

const cx = classNames.bind(styles);

type Props = {
  showExpand: boolean;
};

const { name, triggered, objects } = NOTIFICATION_SORTING_BY;

export function ListHeader({ showExpand }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const {
    groups: groupsStore,
    selectedNotifications,
    notifications,
  } = useSelector((state: RootState) => state.notifications);
  const notificationsPermissions = useSelector((state: RootState) => state.user.permissions.notifications);
  const notificationsAccess = useMemo(() => new AccessEntity(notificationsPermissions), [notificationsPermissions]);

  const [turnAll, setTurnAll] = useState(notifications.every(notification => notification.attributes.isActive));

  const headCheckboxStatus = selectedNotifications.length === notifications.length && notifications.length > 0;
  const headCheckboxStatusIndeterminate =
    selectedNotifications.length > 0 && selectedNotifications.length < notifications.length;

  const handleTurnOnAll = () => {
    if (notificationsAccess.isAllowUpdate()) {
      const newTurnAll = !turnAll;

      dispatch(turnAllNotificationsInList(newTurnAll));
      setTurnAll(newTurnAll);
    }
  };

  const handleCheckAllNotifications = () => {
    if (headCheckboxStatus || groupsStore.every(group => group.checked)) {
      dispatch(toggleCheckedAllNotificationGroups(!headCheckboxStatus));
    } else {
      dispatch(toggleCheckedAllNotificationGroups(true));
    }
  };

  const handleExpandAll = () => {
    const isNotEveryExpanded = !groupsStore.every(group => group?.expanded);
    const isSomeExpanded = groupsStore.some(group => group?.expanded);

    if ((isSomeExpanded && isNotEveryExpanded) || isNotEveryExpanded) {
      dispatch(toggleExpandAllNotificationGroups(true));
    } else {
      dispatch(toggleExpandAllNotificationGroups(false));
    }
  };

  const handleSort = (type: NotificationSortingType) => {
    dispatch(setSort(type));
  };

  return (
    <Fragment>
      <div className={cx(styles.notificationWrapper, styles.notificationListHeader)}>
        <div className={styles.notificationInfo}>
          {showExpand && (
            <ExpandButton
              isOpen={groupsStore.every(group => group.expanded)}
              onClick={handleExpandAll}
              style={styles.notificationExpand}
            />
          )}
          <Checkbox
            handleCheck={handleCheckAllNotifications}
            checked={headCheckboxStatus}
            isIndeterminate={headCheckboxStatusIndeterminate}
          />
          <div className={styles.nameHead}>
            <ButtonIconSorting typeSorting={name} onClick={() => handleSort(name)}>
              <div>{t('notifications.list.header.notification.label')}</div>
            </ButtonIconSorting>
          </div>
        </div>
        <div className={styles.headerButtons}>
          <div
            className={cx(styles.headerButtonsIcon, {
              [styles.headerButtonsIconDisabled]: !notificationsAccess.isAllowUpdate(),
            })}
            onClick={handleTurnOnAll}
          >
            <TurnAllOn data-tip data-for="turnAllOn" />
            {notificationsAccess.isAllowUpdate() && (
              <ReactTooltip className={styles.customTooltip} id="turnAllOn" place="right" type="light" effect="solid">
                <span>{t('notifications.list.header.tooltip.activate.text')}</span>
              </ReactTooltip>
            )}
          </div>
          <div className={styles.headerButtonsIcon}>
            <ButtonIconSorting
              typeSorting={triggered}
              onClick={() => handleSort(triggered)}
              customArrowStyle={styles.headerButtonsIconArrow}
            >
              <Quantity data-tip data-for="triggered" />
            </ButtonIconSorting>
            <ReactTooltip className={styles.customTooltip} id="triggered" place="right" type="light" effect="solid">
              <span>{t('notifications.list.header.tooltip.triggered.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon}>
            <ButtonIconSorting
              typeSorting={objects}
              onClick={() => handleSort(objects)}
              customArrowStyle={styles.headerButtonsIconArrow}
            >
              <People data-tip data-for="objects" />
            </ButtonIconSorting>
            <ReactTooltip className={styles.customTooltip} id="objects" place="right" type="light" effect="solid">
              <span>{t('notifications.list.header.tooltip.objects.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon}>
            <EditIconHead
              onClick={() => dispatch(showModal({ type: NOTIFICATIONS_GROUPING, which: '' }))}
              data-tip
              data-for="settings"
            />
            <ReactTooltip className={styles.customTooltip} id="settings" place="right" type="light" effect="solid">
              <span>{t('notifications.list.header.tooltip.settings.text')}</span>
            </ReactTooltip>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
