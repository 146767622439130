export default {
  en: {
    'map-popup.poi.header.text': 'POI card:',
    'map-popup.poi.header.videocamera.text': 'Camera',
    'map-popup.poi.header.gazanalyzer.text': 'Gazanalyzer',
    'map-popup.poi.header.sensor.text': 'Sensor',
    'map-popup.poi.header.equipment.text': 'Equipment',
    'map-popup.poi.header.accident.text': 'Accident',
    'map-popup.poi.header.free_point.text': 'Free point',

    'map-popup.poi.main.free-point.text': 'Free point:',
    'map-popup.poi.main.current-position.text': 'Location:',
    'map-popup.poi.main.description.text': 'Description:',
    'map-popup.poi.main.date-last.text': 'Date of last check:',
    'map-popup.poi.main.date-next.text': 'Date of next check:',

    'map-popup.poi.videocamera.video-stream.text': 'Video stream',

    'map-popup.poi.sensor.indication.text': 'Indication',
    'map-popup.poi.sensor.temperature.text': 'Temperature',
    'map-popup.poi.sensor.notifications.text': 'Notifications',

    'map-popup.poi.incident.notifications.text': 'Notifications',

    'map-popup.poi.gaz-analyzer.indications.text': 'Indications',
    'map-popup.poi.gaz-analyzer.date.text': 'Analysis date and time:',
    'map-popup.poi.gaz-analyzer.notifications.text': 'Notifications',

    'map-popup.poi.free-point.notifications.text': 'Notifications',

    'map-popup.poi.equipment.notifications.text': 'Notifications',
  },
  ru: {
    'map-popup.poi.header.text': 'Карточка POI точки:',
    'map-popup.poi.header.videocamera.text': 'Видеокамера',
    'map-popup.poi.header.gazanalyzer.text': 'Газанализатор',
    'map-popup.poi.header.sensor.text': 'Датчик',
    'map-popup.poi.header.equipment.text': 'Оснащение',
    'map-popup.poi.header.accident.text': 'Происшествие',
    'map-popup.poi.header.free_point.text': 'Свободная точка',

    'map-popup.poi.main.free-point.text': 'Свободная точка:',
    'map-popup.poi.main.current-position.text': 'Местоположение:',
    'map-popup.poi.main.description.text': 'Описание:',
    'map-popup.poi.main.date-last.text': 'Дата последней поверки:',
    'map-popup.poi.main.date-next.text': 'Дата следующей поверки:',

    'map-popup.poi.videocamera.video-stream.text': 'Видеопоток',

    'map-popup.poi.sensor.indication.text': 'Показания',
    'map-popup.poi.sensor.temperature.text': 'Температура',
    'map-popup.poi.sensor.notifications.text': 'Уведомления',

    'map-popup.poi.incident.notifications.text': 'Уведомления',

    'map-popup.poi.gaz-analyzer.indications.text': 'Показания',
    'map-popup.poi.gaz-analyzer.date.text': 'Дата и время анализа:',
    'map-popup.poi.gaz-analyzer.notifications.text': 'Уведомления',

    'map-popup.poi.free-point.notifications.text': 'Уведомления',

    'map-popup.poi.equipment.notifications.text': 'Уведомления',
  },
  zh: {
    'map-popup.poi.header.text': '興趣點卡：',
    'map-popup.poi.header.videocamera.text': '錄影機',
    'map-popup.poi.header.gazanalyzer.text': '氣體分析儀',
    'map-popup.poi.header.sensor.text': '傳感器',
    'map-popup.poi.header.equipment.text': '設備',
    'map-popup.poi.header.accident.text': '事件',
    'map-popup.poi.header.free_point.text': '自由點',
    'map-popup.poi.main.free-point.text': '自由点',
    'map-popup.poi.main.current-position.text': '地点：',
    'map-popup.poi.main.description.text': '描述：',
    'map-popup.poi.main.date-last.text': '上次检查日期：',
    'map-popup.poi.main.date-next.text': '下次检查日期：',
    'map-popup.poi.videocamera.video-stream.text': '视频广播',
    'map-popup.poi.sensor.indication.text': '指示',
    'map-popup.poi.sensor.temperature.text': '温度',
    'map-popup.poi.sensor.notifications.text': '通知',
    'map-popup.poi.incident.notifications.text': '通知',
    'map-popup.poi.gaz-analyzer.indications.text': '适应症',
    'map-popup.poi.gaz-analyzer.date.text': '分析日期和时间：',
    'map-popup.poi.gaz-analyzer.notifications.text': '通知',
    'map-popup.poi.free-point.notifications.text': '通知',
    'map-popup.poi.equipment.notifications.text': '通知',
  },
  ar: {
    'map-popup.poi.header.text': 'بطاقة POI',
    'map-popup.poi.header.videocamera.text': 'كاميرا فيديو',
    'map-popup.poi.header.gazanalyzer.text': 'محلل الغاز',
    'map-popup.poi.header.sensor.text': 'المستشعر',
    'map-popup.poi.header.equipment.text': 'معدات',
    'map-popup.poi.header.accident.text': 'حادث',
    'map-popup.poi.header.free_point.text': 'نقطة حرة',
    'map-popup.poi.main.free-point.text': 'نقطة حرة:',
    'map-popup.poi.main.current-position.text': 'المكان:',
    'map-popup.poi.main.description.text': 'الوصف:',
    'map-popup.poi.main.date-last.text': 'تاريخ التفقد الأخير:',
    'map-popup.poi.main.date-next.text': 'تاريخ التفقد التالي:',
    'map-popup.poi.videocamera.video-stream.text': 'تدفق فيديو',
    'map-popup.poi.sensor.indication.text': 'المؤشرات',
    'map-popup.poi.sensor.temperature.text': 'الحرارة',
    'map-popup.poi.sensor.notifications.text': 'الإشعارات',
    'map-popup.poi.incident.notifications.text': 'الإشعارات',
    'map-popup.poi.gaz-analyzer.indications.text': 'المعدلات',
    'map-popup.poi.gaz-analyzer.date.text': 'تاريخ ووقت التحليل:',
    'map-popup.poi.gaz-analyzer.notifications.text': 'الإشعارات',
    'map-popup.poi.free-point.notifications.text': 'الإشعارات',
    'map-popup.poi.equipment.notifications.text': 'الإشعارات',
  },
  de: {
    'map-popup.poi.header.text': 'POI-Karte',
    'map-popup.poi.header.videocamera.text': 'Videokamera',
    'map-popup.poi.header.gazanalyzer.text': 'Gasanalysator',
    'map-popup.poi.header.sensor.text': 'Sensor',
    'map-popup.poi.header.equipment.text': 'Ausrüstung',
    'map-popup.poi.header.accident.text': 'Vorfall',
    'map-popup.poi.header.free_point.text': 'Freier Punkt',
    'map-popup.poi.main.free-point.text': 'Kostenloser Punkt:',
    'map-popup.poi.main.current-position.text': 'Ort:',
    'map-popup.poi.main.description.text': 'Beschreibung:',
    'map-popup.poi.main.date-last.text': 'Datum der letzten Überprüfung:',
    'map-popup.poi.main.date-next.text': 'Datum der nächsten Überprüfung:',
    'map-popup.poi.videocamera.video-stream.text': 'Videostream',
    'map-popup.poi.sensor.indication.text': 'Indikationen',
    'map-popup.poi.sensor.temperature.text': 'Temperatur',
    'map-popup.poi.sensor.notifications.text': 'Benachrichtigungen',
    'map-popup.poi.incident.notifications.text': 'Benachrichtigungen',
    'map-popup.poi.gaz-analyzer.indications.text': 'Indikationen',
    'map-popup.poi.gaz-analyzer.date.text': 'Datum und Uhrzeit der Analyse:',
    'map-popup.poi.gaz-analyzer.notifications.text': 'Benachrichtigungen',
    'map-popup.poi.free-point.notifications.text': 'Benachrichtigungen',
    'map-popup.poi.equipment.notifications.text': 'Benachrichtigungen',
  },
};
