declare global {
  interface Window {
    pause: boolean;
    speed: number;
    index: number;
    activeId: string;
    point: number[];

    id: string;
    progress: number;
    currentCoord: number[] | null;
    currentTime: number | null;
    isUsedScroll: boolean;
  }

  interface Date {
    isValid: () => boolean;
  }
}

export interface JSONApi<attributes, relationships, meta, includes> {
  data: {
    type: string;
    id: number;
    relationships: relationships;
    attributes: attributes;
  }[];
  meta: meta;
  includes: includes;
}

export interface JSONApiObj<attributes, relationships, meta, includes> {
  data: {
    type: string;
    id: number;
    relationships: relationships;
    attributes: attributes;
  };
  meta: meta;
  includes: includes;
}

// TODO: сейчас (с 10.08.21) на бекенде переделываются ручки в соответствии с нижеприведенной структурой:
// Следовательно, необходимо переименовать впоследствии (когда все ручки сделаются)
// `NewJSONApi` -> `JSONApi`, `NewJSONApiObj` -> `JSONApiObj`, а выше JSONApi, JSONApiObj удалить

export interface NewJSONApi<attributes, relationships, meta, included> {
  data: {
    type: string;
    id: string;
    relationships: relationships;
    attributes: attributes;
  }[];
  meta: meta;
  included: included;
}

export interface NewJSONApiWithoutRelationships<attributes, meta, included> {
  data: {
    type: string;
    id: string;
    attributes: attributes;
  }[];
  meta: meta;
  included: included;
}

export interface NewJSONApiObj<attributes, relationships, meta, included> {
  data: {
    type: string;
    id: string;
    relationships: relationships;
    attributes: attributes;
  };
  meta: meta;
  included: included;
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type QueryParams = {
  offset?: number;
  limit?: number;

  dateStart?: string;
  dateEnd?: string;

  sortBy?: string;
  sortType?: SortType;

  search?: string;
};
