export default {
  en: {
    'map-popup.pagination.elements.text': 'Items per page',
    'map-popup.pagination.btn.add.text': 'Add field',
  },
  ru: {
    'map-popup.pagination.elements.text': 'Элементов на странице',
    'map-popup.pagination.btn.add.text': 'Добавить поле',
  },
  zh: {
    'map-popup.pagination.elements.text': '每页项目',
    'map-popup.pagination.btn.add.text': '添加字段',
  },
  ar: {
    'map-popup.pagination.elements.text': 'عناصر على صفحة',
    'map-popup.pagination.btn.add.text': 'إصافة خانة',
  },
  de: {
    'map-popup.pagination.elements.text': 'Objekte pro Seite',
    'map-popup.pagination.btn.add.text': 'Feld hinzufügen',
  },
};
