import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as POIIcon } from 'assets/img/header/poi.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';
import { ReactComponent as OpenNewTab } from 'assets/img/button/popup_open_in_new_tab.svg';

import { PoiTypesEnum } from 'components/poi/utils/consts';
import { PoiMarkerProperties } from 'components/map/map.types';
import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { getPoiCardData } from 'components/poi/utils/helpers';
import PoiVideoCameraPopup from './components/poiVideoCameraPopup';
import { PoiGazAnalyzerPopup } from './components/poiGazAnalyzerPopup';
import { PoiSensorPopup } from './components/poiSensorPopup';
import { PoiEquipmentPopup } from './components/poiEquipmentPopup';
import { PoiIncidentPopup } from './components/poiIncidentPopup';
import { PoiFreePointPopup } from './components/poiFreePointPopup';

import { RootState } from 'reducers';
import {
  Poi,
  PoiEquipmentPoint,
  PoiGasAnalyzerPoint,
  PoiSensorPoint,
  PoiVideocameraPoint,
} from 'reducers/poi/interface';
import { fetchOnePoi, setPoiCardData, setPoiCardType } from 'reducers/poi';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import AccessEntity from 'utils/accessEntity';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './poiPopup.module.scss';

const cx = classNames.bind(styles);

type PoiPopupType = {
  onCancel: () => void;
  markerProperties: PoiMarkerProperties | null;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
};

function getPoiPopupSize(poiType: PoiTypesEnum) {
  switch (poiType) {
    case PoiTypesEnum.poiVideocameraPoint:
      return { width: 800, footerHeight: 450 };
    case PoiTypesEnum.poiGasAnalyzerPoint:
      return { width: 430, footerHeight: 167 };
    case PoiTypesEnum.poiSensorPoint:
      return { width: 430, footerHeight: 167 };
    case PoiTypesEnum.poiEquipmentPoint:
      return { width: 430, footerHeight: 52 };
    case PoiTypesEnum.poiAccidentPoint:
      return { width: 430, footerHeight: 52 };
    case PoiTypesEnum.poiFreePoint:
      return { width: 430, footerHeight: 52 };

    default:
      return { width: 0, height: 0 };
  }
}

function getPoiTypeName(poiType: PoiTypesEnum) {
  switch (poiType) {
    case PoiTypesEnum.poiVideocameraPoint:
      return 'map-popup.poi.header.videocamera.text';
    case PoiTypesEnum.poiGasAnalyzerPoint:
      return 'map-popup.poi.header.gazanalyzer.text';
    case PoiTypesEnum.poiSensorPoint:
      return 'map-popup.poi.header.sensor.text';
    case PoiTypesEnum.poiEquipmentPoint:
      return 'map-popup.poi.header.equipment.text';
    case PoiTypesEnum.poiAccidentPoint:
      return 'map-popup.poi.header.accident.text';
    case PoiTypesEnum.poiFreePoint:
      return 'map-popup.poi.header.free_point.text';

    default:
      return '';
  }
}

function getFooter(type: PoiTypesEnum, chosenPoiInfo?: Poi) {
  switch (type) {
    case PoiTypesEnum.poiVideocameraPoint: {
      if (chosenPoiInfo) {
        const poiVideoData = chosenPoiInfo.relationships.poiVideocameraPoint.data as PoiVideocameraPoint;
        return <PoiVideoCameraPopup src={poiVideoData.dataSourceUrl} proxyPath={poiVideoData.proxyPath} />;
      } else {
        return null;
      }
    }
    case PoiTypesEnum.poiGasAnalyzerPoint:
      return <PoiGazAnalyzerPopup />;
    case PoiTypesEnum.poiSensorPoint:
      return <PoiSensorPopup />;
    case PoiTypesEnum.poiEquipmentPoint:
      return <PoiEquipmentPopup />;
    case PoiTypesEnum.poiAccidentPoint:
      return <PoiIncidentPopup />;
    case PoiTypesEnum.poiFreePoint:
      return <PoiFreePointPopup />;

    default:
      return null;
  }
}

function getCheckDates(type: PoiTypesEnum, chosenPoiInfo?: Poi) {
  let data: PoiEquipmentPoint | PoiSensorPoint | PoiGasAnalyzerPoint | null = null;

  if (type === PoiTypesEnum.poiEquipmentPoint) {
    data = chosenPoiInfo?.relationships[type].data as PoiEquipmentPoint;
  }
  if (type === PoiTypesEnum.poiSensorPoint) {
    data = chosenPoiInfo?.relationships[type].data as PoiSensorPoint;
  }
  if (type === PoiTypesEnum.poiGasAnalyzerPoint) {
    data = chosenPoiInfo?.relationships[type].data as PoiGasAnalyzerPoint;
  }

  return {
    lastDate: data?.lastCheckDate ?? '',
    nextDate: data?.nextCheckDate ?? '',
  };
}

function PoiPopup({ onCancel, markerProperties, markerCoords }: PoiPopupType) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { data: poiData } = useSelector((state: RootState) => state.poi);
  const poiType = markerProperties?.poiType;
  const info = markerProperties?.info;
  const chosenPoiInfo = poiData.find(poi => poi.id === info?.id);

  const poiPermissions = useSelector((state: RootState) => state.user.permissions.poi);
  const poiAccess = useMemo(() => new AccessEntity(poiPermissions), [poiPermissions]);

  let lastCheckDate = '';
  let nextCheckDate = '';

  if (
    chosenPoiInfo &&
    (poiType === PoiTypesEnum.poiEquipmentPoint ||
      poiType === PoiTypesEnum.poiSensorPoint ||
      poiType === PoiTypesEnum.poiGasAnalyzerPoint)
  ) {
    const lastDate = getFullDateFromStr(getCheckDates(poiType, chosenPoiInfo).lastDate);
    const nextDate = getFullDateFromStr(getCheckDates(poiType, chosenPoiInfo).nextDate);

    lastCheckDate = `${lastDate.date} ${lastDate.time}`;
    nextCheckDate = `${nextDate.date} ${nextDate.time}`;
  }

  const openEditModal = async () => {
    if (poiAccess.isAllowUpdate() && poiType && chosenPoiInfo) {
      const poiCardData = getPoiCardData(poiType);

      dispatch(setPoiCardType(poiType));
      dispatch(setPoiCardData(poiCardData));
      await dispatch(fetchOnePoi(chosenPoiInfo.id));
    }
    onCancel();
  };

  const headerPopup = (
    <div className={styles.header}>
      <div className={styles.headerInfo}>
        <POIIcon className={styles.headerIcon} />
        <h4 className={styles.headerTitle}>
          {t('map-popup.poi.header.text')}
          <br />
          {poiType ? t(getPoiTypeName(poiType)) : ''}
        </h4>
      </div>
    </div>
  );

  const contentPopup = (
    <div className={styles.content}>
      <div className={styles.contentName}>
        {poiType ? t(getPoiTypeName(poiType)) : ''}
        {': '}
        {chosenPoiInfo?.attributes.name ?? ''}
      </div>
      <div className={styles.contentLocation}>
        {t('map-popup.poi.main.current-position.text')}{' '}
        {`${chosenPoiInfo?.attributes.lon.toFixed(8) ?? ''}, ${chosenPoiInfo?.attributes.lat.toFixed(8) ?? ''}`}
      </div>
      <div className={styles.contentDescription}>
        {t('map-popup.poi.main.description.text')} {chosenPoiInfo?.attributes.description ?? ''}
      </div>
      {(poiType === PoiTypesEnum.poiEquipmentPoint ||
        poiType === PoiTypesEnum.poiSensorPoint ||
        poiType === PoiTypesEnum.poiGasAnalyzerPoint) && (
        <div className={styles.contentDates}>
          <div className={styles.contentDate}>
            {t('map-popup.poi.main.date-last.text')} {lastCheckDate}
          </div>
          <div className={styles.contentDate}>
            {t('map-popup.poi.main.date-next.text')} {nextCheckDate}
          </div>
        </div>
      )}
    </div>
  );

  const footerPopup = (
    <div
      className={cx({
        [styles.footerVideoCamera]: poiType === PoiTypesEnum.poiVideocameraPoint,
      })}
    >
      {poiType ? getFooter(poiType, chosenPoiInfo) : null}
    </div>
  );

  if (!poiType) {
    return null;
  }

  const initialPopupSize = getPoiPopupSize(poiType);

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      additionalHeaderButtons={[
        ...(poiType === PoiTypesEnum.poiVideocameraPoint
          ? [
              {
                name: 'openPoiNewTab',
                button: <OpenNewTab className={styles.headerBtnControl} />,
              },
            ]
          : []),
        ...(poiAccess.isAllowUpdate()
          ? [
              {
                name: 'openPoiParams',
                button: <OpenedEye onClick={openEditModal} className={styles.headerBtnControl} />,
              },
            ]
          : []),
      ]}
      content={contentPopup}
      footer={footerPopup}
      showFooter={true}
      initialWidth={initialPopupSize.width}
      minWidth={poiType === PoiTypesEnum.poiVideocameraPoint ? 500 : undefined}
      initialFooterHeight={initialPopupSize.footerHeight}
      minHeight={poiType === PoiTypesEnum.poiVideocameraPoint ? 450 : undefined}
      initialPosition={markerCoords.mouseCoords}
      onCancel={onCancel}
      resizeAxis="both"
      customClassNames={{
        content: cx(styles.contentPopup, {
          [styles.contentPopupVideoCamera]: poiType === PoiTypesEnum.poiVideocameraPoint,
        }),
        footer: cx({
          [styles.footerPopupVideoCamera]: poiType === PoiTypesEnum.poiVideocameraPoint,
        }),
      }}
    />,
    appContentContainer
  );
}

export default React.memo(PoiPopup);
