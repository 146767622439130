import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { setHours, setMinutes } from 'date-fns';

import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { RadioButton } from 'components/common/radioButton/radioButton';
import { InfoElement } from 'components/common/infoElement/infoElement';
import { NOTIFICATION_CONDITION_GEOZONE_FIXING_BY } from 'components/notifications/utils/consts';
import { GeozoneProps } from 'components/notifications/utils/types';
import MultipleSelectGroup, {
  getValuesForMultipleSelectGroup,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../../tabsModal.module.scss';
import './commonLayoutStyles.scss';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div
      className={cx(styles.datePicker, {
        [styles.intervalTime]: props.intervalTimeStyle,
        [styles.fullWidth]: props.fullWidth,
      })}
      onClick={props.onClick}
      ref={_ref}
    >
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value[0] === '0' ? props.value.substring(1) : props.value}
        witchTimeIcon
        isRequired={props.isRequired}
        isValueError={props.isValueError}
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

export const GeoZoneLayout = ({ states, handlers, refs, invalidFields, additionalFields }: GeozoneProps) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <>
      <div className={styles.modalCol}>
        <div className={cx(styles.mBottom, styles.Inputs)}>
          <MultipleSelectGroup
            label={t('notifications.card.tabs.conditions.field.geozone.geozone-list.label')}
            placeholder={t('notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder')}
            isValueError={invalidFields.includes('geozoneEntities')}
            isRequired={true}
            wrapperStyle={styles.inputWrap}
            withSearchIcon
            options={states.geozoneEntitiesForSelect}
            values={getValuesForMultipleSelectGroup({
              options: states.geozoneEntitiesForSelect,
              values: states.geozoneEntities.map(object => ({ name: object.type, ids: object.entityIds })),
            })}
            translateGroups={states.geozoneEntitiesTranslatesForSelect}
            handleChange={handlers.handleChangeGeozones}
          />
        </div>
        <div className={styles.modalRow}>
          <div className={styles.radioGroup}>
            <div
              className={styles.radioButtonRow}
              onClick={() => handlers.handleCheckedFixingBy(NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.enter)}
            >
              <RadioButton
                name="geozoneFixationNotification"
                checked={states.fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.enter}
              />
              <span>{t('notifications.card.tabs.conditions.field.geozone.rec-in.text')}</span>
            </div>
            <div
              className={styles.radioButtonRow}
              onClick={() => handlers.handleCheckedFixingBy(NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.exit)}
            >
              <RadioButton
                name="geozoneFixationNotification"
                checked={states.fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.exit}
              />
              <span>{t('notifications.card.tabs.conditions.field.geozone.rec-out.text')}</span>
            </div>
            <div className={cx(styles.radioButtonRow, styles.withTooltip)}>
              <div
                className={styles.radioButtonInfo}
                onClick={() => handlers.handleCheckedFixingBy(NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.absence)}
              >
                <RadioButton
                  name="geozoneFixationNotification"
                  checked={states.fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.absence}
                />
                <span>{t('notifications.card.tabs.conditions.field.geozone.rec-absence.text')}</span>
              </div>
              <div className={styles.radioButtonTooltip}>
                <InfoElement
                  id={'absenceGeozoneObject'}
                  text={t('notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text')}
                />
              </div>
            </div>
            <div className={cx(styles.radioButtonRow, styles.withTooltip)}>
              <div
                className={styles.radioButtonInfo}
                onClick={() => handlers.handleCheckedFixingBy(NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.longStaying)}
              >
                <RadioButton
                  name="geozoneFixationNotification"
                  checked={states.fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.longStaying}
                />
                <span>{t('notifications.card.tabs.conditions.field.geozone.rec-long-stay.text')}</span>
              </div>
              <div className={styles.radioButtonTooltip}>
                <InfoElement
                  id={'longStayGeozoneObject'}
                  text={t('notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text')}
                />
              </div>
            </div>
          </div>
        </div>
        {states.fixingBy === NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.longStaying && (
          <div className={cx(styles.modalRow, styles.Inputs)}>
            <DatePicker
              dateFormat={t('notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text')}
              selected={
                states.geozoneStandingLimit
                  ? setHours(setMinutes(new Date(), states.geozoneStandingLimit / 60), 0)
                  : null
              }
              onChange={(date: Date) => handlers.handleChangeLocationLimitValue(date)}
              className={styles.tabInfoDatePicker}
              placeholderText={t('notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder')}
              customInput={
                <DatePickerCustomInput
                  ref={refs.geozoneStandingLimitRef}
                  label={t('notifications.card.tabs.conditions.field.geozone.limit-stay.label')}
                  isValueError={invalidFields.includes('longStayingGeozones')}
                  isRequired={true}
                  fullWidth
                />
              }
              openToDate={setHours(setMinutes(new Date(), 1), 0)}
              locale={getCurrentLocaleForDatePicker(locale)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat={t('notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text')}
              timeIntervals={1}
              timeCaption={t('notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text')}
              minTime={setHours(setMinutes(new Date(), 1), 0)}
              maxTime={setHours(setMinutes(new Date(), 59), 0)}
              popperClassName="popperDatePicker"
            />
          </div>
        )}
      </div>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedFoulFixing}>
            <Checkbox checked={additionalFields.isFoulFixing} />
            <span>{t('notifications.card.tabs.conditions.field.geozone.rec-violation.text')}</span>
          </div>
          <InfoElement
            id={'violationFixedGeozone'}
            text={t('notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text')}
          />
        </div>
        {additionalFields.isFoulFixing && (
          <div className={cx(styles.modalRow, styles.Inputs)}>
            <DatePicker
              dateFormat={t('notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text')}
              selected={
                states.fixingTimeLimit >= 0 ? setHours(setMinutes(new Date(), states.fixingTimeLimit / 60), 0) : null
              }
              onChange={(date: Date) => handlers.handleChangeFixingTimeLimitValue(date)}
              className={styles.tabInfoDatePicker}
              placeholderText={t('notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder')}
              customInput={
                <DatePickerCustomInput
                  ref={refs.fixingTimeLimitRef}
                  label={t('notifications.card.tabs.conditions.field.geozone.waiting-time.label')}
                  isValueError={invalidFields.includes('foulFixingGeozones')}
                  fullWidth
                />
              }
              openToDate={setHours(setMinutes(new Date(), 0), 0)}
              locale={getCurrentLocaleForDatePicker(locale)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat={t('notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text')}
              timeIntervals={1}
              timeCaption={t('notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text')}
              timeInputLabel="fgh"
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 59), 0)}
              popperClassName="popperDatePicker"
            />
          </div>
        )}
      </div>
    </>
  );
};
