import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg';

import Button from 'components/common/button/button';

import styles from './pagination.module.scss';

const cx = classNames.bind(styles);

type Props = {
  page: number;
  onAddButtonClick?: () => void;
  withAddButton?: boolean;
  withSelect?: boolean;
  limit?: number;
  setLimit?: (value: number) => void;
  increasePage?: () => void;
  decreasePage?: () => void;
};

export const ModalPagination = ({
  page,
  limit,
  setLimit,
  onAddButtonClick,
  withAddButton,
  withSelect,
  increasePage,
  decreasePage,
}: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalPaginationWrapper}>
      {withSelect && (
        <>
          <div className={styles.modalPaginationSelectWrapper}>
            <span className={styles.modalPaginationSelectLabel}>Элементов на странице</span>
            <div className={styles.modalPaginationSelect}>
              <select
                className={styles.modalPaginationSelect}
                defaultValue={limit}
                onChange={ev => setLimit && setLimit(parseInt(ev.target.value))}
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
              </select>
            </div>
          </div>
          <div className={styles.modalPaginationPageWrapper}>
            <ArrowLeft className={styles.modalPaginationArrow} onClick={decreasePage} />
            <div className={styles.modalPaginationPage}>{page}</div>
            <ArrowRight className={styles.modalPaginationArrow} onClick={increasePage} />
          </div>
        </>
      )}
      {withAddButton ? (
        <div className={styles.modalPaginationButtonWrapper}>
          <Button
            white
            withSymbol
            text={t('monitoring.unit-card.additional-fields.add-btn.label')}
            onClick={onAddButtonClick}
          />
        </div>
      ) : (
        <div className={cx(styles.modalPaginationButtonWrapper, styles.modalPaginationHideElement)}>
          <Button
            white
            withSymbol
            text={t('monitoring.unit-card.additional-fields.add-btn.label')}
            onClick={onAddButtonClick}
          />
        </div>
      )}
    </div>
  );
};
