import { useState, useEffect, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import { GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES } from 'components/geozones/utils/consts';
import { HANDBOOK_KEYS, HANDBOOK_NAMES } from 'components/handbooks/utils/consts';
import { NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';

import { RootState } from 'reducers';
import {
  AddedNotificationEventInfo,
  AssignFieldEmployeeEventInfo,
  AssignFieldTransportEventInfo,
  AssignGeozoneToGeozoneGroupEventInfo,
  UnAssignGeozoneFromGeozoneGroupEventInfo,
  AssignRoleUserDataEventInfo,
  AssignTrackerEmployeeEventInfo,
  AssignTrackerTransportEventInfo,
  AssignWorkObjectEmployeeEventInfo,
  CreateEmployeeEventInfo,
  CreateGeozoneEventInfo,
  CreateGeozoneGroupEventInfo,
  CreateHandbookEventInfo,
  CreatePoiEventInfo,
  CreateReportEventInfo,
  CreateReportTemplateEventInfo,
  CreateRolePermsEventInfo,
  CreateTrackerEventInfo,
  CreateTransportEventInfo,
  CreateUserDataEventInfo,
  DeleteEmployeeEventInfo,
  DeleteGeozoneEventInfo,
  DeleteGeozoneGroupEventInfo,
  DeletePoiEventInfo,
  DeleteReportTemplateEventInfo,
  DeleteRolePermsEventInfo,
  DeleteTrackerEventInfo,
  DeleteTransportEventInfo,
  DeleteUserDataEventInfo,
  LoginAuthEventInfo,
  StatisticEventTypes,
  StatisticIcon,
  StatisticTableRow,
  UnAssignRoleUserDataEventInfo,
  UnAssignTrackerEmployeeEventInfo,
  UnAssignTrackerTransportEventInfo,
  UnAssignWorkObjectEmployeeEventInfo,
  UpdateAssignedFieldsEmployeeEventInfo,
  UpdateAssignedFieldsTransportEventInfo,
  UpdateEmployeeEventInfo,
  UpdateGeozoneEventInfo,
  UpdateGeozoneGroupEventInfo,
  UpdateHandbookEventInfo,
  UpdatePoiEventInfo,
  UpdateReportTemplateEventInfo,
  UpdateRolePermsEventInfo,
  UpdateTrackerEventInfo,
  UpdateTransportEventInfo,
  UpdateUserDataEventInfo,
  OldNewValues,
  UpdatedAssignedFields,
} from 'reducers/personalStatistics/interface';
import {
  clearStatisticFilter,
  setStatisticFilter,
  setStatistics,
  setStatisticsTotal,
} from 'reducers/personalStatistics';
import { fetchHandbookData } from 'reducers/handbooks';

import { getCurrentLocale, LanguagesKeysType } from 'translate';

import { getFullDateFromStr } from './getFullDateFromStr';
import { toast } from 'react-toastify';

const DEFAULT_STATISTIC_LIMIT = 5;
const DEFAULT_STATISTIC_PAGE = 1;

function usePersonalStatisticRowsAndPagination() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));
  const { statistics, statisticFilter, statisticTotal, isStatisticLoading } = useSelector(
    (state: RootState) => state.personalStatistics
  );
  const { isDataLoading: IsHandbooksLoading, data: handbookData } = useSelector((state: RootState) => state.handbooks);

  const isLoading = isStatisticLoading || IsHandbooksLoading;
  const handbookTransportBrands = handbookData?.transportBrands;
  const handbookTransportModels = handbookData?.transportModels;

  useEffect(() => {
    dispatch(fetchHandbookData([HANDBOOK_KEYS.transportBrands, HANDBOOK_KEYS.transportModels]));
  }, [dispatch]);

  const [page, setPage] = useState(DEFAULT_STATISTIC_PAGE);

  const rows: StatisticTableRow[] = useMemo(
    () =>
      !!statistics?.length && !!handbookTransportBrands?.length && !!handbookTransportModels?.length
        ? statistics.map(statistic => {
            const attr = statistic.attributes;
            const fullDate = getFullDateFromStr(attr.createdAt);
            let base64Icons: {
              old: StatisticIcon;
              new: StatisticIcon;
            } | null = null;
            let eventType = 'Unknown type';
            let eventDescription = JSON.stringify(attr.eventInfo, null, 4);

            try {
              switch (attr.eventType) {
                // auth

                case StatisticEventTypes.LoginAuthEvent:
                  {
                    const info = attr.eventInfo as LoginAuthEventInfo;
                    const text = t('personal-statistic.auth.login-auth.desc.text');
                    const login = `${t('personal-statistic.auth.login-auth.desc.login.text')}: ${
                      info?.userLogin ?? t('personal-statistic.no-data.text')
                    }`;
                    const name = `${t('personal-statistic.auth.login-auth.desc.name.text')}: ${
                      info?.userName ?? t('personal-statistic.no-data.text')
                    }`;
                    const ip = `${t('personal-statistic.auth.login-auth.desc.ip.text')}: ${
                      info?.ip ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.auth.login-auth.type.text');
                    eventDescription = `${text}. ${login}, ${name}, ${ip}`;
                  }
                  break;

                // employee

                case StatisticEventTypes.CreateEmployeeEvent:
                  {
                    const info = attr.eventInfo as CreateEmployeeEventInfo;
                    const text = t('personal-statistic.employee.create.desc.text');
                    const name = `${t('personal-statistic.employee.create.desc.name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.create.desc.sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.create.type.text');
                    eventDescription = `${text}. ${name}, ${sim}`;
                  }
                  break;

                case StatisticEventTypes.DeleteEmployeeEvent:
                  {
                    const info = attr.eventInfo as DeleteEmployeeEventInfo;
                    const text = t('personal-statistic.employee.delete.desc.text');
                    const name = `${t('personal-statistic.employee.delete.desc.name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.delete.desc.sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.delete.type.text');
                    eventDescription = `${text}. ${name}, ${sim}`;
                  }
                  break;

                case StatisticEventTypes.UpdateEmployeeEvent:
                  {
                    const info = attr.eventInfo as UpdateEmployeeEventInfo;
                    const text = t('personal-statistic.employee.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.employee.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.employee.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.employee.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.update.type.text');
                    if (key === 'icon') {
                      eventDescription = `${text}. ${field}. `;
                      base64Icons = {
                        old: {
                          accompanyingText: t('personal-statistic.employee.update.desc.old-value.text'),
                          base64: info[key]?.__old || '',
                        },
                        new: {
                          accompanyingText: t('personal-statistic.employee.update.desc.new-value.text'),
                          base64: info[key]?.__new || '',
                        },
                      };
                    } else {
                      eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                    }
                  }
                  break;

                case StatisticEventTypes.AssignFieldEmployeeEvent:
                  {
                    const info = attr.eventInfo as AssignFieldEmployeeEventInfo;
                    const text = t('personal-statistic.employee.assign-field.desc.text');
                    const name = `${t('personal-statistic.employee.assign-field.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.assign-field.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const fieldName = `${t('personal-statistic.employee.assign-field.desc.field-name.text')}: ${
                      info?.fieldName ?? t('personal-statistic.no-data.text')
                    }`;
                    const fieldValue = `${t('personal-statistic.employee.assign-field.desc.field-value.text')}: ${
                      info?.fieldValue ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.assign-field.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${fieldName}, ${fieldValue}`;
                  }
                  break;

                case StatisticEventTypes.UpdateAssignedFieldsEmployeeEvent:
                  {
                    const text = t('personal-statistic.employee.update-assign-field.desc.text');
                    const info = attr.eventInfo as UpdateAssignedFieldsEmployeeEventInfo;
                    const name = `${t('personal-statistic.employee.update-assign-field.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.update-assign-field.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;

                    const excludedFields = ['firstName', 'secondName', 'lastName', 'simNumber'];
                    const fieldsStr = Object.keys(attr.eventInfo)
                      .filter(key => !excludedFields.includes(key))
                      .reduce((acc, key, index) => {
                        const valuesObj = {
                          label: '',
                          value: '',
                          visible: '',
                        };

                        Object.keys(valuesObj).forEach(currKey => {
                          const currentValue = info[key]?.[currKey as keyof UpdatedAssignedFields];

                          if (!!currentValue) {
                            valuesObj[currKey as keyof typeof valuesObj] = `${t(
                              `personal-statistic.employee.update-assign-field.desc.${currKey}.text`
                            )} ${
                              typeof currentValue === 'string' || typeof currentValue === 'boolean'
                                ? currentValue ?? ''
                                : `${t('personal-statistic.employee.update-assign-field.desc.old.text')}: ${
                                    (currentValue as OldNewValues)?.__old ?? ''
                                  }, ` +
                                  `${t('personal-statistic.employee.update-assign-field.desc.new.text')}: ${
                                    (currentValue as OldNewValues)?.__new ?? ''
                                  }`
                            }`;
                          }
                        });

                        const resultValues: string[] = [];
                        const numberField = parseInt(key[0]) + 1 || '1';

                        Object.values(valuesObj).forEach(val => {
                          if (val) {
                            resultValues.push(val);
                          }
                        });

                        if (key.includes('__added')) {
                          return (
                            acc +
                            `${index ? ', ' : ''}` +
                            `${t('personal-statistic.employee.update-assign-field.desc.added.text')} ${numberField} ` +
                            '(' +
                            `${resultValues.join(', ')}` +
                            ')'
                          );
                        }
                        if (key.includes('__deleted')) {
                          return (
                            acc +
                            `${index ? ', ' : ''}` +
                            `${t(
                              'personal-statistic.employee.update-assign-field.desc.deleted.text'
                            )} ${numberField} ` +
                            '(' +
                            `${resultValues.join(', ')}` +
                            ')'
                          );
                        }
                        return (
                          acc +
                          `${index ? ', ' : ''}` +
                          `${t('personal-statistic.employee.update-assign-field.desc.changed.text')} ${numberField} ` +
                          '(' +
                          `${resultValues.join(', ')}` +
                          ')'
                        );
                      }, '');

                    eventType = t('personal-statistic.employee.update-assign-field.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${fieldsStr}`;
                  }
                  break;

                case StatisticEventTypes.AssignWorkObjectEmployeeEvent:
                  {
                    const info = attr.eventInfo as AssignWorkObjectEmployeeEventInfo;
                    const text = t('personal-statistic.employee.assign-work-object.desc.text');
                    const name = `${t('personal-statistic.employee.assign-work-object.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.assign-work-object.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const wObjName = `${t(
                      'personal-statistic.employee.assign-work-object.desc.work-obj-name.text'
                    )}: ${getTranslateFromLanguageKey(info?.workObjectName, userLanguageKey)}`;

                    eventType = t('personal-statistic.employee.assign-work-object.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${wObjName}`;
                  }
                  break;

                case StatisticEventTypes.UnAssignWorkObjectEmployeeEvent:
                  {
                    const info = attr.eventInfo as UnAssignWorkObjectEmployeeEventInfo;
                    const text = t('personal-statistic.employee.un-assign-work-object.desc.text');
                    const name = `${t('personal-statistic.employee.un-assign-work-object.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.un-assign-work-object.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const wObjName = `${t(
                      'personal-statistic.employee.un-assign-work-object.desc.work-obj-name.text'
                    )}: ${getTranslateFromLanguageKey(info?.workObjectName, userLanguageKey)}`;

                    eventType = t('personal-statistic.employee.un-assign-work-object.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${wObjName}`;
                  }
                  break;

                case StatisticEventTypes.AssignTrackerEmployeeEvent:
                  {
                    const info = attr.eventInfo as AssignTrackerEmployeeEventInfo;
                    const text = t('personal-statistic.employee.assign-tracker.desc.text');
                    const name = `${t('personal-statistic.employee.assign-tracker.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.assign-tracker.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const trackerNumber = `${t(
                      'personal-statistic.employee.assign-tracker.desc.tracker-number.text'
                    )}: ${info?.trackerNumber}`;
                    const trackerModel = `${t('personal-statistic.employee.assign-tracker.desc.tracker-model.text')}: ${
                      info?.trackerModel
                    }`;

                    eventType = t('personal-statistic.employee.assign-tracker.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${trackerNumber}, ${trackerModel}`;
                  }
                  break;

                case StatisticEventTypes.UnAssignTrackerEmployeeEvent:
                  {
                    const info = attr.eventInfo as UnAssignTrackerEmployeeEventInfo;
                    const text = t('personal-statistic.employee.un-assign-tracker.desc.text');
                    const name = `${t('personal-statistic.employee.un-assign-tracker.desc.employee-name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;
                    const sim = `${t('personal-statistic.employee.un-assign-tracker.desc.employee-sim.text')}: ${
                      info?.simNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const trackerNumber = `${t(
                      'personal-statistic.employee.un-assign-tracker.desc.tracker-number.text'
                    )}: ${info?.trackerNumber}`;
                    const trackerModel = `${t(
                      'personal-statistic.employee.un-assign-tracker.desc.tracker-model.text'
                    )}: ${info?.trackerModel}`;

                    eventType = t('personal-statistic.employee.un-assign-tracker.type.text');
                    eventDescription = `${text}. ${name}, ${sim}. ${trackerNumber}, ${trackerModel}`;
                  }
                  break;

                // geozones

                case StatisticEventTypes.CreateGeozoneEvent:
                  {
                    const info = attr.eventInfo as CreateGeozoneEventInfo;
                    const text = t('personal-statistic.geozones.create.desc.text');
                    const name = `${t('personal-statistic.geozones.create.desc.name.text')}: ${
                      info?.name ?? t('personal-statistic.no-data.text')
                    }`;
                    const geomType = info?.geometryType || info?.geometricType;
                    const geometryType = `${t('personal-statistic.geozones.create.desc.geometric-type.text')}: ${
                      geomType
                        ? t(GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES[geomType])
                        : t('personal-statistic.no-data.text')
                    }`;
                    const geozoneType = `${t('personal-statistic.geozones.create.desc.gezone-type.text')}: ${
                      info?.geozoneType ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.create.type.text');
                    eventDescription = `${text}. ${name}, ${geometryType}, ${geozoneType}`;
                  }
                  break;

                case StatisticEventTypes.DeleteGeozoneEvent:
                  {
                    const info = attr.eventInfo as DeleteGeozoneEventInfo;
                    const text = t('personal-statistic.geozones.delete.desc.text');
                    const name = `${t('personal-statistic.geozones.delete.desc.name.text')}: ${
                      info?.name ?? t('personal-statistic.no-data.text')
                    }`;
                    const geomType = info?.geometryType || info?.geometricType;
                    const geometryType = `${t('personal-statistic.geozones.delete.desc.geometric-type.text')}: ${
                      geomType
                        ? t(GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES[geomType])
                        : t('personal-statistic.no-data.text')
                    }`;
                    const geozoneType = `${t('personal-statistic.geozones.delete.desc.gezone-type.text')}: ${
                      info?.geozoneType ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.employee.delete.type.text');
                    eventDescription = `${text}. ${name}, ${geometryType}, ${geozoneType}`;
                  }
                  break;

                case StatisticEventTypes.UpdateGeozoneEvent:
                  {
                    const info = attr.eventInfo as UpdateGeozoneEventInfo;
                    const text = t('personal-statistic.geozones.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.geozones.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.geozones.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.geozones.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.geozones.update.type.text');
                    if (key === 'icon') {
                      eventDescription = `${text}. ${field}. `;
                      base64Icons = {
                        old: {
                          accompanyingText: t('personal-statistic.geozones.update.desc.old-value.text'),
                          base64: info[key]?.__old || '',
                        },
                        new: {
                          accompanyingText: t('personal-statistic.geozones.update.desc.new-value.text'),
                          base64: info[key]?.__new || '',
                        },
                      };
                    } else {
                      eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                    }
                  }
                  break;

                case StatisticEventTypes.CreateGeozoneGroupEvent:
                  {
                    const info = attr.eventInfo as CreateGeozoneGroupEventInfo;
                    const text = t('personal-statistic.geozone-groups.create.desc.text');
                    const name = `${t('personal-statistic.geozone-groups.create.desc.name.text')}: ${
                      info?.name ?? t('personal-statistic.no-data.text')
                    }`;
                    const parentGroupName = `${t(
                      'personal-statistic.geozone-groups.create.desc.geometric-type.text'
                    )}: ${info?.parentGroupName ?? t('personal-statistic.no-data.text')}`;

                    eventType = t('personal-statistic.geozone-groups.create.type.text');
                    eventDescription = `${text}. ${name}, ${parentGroupName}`;
                  }
                  break;

                case StatisticEventTypes.DeleteGeozoneGroupEvent:
                  {
                    const info = attr.eventInfo as DeleteGeozoneGroupEventInfo;
                    const text = t('personal-statistic.geozone-groups.delete.desc.text');
                    const name = `${t('personal-statistic.geozone-groups.delete.desc.name.text')}: ${
                      info?.name ?? t('personal-statistic.no-data.text')
                    }`;
                    const parentGroupName = `${t(
                      'personal-statistic.geozone-groups.delete.desc.geometric-type.text'
                    )}: ${info?.parentGroupName ?? t('personal-statistic.no-data.text')}`;

                    eventType = t('personal-statistic.geozone-groups.delete.type.text');
                    eventDescription = `${text}. ${name}, ${parentGroupName}`;
                  }
                  break;

                case StatisticEventTypes.UpdateGeozoneGroupEvent:
                  {
                    const info = attr.eventInfo as UpdateGeozoneGroupEventInfo;
                    const text = t('personal-statistic.geozone-groups.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.geozone-groups.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.geozone-groups.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.geozone-groups.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.geozone-groups.update.type.text');
                    eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                case StatisticEventTypes.AssignGeozoneToGeozoneGroupEvent:
                  {
                    const info = attr.eventInfo as AssignGeozoneToGeozoneGroupEventInfo;
                    const text = t('personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.text');
                    const name = `${t(
                      'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.name.text'
                    )}: ${info?.name ?? t('personal-statistic.no-data.text')}`;
                    const groupName = `${t(
                      'personal-statistic.geozone-groups.assign-geozone-to-geozone-group.desc.geometric-type.text'
                    )}: ${info?.groupName ?? t('personal-statistic.no-data.text')}`;

                    eventType = t('personal-statistic.geozone-groups.assign-geozone-to-geozone-group.type.text');
                    eventDescription = `${text}. ${name}, ${groupName}`;
                  }
                  break;

                case StatisticEventTypes.UnAssignGeozoneFromGeozoneGroupEvent:
                  {
                    const info = attr.eventInfo as UnAssignGeozoneFromGeozoneGroupEventInfo;
                    const text = t('personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.text');
                    const name = `${t(
                      'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.name.text'
                    )}: ${info?.name ?? t('personal-statistic.no-data.text')}`;
                    const groupName = `${t(
                      'personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.desc.geometric-type.text'
                    )}: ${info?.groupName ?? t('personal-statistic.no-data.text')}`;

                    eventType = t('personal-statistic.geozone-groups.un-assign-geozone-to-geozone-group.type.text');
                    eventDescription = `${text}. ${name}, ${groupName}`;
                  }
                  break;

                // handbooks

                case StatisticEventTypes.CreateHandbookEvent:
                  {
                    const info = attr.eventInfo as CreateHandbookEventInfo;
                    const text = t('personal-statistic.handbooks.create.desc.text');
                    const type = `${t('personal-statistic.handbooks.create.desc.type.text')}: ${t(
                      info?.type
                        ? HANDBOOK_NAMES[info.type as keyof typeof HANDBOOK_NAMES]
                        : 'personal-statistic.no-data.text'
                    )}`;
                    const name = `${t('personal-statistic.handbooks.create.desc.name.text')} - ${
                      Object.keys(info?.name).reduce(
                        (acc, key, index) =>
                          acc +
                          `${!!index ? ',' : ''}` +
                          ` ${t('handbooks.add-translation.option.text.' + key).toLowerCase()}: ${
                            info?.name[key as LanguagesKeysType]
                          }`,
                        ''
                      ) ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.handbooks.create.type.text');
                    eventDescription = `${text}. ${type}. ${name}`;
                  }
                  break;

                case StatisticEventTypes.UpdateHandbookEvent:
                  {
                    const info = attr.eventInfo as UpdateHandbookEventInfo;
                    const text = t('personal-statistic.handbooks.update.desc.text');
                    const key = Object.keys(info).filter(key => key !== 'type')[0];
                    const type = `${t('personal-statistic.handbooks.create.desc.type.text')}: ${t(
                      info?.type
                        ? HANDBOOK_NAMES[info.type as keyof typeof HANDBOOK_NAMES]
                        : 'personal-statistic.no-data.text'
                    )}`;
                    const field = `${t('personal-statistic.handbooks.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.handbooks.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.handbooks.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.handbooks.update.type.text');
                    eventDescription = `${text}. ${type}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                // notifications

                case StatisticEventTypes.AddedNotificationEvent:
                  {
                    const info = attr.eventInfo as AddedNotificationEventInfo;
                    const text = t('personal-statistic.notifications.added.desc.text');
                    const notificationType = `${t('personal-statistic.notifications.added.desc.type.text')}: ${t(
                      info?.type
                        ? NOTIFICATION_TYPES_ENUM_NAMES[info.type as keyof typeof NOTIFICATION_TYPES_ENUM_NAMES]
                        : 'personal-statistic.no-data.text'
                    )}`;
                    const notificationText = `${t('personal-statistic.notifications.added.desc.text.text')}: ${
                      info?.text ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.notifications.added.type.text');
                    eventDescription = `${text}. ${notificationType}, ${notificationText}`;
                  }
                  break;

                // poi

                case StatisticEventTypes.CreatePoiEvent:
                  {
                    const info = attr.eventInfo as CreatePoiEventInfo;
                    const text = t('personal-statistic.poi.create.desc.text');
                    const name = `${t('personal-statistic.poi.create.desc.name.text')}: ${
                      info?.poiName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.poi.create.type.text');
                    eventDescription = `${text}. ${name}`;
                  }
                  break;

                case StatisticEventTypes.DeletePoiEvent:
                  {
                    const info = attr.eventInfo as DeletePoiEventInfo;
                    const text = t('personal-statistic.poi.delete.desc.text');
                    const name = `${t('personal-statistic.poi.delete.desc.name.text')}: ${
                      info?.poiName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.poi.delete.type.text');
                    eventDescription = `${text}. ${name}`;
                  }
                  break;

                case StatisticEventTypes.UpdatePoiEvent:
                  {
                    const info = attr.eventInfo as UpdatePoiEventInfo;
                    const text = t('personal-statistic.poi.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.poi.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.poi.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.poi.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.poi.update.type.text');
                    eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                // report

                case StatisticEventTypes.CreateReportTemplateEvent:
                  {
                    const info = attr.eventInfo as CreateReportTemplateEventInfo;
                    const text = t('personal-statistic.report-template.create.desc.text');
                    const templateName = `${t('personal-statistic.report-template.create.desc.name.text')}: ${
                      info?.templateName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.report-template.create.type.text');
                    eventDescription = `${text}. ${templateName}`;
                  }
                  break;

                case StatisticEventTypes.DeleteReportTemplateEvent:
                  {
                    const info = attr.eventInfo as DeleteReportTemplateEventInfo;
                    const text = t('personal-statistic.report-template.delete.desc.text');
                    const templateName = `${t('personal-statistic.report-template.delete.desc.name.text')}: ${
                      info?.templateName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.report-template.delete.type.text');
                    eventDescription = `${text}. ${templateName}`;
                  }
                  break;

                case StatisticEventTypes.UpdateReportTemplateEvent:
                  {
                    const info = attr.eventInfo as UpdateReportTemplateEventInfo;
                    const text = t('personal-statistic.report-template.update.desc.text');
                    const templateName = `${t('personal-statistic.report-template.update.desc.name.text')}: ${
                      info?.templateName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.report-template.update.type.text');
                    eventDescription = `${text}. ${templateName}`;
                  }
                  break;

                case StatisticEventTypes.CreateReportEvent:
                  {
                    const info = attr.eventInfo as CreateReportEventInfo;
                    const text = t('personal-statistic.report-template.create-report.desc.text');
                    const templateName = `${t('personal-statistic.report-template.create-report.desc.name.text')}: ${
                      info?.templateName ?? t('personal-statistic.no-data.text')
                    }`;
                    const reportNumber = `${t('personal-statistic.report-template.create-report.desc.number.text')}: ${
                      info?.reportId ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.report-template.create-report.type.text');
                    eventDescription = `${text}. ${templateName}, ${reportNumber}`;
                  }
                  break;

                // roles

                case StatisticEventTypes.CreateRolePermsEvent:
                  {
                    const info = attr.eventInfo as CreateRolePermsEventInfo;
                    const text = t('personal-statistic.role.create.desc.text');
                    const roleName = `${t('personal-statistic.role.create.desc.name.text')}: ${
                      info?.roleName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.role.create.type.text');
                    eventDescription = `${text}. ${roleName}`;
                  }
                  break;

                case StatisticEventTypes.DeleteRolePermsEvent:
                  {
                    const info = attr.eventInfo as DeleteRolePermsEventInfo;
                    const text = t('personal-statistic.role.delete.desc.text');
                    const roleName = `${t('personal-statistic.role.delete.desc.name.text')}: ${
                      info?.roleName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.role.delete.type.text');
                    eventDescription = `${text}. ${roleName}`;
                  }
                  break;

                case StatisticEventTypes.UpdateRolePermsEvent:
                  {
                    const info = attr.eventInfo as UpdateRolePermsEventInfo;
                    const text = t('personal-statistic.role.update.desc.text');
                    const key = Object.keys(info).filter(key => key !== 'roleName')[0];
                    const roleName = `${t('personal-statistic.role.update.desc.name.text')}: ${
                      info?.roleName ?? t('personal-statistic.no-data.text')
                    }`;
                    const field = `${t('personal-statistic.role.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.role.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.role.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.role.update.type.text');
                    eventDescription = `${text}. ${roleName}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                // trackers

                case StatisticEventTypes.CreateTrackerEvent:
                  {
                    const info = attr.eventInfo as CreateTrackerEventInfo;
                    const text = t('personal-statistic.trackers.create.desc.text');
                    const number = `${t('personal-statistic.trackers.create.desc.number.text')}: ${
                      info?.trackerNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.trackers.create.desc.model.text')}: ${
                      info?.trackerModelName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.trackers.create.type.text');
                    eventDescription = `${text}. ${number}, ${model}`;
                  }
                  break;

                case StatisticEventTypes.DeleteTrackerEvent:
                  {
                    const info = attr.eventInfo as DeleteTrackerEventInfo;
                    const text = t('personal-statistic.trackers.delete.desc.text');
                    const number = `${t('personal-statistic.trackers.delete.desc.number.text')}: ${
                      info?.trackerNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.trackers.delete.desc.model.text')}: ${
                      info?.trackerModelName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.trackers.delete.type.text');
                    eventDescription = `${text}. ${number}, ${model}`;
                  }
                  break;

                case StatisticEventTypes.UpdateTrackerEvent:
                  {
                    const info = attr.eventInfo as UpdateTrackerEventInfo;
                    const text = t('personal-statistic.trackers.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.trackers.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.trackers.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.trackers.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.trackers.update.type.text');
                    eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                // transport

                case StatisticEventTypes.CreateTransportEvent:
                  {
                    const info = attr.eventInfo as CreateTransportEventInfo;
                    const text = t('personal-statistic.transport.create.desc.text');
                    const brand = `${t('personal-statistic.transport.create.desc.brand.text')}: ${
                      info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.transport.create.desc.model.text')}: ${
                      info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const number = `${t('personal-statistic.transport.create.desc.number.text')}: ${
                      info?.regNumber ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.transport.create.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}`;
                  }
                  break;

                case StatisticEventTypes.DeleteTransportEvent:
                  {
                    const info = attr.eventInfo as DeleteTransportEventInfo;
                    const text = t('personal-statistic.transport.delete.desc.text');
                    const brand = `${t('personal-statistic.transport.delete.desc.brand.text')}: ${
                      info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.transport.delete.desc.model.text')}: ${
                      info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const number = `${t('personal-statistic.transport.delete.desc.number.text')}: ${
                      info?.regNumber ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.transport.create.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}`;
                  }
                  break;

                case StatisticEventTypes.UpdateTransportEvent:
                  {
                    const info = attr.eventInfo as UpdateTransportEventInfo;
                    const text = t('personal-statistic.transport.update.desc.text');
                    const key = Object.keys(info)[0];
                    const field = `${t('personal-statistic.transport.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.transport.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.transport.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.transport.update.type.text');
                    if (key === 'icon') {
                      eventDescription = `${text}. ${field}. `;
                      base64Icons = {
                        old: {
                          accompanyingText: t('personal-statistic.transport.update.desc.old-value.text'),
                          base64: info[key]?.__old || '',
                        },
                        new: {
                          accompanyingText: t('personal-statistic.transport.update.desc.new-value.text'),
                          base64: info[key]?.__new || '',
                        },
                      };
                    } else {
                      eventDescription = `${text}. ${field}. ${oldValue}, ${newValue}`;
                    }
                  }
                  break;

                case StatisticEventTypes.AssignFieldTransportEvent:
                  {
                    const info = attr.eventInfo as AssignFieldTransportEventInfo;
                    const text = t('personal-statistic.transport.assign-field.desc.text');
                    const brand = `${t('personal-statistic.transport.assign-field.desc.brand.text')}: ${
                      info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.transport.assign-field.desc.model.text')}: ${
                      info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const number = `${t('personal-statistic.transport.assign-field.desc.number.text')}: ${
                      info?.regNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const fieldName = `${t('personal-statistic.transport.assign-field.desc.field-name.text')}: ${
                      info?.fieldName ?? t('personal-statistic.no-data.text')
                    }`;
                    const fieldValue = `${t('personal-statistic.transport.assign-field.desc.field-value.text')}: ${
                      info?.fieldValue ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.transport.assign-field.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}. ${fieldName}, ${fieldValue}`;
                  }
                  break;

                case StatisticEventTypes.UpdateAssignedFieldsTransportEvent:
                  {
                    const text = t('personal-statistic.transport.update-assign-field.desc.text');
                    const info = attr.eventInfo as UpdateAssignedFieldsTransportEventInfo;
                    const brand = `${t(
                      'personal-statistic.transport.update-assign-field.desc.transport-brand.text'
                    )}: ${info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')}`;
                    const model = `${t(
                      'personal-statistic.transport.update-assign-field.desc.transport-model.text'
                    )}: ${info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')}`;
                    const number = `${t(
                      'personal-statistic.transport.update-assign-field.desc.transport-number.text'
                    )}: ${info?.regNumber ?? t('personal-statistic.no-data.text')}`;

                    const excludedFields = ['brandName', 'modelName', 'regNumber'];
                    const fieldsStr = Object.keys(attr.eventInfo)
                      .filter(key => !excludedFields.includes(key))
                      .reduce((acc, key, index) => {
                        const valuesObj = {
                          label: '',
                          value: '',
                          visible: '',
                        };

                        Object.keys(valuesObj).forEach(currKey => {
                          const currentValue = info[key]?.[currKey as keyof UpdatedAssignedFields];

                          if (!!currentValue) {
                            valuesObj[currKey as keyof typeof valuesObj] = `${t(
                              `personal-statistic.transport.update-assign-field.desc.${currKey}.text`
                            )} ${
                              typeof currentValue === 'string' || typeof currentValue === 'boolean'
                                ? currentValue ?? ''
                                : `${t('personal-statistic.transport.update-assign-field.desc.old.text')}: ${
                                    (currentValue as OldNewValues)?.__old ?? ''
                                  }, ` +
                                  `${t('personal-statistic.transport.update-assign-field.desc.new.text')}: ${
                                    (currentValue as OldNewValues)?.__new ?? ''
                                  }`
                            }`;
                          }
                        });

                        const resultValues: string[] = [];
                        const numberField = parseInt(key[0]) + 1 || '1';

                        Object.values(valuesObj).forEach(val => {
                          if (val) {
                            resultValues.push(val);
                          }
                        });

                        if (key.includes('__added')) {
                          return (
                            acc +
                            `${index ? ', ' : ''}` +
                            `${t('personal-statistic.transport.update-assign-field.desc.added.text')} ${numberField} ` +
                            '(' +
                            `${resultValues.join(', ')}` +
                            ')'
                          );
                        }
                        if (key.includes('__deleted')) {
                          return (
                            acc +
                            `${index ? ', ' : ''}` +
                            `${t(
                              'personal-statistic.transport.update-assign-field.desc.deleted.text'
                            )} ${numberField} ` +
                            '(' +
                            `${resultValues.join(', ')}` +
                            ')'
                          );
                        }
                        return (
                          acc +
                          `${index ? ', ' : ''}` +
                          `${t('personal-statistic.transport.update-assign-field.desc.changed.text')} ${numberField} ` +
                          '(' +
                          `${resultValues.join(', ')}` +
                          ')'
                        );
                      }, '');

                    eventType = t('personal-statistic.transport.update-assign-field.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}. ${fieldsStr}`;
                  }
                  break;

                case StatisticEventTypes.AssignTrackerTransportEvent:
                  {
                    const info = attr.eventInfo as AssignTrackerTransportEventInfo;
                    const text = t('personal-statistic.transport.assign-tracker.desc.text');
                    const brand = `${t('personal-statistic.transport.assign-tracker.desc.brand.text')}: ${
                      info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.transport.assign-tracker.desc.model.text')}: ${
                      info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const number = `${t('personal-statistic.transport.assign-tracker.desc.number.text')}: ${
                      info?.regNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const trackerNumber = `${t(
                      'personal-statistic.transport.assign-tracker.desc.tracker-number.text'
                    )}: ${info?.trackerNumber}`;
                    const trackerModel = `${t(
                      'personal-statistic.transport.assign-tracker.desc.tracker-model.text'
                    )}: ${info?.trackerModel}`;

                    eventType = t('personal-statistic.transport.assign-tracker.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}. ${trackerNumber}, ${trackerModel}`;
                  }
                  break;

                case StatisticEventTypes.UnAssignTrackerTransportEvent:
                  {
                    const info = attr.eventInfo as UnAssignTrackerTransportEventInfo;
                    const text = t('personal-statistic.transport.un-assign-tracker.desc.text');
                    const brand = `${t('personal-statistic.transport.un-assign-tracker.desc.brand.text')}: ${
                      info?.brandName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const model = `${t('personal-statistic.transport.un-assign-tracker.desc.model.text')}: ${
                      info?.modelName[userLanguageKey] ?? t('personal-statistic.no-data.text')
                    }`;
                    const number = `${t('personal-statistic.transport.un-assign-tracker.desc.number.text')}: ${
                      info?.regNumber ?? t('personal-statistic.no-data.text')
                    }`;
                    const trackerNumber = `${t(
                      'personal-statistic.transport.un-assign-tracker.desc.tracker-number.text'
                    )}: ${info?.trackerNumber}`;
                    const trackerModel = `${t(
                      'personal-statistic.transport.un-assign-tracker.desc.tracker-model.text'
                    )}: ${info?.trackerModel}`;

                    eventType = t('personal-statistic.transport.un-assign-tracker.type.text');
                    eventDescription = `${text}. ${brand}, ${model}, ${number}. ${trackerNumber}, ${trackerModel}`;
                  }
                  break;

                // user

                case StatisticEventTypes.CreateUserDataEvent:
                  {
                    const info = attr.eventInfo as CreateUserDataEventInfo;
                    const text = t('personal-statistic.user.create.desc.text');
                    const login = `${t('personal-statistic.user.create.desc.login.text')}: ${
                      info?.login ?? t('personal-statistic.no-data.text')
                    }`;
                    const name = `${t('personal-statistic.user.create.desc.name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.user.create.type.text');
                    eventDescription = `${text}. ${login}, ${name}`;
                  }
                  break;

                case StatisticEventTypes.DeleteUserDataEvent:
                  {
                    const info = attr.eventInfo as DeleteUserDataEventInfo;
                    const text = t('personal-statistic.user.delete.desc.text');
                    const login = `${t('personal-statistic.user.delete.desc.login.text')}: ${
                      info?.login ?? t('personal-statistic.no-data.text')
                    }`;
                    const name = `${t('personal-statistic.user.delete.desc.name.text')}: ${
                      info?.lastName ?? t('personal-statistic.no-data.text')
                    } ${info?.firstName ?? t('personal-statistic.no-data.text')} ${
                      info?.secondName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.user.delete.type.text');
                    eventDescription = `${text}. ${login}, ${name}`;
                  }
                  break;

                case StatisticEventTypes.UpdateUserDataEvent:
                  {
                    const info = attr.eventInfo as UpdateUserDataEventInfo;
                    const text = t('personal-statistic.user.update.desc.text');
                    const key = Object.keys(info).filter(key => key !== 'login')[0];
                    const login = `${t('personal-statistic.user.update.desc.login.text')}: ${
                      info?.login ?? t('personal-statistic.no-data.text')
                    }`;
                    const field = `${t('personal-statistic.user.update.desc.field.text')}: ${
                      key ?? t('personal-statistic.no-data.text')
                    }`;
                    const oldValue = `${t('personal-statistic.user.update.desc.old-value.text')}: ${
                      info[key]?.__old ?? t('personal-statistic.no-data.text')
                    }`;
                    const newValue = `${t('personal-statistic.user.update.desc.new-value.text')}: ${
                      info[key]?.__new ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.user.update.type.text');
                    eventDescription = `${text}. ${login}. ${field}. ${oldValue}, ${newValue}`;
                  }
                  break;

                case StatisticEventTypes.AssignRoleUserDataEvent:
                  {
                    const info = attr.eventInfo as AssignRoleUserDataEventInfo;
                    const text = t('personal-statistic.user.assign-role.desc.text');
                    const login = `${t('personal-statistic.user.assign-role.desc.login.text')}: ${
                      info?.login ?? t('personal-statistic.no-data.text')
                    }`;
                    const roleName = `${t('personal-statistic.user.assign-role.desc.role-name.text')}: ${
                      info?.roleName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.user.assign-role.type.text');
                    eventDescription = `${text}. ${login}, ${roleName}`;
                  }
                  break;

                case StatisticEventTypes.UnAssignRoleUserDataEvent:
                  {
                    const info = attr.eventInfo as UnAssignRoleUserDataEventInfo;
                    const text = t('personal-statistic.user.un-assign-role.desc.text');
                    const login = `${t('personal-statistic.user.un-assign-role.desc.login.text')}: ${
                      info?.login ?? t('personal-statistic.no-data.text')
                    }`;
                    const roleName = `${t('personal-statistic.user.un-assign-role.desc.role-name.text')}: ${
                      info?.roleName ?? t('personal-statistic.no-data.text')
                    }`;

                    eventType = t('personal-statistic.user.un-assign-role.type.text');
                    eventDescription = `${text}. ${login}, ${roleName}`;
                  }
                  break;

                default:
                  break;
              }
            } catch (error) {
              toast.error('toast.personal-statistic.error.parse-no-data.text');
            }

            return {
              date: fullDate.date,
              time: fullDate.shortTime,
              eventType,
              eventDescription,
              base64Icons,
            };
          })
        : [],
    [t, userLanguageKey, statistics, handbookTransportBrands, handbookTransportModels]
  );

  useEffect(() => {
    return () => {
      dispatch(clearStatisticFilter());
      dispatch(setStatistics([]));
      dispatch(setStatisticsTotal(0));
    };
  }, [dispatch]);

  const handleIncreasePage = () => {
    const pageLimit = Math.ceil(statisticTotal / (statisticFilter.limit ?? DEFAULT_STATISTIC_LIMIT));
    const newPage = page ? page + 1 : 1;

    setPage(newPage <= pageLimit ? newPage : 1);
    dispatch(
      setStatisticFilter({
        ...statisticFilter,
        offset: newPage <= pageLimit ? statisticFilter.offset + statisticFilter.limit : 0,
      })
    );
  };

  const handleDecreasePage = () => {
    const maxPage = Math.ceil(statisticTotal / (statisticFilter.limit ?? DEFAULT_STATISTIC_LIMIT)) || 1;
    const newPage = page - 1;

    setPage(newPage >= 1 ? newPage : maxPage);
    dispatch(
      setStatisticFilter({
        ...statisticFilter,
        offset: newPage >= 1 ? statisticFilter.offset - statisticFilter.limit : (maxPage - 1) * statisticFilter.limit,
      })
    );
  };

  const handleChangeLimit = (newLimit: number) => {
    setPage(1);
    dispatch(
      setStatisticFilter({
        ...statisticFilter,
        limit: newLimit,
        offset: 0,
      })
    );
  };
  return {
    states: {
      rows,
      page,
      isLoading,
      limit: statisticFilter.limit,
    },
    handlers: {
      handleIncreasePage,
      handleDecreasePage,
      handleChangeLimit,
    },
  };
}

export default usePersonalStatisticRowsAndPagination;
