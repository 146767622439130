import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  isShow: boolean;
  coords: {
    x: number;
    y: number;
  };
  children: React.ReactNode;
};

const SelectOptionsPortal = ({ isShow, coords, children }: Props) => {
  const element = useRef(document.createElement('div'));

  useEffect(() => {
    const id = `selectOptionsPortal-${Math.random()}`;
    const div = element.current;

    div.id = id;
    div.style.position = 'absolute';
    div.style.top = `${coords.y}px`;
    div.style.left = `${coords.x}px`;
    div.style.zIndex = '10000'; // 9999 = modalWindow
    document.body.insertAdjacentElement('beforeend', div);

    return () => {
      div.parentNode?.removeChild(div);
    };
  }, [coords, element]);

  return isShow && element ? ReactDOM.createPortal(children, element.current) : null;
};

export default React.memo(SelectOptionsPortal);
