export default class Stack<T> {
  constructor(private data: T[] = [], private capacity: number = Number.POSITIVE_INFINITY) {}

  push(item: T): void {
    if (this.size() === this.capacity) {
      throw Error('Stack has reached max capacity');
    }

    this.data.push(item);
  }

  pop(): T | undefined {
    return this.data.pop();
  }

  peek(): T | undefined {
    const size = this.size();

    return size > 0 ? this.data[size - 1] : undefined;
  }

  size(): number {
    return this.data.length;
  }

  getArray(): T[] {
    return this.data;
  }
}
