import React, { useState, ChangeEvent, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as Search } from 'assets/img/search_page.svg';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';

import SelectOptionsPortal from './components/selectOptions/selectOptions';

import { useSetTitleForWideListItem } from 'utils/useSetTitleForWideListItem';
import { useOutsideClickHandler } from 'utils/hooks/useOutsideClickHandler';

import styles from './select.module.scss';

const cx = classNames.bind(styles);

export interface Option {
  value: string | number;
  label: string | number;
  data?: Record<string, unknown>;
  selected?: boolean;
}

export interface SelectProps {
  label: string;
  placeholder: string;

  options: Option[];
  value: string | number;

  isRequired?: boolean;
  isValueError?: boolean;
  isGood?: boolean;

  withSearchIcon?: boolean;
  withCalendarIcon?: boolean;
  hideCloseIcon?: boolean;

  showOptions?: boolean;

  disabled?: boolean;

  handleChange?: (value: string) => void;

  style?: string;
  containerStyle?: string;
  optionsContainerStyle?: string;
  selectedOptionStyle?: string;

  noWrappedItems?: string[]; // определяет какие элементы опций не переносить на другую строку
  withoutCloseIcon?: boolean;
}

type SelectContainerType = {
  x: number;
  y: number;
  height: number;
  width: number;
};

const SelectCustom = ({
  label,
  placeholder,
  options,
  value,
  isRequired = false,
  isValueError,
  isGood,
  hideCloseIcon,
  disabled,
  style,
  containerStyle,
  optionsContainerStyle,
  selectedOptionStyle,
  noWrappedItems,
  withoutCloseIcon,
  handleChange,
}: SelectProps) => {
  const [showOptions, setShowOptions] = useState(false); // флаг показа опций
  const [showingItems, setShowingItems] = useState(options); // показываемые опции

  const selectContainerRef = React.createRef<HTMLDivElement>(); // контейнер c input
  const optionsListRef = React.createRef<HTMLUListElement>(); // ul опций

  // определение параметров селекта (для дальнейшей отрисовки опций)
  const [selectParams, setSelectParams] = useState<SelectContainerType | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClickHandler(wrapperRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  const getSelectParams = useCallback(() => {
    if (selectContainerRef) {
      const selectContainer = selectContainerRef.current;

      if (selectContainer) {
        const { x, y, height, width } = selectContainer.getBoundingClientRect();

        setSelectParams({ x, y, height, width });
      }
    }
  }, [selectContainerRef]);

  useEffect(() => {
    if (showOptions) {
      if (!selectParams) {
        getSelectParams();
      }
    } else {
      setSelectParams(null);
    }
  }, [getSelectParams, selectParams, showOptions]);

  useEffect(() => {
    if (showOptions) {
      setShowOptions(true);
      setShowingItems(options);
    }
  }, [options, showOptions]);

  // установка троеточия и атрибута title для элементов в
  // списке опций, которые не умещаются по ширине
  useSetTitleForWideListItem({
    wrapperListRef: optionsListRef,
    excludeWrappedItems: noWrappedItems || [],
  });

  useEffect(() => {
    setShowingItems(options);
  }, [options]);

  // обработчик фильтра опций
  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setShowingItems(
      ev.target.value
        ? options.filter(opt => opt.label.toString().toLowerCase().includes(ev.target.value))
        : options.map(opt => ({ ...opt }))
    );
    setShowOptions(true);
  };

  // обработчик выбора опций
  const handleItemClick = (value: string) => {
    if (handleChange) {
      handleChange(value);
    }
    setShowOptions(false);
  };

  // обработчик клика показа опций
  const handleWrapperClick = () => {
    if (!showOptions) {
      setShowOptions(!showOptions);
    }
  };

  const wrapperClasses = cx(styles.wrapper, style, {
    [styles.wrapperFocused]: (showOptions && !disabled) || showOptions,
    [styles.wrapperWarning]: isValueError,
    [styles.wrapperDisabled]: disabled,
  });
  const iconClasses = cx(styles.iconBase, {
    [styles.iconBaseFocused]: showOptions,
    [styles.iconBaseDisabled]: disabled,
  });
  const labelClasses = cx(styles.label, {
    [styles.labelFocused]: showOptions && !disabled,
    [styles.labelDisabled]: disabled,
  });

  const showValue = options.find(opt => opt.value === value)?.label.toString();
  const selectOptionsIsShow = showOptions && selectParams && !disabled && !!options.length;

  const renderPortal = () => (
    <SelectOptionsPortal
      coords={{ x: selectParams?.x ?? 0, y: (selectParams?.y ?? 0) + (selectParams?.height ?? 0) }}
      isShow={showOptions}
    >
      <ul
        className={cx(styles.optionsList, optionsContainerStyle)}
        style={{ width: selectParams ? `${selectParams.width}px` : `100%` }}
        ref={optionsListRef}
      >
        {showingItems.map((item, index) => (
          <li
            key={`option_item_${index}-${item.value}`}
            className={styles.optionsListItem}
            onMouseDown={() => handleItemClick(String(item.value))}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </SelectOptionsPortal>
  );

  return (
    <div className={cx(styles.container, containerStyle)} ref={wrapperRef}>
      <div className={wrapperClasses} onClick={handleWrapperClick} ref={selectContainerRef}>
        {isRequired && <Warning className={styles.iconWarning} />}
        <Search className={iconClasses} />
        <div className={labelClasses}>{label}</div>
        <div>
          {value && showValue ? (
            <div className={cx(styles.selectedOption, selectedOptionStyle)}>
              <span>{showValue.length > 30 ? showValue.slice(0, 31) + '...' : showValue}</span>
              {!withoutCloseIcon && (
                <CloseIcon
                  className={cx(styles.selectedOptionCloseIcon, {
                    [styles.selectedOptionCloseIconHide]: hideCloseIcon,
                  })}
                  onClick={() => handleItemClick('')}
                />
              )}
            </div>
          ) : (
            <input
              type="text"
              placeholder={placeholder}
              className={cx(styles.input, { [styles.isGood]: isGood })}
              required={isRequired}
              disabled={disabled || false}
              onChange={handleInputChange}
            />
          )}
        </div>
      </div>
      {selectOptionsIsShow && renderPortal()}
    </div>
  );
};

export default React.memo(SelectCustom);
