import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'reducers';
import {
  clearMessagesPagination,
  fetchMessages,
  setSelectedMessages,
  setIsMessagesTableShow,
  setMessagesNeedUpdate,
  setMessages,
  removeMessages,
  removeChosenMessage,
  fetchOneMessage,
} from 'reducers/messages';

import AccessEntity from 'utils/accessEntity';

export default function useMessagesTable() {
  const dispatch = useDispatch();

  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);
  const { messages, tableNeedUpdate, selectedMessages } = useSelector((state: RootState) => state.messages);

  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

  const headCheckboxStatus = selectedMessages.length === messages.length && selectedMessages.length > 0;
  const headCheckboxStatusIndeterminate = selectedMessages.length > 0 && selectedMessages.length < messages.length;

  useEffect(() => {
    dispatch(clearMessagesPagination());
    dispatch(fetchMessages());

    return () => {
      dispatch(setMessages([]));
      dispatch(setMessagesNeedUpdate(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (tableNeedUpdate) {
      dispatch(fetchMessages());
    }
  }, [dispatch, tableNeedUpdate]);

  const handleCheckAll = () => {
    if (headCheckboxStatus) {
      dispatch(setSelectedMessages([]));
    } else {
      dispatch(setSelectedMessages(messages.map(message => message.id)));
    }
  };

  const handleCheckItem = (id: string) => {
    const newSelectedMessages = [...selectedMessages];

    if (newSelectedMessages.includes(id)) {
      const foundIndex = newSelectedMessages.indexOf(id);

      if (foundIndex > -1) {
        newSelectedMessages.splice(foundIndex, 1);
      }
    } else {
      newSelectedMessages.push(id);
    }
    dispatch(setSelectedMessages(newSelectedMessages));
  };

  const handleClickName = (event: React.MouseEvent, id: string) => {
    handleCheckItem(id);
    setMouseCoords({ x: event.pageX, y: event.pageY });
    if (!isPopupShow) {
      setIsPopupShow(true);
    }
    dispatch(fetchOneMessage(id));
  };

  const handleClosePopup = () => {
    setIsPopupShow(false);
    dispatch(removeChosenMessage());
  };

  const handleSave = () => {
    // TODO: after backend api
  };

  const handleClose = () => {
    dispatch(setSelectedMessages([]));
    dispatch(clearMessagesPagination());
    dispatch(setIsMessagesTableShow(false));
  };

  const handleDeleteItems = () => {
    setDeletedIds(selectedMessages);
  };

  const handleDeleteAlertCancel = () => {
    setDeletedIds([]);
  };

  const handleDeleteAlertContinue = () => {
    if (deletedIds.length && messagesAccess.isAllowDelete()) {
      dispatch(removeMessages(deletedIds.map(id => Number(id))));
    }
    setDeletedIds([]);
  };

  return {
    states: {
      deletedIds,
      headCheckboxStatus,
      headCheckboxStatusIndeterminate,
      isPopupShow,
      mouseCoords,
    },
    handlers: {
      handleCheckAll,
      handleCheckItem,
      handleClickName,
      handleClosePopup,
      handleSave,
      handleClose,
      handleDeleteItems,
      handleDeleteAlertCancel,
      handleDeleteAlertContinue,
    },
  };
}
