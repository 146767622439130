import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Map from 'components/map/map';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import Content from 'components/content/content';
import Modal from 'components/common/modal/modal';
import ModalAlert from 'components/common/modal/alert';
import Player from 'components/tracks/components/trackPlayer/trackPlayer';
import { AUTH_LOGIN_ROUTE } from 'components/auth/utils/consts';

import { WebSocketContext } from 'contexts/websocketContext';

import { RootState } from 'reducers';
import { fetchInitialData } from 'reducers/common';
import { setAccessToken, setIsAuth, logOut } from 'reducers/auth';
import { clearUserData } from 'reducers/user';

import styles from './index.module.scss';

const MainPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const t = useFormatMessage();

  const ws = useContext(WebSocketContext);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    let timeout: NodeJS.Timeout | null = null;

    if (token) {
      dispatch(setAccessToken(token));
      dispatch(setIsAuth(true));

      if (ws) {
        timeout = setTimeout(() => {
          ws.sendMessage({ type: 'signup', token: token });
        }, 3000);
      } else {
        dispatch(fetchInitialData(location.pathname));
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch, location, ws]);

  // логаут пользователя после ответов от сервера с ошибкой 401
  const { isUnauthorizedReq, wialonSession } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isUnauthorizedReq && wialonSession) {
      // eslint-disable-next-line
      // @ts-ignore
      wialonSession.logout();

      dispatch(clearUserData());
      dispatch(logOut());
      toast.error(t('toast.auth.auth.error.text'));
      history.push(AUTH_LOGIN_ROUTE);
      sessionStorage.clear();
    }
  }, [dispatch, history, t, ws, wialonSession, isUnauthorizedReq]);

  return (
    <div className={styles.itemsWrapper}>
      <Header />
      <Content>
        <Map />
      </Content>
      <Footer />
      <Modal />
      <ModalAlert />
      {location.pathname === '/main/tracks' && <Player />}
    </div>
  );
};

export default MainPage;
