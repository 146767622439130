import Stack from '../common/stack';

export const UNGROUPED_KEY = '__ungrouped';

export interface TreeGroupNode<TItem extends Record<string, unknown>> {
  key: string;
  groupBy: keyof TItem | null;
  items: TItem[];
  children: {
    [key: string]: TreeGroupNode<TItem>[];
  };
}

/**
 *  example of config
 *  - prop1      (offset 0)
 *    - prop2    (offset 1)
 *      - prop3  (offset 2)
 *    - prop4    (offset 1)
 *      - prop5  (offset 2)
 *  - prop6      (offset 0)
 */
export interface TreeGroupConfigItem<TItem extends Record<string, unknown>> {
  groupBy: keyof TItem; // property to group by
  offset: number; // nesting level
  isActive: boolean; // if false skip this level and all nested levels (even if they are active)
}

function addGroupToNode<TItem extends Record<string, unknown>>(
  node: TreeGroupNode<TItem>[],
  index: number,
  groupBy: keyof TItem,
  groupValue: string,
  parentKey: string
): TreeGroupNode<TItem>[] {
  if (!node[index]) {
    node[index] = {
      key: `${parentKey}__${index}`,
      groupBy,
      items: [],
      children: {},
    };
  }

  if (!node[index].children[groupValue]) {
    node[index].children[groupValue] = [];
  }

  return node[index].children[groupValue];
}

function addItemToGroup<TItem extends Record<string, unknown>>(group: TreeGroupNode<TItem>[], item: TItem): void {
  if (!group.length) {
    group.push({
      key: '',
      groupBy: null,
      items: [],
      children: {},
    });
  }

  group[0].items.push(item);
}

function parseConfig<TItem extends Record<string, unknown>>(config: TreeGroupConfigItem<TItem>[]) {
  const result: TreeGroupConfigItem<TItem>[] = [];
  const configIndexes: number[] = [];

  const configIndexesCounter = new Stack<number>();
  let lastInactiveOffset: number | null = null;

  for (const configItem of config) {
    if (typeof lastInactiveOffset === 'number' && configItem.offset > lastInactiveOffset) {
      continue;
    }

    if (!configItem.isActive) {
      lastInactiveOffset = configItem.offset;
    } else {
      lastInactiveOffset = null;

      if (configItem.offset > configIndexesCounter.size() - 1) {
        configIndexesCounter.push(0);
      } else {
        while (configItem.offset < configIndexesCounter.size() - 1) {
          configIndexesCounter.pop();
        }

        const previousIndex = configIndexesCounter.pop();
        configIndexesCounter.push(previousIndex! + 1);
      }

      configIndexes.push(configIndexesCounter.peek()!);
      result.push(configItem);
    }
  }

  return [result, configIndexes] as const;
}

export default function treeGroupBy<TItem extends Record<string, unknown>>(
  items: TItem[],
  initialConfig: TreeGroupConfigItem<TItem>[]
): readonly [TreeGroupNode<TItem>[], { [groupKey: string]: undefined }] {
  // configIndexes will store indexes of each group in its nesting level
  const [config, configIndexes] = parseConfig<TItem>(initialConfig);

  const result: TreeGroupNode<TItem>[] = [];
  const allGroupKeys: { [groupKey: string]: undefined } = {};

  // stack will store links to child groups
  let stack = new Stack<TreeGroupNode<TItem>[]>([result]);
  let nodeKeysStack = new Stack<string>(['']);

  // iterate items
  for (const item of items) {
    // iterate config
    for (let i = 0; i < config.length; i++) {
      const itemGroupValue = item[config[i].groupBy];
      const groupByValue = typeof itemGroupValue === 'string' ? itemGroupValue : UNGROUPED_KEY;

      if (config[i].offset === stack.size() - 1) {
        // add nested group
        const currentGroup = stack.peek();
        const currentNodeKey = nodeKeysStack.peek()!;

        if (currentGroup) {
          const newGroup = addGroupToNode(
            currentGroup,
            configIndexes[i],
            config[i].groupBy,
            groupByValue,
            currentNodeKey
          );

          const newGroupKey = `${currentNodeKey}__${configIndexes[i]}__${groupByValue}`;
          allGroupKeys[newGroupKey] = undefined;

          nodeKeysStack.push(newGroupKey);
          stack.push(newGroup);
        }
      } else {
        // add item to group
        const currentGroup = stack.peek();

        if (currentGroup) {
          addItemToGroup<TItem>(currentGroup, item);
        }

        // going up the tree
        while (config[i].offset !== stack.size() - 1) {
          stack.pop();
          nodeKeysStack.pop();
        }

        // add nested group
        const parentGroup = stack.peek();
        const parentNodeKey = nodeKeysStack.peek()!;

        if (parentGroup) {
          const newGroup = addGroupToNode(
            parentGroup,
            configIndexes[i],
            config[i].groupBy,
            groupByValue,
            parentNodeKey
          );

          const newGroupKey = `${parentNodeKey}__${configIndexes[i]}__${groupByValue}`;
          allGroupKeys[newGroupKey] = undefined;

          nodeKeysStack.push(newGroupKey);
          stack.push(newGroup);
        }
      }
    }

    // finally add an item for last config node
    const currentGroup = stack.peek();

    if (currentGroup) {
      addItemToGroup<TItem>(currentGroup, item);
    }

    // reset stack
    stack = new Stack([result]);
    nodeKeysStack = new Stack(['']);
  }

  return [result, allGroupKeys] as const;
}
