export default {
  en: {
    'poi.button-block.btn.label': 'Create',

    'poi.list.header.name.text': 'Name',
    'poi.list.header.tooltip.turn.text': 'Enable/disable all POIs',
    'poi.list.header.tooltip.view.text': 'Show POI on map',
    'poi.list.header.tooltip.change.text': 'Change POI',

    'poi.list.drop-btn.poi-type.videocamera.text': 'Video camera',
    'poi.list.drop-btn.poi-type.gazanalyser.text': 'Gas analyzer',
    'poi.list.drop-btn.poi-type.sensor.text': 'Sensor',
    'poi.list.drop-btn.poi-type.equipment.text': 'Equipment',
    'poi.list.drop-btn.poi-type.incident.text': 'Incident',
    'poi.list.drop-btn.poi-type.free-point.text': 'Custom point',

    'poi.cards.videocamera.header.label': 'Video camera card',
    'poi.cards.gazanalyser.header.label': 'Gas analyzer card',
    'poi.cards.sensor.header.label': 'Sensor Card',
    'poi.cards.equipment.header.label': 'Equipment Card',
    'poi.cards.incident.header.label': 'Incident Card',
    'poi.cards.free-point.header.label': 'Free point card',

    'poi.card.footer.btn.delete.label': 'Delete',
    'poi.card.footer.btn.cancel.label': 'Cancel',
    'poi.card.footer.btn.save.label': 'Save',
    'poi.card.footer.btn.update.label': 'Update',

    'poi.card.alert.header.label': 'Close card',
    'poi.card.info.text': 'There are unsaved data on the form.',

    'poi.card.alert.header.delete.label': 'Delete poi',
    'poi.card.delete.text': 'A deleted POI cannot be recovered. Are you sure you want to delete POI?',

    'poi.card.videocamera.field.name.label': 'Name',
    'poi.card.videocamera.field.name.placeholder': 'Enter the name',
    'poi.card.videocamera.field.desc.label': 'Description',
    'poi.card.videocamera.field.desc.placeholder': 'Enter a description',
    'poi.card.videocamera.field.model.label': 'Model',
    'poi.card.videocamera.field.model.placeholder': 'Select from the list',
    'poi.card.videocamera.field.link.label': 'Link to data stream',
    'poi.card.videocamera.field.link.placeholder': 'Enter link',
    'poi.card.videocamera.field.coords.label': 'Location coordinates',
    'poi.card.videocamera.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.videocamera.field.angle.label': 'Shooting angle, degrees',
    'poi.card.videocamera.field.angle.placeholder': 'Enter the cameras shooting angle',
    'poi.card.videocamera.field.viewing.label': 'View range, meters',
    'poi.card.videocamera.field.viewing.placeholder': 'Enter the viewing range',
    'poi.card.videocamera.field.direction.label': 'Tracking direction, degrees',
    'poi.card.videocamera.field.direction.placeholder': 'Enter tracking direction',

    'poi.card.gazanalyzer.field.name.label': 'Name',
    'poi.card.gazanalyzer.field.name.placeholder': 'Enter the name',
    'poi.card.gazanalyzer.field.desc.label': 'Description',
    'poi.card.gazanalyzer.field.desc.placeholder': 'Enter a description',
    'poi.card.gazanalyzer.field.model.label': 'Model',
    'poi.card.gazanalyzer.field.model.placeholder': 'Select from the list',
    'poi.card.gazanalyzer.field.link.label': 'Link to data stream',
    'poi.card.gazanalyzer.field.link.placeholder': 'Enter link',
    'poi.card.gazanalyzer.field.coords.label': 'Location coordinates',
    'poi.card.gazanalyzer.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.gazanalyzer.field.sensors.label': 'Sensors',
    'poi.card.gazanalyzer.field.sensors.placeholder': 'Specify sensors',
    'poi.card.gazanalyzer.field.gazes.title.text': 'Analyzed gases',
    'poi.card.gazanalyzer.field.gazes.btn.add.label': 'Add gas',
    'poi.card.gazanalyzer.field.gazes.table.header.gaz.text': 'Gas',
    'poi.card.gazanalyzer.field.gazes.table.header.measurement.text': 'Units',
    'poi.card.gazanalyzer.field.gazes.table.header.range.text': 'Range',
    'poi.card.gazanalyzer.field.gazes.table.header.limit.text': 'Limit',
    'poi.card.gazanalyzer.field.last-date.label': 'Date of last verification',
    'poi.card.gazanalyzer.field.last-date.placeholder': 'Specify the date of the last verification',
    'poi.card.gazanalyzer.field.last-date.time-caption.text': 'Time',
    'poi.card.gazanalyzer.field.next-date.label': 'Date of next verification',
    'poi.card.gazanalyzer.field.next-date.placeholder': 'Indicate the date of the next verification',
    'poi.card.gazanalyzer.field.next-date.time-caption.text': 'Time',

    'poi.card.sensor.field.name.label': 'Name',
    'poi.card.sensor.field.name.placeholder': 'Enter the name',
    'poi.card.sensor.field.desc.label': 'Description',
    'poi.card.sensor.field.desc.placeholder': 'Enter a description',
    'poi.card.sensor.field.type.label': 'Type',
    'poi.card.sensor.field.type.placeholder': 'Select from the list',
    'poi.card.sensor.field.model.label': 'Model',
    'poi.card.sensor.field.model.placeholder': 'Select from the list',
    'poi.card.sensor.field.link.label': 'Link to data stream',
    'poi.card.sensor.field.link.placeholder': 'Enter link',
    'poi.card.sensor.field.coords.label': 'Location coordinates',
    'poi.card.sensor.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.sensor.field.distance.label': 'Measurement range',
    'poi.card.sensor.field.distance.placeholder.from.text': 'From',
    'poi.card.sensor.field.distance.placeholder.to.text': 'to',
    'poi.card.sensor.field.limit.label': 'Limit value',
    'poi.card.sensor.field.limit.placeholder': 'Limit',
    'poi.card.sensor.field.unit-measurement.label': 'Units',
    'poi.card.sensor.field.unit-measurement.placeholder': 'Please specify units',
    'poi.card.sensor.field.last-date.label': 'Date of last verification',
    'poi.card.sensor.field.last-date.placeholder': 'Specify the date of the last verification',
    'poi.card.sensor.field.last-date.time.caption': 'Time',
    'poi.card.sensor.field.next-date.label': 'Date of next verification',
    'poi.card.sensor.field.next-date.placeholder': 'Indicate the date of the next verification',
    'poi.card.sensor.field.next-date.time.caption': 'Time',

    'poi.card.equipment.field.name.label': 'Name',
    'poi.card.equipment.field.name.placeholder': 'Enter the name',
    'poi.card.equipment.field.desc.label': 'Description',
    'poi.card.equipment.field.desc.placeholder': 'Enter a description',
    'poi.card.equipment.field.type.label': 'Type',
    'poi.card.equipment.field.type.placeholder': 'Select from the list',
    'poi.card.equipment.field.model.label': 'Model',
    'poi.card.equipment.field.model.placeholder': 'Select from the list',
    'poi.card.equipment.field.coords.label': 'Location coordinates',
    'poi.card.equipment.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.equipment.field.last-date.label': 'Date of last verification',
    'poi.card.equipment.field.last-date.placeholder': 'Specify the date of the last verification',
    'poi.card.equipment.field.last-date.time.caption': 'Time',
    'poi.card.equipment.field.next-date.label': 'Date of next verification',
    'poi.card.equipment.field.next-date.placeholder': 'Indicate the date of the next verification',
    'poi.card.equipment.field.next-date.time.caption': 'Time',

    'poi.card.accident.field.name.label': 'Name',
    'poi.card.accident.field.name.placeholder': 'Enter the name',
    'poi.card.accident.field.desc.label': 'Description',
    'poi.card.accident.field.desc.placeholder': 'Enter a description',
    'poi.card.accident.field.type.label': 'Type',
    'poi.card.accident.field.type.placeholder': 'Select from the list',
    'poi.card.accident.field.coords.label': 'Location coordinates',
    'poi.card.accident.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.accident.field.next-date.label': 'Point validity period',
    'poi.card.accident.field.next-date.placeholder': 'Specify the period of validity of the point',
    'poi.card.accident.field.next-date.time.caption': 'Time',
    'poi.card.accident.field.auto-delete.text':
      'Automatically delete a point from the map at the end of the points validity period',

    'poi.card.free-point.field.name.label': 'Name',
    'poi.card.free-point.field.name.placeholder': 'Enter the name',
    'poi.card.free-point.field.desc.label': 'Description',
    'poi.card.free-point.field.desc.placeholder': 'Enter a description',
    'poi.card.free-point.field.coords.label': 'Location coordinates',
    'poi.card.free-point.field.coords.placeholder': 'Enter or mark on the map',
    'poi.card.free-point.field.next-date.label': 'Point validity period',
    'poi.card.free-point.field.next-date.placeholder': 'Specify the period of validity of the point',
    'poi.card.free-point.field.next-date.time.caption': 'Time',
    'poi.card.free-point.field.auto-delete.text':
      'Automatically delete a point from the map at the end of the points validity period',
  },
  ru: {
    'poi.button-block.btn.label': 'Создать',

    'poi.list.header.name.text': 'Название',
    'poi.list.header.tooltip.turn.text': 'Включить/Отключить все POI',
    'poi.list.header.tooltip.view.text': 'Показать POI на карте',
    'poi.list.header.tooltip.change.text': 'Изменение POI',

    'poi.list.drop-btn.poi-type.videocamera.text': 'Видеокамера',
    'poi.list.drop-btn.poi-type.gazanalyser.text': 'Газоанализатор',
    'poi.list.drop-btn.poi-type.sensor.text': 'Датчик',
    'poi.list.drop-btn.poi-type.equipment.text': 'Оснащение',
    'poi.list.drop-btn.poi-type.incident.text': 'Происшествие',
    'poi.list.drop-btn.poi-type.free-point.text': 'Свободная точка',

    'poi.cards.videocamera.header.label': 'Карточка видеокамеры',
    'poi.cards.gazanalyser.header.label': 'Карточка газанализатора',
    'poi.cards.sensor.header.label': 'Карточка датчика',
    'poi.cards.equipment.header.label': 'Карточка оснащения',
    'poi.cards.incident.header.label': 'Карточка происшествия',
    'poi.cards.free-point.header.label': 'Карточка свободной точки',

    'poi.card.footer.btn.delete.label': 'Удалить',
    'poi.card.footer.btn.cancel.label': 'Отмена',
    'poi.card.footer.btn.save.label': 'Сохранить',
    'poi.card.footer.btn.update.label': 'Обновить',

    'poi.card.alert.header.label': 'Закрытие карточки',
    'poi.card.info.text': 'На форме остались несохраненные данные.',

    'poi.card.alert.header.delete.label': 'Удаление poi',
    'poi.card.delete.text': 'Удаленную POI нельзя будет восстановить. Вы действительно хотите удалить POI?',

    'poi.card.videocamera.field.name.label': 'Название',
    'poi.card.videocamera.field.name.placeholder': 'Введите название',
    'poi.card.videocamera.field.desc.label': 'Описание',
    'poi.card.videocamera.field.desc.placeholder': 'Введите описание',
    'poi.card.videocamera.field.model.label': 'Модель',
    'poi.card.videocamera.field.model.placeholder': 'Выберите из списка',
    'poi.card.videocamera.field.link.label': 'Ссылка на поток данных',
    'poi.card.videocamera.field.link.placeholder': 'Введите ссылку',
    'poi.card.videocamera.field.coords.label': 'Координаты месторасположения',
    'poi.card.videocamera.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.videocamera.field.angle.label': 'Угол съемки, градусы',
    'poi.card.videocamera.field.angle.placeholder': 'Введите значение',
    'poi.card.videocamera.field.viewing.label': 'Дальность обзора, метры',
    'poi.card.videocamera.field.viewing.placeholder': 'Введите значение',
    'poi.card.videocamera.field.direction.label': 'Направление отслеживания, градусы',
    'poi.card.videocamera.field.direction.placeholder': 'Введите значение',

    'poi.card.gazanalyzer.field.name.label': 'Название',
    'poi.card.gazanalyzer.field.name.placeholder': 'Введите название',
    'poi.card.gazanalyzer.field.desc.label': 'Описание',
    'poi.card.gazanalyzer.field.desc.placeholder': 'Введите описание',
    'poi.card.gazanalyzer.field.model.label': 'Модель',
    'poi.card.gazanalyzer.field.model.placeholder': 'Выберите из списка',
    'poi.card.gazanalyzer.field.link.label': 'Ссылка на поток данных',
    'poi.card.gazanalyzer.field.link.placeholder': 'Введите ссылку',
    'poi.card.gazanalyzer.field.coords.label': 'Координаты месторасположения',
    'poi.card.gazanalyzer.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.gazanalyzer.field.sensors.label': 'Сенсоры',
    'poi.card.gazanalyzer.field.sensors.placeholder': 'Укажите сенсоры',
    'poi.card.gazanalyzer.field.gazes.title.text': 'Анализируемые газы',
    'poi.card.gazanalyzer.field.gazes.btn.add.label': 'Добавить газ',
    'poi.card.gazanalyzer.field.gazes.table.header.gaz.text': 'Газ',
    'poi.card.gazanalyzer.field.gazes.table.header.measurement.text': 'Ед. измерения',
    'poi.card.gazanalyzer.field.gazes.table.header.range.text': 'Диапазон',
    'poi.card.gazanalyzer.field.gazes.table.header.limit.text': 'Предел',
    'poi.card.gazanalyzer.field.last-date.label': 'Дата последней поверки',
    'poi.card.gazanalyzer.field.last-date.placeholder': 'Укажите дату последней поверки',
    'poi.card.gazanalyzer.field.last-date.time-caption.text': 'Время',
    'poi.card.gazanalyzer.field.next-date.label': 'Дата следующей поверки',
    'poi.card.gazanalyzer.field.next-date.placeholder': 'Укажите дату следующей поверки',
    'poi.card.gazanalyzer.field.next-date.time-caption.text': 'Время',

    'poi.card.sensor.field.name.label': 'Название',
    'poi.card.sensor.field.name.placeholder': 'Введите название',
    'poi.card.sensor.field.desc.label': 'Описание',
    'poi.card.sensor.field.desc.placeholder': 'Введите описание',
    'poi.card.sensor.field.type.label': 'Тип',
    'poi.card.sensor.field.type.placeholder': 'Выберите из списка',
    'poi.card.sensor.field.model.label': 'Модель',
    'poi.card.sensor.field.model.placeholder': 'Выберите из списка',
    'poi.card.sensor.field.link.label': 'Ссылка на поток данных',
    'poi.card.sensor.field.link.placeholder': 'Введите ссылку',
    'poi.card.sensor.field.coords.label': 'Координаты месторасположения',
    'poi.card.sensor.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.sensor.field.distance.label': 'Диапазон измерений',
    'poi.card.sensor.field.distance.placeholder.from.text': 'От',
    'poi.card.sensor.field.distance.placeholder.to.text': 'До',
    'poi.card.sensor.field.limit.label': 'Предельное значение',
    'poi.card.sensor.field.limit.placeholder': 'Предел',
    'poi.card.sensor.field.unit-measurement.label': 'Единица измерения',
    'poi.card.sensor.field.unit-measurement.placeholder': 'Укажите единицу измерения',
    'poi.card.sensor.field.last-date.label': 'Дата последней поверки',
    'poi.card.sensor.field.last-date.placeholder': 'Укажите дату последней поверки',
    'poi.card.sensor.field.last-date.time.caption': 'Время',
    'poi.card.sensor.field.next-date.label': 'Дата следующей поверки',
    'poi.card.sensor.field.next-date.placeholder': 'Укажите дату следующей поверки',
    'poi.card.sensor.field.next-date.time.caption': 'Время',

    'poi.card.equipment.field.name.label': 'Название',
    'poi.card.equipment.field.name.placeholder': 'Введите название',
    'poi.card.equipment.field.desc.label': 'Описание',
    'poi.card.equipment.field.desc.placeholder': 'Введите описание',
    'poi.card.equipment.field.type.label': 'Тип',
    'poi.card.equipment.field.type.placeholder': 'Выберите из списка',
    'poi.card.equipment.field.model.label': 'Модель',
    'poi.card.equipment.field.model.placeholder': 'Выберите из списка',
    'poi.card.equipment.field.coords.label': 'Координаты месторасположения',
    'poi.card.equipment.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.equipment.field.last-date.label': 'Дата последней поверки',
    'poi.card.equipment.field.last-date.placeholder': 'Укажите дату последней поверки',
    'poi.card.equipment.field.last-date.time.caption': 'Время',
    'poi.card.equipment.field.next-date.label': 'Дата следующей поверки',
    'poi.card.equipment.field.next-date.placeholder': 'Укажите дату следующей поверки',
    'poi.card.equipment.field.next-date.time.caption': 'Время',

    'poi.card.accident.field.name.label': 'Название',
    'poi.card.accident.field.name.placeholder': 'Введите название',
    'poi.card.accident.field.desc.label': 'Описание',
    'poi.card.accident.field.desc.placeholder': 'Введите описание',
    'poi.card.accident.field.type.label': 'Тип',
    'poi.card.accident.field.type.placeholder': 'Выберите из списка',
    'poi.card.accident.field.coords.label': 'Координаты месторасположения',
    'poi.card.accident.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.accident.field.next-date.label': 'Период действия точки',
    'poi.card.accident.field.next-date.placeholder': 'Укажите период действия точки',
    'poi.card.accident.field.next-date.time.caption': 'Время',
    'poi.card.accident.field.auto-delete.text':
      'Автоматически удалять точку с карты по окончанию периода действия точки',

    'poi.card.free-point.field.name.label': 'Название',
    'poi.card.free-point.field.name.placeholder': 'Введите название',
    'poi.card.free-point.field.desc.label': 'Описание',
    'poi.card.free-point.field.desc.placeholder': 'Введите описание',
    'poi.card.free-point.field.coords.label': 'Координаты месторасположения',
    'poi.card.free-point.field.coords.placeholder': 'Введите или отметьте на карте',
    'poi.card.free-point.field.next-date.label': 'Период действия точки',
    'poi.card.free-point.field.next-date.placeholder': 'Укажите период действия точки',
    'poi.card.free-point.field.next-date.time.caption': 'Время',
    'poi.card.free-point.field.auto-delete.text':
      'Автоматически удалять точку с карты по окончанию периода действия точки',
  },
  zh: {
    'poi.button-block.btn.label': '创建',
    'poi.list.header.name.text': '名称',
    'poi.list.header.tooltip.turn.text': '启用/禁用所有兴趣点',
    'poi.list.header.tooltip.view.text': '在地图上显示兴趣点',
    'poi.list.header.tooltip.change.text': '更改兴趣点',
    'poi.list.drop-btn.poi-type.videocamera.text': '摄像机',
    'poi.list.drop-btn.poi-type.gazanalyser.text': '气体分析仪',
    'poi.list.drop-btn.poi-type.sensor.text': '传感器',
    'poi.list.drop-btn.poi-type.equipment.text': '装备',
    'poi.list.drop-btn.poi-type.incident.text': '事件',
    'poi.list.drop-btn.poi-type.free-point.text': '自由点',
    'poi.cards.videocamera.header.label': '相机卡',
    'poi.cards.gazanalyser.header.label': '气体分析卡',
    'poi.cards.sensor.header.label': '感应卡',
    'poi.cards.equipment.header.label': '装备卡',
    'poi.cards.incident.header.label': '意外卡',
    'poi.cards.free-point.header.label': '',
    'poi.card.footer.btn.delete.label': '删除',
    'poi.card.footer.btn.cancel.label': '取消',
    'poi.card.footer.btn.save.label': '保存',
    'poi.card.footer.btn.update.label': '更新',
    'poi.card.alert.header.label': '关闭卡片',
    'poi.card.info.text': '表单上有未保存的数据。',
    'poi.card.alert.header.delete.label': '消除POI',
    'poi.card.delete.text': '已消除的POI无法恢复。 您真的要消除POI吗？',
    'poi.card.videocamera.field.name.label': '名称',
    'poi.card.videocamera.field.name.placeholder': '输入名称',
    'poi.card.videocamera.field.desc.label': '描述',
    'poi.card.videocamera.field.desc.placeholder': '输入描述',
    'poi.card.videocamera.field.model.label': '模型',
    'poi.card.videocamera.field.model.placeholder': '从列表中选择',
    'poi.card.videocamera.field.link.label': '链接到数据流',
    'poi.card.videocamera.field.link.placeholder': '输入链接',
    'poi.card.videocamera.field.coords.label': '位置坐标',
    'poi.card.videocamera.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.videocamera.field.angle.label': '拍摄角度，度数',
    'poi.card.videocamera.field.angle.placeholder': '输入相机拍摄角度',
    'poi.card.videocamera.field.viewing.label': '可视范围，米',
    'poi.card.videocamera.field.viewing.placeholder': '输入可视角度',
    'poi.card.videocamera.field.direction.label': '跟踪方向，度数',
    'poi.card.videocamera.field.direction.placeholder': '输入跟踪方向',
    'poi.card.gazanalyzer.field.name.label': '名称',
    'poi.card.gazanalyzer.field.name.placeholder': '输入名称',
    'poi.card.gazanalyzer.field.desc.label': '描述',
    'poi.card.gazanalyzer.field.desc.placeholder': '输入描述',
    'poi.card.gazanalyzer.field.model.label': '模型',
    'poi.card.gazanalyzer.field.model.placeholder': '从列表中选择',
    'poi.card.gazanalyzer.field.link.label': '链接到数据流',
    'poi.card.gazanalyzer.field.link.placeholder': '输入链接',
    'poi.card.gazanalyzer.field.coords.label': '位置坐标',
    'poi.card.gazanalyzer.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.gazanalyzer.field.sensors.label': '气体传感器',
    'poi.card.gazanalyzer.field.sensors.placeholder': '选择传感器',
    'poi.card.gazanalyzer.field.gazes.title.text': '分析气体',
    'poi.card.gazanalyzer.field.gazes.btn.add.label': '添加气体',
    'poi.card.gazanalyzer.field.gazes.table.header.gaz.text': '气体',
    'poi.card.gazanalyzer.field.gazes.table.header.measurement.text': '单位',
    'poi.card.gazanalyzer.field.gazes.table.header.range.text': '范围',
    'poi.card.gazanalyzer.field.gazes.table.header.limit.text': '限制',
    'poi.card.gazanalyzer.field.last-date.label': '上次验证的日期',
    'poi.card.gazanalyzer.field.last-date.placeholder': '指定上次验证的日期',
    'poi.card.gazanalyzer.field.last-date.time-caption.text': '时间',
    'poi.card.gazanalyzer.field.next-date.label': '下次验证的日期',
    'poi.card.gazanalyzer.field.next-date.placeholder': '指定下次验证的日期',
    'poi.card.gazanalyzer.field.next-date.time-caption.text': '时间',
    'poi.card.sensor.field.name.label': '名称',
    'poi.card.sensor.field.name.placeholder': '输入名称',
    'poi.card.sensor.field.desc.label': '描述',
    'poi.card.sensor.field.desc.placeholder': '输入描述',
    'poi.card.sensor.field.type.label': '类型',
    'poi.card.sensor.field.type.placeholder': '从列表中选择',
    'poi.card.sensor.field.model.label': '模型',
    'poi.card.sensor.field.model.placeholder': '从列表中选择',
    'poi.card.sensor.field.link.label': '链接到数据流',
    'poi.card.sensor.field.link.placeholder': '输入链接',
    'poi.card.sensor.field.coords.label': '位置坐标',
    'poi.card.sensor.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.sensor.field.distance.label': '测量范围',
    'poi.card.sensor.field.distance.placeholder.from.text': '从',
    'poi.card.sensor.field.distance.placeholder.to.text': '至',
    'poi.card.sensor.field.limit.label': '极限值',
    'poi.card.sensor.field.limit.placeholder': '极限',
    'poi.card.sensor.field.unit-measurement.label': '单位',
    'poi.card.sensor.field.unit-measurement.placeholder': '请指定单位',
    'poi.card.sensor.field.last-date.label': '上次验证的日期',
    'poi.card.sensor.field.last-date.placeholder': '指定上次验证的日期',
    'poi.card.sensor.field.last-date.time.caption': '时间',
    'poi.card.sensor.field.next-date.label': '下次验证的日期',
    'poi.card.sensor.field.next-date.placeholder': '指定下次验证的日期',
    'poi.card.sensor.field.next-date.time.caption': '时间',
    'poi.card.equipment.field.name.label': '名称',
    'poi.card.equipment.field.name.placeholder': '输入名称',
    'poi.card.equipment.field.desc.label': '描述',
    'poi.card.equipment.field.desc.placeholder': '输入描述',
    'poi.card.equipment.field.type.label': '类型',
    'poi.card.equipment.field.type.placeholder': '从列表中选择',
    'poi.card.equipment.field.model.label': '模型',
    'poi.card.equipment.field.model.placeholder': '从列表中选择',
    'poi.card.equipment.field.coords.label': '位置坐标',
    'poi.card.equipment.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.equipment.field.last-date.label': '上次验证的日期',
    'poi.card.equipment.field.last-date.placeholder': '指定上次验证的日期',
    'poi.card.equipment.field.last-date.time.caption': '时间',
    'poi.card.equipment.field.next-date.label': '下次验证的日期',
    'poi.card.equipment.field.next-date.placeholder': '指定下次验证的日期',
    'poi.card.equipment.field.next-date.time.caption': '时间',
    'poi.card.accident.field.name.label': '名称',
    'poi.card.accident.field.name.placeholder': '输入名称',
    'poi.card.accident.field.desc.label': '描述',
    'poi.card.accident.field.desc.placeholder': '输入描述',
    'poi.card.accident.field.type.label': '类型',
    'poi.card.accident.field.type.placeholder': '从列表中选择',
    'poi.card.accident.field.coords.label': '位置坐标',
    'poi.card.accident.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.accident.field.next-date.label': '地点有效期',
    'poi.card.accident.field.next-date.placeholder': '指定地点有效期',
    'poi.card.accident.field.next-date.time.caption': '时间',
    'poi.card.accident.field.auto-delete.text': '在点的有效期结束时自动从地图中删除一个点',
    'poi.card.free-point.field.name.label': '名称',
    'poi.card.free-point.field.name.placeholder': '输入名称',
    'poi.card.free-point.field.desc.label': '描述',
    'poi.card.free-point.field.desc.placeholder': '输入描述',
    'poi.card.free-point.field.coords.label': '位置坐标',
    'poi.card.free-point.field.coords.placeholder': '在地图上输入或标记',
    'poi.card.free-point.field.next-date.label': '点有效期的时间',
    'poi.card.free-point.field.next-date.placeholder': '指定点有效期的时间',
    'poi.card.free-point.field.next-date.time.caption': '时间',
    'poi.card.free-point.field.auto-delete.text': '在点的有效期结束时自动从地图中删除一个点',
  },
  ar: {
    'poi.button-block.btn.label': 'إنشاء',
    'poi.list.header.name.text': 'اسم',
    'poi.list.header.tooltip.turn.text': 'تشغيل/إطفاء كافة POI',
    'poi.list.header.tooltip.view.text': 'عرض كل POI على الخريطة',
    'poi.list.header.tooltip.change.text': 'تغيير POI',
    'poi.list.drop-btn.poi-type.videocamera.text': 'كاميرا فيديو',
    'poi.list.drop-btn.poi-type.gazanalyser.text': 'محلل غاز',
    'poi.list.drop-btn.poi-type.sensor.text': 'مستشعر',
    'poi.list.drop-btn.poi-type.equipment.text': 'عُدَّة',
    'poi.list.drop-btn.poi-type.incident.text': 'حدث',
    'poi.list.drop-btn.poi-type.free-point.text': 'نقطة حرة',
    'poi.cards.videocamera.header.label': 'بطاقة كاميرا الفيديو',
    'poi.cards.gazanalyser.header.label': 'بطاقة محلّل الغاز',
    'poi.cards.sensor.header.label': 'بطاقة المستشعر',
    'poi.cards.equipment.header.label': 'بطاقة العدة',
    'poi.cards.incident.header.label': 'بطاقة الحدث',
    'poi.cards.free-point.header.label': 'بطاقة النقطة الحرة',
    'poi.card.footer.btn.delete.label': 'حذف',
    'poi.card.footer.btn.cancel.label': 'إلغاء',
    'poi.card.footer.btn.save.label': 'حفظ',
    'poi.card.footer.btn.update.label': 'تحديث',
    'poi.card.alert.header.label': 'شطب البطاقة',
    'poi.card.info.text': 'هناك بيانات غير محفوظة في النموذج',
    'poi.card.alert.header.delete.label': 'حذف POI',
    'poi.card.delete.text': 'لا يمكن استعادة Poi المحذوفة. هل أنت متأكد أنك تريد حذف POI؟',
    'poi.card.videocamera.field.name.label': 'اسم',
    'poi.card.videocamera.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.videocamera.field.desc.label': 'وصف',
    'poi.card.videocamera.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.videocamera.field.model.label': 'طراز',
    'poi.card.videocamera.field.model.placeholder': 'اختر من القائمة',
    'poi.card.videocamera.field.link.label': 'رابط تدفق البيانات',
    'poi.card.videocamera.field.link.placeholder': 'أكتب الرابط',
    'poi.card.videocamera.field.coords.label': 'إحداثيات الموقع',
    'poi.card.videocamera.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.videocamera.field.angle.label': 'زاوية التسجيل، درجات',
    'poi.card.videocamera.field.angle.placeholder': 'أكتب قيمة',
    'poi.card.videocamera.field.viewing.label': 'بُعد الرؤية، أمتار',
    'poi.card.videocamera.field.viewing.placeholder': 'أكتب قيمة',
    'poi.card.videocamera.field.direction.label': 'اتجاه التتبع، درجات',
    'poi.card.videocamera.field.direction.placeholder': 'أكتب قيمة',
    'poi.card.gazanalyzer.field.name.label': 'اسم',
    'poi.card.gazanalyzer.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.gazanalyzer.field.desc.label': 'وصف',
    'poi.card.gazanalyzer.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.gazanalyzer.field.model.label': 'طراز',
    'poi.card.gazanalyzer.field.model.placeholder': 'اختر من القائمة',
    'poi.card.gazanalyzer.field.link.label': 'رابط تدفق البيانات',
    'poi.card.gazanalyzer.field.link.placeholder': 'أكتب الرابط',
    'poi.card.gazanalyzer.field.coords.label': 'إحداثيات الموقع',
    'poi.card.gazanalyzer.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.gazanalyzer.field.sensors.label': 'أجهزة استشعار الغاز',
    'poi.card.gazanalyzer.field.sensors.placeholder': 'أذكر أجهزة الاستشعار',
    'poi.card.gazanalyzer.field.gazes.title.text': 'الغازات قيد التحليل',
    'poi.card.gazanalyzer.field.gazes.btn.add.label': 'إضافة غاز',
    'poi.card.gazanalyzer.field.gazes.table.header.gaz.text': 'غازات',
    'poi.card.gazanalyzer.field.gazes.table.header.measurement.text': 'وحدة القياس',
    'poi.card.gazanalyzer.field.gazes.table.header.range.text': 'النطاق',
    'poi.card.gazanalyzer.field.gazes.table.header.limit.text': 'الحد',
    'poi.card.gazanalyzer.field.last-date.label': 'تاريخ التفقد الأخير',
    'poi.card.gazanalyzer.field.last-date.placeholder': 'أذكر تاريخ التفقد الأخير',
    'poi.card.gazanalyzer.field.last-date.time-caption.text': 'الوقت',
    'poi.card.gazanalyzer.field.next-date.label': 'تاريخ التفقد التالي',
    'poi.card.gazanalyzer.field.next-date.placeholder': 'أذكر تاريخ التفقد التالي',
    'poi.card.gazanalyzer.field.next-date.time-caption.text': 'وقت',
    'poi.card.sensor.field.name.label': 'اسم',
    'poi.card.sensor.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.sensor.field.desc.label': 'وصف',
    'poi.card.sensor.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.sensor.field.type.label': 'Тип',
    'poi.card.sensor.field.type.placeholder': 'اختر من القائمة',
    'poi.card.sensor.field.model.label': 'طراز',
    'poi.card.sensor.field.model.placeholder': 'اختر من القائمة',
    'poi.card.sensor.field.link.label': 'رابط تدفق البيانات',
    'poi.card.sensor.field.link.placeholder': 'أكتب الرابط',
    'poi.card.sensor.field.coords.label': 'إحداثيات الموقع',
    'poi.card.sensor.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.sensor.field.distance.label': 'نطاق القياس',
    'poi.card.sensor.field.distance.placeholder.from.text': 'من',
    'poi.card.sensor.field.distance.placeholder.to.text': 'إلى',
    'poi.card.sensor.field.limit.label': 'القيمة القصوى',
    'poi.card.sensor.field.limit.placeholder': 'الحد الأقصى',
    'poi.card.sensor.field.unit-measurement.label': 'وحدة القياس',
    'poi.card.sensor.field.unit-measurement.placeholder': 'أذكر وحدة القياس',
    'poi.card.sensor.field.last-date.label': 'تاريخ التفقد الأخير',
    'poi.card.sensor.field.last-date.placeholder': 'أذكر تاريخ التفقد الأخير',
    'poi.card.sensor.field.last-date.time.caption': 'وقت',
    'poi.card.sensor.field.next-date.label': 'تاريخ التفقد التالي',
    'poi.card.sensor.field.next-date.placeholder': 'أذكر تاريخ التفقد التالي',
    'poi.card.sensor.field.next-date.time.caption': 'وقت',
    'poi.card.equipment.field.name.label': 'اسم',
    'poi.card.equipment.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.equipment.field.desc.label': 'وصف',
    'poi.card.equipment.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.equipment.field.type.label': 'Тип',
    'poi.card.equipment.field.type.placeholder': 'اختر من القائمة',
    'poi.card.equipment.field.model.label': 'طراز',
    'poi.card.equipment.field.model.placeholder': 'اختر من القائمة',
    'poi.card.equipment.field.coords.label': 'إحداثيات الموقع',
    'poi.card.equipment.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.equipment.field.last-date.label': 'تاريخ التفقد الأخير',
    'poi.card.equipment.field.last-date.placeholder': 'أذكر تاريخ التفقد الأخير',
    'poi.card.equipment.field.last-date.time.caption': 'وقت',
    'poi.card.equipment.field.next-date.label': 'تاريخ التفقد التالي',
    'poi.card.equipment.field.next-date.placeholder': 'أذكر تاريخ التفقد التالي',
    'poi.card.equipment.field.next-date.time.caption': 'وقت',
    'poi.card.accident.field.name.label': 'اسم',
    'poi.card.accident.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.accident.field.desc.label': 'وصف',
    'poi.card.accident.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.accident.field.type.label': 'Тип',
    'poi.card.accident.field.type.placeholder': 'اختر من القائمة',
    'poi.card.accident.field.coords.label': 'إحداثيات الموقع',
    'poi.card.accident.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.accident.field.next-date.label': 'مهلة سريان النقطة',
    'poi.card.accident.field.next-date.placeholder': 'أذكر مهلة سريان النقطة',
    'poi.card.accident.field.next-date.time.caption': 'وقت',
    'poi.card.accident.field.auto-delete.text': 'حذف النقطة من على الخريطة تلقائيًا عند انتهاء مهلة سريانها',
    'poi.card.free-point.field.name.label': 'اسم',
    'poi.card.free-point.field.name.placeholder': 'أكتب اسمًا',
    'poi.card.free-point.field.desc.label': 'وصف',
    'poi.card.free-point.field.desc.placeholder': 'أكتب الوصف',
    'poi.card.free-point.field.coords.label': 'إحداثيات الموقع',
    'poi.card.free-point.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'poi.card.free-point.field.next-date.label': 'مهلة سريان النقطة',
    'poi.card.free-point.field.next-date.placeholder': 'أذكر مهلة سريان النقطة',
    'poi.card.free-point.field.next-date.time.caption': 'وقت',
    'poi.card.free-point.field.auto-delete.text': 'حذف النقطة من على الخريطة تلقائيًا عند انتهاء مهلة سريانها',
  },
  de: {
    'poi.button-block.btn.label': 'Erstellen',
    'poi.list.header.name.text': 'Name',
    'poi.list.header.tooltip.turn.text': 'Alle POIs aktivieren/deaktivieren',
    'poi.list.header.tooltip.view.text': 'POI auf der Karte anzeigen',
    'poi.list.header.tooltip.change.text': 'Sonderziel ändern',
    'poi.list.drop-btn.poi-type.videocamera.text': 'Videokamera',
    'poi.list.drop-btn.poi-type.gazanalyser.text': 'Gasanalysator',
    'poi.list.drop-btn.poi-type.sensor.text': 'Sensor',
    'poi.list.drop-btn.poi-type.equipment.text': 'Ausrüstung',
    'poi.list.drop-btn.poi-type.incident.text': 'Inzident',
    'poi.list.drop-btn.poi-type.free-point.text': 'Freier Punkt',
    'poi.cards.videocamera.header.label': 'Camcorder-Karte',
    'poi.cards.gazanalyser.header.label': 'Gasanalysatorkarte',
    'poi.cards.sensor.header.label': 'Sensorkarte',
    'poi.cards.equipment.header.label': 'Ausrüstungskarte',
    'poi.cards.incident.header.label': 'Unfallkarte',
    'poi.cards.free-point.header.label': 'Kostenlose Punktekarte',
    'poi.card.footer.btn.delete.label': 'Löschen',
    'poi.card.footer.btn.cancel.label': 'Zurücksetzen',
    'poi.card.footer.btn.save.label': 'Speichern',
    'poi.card.footer.btn.update.label': 'Aktualisieren',
    'poi.card.alert.header.label': 'Schließen einer Karte',
    'poi.card.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'poi.card.alert.header.delete.label': 'POI löschen',
    'poi.card.delete.text':
      'Ein gelöschter POI kann nicht wiederhergestellt werden. Möchten Sie den POI wirklich löschen?',
    'poi.card.videocamera.field.name.label': 'Name',
    'poi.card.videocamera.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.videocamera.field.desc.label': 'Beschreibung',
    'poi.card.videocamera.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.videocamera.field.model.label': 'Modell',
    'poi.card.videocamera.field.model.placeholder': 'Aus der Liste wählen',
    'poi.card.videocamera.field.link.label': 'Link zum Datenstrom',
    'poi.card.videocamera.field.link.placeholder': 'Link eingeben',
    'poi.card.videocamera.field.coords.label': 'Standortkoordinaten',
    'poi.card.videocamera.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.videocamera.field.angle.label': 'Aufnahmewinkel, Grad',
    'poi.card.videocamera.field.angle.placeholder': 'Wert eingeben',
    'poi.card.videocamera.field.viewing.label': 'Sichtweite, Meter',
    'poi.card.videocamera.field.viewing.placeholder': 'Wert eingeben',
    'poi.card.videocamera.field.direction.label': 'Tracking-Richtung, Grad',
    'poi.card.videocamera.field.direction.placeholder': 'Wert eingeben',
    'poi.card.gazanalyzer.field.name.label': 'Name',
    'poi.card.gazanalyzer.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.gazanalyzer.field.desc.label': 'Beschreibung',
    'poi.card.gazanalyzer.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.gazanalyzer.field.model.label': 'Modell',
    'poi.card.gazanalyzer.field.model.placeholder': 'Aus der Liste wählen',
    'poi.card.gazanalyzer.field.link.label': 'Link zum Datenstrom',
    'poi.card.gazanalyzer.field.link.placeholder': 'Link eingeben',
    'poi.card.gazanalyzer.field.coords.label': 'Standortkoordinaten',
    'poi.card.gazanalyzer.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.gazanalyzer.field.sensors.label': 'Sensoren',
    'poi.card.gazanalyzer.field.sensors.placeholder': 'Sensoren angeben',
    'poi.card.gazanalyzer.field.gazes.title.text': 'Analysierte Gase',
    'poi.card.gazanalyzer.field.gazes.btn.add.label': 'Gas hinzufügen',
    'poi.card.gazanalyzer.field.gazes.table.header.gaz.text': 'Gase',
    'poi.card.gazanalyzer.field.gazes.table.header.measurement.text': 'Meßeinheit',
    'poi.card.gazanalyzer.field.gazes.table.header.range.text': 'Reichweite',
    'poi.card.gazanalyzer.field.gazes.table.header.limit.text': 'Grenze',
    'poi.card.gazanalyzer.field.last-date.label': 'Datum der letzten Überprüfung',
    'poi.card.gazanalyzer.field.last-date.placeholder': 'Geben Sie das Datum der letzten Überprüfung an',
    'poi.card.gazanalyzer.field.last-date.time-caption.text': 'Zeit',
    'poi.card.gazanalyzer.field.next-date.label': 'Datum der nächsten Überprüfung',
    'poi.card.gazanalyzer.field.next-date.placeholder': 'Geben Sie das Datum der nächsten Überprüfung an',
    'poi.card.gazanalyzer.field.next-date.time-caption.text': 'Zeit',
    'poi.card.sensor.field.name.label': 'Name',
    'poi.card.sensor.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.sensor.field.desc.label': 'Beschreibung',
    'poi.card.sensor.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.sensor.field.type.label': 'Typ',
    'poi.card.sensor.field.type.placeholder': 'Aus der Liste wählen',
    'poi.card.sensor.field.model.label': 'Modell',
    'poi.card.sensor.field.model.placeholder': 'Aus der Liste wählen',
    'poi.card.sensor.field.link.label': 'Link zum Datenstrom',
    'poi.card.sensor.field.link.placeholder': 'Link eingeben',
    'poi.card.sensor.field.coords.label': 'Standortkoordinaten',
    'poi.card.sensor.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.sensor.field.distance.label': 'Messbereich',
    'poi.card.sensor.field.distance.placeholder.from.text': 'Von',
    'poi.card.sensor.field.distance.placeholder.to.text': 'Vor',
    'poi.card.sensor.field.limit.label': 'Grenzwert',
    'poi.card.sensor.field.limit.placeholder': 'Grenze',
    'poi.card.sensor.field.unit-measurement.label': 'Maßeinheit',
    'poi.card.sensor.field.unit-measurement.placeholder': 'Geben Sie die Maßeinheit an',
    'poi.card.sensor.field.last-date.label': 'Datum der letzten Überprüfung',
    'poi.card.sensor.field.last-date.placeholder': 'Geben Sie das Datum der letzten Überprüfung an',
    'poi.card.sensor.field.last-date.time.caption': 'Zeit',
    'poi.card.sensor.field.next-date.label': 'Datum der nächsten Überprüfung',
    'poi.card.sensor.field.next-date.placeholder': 'Geben Sie das Datum der nächsten Überprüfung an',
    'poi.card.sensor.field.next-date.time.caption': 'Zeit',
    'poi.card.equipment.field.name.label': 'Name',
    'poi.card.equipment.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.equipment.field.desc.label': 'Beschreibung',
    'poi.card.equipment.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.equipment.field.type.label': 'Typ',
    'poi.card.equipment.field.type.placeholder': 'Aus der Liste wählen',
    'poi.card.equipment.field.model.label': 'Modell',
    'poi.card.equipment.field.model.placeholder': 'Aus der Liste wählen',
    'poi.card.equipment.field.coords.label': 'Standortkoordinaten',
    'poi.card.equipment.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.equipment.field.last-date.label': 'Datum der letzten Überprüfung',
    'poi.card.equipment.field.last-date.placeholder': 'Geben Sie das Datum der letzten Überprüfung an',
    'poi.card.equipment.field.last-date.time.caption': 'Zeit',
    'poi.card.equipment.field.next-date.label': 'Datum der nächsten Überprüfung',
    'poi.card.equipment.field.next-date.placeholder': 'Geben Sie das Datum der nächsten Überprüfung an',
    'poi.card.equipment.field.next-date.time.caption': 'Zeit',
    'poi.card.accident.field.name.label': 'Name',
    'poi.card.accident.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.accident.field.desc.label': 'Beschreibung',
    'poi.card.accident.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.accident.field.type.label': 'Typ',
    'poi.card.accident.field.type.placeholder': 'Aus der Liste wählen',
    'poi.card.accident.field.coords.label': 'Standortkoordinaten',
    'poi.card.accident.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.accident.field.next-date.label': 'Gültigkeitsdauer der Punkte',
    'poi.card.accident.field.next-date.placeholder': 'Geben Sie die Gültigkeitsdauer des Punktes an',
    'poi.card.accident.field.next-date.time.caption': 'Zeit',
    'poi.card.accident.field.auto-delete.text':
      'Einen Punkt am Ende der Gültigkeitsdauer des Punktes automatisch von der Karte entfernen',
    'poi.card.free-point.field.name.label': 'Name',
    'poi.card.free-point.field.name.placeholder': 'Geben Sie den Titel ein',
    'poi.card.free-point.field.desc.label': 'Beschreibung',
    'poi.card.free-point.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'poi.card.free-point.field.coords.label': 'Standortkoordinaten',
    'poi.card.free-point.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'poi.card.free-point.field.next-date.label': 'Gültigkeitsdauer der Punkte',
    'poi.card.free-point.field.next-date.placeholder': 'Geben Sie die Gültigkeitsdauer des Punktes an',
    'poi.card.free-point.field.next-date.time.caption': 'Zeit',
    'poi.card.free-point.field.auto-delete.text':
      'Einen Punkt am Ende der Gültigkeitsdauer des Punktes automatisch von der Karte entfernen',
  },
};
