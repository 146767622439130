import React from 'react';
import classNames from 'classnames/bind';

import styles from './resizable-handle.module.scss';

const cx = classNames.bind(styles);

type ResizableHandleProps = {
  handleAxis?: string;
  children?: React.ReactNode;
};

const ResizableHandle = React.forwardRef((props: ResizableHandleProps, ref: React.Ref<HTMLDivElement>) => {
  const { handleAxis, ...restProps } = props;

  return (
    <div ref={ref} className={cx(styles.wrapper, `${handleAxis}`)} {...restProps}>
      <div className={styles.arrows} />
    </div>
  );
});

ResizableHandle.displayName = 'ResizableHandle';

export default ResizableHandle;
