import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import DropArrow from 'assets/img/drop-arrow.png';
import styles from './selectControls.module.scss';
import classNames from 'classnames/bind';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setFloor, setHeatMapType, setLayer } from 'reducers/map';
import { HEATMAP, LAYERS, MARKERS } from 'utils/consts';

const cx = classNames.bind(styles);

type ControlProps = {
  options: { label: string; keyName: string | HEATMAP | LAYERS | MARKERS }[];
  onChange: (val: string | HEATMAP | LAYERS | MARKERS) => void;
  value: number | string;
  controlName: string;
};

function Control({ options, onChange, value, controlName }: ControlProps) {
  const containerClasses = cx(styles.selectBox);
  let focusedOnControl = '';
  const removeFocus = () => {
    if (document.activeElement !== null) {
      const activeElement = document.activeElement.toString();
      if (activeElement === '[object HTMLElement]' && activeElement === focusedOnControl) {
        (document.activeElement as HTMLElement).blur();
        focusedOnControl = '';
      } else {
        focusedOnControl = activeElement;
      }
    }
  };
  return (
    <div className={containerClasses}>
      <section className={styles.selectBoxCurrent} tabIndex={1} onClick={removeFocus}>
        {options.map((opt, ind) => (
          <div className={styles.selectBoxValue} key={`input-item-${opt.label}-${ind}`}>
            <input
              className={styles.selectBoxInput}
              type="radio"
              checked={String(value) === opt.keyName}
              name={controlName}
              id={`${controlName}-${opt.keyName}`}
              readOnly
            />
            <div className={styles.selectBoxInputText}>{opt.label}</div>
          </div>
        ))}
        <img className={styles.selectBoxIcon} src={DropArrow} alt="arrow icon" />
      </section>
      <ul className={styles.selectBoxList}>
        {options.map((opt, ind) => (
          <li onClick={() => onChange(opt.keyName)} key={`list-item-${opt.label}-${ind}`}>
            <label className={styles.selectBoxOption} htmlFor={opt.keyName}>
              {opt.label}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function SelectControls() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const selectValues = useSelector((state: RootState) => ({
    floor: state.map.floor,
    layer: state.map.layer,
    heatMapType: state.map.heatMapType,
    markerType: state.map.markerType,
  }));
  const withHeatMap = useSelector((state: RootState) => state.addons.withHeatMap);

  const usualControls: ControlProps[] = [
    {
      value: selectValues.floor,
      controlName: 'selectedFloor',
      options: [
        {
          label: t('common-info.map.options.floors.first.text'),
          keyName: '1',
        },
        {
          label: t('common-info.map.options.floors.second.text'),
          keyName: '2',
        },
        {
          label: t('common-info.map.options.floors.third.text'),
          keyName: '3',
        },
        {
          label: t('common-info.map.options.floors.fourth.text'),
          keyName: '4',
        },
        {
          label: t('common-info.map.options.floors.fifth.text'),
          keyName: '5',
        },
        {
          label: t('common-info.map.options.floors.sixth.text'),
          keyName: '6',
        },
        {
          label: t('common-info.map.options.floors.seventh.text'),
          keyName: '7',
        },
        {
          label: t('common-info.map.options.floors.eighth.text'),
          keyName: '8',
        },
      ],
      onChange: (val: string) => dispatch(setFloor(parseInt(val))),
    },
    {
      value: selectValues.layer,
      controlName: 'selectedLayer',
      options: [
        {
          label: 'Mapbox',
          keyName: LAYERS.MAPBOX,
        },
        {
          label: 'OSM',
          keyName: LAYERS.OSM,
        },
      ],
      onChange: (val: string) => dispatch(setLayer(val as LAYERS)),
    },
  ];

  const heatMapControl = {
    value: selectValues.heatMapType,
    controlName: 'selectedHeatmap',
    options: [
      {
        label: t('common-info.map.options.type.heatmap.text'),
        keyName: HEATMAP.none,
      },
      {
        label: t('common-info.map.options.type.objects.text'),
        keyName: HEATMAP.default,
      },
      {
        label: t('common-info.map.options.type.violations.text'),
        keyName: HEATMAP.objects,
      },
      {
        label: t('common-info.map.options.type.all.text'),
        keyName: HEATMAP.all,
      },
    ],
    onChange: (val: string) => dispatch(setHeatMapType(val as HEATMAP)),
  };

  const containerClasses = cx(styles.container, {
    [styles.containerWithHeat]: withHeatMap,
  });

  return (
    <div className={containerClasses}>
      {usualControls.map((control, index) => (
        <Control {...control} key={`control-key-${index}-${control.controlName}`} />
      ))}
      {withHeatMap && <Control {...heatMapControl} />}
    </div>
  );
}
