import isEqual from 'lodash.isequal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setMarkerType } from 'reducers/map';
import { MARKERS } from 'utils/consts';
import { ObjectsShowingHandlers, ObjectsShowingValues } from '../utils/types';

export function useObjectsShowing() {
  const dispatch = useDispatch();
  const showType = useSelector((state: RootState) => state.user.userPreferences.trackableUnitView);

  const [trackerNumberIsShown, setTrackerNumberIsShown] = useState(showType.trackerNumberIsShown);
  const [fullNameIsShown, setFullNameIsShown] = useState(showType.fullNameIsShown);
  const [organizationIsShown, setOrganizationIsShown] = useState(showType.organizationIsShown);
  const [departmentIsShown, setDepartmentIsShown] = useState(showType.departmentIsShown);
  const [positionOrDriverIsShown, setPositionOrDriverIsShown] = useState(showType.positionOrDriverIsShown);
  const [alwaysShow, setAlwaysShow] = useState(showType.alwaysShow);
  const [onRightSign, setOnRightSign] = useState(!!showType.onRightSign);
  const [hasBackground, setHasBackground] = useState(!!showType.hasBackground);

  const handleAlwaysShow = () => {
    const newAlwaysShow = !alwaysShow;
    if (newAlwaysShow) {
      dispatch(setMarkerType(MARKERS.default));
    } else {
      dispatch(setMarkerType(MARKERS.onmouseover));
    }
    setAlwaysShow(newAlwaysShow);
  };

  const values: ObjectsShowingValues = {
    trackerNumberIsShown,
    fullNameIsShown,
    organizationIsShown,
    departmentIsShown,
    positionOrDriverIsShown,
    alwaysShow,
    onRightSign,
    hasBackground,
  };

  const handlers: ObjectsShowingHandlers = {
    setTrackerNumberIsShown,
    setFullNameIsShown,
    setOrganizationIsShown,
    setDepartmentIsShown,
    setPositionOrDriverIsShown,
    handleAlwaysShow,
    setOnRightSign,
    setHasBackground,
  };

  const findDifference = () => {
    if (isEqual(showType, values)) {
      return null;
    }
    return values;
  };

  return {
    values,
    handlers,
    findDifference,
  };
}
