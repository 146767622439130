import { NOTIFICATION_TYPES_ENUM } from './consts';

export function getIdFromTypeNotification(type: NOTIFICATION_TYPES_ENUM | null) {
  const { alert, geoZones, interceptionOfObjects, sensorValue, videoAnalytics } = NOTIFICATION_TYPES_ENUM;

  switch (type) {
    case alert:
      return 1;
    case geoZones:
      return 2;
    case interceptionOfObjects:
      return 3;
    case sensorValue:
      return 4;
    case videoAnalytics:
      return 5;
    default:
      return 0;
  }
}

export function getTypeNotificationFromId(id: number) {
  const { alert, geoZones, interceptionOfObjects, sensorValue, videoAnalytics } = NOTIFICATION_TYPES_ENUM;

  switch (id) {
    case 1:
      return alert;
    case 2:
      return geoZones;
    case 3:
      return interceptionOfObjects;
    case 4:
      return sensorValue;
    case 5:
      return videoAnalytics;
    default:
      return null;
  }
}
