import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';

import turfBbox from '@turf/bbox';
import { lineString as turfLineString, point as turfPoint } from '@turf/helpers';

import { Collection, Feature, Map, View } from 'ol';
import { Extent } from 'ol/extent';
import { Point } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Draw, Modify, Snap } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import { DrawEvent } from 'ol/interaction/Draw';
import { fromLonLat, toLonLat } from 'ol/proj';
import { easeOut } from 'ol/easing';

import { setPoiCardFieldData, setSelectedPoiDirectly } from 'reducers/poi';
import { Poi, PoiCardData, PoiEquipmentPoint, PoiVideocameraPoint } from 'reducers/poi/interface';

import { defaultPoiVideoCameraState as defaults, PoiTypesEnum } from 'components/poi/utils/consts';
import { PoiCardEquipmentData, PoiCardVideoCameraData } from 'components/poi/utils/types';
import { MAP_LAYERS_Z_INDEX, MAX_ZOOM_DEFAULT_VALUE, MIN_ZOOM_DEFAULT_VALUE, ZOOM_DEFAULT_VALUE } from 'utils/consts';

import { POI_VIDEOCAMERA_EDITOR_SECTOR_MARKER_TYPE } from '../constants';
import { PoiMarkerProperties, PoiFeatureProperties } from '../map.types';
import {
  createClusterSource,
  createDrawPoi,
  createModifyPoi,
  createGeoMarker,
  applyFlyViewAnimation,
  geoJSON,
  createSectorFeature,
  createPoiSectorsVectorLayer,
  createPoiVectorLayer,
} from '../utils';
import VideoCameraEditor from '../VideoCameraEditor';

function getSubTypePoiCard(poiCardType: PoiTypesEnum | null, poiCardData: PoiCardData | null) {
  if (!poiCardData || !poiCardType) {
    return 0;
  }

  switch (poiCardType) {
    case PoiTypesEnum.poiEquipmentPoint:
      return (poiCardData as PoiCardEquipmentData).poiEquipmentTypeId;

    default:
      return 0;
  }
}

function getSubTypePoiCardFromPoiData(poi: Poi) {
  if (!poi) {
    return 0;
  }

  switch (poi.attributes.poiType) {
    case PoiTypesEnum.poiEquipmentPoint:
      return (poi.relationships.poiEquipmentPoint.data as PoiEquipmentPoint).poiEquipmentTypeId;

    default:
      return 0;
  }
}

function getPoiFeatureProperties(poiCardType: PoiTypesEnum | null, subTypeId: number): PoiFeatureProperties {
  return {
    poiType: poiCardType,
    poiSubTypeId: subTypeId,
  };
}

// POI markers layer
const poiVectorSourceMarkers: VectorSource = new VectorSource({});
const poiVectorCluster = createClusterSource(poiVectorSourceMarkers);
const poiMarkersMapLayer = createPoiVectorLayer(poiVectorCluster, MAP_LAYERS_Z_INDEX.POI_MARKERS);

// POI sectors layer
const poiVectorSourceSectors: VectorSource = new VectorSource({});
const poiSectorsMapLayer = createPoiSectorsVectorLayer(
  poiVectorSourceSectors,
  MAP_LAYERS_Z_INDEX.POI_VIDEO_CAMERA_SECTORS
);

const viewSettings = new View({
  minZoom: MIN_ZOOM_DEFAULT_VALUE,
  maxZoom: MAX_ZOOM_DEFAULT_VALUE,
  zoom: ZOOM_DEFAULT_VALUE,
});

let draw: Draw;
let snap: Snap;
let modify: Modify;

export const usePoiMarkers = (map: Map) => {
  const dispatch = useDispatch();

  const {
    data: poiData,
    chosenPoi,
    poiCardType,
    poiCardData,
    selectedPoi,
    showingPoiIds,
  } = useSelector((state: RootState) => state.poi);
  const subTypeId = getSubTypePoiCard(poiCardType, poiCardData);

  useEffect(() => {
    map.addLayer(poiMarkersMapLayer);
    map.addLayer(poiSectorsMapLayer);

    return () => {
      map.removeLayer(poiMarkersMapLayer);
      map.removeLayer(poiSectorsMapLayer);

      if (draw) {
        draw.dispose();
      }
      if (snap) {
        snap.dispose();
      }
      if (modify) {
        modify.dispose();
      }
    };
  }, [map]);

  // при смене подтипа в карточке poi (например,
  // в карточке оснащения менять тип оснащения) - очищаем карту
  useEffect(() => {
    if (subTypeId) {
      poiVectorSourceMarkers.refresh();
    }
  }, [subTypeId]);

  const removeInteractions = useCallback(() => {
    map.removeInteraction(draw);
    map.removeInteraction(snap);
    map.removeInteraction(modify);
  }, [map]);

  const handleModify = useCallback(
    (evt: ModifyEvent) => {
      if (evt.type === 'modifystart') {
        map.getTargetElement().style.cursor = 'grabbing';
      }
      if (evt.type === 'modifyend') {
        const features = evt.features;

        if (features) {
          const firstFeature = features.getArray()[0];

          if (firstFeature) {
            const geometry = firstFeature.getGeometry();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const coordinates = toLonLat(geometry.getCoordinates());

            dispatch(
              setPoiCardFieldData({
                key: 'coordinates',
                value: coordinates.join(', '),
              })
            );
          }
        }
        map.getTargetElement().style.cursor = 'pointer';
      }
    },
    [dispatch, map]
  );

  const handleDrawStart = useCallback(() => {
    if (poiVectorSourceMarkers.getFeatures().length) {
      poiVectorSourceMarkers.refresh();
    }
  }, []);

  const [videoCameraEditor, setVideoCameraEditor] = useState<VideoCameraEditor | null>(null);

  const handleDrawEnd = useCallback(
    (evt: DrawEvent) => {
      const feature = evt.feature;
      const geometry = feature.getGeometry();
      const coordinates = toLonLat((geometry as Point).getCoordinates());

      feature.setId('poiMarker');
      feature.setProperties(getPoiFeatureProperties(poiCardType, subTypeId));
      if (draw) {
        map.removeInteraction(draw);
      }
      map.getTargetElement().style.cursor = '';

      dispatch(
        setPoiCardFieldData({
          key: 'coordinates',
          value: coordinates.join(', '),
        })
      );

      // videoCamera point
      if (poiCardType === PoiTypesEnum.poiVideocameraPoint) {
        if (!videoCameraEditor) {
          setVideoCameraEditor(
            new VideoCameraEditor(map, feature, {
              angle: defaults.angle,
              direction: defaults.direction,
              distance: defaults.distance,
            })
          );
        }
      } else {
        // other poi points
        modify = createModifyPoi({
          source: poiVectorSourceMarkers,
          hitDetection: poiMarkersMapLayer,
          features: new Collection([feature]),
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modify.on(['modifystart', 'modifyend'], handleModify);
        map.addInteraction(modify);
      }
    },
    [dispatch, map, poiCardType, subTypeId, handleModify, videoCameraEditor]
  );

  // initializing and disposing videoCameraEditor
  useEffect(() => {
    if (videoCameraEditor) {
      if (!videoCameraEditor.isInitialized) {
        removeInteractions();
        poiVectorSourceMarkers.clear();
        poiVectorSourceSectors.clear();
        videoCameraEditor.init(dispatch);
      }
    }
    return () => {
      if (videoCameraEditor) {
        videoCameraEditor.clear();
        videoCameraEditor.dispose();
        setVideoCameraEditor(null);
      }
    };
  }, [dispatch, videoCameraEditor, removeInteractions]);

  const viewFitExtent = useCallback(
    (duration: number) => {
      const bbox = turfBbox(geoJSON.writeFeaturesObject(poiVectorSourceMarkers.getFeatures()));
      const X1Y1 = fromLonLat([bbox[0], bbox[1]]);
      const X2Y2 = fromLonLat([bbox[2], bbox[3]]);

      const extent = [...X1Y1, ...X2Y2] as Extent;

      map.getView().fit(extent, {
        padding: [150, 150, 150, 150],
        duration,
        easing: easeOut,
      });
    },
    [map]
  );

  // update videoCameraEditor and repaint other points from they coordinates
  useEffect(() => {
    if (poiCardData) {
      if (poiCardType === PoiTypesEnum.poiVideocameraPoint) {
        if (videoCameraEditor?.isInitialized) {
          const { coordinates, angle, distance, direction } = poiCardData as PoiCardVideoCameraData;
          const [lon, lat]: number[] = coordinates.split(',').map(c => Number(c.trim()));

          videoCameraEditor.update({ angle, distance, direction, coordinates: { lon, lat } });
        }
      } else {
        const features = poiVectorSourceMarkers.getFeatures();

        if (features.length) {
          const pointGeometry = features[0].getGeometry() as Point;
          const { coordinates } = poiCardData;
          const [lon, lat]: number[] = coordinates.split(',').map(c => Number(c.trim()));

          pointGeometry.setCoordinates(fromLonLat([lon, lat]));

          viewFitExtent(500);
        }
      }
    } else {
      if (videoCameraEditor) {
        videoCameraEditor.clear();
        videoCameraEditor.dispose();
        setVideoCameraEditor(null);
      }
    }
  }, [viewFitExtent, poiCardData, videoCameraEditor, poiCardType]);

  // создание функции взаимодействий
  const addInteractions = useCallback(() => {
    if (poiCardType && !videoCameraEditor) {
      map.getTargetElement().style.cursor = 'pointer';

      draw = createDrawPoi(poiVectorSourceMarkers, getPoiFeatureProperties(poiCardType, subTypeId));
      draw.on('drawstart', handleDrawStart);
      draw.on('drawend', handleDrawEnd);
      map.addInteraction(draw);

      snap = new Snap({ source: poiVectorSourceMarkers });
      map.addInteraction(snap);
    }
  }, [map, handleDrawStart, handleDrawEnd, poiCardType, subTypeId, videoCameraEditor]);

  // создание функции взаимодействий для выбранной poi
  const addModifyInteractionsForChosenPoi = useCallback(() => {
    if (chosenPoi) {
      const { attributes } = chosenPoi;

      if (poiCardType === PoiTypesEnum.poiVideocameraPoint) {
        const data = chosenPoi.relationships.poiVideocameraPoint.data as PoiVideocameraPoint;
        const { angle, direction, distance } = data;
        const feature = new Feature({
          type: 'poiMarker',
          geometry: new Point(fromLonLat([attributes.lon, attributes.lat])),
        });

        poiVectorSourceMarkers.refresh();
        poiVectorSourceSectors.refresh();

        if (!videoCameraEditor) {
          setVideoCameraEditor(new VideoCameraEditor(map, feature, { angle, direction, distance }));
        }
      } else {
        const chosenPoiFeature = createGeoMarker(fromLonLat([attributes.lon, attributes.lat]));

        poiVectorSourceMarkers.refresh();
        poiVectorSourceSectors.refresh();

        chosenPoiFeature.setProperties({
          info: {
            id: chosenPoi.id,
            name: attributes.name,
          },
          poiType: attributes.poiType,
          poiSubTypeId: getSubTypePoiCardFromPoiData(chosenPoi),
          coordinates: [attributes.lon, attributes.lat],
          type: 'poiMarker',
        });
        poiVectorSourceMarkers.addFeature(chosenPoiFeature);

        modify = createModifyPoi({
          source: poiVectorSourceMarkers,
          hitDetection: poiMarkersMapLayer,
          features: new Collection<Feature>([chosenPoiFeature]),
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modify.on(['modifystart', 'modifyend'], handleModify);
        map.addInteraction(modify);

        snap = new Snap({ source: poiVectorSourceMarkers });
        map.addInteraction(snap);
      }
    }
  }, [map, chosenPoi, handleModify, poiCardType, videoCameraEditor]);

  useEffect(() => {
    removeInteractions();
    if (poiCardType) {
      if (chosenPoi) {
        addModifyInteractionsForChosenPoi();
      } else {
        addInteractions();
      }
    } else {
      if (videoCameraEditor) {
        videoCameraEditor.clear();
        videoCameraEditor.dispose();
        setVideoCameraEditor(null);
      }
    }
    return () => {
      removeInteractions();
    };
  }, [
    poiCardType,
    chosenPoi,
    addInteractions,
    removeInteractions,
    addModifyInteractionsForChosenPoi,
    videoCameraEditor,
  ]);

  const markers: PoiMarkerProperties[] = useMemo(() => {
    if (selectedPoi.length && poiData.length) {
      const filteredPoi = poiData.filter(poi => selectedPoi.includes(poi.id));

      if (filteredPoi.length) {
        return filteredPoi.map(poi => {
          const type = poi.attributes.poiType;
          let additionalInfo = {};

          if (type === 'poiVideocameraPoint') {
            const data = poi.relationships.poiVideocameraPoint.data as PoiVideocameraPoint;
            const { angle, direction, distance } = data;

            additionalInfo = { angle, direction, distance };
          }
          return {
            ...additionalInfo,
            info: {
              id: poi.id,
              name: poi.attributes.name,
            },
            poiType: type,
            poiSubTypeId: getSubTypePoiCardFromPoiData(poi),
            coordinates: [poi.attributes.lon, poi.attributes.lat],
            type: 'poiMarker',
          };
        });
      }
    }
    return [];
  }, [selectedPoi, poiData]);

  const sectors = useMemo(
    () =>
      markers
        .map(marker => {
          if (marker.poiType === 'poiVideocameraPoint') {
            return {
              coordinates: marker.coordinates,
              angle: marker.angle ?? 0,
              direction: marker.direction ?? 0,
              distance: marker.distance ?? 0,
            };
          }
          return null;
        })
        .filter(sectorParams => !!sectorParams),
    [markers]
  );

  // отображение маркеров и секторов на карте
  useEffect(() => {
    if (markers.length) {
      const featureMarkers: Feature[] = [];
      const featureSectors: Feature[] = [];

      markers.forEach(marker => {
        const feature = createGeoMarker(fromLonLat(marker.coordinates));

        feature.setProperties({ ...marker });
        featureMarkers.push(feature);
      });
      poiVectorSourceMarkers.addFeatures(featureMarkers);

      sectors.forEach((sectorParams, i) => {
        if (sectorParams) {
          const sectorFeature = createSectorFeature(sectorParams);

          sectorFeature.setProperties({
            ...sectorParams,
            markerType: POI_VIDEOCAMERA_EDITOR_SECTOR_MARKER_TYPE,
          });
          sectorFeature.setId(`poiSector-${i}`);
          featureSectors.push(sectorFeature);
        }
      });
      poiVectorSourceSectors.addFeatures(featureSectors);
    }
    return () => {
      poiVectorSourceMarkers.clear();
      poiVectorSourceSectors.clear();
    };
  }, [markers, sectors, poiCardType]);

  // показ и центрирование маркера(ов) на карте
  useEffect(() => {
    if (showingPoiIds.length && poiData.length) {
      const showingCoordinates: number[][] = [];

      showingPoiIds.forEach(poiId => {
        const foundPoi = poiData.find(poi => poi.id === poiId);

        if (foundPoi) {
          showingCoordinates.push(fromLonLat([foundPoi.attributes.lon, foundPoi.attributes.lat]));
        }
      });

      const showingCoordinatesLength = showingCoordinates.length;

      if (showingCoordinatesLength) {
        const mapTargetRect = map.getTargetElement().getBoundingClientRect();
        const xCenter = mapTargetRect.width / 2;
        const yCenter = mapTargetRect.height / 2;

        if (showingCoordinatesLength > 1) {
          const line = turfLineString(showingCoordinates);
          const extent = turfBbox(line);

          viewSettings.fit(extent as Extent, {
            padding: [30, 30, 30, 30],
          });
        } else {
          const center = turfPoint(showingCoordinates[0]);
          const size = map.getSize() ?? [0, 0];

          viewSettings.setZoom(ZOOM_DEFAULT_VALUE);
          viewSettings.centerOn(center.geometry.coordinates, size, [xCenter, yCenter]);
        }

        const center = viewSettings.getCenter() ?? [0, 0];

        map.setView(viewSettings);
        applyFlyViewAnimation(center, viewSettings);
        dispatch(setSelectedPoiDirectly(showingPoiIds));
      }
    }
  }, [dispatch, map, poiData, showingPoiIds]);
};
