import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import { ModalHeader } from 'components/common/modal/modalHeader/modalHeader';
import { ModalControls } from 'components/monitoring/components/controls.modal';
import CustomInput from 'components/common/input/input';

import styles from './videoSettings.module.scss';

function VideoSettingsPopup({ which }: { which: string }): JSX.Element {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const closePopup = () => {
    dispatch(closeModal());
  };

  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupHeader}>
        <ModalHeader title={t('map-popup.videocamera.settings.header.label')} onCancel={closePopup} />
      </div>

      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.name.label')}
          placeholder={t('map-popup.videocamera.settings.field.name.placeholder')}
          value=""
          isRequired
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.description.label')}
          placeholder={t('map-popup.videocamera.settings.field.description.placeholder')}
          value=""
        />
      </div>

      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.model.label')}
          placeholder={t('map-popup.videocamera.settings.field.model.placeholder')}
          value=""
          withSearchIcon
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.link.label')}
          placeholder={t('map-popup.videocamera.settings.field.link.placeholder')}
          value={which}
          disabled
          isRequired
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.coords.label')}
          placeholder={t('map-popup.videocamera.settings.field.coords.placeholder')}
          value=""
          isRequired
          withGeoIcon
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.angle.label')}
          placeholder={t('map-popup.videocamera.settings.field.angle.placeholder')}
          value=""
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.viewing-distance.label')}
          placeholder={t('map-popup.videocamera.settings.field.viewing-distance.placeholder')}
          value=""
        />
      </div>
      <div className={styles.popupField}>
        <CustomInput
          label={t('map-popup.videocamera.settings.field.tracking-direction.label')}
          placeholder={t('map-popup.videocamera.settings.field.tracking-direction.placeholder')}
          value=""
        />
      </div>

      <div className={styles.popupControls}>
        <ModalControls
          onSave={() => {
            return;
          }}
          onCancel={closePopup}
          withoutDelete
        />
      </div>
    </div>
  );
}

export default VideoSettingsPopup;
