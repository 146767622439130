import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

import { SortingType } from 'reducers/monitoring/interface';
import { RootState } from 'reducers';
import { toggleSelectUnit } from 'reducers/monitoring';

import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';
import { Sorting } from 'utils/consts';

import { getTrackers, createTracker, deleteTracker, putTracker, getOneTracker, searchTrackers } from './api';
import { retrieveLatestPosition as retrieveLatestPositionRequest } from '../trackableUnits/api';
import { TrackersState, TrackersJSONApi, CreateTracker, ChosenTrackerInfo, ChosenTrackerAttributes } from './interface';
import {
  SingleTimeManyDevicesEvent,
  SingleTimeSingleDeviceEvent,
  SingleDeviceManyTimesEvent,
  ManyDevicesManyTimes,
} from '../../contexts/interfaces';

const initialState: TrackersState = {
  trackers: [],
  isLoading: false,
  markers: { type: 'FeatureCollection', features: [] },
  needUpdate: false,
  chosenTracker: null,
  chosenTrackerIsLoading: false,
  error: null,
  includes: {
    trackerModel: [],
    trackerStatus: [],
  },
  selectedTrackers: [],
  sort: {
    type: Sorting.name,
    ascending: true,
  },
};

export const fetchTrackers = createAsyncThunk('trackerSlice/fetchTrackers', async (_, { rejectWithValue }) => {
  try {
    const response = await getTrackers();
    return response;
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

export const fetchOneTracker = createAsyncThunk(
  'trackerSlice/fetchOneTracker',
  async (id: number) => await getOneTracker(id)
);

export const addTracker = createAsyncThunk(
  'trackerSlice/addTracker',
  async (data: CreateTracker) => await createTracker(data)
);

export const updateTracker = createAsyncThunk(
  'trackerSlice/updateTracker',
  async (tracker: ChosenTrackerAttributes) => await putTracker(tracker)
);

export const removeTracker = createAsyncThunk('trackerSlice/removeTracker', async (id: number, thunkAPI) => {
  const result = await deleteTracker(id);

  // If selected trackableUnit has this tracker
  // then remove selection to remove it from map
  const state = thunkAPI.getState() as RootState;
  const { trackableUnits } = state.trackableUnit;
  const { selectedUnits } = state.monitoring;

  const trackableUnitWithTracker = trackableUnits.find(unit => unit.attributes.trackerId === id);

  if (trackableUnitWithTracker && selectedUnits.includes(trackableUnitWithTracker.id)) {
    thunkAPI.dispatch(toggleSelectUnit({ id: trackableUnitWithTracker.id, currentStatus: true }));
  }

  return result;
});

export const fetchSearchTrackers = createAsyncThunk(
  'trackerSlice/fetchSearchTrackers',
  async (val: string) => await searchTrackers(val)
);

export const retrieveLatestPosition = createAsyncThunk(
  'trackerSlice/retrieveLatestPosition',
  async (deviceIds: string[]) => await retrieveLatestPositionRequest(deviceIds)
);

export const removeTrackerGroup = createAsyncThunk('trackerSlice/removeTrackerGroup', async (group: number[]) => {
  const promises: ParallelItemInterface[] = [];
  group.forEach(id => {
    promises.push({
      promiseMethod: deleteTracker,
      args: id,
    });
  });
  return await reqHandlers.allSettled(promises);
});

const trackerSlice = createSlice({
  name: 'trackerSlice',
  initialState,
  reducers: {
    handleTrackersEvent: (
      state,
      {
        payload,
      }: {
        payload:
          | SingleTimeManyDevicesEvent
          | SingleTimeSingleDeviceEvent
          | SingleDeviceManyTimesEvent
          | ManyDevicesManyTimes;
      }
    ) => {
      switch (payload.type) {
        case 'SingleTimeManyDevicesEvent':
          payload.devices.forEach(d => {
            const marker = state.markers.features.find(feature => feature.properties?.deviceId === d.deviceId);
            if (marker?.geometry.type === 'Point') {
              marker.geometry.coordinates = [d.lon, d.lat];
            }
          });
          break;

        case 'SingleTimeSingleDeviceEvent':
          const marker = state.markers.features.find(feature => feature.properties?.deviceId === payload.deviceId);
          if (marker?.geometry.type === 'Point') {
            marker.geometry.coordinates = [payload.lon, payload.lat];
          }
          break;

        case 'SingleDeviceManyTimesEvent':
          break;

        case 'ManyDevicesManyTimes':
          break;
      }
    },
    setNeedUpdateTrackers: (state, { payload }: { payload: boolean }) => {
      state.needUpdate = payload;
    },
    removeChosenTracker: state => {
      state.chosenTracker = null;
    },
    selectOneTracker: (state, { payload }: { payload: number }) => {
      state.selectedTrackers = [...state.selectedTrackers, { id: payload, watching: false }];
    },
    setSelectedTrackersState: (state, { payload }: { payload: { id: number; watching: boolean }[] }) => {
      const newSelectedTrackers = payload.filter(emp => !state.selectedTrackers.some(sel => sel.id === emp.id));
      state.selectedTrackers = [
        ...state.selectedTrackers,
        ...newSelectedTrackers.map(p => ({ id: p.id, watching: false })),
      ];
    },
    selectBatchOfTrackers: (state, { payload }: { payload: number[] }) => {
      const newSelectedTrackers = payload.filter(emp => !state.selectedTrackers.some(sel => sel.id === emp));
      state.selectedTrackers = [
        ...state.selectedTrackers,
        ...newSelectedTrackers.map(p => ({ id: p, watching: false })),
      ];
    },
    unselectOneTracker: (state, { payload }: { payload: number }) => {
      state.selectedTrackers = state.selectedTrackers.filter(t => t.id !== payload);
    },
    selectAllTrackers: state => {
      state.selectedTrackers = state.trackers.map(t => ({ id: t.id, watching: false }));
    },
    unselectAllTrackers: state => {
      state.selectedTrackers = [];
    },
    resetMarkersCollection: state => {
      state.markers = initialState.markers;
    },
    watchOneTracker: (state, { payload }: { payload: number }) => {
      state.selectedTrackers = state.selectedTrackers.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: true,
          };
        }
        return e;
      });
    },
    unwatchOneTracker: (state, { payload }: { payload: number }) => {
      state.selectedTrackers = state.selectedTrackers.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: false,
          };
        }
        return e;
      });
    },
    watchAllTrackers: state => {
      state.selectedTrackers = state.selectedTrackers.map(e => {
        return {
          ...e,
          watching: true,
        };
      });
    },
    unwatchAllTrackers: state => {
      state.selectedTrackers = state.selectedTrackers.map(e => {
        return {
          ...e,
          watching: false,
        };
      });
    },
    setSort: (state, { payload }: { payload: SortingType }) => {
      const sort = state.sort;

      if (sort.type !== payload) {
        sort.type = payload;
        sort.ascending = false;
      } else {
        sort.ascending = !state.sort.ascending;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTrackers.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchTrackers.fulfilled, (state, { payload }: { payload: TrackersJSONApi }) => {
        state.trackers = payload.data;
        state.needUpdate = false;
        state.includes = payload.includes;
        state.isLoading = false;
      })
      .addCase(fetchTrackers.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchSearchTrackers.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchSearchTrackers.fulfilled, (state, { payload }: { payload: TrackersJSONApi }) => {
        state.trackers = payload.data;
        state.needUpdate = false;
        state.includes = payload.includes;
        state.isLoading = false;
      })
      .addCase(fetchSearchTrackers.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchOneTracker.pending, state => {
        state.error = null;
        state.chosenTrackerIsLoading = true;
      })
      .addCase(fetchOneTracker.fulfilled, (state, { payload }: { payload: ChosenTrackerInfo }) => {
        state.chosenTrackerIsLoading = false;
        state.chosenTracker = payload.data;
      })
      .addCase(fetchOneTracker.rejected, (state, action) => {
        state.chosenTrackerIsLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(addTracker.pending, state => {
        state.chosenTrackerIsLoading = true;
        state.error = null;
      })
      .addCase(addTracker.fulfilled, state => {
        state.chosenTrackerIsLoading = false;
        state.needUpdate = true;
      })
      .addCase(addTracker.rejected, (state, action) => {
        state.chosenTrackerIsLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeTracker.pending, state => {
        state.chosenTrackerIsLoading = true;
        state.error = null;
      })
      .addCase(removeTracker.fulfilled, state => {
        state.chosenTrackerIsLoading = false;
        state.chosenTracker = null;
        state.needUpdate = true;
      })
      .addCase(removeTracker.rejected, (state, action) => {
        state.chosenTrackerIsLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(retrieveLatestPosition.pending, state => {
        state.error = null;
      })
      .addCase(retrieveLatestPosition.fulfilled, (state, { payload }: { payload: FeatureCollection }) => {
        state.needUpdate = true;
        state.markers = payload;
      })
      .addCase(retrieveLatestPosition.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateTracker.pending, state => {
        state.chosenTrackerIsLoading = true;
        state.error = null;
      })
      .addCase(updateTracker.fulfilled, state => {
        state.chosenTrackerIsLoading = false;
        state.chosenTracker = null;
        state.needUpdate = true;
      })
      .addCase(updateTracker.rejected, (state, action) => {
        state.chosenTrackerIsLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeTrackerGroup.pending, state => {
        state.error = null;
      })
      .addCase(removeTrackerGroup.fulfilled, state => {
        state.chosenTracker = null;
        state.needUpdate = true;
      })
      .addCase(removeTrackerGroup.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
  },
});

export const {
  handleTrackersEvent,
  setNeedUpdateTrackers,
  removeChosenTracker,
  selectOneTracker,
  selectAllTrackers,
  setSelectedTrackersState,
  selectBatchOfTrackers,
  unselectOneTracker,
  unselectAllTrackers,
  resetMarkersCollection,
  watchOneTracker,
  unwatchAllTrackers,
  unwatchOneTracker,
  watchAllTrackers,
  setSort,
} = trackerSlice.actions;

export default trackerSlice.reducer;
