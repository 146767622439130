import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { RadioButton } from 'components/common/radioButton/radioButton';
import { InfoElement } from 'components/common/infoElement/infoElement';
import { NOTIFICATION_OBJECT_DISTANCE, NOTIFICATION_POSITION_BY_GEOZONE } from 'components/notifications/utils/consts';
import { InterpositionProps } from 'components/notifications/utils/types';
import MultipleSelectGroup, {
  getValuesForMultipleSelectGroup,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../../tabsModal.module.scss';
import './commonLayoutStyles.scss';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div
      className={cx(styles.datePicker, {
        [styles.intervalTime]: props.intervalTimeStyle,
        [styles.fullWidth]: props.fullWidth,
      })}
      onClick={props.onClick}
      ref={_ref}
    >
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value[0] === '0' ? props.value.substring(1) : props.value}
        witchTimeIcon
        isRequired={props.isRequired}
        isValueError={props.isValueError}
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

export const InterpositionObjectsLayout = ({
  states,
  handlers,
  refs,
  invalidFields,
  additionalFields,
}: InterpositionProps) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <>
      <div className={styles.modalCol}>
        <div className={styles.modalRow}>
          <div className={styles.radioGroup}>
            <div
              className={styles.radioButtonRow}
              onClick={() => handlers.handleCheckedObjectsDistance(NOTIFICATION_OBJECT_DISTANCE.conjunction)}
            >
              <RadioButton
                name="interpositionObjects"
                checked={states.objectsDistance === NOTIFICATION_OBJECT_DISTANCE.conjunction}
              />
              <span>{t('notifications.card.tabs.conditions.field.intersection.rec-proximity.text')}</span>
            </div>
            <div
              className={styles.radioButtonRow}
              onClick={() => handlers.handleCheckedObjectsDistance(NOTIFICATION_OBJECT_DISTANCE.remotion)}
            >
              <RadioButton
                name="interpositionObjects"
                checked={states.objectsDistance === NOTIFICATION_OBJECT_DISTANCE.remotion}
              />
              <span>{t('notifications.card.tabs.conditions.field.intersection.rec-distance.text')}</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.modalRow, styles.Inputs)}>
          <Input
            label={t('notifications.card.tabs.conditions.field.intersection.controlled-radius.label')}
            placeholder={t('notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder')}
            isValueError={invalidFields.includes('radiusInterposition')}
            isRequired={true}
            value={states.radius < 0 ? '' : String(states.radius)}
            handleInputChange={value => handlers.handleChangeRadiusValue(value)}
            withSearchIcon
            customStyle={styles.minutePickerInput}
          />
        </div>
        <div className={styles.modalRow}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedAreaLimited}>
            <Checkbox checked={states.isAreaLimited} />
            <span>{t('notifications.card.tabs.conditions.field.intersection.limit-area.text')}</span>
          </div>
        </div>
        {states.isAreaLimited && (
          <>
            <div className={cx(styles.radioGroup, styles.inner)}>
              <div
                className={styles.radioButtonRow}
                onClick={() => handlers.handleCheckedFixation(NOTIFICATION_POSITION_BY_GEOZONE.inside)}
              >
                <RadioButton
                  name="geozoneLimitationActuation"
                  checked={states.positionByGeozone === NOTIFICATION_POSITION_BY_GEOZONE.inside}
                />
                <span>{t('notifications.card.tabs.conditions.field.intersection.limit-area.in.text')}</span>
              </div>
              <div
                className={styles.radioButtonRow}
                onClick={() => handlers.handleCheckedFixation(NOTIFICATION_POSITION_BY_GEOZONE.outside)}
              >
                <RadioButton
                  name="geozoneLimitationActuation"
                  checked={states.positionByGeozone === NOTIFICATION_POSITION_BY_GEOZONE.outside}
                />
                <span>{t('notifications.card.tabs.conditions.field.intersection.limit-area.out.text')}</span>
              </div>
            </div>

            <div className={cx(styles.modalRow, styles.Inputs)}>
              <MultipleSelectGroup
                label={t('notifications.card.tabs.conditions.field.intersection.geofences-list.label')}
                placeholder={t('notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder')}
                isValueError={invalidFields.includes('geozoneEntitiesInterposition')}
                isRequired={true}
                wrapperStyle={styles.inputWrap}
                withSearchIcon
                options={states.geozoneEntitiesForSelect}
                values={getValuesForMultipleSelectGroup({
                  options: states.geozoneEntitiesForSelect,
                  values: states.geozoneEntities.map(object => ({ name: object.type, ids: object.entityIds })),
                })}
                translateGroups={states.geozoneEntitiesTranslatesForSelect}
                handleChange={handlers.handleChangeGeozones}
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedFoulFixing}>
            <Checkbox checked={additionalFields.isFoulFixing} />
            <span>{t('notifications.card.tabs.conditions.field.intersection.rec-violation.text')}</span>
          </div>
          <InfoElement
            id={'violationFixedInterposition'}
            text={t('notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text')}
          />
        </div>
        {additionalFields.isFoulFixing && (
          <div className={cx(styles.modalRow, styles.Inputs)}>
            <DatePicker
              dateFormat={t('notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text')}
              selected={
                states.fixingTimeLimit >= 0 ? setHours(setMinutes(new Date(), states.fixingTimeLimit / 60), 0) : null
              }
              onChange={(date: Date) => handlers.handleChangeFixingTimeLimitValue(date)}
              className={styles.tabInfoDatePicker}
              placeholderText={t('notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder')}
              customInput={
                <DatePickerCustomInput
                  ref={refs.fixingTimeLimitRef}
                  label={t('notifications.card.tabs.conditions.field.intersection.waiting-time.label')}
                  isValueError={invalidFields.includes('foulFixingInterposition')}
                  fullWidth
                />
              }
              openToDate={setHours(setMinutes(new Date(), 1), 0)}
              locale={getCurrentLocaleForDatePicker(locale)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat={t('notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text')}
              timeIntervals={1}
              timeCaption={t('notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text')}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 59), 0)}
              popperClassName="popperDatePicker"
            />
          </div>
        )}
      </div>
    </>
  );
};
