import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { fetchHandbookData } from 'reducers/handbooks';
import { setNeedUpdateEmployee } from 'reducers/employees';
import { fetchTransportIncludes, setNeedUpdateTransport } from 'reducers/transports';
import { increaseUpdateCounter, setSearch } from 'reducers/trackableUnits';
import { unwatchAllUnits } from 'reducers/monitoring';
import { fetchTrackers } from 'reducers/trackers';

import DropButton from 'components/common/dropbutton/dropbutton';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { SearchedUnits } from './components/searchedUnits/searchedUnits';
import MonitoringList from './components/monitoringList/monitoringList';

import AccessEntity from 'utils/accessEntity';
import { EMPLOYEE, TRANSPORT } from 'utils/consts';

import styles from './monitoring.module.scss';

const Monitoring: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const updateCounter = useSelector((state: RootState) => state.trackableUnit.updateCounter);
  const employeeNeedUpdate = useSelector((state: RootState) => state.employee.needUpdate);
  const transportNeedUpdate = useSelector((state: RootState) => state.transport.needUpdate);

  const employeePermissions = useSelector((state: RootState) => state.user.permissions.employees);
  const transportsPermissions = useSelector((state: RootState) => state.user.permissions.transports);
  const trackerPermissions = useSelector((state: RootState) => state.user.permissions.trackers);

  const employeeAccess = useMemo(() => new AccessEntity(employeePermissions), [employeePermissions]);
  const transportsAccess = useMemo(() => new AccessEntity(transportsPermissions), [transportsPermissions]);
  const trackerAccess = useMemo(() => new AccessEntity(trackerPermissions), [trackerPermissions]);

  const isTrackersIsAllowRead = useMemo(() => trackerAccess.isAllowRead(), [trackerAccess]);

  const employeeCreateBtnProps = employeeAccess.isAllowCreate()
    ? [
        {
          label: 'monitoring.button-block.drop-down-btn.value.employee.text',
          value: EMPLOYEE,
          dataCy: 'test_monitoring_employee_create_button',
        },
      ]
    : [];
  const transportCreateBtnProps = transportsAccess.isAllowCreate()
    ? [
        {
          label: 'monitoring.button-block.drop-down-btn.value.vehicle.text',
          value: TRANSPORT,
          dataCy: 'test_monitoring_vehicle_create_button',
        },
      ]
    : [];
  const dropButtons = [...employeeCreateBtnProps, ...transportCreateBtnProps];

  const needUpdate = employeeNeedUpdate || transportNeedUpdate;

  useEffect(() => {
    dispatch(
      fetchHandbookData([
        HANDBOOK_KEYS.organizations,
        HANDBOOK_KEYS.departments,
        HANDBOOK_KEYS.positions,
        HANDBOOK_KEYS.workObjects,
      ])
    );

    return () => {
      dispatch(setSearch(''));
      dispatch(unwatchAllUnits());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isTrackersIsAllowRead) {
      dispatch(fetchTrackers());
    }
  }, [dispatch, isTrackersIsAllowRead]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(increaseUpdateCounter());
      dispatch(setNeedUpdateEmployee(false));
      dispatch(setNeedUpdateTransport(false));
    }
  }, [dispatch, updateCounter, needUpdate]);

  const handleDropButton = async (value: string) => {
    switch (value) {
      case EMPLOYEE:
        dispatch(showModal({ type: EMPLOYEE, which: '' }));
        break;

      case TRANSPORT:
        dispatch(showModal({ type: TRANSPORT, which: '' }));
        return await dispatch(fetchTransportIncludes());

      default:
        return;
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.buttonBlock}>
        <SearchedUnits />
        {!!dropButtons.length && (
          <DropButton
            data-cy="test_monitoring_create_button"
            label={t('monitoring.button-block.drop-down-btn.label')}
            buttons={dropButtons}
            onClick={handleDropButton}
            customStyle={styles.monitoringCreateButton}
          />
        )}
      </div>
      <MonitoringList />
    </div>
  );
};

export default React.memo(Monitoring);
