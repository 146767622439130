import React, { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/common/button/button';
import DatesRangeWithScripts from 'components/common/datesRangeWithScripts/datesRangeWithScripts';
import Select, { Option } from 'components/common/select/select';
import { NOTIFICATION_TYPES_ENUM, NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';
import NotificationHistoryList from './components/historyList/historyList';

import { RootState } from 'reducers';
import {
  clearHistoryLogs,
  clearHistoryLogsFilter,
  removeMarkerNotificationLog,
  setFilterField,
  setFilterFields,
  setIsTableHistoryLogsShow,
  setNotificationHistoryTotal,
  setSelectedHistoryLogs,
} from 'reducers/notifications';
import { NotificationLogListFilter } from 'reducers/notifications/interface';

import styles from './leftSideBar.module.scss';

const cx = classNames.bind(styles);

type Props = {
  onCancel: () => void;
};

function NotificationHistoryLeftSideBar({ onCancel }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { isTableHistoryLogsShow, historyLogsFilter, historyLogsTotal } = useSelector(
    (state: RootState) => state.notifications
  );

  useEffect(() => {
    return () => {
      dispatch(removeMarkerNotificationLog());
      dispatch(clearHistoryLogsFilter());
      dispatch(setSelectedHistoryLogs([]));
      dispatch(setIsTableHistoryLogsShow(false));
    };
  }, [dispatch]);

  const [period, setPeriod] = useState({ dateFrom: '', dateTo: '' });
  const [typeNotifications, setTypeNotifications] = useState<NOTIFICATION_TYPES_ENUM | null>(null);

  // сброс стейта если фильтр также сбросился
  useEffect(() => {
    if (historyLogsTotal < 0) {
      setPeriod({ dateFrom: '', dateTo: '' });
      setTypeNotifications(null);
    }
  }, [historyLogsTotal]);

  const periodFromRef = React.createRef();
  const periodToRef = React.createRef();

  const typeNotificationsForSelect: Option[] = useMemo(
    () =>
      Object.keys(NOTIFICATION_TYPES_ENUM).map(key => {
        const type = NOTIFICATION_TYPES_ENUM[key as keyof typeof NOTIFICATION_TYPES_ENUM];

        return { label: t(NOTIFICATION_TYPES_ENUM_NAMES[type]), value: key };
      }),
    [t]
  );

  // обработчик выбора диапазона дат
  const handleChangeDates = (dateFrom: string, dateTo: string) => {
    setPeriod({ dateFrom, dateTo });
  };

  // обработчик выбора типа нотификаций
  const handleChangeNotificationType = (value: string) => {
    const type = NOTIFICATION_TYPES_ENUM[value as keyof typeof NOTIFICATION_TYPES_ENUM];

    setTypeNotifications(type || null);
  };

  // обработчик клика для показа таблицы истории нотификаций
  const handleShowTable = () => {
    dispatch(removeMarkerNotificationLog());
    dispatch(setSelectedHistoryLogs([]));
    dispatch(setIsTableHistoryLogsShow(true));
    dispatch(setFilterField({ key: 'page', value: 1 }));
  };

  // обработчик задания параметров для формирования списка истории нотификаций
  const handleClickGenerateList = () => {
    const newFilters: NotificationLogListFilter = {
      ...historyLogsFilter,
      page: 1,
      dateStart: period.dateFrom,
      dateEnd: period.dateTo,
      notificationType: typeNotifications,
    };

    dispatch(setNotificationHistoryTotal(0));
    dispatch(clearHistoryLogs());
    dispatch(setSelectedHistoryLogs([]));
    dispatch(setFilterFields(newFilters));
  };

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.header}>{t('notifications.history.header.text')}</div>
        <DatesRangeWithScripts
          handleChange={handleChangeDates}
          periodFrom={period.dateFrom}
          periodTo={period.dateTo}
          periodFromRef={periodFromRef}
          periodToRef={periodToRef}
          wrapperClass={styles.periods}
        />
        <Select
          options={typeNotificationsForSelect}
          label={t('notifications.history.select.type-notification.label')}
          placeholder={t('notifications.history.select.type-notification.placeholder')}
          isRequired={false}
          withSearchIcon
          value={
            NOTIFICATION_TYPES_ENUM[
              typeNotificationsForSelect.find(({ value }) => value === typeNotifications)
                ?.value as keyof typeof NOTIFICATION_TYPES_ENUM
            ] || ''
          }
          containerStyle={styles.select}
          disabled={false}
          handleChange={value => handleChangeNotificationType(value)}
        />

        {!isTableHistoryLogsShow && <NotificationHistoryList />}

        <div className={styles.footer}>
          {!isTableHistoryLogsShow && (
            <Fragment>
              <Button
                white
                text={t('notifications.history.button.table.label')}
                onClick={handleShowTable}
                customStyle={styles.button}
              />
              <Button
                white
                text={t('notifications.history.button.close.label')}
                onClick={onCancel}
                customStyle={styles.button}
              />
            </Fragment>
          )}
          <Button
            blue
            text={t('notifications.history.button.create.label')}
            onClick={handleClickGenerateList}
            customStyle={cx(styles.button, styles.buttonCreate)}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(NotificationHistoryLeftSideBar);
