import { Icon } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

import { TrackableUnitView } from 'reducers/user/interface';

import { zeroPad } from 'utils/zeroPad';
import { InfoProperties, PointProperties } from '../utils';

function getIconLayout({
  iconSrc,
  iconName,
  iconSizeFromLayout,
}: {
  iconSrc: string;
  iconName: string;
  iconSizeFromLayout: { width: number; height: number };
}) {
  // реальная ширина и высота иконки (в px)
  const widthIcon = iconName === 'transportIcon' ? 184 : 121;
  const heightIcon = iconName === 'transportIcon' ? 195 : 149;
  // scale иконки
  const tagUseImage =
    iconName === 'transportIcon'
      ? '<use xlink:href="#image0" transform="translate(-0.00808679) scale(0.00552268 0.00512821)"/>'
      : '<use xlink:href="#image0" transform="translate(0 -0.00575561) scale(0.00826446 0.00678867)"/>';

  return `
    <rect width="${iconSizeFromLayout.width}" height="${iconSizeFromLayout.height}" fill="url(#pattern0)"/>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">${tagUseImage}</pattern>
      <image id="image0" width="${widthIcon}" height="${heightIcon}" xlink:href="${iconSrc}"/>
    </defs>
  `;
}

function getTextLayout({ hasBg, info, flags }: { hasBg: boolean; info: InfoProperties; flags: TrackableUnitView }) {
  const trackerId = info.trackerId ? zeroPad(info.trackerId, 3) : '';
  const name = info.fio || '';
  const organizationName = info.organizationName || '';
  const departmentName = info.departmentName || '';
  const positionOrDriver = info.positionOrDriver || '';

  const boldTextColor = hasBg ? '#fff' : '#0066FF';
  const textColor = hasBg ? '#fff' : '#000';

  const strokeWidth = !hasBg ? 3 : 0;

  const fontSize = 14;

  const trackerLayout =
    flags.trackerNumberIsShown && !!trackerId ? `<div class="description__text bold">${trackerId}</div>` : '';
  const nameLayout = flags.fullNameIsShown && name ? `<div class="description__text bold">${name}</div>` : '';
  const organizationLayout =
    flags.organizationIsShown && organizationName ? `<div class="description__text">${organizationName}</div>` : '';
  const departmentLayout =
    flags.departmentIsShown && departmentName ? `<div class="description__text">${departmentName}</div>` : '';
  const positionLayout =
    flags.positionOrDriverIsShown && positionOrDriver ? `<div class="description__text">${positionOrDriver}</div>` : '';

  return `
    <style>
      .description {
        position: relative;
        font: ${fontSize}px Roboto, Calibri, sans-serif;
      }
      .description__container {
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${hasBg ? '#0066FF' : 'transparent'};
        padding: 2px 4px;
        border-radius: 5px;
      }
      .description__text {
        white-space: nowrap;
        color: ${textColor};
      }
      .bold {
        font-weight: bold;
        color: ${boldTextColor};
      }
      .stroke {
        -webkit-text-stroke: ${strokeWidth}px #fff;
        color: white;
      }
    </style>

    ${
      // stroke для текста ниже
      !hasBg &&
      `
        <div class="description__container stroke">
          ${trackerLayout}
          ${nameLayout}
          ${organizationLayout}
          ${departmentLayout}
          ${positionLayout}
        </div>
      `
    }

    <div class="description__container">
      ${trackerLayout}
      ${nameLayout}
      ${organizationLayout}
      ${departmentLayout}
      ${positionLayout}
    </div>
  `;
}

export default function getMainIconSVGMarker(properties: PointProperties, pngImagePath: string) {
  // ниже 2 строки - значения ширины и высоты иконки по макету (в px) TransportIcon и EmployeeIcon
  const widthIconFromLayout = 23;
  const heightIconFromLayout = 28;

  // общая ширина и высота маркера/viewbox svg
  const mainWidth = 500; // с запасом
  const mainHeight = 500; // с запасом

  const { info, flags } = properties;

  const isShow = flags?.alwaysShow || !!(properties.isHit && !flags.alwaysShow);

  const svgContent = `
    <svg
      preserveAspectRatio="none"
      width="${mainWidth}" height="${mainHeight}" viewBox="0 0 ${mainWidth} ${mainHeight}" fill="none"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      ${getIconLayout({
        iconSrc: pngImagePath,
        iconName: properties.iconName || '',
        iconSizeFromLayout: {
          width: widthIconFromLayout,
          height: heightIconFromLayout,
        },
      })}

      ${
        isShow &&
        `<foreignObject
          x="${flags.onRightSign ? widthIconFromLayout + 4 : 0}"
          y="${flags.onRightSign ? 0 : heightIconFromLayout + 4}"
          width="500" height="500"
        >
          <div xmlns="http://www.w3.org/1999/xhtml" class="description">
            ${getTextLayout({ hasBg: !!flags.hasBackground, info, flags })}
          </div>
        </foreignObject>`
      }
    </svg>
  `;

  const svgImage = new Image();
  svgImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgContent);

  return new Icon({
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.FRACTION,
    offset: [-mainWidth / 2, -mainHeight / 2],
    imgSize: [mainWidth, mainHeight],
    img: svgImage,
  });
}
