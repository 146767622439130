import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HANDBOOK_ENTITY_ATTRIBUTES_MAIN_FIELDS, HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import {
  getBindedHandbookKeysForChosenElements,
  getDateFromStr,
  getFieldsForNewElements,
  getTranslateFromLanguageKey,
} from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { fetchHandbookData, setChosenElement, setElements, setNewElement } from 'reducers/handbooks';
import { HandbookNewElement } from 'reducers/handbooks/interface';

import { getCurrentLocale } from 'translate';

import AccessEntity from 'utils/accessEntity';

import HandbooksElementsView, { ElementViewData } from '../handbooksElementsView/handbooksElementsView';

const DefaultHandbooksElements: React.VFC = () => {
  const dispatch = useDispatch();

  const {
    chosenHandbookName,
    elements,
    chosenElement,
    isDataLoading,
    needUpdate,
    data: handbooksData,
  } = useSelector((state: RootState) => state.handbooks);

  const handbooksPermissions = useSelector((state: RootState) => state.user.permissions.handbooks);
  const handbooksAccess = useMemo(() => new AccessEntity(handbooksPermissions), [handbooksPermissions]);

  const userLanguageLocale = useSelector((state: RootState) => state.user.userPreferences.locale);
  const userLanguageKey = useMemo(() => getCurrentLocale(userLanguageLocale), [userLanguageLocale]);

  const [chosenHandbookElementId, setChosenHandbookElementId] = useState(0);

  const chosenHandbookKey = useMemo(
    () =>
      chosenHandbookName?.keyName ? HANDBOOK_KEYS[chosenHandbookName.keyName as keyof typeof HANDBOOK_KEYS] : null,
    [chosenHandbookName]
  );

  useEffect(() => {
    if (chosenHandbookKey) {
      dispatch(fetchHandbookData([chosenHandbookKey]));
    }
  }, [dispatch, chosenHandbookKey]);

  useEffect(() => {
    if (needUpdate) {
      if (chosenHandbookKey) {
        dispatch(fetchHandbookData([chosenHandbookKey]));
      }
    }
  }, [dispatch, chosenHandbookKey, needUpdate]);

  // установка списка элементов выбранного справочника
  useEffect(() => {
    if (chosenHandbookName && Object.keys(handbooksData).length) {
      const { keyName = '' } = chosenHandbookName;

      if (handbooksData[keyName]?.length) {
        dispatch(
          setElements(
            handbooksData[keyName].map(handbook => ({
              id: handbook.id,
              name: handbook.attributes.name ?? {},
              createdAt: handbook.attributes.createdAt,
              updatedAt: handbook.attributes.updatedAt,
              deletedAt: handbook.attributes.deletedAt,
            }))
          )
        );
      } else {
        dispatch(setElements([]));
      }
    }
  }, [dispatch, chosenHandbookName, handbooksData]);

  useEffect(() => {
    if (chosenElement) {
      setChosenHandbookElementId(chosenElement.id);
    } else {
      setChosenHandbookElementId(0);
    }
  }, [chosenElement]);

  const handleClick = (id: number) => {
    const foundElement = elements.find(h => h.id === id);

    if (foundElement) {
      dispatch(setChosenElement(foundElement));
    }
  };

  const handleCreateElement = () => {
    if (!!chosenHandbookName) {
      const { keyName = '' } = chosenHandbookName;

      if (keyName) {
        const newElementFields = getFieldsForNewElements(keyName);

        dispatch(setNewElement(newElementFields));
        dispatch(setChosenElement(null));
      }
    }
  };

  const handleCopyElement = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    ev.stopPropagation();
    if (!!chosenHandbookName && handbooksAccess.isAllowCreate()) {
      const { keyName = '' } = chosenHandbookName;
      const foundElement = handbooksData[keyName]?.find(data => data.id === id);

      if (keyName && foundElement) {
        const { attributes, relationships } = foundElement;

        let newElementFields: HandbookNewElement = {
          name: attributes.name,
          ...Object.keys(attributes).reduce((prev, curr) => {
            if (!HANDBOOK_ENTITY_ATTRIBUTES_MAIN_FIELDS.includes(curr)) {
              prev[curr] = attributes[curr] ?? '';
            }
            return prev;
          }, {} as Record<string, unknown>),
        };

        newElementFields = {
          ...newElementFields,
          ...getBindedHandbookKeysForChosenElements(keyName).reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: [],
            }),
            {}
          ),
          ...Object.keys(relationships).reduce((prev, curr) => {
            prev[curr] = relationships[curr].data.map(d => d.id) ?? [];
            return prev;
          }, {} as HandbookNewElement),
        };

        dispatch(setNewElement(newElementFields));
        dispatch(setChosenElement(null));
      }
    }
  };

  const elementsViewData: ElementViewData[] = useMemo(
    () =>
      elements.map(element => ({
        id: element.id,
        name: getTranslateFromLanguageKey(element.name, userLanguageKey),
        createdAt: getDateFromStr(element.createdAt),
        updatedAt: getDateFromStr(element.updatedAt),
      })),
    [elements]
  );

  return (
    <HandbooksElementsView
      isLoading={isDataLoading}
      elements={elementsViewData}
      chosenElementId={chosenHandbookElementId}
      accessEntity={handbooksAccess}
      handleCreateClick={handleCreateElement}
      handleElementClick={handleClick}
      handleCopyElementClick={handleCopyElement}
    />
  );
};

export default DefaultHandbooksElements;
