import { useCallback, useEffect, useState } from 'react';

import { Feature, Map, MapBrowserEvent } from 'ol';
import { easeOut } from 'ol/easing';
import { createEmpty as createEmptyExtent, extend as extentExtend, getCenter as getExtentCenter } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import { FeatureLike } from 'ol/Feature';

import { ContactsRow } from '../components/socialContactsMap/socialContactsMap';

import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneEmployee } from 'reducers/employees';
import { fetchOneTransport } from 'reducers/transports';

import { MARKERS, MAX_ZOOM_DEFAULT_VALUE, MIN_ZOOM_DEFAULT_VALUE } from 'utils/consts';
import { MOCK_CISTERN_POPUP_DATA } from 'utils/mocks';
import { pointStyleFunction } from '../utils';
import { CisternState, CisternProperties } from '../types';

let selectedFeature: FeatureLike | null = null;

export function useHandlers(map: Map) {
  const dispatch = useDispatch();

  const [showEmployeePopup, setShowEmployeePopup] = useState(false);
  const [showTransportPopup, setShowTransportPopup] = useState(false);
  const [showCisternPopup, setShowCisternPopup] = useState(false);
  const [contactsData, setContactsData] = useState<ContactsRow[] | null>(null);
  const [showContacts, setShowContacts] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 }, mapCoords: { x: 0, y: 0 } });
  const [cisternData, setCisternData] = useState(MOCK_CISTERN_POPUP_DATA);
  const [cisternProperties, setCisternProperties] = useState<CisternProperties>({
    level: CisternState.Default,
  });

  const markerType = useSelector((state: RootState) => state.map.markerType);

  const handler = useCallback((e: MapBrowserEvent<UIEvent>) => {
    if (e.dragging) {
      return;
    }
    if (selectedFeature !== null) {
      (selectedFeature as Feature).setStyle(undefined);
      selectedFeature = null;
    }
    const { pixel, map } = e;
    const hit = map.hasFeatureAtPixel(pixel);

    if (hit) {
      const features = map.getFeaturesAtPixel(pixel);
      features.forEach((feature: FeatureLike) => {
        const properties = feature.getProperties();
        const values = properties?.features?.length ? properties.features[0].getProperties() : properties.values;
        const resolution = map.getView().getResolution() ?? 1;
        if (values && values.type === 'geoMarker') {
          selectedFeature = feature;
          (feature as Feature).setStyle(pointStyleFunction(resolution, { ...values, isHit: true }));
        }
        return feature;
      });
    } else {
      map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
        const values = feature.getProperties().values;
        const resolution = map.getView().getResolution() ?? 1;
        if (values && values.type === 'geoMarker') {
          (feature as Feature).setStyle(pointStyleFunction(resolution, { ...values, isHit: false }));
        }
        return feature;
      });
    }
  }, []);

  const clickHandler = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      setShowEmployeePopup(false);
      setShowTransportPopup(false);
      const { pixel, map } = e;
      const mouseCoords = {
        x: e.originalEvent.clientX,
        y: e.originalEvent.clientY,
      };

      map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
        if (feature.get('features')) {
          if (feature.get('features').length === 1) {
            const values = feature.get('features')[0].getProperties('values');
            if (values.type === 'geoMarker' && values.iconName === 'employeeIcon') {
              setShowTransportPopup(false);
              setMarkerCoords({
                mouseCoords: { x: mouseCoords.x, y: mouseCoords.y },
                mapCoords: { x: values.mapCoords[0], y: values.mapCoords[1] },
              });
              setShowEmployeePopup(true);
              dispatch(fetchOneEmployee(values.info.id));
              if (values.contactsData) {
                setContactsData(values.contactsData);
              }
            }
            if (values.type === 'geoMarker' && values.iconName === 'transportIcon') {
              setShowEmployeePopup(false);
              setMarkerCoords({
                mouseCoords: { x: mouseCoords.x, y: mouseCoords.y },
                mapCoords: { x: values.mapCoords[0], y: values.mapCoords[1] },
              });
              setShowTransportPopup(true);
              dispatch(fetchOneTransport(values.info.id));
            }
          } else {
            const originalFeatures = feature.get('features');
            const extent = createEmptyExtent();
            originalFeatures.forEach(function (f: Feature) {
              const geometry = f.getGeometry();
              if (geometry) {
                extentExtend(extent, geometry.getExtent());
              }
            });

            map.getView().fit(extent, {
              size: map.getSize(),
              padding: [150, 150, 150, 150],
              duration: 2000,
              easing: easeOut,
              maxZoom: MAX_ZOOM_DEFAULT_VALUE - 1.5 * MIN_ZOOM_DEFAULT_VALUE,
            });
          }
        } else {
          const values = feature.getProperties();
          if (values.type === 'controlObject') {
            const geometry = feature.getGeometry();
            if (geometry) {
              const center = toLonLat(getExtentCenter(geometry.getExtent()));
              setCisternData(values.data);
              setCisternProperties({
                level: values.level,
              });
              setMarkerCoords({
                mouseCoords: { x: mouseCoords.x, y: mouseCoords.y },
                mapCoords: { x: center[0], y: center[1] },
              });
              setShowCisternPopup(true);
            }
          }
        }

        return feature;
      });
    },
    [dispatch]
  );

  const cursorHandler = useCallback((evt: MapBrowserEvent<UIEvent>) => {
    if (evt.dragging) {
      return;
    }
    const { pixel, map } = evt;
    const hit = map.hasFeatureAtPixel(pixel);

    if (hit) {
      map.getTargetElement().style.cursor = 'pointer';
    } else {
      map.getTargetElement().style.cursor = '';
    }
  }, []);

  useEffect(() => {
    if (markerType === MARKERS.onmouseover) {
      map.on('pointermove', handler);
    } else {
      map.un('pointermove', handler);
    }

    return () => {
      map.un('pointermove', handler);
    };
  }, [map, markerType, handler]);

  useEffect(() => {
    map.on('click', clickHandler);
    map.on('pointermove', cursorHandler);

    return () => {
      map.un('click', clickHandler);
      map.un('pointermove', cursorHandler);
    };
  }, [map, clickHandler, cursorHandler]);

  return {
    employeePopup: {
      onCancel: () => setShowEmployeePopup(false),
      markerCoords: markerCoords,
      handleShowContacts: () => setShowContacts(true),
    },
    transportPopup: {
      onCancel: () => setShowTransportPopup(false),
      markerCoords: markerCoords,
    },
    cisternPopup: {
      onCancel: () => setShowCisternPopup(false),
      markerCoords: markerCoords,
      data: cisternData,
      properties: cisternProperties,
    },
    contactsPopup: {
      contacts: contactsData,
      handleCancel: () => setShowContacts(false),
      handleContactsData: (data: ContactsRow[]) => setContactsData(data),
    },
    showFlags: {
      showEmployeePopup,
      showTransportPopup,
      showCisternPopup,
      showContacts,
    },
    setMarkerCoords,
  };
}
