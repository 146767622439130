import RequestBase from './RequestBase';

import { FileDataInterface } from './interface';
import { RequestHandlersMethods, defaultAdditionalOptions } from './consts';

export default class RequestHandlers extends RequestBase {
  // ----------------- GET -----------------
  async get(route: string, additionalOptions = defaultAdditionalOptions) {
    let jsonOrBlob;

    try {
      if (!this.isParallelRequest) {
        this.setOptions(additionalOptions);
      }

      const response = await this.fetchData({ method: RequestHandlersMethods.GET, route });

      if (additionalOptions.hasBlobResponseData) {
        jsonOrBlob = await response.blob();
      } else {
        jsonOrBlob = await response.json();
      }

      if (additionalOptions.hasSuccessResponseFields || additionalOptions.hasBlobResponseData) {
        const { type, disposition } = this.getTypeAndDisposition(response.headers);

        return {
          successResponseFields: {
            status: response.status,
            type,
            disposition,
          },
          data: jsonOrBlob,
        };
      }
    } catch (errors) {
      const repeatReqCallback = async () => {
        jsonOrBlob = await this.get(route, additionalOptions);
      };

      await this.processErrorPayload(errors, repeatReqCallback);
    }
    return jsonOrBlob;
  }

  // ----------------- GET FILE -----------------
  async getFile(route: string, additionalOptions = defaultAdditionalOptions) {
    return await this.get(route, {
      ...additionalOptions,
      hasBlobResponseData: true,
      hasSuccessResponseFields: true,
    });
  }

  // ----------------- POST -----------------
  async post(route: string, data: FileDataInterface | unknown, additionalOptions = defaultAdditionalOptions) {
    let jsonOrBlob;

    try {
      if (!this.isParallelRequest) {
        this.setOptions(additionalOptions);
      }

      const response = await this.fetchData({ method: RequestHandlersMethods.POST, route, data });

      if (additionalOptions.hasBlobResponseData) {
        jsonOrBlob = await response.blob();
      } else {
        jsonOrBlob = await response.json();
      }

      if (additionalOptions.hasSuccessResponseFields) {
        const { type, disposition } = this.getTypeAndDisposition(response.headers);

        return {
          successResponseFields: {
            status: response.status,
            type,
            disposition,
          },
          data: jsonOrBlob,
        };
      }
    } catch (errors) {
      const repeatReqCallback = async () => {
        jsonOrBlob = await this.post(route, data, additionalOptions);
      };

      await this.processErrorPayload(errors, repeatReqCallback);
    }
    return jsonOrBlob;
  }

  // ----------------- PATCH -----------------
  async patch(route: string, data: unknown, additionalOptions = defaultAdditionalOptions) {
    let json;

    try {
      if (!this.isParallelRequest) {
        this.setOptions(additionalOptions);
      }

      const response = await this.fetchData({ method: RequestHandlersMethods.PATCH, route, data });

      json = await response.json();

      if (additionalOptions.hasSuccessResponseFields) {
        const { type, disposition } = this.getTypeAndDisposition(response.headers);

        return {
          successResponseFields: {
            status: response.status,
            type,
            disposition,
          },
          data: json,
        };
      }
    } catch (errors) {
      const repeatReqCallback = async () => {
        json = await this.patch(route, data, additionalOptions);
      };

      await this.processErrorPayload(errors, repeatReqCallback);
    }
    return json;
  }

  // ----------------- PUT -----------------
  async put(route: string, data: unknown, additionalOptions = defaultAdditionalOptions) {
    let json;

    try {
      if (!this.isParallelRequest) {
        this.setOptions(additionalOptions);
      }

      const response = await this.fetchData({ method: RequestHandlersMethods.PUT, route, data });

      // используем повторно try catch, т.к. некоторые ручки c backend не возвращают информацию,
      // а обработка ошибок в (catch) не должна генерить ошибки (throw) и показывать toast для put
      try {
        const json = await response.json();

        if (additionalOptions.hasSuccessResponseFields) {
          const { type, disposition } = this.getTypeAndDisposition(response.headers);

          return {
            successResponseFields: {
              status: response.status,
              type,
              disposition,
            },
            data: json,
          };
        }
        return json;
      } catch (error) {
        return false;
      }
    } catch (errors) {
      const repeatReqCallback = async () => {
        json = await this.put(route, data, additionalOptions);
      };

      await this.processErrorPayload(errors, repeatReqCallback);
    }
    return json;
  }

  // ----------------- DELETE -----------------
  async delete(route: string, data?: unknown, additionalOptions = defaultAdditionalOptions) {
    try {
      if (!this.isParallelRequest) {
        this.setOptions(additionalOptions);
      }
      await this.fetchData({ method: RequestHandlersMethods.DELETE, route, data });
    } catch (errors) {
      const repeatReqCallback = async () => await this.delete(route, data, additionalOptions);

      await this.processErrorPayload(errors, repeatReqCallback);
    }
  }
}
