import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Option } from 'components/common/select/select';
import { defaultConditions } from 'components/notifications/utils/consts';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { ConditionsType, SensorConditions } from 'reducers/notifications/interface';

import { getCurrentLocale } from 'translate';

const { sensor: defaults } = defaultConditions;

type Params = {
  isFoulFixing: boolean;
  setIsFoulFixing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useValueSensorInfo(params: Params) {
  const { chosenNotification, chosenNotificationType, additionalInfo } = useSelector(
    (state: RootState) => state.notifications
  );
  const { notificationSensors, notificationActivations } = additionalInfo;
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const [notificationSensorId, setNotificationSensorId] = useState(defaults.notificationSensorId);
  const [nameOfSensor, setNameOfSensor] = useState(defaults.nameOfSensor);
  const [notificationActivationId, setNotificationActivationId] = useState(defaults.notificationActivationId);
  const [valueFrom, setValueFrom] = useState(defaults.valueFrom);
  const [valueTo, setValueTo] = useState(defaults.valueTo);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [notificationSensorIdForSelect, setNotificationSensorIdForSelect] = useState<Option[]>([]);
  const [notificationActivationIdForSelect, setNotificationActivationIdForSelect] = useState<Option[]>([]);

  useEffect(() => {
    if (!notificationSensors.length) {
      return;
    }
    setNotificationSensorIdForSelect(
      notificationSensors.map(type => ({
        value: type.id,
        label: getTranslateFromLanguageKey(type.name, userLanguageKey),
      }))
    );
  }, [notificationSensors, userLanguageKey]);

  useEffect(() => {
    if (!notificationActivations.length) {
      return;
    }
    setNotificationActivationIdForSelect(
      notificationActivations.map(activation => ({
        value: activation.id,
        label: getTranslateFromLanguageKey(activation.name, userLanguageKey),
      }))
    );
  }, [notificationActivations, userLanguageKey]);

  useEffect(() => {
    let conditions: ConditionsType = null;
    if (chosenNotification && chosenNotificationType === chosenNotification.attributes.notificationType) {
      conditions = chosenNotification.attributes.conditions as SensorConditions;
    } else {
      conditions = defaults;
    }
    setNotificationSensorId(conditions.notificationSensorId);
    setNameOfSensor(conditions.nameOfSensor);
    setNotificationActivationId(conditions.notificationActivationId);
    setValueFrom(conditions.valueFrom);
    setValueTo(conditions.valueTo);
  }, [chosenNotification, chosenNotificationType]);

  const handleChangeConditionOfActivation = (value: string) => {
    setNotificationActivationId(Number(value));
  };

  const handleCheckedFoulFixing = () => params.setIsFoulFixing(!params.isFoulFixing);

  const handleChangeValueFromValue = (value: string) => {
    const nValue = Number(value);

    if (!isNaN(nValue) && nValue >= 0) {
      setValueFrom(nValue);
    } else if (!value) {
      setValueFrom(-1);
    }
  };
  const handleChangeValueToValue = (value: string) => {
    const nValue = Number(value);

    if (!isNaN(nValue) && nValue >= 0) {
      setValueTo(nValue);
    } else if (!value) {
      setValueTo(-1);
    }
  };

  const handleChangeNameOfSensor = (value: string) => setNameOfSensor(value);

  const handleChangeTypeOfSensor = (value: string) => {
    const findedOption = notificationSensorIdForSelect.find(sensor => sensor.value === Number(value));
    const chosenId = Number(findedOption?.value);

    if (chosenId) {
      setNotificationSensorId(chosenId);
    }
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!notificationSensorId) {
      newInvalidFields.push('notificationSensorId');
    }
    if (!nameOfSensor) {
      newInvalidFields.push('nameOfSensor');
    }
    if (!valueFrom) {
      newInvalidFields.push('valueFromSensor');
    }
    if (!valueTo) {
      newInvalidFields.push('valueToSensor');
    }
    if (valueTo < valueFrom) {
      newInvalidFields.push('valueToLessThanToSensor');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    const currentSensorConditions =
      (chosenNotification?.attributes.conditions as SensorConditions) || defaultConditions.interposition;

    if (
      notificationSensorId !== currentSensorConditions.notificationSensorId ||
      nameOfSensor !== currentSensorConditions.nameOfSensor ||
      notificationActivationId !== currentSensorConditions.notificationActivationId ||
      valueFrom !== currentSensorConditions.valueFrom ||
      valueTo !== currentSensorConditions.valueTo
    ) {
      return true;
    }
    return false;
  };

  return {
    states: {
      notificationSensorId,
      nameOfSensor,
      notificationActivationId,
      valueFrom,
      valueTo,
      notificationSensorIdForSelect,
      notificationActivationIdForSelect,
    },
    handlers: {
      handleChangeConditionOfActivation,
      handleCheckedFoulFixing,
      handleChangeValueFromValue,
      handleChangeValueToValue,
      handleChangeNameOfSensor,
      handleChangeTypeOfSensor,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
