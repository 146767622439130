import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import ColumnTable from './components/columnTable/columnTable';
import HandbooksCard from './components/handbooksCard/handbooksCard';
import HandbooksElements from './components/handbooksElements/handbooksElements';
import HandbooksList from './components/handbooksList/handbooksList';

import styles from './handbooksTable.module.scss';

const HandbooksTable: React.FC = () => {
  const t = useFormatMessage();

  return (
    <div className={styles.wrapper}>
      <ColumnTable title={t('handbooks.table.header.dictionaries.label')} withVerticalScroll>
        <HandbooksList />
      </ColumnTable>
      <ColumnTable title={t('handbooks.table.header.elements.label')} customStyle={styles.elementsColumn} resizable>
        <HandbooksElements />
      </ColumnTable>
      <ColumnTable title={t('handbooks.table.header.elements-card.label')} customStyle={styles.cardColumn}>
        <HandbooksCard />
      </ColumnTable>
    </div>
  );
};

export default HandbooksTable;
