export default {
  en: {
    'map-popup.cistern.header.text': 'Object card',
    'map-popup.cistern.state.text': 'State:',
    'map-popup.cistern.year.text': 'Year of commissioning:',
    'map-popup.cistern.company.text': 'Company:',
    'map-popup.cistern.subdivision.text': 'Subdivision:',
    'map-popup.cistern.object.text': 'Work object progress:',
    'map-popup.cistern.date-last.text': 'Date of last repair:',
    'map-popup.cistern.date-next.text': 'Date of next repair:',
    'map-popup.cistern.coords.text': 'Coordinates:',
    'map-popup.cistern.repair.text': 'Upgrades/Repairs',
    'map-popup.cistern.violation.text': 'Violations',
    'map-popup.cistern.current-coords.text': 'Current position',
  },
  ru: {
    'map-popup.cistern.header.text': 'Карточка объекта',
    'map-popup.cistern.state.text': 'Состояние:',
    'map-popup.cistern.year.text': 'Год ввода в эксплуатацию:',
    'map-popup.cistern.company.text': 'Организация:',
    'map-popup.cistern.subdivision.text': 'Структурное подразделение:',
    'map-popup.cistern.object.text': 'Объект выполнения работ:',
    'map-popup.cistern.date-last.text': 'Дата последнего ремонта:',
    'map-popup.cistern.date-next.text': 'Дата следующего ремонта:',
    'map-popup.cistern.coords.text': 'Координаты:',
    'map-popup.cistern.repair.text': 'Модернизации/Ремонты',
    'map-popup.cistern.violation.text': 'Нарушения',
    'map-popup.cistern.current-coords.text': 'Текущее положение',
  },
  zh: {
    'map-popup.cistern.header.text': '對象卡',
    'map-popup.cistern.state.text': '状态：',
    'map-popup.cistern.year.text': '生产年份：',
    'map-popup.cistern.company.text': '组织：',
    'map-popup.cistern.subdivision.text': '结构分解：',
    'map-popup.cistern.object.text': '工作目标：',
    'map-popup.cistern.date-last.text': '上次装修日期：',
    'map-popup.cistern.date-next.text': '下次装修日期：',
    'map-popup.cistern.coords.text': '协调：',
    'map-popup.cistern.repair.text': '升级/维修',
    'map-popup.cistern.violation.text': '违规行为',
    'map-popup.cistern.current-coords.text': '当前位置',
  },
  ar: {
    'map-popup.cistern.header.text': 'بطاقة الكائن',
    'map-popup.cistern.state.text': 'الحالة:',
    'map-popup.cistern.year.text': 'سنة بدء التشغيل:',
    'map-popup.cistern.company.text': 'مؤسسة:',
    'map-popup.cistern.subdivision.text': 'شعبة:',
    'map-popup.cistern.object.text': 'عملية تنفيذ العمل:',
    'map-popup.cistern.date-last.text': 'تاريخ التصليح الأخير:',
    'map-popup.cistern.date-next.text': 'تاريخ التصليح التالي:',
    'map-popup.cistern.coords.text': 'الإحداثيات:',
    'map-popup.cistern.repair.text': 'تحسينات/ تصليحات',
    'map-popup.cistern.violation.text': 'انتهاكات',
    'map-popup.cistern.current-coords.text': 'حالة جارية',
  },
  de: {
    'map-popup.cistern.header.text': 'Objektkarte',
    'map-popup.cistern.state.text': 'Bedingung:',
    'map-popup.cistern.year.text': 'Inbetriebnahmejahr:',
    'map-popup.cistern.company.text': 'Organisation:',
    'map-popup.cistern.subdivision.text': 'Strukturelle Unterteilung:',
    'map-popup.cistern.object.text': 'Arbeitsgegenstand:',
    'map-popup.cistern.date-last.text': 'Datum der letzten Renovierung:',
    'map-popup.cistern.date-next.text': 'Datum der nächsten Renovierung:',
    'map-popup.cistern.coords.text': 'Koordinaten:',
    'map-popup.cistern.repair.text': 'Upgrades / Reparaturen',
    'map-popup.cistern.violation.text': 'Verstöße',
    'map-popup.cistern.current-coords.text': 'aktuelle Position',
  },
};
