import React, { forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Switch from 'components/common/switch/switch';
import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { TextArea } from 'components/common/textArea/textArea';
import { NOTIFICATED_HOW } from 'components/notifications/utils/consts';
import { NotificationMainInfoStates, NotificationMainInfoHandlers } from 'components/notifications/utils/types';
import { getIdFromTypeNotification } from 'components/notifications/utils/helpers';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../tabsModal.module.scss';

const cx = classNames.bind(styles);

interface Props {
  states: NotificationMainInfoStates;
  handlers: NotificationMainInfoHandlers;
  refs: {
    reportFromRef: React.RefObject<unknown>;
    reportToRef: React.RefObject<unknown>;
  };
  invalidFields: string[];
  isVideoAnalytics: boolean;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
        isRequired={props.isRequired}
        isValueError={props.isValueError}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

export const MainTab = ({ states, handlers, refs, invalidFields, isVideoAnalytics }: Props) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalWrapperContent}>
        <div className={styles.modalColLeft}>
          <div className={cx(styles.modalRow, styles.modalRowCursorPointer)} onClick={handlers.handleChangeTurn}>
            <Switch checked={states.isActive} />
            <span>
              {states.isActive
                ? t('notifications.card.tabs.general.field.notify-on.text')
                : t('notifications.card.tabs.general.field.notify-off.text')}
            </span>
          </div>
          <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
            <Input
              label={t('notifications.card.tabs.general.field.name.label')}
              placeholder={t('notifications.card.tabs.general.field.name.placeholder')}
              isValueError={invalidFields.includes('name')}
              isRequired={true}
              value={states.name}
              customStyle={styles.inputWrap}
              disabled={false}
              handleInputChange={value => handlers.handleChangeName(value)}
            />
          </div>
          <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
            <CustomSelect
              options={states.notificationTypesForSelect}
              label={t('notifications.card.tabs.general.field.type.label')}
              placeholder={t('notifications.card.tabs.general.field.type.placeholder')}
              isValueError={invalidFields.includes('typeNotification')}
              isRequired={true}
              withSearchIcon
              value={getIdFromTypeNotification(states.typeNotification)}
              style={styles.inputWrap}
              handleChange={handlers.handleChangeTypeNotification}
              disabled={false}
            />
          </div>
          <div
            className={cx(styles.modalRow, styles.modalRowCursorPointer)}
            onClick={() => handlers.handleChangeTypeTurnNotification(NOTIFICATED_HOW.popup)}
          >
            <Switch checked={states.isPopupPushOn} />
            <span>{t('notifications.card.tabs.general.field.notify-popup.text')}</span>
          </div>
          <div
            className={cx(styles.modalRow, styles.modalRowCursorPointer)}
            onClick={() => handlers.handleChangeTypeTurnNotification(NOTIFICATED_HOW.email)}
          >
            <Switch checked={states.isEmailPushOn} />
            <span>{t('notifications.card.tabs.general.field.notify-email.text')}</span>
          </div>
          <div
            className={cx(styles.modalRow, styles.modalRowCursorPointer)}
            onClick={() => handlers.handleChangeTypeTurnNotification(NOTIFICATED_HOW.sms)}
          >
            <Switch checked={states.isSmsPushOn} />
            <span>{t('notifications.card.tabs.general.field.notify-sms.text')}</span>
          </div>
          {(states.isEmailPushOn || states.isSmsPushOn) && (
            <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
              <MultipleSelect
                options={states.accountsShortListForSelect}
                disabled={!states.accountsShortListForSelect.length && !states.chosenUsers.length}
                label={t('notifications.card.tabs.general.field.accounts.label')}
                placeholder={t('notifications.card.tabs.general.field.accounts.placeholder')}
                isValueError={invalidFields.includes('chosenUsers')}
                isRequired={true}
                withSearchIcon
                customSelectStyle={styles.inputWrap}
                handleChangeMultiple={handlers.handleChangeUsers}
                values={states.chosenUsers.map((id, i) =>
                  getValueForMultipleSelect({
                    i,
                    id,
                    selectValues: states.accountsShortListForSelect,
                  })
                )}
              />
            </div>
          )}
          {!isVideoAnalytics && (
            <div
              className={cx(styles.modalRow, styles.modalRowCursorPointer)}
              onClick={handlers.handleChangeGeneratedReport}
            >
              <Switch checked={states.isGenerateReport} />
              <span>{t('notifications.card.tabs.general.field.report.text')}</span>
            </div>
          )}
          {states.isGenerateReport && (
            <Fragment>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <CustomSelect
                  options={[]}
                  label={t('notifications.card.tabs.general.field.report-template.label')}
                  placeholder={t('notifications.card.tabs.general.field.report-template.placeholder')}
                  isValueError={invalidFields.includes('reportTemplateId')}
                  isRequired={true}
                  withSearchIcon
                  value={0}
                  style={styles.inputWrap}
                  disabled={true}
                />
              </div>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <DatePicker
                  dateFormat="dd.MM.yyyy, HH:mm"
                  selected={states.reportStartDate ? new Date(states.reportStartDate) : null}
                  onChange={(date: Date) => handlers.handleChangeStartDate(date)}
                  className={styles.tabInfoDatePicker}
                  placeholderText={'21.08.2020 00:00'}
                  customInput={
                    <DatePickerCustomInput
                      ref={refs.reportFromRef}
                      label={t('notifications.card.tabs.general.field.report-from.label')}
                      isValueError={invalidFields.includes('reportStartDate')}
                      isRequired={true}
                    />
                  }
                  openToDate={states.reportStartDate ? new Date(states.reportStartDate) : new Date()}
                  locale={getCurrentLocaleForDatePicker(locale)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={t('notifications.card.tabs.general.field.report-from.time.text')}
                  disabled={false}
                  maxDate={states.reportEndDate ? new Date(states.reportEndDate) : null}
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={
                    !differenceInCalendarDays(new Date(states.reportStartDate), new Date(states.reportEndDate))
                      ? setHours(
                          setMinutes(new Date(), getMinutes(new Date(states.reportEndDate))),
                          getHours(new Date(states.reportEndDate))
                        )
                      : setHours(setMinutes(new Date(), 59), 23)
                  }
                />
              </div>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <DatePicker
                  dateFormat="dd.MM.yyyy, HH:mm"
                  selected={states.reportEndDate ? new Date(states.reportEndDate) : null}
                  onChange={(date: Date) => handlers.handleChangeEndDate(date)}
                  className={styles.tabInfoDatePicker}
                  placeholderText={'21.08.2020 23:59'}
                  customInput={
                    <DatePickerCustomInput
                      ref={refs.reportToRef}
                      label={t('notifications.card.tabs.general.field.report-to.label')}
                      isValueError={invalidFields.includes('reportEndDate')}
                      isRequired={true}
                    />
                  }
                  openToDate={states.reportEndDate ? new Date(states.reportEndDate) : new Date()}
                  locale={getCurrentLocaleForDatePicker(locale)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={t('notifications.card.tabs.general.field.report-to.time.text')}
                  disabled={false}
                  minDate={states.reportStartDate ? new Date(states.reportStartDate) : null}
                  minTime={
                    !differenceInCalendarDays(new Date(states.reportStartDate), new Date(states.reportEndDate))
                      ? setHours(
                          setMinutes(new Date(), getMinutes(new Date(states.reportStartDate))),
                          getHours(new Date(states.reportStartDate))
                        )
                      : setHours(setMinutes(new Date(), 0), 0)
                  }
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
              </div>
            </Fragment>
          )}
        </div>
        <div className={styles.modalColRight}>
          <div className={cx(styles.modalRow, styles.modalRowFullWidth, styles.textArea)}>
            <TextArea
              label={t('notifications.card.tabs.general.field.text-notify.label')}
              placeholder={t('notifications.card.tabs.general.field.text-notify.placeholder')}
              containerStyle={styles.textAreaWrapper}
              areaStyle={styles.areaStyle}
              handleChange={text => handlers.handleChangeText(text)}
              value={states.text}
              isValueError={invalidFields.includes('text')}
              isRequired={true}
            />
          </div>
          <div className={cx(styles.modalRow, styles.tagsInfo)}>
            <ul className={styles.leftTagsColumn}>
              <li>%UNIT%</li>
              <li>%POS_TIME%</li>
              <li>%CUR_TIME%</li>
              <li>%POS%</li>
              <li>%ZONE%</li>
              <li>%LAST_POS%</li>
              <li>%SENSOR_N%</li>
              <li>%SENSOR_V%</li>
              <li>%POI_NAME%</li>
              <li>%POI_TYPE%</li>
              <li>%POI_VALID%</li>
            </ul>
            <ul className={styles.rightTagsColumn}>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.obj.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.date-time.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.obj-coords.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.obj-location.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.detector-name.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.detector-value.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.poi-name.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.poi-type.text')}</li>
              <li>{t('notifications.card.tabs.general.field.text-notify.labels.poi-valid.text')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
