export default {
  en: {
    'notifications.button-block.btn.label': 'Create',

    'notifications.button.history.label': 'Notifications history',
    'notifications.history.header.text': 'Notifications history',
    'notifications.history.select.type-notification.label': 'Notification type',
    'notifications.history.select.type-notification.placeholder': 'All types of notifications',
    'notifications.history.list.header.number.text': '№',
    'notifications.history.list.header.name.text': 'Name',
    'notifications.history.list.header.status.text': 'Status',
    'notifications.history.list.header.map.text': 'Map',
    'notifications.history.list.status.read.text': 'Read',
    'notifications.history.list.status.no-read.text': 'Unread',
    'notifications.history.list.data.date.text': 'Date',
    'notifications.history.list.data.type.text': 'Type',
    'notifications.history.list.data.object.text': 'Object',
    'notifications.history.button.table.label': 'Table',
    'notifications.history.button.close.label': 'Close',
    'notifications.history.button.create.label': 'Generate a list of notifications',
    'notifications.history.table.header.label': 'Notifications history',
    'notifications.history.table.header.number.label': '№',
    'notifications.history.table.header.date.label': 'Date',
    'notifications.history.table.header.name.label': 'Name',
    'notifications.history.table.header.type.label': 'Type',
    'notifications.history.table.header.object.label': 'Object',
    'notifications.history.table.header.status.label': 'Status',
    'notifications.history.table.button.close.label': 'Close',

    'notifications.list.header.notification.label': 'Notification',

    'notifications.list.header.tooltip.activate.text': 'Activate/deactivate notifications',
    'notifications.list.header.tooltip.triggered.text': 'Number of triggered notifications',
    'notifications.list.header.tooltip.objects.text': 'Number of objects that triggered the notification',
    'notifications.list.header.tooltip.settings.text': 'Settings',

    'notifications.list.item.tooltip.settings.text': 'Settings',
    'notifications.list.item.tooltip.testNotification.remove.text': 'Remove test notification',

    'notifications.grouping-modal.header.label': 'Grouping',
    'notifications.grouping-modal.grouping.type.text': 'Grouping by notification type',
    'notifications.grouping-modal.footer.btn.cancel.label': 'Cancel',
    'notifications.grouping-modal.footer.btn.apply.label': 'Apply',

    'notifications.card.header.label': 'Notification properties',
    'notifications.test.card.header.label': 'Test notification for',
    'notifications.test.card.photo.label': 'Test notification for',

    'notifications.card.tabs.general.text': 'General',
    'notifications.card.tabs.general.field.notify-on.text': 'Notification is on',
    'notifications.card.tabs.general.field.notify-off.text': 'Notification is off',
    'notifications.card.tabs.general.field.name.label': 'Notification name',
    'notifications.card.tabs.general.field.name.placeholder': 'Enter notification name',
    'notifications.card.tabs.general.field.type.label': 'Notification type',
    'notifications.card.tabs.general.field.events.label': 'Events',
    'notifications.card.tabs.general.field.type.placeholder': 'Select from the list',
    'notifications.card.tabs.general.field.notify-popup.text': 'Notification in pop-up window',
    'notifications.card.tabs.general.field.notify-email.text': 'Notify by email',
    'notifications.card.tabs.general.field.notify-sms.text': 'Notify by SMS',
    'notifications.card.tabs.general.field.accounts.label': 'Users to receive notifications',
    'notifications.card.tabs.general.field.accounts.placeholder': 'Select from the list',
    'notifications.card.tabs.general.field.report.text': 'Generate a report',
    'notifications.card.tabs.general.field.report-template.label': 'Report template',
    'notifications.card.tabs.general.field.report-template.placeholder': 'Select from the list',
    'notifications.card.tabs.general.field.report-from.label': 'Select the report period, from',
    'notifications.card.tabs.general.field.report-from.time.text': 'Time',
    'notifications.card.tabs.general.field.report-to.label': 'Select the report period, to',
    'notifications.card.tabs.general.field.report-to.time.text': 'Time',
    'notifications.card.tabs.general.field.text-notify.label': 'Notification text',
    'notifications.card.tabs.general.field.text-notify.placeholder':
      'Enter notification text. System tags are supported, for example: %POS_TIME% - Object %OBJECT% has a low battery charge.',
    'notifications.card.tabs.general.field.text-notify.labels.obj.text': 'Object’s name',
    'notifications.card.tabs.general.field.text-notify.labels.date-time.text': 'Date/time of triggered notification',
    'notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text': 'Current date/time',
    'notifications.card.tabs.general.field.text-notify.labels.obj-coords.text': 'Object’s coordinates',
    'notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text': 'Object’s geofence',
    'notifications.card.tabs.general.field.text-notify.labels.obj-location.text': 'Object’s last known location',
    'notifications.card.tabs.general.field.text-notify.labels.detector-name.text': 'Triggered detector’s name',
    'notifications.card.tabs.general.field.text-notify.labels.detector-value.text': 'Triggered detector’s value',
    'notifications.card.tabs.general.field.text-notify.labels.poi-name.text': 'Name of POI',
    'notifications.card.tabs.general.field.text-notify.labels.poi-type.text': 'T  ype of POI',
    'notifications.card.tabs.general.field.text-notify.labels.poi-valid.text': 'POI validity period (Date Time)',

    'notifications.card.tabs.objects.text': 'Objects',
    'notifications.card.tabs.objects.field.employees.text': 'Employees',
    'notifications.card.tabs.objects.field.vehicles.text': 'Vehicles',
    'notifications.card.tabs.objects.field.available.header.text': 'Available objects',
    'notifications.card.tabs.objects.field.available.btn.select-all.label': 'Select all',
    'notifications.card.tabs.objects.field.selected.header.text': 'Selected objects',
    'notifications.card.tabs.objects.field.selected.btn.select-all.label': 'Select all',

    'notifications.card.tabs.conditions.text': 'Conditions and actions',
    'notifications.card.tabs.conditions.default.desc.text': 'Select notification type on the “General” tab',

    'notifications.card.tabs.conditions.field.alert.rec-signal.text': 'Record SOS signal',
    'notifications.card.tabs.conditions.field.alert.rec-signal.tooltip.text':
      'System will record SOS signals from trackers',
    'notifications.card.tabs.conditions.field.alert.rec-trackers.text': 'Record tracker’s fall',
    'notifications.card.tabs.conditions.field.alert.rec-trackers.tooltip.text':
      'System will record fall of the trackers',
    'notifications.card.tabs.conditions.field.alert.limit-area.text': 'Limit controlled area',
    'notifications.card.tabs.conditions.field.alert.limit-area.tooltip.text':
      'Notification will be triggered inside/outside selected geofences only',
    'notifications.card.tabs.conditions.field.alert.limit-area.inside.text': 'Trigger inside area',
    'notifications.card.tabs.conditions.field.alert.limit-area.outside.text': 'Trigger outside area',
    'notifications.card.tabs.conditions.field.alert.geozone-list.label': 'List of controlled geofences',
    'notifications.card.tabs.conditions.field.alert.geozone-list.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.alert.rec-violation.text': 'Record as violation',
    'notifications.card.tabs.conditions.field.alert.rec-violation.tooltip.text':
      'System will record violation when notification is triggered',
    'notifications.card.tabs.conditions.field.alert.rec-inaction.text': 'Record inaction',
    'notifications.card.tabs.conditions.field.alert.rec-inaction.tooltip.text':
      'System will record notification when there are no coordinate changes within a chosen time',
    'notifications.card.tabs.conditions.field.alert.wait-time.label': 'Waiting time of coordinate changes',
    'notifications.card.tabs.conditions.field.alert.wait-time.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.alert.wait-time.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.wait-time.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.wait-time.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.alert.wait-time.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.alert.rec-loss.text': 'Record loss of connection',
    'notifications.card.tabs.conditions.field.alert.rec-loss.tooltip.text':
      'System will record notification when there are no receiving data within a chosen time',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.label': 'Waiting time of data receiving',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.alert.recovery-connection.text': 'Record connection recovery',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.text': 'Record GPS signal loss',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.label': 'Eaiting time of GPS data receiving',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.alert.rec-gps-recovery.text': 'Record GPS signal recovery',

    'notifications.card.tabs.conditions.field.geozone.geozone-list.label': 'List of monitored geofences',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozone.text': 'Geofence',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozonegroup.text': 'Geofence group',
    'notifications.card.tabs.conditions.field.geozone.rec-in.text': 'Record the entrance to the geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-out.text': 'Record the exit from the geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.text':
      'Record the absence of an object in the geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text':
      'The notification will be triggered if the employee is not in the selected geofences',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.text':
      'Record a long stay of an object in a geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text':
      'A notification will be triggered if an employee stays in the selected geofence for longer than a specified time',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.label': 'Limit of stay in controlled geofences',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.text': 'Record as violation',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text':
      'System will record violation when notification is triggered',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.label':
      'Time to wait before committing a notification',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.item.text': 'min',

    'notifications.card.tabs.conditions.field.intersection.rec-proximity.text': 'Record the proximity of objects',
    'notifications.card.tabs.conditions.field.intersection.rec-distance.text': 'Record the distance of objects',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.label':
      'Controlled approach/withdraw radius',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder': '5 meters',
    'notifications.card.tabs.conditions.field.intersection.limit-area.text': 'Limit controlled area',
    'notifications.card.tabs.conditions.field.intersection.limit-area.in.text': 'Triggering inside geofences',
    'notifications.card.tabs.conditions.field.intersection.limit-area.out.text': 'Triggered outside geofences',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.label': 'List of monitored geofences',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.text': 'Record as violation',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text':
      'System will record violation when notification is triggered',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.label':
      'Time to wait before committing a notification',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text': "mm 'min'",
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text': 'Limit',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.item.text': 'min',

    'notifications.card.tabs.conditions.field.sensor.type.label': 'Sensor type',
    'notifications.card.tabs.conditions.field.sensor.type.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.sensor.name.label': 'Sensor name',
    'notifications.card.tabs.conditions.field.sensor.name.placeholder': 'Enter name',
    'notifications.card.tabs.conditions.field.sensor.triggered.label': 'Notification trigger condition',
    'notifications.card.tabs.conditions.field.sensor.triggered.placeholder': 'Select from the list',
    'notifications.card.tabs.conditions.field.sensor.value-from.label': 'Value from',
    'notifications.card.tabs.conditions.field.sensor.value-from.placeholder': 'Enter value',
    'notifications.card.tabs.conditions.field.sensor.value-to.label': 'Value to',
    'notifications.card.tabs.conditions.field.sensor.value-to.placeholder': 'Enter value',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.text': 'Record as violation',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text':
      'System will record violation when notification is triggered',

    'notifications.card.tabs.time.text': 'Time',
    'notifications.card.tabs.time.field.period-activity.text': 'Enter period of notification activity',
    'notifications.card.tabs.time.field.period-activity-from.label': 'Period of notification activity, from',
    'notifications.card.tabs.time.field.period-activity-from.time.header.text': 'Time',
    'notifications.card.tabs.time.field.period-activity-to.label': 'Period of notification activity, to',
    'notifications.card.tabs.time.field.period-activity-to.time.header.text': 'Time',
    'notifications.card.tabs.time.field.max-triggering.label': 'Limit of notification triggering number',
    'notifications.card.tabs.time.field.max-triggering.time.header.text': 'Enter value',
    'notifications.card.tabs.time.field.interval-triggering.text': 'Limit time intervals of notification triggering',
    'notifications.card.tabs.time.field.interval.add-btn.label': 'Add interval',
    'notifications.card.tabs.time.field.interval.label': 'Interval',
    'notifications.card.tabs.time.field.interval.from.label': 'from',
    'notifications.card.tabs.time.field.interval.to.label': 'to',
    'notifications.card.tabs.time.field.interval.time-caption.text': 'Time',
    'notifications.card.tabs.time.field.limit-periods.text': 'Limit triggering periods',
    'notifications.card.tabs.time.field.months.header.text': 'Months',
    'notifications.card.tabs.time.field.months.speed-btn.jan.text': 'Jan',
    'notifications.card.tabs.time.field.months.speed-btn.feb.text': 'Feb',
    'notifications.card.tabs.time.field.months.speed-btn.mar.text': 'Mar',
    'notifications.card.tabs.time.field.months.speed-btn.apr.text': 'Apr',
    'notifications.card.tabs.time.field.months.speed-btn.may.text': 'May',
    'notifications.card.tabs.time.field.months.speed-btn.jun.text': 'Jun',
    'notifications.card.tabs.time.field.months.speed-btn.jul.text': 'Jul',
    'notifications.card.tabs.time.field.months.speed-btn.aug.text': 'Aug',
    'notifications.card.tabs.time.field.months.speed-btn.sep.text': 'Sep',
    'notifications.card.tabs.time.field.months.speed-btn.oct.text': 'Oct',
    'notifications.card.tabs.time.field.months.speed-btn.nov.text': 'Nov',
    'notifications.card.tabs.time.field.months.speed-btn.dec.text': 'Dec',
    'notifications.card.tabs.time.field.days-week.header.text': 'Days of the week',
    'notifications.card.tabs.time.field.days-week.speed-btn.mo.text': 'Mo',
    'notifications.card.tabs.time.field.days-week.speed-btn.tu.text': 'Tu',
    'notifications.card.tabs.time.field.days-week.speed-btn.we.text': 'We',
    'notifications.card.tabs.time.field.days-week.speed-btn.th.text': 'Th',
    'notifications.card.tabs.time.field.days-week.speed-btn.fr.text': 'Fr',
    'notifications.card.tabs.time.field.days-week.speed-btn.sa.text': 'Sa',
    'notifications.card.tabs.time.field.days-week.speed-btn.su.text': 'Su',
    'notifications.card.tabs.time.field.days-months.header.text': 'Days of the month',
    'notifications.card.tabs.time.field.days-months.odd-days.text': 'Odd days',
    'notifications.card.tabs.time.field.days-months.even-days.text': 'Even days',

    'notifications.card.tabs.statistic.text': 'Statistics',

    'notifications.card.tabs.statistic.table.header.date.text': 'Date',
    'notifications.card.tabs.statistic.table.header.time.text': 'Time',
    'notifications.card.tabs.statistic.table.header.text.text': 'Notification text',
    'notifications.card.tabs.statistic.table.header.geofence.text': 'Geofence',
    'notifications.card.tabs.statistic.table.header.coords.text': 'Coordinates',

    'notifications.card.footer.btn.copy.label': 'Copy',
    'notifications.card.footer.btn.cancel.label': 'Cancel',
    'notifications.card.footer.btn.save.label': 'Save',
    'notifications.card.footer.btn.update.label': 'Update',
    'notifications.card.footer.btn.delete.label': 'Delete',
    'notifications.card.footer.btn.test.label': 'Test',

    'notifications.card.alert.title.text': 'Closing the card',
    'notifications.card.alert.info.text': 'There are unsaved data on the form.',

    'notifications.card.alert.title.delete.label': 'Delete notification',
    'notifications.card.alert.info.delete.text':
      'A deleted notification cannot be recovered. Are you sure you want to delete the notification?',

    'notifications.group.alert.text': 'SOS, crash, loss of connection, inactivity',
    'notifications.group.geofences.text': 'Geofences',
    'notifications.group.intersection.text': 'Interposition of objects',
    'notifications.group.sensor.text': 'Sensor value',
    'notifications.group.videoanalytics.text': 'Videoanalytics',
  },
  ru: {
    'notifications.button-block.btn.label': 'Создать',

    'notifications.button.history.label': 'История уведомлений',
    'notifications.history.header.text': 'История уведомлений',
    'notifications.history.select.type-notification.label': 'Тип уведомления',
    'notifications.history.select.type-notification.placeholder': 'Все типы уведомлений',
    'notifications.history.list.header.number.text': '№',
    'notifications.history.list.header.name.text': 'Название',
    'notifications.history.list.header.status.text': 'Статус',
    'notifications.history.list.header.map.text': 'Карта',
    'notifications.history.list.status.read.text': 'Прочитано',
    'notifications.history.list.status.no-read.text': 'Не прочитано',
    'notifications.history.list.data.date.text': 'Дата',
    'notifications.history.list.data.type.text': 'Тип',
    'notifications.history.list.data.object.text': 'Объект',
    'notifications.history.button.table.label': 'Таблица',
    'notifications.history.button.close.label': 'Закрыть',
    'notifications.history.button.create.label': 'Сформировать список уведомлений',
    'notifications.history.table.header.label': 'История уведомлений',
    'notifications.history.table.header.number.label': '№',
    'notifications.history.table.header.date.label': 'Дата',
    'notifications.history.table.header.name.label': 'Название',
    'notifications.history.table.header.type.label': 'Тип',
    'notifications.history.table.header.object.label': 'Объект',
    'notifications.history.table.header.status.label': 'Статус',
    'notifications.history.table.button.close.label': 'Закрыть',

    'notifications.list.header.notification.label': 'Уведомление',

    'notifications.list.header.tooltip.activate.text': 'Включить/отключить все уведомления',
    'notifications.list.header.tooltip.triggered.text': 'Количество сработавших уведомлений',
    'notifications.list.header.tooltip.objects.text': 'Количество объектов, сгенерировавших уведомление',
    'notifications.list.header.tooltip.settings.text': 'Изменение уведомления',

    'notifications.list.item.tooltip.settings.text': 'Изменение уведомления',
    'notifications.list.item.tooltip.testNotification.remove.text': 'Удалить нотификацию',

    'notifications.grouping-modal.header.label': 'Группировка',
    'notifications.grouping-modal.grouping.type.text': 'Группировать по типу уведомлений',
    'notifications.grouping-modal.footer.btn.cancel.label': 'Отмена',
    'notifications.grouping-modal.footer.btn.apply.label': 'Применить',

    'notifications.card.header.label': 'Карточка уведомления',
    'notifications.test.card.header.label': 'Тестовое уведомление для',
    'notifications.test.card.photo.label': 'Тест',

    'notifications.card.tabs.general.text': 'Основное',
    'notifications.card.tabs.general.field.notify-on.text': 'Уведомление включено',
    'notifications.card.tabs.general.field.notify-off.text': 'Уведомление выключено',
    'notifications.card.tabs.general.field.name.label': 'Название уведомления',
    'notifications.card.tabs.general.field.name.placeholder': 'Введите название уведомления',
    'notifications.card.tabs.general.field.type.label': 'Тип уведомления',
    'notifications.card.tabs.general.field.events.label': 'События',
    'notifications.card.tabs.general.field.type.placeholder': 'Выберите из списка',
    'notifications.card.tabs.general.field.notify-popup.text': 'Уведомление во всплывающем окне',
    'notifications.card.tabs.general.field.notify-email.text': 'Уведомлять по Email',
    'notifications.card.tabs.general.field.notify-sms.text': 'Уведомлять по SMS',
    'notifications.card.tabs.general.field.accounts.label': 'Пользователи для получения уведомлений',
    'notifications.card.tabs.general.field.accounts.placeholder': 'Выберите из списка',
    'notifications.card.tabs.general.field.report.text': 'Сформировать отчет',
    'notifications.card.tabs.general.field.report-template.label': 'Шаблон отчета',
    'notifications.card.tabs.general.field.report-template.placeholder': 'Выберите из списка',
    'notifications.card.tabs.general.field.report-from.label': 'Укажите период отчета, от',
    'notifications.card.tabs.general.field.report-from.time.text': 'Время',
    'notifications.card.tabs.general.field.report-to.label': 'Укажите период отчета, до',
    'notifications.card.tabs.general.field.report-to.time.text': 'Время',
    'notifications.card.tabs.general.field.text-notify.label': 'Текст уведомления',
    'notifications.card.tabs.general.field.text-notify.placeholder':
      'Введите текст уведомления. Поддерживаются системные теги, например: %POS_TIME% - У объекта %UNIT% низкий уровень заряда аккумулятора.',
    'notifications.card.tabs.general.field.text-notify.labels.obj.text': 'Имя объекта',
    'notifications.card.tabs.general.field.text-notify.labels.date-time.text': 'Дата и время сработавшего уведомления',
    'notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text': 'Текущие дата и время',
    'notifications.card.tabs.general.field.text-notify.labels.obj-coords.text': 'Координаты объекта',
    'notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text': 'Геозона нахождения объекта',
    'notifications.card.tabs.general.field.text-notify.labels.obj-location.text': 'Последнее известное местоположение',
    'notifications.card.tabs.general.field.text-notify.labels.detector-name.text': 'Название сработавшего датчика',
    'notifications.card.tabs.general.field.text-notify.labels.detector-value.text': 'Значение сработавшего датчика',
    'notifications.card.tabs.general.field.text-notify.labels.poi-name.text': 'Название точки интереса',
    'notifications.card.tabs.general.field.text-notify.labels.poi-type.text': 'Тип точки интереса',
    'notifications.card.tabs.general.field.text-notify.labels.poi-valid.text': 'Дата/время действия точки интереса',

    'notifications.card.tabs.objects.text': 'Объекты',
    'notifications.card.tabs.objects.field.employees.text': 'Сотрудники',
    'notifications.card.tabs.objects.field.vehicles.text': 'Транспорт',
    'notifications.card.tabs.objects.field.available.header.text': 'Объекты, доступные для добавления',
    'notifications.card.tabs.objects.field.available.btn.select-all.label': 'Выделить все',
    'notifications.card.tabs.objects.field.selected.header.text': 'Выбранные объекты',
    'notifications.card.tabs.objects.field.selected.btn.select-all.label': 'Выделить все',

    'notifications.card.tabs.conditions.text': 'Условия и действия',
    'notifications.card.tabs.conditions.default.desc.text': 'Выберите тип уведомления на вкладке “Основное”',

    'notifications.card.tabs.conditions.field.alert.rec-signal.text': 'Фиксировать SOS сигнал',
    'notifications.card.tabs.conditions.field.alert.rec-signal.tooltip.text':
      'Фиксация поступления SOS сигнала от трекера',
    'notifications.card.tabs.conditions.field.alert.rec-trackers.text': 'Фиксировать падение трекера',
    'notifications.card.tabs.conditions.field.alert.rec-trackers.tooltip.text': 'Фиксация падения трекера',
    'notifications.card.tabs.conditions.field.alert.limit-area.text': 'Ограничить контролируемую область',
    'notifications.card.tabs.conditions.field.alert.limit-area.tooltip.text':
      'Уведомление будет срабатывать только внутри или вне указанных геозон',
    'notifications.card.tabs.conditions.field.alert.limit-area.inside.text': 'Срабатывать внутри геозон',
    'notifications.card.tabs.conditions.field.alert.limit-area.outside.text': 'Срабатывать за пределами геозон',
    'notifications.card.tabs.conditions.field.alert.geozone-list.label': 'Список контролируемых геозон',
    'notifications.card.tabs.conditions.field.alert.geozone-list.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.alert.rec-violation.text': 'Фиксировать как нарушение',
    'notifications.card.tabs.conditions.field.alert.rec-violation.tooltip.text':
      'При срабатывании уведомления будет зафиксировано нарушение',
    'notifications.card.tabs.conditions.field.alert.rec-inaction.text': 'Фиксировать бездействие',
    'notifications.card.tabs.conditions.field.alert.rec-inaction.tooltip.text':
      'Фиксация отсутствия изменения координат трекера в течение заданного времени',
    'notifications.card.tabs.conditions.field.alert.wait-time.label': 'Время ожидания изменения координат',
    'notifications.card.tabs.conditions.field.alert.wait-time.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.alert.wait-time.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.wait-time.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.wait-time.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.alert.wait-time.picker.item.text': 'мин',
    'notifications.card.tabs.conditions.field.alert.rec-loss.text': 'Фиксировать потерю связи',
    'notifications.card.tabs.conditions.field.alert.rec-loss.tooltip.text':
      'Фиксация отсутствия приема данных от трекера в течение заданного времени',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.label': 'Время ожидания поступления данных',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.alert.wait-time-receiving.picker.item.text': 'мин',
    'notifications.card.tabs.conditions.field.alert.recovery-connection.text': 'Фиксировать восстановление связи',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.text': 'Фиксировать потерю GPS сигнала',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.label': 'Время ожидания поступления GPS данных',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.alert.rec-gps-loss.picker.item.text': 'мин',
    'notifications.card.tabs.conditions.field.alert.rec-gps-recovery.text': 'Фиксировать восстановление GPS сигнала',

    'notifications.card.tabs.conditions.field.geozone.geozone-list.label': 'Список контролируемых геозон',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozone.text': 'Геозоны',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozonegroup.text': 'Группы геозон',
    'notifications.card.tabs.conditions.field.geozone.rec-in.text': 'Фиксация входа в геозону',
    'notifications.card.tabs.conditions.field.geozone.rec-out.text': 'Фиксация выхода из геозоны',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.text': 'Фиксация отсутствия объекта в геозоне',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text':
      'Уведомление сработает, если сотрудник не находится в выбранных геозонах',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.text':
      'Фиксация долгого нахождения объекта в геозоне',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text':
      'Уведомление сработает, если сотрудник находится дольшe заданного времени в выбранной геозоне',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.label': 'Лимит нахождения в контролируемых геозонах',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.item.text': 'мин',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.text': 'Зафиксировать, как нарушение',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text':
      'При срабатывании уведомления будет зарегистрировано нарушение',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.label':
      'Время ожидания перед началом фиксации уведомления',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.item.text': 'мин',

    'notifications.card.tabs.conditions.field.intersection.rec-proximity.text': 'Фиксация сближения объектов',
    'notifications.card.tabs.conditions.field.intersection.rec-distance.text': 'Фиксация отдаления объектов',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.label':
      'Контролируемый радиус приближения/отдаления',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder': '5 метров',
    'notifications.card.tabs.conditions.field.intersection.limit-area.text': 'Ограничить контролируемую область',
    'notifications.card.tabs.conditions.field.intersection.limit-area.in.text': 'Срабатывание внутри геозон',
    'notifications.card.tabs.conditions.field.intersection.limit-area.out.text': 'Срабатывание за пределами геозон',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.label': 'Список контролируемых геозон',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder': 'Выберете из списка',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.text': 'Зафиксировать, как нарушение',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text':
      'При срабатывании уведомления будет зарегистрировано нарушение',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.label':
      'Время ожидания перед началом фиксации уведомления',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text': 'mm мин.',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text': 'Лимит',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.item.text': 'мин',

    'notifications.card.tabs.conditions.field.sensor.type.label': 'Тип датчика',
    'notifications.card.tabs.conditions.field.sensor.type.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.sensor.name.label': 'Имя датчика',
    'notifications.card.tabs.conditions.field.sensor.name.placeholder': 'Введите имя датчика',
    'notifications.card.tabs.conditions.field.sensor.triggered.label': 'Условие срабатывания уведомления',
    'notifications.card.tabs.conditions.field.sensor.triggered.placeholder': 'Выберите из списка',
    'notifications.card.tabs.conditions.field.sensor.value-from.label': 'Значение от',
    'notifications.card.tabs.conditions.field.sensor.value-from.placeholder': 'Введите значение',
    'notifications.card.tabs.conditions.field.sensor.value-to.label': 'Значение до',
    'notifications.card.tabs.conditions.field.sensor.value-to.placeholder': 'Введите значение',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.text': 'Зафиксировать, как нарушение',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text':
      'При срабатывании уведомления будет зарегистрировано нарушение',

    'notifications.card.tabs.time.text': 'Время',
    'notifications.card.tabs.time.field.period-activity.text': 'Указать период действия уведомления',
    'notifications.card.tabs.time.field.period-activity-from.label': 'Период действия уведомления, от',
    'notifications.card.tabs.time.field.period-activity-from.time.header.text': 'Время',
    'notifications.card.tabs.time.field.period-activity-to.label': 'Период действия уведомления, до',
    'notifications.card.tabs.time.field.period-activity-to.time.header.text': 'Время',
    'notifications.card.tabs.time.field.max-triggering.label': 'Максимальное количество срабатываний',
    'notifications.card.tabs.time.field.max-triggering.time.header.text': 'Введите значение',
    'notifications.card.tabs.time.field.interval-triggering.text': 'Указать интервалы времени срабатывания',
    'notifications.card.tabs.time.field.interval.add-btn.label': 'Добавить интервал',
    'notifications.card.tabs.time.field.interval.label': 'Интервал',
    'notifications.card.tabs.time.field.interval.from.label': 'от',
    'notifications.card.tabs.time.field.interval.to.label': 'до',
    'notifications.card.tabs.time.field.interval.time-caption.text': 'Время',
    'notifications.card.tabs.time.field.limit-periods.text': 'Указать ограничения по периодам срабатывания',
    'notifications.card.tabs.time.field.months.header.text': 'Месяцы',
    'notifications.card.tabs.time.field.months.speed-btn.jan.text': 'Янв',
    'notifications.card.tabs.time.field.months.speed-btn.feb.text': 'Фев',
    'notifications.card.tabs.time.field.months.speed-btn.mar.text': 'Мар',
    'notifications.card.tabs.time.field.months.speed-btn.apr.text': 'Апр',
    'notifications.card.tabs.time.field.months.speed-btn.may.text': 'Май',
    'notifications.card.tabs.time.field.months.speed-btn.jun.text': 'Июн',
    'notifications.card.tabs.time.field.months.speed-btn.jul.text': 'Июл',
    'notifications.card.tabs.time.field.months.speed-btn.aug.text': 'Авг',
    'notifications.card.tabs.time.field.months.speed-btn.sep.text': 'Сен',
    'notifications.card.tabs.time.field.months.speed-btn.oct.text': 'Окт',
    'notifications.card.tabs.time.field.months.speed-btn.nov.text': 'Ноя',
    'notifications.card.tabs.time.field.months.speed-btn.dec.text': 'Дек',
    'notifications.card.tabs.time.field.days-week.header.text': 'Дни недели',
    'notifications.card.tabs.time.field.days-week.speed-btn.mo.text': 'Пн',
    'notifications.card.tabs.time.field.days-week.speed-btn.tu.text': 'Вт',
    'notifications.card.tabs.time.field.days-week.speed-btn.we.text': 'Ср',
    'notifications.card.tabs.time.field.days-week.speed-btn.th.text': 'Чт',
    'notifications.card.tabs.time.field.days-week.speed-btn.fr.text': 'Пт',
    'notifications.card.tabs.time.field.days-week.speed-btn.sa.text': 'Сб',
    'notifications.card.tabs.time.field.days-week.speed-btn.su.text': 'Вс',
    'notifications.card.tabs.time.field.days-months.header.text': 'Дни месяца',
    'notifications.card.tabs.time.field.days-months.odd-days.text': 'Нечетные дни',
    'notifications.card.tabs.time.field.days-months.even-days.text': 'Четные дни',

    'notifications.card.tabs.statistic.text': 'Статистика',

    'notifications.card.tabs.statistic.table.header.date.text': 'Дата',
    'notifications.card.tabs.statistic.table.header.time.text': 'Время',
    'notifications.card.tabs.statistic.table.header.text.text': 'Текст уведомления',
    'notifications.card.tabs.statistic.table.header.geofence.text': 'Геозона',
    'notifications.card.tabs.statistic.table.header.coords.text': 'Координаты',

    'notifications.card.footer.btn.copy.label': 'Копировать',
    'notifications.card.footer.btn.cancel.label': 'Отмена',
    'notifications.card.footer.btn.save.label': 'Сохранить',
    'notifications.card.footer.btn.update.label': 'Обновить',
    'notifications.card.footer.btn.delete.label': 'Удалить',
    'notifications.card.footer.btn.test.label': 'Тест',

    'notifications.card.alert.title.text': 'Закрытие карточки',
    'notifications.card.alert.info.text': 'На форме остались несохраненные данные.',

    'notifications.card.alert.title.delete.label': 'Удаление уведомления',
    'notifications.card.alert.info.delete.text':
      'Удаленное уведомление нельзя будет восстановить. Вы действительно хотите удалить уведомление?',

    'notifications.group.alert.text': 'SOS, падение, потеря связи, бездействие',
    'notifications.group.geofences.text': 'Геозоны',
    'notifications.group.intersection.text': 'Взаиморасположение объектов',
    'notifications.group.sensor.text': 'Значение датчика',
    'notifications.group.videoanalytics.text': 'Видеоаналитика',
  },
  zh: {
    'notifications.button-block.btn.label': '创建',
    'notifications.button.history.label': '通知历史',
    'notifications.history.header.text': '通知历史',
    'notifications.history.select.type-notification.label': '通知种类',
    'notifications.history.select.type-notification.placeholder': '所有通知种类',
    'notifications.history.list.header.number.text': '号',
    'notifications.history.list.header.name.text': '名称',
    'notifications.history.list.header.status.text': '地位',
    'notifications.history.list.header.map.text': '地图',
    'notifications.history.list.status.read.text': '读',
    'notifications.history.list.status.no-read.text': '未读',
    'notifications.history.list.data.date.text': '日期',
    'notifications.history.list.data.type.text': '种类',
    'notifications.history.list.data.object.text': '对象 ',
    'notifications.history.button.table.label': '表',
    'notifications.history.button.close.label': '关闭 ',
    'notifications.history.button.create.label': '形成通知清单',
    'notifications.history.table.header.label': '通知历史',
    'notifications.history.table.header.number.label': '号',
    'notifications.history.table.header.date.label': '日期',
    'notifications.history.table.header.name.label': '名称',
    'notifications.history.table.header.type.label': '种类',
    'notifications.history.table.header.object.label': '对象 ',
    'notifications.history.table.header.status.label': '地位',
    'notifications.history.table.button.close.label': '关闭 ',
    'notifications.list.header.notification.label': '通知',
    'notifications.list.header.tooltip.activate.text': '启用/停用所有通知',
    'notifications.list.header.tooltip.triggered.text': '触发通知的数量',
    'notifications.list.header.tooltip.objects.text': '生成通知的对象数量',
    'notifications.list.header.tooltip.settings.text': '更改通知',
    'notifications.list.item.tooltip.settings.text': '更改通知',
    'notifications.list.item.tooltip.testNotification.remove.text': '消除通知',
    'notifications.grouping-modal.header.label': '分组',
    'notifications.grouping-modal.grouping.type.text': '按通知类型分组',
    'notifications.grouping-modal.footer.btn.cancel.label': '取消',
    'notifications.grouping-modal.footer.btn.apply.label': '应用',
    'notifications.card.header.label': '通知卡',
    'notifications.test.card.header.label': '測試通知',
    'notifications.test.card.photo.label': '測試',
    'notifications.card.tabs.general.text': '一般性',
    'notifications.card.tabs.general.field.notify-on.text': '启用通知',
    'notifications.card.tabs.general.field.notify-off.text': '关闭通知',
    'notifications.card.tabs.general.field.name.label': '通知名称',
    'notifications.card.tabs.general.field.name.placeholder': '输入名称',
    'notifications.card.tabs.general.field.type.label': '通知类型',
    'notifications.card.tabs.general.field.events.label': '事件',
    'notifications.card.tabs.general.field.type.placeholder': '从列表中选择',
    'notifications.card.tabs.general.field.notify-popup.text': '弹出窗口中的通知',
    'notifications.card.tabs.general.field.notify-email.text': '通过电子邮件通知',
    'notifications.card.tabs.general.field.notify-sms.text': '通过短信通知',
    'notifications.card.tabs.general.field.accounts.label': '用户为接收通知',
    'notifications.card.tabs.general.field.accounts.placeholder': '从列表中选择',
    'notifications.card.tabs.general.field.report.text': '生成报告',
    'notifications.card.tabs.general.field.report-template.label': '报告模板',
    'notifications.card.tabs.general.field.report-template.placeholder': '从列表中选择',
    'notifications.card.tabs.general.field.report-from.label': '指定报告期间，从',
    'notifications.card.tabs.general.field.report-from.time.text': '选择时间',
    'notifications.card.tabs.general.field.report-to.label': '指定报告期间，至',
    'notifications.card.tabs.general.field.report-to.time.text': '选择时间',
    'notifications.card.tabs.general.field.text-notify.label': '通知文本',
    'notifications.card.tabs.general.field.text-notify.placeholder':
      '输入通知文本。支持系统标记，例如：%POS_TIME%-对象的%UNIT% 蓄电池电量低。',
    'notifications.card.tabs.general.field.text-notify.labels.obj.text': '对象名称',
    'notifications.card.tabs.general.field.text-notify.labels.date-time.text': '触发通知的日期和时间',
    'notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text': '当前日期和时间',
    'notifications.card.tabs.general.field.text-notify.labels.obj-coords.text': '对象坐标',
    'notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text': '对象位置的地理围栏',
    'notifications.card.tabs.general.field.text-notify.labels.obj-location.text': '最后已知位置',
    'notifications.card.tabs.general.field.text-notify.labels.detector-name.text': '触发传感器的名称',
    'notifications.card.tabs.general.field.text-notify.labels.detector-value.text': '触发传感器的值',
    'notifications.card.tabs.general.field.text-notify.labels.poi-name.text': '兴趣点名称',
    'notifications.card.tabs.general.field.text-notify.labels.poi-type.text': '兴趣点种类',
    'notifications.card.tabs.general.field.text-notify.labels.poi-valid.text': '兴趣点持续日期/时间',
    'notifications.card.tabs.objects.text': '对象',
    'notifications.card.tabs.objects.field.employees.text': '员工',
    'notifications.card.tabs.objects.field.vehicles.text': '车辆',
    'notifications.card.tabs.objects.field.available.header.text': '可用于添加的对象',
    'notifications.card.tabs.objects.field.available.btn.select-all.label': '全选',
    'notifications.card.tabs.objects.field.selected.header.text': '选定的对象',
    'notifications.card.tabs.objects.field.selected.btn.select-all.label': '全选',
    'notifications.card.tabs.conditions.text': '条件和行动',
    'notifications.card.tabs.conditions.default.desc.text': '在“基本”选项卡中选择通知类型',
    'notifications.card.tabs.conditions.field.sos.rec-signal.text': '记录SOS信号',
    'notifications.card.tabs.conditions.field.sos.rec-signal.tooltip.text': '记录来自跟踪器的SOS信号',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.text': '记录跟踪器跌落',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.tooltip.text': '记录跟踪器的跌落',
    'notifications.card.tabs.conditions.field.sos.limit-area.text': '限制受控区域',
    'notifications.card.tabs.conditions.field.sos.limit-area.tooltip.text': '通知会在指定的地理围栏内部或外部触发',
    'notifications.card.tabs.conditions.field.sos.limit-area.inside.text': '地理围栏内触发',
    'notifications.card.tabs.conditions.field.sos.limit-area.outside.text': '地理围栏外触发',
    'notifications.card.tabs.conditions.field.sos.geozone-list.label': '受控地理围栏列表',
    'notifications.card.tabs.conditions.field.sos.geozone-list.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sos.rec-violation.text': '记录为违规',
    'notifications.card.tabs.conditions.field.sos.rec-violation.tooltip.text': '触发通知时将记录违规情况',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.text': '记录无所作为',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.tooltip.text': '记录跟踪器在设定时间内未发生坐标变化',
    'notifications.card.tabs.conditions.field.sos.wait-time.label': '坐标更改的等待时间',
    'notifications.card.tabs.conditions.field.sos.wait-time.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sos.wait-time.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.wait-time.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.sos.rec-loss.text': '记录连接中断',
    'notifications.card.tabs.conditions.field.sos.rec-loss.tooltip.text': '记录在设选定时间内未接收到跟踪器发来的数据',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.label': '接收数据等待时间',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.sos.recovery-connection.text': '记录连接恢复',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.text': '记录GPS信号丢失',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.label': '接收GPS数据等待',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.sos.rec-gps-recovery.text': '记录GPS信号恢复',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.label': '受控地理围栏列表',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder': '请从清单选择一项',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozone.text': '地理区',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozonegroup.text': '地理区组',
    'notifications.card.tabs.conditions.field.geozone.rec-in.text': '追查地理围栏的入口',
    'notifications.card.tabs.conditions.field.geozone.rec-out.text': '追查地理围栏的出口',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.text': '追查地理围栏中没有对象的问题',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text':
      '如果员工不在选定的地理围栏内，将触发通知',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.text': '追查地理围栏中物体的长时间停留',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text':
      '如果员工在所选地理围栏内停留的时间超过指定时间，则会触发通知',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.label': '在受控地理围栏内的停留限制',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.text': '记录为违规',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text': '当通知被触发时，将记录违规',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.label': '提交通知前等待的时间',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.intersection.rec-proximity.text': '追查物体的接近度',
    'notifications.card.tabs.conditions.field.intersection.rec-distance.text': '追查物体的距离',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.label': '受控接近/撤回半径',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder': '5米',
    'notifications.card.tabs.conditions.field.intersection.limit-area.text': '限制受控区域',
    'notifications.card.tabs.conditions.field.intersection.limit-area.in.text': '地理围栏内触发',
    'notifications.card.tabs.conditions.field.intersection.limit-area.out.text': '地理围栏外触发',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.label': '受控地理围栏列表',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder': '选择地理围栏和/或地理围栏组',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.text': '记录为违规',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text': '当通知被触发时，将记录违规',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.label': '提交通知前等待的时间',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text': 'mm 分',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text': '限制',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.item.text': '分',
    'notifications.card.tabs.conditions.field.sensor.type.label': '传感器类型',
    'notifications.card.tabs.conditions.field.sensor.type.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sensor.name.label': '传感器名称',
    'notifications.card.tabs.conditions.field.sensor.name.placeholder': '输入名称',
    'notifications.card.tabs.conditions.field.sensor.triggered.label': '触发通知的条件',
    'notifications.card.tabs.conditions.field.sensor.triggered.placeholder': '从列表中选择',
    'notifications.card.tabs.conditions.field.sensor.value-from.label': '数值从',
    'notifications.card.tabs.conditions.field.sensor.value-from.placeholder': '输入数值',
    'notifications.card.tabs.conditions.field.sensor.value-to.label': '数值到',
    'notifications.card.tabs.conditions.field.sensor.value-to.placeholder': '输入数值',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.text': '记录为违规',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text': '当通知被触发时，将记录违规',
    'notifications.card.tabs.time.text': '时间',
    'notifications.card.tabs.time.field.period-activity.text': '指定通知有效期',
    'notifications.card.tabs.time.field.period-activity-from.label': '通知的有效期，从',
    'notifications.card.tabs.time.field.period-activity-from.time.header.text': '时间',
    'notifications.card.tabs.time.field.period-activity-to.label': '通知有效期，至',
    'notifications.card.tabs.time.field.period-activity-to.time.header.text': '时间',
    'notifications.card.tabs.time.field.max-triggering.label': '触发的最大数量',
    'notifications.card.tabs.time.field.max-triggering.time.header.text': '输入数值',
    'notifications.card.tabs.time.field.interval-triggering.text': '指定触发时间间隔',
    'notifications.card.tabs.time.field.interval.add-btn.label': '添加间隔',
    'notifications.card.tabs.time.field.interval.label': '间隔',
    'notifications.card.tabs.time.field.interval.from.label': '从',
    'notifications.card.tabs.time.field.interval.to.label': '至',
    'notifications.card.tabs.time.field.interval.time-caption.text': '时间',
    'notifications.card.tabs.time.field.limit-periods.text': '指定触发时间段的限制',
    'notifications.card.tabs.time.field.months.header.text': '月份',
    'notifications.card.tabs.time.field.months.speed-btn.jan.text': '一月',
    'notifications.card.tabs.time.field.months.speed-btn.feb.text': '二月',
    'notifications.card.tabs.time.field.months.speed-btn.mar.text': '三月',
    'notifications.card.tabs.time.field.months.speed-btn.apr.text': '四月',
    'notifications.card.tabs.time.field.months.speed-btn.may.text': '五月',
    'notifications.card.tabs.time.field.months.speed-btn.jun.text': '六月',
    'notifications.card.tabs.time.field.months.speed-btn.jul.text': '七月',
    'notifications.card.tabs.time.field.months.speed-btn.aug.text': '八月',
    'notifications.card.tabs.time.field.months.speed-btn.sep.text': '九月',
    'notifications.card.tabs.time.field.months.speed-btn.oct.text': '十月',
    'notifications.card.tabs.time.field.months.speed-btn.nov.text': '十一月',
    'notifications.card.tabs.time.field.months.speed-btn.dec.text': '十二月',
    'notifications.card.tabs.time.field.days-week.header.text': '周',
    'notifications.card.tabs.time.field.days-week.speed-btn.mo.text': '周一',
    'notifications.card.tabs.time.field.days-week.speed-btn.tu.text': '周二',
    'notifications.card.tabs.time.field.days-week.speed-btn.we.text': '周三',
    'notifications.card.tabs.time.field.days-week.speed-btn.th.text': '周四',
    'notifications.card.tabs.time.field.days-week.speed-btn.fr.text': '周五',
    'notifications.card.tabs.time.field.days-week.speed-btn.sa.text': '周六',
    'notifications.card.tabs.time.field.days-week.speed-btn.su.text': '周日',
    'notifications.card.tabs.time.field.days-months.header.text': '月',
    'notifications.card.tabs.time.field.days-months.odd-days.text': '单数日',
    'notifications.card.tabs.time.field.days-months.even-days.text': '双数日',
    'notifications.card.tabs.statistic.text': '统计',
    'notifications.card.tabs.statistic.table.header.date.text': '日期',
    'notifications.card.tabs.statistic.table.header.time.text': '时间',
    'notifications.card.tabs.statistic.table.header.text.text': '通知文本',
    'notifications.card.tabs.statistic.table.header.geofence.text': '地理围栏',
    'notifications.card.tabs.statistic.table.header.coords.text': '对象坐标',
    'notifications.card.footer.btn.copy.label': '复制',
    'notifications.card.footer.btn.cancel.label': '取消',
    'notifications.card.footer.btn.save.label': '保存',
    'notifications.card.footer.btn.update.label': '更新',
    'notifications.card.footer.btn.delete.label': '删除',
    'notifications.card.footer.btn.test.label': '试验',
    'notifications.card.alert.title.text': '关闭卡片',
    'notifications.card.alert.info.text': '表单上有未保存的数据。',
    'notifications.card.alert.title.delete.label': '通知消除',
    'notifications.card.alert.info.delete.text': '已消除的通知无法恢复。 您真的要消除通知吗？',
    'notifications.group.sos.text': 'SOS，跌倒，失去连接，不活跃',
    'notifications.group.geofences.text': '地理围栏',
    'notifications.group.intersection.text': '物体的相对位置',
    'notifications.group.sensor.text': '传感器值',
    'notifications.group.videoanalytics.text': '视频分析',
  },
  ar: {
    'notifications.button-block.btn.label': 'إنشاء',
    'notifications.button.history.label': 'سجل الإخطارات',
    'notifications.history.header.text': 'سجل الإخطارات',
    'notifications.history.select.type-notification.label': 'نوع الإخطار',
    'notifications.history.select.type-notification.placeholder': 'جميع أنواع الإخطارات',
    'notifications.history.list.header.number.text': 'رقم',
    'notifications.history.list.header.name.text': 'الأسم',
    'notifications.history.list.header.status.text': 'الحالة',
    'notifications.history.list.header.map.text': 'الخريطة',
    'notifications.history.list.status.read.text': 'مقروء',
    'notifications.history.list.status.no-read.text': 'غير مقروء',
    'notifications.history.list.data.date.text': 'التاريخ',
    'notifications.history.list.data.type.text': 'النوع',
    'notifications.history.list.data.object.text': 'الموضوع',
    'notifications.history.button.table.label': 'جدول',
    'notifications.history.button.close.label': 'أغلق',
    'notifications.history.button.create.label': 'إنشاء قائمة الإخطارات',
    'notifications.history.table.header.label': 'سجل الإخطارات',
    'notifications.history.table.header.number.label': 'رقم',
    'notifications.history.table.header.date.label': 'التاريخ',
    'notifications.history.table.header.name.label': 'الأسم',
    'notifications.history.table.header.type.label': 'النوع',
    'notifications.history.table.header.object.label': 'الموضوع',
    'notifications.history.table.header.status.label': 'الحالة',
    'notifications.history.table.button.close.label': 'أغلق',
    'notifications.list.header.notification.label': 'إشعارات',
    'notifications.list.header.tooltip.activate.text': 'تشغيل/تعطيل كافة الإشعارات',
    'notifications.list.header.tooltip.triggered.text': 'عدد الإشعارات المشغّلة',
    'notifications.list.header.tooltip.objects.text': 'عدد الكائنات المسببة للإشعارات',
    'notifications.list.header.tooltip.settings.text': 'تغيير الإشعار',
    'notifications.list.item.tooltip.settings.text': 'تغيير الإشعار',
    'notifications.list.item.tooltip.testNotification.remove.text': 'حذف الإخطار',
    'notifications.grouping-modal.header.label': 'فرز',
    'notifications.grouping-modal.grouping.type.text': 'فرز بحسب نوع الإشعارات',
    'notifications.grouping-modal.footer.btn.cancel.label': 'إلغاء',
    'notifications.grouping-modal.footer.btn.apply.label': 'تطبيق',
    'notifications.card.header.label': 'بطاقة الإشعارات',
    'notifications.test.card.header.label': 'إخطار الاختبار لـ',
    'notifications.test.card.photo.label': ' اختبارـ',
    'notifications.card.tabs.general.text': 'عام',
    'notifications.card.tabs.general.field.notify-on.text': 'تم تفعيل الإشعار',
    'notifications.card.tabs.general.field.notify-off.text': 'تم تعطيل الإشعار',
    'notifications.card.tabs.general.field.name.label': 'اسم الإشعار',
    'notifications.card.tabs.general.field.name.placeholder': 'أكتب اسمًا',
    'notifications.card.tabs.general.field.type.label': 'نوع الإشعار',
    'notifications.card.tabs.general.field.events.label': 'الأحداث',
    'notifications.card.tabs.general.field.type.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.general.field.notify-popup.text': 'إشعار عن طريق نافذة منبثقة',
    'notifications.card.tabs.general.field.notify-email.text': 'إشعار عن طريق بريد إلكتروني',
    'notifications.card.tabs.general.field.notify-sms.text': 'إشعار عن طريق SMS',
    'notifications.card.tabs.general.field.accounts.label': 'المستخدمون لاستلام الإشعارات',
    'notifications.card.tabs.general.field.accounts.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.general.field.report.text': 'توليد التقرير',
    'notifications.card.tabs.general.field.report-template.label': 'قالب التقرير',
    'notifications.card.tabs.general.field.report-template.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.general.field.report-from.label': 'أذكر فترة التقرير بدءًا من',
    'notifications.card.tabs.general.field.report-from.time.text': 'اختر الوقت',
    'notifications.card.tabs.general.field.report-to.label': 'أذكر فترة التقرير لغاية',
    'notifications.card.tabs.general.field.report-to.time.text': 'اختر الوقت',
    'notifications.card.tabs.general.field.text-notify.label': 'نص الإشعار',
    'notifications.card.tabs.general.field.text-notify.placeholder':
      'أكتب نص الإشعار. يتم دعم الكلمات الدلالية للنظام، مثلاً: %POS_TIME% - لدى الكائن %UNIT% نسبة البطارية منخفضة.',
    'notifications.card.tabs.general.field.text-notify.labels.obj.text': 'اسم الكائن',
    'notifications.card.tabs.general.field.text-notify.labels.date-time.text': 'تاريخ ووقت الإشعار المشغّل',
    'notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text': 'التاريخ والوقت الحالي',
    'notifications.card.tabs.general.field.text-notify.labels.obj-coords.text': 'إحداثيات الكائن',
    'notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text': 'منطقة الكائن',
    'notifications.card.tabs.general.field.text-notify.labels.obj-location.text': 'المنطقة الأخيرة المعروفة',
    'notifications.card.tabs.general.field.text-notify.labels.detector-name.text': 'اسم المستشعر المشغّل',
    'notifications.card.tabs.general.field.text-notify.labels.detector-value.text': 'قيمة المستشعر المشغّل',
    'notifications.card.tabs.general.field.text-notify.labels.poi-name.text': 'اسم نقطة الاهتمام',
    'notifications.card.tabs.general.field.text-notify.labels.poi-type.text': 'نوع نقطة الاهتمام',
    'notifications.card.tabs.general.field.text-notify.labels.poi-valid.text': 'تاريخ / وقت نشاط نقطة الاهتمام ',
    'notifications.card.tabs.objects.text': 'الكائنات',
    'notifications.card.tabs.objects.field.employees.text': 'موظفون',
    'notifications.card.tabs.objects.field.vehicles.text': 'وسائل النقل',
    'notifications.card.tabs.objects.field.available.header.text': 'الكائنات المتاحة للإضافة',
    'notifications.card.tabs.objects.field.available.btn.select-all.label': 'تحديد الكل',
    'notifications.card.tabs.objects.field.selected.header.text': 'الكائنات المحددة',
    'notifications.card.tabs.objects.field.selected.btn.select-all.label': 'تحديد الكل',
    'notifications.card.tabs.conditions.text': 'شروط وتصرفات',
    'notifications.card.tabs.conditions.default.desc.text': 'اختر نوع الإشعار من علامة التبويب "عامة"',
    'notifications.card.tabs.conditions.field.sos.rec-signal.text': 'تسجيل إشارة SOS',
    'notifications.card.tabs.conditions.field.sos.rec-signal.tooltip.text': 'تسجيل وصول إشارة SOS من المتتبع',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.text': 'تسجيل سقوط المتتبع',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.tooltip.text': 'تسجيل سقوط المتتبع',
    'notifications.card.tabs.conditions.field.sos.limit-area.text': 'حد المنطقة المسيطر عليها',
    'notifications.card.tabs.conditions.field.sos.limit-area.tooltip.text':
      'سيتم تفعيل الإشعار فقط بداخل المناطق المحددة أو بخارجها.',
    'notifications.card.tabs.conditions.field.sos.limit-area.inside.text': 'تشغيل من داخل المناطق',
    'notifications.card.tabs.conditions.field.sos.limit-area.outside.text': 'تشغيل من خارج المناطق',
    'notifications.card.tabs.conditions.field.sos.geozone-list.label': 'قائمة المناطق المسيطر عليها',
    'notifications.card.tabs.conditions.field.sos.geozone-list.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sos.rec-violation.text': 'تسجيل كانتهاك',
    'notifications.card.tabs.conditions.field.sos.rec-violation.tooltip.text': 'عند تفعيل الإشعار سيتم تسجيل انتهاك',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.text': 'تسجيل الخمول',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.tooltip.text':
      'تسجيل عدم وجود تغييرات بالإحداثيات في غضون مهلة محددة',
    'notifications.card.tabs.conditions.field.sos.wait-time.label': 'وقت الانتظار لتغيير الإحداثيات',
    'notifications.card.tabs.conditions.field.sos.wait-time.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sos.wait-time.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.wait-time.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.sos.rec-loss.text': 'تسجيل فقدان الاتصال',
    'notifications.card.tabs.conditions.field.sos.rec-loss.tooltip.text':
      'تسجيل عدم وجود استلام البيانات في غضون مهلة محددة',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.label': 'وقت الانتظار لوصول البيانات',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.sos.recovery-connection.text': 'تثبيت استعادة الاتصال',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.text': 'تسجيل فقدان إشارة GPS',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.label': 'وقت الانتظار لوصول بيانات الـGPS',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.sos.rec-gps-recovery.text': 'تسجيل استرداد إشارة GPS',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.label': 'قائمة المناطق المسيطر عليها',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozone.text': 'السياج الجغرافي',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozonegroup.text':
      'مجموعات السياج الجغرافي',
    'notifications.card.tabs.conditions.field.geozone.rec-in.text': 'تثبيت الدخول إلى المنطقة الجغرافية',
    'notifications.card.tabs.conditions.field.geozone.rec-out.text': 'تثبيت الخروج إلى المنطقة الجغرافية',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.text': 'تثبيت عدم تواجد الكائن في المنطقة الجغرافية',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text':
      'سيتم تفعيل الإشعار إذا لم يكن الموظف في المناطق الجغرافية المحددة.',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.text':
      'تثبيت تواجد الكائن في المنطقة الجغرافية لفترة طويلة',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text':
      'سيتم تفعيل الإشعار إذا تواجد الموظف في المنطقة الجغرافية المحددة لفترة أطول من المقررة.',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.label':
      'الحد للتواجد في المناطق الجغرافية المتحكم عليها',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.text': 'تسجيل كانتهاك',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text':
      'عند تفعيل الإشعار سيتم تسجيل انتهاك',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.label': 'وقت الانتظار قبل بدء تثبيت الإشعار',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.intersection.rec-proximity.text': 'تثبيت اقتراب الكائنات',
    'notifications.card.tabs.conditions.field.intersection.rec-distance.text': 'تثبيت ابتعاد الكائنات',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.label':
      'محال الاقتراب/الابتعاد المتحكم عليه',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder': '٥ أمتار',
    'notifications.card.tabs.conditions.field.intersection.limit-area.text': 'حد المنطقة المسيطر عليها',
    'notifications.card.tabs.conditions.field.intersection.limit-area.in.text': 'تشغيل من داخل المناطق',
    'notifications.card.tabs.conditions.field.intersection.limit-area.out.text': 'تشغيل من خارج المناطق',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.label': 'قائمة المناطق المسيطر عليها',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder':
      'اختر المناطق الجغرافية و/أو مجموعة المناطق الجغرافية',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.text': 'تسجيل كانتهاك',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text':
      'عند تفعيل الإشعار سيتم تسجيل انتهاك',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.label': 'وقت الانتظار قبل بدء تثبيت الإشعار',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text': 'mm دقيقة',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text': 'الحد',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.item.text': 'دقيقة',
    'notifications.card.tabs.conditions.field.sensor.type.label': 'نوع المستشعر',
    'notifications.card.tabs.conditions.field.sensor.type.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sensor.name.label': 'اسم المستشعر',
    'notifications.card.tabs.conditions.field.sensor.name.placeholder': 'أكتب اسم المستشعر',
    'notifications.card.tabs.conditions.field.sensor.triggered.label': 'شرط لتفعيل الإشعار',
    'notifications.card.tabs.conditions.field.sensor.triggered.placeholder': 'اختر من القائمة',
    'notifications.card.tabs.conditions.field.sensor.value-from.label': 'القيمة بدءًا من',
    'notifications.card.tabs.conditions.field.sensor.value-from.placeholder': 'أكتب القيمة',
    'notifications.card.tabs.conditions.field.sensor.value-to.label': 'القيمة حتى',
    'notifications.card.tabs.conditions.field.sensor.value-to.placeholder': 'أكتب القيمة',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.text': 'تسجيل كانتهاك',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text': 'عند تفعيل الإشعار سيتم تسجيل انتهاك',
    'notifications.card.tabs.time.text': 'وقت',
    'notifications.card.tabs.time.field.period-activity.text': 'أكتب مهلة سريان الإشعار',
    'notifications.card.tabs.time.field.period-activity-from.label': 'مهلة سريان الإشعار، بدءًا من',
    'notifications.card.tabs.time.field.period-activity-from.time.header.text': 'وقت',
    'notifications.card.tabs.time.field.period-activity-to.label': 'مهلة سريان الإشعار، لغاية',
    'notifications.card.tabs.time.field.period-activity-to.time.header.text': 'وقت',
    'notifications.card.tabs.time.field.max-triggering.label': 'العدد الأقصى للتفعيل',
    'notifications.card.tabs.time.field.max-triggering.time.header.text': 'أكتب القيمة',
    'notifications.card.tabs.time.field.interval-triggering.text': 'حدد الفواصل الزمنية للتفعيل',
    'notifications.card.tabs.time.field.interval.add-btn.label': 'إضافة فاصل زمني',
    'notifications.card.tabs.time.field.interval.label': 'فاصل',
    'notifications.card.tabs.time.field.interval.from.label': ' من',
    'notifications.card.tabs.time.field.interval.to.label': ' من',
    'notifications.card.tabs.time.field.interval.time-caption.text': 'وقت',
    'notifications.card.tabs.time.field.limit-periods.text': 'أذكر الحدود بحسب الفواصل للتفعيل',
    'notifications.card.tabs.time.field.months.header.text': 'أشهر',
    'notifications.card.tabs.time.field.months.speed-btn.jan.text': 'يناير',
    'notifications.card.tabs.time.field.months.speed-btn.feb.text': 'فبراير',
    'notifications.card.tabs.time.field.months.speed-btn.mar.text': 'مارس',
    'notifications.card.tabs.time.field.months.speed-btn.apr.text': 'أبريل',
    'notifications.card.tabs.time.field.months.speed-btn.may.text': 'مايو',
    'notifications.card.tabs.time.field.months.speed-btn.jun.text': 'يونيو',
    'notifications.card.tabs.time.field.months.speed-btn.jul.text': 'يوليو',
    'notifications.card.tabs.time.field.months.speed-btn.aug.text': 'أغسطس',
    'notifications.card.tabs.time.field.months.speed-btn.sep.text': 'سبتمبر',
    'notifications.card.tabs.time.field.months.speed-btn.oct.text': 'أكتوبر',
    'notifications.card.tabs.time.field.months.speed-btn.nov.text': 'نوفمبر',
    'notifications.card.tabs.time.field.months.speed-btn.dec.text': 'ديسمبر',
    'notifications.card.tabs.time.field.days-week.header.text': 'أيام الأسبوع',
    'notifications.card.tabs.time.field.days-week.speed-btn.mo.text': 'اثنين',
    'notifications.card.tabs.time.field.days-week.speed-btn.tu.text': 'ثلاثاء',
    'notifications.card.tabs.time.field.days-week.speed-btn.we.text': 'أربعاء',
    'notifications.card.tabs.time.field.days-week.speed-btn.th.text': 'خميس',
    'notifications.card.tabs.time.field.days-week.speed-btn.fr.text': 'جمعة',
    'notifications.card.tabs.time.field.days-week.speed-btn.sa.text': 'سبت',
    'notifications.card.tabs.time.field.days-week.speed-btn.su.text': 'أحد',
    'notifications.card.tabs.time.field.days-months.header.text': 'أيام الأشهر',
    'notifications.card.tabs.time.field.days-months.odd-days.text': 'أيام فردية',
    'notifications.card.tabs.time.field.days-months.even-days.text': 'أيام زوجية',
    'notifications.card.tabs.statistic.text': 'الإحصاء',
    'notifications.card.tabs.statistic.table.header.date.text': 'تاريخ',
    'notifications.card.tabs.statistic.table.header.time.text': 'وقت',
    'notifications.card.tabs.statistic.table.header.text.text': 'نص الإشعار',
    'notifications.card.tabs.statistic.table.header.geofence.text': 'منطفة جغرافية',
    'notifications.card.tabs.statistic.table.header.coords.text': 'إحداثيات الكائن',
    'notifications.card.footer.btn.copy.label': 'نسخ',
    'notifications.card.footer.btn.cancel.label': 'إلغاء',
    'notifications.card.footer.btn.save.label': 'حفظ',
    'notifications.card.footer.btn.update.label': 'تحديث',
    'notifications.card.footer.btn.delete.label': 'حذف',
    'notifications.card.footer.btn.test.label': 'أختبار',
    'notifications.card.alert.title.text': 'شطب البطاقة',
    'notifications.card.alert.info.text': 'هناك بيانات غير محفوظة في النموذج',
    'notifications.card.alert.title.delete.label': 'حذف الإخطار',
    'notifications.card.alert.info.delete.text': 'لا يمكن استعادة إخطار محذوف. هل تريد حقًا حذف الإخطار؟',
    'notifications.group.sos.text': 'طلب نجدة، وقوع، فقد الاتصال، عدم الاستجابة',
    'notifications.group.geofences.text': 'مناطق',
    'notifications.group.intersection.text': 'مكان الكائنات لبعضها البعض',
    'notifications.group.sensor.text': 'قيمة المستشعر',
    'notifications.group.videoanalytics.text': 'تحليلات الفيديو',
  },
  de: {
    'notifications.button-block.btn.label': 'Erstellen',
    'notifications.button.history.label': 'Benachrichtigungshistorie',
    'notifications.history.header.text': 'Benachrichtigungshistorie',
    'notifications.history.select.type-notification.label': 'Benachrichtigungsart',
    'notifications.history.select.type-notification.placeholder': 'Alle Benachrichtigungsarten',
    'notifications.history.list.header.number.text': 'Nr.',
    'notifications.history.list.header.name.text': 'Benennung',
    'notifications.history.list.header.status.text': 'Zustand',
    'notifications.history.list.header.map.text': 'Karte',
    'notifications.history.list.status.read.text': 'vorgelesen ',
    'notifications.history.list.status.no-read.text': 'ungelesen',
    'notifications.history.list.data.date.text': 'Datum',
    'notifications.history.list.data.type.text': 'Typ',
    'notifications.history.list.data.object.text': 'Objekt',
    'notifications.history.button.table.label': 'Tabelle',
    'notifications.history.button.close.label': 'schließen',
    'notifications.history.button.create.label': 'Benachrichtigungsliste generieren',
    'notifications.history.table.header.label': 'Benachrichtigungshistorie',
    'notifications.history.table.header.number.label': 'Nr.',
    'notifications.history.table.header.date.label': 'Datum',
    'notifications.history.table.header.name.label': 'Benennung',
    'notifications.history.table.header.type.label': 'Typ',
    'notifications.history.table.header.object.label': 'Objekt',
    'notifications.history.table.header.status.label': 'Zustand',
    'notifications.history.table.button.close.label': 'schließen',
    'notifications.list.header.notification.label': 'Benachrichtigung',
    'notifications.list.header.tooltip.activate.text': 'Aktivieren / Deaktivieren Sie alle Benachrichtigungen',
    'notifications.list.header.tooltip.triggered.text': 'Anzahl der ausgelösten Benachrichtigungen',
    'notifications.list.header.tooltip.objects.text':
      'Die Anzahl der Objekte, die die Benachrichtigung generiert haben',
    'notifications.list.header.tooltip.settings.text': 'Benachrichtigung ändern',
    'notifications.list.item.tooltip.settings.text': 'Benachrichtigung ändern',
    'notifications.list.item.tooltip.testNotification.remove.text': 'Notifizierung löschen',
    'notifications.grouping-modal.header.label': 'Gruppierung',
    'notifications.grouping-modal.grouping.type.text': 'Nach Benachrichtigungstyp gruppieren',
    'notifications.grouping-modal.footer.btn.cancel.label': 'Zurücksetzen',
    'notifications.grouping-modal.footer.btn.apply.label': 'Anwenden',
    'notifications.card.header.label': 'Benachrichtigungskarte',
    'notifications.test.card.header.label': 'Testbenachrichtigung für',
    'notifications.test.card.photo.label': 'Test',
    'notifications.card.tabs.general.text': 'Highlights',
    'notifications.card.tabs.general.field.notify-on.text': 'Benachrichtigung ein',
    'notifications.card.tabs.general.field.notify-off.text': 'Benachrichtigung aus',
    'notifications.card.tabs.general.field.name.label': 'Name der Benachrichtigung',
    'notifications.card.tabs.general.field.name.placeholder': 'Geben Sie einen Titel für die Benachrichtigung ein',
    'notifications.card.tabs.general.field.type.label': 'Benachrichtigungstyp',
    'notifications.card.tabs.general.field.events.label': 'Veranstaltungen',
    'notifications.card.tabs.general.field.type.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.general.field.notify-popup.text': 'Popup-Benachrichtigung',
    'notifications.card.tabs.general.field.notify-email.text': 'Email Benachrichtigung',
    'notifications.card.tabs.general.field.notify-sms.text': 'Per SMS benachrichtigen',
    'notifications.card.tabs.general.field.accounts.label': 'Benutzer, die Benachrichtigungen erhalten',
    'notifications.card.tabs.general.field.accounts.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.general.field.report.text': 'Generieren Sie einen Bericht',
    'notifications.card.tabs.general.field.report-template.label': 'Berichtsvorlage',
    'notifications.card.tabs.general.field.report-template.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.general.field.report-from.label': 'Den Berichtszeitraum von … angeben.',
    'notifications.card.tabs.general.field.report-from.time.text': 'Zeit',
    'notifications.card.tabs.general.field.report-to.label': 'Den Berichtszeitraum bis zum … angeben.',
    'notifications.card.tabs.general.field.report-to.time.text': 'Zeit',
    'notifications.card.tabs.general.field.text-notify.label': 'Benachrichtigungstext',
    'notifications.card.tabs.general.field.text-notify.placeholder':
      'Geben Sie Ihren Benachrichtigungstext ein. System-Tags werden beispielsweise unterstützt:% POS_TIME% - Das% UNIT% -Objekt hat einen niedrigen Batteriestand.',
    'notifications.card.tabs.general.field.text-notify.labels.obj.text': 'Objektname',
    'notifications.card.tabs.general.field.text-notify.labels.date-time.text':
      'Datum und Uhrzeit der ausgelösten Benachrichtigung',
    'notifications.card.tabs.general.field.text-notify.labels.curr-date-time.text': 'Aktuelles Datum und Uhrzeit',
    'notifications.card.tabs.general.field.text-notify.labels.obj-coords.text': 'Objektkoordinaten',
    'notifications.card.tabs.general.field.text-notify.labels.obj-geofence.text': 'Geofence der Objektposition',
    'notifications.card.tabs.general.field.text-notify.labels.obj-location.text': 'Letzter bekannter Ort',
    'notifications.card.tabs.general.field.text-notify.labels.detector-name.text': 'Name des ausgelösten Sensors',
    'notifications.card.tabs.general.field.text-notify.labels.detector-value.text': 'Wert des ausgelösten Sensors',
    'notifications.card.tabs.general.field.text-notify.labels.poi-name.text': 'Punktsbenennung von Interesse',
    'notifications.card.tabs.general.field.text-notify.labels.poi-type.text': 'Punktsart von Interesse',
    'notifications.card.tabs.general.field.text-notify.labels.poi-valid.text':
      'Datum/Uhrzeit, zu der der Punkt von Interesse gültig war',
    'notifications.card.tabs.objects.text': 'Objekte',
    'notifications.card.tabs.objects.field.employees.text': 'Mitarbeiter',
    'notifications.card.tabs.objects.field.vehicles.text': 'Transport',
    'notifications.card.tabs.objects.field.available.header.text': 'Objekte zum Hinzufügen verfügbar',
    'notifications.card.tabs.objects.field.available.btn.select-all.label': 'Wählen Sie Alle',
    'notifications.card.tabs.objects.field.selected.header.text': 'Ausgewählte Objekte',
    'notifications.card.tabs.objects.field.selected.btn.select-all.label': 'Wählen Sie Alle',
    'notifications.card.tabs.conditions.text': 'Bedingungen und Handlungen',
    'notifications.card.tabs.conditions.default.desc.text':
      'Wählen Sie die Art der Benachrichtigung auf der Registerkarte "Allgemein"',
    'notifications.card.tabs.conditions.field.sos.rec-signal.text': 'SOS-Signal korrigieren',
    'notifications.card.tabs.conditions.field.sos.rec-signal.tooltip.text':
      'Behebung des Empfangs eines SOS-Signals vom Tracker',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.text': 'Korrigieren Sie den Sturz des Trackers',
    'notifications.card.tabs.conditions.field.sos.rec-trackers.tooltip.text': 'Behebung des Sturzes des Trackers',
    'notifications.card.tabs.conditions.field.sos.limit-area.text': 'Kontrollbereich begrenzen',
    'notifications.card.tabs.conditions.field.sos.limit-area.tooltip.text':
      'Die Benachrichtigung wird nur innerhalb oder außerhalb der angegebenen Geofences ausgelöst',
    'notifications.card.tabs.conditions.field.sos.limit-area.inside.text': 'In Geofences auslösen',
    'notifications.card.tabs.conditions.field.sos.limit-area.outside.text': 'Außerhalb von Geofences auslösen',
    'notifications.card.tabs.conditions.field.sos.geozone-list.label': 'Liste der überwachten Geofences',
    'notifications.card.tabs.conditions.field.sos.geozone-list.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sos.rec-violation.text': 'Als Verstoß aufzeichnen',
    'notifications.card.tabs.conditions.field.sos.rec-violation.tooltip.text':
      'Wenn die Benachrichtigung ausgelöst wird, wird ein Verstoß aufgezeichnet',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.text': 'Untätigkeit beheben',
    'notifications.card.tabs.conditions.field.sos.rec-inaction.tooltip.text':
      'Behebung des Fehlens von Änderungen in den Koordinaten des Trackers für eine bestimmte Zeit',
    'notifications.card.tabs.conditions.field.sos.wait-time.label': 'Wartezeit für Koordinatenänderung',
    'notifications.card.tabs.conditions.field.sos.wait-time.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sos.wait-time.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.wait-time.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.sos.wait-time.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.sos.rec-loss.text': 'Verbindungsverlust beheben',
    'notifications.card.tabs.conditions.field.sos.rec-loss.tooltip.text':
      'Behebung des Fehlens eines Datenempfangs vom Tracker für eine bestimmte Zeit',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.label': 'Wartezeit für das Eintreffen der Daten',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.sos.wait-time-receiving.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.sos.recovery-connection.text':
      'Korrigieren Sie die Wiederherstellung der Kommunikation',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.text': 'Korrigieren Sie das verlorene GPS-Signal',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.label': 'Wartezeit für das Eintreffen der GPS-Daten',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.sos.rec-gps-loss.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.sos.rec-gps-recovery.text': 'Wiederherstellung des GPS-Signals beheben',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.label': 'Liste der überwachten Geofences',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozone.text': 'Geofences',
    'notifications.card.tabs.conditions.field.geozone.geozone-list.translate.geozonegroup.text': 'Geofences-Gruppen',
    'notifications.card.tabs.conditions.field.geozone.rec-in.text': 'Den Eingang zum Geofence reparieren',
    'notifications.card.tabs.conditions.field.geozone.rec-out.text': 'Den Ausgang vom Geofence reparieren',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.text':
      'Beheben der Abwesenheit eines Objekts im Geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-absence.tooltip.text':
      'Die Benachrichtigung wird ausgelöst, wenn sich der Mitarbeiter nicht in den ausgewählten Geofences befindet',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.text':
      'Beheben eines langen Aufenthalts eines Objekts in einem Geofence',
    'notifications.card.tabs.conditions.field.geozone.rec-long-stay.tooltip.text':
      'Eine Benachrichtigung wird ausgelöst, wenn sich ein Mitarbeiter länger als eine festgelegte Zeit im ausgewählten Geofence aufhält',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.label':
      'Begrenzung des Aufenthalts in kontrollierten Geofences',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.geozone.limit-stay.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.text': 'Als Verstoß aufzeichnen',
    'notifications.card.tabs.conditions.field.geozone.rec-violation.tooltip.text':
      'Wenn die Benachrichtigung ausgelöst wird, wird ein Verstoß registriert',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.label':
      'Zeit zu warten, bevor eine Benachrichtigung abgegeben wird',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.geozone.waiting-time.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.intersection.rec-proximity.text': 'Festlegen der Nähe von Objekten',
    'notifications.card.tabs.conditions.field.intersection.rec-distance.text': 'Festlegen des Abstands von Objekten',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.label':
      'Kontrollierter Anfahr- / Rückzugsradius',
    'notifications.card.tabs.conditions.field.intersection.controlled-radius.placeholder': '5 Meter',
    'notifications.card.tabs.conditions.field.intersection.limit-area.text': 'Kontrollbereich begrenzen',
    'notifications.card.tabs.conditions.field.intersection.limit-area.in.text': 'In Geofences auslösen',
    'notifications.card.tabs.conditions.field.intersection.limit-area.out.text': 'Außerhalb von Geofences auslösen',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.label': 'Liste der überwachten Geofences',
    'notifications.card.tabs.conditions.field.intersection.geofences-list.placeholder':
      'Geofences und/oder Geofences-Gruppen auswählen',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.text': 'Als Verstoß aufzeichnen',
    'notifications.card.tabs.conditions.field.intersection.rec-violation.tooltip.text':
      'Wenn die Benachrichtigung ausgelöst wird, wird ein Verstoß registriert',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.label':
      'Zeit zu warten, bevor eine Benachrichtigung abgegeben wird',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.date-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.time-format.text': 'mm min',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.header.text': 'Grenze',
    'notifications.card.tabs.conditions.field.intersection.waiting-time.picker.item.text': 'min',
    'notifications.card.tabs.conditions.field.sensor.type.label': 'Sensorart',
    'notifications.card.tabs.conditions.field.sensor.type.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sensor.name.label': 'Sensorname',
    'notifications.card.tabs.conditions.field.sensor.name.placeholder': 'Sensornamen eingeben',
    'notifications.card.tabs.conditions.field.sensor.triggered.label':
      'Bedingung für das Auslösen einer Benachrichtigung',
    'notifications.card.tabs.conditions.field.sensor.triggered.placeholder': 'Aus der Liste wählen',
    'notifications.card.tabs.conditions.field.sensor.value-from.label': 'Wert von',
    'notifications.card.tabs.conditions.field.sensor.value-from.placeholder': 'Wert eingeben',
    'notifications.card.tabs.conditions.field.sensor.value-to.label': 'Wert bis',
    'notifications.card.tabs.conditions.field.sensor.value-to.placeholder': 'Wert eingeben',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.text': 'Als Verstoß aufzeichnen',
    'notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text':
      'Wenn die Benachrichtigung ausgelöst wird, wird ein Verstoß registriert',
    'notifications.card.tabs.time.text': 'Zeit',
    'notifications.card.tabs.time.field.period-activity.text':
      'Geben Sie den Gültigkeitszeitraum der Benachrichtigung an',
    'notifications.card.tabs.time.field.period-activity-from.label': 'Die Gültigkeitsdauer der Mitteilung ab',
    'notifications.card.tabs.time.field.period-activity-from.time.header.text': 'Zeit',
    'notifications.card.tabs.time.field.period-activity-to.label': 'Die Gültigkeitsdauer der Mitteilung bis',
    'notifications.card.tabs.time.field.period-activity-to.time.header.text': 'Zeit',
    'notifications.card.tabs.time.field.max-triggering.label': 'Maximale Anzahl von Triggern',
    'notifications.card.tabs.time.field.max-triggering.time.header.text': 'Wert eingeben',
    'notifications.card.tabs.time.field.interval-triggering.text': 'Geben Sie die Antwortzeitintervalle an',
    'notifications.card.tabs.time.field.interval.add-btn.label': 'Intervall hinzufügen',
    'notifications.card.tabs.time.field.interval.label': 'Intervall',
    'notifications.card.tabs.time.field.interval.from.label': 'von',
    'notifications.card.tabs.time.field.interval.to.label': 'bis',
    'notifications.card.tabs.time.field.interval.time-caption.text': 'Zeit',
    'notifications.card.tabs.time.field.limit-periods.text': 'Geben Sie die Grenzwerte für Antwortzeiträume an',
    'notifications.card.tabs.time.field.months.header.text': 'Monate',
    'notifications.card.tabs.time.field.months.speed-btn.jan.text': 'Jan',
    'notifications.card.tabs.time.field.months.speed-btn.feb.text': 'Feb',
    'notifications.card.tabs.time.field.months.speed-btn.mar.text': 'März',
    'notifications.card.tabs.time.field.months.speed-btn.apr.text': 'Apr',
    'notifications.card.tabs.time.field.months.speed-btn.may.text': 'Mai',
    'notifications.card.tabs.time.field.months.speed-btn.jun.text': 'Juni',
    'notifications.card.tabs.time.field.months.speed-btn.jul.text': 'Juli',
    'notifications.card.tabs.time.field.months.speed-btn.aug.text': 'Aug',
    'notifications.card.tabs.time.field.months.speed-btn.sep.text': 'Sep',
    'notifications.card.tabs.time.field.months.speed-btn.oct.text': 'Okt',
    'notifications.card.tabs.time.field.months.speed-btn.nov.text': 'Nov',
    'notifications.card.tabs.time.field.months.speed-btn.dec.text': 'Dez',
    'notifications.card.tabs.time.field.days-week.header.text': 'Wochentage',
    'notifications.card.tabs.time.field.days-week.speed-btn.mo.text': 'Mo',
    'notifications.card.tabs.time.field.days-week.speed-btn.tu.text': 'Di',
    'notifications.card.tabs.time.field.days-week.speed-btn.we.text': 'Mi',
    'notifications.card.tabs.time.field.days-week.speed-btn.th.text': 'Do',
    'notifications.card.tabs.time.field.days-week.speed-btn.fr.text': 'Fr',
    'notifications.card.tabs.time.field.days-week.speed-btn.sa.text': 'Sa',
    'notifications.card.tabs.time.field.days-week.speed-btn.su.text': 'So',
    'notifications.card.tabs.time.field.days-months.header.text': 'Tage des Monats',
    'notifications.card.tabs.time.field.days-months.odd-days.text': 'Ungeradzahlige Tage',
    'notifications.card.tabs.time.field.days-months.even-days.text': 'Geradzahlige Tage',
    'notifications.card.tabs.statistic.text': 'Statistiken',
    'notifications.card.tabs.statistic.table.header.date.text': 'Datum',
    'notifications.card.tabs.statistic.table.header.time.text': 'Zeit',
    'notifications.card.tabs.statistic.table.header.text.text': 'Benachrichtigungstext',
    'notifications.card.tabs.statistic.table.header.geofence.text': 'Geofence',
    'notifications.card.tabs.statistic.table.header.coords.text': 'Objektkoordinaten',
    'notifications.card.footer.btn.copy.label': 'Kopieren',
    'notifications.card.footer.btn.cancel.label': 'Zurücksetzen',
    'notifications.card.footer.btn.save.label': 'Speichern',
    'notifications.card.footer.btn.update.label': 'Aktualisieren',
    'notifications.card.footer.btn.delete.label': 'Löschen',
    'notifications.card.footer.btn.test.label': 'Prüfen',
    'notifications.card.alert.title.text': 'Schließen einer Karte',
    'notifications.card.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'notifications.card.alert.title.delete.label': 'eine Benachrichtigung löschen',
    'notifications.card.alert.info.delete.text':
      'Eine gelöschte Benachrichtigung kann nicht wiederhergestellt werden. Möchten Sie die Benachrichtigung wirklich löschen?',
    'notifications.group.sos.text': 'SOS, Sturz, Verbindungsverlust, Untätigkeit',
    'notifications.group.geofences.text': 'Geofences',
    'notifications.group.intersection.text': 'Relative Position von Objekten',
    'notifications.group.sensor.text': 'Sensorwert',
    'notifications.group.videoanalytics.text': 'Videoanalyse',
  },
};
