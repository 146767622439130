import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { HandbooksNewCardStates, HandbooksNewCardHandlers, MessagesType } from 'components/handbooks/utils/types';
import { HANDBOOK_INPUT_PROPS } from 'components/handbooks/utils/consts';
import DropButton from 'components/common/dropbutton/dropbutton';

import styles from './defaultHandbookCard.module.scss';

const cx = classNames.bind(styles);

const messages: MessagesType = {
  defaultsFields: {
    label: 'handbooks.column.elements-card.default.label',
    placeholder: 'handbooks.column.elements-card.default.placeholder',
  },
  name: {
    label: 'handbooks.column.elements-card.name.label',
    placeholder: 'handbooks.column.elements-card.name.placeholder',
  },
  organizationForm: {
    label: 'handbooks.column.elements-card.organization-form.label',
    placeholder: 'handbooks.column.elements-card.organization-form.placeholder',
  },
  inn: {
    label: 'handbooks.column.elements-card.inn.label',
    placeholder: 'handbooks.column.elements-card.inn.placeholder',
  },
  // TODO: удалить poiGasAnalyzerModelId после правок бекенда, т.к. это данные связанного справочника
  poiGasAnalyzerModelId: {
    label: 'handbooks.column.elements-card.gaz-analyzer-model.label',
    placeholder: 'handbooks.column.elements-card.gaz-analyzer-model.placeholder',
  },
  ...HANDBOOK_INPUT_PROPS,
};

type PropsType = {
  states: HandbooksNewCardStates;
  handlers: HandbooksNewCardHandlers;
  invalidFields: string[];
};

const HandbookNewElementData = ({ states, handlers, invalidFields }: PropsType) => {
  const t = useFormatMessage();

  if (!states.newElement) {
    return null;
  }

  return (
    <Fragment>
      {Object.keys(states.newElement).map((newElementKey, i) => {
        if (!states.newElement) {
          return null;
        }

        const newElementValue = states.newElement[newElementKey];
        const newElementForSelectValue = states.newElementForSelect[newElementKey];

        if (Array.isArray(newElementValue) && newElementForSelectValue) {
          return (
            <MultipleSelect
              key={`fieldField-multipleSelect-${i}`}
              options={newElementForSelectValue}
              label={t(messages[newElementKey]?.label ?? messages.defaultsFields.label)}
              placeholder={t(messages[newElementKey]?.placeholder ?? messages.defaultsFields.placeholder)}
              isRequired={false}
              withSearchIcon
              optionIsInline
              customSelectStyle={cx(styles.row, styles.rowSelect)}
              optionsContainerStyle={styles.rowMultipleSelectOptions}
              handleChangeMultiple={value => handlers.handleChangeFields(newElementKey, value)}
              values={newElementValue.map((id: number, i) =>
                getValueForMultipleSelect({
                  i,
                  id,
                  selectValues: newElementForSelectValue,
                })
              )}
            />
          );
        }
        if (newElementKey === 'name') {
          return (
            <Fragment key={`fieldField-translateKey-input-${i}`}>
              {states.translateKeys.map((translateKey, index) => (
                <Input
                  key={`translateKey-input-${index}`}
                  label={`${t(messages.name.label)} ${t(`handbooks.column.elements-card.name.${translateKey}.text`)}`}
                  placeholder={t(messages.name.placeholder)}
                  isValueError={invalidFields.includes('newElementData_name')}
                  isRequired={true}
                  value={states.newElement ? states.newElement[newElementKey][translateKey] ?? '' : ''}
                  customStyle={styles.row}
                  handleInputChange={value => handlers.handleChangeName(translateKey, value)}
                />
              ))}
              <DropButton
                label={t('handbooks.add-translation.btn.label')}
                buttons={states.dropButtons}
                onClick={handlers.handleChooseTranslate}
                disabled={!states.dropButtons.length}
                customStyle={cx(styles.row, styles.rowDropButton)}
                noAdditionalSymbol
                optionsIsSmall
              />
            </Fragment>
          );
        }
        return (
          <Input
            key={`fieldField-input-${i}`}
            label={t(messages[newElementKey]?.label ?? messages.defaultsFields.label)}
            placeholder={t(messages[newElementKey]?.placeholder ?? messages.defaultsFields.placeholder)}
            isValueError={invalidFields.includes(`newElementData_${newElementKey}`)}
            isRequired={true}
            value={String(newElementValue ?? '')}
            customStyle={styles.row}
            disabled={!messages[newElementKey]}
            handleInputChange={value => handlers.handleChangeFields(newElementKey, value)}
          />
        );
      })}
    </Fragment>
  );
};

export default HandbookNewElementData;
