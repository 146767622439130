import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import Button from 'components/common/button/button';
import styles from './geozoneGroupMultipleDeleteAlert.module.scss';
import { closeAlert } from 'reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { removeGeozone, removeGeozoneGroup, removeSelectedGroup, removeSelectedGeozone } from 'reducers/geozones';
import { RootState } from 'reducers';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function GeozoneGroupMultipleDeleteAlert() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { selectedGroups, selectedGeozones } = useSelector((state: RootState) => state.geozone);

  const handleCancel = () => {
    dispatch(closeAlert());
  };

  const handleContinue = () => {
    if (selectedGroups.length) {
      Promise.resolve(
        selectedGroups.map(e => (dispatch(removeSelectedGroup(e)), dispatch(removeGeozoneGroup(e))))
      ).then(() => {
        if (selectedGeozones.length) {
          selectedGeozones.map(e => (dispatch(removeGeozone(e)), dispatch(removeSelectedGeozone(e))));
        }
      });
      return handleCancel();
    }
    if (selectedGeozones.length) {
      selectedGeozones.map(e => (dispatch(removeGeozone(e)), dispatch(removeSelectedGeozone(e))));
      return handleCancel();
    }
  };

  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <div>{t('geozones.geozone-group-card-multiple.alert.header.label')}</div>
        <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={handleCancel} />
      </div>
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('geozones.geozone-group-card-multiple.alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('geozones.geozone-group-card-multiple.alert.info.text')}</p>
        <p className={styles.modalBodyDeleteText}>{t('geozones.geozone-group-card-multiple.alert.question.text')}</p>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('geozones.geozone-group-card-multiple.footer.btn.no.label')} onClick={handleCancel} />
        <Button red text={t('geozones.geozone-group-card-multiple.footer.btn.yes.label')} onClick={handleContinue} />
      </div>
    </Fragment>
  );
}
