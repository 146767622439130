import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING } from 'components/records/utils/consts';

import { ReportRenderFinishType, resultBeacon } from '../helpers';

import { Map } from 'ol';
import { LineString } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { Geometry, Point } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { fromLonLat } from 'ol/proj';
import { Extent } from 'ol/extent';

import { RootState } from 'reducers';
import { fetchReportMapData } from 'reducers/records';
import { ChosenReportMapData } from 'reducers/records/interface';

import turfBbox from '@turf/bbox';

import { MAP_LAYERS_Z_INDEX } from 'utils/consts';
import { createVectorLayer, geoJSON } from '../utils';

export const REPORTS_MAP_ROUTE = '/main/reports/map';

const trackSource = new VectorSource();
const trackMapLayer = createVectorLayer(trackSource, MAP_LAYERS_Z_INDEX.TRACKS, 'route');

export function useReport(map: Map) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { chosenReport, isChosenReportMapDataLoaded, chosenReportAbsenceEntitiesText } = useSelector(
    (state: RootState) => state.records
  );

  useEffect(() => {
    map.addLayer(trackMapLayer);

    return () => {
      map.removeLayer(trackMapLayer);
      trackMapLayer.dispose();
    };
  }, [map]);

  const renderTracks = useCallback((mapData: ChosenReportMapData[]) => {
    mapData.forEach(data => {
      if (data.mapEnrichment === REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.movementTracks && data.coords?.length) {
        const randomColor = '#' + (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase();
        const route = new LineString(data.coords).transform('EPSG:4326', 'EPSG:3857').simplify(1);
        const routeFeature = new Feature({
          type: 'route',
          geometry: route,
        });
        const textFeature = new Feature({
          type: GeometryType.POINT,
          geometry: new Point(fromLonLat(data.coords[data.coords.length - 1])),
        });

        routeFeature.setStyle([
          new Style({
            stroke: new Stroke({
              color: randomColor,
              width: 5,
            }),
          }),
        ]);
        textFeature.setStyle([
          new Style({
            text: new Text({
              text: data.deviceId ?? '',
              fill: new Fill({
                color: randomColor,
              }),
              font: 'bold 12px sans-serif',
            }),
          }),
        ]);
        trackSource.addFeatures([routeFeature, textFeature]);
      }
    });
  }, []);

  const zooming = useCallback(() => {
    const layers = map.getLayers().getArray();
    const features: Feature<Geometry>[] = [];

    layers.forEach(layer => {
      const source = layer.getProperties().source;

      if (source instanceof VectorSource) {
        const layerFeatures = source.getFeatures();
        const mockIconNames = ['cameraIcon', 'carIcon']; // TODO: здесь массив iconName фильтруемых моковых поинтов. Удалить данный массив когда удалятся эти моки
        const mockFeature = layerFeatures.find(feature => mockIconNames.includes(feature.getProperties().iconName)); // TODO: здесь фильтруем моковые поинты. Удалить данную feature когда удалятся моки

        if (!mockFeature) {
          features.push(...layerFeatures);
        }
      }
    });

    if (features.length) {
      const extentLonLat = turfBbox(geoJSON.writeFeaturesObject(features)) as Extent;
      const X1Y1 = fromLonLat([extentLonLat[0], extentLonLat[1]]);
      const X2Y2 = fromLonLat([extentLonLat[2], extentLonLat[3]]);
      const extent = [...X1Y1, ...X2Y2] as Extent;

      map.getView().fit(extent, {
        size: map.getSize(),
        padding: [50, 50, 50, 50],
      });
    }
  }, [map]);

  useEffect(() => {
    if (history.location.pathname === REPORTS_MAP_ROUTE && !!chosenReport) {
      const {
        attributes: { mapData },
      } = chosenReport;

      if (!!mapData) {
        // если данные еще не загружены
        if (!isChosenReportMapDataLoaded) {
          // запросим данные об отчете и рендерим сущности
          dispatch(fetchReportMapData(mapData));
        }
        // если загружены
        if (isChosenReportMapDataLoaded) {
          // рендерим треки movementTracks (при их наличии)
          renderTracks(mapData);
          // по окончанию рендера карты
          map.once('rendercomplete', () => {
            // сделаем zoom на сущностях
            zooming();
            // создадим finish div с DONE по завершению зуминга и рендера неотрендеренных участков карты
            map.once('rendercomplete', () => {
              // по окончанию zoom и очередного рендера - создадим соответствующий div
              if (chosenReportAbsenceEntitiesText) {
                resultBeacon(ReportRenderFinishType.error, chosenReportAbsenceEntitiesText);
              } else {
                resultBeacon(ReportRenderFinishType.success);
              }
            });
          });
        }
      } else {
        // если нет ошибок и данных для карты - дожидаемся окончания рендера и
        // создаем error div c текстом NO DATA в DOM
        map.once('rendercomplete', () => {
          resultBeacon(ReportRenderFinishType.error, 'No mapData');
        });
      }
    }
  }, [
    history,
    dispatch,
    chosenReport,
    renderTracks,
    map,
    zooming,
    isChosenReportMapDataLoaded,
    chosenReportAbsenceEntitiesText,
  ]);
}
