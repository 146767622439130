import React, { useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { updateCurrentAccount } from 'reducers/accounts';
import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal';
import { fetchUserData, updateUserPreferences } from 'reducers/user';
import { UpdateUserPreferences } from 'reducers/user/interface';

import { CommonSettingsTab } from './components/commonSettingsTab/commonSettingsTab';
import { FooterButtons } from './components/footer/footer';
import { UserSettingHeader } from './components/header/header';
import { ObjectsShowingTab } from './components/objectsShowingTab/objectsShowingTab';
import { PersonalDataTab } from './components/personalDataTab/personalDataTab';
import { UserSettingsTabsNav } from './components/tabs/tabs';
import { TabsShowingTab } from './components/tabsShowingTab/tabsShowingTab';

import { useCommonSettings } from './hooks/useCommonSettings';
import { useObjectsShowing } from './hooks/useObjectsShowing';
import { usePersonalData } from './hooks/usePersonalData';
import { useTabsShowing } from './hooks/useTabsShowing';

import styles from './userSettings.module.scss';

import { ActiveTab } from './utils/consts';

export function UserSettings() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [activeTab, setActiveTab] = useState(ActiveTab.common);
  const userData = useSelector((state: RootState) => state.user);

  const commonSettings = useCommonSettings();
  const personalData = usePersonalData();
  const tabsShowing = useTabsShowing();
  const objectsShowing = useObjectsShowing();

  const handleCancel = () => {
    return dispatch(closeModal());
  };

  const handleSave = async () => {
    const uniqueCommonValues = commonSettings.findUniqueValues();
    const uniqueTabsShowing = tabsShowing.findDifferenceUseEqual();
    const uniqueObjectsShowing = objectsShowing.findDifference();
    const uniquePersonalData = personalData.findDifference();

    try {
      const newJsonPreferences: UpdateUserPreferences = {};

      if (uniqueCommonValues.hasOwnProperty('locale')) {
        window.localStorage.setItem('locale', uniqueCommonValues.locale);
      }
      if (uniqueTabsShowing) {
        newJsonPreferences.tabs = tabsShowing.localTabPrefs.map(tab => {
          delete tab.title;
          return tab;
        });
      }
      if (uniqueObjectsShowing) {
        newJsonPreferences.trackableUnitView = objectsShowing.values;
      }

      if (Object.keys(newJsonPreferences).length || Object.keys(uniqueCommonValues).length) {
        dispatch(updateUserPreferences({ ...uniqueCommonValues, ...newJsonPreferences }));
      }
      if (uniquePersonalData) {
        const data = {
          ...uniquePersonalData,
          id: String(userData.id),
          email: uniquePersonalData.email.length ? uniquePersonalData.email : ' ',
          organizationId: Number.isInteger(uniquePersonalData.organizationId) ? uniquePersonalData.organizationId : 0,
          departmentId: Number.isInteger(uniquePersonalData.departmentId) ? uniquePersonalData.departmentId : 1,
          positionId: Number.isInteger(uniquePersonalData.positionId) ? uniquePersonalData.positionId : 0,
          isBanned: !!uniquePersonalData.isBanned,
          icon: uniquePersonalData.icon,
          userPreferences: userData.userPreferences,
        };
        await dispatch(updateCurrentAccount(data));
        await dispatch(fetchUserData());
      }
    } catch (err) {
      return console.error(err);
    }
    return handleCancel();
  };

  return (
    <>
      <UserSettingHeader title={t('user-settings.modal.header.label')} onCancel={handleCancel} />
      <div className={styles.modalBody}>
        <div className={styles.modalWrapper}>
          <UserSettingsTabsNav activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === ActiveTab.common && <CommonSettingsTab {...commonSettings} />}
          {activeTab === ActiveTab.personal && <PersonalDataTab {...personalData} />}
          {activeTab === ActiveTab.tabs && <TabsShowingTab {...tabsShowing} />}
          {activeTab === ActiveTab.objects && <ObjectsShowingTab {...objectsShowing} />}
        </div>
      </div>
      <FooterButtons handleCancel={handleCancel} handleSave={handleSave} />
    </>
  );
}
