const regularPhone = /\+7\d{10}/g;

export const validator = {
  isEmpty: (val: string) => Boolean(val),
  validPhone: (val: string) => Boolean(val.length === 12 && val.match(regularPhone)),
  validDate: (val: string) => {
    if (val.length !== 12) {
      return false;
    }

    const day = parseInt(val.split('.')[0]);
    const month = parseInt(val.split('.')[1]);
    const year = parseInt(val.split('.')[2]);

    return new Date(year, month - 1, day).toISOString();
  },
};
