import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'components/common/checkbox/checkbox';

import { RootState } from 'reducers';
import { setSelectedMessages } from 'reducers/messages';

import styles from './messagesListHeader.module.scss';

function MessagesListHeader() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { messages, selectedMessages, isTableMessagesShow } = useSelector((state: RootState) => state.messages);

  const headCheckboxStatus = selectedMessages.length === messages.length && selectedMessages.length > 0;
  const headCheckboxStatusIndeterminate = selectedMessages.length > 0 && selectedMessages.length < messages.length;

  const handleCheckAll = () => {
    if (headCheckboxStatus) {
      dispatch(setSelectedMessages([]));
    } else {
      dispatch(setSelectedMessages(messages.map(message => message.id)));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.side}>
        <Checkbox
          checked={!isTableMessagesShow && headCheckboxStatus}
          isIndeterminate={!isTableMessagesShow && headCheckboxStatusIndeterminate}
          handleCheck={handleCheckAll}
          wrapperStyle={styles.checkbox}
        />
        <div className={styles.columnText}>{t('messages.left-bar.content.list.header.name.text')}</div>
      </div>
      <div className={styles.side}>
        <div className={styles.columnText}>{t('messages.left-bar.content.list.header.type.text')}</div>
        <div className={styles.columnText}>{t('messages.left-bar.content.list.header.delete.text')}</div>
      </div>
    </div>
  );
}

export default MessagesListHeader;
