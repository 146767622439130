import { Middleware } from '@reduxjs/toolkit';

import { RootState } from 'reducers';
import { setIsUnauthorizedReq } from 'reducers/auth';

import { reqHandlers } from 'utils/api';

export const checkAndUpdateTokenMiddleware: Middleware<Record<string, unknown>, RootState> =
  store => next => async action => {
    const { dispatch, getState } = store;
    const state = getState() as RootState;

    const { isUnauthorizedReq } = state.auth;

    const isFunctionAction = typeof action === 'function';
    const isAsyncPendingAction = !isFunctionAction && action?.type?.match(/pending$/i);
    const isAsyncLoginAction = !isFunctionAction && action?.type?.match(/login$/i);

    try {
      // если action - не экшен авторизации пользователя
      // и если также это функция-промис либо асинхронный экшен
      if (!isAsyncLoginAction && (isFunctionAction || isAsyncPendingAction)) {
        // устанавливаем isUnauthorizedReq в true для
        // асинхронных экшенов в проекте при ответе от сервера 401
        // и если не смогли повторить запрос
        if (reqHandlers.isUnauthorizedReq && !isUnauthorizedReq) {
          dispatch(setIsUnauthorizedReq(true));
          return next(action);
        }
        // если пока идет запрос на обновление токена
        if (reqHandlers.isCheckTokenInProcess) {
          // подождать
          await reqHandlers.delay();
        } else {
          // иначе обновить токен
          await reqHandlers.checkAndUpdateToken();
        }
      }
    } catch (error) {
      // если возникла ошибка при обновлении токена
      // (просроченный или отсутствие refreshToken)
      // устанавливаем isUnauthorizedReq в true
      if (!isUnauthorizedReq) {
        dispatch(setIsUnauthorizedReq(true));
      }
    }
    return next(action);
  };
