import { CreateUserPreferences, CreateUserTabsPreference } from 'reducers/accounts/interface';
import { LANGUAGES_TO_TEXT_ENUM } from 'translate';
import {
  ALL_TABS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_GPS_FORMAT,
  DEFAULT_METRIC_SYSTEM,
  DEFAULT_TIMEZONE,
  PermissionKeyName as KeyName,
} from 'utils/consts';

export enum ActiveTab {
  info = 'info',
  roles = 'roles',
  stat = 'stat',
}

export const permRows: { label: string; keyName: KeyName }[] = [
  {
    label: 'accounts.roles-table.resource-column.row.trackers.text',
    keyName: 'trackers',
  },
  {
    label: 'accounts.roles-table.resource-column.row.transports.text',
    keyName: 'transports',
  },
  {
    label: 'accounts.roles-table.resource-column.row.employees.text',
    keyName: 'employees',
  },
  {
    label: 'accounts.roles-table.resource-column.row.users.text',
    keyName: 'users',
  },
  {
    label: 'accounts.roles-table.resource-column.row.roles.text',
    keyName: 'roles',
  },
  {
    label: 'accounts.roles-table.resource-column.row.geozones.text',
    keyName: 'geozones',
  },
  {
    label: 'accounts.roles-table.resource-column.row.tracks.text',
    keyName: 'tracks',
  },
  {
    label: 'accounts.roles-table.resource-column.row.records.text',
    keyName: 'records',
  },
  {
    label: 'accounts.roles-table.resource-column.row.map.text',
    keyName: 'map',
  },
  {
    label: 'accounts.roles-table.resource-column.row.handbooks.text',
    keyName: 'handbooks',
  },
  {
    label: 'accounts.roles-table.resource-column.row.buildings.text',
    keyName: 'buildings',
  },
  {
    label: 'accounts.roles-table.resource-column.row.notifications.text',
    keyName: 'notifications',
  },
  {
    label: 'accounts.roles-table.resource-column.row.poi.text',
    keyName: 'poi',
  },
  {
    label: 'accounts.roles-table.resource-column.row.reports.text',
    keyName: 'reports',
  },
  {
    label: 'accounts.roles-table.resource-column.row.messages.text',
    keyName: 'messages',
  },
];

export enum AccountSettingsKeysToNameTabsEnum {
  monitoring = 'common-info.tabs.monitoring.name.text',
  tracks = 'common-info.tabs.tracks.name.text',
  geozones = 'common-info.tabs.geofences.name.text',
  notifications = 'common-info.tabs.notifications.name.text',
  messages = 'common-info.tabs.messages.name.text',
  trackers = 'common-info.tabs.trackers.name.text',
  users = 'common-info.tabs.users.name.text',
  reports = 'common-info.tabs.dashboards.name.text',
  poi = 'common-info.tabs.poi.name.text',
  handbooks = 'common-info.tabs.directories.name.text',
  records = 'common-info.tabs.reports.name.text',
  videoanalytics = 'common-info.tabs.videoanalytics.name.text',
}

export const defaultMainTabState = {
  firstName: '',
  lastName: '',
  secondName: '',
  simNumber: '',
  smsNotified: false,
  login: '',
  organizationId: 0,
  departmentId: 0,
  positionId: 0,
  employeeId: null,
  email: '',
  emailNotified: false,
  isBanned: false,
  userPreferences: {},
  password: '',
};

export const defaultRolesTabState = {
  selectedRoles: [],
};

const defaultTabsPreferences: CreateUserTabsPreference[] = ALL_TABS.map(tabKey => ({
  keyName: tabKey,
  isVisible: true,
}));

export const defaultUserPreferences: CreateUserPreferences = {
  locale: LANGUAGES_TO_TEXT_ENUM.ru,
  metricSystem: DEFAULT_METRIC_SYSTEM,
  timezone: DEFAULT_TIMEZONE,
  dateFormat: DEFAULT_DATE_FORMAT,
  timeFormat: DEFAULT_TIMEZONE,
  gpsFormat: DEFAULT_GPS_FORMAT,
  tabs: defaultTabsPreferences,
  trackableUnitView: {
    alwaysShow: true,
    onRightSign: false,
    hasBackground: false,
    fullNameIsShown: true,
    departmentIsShown: true,
    organizationIsShown: true,
    trackerNumberIsShown: true,
    positionOrDriverIsShown: true,
  },
  monitoringListConfig: [],
  notificationsListConfig: [],
};
