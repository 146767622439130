// скрытый компонент (для бекенда при формировании отчета)
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Map from 'components/map/map';

import { getQS } from 'utils/getQS';

import { clearChosenReport, clearChosenTemplate, fetchOneReport, fetchOneTemplate } from 'reducers/records';

import './reportsMap.scss';

const ReportsMap = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const templateId = parseInt(getQS('templateId') || '');
    const reportId = parseInt(getQS('reportId') || '');

    if (templateId && reportId) {
      dispatch(fetchOneTemplate(String(templateId)));
      dispatch(fetchOneReport({ id: String(reportId), withMapData: true }));
    }

    return () => {
      dispatch(clearChosenTemplate());
      dispatch(clearChosenReport());
    };
  }, [dispatch]);

  return (
    <div className="wrapper">
      <Map hasNoControls />
    </div>
  );
};

export default ReportsMap;
