import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import { PhotoComponent } from 'components/common/photo/photo';

import { TransportInfo } from 'reducers/transports/interface';

import { TransportTabInfoHandlers } from '../utils/types';

import styles from '../monitoring.module.scss';

const cx = classNames.bind(styles);

type Option = {
  value: string | number;
  label: string | number;
};

interface Props {
  fields: TransportInfo;
  handlers: TransportTabInfoHandlers;
  invalidFields: string[];
  handbooks: {
    modelOptions: Option[];
    brandOptions: Option[];
    orgOptions: Option[];
    transportTypeOptions: Option[];
    fuelTypeOptions: Option[];
    driverOptions: Option[];
    departmentOptions: Option[];
    colorOptions: Option[];
    trackerOptions: Option[];
  };
  additionalData: {
    unUsedDrivers: Option[];
  };
  chosenTransportId: number;
}

export const TabInfo = ({ fields, handlers, invalidFields, handbooks, additionalData, chosenTransportId }: Props) => {
  const t = useFormatMessage();

  return (
    <div className={cx(styles.modalContent, styles.modalContentTransport)}>
      <div className={styles.modalColSlim}>
        <div className={styles.photoWrap}>
          <PhotoComponent src={fields.icon} onSetIcon={handlers.setIcon} isTransport />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div>
          <CustomSelect
            options={handbooks.brandOptions}
            label={t('monitoring.unit-card.transport.general.field.brand.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.brand.placeholder')}
            isValueError={invalidFields.includes('brandId')}
            isRequired={true}
            withSearchIcon
            value={fields.brandId}
            handleChange={handlers.handleBrandChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.modelOptions}
            label={t('monitoring.unit-card.transport.general.field.model.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.model.placeholder')}
            isValueError={invalidFields.includes('transportModelId')}
            isRequired={true}
            withSearchIcon
            value={fields.transportModelId}
            handleChange={handlers.handleModelChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.transport.general.field.registration-number.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.registration-number.placeholder')}
            isValueError={invalidFields.includes('regNumber')}
            isRequired={true}
            value={fields.regNumber}
            handleInputChange={handlers.setRegNumber}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.transport.general.field.model-year.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.model-year.placeholder')}
            pattern="[0-9]{4}"
            isValueError={invalidFields.includes('year')}
            isRequired={true}
            value={fields.year}
            handleInputChange={handlers.setYear}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.transportTypeOptions}
            label={t('monitoring.unit-card.transport.general.field.type.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.type.placeholder')}
            isValueError={invalidFields.includes('transportTypeId')}
            isRequired={true}
            withSearchIcon
            value={fields.transportTypeId}
            handleChange={handlers.handleTransportTypeChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.trackerOptions}
            label={t('monitoring.unit-card.transport.general.field.linked-tracker.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.linked-tracker.placeholder')}
            isValueError={invalidFields.includes('trackerId')}
            withSearchIcon
            value={fields.trackerId || ''}
            handleChange={handlers.handleTrackerChange}
            style={styles.inputWrap}
            disabled={!fields.trackersPermission.isAllowRead()}
          />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div>
          <CustomSelect
            options={handbooks.orgOptions}
            label={t('monitoring.unit-card.transport.general.field.company.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.company.placeholder')}
            isValueError={invalidFields.includes('organizationId')}
            isRequired={true}
            withSearchIcon
            value={fields.organizationId}
            handleChange={handlers.handleOrgChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.departmentOptions}
            label={t('monitoring.unit-card.transport.general.field.subdivision.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.subdivision.placeholder')}
            isValueError={invalidFields.includes('departmentId')}
            isRequired={true}
            withSearchIcon
            value={fields.departmentId}
            handleChange={handlers.handleStructureChange}
            style={styles.inputWrap}
            disabled={!fields.organizationId}
          />
        </div>
        <div>
          <CustomSelect
            options={!!chosenTransportId ? handbooks.driverOptions : additionalData.unUsedDrivers}
            label={t('monitoring.unit-card.transport.general.field.driver-name.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.driver-name.placeholder')}
            isValueError={invalidFields.includes('driverNameId')}
            isRequired={true}
            withSearchIcon
            value={fields.driverNameId}
            handleChange={handlers.handleDriverChange}
            style={styles.inputWrap}
            disabled={!handbooks.driverOptions.length}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.colorOptions}
            label={t('monitoring.unit-card.transport.general.field.color.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.color.placeholder')}
            isValueError={invalidFields.includes('transportColorId')}
            isRequired={true}
            withSearchIcon
            value={fields.transportColorId}
            handleChange={handlers.handleColorChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.transport.general.field.carrying-capacity.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.carrying-capacity.placeholder')}
            isValueError={invalidFields.includes('bearingCapacity')}
            value={fields.bearingCapacity}
            handleInputChange={handlers.setBearingOpacity}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.fuelTypeOptions}
            label={t('monitoring.unit-card.transport.general.field.fuel-type.label')}
            placeholder={t('monitoring.unit-card.transport.general.field.fuel-type.placeholder')}
            isValueError={invalidFields.includes('fuelTypeId')}
            isRequired={true}
            withSearchIcon
            value={fields.fuelTypeId}
            handleChange={handlers.handleFuelTypeChange}
            style={styles.inputWrap}
          />
        </div>
      </div>
    </div>
  );
};
