import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Feature, Map, View } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';

import { lineString } from '@turf/helpers';
import Bbox from '@turf/bbox';

import { Extent } from 'ol/extent';
import RenderEvent from 'ol/render/Event';

import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { setFloor } from 'reducers/map';

import { getCurrentLocale } from 'translate';

import { MOCK_CONTACTS_DATA } from 'utils/mocks';
import { MAP_LAYERS_Z_INDEX, MAX_ZOOM_DEFAULT_VALUE, MIN_ZOOM_DEFAULT_VALUE, ZOOM_DEFAULT_VALUE } from 'utils/consts';
import { applyFlyViewAnimation, createClusterSource, createGeoMarker, createVectorLayer } from '../utils';

import { SelectedEmployeeMarker, SelectedTransportMarker, TrackableUnitMarkerProperties } from '../map.types';
import { Marker } from 'reducers/markers';

const vectorSourceMarkers: VectorSource = new VectorSource({});
const vectorCluster = createClusterSource(vectorSourceMarkers);
const markersMapLayer = createVectorLayer(vectorCluster, MAP_LAYERS_Z_INDEX.MONITORING, null, true);
const viewSettings = new View({
  minZoom: MIN_ZOOM_DEFAULT_VALUE,
  maxZoom: MAX_ZOOM_DEFAULT_VALUE,
  zoom: ZOOM_DEFAULT_VALUE,
});

export const useMarkers = (map: Map) => {
  const dispatch = useDispatch();

  const storeMarkers = useSelector((state: RootState) => state.trackableUnit.trackableUnitsParsed);
  const trackableUnitsCoordinates = useSelector((state: RootState) => state.trackableUnit.trackableUnitsCoord);
  const { trackableUnits, searchedUnits } = useSelector((state: RootState) => state.trackableUnit);
  const selectedUnits = useSelector((state: RootState) =>
    state.monitoring.socialContactsUnits.length ? state.monitoring.socialContactsUnits : state.monitoring.selectedUnits
  );
  const watchingUnits = useSelector((state: RootState) => state.monitoring.watchingUnits);
  const { organizations, positions } = useSelector((state: RootState) => state.handbooks.data);
  const trackableUnitView = useSelector((state: RootState) => state.user.userPreferences.trackableUnitView);

  const floor = useSelector((state: RootState) => state.map.floor);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const makeMapMarkers = useCallback(() => {
    return selectedUnits.map(selUnit => {
      const unit = trackableUnits.find(u => u.id === selUnit);
      const fio = unit?.attributes.employeeId
        ? unit?.attributes.aggregatedName
            .split(' ')
            .map((f, i) => (i > 0 ? `${f[0]}.` : f))
            .join(' ')
        : unit?.attributes.aggregatedName ?? '';
      const deviceId = unit?.attributes.deviceId ?? '';
      const organization = organizations?.find(organization => organization.id === unit?.attributes.organizationId);
      const organizationName = getTranslateFromLanguageKey(organization?.attributes.name, userLanguageKey);
      const foundDepartment = organization?.relationships.departments?.data?.find(
        dep => dep.id === unit?.attributes.departmentId
      );
      const departmentName = getTranslateFromLanguageKey(foundDepartment?.name, userLanguageKey);
      const foundPositionOrDriver = positions?.find(pos => pos.id === unit?.attributes.positionId);
      const positionOrDriver = unit?.attributes.employeeId
        ? getTranslateFromLanguageKey(foundPositionOrDriver?.attributes.name, userLanguageKey)
        : '';

      const storeMarker = {
        ...(storeMarkers.find(storeMarker => storeMarker.deviceId === deviceId) ??
          ({
            floor: 0,
            fioMock: '',
            fio: '',
            data: [],
            deviceId: '',
            contacts: [],
            id: '',
          } as Marker)),
      };

      if (!!storeMarkers.length && trackableUnitsCoordinates[deviceId]?.coordinates) {
        storeMarker.data = trackableUnitsCoordinates[deviceId].coordinates;
      }

      return {
        ...storeMarker,
        unitId: unit?.id ?? '',
        info: {
          id: String(unit?.attributes.employeeId ?? unit?.attributes.transportId ?? ''),
          trackerId: unit?.attributes.trackerId ?? -1,
          fio,
          organizationName,
          departmentName: String(departmentName),
          positionOrDriver,
        },
        flags: { ...trackableUnitView },
        iconName: unit?.attributes.employeeId ? 'employeeIcon' : 'transportIcon',
        watching: unit ? watchingUnits.includes(unit?.id) : false,
        contactsData: unit?.attributes.employeeId === 1 ? MOCK_CONTACTS_DATA : null,
      };
    });
  }, [
    storeMarkers,
    selectedUnits,
    trackableUnits,
    watchingUnits,
    organizations,
    positions,
    trackableUnitView,
    userLanguageKey,
    trackableUnitsCoordinates,
  ]);

  const [markers, setMarkers] = useState<ReturnType<typeof makeMapMarkers>>([]);

  useEffect(() => {
    const markers = makeMapMarkers().filter(marker => !!marker);

    setMarkers(markers);
  }, [makeMapMarkers]);

  const watchingMarkers = useMemo(() => markers.filter(m => m.watching), [markers]);

  const makeGeoMarkers = useCallback(
    (floor: number, markers: Array<SelectedTransportMarker | SelectedEmployeeMarker>) => {
      const featuresFloor: Feature[] = [];

      for (let i = 0; i < markers.length; i++) {
        if (!markers[i].data) {
          continue;
        }

        const geoMarker: Feature = createGeoMarker(fromLonLat(markers[i].data));

        geoMarker.setProperties({
          contacts: markers[i].contacts,
          iconName: markers[i].iconName,
          info: markers[i].info,
          flags: markers[i].flags,
          contactsData: markers[i].contactsData,
          mapCoords: markers[i].data,
        } as TrackableUnitMarkerProperties);
        if (markers[i].floor === floor) {
          featuresFloor.push(geoMarker);
        }
      }
      return featuresFloor;
    },
    []
  );

  useEffect(() => {
    const showingFeatures = makeGeoMarkers(floor, markers);

    if (showingFeatures.length) {
      vectorSourceMarkers.addFeatures(showingFeatures);
    }
    return () => {
      vectorSourceMarkers.clear();
    };
  }, [floor, markers, makeGeoMarkers, trackableUnitView]);

  const [isAnimationAllowed, setIsAnimationAllowed] = useState(true); // флаг запрещающий/разрешающий анимация для слежения за юнитами

  // переключение этажей во время слежения за юнитами
  useEffect(() => {
    if (watchingMarkers.length && isAnimationAllowed) {
      if (watchingMarkers.length === 1) {
        const [marker] = watchingMarkers;

        if (marker.floor && floor && marker.floor !== floor) {
          dispatch(setFloor(marker.floor));
        }
      } else {
        watchingMarkers.forEach(marker => {
          if (marker.floor !== floor) {
            toast.warning(`${marker.info.fio} находится на другом этаже - ${marker.floor}`);
          }
        });
      }
    }
  }, [dispatch, watchingMarkers, floor, isAnimationAllowed]);

  // анимация и слежение за юнитами
  const removeListeners = useCallback(() => {
    const listeners = markersMapLayer.getListeners('postrender');

    if (listeners) {
      listeners.forEach(listener => {
        markersMapLayer.un('postrender', listener as (evt: RenderEvent) => void);
      });
    }
  }, []);

  const handlerWatchUnits = useCallback(() => {
    if (watchingMarkers.length) {
      const markersWithData = watchingMarkers.filter(marker => marker.data?.length);

      if (markersWithData.length) {
        if (markersWithData.length === 1) {
          const [marker] = markersWithData;

          if (marker.data?.length) {
            viewSettings.setCenter(fromLonLat(marker.data));
          }
          viewSettings.setZoom(ZOOM_DEFAULT_VALUE);
        } else {
          const line = lineString(markersWithData.map(m => fromLonLat(m.data)));
          const extent = Bbox(line);

          viewSettings.fit(extent as Extent, {
            padding: [50, 50, 50, 50],
          });
        }

        map.setView(viewSettings);
      } else {
        toast.warning('По выбранным объектам нет координат');
      }
    }
  }, [map, watchingMarkers]);

  useEffect(() => {
    removeListeners();
    if (watchingUnits.length) {
      setIsAnimationAllowed(true);
    }
  }, [watchingUnits, removeListeners]);

  useEffect(() => {
    if (isAnimationAllowed) {
      handlerWatchUnits();

      if (watchingUnits.length) {
        const center = viewSettings.getCenter();

        if (center) {
          applyFlyViewAnimation(center, viewSettings, () => {
            setIsAnimationAllowed(false);
            removeListeners();
            markersMapLayer.on('postrender', handlerWatchUnits);
          });
        }
      }
    } else {
      if (!watchingUnits.length) {
        removeListeners();
      }
    }
  }, [handlerWatchUnits, removeListeners, watchingUnits, isAnimationAllowed]);

  // поиск юнитов на карте

  const [isSearchAnimationAllowed, setIsSearchAnimationAllowed] = useState(true);

  const commonSearchedMarkers = useMemo(() => {
    const commonUnits = searchedUnits
      .map(searchedUnit => searchedUnit.id)
      .filter(searchedUnitId => selectedUnits.includes(searchedUnitId));

    return markers
      .map(marker => ({ ...marker, watching: false }))
      .filter(marker => {
        if (marker.unitId) {
          return commonUnits.includes(marker.unitId);
        }
        return false;
      });
  }, [searchedUnits, selectedUnits, markers]);

  useEffect(() => {
    if (commonSearchedMarkers.length && isSearchAnimationAllowed) {
      if (commonSearchedMarkers.length === 1) {
        const [marker] = commonSearchedMarkers;

        if (marker.floor && floor && marker.floor !== floor) {
          dispatch(setFloor(marker.floor));
        }
      }
    }
  }, [dispatch, commonSearchedMarkers, floor, isSearchAnimationAllowed]);

  useEffect(() => {
    if (searchedUnits.length) {
      setIsSearchAnimationAllowed(true);
    }
  }, [searchedUnits]);

  useEffect(() => {
    if (commonSearchedMarkers.length) {
      const markersWithData = commonSearchedMarkers.filter(marker => marker.data?.length);

      if (markersWithData.length) {
        if (markersWithData.length === 1) {
          const [marker] = markersWithData;

          if (marker.data?.length) {
            viewSettings.setCenter(fromLonLat(marker.data));
          }
          viewSettings.setZoom(ZOOM_DEFAULT_VALUE);
        } else {
          const line = lineString(markersWithData.map(m => fromLonLat(m.data)));
          const extent = Bbox(line);

          viewSettings.fit(extent as Extent, {
            padding: [50, 50, 50, 50],
          });
        }
        map.setView(viewSettings);
        if (isSearchAnimationAllowed) {
          const center = viewSettings.getCenter();
          if (center) {
            applyFlyViewAnimation(center, viewSettings, () => {
              setIsSearchAnimationAllowed(false);
            });
          }
        }
      }
    }
  }, [map, commonSearchedMarkers, isSearchAnimationAllowed]);

  useEffect(() => {
    map.addLayer(markersMapLayer);

    return () => {
      removeListeners();
      map.removeLayer(markersMapLayer);
    };
  }, [map, removeListeners]);

  return { markers };
};
