export const POI_POINT_MARKER_TYPE = 'poiPoint';
export const POI_VIDEOCAMERA_EDITOR_SECTOR_MARKER_TYPE = 'poiSectorPolygon';
export const POI_VIDEOCAMERA_EDITOR_SEMI_SPHERE_MARKER_TYPE = 'poiSemiSphereSectorPolygon';

export const POI_VIDEOCAMERA_EDITOR_VIEW_FIT_PAINT_DELAY = 1000;
export const POI_VIDEOCAMERA_EDITOR_VIEW_FIT_EDIT_DELAY = 500;

export enum POI_VIDEOCAMERA_EDITOR_POINT_IDS {
  MOVE = 'movePoint',
  ANGLE = 'anglePoint',
  DIRECTION = 'directionPoint',
  DISTANCE = 'distancePoint',
}

export enum POI_VIDEOCAMERA_EDITOR_INTERACTIONS {
  MOVE = 1,
  ANGLE,
  DIRECTION,
  DISTANCE,
}
