import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import Switch from 'components/common/switch/switch';

import { AccountHandlers, SelectOptions } from '../../utils/types';

import { CreateAccount } from 'reducers/accounts/interface';

import { PhotoComponent } from '../../../common/photo/photo';

import styles from './tabInfo.module.scss';

interface Props {
  values: CreateAccount;
  handlers: AccountHandlers;
  organizationOptions: SelectOptions;
  departmentOptions: SelectOptions;
  positionOptions: SelectOptions;
  invalidFields: string[];
  showBanSwitch: boolean;
}
export const TabInfo = ({
  values,
  handlers,
  invalidFields,
  showBanSwitch,
  organizationOptions,
  departmentOptions,
  positionOptions,
}: Props) => {
  const t = useFormatMessage();
  return (
    <div className={styles.modalContent}>
      <div className={styles.modalColSlim}>
        <div className={styles.photoWrap}>
          <PhotoComponent src={values.icon} onSetIcon={handlers.setIcon} />
        </div>
      </div>
      <div className={styles.modalCol}>
        <Input
          label={t('accounts.card.user.tab.general.field.surname.label')}
          placeholder={t('accounts.card.user.tab.general.field.surname.placeholder')}
          value={values.lastName}
          isValueError={invalidFields.includes('lastName')}
          isRequired={true}
          handleInputChange={handlers.setLastName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.name.label')}
          placeholder={t('accounts.card.user.tab.general.field.name.placeholder')}
          value={values.firstName}
          isValueError={invalidFields.includes('firstName')}
          isRequired={true}
          handleInputChange={handlers.setFirstName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.patronymic.label')}
          placeholder={t('accounts.card.user.tab.general.field.patronymic.placeholder')}
          value={values.secondName}
          handleInputChange={handlers.setSecondName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.phone.label')}
          placeholder={t('accounts.card.user.tab.general.field.phone.placeholder')}
          value={values.simNumber}
          handleInputChange={handlers.setSimNumber}
          customStyle={styles.inputWrapBottom}
        />
        <Switch
          checked={values.smsNotified}
          title={t('accounts.card.user.tab.general.field.sms-notify.text')}
          containerStyle={styles.switchContainer}
          onChange={handlers.handleSmsNotifyChange}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.login.label')}
          placeholder={t('accounts.card.user.tab.general.field.login.placeholder')}
          value={values.login}
          isValueError={invalidFields.includes('login')}
          isRequired={true}
          handleInputChange={handlers.setLogin}
          customStyle={styles.inputWrapBottom}
        />
      </div>
      <div className={styles.modalCol}>
        <CustomSelect
          label={t('accounts.card.user.tab.general.field.company.label')}
          placeholder={t('accounts.card.user.tab.general.field.company.placeholder')}
          value={values.organizationId}
          isValueError={invalidFields.includes('organizationId')}
          isRequired={true}
          options={organizationOptions}
          style={styles.inputWrap}
          handleChange={handlers.handleOrgChange}
        />
        <CustomSelect
          label={t('accounts.card.user.tab.general.field.subdivision.label')}
          placeholder={t('accounts.card.user.tab.general.field.subdivision.placeholder')}
          disabled={!Boolean(values.organizationId)}
          value={values.departmentId}
          isValueError={invalidFields.includes('departmentId')}
          isRequired={true}
          options={departmentOptions}
          style={styles.inputWrap}
          handleChange={handlers.handleDepartmentChange}
        />
        <CustomSelect
          label={t('accounts.card.user.tab.general.field.position.label')}
          placeholder={t('accounts.card.user.tab.general.field.position.placeholder')}
          value={values.positionId}
          isValueError={invalidFields.includes('positionId')}
          isRequired={true}
          options={positionOptions}
          style={styles.inputWrap}
          handleChange={handlers.handlePositionChange}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.email.label')}
          placeholder={t('accounts.card.user.tab.general.field.email.placeholder')}
          value={values.email}
          handleInputChange={handlers.setEmail}
          customStyle={styles.inputWrapBottom}
          isValueError={invalidFields.includes('email')}
          isRequired={true}
        />
        <Switch
          checked={values.emailNotified}
          title={t('accounts.card.user.tab.general.field.email-notify.text')}
          containerStyle={styles.switchContainer}
          onChange={handlers.handleEmailNotifyChange}
        />
        <Input
          label={t('accounts.card.user.tab.general.field.pass.label')}
          placeholder={t('accounts.card.user.tab.general.field.pass.placeholder')}
          value={values.password}
          handleInputChange={handlers.setPassword}
          customStyle={styles.inputWrapBottom}
        />
      </div>
      {showBanSwitch && (
        <div className={styles.banBlock}>
          <Switch
            checked={values.isBanned}
            onChange={handlers.handleBanStatusChange}
            title={t('accounts.card.user.tab.general.field.user-block.text')}
            containerStyle={styles.banBlockSwitch}
          />
        </div>
      )}
    </div>
  );
};
