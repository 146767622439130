import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Button from 'components/common/button/button';

import styles from './controlsModal.module.scss';

type Props = {
  onSave: () => void;
  onClose: () => void;
};

export const ControlsModal = ({ onSave, onClose }: Props) => {
  const t = useFormatMessage();

  return (
    <>
      <Button
        white
        text={t('handbooks.statistic-modal.footer.btn.close.label')}
        onClick={onClose}
        customStyle={styles.button}
      />
      <Button
        blue
        text={t('handbooks.statistic-modal.footer.btn.save.label')}
        onClick={onSave}
        customStyle={styles.button}
      />
    </>
  );
};
