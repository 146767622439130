import React, { MouseEvent, ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';

import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';

import Search from 'components/common/search/search';
import Button from 'components/common/button/button';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { NotificationObjectsInfoStates, NotificationObjectsInfoHandlers } from 'components/notifications/utils/types';

import styles from '../tabsModal.module.scss';

const cx = classNames.bind(styles);

interface Props {
  states: NotificationObjectsInfoStates;
  handlers: NotificationObjectsInfoHandlers;
}

type SortableItemProps = {
  value: string;
  handleClick: (ev: MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
};
type SortableListProps = {
  children: ReactNode;
  style: string;
};

const SortableItem = SortableElement(({ value, handleClick, selected }: SortableItemProps) => (
  <li
    className={selected ? cx(styles.groupsListItem, styles.groupsListItemSelected) : styles.groupsListItem}
    onClick={handleClick}
  >
    {value}
  </li>
));

const SortableList = SortableContainer(({ style, children }: SortableListProps) => {
  return <div className={style}>{children}</div>;
});

export const ObjectsTab = ({ states, handlers }: Props) => {
  const t = useFormatMessage();

  return (
    <div className={cx(styles.modalContent, styles.modalContentObjects)}>
      <div className={cx(styles.modalWrapperContent, styles.modalWrapperContentNoFlex)}>
        <div className={cx(styles.modalRow, styles.modalSearchWrapper)}>
          <div className={styles.modalSearch}>
            <Search handleChange={handlers.handleSearch} />
          </div>
          <div className={styles.modalChecks}>
            <div className={styles.checkbox} onClick={handlers.handleCheckedEmployee}>
              <Checkbox checked={states.chosenEmployee} />
              <span>{t('notifications.card.tabs.objects.field.employees.text')}</span>
            </div>
            <div className={styles.checkbox} onClick={handlers.handleCheckedTransport}>
              <Checkbox checked={states.chosenTransport} />
              <span>{t('notifications.card.tabs.objects.field.vehicles.text')}</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.modalRow, styles.modalSelectObjects)}>
          <SortableList distance={20} onSortEnd={handlers.handleSortEnd} lockAxis="x" style={styles.objectsList}>
            <div className={styles.objectsListDisabled}>
              <div className={styles.objectsListHeader}>
                {t('notifications.card.tabs.objects.field.available.header.text')}
              </div>
              <ul className={styles.objectsListItemsWrap}>
                {states.showObjects.map((obj, index) => {
                  if (!obj.isChosen) {
                    return (
                      <SortableItem
                        key={`available-item_${index}`}
                        index={index}
                        value={obj.data.name}
                        collection={obj.customId}
                        handleClick={(ev: MouseEvent<HTMLLIElement>) =>
                          handlers.handleAvailableItemClick(ev, obj.customId, index)
                        }
                        selected={states.selectedAvailable.includes(obj.customId)}
                      />
                    );
                  }
                  return null;
                })}
              </ul>
              <Button
                white
                text={t('notifications.card.tabs.objects.field.available.btn.select-all.label')}
                customStyle={styles.selectAllButton}
                onClick={handlers.handleSelectAvailableAll}
              />
            </div>

            <div className={styles.moveButtons}>
              <div className={styles.moveButtonsBtn} onClick={handlers.handleMoveRight}>
                <ArrowIcon className={styles.moveButtonsBtnToRight} />
              </div>
              <div className={styles.moveButtonsBtn} onClick={handlers.handleMoveLeft}>
                <ArrowIcon className={styles.moveButtonsBtnToLeft} />
              </div>
            </div>

            <div className={styles.objectsListActive}>
              <div className={styles.objectsListHeader}>
                {t('notifications.card.tabs.objects.field.selected.header.text')}
              </div>
              <ul className={styles.objectsListItemsWrap}>
                {states.showObjects.map((obj, index) => {
                  if (obj.isChosen) {
                    return (
                      <SortableItem
                        key={`chosen-item_${index}`}
                        index={index}
                        value={obj.data.name}
                        collection={obj.customId}
                        handleClick={(ev: MouseEvent<HTMLLIElement>) =>
                          handlers.handleChosenItemClick(ev, obj.customId, index)
                        }
                        selected={states.selectedChosen.includes(obj.customId)}
                      />
                    );
                  }
                  return null;
                })}
              </ul>
              <Button
                white
                text={t('notifications.card.tabs.objects.field.selected.btn.select-all.label')}
                customStyle={styles.selectAllButton}
                onClick={handlers.handleSelectChosedAll}
              />
            </div>
          </SortableList>
        </div>
      </div>
    </div>
  );
};
