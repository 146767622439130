import { MessagesType } from 'components/handbooks/utils/types';
import { DEFAULT_LANGUAGES, LanguagesKeysType } from 'translate';

// для internationalization
const messages = {
  organizations: 'handbooks.column.dictionaries.name.organizations.text',
  departments: 'handbooks.column.dictionaries.name.departments.text',
  positions: 'handbooks.column.dictionaries.name.positions.text',
  workObjects: 'handbooks.column.dictionaries.name.work-objects.text',
  transportBrands: 'handbooks.column.dictionaries.name.transport-brands.text',
  transportModels: 'handbooks.column.dictionaries.name.transport-models.text',
  transportTypes: 'handbooks.column.dictionaries.name.transport-types.text',
  transportColors: 'handbooks.column.dictionaries.name.transport-colors.text',
  transportDrivers: 'handbooks.column.dictionaries.name.transport-drivers.text',
  fuelTypes: 'handbooks.column.dictionaries.name.fuel-types.text',
  poiVideoCameraModels: 'handbooks.column.dictionaries.name.poi-video-camera-models.text',
  poiGasAnalyzerModels: 'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text',
  poiGasSensors: 'handbooks.column.dictionaries.name.poi-gas-sensors.text',
  poiGasMeasurements: 'handbooks.column.dictionaries.name.poi-gas-measurements.text',
  poiGasTypes: 'handbooks.column.dictionaries.name.poi-gas-types.text',
  poiSensorTypes: 'handbooks.column.dictionaries.name.poi-sensor-types.text',
  poiSensorModels: 'handbooks.column.dictionaries.name.poi-sensor-models.text',
  poiSensorMeasureUnits: 'handbooks.column.dictionaries.name.poi-sensor-measure-units.text',
  poiEquipmentTypes: 'handbooks.column.dictionaries.name.poi-equipment-types.text',
  poiEquipmentModels: 'handbooks.column.dictionaries.name.poi-equipment-models.text',
  poiAccidentTypes: 'handbooks.column.dictionaries.name.poi-accident-types.text',
  notificationSensors: 'handbooks.column.dictionaries.name.notification-sensors.text',
  notificationActivations: 'handbooks.column.dictionaries.name.notification-activations.text',
};

export const HANDBOOK_NAMES = {
  organizations: messages.organizations,
  departments: messages.departments,
  positions: messages.positions,
  workObjects: messages.workObjects,
  transportBrands: messages.transportBrands,
  transportModels: messages.transportModels,
  transportTypes: messages.transportTypes,
  transportColors: messages.transportColors,
  transportDrivers: messages.transportDrivers,
  fuelTypes: messages.fuelTypes,
  poiVideoCameraModels: messages.poiVideoCameraModels,
  poiGasAnalyzerModels: messages.poiGasAnalyzerModels,
  poiGasSensors: messages.poiGasSensors,
  poiGasMeasurements: messages.poiGasMeasurements,
  poiGasTypes: messages.poiGasTypes,
  poiSensorTypes: messages.poiSensorTypes,
  poiSensorModels: messages.poiSensorModels,
  poiSensorMeasureUnits: messages.poiSensorMeasureUnits,
  poiEquipmentTypes: messages.poiEquipmentTypes,
  poiEquipmentModels: messages.poiEquipmentModels,
  poiAccidentTypes: messages.poiAccidentTypes,
  notificationSensors: messages.notificationSensors,
  notificationActivations: messages.notificationActivations,
};

export enum HANDBOOK_KEYS {
  organizations = 'organizations',
  departments = 'departments',
  positions = 'positions',
  workObjects = 'workObjects',
  transportBrands = 'transportBrands',
  transportModels = 'transportModels',
  transportTypes = 'transportTypes',
  transportColors = 'transportColors',
  transportDrivers = 'transportDrivers',
  fuelTypes = 'fuelTypes',
  poiVideoCameraModels = 'poiVideoCameraModels',
  poiGasAnalyzerModels = 'poiGasAnalyzerModels',
  poiGasSensors = 'poiGasSensors',
  poiGasMeasurements = 'poiGasMeasurements',
  poiGasTypes = 'poiGasTypes',
  poiSensorTypes = 'poiSensorTypes',
  poiSensorModels = 'poiSensorModels',
  poiSensorMeasureUnits = 'poiSensorMeasureUnits',
  poiEquipmentTypes = 'poiEquipmentTypes',
  poiEquipmentModels = 'poiEquipmentModels',
  poiAccidentTypes = 'poiAccidentTypes',
  notificationSensors = 'notificationSensors',
  notificationActivations = 'notificationActivations',
}

export const HANDBOOK_INPUT_PROPS: MessagesType = {
  departments: {
    label: messages.departments,
    placeholder: 'handbooks.column.elements-card.placeholder.departments.text',
  },
  positions: {
    label: messages.positions,
    placeholder: 'handbooks.column.elements-card.placeholder.positions.text',
  },
  workObjects: {
    label: messages.workObjects,
    placeholder: 'handbooks.column.elements-card.placeholder.objects.text',
  },
  transportBrands: {
    label: messages.transportBrands,
    placeholder: 'handbooks.column.elements-card.placeholder.transport-brands.text',
  },
  transportModels: {
    label: messages.transportModels,
    placeholder: 'handbooks.column.elements-card.placeholder.transport-models.text',
  },
  transportTypes: {
    label: messages.transportTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.transport-types.text',
  },
  transportColors: {
    label: messages.transportColors,
    placeholder: 'handbooks.column.elements-card.placeholder.transport-colors.text',
  },
  fuelTypes: {
    label: messages.fuelTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.fuel-types.text',
  },
  poiVideoCameraModels: {
    label: messages.poiVideoCameraModels,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-video-camera-models.text',
  },
  poiGasAnalyzerModels: {
    label: messages.poiGasAnalyzerModels,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text',
  },
  poiGasSensors: {
    label: messages.poiGasSensors,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-gas-sensors.text',
  },
  poiGasMeasurements: {
    label: messages.poiGasMeasurements,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-gas-measurements.text',
  },
  poiGasTypes: {
    label: messages.poiGasTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-gas-types.text',
  },
  poiSensorTypes: {
    label: messages.poiSensorTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-sensors-types.text',
  },
  poiSensorModels: {
    label: messages.poiSensorModels,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-sensors-models.text',
  },
  poiSensorMeasureUnits: {
    label: messages.poiSensorMeasureUnits,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text',
  },
  poiEquipmentTypes: {
    label: messages.poiEquipmentTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-equipment-types.text',
  },
  poiEquipmentModels: {
    label: messages.poiEquipmentModels,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-equipment-models.text',
  },
  poiAccidentTypes: {
    label: messages.poiAccidentTypes,
    placeholder: 'handbooks.column.elements-card.placeholder.poi-accident-types.text',
  },
  notificationSensors: {
    label: messages.notificationSensors,
    placeholder: 'handbooks.column.elements-card.placeholder.notification-sensors.text',
  },
  notificationActivations: {
    label: messages.notificationActivations,
    placeholder: 'handbooks.column.elements-card.placeholder.notification-activations.text',
  },
};

export const HANDBOOK_ENTITY_ATTRIBUTES_MAIN_FIELDS = ['createdAt', 'updatedAt', 'deletedAt', 'name'];

export const defaultHandBookWithBind = {
  bindedHandbooks: {},
  bindedHandbooksForSelect: {},
};

export const defaultHandBookElementData = {
  name: {
    [DEFAULT_LANGUAGES as LanguagesKeysType]: '',
  },
};

export const defaultHandBookNewElementData = {
  newElementForSelect: {},
};

export enum HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE {
  create = 'create',
  update = 'update',
  delete = 'delete',
  import = 'import',
}

export enum HANDBOOK_STATISTIC_ATTRIBUTES_SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum HANDBOOK_POI_EQUIPMENT_TYPE_IDS {
  FIRST_AID_KIT = 1,
  STAIRS,
  FIRE_EXTINGUISHER,
  FIRE_CRANE,
}
