import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import Accounts from 'components/accounts/accounts';
import Records from 'components/records/records';
import Geozones from 'components/geozones/geozones';
import Monitoring from 'components/monitoring/monitoring';
import Notifications from 'components/notifications/notifications';
import POI from 'components/poi/poi';
import Trackers from 'components/trackers/trackers';
import Tracks from 'components/tracks/tracks';
import AccessEntity from 'utils/accessEntity';
import Handbooks from 'components/handbooks/handbooks';
import Messages from 'components/messages/messages';

import { RootState } from 'reducers';

const routes = [
  {
    name: 'monitoring',
    permissionsNames: ['employees', 'transports'],
    component: <Monitoring />,
  },
  {
    name: 'geozones',
    permissionsNames: ['geozones'],
    component: <Geozones />,
  },
  {
    name: 'trackers',
    permissionsNames: ['trackers'],
    component: <Trackers />,
  },
  {
    name: 'notifications',
    permissionsNames: ['notifications'],
    component: <Notifications />,
  },
  {
    name: 'messages',
    permissionsNames: ['messages'],
    component: <Messages />,
  },
  {
    name: 'poi',
    permissionsNames: ['poi'],
    component: <POI />,
  },
  {
    name: 'handbooks',
    permissionsNames: ['handbooks'],
    component: <Handbooks />,
  },
  {
    name: 'records',
    permissionsNames: ['records'],
    component: <Records />,
  },
  {
    name: 'tracks',
    permissionsNames: ['tracks'],
    component: <Tracks />,
  },
  {
    name: 'accounts',
    permissionsNames: ['users'],
    component: <Accounts />,
  },
];

export function Routes() {
  const userPermissions = useSelector((state: RootState) => state.user.permissions);

  const showingRoutes = routes.filter(route => {
    const totalAccess: boolean[] = [];
    for (let i = 0; i < route.permissionsNames.length; i++) {
      const access = new AccessEntity(userPermissions[route.permissionsNames[i]]);
      totalAccess.push(access.isAllowRead());
    }
    return totalAccess.every(a => a);
  });

  return (
    <Switch>
      {showingRoutes.map((route, index) => (
        <Route key={`route-${route.name}-${index}`} path={`/main/${route.name}`}>
          {route.component}
        </Route>
      ))}
    </Switch>
  );
}
