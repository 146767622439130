import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import Button from 'components/common/button/button';
import styles from './alert.module.scss';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  infoText: string;
  handleCancel: () => void;
  handleContinue: () => void;
};

export const Alert = ({ title, infoText, handleCancel, handleContinue }: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.layout}>
      <div className={styles.default}>
        <div className={styles.modalHeader}>
          <div>{title}</div>
          <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={handleCancel} />
        </div>
        <div className={styles.modalBodyDelete}>
          <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
            <Warning /> {t('alert.warning.text')}
          </p>
          <p className={styles.modalBodyDeleteText}>{infoText}</p>
          <p className={styles.modalBodyDeleteText}>{t('alert.question.text')}</p>
        </div>
        <div className={styles.modalControls}>
          <Button white text={t('alert.btn.no.label')} onClick={handleCancel} />
          <Button blue text={t('alert.btn.yes.label')} onClick={handleContinue} />
        </div>
      </div>
    </div>
  );
};
