import { getDateFromStr } from 'components/handbooks/utils/helpers';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import {
  clearTransportDriversHadbook,
  fetchTransportDriversHandbookData,
  setChosenTransportDriver,
  setCopyTransportDriver,
  setShowTransportDriversCard,
} from 'reducers/handbooks';

import AccessEntity from 'utils/accessEntity';
import { composeFullName } from 'utils/users/viewData';

import HandbooksElementsView, { ElementViewData } from '../handbooksElementsView/handbooksElementsView';

const TransportDriversHandbooksElements: React.VFC = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.isLoading);
  const transportDrivers = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.data);
  const updateCounter = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.updateCounter);
  const chosenElementId = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.chosenElementId);

  const handbooksPermissions = useSelector((state: RootState) => state.user.permissions.handbooks);
  const handbooksAccessEntity = useMemo(() => new AccessEntity(handbooksPermissions), [handbooksPermissions]);

  const transportDriversViewData: ElementViewData[] = useMemo(
    () =>
      transportDrivers.map(driver => ({
        id: driver.id,
        name: composeFullName(driver.lastName, driver.firstName, driver.secondName),
        createdAt: getDateFromStr(driver.createdAt),
        updatedAt: getDateFromStr(driver.updatedAt),
      })),
    [transportDrivers]
  );

  // fetch on mount and after mutation
  useEffect(() => {
    dispatch(fetchTransportDriversHandbookData());
  }, [updateCounter]);

  // component will unmount
  useEffect(() => {
    return () => {
      dispatch(clearTransportDriversHadbook());
    };
  }, []);

  const handleCreateClick = () => {
    dispatch(setCopyTransportDriver(null));
    dispatch(setChosenTransportDriver(null));
    dispatch(setShowTransportDriversCard(true));
  };

  const handleElementClick = (elementId: number) => {
    dispatch(setCopyTransportDriver(null));
    dispatch(setChosenTransportDriver(elementId));
    dispatch(setShowTransportDriversCard(true));
  };

  const handleCopyElement = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    ev.stopPropagation();
    dispatch(setCopyTransportDriver(id));
  };

  return (
    <HandbooksElementsView
      isLoading={isLoading}
      elements={transportDriversViewData}
      chosenElementId={chosenElementId}
      accessEntity={handbooksAccessEntity}
      handleCreateClick={handleCreateClick}
      handleElementClick={handleElementClick}
      handleCopyElementClick={handleCopyElement}
    />
  );
};

export default TransportDriversHandbooksElements;
