import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';
import { PoiFreePoint, ChosenPoi } from 'reducers/poi/interface';

import { defaultPoiFreePointState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardFreePointData } from '../utils/types';

export default function usePoiFreePoint(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardFreePointData);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const [createdDate, setCreatedDate] = useState(defaults.createdDate);

  const activePeriodRef = React.createRef();

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiFreePoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiFreePoint = relationships.poiFreePoint.data as PoiFreePoint;

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          activePeriod: poiFields.activePeriod,
          isRemovedInTheEnd: poiFields.isRemovedInTheEnd,
          createdDate: attributes.createdAt ?? new Date().toISOString(),
        })
      );
      if (attributes.createdAt) {
        setCreatedDate(attributes.createdAt);
      }
    }
  }, [dispatch, chosenPoi]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );
  const handleChangeCoordinates = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'coordinates',
        value,
      })
    );

  const handleChangeActivePeriod = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'activePeriod',
        value: date.toISOString(),
      })
    );

  const handleChangeIsRemovedInTheEnd = () =>
    dispatch(
      setPoiCardFieldData({
        key: 'isRemovedInTheEnd',
        value: !poiCardData.isRemovedInTheEnd,
      })
    );

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiFreePoint = relationships.poiFreePoint.data as PoiFreePoint;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        activePeriod: poiFields.activePeriod,
        isRemovedInTheEnd: poiFields.isRemovedInTheEnd,
        createdDate,
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      createdDate,
    },
    refs: {
      activePeriodRef,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangeCoordinates,
      handleChangeActivePeriod,
      handleChangeIsRemovedInTheEnd,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
