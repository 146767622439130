import { PoiCardData } from 'reducers/poi/interface';
import {
  defaultPoiAccidentState,
  defaultPoiEquipmentState,
  defaultPoiFreePointState,
  defaultPoiGazAnalyzerState,
  defaultPoiSensorState,
  defaultPoiVideoCameraState,
  PoiTypesEnum,
} from './consts';
import {
  PoiCardAccidentData,
  PoiCardEquipmentData,
  PoiCardFreePointData,
  PoiCardGazAnalyzerData,
  PoiCardSensorData,
  PoiCardVideoCameraData,
} from './types';

enum PoiCardNames {
  poiVideocameraPoint = 'poi.cards.videocamera.header.label',
  poiGasAnalyzerPoint = 'poi.cards.gazanalyser.header.label',
  poiSensorPoint = 'poi.cards.sensor.header.label',
  poiEquipmentPoint = 'poi.cards.equipment.header.label',
  poiAccidentPoint = 'poi.cards.incident.header.label',
  poiFreePoint = 'poi.cards.free-point.header.label',
}

const keysOfPoiCardNames = Object.keys(PoiCardNames);

export function getPoiCardData(type: PoiTypesEnum) {
  let defaultStoreData: PoiCardData | null = null;

  switch (type) {
    case PoiTypesEnum.poiVideocameraPoint:
      defaultStoreData = { ...defaultPoiVideoCameraState } as PoiCardVideoCameraData;
      break;

    case PoiTypesEnum.poiGasAnalyzerPoint:
      defaultStoreData = { ...defaultPoiGazAnalyzerState } as PoiCardGazAnalyzerData;
      break;

    case PoiTypesEnum.poiSensorPoint:
      defaultStoreData = { ...defaultPoiSensorState } as PoiCardSensorData;
      break;

    case PoiTypesEnum.poiEquipmentPoint:
      defaultStoreData = { ...defaultPoiEquipmentState } as PoiCardEquipmentData;
      break;

    case PoiTypesEnum.poiAccidentPoint:
      defaultStoreData = { ...defaultPoiAccidentState } as PoiCardAccidentData;
      break;

    case PoiTypesEnum.poiFreePoint:
      defaultStoreData = { ...defaultPoiFreePointState } as PoiCardFreePointData;
      break;

    default:
      break;
  }

  return defaultStoreData;
}

export function getPoiCardTitle(poiType: PoiTypesEnum) {
  if (keysOfPoiCardNames.includes(poiType)) {
    return PoiCardNames[poiType];
  }
}

export function validateCoordinates(coordinates: string) {
  if (!coordinates) {
    return false;
  }

  const coords: number[] = coordinates.split(',').map(c => Number(c.trim()));

  if (coords.length !== 2) {
    return false;
  }

  const [lon, lat] = coords;

  if (!lon && !lat) {
    return false;
  }

  if (isNaN(lon) || isNaN(lat)) {
    return false;
  }

  return true;
}

export function addNHoursToDate(date: Date, nHours: number) {
  return new Date(date.setHours(date.getHours() + nHours));
}
