import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Button from 'components/common/button/button';
import styles from './footer.module.scss';

type Props = {
  handleCancel: () => void;
  handleSave: () => void;
};

export function FooterButtons({ handleCancel, handleSave }: Props) {
  const t = useFormatMessage();

  return (
    <div className={styles.footerButtons}>
      <Button text={t('user-settings.modal.footer.btn.cancel.label')} white onClick={handleCancel} />
      <Button text={t('user-settings.modal.footer.btn.save.label')} blue onClick={handleSave} />
    </div>
  );
}
