/**
 * Default string includes with ignore case
 * @param str a string to search in
 * @param searchStr a string to be searched for within str
 * @param position The position within the string at which to begin searching for searchStr (Defaults to 0)
 * @returns true or false
 */
export function includesIgnoreCase(str: string, searchStr: string, position = 0): boolean {
  return str.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase(), position);
}
