import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';
import { reqHandlers } from 'utils/api';
import {
  GetTransportDriversHandbookResponse,
  HandbookNewElement,
  HandbookStatisticDataFilter,
  PostTransportDriversHandbookRequest,
  PutTransportDriversHandbookRequest,
} from './interface';

export function getHandbooksNames() {
  return reqHandlers.get('/api/handbooks');
}

export function getHandbooksData(key: string) {
  return reqHandlers.get(`/api/handbooks/${key}`);
}

export function importElements(key: string, file: File) {
  return reqHandlers.post(`/api/handbooks/${key}/import`, { key, file });
}

export function exportElements(key: string) {
  return reqHandlers.getFile(`/api/handbooks/${key}/export`);
}

export function createElement({ key, data }: { key: HANDBOOK_KEYS; data: HandbookNewElement }) {
  return reqHandlers.post(`/api/handbooks/${key}`, data);
}

export function putElement({ id, key, data }: { id: number; key: HANDBOOK_KEYS; data: HandbookNewElement }) {
  return reqHandlers.put(`/api/handbooks/${key}/update/${id}`, data);
}

export function getHandbooksStatistic(queryParams: HandbookStatisticDataFilter) {
  const query = getQueryStrFromObjKeys(queryParams);

  return reqHandlers.get(`/api/handbooks/statistic?${query}`);
}

export function exportHandbooksStatistic(queryParams: HandbookStatisticDataFilter) {
  const query = getQueryStrFromObjKeys(queryParams);

  return reqHandlers.getFile(`/api/handbooks/statistic/export?${query}`);
}

export function deleteElements({ id, key }: { id: number; key: HANDBOOK_KEYS }) {
  return reqHandlers.delete(`/api/handbooks/${key}/${id}`);
}

export function getTransportDriversHandbookData(): Promise<GetTransportDriversHandbookResponse> {
  return reqHandlers.get('/api/handbooks/transportDrivers');
}

export function postTransportDriversHandbookData(data: PostTransportDriversHandbookRequest): Promise<unknown> {
  return reqHandlers.post('/api/handbooks/transportDrivers', data);
}

export function putTransportDriversHandbookData(
  id: number,
  data: PutTransportDriversHandbookRequest
): Promise<unknown> {
  return reqHandlers.put(`/api/handbooks/transportDrivers/${id}`, data);
}
