import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg';

import styles from './pagination.module.scss';

const cx = classNames.bind(styles);

type Props = {
  page: number;
  withSelect?: boolean;
  limit?: number;
  setLimit?: (value: number) => void;
  increasePage?: () => void;
  decreasePage?: () => void;
  wrapperClass?: string;
};

function Pagination({ page, limit, setLimit, withSelect, increasePage, decreasePage, wrapperClass }: Props) {
  const t = useFormatMessage();

  return (
    <div className={cx(styles.modalPaginationWrapper, wrapperClass)}>
      {withSelect && (
        <>
          <div className={styles.modalPaginationSelectWrapper}>
            <span className={styles.modalPaginationSelectLabel}>
              {t('handbooks.statistic-modal.table.pagination.text')}
            </span>
            <div className={styles.modalPaginationSelect}>
              <select
                className={styles.modalPaginationSelect}
                defaultValue={limit}
                onChange={ev => setLimit && setLimit(parseInt(ev.target.value))}
              >
                <option>5</option>
                <option>10</option>
                <option>15</option>
              </select>
            </div>
          </div>
          <div className={styles.modalPaginationPageWrapper}>
            <ArrowLeft className={styles.modalPaginationArrow} onClick={decreasePage} />
            <div className={styles.modalPaginationPage}>{page}</div>
            <ArrowRight className={styles.modalPaginationArrow} onClick={increasePage} />
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Pagination);
