import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import {
  HandbooksCardWithBindHandlers,
  HandbooksCardWithBindStates,
  MessagesType,
} from 'components/handbooks/utils/types';
import { HANDBOOK_INPUT_PROPS } from 'components/handbooks/utils/consts';

import styles from './defaultHandbookCard.module.scss';

const cx = classNames.bind(styles);

const messages: MessagesType = {
  defaultsFields: {
    label: 'handbooks.column.elements-card.default.label',
    placeholder: 'handbooks.column.elements-card.default.placeholder',
  },
  ...HANDBOOK_INPUT_PROPS,
};

type PropsType = {
  states: HandbooksCardWithBindStates;
  handlers: HandbooksCardWithBindHandlers;
};

const HandbooksWithBind = ({ states, handlers }: PropsType) => {
  const t = useFormatMessage();

  return (
    <Fragment>
      {Object.keys(states.bindedHandbooks).map((key, i) => (
        <MultipleSelect
          key={`bindedHandbooks-multipleSelect-${key}-${i}`}
          options={states.bindedHandbooksForSelect[key]}
          label={t(messages[key]?.label) ?? t(messages.defaultsFields.label)}
          placeholder={t(messages[key]?.placeholder) ?? t(messages.defaultsFields.placeholder)}
          isRequired={false}
          withSearchIcon
          optionIsInline
          customSelectStyle={cx(styles.row, styles.rowSelect)}
          optionsContainerStyle={styles.rowMultipleSelectOptions}
          handleChangeMultiple={value => handlers.handleChangeBindedHandbook(key, value)}
          values={states.bindedHandbooks[key].map((id, i) =>
            getValueForMultipleSelect({
              i,
              id,
              selectValues: states.bindedHandbooksForSelect[key],
            })
          )}
        />
      ))}
    </Fragment>
  );
};

export default HandbooksWithBind;
