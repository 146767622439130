import { useCallback, useEffect, useState } from 'react';

import { Map, MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';

import { PoiMarkerProperties } from '../map.types';

export function usePoiHandlers(map: Map) {
  const [showPoiPopup, setShowPoiPopup] = useState(false);
  const [markerProperties, setMarkerProperties] = useState<PoiMarkerProperties | null>(null);
  const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 }, mapCoords: { x: 0, y: 0 } });

  const clickHandler = useCallback((evt: MapBrowserEvent<PointerEvent>) => {
    const { pixel, map } = evt;
    const mouseX = evt.originalEvent.clientX;
    const mouseY = evt.originalEvent.clientY;

    setShowPoiPopup(false);

    map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
      if (feature.get('features')) {
        if (feature.get('features').length === 1) {
          const values = feature.get('features')[0].getProperties('values') as PoiMarkerProperties;

          if (values.type === 'poiMarker') {
            setMarkerProperties(values);
            setMarkerCoords({
              mouseCoords: { x: mouseX, y: mouseY },
              mapCoords: { x: values.coordinates[0], y: values.coordinates[1] },
            });
            setShowPoiPopup(true);
          }
        }
      }
      return feature;
    });
  }, []);

  useEffect(() => {
    map.on('click', clickHandler);

    return () => {
      map.un('click', clickHandler);
    };
  }, [map, clickHandler]);

  return {
    poiPopup: {
      onCancel: () => setShowPoiPopup(false),
      markerCoords,
      markerProperties,
    },
    showPoiPopup,
  };
}
