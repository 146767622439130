import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import styles from './tabRoles.module.scss';
import { permRows } from '../../utils/consts';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { Spinner } from 'components/common/spinner/spinner';
import { RoleType } from 'reducers/roles/interface';
import { PermissionKeyName as KeyName } from 'utils/consts';
import { findAccess } from 'utils/findAccess';

interface Props {
  roles: RoleType[];
  isLoading: boolean;
  selectedRoles: number[];
  handleRoleSelection: (id: number) => void;
}

export const TabRoles = ({ roles, isLoading, selectedRoles, handleRoleSelection }: Props) => {
  const t = useFormatMessage();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.modalContent}>
      <div className={styles.rolesBlock}>
        <div className={styles.rolesBlockTitle}>
          {t('accounts.card.user.tab.roles.field.roles-available.header.label')}
        </div>
        <div className={styles.rolesBlockRoles}>
          {roles.map((role, ind) => (
            <div key={`role-at-account-creation-${ind}`} className={styles.rolesBlockRoleItem}>
              <Checkbox checked={selectedRoles.includes(role.id)} handleCheck={() => handleRoleSelection(role.id)} />
              <span className={styles.rolesBlockRoleItemLabel}>{role.attributes.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.permissionsBlock}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>
                {t('accounts.card.user.tab.roles.field.table.header.resource.label')}
              </th>
              <th className={styles.tableHeader}>{t('accounts.card.user.tab.roles.field.table.header.read.label')}</th>
              <th className={styles.tableHeader}>
                {t('accounts.card.user.tab.roles.field.table.header.create.label')}
              </th>
              <th className={styles.tableHeader}>{t('accounts.card.user.tab.roles.field.table.header.edit.label')}</th>
              <th className={styles.tableHeader}>
                {t('accounts.card.user.tab.roles.field.table.header.delete.label')}
              </th>
            </tr>
          </thead>
          <tbody>
            {permRows.map((row: { label: string; keyName: KeyName }, ind) => {
              const access = findAccess(row.keyName, selectedRoles, roles);
              return (
                <tr key={`account-creation_${ind}-${row.keyName}`}>
                  <td className={styles.tableBodyCell}>{t(row.label)}</td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={access[3] > 0}
                        handleCheck={() => {
                          return;
                        }}
                        isNegative={access[3] === 0}
                        cursorDefault
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={access[2] > 0}
                        handleCheck={() => {
                          return;
                        }}
                        cursorDefault
                        isNegative={access[2] === 0}
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={access[1] > 0}
                        handleCheck={() => {
                          return;
                        }}
                        cursorDefault
                        isNegative={access[1] === 0}
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={access[0] > 0}
                        handleCheck={() => {
                          return;
                        }}
                        cursorDefault
                        isNegative={access[0] === 0}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
