import React, { ChangeEvent } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import styles from './trackPlayer.module.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { playersStatus } from 'reducers/tracks';
import { RootState } from 'reducers';
import { formatToDDMMYYHHMM } from 'utils/formatTime';

import { ReactComponent as Play } from 'assets/img/play-item.svg';
import { ReactComponent as Pause } from 'assets/img/player/pause-icon.svg';
import { ReactComponent as Skip } from 'assets/img/player/skip-icon.svg';
import { ReactComponent as Close } from 'assets/img/close-icon.svg';
import { ReactComponent as Hide } from 'assets/img/player/hide-play.svg';
import { ReactComponent as Minimize } from 'assets/img/player/minimize.svg';

const cx = classNames.bind(styles);

const TrackPlayer = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { players, newTracksGroups, newTracks } = useSelector((state: RootState) => state.track);

  const handlePlay = (id: string) => {
    dispatch(
      playersStatus({
        id,
        // eslint-disable-next-line
        // @ts-ignore
        play: !players[id] ? true : !players[id].play,
        // eslint-disable-next-line
        // @ts-ignore
        pause: !players[id] ? false : !!players[id].play,
        show: true,
      })
    );
    window.activeId = id;
  };

  const handleShowInfo = (id: string) => {
    dispatch(
      playersStatus({
        id,
        // eslint-disable-next-line
        // @ts-ignore
        showInfo: !players[id].showInfo,
      })
    );
  };

  const handleChangeSpeed = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      playersStatus({
        id: window.activeId,
        speed: parseInt(e.target.value),
      })
    );
  };

  const handleStop = (id: string) => {
    dispatch(
      playersStatus({
        id,
        play: false,
        pause: true,
        show: false,
        mini: false,
      })
    );
  };

  const changePlayerSize = (id: string, status: boolean) => {
    dispatch(
      playersStatus({
        id,
        mini: status,
      })
    );
  };

  const handleScroll = (id: string, progress: number, scrollByUser = false) => {
    window.progress = progress;
    dispatch(
      playersStatus({
        id,
        play: false,
        pause: true,
        progress,
        mini: false,
        scrollByUser,
      })
    );
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    let { clientWidth } = e.nativeEvent.target as HTMLDivElement;
    if ((e.nativeEvent.target as HTMLDivElement).id !== `player${id}`) {
      clientWidth = ((e.nativeEvent.target as HTMLDivElement).parentNode as HTMLDivElement).clientWidth;
    }
    // eslint-disable-next-line
    // @ts-ignore
    if (!players[id].pause) {
      const percent = Math.floor((e.nativeEvent.offsetX / clientWidth) * 100);
      window.progress = percent;
      dispatch(
        playersStatus({
          id,
          play: true,
          pause: false,
          progress: percent,
          mini: false,
          scrollByUser: true,
        })
      );
    }
  };

  const playersKeys = Object.keys(players);
  if (!playersKeys.length) {
    return <></>;
  }
  // eslint-disable-next-line
  // @ts-ignore
  const id = players[playersKeys[0]]?.id || -1;
  const tracksFromGroup = newTracks.filter(tr => String(tr.attributes.trackGroupId) === id);
  const group = newTracksGroups.find(gr => gr.id === id);
  const dateFrom = new Date(tracksFromGroup[0]?.attributes.periodFrom || '');
  const dateTo = new Date(tracksFromGroup[0]?.attributes.periodTo || '');
  const dateFromFormated = formatToDDMMYYHHMM(dateFrom);
  const dateToFormated = formatToDDMMYYHHMM(dateTo);

  return (
    <>
      {playersKeys.map(index => {
        const playerNumber = parseInt(index, 10);
        if (!players[playerNumber]) {
          return <></>;
        }
        const { speed, show, mini, showInfo, progress } = players[playerNumber];
        const { currentCoord, currentTime } = window;
        const time = new Date(currentTime || tracksFromGroup[0]?.attributes.periodFrom || '');
        const timeFormated = formatToDDMMYYHHMM(time);
        return (
          <div key={playerNumber}>
            {!show && <></>}
            {show && mini && (
              <div className={cx(styles.minimizedPlayer, styles.main)}>
                <div className={styles.headBlock}>
                  <div />
                  <span>{`${t('track.player.header.label')} ${group?.attributes.name ?? ''}`}</span>
                  <div>
                    <Minimize className={styles.headBlockMinimize} onClick={() => changePlayerSize(id, false)} />
                    <Close className={styles.headBlockClose} onClick={() => handleStop(String(playerNumber))} />
                  </div>
                </div>
              </div>
            )}
            {show && !mini && (
              <div className={styles.container}>
                <div className={styles.main}>
                  <div className={styles.headBlock}>
                    <div className={styles.headBlockText}>
                      <Hide
                        className={cx(styles.headBlockHide, {
                          [styles.headBlockHideUp]: showInfo,
                          [styles.headBlockHideDown]: !showInfo,
                        })}
                        onClick={() => handleShowInfo(id)}
                      />
                      <span>{`${t('track.player.header.label')} ${playerNumber}`}</span>
                    </div>
                    <div>
                      <Minimize className={styles.headBlockMinimize} onClick={() => changePlayerSize(id, true)} />
                      <Close className={styles.headBlockClose} onClick={() => handleStop(id)} />
                    </div>
                  </div>
                  <div className={styles.timeline}>
                    <div className={styles.timelineDates}>
                      <span>{dateFromFormated}</span>
                      <span>{dateToFormated}</span>
                    </div>
                    <div
                      className={styles.timelineScaleWrap}
                      onClick={e => handleProgressBarClick(e, id)}
                      id={`player${playerNumber}`}
                    >
                      <div className={styles.timelineScale} style={{ width: progress + '%' }} />
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.buttons}>
                      <Skip
                        className={cx([styles.buttonsSkip, styles.buttonsSkipPlayback])}
                        onClick={() => handleScroll(id, 0)}
                      />
                      {players[playerNumber].play ? (
                        <Pause onClick={() => handlePlay(id)} className={styles.pointer} />
                      ) : (
                        <Play onClick={() => handlePlay(id)} className={styles.pointer} />
                      )}
                      <Skip className={styles.buttonsSkip} onClick={() => handleScroll(id, 100)} />
                    </div>
                    <div>{timeFormated}</div>
                  </div>
                </div>

                {showInfo && (
                  <div className={styles.additional}>
                    <div className={styles.speed}>
                      <div className={styles.speedTitle}>
                        {t('track.player.speed.label')} X{speed || 1}
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="30"
                        value={players[playerNumber].speed}
                        className={styles.speedSlider}
                        onChange={handleChangeSpeed}
                      />
                    </div>
                    <div className={styles.trackers}>
                      {tracksFromGroup.map((tracks, i) => (
                        <div key={`player-track-${i}`} className={styles.trackersItem}>
                          <div
                            className={styles.trackersItemColor}
                            style={{ backgroundColor: tracks.attributes.color }}
                          />
                          <div className={styles.trackersItemInfo}>
                            <div className={styles.trackersItemInfoName}>{tracks.attributes.name}</div>
                            <div>
                              {t('track.player.coordinates.text')}(
                              {currentCoord ? currentCoord.toString() : t('track.player.unknown.text')})
                            </div>
                            <div>{t('track.player.speed.label')}: 4км/ч</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TrackPlayer;
