import { REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS } from 'components/records/utils/consts';
import { reqHandlers } from 'utils/api';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';
import { CreateReport, RecordTemplateCreate, ReportsHistoryFilter } from './interface';

export function getTemplates() {
  return reqHandlers.get('/api/report/template');
}

export function createOneTemplate(data: RecordTemplateCreate) {
  return reqHandlers.post(`/api/report/template`, data);
}

export function sendTemplateFile(key: string, file: File) {
  return reqHandlers.post(`/api/report/printableTemplate`, { key, file });
}

export function getGroups() {
  return reqHandlers.get('/api/report/template/fieldGroups');
}

export function getGroupFields(groupName: string) {
  return reqHandlers.get(`/api/report/template/field/${groupName}`);
}

export function getTemplateWorkObject(type: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS) {
  return reqHandlers.get(`/api/report/template/workObject/${type}`);
}

export function getOneTemplate(id: string) {
  return reqHandlers.get(`/api/report/template/${id}`);
}

export function deleteOneTemplate(id: string) {
  return reqHandlers.delete(`/api/report/template/${id}`);
}

export function putOneTemplate(id: string, data: RecordTemplateCreate) {
  return reqHandlers.put(`/api/report/template/${id}`, data);
}

export function getReports(queryParams?: ReportsHistoryFilter) {
  const query = queryParams ? '?' + getQueryStrFromObjKeys(queryParams) : '';

  return reqHandlers.get(`/api/report${query}`);
}

export function getOneReport(id: string, withMapData = false) {
  return reqHandlers.get(`/api/report/${id}?withMapData=${withMapData}`);
}

export function createOneReport(templateId: string, newReport: CreateReport) {
  return reqHandlers.post(`/api/report/${templateId}`, newReport);
}

export function deleteOneReport(id: string) {
  return reqHandlers.delete(`/api/report/${id}`);
}

export function reportGenerateTags() {
  return reqHandlers.getFile(`/api/report/generate/tags`);
}

export function reportGeneratePrintedForm({
  reportTemplateId,
  reportId,
}: {
  reportTemplateId: number;
  reportId: number;
}) {
  return reqHandlers.post(
    `/api/report/generate/printedForm`,
    {
      reportTemplateId,
      reportId,
    },
    {
      withToast: false,
      hasSuccessResponseFields: true,
      hasBlobResponseData: true,
    }
  );
}
