import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { NOTIFICATION_TYPES_ENUM } from 'components/notifications/utils/consts';
import {
  NotificationTypesEnumType,
  AlertProps,
  GeozoneProps,
  InterpositionProps,
  SensorProps,
} from 'components/notifications/utils/types';
import { AlertLayout } from './components/alertLayout';
import { GeoZoneLayout } from './components/geoZoneLayout';
import { InterpositionObjectsLayout } from './components/interpositionObjectsLayout';
import { ValuesSensorLayout } from './components/valuesSensorLayout';

import styles from '../tabsModal.module.scss';

interface Props {
  notificationType: NotificationTypesEnumType | null;
  statesAndHandlers: {
    alert: AlertProps;
    geoZones: GeozoneProps;
    interceptionOfObjects: InterpositionProps;
    sensorValue: SensorProps;
  };
}

export const ActionsTab = (props: Props) => {
  const t = useFormatMessage();

  const showContent = (value: NotificationTypesEnumType | null) => {
    const { alert, geoZones, interceptionOfObjects, sensorValue } = NOTIFICATION_TYPES_ENUM;
    switch (value) {
      case alert:
        return <AlertLayout {...props.statesAndHandlers.alert} />;
      case geoZones:
        return <GeoZoneLayout {...props.statesAndHandlers.geoZones} />;
      case interceptionOfObjects:
        return <InterpositionObjectsLayout {...props.statesAndHandlers.interceptionOfObjects} />;
      case sensorValue:
        return <ValuesSensorLayout {...props.statesAndHandlers.sensorValue} />;

      default:
        return t('notifications.card.tabs.conditions.default.desc.text');
    }
  };

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalWrapperContent}>{showContent(props.notificationType)}</div>
    </div>
  );
};
