import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Objects } from 'components/notifications/utils/types';
import {
  NOTIFICATION_SORTING_BY,
  NOTIFICATION_MAX_LOGS,
  NOTIFICATION_TYPES_ENUM,
  NOTIFICATION_SORTING_LOGS_MODE,
} from 'components/notifications/utils/consts';

import { RootState } from 'reducers';
import { HandbookTranslatedFieldType } from 'reducers/handbooks/interface';

import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';

import {
  NotificationJSONApi,
  NotificationAttributesBase,
  NotificationsState,
  NotificationSortingType,
  NotificationListConfig,
  NotificationEntityWS,
  NotificationLogsJSONApi,
  NotificationLogJSONApi,
  NotificationsGroup,
  NotificationLog,
  NotificationLogListFilter,
  NotificationUnreadLogs,
  NotificationTrackableUnit,
  NotificationUser,
} from './interface';
import {
  getNotifications,
  createNotification,
  getNotification,
  putNotification,
  deleteNotification,
  createNotificationsTrackableUnit,
  createNotificationsUser,
  deleteNotificationsTrackableUnit,
  deleteNotificationsUser,
  getLog,
  getAllLogs,
  getCountUnredLogs,
  putIsDoneLog,
  getLogList,
} from './api';
import { TEST_NOTIFICATION_ID, TEST_NOTIFICATION_KEY_NAME } from 'utils/consts';

import { getFromStorageNewVersion, removeFromStorage } from 'utils/workWithStorages';

export const defaultHistoryLogsFilter: NotificationLogListFilter = {
  page: 1,
  limit: 10,
  dateStart: '',
  dateEnd: '',
  sortBy: 'createdAt',
  sortType: NOTIFICATION_SORTING_LOGS_MODE.ASC,
  notificationType: null,
};

const initialState: NotificationsState = {
  notifications: [],
  isLoading: false,

  needUpdate: false,
  additionalInfo: {
    notificationActivations: [],
    notificationSensors: [],
  },

  chosenNotification: null,
  isChosenNotificationLoading: false,

  chosenNotificationType: null,

  chosenNotificationLogs: [],
  isChosenNotificationLogsLoading: false,

  notificationUnreadLogs: {},
  isNotificationUnreadLogsLoading: false,

  markerNotificationLog: null,
  isMarkerNotificationLogLoading: false,

  clusterNotificationLogsIds: [],
  clusterNotificationLogs: [],
  isClusterNotificationLogsLoading: false,

  countUnreadLogs: [],
  countUnreadLogsIsLoading: false,
  isDoneLogIsUpdating: false,

  listConfig: [],
  selectedNotifications: [],
  groups: [],
  sort: {
    type: NOTIFICATION_SORTING_BY.name,
    ascending: true,
  },

  historyLogs: [],
  isHistoryLogsLoading: false,
  historyLogsTotal: 0,
  historyLogsFilter: defaultHistoryLogsFilter,
  selectedHistoryLogs: [],
  isTableHistoryLogsShow: false,

  error: null,
};

export const fetchNotifications = createAsyncThunk(
  'notificationsSlice/fetchNotifications',
  async () => await getNotifications()
);

export const removeNotification = createAsyncThunk(
  'notificationsSlice/removeNotification',
  async (id: string) => await deleteNotification(id)
);

export const addOrUpdateNotification = createAsyncThunk(
  'notificationsSlice/addOrUpdateNotification',
  async ({
    id,
    chosenObjects,
    activeObjects,
    chosenUsers,
    activeUsers,
    notificationInfo,
  }: {
    id: string | null;
    chosenObjects: Objects[];
    activeObjects: Objects[];
    chosenUsers: number[];
    activeUsers: number[];
    notificationInfo: NotificationAttributesBase;
  }) => {
    let notificationId = id;

    if (!id) {
      // create notification
      const n = await createNotification(notificationInfo);
      notificationId = n.data.id;
    } else {
      // update notification
      await putNotification({ id, attributes: notificationInfo });
    }

    const promises: ParallelItemInterface[] = [];
    const chosenObjectsIds = chosenObjects.map(obj => obj.data.id);
    const activeObjectsIds = activeObjects.map(obj => obj.data.id);
    const { isEmailPushOn, isSmsPushOn } = notificationInfo;

    chosenObjects.forEach(obj => {
      if (!activeObjectsIds.includes(obj.data.id)) {
        const args: NotificationTrackableUnit = {
          notificationId: notificationId ?? '',
          trackableUnitId: obj.data.id,
        };

        promises.push({ promiseMethod: createNotificationsTrackableUnit, args });
      }
    });
    activeObjects.forEach(obj => {
      if (!chosenObjectsIds.includes(obj.data.id)) {
        const args: NotificationTrackableUnit = {
          notificationId: notificationId ?? '',
          trackableUnitId: obj.data.id,
        };

        promises.push({ promiseMethod: deleteNotificationsTrackableUnit, args });
      }
    });

    if (isEmailPushOn || isSmsPushOn) {
      chosenUsers.forEach(chosenUserId => {
        if (!activeUsers.includes(chosenUserId)) {
          const args: NotificationUser = {
            notificationId: notificationId ?? '',
            userId: chosenUserId,
          };

          promises.push({ promiseMethod: createNotificationsUser, args });
        }
      });
      activeUsers.forEach(activeUserId => {
        if (!chosenUsers.includes(activeUserId)) {
          const args: NotificationUser = {
            notificationId: notificationId ?? '',
            userId: activeUserId,
          };

          promises.push({ promiseMethod: deleteNotificationsUser, args });
        }
      });
    } else {
      activeUsers.forEach(activeUserId => {
        const args: NotificationUser = {
          notificationId: notificationId ?? '',
          userId: activeUserId,
        };

        promises.push({ promiseMethod: deleteNotificationsUser, args });
      });
    }
    await reqHandlers.allSettled(promises);
  }
);

export const fetchChosenNotification = createAsyncThunk(
  'notificationsSlice/fetchChosenNotification',
  async (id: string) => await getNotification(id)
);

export const fetchChosenNotificationLogs = createAsyncThunk(
  'notificationsSlice/fetchChosenNotificationLogs',
  async (id: string) => await getAllLogs(id)
);

export const fetchNotificationUnreadLogs = createAsyncThunk(
  'notificationsSlice/fetchNotificationUnreadLogs',
  async (_, thunkApi) => {
    const {
      user: {
        userPreferences: { locale },
      },
    } = thunkApi.getState() as RootState;

    return await getLogList({
      limit: NOTIFICATION_MAX_LOGS,
      sortBy: 'createdAt',
      sortType: NOTIFICATION_SORTING_LOGS_MODE.ASC,
      locale,
      unreadOnly: true,
    });
  }
);

export const fetchNotificationHistoryLogs = createAsyncThunk(
  'notificationsSlice/fetchNotificationHistoryLogs',
  async (queryParams: NotificationLogListFilter, thunkApi) => {
    const {
      user: {
        userPreferences: { locale },
      },
    } = thunkApi.getState() as RootState;

    return await getLogList({ ...queryParams, locale });
  }
);

export const fetchNotificationHistoryLogsLazy = createAsyncThunk(
  'notificationsSlice/fetchNotificationHistoryLogsLazy',
  async (queryParams: NotificationLogListFilter, thunkApi) => {
    const {
      user: {
        userPreferences: { locale },
      },
    } = thunkApi.getState() as RootState;

    return await getLogList({ ...queryParams, locale });
  }
);

export const fetchMarkerLog = createAsyncThunk(
  'notificationsSlice/fetchMarkerLog',
  async (id: number) => await getLog(id)
);

export const fetchClusterNotificationLogs = createAsyncThunk(
  'notificationsSlice/fetchClusterNotificationLogs',
  async (logIds: number[]) => {
    const promises: ParallelItemInterface[] = [];

    logIds.forEach(logId => {
      if (logId !== TEST_NOTIFICATION_ID) {
        promises.push({ promiseMethod: getLog, args: logId });
      }
    });

    const dataResults = await reqHandlers.allSettled(promises);
    const fulfilledData: NotificationLog[] = [];
    let isError = false;

    dataResults.forEach(dataResult => {
      if (dataResult.status === 'fulfilled') {
        const log = dataResult.value as unknown as NotificationLogJSONApi;

        fulfilledData.push(log.data);
      }
      if (dataResult.status === 'rejected') {
        isError = true;
      }
    });

    if (!isError) {
      return Promise.resolve(fulfilledData);
    }
    throw Promise.reject('Fetch data error');
  }
);

export const fetchCountUnreadLogs = createAsyncThunk(
  'notificationsSlice/fetchCountUnreadLogs',
  async () => await getCountUnredLogs()
);

export const updateIsDoneLog = createAsyncThunk(
  'notificationsSlice/updateIsDoneLog',
  async (id: number) => await putIsDoneLog(id)
);

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setNotificationsGroups: (state, { payload }: { payload: (NotificationsGroup | null)[] }) => {
      const groups: NotificationsGroup[] = [];
      for (const group of payload) {
        if (!group) {
          continue;
        }
        groups.push(group);
      }
      state.groups = groups;
    },
    changeNotificationGroup: (
      state,
      {
        payload,
      }: {
        payload: {
          type: NOTIFICATION_TYPES_ENUM;
          state: 'isActive' | 'expanded' | 'checked';
        };
      }
    ) => {
      const groups = state.groups;

      const foundGroupIndex = groups.findIndex(group => group.notificationType === payload.type);

      if (foundGroupIndex < 0) {
        return;
      }
      switch (payload.state) {
        case 'isActive':
          groups[foundGroupIndex].isActive = !groups[foundGroupIndex].isActive;
          break;

        case 'expanded':
          groups[foundGroupIndex].expanded = !groups[foundGroupIndex].expanded;
          break;

        case 'checked':
          const newChecked = !groups[foundGroupIndex].checked;
          state.notifications = state.notifications.map(notification => {
            const foundNotificationId = groups[foundGroupIndex].notificationsIds.find(id => id === notification.id);
            if (!foundNotificationId) {
              return notification;
            }
            return {
              ...notification,
              checked: newChecked,
            };
          });
          groups[foundGroupIndex].checked = newChecked;
          groups[foundGroupIndex].notificationsIds = groups[foundGroupIndex].notificationsIds.map(id => id);
          break;

        default:
          break;
      }
      state.groups = groups;
    },
    toggleExpandAllNotificationGroups: (state, { payload }: { payload: boolean }) => {
      state.groups = state.groups.map(group => ({ ...group, expanded: payload }));
    },
    toggleCheckedAllNotificationGroups: (state, { payload }: { payload: boolean }) => {
      state.groups = state.groups.map(group => ({ ...group, checked: payload }));
      if (payload) {
        state.selectedNotifications = state.notifications.map(n => n.id);
      } else {
        state.selectedNotifications = [];
      }
    },

    setUsersNotificationsState: (state, { payload }) => {
      if (payload.selectedNotifications?.length) {
        state.selectedNotifications = payload.selectedNotifications;
      }
      if (payload.groups?.length) {
        state.groups = payload.groups;
      }
    },

    setChosenNotificationType: (state, { payload }: { payload: NOTIFICATION_TYPES_ENUM | null }) => {
      state.chosenNotificationType = payload;
    },

    setSelectedNotifications: (
      state,
      {
        payload,
      }: {
        payload: {
          ids: string[];
          status: boolean;
        };
      }
    ) => {
      const { ids, status } = payload;

      ids.forEach(id => {
        if (status) {
          if (!state.selectedNotifications.includes(id)) {
            state.selectedNotifications.push(id);
          }
        } else {
          if (state.selectedNotifications.includes(id)) {
            const index = state.selectedNotifications.findIndex(item => item === id);
            if (index > -1) {
              state.selectedNotifications.splice(index, 1);
            }
          }
        }
      });
    },

    toggleCheckedNotification: (state, { payload }: { payload: string }) => {
      const selectedNotifications = state.selectedNotifications;

      if (!selectedNotifications.includes(payload)) {
        selectedNotifications.push(payload);
      } else {
        const findIndex = selectedNotifications.findIndex(sel => sel === payload);
        if (findIndex >= 0) {
          selectedNotifications.splice(findIndex, 1);
        }
      }
      state.selectedNotifications = selectedNotifications;
    },

    removeChosenNotification: state => {
      state.chosenNotification = null;
    },

    turnNotificationInList: (state, { payload }: { payload: string }) => {
      state.notifications = state.notifications.map(notification => {
        if (notification.id === payload) {
          return {
            ...notification,
            attributes: { ...notification.attributes, isActive: !notification.attributes.isActive },
          };
        }
        return notification;
      });
    },
    setTurnedNotifications: (state, action) => {
      const payload: {
        ids: string[];
        status: boolean;
      } = action.payload;

      state.notifications = state.notifications.map(item => {
        if (payload.ids.includes(item.id)) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              isActive: payload.status,
            },
          };
        }
        return item;
      });
    },
    turnAllNotificationsInList: (state, { payload }: { payload: boolean }) => {
      state.notifications = state.notifications.map(notification => ({
        ...notification,
        attributes: { ...notification.attributes, isActive: payload },
      }));
    },

    updateListConfig: (state, { payload }: { payload: NotificationListConfig }) => {
      state.listConfig = [...payload];
    },

    setMarkerNotificationLog: (state, { payload }: { payload: NotificationLog }) => {
      state.markerNotificationLog = payload;
    },
    removeMarkerNotificationLog: state => {
      state.markerNotificationLog = null;
    },
    setTestMarkerNotificationLog: state => {
      const testNotification: NotificationLog = getFromStorageNewVersion(TEST_NOTIFICATION_KEY_NAME);
      if (testNotification) {
        state.markerNotificationLog = testNotification;
      }
    },
    setClusterNotificationLogsIds: (state, { payload }: { payload: number[] }) => {
      state.clusterNotificationLogsIds = payload;
    },
    removeClusterNotificationLogs: state => {
      state.clusterNotificationLogs = [];
    },

    removeChosenNotificationLogs: state => {
      state.chosenNotificationLogs = [];
    },

    decreaseCountUnreadLogs: (state, { payload }: { payload: string }) => {
      const currentNotification = state.notifications.find(notification => notification.id === payload);

      if (!currentNotification) {
        return;
      }

      const countUnreadLogs = [...state.countUnreadLogs];
      const foundIndex = countUnreadLogs.findIndex(
        unreadLog => unreadLog.notificationType === currentNotification.attributes.notificationType
      );

      if (foundIndex < 0) {
        return;
      }
      countUnreadLogs[foundIndex].count--;
      state.countUnreadLogs = [...countUnreadLogs];
    },

    deleteNotificationLogById: (state, { payload }: { payload: { logId: number; notificationId: string } }) => {
      const { logId, notificationId }: { logId: number; notificationId: string } = payload;
      const notifications = [...state.notifications];
      const foundNotificationIndex = notifications.findIndex(n => n.id === notificationId);

      if (foundNotificationIndex < 0) {
        return;
      }

      const notifyId = Number(notifications[foundNotificationIndex].id);
      const currentUnreadLogs = state.notificationUnreadLogs[notifyId].filter(log => log.id !== logId);

      notifications[foundNotificationIndex].meta.numberOfNotified = String(
        Number(notifications[foundNotificationIndex].meta.numberOfNotified) - 1
      );

      state.notifications = notifications;
      state.notificationUnreadLogs = { ...state.notificationUnreadLogs, [notifyId]: currentUnreadLogs };
    },

    addLogToNotificationAndIncreaseCount: (state, { payload }: { payload: NotificationEntityWS }) => {
      const { log } = payload;

      if (log) {
        const notificationId = log.notificationId;

        const unreadLogs = state.notificationUnreadLogs;
        const countUnreadLogs = state.countUnreadLogs;

        unreadLogs[notificationId] = [
          ...(unreadLogs[notificationId] ?? []),
          {
            id: notificationId,
            type: 'SosDeviceEvent',
            attributes: log,
          },
        ];

        const foundIndexUnreadLogs = countUnreadLogs.findIndex(
          count => count.notificationType === log.notificationType
        );

        if (foundIndexUnreadLogs > -1) {
          const newCount = Number(countUnreadLogs[foundIndexUnreadLogs].count);

          if (!isNaN(newCount)) {
            countUnreadLogs[foundIndexUnreadLogs].count = newCount + 1;
          }
        }

        state.notificationUnreadLogs = unreadLogs;
        state.countUnreadLogs = countUnreadLogs;
      }
    },

    addLogToNotificationAndIncreaseCountArray: (state, { payload: payloads }: { payload: NotificationEntityWS[] }) => {
      const unreadLogs = state.notificationUnreadLogs;
      const countUnreadLogs = state.countUnreadLogs;

      payloads.forEach(payload => {
        const { log } = payload;

        if (log) {
          const notificationId = log.notificationId;

          unreadLogs[notificationId] = [
            ...(unreadLogs[notificationId] ?? []),
            {
              id: notificationId,
              type: 'SosDeviceEvent',
              attributes: log,
            },
          ];

          const foundIndexUnreadLogs = countUnreadLogs.findIndex(
            count => count.notificationType === log.notificationType
          );

          if (foundIndexUnreadLogs > -1) {
            const newCount = Number(countUnreadLogs[foundIndexUnreadLogs].count);

            if (!isNaN(newCount)) {
              countUnreadLogs[foundIndexUnreadLogs].count = newCount + 1;
            }
          }
        }
      });

      state.notificationUnreadLogs = unreadLogs;
      state.countUnreadLogs = countUnreadLogs;
    },

    setSort: (state, { payload }: { payload: NotificationSortingType }) => {
      const sort = state.sort;

      if (sort.type !== payload) {
        sort.type = payload;
        sort.ascending = false;
      } else {
        sort.ascending = !state.sort.ascending;
      }
    },

    setHistoryLogs: (state, { payload }: { payload: NotificationLog[] }) => {
      state.historyLogs = payload;
    },
    setSelectedHistoryLogs: (state, { payload }: { payload: number[] }) => {
      state.selectedHistoryLogs = payload;
    },
    setIsTableHistoryLogsShow: (state, { payload }: { payload: boolean }) => {
      state.isTableHistoryLogsShow = payload;
    },
    setFilterField: (
      state,
      { payload }: { payload: { key: string; value: NOTIFICATION_SORTING_LOGS_MODE | string | number } }
    ) => {
      state.historyLogsFilter = {
        ...state.historyLogsFilter,
        [payload.key]: payload.value,
      };
    },
    setFilterFields: (state, { payload }: { payload: NotificationLogListFilter }) => {
      state.historyLogsFilter = payload;
    },
    setNotificationHistoryTotal: (state, { payload }: { payload: number }) => {
      state.historyLogsTotal = payload;
    },
    clearHistoryLogsFilter: state => {
      state.historyLogsFilter = defaultHistoryLogsFilter;
    },
    clearHistoryLogs: state => {
      state.historyLogs = [];
    },
    addTestNotification: (state, { payload }: { payload: NotificationLog }) => {
      state.notificationUnreadLogs[payload.attributes.notificationId] = [payload];
    },
    removeTestNotification: state => {
      const testNotification: NotificationLog = getFromStorageNewVersion(TEST_NOTIFICATION_KEY_NAME);
      const notificationUnreadLogs = state.notificationUnreadLogs;
      if (testNotification) {
        const id = testNotification.attributes.notificationId.toString();
        for (const i in notificationUnreadLogs) {
          if (notificationUnreadLogs.hasOwnProperty(i) && i === id) {
            notificationUnreadLogs[i] = notificationUnreadLogs[i].filter(ulog => ulog.id !== testNotification.id);
          }
        }
        state.notificationUnreadLogs = notificationUnreadLogs;
        state.needUpdate = true;
        removeFromStorage(TEST_NOTIFICATION_KEY_NAME);
      }
    },
  },
  extraReducers: builder => {
    // get all
    builder
      .addCase(fetchNotifications.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }: { payload: NotificationJSONApi }) => {
        state.notifications = payload.data;
        state.needUpdate = false;
        state.isLoading = false;
        state.additionalInfo = payload.meta;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    // get chosen info
    builder
      .addCase(fetchChosenNotification.pending, state => {
        state.error = null;
        state.isChosenNotificationLoading = true;
      })
      .addCase(fetchChosenNotification.fulfilled, (state, action) => {
        state.chosenNotification = action.payload.data;
        state.isChosenNotificationLoading = false;
      })
      .addCase(fetchChosenNotification.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenNotificationLoading = false;
      });
    // remove one
    builder
      .addCase(removeNotification.pending, state => {
        state.error = null;
      })
      .addCase(removeNotification.fulfilled, state => {
        state.chosenNotification = null;
        state.needUpdate = true;
      })
      .addCase(removeNotification.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    // add or update one
    builder
      .addCase(addOrUpdateNotification.pending, state => {
        state.error = null;
      })
      .addCase(addOrUpdateNotification.fulfilled, state => {
        state.chosenNotification = null;
        state.needUpdate = true;
      })
      .addCase(addOrUpdateNotification.rejected, (state, action) => {
        state.chosenNotification = null;
        state.error = action.error.message ?? 'Error';
      });
    // get marker log
    builder
      .addCase(fetchMarkerLog.pending, state => {
        state.error = null;
        state.isMarkerNotificationLogLoading = true;
      })
      .addCase(fetchMarkerLog.fulfilled, (state, { payload }: { payload: NotificationLogJSONApi }) => {
        const log = payload.data;
        const transportType = log.attributes.transportType;
        const employeePosition = log.attributes.employeePosition;
        let parsedData: HandbookTranslatedFieldType | string | null = null;

        if (transportType) {
          try {
            parsedData = JSON.parse(transportType as string);
          } catch (error) {
            parsedData = transportType;
          }
        }
        if (employeePosition) {
          try {
            parsedData = JSON.parse(employeePosition as string);
          } catch (error) {
            parsedData = employeePosition;
          }
        }
        state.markerNotificationLog = {
          ...log,
          attributes: {
            ...log.attributes,
            transportType: transportType ? parsedData : null,
            employeePosition: employeePosition ? parsedData : null,
          },
        };
        state.isMarkerNotificationLogLoading = false;
      })
      .addCase(fetchMarkerLog.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isMarkerNotificationLogLoading = false;
      });
    // get cluster logs
    builder
      .addCase(fetchClusterNotificationLogs.pending, state => {
        state.error = null;
        state.isClusterNotificationLogsLoading = true;
      })
      .addCase(fetchClusterNotificationLogs.fulfilled, (state, { payload }: { payload: NotificationLog[] }) => {
        state.isClusterNotificationLogsLoading = false;
        state.clusterNotificationLogs = [...state.clusterNotificationLogs, ...payload];
      })
      .addCase(fetchClusterNotificationLogs.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isClusterNotificationLogsLoading = false;
      });
    // get unread logs
    builder
      .addCase(fetchNotificationUnreadLogs.pending, state => {
        state.error = null;
        state.isNotificationUnreadLogsLoading = true;
      })
      .addCase(fetchNotificationUnreadLogs.fulfilled, (state, { payload }: { payload: NotificationLogsJSONApi }) => {
        state.historyLogsTotal = payload.meta.total;
        state.notificationUnreadLogs = payload.data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.attributes.notificationId]: [...(acc[curr.attributes.notificationId] || []), curr],
          }),
          {} as NotificationUnreadLogs
        );
        const testNotification = getFromStorageNewVersion(TEST_NOTIFICATION_KEY_NAME);
        if (testNotification) {
          state.notificationUnreadLogs[testNotification.attributes.notificationId] = [testNotification];
        }
        state.isNotificationUnreadLogsLoading = false;
      })
      .addCase(fetchNotificationUnreadLogs.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isNotificationUnreadLogsLoading = false;
      });
    // get history logs
    builder
      .addCase(fetchNotificationHistoryLogs.pending, state => {
        state.error = null;
        state.isHistoryLogsLoading = true;
      })
      .addCase(fetchNotificationHistoryLogs.fulfilled, (state, { payload }: { payload: NotificationLogsJSONApi }) => {
        state.historyLogs = payload.data;
        state.isHistoryLogsLoading = false;

        if (!payload.data.length) {
          state.historyLogsTotal = -1;
        } else {
          state.historyLogsTotal = payload.meta.total;
        }
      })
      .addCase(fetchNotificationHistoryLogs.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isHistoryLogsLoading = false;
      });
    // get history logs lazy
    builder
      .addCase(fetchNotificationHistoryLogsLazy.pending, state => {
        state.error = null;
        state.isHistoryLogsLoading = true;
      })
      .addCase(
        fetchNotificationHistoryLogsLazy.fulfilled,
        (state, { payload }: { payload: NotificationLogsJSONApi }) => {
          const markerLog = state.markerNotificationLog;

          if (markerLog) {
            state.historyLogs = [...state.historyLogs, ...payload.data.filter(log => log.id !== markerLog.id)];
          } else {
            state.historyLogs = [...state.historyLogs, ...payload.data];
          }
          state.historyLogsTotal = payload.meta.total;
          state.isHistoryLogsLoading = false;
        }
      )
      .addCase(fetchNotificationHistoryLogsLazy.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isHistoryLogsLoading = false;
      });
    // get counts unread logs for footer
    builder
      .addCase(fetchCountUnreadLogs.pending, state => {
        state.error = null;
        state.countUnreadLogs = [];
      })
      .addCase(fetchCountUnreadLogs.fulfilled, (state, action) => {
        state.countUnreadLogs = action.payload.data;
        state.isMarkerNotificationLogLoading = false;
      })
      .addCase(fetchCountUnreadLogs.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isMarkerNotificationLogLoading = false;
      });
    // update isDone of current log
    builder
      .addCase(updateIsDoneLog.pending, state => {
        state.isDoneLogIsUpdating = true;
        state.error = null;
      })
      .addCase(updateIsDoneLog.fulfilled, state => {
        state.isDoneLogIsUpdating = false;
      })
      .addCase(updateIsDoneLog.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isDoneLogIsUpdating = false;
      });
    // get all logs for current notification
    builder
      .addCase(fetchChosenNotificationLogs.pending, state => {
        state.isChosenNotificationLogsLoading = true;
        state.error = null;
      })
      .addCase(fetchChosenNotificationLogs.fulfilled, (state, { payload }: { payload: NotificationLogsJSONApi }) => {
        state.isChosenNotificationLogsLoading = false;
        state.chosenNotificationLogs = payload.data.map(log => {
          const transportType = log.attributes.transportType;
          const employeePosition = log.attributes.employeePosition;
          let parsedData: HandbookTranslatedFieldType | string | null = null;

          if (transportType) {
            try {
              parsedData = JSON.parse(transportType as string);
            } catch (error) {
              parsedData = transportType;
            }
          }
          if (employeePosition) {
            try {
              parsedData = JSON.parse(employeePosition as string);
            } catch (error) {
              parsedData = employeePosition;
            }
          }
          return {
            ...log,
            attributes: {
              ...log.attributes,
              transportType: transportType ? parsedData : null,
              employeePosition: employeePosition ? parsedData : null,
            },
          };
        });
      })
      .addCase(fetchChosenNotificationLogs.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenNotificationLogsLoading = false;
      });
  },
});

export const {
  setNotificationsGroups,
  changeNotificationGroup,
  toggleExpandAllNotificationGroups,
  toggleCheckedAllNotificationGroups,

  setUsersNotificationsState,

  setSelectedNotifications,
  toggleCheckedNotification,

  setChosenNotificationType,
  removeChosenNotification,

  turnNotificationInList,
  setTurnedNotifications,
  turnAllNotificationsInList,

  setMarkerNotificationLog,
  removeMarkerNotificationLog,
  setClusterNotificationLogsIds,
  removeClusterNotificationLogs,
  removeChosenNotificationLogs,
  setTestMarkerNotificationLog,

  decreaseCountUnreadLogs,
  deleteNotificationLogById,

  updateListConfig,

  setSort,

  addLogToNotificationAndIncreaseCount,
  addLogToNotificationAndIncreaseCountArray,

  setHistoryLogs,
  setSelectedHistoryLogs,
  setIsTableHistoryLogsShow,
  setFilterField,
  setFilterFields,
  setNotificationHistoryTotal,
  clearHistoryLogsFilter,
  clearHistoryLogs,

  addTestNotification,
  removeTestNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
