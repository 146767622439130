import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';

import styles from './cardButtons.module.scss';

type Props = {
  isEditMode: boolean; // create new mode if false
  allowCreate?: boolean;
  allowUpdate?: boolean;
  allowDelete?: boolean;
  handleSubmitClick: () => void;
  handleCancelClick: () => void;
  handleDeleteClick: () => void;
};

const messages = {
  buttonText: (action: string) => `handbooks.column.elements-card.footer.btn.${action}.text`,
};

const CardButtons: React.VFC<Props> = ({
  isEditMode,
  allowCreate = true,
  allowUpdate = true,
  allowDelete = true,
  handleSubmitClick,
  handleCancelClick,
  handleDeleteClick,
}) => {
  const t = useFormatMessage();

  const disabledSave = isEditMode ? !allowUpdate : !allowCreate;
  const disabledDelete = !isEditMode || !allowDelete;

  return (
    <div className={styles.container}>
      <Button red text={t(messages.buttonText('delete'))} onClick={handleDeleteClick} disabled={disabledDelete} />
      <Button white text={t(messages.buttonText('cancel'))} onClick={handleCancelClick} />
      <Button
        blue
        text={isEditMode ? t(messages.buttonText('update')) : t(messages.buttonText('save'))}
        onClick={handleSubmitClick}
        disabled={disabledSave}
      />
    </div>
  );
};

export default CardButtons;
