import { useState } from 'react';
import { SortEndHandler } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { TreeGroupConfigItem } from 'utils/treeGroupBy/treeGroupBy';

export interface TreeGroupConfigViewItem<TItem extends Record<string, unknown>> extends TreeGroupConfigItem<TItem> {
  label: string;
  canLeft: boolean;
  canRight: boolean;
}

export default function useTreeGroupConfig<TItem extends Record<string, unknown>>(
  initialConfig: TreeGroupConfigItem<TItem>[],
  configLabels: string[]
) {
  const [config, setConfig] = useState<TreeGroupConfigViewItem<TItem>[]>(() =>
    initialConfig.map((item, index, arr) => ({
      ...item,
      label: configLabels[index],
      canLeft: index !== 0 && item.offset !== 0,
      canRight: index !== 0 && item.offset <= arr[index - 1].offset,
    }))
  );

  const configToSave: TreeGroupConfigItem<TItem>[] = config.map(item => ({
    groupBy: item.groupBy,
    offset: item.offset,
    isActive: item.isActive,
  }));

  const handleItemCheck = (index: number, checkNested = false) => {
    const offset = config[index].offset;
    const nextValue = !config[index].isActive;

    const nextConfig = [...config];
    nextConfig[index].isActive = nextValue;

    if (checkNested) {
      for (let i = index + 1; i < nextConfig.length; i++) {
        if (nextConfig[i].offset <= offset) {
          break;
        }

        nextConfig[i].isActive = nextValue;
      }
    }

    setConfig(nextConfig);
  };

  const handleLeftClick = (index: number) => {
    if (config[index].canLeft) {
      const offset = config[index].offset;
      const nextConfig = [...config];

      nextConfig[index].offset -= 1;
      nextConfig[index].canLeft = offset > 1;
      nextConfig[index].canRight = true;

      // shift nested items
      for (let i = index + 1; i < nextConfig.length; i++) {
        if (nextConfig[i].offset === offset) {
          nextConfig[i].canRight = i > index + 1;
          break;
        }

        if (nextConfig[i].offset < offset) {
          break;
        }

        nextConfig[i].offset -= 1;
      }

      setConfig(nextConfig);
    }
  };

  const handleRightClick = (index: number) => {
    if (config[index].canRight) {
      const offset = config[index].offset;
      const nextConfig = [...config];

      nextConfig[index].offset += 1;
      nextConfig[index].canLeft = true;
      nextConfig[index].canRight = config[index - 1].offset > offset;

      // shift nested items
      for (let i = index + 1; i < nextConfig.length; i++) {
        if (nextConfig[i].offset <= offset) {
          break;
        }

        nextConfig[i].offset += 1;
      }

      setConfig(nextConfig);
    }
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    // move config item to new position
    const nextConfig = arrayMove(config, oldIndex, newIndex);

    // keep nest level the same
    for (let i = 0; i < nextConfig.length; i++) {
      nextConfig[i] = {
        ...nextConfig[i],
        offset: config[i].offset,
        canLeft: config[i].canLeft,
        canRight: config[i].canRight,
      };
    }

    setConfig(nextConfig);
  };

  return [config, configToSave, handleItemCheck, handleLeftClick, handleRightClick, handleSortEnd] as const;
}
