import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { MESSAGE_TYPES_TO_TRANSLATE, MESSAGE_TYPES } from 'components/messages/utils/consts';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';

import { RootState } from 'reducers';
import { setMessagesFiltersField } from 'reducers/messages';
import { fetchUnits } from 'reducers/trackableUnits';

export default function useMessagesFilter() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const { trackableUnits, isLoading: isTrackableUnitsLoading } = useSelector((state: RootState) => state.trackableUnit);

  const messageFilterObjectsForSelect: ValuesType = useMemo(
    () =>
      trackableUnits.map(unit => ({ value: unit.id, label: unit.attributes.aggregatedName, data: { id: unit.id } })),
    [trackableUnits]
  );
  const messageFilterTypesForSelect: ValuesType = Object.keys(MESSAGE_TYPES).map(key => ({
    value: key,
    label: t(MESSAGE_TYPES_TO_TRANSLATE[key as keyof typeof MESSAGE_TYPES_TO_TRANSLATE]),
  }));

  const periodFromRef = React.createRef();
  const periodToRef = React.createRef();

  useEffect(() => {
    dispatch(fetchUnits());
  }, [dispatch]);

  const handleChangeDates = (fromDate: string, toDate: string) => {
    dispatch(setMessagesFiltersField({ key: 'dateStart', value: fromDate }));
    dispatch(setMessagesFiltersField({ key: 'dateEnd', value: toDate }));
  };

  const handleChangeObjects = (values: ValuesType) => {
    const valuesIds = values.map(val => val.value);
    const filteredUnits = trackableUnits.filter(unit => valuesIds.includes(unit.id)).map(unit => unit.id);

    dispatch(setMessagesFiltersField({ key: 'trackableUnitsIds', value: filteredUnits }));
  };

  const handleChangeType = (values: ValuesType) => {
    const filterValues: MESSAGE_TYPES[] = values.map(val => val.value as MESSAGE_TYPES);

    dispatch(
      setMessagesFiltersField({
        key: 'messageTypes',
        value: filterValues,
      })
    );
  };

  return {
    states: {
      messageFilterObjectsForSelect,
      messageFilterTypesForSelect,
      isTrackableUnitsLoading,
    },
    refs: {
      periodFromRef,
      periodToRef,
    },
    handlers: {
      handleChangeDates,
      handleChangeObjects,
      handleChangeType,
    },
  };
}
