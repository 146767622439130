import { useEffect, useState } from 'react';

import {
  defaultHandBookElementData as defaults,
  HANDBOOK_ENTITY_ATTRIBUTES_MAIN_FIELDS,
} from 'components/handbooks/utils/consts';
import { AdditionalFieldsType } from 'components/handbooks/utils/types';
import { DropButtonsType } from 'components/common/dropbutton/dropbutton';

import { HandbookEntity, HandbookTranslatedFieldType } from 'reducers/handbooks/interface';

import { LanguagesKeysType, DEFAULT_LANGUAGES, LANGUAGES_TO_TEXT_ENUM } from 'translate';

function getDefaultDropButtons(entityLng: HandbookTranslatedFieldType): DropButtonsType {
  return Object.keys(LANGUAGES_TO_TEXT_ENUM)
    .map(lng => ({
      label: `handbooks.add-translation.option.text.${lng}`,
      value: lng,
    }))
    .filter(btn => btn.value !== DEFAULT_LANGUAGES && !entityLng.hasOwnProperty(btn.value));
}

export default function useHandbookElementData(entity: HandbookEntity | null) {
  const [name, setName] = useState<HandbookTranslatedFieldType>(defaults.name);
  const [translateKeys, setTranslateKeys] = useState<LanguagesKeysType[]>([DEFAULT_LANGUAGES]);
  const [dropButtons, setDropButtons] = useState<DropButtonsType>(() => getDefaultDropButtons(LANGUAGES_TO_TEXT_ENUM));
  const [attributesAdditionalFields, setAttributesAdditionalFields] = useState<AdditionalFieldsType>({});
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // определение основного поля name и additionals-полей
  useEffect(() => {
    if (entity) {
      const newAdditionalFields = Object.keys(entity.attributes).reduce((prev, curr) => {
        if (!HANDBOOK_ENTITY_ATTRIBUTES_MAIN_FIELDS.includes(curr)) {
          prev[curr] = entity.attributes[curr];
        }
        return prev;
      }, {} as AdditionalFieldsType);
      setName(entity.attributes.name);
      setAttributesAdditionalFields({ ...newAdditionalFields });
      setDropButtons(getDefaultDropButtons(entity.attributes.name));
    }
  }, [entity]);

  const handleChangeName = (key: LanguagesKeysType, value: string) =>
    setName({
      ...name,
      [key]: value,
    });

  const handleChooseTranslate = (key: string) => {
    if (!translateKeys.includes(key as LanguagesKeysType)) {
      setTranslateKeys([...translateKeys, key as LanguagesKeysType]);
    }

    const foundDropValueIndex = dropButtons.findIndex(btn => btn.value === key);

    if (foundDropValueIndex > -1) {
      const newDropButtons = [...dropButtons];

      newDropButtons.splice(foundDropValueIndex, 1);
      setDropButtons(newDropButtons);
      handleChangeName(key as LanguagesKeysType, '');
    }
  };

  const handleChangeAdditionalFields = ({ key, value }: { key: string; value: string | number }) => {
    setAttributesAdditionalFields({ ...attributesAdditionalFields, [key]: value });
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!name || !name[DEFAULT_LANGUAGES]?.length) {
      newInvalidFields.push('elementData_name');
    }
    if (
      attributesAdditionalFields.hasOwnProperty('inn') &&
      ((typeof attributesAdditionalFields.inn === 'string' && attributesAdditionalFields.inn?.length !== 10) ||
        isNaN(Number(attributesAdditionalFields.inn)))
    ) {
      newInvalidFields.push('elementData_inn');
    }
    if (attributesAdditionalFields.hasOwnProperty('organizationForm') && !attributesAdditionalFields.organizationForm) {
      newInvalidFields.push('elementData_organizationForm');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    if (entity) {
      for (const key in attributesAdditionalFields) {
        if (
          attributesAdditionalFields.hasOwnProperty(key) &&
          attributesAdditionalFields[key] !== entity.attributes[key]
        ) {
          return true;
        }
      }
      return name !== entity.attributes.name;
    }
    return false;
  };

  return {
    states: {
      name,
      dropButtons,
      attributesAdditionalFields,
    },
    handlers: {
      handleChangeName,
      handleChooseTranslate,
      handleChangeAdditionalFields,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
