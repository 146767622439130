import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import turfTransformRotate from '@turf/transform-rotate';

import { Feature } from 'ol';
import GeometryType from 'ol/geom/GeometryType';
import { Geometry, Point, Polygon } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

// углы в радианы
export function degToRad(deg: number) {
  const rad = deg * (Math.PI / 180.0);

  return isNaN(rad) ? 0 : rad;
}

// радианы в углы
export function radToDeg(rad: number) {
  const deg = rad * (180.0 / Math.PI);

  return isNaN(deg) ? 0 : deg;
}

// функция определения угла поворота между двумя радиус-векторами.
// Возвращает угол в радианах
export function getAngleBetweenRadiusVectors(rVectorA: number[], rVectorB: number[]) {
  if (rVectorA.length === 2 && rVectorB.length === 2) {
    // cos(α) = (a * b) / (|a| * |b|)
    const cosA =
      (rVectorA[0] * rVectorB[0] + rVectorA[1] * rVectorB[1]) /
      (Math.sqrt(rVectorA[0] ** 2 + rVectorA[1] ** 2) * Math.sqrt(rVectorB[0] ** 2 + rVectorB[1] ** 2));

    return cosA;
  }

  return 0;
}

// функция определения направление поворота
// n= (B-A)x(C-B), где x - перекрестное произведение
// Т.е. для векторов n = ((B.x-A.x)*(C.y-B.y)) - ((B.y-A.y)*(C.x-B.x))
// Здесь т.А - центр coords, т.В - feature coords, т.С - dragging coords
// Т.о. n определяет направление, n > 0 - против часовой (return 1), n < 0 - по часовой (return -1),
// n = 0 (return 0), тогда точки B и C либо противоположны, либо идентичны, поэтому направление не имеет значения
export function getDirectionOfRotation(centerA: number[], rVectorFeature: number[], rVectorDraggingFeature: number[]) {
  if (centerA.length === 2 && rVectorFeature.length === 2 && rVectorDraggingFeature.length === 2) {
    const A = {
      x: centerA[0],
      y: centerA[1],
    };
    const B = {
      x: rVectorFeature[0],
      y: rVectorFeature[1],
    };
    const C = {
      x: rVectorDraggingFeature[0],
      y: rVectorDraggingFeature[1],
    };

    const edge = (B.x - A.x) * (C.y - B.y) - (B.y - A.y) * (C.x - B.x);

    return edge > 0 ? 1 : -1;
  }
  return 0;
}

// функция определения принадлежности точки в полигоне.
// Полигон может быть выпуклым или вогнутым. Функция учитывает дыры.
// Возвращает true, если точка находится внутри полигона и
// false, если точка не находится внутри многоугольника
export function getBooleanPointInPolygon(point: Feature, polygon: Feature) {
  if (
    point.getGeometry()?.getType() === GeometryType.POINT &&
    polygon.getGeometry()?.getType() === GeometryType.POLYGON
  ) {
    const pointCoords = (point.getGeometry() as Point).getCoordinates();
    const polygonCoords = (polygon.getGeometry() as Polygon).getCoordinates();
    const pointLonLatCoords = toLonLat(pointCoords);
    const polygonLonLatCoords = [polygonCoords[0].map(coord => toLonLat(coord))];
    const turfedPoint = turfPoint(pointLonLatCoords);
    const turfedPolygon = turfPolygon(polygonLonLatCoords);

    return turfBooleanPointInPolygon(turfedPoint, turfedPolygon);
  }
  return false;
}

// функция поворачивающая point относительно координаты pivot-точки,
// возвращает координаты вращающей точки
export function getRotatedPointCoordinates(pointGeometry: Point, angleDeg: number, options?: { pivot: Coordinate }) {
  const pointCoordinates = pointGeometry.getCoordinates();
  const turfedPoint = turfPoint(toLonLat(pointCoordinates));
  const rotatedTurfPoint = turfTransformRotate(turfedPoint, angleDeg, options);

  return fromLonLat(rotatedTurfPoint.geometry.coordinates);
}

// функция получения координат вращаемой геометрии полигона
export function getRotatedPolygonCoordinates(
  polygonGeometry: Geometry,
  angleDeg: number,
  options?: { pivot: Coordinate }
) {
  let polygonCoordinates = (polygonGeometry as Polygon).getCoordinates();

  if (polygonCoordinates?.length) {
    const turfedPolygon = turfPolygon([polygonCoordinates[0].map(coords => toLonLat(coords))]);
    const rotatedTurfedPolygon = turfTransformRotate(turfedPolygon, angleDeg, options);
    const rotatedTurfedPolygonCoordinates = rotatedTurfedPolygon.geometry.coordinates;

    if (rotatedTurfedPolygonCoordinates.length) {
      const newCoords = rotatedTurfedPolygonCoordinates[0].map(coords => fromLonLat(coords));

      polygonCoordinates = [newCoords];
    }
  }
  return polygonCoordinates;
}

// индикация окончания отрисовки карты (для отчета)

export enum ReportRenderFinishType {
  success = 'success',
  error = 'error',
}

enum ReportBeaconMsg {
  ReportRenderFinish = 'report-render-finish',
  ReportRenderError = 'report-render-error',
}

export function resultBeacon(type: ReportRenderFinishType, text = 'Render page error') {
  switch (type) {
    case ReportRenderFinishType.success:
      // eslint-disable-next-line no-console
      console.log(ReportBeaconMsg.ReportRenderFinish);
      break;

    case ReportRenderFinishType.error:
      // eslint-disable-next-line no-console
      console.log(`${ReportBeaconMsg.ReportRenderError}: ${text}`);
      break;

    default:
      break;
  }
}
