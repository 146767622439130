import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Button from 'components/common/button/button';
import { Alert } from 'components/common/alert/alert';
import { Spinner } from 'components/common/spinner/spinner';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { MESSAGE_TYPES_TO_TRANSLATE } from 'components/messages/utils/consts';
import { TablePagination } from './components/tablePagination/tablePagination';
import { MessagesTableMainStates, MessagesTableMainHandlers } from 'components/messages/utils/types';
import MessagesPopup from 'components/map/components/messagesPopup/messagesPopup';

import useMessagesTableMain from './hooks/useMessagesTableMain';
import useMessageTablePagination from './hooks/useMessageTablePagination';

import { RootState } from 'reducers';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';

import styles from './messagesTable.module.scss';

const cx = classNames.bind(styles);

function MessagesTable() {
  const t = useFormatMessage();

  const { isMessagesLoading, included, messages, selectedMessages, pagination } = useSelector(
    (state: RootState) => state.messages
  );

  const { page = 1, limit = 1 } = pagination;

  const {
    states: mainStates,
    handlers: mainHandlers,
  }: { states: MessagesTableMainStates; handlers: MessagesTableMainHandlers } = useMessagesTableMain();
  const { handlers: paginationHandlers } = useMessageTablePagination();

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h4 className={styles.headerTitle}>{t('messages.table.header.label')}</h4>
        </div>
        <div className={styles.table}>
          <div className={cx(styles.tableRow, styles.tableHeader)}>
            <Checkbox
              checked={mainStates.headCheckboxStatus}
              isIndeterminate={mainStates.headCheckboxStatusIndeterminate}
              handleCheck={mainHandlers.handleCheckAll}
            />
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
              {t('messages.table.header.number.text')}
            </div>
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>{t('messages.table.header.date.text')}</div>
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
              {t('messages.table.header.address.text')}
            </div>
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
              {t('messages.table.header.tracker-number.text')}
            </div>
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>{t('messages.table.header.type.text')}</div>
            <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
              {t('messages.table.header.message.text')}
            </div>
          </div>
          {isMessagesLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            <ul className={styles.tableContent}>
              {messages.map((message, i) => {
                const attr = message.attributes;
                const trackableUnitsIds = message.relationships.trackableUnits.data.map(unit => unit.id);
                const trackableUnits = included.filter(unit => trackableUnitsIds.includes(unit.id));
                const fullDate = getFullDateFromStr(attr.createdAt);

                return (
                  <li className={styles.tableRow} key={`table-message-${message.id}-${i}`}>
                    <Checkbox
                      checked={selectedMessages.includes(message.id)}
                      handleCheck={() => mainHandlers.handleCheckItem(message.id)}
                    />
                    <div className={styles.tableColumn}>{i + 1 + (page - 1) * limit}</div>
                    <div className={styles.tableColumn}>
                      <div>{fullDate.date}</div>
                      <div>{fullDate.time}</div>
                    </div>
                    <div
                      className={cx(styles.tableColumn, styles.tableColumnName)}
                      onClick={e => mainHandlers.handleClickName(e, message.id)}
                    >
                      {trackableUnits.map(unit => unit.attributes.aggregatedName).join(',')}
                    </div>
                    <div className={styles.tableColumn}>
                      {trackableUnits.map(unit => unit.attributes.trackerNumber).join(',')}
                    </div>
                    <div className={styles.tableColumn}>
                      {attr.messageType ? t(MESSAGE_TYPES_TO_TRANSLATE[attr.messageType]) : ''}
                    </div>
                    <div className={styles.tableColumn}>{attr.body}</div>
                  </li>
                );
              })}
            </ul>
          )}
          <TablePagination handlers={paginationHandlers} />
        </div>
        <div className={styles.footer}>
          <Button
            red
            text={t('messages.table.footer.btn.delete.text')}
            onClick={mainHandlers.handleDeleteItems}
            customStyle={cx(styles.button, styles.buttonCreate)}
            disabled={!selectedMessages.length}
          />
          <Button
            white
            text={t('messages.table.footer.btn.save.text')}
            onClick={mainHandlers.handleSave}
            customStyle={cx(styles.button, styles.buttonCreate)}
          />
          <Button
            white
            text={t('messages.table.footer.btn.close.text')}
            onClick={mainHandlers.handleClose}
            customStyle={cx(styles.button, styles.buttonCreate)}
          />
        </div>

        {!!mainStates.deletedIds.length && (
          <Alert
            title={t('messages.list.template-list.array.alert.delete.title')}
            infoText={t('messages.list.template-list.array.alert.delete.text')}
            handleCancel={mainHandlers.handleDeleteAlertCancel}
            handleContinue={mainHandlers.handleDeleteAlertContinue}
          />
        )}
      </div>

      {mainStates.isPopupShow && (
        <MessagesPopup
          onCancel={mainHandlers.handleClosePopup}
          markerCoords={{ mapCoords: { x: 0, y: 0 }, mouseCoords: mainStates.mouseCoords }}
        />
      )}
    </Fragment>
  );
}

export default MessagesTable;
