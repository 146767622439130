import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDangerZones, getDangerZonesEn } from './api';
import { LanguagesKeysType } from '../../translate';

const withHeatMap = process.env.REACT_APP_ADDON === 'HEAT_MAP' || process.env.REACT_APP_ENV === 'HEAT_MAP';

const initialState = {
  withHeatMap,
  dangerZonesData: {},
};

export const fetchDangerZones = createAsyncThunk('AddonsSlice/fetchHeatMap', async (locale: LanguagesKeysType) => {
  switch (locale) {
    case 'ru':
      return getDangerZones();
    case 'en':
    default:
      return getDangerZonesEn();
  }
});

const addonsSlice = createSlice({
  name: 'AddonsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDangerZones.fulfilled, (state, action) => {
      state.dangerZonesData = action.payload;
    });
  },
});

export default addonsSlice.reducer;
