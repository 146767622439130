import React, { useEffect, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { MonitoringUnitsTree } from 'components/monitoring/utils/MonitoringUnitsTree';
import { Spinner } from 'components/common/spinner/spinner';
import { defaultMonitoringListConfig } from 'components/monitoring/utils/consts';
import ListHeader from './components/listHeader/listHeader';
import ListUnits from './components/listUnits/listUnits';

import { RootState } from 'reducers';
import { fetchUnits } from 'reducers/trackableUnits';
import { updateListConfig } from 'reducers/monitoring';

import { getCurrentLocale } from 'translate';

import styles from './monitoringList.module.scss';

const MonitoringList: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const showContactsRoute = useSelector((state: RootState) => state.map.showContactsRoute);
  const contactsIds = useSelector((state: RootState) => state.map.contactsIds);

  const search = useSelector((state: RootState) => state.trackableUnit.search);
  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);
  const searchedUnits = useSelector((state: RootState) => state.trackableUnit.searchedUnits);
  const updateCounter = useSelector((state: RootState) => state.trackableUnit.updateCounter);
  const isLoading = useSelector((state: RootState) => state.trackableUnit.isLoading);

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);
  const userLanguageKey = getCurrentLocale(locale);

  const handbooksData = useSelector((state: RootState) => state.handbooks.data);
  const hierarchyItems = {
    organizations: handbooksData.organizations,
    departments: handbooksData.departments,
    positions: handbooksData.positions,
  };

  const listConfigUserPreferences = useSelector((state: RootState) => state.user.userPreferences.monitoringListConfig);

  useEffect(() => {
    if (listConfigUserPreferences?.length) {
      dispatch(updateListConfig(listConfigUserPreferences));
    } else {
      dispatch(updateListConfig(defaultMonitoringListConfig));
    }
  }, [dispatch, listConfigUserPreferences]);

  useEffect(() => {
    dispatch(fetchUnits());
  }, [dispatch, updateCounter]);

  const monitoringUnitsTree = useMemo(() => {
    const monitoringTree = new MonitoringUnitsTree(hierarchyItems, listConfigUserPreferences, t, userLanguageKey);
    monitoringTree.mapTrackableUnits();

    if (showContactsRoute) {
      const showingUnits = trackableUnits.filter(u => contactsIds.includes(u.id));
      monitoringTree.setUnits(showingUnits);
    } else if (search) {
      monitoringTree.setUnits(searchedUnits);
    } else {
      monitoringTree.setUnits(trackableUnits);
    }

    monitoringTree.mapTrackableUnits();
    return monitoringTree.getMonitoringTree();
  }, [
    t,
    trackableUnits,
    showContactsRoute,
    search,
    searchedUnits,
    listConfigUserPreferences,
    contactsIds,
    hierarchyItems,
    userLanguageKey,
  ]);

  const hasTree = listConfigUserPreferences.some(c => c.isActive);

  return (
    <div className={styles.monitoringList}>
      <ListHeader showExpand={hasTree} monitoringUnitsTree={monitoringUnitsTree} />
      {isLoading ? <Spinner /> : <ListUnits hasTree={hasTree} monitoringUnitsTree={monitoringUnitsTree} />}
    </div>
  );
};

export default React.memo(MonitoringList);
