import React, { ChangeEvent, Dispatch, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as CloseIcon } from 'assets/img/delete-photo-icon.svg';
import DefaultPhoto from 'assets/img/photo-default.png';
import DefaultPhotoTransport from 'assets/img/photo-transport-default.png';

import { MAX_UPLOAD_FILE_SIZE } from 'utils/consts';

import styles from './photo.module.scss';

const cx = classNames.bind(styles);

interface PhotoComponentProps {
  src: string | null;
  onSetIcon: Dispatch<string | null>;
  isTransport?: boolean;
}

const MAX_PHOTO_SIZE = 1024; // px

export const PhotoComponent = (props: PhotoComponentProps) => {
  const t = useFormatMessage();

  const [preview, setPreview] = useState<string>('');

  const defaultPhotoValue = props.isTransport ? DefaultPhotoTransport : DefaultPhoto;

  useEffect(() => {
    if (props.src) {
      setPreview(props.src);
    }
  }, [props.src]);

  const cancelAll = () => {
    props.onSetIcon(null);
    setPreview('');
  };

  const selectImage = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const file = e.target.files?.[0];

    if (!!file && file?.size > MAX_UPLOAD_FILE_SIZE) {
      return toast.warn(t('photo.upload.error.size.text'));
    }

    reader.onloadend = () => {
      if (reader.readyState === reader.DONE && reader.result) {
        const iconBase64 = reader.result as string;
        const tempImage = document.createElement('img');

        tempImage.src = iconBase64;
        tempImage.onload = () => {
          const maxValue = Math.max(tempImage.width, tempImage.height);

          if (maxValue > MAX_PHOTO_SIZE) {
            return toast.warn(t('photo.upload.error.bad-img-size.text'));
          }

          setPreview(iconBase64);
          props.onSetIcon(iconBase64);
        };
      } else {
        toast.error(t('photo.upload.error'));
        cancelAll();
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{t('photo.header.text')}</div>
      <div className={styles.content}>
        {!!preview && <CloseIcon className={styles.closeBtn} onClick={cancelAll} />}
        <input
          type="file"
          className={styles.inputFile}
          onChange={selectImage}
          accept="image/png, image/jpg, image/jpeg"
          id="photoInput"
        />
        <label
          htmlFor="photoInput"
          className={cx(styles.inputFileLabel, {
            [styles.inputFileLabelDefault]: !preview,
          })}
        >
          <img src={preview || defaultPhotoValue} alt={t('photo.header.text')} className={styles.img} />
        </label>
      </div>
    </div>
  );
};
