export const mockField = {
  label: 'Название поля',
  value: '',
  visible: true,
};

export const defaultMonitoringListConfig = [
  {
    id: 1,
    name: 'monitoring.list-group-modal.list-config.company.text',
    idField: 'organizationId',
    keyName: 'organizations',
    isActive: false,
    emptyTitle: 'monitoring.list-group-modal.list-config.company.empty.text',
    childIndexes: [1, 2],
    parentIndexes: [],
  },
  {
    id: 2,
    name: 'monitoring.list-group-modal.list-config.subdivision.text',
    idField: 'departmentId',
    keyName: 'departments',
    isActive: false,
    emptyTitle: 'monitoring.list-group-modal.list-config.subdivision.empty.text',
    childIndexes: [2],
    parentIndexes: [0],
  },
  {
    id: 3,
    name: 'monitoring.list-group-modal.list-config.position.text',
    idField: 'positionId',
    keyName: 'positions',
    isActive: false,
    emptyTitle: 'monitoring.list-group-modal.list-config.position.empty.text',
    childIndexes: [],
    parentIndexes: [0, 1],
  },
];

export enum TRACKABLE_UNIT_RELATIONSHIP_TYPES {
  batteryCharge = 'batteryCharge',
  signalLevel = 'signalLevel',
  isActive = 'isActive',
}
