import React from 'react';
import styles from './switch.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface SwitchProps {
  checked: boolean;
  isIndeterminate?: boolean;
  onChange?: () => void;
  title?: string;
  containerStyle?: string;
  disabled?: boolean;
}

const Switch = ({ checked, isIndeterminate, onChange, title, containerStyle, disabled }: SwitchProps) => {
  const containerClasses = cx(styles.container, containerStyle, {
    [styles.containerDisabled]: disabled,
    [styles.containerIndeterminate]: isIndeterminate,
  });

  return (
    <div className={containerClasses} onClick={onChange}>
      <label className={styles.switch}>
        <input type="checkbox" checked={checked ?? false} disabled readOnly />
        <span className={styles.slider}></span>
      </label>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default Switch;
