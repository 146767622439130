import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';
import { ChosenPoi, PoiAccidentPoint } from 'reducers/poi/interface';

import { Option } from 'components/common/select/select';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { getCurrentLocale } from 'translate';

import { defaultPoiAccidentState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardAccidentData } from '../utils/types';

export default function usePoiAccident(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardAccidentData);

  const [createdDate, setCreatedDate] = useState(new Date().toISOString());
  const [poiAccidentTypeIdForSelect, setPoiAccidentTypeIdForSelect] = useState<Option[]>([]);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const activePeriodRef = React.createRef();

  useEffect(() => {
    const handbookAccidentTypes = handbookData?.poiAccidentTypes;

    if (handbookAccidentTypes) {
      setPoiAccidentTypeIdForSelect(
        handbookAccidentTypes.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
    }
  }, [handbookData, userLanguageKey]);

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiAccidentPoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiAccidentPoint = relationships.poiAccidentPoint.data as PoiAccidentPoint;

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          poiAccidentTypeId: poiFields.poiAccidentTypeId,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          activePeriod: poiFields.activePeriod,
          isRemovedInTheEnd: poiFields.isRemovedInTheEnd,
          createdDate: attributes.createdAt ?? new Date().toISOString(),
        })
      );

      if (attributes.createdAt) {
        setCreatedDate(attributes.createdAt);
      }
    }
  }, [dispatch, chosenPoi]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );

  const handleChangePoiAccidentTypeId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiAccidentTypeId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeCoordinates = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'coordinates',
        value,
      })
    );

  const handleChangeActivePeriod = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'activePeriod',
        value: date.toISOString(),
      })
    );

  const handleChangeIsRemovedInTheEnd = () =>
    dispatch(
      setPoiCardFieldData({
        key: 'isRemovedInTheEnd',
        value: !poiCardData.isRemovedInTheEnd,
      })
    );

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!poiCardData.poiAccidentTypeId) {
      newInvalidFields.push('poiAccidentTypeId');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiAccidentPoint = relationships.poiAccidentPoint.data as PoiAccidentPoint;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        poiAccidentTypeId: poiFields.poiAccidentTypeId,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        activePeriod: poiFields.activePeriod,
        isRemovedInTheEnd: poiFields.isRemovedInTheEnd,
        createdDate,
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      createdDate,
      poiAccidentTypeIdForSelect,
    },
    refs: {
      activePeriodRef,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePoiAccidentTypeId,
      handleChangeCoordinates,
      handleChangeActivePeriod,
      handleChangeIsRemovedInTheEnd,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
