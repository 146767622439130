import React, { ReactElement, PropsWithChildren } from 'react';
import { SortableElement, SortableContainer, SortableHandle, SortEndHandler } from 'react-sortable-hoc';

import { ReactComponent as DragIcon } from 'assets/img/three-lines-drag.svg';
import { ReactComponent as OpenedEyeIcon } from 'assets/img/opened-eye.svg';
import { ReactComponent as ClosedEyeIcon } from 'assets/img/closed-eye.svg';

import { VisibleColumnsConfigViewItem } from './hooks/useVisibleColumnsConfig';

import styles from './visibleColumnsConfigView.module.scss';

type Props<TItem extends Record<string, unknown>> = {
  config: VisibleColumnsConfigViewItem<TItem>[];
  handleIsVisibleClick: (index: number) => void;
  handleSortEnd: SortEndHandler;
};

type ConfigItemProps = {
  // we really don't care of item type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: VisibleColumnsConfigViewItem<any>;
  onIsVisibleClick: () => void;
};

const DragButton = SortableHandle(() => <DragIcon className={styles.dragIcon} />);

const ConfigItem = SortableElement(({ item, onIsVisibleClick }: ConfigItemProps) => {
  return (
    <div className={styles.configItem}>
      <DragButton />
      <div className={styles.configItemLabel}>{item.label}</div>
      {item.isVisible ? (
        <OpenedEyeIcon className={styles.eyeIcon} onClick={onIsVisibleClick} />
      ) : (
        <ClosedEyeIcon className={styles.eyeIcon} onClick={onIsVisibleClick} />
      )}
    </div>
  );
});

const ConfigContainer = SortableContainer(({ children }: PropsWithChildren<unknown>) => <div>{children}</div>);

function VisibleColumnsConfigView<TItem extends Record<string, unknown>>({
  config,
  handleIsVisibleClick,
  handleSortEnd,
}: Props<TItem>): ReactElement | null {
  return (
    <ConfigContainer onSortEnd={handleSortEnd} useDragHandle>
      {config.map((item, index) => (
        <ConfigItem
          key={index}
          index={index}
          item={item}
          onIsVisibleClick={() => {
            handleIsVisibleClick(index);
          }}
        />
      ))}
    </ConfigContainer>
  );
}

export default VisibleColumnsConfigView;
