import React, { useState, Dispatch, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import isWithinInterval from 'date-fns/isWithinInterval';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';

import { ModalLayout } from 'components/common/modalLayout/modalLayout';
import { ModalPagination } from '../pagination/pagination.modal';
import Input from 'components/common/input/input';
import Button from 'components/common/button/button';
import { ModalHeader } from '../../../common/modal/modalHeader/modalHeader';

import { RootState } from 'reducers';
import { openContactsRoute } from 'reducers/map';
import { selectSocialContactsUnits } from 'reducers/monitoring';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from './socialContactsMap.module.scss';

import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type ContactsRow = {
  id: string;
  date: string;
  time: string;
  trackerNumber: string;
  fio: string;
  zone: string;
  contactTime: string;
};

interface FilterProps {
  handleShowStat: () => void;
  startDate: Date;
  setStartDate: Dispatch<Date>;
  endDate: Date;
  setEndDate: Dispatch<Date>;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name, @typescript-eslint/no-unused-vars
const DatePickerCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <div>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        disabled
        iconClick={props.onClick}
        customStyle={styles.inputWrap}
      />
    </div>
  );
});

const FilterDate = ({ handleShowStat, setStartDate, startDate, endDate, setEndDate }: FilterProps) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <div className={styles.dateFilter}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date);
        }}
        customInput={<DatePickerCustomInput label={t('map-popup.social-contact.date-start.text')} />}
        locale={getCurrentLocaleForDatePicker(locale)}
      />
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={endDate}
        onChange={(date: Date) => {
          setEndDate(date);
        }}
        customInput={<DatePickerCustomInput label={t('map-popup.social-contact.date-end.text')} />}
        locale={getCurrentLocaleForDatePicker(locale)}
      />
      <Button blue onClick={handleShowStat} text={t('map-popup.social-contact.show.text')} />
    </div>
  );
};

export const SocialContactsMap = ({
  contacts,
  handleCancel,
}: {
  contacts: ContactsRow[] | null;
  handleCancel: () => void;
}) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [rows] = useState(contacts ?? []);
  const [length] = useState(rows.length);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [startDate, setStartDate] = useState<Date>(new Date(2020, 7, 1));
  const [endDate, setEndDate] = useState<Date>(new Date(2020, 7, 2));

  const [showingRows, setShowingRows] = useState<ContactsRow[]>(
    contacts?.filter(r => isWithinInterval(Date.parse(r.date), { start: startDate, end: endDate })) ?? []
  );

  const updateShowingRows = (newShowingRows: ContactsRow[]) => {
    setShowingRows(
      newShowingRows.filter(r => isWithinInterval(Date.parse(r.date), { start: startDate, end: endDate }))
    );
  };

  const handleShowStat = () => {
    const newShowingRows = rows.slice((page - 1) * limit, page * limit);
    updateShowingRows(newShowingRows);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    const newShowingRows = rows.slice((page - 1) * newLimit, page * limit);
    updateShowingRows(newShowingRows);
  };

  const increasePage = () => {
    if ((page - 1) * limit >= length) {
      return;
    }
    setPage(page + 1);
    const newShowingRows = rows.slice(page * limit, (page + 1) * limit);
    updateShowingRows(newShowingRows);
  };

  const decreasePage = () => {
    if (page === 1) {
      return;
    }
    setPage(page - 1);
    const newShowingRows = rows.slice((page - 2) * limit, (page - 1) * limit);
    updateShowingRows(newShowingRows);
  };

  const showContactsRoute = () => {
    dispatch(openContactsRoute(contacts ? contacts.map(c => c.id) : []));
    dispatch(selectSocialContactsUnits(contacts ? contacts.map(c => c.id) : []));
    handleCancel();
  };

  return (
    <ModalLayout>
      <ModalHeader title={t('map-popup.social-contact.map-contacts.text')} onCancel={handleCancel} />
      <div className={styles.modalBody}>
        <div className={styles.tabContent}>
          <FilterDate
            handleShowStat={handleShowStat}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <div className={styles.table}>
            <table className={styles.tableWrap}>
              <thead>
                <tr className={styles.tableHead}>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellFirst)}>
                    {t('map-popup.social-contact.table.date.text')}
                  </th>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellSecond)}>
                    {t('map-popup.social-contact.table.time.text')}
                  </th>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellThird)}>
                    {t('map-popup.social-contact.table.tracker-number.text')}
                  </th>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellFourth)}>
                    {t('map-popup.social-contact.table.fio.text')}
                  </th>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellFifth)}>
                    {t('map-popup.social-contact.table.zone.text')}
                  </th>
                  <th className={cx(styles.tableHeadCell, styles.tableHeadCellSixth)}>
                    {t('map-popup.social-contact.table.long-time.text')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {showingRows.map((row, index) => (
                  <tr key={`stat-object-${index}`} className={styles.tableRow}>
                    <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>
                      {format(Date.parse(row.date), 'dd.MM.yyyy')}
                    </td>
                    <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.time}</td>
                    <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.trackerNumber}</td>
                    <td className={styles.tableRowCell}>{row.fio}</td>
                    <td className={styles.tableRowCell}>{row.zone}</td>
                    <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.contactTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ModalPagination
            increasePage={increasePage}
            decreasePage={decreasePage}
            setLimit={handleLimitChange}
            limit={limit}
            withSelect
            page={page}
          />
        </div>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('map-popup.footer.btn.close.text')} onClick={handleCancel} />
        <Button white text={t('map-popup.footer.btn.show-map.text')} onClick={showContactsRoute} />
        <Button blue text={t('map-popup.footer.btn.save-file.text')} onClick={handleCancel} />
      </div>
    </ModalLayout>
  );
};
