import { Middleware } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';

import { RootState } from 'reducers';
import { putClientUserPreferences } from 'reducers/clientUserPreferences/api';
import { PutClientUserPreferencesRequestData } from 'reducers/clientUserPreferences/interface';
import { updateClientUserPreferences } from 'reducers/clientUserPreferences';

const debouncedPutClientUserPreferences = debounce(
  (data: PutClientUserPreferencesRequestData) => putClientUserPreferences(data),
  1500
);

export const saveClientUserPreferencesMiddleware: Middleware<Record<string, unknown>, RootState> =
  store => next => async action => {
    next(action);

    if (action.type === updateClientUserPreferences.type) {
      const afterState = store.getState();

      debouncedPutClientUserPreferences({
        clientMeta: JSON.stringify(afterState.clientUserPreferences),
      });
    }
  };
