import React from 'react';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';

import { Spinner } from 'components/common/spinner/spinner';
import { PoiItem } from './components/poiItem/poiItem';
import PoiListHeader from './components/poiListHeader/poiListHeader';

import styles from './poiList.module.scss';

export default function PoiList() {
  const { data: poiData, isDataLoading } = useSelector((state: RootState) => state.poi);

  return (
    <div className={styles.poiList}>
      <PoiListHeader showExpand={true} />
      {isDataLoading ? <Spinner /> : poiData.map((poi, i) => <PoiItem key={`poiListItem-${i}`} poi={poi} />)}
    </div>
  );
}
