import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import Employee from 'assets/img/employee-big.png';
import Transport from 'assets/img/transport-big.png';
import Sensor from 'assets/img/sensor-big.png';
import { ReactComponent as Warning } from 'assets/img/mdi_error.svg';

import { Spinner } from 'components/common/spinner/spinner';
import Button from 'components/common/button/button';
import { NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import MarkerPopup from 'components/common/markerPopup/markerPopup';

import { RootState } from 'reducers';
import {
  removeMarkerNotificationLog,
  updateIsDoneLog,
  setSelectedNotifications,
  decreaseCountUnreadLogs,
  deleteNotificationLogById,
  setSelectedHistoryLogs,
  setIsTableHistoryLogsShow,
  setHistoryLogs,
  setFilterField,
  removeTestNotification,
} from 'reducers/notifications';
import { TrackableUnitType } from 'reducers/trackableUnits/interface';

import { getCurrentLocale } from 'translate';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './notificationPopup.module.scss';
import { TEST_NOTIFICATION_ID } from 'utils/consts';

const cx = classNames.bind(styles);

const getPhoto = (type: TrackableUnitType) => {
  switch (type) {
    case 'employee':
      return Employee;
    case 'transport':
      return Transport;
    case 'sensor':
      return Sensor;
    default:
      break;
  }
};

export type NotificationLogPopupData = {
  nameNotification: string;
  aggregatedName: string;
  positionOrType: string;
  simOrRegNumber: string;
  notificationType: string;
  date: {
    date: string;
    shortTime: string;
    time: string;
  };
  geozone: {
    name: string;
    coords: string;
  };
  text: string;
  photo: string;
  trackerNumber: string;
  isDone: boolean;
} | null;

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
}

const INITIAL_POPUP_WIDTH = 400;

function NotificationPopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const {
    isMarkerNotificationLogLoading,
    markerNotificationLog,
    notifications,
    selectedNotifications,
    historyLogs,
    isTableHistoryLogsShow,
  } = useSelector((state: RootState) => state.notifications);

  const notificationLogPopupData: NotificationLogPopupData = useMemo(() => {
    if (!markerNotificationLog) {
      return null;
    }

    const notification = notifications.find(n => n.id === String(markerNotificationLog.attributes.notificationId));
    if (!notification) {
      return null;
    }

    const { attributes } = markerNotificationLog;
    const type = notifications.find(notification => notification.id === String(attributes.notificationId))?.attributes
      .notificationType;

    const employeePosition =
      attributes.employeePosition && typeof attributes.employeePosition !== 'string'
        ? getTranslateFromLanguageKey(attributes.employeePosition, userLanguageKey)
        : '';
    const transportType =
      attributes.transportType && typeof attributes.transportType !== 'string'
        ? getTranslateFromLanguageKey(attributes.transportType, userLanguageKey)
        : '';

    return {
      nameNotification: notification.attributes.name,
      aggregatedName: attributes.aggregatedName,
      positionOrType: employeePosition ?? transportType ?? null,
      transportRegNumber: attributes.transportRegNumber ?? '-',
      notificationType: type ? t(NOTIFICATION_TYPES_ENUM_NAMES[type]) : '-',
      simOrRegNumber: attributes.employeeSimNumber ?? attributes.transportRegNumber ?? '-',
      date: getFullDateFromStr(attributes.eventTime),
      geozone: {
        name: attributes.geozoneName,
        coords: attributes.coords.replace(',', ', '),
      },
      text: attributes.text,
      photo: getPhoto(attributes.trackableUnitType) ?? '',
      trackerNumber: attributes.trackerNumber,
      isDone: attributes.isDone,
      type,
    };
  }, [t, markerNotificationLog, notifications, userLanguageKey]);

  const handleShowOnMap = () => {
    if (markerNotificationLog && isTableHistoryLogsShow) {
      dispatch(setSelectedHistoryLogs([markerNotificationLog.id]));
      dispatch(setHistoryLogs([markerNotificationLog]));
      dispatch(setFilterField({ key: 'page', value: 1 }));
      dispatch(setIsTableHistoryLogsShow(false));
    }
  };

  const handleIsDone = async () => {
    if (markerNotificationLog) {
      if (markerNotificationLog.id === TEST_NOTIFICATION_ID) {
        dispatch(removeTestNotification());
      } else {
        await dispatch(updateIsDoneLog(markerNotificationLog.id));
        dispatch(
          deleteNotificationLogById({
            logId: markerNotificationLog.id,
            notificationId: String(markerNotificationLog.attributes.notificationId),
          })
        );
        if (selectedNotifications.length) {
          dispatch(
            setSelectedNotifications({
              ids: [...selectedNotifications],
              status: true,
            })
          );
          dispatch(decreaseCountUnreadLogs(String(markerNotificationLog.attributes.notificationId)));
        }
        if (historyLogs.length) {
          const newHistoryLogs = [...historyLogs];
          const foundHistoryLogIndex = newHistoryLogs.findIndex(hLog => hLog.id === markerNotificationLog.id);

          if (foundHistoryLogIndex > -1) {
            newHistoryLogs[foundHistoryLogIndex] = {
              ...newHistoryLogs[foundHistoryLogIndex],
              attributes: {
                ...newHistoryLogs[foundHistoryLogIndex].attributes,
                isDone: true,
              },
            };
            dispatch(setHistoryLogs(newHistoryLogs));
          }
        }
      }
      onCancel();
    }
  };

  const handleClosePopup = () => {
    dispatch(removeMarkerNotificationLog());
    onCancel();
  };

  if (!notificationLogPopupData) {
    return null;
  }

  const headerPopup = (
    <div className={styles.popupHeader}>
      <div className={styles.popupHeaderName}>
        <div className={styles.popupHeaderIcon}>
          <Warning className={styles.popupHeaderImage} />
        </div>
        <div className={styles.popupHeaderTitle}>
          <h4>{t('map-popup.notification.title.text')}</h4>
          {!isMarkerNotificationLogLoading && (
            <span className={styles.popupHeaderTitleName} title={notificationLogPopupData.nameNotification}>
              {notificationLogPopupData.nameNotification}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const isVideoAnalytics =
    notificationLogPopupData.notificationType === t(NOTIFICATION_TYPES_ENUM_NAMES.videoAnalytics);

  const MOCK_VIDEO_DATA = {
    cameraName: 'К1-01',
    listOfEmployees: 'Иванов И.И., Петров П.П., Сидоров С.С,',
  };

  const contentPopup = isMarkerNotificationLogLoading ? (
    <Spinner />
  ) : (
    <div>
      <div className={styles.popupInfo}>
        {!isVideoAnalytics && (
          <div className={styles.popupInfoIcon}>
            <div className={styles.popupInfoIconImage}>
              <img className={styles.popupInfoIconPhoto} src={notificationLogPopupData.photo} alt="Notification Icon" />
            </div>
            <div className={styles.popupInfoIconNumber}>{notificationLogPopupData.trackerNumber}</div>
          </div>
        )}

        <div className={styles.popupInfoContent}>
          {!isVideoAnalytics && (
            <div className={styles.popupInfoContentData}>
              <div className={styles.popupInfoContentDataName}>{notificationLogPopupData.aggregatedName}</div>
              <div className={styles.popupInfoContentDataPosition}>{notificationLogPopupData.positionOrType}</div>
              <div className={styles.popupInfoContentDataNumber}>{notificationLogPopupData.simOrRegNumber}</div>
            </div>
          )}
          <div className={cx(styles.popupInfoContentData, styles.NotificationData)}>
            <div className={styles.popupInfoContentDataType}>
              {t('map-popup.notification.type.text')} {notificationLogPopupData.notificationType}
            </div>
            <div className={styles.popupInfoContentDataDate}>
              {`${t('map-popup.notification.date.text')} ${notificationLogPopupData.date.date} - ${
                notificationLogPopupData.date.shortTime
              }`}
            </div>
            {isVideoAnalytics && (
              <>
                <div className={styles.popupInfoContentDataPlace}>
                  {`${t('map-popup.notification.place.text')} ${notificationLogPopupData.geozone.name} (${
                    MOCK_VIDEO_DATA.cameraName
                  })`}
                </div>
                <div className={styles.popupInfoContentDataPlace}>
                  {`${t('map-popup.notification.place.text')} ${notificationLogPopupData.geozone.name} (${
                    MOCK_VIDEO_DATA.listOfEmployees
                  })`}
                </div>
              </>
            )}
            {!isVideoAnalytics && (
              <div className={styles.popupInfoContentDataPlace}>
                {`${t('map-popup.notification.place.text')} ${notificationLogPopupData.geozone.name} (${
                  notificationLogPopupData.geozone.coords
                })`}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.popupDescription}>
        <h5 className={styles.popupDescriptionTitle}>{t('map-popup.notification.notification-text.text')}</h5>
        <div className={styles.popupDescriptionText}>
          {notificationLogPopupData.text
            .replace('%POS_TIME%', notificationLogPopupData.date.shortTime)
            .replace('%UNIT%', notificationLogPopupData.aggregatedName)
            .replace('%ZONE%', notificationLogPopupData.geozone.name)}
        </div>
      </div>

      <div className={styles.popupBtns}>
        {!!markerNotificationLog && isTableHistoryLogsShow && (
          <Button
            white
            text={t('map-popup.notification.show-on-map.text')}
            onClick={handleShowOnMap}
            customStyle={styles.popupContentBtnsMaxWidth}
          />
        )}
        <Button white text={t('map-popup.notification.btn.close.text')} onClick={handleClosePopup} />
        {!isMarkerNotificationLogLoading && (
          <Button
            blue
            text={t('map-popup.notification.btn.read.text')}
            disabled={notificationLogPopupData.isDone}
            onClick={handleIsDone}
          />
        )}
      </div>
    </div>
  );

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      content={contentPopup}
      initialWidth={INITIAL_POPUP_WIDTH}
      initialPosition={markerCoords.mouseCoords}
      customClassNames={{ content: styles.customContentStyles }}
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(NotificationPopup);
