import React, { useEffect, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/common/button/button';
import Search from 'components/common/search/search';
import TrackersList from './components/list1/list1';
import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { TRACKER } from 'utils/consts';
import AccessEntity from 'utils/accessEntity';
import { fetchTrackers, unwatchAllTrackers, fetchSearchTrackers } from 'reducers/trackers';
import styles from './trackers.module.scss';
import { fetchHandbookData } from 'reducers/handbooks';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { debounce } from 'lodash';

const Trackers: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const permissions = useSelector((state: RootState) => state.user.permissions.trackers);

  const trackersPermission = useMemo(() => {
    return new AccessEntity(permissions);
  }, [permissions]);

  const {
    data: { organizations, positions, departments },
  } = useSelector((state: RootState) => state.handbooks);

  useEffect(() => {
    return () => {
      dispatch(unwatchAllTrackers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (trackersPermission.isAllowRead()) {
      dispatch(fetchTrackers());
    }
  }, [dispatch, trackersPermission]);

  useEffect(() => {
    if (!organizations?.length || !positions?.length) {
      dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.positions, HANDBOOK_KEYS.departments]));
    }
  }, [dispatch, organizations, positions, departments]);

  const openModal = () => {
    dispatch(showModal({ type: TRACKER, which: '' }));
  };

  const handleSearchChange = debounce((val: string) => {
    dispatch(fetchSearchTrackers(val));
  }, 500);

  return (
    <div className={styles.content}>
      <div className={styles.buttonBlock}>
        <div className={styles.searchWrapper}>
          <Search handleChange={handleSearchChange} />
        </div>
        {trackersPermission.isAllowCreate() && (
          <Button
            text={t('trackers.button-block.btn.label')}
            blue
            long
            withSymbol
            onClick={openModal}
            customStyle={styles.trackerCreateButton}
          />
        )}
      </div>
      <TrackersList />
    </div>
  );
};

export default React.memo(Trackers);
