import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';
import { setChosenElement, setChosenHandbook, fetchHandbooksNames } from 'reducers/handbooks';

import styles from './handbooksList.module.scss';

const cx = classNames.bind(styles);

const HandbooksList: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { handbooksNames, handbooksNamesIsLoading, chosenHandbookName, searchedHandbooksNames } = useSelector(
    (state: RootState) => state.handbooks
  );

  useEffect(() => {
    dispatch(fetchHandbooksNames());
  }, [dispatch]);

  const handleClick = (id: number) => {
    const foundHandbook = handbooksNames.find(h => h.id === id);

    if (foundHandbook) {
      dispatch(setChosenElement(null));
      dispatch(setChosenHandbook(foundHandbook));
    }
  };

  const handbooksLayout = searchedHandbooksNames.length ? [...searchedHandbooksNames] : [...handbooksNames];

  if (handbooksNamesIsLoading) {
    return (
      <div className={styles.list}>
        <Spinner />
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {handbooksLayout.map((handbook, index) => (
        <li
          key={`handbook-item-${index}`}
          className={cx(styles.listItem, {
            [styles.listItemChosen]: chosenHandbookName?.id === handbook.id,
          })}
          title={handbook.name || t('handbooks.column.dictionaries.name.default.text')}
          onClick={() => handleClick(handbook.id)}
        >
          {handbook.name || t('handbooks.column.dictionaries.name.default.text')}
        </li>
      ))}
    </ul>
  );
};

export default HandbooksList;
