import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import styles from './modalHeader.module.scss';

interface Header {
  onCancel: () => void;
  title: string;
}

export const ModalHeader = ({ title, onCancel }: Header) => {
  return (
    <div className={styles.modalHeader}>
      <div>{title}</div>
      <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={onCancel} />
    </div>
  );
};
