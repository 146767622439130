export default {
  en: {
    'map-popup.social-contact.date-start.text': 'Start date of the period',
    'map-popup.social-contact.date-end.text': 'End date of the period',
    'map-popup.social-contact.show.text': 'Create',

    'map-popup.social-contact.map-contacts.text': 'Map of social contacts',

    'map-popup.social-contact.table.date.text': 'Date',
    'map-popup.social-contact.table.time.text': 'Time',
    'map-popup.social-contact.table.tracker-number.text': '№ Tracker',
    'map-popup.social-contact.table.fio.text': 'Name of contact person',
    'map-popup.social-contact.table.zone.text': 'Contact zone',
    'map-popup.social-contact.table.long-time.text': 'Contact duration',

    'map-popup.footer.btn.close.text': 'Close',
    'map-popup.footer.btn.show-map.text': 'Show on the map',
    'map-popup.footer.btn.save-file.text': 'Save to file',
  },
  ru: {
    'map-popup.social-contact.date-start.text': 'Дата начала периода',
    'map-popup.social-contact.date-end.text': 'Дата конца периода',
    'map-popup.social-contact.show.text': 'Показать',

    'map-popup.social-contact.map-contacts.text': 'Карта социальных контактов',

    'map-popup.social-contact.table.date.text': 'Дата',
    'map-popup.social-contact.table.time.text': 'Время',
    'map-popup.social-contact.table.tracker-number.text': '№ трекера',
    'map-popup.social-contact.table.fio.text': 'Фио контактного лица',
    'map-popup.social-contact.table.zone.text': 'Название зоны контакта',
    'map-popup.social-contact.table.long-time.text': 'Длительность контакта',

    'map-popup.footer.btn.close.text': 'Закрыть',
    'map-popup.footer.btn.show-map.text': 'Показать на карте',
    'map-popup.footer.btn.save-file.text': 'Сохранить в файл',
  },
  zh: {
    'map-popup.social-contact.date-start.text': '期间开始日期',
    'map-popup.social-contact.date-end.text': '期间结束日期',
    'map-popup.social-contact.show.text': '显示',
    'map-popup.social-contact.map-contacts.text': '社交联系卡',
    'map-popup.social-contact.table.date.text': '日期',
    'map-popup.social-contact.table.time.text': '时间',
    'map-popup.social-contact.table.tracker-number.text': '跟踪器 ID',
    'map-popup.social-contact.table.fio.text': '联系人',
    'map-popup.social-contact.table.zone.text': '联系区域名称',
    'map-popup.social-contact.table.long-time.text': '联系时间',
    'map-popup.footer.btn.close.text': '故障',
    'map-popup.footer.btn.show-map.text': '在地图上显示',
    'map-popup.footer.btn.save-file.text': '保存到文件',
  },
  ar: {
    'map-popup.social-contact.date-start.text': 'تاريخ بدء المهلة',
    'map-popup.social-contact.date-end.text': 'تاريخ انتهاء المهلة',
    'map-popup.social-contact.show.text': 'عرض',
    'map-popup.social-contact.map-contacts.text': 'خريطة جهات الاتصال الاجتماعية',
    'map-popup.social-contact.table.date.text': 'التاريخ',
    'map-popup.social-contact.table.time.text': 'الوقت',
    'map-popup.social-contact.table.tracker-number.text': 'رقم المتتبع',
    'map-popup.social-contact.table.fio.text': 'الاسم الكامل لجهة الاتصال',
    'map-popup.social-contact.table.zone.text': 'اسم منطقة لجهة الاتصال',
    'map-popup.social-contact.table.long-time.text': 'مدة جهة الاتصال',
    'map-popup.footer.btn.close.text': 'إغلاق',
    'map-popup.footer.btn.show-map.text': 'عرض على الخريطة',
    'map-popup.footer.btn.save-file.text': 'حفظ كملف',
  },
  de: {
    'map-popup.social-contact.date-start.text': 'Startdatum der Periode',
    'map-popup.social-contact.date-end.text': 'Enddatum des Zeitraums',
    'map-popup.social-contact.show.text': 'Zeigen',
    'map-popup.social-contact.map-contacts.text': 'Soziale Kontaktkarte',
    'map-popup.social-contact.table.date.text': 'Datum',
    'map-popup.social-contact.table.time.text': 'Zeit',
    'map-popup.social-contact.table.tracker-number.text': 'Tracker Nr.',
    'map-popup.social-contact.table.fio.text': 'Name der Kontaktperson',
    'map-popup.social-contact.table.zone.text': 'Name des Kontaktbereichs',
    'map-popup.social-contact.table.long-time.text': 'Kontaktdauer',
    'map-popup.footer.btn.close.text': 'Schließen',
    'map-popup.footer.btn.show-map.text': 'Auf der Karte anzeigen',
    'map-popup.footer.btn.save-file.text': 'Speichern unter',
  },
};
