import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UnitItem } from './components/unitItem/unitItem';
import { MonitoringRow } from './components/monitoringRow/monitoringRow';
import { useSortingSubstructures, useSortingUnits } from 'components/monitoring/utils/useSorting';
import { TransformedUnitsData } from 'components/monitoring/utils/types';

import { RootState } from 'reducers';
import { fetchLastPosition } from 'reducers/trackableUnits';

type Props = {
  monitoringUnitsTree: TransformedUnitsData;
  hasTree: boolean;
};

const WIDE_WIDTH = 580;

const ListUnits = ({ hasTree, monitoringUnitsTree }: Props) => {
  const dispatch = useDispatch();

  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);
  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);
  const contentSize = useSelector((state: RootState) => state.common.contentSize);

  const isWide = contentSize.width > WIDE_WIDTH;

  const substructuresArr = useSortingSubstructures(monitoringUnitsTree);
  const unitsArr = useSortingUnits(trackableUnits);

  useEffect(() => {
    if (unitsArr.length) {
      const arr: { id: string }[] = [];
      unitsArr.forEach(unit => {
        const isUnitSelected = selectedUnits.includes(unit.id);
        if (isUnitSelected && unit.attributes.deviceId) {
          arr.push({ id: unit.attributes.deviceId });
        }
      });
      dispatch(fetchLastPosition(arr));
    }
  }, [dispatch, selectedUnits, unitsArr]);

  return (
    <div>
      {hasTree
        ? substructuresArr.map((transformedUnit, index) => (
            <MonitoringRow
              key={`transformed-unit-first-level-${index}`}
              structure={monitoringUnitsTree[transformedUnit]}
              deepLevel={0}
              isWide={isWide}
            />
          ))
        : unitsArr.map((unit, unitInd) => (
            <UnitItem
              unit={unit}
              key={`monitoring-unit-${unitInd}-${unit.attributes.aggregatedName}`}
              paddingLeft={0}
              isWide={isWide}
            />
          ))}
    </div>
  );
};

export default React.memo(ListUnits);
