import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import styles from '../monitoring.module.scss';

type Props = {
  title: string;
  onCancel: () => void;
};

export const ModalHeader = ({ title, onCancel }: Props) => {
  return (
    <>
      <div>{title}</div>
      <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={onCancel} />
    </>
  );
};
