import React, { useEffect, useState, useRef, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classnames from 'classnames/bind';

import Button from 'components/common/button/button';
import { Spinner } from 'components/common/spinner/spinner';
import { REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE_ALL } from 'components/records/utils/consts';

import { RootState } from 'reducers';
import {
  generatePrintedForm,
  handleIsReportInfoShow,
  clearChosenReport,
  saveToDiskPrintedForm,
  saveToDiskChosenReportData,
  clearReportInfoPdfUrl,
  setIsPrintableTemplateContained,
} from 'reducers/records';

import { printFileFromUrl } from 'utils/printFileFromUrl';

import styles from './reportInfo.module.scss';

const cx = classnames.bind(styles);

const MIN_WRAPPER_HEIGHT = 125;
const BOUNDARY_WRAPPER_HEIGHT_PERCENT = 15;
const HEADER_WIDE_BTN_UP_HEIGHT = 47;

function ReportInfo() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const {
    isGenerateReportPrintedFormsLoading,
    chosenReport,
    isChosenReportLoading,
    reportInfoPdfUrl,
    isReportInfoPdfURISaving,
    isPrintableTemplateContained,
  } = useSelector((state: RootState) => state.records);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const separatorRef = useRef<HTMLDivElement>(null);

  const [isSeparatorCapture, setIsSeparatorCapture] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearChosenReport());
      dispatch(clearReportInfoPdfUrl());
      dispatch(setIsPrintableTemplateContained(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (chosenReport?.id && chosenReport?.attributes.reportTemplateId && isPrintableTemplateContained) {
      dispatch(
        generatePrintedForm({
          reportId: Number(chosenReport?.id) || 0,
          reportTemplateId: Number(chosenReport?.attributes.reportTemplateId) || 0,
        })
      );
    }
  }, [dispatch, chosenReport, isPrintableTemplateContained]);

  useEffect(() => {
    function handleMouseUp() {
      setIsSeparatorCapture(false);
    }

    function handlerMouseMove(event: MouseEvent) {
      const wrapper = wrapperRef?.current;
      const parent = wrapper?.parentElement; // для определения границ перемещения wrapper
      const separator = separatorRef.current;
      const content = separator?.parentElement?.nextElementSibling?.firstElementChild; // для скрытия/показа контентной части

      if (wrapper && parent && content && separator && isSeparatorCapture) {
        const parentHeight = parent.offsetHeight;
        const currentWrapperHeight = wrapper.offsetHeight;

        const deltaY = event.pageY - separator.getBoundingClientRect().top;

        const newWrapperHeight = currentWrapperHeight - deltaY;
        const currentHeightPercent = (newWrapperHeight * 100) / parentHeight;

        const mapWideBtn = separator.previousElementSibling;
        const dataWideBtn = separator.nextElementSibling;

        if (newWrapperHeight > parentHeight - HEADER_WIDE_BTN_UP_HEIGHT) {
          return;
        }

        if (newWrapperHeight < MIN_WRAPPER_HEIGHT) {
          dataWideBtn?.classList.remove(styles.headerWideButtonHide);
          content.classList.add(styles.contentHide);
          return;
        } else {
          dataWideBtn?.classList.add(styles.headerWideButtonHide);
          content.classList.remove(styles.contentHide);
        }

        if (currentHeightPercent > 100 - BOUNDARY_WRAPPER_HEIGHT_PERCENT) {
          mapWideBtn?.classList.remove(styles.headerWideButtonHide);
        } else {
          mapWideBtn?.classList.add(styles.headerWideButtonHide);
        }

        wrapper.style.height = `${newWrapperHeight}px`;
      }
    }

    if (isSeparatorCapture) {
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handlerMouseMove);
    }

    return () => {
      if (isSeparatorCapture) {
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handlerMouseMove);
      }
    };
  }, [wrapperRef, separatorRef, isSeparatorCapture]);

  // формируем данные для отображения в виде таблицы или
  const chosenReportDataProcessed: Record<string, string[]> = useMemo(() => {
    if (chosenReport?.attributes.data) {
      return Object.keys(chosenReport.attributes.data).reduce((acc, key) => {
        const values = chosenReport.attributes.data?.[key]?.map(data => String(data || '')) ?? [];
        const headerColumnName = t(
          REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE_ALL[
            key as keyof typeof REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE_ALL
          ] ?? 'records.right-report-view.table.header.other.text'
        );

        return { ...acc, [headerColumnName]: values };
      }, {});
    }
    return {};
  }, [t, chosenReport]);

  const handleCaptureStart = () => setIsSeparatorCapture(true);

  const handlePrint = () => {
    if (reportInfoPdfUrl) {
      printFileFromUrl(reportInfoPdfUrl);
    }
  };

  const handleClose = () => {
    dispatch(handleIsReportInfoShow(false));
  };

  const handleSave = () => {
    if (reportInfoPdfUrl) {
      return dispatch(saveToDiskPrintedForm('report-printed-form.pdf'));
    }
    if (chosenReport?.attributes.data) {
      return dispatch(saveToDiskChosenReportData({ data: chosenReportDataProcessed, fileName: 'report.csv' }));
    }
  };

  const handleWideBtnClick = () => {
    const wrapper = wrapperRef?.current;
    const parent = wrapper?.parentElement;

    const separator = separatorRef.current;
    const mapWideBtn = separator?.previousElementSibling;
    const dataWideBtn = separator?.nextElementSibling;
    const content = separator?.parentElement?.nextElementSibling?.firstElementChild;

    if (wrapper && parent && content && mapWideBtn && dataWideBtn) {
      const parentHeight = parent.offsetHeight;

      wrapper.style.height = `${(parentHeight * 50) / 100}px`;
      mapWideBtn.classList.add(styles.headerWideButtonHide);
      dataWideBtn.classList.add(styles.headerWideButtonHide);
      content.classList.remove(styles.contentHide);
    }
  };

  const isTableDataExisted = chosenReport?.attributes.data && !!Object.keys(chosenReportDataProcessed).length;

  const isDataExisted = reportInfoPdfUrl || isTableDataExisted;

  const isBusy =
    !reportInfoPdfUrl || isGenerateReportPrintedFormsLoading || isChosenReportLoading || isReportInfoPdfURISaving;

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.header}>
        <div
          className={cx(styles.headerWideButton, styles.headerWideButtonHide, styles.headerWideButtonUp)}
          onClick={handleWideBtnClick}
        >
          <div className={styles.headerArrow} />
          <div className={styles.headerText}>{t('records.right-report-view.header.map.text')}</div>
        </div>
        <div className={styles.separator} onMouseDown={handleCaptureStart} ref={separatorRef} />
        <div className={cx(styles.headerWideButton, styles.headerWideButtonHide)} onClick={handleWideBtnClick}>
          <div className={cx(styles.headerArrow, styles.headerArrowFlip)} />
          <div className={styles.headerText}>{t('records.right-report-view.header.data.text')}</div>
        </div>
      </div>
      <div
        className={cx(styles.contentContainer, {
          [styles.contentPartlyHide]: isSeparatorCapture,
        })}
      >
        {isChosenReportLoading ? (
          <Spinner />
        ) : (
          <div className={styles.content}>
            {isDataExisted ? (
              <Fragment>
                {!isPrintableTemplateContained && chosenReport?.attributes.data && isTableDataExisted && (
                  <div
                    className={cx(styles.table, {
                      [styles.tableWithMargin]: reportInfoPdfUrl,
                    })}
                  >
                    {Object.keys(chosenReportDataProcessed).map(headerColumnName => {
                      const values = chosenReportDataProcessed[headerColumnName] ?? [];

                      return (
                        <div className={styles.tableColumn} key={`chosen-report-column-table-${headerColumnName}`}>
                          <div className={cx(styles.tableColumnCell, styles.tableColumnCellHeader)}>
                            {headerColumnName}
                          </div>
                          {values.map((value, index) => (
                            <div
                              className={styles.tableColumnCell}
                              key={`chosen-report-column-table-cell-${headerColumnName}-${index}`}
                            >
                              {value || '-'}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                )}
                {reportInfoPdfUrl && (
                  <embed
                    type="application/pdf"
                    src={reportInfoPdfUrl}
                    id="embedReportPdfDocument"
                    className={styles.embed}
                  />
                )}
              </Fragment>
            ) : (
              <div className={styles.noDataTextWrapper}>{t('records.right-report-view.no-data.text')}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          white
          text={t('records.right-report-view.footer.btn.print.text')}
          onClick={handlePrint}
          customStyle={styles.footerBtn}
          disabled={isBusy}
        />
        <Button
          white
          text={t('records.right-report-view.footer.btn.close.text')}
          onClick={handleClose}
          customStyle={styles.footerBtn}
        />
        <Button
          blue
          text={t('records.right-report-view.footer.btn.save.text')}
          onClick={handleSave}
          customStyle={styles.footerBtn}
          disabled={!reportInfoPdfUrl && !isTableDataExisted}
        />
      </div>
    </div>
  );
}

export default ReportInfo;
