import { reqHandlers } from 'utils/api';
import { TransportAttributes } from './interface';

export function getTransports(string: string) {
  return reqHandlers.get(`/api/transports?string=${string}`);
}

export function createTransport(data: TransportAttributes) {
  if (data.hasOwnProperty('id')) {
    delete data.id;
  }
  return reqHandlers.post('/api/transports', data);
}
export function deleteTransport(id: number) {
  return reqHandlers.delete(`/api/transports/${id}`);
}

export function getOneTransport(id: number) {
  return reqHandlers.get(`/api/transports/${id}`);
}

export function getTransportIncludes() {
  return reqHandlers.get('/api/transports/includesInfo');
}

export function putTransport(data: TransportAttributes) {
  return reqHandlers.put(`/api/transports/${data.id}`, data);
}
