import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import DatePicker from 'react-datepicker';

import { getCurrentLocaleForDatePicker } from 'translate';

import { Option } from 'components/common/select/select';
import Button from 'components/common/button/button';
import Input from 'components/common/input/input';
import MultipleSelect, { getValueForMultipleSelect, ValuesType } from 'components/common/multipleSelect/multipleSelect';

import { RootState } from 'reducers';
import { fetchHandbookStatistic, setFilterField } from 'reducers/handbooks';

import styles from './datesFilterModal.module.scss';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const DatesFilterModal = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { handbooksNames, statisticDataFilter } = useSelector((state: RootState) => state.handbooks);
  const { locale } = useSelector((state: RootState) => state.user.userPreferences);
  const [chosenHandbooksIdsForSelect, setChosenHandbooksIdsForSelect] = useState<Option[]>([]);

  useEffect(() => {
    if (handbooksNames.length && !chosenHandbooksIdsForSelect.length) {
      setChosenHandbooksIdsForSelect(
        handbooksNames.map((handbookName, i) => ({
          value: i + 1,
          label: handbookName.name,
          data: { id: handbookName.id },
        }))
      );
    }
  }, [handbooksNames, chosenHandbooksIdsForSelect]);

  const chosenHandbooksIds = useMemo(
    () =>
      statisticDataFilter.types
        ? statisticDataFilter.types
            .split(',')
            .map(type => handbooksNames.find(name => name.keyName === type)?.id || -1)
            .filter(id => id > 0)
        : [],
    [statisticDataFilter, handbooksNames]
  );

  const dateFromRef = React.createRef();
  const dateFromTo = React.createRef();

  const handleChangeBindedHandbookSensors = (values: ValuesType) => {
    dispatch(
      setFilterField({
        key: 'types',
        value: values.map(val => handbooksNames.find(name => name.id === val.data?.id)?.keyName).join(','),
      })
    );
  };
  const handleStartDate = (date: Date) => {
    dispatch(
      setFilterField({
        key: 'dateStart',
        value: date.toISOString(),
      })
    );
  };
  const handleEndDate = (date: Date) => {
    dispatch(
      setFilterField({
        key: 'dateEnd',
        value: date.toISOString(),
      })
    );
  };
  const handleShow = () => {
    dispatch(fetchHandbookStatistic());
  };

  return (
    <div className={styles.wrapper}>
      <MultipleSelect
        options={chosenHandbooksIdsForSelect}
        label={t('handbooks.statistic-modal.field.directory.label')}
        placeholder={t('handbooks.statistic-modal.field.directory.placeholder')}
        isRequired={false}
        withSearchIcon
        optionIsInline
        wrapperStyle={styles.select}
        optionsContainerStyle={styles.selectOptions}
        handleChangeMultiple={handleChangeBindedHandbookSensors}
        values={chosenHandbooksIds.map((id, i) =>
          getValueForMultipleSelect({
            i,
            id,
            selectValues: chosenHandbooksIdsForSelect,
          })
        )}
      />
      <div className={styles.datesWrapper}>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          selected={statisticDataFilter.dateStart ? new Date(statisticDataFilter.dateStart) : null}
          onChange={(date: Date) => handleStartDate(date)}
          wrapperClassName={styles.date}
          placeholderText={t('handbooks.statistic-modal.field.period-start.placeholder')}
          customInput={
            <DatePickerCustomInput ref={dateFromRef} label={t('handbooks.statistic-modal.field.period-start.label')} />
          }
          openToDate={statisticDataFilter.dateStart ? new Date(statisticDataFilter.dateStart) : new Date()}
          maxDate={statisticDataFilter.dateEnd ? new Date(statisticDataFilter.dateEnd) : null}
          locale={getCurrentLocaleForDatePicker(locale)}
          disabled={false}
        />
        <DatePicker
          dateFormat="dd.MM.yyyy"
          selected={statisticDataFilter.dateEnd ? new Date(statisticDataFilter.dateEnd) : null}
          onChange={(date: Date) => handleEndDate(date)}
          wrapperClassName={styles.date}
          placeholderText={t('handbooks.statistic-modal.field.period-end.placeholder')}
          customInput={
            <DatePickerCustomInput ref={dateFromTo} label={t('handbooks.statistic-modal.field.period-end.label')} />
          }
          openToDate={statisticDataFilter.dateEnd ? new Date(statisticDataFilter.dateEnd) : new Date()}
          minDate={statisticDataFilter.dateStart ? new Date(statisticDataFilter.dateStart) : null}
          locale={getCurrentLocaleForDatePicker(locale)}
          disabled={false}
        />
        <Button
          blue
          text={t('handbooks.statistic-modal.btn.show.label')}
          customStyle={styles.button}
          onClick={handleShow}
        />
      </div>
    </div>
  );
};

export default DatesFilterModal;
