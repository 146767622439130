import React, { useEffect, useRef } from 'react';
import cs from 'classnames';

import styles from './growingTextArea.module.scss';

type Props = {
  className?: string;
  placeholder?: string;
  maxRows?: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const defaultMaxRows = 3;

const GrowingTextArea: React.FC<Props> = ({
  className,
  placeholder = '',
  maxRows = defaultMaxRows,
  value,
  onChange,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const el = textAreaRef.current;

    if (el) {
      // compute and set rows attribute for textarea
      el.setAttribute('rows', '1');
      el.style.overflowY = 'hidden';

      const elStyle = getComputedStyle(el);
      const contentHeight = el.scrollHeight - parseFloat(elStyle.paddingTop) - parseFloat(elStyle.paddingBottom);
      const lineHeight = parseFloat(elStyle.lineHeight);

      const realRows = Math.round(contentHeight / lineHeight);
      const nextRows = realRows < maxRows ? realRows : maxRows;

      el.setAttribute('rows', nextRows.toString());
      el.style.overflowY = 'auto';
    }
  }, [value, maxRows]);

  return (
    <textarea
      className={cs(styles.textArea, className)}
      ref={textAreaRef}
      rows={1} // initial value that will be changed in effect
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default GrowingTextArea;
