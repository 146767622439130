export default {
  en: {
    'accounts.button-block.btn.create.label': 'Create',

    'accounts.button-block.btn.drop-list.users.label': 'Users',
    'accounts.button-block.btn.drop-list.roles.label': 'Roles',

    'accounts.users-config-modal.title.text': 'Settings',
    'accounts.users-config-modal.ok-button.text': 'Apply',
    'accounts.users-config-modal.cancel-button.text': 'Cancel',

    'accounts.users-config-modal.group-by-organization.text': 'By organization',
    'accounts.users-config-modal.group-by-department.text': 'By department',
    'accounts.users-config-modal.group-by-position.text': 'By position',

    'accounts.users-list-tree.entity-organization.text': 'Organization',
    'accounts.users-list-tree.entity-department.text': 'Department',
    'accounts.users-list-tree.entity-position.text': 'Position',

    'accounts.users-list-filters.all-organizations.text': 'All organizations',
    'accounts.users-list-filters.all-departments.text': 'All departments',
    'accounts.users-list-filters.all-positions.text': 'All positions',

    'accounts.users-list-filters.organizations-label.text': 'Organization',
    'accounts.users-list-filters.departments-label.text': 'Department',
    'accounts.users-list-filters.positions-label.text': 'Position',

    'accounts.users-list-tree.entity-without-name.text': 'Without name',

    'accounts.users-list-tree.organization-ungrouped.text': 'Without organization',
    'accounts.users-list-tree.department-ungrouped.text': 'Without department',
    'accounts.users-list-tree.position-ungrouped.text': 'Without position',
    'accounts.users-list-tree.item.roles.more.text': '{count, plural, one {# More role} other {# More roles}}',

    'accounts.users-list-columns.column-fullName.text': 'Full name',
    'accounts.users-list-columns.column-login.text': 'Login',
    'accounts.users-list-columns.column-organization.text': 'Organization',
    'accounts.users-list-columns.column-department.text': 'Department',
    'accounts.users-list-columns.column-position.text': 'Position',
    'accounts.users-list-columns.column-roles.text': 'Roles',

    'accounts.users-config-modal.tab-grouping.text': 'Grouping',
    'accounts.users-config-modal.tab-columns.text': 'Columns',

    'accounts.roles-list-columns.column-name.text': 'Role name',
    'accounts.roles-list-columns.column-createdBy.text': 'Created by',
    'accounts.roles-list-columns.column-employeesCount.text': 'Number of employees with a role',
    'accounts.roles-list-columns.column-accessEntities.text': 'Role pramteres',

    'accounts.roles-list-table.accessEntities.operation-read.text': 'Read',
    'accounts.roles-list-table.accessEntities.operation-create.text': 'Create',
    'accounts.roles-list-table.accessEntities.operation-update.text': 'Edit',
    'accounts.roles-list-table.accessEntities.operation-delete.text': 'Delete',

    'accounts.roles-list-table.accessEntities.more.text': ', ... + {count} more',
    'accounts.roles-list-table.assign-role-button.text': 'Assign the role',

    'accounts.users-table.header.user.label': 'User',
    'accounts.users-table.header.login.label': 'Username',
    'accounts.users-table.header.company.label': 'Company',
    'accounts.users-table.header.subdivision.label': 'Subdivision',
    'accounts.users-table.header.position.label': 'Position',
    'accounts.users-table.header.roles.label': 'Roles granted',
    'accounts.users-table.item.roles.more.text': '+ More roles:',

    'accounts.card.user.header.label': 'User settings',

    'accounts.card.user.tabs.main.text': 'General',
    'accounts.card.user.tabs.roles.text': 'User roles',
    'accounts.card.user.tabs.statistic.text': 'Statistic',

    'accounts.card.user.tab.general.field.surname.label': 'Surname',
    'accounts.card.user.tab.general.field.surname.placeholder': 'Enter surname',
    'accounts.card.user.tab.general.field.name.label': 'Name',
    'accounts.card.user.tab.general.field.name.placeholder': 'Enter name',
    'accounts.card.user.tab.general.field.patronymic.label': 'Patronymic',
    'accounts.card.user.tab.general.field.patronymic.placeholder': 'Enter patronymic',
    'accounts.card.user.tab.general.field.phone.label': 'Phone number',
    'accounts.card.user.tab.general.field.phone.placeholder': 'Input format: +7XXXXXXXXXX',
    'accounts.card.user.tab.general.field.sms-notify.text': 'Notify by sms',
    'accounts.card.user.tab.general.field.login.label': 'Username',
    'accounts.card.user.tab.general.field.login.placeholder': 'Enter username',
    'accounts.card.user.tab.general.field.user-block.text': 'Block user',
    'accounts.card.user.tab.general.field.company.label': 'Company',
    'accounts.card.user.tab.general.field.company.placeholder': 'Select from the list',
    'accounts.card.user.tab.general.field.subdivision.label': 'Subdivision',
    'accounts.card.user.tab.general.field.subdivision.placeholder': 'Select from the list',
    'accounts.card.user.tab.general.field.position.label': 'Position',
    'accounts.card.user.tab.general.field.position.placeholder': 'Select from the list',
    'accounts.card.user.tab.general.field.email.label': 'Email',
    'accounts.card.user.tab.general.field.email.placeholder': 'Enter email',
    'accounts.card.user.tab.general.field.email-notify.text': 'Notify by email',
    'accounts.card.user.tab.general.field.pass.label': 'Password',
    'accounts.card.user.tab.general.field.pass.placeholder': 'Enter password',

    'accounts.card.user.tab.roles.field.roles-available.header.label': 'Available roles',
    'accounts.card.user.tab.roles.field.table.header.resource.label': 'Resource',
    'accounts.card.user.tab.roles.field.table.header.read.label': 'Read',
    'accounts.card.user.tab.roles.field.table.header.create.label': 'Create',
    'accounts.card.user.tab.roles.field.table.header.edit.label': 'Edit',
    'accounts.card.user.tab.roles.field.table.header.delete.label': 'Delete',

    'accounts.card.user.tab.statistic.table.header.date.label': 'Date',
    'accounts.card.user.tab.statistic.table.header.time.label': 'Time',
    'accounts.card.user.tab.statistic.table.header.event.label': 'Event',

    'accounts.roles-table.resource-column.row.trackers.text': 'Tracker parameters',
    'accounts.roles-table.resource-column.row.transports.text': 'Vehicle parameters',
    'accounts.roles-table.resource-column.row.employees.text': 'Employee parameters',
    'accounts.roles-table.resource-column.row.users.text': 'User parameters',
    'accounts.roles-table.resource-column.row.roles.text': 'User roles',
    'accounts.roles-table.resource-column.row.geozones.text': 'Geofences',
    'accounts.roles-table.resource-column.row.tracks.text': 'Tracks',
    'accounts.roles-table.resource-column.row.reports.text': 'Dashboards',
    'accounts.roles-table.resource-column.row.map.text': 'Map uploading',
    'accounts.roles-table.resource-column.row.handbooks.text': 'Dictionaries',
    'accounts.roles-table.resource-column.row.buildings.text': 'Building markings',
    'accounts.roles-table.resource-column.row.notifications.text': 'Notifications',
    'accounts.roles-table.resource-column.row.poi.text': 'POI',
    'accounts.roles-table.resource-column.row.records.text': 'Reports',
    'accounts.roles-table.resource-column.row.messages.text': 'Messages',

    'accounts.card.user.footer.btn.delete.label': 'Delete',
    'accounts.card.user.footer.btn.cancel.label': 'Cancel',
    'accounts.card.user.footer.btn.create.label': 'Create',
    'accounts.card.user.footer.btn.update.label': 'Update',

    'accounts.card.roles.header.label': 'Create user role',
    'accounts.card.roles.field.name.label': 'Role name',
    'accounts.card.roles.field.name.placeholder': 'Enter role name',
    'accounts.card.roles.table.header.resource.label': 'Resource',
    'accounts.card.roles.table.header.read.label': 'Read',
    'accounts.card.roles.table.header.create.label': 'Create',
    'accounts.card.roles.table.header.edit.label': 'Edit',
    'accounts.card.roles.table.header.delete.label': 'Delete',
    'accounts.card.roles.footer.btn.add-role.label': 'Assign role',
    'accounts.card.roles.footer.btn.cancel.label': 'Cancel',
    'accounts.card.roles.footer.btn.save.label': 'Save',

    'accounts.card.roles.assign-role.header.label': 'Assign role',
    'accounts.card.roles.assign-role.available-users.text': 'Users in the system',
    'accounts.card.roles.assign-role.available-users.btn.text': 'Select all',
    'accounts.card.roles.assign-role.chosen-users.text': 'Users with role',
    'accounts.card.roles.assign-role.chosen-users.btn.text': 'Select all',
    'accounts.card.roles.assign-role.footer.btn.cancel.label': 'Cancel',
    'accounts.card.roles.assign-role.footer.btn.save.label': 'Save',

    'accounts.card.alert.title.text': 'Close card',
    'accounts.card.alert.info.text': 'There are unsaved data on the form.',

    'accounts.card.alert.title.delete.text': 'Delete user',
    'accounts.card.alert.info.delete.text':
      'Deleted user cannot be recovered. Are you sure you want to delete the user?',
  },
  ru: {
    'accounts.button-block.btn.create.label': 'Создать',

    'accounts.button-block.btn.drop-list.users.label': 'Пользователи',
    'accounts.button-block.btn.drop-list.roles.label': 'Роли',

    'accounts.users-config-modal.title.text': 'Настройки',
    'accounts.users-config-modal.ok-button.text': 'Применить',
    'accounts.users-config-modal.cancel-button.text': 'Отмена',

    'accounts.users-config-modal.group-by-organization.text': 'По организациям',
    'accounts.users-config-modal.group-by-department.text': 'По подразделениям',
    'accounts.users-config-modal.group-by-position.text': 'По должностям',

    'accounts.users-list-tree.entity-organization.text': 'Организация',
    'accounts.users-list-tree.entity-department.text': 'Подразделение',
    'accounts.users-list-tree.entity-position.text': 'Должность',

    'accounts.users-list-filters.all-organizations.text': 'Все организации',
    'accounts.users-list-filters.all-departments.text': 'Все подразделения',
    'accounts.users-list-filters.all-positions.text': 'Все должности',

    'accounts.users-list-filters.organizations-label.text': 'Организация',
    'accounts.users-list-filters.departments-label.text': 'Подразделение',
    'accounts.users-list-filters.positions-label.text': 'Должность',

    'accounts.users-list-tree.entity-without-name.text': 'Без названия',

    'accounts.users-list-tree.organization-ungrouped.text': 'Без организации',
    'accounts.users-list-tree.department-ungrouped.text': 'Без подразделения',
    'accounts.users-list-tree.position-ungrouped.text': 'Без должности',
    'accounts.users-list-tree.item.roles.more.text':
      'Еще {count, plural, one {# роль} two {# роли} few {# роли} other {# ролей}}',

    'accounts.users-list-columns.column-fullName.text': 'Фамилия Имя Отчество',
    'accounts.users-list-columns.column-login.text': 'Логин',
    'accounts.users-list-columns.column-organization.text': 'Организация',
    'accounts.users-list-columns.column-department.text': 'Подразделение',
    'accounts.users-list-columns.column-position.text': 'Должность',
    'accounts.users-list-columns.column-roles.text': 'Выданные роли',

    'accounts.users-config-modal.tab-grouping.text': 'Группировка',
    'accounts.users-config-modal.tab-columns.text': 'Столбцы',

    'accounts.roles-list-columns.column-name.text': 'Название роли',
    'accounts.roles-list-columns.column-createdBy.text': 'Создатель роли',
    'accounts.roles-list-columns.column-employeesCount.text': 'Количество сотрудников с ролью',
    'accounts.roles-list-columns.column-accessEntities.text': 'Параметры роли',

    'accounts.roles-list-table.accessEntities.operation-read.text': 'Просмотр',
    'accounts.roles-list-table.accessEntities.operation-create.text': 'Создание',
    'accounts.roles-list-table.accessEntities.operation-update.text': 'Изменение',
    'accounts.roles-list-table.accessEntities.operation-delete.text': 'Удаление',

    'accounts.roles-list-table.accessEntities.more.text': ', ... + еще {count}',
    'accounts.roles-list-table.assign-role-button.text': 'Назначить роль',

    'accounts.users-table.header.user.label': 'Фамилия Имя Отчество',
    'accounts.users-table.header.login.label': 'Логин',
    'accounts.users-table.header.company.label': 'Организация',
    'accounts.users-table.header.subdivision.label': 'Подразделение',
    'accounts.users-table.header.position.label': 'Должность',
    'accounts.users-table.header.roles.label': 'Выданные роли',
    'accounts.users-table.item.roles.more.text': '+ Еще ролей:',

    'accounts.card.user.header.label': 'Карточка пользователя',

    'accounts.card.user.tabs.main.text': 'Основная информация',
    'accounts.card.user.tabs.roles.text': 'Роли пользователя',
    'accounts.card.user.tabs.statistic.text': 'Статистика',

    'accounts.card.user.tab.general.field.surname.label': 'Фамилия',
    'accounts.card.user.tab.general.field.surname.placeholder': 'Введите фамилию',
    'accounts.card.user.tab.general.field.name.label': 'Имя',
    'accounts.card.user.tab.general.field.name.placeholder': 'Введите имя',
    'accounts.card.user.tab.general.field.patronymic.label': 'Отчество',
    'accounts.card.user.tab.general.field.patronymic.placeholder': 'Введите отчество',
    'accounts.card.user.tab.general.field.phone.label': 'Телефон',
    'accounts.card.user.tab.general.field.phone.placeholder': 'Формат ввода: +7ХХХХХХХХХХ',
    'accounts.card.user.tab.general.field.sms-notify.text': 'Уведомлять по sms',
    'accounts.card.user.tab.general.field.login.label': 'Логин',
    'accounts.card.user.tab.general.field.login.placeholder': 'Введите логин',
    'accounts.card.user.tab.general.field.user-block.text': 'Блокировка пользователя',
    'accounts.card.user.tab.general.field.company.label': 'Организация',
    'accounts.card.user.tab.general.field.company.placeholder': 'Выберите из списка',
    'accounts.card.user.tab.general.field.subdivision.label': 'Подразделение',
    'accounts.card.user.tab.general.field.subdivision.placeholder': 'Выберите из списка',
    'accounts.card.user.tab.general.field.position.label': 'Должность',
    'accounts.card.user.tab.general.field.position.placeholder': 'Выберите из списка',
    'accounts.card.user.tab.general.field.email.label': 'Электронная почта',
    'accounts.card.user.tab.general.field.email.placeholder': 'Введите электронную почту',
    'accounts.card.user.tab.general.field.email-notify.text': 'Уведомлять по e-mail',
    'accounts.card.user.tab.general.field.pass.label': 'Пароль',
    'accounts.card.user.tab.general.field.pass.placeholder': 'Введите пароль',

    'accounts.card.user.tab.roles.field.roles-available.header.label': 'Роли',
    'accounts.card.user.tab.roles.field.table.header.resource.label': 'Ресурс',
    'accounts.card.user.tab.roles.field.table.header.read.label': 'Просмотр',
    'accounts.card.user.tab.roles.field.table.header.create.label': 'Создание',
    'accounts.card.user.tab.roles.field.table.header.edit.label': 'Изменение',
    'accounts.card.user.tab.roles.field.table.header.delete.label': 'Удаление',

    'accounts.card.user.tab.statistic.table.header.date.label': 'Дата',
    'accounts.card.user.tab.statistic.table.header.time.label': 'Время',
    'accounts.card.user.tab.statistic.table.header.event.label': 'Событие',

    'accounts.roles-table.resource-column.row.trackers.text': 'Карточка трекера',
    'accounts.roles-table.resource-column.row.transports.text': 'Карточка транспорта',
    'accounts.roles-table.resource-column.row.employees.text': 'Карточка сотрудника',
    'accounts.roles-table.resource-column.row.users.text': 'Карточка пользователя',
    'accounts.roles-table.resource-column.row.roles.text': 'Роли пользователей',
    'accounts.roles-table.resource-column.row.geozones.text': 'Геозоны',
    'accounts.roles-table.resource-column.row.tracks.text': 'Треки',
    'accounts.roles-table.resource-column.row.reports.text': 'Дашборды',
    'accounts.roles-table.resource-column.row.map.text': 'Загрузка карт',
    'accounts.roles-table.resource-column.row.handbooks.text': 'Справочники',
    'accounts.roles-table.resource-column.row.buildings.text': 'Разметка зданий',
    'accounts.roles-table.resource-column.row.notifications.text': 'Уведомления',
    'accounts.roles-table.resource-column.row.poi.text': 'POI точки',
    'accounts.roles-table.resource-column.row.records.text': 'Отчеты',
    'accounts.roles-table.resource-column.row.messages.text': 'Сообщения',

    'accounts.card.user.footer.btn.delete.label': 'Удалить',
    'accounts.card.user.footer.btn.cancel.label': 'Отмена',
    'accounts.card.user.footer.btn.create.label': 'Создать',
    'accounts.card.user.footer.btn.update.label': 'Обновить',

    'accounts.card.roles.header.label': 'Создание роли пользователя',
    'accounts.card.roles.field.name.label': 'Название роли',
    'accounts.card.roles.field.name.placeholder': 'Введите название роли',
    'accounts.card.roles.table.header.resource.label': 'Ресурс',
    'accounts.card.roles.table.header.read.label': 'Просмотр',
    'accounts.card.roles.table.header.create.label': 'Создание',
    'accounts.card.roles.table.header.edit.label': 'Изменение',
    'accounts.card.roles.table.header.delete.label': 'Удаление',
    'accounts.card.roles.footer.btn.add-role.label': 'Назначить роль',
    'accounts.card.roles.footer.btn.cancel.label': 'Отмена',
    'accounts.card.roles.footer.btn.save.label': 'Сохранить',

    'accounts.card.roles.assign-role.header.label': 'Назначение роли',
    'accounts.card.roles.assign-role.available-users.text': 'Пользователи в системе',
    'accounts.card.roles.assign-role.available-users.btn.text': 'Выделить все',
    'accounts.card.roles.assign-role.chosen-users.text': 'Пользователи с ролью',
    'accounts.card.roles.assign-role.chosen-users.btn.text': 'Выделить все',
    'accounts.card.roles.assign-role.footer.btn.cancel.label': 'Отмена',
    'accounts.card.roles.assign-role.footer.btn.save.label': 'Сохранить',

    'accounts.card.alert.title.text': 'Закрытие карточки',
    'accounts.card.alert.info.text': 'На форме остались несохраненные данные.',

    'accounts.card.alert.title.delete.text': 'Удаление пользователя',
    'accounts.card.alert.info.delete.text':
      'Удаленного пользователя нельзя будет восстановить. Вы действительно хотите удалить пользователя?',
  },
  zh: {
    'accounts.button-block.btn.create.label': '创建',

    'accounts.button-block.btn.drop-list.users.label': '用户',
    'accounts.button-block.btn.drop-list.roles.label': '角色',

    'accounts.users-config-modal.title.text': '设置',
    'accounts.users-config-modal.ok-button.text': '运用',
    'accounts.users-config-modal.cancel-button.text': '废止',
    'accounts.users-config-modal.group-by-organization.text': '按组织',
    'accounts.users-config-modal.group-by-department.text': '按部门',
    'accounts.users-config-modal.group-by-position.text': '按职务',
    'accounts.users-list-tree.entity-organization.text': '组织',
    'accounts.users-list-tree.entity-department.text': '部门',
    'accounts.users-list-tree.entity-position.text': '职务',
    'accounts.users-list-filters.all-organizations.text': '所有组织',
    'accounts.users-list-filters.all-departments.text': '所有部门',
    'accounts.users-list-filters.all-positions.text': '所有职务',
    'accounts.users-list-filters.organizations-label.text': '组织',
    'accounts.users-list-filters.departments-label.text': '部门',
    'accounts.users-list-filters.positions-label.text': '职务',
    'accounts.users-list-tree.entity-without-name.text': '无名称',
    'accounts.users-list-tree.organization-ungrouped.text': '无组织',
    'accounts.users-list-tree.department-ungrouped.text': '无部门',
    'accounts.users-list-tree.position-ungrouped.text': '无职务',
    'accounts.users-list-tree.item.roles.more.text':
      '还 {count, plural, one {# 作用} two {# 两个作用} few {# 作用} other {# 两个作用}}',
    'accounts.users-list-columns.column-fullName.text': '姓名',
    'accounts.users-list-columns.column-login.text': '用户名',
    'accounts.users-list-columns.column-organization.text': '组织',
    'accounts.users-list-columns.column-department.text': '部门',
    'accounts.users-list-columns.column-position.text': '职务',
    'accounts.users-list-columns.column-roles.text': '配给作用',
    'accounts.users-config-modal.tab-grouping.text': '集团',
    'accounts.users-config-modal.tab-columns.text': '栏',
    'accounts.roles-list-columns.column-name.text': '作用名称',
    'accounts.roles-list-columns.column-createdBy.text': '作用创造者',
    'accounts.roles-list-columns.column-employeesCount.text': '有作用工作人数',
    'accounts.roles-list-columns.column-accessEntities.text': '作用参数',
    'accounts.roles-list-table.accessEntities.operation-read.text': '查看',
    'accounts.roles-list-table.accessEntities.operation-create.text': '创造',
    'accounts.roles-list-table.accessEntities.operation-update.text': '变化',
    'accounts.roles-list-table.accessEntities.operation-delete.text': '消除',
    'accounts.roles-list-table.accessEntities.more.text': ', ... + 还 {count}',
    'accounts.roles-list-table.assign-role-button.text': '指定作用',
    'accounts.users-table.header.user.label': '姓名',
    'accounts.users-table.header.login.label': '用户名',
    'accounts.users-table.header.company.label': '单位',
    'accounts.users-table.header.subdivision.label': '部门',
    'accounts.users-table.header.position.label': '职位',
    'accounts.users-table.header.roles.label': '分配的角色',
    'accounts.users-table.item.roles.more.text': '+ 更多角色：',
    'accounts.card.user.header.label': '用户卡',
    'accounts.card.user.tabs.main.text': '基本信息',
    'accounts.card.user.tabs.roles.text': '用户的角色',
    'accounts.card.user.tabs.statistic.text': '统计数据',
    'accounts.card.user.tab.general.field.surname.label': '姓',
    'accounts.card.user.tab.general.field.surname.placeholder': '输入姓',
    'accounts.card.user.tab.general.field.name.label': '名',
    'accounts.card.user.tab.general.field.name.placeholder': '输入名',
    'accounts.card.user.tab.general.field.patronymic.label': '父称',
    'accounts.card.user.tab.general.field.patronymic.placeholder': '输入父称',
    'accounts.card.user.tab.general.field.phone.label': '电话',
    'accounts.card.user.tab.general.field.phone.placeholder': '输入格式+7XXXXXXXXXX',
    'accounts.card.user.tab.general.field.sms-notify.text': '通过短信通知',
    'accounts.card.user.tab.general.field.login.label': '用户名',
    'accounts.card.user.tab.general.field.login.placeholder': '输入用户名',
    'accounts.card.user.tab.general.field.user-block.text': '用户被锁定',
    'accounts.card.user.tab.general.field.company.label': '单位',
    'accounts.card.user.tab.general.field.company.placeholder': '从列表中选择',
    'accounts.card.user.tab.general.field.subdivision.label': '部门',
    'accounts.card.user.tab.general.field.subdivision.placeholder': '从列表中选择',
    'accounts.card.user.tab.general.field.position.label': '职位',
    'accounts.card.user.tab.general.field.position.placeholder': '从列表中选择',
    'accounts.card.user.tab.general.field.email.label': '电子邮箱',
    'accounts.card.user.tab.general.field.email.placeholder': '输入电子邮件地址',
    'accounts.card.user.tab.general.field.email-notify.text': '通过电子邮件通知',
    'accounts.card.user.tab.general.field.pass.label': '密码',
    'accounts.card.user.tab.general.field.pass.placeholder': '输入密码',
    'accounts.card.user.tab.roles.field.roles-available.header.label': '角色',
    'accounts.card.user.tab.roles.field.table.header.resource.label': '资源',
    'accounts.card.user.tab.roles.field.table.header.read.label': '查看',
    'accounts.card.user.tab.roles.field.table.header.create.label': '创建',
    'accounts.card.user.tab.roles.field.table.header.edit.label': '更改',
    'accounts.card.user.tab.roles.field.table.header.delete.label': '删除',
    'accounts.card.user.tab.statistic.table.header.date.label': '日期',
    'accounts.card.user.tab.statistic.table.header.time.label': '时间',
    'accounts.card.user.tab.statistic.table.header.event.label': '事件',
    'accounts.roles-table.resource-column.row.tracker.text': '跟踪器卡',
    'accounts.roles-table.resource-column.row.vehicle.text': '交通卡',
    'accounts.roles-table.resource-column.row.employee.text': '员工卡',
    'accounts.roles-table.resource-column.row.user.text': '用户卡',
    'accounts.roles-table.resource-column.row.user-roles.text': '用户角色',
    'accounts.roles-table.resource-column.row.geofences.text': '地理围栏',
    'accounts.roles-table.resource-column.row.tracks.text': '轨迹',
    'accounts.roles-table.resource-column.row.reports.text': '仪表盘',
    'accounts.roles-table.resource-column.row.map-uploading.text': '加载地图',
    'accounts.roles-table.resource-column.row.dictionaries.text': '基础档案',
    'accounts.roles-table.resource-column.row.buildings.text': '建筑物标记',
    'accounts.roles-table.resource-column.row.notifications.text': '通知',
    'accounts.roles-table.resource-column.row.poi.text': '兴趣点',
    'accounts.roles-table.resource-column.row.records.text': '报告',
    'accounts.roles-table.resource-column.row.messages.text': '留言',
    'accounts.card.user.footer.btn.delete.label': '删除',
    'accounts.card.user.footer.btn.cancel.label': '取消',
    'accounts.card.user.footer.btn.create.label': '创建',
    'accounts.card.user.footer.btn.update.label': '更新',
    'accounts.card.roles.header.label': '创建用户角色',
    'accounts.card.roles.field.name.label': '角色名称',
    'accounts.card.roles.field.name.placeholder': '输入角色名称',
    'accounts.card.roles.table.header.resource.label': '资源',
    'accounts.card.roles.table.header.read.label': '查看',
    'accounts.card.roles.table.header.create.label': '创建',
    'accounts.card.roles.table.header.edit.label': '更改',
    'accounts.card.roles.table.header.delete.label': '删除',
    'accounts.card.roles.footer.btn.add-role.label': '分配角色',
    'accounts.card.roles.footer.btn.cancel.label': '取消',
    'accounts.card.roles.footer.btn.save.label': '保存',
    'accounts.card.roles.assign-role.header.label': '分配角色',
    'accounts.card.roles.assign-role.available-users.text': '系统中的用户',
    'accounts.card.roles.assign-role.available-users.btn.text': '全选',
    'accounts.card.roles.assign-role.chosen-users.text': '角色有的用户',
    'accounts.card.roles.assign-role.chosen-users.btn.text': '全选',
    'accounts.card.roles.assign-role.footer.btn.cancel.label': '取消',
    'accounts.card.roles.assign-role.footer.btn.save.label': '保存',
    'accounts.card.alert.title.text': '封闭卡',
    'accounts.card.alert.info.text': '表包括不保存数据',
    'accounts.card.alert.title.delete.text': '消除用户',
    'accounts.card.alert.info.delete.text': '已消除的用户无法恢复。 您真的要消除用户吗？',
  },
  ar: {
    'accounts.button-block.btn.create.label': 'إنشاء',

    'accounts.button-block.btn.drop-list.users.label': 'مستخدم',
    'accounts.button-block.btn.drop-list.roles.label': 'صلاحية',

    'accounts.users-config-modal.title.text': 'الإعدادات',
    'accounts.users-config-modal.ok-button.text': 'تنفيذ',
    'accounts.users-config-modal.cancel-button.text': 'ألغاء',
    'accounts.users-config-modal.group-by-organization.text': 'حسب المنظمات',
    'accounts.users-config-modal.group-by-department.text': 'حسب الأقسام',
    'accounts.users-config-modal.group-by-position.text': 'حسب المنصب',
    'accounts.users-list-tree.entity-organization.text': 'المنظمة',
    'accounts.users-list-tree.entity-department.text': 'التقسيم',
    'accounts.users-list-tree.entity-position.text': 'المنصب',
    'accounts.users-list-filters.all-organizations.text': 'جميع المنظمات',
    'accounts.users-list-filters.all-departments.text': 'كل الاقسام',
    'accounts.users-list-filters.all-positions.text': 'جميع المناصب',
    'accounts.users-list-filters.organizations-label.text': 'المنظمة',
    'accounts.users-list-filters.departments-label.text': 'التقسيم',
    'accounts.users-list-filters.positions-label.text': 'المنصب',
    'accounts.users-list-tree.entity-without-name.text': 'بدون أسم',
    'accounts.users-list-tree.organization-ungrouped.text': 'بدون منظمة',
    'accounts.users-list-tree.department-ungrouped.text': 'بدون أقسام',
    'accounts.users-list-tree.position-ungrouped.text': 'بدون منصب',
    'accounts.users-list-tree.item.roles.more.text':
      'أيضا {count, plural, one {# دور} two {# أدوار} few {# أدوار} other {# أدوار}}',
    'accounts.users-list-columns.column-fullName.text': 'الاسم بالكامل',
    'accounts.users-list-columns.column-login.text': 'تسجيل الدخول',
    'accounts.users-list-columns.column-organization.text': 'المنظمة',
    'accounts.users-list-columns.column-department.text': 'التقسيم',
    'accounts.users-list-columns.column-position.text': 'المنصب',
    'accounts.users-list-columns.column-roles.text': 'الأدوار الصادرة',
    'accounts.users-config-modal.tab-grouping.text': 'الجماعة',
    'accounts.users-config-modal.tab-columns.text': 'الأعمدة',
    'accounts.roles-list-columns.column-name.text': 'اسم الادوار',
    'accounts.roles-list-columns.column-createdBy.text': 'منشئ الادوار',
    'accounts.roles-list-columns.column-employeesCount.text': 'عدد الموظفين بالدور',
    'accounts.roles-list-columns.column-accessEntities.text': 'خيارات الادوار',
    'accounts.roles-list-table.accessEntities.operation-read.text': 'مشاهدة',
    'accounts.roles-list-table.accessEntities.operation-create.text': 'إنشاء',
    'accounts.roles-list-table.accessEntities.operation-update.text': 'تغيير',
    'accounts.roles-list-table.accessEntities.operation-delete.text': 'حذف',
    'accounts.roles-list-table.accessEntities.more.text': '، ... + المزيد {count}',
    'accounts.roles-list-table.assign-role-button.text': 'تعيين الدور',
    'accounts.users-table.header.user.label': 'اسم المستخدم بالكامل',
    'accounts.users-table.header.login.label': 'تسجيل دخول',
    'accounts.users-table.header.company.label': 'مؤسسة',
    'accounts.users-table.header.subdivision.label': 'شعبة',
    'accounts.users-table.header.position.label': 'وظيفة',
    'accounts.users-table.header.roles.label': 'الصلاحيات الممنوحة',
    'accounts.users-table.item.roles.more.text': '+ المزيد من الصلاحيات:',
    'accounts.card.user.header.label': 'بطاقة المستخدم',
    'accounts.card.user.tabs.main.text': 'معلومات عامة',
    'accounts.card.user.tabs.roles.text': 'صلاحيات المستخدم',
    'accounts.card.user.tabs.statistic.text': 'إحصاء',
    'accounts.card.user.tab.general.field.surname.label': 'اللقب',
    'accounts.card.user.tab.general.field.surname.placeholder': 'أكتب اللقب',
    'accounts.card.user.tab.general.field.name.label': 'الاسم',
    'accounts.card.user.tab.general.field.name.placeholder': 'أكتب الاسم',
    'accounts.card.user.tab.general.field.patronymic.label': 'اسم الأب',
    'accounts.card.user.tab.general.field.patronymic.placeholder': 'أكتب اسم الأب',
    'accounts.card.user.tab.general.field.phone.label': 'هاتف',
    'accounts.card.user.tab.general.field.phone.placeholder': 'صيغة الرقم +7XXXXXXXXXX',
    'accounts.card.user.tab.general.field.sms-notify.text': 'إشعار عبر SMS',
    'accounts.card.user.tab.general.field.login.label': 'تسجيل دخول',
    'accounts.card.user.tab.general.field.login.placeholder': 'أكتب اسم المستخدم',
    'accounts.card.user.tab.general.field.user-block.text': 'حظر المستخدم',
    'accounts.card.user.tab.general.field.company.label': 'مؤسسة',
    'accounts.card.user.tab.general.field.company.placeholder': 'اختر من القائمة',
    'accounts.card.user.tab.general.field.subdivision.label': 'شعبة',
    'accounts.card.user.tab.general.field.subdivision.placeholder': 'اختر من القائمة',
    'accounts.card.user.tab.general.field.position.label': 'وظيفة',
    'accounts.card.user.tab.general.field.position.placeholder': 'اختر من القائمة',
    'accounts.card.user.tab.general.field.email.label': 'بريد إلكتروني',
    'accounts.card.user.tab.general.field.email.placeholder': 'أكتب بريدًا إلكترونيًا',
    'accounts.card.user.tab.general.field.email-notify.text': 'إشعار عبر بريد إلكتروني',
    'accounts.card.user.tab.general.field.pass.label': 'كلمة مرور',
    'accounts.card.user.tab.general.field.pass.placeholder': 'أكتب كلمة مرور',
    'accounts.card.user.tab.roles.field.roles-available.header.label': 'صلاحيات',
    'accounts.card.user.tab.roles.field.table.header.resource.label': 'مورد',
    'accounts.card.user.tab.roles.field.table.header.read.label': 'عرض',
    'accounts.card.user.tab.roles.field.table.header.create.label': 'إنشاء',
    'accounts.card.user.tab.roles.field.table.header.edit.label': 'تعديل',
    'accounts.card.user.tab.roles.field.table.header.delete.label': 'حذف',
    'accounts.card.user.tab.statistic.table.header.date.label': 'التاريخ',
    'accounts.card.user.tab.statistic.table.header.time.label': 'الوقت',
    'accounts.card.user.tab.statistic.table.header.event.label': 'الحدث',
    'accounts.roles-table.resource-column.row.tracker.text': 'بطاقة المتتبع',
    'accounts.roles-table.resource-column.row.vehicle.text': 'بطاقة وسيلة النقل',
    'accounts.roles-table.resource-column.row.employee.text': 'بطاقة الموظف',
    'accounts.roles-table.resource-column.row.user.text': 'بطاقة المستخدم',
    'accounts.roles-table.resource-column.row.user-roles.text': 'صلاحيات المستخدم',
    'accounts.roles-table.resource-column.row.geofences.text': 'مناطق',
    'accounts.roles-table.resource-column.row.tracks.text': 'مسارات',
    'accounts.roles-table.resource-column.row.reports.text': 'لوحات المعلومات',
    'accounts.roles-table.resource-column.row.map-uploading.text': 'تحميل الخرائط',
    'accounts.roles-table.resource-column.row.dictionaries.text': 'مراجع',
    'accounts.roles-table.resource-column.row.buildings.text': 'تعليم المباني',
    'accounts.roles-table.resource-column.row.notifications.text': 'إشعارات',
    'accounts.roles-table.resource-column.row.poi.text': 'نقاط POI',
    'accounts.roles-table.resource-column.row.records.text': 'تقارير',
    'accounts.roles-table.resource-column.row.messages.text': 'رسائل',
    'accounts.card.user.footer.btn.delete.label': 'حذف',
    'accounts.card.user.footer.btn.cancel.label': 'إلغاء',
    'accounts.card.user.footer.btn.create.label': 'إنشاء',
    'accounts.card.user.footer.btn.update.label': 'تحديث',
    'accounts.card.roles.header.label': 'إنشاء صلاحية المستخدم',
    'accounts.card.roles.field.name.label': 'اسم الصلاحية',
    'accounts.card.roles.field.name.placeholder': 'أكتب اسمًا للصلاحية',
    'accounts.card.roles.table.header.resource.label': 'مورد',
    'accounts.card.roles.table.header.read.label': 'عرض',
    'accounts.card.roles.table.header.create.label': 'إنشاء',
    'accounts.card.roles.table.header.edit.label': 'تعديل',
    'accounts.card.roles.table.header.delete.label': 'حذف',
    'accounts.card.roles.footer.btn.add-role.label': 'تعيين الصلاحية',
    'accounts.card.roles.footer.btn.cancel.label': 'إلغاء',
    'accounts.card.roles.footer.btn.save.label': 'حفظ',
    'accounts.card.roles.assign-role.header.label': 'جاري تعيين الصلاحية',
    'accounts.card.roles.assign-role.available-users.text': 'المستخدمون في النظام',
    'accounts.card.roles.assign-role.available-users.btn.text': 'تحديد الكل',
    'accounts.card.roles.assign-role.chosen-users.text': 'المستخدمون ذوو الصلاحيات',
    'accounts.card.roles.assign-role.chosen-users.btn.text': 'تحديد الكل',
    'accounts.card.roles.assign-role.footer.btn.cancel.label': 'إلغاء',
    'accounts.card.roles.assign-role.footer.btn.save.label': 'حفظ',
    'accounts.card.alert.title.text': 'إغلاق البطاقة',
    'accounts.card.alert.info.text': 'تبقت بيانات غير محفوظة على النموذج.',
    'accounts.card.alert.title.delete.text': 'حذف المستخدم',
    'accounts.card.alert.info.delete.text': 'لا يمكن استعادة المستخدم المحذوف. هل أنت متأكد أنك تريد حذف المستخدم؟',
  },
  de: {
    'accounts.button-block.btn.create.label': 'Erstellen',

    'accounts.button-block.btn.drop-list.users.label': 'Benutzer',
    'accounts.button-block.btn.drop-list.roles.label': 'Rolle',

    'accounts.users-config-modal.title.text': 'Einstellungen',
    'accounts.users-config-modal.ok-button.text': 'Übernehmen',
    'accounts.users-config-modal.cancel-button.text': 'Abbrechen',
    'accounts.users-config-modal.group-by-organization.text': 'Nach Organisationen',
    'accounts.users-config-modal.group-by-department.text': 'Nach Abteilung',
    'accounts.users-config-modal.group-by-position.text': 'Nach Position',
    'accounts.users-list-tree.entity-organization.text': 'Organisation',
    'accounts.users-list-tree.entity-department.text': 'Abteilung',
    'accounts.users-list-tree.entity-position.text': 'Position',
    'accounts.users-list-filters.all-organizations.text': 'Alle Organisationen',
    'accounts.users-list-filters.all-departments.text': 'Alle Abteilungen',
    'accounts.users-list-filters.all-positions.text': 'Alle Positionen',
    'accounts.users-list-filters.organizations-label.text': 'Organisation',
    'accounts.users-list-filters.departments-label.text': 'Abteilung',
    'accounts.users-list-filters.positions-label.text': 'Position',
    'accounts.users-list-tree.entity-without-name.text': 'Ohne Benennung',
    'accounts.users-list-tree.organization-ungrouped.text': 'Ohne Organisation',
    'accounts.users-list-tree.department-ungrouped.text': 'Ohne Abteilung',
    'accounts.users-list-tree.position-ungrouped.text': 'Ohne Position',
    'accounts.users-list-tree.item.roles.more.text':
      'Noch {count, plural, one {# Rolle} two {# Rollen} few {# Rollen} other {# Rollen}}',
    'accounts.users-list-columns.column-fullName.text': 'Vollständiger Name',
    'accounts.users-list-columns.column-login.text': 'Benutzername',
    'accounts.users-list-columns.column-organization.text': 'Organisation',
    'accounts.users-list-columns.column-department.text': 'Abteilung',
    'accounts.users-list-columns.column-position.text': 'Position',
    'accounts.users-list-columns.column-roles.text': 'Ausgestellte Rollen',
    'accounts.users-config-modal.tab-grouping.text': 'Gruppierung',
    'accounts.users-config-modal.tab-columns.text': 'Spalte',
    'accounts.roles-list-columns.column-name.text': 'Rollenname',
    'accounts.roles-list-columns.column-createdBy.text': 'Rollenersteller',
    'accounts.roles-list-columns.column-employeesCount.text': 'Anzahl der Mitarbeiter mit einer Rolle',
    'accounts.roles-list-columns.column-accessEntities.text': 'Rollenparameter',
    'accounts.roles-list-table.accessEntities.operation-read.text': 'Ansicht',
    'accounts.roles-list-table.accessEntities.operation-create.text': 'Erstellen',
    'accounts.roles-list-table.accessEntities.operation-update.text': 'Ändern',
    'accounts.roles-list-table.accessEntities.operation-delete.text': 'Löschen',
    'accounts.roles-list-table.accessEntities.more.text': ', ... + noch {count}',
    'accounts.roles-list-table.assign-role-button.text': 'Rolle zuweisen',
    'accounts.users-table.header.user.label': 'Vollständiger Name',
    'accounts.users-table.header.login.label': 'Anmeldung',
    'accounts.users-table.header.company.label': 'Organisation',
    'accounts.users-table.header.subdivision.label': 'Niederlassung',
    'accounts.users-table.header.position.label': 'Dienststelle',
    'accounts.users-table.header.roles.label': 'Verliehene Rollen',
    'accounts.users-table.item.roles.more.text': '+ Noch Rollen:',
    'accounts.card.user.header.label': 'Benutzerkarte',
    'accounts.card.user.tabs.main.text': 'Grundinformation',
    'accounts.card.user.tabs.roles.text': 'Benutzerregeln',
    'accounts.card.user.tabs.statistic.text': 'Statistik',
    'accounts.card.user.tab.general.field.surname.label': 'Familienname',
    'accounts.card.user.tab.general.field.surname.placeholder': 'Bitte geben Sie ihren Familiennamen ein',
    'accounts.card.user.tab.general.field.name.label': 'Name',
    'accounts.card.user.tab.general.field.name.placeholder': 'Geben sie Ihren Namen ein',
    'accounts.card.user.tab.general.field.patronymic.label': 'Vatersname',
    'accounts.card.user.tab.general.field.patronymic.placeholder': 'Geben Sie den zweiten Vornamen ein',
    'accounts.card.user.tab.general.field.phone.label': 'Telefon',
    'accounts.card.user.tab.general.field.phone.placeholder': 'Eingabeformat: + 7XXXXXXXXXX',
    'accounts.card.user.tab.general.field.sms-notify.text': 'Per SMS benachrichtigen',
    'accounts.card.user.tab.general.field.login.label': 'Anmeldung',
    'accounts.card.user.tab.general.field.login.placeholder': 'Login eingeben',
    'accounts.card.user.tab.general.field.user-block.text': 'Benutzer blockieren',
    'accounts.card.user.tab.general.field.company.label': 'Organisation',
    'accounts.card.user.tab.general.field.company.placeholder': 'Aus der Liste wählen',
    'accounts.card.user.tab.general.field.subdivision.label': 'Niederlassung',
    'accounts.card.user.tab.general.field.subdivision.placeholder': 'Aus der Liste wählen',
    'accounts.card.user.tab.general.field.position.label': 'Dienststelle',
    'accounts.card.user.tab.general.field.position.placeholder': 'Aus der Liste wählen',
    'accounts.card.user.tab.general.field.email.label': 'E-Mail',
    'accounts.card.user.tab.general.field.email.placeholder': 'Geben sie ihre E-Mail-Adresse ein',
    'accounts.card.user.tab.general.field.email-notify.text': 'Benachrichtigen Sie per E-Mail',
    'accounts.card.user.tab.general.field.pass.label': 'Passwort',
    'accounts.card.user.tab.general.field.pass.placeholder': 'Passwort eingeben',
    'accounts.card.user.tab.roles.field.roles-available.header.label': 'Rollen',
    'accounts.card.user.tab.roles.field.table.header.resource.label': 'Ressource',
    'accounts.card.user.tab.roles.field.table.header.read.label': 'Aussicht',
    'accounts.card.user.tab.roles.field.table.header.create.label': 'Generierung',
    'accounts.card.user.tab.roles.field.table.header.edit.label': 'Änderung',
    'accounts.card.user.tab.roles.field.table.header.delete.label': 'Löschen',
    'accounts.card.user.tab.statistic.table.header.date.label': 'Datum',
    'accounts.card.user.tab.statistic.table.header.time.label': 'Zeit',
    'accounts.card.user.tab.statistic.table.header.event.label': 'Ereignis',
    'accounts.roles-table.resource-column.row.tracker.text': 'Verfolgungskarte',
    'accounts.roles-table.resource-column.row.vehicle.text': 'Transportkarte',
    'accounts.roles-table.resource-column.row.employee.text': 'Mitarbeiterkarte',
    'accounts.roles-table.resource-column.row.user.text': 'Benutzerkarte',
    'accounts.roles-table.resource-column.row.user-roles.text': 'Benutzerregeln',
    'accounts.roles-table.resource-column.row.geofences.text': 'Geofences',
    'accounts.roles-table.resource-column.row.tracks.text': 'Spuren',
    'accounts.roles-table.resource-column.row.reports.text': 'Dashboards',
    'accounts.roles-table.resource-column.row.map-uploading.text': 'Karten laden',
    'accounts.roles-table.resource-column.row.dictionaries.text': 'Verzeichnisse',
    'accounts.roles-table.resource-column.row.buildings.text': 'Gebäudemarkierungen',
    'accounts.roles-table.resource-column.row.notifications.text': 'Benachrichtigungen',
    'accounts.roles-table.resource-column.row.poi.text': 'POI-Punkte',
    'accounts.roles-table.resource-column.row.records.text': 'Berichte',
    'accounts.roles-table.resource-column.row.messages.text': 'Mitteilungen',
    'accounts.card.user.footer.btn.delete.label': 'Löschen',
    'accounts.card.user.footer.btn.cancel.label': 'Zurücksetzen',
    'accounts.card.user.footer.btn.create.label': 'Erstellen',
    'accounts.card.user.footer.btn.update.label': 'Aktualisieren',
    'accounts.card.roles.header.label': 'Benutzerrolle erstellen',
    'accounts.card.roles.field.name.label': 'Rollenname',
    'accounts.card.roles.field.name.placeholder': 'Geben Sie den Namen der Rolle ein',
    'accounts.card.roles.table.header.resource.label': 'Ressource',
    'accounts.card.roles.table.header.read.label': 'Aussicht',
    'accounts.card.roles.table.header.create.label': 'Generierung',
    'accounts.card.roles.table.header.edit.label': 'Änderung',
    'accounts.card.roles.table.header.delete.label': 'Löschen',
    'accounts.card.roles.footer.btn.add-role.label': 'Rolle angeben',
    'accounts.card.roles.footer.btn.cancel.label': 'Zurücksetzen',
    'accounts.card.roles.footer.btn.save.label': 'Speichern',
    'accounts.card.roles.assign-role.header.label': 'Rolleangabe',
    'accounts.card.roles.assign-role.available-users.text': 'Systembenutzer',
    'accounts.card.roles.assign-role.available-users.btn.text': 'Wählen Sie Alle',
    'accounts.card.roles.assign-role.chosen-users.text': 'Rollenbenutzer',
    'accounts.card.roles.assign-role.chosen-users.btn.text': 'Wählen Sie Alle',
    'accounts.card.roles.assign-role.footer.btn.cancel.label': 'Zurücksetzen',
    'accounts.card.roles.assign-role.footer.btn.save.label': 'Speichern',
    'accounts.card.alert.title.text': 'Karte schließen ',
    'accounts.card.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'accounts.card.alert.title.delete.text': 'Benutzer löschen',
    'accounts.card.alert.info.delete.text':
      'Der gelöschte Benutzer kann nicht wiederhergestellt werden. Möchten Sie den Benutzer wirklich löschen?',
  },
};
