import React, { Fragment, forwardRef } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { useSelector } from 'react-redux';

import Input from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import { Checkbox } from 'components/common/checkbox/checkbox';
import {
  PoiCardFreePointHandlers,
  PoiCardFreePointRefs,
  PoiCardFreePointData,
  PoiCardFreePointAdditionalData,
} from 'components/poi/utils/types';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../poiCard.module.scss';

const cx = classNames.bind(styles);

interface Props {
  storeData: PoiCardFreePointData;
  additionalData: PoiCardFreePointAdditionalData;
  handlers: PoiCardFreePointHandlers;
  refs: PoiCardFreePointRefs;
  invalidFields: string[];
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const PoiFreePointContent = ({ storeData, additionalData, refs, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <Fragment>
      <Input
        label={t('poi.card.free-point.field.name.label')}
        placeholder={t('poi.card.free-point.field.name.placeholder')}
        isValueError={invalidFields.includes('name')}
        isRequired={true}
        value={storeData.name}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeName(value)}
      />
      <TextArea
        label={t('poi.card.free-point.field.desc.label')}
        placeholder={t('poi.card.free-point.field.desc.placeholder')}
        isRequired={false}
        value={storeData.description}
        containerStyle={cx(styles.row, styles.rowTextarea)}
        handleChange={value => handlers.handleChangeDescription(value)}
        autoSetHeight
      />
      <Input
        label={t('poi.card.free-point.field.coords.label')}
        placeholder={t('poi.card.free-point.field.coords.placeholder')}
        isValueError={invalidFields.includes('coordinates')}
        isRequired={true}
        value={storeData.coordinates}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeCoordinates(value)}
        withGeoIcon
      />
      <DatePicker
        dateFormat="dd.MM.yyyy, HH:mm"
        selected={storeData.activePeriod ? new Date(storeData.activePeriod) : null}
        onChange={(date: Date) => handlers.handleChangeActivePeriod(date)}
        wrapperClassName={cx(styles.row, styles.rowDatepicker)}
        placeholderText={t('poi.card.free-point.field.next-date.placeholder')}
        customInput={
          <DatePickerCustomInput ref={refs.activePeriodRef} label={t('poi.card.free-point.field.next-date.label')} />
        }
        openToDate={storeData.activePeriod ? new Date(storeData.activePeriod) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('poi.card.free-point.field.next-date.time.caption')}
        minDate={new Date(additionalData.createdDate)}
        minTime={
          !differenceInCalendarDays(new Date(additionalData.createdDate), new Date(storeData.activePeriod))
            ? setHours(
                setMinutes(new Date(), getMinutes(new Date(additionalData.createdDate))),
                getHours(new Date(additionalData.createdDate))
              )
            : setHours(setMinutes(new Date(), 0), 0)
        }
        maxTime={setHours(setMinutes(new Date(), 59), 23)}
        disabled={false}
      />
      <div className={cx(styles.row, styles.rowCheckboxWrapper)} onClick={handlers.handleChangeIsRemovedInTheEnd}>
        <Checkbox checked={storeData.isRemovedInTheEnd} />
        <span className={styles.rowText}>{t('poi.card.free-point.field.auto-delete.text')}</span>
      </div>
    </Fragment>
  );
};

export default PoiFreePointContent;
