// функция загрузки файла (blob) на диск
export function downloadFileToDisk(blob: Blob, name: string) {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export async function downloadFileFromURIToDisk(blobURI: string, name: string) {
  await fetch(blobURI)
    .then(res => res.blob())
    .then(res => downloadFileToDisk(res, name));
}
