import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/img/info.svg';
import ReactTooltip from 'react-tooltip';
import styles from './infoElement.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  id: string;
  text: string;
  customStyle?: string;
};

export const InfoElement = ({ id, text, customStyle = '' }: Props): JSX.Element => (
  <div className={cx(styles.tooltip, customStyle)}>
    <InfoIcon data-tip data-for={id} />
    <ReactTooltip className={styles.customTooltip} id={id} place="top" type="light" effect="solid">
      <span>{text}</span>
    </ReactTooltip>
  </div>
);
