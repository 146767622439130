import { createSlice } from '@reduxjs/toolkit';
import {
  MonitoringState,
  ToggleSelectionOfUnitsPayload,
  TogglePayload,
  ToggleUnitsPayload,
  SortingType,
  ListConfig,
} from './interface';
import { Sorting } from 'utils/consts';

const initialState: MonitoringState = {
  selectedUnits: [],
  socialContactsUnits: [],
  watchingUnits: [],
  isAllExpanded: false,
  openedStructures: [],
  listConfig: [],
  sort: {
    type: Sorting.name,
    ascending: true,
  },
};

const monitoringSlice = createSlice({
  name: 'monitoringSlice',
  initialState,
  reducers: {
    setUsersMonitoringState: (state, { payload }) => {
      if (payload.selectedUnits) {
        state.selectedUnits = payload.selectedUnits;
      }
      if (payload.openedStructures) {
        state.openedStructures = payload.openedStructures;
      }
    },
    setOpenAll: (state, { payload }) => {
      state.isAllExpanded = true;
      state.openedStructures = payload;
    },
    setCloseAll: state => {
      state.openedStructures = [];
      state.isAllExpanded = false;
    },
    toggleSelectUnit: (state, { payload }: { payload: ToggleUnitsPayload }) => {
      if (payload.currentStatus) {
        state.selectedUnits = state.selectedUnits.filter(o => o !== payload.id);
      } else {
        state.selectedUnits.push(payload.id);
      }
    },
    toggleSelectionOfUnits: (state, { payload }: { payload: ToggleSelectionOfUnitsPayload }) => {
      if (payload.currentStatus) {
        state.selectedUnits = state.selectedUnits.filter(u => !payload.unitIds.includes(u));
      } else {
        state.selectedUnits = [
          ...state.selectedUnits,
          ...payload.unitIds.map(unitId => unitId).filter(u => !state.selectedUnits.includes(u)),
        ];
      }
    },
    selectBatchOfUnits: (state, { payload }: { payload: string[] }) => {
      state.selectedUnits = state.selectedUnits.concat(payload.filter(u => !state.selectedUnits.includes(u)));
    },
    setSelectedOneUnit: (state, { payload }: { payload: string }) => {
      state.selectedUnits = [payload];
    },
    unSelectAllUnits: state => {
      state.selectedUnits = [];
    },
    toggleWatchUnit: (state, { payload }: { payload: ToggleUnitsPayload }) => {
      if (payload.currentStatus) {
        state.watchingUnits = state.watchingUnits.filter(o => o !== payload.id);
      } else {
        state.watchingUnits.push(payload.id);
      }
    },
    setWatchAll: state => {
      state.watchingUnits = state.watchingUnits.concat(
        state.selectedUnits.filter(u => !state.watchingUnits.includes(u))
      );
    },
    unwatchAllUnits: state => {
      state.watchingUnits = [];
    },
    toggleOpenStructure: (state, { payload }: { payload: TogglePayload }) => {
      if (payload.currentStatus) {
        state.openedStructures = state.openedStructures.filter(o => o !== payload.id);
      } else {
        state.openedStructures.push(payload.id);
      }
    },
    selectSocialContactsUnits: (state, { payload }: { payload: string[] }) => {
      state.socialContactsUnits = payload;
    },
    setSort: (state, { payload }: { payload: SortingType }) => {
      const sort = state.sort;

      if (sort.type !== payload) {
        sort.type = payload;
        sort.ascending = false;
      } else {
        sort.ascending = !state.sort.ascending;
      }
    },
    updateListConfig: (state, { payload }: { payload: ListConfig }) => {
      state.listConfig = [...payload];
    },
  },
});

export const {
  toggleSelectionOfUnits,
  toggleSelectUnit,
  unSelectAllUnits,
  toggleWatchUnit,
  setOpenAll,
  setCloseAll,
  setWatchAll,
  unwatchAllUnits,
  selectBatchOfUnits,
  setSelectedOneUnit,
  setUsersMonitoringState,
  toggleOpenStructure,
  selectSocialContactsUnits,
  setSort,
  updateListConfig,
} = monitoringSlice.actions;

export default monitoringSlice.reducer;
