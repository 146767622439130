export default {
  en: {
    'multiple-select-group.deleted-value.text': 'Deleted',
    'multiple-select-group.deleted-value.tooltip.text': 'Resource not found or deleted',
  },
  ru: {
    'multiple-select-group.deleted-value.text': 'Удалено',
    'multiple-select-group.deleted-value.tooltip.text': 'Ресурс не найден или удален',
  },
  zh: {
    'multiple-select-group.deleted-value.text': '已消除',
    'multiple-select-group.deleted-value.tooltip.text': '未找到或消除资源',
  },
  ar: {
    'multiple-select-group.deleted-value.text': 'تمت ازالته',
    'multiple-select-group.deleted-value.tooltip.text': 'لم يتم العثور على المورد أو حذف',
  },
  de: {
    'multiple-select-group.deleted-value.text': 'gelöscht',
    'multiple-select-group.deleted-value.tooltip.text': 'Ressource nicht gefunden oder gelöscht',
  },
};
