// хук, который следит за шириной левого сайдбара и возвращает текущую ширину
import { useEffect, useState } from 'react';

import { ROUTES_WRAPPER_ID } from 'components/content/content';

function useObserveToWidthLeftSideBar(ref: React.RefObject<HTMLElement>) {
  // ref - контейнер, который находится в левой сайдбаре
  const [width, setWidth] = useState(0);

  // определяем обработчик для получения ширины левого сайдбара
  // и инициализируем начальное значение ширины
  useEffect(() => {
    // определим контейнер, содержащий интересующие элементы с [data-ellipsis] и [data-amount]
    const container = ref?.current;
    // наблюдатель за левым сайдбаром
    let observer: MutationObserver | null = null;
    // левый сайдбар
    let leftSideBar: Element | null = null;

    // обработчик слежения за шириной левого сайдбара
    function handler(mutations: MutationRecord[]) {
      const foundMutation = mutations.find(
        mutation => mutation.type === 'attributes' && mutation.attributeName === 'style'
      );

      if (foundMutation) {
        // левый сайдбар
        const lSideBar = foundMutation.target;
        // его текущая ширина
        const currentWidth = (lSideBar as Element).getBoundingClientRect().width;

        setWidth(currentWidth);
      }
    }

    // определим левый сайдбар
    leftSideBar = container?.closest(`#${ROUTES_WRAPPER_ID}`)?.parentElement ?? null;

    if (leftSideBar) {
      // создадим наблюдатель
      observer = new MutationObserver(handler);
      // навесим наблюдатель на левый сайдбар
      observer.observe(leftSideBar, {
        attributes: true,
        attributeOldValue: true,
      });
      // инициализируем ширину width хука
      const lSideBarStyle = leftSideBar.getAttribute('style');

      if (lSideBarStyle) {
        const currentWidthStr =
          lSideBarStyle
            ?.split(';')
            .map(oldValue => oldValue.trim())
            .find(oldValue => oldValue.startsWith('width:'))
            ?.split(' ')[1] || ''; // etc ["width:", "150px"]
        const currentWidth = parseInt(currentWidthStr);

        // если ширина имеет целочисленное значение
        if (currentWidth) {
          // установим новое значение ширины
          setWidth(currentWidth);
        } else {
          // иначе возьмем значение минимальной ширины (в случае перехода из вкладок где нет карты)
          // или, на крайний случай, ширину левого сайдбара
          const currentMinWidth = window.getComputedStyle(leftSideBar).minWidth;

          setWidth(parseInt(currentMinWidth) || leftSideBar.getBoundingClientRect().width);
        }
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref]);

  return width;
}

export default useObserveToWidthLeftSideBar;
