import React, { FC, useEffect, useState, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Geometry } from 'ol/geom';
import { Feature } from 'ol';

import DefaultMarker from 'assets/img/geozones/map/default-marker.svg';

import Input from 'components/common/input/input';
import Button from 'components/common/button/button';
import Select from 'components/common/select/select';
import { ColorPicker } from 'components/common/colorPicker/colorPicker';
import { TextArea } from 'components/common/textArea/textArea';
import { geoJSON } from 'components/map/utils';
import { GeozoneTypeOf, GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';
import InputIconFile from 'components/common/inputIconFile/inputIconFile';
import { SizeField } from '../sizeField/sizeField';

import { CreateGeozone, GeozoneAttributes } from 'reducers/geozones/interface';
import {
  addGeozone,
  clearSelected,
  removeChosenGeozone,
  removeGeozoneFeature,
  selectAllSaved,
  setGeozoneFeature,
  updateGeozone,
} from 'reducers/geozones';
import { RootState } from 'reducers';
import {
  cleanDrawColor,
  cleanDrawLabelColor,
  cleanDrawType,
  setDrawColor,
  setDrawLabelColor,
  setDrawType,
} from 'reducers/map';
import { showAlert } from 'reducers/modal';

import AccessEntity from 'utils/accessEntity';
import { DELETE_GEOZONE, geozoneColors, labelColors } from 'utils/consts';
import { getBase64FromImageSrc } from 'utils/getBase64FromImageSrc';

import styles from './geozoneCreation.module.scss';

type Props = {
  closeGeozoneCreation: () => void;
};

interface Options {
  value: number | string;
  label: string;
  data: {
    key: string;
  };
}

const GeozoneCreation: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const chosenGeozoneId = useSelector((state: RootState) => state.geozone.chosenGeozone);
  const chosenGeozone = useSelector((state: RootState) => state.geozone.geozones.find(g => g.id === chosenGeozoneId));
  const drawColor = useSelector((state: RootState) => state.map.drawColor);
  const labelColor = useSelector((state: RootState) => state.map.drawLabelColor);
  const geozonesPermission = useSelector((state: RootState) => state.user.permissions.geozones);
  const geozonesAccess = useMemo(() => new AccessEntity(geozonesPermission), [geozonesPermission]);

  const [geometricType, setGeometricType] = useState<GEOZONE_GEOMETRIC_TYPES | null>(
    chosenGeozone ? chosenGeozone.attributes.geometricType : null
  );
  const [name, setName] = useState(chosenGeozone ? chosenGeozone.attributes.name : '');
  const [geozoneGroup, setGeozoneGroup] = useState(
    chosenGeozone ? chosenGeozone.relationships.parentGroup.data?.id : null
  );
  const [description, setDescription] = useState(chosenGeozone ? chosenGeozone.attributes.description : '');
  const [icon, setIcon] = useState(chosenGeozone?.attributes.icon ?? null);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const geozonesGeometricTypes = useSelector((state: RootState) => state.geozone.geozonesGeometricTypes);
  const userId = useSelector((state: RootState) => state.user.id);
  const geozoneFeature = useSelector((state: RootState) => state.geozone.geozoneFeature);
  const groupsFromStore = useSelector((state: RootState) => state.geozone.groups);
  const groups = useMemo(
    () => groupsFromStore.map(gr => ({ label: gr.attributes.name, value: gr.id })),
    [groupsFromStore]
  );
  const drawType = useSelector((state: RootState) => state.map.drawType);
  const floorsFromStore = useSelector((state: RootState) => state.floors.floors);

  const floors = useMemo(
    () => floorsFromStore.map((fl, index) => ({ label: `${index + 1} этаж`, value: index })),
    [floorsFromStore]
  );

  const [floor, setFloor] = useState(!!floors.length ? floors[0].value : null);

  const geozoneGeometricTypesOptions: Options[] = geozonesGeometricTypes
    ? Object.keys(geozonesGeometricTypes).map((typeKey, i) => {
        const type = geozonesGeometricTypes[typeKey as keyof typeof geozonesGeometricTypes];

        return {
          label: t(type.description),
          value: i + 1,
          data: {
            key: typeKey as keyof typeof geozonesGeometricTypes,
          },
        };
      })
    : [];

  useEffect(() => {
    return () => {
      dispatch(cleanDrawColor());
      dispatch(cleanDrawLabelColor());
    };
  }, [dispatch]);

  useEffect(() => {
    if (geozonesGeometricTypes && geometricType) {
      const fullType = geozonesGeometricTypes[geometricType];

      if (fullType) {
        dispatch(setDrawType(fullType.olName));
      }
      return () => {
        dispatch(cleanDrawType());
        dispatch(removeChosenGeozone());
        dispatch(removeGeozoneFeature());
      };
    }
  }, [dispatch, geometricType, geozonesGeometricTypes]);

  const handleGeozoneColor = (val: string) => {
    dispatch(setDrawColor(val));
    if (geozoneFeature) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!Array.isArray(geozoneFeature)) {
        const feature = geozoneFeature.clone();
        feature.set('drawColor', val);
        dispatch(setGeozoneFeature(feature));
      } else {
        const features: Feature<Geometry>[] = [];
        geozoneFeature.forEach(f => {
          const feature = f.clone();
          feature.set('drawColor', val);
          features.push(feature);
        });
        dispatch(setGeozoneFeature(features));
      }
    }
  };

  const handleLabelColor = (val: string) => {
    dispatch(setDrawLabelColor(val));
    if (geozoneFeature) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!Array.isArray(geozoneFeature)) {
        const feature = geozoneFeature.clone();
        feature.set('drawLabelColor', val);
        dispatch(setGeozoneFeature(feature));
      } else {
        const features: Feature<Geometry>[] = [];
        geozoneFeature.forEach(f => {
          const feature = f.clone();
          feature.set('drawLabelColor', val);
          features.push(feature);
        });
        dispatch(setGeozoneFeature(features));
      }
    }
  };

  const handleTypeChange = (val: string) => {
    const id = parseInt(val);
    const foundType = geozoneGeometricTypesOptions.find(option => option.value === id)?.data.key;
    if (foundType) {
      setGeometricType(foundType as GEOZONE_GEOMETRIC_TYPES);
    } else {
      setGeometricType(null);
    }
  };

  const handleGroupChange = (val: string) => {
    if (!val) {
      return setGeozoneGroup(null);
    }
    return setGeozoneGroup(val);
  };

  const handleFloorChange = (val: string) => {
    if (!val) {
      return setFloor(null);
    }
    return setFloor(+val);
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!geometricType) {
      newInvalidFields.push('geometricType');
    }
    if (!name) {
      newInvalidFields.push('name');
    }

    return newInvalidFields;
  };

  const handleClose = () => {
    dispatch(selectAllSaved());
    dispatch(clearSelected());
    props.closeGeozoneCreation();
  };

  const handleSave = async () => {
    const validationResult = validateRequiredFields();

    try {
      const iconBase64 = await getBase64FromImageSrc(DefaultMarker);

      if (validationResult.length) {
        return setInvalidFields(validationResult);
      }

      if (chosenGeozone) {
        const geozone: GeozoneAttributes & { id: string; parentGroupId: number | null } = {
          ...chosenGeozone.attributes,
          name,
          description,
          geometricType: geometricType || GEOZONE_GEOMETRIC_TYPES.Line,
          geoJson: geozoneFeature
            ? Array.isArray(geozoneFeature)
              ? geoJSON.writeFeatures(geozoneFeature)
              : geoJSON.writeFeature(geozoneFeature)
            : null,
          icon: icon || iconBase64,
          id: chosenGeozoneId || '0',
          parentGroupId: Number(geozoneGroup) || null,
        };

        if (geozone.hasOwnProperty('ndData')) {
          delete geozone.ndData;
        }

        dispatch(updateGeozone(geozone));
      } else {
        const geozone: CreateGeozone = {
          name,
          description,
          geometricType: geometricType || GEOZONE_GEOMETRIC_TYPES.Line,
          geoJson: geozoneFeature
            ? Array.isArray(geozoneFeature)
              ? geoJSON.writeFeatures(geozoneFeature)
              : geoJSON.writeFeature(geozoneFeature)
            : null,
          icon: icon || iconBase64,
          type: GeozoneTypeOf.primary,
          parentGroupId: Number(geozoneGroup) || null,
          userId: parseInt(userId),
        };

        dispatch(addGeozone(geozone));
      }
    } catch (error) {
      toast.error('Error get base64 string');
    }

    handleClose();
  };

  const handleChangeIcon = (valueBase64: string) => {
    setIcon(valueBase64);
  };

  const openAlert = () => {
    dispatch(showAlert({ alertType: DELETE_GEOZONE }));
  };

  return (
    <>
      <div className={styles.container}>
        <Input
          label={t('geozones.geozone-card.field.name.label')}
          placeholder={t('geozones.geozone-card.field.name.placeholder')}
          value={name}
          handleInputChange={setName}
          isValueError={invalidFields.includes('name')}
          isRequired={true}
          customStyle={styles.inputWrap}
        />
        <div className={styles.row}>
          <InputIconFile handleChange={handleChangeIcon} defaultSrc={DefaultMarker} iconStr={icon} />
          <TextArea
            label={t('geozones.geozone-card.field.description.label')}
            placeholder={t('geozones.geozone-card.field.description.placeholder')}
            containerStyle={styles.textAreaWrapper}
            value={description ?? ''}
            handleChange={setDescription}
            areaStyle={styles.areaStyle}
          />
        </div>
        <Select
          label={t('geozones.geozone-card.field.type.placeholder')}
          options={geozoneGeometricTypesOptions}
          placeholder=""
          value={geozoneGeometricTypesOptions.find(typeOption => typeOption.data.key === geometricType)?.value ?? 3}
          isValueError={invalidFields.includes('geometricType')}
          isRequired={true}
          handleChange={handleTypeChange}
          style={styles.inputWrap}
        />
        <ColorPicker
          label={t('geozones.geozone-card.field.color-geozone.header.text')}
          colors={geozoneColors}
          onClick={handleGeozoneColor}
          value={drawColor ?? ''}
        />
        <ColorPicker
          label={t('geozones.geozone-card.field.color-title.header.text')}
          colors={labelColors}
          onClick={handleLabelColor}
          value={labelColor ?? ''}
        />
        <SizeField fieldType={drawType} />
        <Select
          label={t('geozones.geozone-card.field.groups.placeholder')}
          options={groups}
          value={geozoneGroup ?? ''}
          placeholder=""
          isRequired={false}
          handleChange={handleGroupChange}
          style={styles.inputWrap}
        />

        <Select
          label={t('geozones.geozone-card.field.floor.placeholder')}
          options={floors}
          placeholder=""
          value={floor ?? ''}
          isRequired={false}
          handleChange={handleFloorChange}
          style={styles.inputWrap}
        />

        <div className={styles.buttonsGroup}>
          {Boolean(chosenGeozone) && geozonesAccess.isAllowDelete() && (
            <Button red text={t('geozones.geozone-card.footer.btn.delete.label')} onClick={openAlert} />
          )}
          <Button white text={t('geozones.geozone-card.footer.btn.cancel.label')} onClick={handleClose} />
          <Button
            blue
            text={
              chosenGeozone
                ? t('geozones.geozone-card.footer.btn.update.label')
                : t('geozones.geozone-card.footer.btn.save.label')
            }
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  );
};

export default GeozoneCreation;
