import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Feature, Map, MapBrowserEvent } from 'ol';
import { easeOut } from 'ol/easing';
import { createEmpty as createEmptyExtent, extend as extentExtend } from 'ol/extent';
import { FeatureLike } from 'ol/Feature';

import { RootState } from 'reducers';
import { fetchOneEmployee } from 'reducers/employees';
import { fetchOneTransport } from 'reducers/transports';

import { MARKERS, MAX_ZOOM_DEFAULT_VALUE, MIN_ZOOM_DEFAULT_VALUE } from 'utils/consts';
import { pointStyleFunction } from '../utils';

export function useTrackerHandlers(map: Map) {
  // TODO: оставил ниже 2 строчки на случай добавления попапа для трекеров
  // const [showEmployeePopup, setShowEmployeePopup] = useState(false);
  // const [showTransportPopup, setShowTransportPopup] = useState(false);

  // const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 }, mapCoords: { x: 0, y: 0 } });

  const markerType = useSelector((state: RootState) => state.map.markerType);
  const dispatch = useDispatch();

  const handler = useCallback((e: MapBrowserEvent<UIEvent>) => {
    if (e.dragging) {
      return;
    }
    const { pixel, map } = e;
    const hit = map.hasFeatureAtPixel(pixel);

    if (hit) {
      const features = map.getFeaturesAtPixel(pixel);
      features.forEach((feature: FeatureLike) => {
        const properties = feature.getProperties();
        const values = properties?.features?.length ? properties.features[0].getProperties() : properties.values;
        const resolution = map.getView().getResolution() ?? 1;
        if (values && values.type === 'trackerMarker') {
          (feature as Feature).setStyle(pointStyleFunction(resolution, { ...values, isHit: true }));
        }
        return feature;
      });
    } else {
      map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
        const values = feature.getProperties().values;
        const resolution = map.getView().getResolution() ?? 1;
        if (values && values.type === 'trackerMarker') {
          (feature as Feature).setStyle(pointStyleFunction(resolution, { ...values, isHit: false }));
        }
        return feature;
      });
    }
  }, []);

  const clickHandler = useCallback(
    (e: MapBrowserEvent<PointerEvent>) => {
      // TODO: оставил ниже 2 строчки на случай добавления попапа для трекеров
      // setShowEmployeePopup(false);
      // setShowTransportPopup(false);
      const { pixel, map } = e;

      map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
        if (feature.get('features')) {
          if (feature.get('features').length === 1) {
            const values = feature.get('features')[0].getProperties('values');

            if (values.type === 'trackerMarker' && values.iconName === 'employeeIcon') {
              // TODO: оставил ниже 2 строчки на случай добавления попапа для трекеров
              // setShowEmployeePopup(true);
              // setShowTransportPopup(false);
              // setMarkerCoords({
              //   mouseCoords: { x: e.originalEvent.clientX, y: e.originalEvent.clientY },
              //   mapCoords: { x: values.mapCoords[0], y: values.mapCoords[1] },
              // });
              dispatch(fetchOneEmployee(values.info.id));
            }
            if (values.type === 'trackerMarker' && values.iconName === 'transportIcon') {
              // TODO: оставил ниже 2 строчки на случай добавления попапа для трекеров
              // setShowTransportPopup(true);
              // setShowEmployeePopup(false);
              // setMarkerCoords({
              //   mouseCoords: { x: e.originalEvent.clientX, y: e.originalEvent.clientY },
              //   mapCoords: { x: values.mapCoords[0], y: values.mapCoords[1] },
              // });
              dispatch(fetchOneTransport(values.info.id));
            }
          } else {
            const originalFeatures = feature.get('features');
            const extent = createEmptyExtent();

            originalFeatures.forEach(function (f: Feature) {
              const geometry = f.getGeometry();
              if (geometry) {
                extentExtend(extent, geometry.getExtent());
              }
            });

            map.getView().fit(extent, {
              size: map.getSize(),
              padding: [150, 150, 150, 150],
              duration: 2000,
              easing: easeOut,
              maxZoom: MAX_ZOOM_DEFAULT_VALUE - 1.5 * MIN_ZOOM_DEFAULT_VALUE,
            });
          }
        }
        return feature;
      });
    },
    [dispatch]
  );

  const cursorHandler = useCallback((evt: MapBrowserEvent<UIEvent>) => {
    if (evt.dragging) {
      return;
    }
    const { pixel, map } = evt;
    const hit = map.hasFeatureAtPixel(pixel);

    if (hit) {
      map.getTargetElement().style.cursor = 'pointer';
    } else {
      map.getTargetElement().style.cursor = '';
    }
  }, []);

  useEffect(() => {
    if (markerType === MARKERS.onmouseover) {
      map.on('pointermove', handler);
    } else {
      map.un('pointermove', handler);
    }

    return () => {
      map.un('pointermove', handler);
    };
  }, [map, markerType, handler]);

  useEffect(() => {
    map.on('click', clickHandler);
    map.on('pointermove', cursorHandler);

    return () => {
      map.un('click', clickHandler);
      map.un('pointermove', cursorHandler);
    };
  }, [map, clickHandler, cursorHandler]);
}
