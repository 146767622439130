import React, { useState, forwardRef, useEffect, useMemo, useCallback } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { setHours, setMinutes, getMinutes, getHours, differenceInCalendarDays } from 'date-fns';
import Select from 'components/common/select/select';
import Button from 'components/common/button/button';
import { ColorPicker } from 'components/common/colorPicker/colorPicker';
import styles from './trackCreation.module.scss';
import { Checkbox } from 'components/common/checkbox/checkbox';
import Input from 'components/common/input/input';
import DatePicker from 'react-datepicker';
import { getCurrentLocaleForDatePicker } from 'translate';
import { ONE_DAY, ONE_WEEK, ONE_MONTH } from 'utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { pickerColors } from 'utils/consts';
import { ReactComponent as Warning } from 'assets/img/warning.svg';

import classNames from 'classnames/bind';
import { fetchTrackers } from 'reducers/trackers';
import { fetchPostTracksGroup, fetchPutTrackById } from 'reducers/tracks';
import AccessEntity from 'utils/accessEntity';
import { fetchUnits } from 'reducers/trackableUnits';
import { Unit } from 'reducers/trackableUnits/interface';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name, @typescript-eslint/no-unused-vars
const DatePickerCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});

const periods = [
  {
    keyName: 'day',
    name: 'track.props.field.period.btn.day.text',
    dist: ONE_DAY,
  },
  { keyName: 'week', name: 'track.props.field.period.btn.week.text', dist: ONE_WEEK },
  { keyName: 'month', name: 'track.props.field.period.btn.month.text', dist: ONE_MONTH },
];

type TrackObject = {
  name: string;
  color: string;
  objectId: number;
};

interface TrackCreationInterface {
  handleClose: () => void;
}

const TrackCreation = ({ handleClose }: TrackCreationInterface) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { locale, userId } = useSelector((state: RootState) => state.user.userPreferences);
  const { trackers } = useSelector((state: RootState) => state.tracker);

  let trackableUnits: Unit[] = [];
  trackableUnits = useSelector((state: RootState) =>
    trackableUnits && !trackableUnits.length ? state.trackableUnit.trackableUnits : trackableUnits
  );

  const {
    chosenTrack,
    newTracksMeta: { total },
    newTracks,
  } = useSelector((state: RootState) => state.track);

  const permissionsTrackersFromStore = useSelector((state: RootState) => state.user.permissions.trackers);
  const permissionsEmployeesFromStore = useSelector((state: RootState) => state.user.permissions.employees);
  const permissionsTransportsFromStore = useSelector((state: RootState) => state.user.permissions.transports);

  const trackersPermission = useMemo(() => {
    return new AccessEntity(permissionsTrackersFromStore);
  }, [permissionsTrackersFromStore]);

  const employeesPermission = useMemo(() => {
    return new AccessEntity(permissionsEmployeesFromStore);
  }, [permissionsEmployeesFromStore]);

  const transportsPermission = useMemo(() => {
    return new AccessEntity(permissionsTransportsFromStore);
  }, [permissionsTransportsFromStore]);

  useEffect(() => {
    if (trackersPermission.isAllowRead() && trackers.length === 0) {
      dispatch(fetchTrackers());
    }
    if (employeesPermission.isAllowRead() && transportsPermission.isAllowRead() && trackableUnits.length === 0) {
      dispatch(fetchUnits());
    }
  }, [dispatch, employeesPermission, transportsPermission, trackersPermission, trackableUnits.length, trackers.length]);

  const tracks = newTracks.filter(tr => String(tr.attributes.trackGroupId) === chosenTrack);
  const trackTrackerId = parseInt(tracks[0]?.relationships.tracker.data.id);

  const [objects, setObjects] = useState<TrackObject[]>(
    tracks.length
      ? [
          {
            name: tracks[0].attributes.name,
            color: tracks[0].attributes.color,
            objectId: trackTrackerId,
          },
        ]
      : [
          {
            name: '',
            color: '',
            objectId: 0,
          },
        ]
  );

  const selectOptions = trackableUnits
    .filter(item => item.attributes.trackerId)
    .map(item => ({
      label: item.attributes.aggregatedName,
      value: item.attributes.trackerId!,
    }));

  const [isExistTrackId, setIsExistTrackId] = useState(!!objects.find(o => o.objectId));
  const [isTrackEdit, setIsTrackEdit] = useState(tracks.length ? !!trackTrackerId : false);
  const [isEmptyValue, setIsEmptyValue] = useState(false);
  const [isEmptyDateFrom, setIsEmptyDateFrom] = useState(false);
  const [isEmptyDateTo, setIsEmptyDateTo] = useState(false);
  const [tryToSave, setTryToSave] = useState(false);

  const setValueForSelect = (value: string, index: number) => {
    setIsExistTrackId(true);
    const numValue = parseInt(value);
    setObjects(
      objects.map((obj, ind) => {
        if (index === ind) {
          return { ...obj, objectId: numValue };
        }
        return obj;
      })
    );
  };

  const [period, setPeriod] = useState('');
  const [startDate, setStartDate] = useState(tracks.length ? tracks[0].attributes.periodFrom : '');
  const [trackGroupId] = useState(tracks.length ? tracks[0].attributes.trackGroupId : null);
  const [endDate, setEndDate] = useState(tracks.length ? tracks[0].attributes.periodTo : '');
  const [showDate, setShowDate] = useState(tracks.length ? Boolean(tracks[0].attributes.isShowDate) : false);
  const [showCoords, setShowCoords] = useState(tracks.length ? Boolean(tracks[0].attributes.isShowCoords) : false);

  const checkFields = useCallback(() => {
    objects.forEach(obj => {
      if (!obj.objectId) {
        setIsEmptyValue(true);
      }
    });
    if (!startDate) {
      setIsEmptyDateFrom(true);
    }
    if (!endDate) {
      setIsEmptyDateTo(true);
    }
  }, [endDate, startDate, objects]);

  useEffect(() => {
    if (tryToSave) {
      checkFields();
    }
  }, [checkFields, tryToSave, isEmptyValue, isEmptyDateFrom, isEmptyDateTo]);

  const saveColor = (newColor: string, ind: number) => {
    setObjects(
      objects.map((obj, index) => {
        if (index === ind) {
          return { ...obj, color: newColor };
        }
        return obj;
      })
    );
  };

  const handleChangePeriod = (newPeriod: typeof periods[0]) => {
    if (period === newPeriod.keyName) {
      return setPeriod('');
    }

    const now = Date.now();
    const newStartDate = new Date(now - newPeriod.dist);
    const newEndDate = new Date(now);

    setStartDate(newStartDate.toISOString());
    setEndDate(newEndDate.toISOString());

    return setPeriod(newPeriod.keyName);
  };

  const sendDataToServer = () => {
    if (!isEmptyValue && !isEmptyDateFrom && !isEmptyDateTo) {
      const objsToSave = objects.map(obj => {
        let trackName =
          trackableUnits.find(item => trackTrackerId === obj.objectId)?.attributes?.aggregatedName || null;
        if (!trackName) {
          trackName = trackers.find(item => item.id === obj.objectId)?.attributes.simNumber || '';
        }
        return {
          name: trackName,
          periodFrom: startDate,
          periodTo: endDate,
          color: obj.color,
          userId,
          trackerId: obj.objectId,
          isShowDate: showDate,
          isShowCoords: showCoords,
          trackGroupId,
        };
      });
      if (!isTrackEdit) {
        dispatch(
          fetchPostTracksGroup({
            trackGroupName: { name: `${t('track.props.field.track-group.part-of-name')}${total + 1}` },
            tracksData: objsToSave,
          })
        );
      } else {
        objsToSave.forEach(obj => {
          dispatch(
            fetchPutTrackById({
              id: String(tracks.find(tr => parseInt(tr.relationships.tracker.data.id) === obj.trackerId)?.id),
              data: obj,
            })
          );
        });
      }
      onClose();
    }
  };

  const handleSave = () => {
    if (!tryToSave) {
      setTryToSave(true);
    }
    sendDataToServer();
  };

  const onClose = () => {
    handleClose();
    setIsTrackEdit(false);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.objects}>
          {objects.map((obj, index) => (
            <div className={styles.objectWrap} key={`track-object-${index}`}>
              <div className={styles.objectFirstRow}>
                <div className={styles.selectWrapper}>
                  <Select
                    label={t('track.props.field.name.label')}
                    placeholder={t('track.props.field.name.placeholder')}
                    isRequired={true}
                    isValueError={!obj.objectId}
                    options={selectOptions}
                    value={obj.objectId}
                    handleChange={(value: string) => setValueForSelect(value, index)}
                  />
                </div>
              </div>

              <ColorPicker
                label={t('track.props.field.color.text')}
                colors={pickerColors}
                value={obj.color || pickerColors[0]}
                onClick={(value: string) => saveColor(value, index)}
                containerClearPadding
              />
            </div>
          ))}
        </div>

        <div className={styles.periodWrap}>
          <div className={styles.periodLabel}>{t('track.props.field.period.title.text')}</div>
          <div className={styles.periodButtonsGroup}>
            {periods.map((per, ind) => (
              <Button
                customStyle={styles.periodButton}
                text={t(per.name)}
                white
                long
                onClick={() => handleChangePeriod(per)}
                blue={period === per.keyName}
                key={`period-button-${ind}`}
              />
            ))}
          </div>
          <div className={styles.periodDatePikersContainer}>
            <div className={cx(styles.datepickerWrapper, !startDate && styles.tabInfoDatePickerEmpty)}>
              {!startDate && <Warning className={styles.iconWarning} />}
              <DatePicker
                dateFormat="dd.MM.yyyy, HH:mm"
                selected={startDate ? new Date(startDate) : null}
                onChange={(date: Date) => setStartDate(date.toISOString())}
                className={styles.tabInfoDatePicker}
                placeholderText={t('track.props.field.period.from.placeholder')}
                customInput={<DatePickerCustomInput label={t('track.props.field.period.from.label')} />}
                openToDate={startDate ? new Date(startDate) : new Date()}
                locale={getCurrentLocaleForDatePicker(locale)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption={t('track.props.field.period.from.time.text')}
                disabled={Boolean(period)}
                maxDate={endDate ? new Date(endDate) : null}
                minTime={setHours(setMinutes(new Date(), 0), 0)}
                maxTime={
                  !differenceInCalendarDays(new Date(startDate), new Date(endDate))
                    ? setHours(setMinutes(new Date(), getMinutes(new Date(endDate))), getHours(new Date(endDate)))
                    : setHours(setMinutes(new Date(), 59), 23)
                }
              />
            </div>
            <div className={cx(styles.datepickerWrapper, !endDate && styles.tabInfoDatePickerEmpty)}>
              {!startDate && <Warning className={styles.iconWarning} />}
              <DatePicker
                dateFormat="dd.MM.yyyy, HH:mm"
                selected={endDate ? new Date(endDate) : null}
                onChange={(date: Date) => setEndDate(date.toISOString())}
                className={styles.tabInfoDatePicker}
                placeholderText={t('track.props.field.period.to.placeholder')}
                customInput={<DatePickerCustomInput label={t('track.props.field.period.to.label')} />}
                openToDate={endDate ? new Date(endDate) : new Date()}
                locale={getCurrentLocaleForDatePicker(locale)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption={t('track.props.field.period.to.time.text')}
                disabled={Boolean(period)}
                minDate={startDate ? new Date(startDate) : null}
                minTime={
                  !differenceInCalendarDays(new Date(startDate), new Date(endDate))
                    ? setHours(setMinutes(new Date(), getMinutes(new Date(startDate))), getHours(new Date(startDate)))
                    : setHours(setMinutes(new Date(), 0), 0)
                }
                maxTime={setHours(setMinutes(new Date(), 59), 23)}
              />
            </div>
          </div>
        </div>

        <div className={styles.checkWrap}>
          <Checkbox checked={showDate} handleCheck={() => setShowDate(!showDate)} />{' '}
          <span className={styles.checkLabel}>{t('track.props.field.date-show.text')}</span>
        </div>
        <div className={cx(styles.checkWrap, styles.checkWrapBottom)}>
          <Checkbox checked={showCoords} handleCheck={() => setShowCoords(!showCoords)} />{' '}
          <span className={styles.checkLabel}>{t('track.props.field.coordinates-show.text')}</span>
        </div>
        <div className={styles.buttonsGroup}>
          <Button white text={t('track.props.footer.btn.cancel.text')} onClick={onClose} />
          <Button
            text={t('track.props.footer.btn.create.text')}
            blue
            onClick={handleSave}
            disabled={!(startDate && endDate && isExistTrackId)}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackCreation;
