import {
  GeozoneConditions,
  InterpositionConditions,
  NotificationAttributesBase,
  SensorConditions,
  AlertConditions,
} from 'reducers/notifications/interface';
import { NotificationTimeInfoStates } from 'components/notifications/utils/types';
export const NOTIFICATIONS_LOCATION_PATH = '/main/notifications';

export enum NOTIFICATION_TYPES_ENUM {
  alert = 'alert',
  geoZones = 'geoZones',
  interceptionOfObjects = 'interceptionOfObjects',
  sensorValue = 'sensorValue',
  videoAnalytics = 'videoAnalytics',
}

export enum NOTIFICATION_TYPES_ENUM_NAMES {
  alert = 'notifications.group.alert.text',
  geoZones = 'notifications.group.geofences.text',
  interceptionOfObjects = 'notifications.group.intersection.text',
  sensorValue = 'notifications.group.sensor.text',
  videoAnalytics = 'notifications.group.videoanalytics.text',
}

export enum ActiveTab {
  main = 'main',
  objects = 'objects',
  actions = 'actions',
  time = 'time',
  stats = 'stats',
}

export enum NOTIFICATED_HOW {
  popup = 'popup',
  email = 'email',
  sms = 'sms',
}

export enum NOTIFICATION_CONDITION_GEOZONE_FIXING_BY {
  enter = 'enter',
  exit = 'exit',
  absence = 'absence',
  longStaying = 'longStaying',
}

export enum NOTIFICATION_POSITION_BY_GEOZONE {
  inside = 'inside',
  outside = 'outside',
}

export enum NOTIFICATION_OBJECT_DISTANCE {
  conjunction = 'conjunction',
  remotion = 'remotion',
}

export enum NOTIFICATION_OBJECTS {
  employee = 'employee',
  transport = 'transport',
}

export enum NOTIFICATION_GEOZONE_ENTITY {
  Geozone = 'Geozone',
  GeozoneGroup = 'GeozoneGroup',
}

export const monthsNames = [
  'notifications.card.tabs.time.field.months.speed-btn.jan.text',
  'notifications.card.tabs.time.field.months.speed-btn.feb.text',
  'notifications.card.tabs.time.field.months.speed-btn.mar.text',
  'notifications.card.tabs.time.field.months.speed-btn.apr.text',
  'notifications.card.tabs.time.field.months.speed-btn.may.text',
  'notifications.card.tabs.time.field.months.speed-btn.jun.text',
  'notifications.card.tabs.time.field.months.speed-btn.jul.text',
  'notifications.card.tabs.time.field.months.speed-btn.aug.text',
  'notifications.card.tabs.time.field.months.speed-btn.sep.text',
  'notifications.card.tabs.time.field.months.speed-btn.oct.text',
  'notifications.card.tabs.time.field.months.speed-btn.nov.text',
  'notifications.card.tabs.time.field.months.speed-btn.dec.text',
];

export const daysOfWeekNames = [
  'notifications.card.tabs.time.field.days-week.speed-btn.mo.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.tu.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.we.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.th.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.fr.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.sa.text',
  'notifications.card.tabs.time.field.days-week.speed-btn.su.text',
];

const daysInAMount = 31;

export const evenDaysNames = Array.from(Array(daysInAMount).keys())
  .map(n => n + 1)
  .filter(n => n % 2);

export const oddDaysNames = Array.from(Array(daysInAMount).keys())
  .map(n => n + 1)
  .filter(n => n % 2 === 0);

export enum DatesStatus {
  selected = 'selected',
  no_selected = 'no_selected',
  disabled = 'disabled',
}

export const defaultNotificationAttributes: NotificationAttributesBase = {
  isActive: false,
  name: '',
  conditions: null,
  isEmailPushOn: false,
  isPopupPushOn: false,
  isSmsPushOn: false,
  notificationType: null,
  text: '',
  timeSettings: null,
  isGenerateReport: false,
  reportTemplateId: 0,
  reportStartDate: new Date().toISOString(),
  reportEndDate: new Date().toISOString(),
  isFoulFixing: false,
};

export const defaultConditions: {
  alert: AlertConditions;
  geozone: GeozoneConditions;
  interposition: InterpositionConditions;
  sensor: SensorConditions;
  videoanalytics: unknown;
} = {
  alert: {
    isSosFixing: false,
    isFallFixing: false,
    isAreaLimited: false,
    isInactionFixing: false,
    isDisconnectionFixing: false,
    coordsChangingLimit: 60, // в секундах
    dataGettingLimit: 60, // в секундах
    positionByGeozone: NOTIFICATION_POSITION_BY_GEOZONE.inside,
    geozones: [],
    isRestoreConnectionFixing: false,
    isGnssLost: false,
    gnssWaitingTime: 60, // в секундах
    isGnssRestoreFixing: false,
  },
  geozone: {
    fixingBy: NOTIFICATION_CONDITION_GEOZONE_FIXING_BY.enter,
    fixingTimeLimit: 0, // в секундах
    geozones: [],
    geozoneStandingLimit: 60, // в секундах
  },
  interposition: {
    objectsDistance: NOTIFICATION_OBJECT_DISTANCE.conjunction,
    radius: 0,
    fixingTimeLimit: 0, // в секундах
    isAreaLimited: false,
    positionByGeozone: NOTIFICATION_POSITION_BY_GEOZONE.inside,
    geozones: [],
  },
  sensor: {
    notificationSensorId: 0,
    nameOfSensor: '',
    notificationActivationId: 0,
    valueFrom: 0,
    valueTo: 0,
  },
  videoanalytics: {
    geozones: [],
    isGnssLost: false,
    isSosFixing: true,
    isFallFixing: false,
    isAreaLimited: true,
    gnssWaitingTime: 60,
    dataGettingLimit: 60,
    isInactionFixing: true,
    positionByGeozone: NOTIFICATION_POSITION_BY_GEOZONE.inside,
    coordsChangingLimit: 60,
    isGnssRestoreFixing: false,
    isDisconnectionFixing: false,
    isRestoreConnectionFixing: false,
  },
};

export const defaultTimeState: NotificationTimeInfoStates = {
  isActionPeriodOn: false,
  periodFrom: '',
  periodTo: '',
  isIntervalsOn: false,
  isPeriodLimitsOn: false,
  intervals: [],
  months: [],
  daysOfWeek: [],
  isDaysOfWeekLimitOn: true,
  isDaysOfMonthLimitOn: false,
  evenDays: [],
  oddDays: [],
  activationCountMax: 1,
  datesSelected: {
    months: monthsNames.map((m, i) => ({
      id: i + 1,
      text: m,
      status: DatesStatus.no_selected,
    })),
    daysOfWeek: daysOfWeekNames.map((d, i) => ({
      id: i + 1,
      text: d,
      status: DatesStatus.no_selected,
    })),
    evenDays: evenDaysNames.map((d, i) => ({
      id: i + 1,
      text: String(d),
      status: DatesStatus.no_selected,
    })),
    oddDays: oddDaysNames.map((d, i) => ({
      id: i + 1,
      text: String(d),
      status: DatesStatus.no_selected,
    })),
  },
};

export enum NOTIFICATION_SORTING_BY {
  name = 'name',
  triggered = 'triggered',
  objects = 'objects',
}

export const defaultNotificationListConfig = [
  {
    id: 1,
    name: 'notifications.grouping-modal.grouping.type.text',
    isActive: false,
    keyName: 'type',
  },
];

export const NOTIFICATION_MAX_LOGS = 1000;

export enum NOTIFICATION_SORTING_LOGS_MODE {
  ASC = 'ASC',
  DESC = 'DESC',
}
