import React, { ReactNode, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';
import { ReactComponent as DragIcon } from 'assets/img/three-lines-drag.svg';
import { ReactComponent as LeftArrow } from 'assets/img/monitoring/left-move-arrow.svg';
import { ReactComponent as RightArrow } from 'assets/img/monitoring/right-move-arrow.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Button from 'components/common/button/button';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal';
import { MonitoringListConfigItem } from 'reducers/user/interface';
import { updateUserPreferences } from 'reducers/user';
import { updateListConfig } from 'reducers/monitoring';

import styles from './listConfig.module.scss';

type SortableItemProps = {
  config: MonitoringListConfigItem;
  handleCheck: () => void;
  marginStyle: { marginLeft: string };
  showRightArrow: boolean;
  showLeftArrow: boolean;
  leftClick: () => void;
  rightClick: () => void;
};

type SortableListProps = {
  children: ReactNode;
  style: string;
};

const DragHandler = SortableHandle(() => <DragIcon className={styles.configItemDragIcon} />);

const SortableItem = SortableElement(
  ({ config, handleCheck, marginStyle, showLeftArrow, showRightArrow, leftClick, rightClick }: SortableItemProps) => {
    const t = useFormatMessage();

    return (
      <div className={styles.configItemWrapper} style={marginStyle}>
        {showLeftArrow && <LeftArrow className={styles.configItemArrow} onClick={leftClick} />}
        <div className={styles.configItem}>
          <Checkbox checked={config.isActive} handleCheck={handleCheck} />
          <DragHandler />
          <div className={styles.configItemName}>{t(config.name)}</div>
        </div>
        {showRightArrow && <RightArrow className={styles.configItemArrow} onClick={rightClick} />}
      </div>
    );
  }
);

const SortableList = SortableContainer(({ style, children }: SortableListProps) => (
  <div className={style}>{children}</div>
));

export function ListConfigModal() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const listConfig = useSelector((state: RootState) => state.monitoring.listConfig);

  const [localConfig, setLocalConfig] = useState(listConfig.map(c => ({ ...c })));

  const handleCheck = (id: number) => {
    setLocalConfig(localConfig.map(c => (c.id === id ? { ...c, isActive: !c.isActive } : c)));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    try {
      dispatch(updateUserPreferences({ monitoringListConfig: localConfig }));
      dispatch(updateListConfig(localConfig));
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleLeftClick = (ind: number) => {
    const removingParentIndex = localConfig[ind].parentIndexes[localConfig[ind].parentIndexes.length - 1];
    const newConfigs = localConfig.map((config, configIndex) => {
      if (configIndex === ind) {
        const parents = [...config.parentIndexes];
        parents.pop();
        return {
          ...config,
          parentIndexes: parents,
        };
      }
      if (configIndex === removingParentIndex) {
        return {
          ...config,
          childIndexes: config.childIndexes.filter(ch => !(ch === ind || localConfig[ind].childIndexes.includes(ch))),
        };
      }
      return {
        ...config,
        parentIndexes: config.parentIndexes.filter(par => par !== removingParentIndex),
      };
    });

    return setLocalConfig(newConfigs);
  };

  const handleRightClick = (ind: number) => {
    const parentId = localConfig
      .slice(0, ind)
      .reverse()
      .find(c => c.parentIndexes.length === localConfig[ind].parentIndexes.length)?.id;
    const addingParentIndex = localConfig.findIndex(c => c.id === parentId);
    const newConfigs = localConfig.map((config, configIndex) => {
      if (configIndex === ind) {
        return {
          ...config,
          parentIndexes: [...config.parentIndexes, addingParentIndex],
        };
      }
      if (configIndex === addingParentIndex) {
        const children = [...config.childIndexes, ind, ...localConfig[ind].childIndexes];
        return {
          ...config,
          childIndexes: Array.from(new Set(children)),
        };
      }

      if (config.parentIndexes.includes(ind)) {
        return {
          ...config,
          parentIndexes: [addingParentIndex, ...config.parentIndexes],
        };
      }
      return config;
    });

    return setLocalConfig(newConfigs);
  };

  const canMoveLeft = (config: MonitoringListConfigItem) => {
    return Boolean(config.parentIndexes.length);
  };

  const canMoveRight = (config: MonitoringListConfigItem, index: number) => {
    return index > 0 && config.parentIndexes.length < index && !config.parentIndexes.includes(index - 1);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newLocalConfig = localConfig.map((c, i) => {
      if (i === oldIndex) {
        return {
          ...c,
          isNextChild: newIndex === localConfig.length - 1 ? false : true,
          parentIndexes: [...localConfig[newIndex].parentIndexes],
          childIndexes: [...localConfig[newIndex].childIndexes],
        };
      } else if (i === newIndex) {
        return {
          ...c,
          isNextChild: oldIndex === localConfig.length - 1 ? false : true,
          parentIndexes: [...localConfig[oldIndex].parentIndexes],
          childIndexes: [...localConfig[oldIndex].childIndexes],
        };
      }
      return c;
    });
    setLocalConfig([...arrayMove(newLocalConfig, oldIndex, newIndex)]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{t('monitoring.list-group-modal.header.label')}</div>
        <div className={styles.headerCloseIcon}>
          <CloseIcon onClick={handleClose} />{' '}
        </div>
      </div>

      <SortableList style={styles.body} onSortEnd={onSortEnd} useDragHandle>
        {localConfig.map((config, index) => (
          <SortableItem
            key={`config-item-${index}`}
            config={config}
            index={index}
            handleCheck={() => handleCheck(config.id)}
            marginStyle={{ marginLeft: `${config.parentIndexes.length * 20}px` }}
            showLeftArrow={canMoveLeft(config)}
            showRightArrow={canMoveRight(config, index)}
            leftClick={() => handleLeftClick(index)}
            rightClick={() => handleRightClick(index)}
          />
        ))}
      </SortableList>

      <div className={styles.buttons}>
        <Button text={t('monitoring.list-group-modal.footer.btn.cancel.label')} white onClick={handleClose} />
        <Button text={t('monitoring.list-group-modal.footer.btn.apply.label')} blue onClick={handleSave} />
      </div>
    </div>
  );
}
