import React from 'react';
import cs from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';

import Button from 'components/common/button/button';

import styles from './modalTemplate.module.scss';

type Props = {
  className?: string;
  title: string;
  okLabel: string;
  cancelLabel: string;
  handleOkClick: () => void;
  handleClose: () => void;
};

const Modal: React.FC<Props> = ({ className, children, title, okLabel, cancelLabel, handleClose, handleOkClick }) => {
  return (
    <div className={cs(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{title}</div>
        <div className={styles.headerCloseIcon}>
          <CloseIcon onClick={handleClose} />
        </div>
      </div>

      <div className={styles.content}>{children}</div>

      <div className={styles.footer}>
        <Button text={cancelLabel} onClick={handleClose} white />
        <Button text={okLabel} onClick={handleOkClick} blue />
      </div>
    </div>
  );
};

export default Modal;
