import React, { useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal';
import { updateListConfig } from 'reducers/notifications';
import { updateUserPreferences } from 'reducers/user';
import { Checkbox } from 'components/common/checkbox/checkbox';
import Button from 'components/common/button/button';
import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';
import styles from './listConfig.module.scss';

export function ListConfigGroupingModal() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const listConfig = useSelector((state: RootState) => state.notifications.listConfig);

  const [localConfig, setLocalConfig] = useState(listConfig.map(c => ({ ...c })));

  const handleCheck = (id: number) => {
    setLocalConfig(localConfig.map(c => (c.id === id ? { ...c, isActive: !c.isActive } : c)));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    try {
      dispatch(updateUserPreferences({ notificationsListConfig: localConfig }));
      dispatch(updateListConfig(localConfig));
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{t('notifications.grouping-modal.header.label')}</div>
        <div className={styles.headerCloseIcon}>
          <CloseIcon onClick={handleClose} />
        </div>
      </div>

      {localConfig.map((config, index) => (
        <div key={`config-type_${index}`} className={styles.configItemWrapper}>
          <div className={styles.configItem} onClick={() => handleCheck(index + 1)}>
            <Checkbox checked={config.isActive} />
            <div className={styles.configItemName}>{t(config.name)}</div>
          </div>
        </div>
      ))}

      <div className={styles.buttons}>
        <Button text={t('notifications.grouping-modal.footer.btn.cancel.label')} white onClick={handleClose} />
        <Button text={t('notifications.grouping-modal.footer.btn.apply.label')} blue onClick={handleSave} />
      </div>
    </div>
  );
}
