import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import styles from './trackers.module.scss';
import { closeModal, closeAlert } from 'reducers/modal';
import { RootState } from 'reducers';
import { removeTracker, removeChosenTracker } from 'reducers/trackers';
import { TrackersModalControls } from './components/controls1/controls1';
import { TrackersModalHeader } from './components/header1/header1';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import AccessEntity from 'utils/accessEntity';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const DeleteTrackerModal = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { chosenTracker } = useSelector((state: RootState) => state.tracker);
  const trackersPermission = useSelector((state: RootState) => state.user.permissions.trackers);
  const trackersAccess = useMemo(() => new AccessEntity(trackersPermission), [trackersPermission]);

  const onDelete = () => {
    if (chosenTracker) {
      dispatch(removeTracker(chosenTracker.id));
    }
    dispatch(closeAlert());
    dispatch(removeChosenTracker());
    dispatch(closeModal());
  };
  const onCancelDelete = () => {
    dispatch(closeAlert());
  };
  return (
    <>
      <TrackersModalHeader title={t('trackers.alert.header.label')} onCancel={onCancelDelete} />
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('trackers.alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('trackers.alert.tracker-warning')}</p>
        <p className={styles.modalBodyDeleteText}>{t('trackers.alert.info.text')}</p>
        <p className={styles.modalBodyDeleteText}>{t('trackers.alert.confirm.text')}</p>
      </div>
      <TrackersModalControls
        isAllowDelete={trackersAccess.isAllowDelete()}
        isAllowCreate={trackersAccess.isAllowCreate()}
        isAllowUpdate={trackersAccess.isAllowUpdate()}
        onCancel={onCancelDelete}
        onDelete={onDelete}
      />
    </>
  );
};

export default DeleteTrackerModal;
