import React, { ReactElement, useRef } from 'react';
import styles from './uploadFile.module.scss';

export interface UploadFileProps {
  onUpload: (file: File) => void;
  children: ReactElement;
}

const UploadFile = (props: UploadFileProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputId = `inputFile-${Math.random()}`;

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef?.current && !!e.target.files?.length && props.onUpload) {
      props.onUpload(e.target.files[0]);
      inputRef.current.value = '';
    }
  };

  const handleClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className={styles.wrapper}>
      <label htmlFor={inputId} onClick={handleClick}>
        {props.children}
      </label>
      <input id={inputId} type="file" className={styles.input} onChange={handleUpload} ref={inputRef} />
    </div>
  );
};

export default UploadFile;
