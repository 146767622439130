import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import Button from 'components/common/button/button';
import styles from './handbooksAlert.module.scss';
import { closeAlert } from 'reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import classNames from 'classnames/bind';
import { deleteElement, deleteTransportDriversHandbookData } from 'reducers/handbooks';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

const cx = classNames.bind(styles);

export default function HandbooksDeleteAlert() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { chosenHandbookName, chosenElement } = useSelector((state: RootState) => state.handbooks);
  const chosenTransportDriverId = useSelector(
    (state: RootState) => state.handbooks.transportDriversHandbook.chosenElementId
  );

  const handleCancel = () => {
    dispatch(closeAlert());
  };

  const handleContinue = () => {
    if (chosenHandbookName) {
      switch (chosenHandbookName.keyName) {
        case HANDBOOK_KEYS.transportDrivers:
          if (chosenTransportDriverId) {
            dispatch(deleteTransportDriversHandbookData(chosenTransportDriverId));
          }
        default:
          if (chosenElement) {
            dispatch(
              deleteElement({
                id: chosenElement.id,
                key: HANDBOOK_KEYS[chosenHandbookName.keyName as HANDBOOK_KEYS],
              })
            );
          }
      }
    }

    handleCancel();
  };
  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <div>{t('handbooks.alert.header.label')}</div>
        <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={handleCancel} />
      </div>
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('handbooks.alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('handbooks.alert.info.text')}</p>
        <p className={styles.modalBodyDeleteText}>{t('handbooks.alert.question.text')}</p>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('handbooks.alert.footer.btn.no.label')} onClick={handleCancel} />
        <Button blue text={t('handbooks.alert.footer.btn.yes.label')} onClick={handleContinue} />
      </div>
    </Fragment>
  );
}
