import timezones from './timezones';

export default {
  en: {
    ...timezones.en,
  },
  ru: {
    ...timezones.ru,
  },
  zh: {
    ...timezones.zh,
  },
  ar: {
    ...timezones.ar,
  },
  de: {
    ...timezones.de,
  },
};
