import React, { useState, ChangeEvent, useEffect } from 'react';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import classNames from 'classnames/bind';
import styles from './rangeInputs.module.scss';

const cx = classNames.bind(styles);

const messages = {
  separator: '-',
  inputPattern: '',
};

const MAX_LENGTH_INPUT = 10;

type Props = {
  valueFrom: string;
  valueTo: string;

  handleInputFromChange?: (value: string) => void;
  handleInputToChange?: (value: string) => void;

  maxLength?: number;
  pattern?: string;
  separator?: string;
  isRequired?: boolean;
  isValueError?: boolean;
  label?: string;

  placeholderFrom: string;
  placeholderTo: string;

  wrapperStyle?: string;
  labelStyle?: string;
  separatorStyle?: string;
  inputsWrapperStyle?: string;
  inputStyle?: string;

  disabled?: boolean;
};

const RangeInputs = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const wrapClasses = cx(styles.wrapper, props.wrapperStyle, {
    [styles.wrapperWarning]: props.isValueError,
    [styles.wrapperFocused]: isFocused,
  });
  const labelClasses = cx(styles.label, props.labelStyle, {
    [styles.labelFocused]: isFocused,
  });
  const separatorClasses = cx(styles.separator, props.separatorStyle);
  const inputsWrapperClasses = cx(styles.inputsWrapper, props.inputsWrapperStyle);
  const inputClasses = cx(styles.input, props.inputStyle);

  const refFrom = React.createRef<HTMLInputElement>();
  const refTo = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (refFrom) {
      const input = refFrom.current;
      if (!input) {
        return;
      }
      if (props.valueFrom) {
        input.size = props.valueFrom.length;
      } else {
        input.size = props.placeholderFrom.length || 1;
      }
    }
  }, [refFrom, props.valueFrom, props.placeholderFrom]);

  useEffect(() => {
    if (refTo) {
      const input = refTo.current;
      if (!input) {
        return;
      }
      if (props.valueTo) {
        input.size = props.valueTo.length || 1;
      } else {
        input.size = props.placeholderTo.length || 1;
      }
    }
  }, [refTo, props.valueTo, props.placeholderTo]);

  const handleChangeFrom = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    const value = target.value;
    if (!isNaN(Number(value))) {
      target.size = value.length || props.placeholderFrom.length || 1;
      return props.handleInputFromChange ? props.handleInputFromChange(value) : null;
    }
    return props.handleInputFromChange ? props.handleInputFromChange(props.valueFrom) : null;
  };

  const handleChangeTo = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    const value = target.value;
    if (!isNaN(Number(value))) {
      target.size = value.length || props.placeholderTo.length || 1;
      return props.handleInputToChange ? props.handleInputToChange(value) : null;
    }
    return props.handleInputToChange ? props.handleInputToChange(props.valueFrom) : null;
  };

  return (
    <div className={wrapClasses}>
      {!!props.label && <div className={labelClasses}>{props.label}</div>}
      {props.isRequired && <Warning className={styles.iconWarning} />}
      <div className={inputsWrapperClasses}>
        <input
          value={props.valueFrom}
          type="text"
          placeholder={props.placeholderFrom}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={ev => handleChangeFrom(ev)}
          required={props.isRequired}
          maxLength={props.maxLength || MAX_LENGTH_INPUT}
          pattern={props.pattern || messages.inputPattern}
          disabled={props.disabled || false}
          className={inputClasses}
          ref={refFrom}
        />
        <div className={separatorClasses}>{props.separator || messages.separator}</div>
        <input
          value={props.valueTo}
          type="text"
          placeholder={props.placeholderTo}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={ev => handleChangeTo(ev)}
          required={props.isRequired}
          maxLength={props.maxLength || MAX_LENGTH_INPUT}
          pattern={props.pattern || messages.inputPattern}
          disabled={props.disabled || false}
          className={inputClasses}
          ref={refTo}
        />
      </div>
    </div>
  );
};

export default RangeInputs;
