export default {
  en: {
    'timezones.Pacific/Midway': 'Midway Island, Samoa',
    'timezones.Pacific/Honolulu': 'Hawaii',
    'timezones.America/Juneau': 'Alaska',
    'timezones.America/Boise': 'Boise',
    'timezones.America/Dawson': 'Dawson, Yukon',
    'timezones.America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
    'timezones.America/Phoenix': 'Arizona',
    'timezones.America/Chicago': 'Chicago',
    'timezones.America/Regina': 'Saskatchewan',
    'timezones.America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
    'timezones.America/Belize': 'Belize',
    'timezones.America/Detroit': 'Detroit',
    'timezones.America/Bogota': 'Bogota, Lima, Quito',
    'timezones.America/Caracas': 'Caracas, La Paz',
    'timezones.America/Santiago': 'Santiago',
    'timezones.America/St_Johns': 'Newfoundland and Labrador',
    'timezones.America/Sao_Paulo': 'Brasilia',
    'timezones.America/Tijuana': 'Tijuana',
    'timezones.America/Montevideo': 'Montevideo',
    'timezones.America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
    'timezones.America/Godthab': 'Greenland',
    'timezones.America/Los_Angeles': 'Los Angeles',
    'timezones.Atlantic/Azores': 'Azores',
    'timezones.Atlantic/Cape_Verde': 'Cape Verde Islands',
    'timezones.Europe/London': 'Edinburgh, London',
    'timezones.Europe/Dublin': 'Dublin',
    'timezones.Europe/Lisbon': 'Lisbon',
    'timezones.Africa/Casablanca': 'Casablanca, Monrovia',
    'timezones.Atlantic/Canary': 'Canary Islands',
    'timezones.Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Prague',
    'timezones.Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
    'timezones.Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
    'timezones.Europe/Amsterdam': 'Amsterdam, Berlin, Rome, Stockholm, Vienna',
    'timezones.Africa/Algiers': 'Algiers',
    'timezones.Europe/Bucharest': 'Bucharest',
    'timezones.Africa/Cairo': 'Cairo',
    'timezones.Europe/Helsinki': 'Helsinki, Kiev, Riga, Vilnius',
    'timezones.Europe/Athens': 'Athens, Minsk',
    'timezones.Asia/Jerusalem': 'Jerusalem',
    'timezones.Africa/Harare': 'Harare, Pretoria',
    'timezones.Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
    'timezones.Asia/Kuwait': 'Kuwait, Riyadh',
    'timezones.Africa/Nairobi': 'Nairobi',
    'timezones.Asia/Baghdad': 'Baghdad',
    'timezones.Asia/Tehran': 'Tehran',
    'timezones.Asia/Dubai': 'Abu Dhabi, Muscat',
    'timezones.Asia/Baku': 'Baku, Tbilisi, Yerevan',
    'timezones.Asia/Kabul': 'Kabul',
    'timezones.Asia/Yekaterinburg': 'Ekaterinburg',
    'timezones.Asia/Karachi': 'Islamabad, Karachi, Tashkent',
    'timezones.Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
    'timezones.Asia/Kathmandu': 'Kathmandu',
    'timezones.Asia/Dhaka': 'Astana, Dhaka',
    'timezones.Asia/Colombo': 'Sri Jayawardenepura Kotte',
    'timezones.Asia/Almaty': 'Almaty, Novosibirsk',
    'timezones.Asia/Rangoon': 'Yangon Rangoon',
    'timezones.Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
    'timezones.Asia/Krasnoyarsk': 'Krasnoyarsk',
    'timezones.Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
    'timezones.Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
    'timezones.Asia/Taipei': 'Taipei',
    'timezones.Australia/Perth': 'Perth',
    'timezones.Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
    'timezones.Asia/Seoul': 'Seoul',
    'timezones.Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
    'timezones.Asia/Yakutsk': 'Yakutsk',
    'timezones.Australia/Darwin': 'Darwin',
    'timezones.Australia/Adelaide': 'Adelaide',
    'timezones.Australia/Sydney': 'Canberra, Melbourne, Sydney',
    'timezones.Australia/Brisbane': 'Brisbane',
    'timezones.Australia/Hobart': 'Hobart',
    'timezones.Asia/Vladivostok': 'Vladivostok',
    'timezones.Pacific/Guam': 'Guam, Port Moresby',
    'timezones.Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
    'timezones.Asia/Kamchatka': 'Kamchatka, Marshall Islands',
    'timezones.Pacific/Fiji': 'Fiji Islands',
    'timezones.Pacific/Auckland': 'Auckland, Wellington',
    'timezones.Pacific/Tongatapu': "Nuku'alofa",
  },
  ru: {
    'timezones.Pacific/Midway': 'Остров Мидуэй, Самоа',
    'timezones.Pacific/Honolulu': 'Гавайи',
    'timezones.America/Juneau': 'Аляска',
    'timezones.America/Boise': 'Бойсе',
    'timezones.America/Dawson': 'Доусон, Юкон',
    'timezones.America/Chihuahua': 'Чиуауа, Ла-Пас, Масатлан',
    'timezones.America/Phoenix': 'Аризона',
    'timezones.America/Chicago': 'Чикаго',
    'timezones.America/Regina': 'Саскачеван',
    'timezones.America/Mexico_City': 'Гвадалахара, Мехико, Монтеррей',
    'timezones.America/Belize': 'Белиз',
    'timezones.America/Detroit': 'Детройт',
    'timezones.America/Bogota': 'Богота, Лима, Кито',
    'timezones.America/Caracas': 'Каракас, Ла-Пас',
    'timezones.America/Santiago': 'Сантьяго',
    'timezones.America/St_Johns': 'Ньюфаундленд и Лабрадор',
    'timezones.America/Sao_Paulo': 'Бразилиа',
    'timezones.America/Tijuana': 'Тихуана',
    'timezones.America/Montevideo': 'Монтевидео',
    'timezones.America/Argentina/Buenos_Aires': 'Буэнос-Айрес, Джорджтаун',
    'timezones.America/Godthab': 'Гренландия',
    'timezones.America/Los_Angeles': 'Лос-Анджелес',
    'timezones.Atlantic/Azores': 'Азорские острова',
    'timezones.Atlantic/Cape_Verde': 'Кабо-Верде',
    'timezones.Europe/London': 'Эдинбург, Лондон',
    'timezones.Europe/Dublin': 'Дублин',
    'timezones.Europe/Lisbon': 'Лиссабон',
    'timezones.Africa/Casablanca': 'Касабланка, Монровия',
    'timezones.Atlantic/Canary': 'Канарские острова',
    'timezones.Europe/Belgrade': 'Белград, Братислава, Будапешт, Прага',
    'timezones.Europe/Sarajevo': 'Сараево, Скопье, Варшава, Загреб',
    'timezones.Europe/Brussels': 'Брюссель, Копенгаген, Мадрид, Париж',
    'timezones.Europe/Amsterdam': 'Амстердам, Берлин, Рим, Стокгольм, Вена',
    'timezones.Africa/Algiers': 'Алжир',
    'timezones.Europe/Bucharest': 'Бухарест',
    'timezones.Africa/Cairo': 'Каир',
    'timezones.Europe/Helsinki': 'Хельсинки, Киев, Рига, Вильнюс',
    'timezones.Europe/Athens': 'Афины, Минск',
    'timezones.Asia/Jerusalem': 'Иерусалим',
    'timezones.Africa/Harare': 'Хараре, Претория',
    'timezones.Europe/Moscow': 'Стамбул, Москва, Санкт-Петербург, Волгоград',
    'timezones.Asia/Kuwait': 'Кувейт, Эр-Рияд',
    'timezones.Africa/Nairobi': 'Найроби',
    'timezones.Asia/Baghdad': 'Багдад',
    'timezones.Asia/Tehran': 'Тегеран',
    'timezones.Asia/Dubai': 'Абу-Даби, Маскат',
    'timezones.Asia/Baku': 'Баку, Тбилиси, Ереван',
    'timezones.Asia/Kabul': 'Кабул',
    'timezones.Asia/Yekaterinburg': 'Екатеринбург',
    'timezones.Asia/Karachi': 'Исламабад, Карачи, Ташкент',
    'timezones.Asia/Kolkata': 'Ченнаи, Калькутта, Мумбаи, Нью-Дели',
    'timezones.Asia/Kathmandu': 'Катманду',
    'timezones.Asia/Dhaka': 'Астана, Дакка',
    'timezones.Asia/Colombo': 'Шри-Джаяварденепура-Котте',
    'timezones.Asia/Almaty': 'Алматы, Новосибирск',
    'timezones.Asia/Rangoon': 'Янгон Рангун',
    'timezones.Asia/Bangkok': 'Бангкок, Ханой, Джакарта',
    'timezones.Asia/Krasnoyarsk': 'Красноярск',
    'timezones.Asia/Shanghai': 'Пекин, Чунцин, Гонконг, Урумчи',
    'timezones.Asia/Kuala_Lumpur': 'Куала-Лумпур, Сингапур',
    'timezones.Asia/Taipei': 'Тайбэй',
    'timezones.Australia/Perth': 'Перт',
    'timezones.Asia/Irkutsk': 'Иркутск, Улан-Батор',
    'timezones.Asia/Seoul': 'Сеул',
    'timezones.Asia/Tokyo': 'Осака, Саппоро, Токио',
    'timezones.Asia/Yakutsk': 'Якутск',
    'timezones.Australia/Darwin': 'Дарвин',
    'timezones.Australia/Adelaide': 'Аделаида',
    'timezones.Australia/Sydney': 'Канберра, Мельбурн, Сидней',
    'timezones.Australia/Brisbane': 'Брисбен',
    'timezones.Australia/Hobart': 'Хобарт',
    'timezones.Asia/Vladivostok': 'Владивосток',
    'timezones.Pacific/Guam': 'Гуам, Порт-Морсби',
    'timezones.Asia/Magadan': 'Магадан, Соломоновы Острова, Новая Каледония',
    'timezones.Asia/Kamchatka': 'Камчатка, Маршалловы Острова',
    'timezones.Pacific/Fiji': 'Острова Фиджи',
    'timezones.Pacific/Auckland': 'Окленд, Веллингтон',
    'timezones.Pacific/Tongatapu': 'Нукуалофа',
  },
  zh: {
    'timezones.Pacific/Midway': '中途岛，萨摩亚',
    'timezones.Pacific/Honolulu': '夏威夷群岛',
    'timezones.America/Juneau': '阿拉斯加',
    'timezones.America/Boise': '博伊西',
    'timezones.America/Dawson': '道森，育空',
    'timezones.America/Chihuahua': '奇瓦瓦，拉巴斯，马萨特兰',
    'timezones.America/Phoenix': '亚利桑那',
    'timezones.America/Chicago': '芝加哥',
    'timezones.America/Regina': '萨斯喀彻温',
    'timezones.America/Mexico_City': '瓜达拉哈拉，墨西哥，蒙特雷',
    'timezones.America/Belize': '伯利兹 ',
    'timezones.America/Detroit': '底特律',
    'timezones.America/Bogota': '波哥大，利马，基多',
    'timezones.America/Caracas': '加拉加斯，拉巴斯',
    'timezones.America/Santiago': '圣地亚哥',
    'timezones.America/St_Johns': '纽芬兰与拉布拉多',
    'timezones.America/Sao_Paulo': '巴西利亚',
    'timezones.America/Tijuana': '蒂华纳',
    'timezones.America/Montevideo': '蒙得维的亚',
    'timezones.America/Argentina/Buenos_Aires': '布宜诺斯艾利斯，乔治敦',
    'timezones.America/Godthab': '格陵兰岛',
    'timezones.America/Los_Angeles': '洛杉矶',
    'timezones.Atlantic/Azores': '亚速尔群岛',
    'timezones.Atlantic/Cape_Verde': '佛得角',
    'timezones.Europe/London': '爱丁堡，伦敦',
    'timezones.Europe/Dublin': '都柏林',
    'timezones.Europe/Lisbon': '里斯本',
    'timezones.Africa/Casablanca': '卡萨布兰卡，蒙罗维亚',
    'timezones.Atlantic/Canary': '加那利群岛',
    'timezones.Europe/Belgrade': '贝尔格莱德，布拉迪斯拉发，布达佩斯，布拉格',
    'timezones.Europe/Sarajevo': '萨拉热窝，斯科普里，华沙，萨格勒布',
    'timezones.Europe/Brussels': '布鲁塞尔，哥本哈根，马德里，巴黎',
    'timezones.Europe/Amsterdam': '阿姆斯特丹，柏林，罗马，斯德哥尔摩，维也纳',
    'timezones.Africa/Algiers': '阿尔及利亚',
    'timezones.Europe/Bucharest': '布加勒斯特',
    'timezones.Africa/Cairo': '开罗',
    'timezones.Europe/Helsinki': '赫尔辛基，基辅，里加，维尔纽斯',
    'timezones.Europe/Athens': '雅典，明斯克',
    'timezones.Asia/Jerusalem': '耶路撒冷',
    'timezones.Africa/Harare': '哈拉雷，比勒陀利亚',
    'timezones.Europe/Moscow': '伊斯坦布尔，莫斯科，圣彼得堡，伏尔加格勒',
    'timezones.Asia/Kuwait': '科威特，利雅德',
    'timezones.Africa/Nairobi': '内罗毕',
    'timezones.Asia/Baghdad': '巴格达',
    'timezones.Asia/Tehran': '德黑兰',
    'timezones.Asia/Dubai': '阿布扎比，马斯喀特',
    'timezones.Asia/Baku': '巴库，第比里斯，埃里温',
    'timezones.Asia/Kabul': '喀布尔',
    'timezones.Asia/Yekaterinburg': '叶卡捷琳堡',
    'timezones.Asia/Karachi': '伊斯兰堡，卡拉奇，塔什干',
    'timezones.Asia/Kolkata': '千耐，加尔各答，孟买，新德里',
    'timezones.Asia/Kathmandu': '加德满都',
    'timezones.Asia/Dhaka': '阿斯塔纳，达卡',
    'timezones.Asia/Colombo': '斯里贾亚瓦德纳普拉科特',
    'timezones.Asia/Almaty': '阿拉木图，新西伯利亚',
    'timezones.Asia/Rangoon': '仰光',
    'timezones.Asia/Bangkok': '曼谷，河内，雅加达',
    'timezones.Asia/Krasnoyarsk': '克拉斯诺亚尔斯克',
    'timezones.Asia/Shanghai': '北京，重庆，香港，乌鲁木齐',
    'timezones.Asia/Kuala_Lumpur': '吉隆坡，新加坡',
    'timezones.Asia/Taipei': '台北',
    'timezones.Australia/Perth': '伯斯',
    'timezones.Asia/Irkutsk': '伊尔库茨克，乌兰巴托',
    'timezones.Asia/Seoul': '首尔',
    'timezones.Asia/Tokyo': '大阪，札幌，东京',
    'timezones.Asia/Yakutsk': '雅库茨克',
    'timezones.Australia/Darwin': '达尔文',
    'timezones.Australia/Adelaide': '阿得雷德',
    'timezones.Australia/Sydney': '堪培拉，墨尔本，悉尼',
    'timezones.Australia/Brisbane': '布里斯班',
    'timezones.Australia/Hobart': '荷巴特',
    'timezones.Asia/Vladivostok': '符拉迪沃斯托克',
    'timezones.Pacific/Guam': '关岛，莫尔兹比港',
    'timezones.Asia/Magadan': '马加丹，所罗门群岛，新喀里多尼亚',
    'timezones.Asia/Kamchatka': '堪察加半岛，马绍尔群岛',
    'timezones.Pacific/Fiji': '菲吉群岛',
    'timezones.Pacific/Auckland': '奥克兰，惠灵顿',
    'timezones.Pacific/Tongatapu': '努库阿洛法',
  },
  ar: {
    'timezones.Pacific/Midway': 'جزيرة ميدواي, ساموا',
    'timezones.Pacific/Honolulu': 'هاواي',
    'timezones.America/Juneau': 'ألاسكا',
    'timezones.America/Boise': 'بويز',
    'timezones.America/Dawson': 'داوسون ، يوكون',
    'timezones.America/Chihuahua': 'شيواوا ، لاباز ، مازاتلان',
    'timezones.America/Phoenix': 'أريزونا',
    'timezones.America/Chicago': 'شيكاغو',
    'timezones.America/Regina': 'ساسكاتشوان',
    'timezones.America/Mexico_City': 'غوادالاخارا ، مكسيكو سيتي ، مونتيري',
    'timezones.America/Belize': 'بليز',
    'timezones.America/Detroit': 'ديترويت',
    'timezones.America/Bogota': 'بوغوتا ، ليما ، كيتو',
    'timezones.America/Caracas': 'كراكاس, لاباس',
    'timezones.America/Santiago': 'سانتيغو',
    'timezones.America/St_Johns': 'نيوفاوندلاند ولابرادور',
    'timezones.America/Sao_Paulo': 'برازيليا',
    'timezones.America/Tijuana': 'تيخوانا',
    'timezones.America/Montevideo': 'مونتيفيديو',
    'timezones.America/Argentina/Buenos_Aires': 'بوينس آيريس ، جورج تاون',
    'timezones.America/Godthab': '  الأرض الخضراء',
    'timezones.America/Los_Angeles': '  لوس أنجلوس',
    'timezones.Atlantic/Azores': '  جزر الأزور',
    'timezones.Atlantic/Cape_Verde': '  الرأس الأخضر',
    'timezones.Europe/London': '  ادنبره ، لندن',
    'timezones.Europe/Dublin': '  دبلن',
    'timezones.Europe/Lisbon': '  لشبونة',
    'timezones.Africa/Casablanca': '  الدار البيضاء ، مونروفيا',
    'timezones.Atlantic/Canary': '  جزر الكناري',
    'timezones.Europe/Belgrade': 'بلغراد ، براتيسلافا ، بودابست ، براغ',
    'timezones.Europe/Sarajevo': '  سراييفو ، سكوبي ، وارسو ، زغرب',
    'timezones.Europe/Brussels': '  بروكسل ، كوبنهاغن ، مدريد ، باريس',
    'timezones.Europe/Amsterdam': '  أمستردام ، برلين ، روما ، ستوكهولم ، فيينا',
    'timezones.Africa/Algiers': '  الجزائر',
    'timezones.Europe/Bucharest': '  بوخارست',
    'timezones.Africa/Cairo': '  القاهرة',
    'timezones.Europe/Helsinki': '  هلسنكي ، كييف ، ريغا ، فيلنيوس',
    'timezones.Europe/Athens': '  أثينا ، مينسك',
    'timezones.Asia/Jerusalem': '  بيت المقدس',
    'timezones.Africa/Harare': '  هراري ، بريتوريا',
    'timezones.Europe/Moscow': '  اسطنبول ، موسكو ، سانت بطرسبرغ ، فولغوغراد',
    'timezones.Asia/Kuwait': '  الكويت ، الرياض',
    'timezones.Africa/Nairobi': '  نيروبي',
    'timezones.Asia/Baghdad': '  بغداد',
    'timezones.Asia/Tehran': '  طهران',
    'timezones.Asia/Dubai': '  أبو ظبي ، مسقط',
    'timezones.Asia/Baku': '  باكو وتبليسي ويريفان',
    'timezones.Asia/Kabul': '  كابول',
    'timezones.Asia/Yekaterinburg': '  يكاترينبورغ',
    'timezones.Asia/Karachi': '  إسلام أباد ، كراتشي ، طشقند',
    'timezones.Asia/Kolkata': '  تشيناي ، كولكاتا ، مومباي ، نيودلهي',
    'timezones.Asia/Kathmandu': '  كاتماندو',
    'timezones.Asia/Dhaka': 'أستانا ، دكا',
    'timezones.Asia/Colombo': '  سري جايواردنابورا كوتي',
    'timezones.Asia/Almaty': '  ألماتي ، نوفوسيبيرسك',
    'timezones.Asia/Rangoon': '  يانجون رانجون',
    'timezones.Asia/Bangkok': '  بانكوك ، هانوي ، جاكرتا',
    'timezones.Asia/Krasnoyarsk': '  كراسنويارسك',
    'timezones.Asia/Shanghai': '  بكين ، تشونغتشينغ ، هونغ كونغ ، أورومتشي',
    'timezones.Asia/Kuala_Lumpur': '  كوالالمبور ، سنغافورة',
    'timezones.Asia/Taipei': '  تايبيه',
    'timezones.Australia/Perth': '  بيرث',
    'timezones.Asia/Irkutsk': '  إيركوتسك ، أولان باتور',
    'timezones.Asia/Seoul': '  سيول',
    'timezones.Asia/Tokyo': '  أوساكا ، سابورو ، طوكيو',
    'timezones.Asia/Yakutsk': '  ياكوتسك',
    'timezones.Australia/Darwin': '  داروين',
    'timezones.Australia/Adelaide': '  اديلايد',
    'timezones.Australia/Sydney': '  كانبيرا ، ملبورن ، سيدني',
    'timezones.Australia/Brisbane': '  بريسبان',
    'timezones.Australia/Hobart': '  هوبارت',
    'timezones.Asia/Vladivostok': '  فلاديفوستوك',
    'timezones.Pacific/Guam': '  غوام ، بورت مورسبي',
    'timezones.Asia/Magadan': '  ماجادان ، جزر سليمان ، كاليدونيا الجديدة',
    'timezones.Asia/Kamchatka': '  كامتشاتكا ، جزر مارشال',
    'timezones.Pacific/Fiji': 'جزر فيجي',
    'timezones.Pacific/Auckland': '  أوكلاند ، ويلينجتون',
    'timezones.Pacific/Tongatapu': '  نوكوالوفا',
  },
  de: {
    'timezones.Pacific/Midway': 'Midway Island, Samoa',
    'timezones.Pacific/Honolulu': 'Hawaii',
    'timezones.America/Juneau': 'Alaska',
    'timezones.America/Boise': 'boise',
    'timezones.America/Dawson': 'Dawson, Yukon',
    'timezones.America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
    'timezones.America/Phoenix': 'Arizona',
    'timezones.America/Chicago': 'Chicago',
    'timezones.America/Regina': 'Saskatchewan',
    'timezones.America/Mexico_City': 'Guadalajara, Mexiko-Stadt, Monterrey',
    'timezones.America/Belize': 'Belize',
    'timezones.America/Detroit': 'Detroit',
    'timezones.America/Bogota': 'Bogotá, Lima, Quito',
    'timezones.America/Caracas': 'Caracas, LaPaz',
    'timezones.America/Santiago': 'Santiago',
    'timezones.America/St_Johns': 'Neufundland und Labrador',
    'timezones.America/Sao_Paulo': 'Brasilien',
    'timezones.America/Tijuana': 'Tijuana',
    'timezones.America/Montevideo': 'Montevideo',
    'timezones.America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
    'timezones.America/Godthab': 'Grönland',
    'timezones.America/Los_Angeles': 'Los Angeles',
    'timezones.Atlantic/Azores': 'Azoren',
    'timezones.Atlantic/Cape_Verde': 'Kap Verde',
    'timezones.Europe/London': 'Edinburg, London',
    'timezones.Europe/Dublin': 'Dublin',
    'timezones.Europe/Lisbon': 'Lissabon',
    'timezones.Africa/Casablanca': 'Casablanca, Monrovia',
    'timezones.Atlantic/Canary': 'Kanarische Inseln',
    'timezones.Europe/Belgrade': 'Belgrad, Bratislava, Budapest, Prag',
    'timezones.Europe/Sarajevo': 'Sarajevo, Skopje, Warschau, Zagreb',
    'timezones.Europe/Brussels': 'Brüssel, Kopenhagen, Madrid, Paris',
    'timezones.Europe/Amsterdam': 'Amsterdam, Berlin, Rom, Stockholm, Wien',
    'timezones.Africa/Algiers': 'Algerien',
    'timezones.Europe/Bucharest': 'Bukarest',
    'timezones.Africa/Cairo': 'Kairo',
    'timezones.Europe/Helsinki': 'Helsinki, Kiew, Riga, Vilnius',
    'timezones.Europe/Athens': 'Athen, Minsk',
    'timezones.Asia/Jerusalem': 'Jerusalem',
    'timezones.Africa/Harare': 'Harare, Pretoria',
    'timezones.Europe/Moscow': 'Istanbul, Moskau, St. Petersburg, Wolgograd',
    'timezones.Asia/Kuwait': 'Kuwait, Riad',
    'timezones.Africa/Nairobi': 'Nairobi',
    'timezones.Asia/Baghdad': 'Bagdad',
    'timezones.Asia/Tehran': 'Teheran',
    'timezones.Asia/Dubai': 'Abu Dhabi, Maskat',
    'timezones.Asia/Baku': 'Baku, Tiflis, Eriwan',
    'timezones.Asia/Kabul': 'Kabul',
    'timezones.Asia/Yekaterinburg': 'Jekaterinburg',
    'timezones.Asia/Karachi': 'Islamabad, Karatschi, Taschkent',
    'timezones.Asia/Kolkata': 'Chennai, Kolkata, Mumbai, Neu-Delhi',
    'timezones.Asia/Kathmandu': 'Kathmandu',
    'timezones.Asia/Dhaka': 'Astana, Dhaka',
    'timezones.Asia/Colombo': 'Sri Jayewardenepura Kotte',
    'timezones.Asia/Almaty': 'Almaty, Nowosibirsk',
    'timezones.Asia/Rangoon': 'Yangon Rangun',
    'timezones.Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
    'timezones.Asia/Krasnoyarsk': 'Krasnojarsk',
    'timezones.Asia/Shanghai': 'Peking, Chongqing, Hongkong, Ürümqi',
    'timezones.Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapur',
    'timezones.Asia/Taipei': 'Taipeh',
    'timezones.Australia/Perth': 'Perth',
    'timezones.Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
    'timezones.Asia/Seoul': 'Seoul',
    'timezones.Asia/Tokyo': 'Osaka, Sapporo, Tokio',
    'timezones.Asia/Yakutsk': 'Jakutsk',
    'timezones.Australia/Darwin': 'Darwin',
    'timezones.Australia/Adelaide': 'Adelheid',
    'timezones.Australia/Sydney': 'Canberra, Melbourne, Sydney',
    'timezones.Australia/Brisbane': 'Brisbane',
    'timezones.Australia/Hobart': 'Hobart',
    'timezones.Asia/Vladivostok': 'Wladiwostok',
    'timezones.Pacific/Guam': 'Guam, Port Moresby',
    'timezones.Asia/Magadan': 'Magadan, Salomonen, Neukaledonien',
    'timezones.Asia/Kamchatka': 'Kamtschatka, Marshallinseln',
    'timezones.Pacific/Fiji': 'Fidschi-Inseln',
    'timezones.Pacific/Auckland': 'Auckland, Wellington',
    'timezones.Pacific/Tongatapu': 'Nukualofa',
  },
};
