export default {
  en: {
    'track.button-block.btn.label': 'Create',

    'track.list.header.track.label': 'Track',
    'track.list.track-name.text': 'Track №',
    'track.list.tracker-name.text': 'Tracker №',

    'track.props.field.name.label': 'Monitoring object',
    'track.props.field.name.placeholder': 'Specify an object',
    'track.props.field.color.text': 'Track’s color',
    'track.props.field.add.btn.text': 'Add tracked object',
    'track.props.field.period.title.text': 'Tracking period',
    'track.props.field.period.btn.day.text': 'Day',
    'track.props.field.period.btn.week.text': 'Week',
    'track.props.field.period.btn.month.text': 'Month',
    'track.props.field.period.from.label': 'Date and time of track, from',
    'track.props.field.period.from.placeholder': 'Track from',
    'track.props.field.period.from.time.text': 'Time',
    'track.props.field.period.to.label': 'Date and time of track, to',
    'track.props.field.period.to.placeholder': 'Track to',
    'track.props.field.period.to.time.text': 'Time',
    'track.props.field.date-show.text': 'Show points date/time on the map',
    'track.props.field.coordinates-show.text': 'Show points coordinates on the map',
    'track.props.field.obj.delete.text': 'Delete object',
    'track.props.field.track-group.part-of-name': 'Track №',

    'track.props.footer.btn.cancel.text': 'Cancel',
    'track.props.footer.btn.create.text': 'Create',
    'track.props.footer.btn.update.text': 'Update',

    'track.alert.header.label': 'Delete track or group of tracks',
    'track.alert.warning.text': 'Warning!',
    'track.alert.info.text': 'Deleting a track or group of tracks can’t be canceled. Data won’t be recovered.',
    'track.alert.delete.text': 'Do you want to continue?',
    'track.alert.btn.cancel.text': 'Cancel',
    'track.alert.btn.delete.text': 'Delete',

    'track.player.header.label': 'Player',
    'track.player.unknown.text': 'Unknown',
    'track.player.tracker.text': 'Tracker №',
    'track.player.coordinates.text': 'Tracker coordinates:',
    'track.player.time.text': 'Time:',
    'track.player.speed.text': 'Playback speed:',
    'track.player.km.text': 'km/h',
    'track.player.speed.label': 'Speed',
  },
  ru: {
    'track.button-block.btn.label': 'Создать',

    'track.list.header.track.label': 'Трек',
    'track.list.track-name.text': 'Трек №',
    'track.list.tracker-name.text': 'Трекер №',

    'track.props.field.name.label': 'Объект мониторинга',
    'track.props.field.name.placeholder': 'Выберите из списка',
    'track.props.field.color.text': 'Цвет трека',
    'track.props.field.add.btn.text': 'Добавить объект отслеживания',
    'track.props.field.period.title.text': 'Период отслеживания',
    'track.props.field.period.btn.day.text': 'День',
    'track.props.field.period.btn.week.text': 'Неделя',
    'track.props.field.period.btn.month.text': 'Месяц',
    'track.props.field.period.from.label': 'Выберите время',
    'track.props.field.period.from.placeholder': 'Отслеживать от',
    'track.props.field.period.from.time.text': 'Время',
    'track.props.field.period.to.label': 'Выберите время',
    'track.props.field.period.to.placeholder': 'Отслеживать до',
    'track.props.field.period.to.time.text': 'Время',
    'track.props.field.date-show.text': 'Отображать дату и время точек на карте',
    'track.props.field.coordinates-show.text': 'Отображать координаты точек на карте',
    'track.props.field.obj.delete.text': 'Удалить объект',
    'track.props.field.track-group.part-of-name': 'Трек№',

    'track.props.footer.btn.cancel.text': 'Отмена',
    'track.props.footer.btn.create.text': 'Построить трек',
    'track.props.footer.btn.update.text': 'Обновить трек',

    'track.alert.header.label': 'Удаление трека или группы треков',
    'track.alert.warning.text': 'Внимание!',
    'track.alert.info.text':
      'Удаление трека или группы треков не сможет быть отменено, данные не смогут быть восстановлены.',
    'track.alert.delete.text': 'Вы действительно хотите продолжить?',
    'track.alert.btn.cancel.text': 'Отмена',
    'track.alert.btn.delete.text': 'Удалить',

    'track.player.header.label': 'Воспроизведение',
    'track.player.unknown.text': 'Неизвестно',
    'track.player.tracker.text': 'Трекер №',
    'track.player.coordinates.text': 'Координаты трекера:',
    'track.player.time.text': 'Время:',
    'track.player.speed.text': 'Скорость воспроизведения:',
    'track.player.km.text': 'км/ч',
    'track.player.speed.label': 'Скорость',
  },
  zh: {
    'track.button-block.btn.label': '创建',
    'track.list.header.track.label': '轨迹',
    'track.list.track-name.text': '跟踪号',
    'track.list.tracker-name.text': '跟踪器号',
    'track.props.field.name.label': '监测对象',
    'track.props.field.name.placeholder': '请指定对象',
    'track.props.field.color.text': '轨迹颜色',
    'track.props.field.add.btn.text': '添加跟踪的对象',
    'track.props.field.period.title.text': '跟踪时间段',
    'track.props.field.period.btn.day.text': '日',
    'track.props.field.period.btn.week.text': '周',
    'track.props.field.period.btn.month.text': '月',
    'track.props.field.period.from.label': '跟踪日期与时间，从',
    'track.props.field.period.from.placeholder': '跟踪从',
    'track.props.field.period.from.time.text': '时间',
    'track.props.field.period.to.label': '跟踪日期与时间，到',
    'track.props.field.period.to.placeholder': '跟踪到',
    'track.props.field.period.to.time.text': '时间',
    'track.props.field.date-show.text': '在地图上显示点的日期和时间',
    'track.props.field.coordinates-show.text': '显示地图上点的坐标',
    'track.props.field.obj.delete.text': '消除对象',
    'track.props.field.track-group.part-of-name': '跟踪号',
    'track.props.footer.btn.cancel.text': '取消',
    'track.props.footer.btn.create.text': '建立轨迹',
    'track.props.footer.btn.update.text': '更新轨迹',
    'track.alert.header.label': '删除轨迹或轨迹组',
    'track.alert.warning.text': '注意！',
    'track.alert.info.text': '删除曲目或曲目组无法撤消。',
    'track.alert.delete.text': '您确定要继续吗？',
    'track.alert.btn.cancel.text': '取消',
    'track.alert.btn.delete.text': '删除',
    'track.player.header.label': '再现',
    'track.player.unknown.text': '未知',
    'track.player.tracker.text': '追踪器编号',
    'track.player.coordinates.text': '跟踪器坐标：',
    'track.player.time.text': '时间：',
    'track.player.speed.text': '播放速度：',
    'track.player.km.text': '公里/小时',
    'track.player.speed.label': '速度',
  },
  ar: {
    'track.button-block.btn.label': 'إنشاء',
    'track.list.header.track.label': 'مسار',
    'track.list.track-name.text': 'مقطع رقم',
    'track.list.tracker-name.text': 'متتبع رقم',
    'track.props.field.name.label': 'موضوع المراقبة',
    'track.props.field.name.placeholder': 'حدد موضوع',
    'track.props.field.color.text': 'لون المسار',
    'track.props.field.add.btn.text': 'إضافة كائن للتتبع',
    'track.props.field.period.title.text': 'مدة التتبع',
    'track.props.field.period.btn.day.text': 'يومًا',
    'track.props.field.period.btn.week.text': 'أسبوعًا',
    'track.props.field.period.btn.month.text': 'شهرًا',
    'track.props.field.period.from.label': 'تاريخ ووقت التتبع, من',
    'track.props.field.period.from.placeholder': 'التتبع من',
    'track.props.field.period.from.time.text': 'الوقت',
    'track.props.field.period.to.label': 'تاريخ ووقت التتبع, حتى',
    'track.props.field.period.to.placeholder': 'التتبع حتى',
    'track.props.field.period.to.time.text': 'الوقت',
    'track.props.field.date-show.text': 'عرض تاريخ ووقت النقاط على الخريطة',
    'track.props.field.coordinates-show.text': 'عرض إحداثيات النقاط على الخريطة',
    'track.props.field.obj.delete.text': 'حذف الموضوع',
    'track.props.field.track-group.part-of-name': 'التتبع رقم',
    'track.props.footer.btn.cancel.text': 'إلغاء',
    'track.props.footer.btn.create.text': 'إنشاء مسار',
    'track.props.footer.btn.update.text': 'تحديث مسار',
    'track.alert.header.label': 'حذف مسار أو مجموعة مسارات',
    'track.alert.warning.text': 'انتباه!',
    'track.alert.info.text': 'لا يمكن استعادة المقطع أو مجموعة من المفاطع بعد حذفها.',
    'track.alert.delete.text': 'هل حقًا ترغب بمتابعة؟',
    'track.alert.btn.cancel.text': 'إلغاء',
    'track.alert.btn.delete.text': 'حذف',
    'track.player.header.label': 'المشغّل',
    'track.player.unknown.text': 'غير معروف',
    'track.player.tracker.text': 'متتبع رقم',
    'track.player.coordinates.text': 'إحداثيات المتتبع:',
    'track.player.time.text': 'الوقت:',
    'track.player.speed.text': 'سرعة القراءة:',
    'track.player.km.text': 'كم/سا',
    'track.player.speed.label': 'السرعة',
  },
  de: {
    'track.button-block.btn.label': 'Erstellen',
    'track.list.header.track.label': 'Strecke',
    'track.list.track-name.text': 'Strecken-Nr.',
    'track.list.tracker-name.text': 'Tracker-Nr.',
    'track.props.field.name.label': 'Überwachungsobjekt',
    'track.props.field.name.placeholder': 'Geben Sie ein Objekt an',
    'track.props.field.color.text': 'Streckenfarbe',
    'track.props.field.add.btn.text': 'Tracking-Element hinzufügen',
    'track.props.field.period.title.text': 'Verfolgungszeitraum',
    'track.props.field.period.btn.day.text': 'Tag',
    'track.props.field.period.btn.week.text': 'Woche',
    'track.props.field.period.btn.month.text': 'Monat',
    'track.props.field.period.from.label': 'Datum und Uhrzeit der Strecke, vom',
    'track.props.field.period.from.placeholder': 'Strecke vom',
    'track.props.field.period.from.time.text': 'Zeit',
    'track.props.field.period.to.label': 'Datum und Uhrzeit der Streckes, bis zum',
    'track.props.field.period.to.placeholder': 'Strecke bis zum',
    'track.props.field.period.to.time.text': 'Zeit',
    'track.props.field.date-show.text': 'Datum und Uhrzeit der Punkte auf der Karte anzeigen',
    'track.props.field.coordinates-show.text': 'Zeigen Sie die Koordinaten der Punkte auf der Karte an',
    'track.props.field.obj.delete.text': 'Objekt löschen',
    'track.props.field.track-group.part-of-name': 'Strecken-Nr.',
    'track.props.footer.btn.cancel.text': 'Zurücksetzen',
    'track.props.footer.btn.create.text': 'Strecke bauen',
    'track.props.footer.btn.update.text': 'Strecke aktualisieren',
    'track.alert.header.label': 'Löschen der Strecke oder der Streckengruppe ',
    'track.alert.warning.text': 'Beachtung!',
    'track.alert.info.text': 'Das Löschen eines Tracks oder einer Trackgruppe kann nicht rückgängig gemacht werden.',
    'track.alert.delete.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'track.alert.btn.cancel.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'track.alert.btn.delete.text': 'Zurücksetzen',
    'track.player.header.label': 'Löschen',
    'track.player.unknown.text': 'Wiedergabe',
    'track.player.tracker.text': 'Tracker-Nr.',
    'track.player.coordinates.text': 'Tracker-Koordinaten:',
    'track.player.time.text': 'Zeit:',
    'track.player.speed.text': 'Spielgeschwindigkeit:',
    'track.player.km.text': 'km/h',
    'track.player.speed.label': 'Geschwindigkeit',
  },
};
