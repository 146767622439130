import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SingleTimeManyDevicesEvent, SingleTimeSingleDeviceEvent } from 'contexts/interfaces';

import { RootState } from 'reducers';
import { toggleSelectionOfUnits } from 'reducers/monitoring';

import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';

import {
  deleteUnitFromUser,
  getActiveUnits,
  getNotActiveUnits,
  getUnits,
  postUnitToUser,
  getUnitsShort,
  retrieveLatestPosition,
} from './api';
import {
  ChosenClusterTrackableUnitsInfo,
  Marker,
  Unit,
  UnitsState,
  LastPositions,
  TrackableUnitsCoord,
  UnitShortJsonApi,
  UnitJsonApi,
  BindUnitToUserReqParams,
} from './interface';

const initialState: UnitsState = {
  trackableUnits: [],
  trackableUnitsIncluded: [],
  isLoading: false,
  updateCounter: 0,

  limit: 1000,
  offset: 0,
  total: 0,

  isAllObjectsLoading: false, // для окна настройки списка объектов

  dbUnits: [],
  localUnits: [],

  search: '',
  searchedUnits: [],

  trackableUnitsShort: [],
  isShortsLoading: false,

  chosenClusterTrackableUnitsInfo: [],

  error: null,
  trackableUnitsParsed: [],

  trackableUnitsCoord: {},
};

export const fetchUnits = createAsyncThunk('unitsSlice/fetchUnits', async (args, thunkApi) => {
  const { trackableUnit } = thunkApi.getState() as RootState;
  const search = trackableUnit.search;
  return await getUnits(search);
});

export const fetchUnitsForObjectsModal = createAsyncThunk('unitsSlice/fetchUnitsForObjectsModal', async () => {
  const active = await getActiveUnits();
  const notActive = await getNotActiveUnits();

  return { active, notActive };
});

export const fetchLastPosition = createAsyncThunk(
  'unitsSlice/fetchLastPosition',
  async (devices: { id: string } | { id: string }[], thunkAPI) => {
    const {
      trackableUnit: { trackableUnitsCoord },
    } = thunkAPI.getState() as RootState;

    const arrayOfDevices = [];

    if (Array.isArray(devices)) {
      for (const device of devices) {
        if (!trackableUnitsCoord[device.id]) {
          arrayOfDevices.push(device.id);
        }
      }
    } else {
      if (!trackableUnitsCoord[devices.id]) {
        arrayOfDevices.push(devices.id);
      }
    }

    if (!!arrayOfDevices.length) {
      const result: TrackableUnitsCoord = {};

      const res = await retrieveLatestPosition(arrayOfDevices);

      res.features.forEach((f: LastPositions) => {
        result[f.properties.deviceId] = f.geometry;
      });

      thunkAPI.dispatch(setTrackableUnitsCoordinates(result));
    }
  }
);

export const updateActiveUnits = createAsyncThunk(
  'unitsSlice/updateActiveUnits',
  async (args: { unitsForRemoving: number[]; unitsForAdding: number[] }, thunkAPI) => {
    const promises: ParallelItemInterface[] = [];

    const idsToRemoveSelection: string[] = [];

    args.unitsForRemoving.forEach(unitId => {
      const args: number = unitId;

      idsToRemoveSelection.push(unitId.toString());
      promises.push({ promiseMethod: deleteUnitFromUser, args });
    });

    args.unitsForAdding.forEach(unitId => {
      const args: BindUnitToUserReqParams = {
        trackableUnitId: unitId,
      };

      promises.push({ promiseMethod: postUnitToUser, args });
    });

    // Unselect removed items
    thunkAPI.dispatch(toggleSelectionOfUnits({ unitIds: idsToRemoveSelection, currentStatus: true }));

    return await reqHandlers.allSettled(promises);
  }
);

export const fetchUnitsShort = createAsyncThunk('unitsSlice/fetchUnitsShort', async () => await getUnitsShort());

const trackableUnitSlice = createSlice({
  name: 'unitsSlice',
  initialState,
  reducers: {
    setLimit: (state, { payload }: { payload: number }) => {
      state.limit = payload;
    },
    setOffset: (state, { payload }: { payload: number }) => {
      state.offset = payload;
    },
    setSearch: (state, { payload }: { payload: string }) => {
      state.search = payload;
    },
    setLocalUnits: (state, { payload }) => {
      state.localUnits = payload;
    },
    setSearchedUnits: (state, { payload }) => {
      if (payload?.length) {
        state.searchedUnits = [...payload];
      } else {
        state.searchedUnits = [];
      }
    },
    increaseUpdateCounter: state => {
      state.updateCounter += 1;
    },
    updateDataForTrackableUnits: (
      state,
      //TODO: types
      { payload }: { payload: { updatedTrackableUnits: any; updatedTrackableUnitsParsed: any } } //eslint-disable-line
    ) => {
      state.trackableUnits = payload.updatedTrackableUnits;
      state.trackableUnitsParsed = payload.updatedTrackableUnitsParsed;
    },
    setChosenClusterTrackableUnitsInfo: (
      state,
      {
        payload,
      }: {
        payload: ChosenClusterTrackableUnitsInfo[];
      }
    ) => {
      state.chosenClusterTrackableUnitsInfo = payload;
    },
    removeChosenClusterTrackableUnitsInfo: state => {
      state.chosenClusterTrackableUnitsInfo = [];
    },
    setTrackableUnitsCoordinates: (state, { payload }: { payload: TrackableUnitsCoord }) => {
      const trackableUnitsCoordParsed = state.trackableUnitsCoord;
      Object.keys(payload).forEach((key: string) => {
        trackableUnitsCoordParsed[key] = payload[key];
      });

      state.trackableUnitsCoord = trackableUnitsCoordParsed;
    },
    setTrackableUnitsCoordinatesFromWS: (state, { payload: payloads }: { payload: SingleTimeManyDevicesEvent[] }) => {
      const { trackableUnitsCoord, trackableUnitsParsed } = state;

      payloads.forEach(payload => {
        payload.devices.forEach((device: { deviceId: string; lat: number; lon: number }) => {
          const { deviceId , lat, lon } = device;
          if (!!lon && !!lat) {
            trackableUnitsCoord[deviceId] = {
              coordinates: [lon, lat],
              type: 'FeatureCollection',
            };
            trackableUnitsParsed.forEach(u => {
              u.temp = 'N/A';
              if (u.deviceId === deviceId) {
                u.data = [lon, lat];
              }
            });
          }
        });
      });

      state.trackableUnitsParsed = trackableUnitsParsed;
      state.trackableUnitsCoord = trackableUnitsCoord;
    },
    setTrackableUnitsCoordinatesFromWSSinglePoint: (
      state,
      { payload: payloads }: { payload: SingleTimeSingleDeviceEvent[] }
    ) => {
      const { trackableUnitsCoord, trackableUnitsParsed } = state;
      payloads.forEach(payload => {
        const { deviceId , lat, lon } = payload;
        if (!!lon && !!lat) {
          trackableUnitsCoord[deviceId] = {
            coordinates: [lon, lat],
            type: 'FeatureCollection',
          };
          trackableUnitsParsed.forEach(u => {
            u.temp = 'N/A';
            if (u.deviceId === deviceId) {
              u.data = [lon, lat];
            }
          });
        }
      });

      state.trackableUnitsParsed = trackableUnitsParsed;
      state.trackableUnitsCoord = trackableUnitsCoord;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUnits.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchUnits.fulfilled, (state, { payload }: { payload: UnitJsonApi }) => {
        state.isLoading = false;
        if (payload.data.length > 0) {
          const result: Marker[] = [];
          payload.data.forEach((item: Unit) => {
            if (item.attributes.deviceId) {
              const { contacts, floor, aggregatedName, deviceId } = item.attributes;
              const { id } = item;

              result.push({
                contacts,
                floor,
                fio: aggregatedName,
                fioMock: aggregatedName,
                data: [],
                deviceId,
                id,
              });
            }
          });
          state.trackableUnitsParsed = result;
        }
        state.trackableUnits = payload.data;
        state.trackableUnitsIncluded = payload.included;
        state.total = payload.data.length;
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(updateActiveUnits.pending, state => {
        state.error = null;
        state.isAllObjectsLoading = true;
      })
      .addCase(updateActiveUnits.fulfilled, state => {
        state.isAllObjectsLoading = false;
        state.updateCounter += 1;
      })
      .addCase(updateActiveUnits.rejected, (state, action) => {
        state.isAllObjectsLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchUnitsForObjectsModal.pending, state => {
        state.error = null;
        state.isAllObjectsLoading = true;
      })
      .addCase(fetchUnitsForObjectsModal.fulfilled, (state, action) => {
        const units = action.payload.notActive.data
          .map((u: Unit) => ({ ...u, attributes: { ...u.attributes, isActive: false } }))
          .concat(
            action.payload.active.data.map((u: Unit) => ({ ...u, attributes: { ...u.attributes, isActive: true } }))
          );
        state.dbUnits = units;
        state.localUnits = units;
        state.isAllObjectsLoading = false;
      })
      .addCase(fetchUnitsForObjectsModal.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isAllObjectsLoading = false;
      });
    builder
      .addCase(fetchUnitsShort.pending, state => {
        state.error = null;
        state.isShortsLoading = true;
      })
      .addCase(fetchUnitsShort.fulfilled, (state, { payload }: { payload: UnitShortJsonApi }) => {
        state.isShortsLoading = false;
        state.trackableUnitsShort = payload.data;
      })
      .addCase(fetchUnitsShort.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isShortsLoading = false;
      });
  },
});

export const {
  setLimit,
  setOffset,
  setSearch,
  setLocalUnits,
  setSearchedUnits,
  increaseUpdateCounter,
  setTrackableUnitsCoordinates,
  setTrackableUnitsCoordinatesFromWS,
  setTrackableUnitsCoordinatesFromWSSinglePoint,
  setChosenClusterTrackableUnitsInfo,
  removeChosenClusterTrackableUnitsInfo,
  updateDataForTrackableUnits,
} = trackableUnitSlice.actions;

export default trackableUnitSlice.reducer;
