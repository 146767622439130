import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import arrayMove from 'array-move';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { fetchGeozonesGroups, fetchGeozonesShort } from 'reducers/geozones';
import { fetchWorkObjectFromType } from 'reducers/records';
import { fetchHandbookData } from 'reducers/handbooks';
import { fetchRoles } from 'reducers/roles';
import { ReportTemplateConditionsGrouping } from 'reducers/records/interface';

import {
  MultipleSelectGroupOption,
  MultipleSelectGroupTranslate,
  MultipleSelectGroupValue,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';
import {
  defaultRecordsConditions as defaults,
  REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS,
  REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_TO_ID,
} from 'components/records/utils/consts';
import {
  ConditionsTabCheckBoxGroupType,
  ConditionsTabCheckBoxAdditionalFiltersType,
} from 'components/records/utils/types';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { getCurrentLocale } from 'translate';

export default function useConditionsTab() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  useEffect(() => {
    // geozones data
    dispatch(fetchGeozonesShort());
    dispatch(fetchGeozonesGroups());

    // organization, position, department data
    dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.positions, HANDBOOK_KEYS.departments]));

    // notification data
    dispatch(fetchWorkObjectFromType([REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification]));

    // role data
    dispatch(fetchRoles());
  }, [dispatch]);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const {
    isShortsLoading: geozonesShortIsLoading,
    isLoading: geozonesIsLoading,
    geozonesShort,
    groups: geozoneGroups,
  } = useSelector((state: RootState) => state.geozone);
  const { isDataLoading: handbooksIsLoading, data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const { templateWindow, chosenTemplate } = useSelector((state: RootState) => state.records);
  const { isLoading: rolesIsLoading, roles } = useSelector((state: RootState) => state.role);

  const isDataLoading =
    geozonesShortIsLoading ||
    geozonesIsLoading ||
    handbooksIsLoading ||
    templateWindow.isWorkObjectsLoading ||
    rolesIsLoading;

  const [groupingList, setGroupingList] = useState<ConditionsTabCheckBoxGroupType[]>([]);

  const [geofences, setGeofences] = useState(defaults.geofences);
  const [notificationMarkers, setNotificationMarkers] = useState(defaults.notificationMarkers);
  const [movementTracks, setMovementTracks] = useState(defaults.movementTracks);
  const [POIPoints, setPOIPoints] = useState(defaults.POIPoints);
  const [heatmaps, setHeatmaps] = useState(defaults.heatmaps);

  const [limitListOfObjects, setLimitListOfObjects] = useState(defaults.limitListOfObjects);

  const [additionalFilters, setAdditionalFilters] = useState<ConditionsTabCheckBoxAdditionalFiltersType[]>(
    defaults.additionalFilters
  );
  const [objectIds, setObjectIds] = useState<MultipleSelectGroupValue[]>(defaults.objectIds);
  const [objectsIdsForSelect, setObjectIdsForSelect] = useState<MultipleSelectGroupOption[]>([]);
  const [translateGroups, setTranslateGroups] = useState<MultipleSelectGroupTranslate[]>([]);

  const [lineNumeration, setLineNumeration] = useState(defaults.lineNumeration);
  const [totalColumn, setTotalColumn] = useState(defaults.totalColumn);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // get data from additional filter key for select options and translates group
  const getFilterSelectInfo = useCallback(
    (key: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS) => {
      let newSelectOptions: MultipleSelectGroupOption[] = [];

      switch (key) {
        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZone:
          if (geozoneGroups.length || geozonesShort.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZoneGroup,
                options: geozoneGroups.map((geozoneGroup, i) => ({
                  value: i + 1,
                  label: geozoneGroup.attributes.name,
                  data: {
                    id: geozoneGroup.id,
                  },
                })),
              },
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZone,
                options: geozonesShort.map((shortGeozone, i) => ({
                  value: i + 1,
                  label: shortGeozone.attributes.name,
                  data: {
                    id: shortGeozone.id,
                  },
                })),
              },
            ];
          }
          break;

        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Organization:
          if (handbookData.organizations?.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Organization,
                options: handbookData.organizations.map((organization, i) => ({
                  value: i + 1,
                  label: getTranslateFromLanguageKey(organization.attributes.name, userLanguageKey),
                  data: {
                    id: organization.id,
                  },
                })),
              },
            ];
          }
          break;

        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Position:
          if (handbookData.positions?.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Position,
                options: handbookData.positions.map((position, i) => ({
                  value: i + 1,
                  label: getTranslateFromLanguageKey(position.attributes.name, userLanguageKey),
                  data: {
                    id: position.id,
                  },
                })),
              },
            ];
          }
          break;

        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification:
          if (templateWindow.workObjects[REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification]?.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification,
                options:
                  templateWindow.workObjects[REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification]?.map(
                    (object, i) => ({
                      value: i + 1,
                      label: t(
                        `records.card.template-create.condition.additional-fields.notification.${object.toLowerCase()}.text`
                      ),
                      data: {
                        id: i + 1,
                      },
                    })
                  ) ?? [],
              },
            ];
          }
          break;

        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Department:
          if (handbookData.departments?.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Department,
                options: handbookData.departments.map((department, i) => ({
                  value: i + 1,
                  label: getTranslateFromLanguageKey(department.attributes.name, userLanguageKey),
                  data: {
                    id: department.id,
                  },
                })),
              },
            ];
          }
          break;

        case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Role:
          if (roles.length) {
            newSelectOptions = [
              {
                name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Role,
                options: roles.map((role, i) => ({
                  value: i + 1,
                  label: role.attributes.name,
                  data: {
                    id: role.id,
                  },
                })),
              },
            ];
          }
          break;

        default:
          break;
      }

      const newTranslatesGroup: MultipleSelectGroupTranslate[] = [];

      newSelectOptions.forEach((selectOption, i) => {
        let translate: MultipleSelectGroupTranslate;

        switch (key) {
          case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZone:
            translate = {
              name:
                i === 0 // 0 - GeoZoneGroup, 1 - GeoZone (аналогично с newSelectOptions выше)
                  ? REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZoneGroup
                  : REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZone,
              translate: t(
                `records.card.template-create.condition.additional-fields.by-${selectOption.name.toLowerCase()}.text`
              ),
            };
            break;
          default:
            translate = {
              name: key,
              translate: t(
                `records.card.template-create.condition.additional-fields.by-${selectOption.name.toLowerCase()}.text`
              ),
            };
            break;
        }
        newTranslatesGroup.push(translate);
      });

      return {
        selectOptions: newSelectOptions,
        translatesGroup: newTranslatesGroup,
      };
    },
    [t, geozoneGroups, geozonesShort, handbookData, roles, templateWindow, userLanguageKey]
  );

  const updateState = useCallback(() => {
    if (chosenTemplate) {
      const attr = chosenTemplate.attributes;

      // groupingList
      const groupsBy: ReportTemplateConditionsGrouping[] = [...(attr.groupsBy ?? [])];
      let newGroupingList: ConditionsTabCheckBoxGroupType[] = groupsBy
        .sort((a, b) => a.order - b.order)
        .map(group => ({
          key: group.value,
          text: '',
          checked: true,
        }));

      if (groupsBy.length) {
        defaults.groupingList.forEach(group => {
          const foundNewGroupIndex = newGroupingList.findIndex(gr => gr.key === group.key);

          if (foundNewGroupIndex > -1) {
            newGroupingList[foundNewGroupIndex].text = group.text;
          } else {
            newGroupingList.push(group);
          }
        });
      } else {
        newGroupingList = [...defaults.groupingList];
      }

      // other state properties
      const newLimitListOfObjects = !!attr.limitListOfObjects;
      const newAdditionalFilters = defaults.additionalFilters.map(filter => ({
        ...filter,
        checked: !!attr.workObjectConstraints?.find(workObject => workObject.type === filter.key),
      }));
      const newObjectIds: MultipleSelectGroupValue[] = [];

      if (newLimitListOfObjects) {
        if (attr.workObjectConstraints) {
          attr.workObjectConstraints.forEach(workObject => {
            switch (workObject.type) {
              case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification:
                newObjectIds.push({
                  name: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification,
                  ids:
                    workObject.notifications?.map(
                      notification =>
                        REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_TO_ID[
                          notification as keyof typeof REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_TO_ID
                        ]
                    ) ?? [],
                });
                break;

              default:
                newObjectIds.push({
                  name: workObject.type,
                  ids: workObject.entityIds ?? [],
                });
                break;
            }
          });
        }
      }

      return {
        groupingList: newGroupingList,
        geofences: attr.geofences,
        notificationMarkers: attr.notificationMarkers,
        movementTracks: attr.movementTracks,
        POIPoints: attr.POIPoints,
        heatmaps: attr.heatmaps,
        limitListOfObjects: newLimitListOfObjects,
        additionalFilters: newAdditionalFilters,
        lineNumeration: attr.lineNumeration,
        totalColumn: attr.totalColumn,
        objectIds: newObjectIds,
      };
    } else {
      const groupingList = defaults.groupingList.map(group => ({
        ...group,
        checked: false,
      }));

      return { ...defaults, groupingList };
    }
  }, [chosenTemplate]);

  useEffect(() => {
    const data = updateState();

    setGroupingList(data.groupingList);
    setGeofences(data.geofences);
    setNotificationMarkers(data.notificationMarkers);
    setMovementTracks(data.movementTracks);
    setPOIPoints(data.POIPoints);
    setHeatmaps(data.heatmaps);
    setLimitListOfObjects(data.limitListOfObjects);
    setAdditionalFilters(data.additionalFilters);
    setLineNumeration(data.lineNumeration);
    setTotalColumn(data.totalColumn);
    setObjectIds(data.objectIds);
  }, [updateState]);

  // update additional filters for select options and translates groups when chosenTemplate exist
  useEffect(() => {
    if (chosenTemplate) {
      const foundCheckedFilterKey = additionalFilters.find(filter => filter.checked)?.key;

      if (foundCheckedFilterKey) {
        const { selectOptions, translatesGroup } = getFilterSelectInfo(foundCheckedFilterKey);

        if (!objectsIdsForSelect.length) {
          setObjectIdsForSelect(selectOptions);
        }
        if (!translateGroups.length) {
          setTranslateGroups(translatesGroup);
        }
      }
    }
  }, [chosenTemplate, getFilterSelectInfo, additionalFilters, objectsIdsForSelect, translateGroups]);

  // reset additional filters when limitListOfObjects === true
  useEffect(() => {
    if (!limitListOfObjects) {
      setAdditionalFilters(defaults.additionalFilters);
      setObjectIds([]);
    }
  }, [limitListOfObjects]);

  const handleCheckAdditionalFilter = (index: number) => {
    if (!additionalFilters[index].checked) {
      const newAdditionalFilters = [...additionalFilters].map(item => ({
        ...item,
        checked: false,
      }));

      newAdditionalFilters[index].checked = true;
      setAdditionalFilters(newAdditionalFilters);
    }

    const { selectOptions, translatesGroup } = getFilterSelectInfo(additionalFilters[index].key);

    setObjectIdsForSelect(selectOptions);
    setTranslateGroups(translatesGroup);
  };

  const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setGroupingList([...arrayMove(groupingList, oldIndex, newIndex)]);
  };

  const handleCheckGroup = (index: number) => {
    const newGroupingList = [...groupingList];

    newGroupingList[index].checked = !newGroupingList[index].checked;
    setGroupingList(newGroupingList);
  };

  const handleCheckGeofences = () => setGeofences(!geofences);
  const handleCheckNotificationMarkers = () => setNotificationMarkers(!notificationMarkers);
  const handleCheckMovementTrack = () => setMovementTracks(!movementTracks);
  const handleCheckPoiPoints = () => setPOIPoints(!POIPoints);
  const handleCheckHeatmaps = () => setHeatmaps(!heatmaps);

  const handleCheckLimitListOfObjects = () => setLimitListOfObjects(!limitListOfObjects);

  const handleChangeObjects = (values: MultipleSelectGroupOption[]) => {
    const newValues: MultipleSelectGroupValue[] = values.map(groupValue => ({
      name: groupValue.name,
      ids: Array.from(new Set(groupValue.options.map(option => option.data?.id as number).filter(id => id))),
    }));

    setObjectIds(newValues);
  };

  const handleCheckLineNumeration = () => setLineNumeration(!lineNumeration);

  const handleCheckTotalColumn = () => setTotalColumn(!totalColumn);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (limitListOfObjects && !objectIds.length) {
      newInvalidFields.push('objectsNames');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenTemplate) {
      comparedObj = { ...updateState() };
    }
    return !isEqual(
      {
        groupingList,
        geofences,
        notificationMarkers,
        movementTracks,
        POIPoints,
        heatmaps,
        limitListOfObjects,
        additionalFilters,
        lineNumeration,
        totalColumn,
        objectIds,
      },
      comparedObj
    );
  };

  return {
    states: {
      groupingList,
      limitListOfObjects,
      additionalFilters,
      lineNumeration,
      totalColumn,
      objectIds,
      geofences,
      notificationMarkers,
      movementTracks,
      POIPoints,
      heatmaps,
    },
    additionalData: {
      isDataLoading,
      objectsIdsForSelect,
      translateGroups,
    },
    handlers: {
      handleSortEnd,
      handleCheckGroup,
      handleCheckGeofences,
      handleCheckNotificationMarkers,
      handleCheckMovementTrack,
      handleCheckPoiPoints,
      handleCheckHeatmaps,
      handleCheckLimitListOfObjects,
      handleCheckAdditionalFilter,
      handleChangeObjects,
      handleCheckLineNumeration,
      handleCheckTotalColumn,
    },
    validateRequiredFields,
    setInvalidFields,
    invalidFields,
    hasChanges,
  };
}
