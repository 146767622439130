import { reqHandlers } from 'utils/api';
import { StatisticFilter } from './interface';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';

export function getEmployeeStatistic(params: StatisticFilter & { id: string }) {
  const { id, ...rest } = params;
  const query = getQueryStrFromObjKeys<StatisticFilter>(rest);

  return reqHandlers.get(`/api/employees/${id}/statistic?${query}`);
}

export function getUserStatistic(params: StatisticFilter & { id: string }) {
  const { id, ...rest } = params;
  const query = getQueryStrFromObjKeys<StatisticFilter>(rest);

  return reqHandlers.get(`/api/users/${id}/statistic?${query}`);
}
