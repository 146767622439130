import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';
import { ChosenPoi, PoiSensorPoint } from 'reducers/poi/interface';

import { Option } from 'components/common/select/select';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { NUMBER_REGEXP, URL_REGEXP } from 'utils/consts';
import { getCurrentLocale } from 'translate';

import { defaultPoiSensorState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardSensorData } from '../utils/types';

export default function usePoiSensor(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardSensorData);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const [poiSensorTypeIdForSelect, setPoiSensorTypeIdForSelect] = useState<Option[]>([]);
  const [poiSensorModelIdForSelect, setPoiSensorModelIdForSelect] = useState<Option[]>([]);
  const [poiSensorMeasureUnitIdForSelect, setPoiSensorMeasureUnitIdForSelect] = useState<Option[]>([]);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const lastCheckDateRef = React.createRef();
  const nextCheckDateRef = React.createRef();

  useEffect(() => {
    const handbookSensorModels = handbookData?.poiSensorModels;
    const handbookSensorTypes = handbookData?.poiSensorTypes;
    const handbookSensorMeasureUnits = handbookData?.poiSensorMeasureUnits;

    if (handbookSensorModels && handbookSensorTypes && handbookSensorMeasureUnits) {
      setPoiSensorTypeIdForSelect(
        handbookSensorTypes.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
      setPoiSensorModelIdForSelect(
        handbookSensorModels.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
      setPoiSensorMeasureUnitIdForSelect(
        handbookSensorMeasureUnits.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
    }
  }, [handbookData, userLanguageKey]);

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiSensorPoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiSensorPoint = relationships.poiSensorPoint.data as PoiSensorPoint;

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          poiSensorTypeId: poiFields.poiSensorTypeId,
          poiSensorModelId: poiFields.poiSensorModelId,
          dataSourceUrl: poiFields.dataSourceUrl,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          measureRangeFrom: poiFields.measureRangeFrom,
          measureRangeTo: poiFields.measureRangeTo,
          poiSensorMeasureUnitId: poiFields.poiSensorMeasureUnitId,
          measureLimit: poiFields.measureLimit,
          lastCheckDate: poiFields.lastCheckDate,
          nextCheckDate: poiFields.nextCheckDate,
        })
      );
    }
  }, [dispatch, chosenPoi]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );

  const handleChangePoiSensorTypeId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiSensorTypeId',
          value: newValue,
        })
      );
    }
  };

  const handleChangePoiSensorModelId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiSensorModelId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeDataSourceUrl = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'dataSourceUrl',
        value,
      })
    );

  const handleChangeCoordinates = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'coordinates',
        value,
      })
    );

  const handleChangeMeasureRangeFrom = (value: string) => {
    if (NUMBER_REGEXP.test(value)) {
      dispatch(
        setPoiCardFieldData({
          key: 'measureRangeFrom',
          value: Number(value),
        })
      );
    }
  };

  const handleChangeMeasureRangeTo = (value: string) => {
    if (NUMBER_REGEXP.test(value)) {
      dispatch(
        setPoiCardFieldData({
          key: 'measureRangeTo',
          value: Number(value),
        })
      );
    }
  };

  const handleChangePoiSensorMeasureUnitId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiSensorMeasureUnitId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeMeasureLimit = (value: string) => {
    if (NUMBER_REGEXP.test(value)) {
      dispatch(
        setPoiCardFieldData({
          key: 'measureLimit',
          value: Number(value),
        })
      );
    }
  };

  const handleChangeLastCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'lastCheckDate',
        value: date.toISOString(),
      })
    );

  const handleChangeNextCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'nextCheckDate',
        value: date.toISOString(),
      })
    );

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!poiCardData.poiSensorTypeId) {
      newInvalidFields.push('poiSensorTypeId');
    }
    if (!poiCardData.dataSourceUrl || !URL_REGEXP.test(poiCardData.dataSourceUrl)) {
      newInvalidFields.push('dataSourceUrl');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiSensorPoint = relationships.poiSensorPoint.data as PoiSensorPoint;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        poiSensorTypeId: poiFields.poiSensorTypeId,
        poiSensorModelId: poiFields.poiSensorModelId,
        dataSourceUrl: poiFields.dataSourceUrl,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        measureRangeFrom: poiFields.measureRangeFrom,
        measureRangeTo: poiFields.measureRangeTo,
        poiSensorMeasureUnitId: poiFields.poiSensorMeasureUnitId,
        measureLimit: poiFields.measureLimit,
        lastCheckDate: poiFields.lastCheckDate,
        nextCheckDate: poiFields.nextCheckDate,
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      poiSensorTypeIdForSelect,
      poiSensorModelIdForSelect,
      poiSensorMeasureUnitIdForSelect,
    },
    refs: {
      lastCheckDateRef,
      nextCheckDateRef,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePoiSensorTypeId,
      handleChangePoiSensorModelId,
      handleChangeCoordinates,
      handleChangeDataSourceUrl,
      handleChangeMeasureRangeFrom,
      handleChangeMeasureRangeTo,
      handleChangePoiSensorMeasureUnitId,
      handleChangeMeasureLimit,
      handleChangeLastCheckDate,
      handleChangeNextCheckDate,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
