import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { defaultHandBookWithBind as defaults } from 'components/handbooks/utils/consts';
import { BindedHandbookForSelectType, BindedHandbookType } from 'components/handbooks/utils/types';
import {
  getTranslateFromLanguageKey,
  getBindedHandbookKeysForChosenElements,
} from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { HandbookEntity } from 'reducers/handbooks/interface';

import { getCurrentLocale } from 'translate';

export default function useHandbookWithBind(entity: HandbookEntity | null) {
  const { data: handbooksData } = useSelector((state: RootState) => state.handbooks);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));
  const [bindedHandbooks, setBindedHandbooks] = useState<BindedHandbookType>(defaults.bindedHandbooks);
  const [bindedHandbooksForSelect, setBindedHandbooksForSelect] = useState<BindedHandbookForSelectType>(
    defaults.bindedHandbooksForSelect
  );

  // определяем связанные справочники для entity и их значения
  // их значения для существующего справочника (entity)
  useEffect(() => {
    if (Object.keys(handbooksData).length && entity) {
      const data = handbooksData[entity.type];

      if (data?.length) {
        const currentBindedHandbookKeys = getBindedHandbookKeysForChosenElements(entity.type);

        if (currentBindedHandbookKeys.length) {
          const newBindedHandbooks: BindedHandbookType = {};
          const newBindedHandbooksForSelect: BindedHandbookForSelectType = {};

          currentBindedHandbookKeys.forEach(relKey => {
            newBindedHandbooks[relKey] = entity.relationships[relKey]?.data.map(rel => rel.id) ?? [];
            newBindedHandbooksForSelect[relKey] =
              handbooksData[relKey]?.map((data, i) => ({
                value: i + 1,
                label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
                data: { id: data.id },
              })) ?? [];
          });

          if (Object.keys(newBindedHandbooks).length && Object.keys(newBindedHandbooksForSelect).length) {
            setBindedHandbooks(newBindedHandbooks);
            setBindedHandbooksForSelect(newBindedHandbooksForSelect);
          }
        } else {
          setBindedHandbooks(defaults.bindedHandbooks);
          setBindedHandbooksForSelect(defaults.bindedHandbooksForSelect);
        }
      }
    }
  }, [handbooksData, entity, userLanguageKey]);

  const handleChangeBindedHandbook = (key: string, values: ValuesType) => {
    setBindedHandbooks({ ...bindedHandbooks, [key]: values.map(value => (value.data?.id as number) || -1) });
  };

  const hasChanges = () => {
    if (Object.keys(handbooksData).length && entity) {
      const currentHandbooksIds: BindedHandbookType = {};

      Object.keys(entity.relationships).forEach(relKey => {
        currentHandbooksIds[relKey] = entity.relationships[relKey].data.map(rel => rel.id);
      });
      for (const relKey in currentHandbooksIds) {
        if (!isEqual(currentHandbooksIds[relKey], bindedHandbooks[relKey])) {
          return true;
        }
      }
    }
    return false;
  };

  return {
    states: {
      bindedHandbooks,
      bindedHandbooksForSelect,
    },
    handlers: {
      handleChangeBindedHandbook,
    },
    hasChanges,
  };
}
