export enum CisternState {
  Default = '0',
  Normal = '1',
  Warn = '2',
  Bad = '3',
}

export type CisternProperties = {
  level: CisternState;
};

export interface GeometryTypeCustom {
  POINT: string;
  LINE_STRING: string;
  LINEAR_STRING: string;
  POLYGON: string;
  MULTI_POLYGON: string;
  MULTI_LINE_STRING: string;
  MULTI_POINT: string;
  CIRCLE: string;
  GEOMETRY_COLLECTION: string;
}
