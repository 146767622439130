import { reqHandlers } from 'utils/api';
import { TrackDTO, TrackGroupDTO, TrackGroupsByIdParams } from './interface';

export async function getTracks() {
  return reqHandlers.get('/api/tracks');
}

export async function getTrackGroups() {
  return reqHandlers.get('/api/tracks/groups');
}

export async function getTracksGroupGeodata(params: TrackGroupsByIdParams) {
  const { id, from, to } = params;
  const dateFromWithOffset = new Date(from);
  const dateToWithOffset = new Date(to);
  const userTimezoneOffset = dateFromWithOffset.getTimezoneOffset() * 60000;
  const dateFrom = new Date(dateFromWithOffset.getTime() - userTimezoneOffset);
  const dateTo = new Date(dateToWithOffset.getTime() - userTimezoneOffset);
  const query = `from=${dateFrom.toISOString()}&to=${dateTo.toISOString()}`;
  return reqHandlers.get(`/api/tracks/groups/${id}/geodata?${query}`);
}

export async function getTracksGroupById(id: string) {
  return reqHandlers.get(`/api/tracks/groups/${id}`);
}

export async function postTrack(data: TrackDTO) {
  return reqHandlers.post('/api/tracks', data);
}

export async function postTrackGroup(data: TrackGroupDTO) {
  return reqHandlers.post('/api/tracks/groups', data);
}

export async function putTracksGroupById(id: string, data: TrackGroupDTO) {
  return reqHandlers.put(`/api/tracks/groups/${id}`, data);
}

export async function putTrackById(id: string, data: TrackDTO) {
  return reqHandlers.put(`/api/tracks/${id}`, data);
}

export async function deleteTracksGroupById(id: string) {
  return reqHandlers.delete(`/api/tracks/groups/${id}`);
}
