import { createSlice } from '@reduxjs/toolkit';
import { GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';
import { HEATMAP, LAYERS, MAP_CENTER, MARKERS } from 'utils/consts';
import { MapState, ViewportInfo } from './interface';

const initialState: MapState = {
  showContentData: true,
  showContactsRoute: false,
  contactsIds: [],
  drawType: null,
  drawColor: '#0F00BF',
  drawLabelColor: '#000000',
  lineWidth: 1,
  objectFromGrafana: null,
  showMap: true,
  floor: 1,
  layer: LAYERS.MAPBOX,
  markerType: MARKERS.default,
  heatMapType: HEATMAP.none,
  updateSizeCounter: 0,
  viewportInfo: {
    center: [MAP_CENTER[0], MAP_CENTER[1]],
    mountedNodeHeight: 0,
    mountedNodeWidth: 0,
  },
};

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setViewportInfo: (state, { payload }: { payload: ViewportInfo }) => {
      state.viewportInfo = payload;
    },
    setLineWidth: (state, { payload }: { payload: number }) => {
      state.lineWidth = payload;
    },
    toggleShowContent: state => {
      state.showContentData = !state.showContentData;
    },
    openContactsRoute: (state, { payload }: { payload: string[] }) => {
      state.showContactsRoute = true;
      state.contactsIds = payload;
    },
    closeContactsRoute: state => {
      state.showContactsRoute = false;
      state.contactsIds = [];
    },
    setDrawType: (state, { payload }: { payload: GEOZONE_GEOMETRIC_TYPES }) => {
      state.drawType = payload;
    },
    cleanDrawType: state => {
      state.drawType = null;
    },
    setDrawColor: (state, { payload }: { payload: string }) => {
      state.drawColor = payload;
    },
    cleanDrawColor: state => {
      state.drawColor = '#0F00BF';
    },
    setDrawLabelColor: (state, { payload }: { payload: string }) => {
      state.drawLabelColor = payload;
    },
    cleanDrawLabelColor: state => {
      state.drawLabelColor = '#000000';
    },
    setObjectFromGrafana: (state, { payload }: { payload: number }) => {
      state.objectFromGrafana = payload;
    },
    cleanObjectFromGrafana: state => {
      state.objectFromGrafana = null;
    },
    setShowMap: (state, { payload }: { payload: boolean }) => {
      state.showMap = payload;
    },
    setFloor: (state, { payload }: { payload: number }) => {
      state.floor = payload;
    },
    setLayer: (state, { payload }: { payload: LAYERS }) => {
      state.layer = payload;
    },
    setMarkerType: (state, { payload }: { payload: MARKERS }) => {
      state.markerType = payload;
    },
    setHeatMapType: (state, { payload }: { payload: HEATMAP }) => {
      state.heatMapType = payload;
    },
    setSizeCounter: state => {
      state.updateSizeCounter += 1;
    },
  },
});

export const {
  toggleShowContent,
  openContactsRoute,
  closeContactsRoute,
  setDrawType,
  cleanDrawType,
  setDrawColor,
  cleanDrawColor,
  setObjectFromGrafana,
  cleanObjectFromGrafana,
  setShowMap,
  setDrawLabelColor,
  cleanDrawLabelColor,
  setFloor,
  setLayer,
  setMarkerType,
  setHeatMapType,
  setSizeCounter,
  setLineWidth,
  setViewportInfo,
} = mapSlice.actions;

export default mapSlice.reducer;
