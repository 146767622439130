import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Feature, Map, View } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Extent } from 'ol/extent';
import RenderEvent from 'ol/render/Event';

import { lineString } from '@turf/helpers';
import Bbox from '@turf/bbox';

import { RootState } from 'reducers';

import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { MAP_LAYERS_Z_INDEX, MAX_ZOOM_DEFAULT_VALUE, MIN_ZOOM_DEFAULT_VALUE, ZOOM_DEFAULT_VALUE } from 'utils/consts';

import { getCurrentLocale } from 'translate';

import { applyFlyViewAnimation, createClusterSource, createGeoMarker, createVectorLayer } from '../utils';
import { SelectedTrackerMarker } from '../map.types';

const vectorSourceMarkers: VectorSource = new VectorSource({});
const vectorCluster = createClusterSource(vectorSourceMarkers);
const markersMapLayer = createVectorLayer(vectorCluster, MAP_LAYERS_Z_INDEX.TRACKERS, null, true);
const viewSettings = new View({
  minZoom: MIN_ZOOM_DEFAULT_VALUE,
  maxZoom: MAX_ZOOM_DEFAULT_VALUE,
  zoom: ZOOM_DEFAULT_VALUE,
});

export const useTrackerMarkers = (map: Map) => {
  const trackableUnitsCoordinates = useSelector((state: RootState) => state.trackableUnit.trackableUnitsCoord); // TODO: координаты берутся из координат trackableUnits, исправить для трекеров
  const { trackableUnits } = useSelector((state: RootState) => state.trackableUnit); // TODO: координаты берутся из координат trackableUnits, исправить для трекеров
  const { trackers, selectedTrackers } = useSelector((state: RootState) => state.tracker);
  const { organizations, positions } = useSelector((state: RootState) => state.handbooks.data);
  const trackableUnitView = useSelector((state: RootState) => state.user.userPreferences.trackableUnitView);
  const { includes: transportIncludesInfo } = useSelector((state: RootState) => state.transport);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const makeMapMarkers = useCallback(() => {
    return selectedTrackers.map(selTracker => {
      const tracker = trackers.find(tr => tr.id === selTracker.id);
      const deviceId = trackableUnits.find(u => Number(u.id) === selTracker.id)?.attributes.deviceId ?? '';

      const aggregatedName = tracker?.relationships.trackableUnit?.data.employeeId
        ? tracker?.relationships.trackableUnit?.data.aggregatedName
            .split(' ')
            .map((name, id) => (id === 0 ? name : `${name[0]}.`))
            .join(' ')
        : tracker?.relationships.trackableUnit?.data.aggregatedName ?? '';
      const fio = aggregatedName ?? '';
      const organization = organizations?.find(
        organization => organization.id === tracker?.relationships.trackableUnit?.data.organizationId ?? 0
      );
      const organizationName = getTranslateFromLanguageKey(organization?.attributes.name, userLanguageKey);
      const department = organization?.relationships.departments?.data?.find(
        dep => dep.id === tracker?.relationships.trackableUnit?.data.departmentId ?? 0
      );
      const departmentName = getTranslateFromLanguageKey(department?.name, userLanguageKey);
      const foundPositionOrDriver = positions?.find(
        pos => pos.id === tracker?.relationships.trackableUnit?.data.positionId
      );
      const foundDriverName =
        transportIncludesInfo.driverName.find(
          driver => driver.id === tracker?.relationships?.transport?.data.driverNameId
        )?.name ?? '';
      const driverName =
        typeof foundDriverName === 'string'
          ? foundDriverName
          : getTranslateFromLanguageKey(foundDriverName, userLanguageKey);
      const positionOrDriver = tracker?.relationships?.trackableUnit?.data.employeeId
        ? getTranslateFromLanguageKey(foundPositionOrDriver?.attributes.name, userLanguageKey)
        : driverName;

      return {
        data: trackableUnitsCoordinates[deviceId]?.coordinates ?? [],
        info: {
          id:
            tracker?.relationships.trackableUnit?.data.employeeId ??
            tracker?.relationships.trackableUnit?.data.transportId ??
            0,
          trackerId: tracker?.id ?? 0,
          fio,
          organizationName,
          departmentName,
          positionOrDriver,
        },
        flags: { ...trackableUnitView },
        iconName: tracker?.relationships.trackableUnit?.data.employeeId ? 'employeeIcon' : 'transportIcon',
        watching: selTracker.watching,
      };
    });
  }, [
    trackableUnits,
    trackableUnitsCoordinates,
    trackers,
    selectedTrackers,
    organizations,
    positions,
    trackableUnitView,
    transportIncludesInfo,
    userLanguageKey,
  ]);

  const [markers, setMarkers] = useState<ReturnType<typeof makeMapMarkers>>([]);

  useEffect(() => {
    const mapMarkers = makeMapMarkers();

    setMarkers(mapMarkers);
  }, [makeMapMarkers]);

  const watchingMarkers = useMemo(() => markers.filter(m => m.watching), [markers]);

  const makeGeoMarkers = useCallback((markers: SelectedTrackerMarker[]) => {
    const features: Feature[] = [];

    for (const marker of markers) {
      if (!marker.data || !marker.watching) {
        continue;
      }

      const geoMarker: Feature = createGeoMarker(fromLonLat(marker.data));

      geoMarker.setProperties({
        iconName: marker.iconName,
        info: marker.info,
        flags: marker.flags,
        mapCoords: marker.data,
      });
      features.push(geoMarker);
    }
    return features;
  }, []);

  useEffect(() => {
    const showingFeatures = makeGeoMarkers(markers);

    if (showingFeatures.length) {
      vectorSourceMarkers.addFeatures(showingFeatures);
    }
    return () => {
      vectorSourceMarkers.clear();
    };
  }, [markers, makeGeoMarkers, trackableUnitView]);

  const [isAnimationAllowed, setIsAnimationAllowed] = useState(true); // флаг запрещающий/разрешающий анимацию перед слежением

  const removeListeners = useCallback(() => {
    const listeners = markersMapLayer.getListeners('postrender');

    if (listeners) {
      listeners.forEach(listener => {
        markersMapLayer.un('postrender', listener as (evt: RenderEvent) => void);
      });
    }
  }, []);

  const handlerWatchTrackers = useCallback(() => {
    if (watchingMarkers.length) {
      const filteredWatchingMarkers = watchingMarkers.filter(m => !!m.data.length);
      if (filteredWatchingMarkers.length === 1) {
        const [marker] = filteredWatchingMarkers;

        viewSettings.setCenter(fromLonLat(marker.data));
        viewSettings.setZoom(ZOOM_DEFAULT_VALUE);
        map.setView(viewSettings);
      } else if (filteredWatchingMarkers.length) {
        const line = lineString(filteredWatchingMarkers.map(m => fromLonLat(m.data)));
        const extent = Bbox(line);

        viewSettings.fit(extent as Extent, {
          padding: [50, 50, 50, 50],
        });
        map.setView(viewSettings);
      }
    }
  }, [map, watchingMarkers]);

  useEffect(() => {
    if (selectedTrackers.length) {
      const watchingTrackers = selectedTrackers.filter(tr => tr.watching);
      removeListeners();
      if (watchingTrackers.length) {
        setIsAnimationAllowed(true);
      }
    }
  }, [selectedTrackers, removeListeners]);

  useEffect(() => {
    if (isAnimationAllowed) {
      handlerWatchTrackers();
      if (watchingMarkers.length) {
        const center = viewSettings.getCenter();
        if (center) {
          applyFlyViewAnimation(center, viewSettings, () => {
            setIsAnimationAllowed(false);
            removeListeners();
            markersMapLayer.on('postrender', handlerWatchTrackers);
          });
        }
      }
    } else {
      if (!watchingMarkers.length) {
        removeListeners();
      }
    }
  }, [handlerWatchTrackers, removeListeners, watchingMarkers, isAnimationAllowed]);

  useEffect(() => {
    map.addLayer(markersMapLayer);

    return () => {
      removeListeners();
      map.removeLayer(markersMapLayer);
    };
  }, [map, removeListeners]);

  return { markers };
};
