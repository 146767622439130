import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers';

import { Map } from 'ol';
import TileLayer from 'ol/layer/Tile';

import { getSourceFromLayerId } from '../utils';
import { XYZ } from 'ol/source';

export function useLayers(map: Map) {
  const layer = useSelector((state: RootState) => state.map.layer);

  useEffect(() => {
    const openMapLayer: TileLayer<XYZ> = new TileLayer({});
    openMapLayer.setSource(getSourceFromLayerId(layer));
    map.getLayers().insertAt(0, openMapLayer);

    return () => {
      map.removeLayer(openMapLayer);
      openMapLayer.dispose();
    };
  }, [map, layer]);
}
