export default {
  en: {
    'user-settings.modal.header.label': 'User settings',

    'user-settings.modal.tabs.general.text': 'General settings',
    'user-settings.modal.tabs.data.text': 'Personal data',
    'user-settings.modal.tabs.tabs-settings.text': 'Tabs',
    'user-settings.modal.tabs.objects.text': 'Display Objects',

    'user-settings.modal.general.field.lang.label': 'Interface language',
    'user-settings.modal.general.field.lang.placeholder': 'Select from the list',
    'user-settings.modal.general.field.measurement.label': 'Measurement system',
    'user-settings.modal.general.field.measurement.placeholder': 'Select from the list',
    'user-settings.modal.general.field.timezone.label': 'Timezone',
    'user-settings.modal.general.field.timezone.placeholder': 'Select from the list',
    'user-settings.modal.general.field.date-format.label': 'Date format',
    'user-settings.modal.general.field.date-format.placeholder': 'Select from the list',
    'user-settings.modal.general.field.time-format.label': 'Time format',
    'user-settings.modal.general.field.time-format.placeholder': 'Select from the list',
    'user-settings.modal.general.field.gps-format.label': 'GPS coordinates format',
    'user-settings.modal.general.field.gps-format.placeholder': 'Select from the list',

    'user-settings.modal.personal.field.second-name.label': 'Last Name',
    'user-settings.modal.personal.field.second-name.placeholder': 'Enter last nam',
    'user-settings.modal.personal.field.first-name.label': 'Name',
    'user-settings.modal.personal.field.first-name.placeholder': 'Enter name',
    'user-settings.modal.personal.field.last-name.label': 'Patronymic',
    'user-settings.modal.personal.field.last-name.placeholder': 'Enter patronymic',
    'user-settings.modal.personal.field.telephone.label': 'Phone',
    'user-settings.modal.personal.field.telephone.placeholder': 'Enter phone number',
    'user-settings.modal.personal.field.sms.text': 'Notify by sms',
    'user-settings.modal.personal.field.login.label': 'Login',
    'user-settings.modal.personal.field.login.placeholder': 'Please login',
    'user-settings.modal.personal.field.company.label': 'Company',
    'user-settings.modal.personal.field.company.placeholder': 'Select from the list',
    'user-settings.modal.personal.field.subdivision.label': 'Subdivision',
    'user-settings.modal.personal.field.subdivision.placeholder': 'Select from the list',
    'user-settings.modal.personal.field.position.label': 'Position',
    'user-settings.modal.personal.field.position.placeholder': 'Select from the list',
    'user-settings.modal.personal.field.email.label': 'Email',
    'user-settings.modal.personal.field.email.placeholder': 'Enter email',
    'user-settings.modal.personal.field.email.text': 'Notify by e-mail',
    'user-settings.modal.personal.field.pass.label': 'Password',
    'user-settings.modal.personal.field.pass.placeholder': 'Enter password',

    'user-settings.modal.objects.header.text': 'Current view of the icons on the map',
    'user-settings.modal.objects.tooltip.photo.text': 'Photo is always displayed',
    'user-settings.modal.objects.tooltip.tracker-number.text': 'Checked checkbox displays the tracker number',
    'user-settings.modal.objects.tooltip.full-name.text': 'Checked checkbox displays the detailed name of the object',
    'user-settings.modal.objects.tooltip.organization.text': 'Checked checkbox displays the name of the organization',
    'user-settings.modal.objects.tooltip.department.text': 'Checked checkbox displays the name of the department',
    'user-settings.modal.objects.tooltip.position.text':
      'Checked checkbox displays the position of the employee or the full name of the transport driver',
    'user-settings.modal.objects.tooltip.object-info.text':
      'Checked checkbox always displays the signature, otherwise it displays only when you hover the mouse over the photo',
    'user-settings.modal.objects.texts.tracker-number.text': 'Tracker №',
    'user-settings.modal.objects.texts.full-name.text': 'Full name',
    'user-settings.modal.objects.texts.organization.text': 'Company',
    'user-settings.modal.objects.texts.department.text': 'Department',
    'user-settings.modal.objects.texts.position.text': 'Position/Full name of the driver',
    'user-settings.modal.objects.object-info.text': 'Always display signature',
    'user-settings.modal.objects.on-right-side.text': 'Caption to the right of the photo',
    'user-settings.modal.objects.has-bg.text': 'Signature in the background',

    'user-settings.modal.footer.btn.cancel.label': 'Cancel',
    'user-settings.modal.footer.btn.save.label': 'Save',
  },
  ru: {
    'user-settings.modal.header.label': 'Настройки пользователя',

    'user-settings.modal.tabs.general.text': 'Общие настройки',
    'user-settings.modal.tabs.data.text': 'Личные данные',
    'user-settings.modal.tabs.tabs-settings.text': 'Вкладки',
    'user-settings.modal.tabs.objects.text': 'Отображение объектов',

    'user-settings.modal.general.field.lang.label': 'Язык интерфейса',
    'user-settings.modal.general.field.lang.placeholder': 'Выберите из списка',
    'user-settings.modal.general.field.measurement.label': 'Система измерений',
    'user-settings.modal.general.field.measurement.placeholder': 'Выберите из списка',
    'user-settings.modal.general.field.timezone.label': 'Часовой пояс',
    'user-settings.modal.general.field.timezone.placeholder': 'Выберите из списка',
    'user-settings.modal.general.field.date-format.label': 'Формат даты',
    'user-settings.modal.general.field.date-format.placeholder': 'Выберите из списка',
    'user-settings.modal.general.field.time-format.label': 'Формат времени',
    'user-settings.modal.general.field.time-format.placeholder': 'Выберите из списка',
    'user-settings.modal.general.field.gps-format.label': 'Формат GPS координат',
    'user-settings.modal.general.field.gps-format.placeholder': 'Выберите из списка',

    'user-settings.modal.personal.field.second-name.label': 'Фамилия',
    'user-settings.modal.personal.field.second-name.placeholder': 'Введите фамилию',
    'user-settings.modal.personal.field.first-name.label': 'Имя',
    'user-settings.modal.personal.field.first-name.placeholder': 'Введите имя',
    'user-settings.modal.personal.field.last-name.label': 'Отчество',
    'user-settings.modal.personal.field.last-name.placeholder': 'Введите отчество',
    'user-settings.modal.personal.field.telephone.label': 'Телефон',
    'user-settings.modal.personal.field.telephone.placeholder': 'Введите номер телефона',
    'user-settings.modal.personal.field.sms.text': 'Уведомлять по sms',
    'user-settings.modal.personal.field.login.label': 'Логин',
    'user-settings.modal.personal.field.login.placeholder': 'Введите логин',
    'user-settings.modal.personal.field.company.label': 'Организация',
    'user-settings.modal.personal.field.company.placeholder': 'Выберите из списка',
    'user-settings.modal.personal.field.subdivision.label': 'Структурное подразделение',
    'user-settings.modal.personal.field.subdivision.placeholder': 'Выберите из списка',
    'user-settings.modal.personal.field.position.label': 'Должность',
    'user-settings.modal.personal.field.position.placeholder': 'Выберите из списка',
    'user-settings.modal.personal.field.email.label': 'Электронная почта',
    'user-settings.modal.personal.field.email.placeholder': 'Введите электронную почту',
    'user-settings.modal.personal.field.email.text': 'Уведомлять по e-mail',
    'user-settings.modal.personal.field.pass.label': 'Пароль',
    'user-settings.modal.personal.field.pass.placeholder': 'Введите пароль',

    'user-settings.modal.objects.header.text': 'Текущее представление иконок на карте',
    'user-settings.modal.objects.tooltip.photo.text': 'Фотография отображается всегда',
    'user-settings.modal.objects.tooltip.tracker-number.text': 'Отмеченный чекбокс выводит номер трекера',
    'user-settings.modal.objects.tooltip.full-name.text': 'Отмеченный чекбокс выводит подробное название объекта',
    'user-settings.modal.objects.tooltip.organization.text': 'Отмеченный чекбокс выводит название организации',
    'user-settings.modal.objects.tooltip.department.text': 'Отмеченный чекбокс выводит название подразделения',
    'user-settings.modal.objects.tooltip.position.text':
      'Отмеченный чекбокс выводит должность сотрудника или ФИО водителя транспорта',
    'user-settings.modal.objects.tooltip.object-info.text':
      'Отмеченный чекбокс выводит подпись всегда, иначе выводит только при наведении курсора мыши на фото',
    'user-settings.modal.objects.texts.tracker-number.text': '№ трекера',
    'user-settings.modal.objects.texts.full-name.text': 'Полное название',
    'user-settings.modal.objects.texts.organization.text': 'Организация',
    'user-settings.modal.objects.texts.department.text': 'Подразделение',
    'user-settings.modal.objects.texts.position.text': 'Должность/ФИО водителя',
    'user-settings.modal.objects.object-info.text': 'Выводить подпись всегда',
    'user-settings.modal.objects.on-right-side.text': 'Подпись справа от фото',
    'user-settings.modal.objects.has-bg.text': 'Подпись на фоне',

    'user-settings.modal.footer.btn.cancel.label': 'Отмена',
    'user-settings.modal.footer.btn.save.label': 'Сохранить',
  },
  zh: {
    'user-settings.modal.header.label': '用户设置',
    'user-settings.modal.tabs.general.text': '常规设置',
    'user-settings.modal.tabs.data.text': '个人资料',
    'user-settings.modal.tabs.tabs-settings.text': '标签',
    'user-settings.modal.tabs.objects.text': '显示对象',
    'user-settings.modal.general.field.lang.label': '界面语言',
    'user-settings.modal.general.field.lang.placeholder': '从列表中选择',
    'user-settings.modal.general.field.measurement.label': '测量系统',
    'user-settings.modal.general.field.measurement.placeholder': '从列表中选择',
    'user-settings.modal.general.field.timezone.label': '时区',
    'user-settings.modal.general.field.timezone.placeholder': '从列表中选择',
    'user-settings.modal.general.field.date-format.label': '日期格式',
    'user-settings.modal.general.field.date-format.placeholder': '从列表中选择',
    'user-settings.modal.general.field.time-format.label': '时间格式',
    'user-settings.modal.general.field.time-format.placeholder': '从列表中选择',
    'user-settings.modal.general.field.gps-format.label': 'GPS坐标格式',
    'user-settings.modal.general.field.gps-format.placeholder': '从列表中选择',
    'user-settings.modal.personal.field.second-name.label': '姓',
    'user-settings.modal.personal.field.second-name.placeholder': '输入姓氏',
    'user-settings.modal.personal.field.first-name.label': '名称',
    'user-settings.modal.personal.field.first-name.placeholder': '输入名字',
    'user-settings.modal.personal.field.last-name.label': '父系的',
    'user-settings.modal.personal.field.last-name.placeholder': '输入父系',
    'user-settings.modal.personal.field.telephone.label': '电话',
    'user-settings.modal.personal.field.telephone.placeholder': '输入电话号码',
    'user-settings.modal.personal.field.sms.text': '短信通知',
    'user-settings.modal.personal.field.login.label': '登录名',
    'user-settings.modal.personal.field.login.placeholder': '请登录',
    'user-settings.modal.personal.field.company.label': '公司',
    'user-settings.modal.personal.field.company.placeholder': '从列表中选择',
    'user-settings.modal.personal.field.subdivision.label': '细分',
    'user-settings.modal.personal.field.subdivision.placeholder': '从列表中选择',
    'user-settings.modal.personal.field.position.label': '位置',
    'user-settings.modal.personal.field.position.placeholder': '从列表中选择',
    'user-settings.modal.personal.field.email.label': '电子邮件',
    'user-settings.modal.personal.field.email.placeholder': '输入电子邮件',
    'user-settings.modal.personal.field.email.text': '通过电子邮件通知',
    'user-settings.modal.personal.field.pass.label': '密码',
    'user-settings.modal.personal.field.pass.placeholder': '输入密码',
    'user-settings.modal.objects.header.text': '地图上图标的当前视图',
    'user-settings.modal.objects.tooltip.photo.text': '照片总是显示',
    'user-settings.modal.objects.tooltip.tracker-number.text': '选中的复选框显示跟踪器编号',
    'user-settings.modal.objects.tooltip.full-name.text': '选中的复选框显示对象的详细名称',
    'user-settings.modal.objects.tooltip.organization.text': '选中的复选框显示组织名称',
    'user-settings.modal.objects.tooltip.department.text': '选中的复选框显示单位名称',
    'user-settings.modal.objects.tooltip.position.text': '标记的复选框显示员工的职位或运输司机的全名',
    'user-settings.modal.objects.tooltip.object-info.text':
      '选中的复选框始终显示签名，否则仅当您将鼠标光标悬停在照片上时才显示',
    'user-settings.modal.objects.texts.tracker-number.text': '追踪器编号',
    'user-settings.modal.objects.texts.full-name.text': '全称',
    'user-settings.modal.objects.texts.organization.text': '组织',
    'user-settings.modal.objects.texts.department.text': '细分',
    'user-settings.modal.objects.texts.position.text': '司机的职位/全名',
    'user-settings.modal.objects.object-info.text': '始终显示签名',
    'user-settings.modal.objects.on-right-side.text': '照片右侧的签名',
    'user-settings.modal.objects.has-bg.text': '后台签名',
    'user-settings.modal.footer.btn.cancel.label': '取消',
    'user-settings.modal.footer.btn.save.label': '保存',
  },
  ar: {
    'user-settings.modal.header.label': 'إعدادات المستخدمين',
    'user-settings.modal.tabs.general.text': 'إعدادات عامة',
    'user-settings.modal.tabs.data.text': 'بيانات شخصية',
    'user-settings.modal.tabs.tabs-settings.text': 'علامات التبويب',
    'user-settings.modal.tabs.objects.text': 'عرض الكائنات',
    'user-settings.modal.general.field.lang.label': 'لغة الواجهة',
    'user-settings.modal.general.field.lang.placeholder': 'اختر من القائمة',
    'user-settings.modal.general.field.measurement.label': 'نظام القياس',
    'user-settings.modal.general.field.measurement.placeholder': 'اختر من القائمة',
    'user-settings.modal.general.field.timezone.label': 'المنطقة الزمنية',
    'user-settings.modal.general.field.timezone.placeholder': 'اختر من القائمة',
    'user-settings.modal.general.field.date-format.label': 'تنسيق التاريخ',
    'user-settings.modal.general.field.date-format.placeholder': 'اختر من القائمة',
    'user-settings.modal.general.field.time-format.label': 'تنسيق الوقت',
    'user-settings.modal.general.field.time-format.placeholder': 'اختر من القائمة',
    'user-settings.modal.general.field.gps-format.label': 'تنسيق إحداثيات GPS',
    'user-settings.modal.general.field.gps-format.placeholder': 'اختر من القائمة',
    'user-settings.modal.personal.field.second-name.label': 'اللقب',
    'user-settings.modal.personal.field.second-name.placeholder': 'أكتب اللقب',
    'user-settings.modal.personal.field.first-name.label': 'الاسم',
    'user-settings.modal.personal.field.first-name.placeholder': 'أكتب الاسم',
    'user-settings.modal.personal.field.last-name.label': 'اسم الأب',
    'user-settings.modal.personal.field.last-name.placeholder': 'أكتب اسم الأب',
    'user-settings.modal.personal.field.telephone.label': 'رقم الهاتف',
    'user-settings.modal.personal.field.telephone.placeholder': 'أكتب رقم الهاتف',
    'user-settings.modal.personal.field.sms.text': 'إرسال إشعار عن طريق sms',
    'user-settings.modal.personal.field.login.label': 'تسجيل دخول',
    'user-settings.modal.personal.field.login.placeholder': 'أكتب اسم الدخول',
    'user-settings.modal.personal.field.company.label': 'مؤسسة',
    'user-settings.modal.personal.field.company.placeholder': 'اختر من القائمة',
    'user-settings.modal.personal.field.subdivision.label': 'شعبة',
    'user-settings.modal.personal.field.subdivision.placeholder': 'اختر من القائمة',
    'user-settings.modal.personal.field.position.label': 'وظيفة',
    'user-settings.modal.personal.field.position.placeholder': 'اختر من القائمة',
    'user-settings.modal.personal.field.email.label': 'بريد إلكتروني',
    'user-settings.modal.personal.field.email.placeholder': 'أكتب بريد إلكتروني',
    'user-settings.modal.personal.field.email.text': 'إرسال إشعار عن طريق بريد إلكتروني',
    'user-settings.modal.personal.field.pass.label': 'كلمة مرور',
    'user-settings.modal.personal.field.pass.placeholder': 'أكتب كلمة مرور',
    'user-settings.modal.objects.header.text': 'العرض الحالي للأيقونات على الخريطة',
    'user-settings.modal.objects.tooltip.photo.text': 'عرض الصور دائمًا',
    'user-settings.modal.objects.tooltip.tracker-number.text': 'بتحديد صندوق الاختيار يتم إظهار رقم المتتبع',
    'user-settings.modal.objects.tooltip.full-name.text': 'بتحديد صندوق الاختيار يتم إظهار الاسم التفصيلي للكائن',
    'user-settings.modal.objects.tooltip.organization.text': 'بتحديد صندوق الاختيار يتم إظهار اسم المؤسسة',
    'user-settings.modal.objects.tooltip.department.text': 'بتحديد صندوق الاختيار يتم إظهار اسم الشعبة',
    'user-settings.modal.objects.tooltip.position.text':
      'بتحديد صندوق الاختيار يتم إظهار لبب الموظف أو الاسم الكامل لسائق وسيلة النقل',
    'user-settings.modal.objects.tooltip.object-info.text':
      'بتحديد صندوق الاختيار يتم إظهار التوقيع دائمًا، عدا ذلك يتم الإظهار عند تحديد مؤشر الفأرة على الصورة',
    'user-settings.modal.objects.texts.tracker-number.text': 'رقم المتتبع',
    'user-settings.modal.objects.texts.full-name.text': 'الاسم الكامل',
    'user-settings.modal.objects.texts.organization.text': 'مؤسسة',
    'user-settings.modal.objects.texts.department.text': 'شعبة',
    'user-settings.modal.objects.texts.position.text': 'وظيفة/ الاسم الكامل للسائق',
    'user-settings.modal.objects.object-info.text': 'إظهار التوقيع دائمًا',
    'user-settings.modal.objects.on-right-side.text': 'التوقيع على يمين الصورة',
    'user-settings.modal.objects.has-bg.text': 'التوقيع على الخلفية',
    'user-settings.modal.footer.btn.cancel.label': 'إلغاء',
    'user-settings.modal.footer.btn.save.label': 'حفظ',
  },
  de: {
    'user-settings.modal.header.label': 'Benutzereinstellungen',
    'user-settings.modal.tabs.general.text': 'Allgemeine Einstellungen',
    'user-settings.modal.tabs.data.text': 'Persönliche Daten',
    'user-settings.modal.tabs.tabs-settings.text': 'Registerkarten',
    'user-settings.modal.tabs.objects.text': 'Objekte anzeigen',
    'user-settings.modal.general.field.lang.label': 'Schnittstellensprache',
    'user-settings.modal.general.field.lang.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.general.field.measurement.label': 'Mess-System',
    'user-settings.modal.general.field.measurement.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.general.field.timezone.label': 'Zeitzone',
    'user-settings.modal.general.field.timezone.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.general.field.date-format.label': 'Datumsformat',
    'user-settings.modal.general.field.date-format.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.general.field.time-format.label': 'Zeitformat',
    'user-settings.modal.general.field.time-format.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.general.field.gps-format.label': 'GPS-Koordinatenformat',
    'user-settings.modal.general.field.gps-format.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.personal.field.second-name.label': 'Nachname',
    'user-settings.modal.personal.field.second-name.placeholder': 'Bitte geben Sie ihren Nachnamen ein',
    'user-settings.modal.personal.field.first-name.label': 'Name',
    'user-settings.modal.personal.field.first-name.placeholder': 'Gib deinen Namen ein',
    'user-settings.modal.personal.field.last-name.label': 'zweiter Vorname',
    'user-settings.modal.personal.field.last-name.placeholder': 'Geben Sie den zweiten Vornamen ein',
    'user-settings.modal.personal.field.telephone.label': 'Telefon',
    'user-settings.modal.personal.field.telephone.placeholder': 'Trage deine Telefonnummer ein',
    'user-settings.modal.personal.field.sms.text': 'Per SMS benachrichtigen',
    'user-settings.modal.personal.field.login.label': 'Anmeldung',
    'user-settings.modal.personal.field.login.placeholder': 'Login eingeben',
    'user-settings.modal.personal.field.company.label': 'Organisation',
    'user-settings.modal.personal.field.company.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.personal.field.subdivision.label': 'Strukturelle Unterteilung',
    'user-settings.modal.personal.field.subdivision.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.personal.field.position.label': 'Position',
    'user-settings.modal.personal.field.position.placeholder': 'Aus der Liste auswählen',
    'user-settings.modal.personal.field.email.label': 'E-Mail',
    'user-settings.modal.personal.field.email.placeholder': 'Geben sie ihre E-Mail Adresse ein',
    'user-settings.modal.personal.field.email.text': 'Per E-Mail benachrichtigen',
    'user-settings.modal.personal.field.pass.label': 'Passwort',
    'user-settings.modal.personal.field.pass.placeholder': 'Passwort eingeben',
    'user-settings.modal.objects.header.text': 'Die aktuelle Ansicht der Symbole auf der Karte',
    'user-settings.modal.objects.tooltip.photo.text': 'Das Foto wird immer angezeigt',
    'user-settings.modal.objects.tooltip.tracker-number.text':
      'Das markierte Kontrollkästchen zeigt die Tracker-Nummer an',
    'user-settings.modal.objects.tooltip.full-name.text':
      'Das markierte Kontrollkästchen zeigt den detaillierten Namen des Objekts an',
    'user-settings.modal.objects.tooltip.organization.text':
      'Das markierte Kontrollkästchen zeigt den Namen der Organisation an',
    'user-settings.modal.objects.tooltip.department.text':
      'Das markierte Kontrollkästchen zeigt den Namen der Einheit an',
    'user-settings.modal.objects.tooltip.position.text':
      'Das markierte Kontrollkästchen zeigt die Position des Mitarbeiters oder den vollständigen Namen des Transportfahrers an',
    'user-settings.modal.objects.tooltip.object-info.text':
      'Das markierte Kontrollkästchen zeigt immer die Unterschrift an, ansonsten wird sie nur angezeigt, wenn Sie den Mauszeiger über das Foto bewegen',
    'user-settings.modal.objects.texts.tracker-number.text': 'Tracker Nr.',
    'user-settings.modal.objects.texts.full-name.text': 'Ganzer Titel',
    'user-settings.modal.objects.texts.organization.text': 'Organisation',
    'user-settings.modal.objects.texts.department.text': 'Unterteilung',
    'user-settings.modal.objects.texts.position.text': 'Position / vollständiger Name des Fahrers',
    'user-settings.modal.objects.object-info.text': 'Signatur immer anzeigen',
    'user-settings.modal.objects.on-right-side.text': 'Unterschrift rechts neben dem Foto',
    'user-settings.modal.objects.has-bg.text': 'Unterschrift im Hintergrund',
    'user-settings.modal.footer.btn.cancel.label': 'Zurücksetzen',
    'user-settings.modal.footer.btn.save.label': 'Speichern',
  },
};
