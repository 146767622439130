import { reqHandlers } from 'utils/api';
import { SortType } from 'utils/interfaces';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';

import { MessageCreate, MessageQueryParams } from './interface';

export function getMessages(queryParams: MessageQueryParams) {
  let query = getQueryStrFromObjKeys(queryParams);

  query += `${query.length ? '&' : ''}sortBy=updatedAt&sortType=${SortType.ASC}`;
  return reqHandlers.get(`/api/messages?${query}`);
}

export function getOneMessage(id: string) {
  return reqHandlers.get(`/api/messages/${id}`);
}

export function createOneMessage(data: MessageCreate) {
  return reqHandlers.post(`/api/messages`, data);
}

export function deleteMessages(messagesIds: number[]) {
  return reqHandlers.delete(`/api/messages`, { ids: messagesIds });
}
