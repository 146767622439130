import React from 'react';
import styles from './colorPicker.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  label: string;
  colors: string[];
  onClick: (value: string) => void;
  value: string;
  containerClearPadding?: boolean;
};

export const ColorPicker = (props: Props) => {
  return (
    <div className={cx(styles.container, props.containerClearPadding && styles.containerClearPadding)}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.colorsList}>
        {props.colors.map((color, index) => {
          const wrapStyle = cx(styles.itemWrap, { [styles.itemWrapActive]: props.value === color });
          return (
            <div key={`color-picker-${index}-${color}`} className={wrapStyle}>
              <div
                className={styles.colorItem}
                style={{ backgroundColor: color }}
                onClick={() => props.onClick(color)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
