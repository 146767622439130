import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'reducers';
import { setMessagesPaginationField, setMessagesTableNeedUpdate } from 'reducers/messages';

export default function useMessageTablePagination() {
  const dispatch = useDispatch();

  const { pagination, total } = useSelector((state: RootState) => state.messages);

  // increase page
  const handleIncreasePage = () => {
    const { page, limit } = pagination;
    const pageLimit = Math.ceil(total / limit);

    const inc = page ? page + 1 : 1;
    const newPage = inc <= pageLimit ? inc : 1;

    dispatch(
      setMessagesPaginationField({
        key: 'page',
        value: newPage,
      })
    );
    dispatch(
      setMessagesPaginationField({
        key: 'offset',
        value: (newPage - 1) * limit,
      })
    );
    dispatch(setMessagesTableNeedUpdate(true));
  };

  // decrease page
  const handleDecreasePage = () => {
    const { page, limit } = pagination;
    const pageLimit = Math.ceil(total / limit);

    const dec = page ? page - 1 : 1;
    const newPage = dec >= 1 ? dec : pageLimit;

    dispatch(
      setMessagesPaginationField({
        key: 'page',
        value: newPage,
      })
    );
    dispatch(
      setMessagesPaginationField({
        key: 'offset',
        value: (newPage - 1) * limit,
      })
    );
    dispatch(setMessagesTableNeedUpdate(true));
  };

  // set limit
  const handleSetLimit = (value: number) => {
    dispatch(
      setMessagesPaginationField({
        key: 'limit',
        value: value,
      })
    );
    dispatch(
      setMessagesPaginationField({
        key: 'page',
        value: 1,
      })
    );
    dispatch(
      setMessagesPaginationField({
        key: 'offset',
        value: 0,
      })
    );
    dispatch(setMessagesTableNeedUpdate(true));
  };

  return {
    handlers: {
      handleIncreasePage,
      handleDecreasePage,
      handleSetLimit,
    },
  };
}
