import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { defaultHandBookNewElementData as defaults } from 'components/handbooks/utils/consts';
import { BindedHandbookForSelectType } from 'components/handbooks/utils/types';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import { DropButtonsType } from 'components/common/dropbutton/dropbutton';

import { RootState } from 'reducers';
import { setNewElement, setNewElementValue } from 'reducers/handbooks';

import { getCurrentLocale, LanguagesKeysType, DEFAULT_LANGUAGES, LANGUAGES_TO_TEXT_ENUM } from 'translate';

export default function useHandbookNewElementData() {
  const dispatch = useDispatch();

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));
  const {
    data: handbooksData,
    chosenHandbookName,
    chosenElement,
    search,
    newElement,
  } = useSelector((state: RootState) => state.handbooks);

  const [newElementForSelect, setNewElementForSelect] = useState<BindedHandbookForSelectType>(
    defaults.newElementForSelect
  );

  const [translateKeys, setTranslateKeys] = useState<LanguagesKeysType[]>([DEFAULT_LANGUAGES]);
  const [dropButtons, setDropButtons] = useState<DropButtonsType>(
    Object.keys(LANGUAGES_TO_TEXT_ENUM)
      .map(lng => ({
        label: `handbooks.add-translation.option.text.${lng}`,
        value: lng,
      }))
      .filter(btn => btn.value !== DEFAULT_LANGUAGES)
  );

  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isDataSet, setIsDataSet] = useState(false);

  const resetData = useCallback(() => {
    dispatch(setNewElement(null));
    setIsDataSet(false);
    setInvalidFields([]);
  }, [dispatch]);

  useEffect(() => {
    if (!!chosenHandbookName) {
      resetData();
    }
  }, [resetData, chosenHandbookName]);

  useEffect(() => {
    if (!!chosenElement) {
      resetData();
    }
  }, [resetData, chosenElement]);

  useEffect(() => {
    if (search) {
      resetData();
    }
  }, [resetData, search]);

  // определение полей для связанных справочников при создании нового элемента (newElement)
  useEffect(() => {
    if (!isDataSet && newElement && !!handbooksData) {
      const newElementKeys = Object.keys(newElement);

      if (newElementKeys.length > 1) {
        const newFieldsForSelect: BindedHandbookForSelectType = {};

        newElementKeys.forEach(newElementKey => {
          const newElementValue = newElement[newElementKey];

          if (Array.isArray(newElementValue)) {
            newFieldsForSelect[newElementKey] =
              handbooksData[newElementKey]?.map((data, i) => ({
                value: i + 1,
                label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
                data: { id: data.id },
              })) || [];
          }
        });
        if (Object.keys(newFieldsForSelect).length) {
          setNewElementForSelect(newFieldsForSelect);
          setIsDataSet(true);
        }
      }
    }
  }, [handbooksData, newElement, isDataSet, userLanguageKey]);

  const handleChangeName = (lngKey: LanguagesKeysType, value: string) =>
    dispatch(
      setNewElementValue({
        key: 'name',
        value: {
          [lngKey]: value,
        },
      })
    );

  const handleChooseTranslate = (key: string) => {
    if (!translateKeys.includes(key as LanguagesKeysType)) {
      setTranslateKeys([...translateKeys, key as LanguagesKeysType]);
    }

    const foundDropValueIndex = dropButtons.findIndex(btn => btn.value === key);

    if (foundDropValueIndex > -1) {
      const newDropButtons = [...dropButtons];
      newDropButtons.splice(foundDropValueIndex, 1);
      setDropButtons(newDropButtons);
    }
  };

  const handleChangeFields = (key: string, values: ValuesType | string) => {
    if (!newElement) {
      return;
    }

    const fieldValue = newElement[key];

    if (Array.isArray(fieldValue)) {
      return dispatch(
        setNewElementValue({ key, value: (values as ValuesType).map(value => (value.data?.id as number) || -1) })
      );
    }
    return dispatch(setNewElementValue({ key, value: values as string }));
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!newElement) {
      return [];
    }
    if (!newElement.name || !newElement.name[DEFAULT_LANGUAGES]?.length) {
      newInvalidFields.push('newElementData_name');
    }
    if (
      newElement.hasOwnProperty('inn') &&
      ((typeof newElement.inn === 'string' && newElement.inn.length !== 10) || isNaN(Number(newElement.inn)))
    ) {
      newInvalidFields.push('newElementData_inn');
    }
    if (newElement.hasOwnProperty('organizationForm') && !newElement.organizationForm) {
      newInvalidFields.push('newElementData_organizationForm');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    if (!newElement) {
      return false;
    }
    for (const key in newElement) {
      const value = newElement[key];
      if (typeof value === 'string' && value.length) {
        return true;
      }
    }
    return false;
  };

  return {
    states: {
      newElement,
      dropButtons,
      translateKeys,
      newElementForSelect,
    },
    handlers: {
      handleChangeName,
      handleChooseTranslate,
      handleChangeFields,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
