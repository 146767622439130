import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { Map } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { LineString } from 'ol/geom';
import { Point } from 'ol/geom';
import { getVectorContext } from 'ol/render';
import { createVectorLayer } from '../utils';
import Feature from 'ol/Feature';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { playersStatus } from 'reducers/tracks';
import RenderEvent from 'ol/render/Event';
import { MAP_LAYERS_Z_INDEX } from 'utils/consts';

const trackSource = new VectorSource();
const trackMapLayer = createVectorLayer(trackSource, MAP_LAYERS_Z_INDEX.TRACKS, 'route');

export function useTrack(map: Map) {
  const dispatch = useDispatch();
  const { players, tracksToRender, newRoutes, tracksToRenderAttributes } = useSelector(
    (state: RootState) => state.track
  );

  let now: number;
  let routeLength = 0;
  const styles = {
    icon: new Style({
      image: new CircleStyle({
        radius: 70,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({
          color: 'yellow',
          width: 20,
        }),
      }),
    }),
    geoMarker: new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({
          color: 'yellow',
          width: 2,
        }),
      }),
    }),
  };

  tracksToRender.forEach(trace => {
    routeLength = trace.length > routeLength ? trace.length : routeLength;
  });
  useEffect(() => {
    map.addLayer(trackMapLayer);

    return () => {
      map.removeLayer(trackMapLayer);
      trackMapLayer.dispose();
    };
  }, [map]);

  useEffect(() => {
    function animation(tracksToRender: number[][][]) {
      const moveFeature = function (event: RenderEvent) {
        const vectorContext = getVectorContext(event);
        const frameStateTime = event.frameState?.time || 0;
        const elapsedTime = frameStateTime - now;
        let currentPoint;

        const animationSpeed = window.speed;

        let index: number;
        if (!window.pause) {
          // скорость воспроизведения
          index = Math.round((animationSpeed * elapsedTime) / 1000);
          window.index = index;
          const actualProgress = Math.round(index / (routeLength / 100));

          if (index >= routeLength) {
            window.progress = 0;
            dispatch(
              playersStatus({
                id: window.activeId,
                play: false,
                pause: false,
                currentCoord: null,
                currentTime: null,
              })
            );
            window.currentCoord = null;
            window.currentTime = null;
            stopAnimation();
            return;
          }

          tracksToRender.forEach(trace => {
            if (Number.isInteger(window.progress) && window.progress !== actualProgress - 1) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              index = Math.floor(window.progress * (routeLength / 100)) ?? index;
              window.index = index;
            }
            window.point = trace[index] ? trace[index] : trace[trace.length - 1];
            currentPoint = new Point(window.point).transform('EPSG:4326', 'EPSG:3857').simplify(1);
            const feature = new Feature(currentPoint);
            vectorContext.drawFeature(feature, styles.geoMarker);
            if (routeLength === trace.length) {
              // TODO: пока что диспатч не трогать
              dispatch(
                playersStatus({
                  id: window.activeId,
                  progress: actualProgress,
                })
              );
              window.id = window.activeId;
              window.progress = actualProgress;
              window.currentCoord = newRoutes[index as number].coords || [0, 0];
              window.currentTime = parseInt(newRoutes[index as number].time) || 0;
            }
          });
        } else {
          index = window.index;
          now = new Date().getTime() - (frameStateTime - now);
        }
        currentPoint = new Point(window.point).transform('EPSG:4326', 'EPSG:3857').simplify(1);
        const feature = new Feature(currentPoint);
        vectorContext.drawFeature(feature, styles.geoMarker);
      };

      const startAnimation = function () {
        now = new Date().getTime();
        trackMapLayer.on('postrender', moveFeature);
      };

      const stopAnimation = function () {
        trackMapLayer.un('postrender', moveFeature);
      };

      // eslint-disable-next-line
      // @ts-ignore
      if (window.activeId && players && players[window.activeId].play) {
        const listeners = trackMapLayer.getListeners('postrender');
        if (!listeners) {
          startAnimation();
        } else {
          now = new Date().getTime();
        }
      }
    }

    // eslint-disable-next-line
    // @ts-ignore
    window.pause = players[window.activeId]?.pause || false;
    // eslint-disable-next-line
    // @ts-ignore
    window.speed = players[window.activeId]?.speed || 3;

    if (tracksToRender.length === 0 || !tracksToRender[0][0]) {
      const listeners = trackMapLayer.getListeners('postrender');
      if (listeners && listeners.length > 0) {
        listeners.forEach(listener => {
          // eslint-disable-next-line
          // @ts-ignore
          trackMapLayer.un('postrender', listener);
        });
      }
      trackSource.clear();
    } else {
      tracksToRender.forEach((trace: number[][], index) => {
        // TODO: добавить обработку ошибки отсутствия координат
        if (trace[0]) {
          let route = new LineString(trace).transform('EPSG:4326', 'EPSG:3857');
          route = route.simplify(1);
          const routeFeature = new Feature({
            type: 'route',
            geometry: route,
            properties: tracksToRenderAttributes[index],
          });
          trackSource.addFeatures([routeFeature]);
        }
      });
      animation(tracksToRender);
    }
  }, [players, tracksToRender, newRoutes, tracksToRenderAttributes]);
}
