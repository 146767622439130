import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardType } from 'reducers/poi';
import Search from 'components/common/search/search';
import DropButton from 'components/common/dropbutton/dropbutton';
import { PoiTypesEnum } from 'components/poi/utils/consts';
import { getPoiCardData } from 'components/poi/utils/helpers';

import AccessEntity from 'utils/accessEntity';

import styles from './poiHeader.module.scss';

const dropButtons = [
  {
    label: 'poi.list.drop-btn.poi-type.videocamera.text',
    value: PoiTypesEnum.poiVideocameraPoint,
  },
  {
    label: 'poi.list.drop-btn.poi-type.gazanalyser.text',
    value: PoiTypesEnum.poiGasAnalyzerPoint,
  },
  {
    label: 'poi.list.drop-btn.poi-type.sensor.text',
    value: PoiTypesEnum.poiSensorPoint,
  },
  {
    label: 'poi.list.drop-btn.poi-type.equipment.text',
    value: PoiTypesEnum.poiEquipmentPoint,
  },
  {
    label: 'poi.list.drop-btn.poi-type.incident.text',
    value: PoiTypesEnum.poiAccidentPoint,
  },
  {
    label: 'poi.list.drop-btn.poi-type.free-point.text',
    value: PoiTypesEnum.poiFreePoint,
  },
];

export default function PoiHeader() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const poiPermissions = useSelector((state: RootState) => state.user.permissions.poi);
  const poiAccess = useMemo(() => new AccessEntity(poiPermissions), [poiPermissions]);

  const handleDropButton = (value: string) => {
    if (poiAccess.isAllowCreate()) {
      const poiCardType = value as PoiTypesEnum;
      const poiCardData = getPoiCardData(poiCardType);

      dispatch(setPoiCardType(poiCardType));
      dispatch(setPoiCardData(poiCardData));
    }
  };

  const handleSearch = () => {
    // select
  };

  return (
    <div className={styles.contentControls}>
      <div className={styles.contentSearch}>
        <Search handleChange={handleSearch} />
      </div>
      {poiAccess.isAllowCreate() && (
        <div className={styles.contentCreate}>
          <DropButton
            label={t('poi.button-block.btn.label')}
            buttons={dropButtons}
            onClick={handleDropButton}
            customStyle={styles.dropButton}
            optionsWidth={140}
            noWrappedItems={[t('poi.list.drop-btn.poi-type.free-point.text')]}
          />
        </div>
      )}
    </div>
  );
}
