import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GeozonesList from './components/geozonesList/geozonesList';
import GeozoneCreation from './components/geozoneCreation/geozoneCreation';
import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import {
  fetchGeozones,
  fetchGeozonesGroups,
  unselectAll,
  saveSelected,
  setGeozoneCreationIsShow,
} from 'reducers/geozones';

import { GEOZONE_GROUP } from 'utils/consts';

const Geozones = () => {
  const dispatch = useDispatch();

  const userDataIsLoaded = useSelector((state: RootState) => state.common.userDataIsLoaded);
  const { updateCounter, geozoneCreationIsShow, isLoading } = useSelector((state: RootState) => state.geozone);

  useEffect(() => {
    if (userDataIsLoaded) {
      dispatch(fetchGeozones());
      dispatch(fetchGeozonesGroups());
    }
  }, [dispatch, updateCounter, userDataIsLoaded]);

  const openGeozoneCreation = () => {
    dispatch(saveSelected());
    dispatch(unselectAll());
    dispatch(setGeozoneCreationIsShow(true));
  };

  const openGroupCreation = () => {
    dispatch(saveSelected());
    dispatch(unselectAll());
    dispatch(showModal({ type: GEOZONE_GROUP, which: '' }));
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (geozoneCreationIsShow) {
    return <GeozoneCreation closeGeozoneCreation={() => dispatch(setGeozoneCreationIsShow(false))} />;
  }
  return <GeozonesList openGeozoneCreation={openGeozoneCreation} openGroupCreation={openGroupCreation} />;
};

export default Geozones;
