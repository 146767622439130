import { toast } from 'react-toastify';

import { REPORTS_MAP_ROUTE } from 'components/map/hooks/useReport';
import { AUTH_LOGIN_ROUTE } from 'components/auth/utils/consts';

import { JWTAuthInterface } from 'reducers/auth/interface';

import {
  RequestHandlersMethods,
  EXPIRED_AT_DELTA_SECONDS,
  UNAUTHORIZED_ERROR_STATUS,
  CHECK_UPDATING_TOKEN_DELAY_MS,
  defaultAdditionalOptions,
} from './consts';
import {
  ErrorArrElemType,
  ErrorType,
  ErrorObjType,
  RequestParamsInterface,
  ParallelItemInterface,
  RequestHeaderInterface,
  FileDataInterface,
  FetchDataParamsInterface,
  ProcessErrorPayloadInterface,
  AdditionalOptionsType,
} from './interface';

export default class RequestBase {
  private isInitialized = false; // статус инициализации
  private isCheckTokenInProcessStatus = false; // статус процесса обновления токена
  private isUnauthorizedReqStatus = false; // статус ответа от сервера с ошибкой 401
  private isUpdateTokenForce = false; // флаг немедленного обновления токена (для реализации ф-ии интерцептора)
  protected isParallelRequest = false; // флаг выполнения параллельного запроса
  protected options = defaultAdditionalOptions; // дополнительные опции (показывать ли toast, проверять ли токен и пр. (описание в defaultAdditionalOptions))

  constructor() {
    this.reset();
  }

  // инициализация для работы с запросами и обновления токена
  init() {
    this.isInitialized = true;
  }

  // получение статуса инициализации
  get isInit() {
    return this.isInitialized;
  }

  // получение статуса процесса обновления токена
  get isCheckTokenInProcess() {
    return this.isCheckTokenInProcessStatus;
  }

  // получение статуса инициализации
  get isUnauthorizedReq() {
    return this.isUnauthorizedReqStatus;
  }

  // сброс настроек
  reset() {
    this.isInitialized = false;
    this.isCheckTokenInProcessStatus = false;
    this.isUnauthorizedReqStatus = false;
    this.isUpdateTokenForce = false;
    this.resetOptions();
  }

  // установка дополнительных опций
  protected setOptions(options: AdditionalOptionsType) {
    this.options = options;
  }

  // сброс дополнительных опций
  protected resetOptions() {
    this.options = defaultAdditionalOptions;
  }

  // обработка ошибок после запросов
  protected async processErrorPayload(
    error: ErrorArrElemType[] | ErrorType | ErrorObjType,
    repeatReqCallback: () => Promise<unknown>
  ) {
    let errorStatus = '';
    let errorMessage = '';

    if (Array.isArray(error)) {
      errorStatus = String((error[0] as ErrorArrElemType).status || '');
      errorMessage = (error[0] as ErrorArrElemType).detail || '';
    } else if ((error as ErrorObjType).errors) {
      errorStatus = String((error as ErrorObjType).errors[0].status || '');
      errorMessage = (error as ErrorObjType).errors[0].detail || '';
    } else {
      errorStatus = (error as ErrorType).statusCode ? String((error as ErrorType).statusCode) : '';
      errorMessage = (error as ErrorType).message || '';
    }

    // показывать сообщение об ошибке на соответствующих роутах, при наличии
    // флага необходимости обновления и если код ответа от сервера не равен 401
    if (
      this.options.withToast &&
      this.options.withCheckToken &&
      ![AUTH_LOGIN_ROUTE, REPORTS_MAP_ROUTE].includes(window.location.pathname) &&
      Number(errorStatus) !== UNAUTHORIZED_ERROR_STATUS
    ) {
      const statusText = errorStatus ? ` (${errorStatus})` : '';

      toast.error(`Error${statusText}: ${errorMessage}`);
    }
    // если инициализированы и статус ответа от сервера равен 401
    // обновить токен и повторить запрос
    if (this.isInitialized && !this.isUnauthorizedReqStatus && Number(errorStatus) === UNAUTHORIZED_ERROR_STATUS) {
      try {
        this.isUpdateTokenForce = true;
        await this.updateTokenForce();
        return await repeatReqCallback();
      } catch (error) {
        this.isUnauthorizedReqStatus = true;
      }
    }
    this.resetOptions();
    //eslint-disable-next-line no-throw-literal
    throw {
      code: errorStatus,
      message: errorMessage,
    } as ProcessErrorPayloadInterface;
  }

  // проверка просроченности токена и его обновление
  async checkAndUpdateToken() {
    this.isCheckTokenInProcessStatus = true;

    const isLoginLocation = window.location.pathname === AUTH_LOGIN_ROUTE;

    const userId = sessionStorage.getItem('userId');
    const accessToken = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const expiresAt = sessionStorage.getItem('expiresAt');

    // если необходимо обновить токен и есть соответствующие данные в sessionStorage
    // и если мы не на странице авторизации
    if (this.options.withCheckToken && userId && refreshToken && expiresAt && !isLoginLocation) {
      // проверяем просроченность токена,
      // обновляем его за EXPIRED_AT_DELTA_SECONDS секунд от окончания expiresAt
      if (
        this.isInitialized &&
        !this.isUpdateTokenForce &&
        Date.now() < Number(expiresAt) - EXPIRED_AT_DELTA_SECONDS * 1000
      ) {
        this.isCheckTokenInProcessStatus = false;
        return;
      }

      if (this.isUpdateTokenForce) {
        this.isUpdateTokenForce = false;
      }

      const params: RequestParamsInterface = {
        method: 'POST',
        body: JSON.stringify({ token: refreshToken }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(`/api/auth/refresh/${userId}`, params);

      if (!response.ok) {
        this.isCheckTokenInProcessStatus = false;
        this.resetOptions();
        throw await response.json();
      }

      const result: JWTAuthInterface = await response.json();

      sessionStorage.setItem('accessToken', result.accessToken);
      sessionStorage.setItem('refreshToken', result.refreshToken);
      sessionStorage.setItem('expiresAt', String(Date.now() + result.expiresIn * 1000));

      // в случае обновления страницы - инициализируемся
      if (!this.isInitialized) {
        this.init();
      }
    }
    this.isCheckTokenInProcessStatus = false;
  }

  // циклическая задержка, проверяющая окончания завершения обновления токена
  async delay() {
    if (this.isCheckTokenInProcessStatus) {
      return await new Promise(resolve => {
        setTimeout(async () => {
          await this.delay();
          resolve();
        }, CHECK_UPDATING_TOKEN_DELAY_MS);
      });
    }
    return await Promise.resolve();
  }

  // метод обновления токена и задержки
  private async updateTokenForce() {
    if (!this.isCheckTokenInProcessStatus) {
      await this.checkAndUpdateToken();
    } else {
      await this.delay();
    }
  }

  // подготовка данных файла для отправки
  private getBodyFromFile(data: FileDataInterface) {
    const body = new FormData();

    body.append(data.key, data.file);
    return body;
  }

  // получение типа и имени файла из заголовков
  protected getTypeAndDisposition(headers: Headers) {
    const fileType = headers.get('Content-Type') ?? '';
    const fileName = headers.get('Content-Disposition')?.split('=')[1] ?? '';

    return {
      type: fileType,
      disposition: fileName,
    };
  }

  // подготовка параметров для запроса и его выполнение
  protected async fetchData(dataParams: FetchDataParamsInterface) {
    const accessToken = sessionStorage.getItem('accessToken');
    let headers: RequestHeaderInterface = accessToken
      ? {
          Accept: '*/*',
          Authorization: `Bearer ${accessToken}`,
        }
      : {
          Accept: '*/*',
        };
    let params: RequestParamsInterface = { method: dataParams.method, headers };

    switch (dataParams.method) {
      // get method
      case RequestHandlersMethods.GET:
        break;

      // post method
      case RequestHandlersMethods.POST:
        const file = (dataParams.data as FileDataInterface).file;
        const isDataFileIncluded = !!file && file instanceof File;

        if (!isDataFileIncluded) {
          headers = {
            ...headers,
            'Content-Type': 'application/json',
          };
        }
        params = {
          ...params,
          headers,
          body: isDataFileIncluded
            ? this.getBodyFromFile(dataParams.data as FileDataInterface)
            : JSON.stringify(dataParams.data),
        };
        break;

      // patch and put methods
      case RequestHandlersMethods.PATCH:
      case RequestHandlersMethods.PUT:
        headers = {
          ...headers,
          'Content-Type': 'application/json',
        };
        params = {
          ...params,
          headers,
          body: JSON.stringify(dataParams.data),
        };
        break;

      // delete method
      case RequestHandlersMethods.DELETE:
        headers = {
          ...headers,
          'Content-Type': 'application/json',
        };
        if (dataParams.data) {
          params = {
            ...params,
            headers,
            body: JSON.stringify(dataParams.data),
          };
        } else {
          params = { ...params, headers };
        }
        break;

      default:
        break;
    }

    const response = await fetch(dataParams.route, params);

    // если флаг проверки токена отключен
    if (!this.options.withCheckToken) {
      this.options.withCheckToken = true; // то восстанавливаем значение флага для других запросов
    }

    if (!response.ok) {
      switch (dataParams.method) {
        // используем обработку ошибок для PUT по-другому, т.к. некоторые ручки c backend
        // не возвращают информацию, чтобы обработать ее как `await response.json()`
        case RequestHandlersMethods.PUT:
          //eslint-disable-next-line no-throw-literal
          throw {
            statusCode: response.status,
            message: 'Error update',
          } as ErrorType;

        default:
          throw await response.json();
      }
    }
    return response;
  }

  // обработка параллельных запросов через Promise.allSettled
  async allSettled<T = void>(parallelItems: ParallelItemInterface<T>[], additionalOptions = defaultAdditionalOptions) {
    try {
      this.isParallelRequest = true;
      // здесь проверку токена запускаем отдельно (не в middleware), т.к. есть места,
      // где Promise.allSettled используется неоднократно в асинхронных экшенах
      await this.updateTokenForce();
      this.setOptions(additionalOptions);

      const promises = parallelItems.map(({ promiseMethod, args }) => promiseMethod(args));
      const result = await Promise.allSettled(promises);

      this.isParallelRequest = false;
      this.resetOptions();

      return result;
    } catch (errors) {
      const repeatReqCallback = async () => await this.allSettled(parallelItems, additionalOptions);

      this.isParallelRequest = false;
      await this.processErrorPayload(errors, repeatReqCallback);
    }
    this.resetOptions();

    return [];
  }
}
