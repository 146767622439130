import { Structure } from './types';

export function getUnitIdsByStructure(structure: Structure) {
  let acc: string[] = [];

  const recursiveUnitsChecking = (structure: Structure) => {
    if (structure.units.length) {
      return (acc = acc.concat(structure.units.map(u => u.id)));
    }
    return Object.keys(structure.substructures).forEach(sub => {
      recursiveUnitsChecking(structure.substructures[sub]);
    });
  };

  recursiveUnitsChecking(structure);

  return acc;
}
