import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Button from 'components/common/button/button';
import { RecordsObjectsHandlers, RecordsObjectsRefs, RecordsObjectsStates } from 'components/records/utils/types';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import MultipleSelectGroup, {
  getValuesForMultipleSelectGroup,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';
import DatesRangeWithScripts from 'components/common/datesRangeWithScripts/datesRangeWithScripts';
import Popup from 'components/common/popup/popup';
import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';

import styles from './recordsObjects.module.scss';

const cx = classNames.bind(styles);

type Props = {
  states: RecordsObjectsStates;
  handlers: RecordsObjectsHandlers;
  refs: RecordsObjectsRefs;
};

export const RecordsObjects = (props: Props) => {
  const t = useFormatMessage();

  const states = useMemo(() => props.states, [props.states]);
  const refs = useMemo(() => props.refs, [props.refs]);
  const handlers = useMemo(() => props.handlers, [props.handlers]);

  const modalOptions = useSelector((state: RootState) => state.modal);
  const { currentTemplateId } = useSelector((state: RootState) => state.records);

  const isNothingTemplateChosen = !currentTemplateId;
  const isNothingDatesChosen = !states.fromDate || !states.toDate;
  const isNothingTypesChosen = !states.typeIds.length;
  const isNothingObjectsChosen = !states.objects.length;
  const isNothingChosen =
    isNothingDatesChosen || isNothingTemplateChosen || isNothingTypesChosen || isNothingObjectsChosen;

  if (states.isDataLoading && !modalOptions.show) {
    return <Spinner />;
  }

  const valuesForMultipleSelectPeriodFieldType = states.typeIds.map((id, i) =>
    getValueForMultipleSelect({
      i,
      id,
      selectValues: states.typesForSelect,
    })
  );

  const isDisabledMultipleSelectPeriodFieldType = isNothingTemplateChosen || isNothingDatesChosen;

  const valuesForMultipleSelectPeriodFieldName = getValuesForMultipleSelectGroup({
    options: states.objectsForSelect,
    values: states.objects.map(object => ({
      name: object.type,
      ids: object.entityIds.map(id => id),
    })),
  });

  const isDisabledMultipleSelectPeriodFieldName =
    isNothingDatesChosen || isNothingTypesChosen || isNothingTemplateChosen;

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.data}>
          <h4 className={cx(styles.row, styles.rowTitle)}>{t('records.left-sb.period.header.label')}</h4>
          <DatesRangeWithScripts
            handleChange={handlers.handleChangeDates}
            periodFrom={states.fromDate}
            periodTo={states.toDate}
            periodFromRef={refs.periodFromRef}
            periodToRef={refs.periodToRef}
            wrapperClass={styles.row}
            disabled={isNothingTemplateChosen}
          />
          <MultipleSelect
            options={states.typesForSelect}
            label={t('records.left-sb.period.field.type.label')}
            placeholder={t('records.left-sb.period.field.type.placeholder')}
            isRequired={false}
            withSearchIcon
            wrapperStyle={styles.row}
            handleChangeMultiple={handlers.handleChangeTypes}
            values={valuesForMultipleSelectPeriodFieldType}
            disabled={isDisabledMultipleSelectPeriodFieldType}
          />
          <MultipleSelectGroup
            label={t('records.left-sb.period.field.name.label')}
            placeholder={t('records.left-sb.period.field.name.placeholder')}
            isRequired={false}
            disabled={isDisabledMultipleSelectPeriodFieldName}
            wrapperStyle={styles.row}
            valuesWrapperStyle={styles.multipleSelectGroupValues}
            withSearchIcon
            options={states.objectsForSelect}
            values={valuesForMultipleSelectPeriodFieldName}
            translateGroups={states.objectsTranslatesForSelect}
            handleChange={handlers.handleChangeObjects}
          />
        </div>
      </div>
      <div className={cx(styles.row, styles.rowButtonsList)}>
        <Button
          white
          text={t('records.left-sb.period.field.history.btn.label')}
          customStyle={cx(styles.rowButton, styles.rowButtonFooter)}
          onClick={handlers.handleShowReportsHistory}
        />
        <Button
          blue
          text={t('records.left-sb.period.field.generate.btn.label')}
          customStyle={cx(styles.rowButton, styles.rowButtonFooter)}
          onClick={handlers.handleClickGenerateReport}
          disabled={isNothingChosen}
        />
      </div>
      {states.showLocalAlert && (
        <Popup
          header={<h3 className={styles.popupHeader}>{t('records.report-create.popup.header.text')}</h3>}
          content={
            <div className={styles.popupContent}>
              <div>{t('records.report-create.popup.content.text1')}</div>
              <div>{t('records.report-create.popup.content.text2')}</div>
              <div>{t('records.report-create.popup.content.text3')}</div>
              <div>{t('records.report-create.popup.content.text4')}</div>
            </div>
          }
          footer={
            <div className={styles.popupFooter}>
              <Button
                white
                text={t('records.report-create.popup.footer.btn.close.label')}
                customStyle={styles.popupClose}
                onClick={handlers.handleClosePopup}
              />
            </div>
          }
        />
      )}
    </Fragment>
  );
};
