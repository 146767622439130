export const GEOZONES_LOCATION_PATH = '/main/geozones';

export enum GEOZONE_GEOMETRIC_TYPES {
  Circle = 'Circle',
  Line = 'LineString',
  Polygon = 'Polygon',
  Point = 'Point',
}

export enum GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES {
  Circle = 'geozones.geozone-types.circle.text',
  Line = 'geozones.geozone-types.line.text',
  LineString = 'geozones.geozone-types.line.text',
  Polygon = 'geozones.geozone-types.polygon.text',
  Point = 'geozones.geozone-types.point.text',

  // обратная совместимость
  circle = 'geozones.geozone-types.circle.text',
  line = 'geozones.geozone-types.line.text',
  polygon = 'geozones.geozone-types.polygon.text',
  point = 'geozones.geozone-types.point.text',
}

export enum GeozoneTypeOf {
  primary = 'PRIMARY',
  nd = 'ND',
}

export const GEOZONE_FEATURE_TYPE = 'geozone';

export const MODULE_ND_URL = 'http://isobr.gis.visitech.local/nd/';
