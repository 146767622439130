export default {
  en: {
    'common-info.tabs.monitoring.name.text': 'Monitoring',
    'common-info.tabs.tracks.name.text': 'Tracks',
    'common-info.tabs.geofences.name.text': 'Geofences',
    'common-info.tabs.notifications.name.text': 'Notifications',
    'common-info.tabs.messages.name.text': 'Messages',
    'common-info.tabs.trackers.name.text': 'Trackers',
    'common-info.tabs.users.name.text': 'Users',
    'common-info.tabs.dashboards.name.text': 'Dashboards',
    'common-info.tabs.videoanalytics.name.text': 'Videoanalytics',
    'common-info.tabs.poi.name.text': 'POI',
    'common-info.tabs.directories.name.text': 'Directories',
    'common-info.tabs.reports.name.text': 'Reports',

    'common-info.footer.online.text': 'Online',
    'common-info.footer.copyright.text': 'All rights reserved Visitech LLC 2014-2022',

    'common-info.footer.notifications.tooltip.alert.text':
      'Unread notifications “SOS, fall, loss of connection, inactivity”',
    'common-info.footer.notifications.tooltip.geozones.text': 'Unread notifications “Geozones”',
    'common-info.footer.notifications.tooltip.intersection-objects.text':
      'Unread notifications “Mutual arrangement of objects”',
    'common-info.footer.notifications.tooltip.sensors.text': 'Unread notifications “Sensor value”',

    'common-info.map.options.floors.first.text': '1 floor',
    'common-info.map.options.floors.second.text': '2 floor',
    'common-info.map.options.floors.third.text': '3 floor',
    'common-info.map.options.floors.fourth.text': '4 floor',
    'common-info.map.options.floors.fifth.text': '5 floor',
    'common-info.map.options.floors.sixth.text': '6 floor',
    'common-info.map.options.floors.seventh.text': '7 floor',
    'common-info.map.options.floors.eighth.text': '8 floor',

    'common-info.map.options.type.heatmap.text': 'Heatmap',
    'common-info.map.options.type.objects.text': 'Objects',
    'common-info.map.options.type.violations.text': 'Violations',
    'common-info.map.options.type.all.text': 'All',

    'common-info.authorization.login.header.label': 'Login',
    'common-info.authorization.field.user-name.label': 'Username',
    'common-info.authorization.field.user-name.placeholder': 'Enter username',
    'common-info.authorization.field.password.label': 'Password',
    'common-info.authorization.field.password.placeholder': 'Enter password',
    'common-info.authorization.field.lng.label': 'Language',
    'common-info.authorization.field.lng.value.ru': 'Russian',
    'common-info.authorization.field.lng.value.en': 'English',
    'common-info.authorization.field.lng.value.zh': 'Chinese',
    'common-info.authorization.field.lng.value.ar': 'Arab',
    'common-info.authorization.field.lng.value.de': 'German',
    'common-info.authorization.checkbox.remember-me.text': 'Remember me',
    'common-info.authorization.btn.text': 'Login',
    'common-info.authorization.link.cant-login.text': 'Can’t login?',
    'common-info.authorization.link.cant-login.subject.text': 'Problem with authorization',

    'common-info.submenu.user.settings.text': 'User settings',
    'common-info.submenu.user.exit.text': 'Logout',

    'common-info.map-popup.hide.text': 'Hide',
    'common-info.map-popup.more.text': 'More',
  },
  ru: {
    'common-info.tabs.monitoring.name.text': 'Мониторинг',
    'common-info.tabs.tracks.name.text': 'Треки',
    'common-info.tabs.geofences.name.text': 'Геозоны',
    'common-info.tabs.notifications.name.text': 'Уведомления',
    'common-info.tabs.messages.name.text': 'Сообщения',
    'common-info.tabs.trackers.name.text': 'Трекеры',
    'common-info.tabs.users.name.text': 'Пользователи',
    'common-info.tabs.dashboards.name.text': 'Дашборды',
    'common-info.tabs.videoanalytics.name.text': 'Видеоаналитика',
    'common-info.tabs.poi.name.text': 'POI точки',
    'common-info.tabs.directories.name.text': 'Справочники',
    'common-info.tabs.reports.name.text': 'Отчёты',

    'common-info.footer.online.text': 'Онлайн',
    'common-info.footer.copyright.text': 'Все права защищены ООО Визитек 2014-2022',

    'common-info.footer.notifications.tooltip.alert.text':
      'Непрочитанных уведомлений типа “SOS, падение, потеря связи, бездействие”',
    'common-info.footer.notifications.tooltip.geozones.text': 'Непрочитанных уведомлений типа “Геозоны”',
    'common-info.footer.notifications.tooltip.intersection-objects.text':
      'Непрочитанных уведомлений типа “Взаиморасположение объектов”',
    'common-info.footer.notifications.tooltip.sensors.text': 'Непрочитанных уведомлений типа “Значение датчика”',

    'common-info.map.options.floors.first.text': '1 этаж',
    'common-info.map.options.floors.second.text': '2 этаж',
    'common-info.map.options.floors.third.text': '3 этаж',
    'common-info.map.options.floors.fourth.text': '4 этаж',
    'common-info.map.options.floors.fifth.text': '5 этаж',
    'common-info.map.options.floors.sixth.text': '6 этаж',
    'common-info.map.options.floors.seventh.text': '7 этаж',
    'common-info.map.options.floors.eighth.text': '8 этаж',

    'common-info.map.options.type.heatmap.text': 'Тепловая карта',
    'common-info.map.options.type.objects.text': 'Люди',
    'common-info.map.options.type.violations.text': 'Объекты',
    'common-info.map.options.type.all.text': 'Все',

    'common-info.authorization.login.header.label': 'Вход в систему',
    'common-info.authorization.field.user-name.label': 'Имя пользователя',
    'common-info.authorization.field.user-name.placeholder': 'Введите имя пользователя',
    'common-info.authorization.field.password.label': 'Пароль',
    'common-info.authorization.field.password.placeholder': 'Введите пароль',
    'common-info.authorization.field.lng.label': 'Язык интерфейса',
    'common-info.authorization.field.lng.value.ru': 'Русский',
    'common-info.authorization.field.lng.value.en': 'Английский',
    'common-info.authorization.field.lng.value.zh': 'Китайский',
    'common-info.authorization.field.lng.value.ar': 'Арабский',
    'common-info.authorization.field.lng.value.de': 'Немецкий',
    'common-info.authorization.checkbox.remember-me.text': 'Запомнить меня',
    'common-info.authorization.btn.text': 'Вход в систему',
    'common-info.authorization.link.cant-login.text': 'Не можете войти?',
    'common-info.authorization.link.cant-login.subject.text': 'Проблема с авторизацией',

    'common-info.submenu.user.settings.text': 'Настройки пользователя',
    'common-info.submenu.user.exit.text': 'Выйти из системы',

    'common-info.map-popup.hide.text': 'Скрыть',
    'common-info.map-popup.more.text': 'Подробнее',
  },
  zh: {
    'common-info.tabs.monitoring.name.text': '监测',
    'common-info.tabs.tracks.name.text': '轨迹',
    'common-info.tabs.geofences.name.text': '地理围栏',
    'common-info.tabs.notifications.name.text': '通知',
    'common-info.tabs.messages.name.text': '留言',
    'common-info.tabs.trackers.name.text': '跟踪器',
    'common-info.tabs.users.name.text': '用户',
    'common-info.tabs.dashboards.name.text': '后台',
    'common-info.tabs.videoanalytics.name.text': '视频分析',
    'common-info.tabs.poi.name.text': 'POI兴趣点',
    'common-info.tabs.directories.name.text': '目录',
    'common-info.tabs.reports.name.text': '报告',
    'common-info.footer.online.text': '在线',
    'common-info.footer.copyright.text': 'Visitech 有限公司 2014-2022 版权所有',
    'common-info.footer.notifications.tooltip.alert.text': '未读的通知如“ SOS，跌落，连接中断，静止不动”',
    'common-info.footer.notifications.tooltip.geozones.text': '未读的通知如“地理围栏"',
    'common-info.footer.notifications.tooltip.intersection-objects.text': '未读的通知如”对象间相互位置"',
    'common-info.footer.notifications.tooltip.sensors.text': '未讀通知“傳感器值”',
    'common-info.map.options.floors.first.text': '1楼',
    'common-info.map.options.floors.second.text': '2楼',
    'common-info.map.options.floors.third.text': '3楼',
    'common-info.map.options.floors.fourth.text': '4楼',
    'common-info.map.options.floors.fifth.text': '5楼',
    'common-info.map.options.floors.sixth.text': '6楼',
    'common-info.map.options.floors.seventh.text': '7楼',
    'common-info.map.options.floors.eighth.text': '8楼',
    'common-info.map.options.type.heatmap.text': '热图',
    'common-info.map.options.type.objects.text': '人们',
    'common-info.map.options.type.violations.text': '对象',
    'common-info.map.options.type.all.text': '全部',
    'common-info.authorization.login.header.label': '登录系统',
    'common-info.authorization.field.user-name.label': '用户名',
    'common-info.authorization.field.user-name.placeholder': '输入用户名',
    'common-info.authorization.field.password.label': '密码',
    'common-info.authorization.field.password.placeholder': '输入密码',
    'common-info.authorization.field.lng.label': '界面语言',
    'common-info.authorization.field.lng.value.ru': '俄语',
    'common-info.authorization.field.lng.value.en': '英语',
    'common-info.authorization.field.lng.value.zh': '中文',
    'common-info.authorization.field.lng.value.ar': '阿拉伯',
    'common-info.authorization.field.lng.value.de': '德语',
    'common-info.authorization.checkbox.remember-me.text': '记住我',
    'common-info.authorization.btn.text': '登录系统',
    'common-info.authorization.link.cant-login.text': '无法登录？',
    'common-info.authorization.link.cant-login.subject.text': '授权问题',
    'common-info.submenu.user.settings.text': '用户设置',
    'common-info.submenu.user.exit.text': '登出',
    'common-info.map-popup.hide.text': '隐藏',
    'common-info.map-popup.more.text': '更多细节',
  },
  ar: {
    'common-info.tabs.monitoring.name.text': 'رصد',
    'common-info.tabs.tracks.name.text': 'مسارات',
    'common-info.tabs.geofences.name.text': 'مناطق',
    'common-info.tabs.notifications.name.text': 'إشعارات',
    'common-info.tabs.messages.name.text': 'رسائل',
    'common-info.tabs.trackers.name.text': 'متتبعات',
    'common-info.tabs.users.name.text': 'مستخدمون',
    'common-info.tabs.dashboards.name.text': 'لوحات المعلومات',
    'common-info.tabs.videoanalytics.name.text': 'تحليلات الفيديو',
    'common-info.tabs.poi.name.text': 'POI',
    'common-info.tabs.directories.name.text': 'مراجع',
    'common-info.tabs.reports.name.text': 'تقارير',
    'common-info.footer.online.text': 'عبر الانترنت',
    'common-info.footer.copyright.text': 'جميع الحقوق محفوظة ش.ذ.م.م. فيزيتيك 2014-2022',
    'common-info.footer.notifications.tooltip.alert.text':
      'الإشعارات غير المقروءة من النوع "SOS، سقوط، فقد الاتصال، الخمول"',
    'common-info.footer.notifications.tooltip.geozones.text': 'الإشعارات غير المقروءة من النوع "مناطق"',
    'common-info.footer.notifications.tooltip.intersection-objects.text':
      'الإشعارات غير المقروءة من النوع "تموضع الكائنات"',
    'common-info.footer.notifications.tooltip.sensors.text': 'إخطارات قيمة المستشعر غير المقروءة',
    'common-info.map.options.floors.first.text': 'طابق 1',
    'common-info.map.options.floors.second.text': 'طابق 2',
    'common-info.map.options.floors.third.text': 'طابق 3',
    'common-info.map.options.floors.fourth.text': 'طابق 4',
    'common-info.map.options.floors.fifth.text': 'طابق 5',
    'common-info.map.options.floors.sixth.text': 'طابق 6',
    'common-info.map.options.floors.seventh.text': 'طابق 7',
    'common-info.map.options.floors.eighth.text': 'طابق 8',
    'common-info.map.options.type.heatmap.text': 'خريطة حرارية',
    'common-info.map.options.type.objects.text': 'أفراد',
    'common-info.map.options.type.violations.text': 'كائنات',
    'common-info.map.options.type.all.text': 'الكل',
    'common-info.authorization.login.header.label': 'تسجيل دخول',
    'common-info.authorization.field.user-name.label': 'اسم المستخدم',
    'common-info.authorization.field.user-name.placeholder': 'أكتب اسم المستخدم',
    'common-info.authorization.field.password.label': 'كلمة مرور',
    'common-info.authorization.field.password.placeholder': 'أكتب كلمة مرور',
    'common-info.authorization.field.lng.label': 'اللغة',
    'common-info.authorization.field.lng.value.ru': 'اللغة الروسية',
    'common-info.authorization.field.lng.value.en': 'الإنجليزية',
    'common-info.authorization.field.lng.value.zh': 'صينى',
    'common-info.authorization.field.lng.value.ar': 'عربى',
    'common-info.authorization.field.lng.value.de': 'ألمانية',
    'common-info.authorization.checkbox.remember-me.text': 'حفظ المستخدم',
    'common-info.authorization.btn.text': 'تسجيل دخول',
    'common-info.authorization.link.cant-login.text': 'لا يمكنك تسجيل الدخول؟',
    'common-info.authorization.link.cant-login.subject.text': 'قضايا التفويض',
    'common-info.submenu.user.settings.text': 'إعدادات المستخدم',
    'common-info.submenu.user.exit.text': 'خروج',
    'common-info.map-popup.hide.text': 'يخفي',
    'common-info.map-popup.more.text': 'المزيد من التفاصيل',
  },
  de: {
    'common-info.tabs.monitoring.name.text': 'Überwachung',
    'common-info.tabs.tracks.name.text': 'Spuren',
    'common-info.tabs.geofences.name.text': 'Geofences',
    'common-info.tabs.notifications.name.text': 'Benachrichtigungen',
    'common-info.tabs.messages.name.text': 'Mitteilungen',
    'common-info.tabs.trackers.name.text': 'Tracker',
    'common-info.tabs.users.name.text': 'Benutzer',
    'common-info.tabs.dashboards.name.text': 'Dashboards',
    'common-info.tabs.videoanalytics.name.text': 'Videoanalyse',
    'common-info.tabs.poi.name.text': 'POI-Punkte',
    'common-info.tabs.directories.name.text': 'Verzeichnisse',
    'common-info.tabs.reports.name.text': 'Berichte',
    'common-info.footer.online.text': 'Online',
    'common-info.footer.copyright.text': 'Alle Rechte sind vorbehalten. OOO Visitech 2014-2022',
    'common-info.footer.notifications.tooltip.alert.text':
      'Ungelesene Benachrichtigungen wie "SOS, Absturz, Verbindungsverlust, Inaktivität"',
    'common-info.footer.notifications.tooltip.geozones.text': 'Ungelesene Benachrichtigungen wie "Geofences"',
    'common-info.footer.notifications.tooltip.intersection-objects.text':
      'Ungelesene Benachrichtigungen vom Typ "Objektkonfiguration"',
    'common-info.footer.notifications.tooltip.sensors.text': 'Ungelesene Benachrichtigungen „Sensorwert“',
    'common-info.map.options.floors.first.text': 'Erdgeschoß',
    'common-info.map.options.floors.second.text': '1. Stock',
    'common-info.map.options.floors.third.text': '2. Stock',
    'common-info.map.options.floors.fourth.text': '3. Stock',
    'common-info.map.options.floors.fifth.text': '4. Stock',
    'common-info.map.options.floors.sixth.text': '5. Stock',
    'common-info.map.options.floors.seventh.text': '6. Stock',
    'common-info.map.options.floors.eighth.text': '7. Stock',
    'common-info.map.options.type.heatmap.text': 'Wärmekarte',
    'common-info.map.options.type.objects.text': 'Menschen',
    'common-info.map.options.type.violations.text': 'Objekte',
    'common-info.map.options.type.all.text': 'Alles',
    'common-info.authorization.login.header.label': 'Anmelden',
    'common-info.authorization.field.user-name.label': 'Benutzername',
    'common-info.authorization.field.user-name.placeholder': 'Geben Sie Ihren Benutzernamen ein',
    'common-info.authorization.field.password.label': 'Passwort',
    'common-info.authorization.field.password.placeholder': 'Passwort eingeben',
    'common-info.authorization.field.lng.label': 'Interface-Sprache',
    'common-info.authorization.field.lng.value.ru': 'Russisch',
    'common-info.authorization.field.lng.value.en': 'Englisch',
    'common-info.authorization.field.lng.value.zh': 'Chinesisch',
    'common-info.authorization.field.lng.value.ar': 'Arabisch',
    'common-info.authorization.field.lng.value.de': 'Deutsche',
    'common-info.authorization.checkbox.remember-me.text': 'Anmeldung merken',
    'common-info.authorization.btn.text': 'Anmelden',
    'common-info.authorization.link.cant-login.text': 'Können Sie sich nicht anmelden?',
    'common-info.authorization.link.cant-login.subject.text': 'Berechtigungsprobleme',
    'common-info.submenu.user.settings.text': 'Benutzereinstellungen',
    'common-info.submenu.user.exit.text': 'Ausloggen',
    'common-info.map-popup.hide.text': 'Ausblenden',
    'common-info.map-popup.more.text': 'Mehr Details',
  },
};
