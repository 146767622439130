import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/common/input/input';
import { GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';

import { RootState } from 'reducers';
import { setGeozoneFeature } from 'reducers/geozones';
import { setLineWidth } from 'reducers/map';

import styles from './sizeField.module.scss';

type Props = {
  fieldType: GEOZONE_GEOMETRIC_TYPES | null;
};

export const SizeField = ({ fieldType }: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const geozoneFeature = useSelector((state: RootState) => state.geozone.geozoneFeature);

  const geozoneFeatureProps = !Array.isArray(geozoneFeature)
    ? geozoneFeature?.getProperties()
    : geozoneFeature[0]?.getProperties();
  const circleRadius = geozoneFeatureProps ? geozoneFeatureProps.circleRadius : '';

  const lineWidth = useSelector((state: RootState) => state.map.lineWidth);

  const handleChangeRadius = (val: string) => {
    if (geozoneFeature) {
      if (!Array.isArray(geozoneFeature)) {
        const feature = geozoneFeature.clone();

        feature.set('circleRadius', val.split(' ')[0]);
        dispatch(setGeozoneFeature(feature));
      }
    }
  };

  const handleChangeWidth = (val: string) => {
    if (Array.isArray(geozoneFeature)) {
      const newFeatures = geozoneFeature.map(f => {
        const feature = f.clone();

        feature.set('lineWidth', parseInt(val));
        return feature;
      });

      dispatch(setGeozoneFeature(newFeatures));
    }
    dispatch(setLineWidth(parseInt(val) || 0));
  };

  switch (fieldType) {
    case GEOZONE_GEOMETRIC_TYPES.Circle:
      return (
        <Input
          label={t('geozones.geozone-card.field.radius.label')}
          placeholder="0"
          value={circleRadius || ''}
          handleInputChange={handleChangeRadius}
          customStyle={styles.inputWrap}
        />
      );

    case GEOZONE_GEOMETRIC_TYPES.Line:
      return (
        <Input
          label={t('geozones.geozone-card.field.width.label')}
          placeholder="0"
          value={lineWidth}
          handleInputChange={handleChangeWidth}
          customStyle={styles.inputWrap}
        />
      );

    default:
      return null;
  }
};
