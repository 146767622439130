import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Input from 'components/common/input/input';
import Select from 'components/common/select/select';
import { Alert } from 'components/common/alert/alert';

import { RootState } from 'reducers';
import {
  addTransportDriversHandbookData,
  setChosenTransportDriver,
  setShowTransportDriversCard,
  updateTransportDriversHandbookData,
} from 'reducers/handbooks';
import { showAlert } from 'reducers/modal';

import { useOrganizationDepartmentPositionSelectOptions } from 'utils/hooks/useOrganizationDepartmentPositionSelectOptions';
import { DELETE_HANDBOOKS_NOTE } from 'utils/consts';
import AccessEntity from 'utils/accessEntity';

import CardButtons from '../../cardButtons/cardButtons';

import styles from './transportDriverCard.module.scss';

export type FormFields = {
  lastName: string;
  firstName: string;
  secondName: string;
  organization: string;
  department: string;
  position: string;
};

const formInitialValues: FormFields = {
  lastName: '',
  firstName: '',
  secondName: '',
  organization: '',
  department: '',
  position: '',
};

const messages = {
  alertHeader: 'handbooks.column.elements-card.alert.header.label',
  alertInfo: 'handbooks.column.elements-card.alert.info.text',
  fieldLabel: (field: keyof FormFields) => `handbooks.transport-drivers.form.${field}.label`,
};

const validationSchema: yup.SchemaOf<FormFields> = yup.object({
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  secondName: yup.string().required(),
  organization: yup.string().required(),
  department: yup.string().required(),
  position: yup.string().required(),
});

const TransportDriverCard: React.VFC = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const showForm = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.showCard);
  const transportDrivers = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.data);
  const chosenElementId = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.chosenElementId);
  const copyElementId = useSelector((state: RootState) => state.handbooks.transportDriversHandbook.copyElementId);

  const handbooksPermissions = useSelector((state: RootState) => state.user.permissions.handbooks);
  const handbooksAccess = useMemo(() => new AccessEntity(handbooksPermissions), [handbooksPermissions]);

  const [showFormHasChangesAlert, setShowFormHasChangesAlert] = useState(false);

  const initialValues: FormFields = useMemo(() => {
    const sourceElementId = copyElementId || chosenElementId;

    if (sourceElementId === null) {
      return formInitialValues;
    }

    const sourceElement = transportDrivers.find(element => element.id === sourceElementId);

    if (!sourceElement) {
      return formInitialValues;
    }

    return {
      lastName: sourceElement.lastName,
      firstName: sourceElement.firstName,
      secondName: sourceElement.secondName,
      organization: String(sourceElement.organizationId),
      department: String(sourceElement.departmentId),
      position: String(sourceElement.positionId),
    };
  }, [chosenElementId, transportDrivers, copyElementId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      if (chosenElementId !== null) {
        // update chosen
        if (formHasChanges()) {
          dispatch(
            updateTransportDriversHandbookData({
              id: chosenElementId,
              formData: values,
            })
          );
        } else {
          return;
        }
      } else {
        // create new
        dispatch(addTransportDriversHandbookData(values));
      }

      formikHelpers.resetForm();
      dispatch(setShowTransportDriversCard(false));
    },
  });

  // Get options for selects
  const { organizationOptions, departmentOptions, positionOptions } = useOrganizationDepartmentPositionSelectOptions(
    formik.values.organization
  );

  const handleFieldChange = (field: keyof FormFields, value: string) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);

    // reset deparment value after org chosen
    if (field === 'organization') {
      formik.setFieldValue('department', '', false);
    }
  };

  const formHasChanges = () => {
    if (chosenElementId) {
      // edit mode
      const chosenElement = transportDrivers.find(element => element.id === chosenElementId);

      if (!chosenElement) {
        return false;
      }

      return (
        formik.values.lastName !== chosenElement.lastName ||
        formik.values.firstName !== chosenElement.firstName ||
        formik.values.secondName !== chosenElement.secondName ||
        formik.values.organization !== String(chosenElement.organizationId) ||
        formik.values.department !== String(chosenElement.departmentId) ||
        formik.values.position !== String(chosenElement.positionId)
      );
    } else {
      // create mode
      // true if any field has value
      return Object.values(formik.values).some(value => value);
    }
  };

  const handleSubmitClick = () => {
    formik.submitForm();
  };

  const handleCancelClick = () => {
    if (formHasChanges()) {
      setShowFormHasChangesAlert(true);
    } else {
      dispatch(setShowTransportDriversCard(false));
      dispatch(setChosenTransportDriver(null));
    }
  };

  const handleDeleteClick = () => {
    dispatch(showAlert({ alertType: DELETE_HANDBOOKS_NOTE }));
  };

  const handleAlertCancel = () => {
    setShowFormHasChangesAlert(false);
  };

  const handleAlertContinue = () => {
    dispatch(setShowTransportDriversCard(false));
    dispatch(setChosenTransportDriver(null));
    setShowFormHasChangesAlert(false);
  };

  if (!showForm) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.form}>
          <Input
            label={t(messages.fieldLabel('lastName'))}
            placeholder={t(messages.fieldLabel('lastName'))}
            value={formik.values.lastName}
            isValueError={formik.touched.lastName && Boolean(formik.errors.lastName)}
            handleInputChange={value => handleFieldChange('lastName', value)}
            isRequired
          />

          <Input
            label={t(messages.fieldLabel('firstName'))}
            placeholder={t(messages.fieldLabel('firstName'))}
            value={formik.values.firstName}
            isValueError={formik.touched.firstName && Boolean(formik.errors.firstName)}
            handleInputChange={value => handleFieldChange('firstName', value)}
            isRequired
          />

          <Input
            label={t(messages.fieldLabel('secondName'))}
            placeholder={t(messages.fieldLabel('secondName'))}
            value={formik.values.secondName}
            isValueError={formik.touched.secondName && Boolean(formik.errors.secondName)}
            handleInputChange={value => handleFieldChange('secondName', value)}
            isRequired
          />

          <Select
            label={t(messages.fieldLabel('organization'))}
            placeholder={t(messages.fieldLabel('organization'))}
            options={organizationOptions}
            value={formik.values.organization}
            isValueError={formik.touched.organization && Boolean(formik.errors.organization)}
            handleChange={value => handleFieldChange('organization', value)}
            isRequired
          />

          <Select
            label={t(messages.fieldLabel('department'))}
            placeholder={t(messages.fieldLabel('department'))}
            options={departmentOptions}
            value={formik.values.department}
            isValueError={formik.touched.department && Boolean(formik.errors.department)}
            handleChange={value => handleFieldChange('department', value)}
            isRequired
          />

          <Select
            label={t(messages.fieldLabel('position'))}
            placeholder={t(messages.fieldLabel('position'))}
            options={positionOptions}
            value={formik.values.position}
            isValueError={formik.touched.position && Boolean(formik.errors.position)}
            handleChange={value => handleFieldChange('position', value)}
            isRequired
          />
        </div>

        <CardButtons
          isEditMode={chosenElementId !== null}
          allowCreate={handbooksAccess.isAllowCreate()}
          allowUpdate={handbooksAccess.isAllowUpdate()}
          allowDelete={handbooksAccess.isAllowDelete()}
          handleSubmitClick={handleSubmitClick}
          handleCancelClick={handleCancelClick}
          handleDeleteClick={handleDeleteClick}
        />
      </div>

      {showFormHasChangesAlert && (
        <Alert
          title={t(messages.alertHeader)}
          infoText={t(messages.alertInfo)}
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </>
  );
};

export default TransportDriverCard;
