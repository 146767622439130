import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { Spinner } from 'components/common/spinner/spinner';
import Pagination from 'components/common/pagination/pagination';

import usePersonalStatisticRowsAndPagination from 'utils/useGetPersonalStatisticRows';

import { RootState } from 'reducers';
import { fetchUserStatistic } from 'reducers/personalStatistics';

import styles from './tabStat.module.scss';

const cx = classNames.bind(styles);

export const TabStat = () => {
  const t = useFormatMessage();

  const dispatch = useDispatch();

  const { chosenAccount } = useSelector((state: RootState) => state.account);
  const { statisticFilter } = useSelector((state: RootState) => state.personalStatistics);

  useEffect(() => {
    if (chosenAccount) {
      dispatch(fetchUserStatistic({ id: chosenAccount, ...statisticFilter }));
    }
  }, [dispatch, chosenAccount, statisticFilter]);

  const stats = usePersonalStatisticRowsAndPagination();

  return (
    <div className={styles.container}>
      {stats.states.isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.tableWrap}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeadCell}>
                  {t('accounts.card.user.tab.statistic.table.header.date.label')}
                </th>
                <th className={styles.tableHeadCell}>
                  {t('accounts.card.user.tab.statistic.table.header.time.label')}
                </th>
                <th className={styles.tableHeadCell}>
                  {t('accounts.card.user.tab.statistic.table.header.event.label')}
                </th>
              </tr>
            </thead>
            <tbody>
              {stats.states.rows.map((row, index) => (
                <tr key={`statistic-${row.eventDescription}-${index}`} className={styles.tableRow}>
                  <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.date}</td>
                  <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.time}</td>
                  <td className={styles.tableRowCell}>
                    <span className={styles.tableRowCellDescription}>{row.eventDescription}</span>
                    {!!row.base64Icons && (
                      <div className={styles.tableRowBase64Icons}>
                        <div className={styles.tableRowCellIconWrapper}>
                          <span className={styles.tableRowCellIconDescription}>
                            {row.base64Icons?.old.accompanyingText}
                            {': '}
                          </span>
                          {!!row.base64Icons?.old.base64 ? (
                            <img src={row.base64Icons?.old.base64} alt="old_icon" className={styles.tableRowCellIcon} />
                          ) : (
                            `${t('personal-statistic.no.text')}, `
                          )}
                        </div>
                        <div className={styles.tableRowCellIconWrapper}>
                          <span className={styles.tableRowCellIconDescription}>
                            {row.base64Icons?.new.accompanyingText}
                            {': '}
                          </span>
                          {!!row.base64Icons?.new.base64 ? (
                            <img src={row.base64Icons?.new.base64} alt="new_icon" className={styles.tableRowCellIcon} />
                          ) : (
                            `${t('personal-statistic.no.text')}.`
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className={styles.pagination}>
        <Pagination
          increasePage={stats.handlers.handleIncreasePage}
          decreasePage={stats.handlers.handleDecreasePage}
          setLimit={stats.handlers.handleChangeLimit}
          limit={stats.states.limit}
          withSelect
          page={stats.states.page}
        />
      </div>
    </div>
  );
};
