export default {
  en: {
    'messages.button-block.btn.label': 'Create',

    'messages.create-modal.header.label': 'Create message',
    'messages.create-modal.content.objects.label': 'Name of an object or group of objects',
    'messages.create-modal.content.objects.placeholder': 'Please specify an object or objects',
    'messages.create-modal.content.object.label': 'Name of an object or group of objects',
    'messages.create-modal.content.object.placeholder': 'Please specify an object or objects',
    'messages.create-modal.content.text.label': 'Message text',
    'messages.create-modal.content.text.placeholder': 'Enter notification text',
    'messages.create-modal.footer.btn.copy.text': 'Copy',
    'messages.create-modal.footer.btn.cancel.text': 'Cancel',
    'messages.create-modal.footer.btn.send.text': 'Send',
    'messages.create-modal.alert.title': 'Close',
    'messages.create-modal.alert.info.text': 'There are unsaved data on the form.',

    'messages.left-bar.header.text': 'Messages',

    'messages.left-bar.filter.object.label': 'Objects',
    'messages.left-bar.filter.object.placeholder': 'Please specify an object or objects',
    'messages.left-bar.filter.type.label': 'Message types',
    'messages.left-bar.filter.type.placeholder': 'All message types',
    'messages.left-bar.filter.type.gps.text': 'GPS',
    'messages.left-bar.filter.type.amount-satellite.text': 'Number of satellites',
    'messages.left-bar.filter.type.height.text': 'Height',
    'messages.left-bar.filter.type.hdop.text': 'HDOP',

    'messages.left-bar.content.list.header.name.text': 'Title',
    'messages.left-bar.content.list.header.type.text': 'Type',
    'messages.left-bar.content.list.header.delete.text': 'Delete',
    'messages.left-bar.content.list.item.type.incoming.text': 'Incoming',
    'messages.left-bar.content.list.item.type.outgoing.text': 'Outgoing',
    'messages.left-bar.content.list.item.type.coordinates.text': 'Coordinates',
    'messages.left-bar.content.list.item.tooltip.delete.text': 'Delete',
    'messages.left-bar.content.list.item.description.tracker-number.text': 'Tracker #',
    'messages.left-bar.content.list.item.description.date.text': 'Date',
    'messages.left-bar.content.list.item.description.message.text': 'Message',

    'messages.left-bar.footer.btn.table.text': 'Table',
    'messages.left-bar.footer.btn.delete.text': 'Delete',
    'messages.left-bar.footer.btn.save.text': 'Save',
    'messages.left-bar.footer.btn.form.text': 'Generate a list of messages',

    'messages.list.template-list.alert.delete.title': 'Delete message',
    'messages.list.template-list.alert.delete.text':
      'The deleted message cannot be recovered. Are you sure you want to delete the message?',

    'messages.list.template-list.array.alert.delete.title': 'Delete messages',
    'messages.list.template-list.array.alert.delete.text':
      'The deleted messages cannot be recovered. Are you sure you want to delete the messages?',

    'messages.popup.header.text': 'The list of messages is being generated...',
    'messages.popup.content.text1':
      'It can take a long time to generate the list of messages. After the operation is completed, list of messages will be available on this tab.',
    'messages.popup.content.text2': 'Now you can close this window and continue working with the system.',
    'messages.popup.button.text': 'Close',

    'messages.table.header.label': 'Messages',
    'messages.table.header.number.text': '#',
    'messages.table.header.date.text': 'Date',
    'messages.table.header.address.text': 'From  To',
    'messages.table.header.tracker-number.text': 'Tracker #',
    'messages.table.header.type.text': 'Type',
    'messages.table.header.message.text': 'Message',
    'messages.table.footer.btn.delete.text': 'Delete',
    'messages.table.footer.btn.save.text': 'Save',
    'messages.table.footer.btn.close.text': 'Close',

    'messages.chosen-messages.popup.header.text': 'Message',
    'messages.chosen-messages.popup.body.type.text': 'Type',
    'messages.chosen-messages.popup.body.date.text': 'Date and time',
    'messages.chosen-messages.popup.body.place.text': 'Place',
    'messages.chosen-messages.popup.footer.label.text': 'Message',
    'messages.chosen-messages.popup.footer.btn.delete.text': 'Delete',
    'messages.chosen-messages.popup.footer.btn.save.text': 'Save',
    'messages.chosen-messages.popup.footer.btn.close.text': 'Close',
  },
  ru: {
    'messages.button-block.btn.label': 'Создать',

    'messages.create-modal.header.label': 'Создание сообщения',
    'messages.create-modal.content.objects.label': 'Название объекта или группы объектов',
    'messages.create-modal.content.objects.placeholder': 'Укажите объект или объекты',
    'messages.create-modal.content.object.label': 'Название объекта или группы объектов',
    'messages.create-modal.content.object.placeholder': 'Укажите объект или объекты',
    'messages.create-modal.content.text.label': 'Текст сообщения',
    'messages.create-modal.content.text.placeholder': 'Введите текст уведомления',
    'messages.create-modal.footer.btn.copy.text': 'Копировать',
    'messages.create-modal.footer.btn.cancel.text': 'Отмена',
    'messages.create-modal.footer.btn.send.text': 'Отправить',
    'messages.create-modal.alert.title': 'Закрыть',
    'messages.create-modal.alert.info.text': 'На форме остались несохраненные данные.',

    'messages.left-bar.header.text': 'Сообщения',

    'messages.left-bar.filter.object.label': 'Объекты',
    'messages.left-bar.filter.object.placeholder': 'Укажите объект или объекты',
    'messages.left-bar.filter.type.label': 'Типы сообщений',
    'messages.left-bar.filter.type.placeholder': 'Все типы сообщений',
    'messages.left-bar.filter.type.gps.text': 'GPS',
    'messages.left-bar.filter.type.amount-satellite.text': 'Количество спутников',
    'messages.left-bar.filter.type.height.text': 'Высота',
    'messages.left-bar.filter.type.hdop.text': 'HDOP',

    'messages.left-bar.content.list.header.name.text': 'Название',
    'messages.left-bar.content.list.header.type.text': 'Тип',
    'messages.left-bar.content.list.header.delete.text': 'Удалить',
    'messages.left-bar.content.list.item.type.incoming.text': 'Входящее',
    'messages.left-bar.content.list.item.type.outgoing.text': 'Исходящее',
    'messages.left-bar.content.list.item.type.coordinates.text': 'Координаты',
    'messages.left-bar.content.list.item.tooltip.delete.text': 'Удалить',
    'messages.left-bar.content.list.item.description.tracker-number.text': '№ трекера',
    'messages.left-bar.content.list.item.description.date.text': 'Дата',
    'messages.left-bar.content.list.item.description.message.text': 'Сообщение',

    'messages.left-bar.footer.btn.table.text': 'Таблица',
    'messages.left-bar.footer.btn.delete.text': 'Удалить',
    'messages.left-bar.footer.btn.save.text': 'Сохранить',
    'messages.left-bar.footer.btn.form.text': 'Сформировать список сообщений',

    'messages.list.template-list.alert.delete.title': 'Удаление сообщения',
    'messages.list.template-list.alert.delete.text':
      'Удаленное сообщение нельзя будет восстановить. Вы действительно хотите удалить сообщение?',

    'messages.list.template-list.array.alert.delete.title': 'Удаление сообщений',
    'messages.list.template-list.array.alert.delete.text':
      'Удаленные сообщения нельзя будет восстановить. Вы действительно хотите удалить сообщения?',

    'messages.popup.header.text': 'Идет формирование списка сообщений...',
    'messages.popup.content.text1':
      'На формирование списка сообщений может потребоваться значительное время. После формирования список сообщений будет доступен на этой вкладке.',
    'messages.popup.content.text2': 'Сейчас Вы можете закрыть это окно и продолжить работу с системой.',
    'messages.popup.button.text': 'Закрыть',

    'messages.table.header.label': 'Сообщения',
    'messages.table.header.number.text': '№',
    'messages.table.header.date.text': 'Дата',
    'messages.table.header.address.text': 'От Кого  Кому',
    'messages.table.header.tracker-number.text': '№ трекера',
    'messages.table.header.type.text': 'Тип',
    'messages.table.header.message.text': 'Сообщение',
    'messages.table.footer.btn.delete.text': 'Удалить',
    'messages.table.footer.btn.save.text': 'Сохранить',
    'messages.table.footer.btn.close.text': 'Закрыть',

    'messages.chosen-messages.popup.header.text': 'Сообщение',
    'messages.chosen-messages.popup.body.type.text': 'Тип',
    'messages.chosen-messages.popup.body.date.text': 'Дата и время',
    'messages.chosen-messages.popup.body.place.text': 'Место',
    'messages.chosen-messages.popup.footer.label.text': 'Сообщение',
    'messages.chosen-messages.popup.footer.btn.delete.text': 'Удалить',
    'messages.chosen-messages.popup.footer.btn.save.text': 'Сохранить',
    'messages.chosen-messages.popup.footer.btn.close.text': 'Закрыть',
  },
  zh: {
    'messages.button-block.btn.label': '创造',
    'messages.create-modal.header.label': '创造报道',
    'messages.create-modal.content.objects.label': '对象或对象组的名称',
    'messages.create-modal.content.objects.placeholder': '请指定一个对象或几个对象',
    'messages.create-modal.content.object.label': '对象或对象组的名称',
    'messages.create-modal.content.object.placeholder': '请指定一个对象或几个对象',
    'messages.create-modal.content.text.label': '报道文',
    'messages.create-modal.content.text.placeholder': '请输入通知文',
    'messages.create-modal.footer.btn.copy.text': '复制',
    'messages.create-modal.footer.btn.cancel.text': '取消',
    'messages.create-modal.footer.btn.send.text': '发出',
    'messages.create-modal.alert.title': '关闭',
    'messages.create-modal.alert.info.text': '表内有不保存的数据。',
    'messages.left-bar.header.text': '报道 ',
    'messages.left-bar.filter.object.label': '对象 ',
    'messages.left-bar.filter.object.placeholder': '请指定一个对象或几个对象',
    'messages.left-bar.filter.type.label': '报道种类',
    'messages.left-bar.filter.type.placeholder': '所有的报道种类',
    'messages.left-bar.filter.type.gps.text': 'GPS',
    'messages.left-bar.filter.type.amount-satellite.text': '卫星数量',
    'messages.left-bar.filter.type.height.text': '高度',
    'messages.left-bar.filter.type.hdop.text': 'HDOP',
    'messages.left-bar.content.list.header.name.text': '名称',
    'messages.left-bar.content.list.header.type.text': '种类',
    'messages.left-bar.content.list.header.delete.text': '消除',
    'messages.left-bar.content.list.item.type.incoming.text': '来到',
    'messages.left-bar.content.list.item.type.outgoing.text': '发出',
    'messages.left-bar.content.list.item.type.coordinates.text': '坐标',
    'messages.left-bar.content.list.item.tooltip.delete.text': '消除',
    'messages.left-bar.content.list.item.description.tracker-number.text': '跟踪器号',
    'messages.left-bar.content.list.item.description.date.text': '日期',
    'messages.left-bar.content.list.item.description.message.text': '报道 ',
    'messages.left-bar.footer.btn.table.text': '表 ',
    'messages.left-bar.footer.btn.delete.text': '消除',
    'messages.left-bar.footer.btn.save.text': '保存',
    'messages.left-bar.footer.btn.form.text': '形成报道清单',
    'messages.list.template-list.alert.delete.title': '消除报道',
    'messages.list.template-list.alert.delete.text': '已消除的报道无法恢复。 您真的要消除报道吗？',
    'messages.list.template-list.array.alert.delete.title': '消除报道',
    'messages.list.template-list.array.alert.delete.text': '已消除的报道无法恢复。 您真的要消除报道吗？',
    'messages.popup.header.text': '报道清单在形成过程中。。。',
    'messages.popup.content.text1': '形成报道清单可要很多时间。形成后在这个标签可以找到报道清单。',
    'messages.popup.content.text2': '现在您可以关闭这个窗口并继续系统工作。',
    'messages.popup.button.text': '关闭',
    'messages.table.header.label': '报道',
    'messages.table.header.number.text': '号',
    'messages.table.header.date.text': '日期',
    'messages.table.header.address.text': '从 / 向',
    'messages.table.header.tracker-number.text': '跟踪器号',
    'messages.table.header.type.text': '种类',
    'messages.table.header.message.text': '报道 ',
    'messages.table.footer.btn.delete.text': '消除',
    'messages.table.footer.btn.save.text': '保存',
    'messages.table.footer.btn.close.text': '关闭',
    'messages.chosen-messages.popup.header.text': '报道',
    'messages.chosen-messages.popup.body.type.text': '种类',
    'messages.chosen-messages.popup.body.date.text': '日期与时间',
    'messages.chosen-messages.popup.body.place.text': '地方',
    'messages.chosen-messages.popup.footer.label.text': '报道 ',
    'messages.chosen-messages.popup.footer.btn.delete.text': '消除',
    'messages.chosen-messages.popup.footer.btn.save.text': '保存',
    'messages.chosen-messages.popup.footer.btn.close.text': '关闭',
  },
  ar: {
    'messages.button-block.btn.label': 'إنشاء',
    'messages.create-modal.header.label': 'إنشاء رسالة',
    'messages.create-modal.content.objects.label': 'اسم شئ أو مجموعة أشياء',
    'messages.create-modal.content.objects.placeholder': 'يرجى تحديد شئ أو أشياء',
    'messages.create-modal.content.object.label': 'اسم شئ أو مجموعة أشياء',
    'messages.create-modal.content.object.placeholder': 'يرجى تحديد شئ  أو أشياء',
    'messages.create-modal.content.text.label': 'رسالة نصية',
    'messages.create-modal.content.text.placeholder': 'أدخل نص الإشعار',
    'messages.create-modal.footer.btn.copy.text': 'نسخ',
    'messages.create-modal.footer.btn.cancel.text': 'ألغاء',
    'messages.create-modal.footer.btn.send.text': 'إرسال',
    'messages.create-modal.alert.title': 'إغلاق',
    'messages.create-modal.alert.info.text': 'هناك بيانات غير محفوظة في النموذج.',
    'messages.left-bar.header.text': 'الرسائل',
    'messages.left-bar.filter.object.label': 'الأشياء',
    'messages.left-bar.filter.object.placeholder': 'يرجى تحديد شئ أو أشياء',
    'messages.left-bar.filter.type.label': 'أنواع الرسائل',
    'messages.left-bar.filter.type.placeholder': 'جميع أنواع الرسائل',
    'messages.left-bar.filter.type.gps.text': 'GPS',
    'messages.left-bar.filter.type.amount-satellite.text': 'عدد الأقمار الصناعية',
    'messages.left-bar.filter.type.height.text': 'الارتفاع',
    'messages.left-bar.filter.type.hdop.text': 'HDOP',
    'messages.left-bar.content.list.header.name.text': 'الاسم',
    'messages.left-bar.content.list.header.type.text': 'النوع',
    'messages.left-bar.content.list.header.delete.text': 'حذف',
    'messages.left-bar.content.list.item.type.incoming.text': 'وارد',
    'messages.left-bar.content.list.item.type.outgoing.text': 'صادر',
    'messages.left-bar.content.list.item.type.coordinates.text': 'الإحداثيات',
    'messages.left-bar.content.list.item.tooltip.delete.text': 'حذف',
    'messages.left-bar.content.list.item.description.tracker-number.text': 'رقم التعقب',
    'messages.left-bar.content.list.item.description.date.text': 'التاريخ',
    'messages.left-bar.content.list.item.description.message.text': 'رسالة',
    'messages.left-bar.footer.btn.table.text': 'جدول',
    'messages.left-bar.footer.btn.delete.text': 'حذف',
    'messages.left-bar.footer.btn.save.text': 'حفظ',
    'messages.left-bar.footer.btn.form.text': 'عمل قائمة بالرسائل',
    'messages.list.template-list.alert.delete.title': 'حذف الرسالة',
    'messages.list.template-list.alert.delete.text':
      'لا يمكن استعادة الرسالة المحذوفة. هل أنت متأكد أنك تريد حذف الرسالة؟',
    'messages.list.template-list.array.alert.delete.title': 'حذف الرسائل',
    'messages.list.template-list.array.alert.delete.text':
      'لا يمكن استعادة الرسائل المحذوفة. هل أنت متأكد أنك تريد حذف الرسائل؟',
    'messages.popup.header.text': 'يتم إنشاء قائمة بالرسائل ...',
    'messages.popup.content.text1':
      'قد يستغرق إنشاء قائمة بالرسائل وقتًا طويلاً. بعد تشكيل قائمة الرسائل ستكون متاحة في علامة التبويب هذه.',
    'messages.popup.content.text2': 'يمكنك الآن إغلاق هذه النافذة ومواصلة العمل مع النظام.',
    'messages.popup.button.text': 'أغلاق',
    'messages.table.header.label': 'الرسائل',
    'messages.table.header.number.text': 'رقم',
    'messages.table.header.date.text': 'التاريخ',
    'messages.table.header.address.text': 'من / لمن',
    'messages.table.header.tracker-number.text': 'رقم التعقب',
    'messages.table.header.type.text': 'النوع',
    'messages.table.header.message.text': 'رسالة',
    'messages.table.footer.btn.delete.text': 'حذف',
    'messages.table.footer.btn.save.text': 'حفظ',
    'messages.table.footer.btn.close.text': 'أغلاق',
    'messages.chosen-messages.popup.header.text': 'رسالة',
    'messages.chosen-messages.popup.body.type.text': 'النوع',
    'messages.chosen-messages.popup.body.date.text': 'التاريخ و الوقت',
    'messages.chosen-messages.popup.body.place.text': 'المكان',
    'messages.chosen-messages.popup.footer.label.text': 'رسالة',
    'messages.chosen-messages.popup.footer.btn.delete.text': 'حذف',
    'messages.chosen-messages.popup.footer.btn.save.text': 'حفظ',
    'messages.chosen-messages.popup.footer.btn.close.text': 'أغلاق',
  },
  de: {
    'messages.button-block.btn.label': 'Erstellen',
    'messages.create-modal.header.label': 'Nachrichten erstellen',
    'messages.create-modal.content.objects.label': 'Name eines Objekts oder einer Gruppe von Objekten',
    'messages.create-modal.content.objects.placeholder': 'Geben Sie ein Objekt oder Objekte an',
    'messages.create-modal.content.object.label': 'Name eines Objekts oder einer Gruppe von Objekten',
    'messages.create-modal.content.object.placeholder': 'Geben Sie ein Objekt oder Objekte an',
    'messages.create-modal.content.text.label': 'Nachrichtentext',
    'messages.create-modal.content.text.placeholder': 'Benachrichtigungstext eingeben',
    'messages.create-modal.footer.btn.copy.text': 'Kopieren',
    'messages.create-modal.footer.btn.cancel.text': 'Abbrechen ',
    'messages.create-modal.footer.btn.send.text': 'Senden',
    'messages.create-modal.alert.title': 'schließen',
    'messages.create-modal.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'messages.left-bar.header.text': 'Nachrichten',
    'messages.left-bar.filter.object.label': 'Objekte',
    'messages.left-bar.filter.object.placeholder': 'Geben Sie ein Objekt oder Objekte an',
    'messages.left-bar.filter.type.label': 'Nachrichtentypen',
    'messages.left-bar.filter.type.placeholder': 'Alle Arten von Nachrichten',
    'messages.left-bar.filter.type.gps.text': 'GPS',
    'messages.left-bar.filter.type.amount-satellite.text': 'Anzahl der Satelliten',
    'messages.left-bar.filter.type.height.text': 'Höhe',
    'messages.left-bar.filter.type.hdop.text': 'HDOP',
    'messages.left-bar.content.list.header.name.text': 'Name',
    'messages.left-bar.content.list.header.type.text': 'Typ',
    'messages.left-bar.content.list.header.delete.text': 'Löschen',
    'messages.left-bar.content.list.item.type.incoming.text': 'Eingehende',
    'messages.left-bar.content.list.item.type.outgoing.text': 'Ausgehende',
    'messages.left-bar.content.list.item.type.coordinates.text': 'Koordinaten',
    'messages.left-bar.content.list.item.tooltip.delete.text': 'Löschen',
    'messages.left-bar.content.list.item.description.tracker-number.text': 'Tracker-Nummer',
    'messages.left-bar.content.list.item.description.date.text': 'Datum',
    'messages.left-bar.content.list.item.description.message.text': 'Nachricht',
    'messages.left-bar.footer.btn.table.text': 'Tabelle',
    'messages.left-bar.footer.btn.delete.text': 'Löschen',
    'messages.left-bar.footer.btn.save.text': 'Speichern',
    'messages.left-bar.footer.btn.form.text': 'Erstellen Sie eine Nachrichtenliste',
    'messages.list.template-list.alert.delete.title': 'Nachricht löschen',
    'messages.list.template-list.alert.delete.text':
      'Eine gelöschte Nachricht kann nicht wiederhergestellt werden. Möchten Sie die Nachricht wirklich löschen?',
    'messages.list.template-list.array.alert.delete.title': 'Nachrichten löschen',
    'messages.list.template-list.array.alert.delete.text':
      'Gelöschte Nachrichten können nicht wiederhergestellt werden. Möchten Sie Nachrichten wirklich löschen?',
    'messages.popup.header.text': 'Nachrichtenliste wird erstellt...',
    'messages.popup.content.text1':
      'Die Bildung einer Nachrichtenliste kann lange dauern. Nach der Bildung der Liste der Nachrichten wird auf dieser Registerkarte verfügbar sein.',
    'messages.popup.content.text2': 'Jetzt können Sie dieses Fenster schließen und mit dem System weiterarbeiten.',
    'messages.popup.button.text': 'schließen',
    'messages.table.header.label': 'Nachrichten',
    'messages.table.header.number.text': 'Nr.',
    'messages.table.header.date.text': 'Datum',
    'messages.table.header.address.text': 'Von wem/an',
    'messages.table.header.tracker-number.text': 'Tracker-Nummer',
    'messages.table.header.type.text': 'Typ',
    'messages.table.header.message.text': 'Nachricht',
    'messages.table.footer.btn.delete.text': 'Löschen',
    'messages.table.footer.btn.save.text': 'Speichern',
    'messages.table.footer.btn.close.text': 'schließen',
    'messages.chosen-messages.popup.header.text': 'Nachricht',
    'messages.chosen-messages.popup.body.type.text': 'Typ',
    'messages.chosen-messages.popup.body.date.text': 'Datum und Uhrzeit',
    'messages.chosen-messages.popup.body.place.text': 'Ort',
    'messages.chosen-messages.popup.footer.label.text': 'Nachricht',
    'messages.chosen-messages.popup.footer.btn.delete.text': 'Löschen',
    'messages.chosen-messages.popup.footer.btn.save.text': 'Speichern',
    'messages.chosen-messages.popup.footer.btn.close.text': 'schließen',
  },
};
