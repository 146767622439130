import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import Select from 'components/common/select/select';
import {
  PoiCardVideoCameraHandlers,
  PoiCardVideoCameraAdditionalData,
  PoiCardVideoCameraData,
} from 'components/poi/utils/types';

import styles from '../poiCard.module.scss';

const cx = classNames.bind(styles);

interface Props {
  storeData: PoiCardVideoCameraData;
  additionalData: PoiCardVideoCameraAdditionalData;
  handlers: PoiCardVideoCameraHandlers;
  invalidFields: string[];
}

const PoiVideoCameraContent = ({ storeData, additionalData, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();

  return (
    <Fragment>
      <Input
        label={t('poi.card.videocamera.field.name.label')}
        placeholder={t('poi.card.videocamera.field.name.placeholder')}
        isValueError={invalidFields.includes('name')}
        isRequired={true}
        value={storeData.name}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeName(value)}
      />
      <TextArea
        label={t('poi.card.videocamera.field.desc.label')}
        placeholder={t('poi.card.videocamera.field.desc.placeholder')}
        isRequired={false}
        value={storeData.description}
        containerStyle={cx(styles.row, styles.rowTextarea)}
        handleChange={value => handlers.handleChangeDescription(value)}
        autoSetHeight
      />
      <Select
        options={additionalData.poiVideocameraModelIdForSelect}
        label={t('poi.card.videocamera.field.model.label')}
        placeholder={t('poi.card.videocamera.field.model.placeholder')}
        isRequired={false}
        withSearchIcon
        value={
          additionalData.poiVideocameraModelIdForSelect.findIndex(
            val => val.data?.id === storeData.poiVideocameraModelId
          ) + 1 || ''
        }
        containerStyle={styles.row}
        disabled={false}
        handleChange={value => handlers.handleChangePoiVideocameraModelId(value)}
      />
      <Input
        label={t('poi.card.videocamera.field.link.label')}
        placeholder={t('poi.card.videocamera.field.link.placeholder')}
        isValueError={invalidFields.includes('dataSourceUrl')}
        isRequired={true}
        value={storeData.dataSourceUrl}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeDataSourceUrl(value)}
      />
      <Input
        label={t('poi.card.videocamera.field.coords.label')}
        placeholder={t('poi.card.videocamera.field.coords.placeholder')}
        isValueError={invalidFields.includes('coordinates')}
        isRequired={true}
        value={storeData.coordinates}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeCoordinates(value)}
        withGeoIcon
      />
      <Input
        label={t('poi.card.videocamera.field.angle.label')}
        placeholder={t('poi.card.videocamera.field.angle.placeholder')}
        isRequired={false}
        value={String(storeData.angle)}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeAngle(value)}
      />
      <Input
        label={t('poi.card.videocamera.field.viewing.label')}
        placeholder={t('poi.card.videocamera.field.viewing.placeholder')}
        isRequired={false}
        value={String(storeData.distance)}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeDistance(value)}
      />
      <Input
        label={t('poi.card.videocamera.field.direction.label')}
        placeholder={t('poi.card.videocamera.field.direction.placeholder')}
        isRequired={false}
        value={String(storeData.direction)}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeDirection(value)}
      />
    </Fragment>
  );
};

export default PoiVideoCameraContent;
