import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { setMarkerType } from 'reducers/map';

import { MARKERS } from 'utils/consts';

export function useSetMarkerType() {
  const dispatch = useDispatch();
  const trackableUnitView = useSelector((state: RootState) => state.user.userPreferences.trackableUnitView);

  useEffect(() => {
    if (trackableUnitView.alwaysShow) {
      dispatch(setMarkerType(MARKERS.default));
    } else {
      dispatch(setMarkerType(MARKERS.onmouseover));
    }
  }, [dispatch, trackableUnitView]);
}
