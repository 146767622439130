import React, { Fragment, ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as ManualSortingIcon } from 'assets/img/records/manual_sorting_button.svg';

import { InfoElement } from 'components/common/infoElement/infoElement';
import {
  RecordsConditionsTabAdditionalData,
  RecordsConditionsTabHandlers,
  RecordsConditionsTabStates,
} from 'components/records/utils/types';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { RadioButton } from 'components/common/radioButton/radioButton';
import MultipleSelectGroup, {
  getValuesForMultipleSelectGroup,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';
import { Spinner } from 'components/common/spinner/spinner';

import styles from './conditionsTab.module.scss';

const cx = classNames.bind(styles);

interface Props {
  states: RecordsConditionsTabStates;
  additionalData: RecordsConditionsTabAdditionalData;
  handlers: RecordsConditionsTabHandlers;
  invalidFields: string[];
}

type SortableItemProps = {
  children: ReactNode;
};
type SortableListProps = {
  children: ReactNode;
  style?: string;
};

const DragHandler = SortableHandle(() => <ManualSortingIcon className={styles.sortableListItemManualSorting} />);
const SortableItem = SortableElement(({ children }: SortableItemProps) => (
  <li className={cx(styles.sortableListItem)}>
    <DragHandler />
    {children}
  </li>
));
const SortableList = SortableContainer(({ style = '', children }: SortableListProps) => (
  <div className={style}>{children}</div>
));

export const ConditionsTab = ({ states, additionalData, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();

  if (additionalData.isDataLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      <div className={styles.column}>
        <div className={cx(styles.row, styles.group)}>
          <div className={styles.groupLabel}>{t('records.card.template-create.condition.grouping.label')}</div>
          <SortableList axis="y" lockAxis="y" onSortEnd={handlers.handleSortEnd} useDragHandle>
            <ul className={styles.groupList}>
              {states.groupingList.map((group, i) => (
                <SortableItem key={`group-template-by-${group.key}-${i}`} index={i}>
                  <div className={styles.checkboxWrapper} onClick={() => handlers.handleCheckGroup(i)}>
                    <Checkbox checked={group.checked} wrapperStyle={styles.checkbox} />
                    <span>{t(group.text)}</span>
                  </div>
                </SortableItem>
              ))}
            </ul>
          </SortableList>
          <InfoElement
            id="createTemplateGrouping"
            text={t('records.card.template-create.condition.grouping.tooltip.text')}
            customStyle={styles.groupTooltipIcon}
          />
        </div>
        <div className={cx(styles.row, styles.group)}>
          <div className={styles.groupLabel}>{t('records.card.template-create.condition.map.label')}</div>
          <ul className={styles.groupList}>
            <li onClick={handlers.handleCheckGeofences} className={styles.groupListItem}>
              <Checkbox checked={states.geofences} wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)} />
              <span>{t('records.card.template-create.condition.map.geofences.text')}</span>
            </li>
            <li onClick={handlers.handleCheckNotificationMarkers} className={styles.groupListItem}>
              <Checkbox
                checked={states.notificationMarkers}
                wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)}
              />
              <span>{t('records.card.template-create.condition.map.notificationmarkers.text')}</span>
            </li>
            <li onClick={handlers.handleCheckMovementTrack} className={styles.groupListItem}>
              <Checkbox
                checked={states.movementTracks}
                wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)}
              />
              <span>{t('records.card.template-create.condition.map.movementtracks.text')}</span>
            </li>
            <li onClick={handlers.handleCheckPoiPoints} className={styles.groupListItem}>
              <Checkbox checked={states.POIPoints} wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)} />
              <span>{t('records.card.template-create.condition.map.poipoints.text')}</span>
            </li>
            <li onClick={handlers.handleCheckHeatmaps} className={styles.groupListItem}>
              <Checkbox checked={states.heatmaps} wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)} />
              <span>{t('records.card.template-create.condition.map.heatmaps.text')}</span>
            </li>
          </ul>
          <InfoElement
            id="createTemplateMap"
            text={t('records.card.template-create.condition.map.tooltip.text')}
            customStyle={styles.groupTooltipIcon}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div
          className={cx(styles.row, styles.rowWithTooltip, {
            [styles.rowWithOptions]: states.limitListOfObjects,
          })}
        >
          <div className={styles.checkboxWrapper} onClick={handlers.handleCheckLimitListOfObjects}>
            <Checkbox
              checked={states.limitListOfObjects}
              wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)}
            />
            <span>{t('records.card.template-create.condition.additional-fields.text')}</span>
          </div>
          <InfoElement
            id="createTemplateAdditionalFields"
            text={t('records.card.template-create.condition.additional-fields.tooltip.text')}
            customStyle={styles.tooltipIcon}
          />
        </div>
        {states.limitListOfObjects && (
          <Fragment>
            <ul className={cx(styles.row, styles.rowInner)}>
              {states.additionalFilters.map((additionalField, i) => (
                <li
                  key={`additional-field-${additionalField.key}-${i}`}
                  onClick={() => handlers.handleCheckAdditionalFilter(i)}
                  className={styles.radioButtonWrapper}
                >
                  <RadioButton
                    checked={additionalField.checked}
                    name="additionalField"
                    wrapperStyle={styles.radioButton}
                  />
                  <span>{t(additionalField.text)}</span>
                </li>
              ))}
            </ul>
            <MultipleSelectGroup
              label={t('records.card.template-create.condition.additional-fields.object-name.label')}
              placeholder={t('records.card.template-create.condition.additional-fields.object-name.placeholder')}
              isValueError={invalidFields.includes('objectsNames')}
              isRequired={true}
              disabled={!states.additionalFilters.filter(addFilter => addFilter.checked).length}
              wrapperStyle={styles.row}
              withSearchIcon
              options={additionalData.objectsIdsForSelect}
              values={getValuesForMultipleSelectGroup({
                options: additionalData.objectsIdsForSelect,
                values: states.objectIds,
              })}
              translateGroups={additionalData.translateGroups}
              handleChange={handlers.handleChangeObjects}
              optionIsInline
            />
          </Fragment>
        )}
        <div className={styles.row}>
          <div className={styles.checkboxWrapper} onClick={handlers.handleCheckLineNumeration}>
            <Checkbox checked={states.lineNumeration} wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)} />
            <span>{t('records.card.template-create.condition.additional-fields.numbering-line.text')}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.checkboxWrapper} onClick={handlers.handleCheckTotalColumn}>
            <Checkbox checked={states.totalColumn} wrapperStyle={cx(styles.checkbox, styles.checkboxNoMarginLeft)} />
            <span>{t('records.card.template-create.condition.additional-fields.display-total.text')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
