import alert from './alert';
import search from './search';
import usersettings from './usersettings';
import datesRangeWithScripts from './datesRangeWithScripts';
import photo from './photo';
import inputIconFile from './inputIconFile';
import personalStatistic from './personalStatistic';
import multipleSelectGroup from './multipleSelectGroup';

export default {
  en: {
    ...alert.en,
    ...search.en,
    ...usersettings.en,
    ...datesRangeWithScripts.en,
    ...photo.en,
    ...inputIconFile.en,
    ...personalStatistic.en,
    ...multipleSelectGroup.en,
  },
  ru: {
    ...alert.ru,
    ...search.ru,
    ...usersettings.ru,
    ...datesRangeWithScripts.ru,
    ...photo.ru,
    ...inputIconFile.ru,
    ...personalStatistic.ru,
    ...multipleSelectGroup.ru,
  },
  zh: {
    ...alert.zh,
    ...search.zh,
    ...usersettings.zh,
    ...datesRangeWithScripts.zh,
    ...photo.zh,
    ...inputIconFile.zh,
    ...personalStatistic.zh,
    ...multipleSelectGroup.zh,
  },
  ar: {
    ...alert.ar,
    ...search.ar,
    ...usersettings.ar,
    ...datesRangeWithScripts.ar,
    ...photo.ar,
    ...inputIconFile.ar,
    ...personalStatistic.ar,
    ...multipleSelectGroup.ar,
  },
  de: {
    ...alert.de,
    ...search.de,
    ...usersettings.de,
    ...datesRangeWithScripts.de,
    ...photo.de,
    ...inputIconFile.de,
    ...personalStatistic.de,
    ...multipleSelectGroup.de,
  },
};
