import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { Spinner } from 'components/common/spinner/spinner';
import { HeaderModal } from './components/headerModal/headerModal';
import { ControlsModal } from './components/controlsModal/controlsModal';
import { PaginationFilterModal } from './components/paginationFilterModal/paginationFilterModal';
import { TableModal } from './components/tableModal/tableModal';
import DatesFilterModal from './components/datesFilterModal/datesFilterModal';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal/index';
import { clearHistoryLogsFilter, fetchHandbookStatistic, exportStatisticToFile } from 'reducers/handbooks';

import styles from './handbooksModal.module.scss';

const HandbooksStatisticModal = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { statisticIsLoading, statisticFileIsExporting } = useSelector((state: RootState) => state.handbooks);

  useEffect(() => {
    dispatch(fetchHandbookStatistic());

    return () => {
      dispatch(clearHistoryLogsFilter());
    };
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    dispatch(exportStatisticToFile());
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalHeader}>
        <HeaderModal title={t('handbooks.statistic-modal.header.label')} onCancel={handleCancel} />
      </div>
      <div className={styles.modalBody}>
        <DatesFilterModal />
        <div className={styles.modalBodyTable}>
          {statisticIsLoading || statisticFileIsExporting ? <Spinner /> : <TableModal />}
        </div>
        <PaginationFilterModal />
      </div>
      <div className={styles.modalControls}>
        <ControlsModal onClose={handleCancel} onSave={handleSave} />
      </div>
    </div>
  );
};

export default HandbooksStatisticModal;
