import React from 'react';
import classNames from 'classnames/bind';
import styles from './speedButton.module.scss';

const cx = classNames.bind(styles);

type Props = {
  onClick?: () => void;
  text: string;
  downed?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  customStyle?: string;
};

export const SpeedButton = (props: Props): JSX.Element => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      onClick={handleClick}
      className={cx(
        styles.main,
        props.downed && styles.downed,
        props.disabled && styles.disabled,
        props.rounded && styles.rounded,
        props.customStyle ?? ''
      )}
    >
      <div>{props.text}</div>
    </button>
  );
};
