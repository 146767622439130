import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AccountSettingsKeysToNameTabsEnum } from 'components/accounts/utils/consts';

import { getUserData, getUserPreferences, putUserPreferences } from './api';
import {
  TabPreference,
  UpdateUserPreferences,
  User,
  UserPreferencesJSONApi,
  UserPreferences,
  Permissions,
  UserAttributes,
} from './interface';

import { DEFAULT_LANGUAGES, LANGUAGES_TO_TEXT_ENUM } from 'translate';
import { DEFAULT_TIMEZONE } from 'utils/consts';

const defaultUserAttributes: UserAttributes = {
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
  login: '',
  firstName: '',
  secondName: '',
  lastName: '',
  simNumber: '',
  employeeId: null,
  organizationId: null,
  departmentId: null,
  positionId: null,
  smsNotified: false,
  emailNotified: false,
  isBanned: false,
  email: '',
  icon: null,
};

const defaultUserPermissions: Permissions = {
  employees: '0000',
  transports: '0000',
  trackers: '0000',
  users: '0000',
  roles: '0000',
  geozones: '0000',
  tracks: '0000',
  reports: '0000',
  userPreferences: '0000',
  map: '0000',
  buildings: '0000',
  notifications: '0000',
  records: '0000',
  poi: '0000',
  handbooks: '0000',
  messages: '0000',
};

const defaultUserPreferences: UserPreferences = {
  id: 0,
  userId: 0,
  locale: LANGUAGES_TO_TEXT_ENUM[DEFAULT_LANGUAGES],
  metricSystem: '',
  timezone: DEFAULT_TIMEZONE,
  dateFormat: '',
  timeFormat: '',
  gpsFormat: '',
  tabs: [],
  trackableUnitView: {
    trackerNumberIsShown: false,
    fullNameIsShown: false,
    organizationIsShown: false,
    departmentIsShown: false,
    positionOrDriverIsShown: false,
    alwaysShow: false,
    onRightSign: false,
    hasBackground: false,
  },
  monitoringListConfig: [],
  notificationsListConfig: [],
};

const initialState: User = {
  type: '',
  id: '0',
  attributes: defaultUserAttributes,
  permissions: defaultUserPermissions,
  userPreferences: defaultUserPreferences,
};

export const fetchUserData = createAsyncThunk('userSlice/fetchUserMe', async () => await getUserData());

export const fetchUserPreferences = createAsyncThunk(
  'userSlice/fetchUserPreferences',
  async () => await getUserPreferences()
);

export const updateUserPreferences = createAsyncThunk(
  'userSlice/updateUserPreferences',
  async (data: UpdateUserPreferences) => await putUserPreferences(data)
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setInterfaceLanguage: (state, { payload }: { payload: LANGUAGES_TO_TEXT_ENUM }) => {
      state.userPreferences.locale = payload;
    },
    clearUserData: state => {
      state.type = '';
      state.id = '0';

      state.attributes = defaultUserAttributes;
      state.permissions = defaultUserPermissions;
      state.userPreferences = defaultUserPreferences;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const data = action.payload.data;
      const meta = action.payload.meta;
      state.type = data.type;
      state.id = data.id;
      state.attributes = data.attributes;
      state.permissions = meta.permissions;
    });
    builder.addCase(fetchUserPreferences.fulfilled, (state, { payload }: { payload: UserPreferencesJSONApi }) => {
      const data = payload.data;

      if (data?.attributes) {
        sessionStorage.setItem('userId', String(data.attributes.userId)); // для обновления токена

        state.userPreferences = {
          ...data.attributes,
          id: data.id,
          tabs: data.attributes.tabs.map((tab: TabPreference) => ({
            ...tab,
            title: AccountSettingsKeysToNameTabsEnum[tab.keyName as keyof typeof AccountSettingsKeysToNameTabsEnum],
          })),
          trackableUnitView:
            typeof data.attributes.trackableUnitView === 'string'
              ? JSON.parse(data.attributes.trackableUnitView)
              : data.attributes.trackableUnitView,
          monitoringListConfig:
            typeof data.attributes.monitoringListConfig === 'string'
              ? JSON.parse(data.attributes.monitoringListConfig)
              : data.attributes.monitoringListConfig,
          notificationsListConfig:
            typeof data.attributes.notificationsListConfig === 'string'
              ? JSON.parse(data.attributes.notificationsListConfig)
              : data.attributes.notificationsListConfig,
        };
      }
    });
    builder.addCase(updateUserPreferences.fulfilled, (state, { payload }: { payload: UserPreferencesJSONApi }) => {
      const data = payload.data;

      sessionStorage.setItem('userId', String(data.attributes.userId)); // для обновления токена
      state.userPreferences = {
        ...data.attributes,
        id: data.id,
        tabs: data.attributes.tabs.map((tab: TabPreference) => ({
          ...tab,
          title: AccountSettingsKeysToNameTabsEnum[tab.keyName as keyof typeof AccountSettingsKeysToNameTabsEnum],
        })),
        trackableUnitView:
          typeof data.attributes.trackableUnitView === 'string'
            ? JSON.parse(data.attributes.trackableUnitView)
            : data.attributes.trackableUnitView,
        monitoringListConfig:
          typeof data.attributes.monitoringListConfig === 'string'
            ? JSON.parse(data.attributes.monitoringListConfig)
            : data.attributes.monitoringListConfig,
        notificationsListConfig:
          typeof data.attributes.notificationsListConfig === 'string'
            ? JSON.parse(data.attributes.notificationsListConfig)
            : data.attributes.notificationsListConfig,
      };
    });
  },
});

export const { setInterfaceLanguage, clearUserData } = userSlice.actions;

export default userSlice.reducer;
