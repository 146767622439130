import { reqHandlers } from 'utils/api';
import { getCurrentLocale, LANGUAGES_TO_TEXT_ENUM } from 'translate';
import { UpdateUserPreferences } from './interface';

export function getUserData() {
  return reqHandlers.get('/api/users/me');
}

export function getUserPreferences() {
  return reqHandlers.get('/api/userPreferences');
}

export function putUserPreferences(data: UpdateUserPreferences) {
  const { locale } = data;
  if (locale) {
    data.locale = getCurrentLocale(locale as LANGUAGES_TO_TEXT_ENUM);
  }
  return reqHandlers.put('/api/userPreferences', data);
}
