import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';

import styles from './controlsModal.module.scss';

type Props = {
  onDelete?: () => void;
  onCancel: () => void;
  onSave: () => void;
  onCopy: () => void;
  withoutDelete?: boolean;
  withoutCopy?: boolean;
  saveButtonText: string;
};

export const ControlsModal = ({
  onDelete,
  onCancel,
  onSave,
  onCopy,
  withoutCopy,
  withoutDelete,
  saveButtonText,
}: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.wrapper}>
      {!withoutCopy && (
        <Button white text={t('records.card.template-create.footer.btn.copy.label')} withCopy onClick={onCopy} />
      )}
      {!withoutDelete && (
        <Button red text={t('records.card.template-create.footer.btn.delete.label')} onClick={onDelete} />
      )}
      <Button white text={t('records.card.template-create.footer.btn.cancel.label')} onClick={onCancel} />
      <Button blue text={saveButtonText} onClick={onSave} />
    </div>
  );
};
