import React, { FC, useMemo, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import classNames from 'classnames/bind';
import { setAllShowingIds, toggleCheckedAllPoi, toggleTurnAllPoi } from 'reducers/poi';
import { ReactComponent as TurnAllOn } from 'assets/img/poi/turn_on.svg';
import { ReactComponent as Marker } from 'assets/img/poi/marker.svg';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import AccessEntity from 'utils/accessEntity';
import styles from './poiListHeader.module.scss';

const cx = classNames.bind(styles);

type Props = {
  showExpand: boolean;
};

const PoiListHeader: FC<Props> = ({ showExpand }: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const poiPermissions = useSelector((state: RootState) => state.user.permissions.poi);
  const poiAccess = useMemo(() => new AccessEntity(poiPermissions), [poiPermissions]);
  const { data: poiData, selectedPoi } = useSelector((state: RootState) => state.poi);

  const [turnAll, setTurnAll] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const handleTurnedPoi = () => {
    if (poiAccess.isAllowUpdate()) {
      dispatch(toggleTurnAllPoi(turnAll));
      setTurnAll(!turnAll);
    }
  };

  const handleCheckedAllPoi = () => {
    if ((selectedPoi.length && selectedPoi.length < poiData.length) || !selectedPoi.length) {
      return dispatch(toggleCheckedAllPoi(true));
    }
    return dispatch(toggleCheckedAllPoi(false));
  };

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  const handleShowAllPoi = () => {
    dispatch(setAllShowingIds());
  };

  const headCheckboxStatus = selectedPoi.length === poiData.length && poiData.length > 0;
  const headCheckboxStatusIndeterminate = selectedPoi.length > 0 && selectedPoi.length < poiData.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        {showExpand && <ExpandButton isOpen={expandAll} onClick={handleExpandAll} style={styles.infoExpand} />}
        <Checkbox
          handleCheck={handleCheckedAllPoi}
          checked={headCheckboxStatus}
          isIndeterminate={headCheckboxStatusIndeterminate}
        />
        <div className={styles.infoNameHead}>
          <div>{t('poi.list.header.name.text')}</div>
        </div>
      </div>
      <div className={styles.buttons}>
        <div
          className={cx(styles.buttonsIcon, {
            [styles.buttonsIconDisabled]: !poiAccess.isAllowUpdate(),
          })}
          onClick={handleTurnedPoi}
        >
          <TurnAllOn data-tip data-for="turnAllOn" />
          {poiAccess.isAllowUpdate() && (
            <ReactTooltip className={styles.customTooltip} id="turnAllOn" place="right" type="light" effect="solid">
              <span>{t('poi.list.header.tooltip.turn.text')}</span>
            </ReactTooltip>
          )}
        </div>
        <div className={styles.buttonsIcon} onClick={handleShowAllPoi}>
          <Marker data-tip data-for="poiOnMap" />
          <ReactTooltip className={styles.customTooltip} id="poiOnMap" place="right" type="light" effect="solid">
            <span>{t('poi.list.header.tooltip.view.text')}</span>
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default PoiListHeader;
