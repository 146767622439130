export default {
  en: {
    'toast.records.template.name-less.error.text': 'The name must be at least 3 characters',

    'toast.records.template-modal.main.printable-template.file-type.error.text': 'Supported file types: DOCX, XLSX',
    'toast.records.template-modal.main.printable-template.file-size.error.text': 'The file size must not exceed 10 Mb',

    'toast.records.reports-history.get-list.error.text': 'Reports get error',

    'toast.records.get-report.status.no-report-has-template-id.error.text':
      'Report is defective and does not contain information about the template',
    'toast.records.get-report.status.no-template-has-printable-template-id.error.text':
      'The template for this report does not contain a printable file',

    'toast.records.generated-printed-form.status.202.text': 'Report is in compilation. Try later',
    'toast.records.generated-printed-form.status.404.text': 'Not found',
    'toast.records.generated-printed-form.status.406.text': 'Report has error',
    'toast.records.generated-printed-form.status.500.text': 'Unknown error',
    'toast.records.generated-printed-form.na-file-name.text': 'No file name',
  },
  ru: {
    'toast.records.template.name-less.error.text': 'Имя должно быть не менее 3х символов',

    'toast.records.template-modal.main.printable-template.file-type.error.text':
      'Поддерживаемые форматы файлов: DOCX, XLSX',
    'toast.records.template-modal.main.printable-template.file-size.error.text':
      'Размер файла не должен превышать 10Mb',

    'toast.records.reports-history.get-list.error.text': 'Ошибка получения отчетов',

    'toast.records.get-report.status.no-report-has-template-id.error.text':
      'Отчет не исправен и не содержит информацию о шаблоне',
    'toast.records.get-report.status.no-template-has-printable-template-id.error.text':
      'Шаблон для данного отчета не содержит файл печатной формы',

    'toast.records.generated-printed-form.status.202.text': 'Отчет находится в стадии составления. Попробуйте позже',
    'toast.records.generated-printed-form.status.404.text': 'Не найден',
    'toast.records.generated-printed-form.status.406.text': 'В отчете есть ошибка',
    'toast.records.generated-printed-form.status.500.text': 'Неизвестная ошибка',
    'toast.records.generated-printed-form.na-file-name.text': 'Не задано имя файла',
  },
  zh: {
    'toast.records.template.name-less.error.text': '名字必须至少包含 3 个字符',
    'toast.records.template-modal.main.printable-template.file-type.error.text': '支持的文件格式：DOCX、XLSX',
    'toast.records.template-modal.main.printable-template.file-size.error.text': '文件大小不应超过10兆字节',
    'toast.records.reports-history.get-list.error.text': '收到报告错误',
    'toast.records.get-report.status.no-report-has-template-id.error.text': '报告不正确并不包含有关模板的信息',
    'toast.records.get-report.status.no-template-has-printable-template-id.error.text': '该报告模板不包括印刷版文件',
    'toast.records.generated-printed-form.status.202.text': '该报告正在创造过程中。 请重试。',
    'toast.records.generated-printed-form.status.404.text': '未找到 ',
    'toast.records.generated-printed-form.status.406.text': '报告包括错误',
    'toast.records.generated-printed-form.status.500.text': '未知错误',
    'toast.records.generated-printed-form.na-file-name.text': '未设置文件名',
  },
  ar: {
    'toast.records.template.name-less.error.text': 'يجب أن يحتوي الاسم على 3 رموز على الأقل',
    'toast.records.template-modal.main.printable-template.file-type.error.text': 'أنواع الملفات المدعومة: DOCX و XLSX',
    'toast.records.template-modal.main.printable-template.file-size.error.text':
      'يجب ألا يتجاوز حجم الملف 10 ميغا بايت',
    'toast.records.reports-history.get-list.error.text': 'خطأ في تلقي التقارير',
    'toast.records.get-report.status.no-report-has-template-id.error.text':
      'التقرير غير صحيح ولا يحتوي على معلومات حول القالب',
    'toast.records.get-report.status.no-template-has-printable-template-id.error.text':
      'لا يحتوي قالب هذا التقرير على ملف قابل للطباعة',
    'toast.records.generated-printed-form.status.202.text': 'التقرير في طور الإنشاء. حاول لاحقا.',
    'toast.records.generated-printed-form.status.404.text': 'لم يتم العثور على',
    'toast.records.generated-printed-form.status.406.text': 'التقرير به خطأ',
    'toast.records.generated-printed-form.status.500.text': 'خطأ غير معروف',
    'toast.records.generated-printed-form.na-file-name.text': 'لم يتم تعيين اسم الملف',
  },
  de: {
    'toast.records.template.name-less.error.text': 'Der Name muss mindestens 3 Zeichen enthalten',
    'toast.records.template-modal.main.printable-template.file-type.error.text':
      'Unterstützte Dateiformate: DOCX, XLSX',
    'toast.records.template-modal.main.printable-template.file-size.error.text':
      'Die Dateigröße sollte 10 MB nicht überschreiten',
    'toast.records.reports-history.get-list.error.text': 'Fehler beim Empfangen von Berichten',
    'toast.records.get-report.status.no-report-has-template-id.error.text':
      'Der Bericht ist nicht korrekt und enthält keine Informationen über die Vorlage',
    'toast.records.get-report.status.no-template-has-printable-template-id.error.text':
      'Die Vorlage für diesen Bericht enthält keine druckbare Datei',
    'toast.records.generated-printed-form.status.202.text':
      'Der Bericht befindet sich in der Erstellung. Versuchen Sie es später.',
    'toast.records.generated-printed-form.status.404.text': 'Nicht gefunden',
    'toast.records.generated-printed-form.status.406.text': 'Der Bericht enthält einen Fehler',
    'toast.records.generated-printed-form.status.500.text': 'Unbekannter Fehler',
    'toast.records.generated-printed-form.na-file-name.text': 'Dateiname nicht festgelegt',
  },
};
