import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { PERSONAL_STATISTIC_SORTING_MODE } from 'utils/consts';

import { getEmployeeStatistic, getUserStatistic } from './api';

import {
  EmployeeStatisticJSONApi,
  Statistic,
  StatisticFilter,
  StatisticState,
  UserStatisticJSONApi,
} from './interface';

const defaultFilter: StatisticFilter = {
  offset: 0,
  limit: 10,
  sortBy: 'createdAt',
  sortType: PERSONAL_STATISTIC_SORTING_MODE.DESC,
};

const initialState: StatisticState = {
  isStatisticLoading: false,
  statistics: [],
  statisticTotal: 0,
  statisticFilter: defaultFilter,

  error: null,
};

export const fetchEmployeeStatistic = createAsyncThunk(
  'personalStatisticsSlice/fetchEmployeeStatistic',
  async (params: StatisticFilter & { id: string }) => await getEmployeeStatistic(params)
);

export const fetchUserStatistic = createAsyncThunk(
  'personalStatisticsSlice/fetchUserStatistic',
  async (params: StatisticFilter & { id: string }) => await getUserStatistic(params)
);

const statisticSlice = createSlice({
  name: 'personalStatisticsSlice',
  initialState,
  reducers: {
    setStatisticFilter: (state, { payload }: { payload: StatisticFilter }) => {
      state.statisticFilter = payload;
    },
    clearStatisticFilter: state => {
      state.statisticFilter = defaultFilter;
    },
    setStatistics: (state, { payload }: { payload: Statistic[] }) => {
      state.statistics = payload;
    },
    setStatisticsTotal: (state, { payload }: { payload: number }) => {
      state.statisticTotal = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEmployeeStatistic.pending, state => {
        state.error = null;
        state.isStatisticLoading = true;
      })
      .addCase(fetchEmployeeStatistic.fulfilled, (state, { payload }: { payload: EmployeeStatisticJSONApi }) => {
        try {
          state.statistics = payload.data.map(data => ({
            ...data,
            attributes: {
              ...data.attributes,
              eventInfo:
                typeof data.attributes.eventInfo === 'string'
                  ? JSON.parse(data.attributes.eventInfo)
                  : data.attributes.eventInfo,
            },
          }));
          state.statisticTotal = payload.meta.total;
        } catch (error) {
          toast.error((error as ErrorEvent).message);
        }
        state.isStatisticLoading = false;
      })
      .addCase(fetchEmployeeStatistic.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isStatisticLoading = false;
      });
    builder
      .addCase(fetchUserStatistic.pending, state => {
        state.error = null;
        state.isStatisticLoading = true;
      })
      .addCase(fetchUserStatistic.fulfilled, (state, { payload }: { payload: UserStatisticJSONApi }) => {
        try {
          state.statistics = payload.data.map(data => ({
            ...data,
            id: String(data.id),
            attributes: {
              ...data.attributes,
              eventInfo:
                typeof data.attributes.eventInfo === 'string'
                  ? JSON.parse(data.attributes.eventInfo)
                  : data.attributes.eventInfo,
            },
          }));
          state.statisticTotal = payload.meta.total;
        } catch (error) {
          toast.error((error as ErrorEvent).message);
        }
        state.isStatisticLoading = false;
      })
      .addCase(fetchUserStatistic.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isStatisticLoading = false;
      });
  },
});

export const { setStatisticFilter, clearStatisticFilter, setStatistics, setStatisticsTotal } = statisticSlice.actions;

export default statisticSlice.reducer;
