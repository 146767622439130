import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { RootState } from 'reducers';

import { MAP_LAYERS_Z_INDEX } from 'utils/consts';
import { createVectorLayer, geoJSON } from '../utils';

// Слои этажа добавленный в map
const floorSource = new VectorSource();

export function useFloors(map: Map) {
  const floors = useSelector((state: RootState) => state.floors.floors);
  const floor = useSelector((state: RootState) => state.map.floor);

  useEffect(() => {
    const floorMapLayer: VectorLayer<VectorSource> = createVectorLayer(floorSource, MAP_LAYERS_Z_INDEX.FLOORS);
    map.addLayer(floorMapLayer);

    return () => {
      map.removeLayer(floorMapLayer);
      floorMapLayer.dispose();
    };
  }, [map]);

  useEffect(() => {
    const showingFeatures = floors[floor - 1];

    if (floors.length && showingFeatures) {
      floorSource.addFeatures(geoJSON.readFeatures(showingFeatures));
    }

    return () => {
      floorSource.clear();
    };
  }, [floors, floor]);
}
