import { reqHandlers } from 'utils/api';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';
import { NotificationLogListFilter, NotificationTrackableUnit, NotificationUser } from './interface';

export function getNotifications() {
  return reqHandlers.get(`/api/notifications`);
}

export function createNotification(data: unknown) {
  return reqHandlers.post(`/api/notifications`, data);
}

export function getLogList(queryParams: NotificationLogListFilter & { locale: string }) {
  const query = getQueryStrFromObjKeys(queryParams);

  return reqHandlers.get(`/api/notifications/logs/list?${query}`);
}

export function getLog(logId: number) {
  return reqHandlers.get(`/api/notifications/logs/${logId}`);
}

export function getCountUnredLogs() {
  return reqHandlers.get(`/api/notifications/countUnread`);
}

export function getNotification(id: string) {
  return reqHandlers.get(`/api/notifications/${id}`);
}

export function putNotification({ id, attributes }: { id: string; attributes: unknown }) {
  return reqHandlers.put(`/api/notifications/${id}`, attributes);
}

export function deleteNotification(id: string) {
  return reqHandlers.delete(`/api/notifications/${id}`);
}

export function createNotificationsTrackableUnit({ notificationId, trackableUnitId }: NotificationTrackableUnit) {
  return reqHandlers.post(`/api/notifications/${notificationId}/activeTrackableUnits`, { trackableUnitId });
}

export function deleteNotificationsTrackableUnit({ notificationId, trackableUnitId }: NotificationTrackableUnit) {
  return reqHandlers.delete(`/api/notifications/${notificationId}/activeTrackableUnits/${trackableUnitId}`);
}

export function createNotificationsUser({ notificationId, userId }: NotificationUser) {
  return reqHandlers.post(`/api/notifications/${notificationId}/activeUsers`, { userId });
}

export function deleteNotificationsUser({ notificationId, userId }: NotificationUser) {
  return reqHandlers.delete(`/api/notifications/${notificationId}/activeUsers/${userId}`);
}

export function getAllLogs(notificationId: string) {
  return reqHandlers.get(`/api/notifications/${notificationId}/logs`);
}

export function putIsDoneLog(logId: number) {
  return reqHandlers.put(`/api/notifications/logs/makeRead/${logId}`, {});
}
