import React, { Fragment, useState, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal/index';
import {
  addOrUpdateNotification,
  removeChosenNotification,
  removeNotification,
  toggleCheckedNotification,
  addTestNotification,
} from 'reducers/notifications';
import { ConditionsType, TimeSettings } from 'reducers/notifications/interface';

import useMainInfo from 'components/notifications/hooks/useMainInfo';
import useObjectsInfo from 'components/notifications/hooks/useObjectsInfo';
import useAlertInfo from 'components/notifications/hooks/useAlertInfo';
import useGeozoneInfo from 'components/notifications/hooks/useGeozoneInfo';
import useInterpositionInfo from 'components/notifications/hooks/useInterpositionInfo';
import useValueSensorInfo from 'components/notifications/hooks/useValueSensorInfo';
import useTimeInfo from 'components/notifications/hooks/useTimeInfo';
import { Spinner } from 'components/common/spinner/spinner';
import { HeaderModal } from './components/headerModal/headerModal';
import { ControlsModal } from './components/controlsModal/controlsModal';
import { TabsModal } from './components/tabsModal/tabsModal';
import { MainTab } from './components/tabsModal/components/mainTab';
import { ObjectsTab } from './components/tabsModal/components/objectsTab';
import { ActionsTab } from './components/tabsModal/components/actionsTab';
import { StatsTab } from './components/tabsModal/components/statsTab';
import { TimeTab } from './components/tabsModal/components/timeTab';
import { Alert } from 'components/common/alert/alert';

import AccessEntity from 'utils/accessEntity';
import { ActiveTab, DatesStatus, NOTIFICATION_TYPES_ENUM } from '../../utils/consts';
import { TrackableUnitType } from 'reducers/trackableUnits/interface';
import { saveToStorage } from 'utils/workWithStorages';
import { TEST_NOTIFICATION_ID, TEST_NOTIFICATION_KEY_NAME } from 'utils/consts';

import styles from './notificationModal.module.scss';

enum alertContinueStateEnum {
  none = '',
  delete = 'delete',
  cancel = 'cancel',
}

const NotificationModalForm = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.main);
  const [showLocalAlert, setShowLocalAlert] = useState(alertContinueStateEnum.none);
  const viewportInfo = useSelector((state: RootState) => state.map.viewportInfo);

  const notificationsPermissions = useSelector((state: RootState) => state.user.permissions.notifications);
  const notificationsAccess = useMemo(() => new AccessEntity(notificationsPermissions), [notificationsPermissions]);

  const chosenNotification = useSelector((state: RootState) => state.notifications.chosenNotification);
  const isChosenNotificationLoading = useSelector(
    (state: RootState) => state.notifications.isChosenNotificationLoading
  );
  const selectedNotifications = useSelector((state: RootState) => state.notifications.selectedNotifications);

  const userShortsIsLoading = useSelector((state: RootState) => state.account.isShortsLoading);
  const trackableUnitShortsIsLoading = useSelector((state: RootState) => state.trackableUnit.isShortsLoading);
  const isGeozoneShortsLoading = useSelector((state: RootState) => state.geozone.isShortsLoading);

  const {
    states: mainInfoStates,
    refs: mainInfoRefs,
    handlers: mainInfoHandlers,
    validateRequiredFields: mainInfoValidateRequiredFields,
    invalidFields: mainInfoInvalidFields,
    setInvalidFields: mainInfoSetInvalidFields,
    hasChanges: mainInfoHasChanges,
    changedItems: mainInfoChangedItems,
    isFoulFixing: mainInfoIsFoulFixing,
    setIsFoulFixing: mainInfoSetIsFoulFixing,
  } = useMainInfo();

  const {
    states: objectsInfoStates,
    handlers: objectsInfoHandlers,
    chosenObjects: objectsInfoObjects,
    activeObjects: objectsInfoActiveObjects,
    hasChanges: objectsInfoHasChanges,
  } = useObjectsInfo();

  const {
    states: alertInfoStates,
    handlers: alertInfoHandlers,
    refs: alertInfoRefs,
    hasChanges: alertInfoHasChanges,
    invalidFields: alertInfoInvalidFields,
    validateRequiredFields: alertInfoValidateRequiredFields,
    setInvalidFields: alertInfoSetInvalidFields,
  } = useAlertInfo({ isFoulFixing: mainInfoIsFoulFixing, setIsFoulFixing: mainInfoSetIsFoulFixing });

  const {
    states: interpositionInfoStates,
    handlers: interpositionInfoHandlers,
    refs: interpositionInfoRefs,
    hasChanges: interpositionInfoHasChanges,
    invalidFields: interpositionInfoInvalidFields,
    validateRequiredFields: interpositionInfoValidateRequiredFields,
    setInvalidFields: interpositionInfoSetInvalidFields,
  } = useInterpositionInfo({ isFoulFixing: mainInfoIsFoulFixing, setIsFoulFixing: mainInfoSetIsFoulFixing });

  const {
    states: geozoneInfoStates,
    handlers: geozoneInfoHandlers,
    refs: geozoneInfoRefs,
    hasChanges: geozoneInfoHasChanges,
    invalidFields: geozoneInfoInvalidFields,
    validateRequiredFields: geozoneInfoValidateRequiredFields,
    setInvalidFields: geozoneInfoSetInvalidFields,
  } = useGeozoneInfo({ isFoulFixing: mainInfoIsFoulFixing, setIsFoulFixing: mainInfoSetIsFoulFixing });

  const {
    states: sensorInfoStates,
    handlers: sensorInfoHandlers,
    hasChanges: sensorInfoHasChanges,
    invalidFields: sensorInfoInvalidFields,
    validateRequiredFields: sensorInfoValidateRequiredFields,
    setInvalidFields: sensorInfoSetInvalidFields,
  } = useValueSensorInfo({ isFoulFixing: mainInfoIsFoulFixing, setIsFoulFixing: mainInfoSetIsFoulFixing });

  const {
    states: timeInfoStates,
    handlers: timeInfoHandlers,
    refs: timeInfoRefs,
    hasChanges: timeInfoHasChanges,
    invalidFields: timeInfoInvalidFields,
    validateRequiredFields: timeInfoValidateRequiredFields,
    setInvalidFields: timeInfoSetInvalidFields,
  } = useTimeInfo();

  const isVideoAnalytics = mainInfoStates.typeNotification === NOTIFICATION_TYPES_ENUM.videoAnalytics;

  function getBodyModal(tab: ActiveTab) {
    const { main, objects, actions, time, stats } = ActiveTab;

    switch (tab) {
      case main:
        return (
          <MainTab
            states={mainInfoStates}
            handlers={mainInfoHandlers}
            refs={mainInfoRefs}
            invalidFields={mainInfoInvalidFields}
            isVideoAnalytics={isVideoAnalytics}
          />
        );

      case objects:
        return <ObjectsTab states={objectsInfoStates} handlers={objectsInfoHandlers} />;

      case actions:
        return (
          <ActionsTab
            notificationType={mainInfoStates.typeNotification}
            statesAndHandlers={{
              alert: {
                states: alertInfoStates,
                handlers: alertInfoHandlers,
                refs: alertInfoRefs,
                invalidFields: alertInfoInvalidFields,
                additionalFields: {
                  isFoulFixing: mainInfoIsFoulFixing,
                },
              },
              geoZones: {
                states: geozoneInfoStates,
                handlers: geozoneInfoHandlers,
                refs: geozoneInfoRefs,
                invalidFields: geozoneInfoInvalidFields,
                additionalFields: {
                  isFoulFixing: mainInfoIsFoulFixing,
                },
              },
              interceptionOfObjects: {
                states: interpositionInfoStates,
                handlers: interpositionInfoHandlers,
                refs: interpositionInfoRefs,
                invalidFields: interpositionInfoInvalidFields,
                additionalFields: {
                  isFoulFixing: mainInfoIsFoulFixing,
                },
              },
              sensorValue: {
                states: sensorInfoStates,
                handlers: sensorInfoHandlers,
                refs: null,
                invalidFields: sensorInfoInvalidFields,
                additionalFields: {
                  isFoulFixing: mainInfoIsFoulFixing,
                },
              },
            }}
          />
        );

      case time:
        return (
          <TimeTab
            states={timeInfoStates}
            handlers={timeInfoHandlers}
            refs={timeInfoRefs}
            invalidFields={timeInfoInvalidFields}
          />
        );

      case stats:
        return <StatsTab />;

      default:
        return null;
    }
  }

  const handleCancel = () => {
    const { alert, geoZones, interceptionOfObjects, sensorValue } = NOTIFICATION_TYPES_ENUM;
    let hasConditionsChanges = false;

    switch (mainInfoStates.typeNotification) {
      case alert:
        hasConditionsChanges = alertInfoHasChanges();
        break;

      case geoZones:
        hasConditionsChanges = geozoneInfoHasChanges();
        break;

      case interceptionOfObjects:
        hasConditionsChanges = interpositionInfoHasChanges();
        break;

      case sensorValue:
        hasConditionsChanges = sensorInfoHasChanges();
        break;

      default:
        break;
    }

    if (mainInfoHasChanges() || objectsInfoHasChanges() || hasConditionsChanges || timeInfoHasChanges()) {
      return setShowLocalAlert(alertContinueStateEnum.cancel);
    }
    if (chosenNotification) {
      dispatch(removeChosenNotification());
    }
    return dispatch(closeModal());
  };

  const handleDelete = () => {
    if (chosenNotification) {
      setShowLocalAlert(alertContinueStateEnum.delete);
    }
  };

  const handleSave = async () => {
    const { alert, geoZones, interceptionOfObjects, sensorValue, videoAnalytics } = NOTIFICATION_TYPES_ENUM;
    const validationResultMainInfo = mainInfoValidateRequiredFields();
    const validationResultTimeInfo = timeInfoValidateRequiredFields();
    let conditions: ConditionsType = null;

    if (validationResultMainInfo.length) {
      mainInfoSetInvalidFields(validationResultMainInfo);
      return setActiveTab(ActiveTab.main);
    }

    switch (mainInfoStates.typeNotification) {
      case alert:
        const validationResultAlertInfo = alertInfoValidateRequiredFields();
        if (validationResultAlertInfo.length) {
          alertInfoSetInvalidFields(validationResultAlertInfo);
          return setActiveTab(ActiveTab.actions);
        }
        conditions = {
          isSosFixing: alertInfoStates.isSosFixing,
          isFallFixing: alertInfoStates.isFallFixing,
          isAreaLimited: alertInfoStates.isAreaLimited,
          isInactionFixing: alertInfoStates.isInactionFixing,
          isDisconnectionFixing: alertInfoStates.isDisconnectionFixing,
          coordsChangingLimit: alertInfoStates.coordsChangingLimit,
          dataGettingLimit: alertInfoStates.dataGettingLimit,
          positionByGeozone: alertInfoStates.positionByGeozone,
          geozones: alertInfoStates.geozoneEntities,
          isRestoreConnectionFixing: alertInfoStates.isRestoreConnectionFixing,
          isGnssLost: alertInfoStates.isGnssLost,
          gnssWaitingTime: alertInfoStates.gnssWaitingTime,
          isGnssRestoreFixing: alertInfoStates.isGnssRestoreFixing,
        };
        break;

      case geoZones:
        const validationResultGeozoneInfo = geozoneInfoValidateRequiredFields();
        if (validationResultGeozoneInfo.length) {
          geozoneInfoSetInvalidFields(validationResultGeozoneInfo);
          return setActiveTab(ActiveTab.actions);
        }
        conditions = {
          fixingBy: geozoneInfoStates.fixingBy,
          fixingTimeLimit: geozoneInfoStates.fixingTimeLimit,
          geozones: geozoneInfoStates.geozoneEntities,
          geozoneStandingLimit: geozoneInfoStates.geozoneStandingLimit,
        };
        break;

      case interceptionOfObjects:
        const validationResultInterpositionInfo = interpositionInfoValidateRequiredFields();
        if (validationResultInterpositionInfo.length) {
          interpositionInfoSetInvalidFields(validationResultInterpositionInfo);
          return setActiveTab(ActiveTab.actions);
        }
        conditions = {
          objectsDistance: interpositionInfoStates.objectsDistance,
          radius: interpositionInfoStates.radius,
          fixingTimeLimit: interpositionInfoStates.fixingTimeLimit,
          isAreaLimited: interpositionInfoStates.isAreaLimited,
          positionByGeozone: interpositionInfoStates.positionByGeozone,
          geozones: interpositionInfoStates.geozoneEntities,
        };
        break;

      case sensorValue:
        const validationResultSensorInfo = sensorInfoValidateRequiredFields();
        if (validationResultSensorInfo.length) {
          sensorInfoSetInvalidFields(validationResultSensorInfo);
          return setActiveTab(ActiveTab.actions);
        }
        conditions = {
          notificationSensorId: sensorInfoStates.notificationSensorId,
          nameOfSensor: sensorInfoStates.nameOfSensor,
          notificationActivationId: sensorInfoStates.notificationActivationId,
          valueFrom: sensorInfoStates.valueFrom,
          valueTo: sensorInfoStates.valueTo,
        };
        break;

      case videoAnalytics:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        conditions = {};
        break;

      default:
        break;
    }

    if (validationResultTimeInfo.length) {
      timeInfoSetInvalidFields(validationResultTimeInfo);
      return setActiveTab(ActiveTab.time);
    }

    const timeSettings: TimeSettings = {
      isActionPeriodOn: timeInfoStates.isActionPeriodOn,
      periodFrom: timeInfoStates.periodFrom,
      periodTo: timeInfoStates.periodTo,
      isIntervalsOn: timeInfoStates.isIntervalsOn,
      intervals: timeInfoStates.intervals,
      isPeriodLimitsOn: timeInfoStates.isPeriodLimitsOn,
      isDaysOfWeekLimitOn: timeInfoStates.isDaysOfWeekLimitOn,
      activationCountMax: timeInfoStates.activationCountMax,
      isDaysOfMonthLimitOn: timeInfoStates.isDaysOfMonthLimitOn,
      months: timeInfoStates.isPeriodLimitsOn
        ? timeInfoStates.datesSelected.months.reduce((prev, curr, i) => {
            if (curr.status === DatesStatus.selected) {
              prev.push(i);
            }
            return prev;
          }, [] as number[])
        : [],
      daysOfWeek:
        timeInfoStates.isPeriodLimitsOn && timeInfoStates.isDaysOfWeekLimitOn
          ? timeInfoStates.datesSelected.daysOfWeek.reduce((prev, curr, i) => {
              if (curr.status === DatesStatus.selected) {
                prev.push(i);
              }
              return prev;
            }, [] as number[])
          : [],
      evenDays:
        timeInfoStates.isPeriodLimitsOn && timeInfoStates.isDaysOfMonthLimitOn
          ? timeInfoStates.datesSelected.evenDays.reduce((prev, curr, i) => {
              if (curr.status === DatesStatus.selected) {
                prev.push(i);
              }
              return prev;
            }, [] as number[])
          : [],
      oddDays:
        timeInfoStates.isPeriodLimitsOn && timeInfoStates.isDaysOfMonthLimitOn
          ? timeInfoStates.datesSelected.oddDays.reduce((prev, curr, i) => {
              if (curr.status === DatesStatus.selected) {
                prev.push(i);
              }
              return prev;
            }, [] as number[])
          : [],
    };

    if (chosenNotification) {
      if (notificationsAccess.isAllowUpdate()) {
        await dispatch(
          addOrUpdateNotification({
            id: chosenNotification.id,
            chosenObjects: objectsInfoObjects,
            activeObjects: objectsInfoActiveObjects,
            chosenUsers: mainInfoStates.chosenUsers,
            activeUsers: mainInfoStates.activeUsers,
            notificationInfo: { ...mainInfoChangedItems, conditions, timeSettings },
          })
        );
      }
    } else {
      if (notificationsAccess.isAllowCreate()) {
        await dispatch(
          addOrUpdateNotification({
            id: null,
            chosenUsers: mainInfoStates.chosenUsers,
            activeObjects: [],
            chosenObjects: objectsInfoObjects,
            activeUsers: [],
            notificationInfo: { ...mainInfoChangedItems, conditions, timeSettings },
          })
        );
      }
    }
    return dispatch(closeModal());
  };

  const handleCreateTestMessage = () => {
    const isEmployee = Math.round(Math.random());

    const data = {
      attributes: {
        aggregatedName: `${t('notifications.test.card.header.label')} ${chosenNotification?.attributes.name}`,
        coords: viewportInfo.center.toString(),
        createdAt: new Date().toISOString(),
        deletedAt: null,
        employeePosition: isEmployee ? '{"ru":"Слесарь-ремонтник тестовый"}' : null,
        employeeSimNumber: isEmployee ? '+79999999999' : null,
        eventTime: new Date().toISOString(),
        geozoneName: '',
        isDone: false,
        isFoul: chosenNotification?.attributes.isFoulFixing || false,
        notificationId: parseInt(chosenNotification?.id || '0'),
        notificationType:
          (chosenNotification?.attributes.notificationType as NOTIFICATION_TYPES_ENUM) || NOTIFICATION_TYPES_ENUM.alert,
        text: `${chosenNotification?.attributes.text}`,
        trackableUnitType: (isEmployee ? 'employee' : 'transport') as TrackableUnitType,
        trackerNumber: `${t('notifications.test.card.photo.label')}`,
        transportRegNumber: isEmployee ? null : 'o000oo',
        transportType: isEmployee ? null : '{"ru":"Пассажирский тестовый"}',
        updatedAt: new Date().toISOString(),
      },
      id: TEST_NOTIFICATION_ID,
      type: 'notificationLog',
    };

    dispatch(addTestNotification(data));
    saveToStorage(TEST_NOTIFICATION_KEY_NAME, data);
    return dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    return setShowLocalAlert(alertContinueStateEnum.none);
  };

  const handleAlertContinue = () => {
    switch (showLocalAlert) {
      case alertContinueStateEnum.cancel:
        setShowLocalAlert(alertContinueStateEnum.none);
        dispatch(removeChosenNotification());
        break;

      case alertContinueStateEnum.delete:
        if (chosenNotification && notificationsAccess.isAllowDelete()) {
          if (selectedNotifications.includes(chosenNotification.id)) {
            dispatch(toggleCheckedNotification(chosenNotification.id));
          }
          dispatch(removeNotification(chosenNotification.id));
        }
        break;

      default:
        break;
    }
    return dispatch(closeModal());
  };

  const isLoading =
    isChosenNotificationLoading || userShortsIsLoading || trackableUnitShortsIsLoading || isGeozoneShortsLoading;

  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <HeaderModal title={t('notifications.card.header.label')} onCancel={handleCancel} />
      </div>
      <div className={styles.modalBody}>
        <div className={styles.tabContent}>
          {isLoading ? (
            <Spinner />
          ) : (
            <TabsModal
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isShowStatistic={!!chosenNotification}
              isVideoAnalytics={isVideoAnalytics}
            >
              {getBodyModal(activeTab)}
            </TabsModal>
          )}
        </div>
      </div>
      <div className={styles.modalControls}>
        <ControlsModal
          withTestButton={!!chosenNotification}
          onSaveTest={handleCreateTestMessage}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          withoutDelete={!chosenNotification || !notificationsAccess.isAllowDelete()}
          withoutSave={chosenNotification ? !notificationsAccess.isAllowUpdate() : !notificationsAccess.isAllowCreate()}
          saveButtonText={
            chosenNotification
              ? t('notifications.card.footer.btn.update.label')
              : t('notifications.card.footer.btn.save.label')
          }
        />
      </div>

      {showLocalAlert && (
        <Alert
          title={
            showLocalAlert === alertContinueStateEnum.delete
              ? t('notifications.card.alert.title.delete.label')
              : t('notifications.card.alert.title.text')
          }
          infoText={
            showLocalAlert === alertContinueStateEnum.delete
              ? t('notifications.card.alert.info.delete.text')
              : t('notifications.card.alert.info.text')
          }
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default React.memo(NotificationModalForm);
