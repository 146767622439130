import React, { ReactNode, useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';

import Search from 'components/common/search/search';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { Alert } from 'components/common/alert/alert';
import Button from 'components/common/button/button';
import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal/index';
import { fetchUnitsForObjectsModal, setLocalUnits, updateActiveUnits } from 'reducers/trackableUnits';

import { isEqualArraysOfObjects } from 'utils/isEqualArraysOfObjects';

import styles from './objectList.module.scss';

const cx = classNames.bind(styles);

type SortableItemProps = {
  value: string;
  handleClick: (ev: MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
};

type SortableListProps = {
  children: ReactNode;
  style: string;
};

const SortableItem = SortableElement(({ value, handleClick, selected }: SortableItemProps) => (
  <li
    className={cx(styles.objectsListItem, {
      [styles.objectsListItemSelected]: selected,
    })}
    onClick={handleClick}
  >
    {value}
  </li>
));

const SortableList = SortableContainer(({ style, children }: SortableListProps) => {
  return <div className={style}>{children}</div>;
});

export const ObjectListModal = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [showEmployees, setShowEmployees] = useState(true);
  const [showTransports, setShowTransports] = useState(true);
  const [selectedDisabled, setSelectedDisabled] = useState<string[]>([]);
  const [selectedActivated, setSelectedActivated] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const {
    dbUnits: storeUnits,
    localUnits,
    isAllObjectsLoading,
  } = useSelector((state: RootState) => state.trackableUnit);
  const [showingUnits, setShowingUnits] = useState<typeof localUnits>([]);

  useEffect(() => {
    dispatch(fetchUnitsForObjectsModal());
  }, [dispatch]);

  useEffect(() => {
    if (searchValue) {
      setShowingUnits(
        localUnits.filter(
          unit =>
            ((showTransports && unit.attributes.transportId) || (showEmployees && unit.attributes.employeeId)) &&
            unit.attributes.aggregatedName.includes(searchValue)
        )
      );
    } else {
      setShowingUnits(
        localUnits.filter(
          unit => (showTransports && unit.attributes.transportId) || (showEmployees && unit.attributes.employeeId)
        )
      );
    }
  }, [searchValue, localUnits, showEmployees, showTransports]);

  const handleClose = () => {
    if (isEqualArraysOfObjects(storeUnits, localUnits)) {
      return dispatch(closeModal());
    }
    return setShowAlert(true);
  };

  const onSortEnd = ({ collection }: { collection: number | string }) => {
    const newObjects = localUnits.map(unit => {
      if (unit.id === collection) {
        return {
          ...unit,
          attributes: {
            ...unit.attributes,
            isActive: !unit.attributes.isActive,
          },
        };
      }
      return unit;
    });
    dispatch(setLocalUnits(newObjects));
  };

  const handleDisabledItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedDisabled.includes(id)) {
      return setSelectedDisabled(selectedDisabled.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedDisabled([...selectedDisabled, id]);
    } else if (ev.shiftKey && selectedDisabled.length) {
      const startInd = showingUnits.findIndex(o => o.id === selectedDisabled[0]);
      const newSelected = showingUnits.slice(startInd, index + 1).map(o => o.id);
      setSelectedDisabled(newSelected);
    } else {
      setSelectedDisabled([id]);
    }
    return setSelectedActivated([]);
  };
  const handleActivatedItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedActivated.includes(id)) {
      return setSelectedActivated(selectedActivated.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedActivated([...selectedActivated, id]);
    } else if (ev.shiftKey && selectedActivated.length) {
      const startInd = showingUnits.findIndex(o => o.id === selectedActivated[0]);
      const newSelected = showingUnits.slice(startInd, index + 1).map(o => o.id);
      setSelectedActivated(newSelected);
    } else {
      setSelectedActivated([id]);
    }
    return setSelectedDisabled([]);
  };

  const handleSelectDisabledAll = () => {
    const showObjectsIds = showingUnits.filter(obj => !obj.attributes.isActive).map(obj => obj.id);
    setSelectedDisabled(showObjectsIds);
    setSelectedActivated([]);
  };

  const handleSelectActivatedAll = () => {
    const showObjectsIds = showingUnits.filter(obj => obj.attributes.isActive).map(obj => obj.id);
    setSelectedActivated(showObjectsIds);
    setSelectedDisabled([]);
  };

  const handleMoveRight = () => {
    const newObjects = localUnits.map(obj => {
      if (selectedDisabled.includes(obj.id)) {
        return {
          ...obj,
          attributes: {
            ...obj.attributes,
            isActive: !obj.attributes.isActive,
          },
        };
      }
      return obj;
    });
    dispatch(setLocalUnits(newObjects));
    setSelectedDisabled([]);
  };

  const handleMoveLeft = () => {
    const newObjects = localUnits.map(obj => {
      if (selectedActivated.includes(obj.id)) {
        return {
          ...obj,
          attributes: {
            ...obj.attributes,
            isActive: !obj.attributes.isActive,
          },
        };
      }
      return obj;
    });
    dispatch(setLocalUnits(newObjects));
    setSelectedActivated([]);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleContinueAlert = () => {
    setShowAlert(false);
    dispatch(closeModal());
  };

  const handleSave = async () => {
    if (isEqualArraysOfObjects(storeUnits, localUnits)) {
      return dispatch(closeModal());
    }

    const newActiveObjects: number[] = [];
    const removedActiveObjects: number[] = [];
    localUnits.forEach(obj => {
      if (obj.attributes.isActive !== storeUnits.find(u => u.id === obj.id)?.attributes.isActive) {
        if (obj.attributes.isActive) {
          newActiveObjects.push(Number(obj.id));
        } else {
          removedActiveObjects.push(Number(obj.id));
        }
      }
    });
    await dispatch(updateActiveUnits({ unitsForRemoving: removedActiveObjects, unitsForAdding: newActiveObjects }));
    return dispatch(closeModal());
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>{t('monitoring.manage-list-card.header.label')}</div>
        <CloseIcon className={styles.headerCloseIcon} onClick={handleClose} />
      </div>

      {isAllObjectsLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.filterBlock}>
            <Search handleChange={val => setSearchValue(val)} style={styles.filterBlockSearch} />
            <div className={styles.checkboxWrap}>
              <Checkbox checked={showEmployees} handleCheck={() => setShowEmployees(!showEmployees)} />
              <span className={styles.checkboxWrapText}>{t('monitoring.manage-list-card.filter.employee.text')}</span>
            </div>
            <div className={styles.checkboxWrap}>
              <Checkbox checked={showTransports} handleCheck={() => setShowTransports(!showTransports)} />
              <span className={styles.checkboxWrapText}>{t('monitoring.manage-list-card.filter.vehicle.text')}</span>
            </div>
          </div>

          <div className={styles.modalBody}>
            <SortableList distance={20} onSortEnd={onSortEnd} lockAxis="x" style={styles.objectsList}>
              <div className={styles.objectsListDisabled}>
                <div className={styles.objectsListHeader}>{t('monitoring.manage-list-card.obj.available.text')}</div>
                <ul className={styles.objectsListItemsWrap}>
                  {showingUnits.map((obj, index) => {
                    if (!obj.attributes.isActive) {
                      return (
                        <SortableItem
                          key={`disabled-item_${index}`}
                          index={index}
                          value={obj.attributes.aggregatedName}
                          collection={obj.id}
                          handleClick={(ev: MouseEvent<HTMLLIElement>) => handleDisabledItemClick(ev, obj.id, index)}
                          selected={selectedDisabled.includes(obj.id)}
                        />
                      );
                    }
                    return null;
                  })}
                </ul>
                <Button
                  white
                  text={t('monitoring.manage-list-card.obj.available.select-btn.label')}
                  customStyle={styles.selectAllButton}
                  onClick={handleSelectDisabledAll}
                />
              </div>

              <div className={styles.moveButtons}>
                <div className={styles.moveButtonsBtn} onClick={handleMoveRight}>
                  <ArrowIcon className={styles.moveButtonsBtnToRight} />
                </div>
                <div className={styles.moveButtonsBtn} onClick={handleMoveLeft}>
                  <ArrowIcon className={styles.moveButtonsBtnToLeft} />
                </div>
              </div>

              <div className={styles.objectsListActive}>
                <div className={styles.objectsListHeader}>{t('monitoring.manage-list-card.obj.chosen.text')}</div>
                <ul className={styles.objectsListItemsWrap}>
                  {showingUnits.map((obj, index) => {
                    if (obj.attributes.isActive) {
                      return (
                        <SortableItem
                          key={`active-item_${index}`}
                          index={index}
                          value={obj.attributes.aggregatedName}
                          collection={obj.id}
                          handleClick={(ev: MouseEvent<HTMLLIElement>) => handleActivatedItemClick(ev, obj.id, index)}
                          selected={selectedActivated.includes(obj.id)}
                        />
                      );
                    }
                    return null;
                  })}
                </ul>
                <Button
                  white
                  text={t('monitoring.manage-list-card.obj.chosen.select-btn.label')}
                  customStyle={styles.selectAllButton}
                  onClick={handleSelectActivatedAll}
                />
              </div>
            </SortableList>
          </div>
        </div>
      )}

      <div className={styles.modalFooter}>
        <div className={styles.modalFooterButtonsWrap}>
          <Button white text={t('monitoring.manage-list-card.footer.btn.cancel.label')} onClick={handleClose} />
          <Button blue text={t('monitoring.manage-list-card.footer.btn.save.label')} onClick={handleSave} />
        </div>
      </div>

      {showAlert && (
        <Alert
          title={t('monitoring.manage-list-card.alert.title.text')}
          infoText={t('monitoring.manage-list-card.alert.info.text')}
          handleCancel={handleCloseAlert}
          handleContinue={handleContinueAlert}
        />
      )}
    </div>
  );
};
