import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NOTIFICATION_OBJECTS } from 'components/notifications/utils/consts';
import { Objects } from 'components/notifications/utils/types';

import { RootState } from 'reducers';
import { UnitShort } from 'reducers/trackableUnits/interface';
import { ActiveTrackableUnitType } from 'reducers/notifications/interface';

function getTypeTrackableUnit(trackableUnitsShort: UnitShort) {
  if (trackableUnitsShort.attributes.employeeId) {
    return NOTIFICATION_OBJECTS.employee;
  }
  return NOTIFICATION_OBJECTS.transport;
}

function getTypeActiveTrackableUnit(unit: ActiveTrackableUnitType, trackableUnitsShorts: UnitShort[]) {
  const foundTrackableUnit = trackableUnitsShorts.find(trackableUnit => trackableUnit.id === unit.id);
  if (foundTrackableUnit?.attributes.employeeId) {
    return NOTIFICATION_OBJECTS.employee;
  }
  return NOTIFICATION_OBJECTS.transport;
}

export default function useObjectsInfo() {
  const [chosenEmployee, setChosenEmployee] = useState(false);
  const [chosenTransport, setChosenTransport] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [objects, setObjects] = useState<Objects[]>([]);
  const [showObjects, setShowObjects] = useState<Objects[]>([]);
  const [chosenObjects, setChosenObjects] = useState<Objects[]>([]);

  const [selectedAvailable, setSelectedAvailable] = useState<string[]>([]);
  const [selectedChosen, setSelectedChosen] = useState<string[]>([]);

  const [activeObjects, setActiveObjects] = useState<Objects[]>([]);

  const trackableUnitsShort = useSelector((state: RootState) => state.trackableUnit.trackableUnitsShort);
  const chosenNotification = useSelector((state: RootState) => state.notifications.chosenNotification);

  useEffect(() => {
    if (chosenNotification) {
      const { relationships } = chosenNotification;
      setActiveObjects(
        relationships.activeTrackableUnits.map(unit => ({
          customId: `chosen-${unit.id}`,
          data: {
            id: unit.id,
            name: unit.aggregatedName,
          },
          isChosen: true,
          type: getTypeActiveTrackableUnit(unit, trackableUnitsShort),
        }))
      );
    }
  }, [chosenNotification, trackableUnitsShort]);

  useEffect(() => {
    const trackableUnitsShortToObjArr = trackableUnitsShort.map(unit => ({
      customId: `avaiable-${unit.id}`,
      data: {
        id: unit.id,
        name: unit.attributes.aggregatedName,
      },
      isChosen: false,
      type: getTypeTrackableUnit(unit),
    }));
    const activeTrackableUnitsToObjArr =
      chosenNotification?.relationships.activeTrackableUnits.map(unit => ({
        customId: `chosen-${unit.id}`,
        data: {
          id: unit.id,
          name: unit.aggregatedName,
        },
        isChosen: true,
        type: getTypeActiveTrackableUnit(unit, trackableUnitsShort),
      })) ?? [];
    const activeTrackableUnitsNames = activeTrackableUnitsToObjArr.map(obj => obj.data.name);

    const filteredTrackableUnitsShortToObjArr = trackableUnitsShortToObjArr.filter(
      obj => !activeTrackableUnitsNames.includes(obj.data.name)
    );

    setObjects([...filteredTrackableUnitsShortToObjArr, ...activeTrackableUnitsToObjArr]);
  }, [trackableUnitsShort, chosenNotification]);

  const filterObjects = useCallback(
    (o: Objects) => {
      if ((chosenEmployee && chosenTransport) || (!chosenEmployee && !chosenTransport)) {
        return o;
      }
      if (chosenEmployee && !chosenTransport) {
        return o.type === NOTIFICATION_OBJECTS.employee;
      }
      if (!chosenEmployee && chosenTransport) {
        return o.type === NOTIFICATION_OBJECTS.transport;
      }
      return o;
    },
    [chosenEmployee, chosenTransport]
  );

  useEffect(() => {
    if (searchValue) {
      setShowObjects(objects.filter(g => g.data.name.includes(searchValue)));
    } else {
      setShowObjects(objects.filter(filterObjects));
    }
    setChosenObjects(objects.filter(obj => obj.isChosen));
  }, [searchValue, objects, chosenEmployee, chosenNotification, filterObjects]);

  const handleSortEnd = ({ collection }: { collection: number | string }) => {
    const newObjects = objects.map(obj => {
      if (obj.customId === collection) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
        };
      }
      return obj;
    });
    setObjects(newObjects);
  };

  const handleCheckedEmployee = () => setChosenEmployee(!chosenEmployee);
  const handleCheckedTransport = () => setChosenTransport(!chosenTransport);

  const handleSearch = (val: string) => setSearchValue(val);

  const handleSelectAvailableAll = () => {
    const showObjectsIds = showObjects.filter(obj => !obj.isChosen).map(obj => obj.customId);
    setSelectedAvailable(showObjectsIds);
    setSelectedChosen([]);
  };

  const handleSelectChosedAll = () => {
    const showObjectsIds = showObjects.filter(obj => obj.isChosen).map(obj => obj.customId);
    setSelectedChosen(showObjectsIds);
    setSelectedAvailable([]);
  };

  const handleMoveRight = () => {
    const newObjects = objects.map(obj => {
      if (selectedAvailable.includes(obj.customId)) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
        };
      }
      return obj;
    });
    setObjects(newObjects);
    setSelectedAvailable([]);
  };

  const handleMoveLeft = () => {
    const newObjects = objects.map(obj => {
      if (selectedChosen.includes(obj.customId)) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
        };
      }
      return obj;
    });
    setObjects(newObjects);
    setSelectedChosen([]);
  };

  const handleAvailableItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedAvailable.includes(id)) {
      return setSelectedAvailable(selectedAvailable.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedAvailable([...selectedAvailable, id]);
    } else if (ev.shiftKey && selectedAvailable.length) {
      const startInd = showObjects.findIndex(o => o.customId === selectedAvailable[0]);
      const newSelected = showObjects.slice(startInd, index + 1).map(o => o.customId);
      setSelectedAvailable(newSelected);
    } else {
      setSelectedAvailable([id]);
    }
    return setSelectedChosen([]);
  };

  const handleChosenItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedChosen.includes(id)) {
      return setSelectedChosen(selectedChosen.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedChosen([...selectedChosen, id]);
    } else if (ev.shiftKey && selectedChosen.length) {
      const startInd = showObjects.findIndex(o => o.customId === selectedChosen[0]);
      const newSelected = showObjects.slice(startInd, index + 1).map(o => o.customId);
      setSelectedChosen(newSelected);
    } else {
      setSelectedChosen([id]);
    }
    return setSelectedAvailable([]);
  };

  const hasChanges = () => {
    const activeObjectsLength = activeObjects.length;
    const chosenObjectsLength = chosenObjects.length;
    const activeObjectsNames = activeObjects.map(obj => obj.data.name);
    const chosenObjectNames = chosenObjects.map(obj => obj.data.name);

    if (!chosenObjectsLength && !activeObjectsLength) {
      return false;
    }
    if (chosenObjectsLength !== activeObjectsLength) {
      return true;
    }
    return chosenObjectNames.some(name => !activeObjectsNames.includes(name));
  };

  return {
    states: {
      chosenEmployee,
      chosenTransport,
      searchValue,
      objects,
      showObjects,
      selectedAvailable,
      selectedChosen,
    },
    handlers: {
      handleSelectAvailableAll,
      handleSelectChosedAll,
      handleMoveRight,
      handleMoveLeft,
      handleAvailableItemClick,
      handleChosenItemClick,
      handleCheckedEmployee,
      handleCheckedTransport,
      handleSearch,
      handleSortEnd,
    },
    chosenObjects,
    activeObjects,
    hasChanges,
  };
}
