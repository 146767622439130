import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { getFromStorageNewVersion, saveToStorage } from 'utils/workWithStorages';

import Search from 'components/common/search/search';
import Button from 'components/common/button/button';
import { ListHeader } from './components/list/listHeader/listHeader';
import { NotificationItem } from './components/list/notificationItem/notificationItem';
import { NotificationGroup } from './components/list/notificationGroup/notificationGroup';
import NotificationHistoryLeftSideBar from './components/notificationHistory/leftSideBar/leftSideBar';

import { useSortingItems, useSortingGroupItems } from './hooks/useSorting';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import {
  turnNotificationInList,
  fetchChosenNotification,
  updateListConfig,
  setNotificationsGroups,
  removeTestNotification,
} from 'reducers/notifications';

import AccessEntity from 'utils/accessEntity';
import { NOTIFICATIONS, TEST_NOTIFICATION_KEY_NAME } from 'utils/consts';
import { NotificationsTree } from './utils/NotificationsTree';
import { defaultNotificationListConfig } from './utils/consts';

import styles from './notifications.module.scss';
import { NotificationLog } from 'reducers/notifications/interface';

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const {
    notifications,
    listConfig: listConfigStore,
    groups: groupsStore,
    selectedNotifications,
  } = useSelector((state: RootState) => state.notifications);
  const listConfigUserPreferences = useSelector(
    (state: RootState) => state.user.userPreferences.notificationsListConfig
  );
  const notificationsPermissions = useSelector((state: RootState) => state.user.permissions.notifications);
  const notificationsAccess = useMemo(() => new AccessEntity(notificationsPermissions), [notificationsPermissions]);
  const testNotification: NotificationLog = getFromStorageNewVersion(TEST_NOTIFICATION_KEY_NAME);

  const [isShowHistory, setIsShowHistory] = useState(false);

  useEffect(() => {
    if (listConfigUserPreferences?.length) {
      dispatch(updateListConfig(listConfigUserPreferences));
    } else {
      dispatch(updateListConfig(defaultNotificationListConfig));
    }
  }, [dispatch, listConfigUserPreferences]);

  const hasTree = useMemo(() => {
    if (listConfigStore) {
      return listConfigStore.some(c => c.isActive);
    }
    return false;
  }, [listConfigStore]);

  const notificationsGroups = useMemo(() => {
    if (hasTree) {
      const groupsStructure = new NotificationsTree(notifications, selectedNotifications, t);

      groupsStructure.createGroups(groupsStore);

      const tree = groupsStructure.getTree();
      const filteredTree = tree.filter(group => group);

      saveToStorage('notifications', {
        groups: filteredTree,
        selectedNotifications: groupsStore.length !== filteredTree.length ? [] : selectedNotifications,
      });

      return filteredTree;
    }
    return [];
  }, [t, hasTree, notifications, groupsStore, selectedNotifications]);

  useEffect(() => {
    if (hasTree) {
      if ((notificationsGroups.length && !groupsStore.length) || notificationsGroups.length !== groupsStore.length) {
        dispatch(setNotificationsGroups(notificationsGroups));
      }
    }
  }, [dispatch, hasTree, notificationsGroups, groupsStore]);

  const handleChangeSelect = () => {
    // return dispatch(showModal({ type: TRANSPORT, which: '' }));
  };

  const handleTurnNotification = (id: string) => {
    if (notificationsAccess.isAllowUpdate()) {
      dispatch(turnNotificationInList(id));
    }
  };

  const handleChangeNotification = (id: string) => {
    if (notificationsAccess.isAllowUpdate()) {
      dispatch(fetchChosenNotification(id));
      dispatch(showModal({ type: NOTIFICATIONS, which: '' }));
    }
  };

  const handleClickButton = () => {
    dispatch(showModal({ type: NOTIFICATIONS, which: '' }));
  };

  const notificationItems = useSortingItems(notifications);
  const groupsItems = useSortingGroupItems(notificationsGroups);

  return (
    <div className={styles.content}>
      <div className={styles.buttonBlock}>
        <div className={styles.searchWrapper}>
          <Search handleChange={handleChangeSelect} />
        </div>
        {notificationsAccess.isAllowCreate() && (
          <div className={styles.buttonWrapper}>
            <Button
              text={t('notifications.button-block.btn.label')}
              withSymbol
              blue
              long
              onClick={handleClickButton}
              customStyle={styles.notificationCreateButton}
            />
          </div>
        )}
      </div>

      {isShowHistory ? (
        <NotificationHistoryLeftSideBar onCancel={() => setIsShowHistory(false)} />
      ) : (
        <Fragment>
          <div className={styles.notificationsList}>
            <ListHeader showExpand={hasTree} />
            {hasTree
              ? groupsItems.map(
                  (group, index) =>
                    group && (
                      <NotificationGroup
                        key={`notification-group-item_${index}`}
                        group={group}
                        disabledTurn={!notificationsAccess.isAllowUpdate()}
                      />
                    )
                )
              : notificationItems.map((notification, index) => {
                  const { name, isActive } = notification.attributes;
                  const { numberOfNotified, numberOfUnits } = notification.meta;

                  return (
                    <NotificationItem
                      key={`notification-item-${index}`}
                      text={name}
                      isActive={isActive}
                      disabledTurn={!notificationsAccess.isAllowUpdate()}
                      checked={selectedNotifications.includes(notification.id)}
                      numberOfNotified={
                        notification?.attributes.notificationType === testNotification?.attributes.notificationType
                          ? String(Number(numberOfNotified) + 1)
                          : numberOfNotified
                      }
                      numberOfUnits={numberOfUnits}
                      handleTurn={() => handleTurnNotification(notification.id)}
                      handleChange={() => handleChangeNotification(notification.id)}
                      notificationId={notification.id}
                    />
                  );
                })}
          </div>
          <div className={styles.notificationsButtons}>
            <Button
              white
              text={t('notifications.button.history.label')}
              onClick={() => setIsShowHistory(true)}
              customStyle={styles.notificationsButton}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(Notifications);
