import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  TrackState,
  Player,
  TrackDTO,
  TrackGroupDTO,
  CreateTrackGroup,
  TrackGroupsByIdParams,
  NewTracksGroupsJSONApi,
  NewTracksJSONApi,
  TracksGroupByIdAnswerJSONApi,
  NewTracksAttributes,
  Route,
} from './interface';
import {
  postTrack,
  deleteTracksGroupById,
  getTracksGroupById,
  getTrackGroups,
  getTracksGroupGeodata,
  getTracks,
  postTrackGroup,
  putTracksGroupById,
  putTrackById,
} from './api';
import { Sorting, pickerColors } from 'utils/consts';
import { SortingType } from '../geozones/interface';
import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';

const initialState: TrackState = {
  chosenTrack: '0',
  chosenTrackForDelete: '0',
  selectedTracks: [],

  tracks: [],
  tracksToRender: [],
  tracksToRenderAttributes: [],
  trackGroups: [],
  players: {},
  scrollByUser: false,

  newTracks: [],
  newTracksIncluded: [],
  newTracksMeta: { total: 0 },
  newTracksGroups: [],
  newTracksGroupsIncluded: [],
  newTracksGroupsMeta: { total: 0 },
  newRoutes: [],

  error: null,

  sort: {
    type: Sorting.name,
    ascending: true,
  },

  isLoading: false,
  needUpdate: false,
};

export const fetchGetTracksGroupById = createAsyncThunk(
  'trackSlice/fetchGetTracksGroupById',
  async (id: string) => await getTracksGroupById(id)
);

export const fetchGetTracks = createAsyncThunk('trackSlice/fetchGetTracks', async () => await getTracks());

export const fetchGetTracksGroupGeodata = createAsyncThunk(
  'trackSlice/fetchGetTracksGroupGeodata',
  async (params: TrackGroupsByIdParams) => await getTracksGroupGeodata(params)
);

export const fetchGetTracksGroups = createAsyncThunk(
  'trackSlice/fetchGetTracksGroups',
  async () => await getTrackGroups()
);

export const fetchPostTrack = createAsyncThunk(
  'trackSlice/fetchPostTrack',
  async (data: TrackDTO) => await postTrack(data)
);

export const fetchPostTracksGroup = createAsyncThunk(
  'trackSlice/fetchPostTracksGroup',
  async (data: CreateTrackGroup) => {
    const res = await postTrackGroup(data.trackGroupName);
    const promises: ParallelItemInterface[] = [];

    data.tracksData.forEach(track => {
      track.trackGroupId = parseInt(res.data.id);
      promises.push({
        promiseMethod: postTrack,
        args: track,
      });
    });

    if (promises.length) {
      await reqHandlers.allSettled(promises);
    }
    return res;
  }
);

export const fetchPutTracksGroupById = createAsyncThunk(
  'trackSlice/fetchPutTracksGroupById',
  async (data: { id: string; data: TrackGroupDTO }) => await putTracksGroupById(data.id, data.data)
);

export const fetchPutTrackById = createAsyncThunk(
  'trackSlice/fetchPutTrackById',
  async (data: { id: string; data: TrackDTO }) => await putTrackById(data.id, data.data)
);

export const fetchDeleteTracksGroupById = createAsyncThunk(
  'trackSlice/fetchDeleteTracksGroupById',
  async (id: string) => await deleteTracksGroupById(id)
);

const trackSlice = createSlice({
  name: 'trackSlice',
  initialState,
  reducers: {
    setChosenTrack: (state, action) => {
      state.chosenTrack = action.payload;
    },
    setChosenTrackForDelete: (state, action) => {
      state.chosenTrackForDelete = action.payload;
    },
    selectAllTracks: (state, action) => {
      state.selectedTracks = action.payload;
    },
    unSelectAllTracks: state => {
      state.selectedTracks = [];
      state.tracksToRender = [];
    },
    selectTrack: (state, { payload }: { payload: string }) => {
      state.selectedTracks = [...state.selectedTracks, payload];
    },
    unselectTrack: (state, { payload }: { payload: string }) => {
      state.selectedTracks = state.selectedTracks.filter(t => t !== payload);
    },
    tracesToShow: state => {
      state.tracksToRender = [];
    },
    playersStatus: (state, { payload }: { payload: Player }) => {
      const { players } = state;
      if (payload.show) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        players[payload.id] = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...players[payload.id],
          ...payload,
        };
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete players[payload.id];
      }
      state.players = players;
      window.activeId = payload.id;
    },
    setSort: (state, { payload }: { payload: SortingType }) => {
      const sort = state.sort;

      if (sort.type !== payload) {
        sort.type = payload;
        sort.ascending = false;
      } else {
        sort.ascending = !state.sort.ascending;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetTracksGroupGeodata.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(
        fetchGetTracksGroupGeodata.fulfilled,
        (state, { payload }: { payload: TracksGroupByIdAnswerJSONApi }) => {
          state.needUpdate = false;
          state.isLoading = false;
          const routesArr: number[][][] = [];

          const { routes } = payload.meta;
          const routeArr: number[][] = [];
          const attributesArray: NewTracksAttributes[] = [];
          let routeForGetMetaData: Route[] = [];
          routes.forEach((route, index) => {
            const routeKeys = Object.keys(route);
            const attributesForRender = Object.assign({}, payload.included[index].attributes);
            if (!attributesForRender.color) {
              attributesForRender.color = pickerColors[0];
            }
            attributesArray.push(attributesForRender);
            if (routeKeys.length) {
              if (index === 0) {
                routeForGetMetaData = route[routeKeys[0]].data;
              }
              route[routeKeys[0]].data.forEach(data => {
                routeArr.push(data.coords);
              });
            }
          });
          routesArr.push(routeArr);
          state.tracksToRender = routesArr;
          state.tracksToRenderAttributes = attributesArray;
          state.newRoutes = routeForGetMetaData;
        }
      )
      .addCase(fetchGetTracksGroupGeodata.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(fetchGetTracksGroups.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchGetTracksGroups.fulfilled, (state, { payload }: { payload: NewTracksGroupsJSONApi }) => {
        state.newTracksGroups = payload.data;
        state.newTracksGroupsIncluded = payload.included;
        state.newTracksMeta = payload.meta;
        state.needUpdate = false;
        state.isLoading = false;
      })
      .addCase(fetchGetTracksGroups.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchGetTracks.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchGetTracks.fulfilled, (state, { payload }: { payload: NewTracksJSONApi }) => {
        state.newTracks = payload.data;
        state.newTracksIncluded = payload.included;
        state.newTracksMeta = payload.meta;
        state.needUpdate = false;
        state.isLoading = false;
      })
      .addCase(fetchGetTracks.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchPostTracksGroup.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchPostTracksGroup.fulfilled, state => {
        state.needUpdate = true;
        state.isLoading = false;
      })
      .addCase(fetchPostTracksGroup.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchPostTrack.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchPostTrack.fulfilled, state => {
        state.needUpdate = true;
        state.isLoading = false;
      })
      .addCase(fetchPostTrack.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchPutTrackById.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchPutTrackById.fulfilled, state => {
        state.needUpdate = true;
        state.isLoading = false;
      })
      .addCase(fetchPutTrackById.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchPutTracksGroupById.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchPutTracksGroupById.fulfilled, state => {
        state.needUpdate = true;
        state.isLoading = false;
      })
      .addCase(fetchPutTracksGroupById.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
    builder
      .addCase(fetchDeleteTracksGroupById.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchDeleteTracksGroupById.fulfilled, state => {
        state.needUpdate = true;
        state.isLoading = false;
      })
      .addCase(fetchDeleteTracksGroupById.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.needUpdate = false;
        state.isLoading = false;
      });
  },
});

export const {
  selectTrack,
  setChosenTrackForDelete,
  unselectTrack,
  selectAllTracks,
  unSelectAllTracks,
  tracesToShow,
  playersStatus,
  setSort,
  setChosenTrack,
} = trackSlice.actions;

export default trackSlice.reducer;
