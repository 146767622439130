import { FormatMessage } from '@comparaonline/react-intl-hooks';
import { Unit, UnitAttributes } from 'reducers/trackableUnits/interface';
import { MonitoringListConfig } from 'reducers/user/interface';
import { LanguagesKeysType } from 'translate';
import { HierarchyItems, TransformedUnitsData } from './types';

export class MonitoringUnitsTree {
  private result: TransformedUnitsData = {};
  private activeConfigs: MonitoringListConfig = [];
  private activeKeys: number[] = [];
  private units: Unit[] = [];

  constructor(
    private hierarchy: HierarchyItems,
    private config: MonitoringListConfig,
    private tHook: FormatMessage,
    private langKey: LanguagesKeysType
  ) {}

  setUnits(units: Unit[]) {
    this.units = units;
  }

  setActiveConfigs() {
    const activeConfigs = this.config?.filter(c => c.isActive) || [];

    if (activeConfigs.length) {
      const lastConfig = Object.assign({}, activeConfigs.pop());

      lastConfig.childIndexes = [];
      activeConfigs.push(lastConfig);
    }
    this.activeConfigs = activeConfigs;
  }

  setActiveKeys(unit: Unit) {
    this.activeKeys = this.activeConfigs.map(
      c => (unit.attributes[c.idField as keyof UnitAttributes] as number | null) ?? 0
    );
  }

  checkUnitStructure(resObj: TransformedUnitsData, unit: Unit, ind: number): unknown {
    const activeConfig = this.activeConfigs[ind];

    if (!activeConfig) {
      return;
    } // терминальное условие

    const activeKey = this.activeKeys[ind];
    const objKey = `${activeConfig.keyName}-${activeKey}`;

    if (!activeConfig.parentIndexes.length) {
      resObj = this.result;
    }

    if (!resObj[objKey]) {
      const structure = this.hierarchy[activeConfig.keyName as keyof HierarchyItems]?.find(s => s.id === activeKey);
      resObj[objKey] = {
        id: activeKey,
        uniqueKey: `${activeConfig.keyName}-${activeConfig.parentIndexes.join('')}-${activeKey}`,
        name: structure?.attributes.name[this.langKey] ?? this.tHook(activeConfig.emptyTitle),
        substructures: {},
        units: [],
      };
    }

    if (!activeConfig.childIndexes.length) {
      resObj[objKey].units.push(unit);
      return this.checkUnitStructure(resObj, unit, ind + 1);
    } else {
      return this.checkUnitStructure(resObj[objKey].substructures, unit, ind + 1);
    }
  }

  mapTrackableUnits() {
    this.setActiveConfigs();
    this.units.forEach(unit => {
      this.setActiveKeys(unit);
      this.checkUnitStructure(this.result, unit, 0);
    });
  }

  getMonitoringTree() {
    return this.result;
  }
}
