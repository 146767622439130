import { reqHandlers } from 'utils/api';
import { CreateRole } from 'reducers/roles/interface';

export function getRoles() {
  return reqHandlers.get(`/api/roles`);
}

export function getOneRole(id: string) {
  return reqHandlers.get(`/api/roles/${id}`);
}

export function createRole(data: CreateRole) {
  return reqHandlers.post(`/api/roles`, data);
}

export function putRole(id: string, data: CreateRole) {
  return reqHandlers.put(`/api/roles/${id}`, data);
}

export function deleteRole(id: number) {
  return reqHandlers.delete(`/api/roles/${id}`);
}
