import { Dispatch } from 'react';

export type SelectOptions = { value: string | number; label: string | number }[];

export type AccountHandlers = {
  handleSmsNotifyChange: () => void;
  handleOrgChange: (val: string) => void;
  handleDepartmentChange: (val: string) => void;
  handlePositionChange: (val: string) => void;
  handleEmailNotifyChange: () => void;
  handleBanStatusChange: () => void;
  setIcon: (val: string | null) => void;
} & { [key: string]: Dispatch<string> };

export enum AccountsPage {
  Users = 'users',
  Roles = 'roles',
}
