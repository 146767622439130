import React, { Fragment, useEffect, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/common/button/button';
import Search from 'components/common/search/search';

import { RootState } from 'reducers';
import { fetchUnits, setSearch, setSearchedUnits } from 'reducers/trackableUnits';
import { unwatchAllUnits, setSelectedOneUnit } from 'reducers/monitoring';

import { debounce } from 'lodash';

import styles from './searchedUnits.module.scss';

// кастомный компонент
const CustomSpan = ({ text }: { text: string }) => <span style={{ fontWeight: 'bold', color: '#0066FF' }}>{text}</span>;

// функция, форматирующая найденное имя
function getFormattedName(searchStrTrimed: string, nameSearch: string) {
  let searchStrRegExp = /./;

  try {
    searchStrRegExp = new RegExp(searchStrTrimed, 'gi');
  } catch (e) {
    const withNoSpecialCharactersStr = searchStrTrimed.replace(/^[.*+?^${}()|[\]\\]+/g, '\\$&'); // экранирование спецсимволов RegExp
    searchStrRegExp = new RegExp(withNoSpecialCharactersStr, 'gi');
  }

  if (searchStrRegExp.test(nameSearch)) {
    const matchedStrs = nameSearch.match(searchStrRegExp);
    const textArray = nameSearch.split(searchStrRegExp);

    const result = textArray
      .map((substr, index) => {
        if (index !== textArray.length - 1 && matchedStrs?.length) {
          return (
            <Fragment key={`splitted-span-${index}`}>
              {substr}
              <CustomSpan text={matchedStrs[index]} />
            </Fragment>
          );
        }
        return <Fragment key={`splitted-${index}`}>{substr}</Fragment>;
      })
      .reduce((pred, curr) => (
        <Fragment>
          {pred}
          {curr}
        </Fragment>
      ));
    return result;
  }
  return null;
}

export const SearchedUnits = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const search = useSelector((state: RootState) => state.trackableUnit.search);
  const units = useSelector((state: RootState) => state.trackableUnit.trackableUnits);

  const [isHideSearchContextMenu, setIsHideSearchContextMenu] = useState(false);

  const searchStrTrimed = search.trim();

  useEffect(() => {
    setIsHideSearchContextMenu(false);
    dispatch(setSearchedUnits(null));
  }, [dispatch, search]);

  const handleSelectedUnitChange = (index: number) => {
    const unit = units[index];
    if (unit) {
      // очистить список отслеживаемых юнитов
      dispatch(unwatchAllUnits());
      // скрыть контекстное меню
      setIsHideSearchContextMenu(true);
      // установить в список юнитов найденный юнит
      dispatch(setSearchedUnits([unit]));
      // check выбранного юнита
      dispatch(setSelectedOneUnit(unit.id));
    }
  };

  const handleSearchChange = debounce(function (val: string) {
    dispatch(setSearch(val));
    dispatch(fetchUnits());
  }, 500);

  const findedUnits = units
    .map(unit => ({
      name: getFormattedName(searchStrTrimed, unit.attributes.aggregatedName),
      type: unit.attributes.transportId
        ? t('monitoring.search.unit-type.transport.text')
        : t('monitoring.search.unit-type.employee.text'),
    }))
    .filter(result => result.name);

  return (
    <div className={styles.searchWrapper} onFocus={() => setIsHideSearchContextMenu(false)}>
      <Search handleChange={handleSearchChange} />
      {!isHideSearchContextMenu && searchStrTrimed && !!units.length && !!findedUnits.length && (
        <div className={styles.searchResult}>
          <div className={styles.searchResultWrapper}>
            <ul className={styles.searchResultList}>
              {findedUnits.map((unit, index) => (
                <li
                  key={`searched-result_${index}`}
                  className={styles.searchResultItem}
                  onClick={() => handleSelectedUnitChange(index)}
                >
                  <div className={styles.searchResultName}>{unit.name}</div>
                  <div className={styles.searchResultType}>{unit.type}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.searchResultMore}>
            <Button white text={t('search.more-result.btn.label')} />
          </div>
        </div>
      )}
    </div>
  );
};
