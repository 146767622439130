import React, { Fragment, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';

import { ReactComponent as ManualSortingIcon } from 'assets/img/records/manual_sorting_button.svg';
import { ReactComponent as SortingIcon } from 'assets/img/records/sort_button_icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';

import Search from 'components/common/search/search';
import Button from 'components/common/button/button';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import { InfoElement } from 'components/common/infoElement/infoElement';
import { Spinner } from 'components/common/spinner/spinner';
import { RecordsDataTabHandlers, RecordsDataTabRefs, RecordsDataTabStates } from 'components/records/utils/types';
import { getCharFromCode } from 'components/records/utils/helpers';
import {
  ReportTemplateDataFieldsTranslate,
  REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE,
  REPORT_TEMPLATE_DATA_GROUPS_TRANSLATE,
  REPORT_CHOSEN_DATA_SORTING_MODE,
} from 'components/records/utils/consts';

import { SORTABLE_LIST_CONTAINER_DATA_SET } from '../../../hooks/useDataTab';

import styles from './dataTab.module.scss';
import { includesIgnoreCase } from 'utils/common/strings';

const cx = classNames.bind(styles);

interface Props {
  states: RecordsDataTabStates;
  handlers: RecordsDataTabHandlers;
  refs: RecordsDataTabRefs;
  invalidFields: string[];
}

type CommonSortableItemProps = {
  children: ReactNode;
  style?: string;
  handleMouseDown?: () => void;
};
type CommonSortableListProps = {
  children: ReactNode;
  style?: string;
};

type SortableItemProps = {
  children: ReactNode;
  idx: number;
  states: RecordsDataTabStates;
  handleMouseDown: () => void;
};

const AvailableDragHandler = SortableHandle(({ children }: { children: ReactNode }) => <Fragment>{children}</Fragment>);
const AvailableSortableItem = SortableElement(({ children, handleMouseDown, style }: CommonSortableItemProps) => (
  <AvailableDragHandler>
    <div className={style} onMouseDown={handleMouseDown}>
      {children}
    </div>
  </AvailableDragHandler>
));
const SortableList = SortableContainer(({ style, children }: CommonSortableListProps) => {
  return <div className={style}>{children}</div>;
});

const ChosenDragHandler = SortableHandle(() => <ManualSortingIcon className={styles.sortableListItemManualSorting} />);
const ChosenSortableItem = SortableElement(({ children, idx, states, handleMouseDown }: SortableItemProps) => (
  <li
    className={cx(styles.sortableListItem, {
      [styles.sortableListItemIsChoose]: states.selectedChosen === idx,
    })}
    onMouseDown={handleMouseDown}
  >
    <ChosenDragHandler />

    {children}
  </li>
));

export const DataTab = ({ states, handlers, refs, invalidFields }: Props) => {
  const t = useFormatMessage();
  const { templateWindow } = useSelector((state: RootState) => state.records);

  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  if (templateWindow.isAvailableTemplateDataGroupsLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <div className={styles.rowSearch}>
          <Search handleChange={handleSearchChange} />
        </div>
      </div>

      <SortableList
        onSortEnd={handlers.handleCommonSortEnd}
        onSortStart={handlers.handleCommonSortStart}
        style={styles.dataList}
        axis="xy"
        useDragHandle
      >
        <div className={styles.dataListAvailable}>
          <div className={styles.dataListHeader}>{t('records.card.template-create.data.available.header.text')}</div>
          <ul className={styles.dataListItems} data-sortcontainer={SORTABLE_LIST_CONTAINER_DATA_SET.AVAILABLE}>
            {states.availableData.map((availableItem, idx) => {
              const group = availableItem.data.group as keyof typeof REPORT_TEMPLATE_DATA_GROUPS_TRANSLATE;
              const fields = REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE[group];

              return (
                <li key={`available-data-${idx}`} className={styles.dataListItem}>
                  <div
                    className={styles.dataListItemRow}
                    onClick={() => handlers.handleExpandAvailableData(availableItem.data.id)}
                  >
                    <ExpandButton
                      isOpen={availableItem.isExpanded}
                      style={styles.dataListItemExpand}
                      onClick={() => handlers.handleExpandAvailableData(availableItem.data.id)}
                    />
                    <span className={styles.dataListItemName} title={availableItem.data.group}>
                      {t(REPORT_TEMPLATE_DATA_GROUPS_TRANSLATE[group]) ?? ''}
                    </span>
                  </div>
                  {availableItem.isExpanded &&
                    availableItem.data.fields.map((prop, i) => {
                      const field = t(fields[prop as keyof typeof fields]);

                      if (searchValue && !includesIgnoreCase(field, searchValue)) {
                        return null;
                      }

                      return (
                        <AvailableSortableItem
                          key={`available-item_${idx}-${i}`}
                          index={idx + i}
                          collection={availableItem.customId}
                          handleMouseDown={() => handlers.handleMouseDownAvailableItem({ parentId: idx, currentId: i })}
                          style={cx(styles.dataListItemRow, styles.dataListItemRowProperties, {
                            [styles.dataListItemRowIsChoose]:
                              states.selectedAvailable.parentId === idx && states.selectedAvailable.currentId === i,
                          })}
                        >
                          <div className={styles.dataListItemRowProperty}>{field}</div>
                        </AvailableSortableItem>
                      );
                    })}
                </li>
              );
            })}
          </ul>
        </div>

        <div className={styles.moveButtons}>
          <div className={styles.moveButtonsBtn} onClick={handlers.handleMoveRight}>
            <ArrowIcon className={styles.moveButtonsBtnToRight} />
          </div>
          <div className={styles.moveButtonsBtn} onClick={handlers.handleMoveLeft}>
            <ArrowIcon className={styles.moveButtonsBtnToLeft} />
          </div>
        </div>

        <div>
          <div className={styles.dataListActive}>
            <div className={styles.dataListHeader}>{t('records.card.template-create.data.select.header.text')}</div>
            <ul
              className={cx(styles.dataListItems, {
                [styles.dataListItemsError]: invalidFields.includes('chosenData'),
              })}
              data-sortcontainer={SORTABLE_LIST_CONTAINER_DATA_SET.CHOSEN}
            >
              {states.chosenData.map((chosenItem, i) => {
                let translateName = '';

                if (chosenItem.dataType && chosenItem.dataType in ReportTemplateDataFieldsTranslate) {
                  translateName = t(
                    ReportTemplateDataFieldsTranslate[
                      chosenItem.dataType as keyof typeof ReportTemplateDataFieldsTranslate
                    ]
                  );
                }
                if (chosenItem.expression) {
                  translateName = chosenItem.expression;
                }

                return (
                  <ChosenSortableItem
                    key={`chosen-data-${i}`}
                    index={i}
                    idx={i}
                    states={states}
                    collection={'chosenItems'}
                    handleMouseDown={() => handlers.handleMouseDownChosenItem(i)}
                  >
                    <div className={cx(styles.sortableListItemRow, styles.sortableListItemRowSpaceBetween)}>
                      <div className={styles.sortableListItemChosenName} title={translateName}>
                        {chosenItem.mark}
                        {'. '}
                        {translateName}
                      </div>
                      <SortingIcon
                        className={cx(styles.sortableListItemAutoSorting, {
                          [styles.sortableListItemAutoSortingAscending]:
                            chosenItem.sort === REPORT_CHOSEN_DATA_SORTING_MODE.ASC,
                          [styles.sortableListItemAutoSortingDescending]:
                            chosenItem.sort === REPORT_CHOSEN_DATA_SORTING_MODE.DESC,
                        })}
                        onClick={evt => handlers.handleClickChosenItemBtnSorting(evt, i)}
                      />
                    </div>
                  </ChosenSortableItem>
                );
              })}
              {states.isCreateNewChosenData && (
                <li key={`chosen-data-${states.chosenData.length}`} className={styles.sortableListItem}>
                  <ManualSortingIcon className={styles.sortableListItemManualSorting} />
                  <div className={cx(styles.sortableListItemRow, styles.sortableListItemRowSpaceBetween)}>
                    <div className={styles.sortableListItemChosenName}>
                      {getCharFromCode(states.chosenData.length)}
                      {'. '}
                      <input
                        type="text"
                        className={styles.sortableListItemChosenInput}
                        placeholder={t('records.card.template-create.data.select.data.placeholder')}
                        ref={refs.createNewChosenDataInputRef}
                        onBlur={e => handlers.handleBlurCreateNewChosenData(e)}
                        onKeyDown={e => handlers.handleKeyDownCreateNewChosenData(e)}
                      />
                    </div>
                    <SortingIcon className={styles.sortableListItemAutoSorting} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </SortableList>

      <div className={styles.dataListButtons}>
        <Button
          white
          text={t('records.card.template-create.data.select.btn.text')}
          customStyle={styles.dataListButton}
          onClick={handlers.handleIsCreateNewChosenData}
        />
        <InfoElement
          id={'addButtonTemplate'}
          text={t('records.card.template-create.data.select.tooltip.text')}
          customStyle={styles.tooltip}
        />
      </div>
    </div>
  );
};
