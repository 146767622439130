export default {
  en: {
    'alert.warning.text': 'Warning!',
    'alert.question.text': 'Are you sure you want to continue?',
    'alert.btn.yes.label': 'Yes',
    'alert.btn.no.label': 'No',
  },
  ru: {
    'alert.warning.text': 'Внимание!',
    'alert.question.text': 'Вы действительно хотите продолжить?',
    'alert.btn.yes.label': 'Да',
    'alert.btn.no.label': 'Нет',
  },
  zh: {
    'alert.warning.text': '注意！',
    'alert.question.text': '您确定要继续吗？',
    'alert.btn.yes.label': '是',
    'alert.btn.no.label': '否',
  },
  ar: {
    'alert.warning.text': 'انتباه!',
    'alert.question.text': 'هل حقًا ترغب بمتابعة؟',
    'alert.btn.yes.label': 'نعم',
    'alert.btn.no.label': 'كلا',
  },
  de: {
    'alert.warning.text': 'Beachtung!',
    'alert.question.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'alert.btn.yes.label': 'Ja',
    'alert.btn.no.label': 'Nein',
  },
};
