import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Option } from 'components/common/select/select';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { fetchHandbookData } from 'reducers/handbooks';

export const useOrganizationDepartmentPositionSelectOptions = (selectedOrganizationId: string) => {
  const dispatch = useDispatch();

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);
  const organizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const positions = useSelector((state: RootState) => state.handbooks.data.positions);

  useEffect(() => {
    dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.positions]));
  }, []);

  const organizationOptions: Option[] = useMemo(() => {
    if (!Array.isArray(organizations)) {
      return [];
    }

    return organizations.map(org => ({
      value: String(org.id),
      label: getTranslateFromLanguageKey(org.attributes.name, locale),
    }));
  }, [organizations, locale]);

  const departmentOptions: Option[] = useMemo(() => {
    if (!Array.isArray(organizations) || !selectedOrganizationId) {
      return [];
    }

    const selectedOrganization = organizations.find(org => String(org.id) === selectedOrganizationId);

    if (!selectedOrganization) {
      return [];
    }

    const relatedDepartments = selectedOrganization.relationships.departments?.data;

    if (!relatedDepartments) {
      return [];
    }

    return relatedDepartments.map(department => ({
      value: String(department.id),
      label: getTranslateFromLanguageKey(department.name, locale),
    }));
  }, [selectedOrganizationId, organizations, locale]);

  const positionOptions: Option[] = useMemo(() => {
    if (!Array.isArray(positions)) {
      return [];
    }

    return positions.map(position => ({
      value: String(position.id),
      label: getTranslateFromLanguageKey(position.attributes.name, locale),
    }));
  }, [positions, locale]);

  return {
    organizationOptions,
    departmentOptions,
    positionOptions,
  };
};
