import React from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import DatesRangeWithScripts from 'components/common/datesRangeWithScripts/datesRangeWithScripts';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { MESSAGE_TYPES } from 'components/messages/utils/consts';
import {
  MessagesLeftBarFilterStates,
  MessagesLeftBarFilterRefs,
  MessagesLeftBarFilterHandlers,
} from 'components/messages/utils/types';

import { RootState } from 'reducers';

import styles from './messagesListFilter.module.scss';

type Props = {
  states: MessagesLeftBarFilterStates;
  refs: MessagesLeftBarFilterRefs;
  handlers: MessagesLeftBarFilterHandlers;
};

function MessagesListFilter({ states, refs, handlers }: Props) {
  const t = useFormatMessage();

  const { filter } = useSelector((state: RootState) => state.messages);

  return (
    <div className={styles.wrapper}>
      <DatesRangeWithScripts
        handleChange={handlers.handleChangeDates}
        periodFrom={filter.dateStart}
        periodTo={filter.dateEnd}
        periodFromRef={refs.periodFromRef}
        periodToRef={refs.periodToRef}
        wrapperClass={styles.row}
      />
      <MultipleSelect
        options={states.messageFilterObjectsForSelect}
        label={t('messages.left-bar.filter.object.label')}
        placeholder={t('messages.left-bar.filter.object.placeholder')}
        isRequired={false}
        withSearchIcon
        wrapperStyle={styles.row}
        handleChangeMultiple={handlers.handleChangeObjects}
        values={filter.trackableUnitsIds.map((id, i) =>
          getValueForMultipleSelect({
            i,
            id,
            selectValues: states.messageFilterObjectsForSelect,
          })
        )}
      />
      <MultipleSelect
        options={states.messageFilterTypesForSelect}
        label={t('messages.left-bar.filter.type.label')}
        placeholder={t('messages.left-bar.filter.type.placeholder')}
        isRequired={false}
        withSearchIcon
        wrapperStyle={styles.row}
        handleChangeMultiple={handlers.handleChangeType}
        values={states.messageFilterTypesForSelect.filter(option =>
          filter.messageTypes.includes(option.value as MESSAGE_TYPES)
        )}
        optionIsInline
      />
    </div>
  );
}

export default MessagesListFilter;
