import { reqHandlers } from 'utils/api';
import { BindUnitToUserReqParams } from './interface';

export function getUnits(search: string) {
  let query = '';

  if (search) {
    query += `?search=${search}`;
  }
  return reqHandlers.get(`/api/trackableUnits${query}`);
}

export function getActiveUnits() {
  return reqHandlers.get(`/api/trackableUnits/active`);
}

export function getNotActiveUnits() {
  return reqHandlers.get(`/api/trackableUnits/notActive`);
}

export function postUnitToUser(data: BindUnitToUserReqParams) {
  return reqHandlers.post('/api/trackableUnits/active', data);
}

export function deleteUnitFromUser(unitId: number) {
  return reqHandlers.delete(`/api/trackableUnits/active/${unitId}`);
}

export function getUnitsShort() {
  return reqHandlers.get('/api/trackableUnits/shortAll');
}

export function retrieveLatestPosition(deviceId: string | string[]) {
  return reqHandlers.post('/api/trackableUnits/retrieveLatestPosition', {
    devices: Array.isArray(deviceId) ? [...deviceId] : [deviceId],
  });
}
