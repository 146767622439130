import React, { useEffect, useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { Spinner } from 'components/common/spinner/spinner';
import { getIconByNotificationType, getNotificationIcon } from 'components/map/utils';
import NotificationPopup from '../notificationPopup/notificationPopup';

import { RootState } from 'reducers';
import {
  removeMarkerNotificationLog,
  setMarkerNotificationLog,
  setClusterNotificationLogsIds,
  fetchClusterNotificationLogs,
} from 'reducers/notifications';
import { NotificationLog } from 'reducers/notifications/interface';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './notificationClusterPopup.module.scss';

const CLUSTER_POPUP_WIDTH = 320;
const CLUSTER_POPUP_HEIGHT = 170;

const FETCHED_ELEMENTS_COUNTS = 10; // количество запрашиваемых элементов

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
}

function NotificationClusterPopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();

  const { isClusterNotificationLogsLoading, clusterNotificationLogsIds, clusterNotificationLogs, notifications } =
    useSelector((state: RootState) => state.notifications);

  const notificationTypes: string[] = useMemo(
    () =>
      clusterNotificationLogs.map(log => {
        const notificationId = log.attributes.notificationId;
        const foundNotificationType = notifications.find(notification => notification.id === String(notificationId))
          ?.attributes.notificationType;

        if (foundNotificationType) {
          return getNotificationIcon(getIconByNotificationType(foundNotificationType));
        }
        return '';
      }),
    [notifications, clusterNotificationLogs]
  );

  const [showItemPopup, setShowItemPopup] = useState(false);
  const [markerCoordsItemPopup, setMarkerCoordsItemPopup] = useState({
    mouseCoords: { x: 0, y: 0 },
    mapCoords: { x: 0, y: 0 },
  });

  const clusterLogListRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(removeMarkerNotificationLog());
      dispatch(setClusterNotificationLogsIds([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (clusterNotificationLogsIds.length) {
      dispatch(fetchClusterNotificationLogs(clusterNotificationLogsIds.slice(0, FETCHED_ELEMENTS_COUNTS)));
    }
  }, [dispatch, clusterNotificationLogsIds]);

  const handleItemClick = (evt: React.MouseEvent<HTMLLIElement, MouseEvent>, log: NotificationLog) => {
    setMarkerCoordsItemPopup({
      mouseCoords: { x: evt.clientX, y: evt.clientY },
      mapCoords: { x: 0, y: 0 },
    });
    dispatch(setMarkerNotificationLog(log));
    setShowItemPopup(true);
  };

  const handleScroll = () => {
    const clusterLogList = clusterLogListRef.current;

    if (clusterLogList) {
      const clusterLogListHeight = (clusterLogList as Element).getBoundingClientRect().height;
      const clusterLogListScrollHeight = (clusterLogList as Element).scrollHeight;
      const clusterLogListScrollTop = (clusterLogList as Element).scrollTop;

      if (
        clusterLogListScrollTop >= clusterLogListScrollHeight - clusterLogListHeight &&
        clusterNotificationLogsIds.length
      ) {
        dispatch(setClusterNotificationLogsIds(clusterNotificationLogsIds.slice(FETCHED_ELEMENTS_COUNTS)));
      }
    }
  };

  const getParams = () => {
    handleScroll();
  };

  const content = (
    <ul className={styles.popupList} onScroll={handleScroll} ref={clusterLogListRef}>
      {clusterNotificationLogs.map((log, i) => {
        const attr = log.attributes;
        const dateTime = getFullDateFromStr(attr.eventTime);
        const iconSrc = notificationTypes[i];

        return (
          <li
            className={styles.popupListItem}
            key={`notification-log-${log.id}-${attr.text}`}
            onClick={evt => handleItemClick(evt, log)}
          >
            <div className={styles.popupListItemDate}>
              <div>{dateTime.date}</div>
              <div>{dateTime.shortTime}</div>
            </div>
            <div className={styles.popupListItemDescription}>
              <div className={styles.popupListItemDescriptionIcon}>
                <img src={iconSrc} alt="icon" />
              </div>
              <div className={styles.popupListItemDescriptionText}>{attr.text}</div>
            </div>
          </li>
        );
      })}
      {isClusterNotificationLogsLoading && (
        <li className={styles.popupSpinner}>
          <Spinner />
        </li>
      )}
    </ul>
  );

  if (showItemPopup) {
    return <NotificationPopup onCancel={onCancel} markerCoords={markerCoordsItemPopup} />;
  }

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      content={content}
      initialWidth={CLUSTER_POPUP_WIDTH}
      initialHeight={CLUSTER_POPUP_HEIGHT}
      initialPosition={markerCoords.mouseCoords}
      resizeAxis="y"
      customClassNames={{ content: styles.popupContentNoPaddings }}
      getParams={getParams}
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(NotificationClusterPopup);
