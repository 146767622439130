import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import arrayMove from 'array-move';
import isEqual from 'lodash.isequal';

import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import { Option } from 'components/common/select/select';

import { RootState } from 'reducers';
import { AdditionalField, EmployeeRefs, CreateEmployeeAttributes } from 'reducers/employees/interface';
import { HandbookTranslatedFieldType } from 'reducers/handbooks/interface';
import { fetchTrackers } from 'reducers/trackers';
import { TransportAttributes } from 'reducers/transports/interface';

import { isEqualArraysOfObjects } from 'utils/isEqualArraysOfObjects';
import { validator } from 'utils/validator';

import { getCurrentLocale } from 'translate';

import { EmployeeTabInfoHandlers, TransportTabInfoHandlers } from './types';

export function useEmployeeInfo() {
  const dispatch = useDispatch();

  const isEditing = useSelector((state: RootState) => !!state.employee.chosenEmployee);
  const chosenEmployee = useSelector((state: RootState) => state.employee.chosenEmployeeData);
  const chosenEmployeeId = useSelector((state: RootState) => state.employee.chosenEmployeeData?.id ?? '');
  const needUpdate = useSelector((state: RootState) => state.employee.needUpdate);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  useEffect(() => {
    dispatch(fetchTrackers());
  }, [dispatch]);

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [simNumber, setSimNumber] = useState('');
  const [organizationId, setOrganizationId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [positionId, setPositionId] = useState(0);
  const [workObjects, setWorkObjects] = useState<number[]>([]);
  const [passNumber, setPassNumber] = useState('');
  const [issuePassDate, setIssuePassDate] = useState('');
  const [endPassDate, setEndPassDate] = useState('');
  const [trackerId, setTrackerId] = useState<number | null>(null);
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    if (chosenEmployee) {
      const attr = chosenEmployee.attributes;

      setLastName(attr.lastName);
      setFirstName(attr.firstName);
      setSecondName(attr.secondName);
      setSimNumber(attr.simNumber);
      setOrganizationId(attr.organizationId);
      setDepartmentId(attr.departmentId);
      setPositionId(attr.positionId);
      setPassNumber(attr.passNumber);
      setIssuePassDate(attr.issuePassDate);
      setEndPassDate(attr.endPassDate);
      setWorkObjects(chosenEmployee.relationships.workObjects.data.map(wObj => Number(wObj.id)));

      const chosenTrackerId = chosenEmployee.relationships.tracker.data
        ? Number(chosenEmployee.relationships.tracker.data.id)
        : null;

      setTrackerId(chosenTrackerId);
      setIcon(attr.icon);
    }
  }, [chosenEmployee]);

  const issuePassDateRef = useRef(null);
  const endPassDateRef = useRef(null);

  const handbooksDataOrganizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const handbooksDataPositions = useSelector((state: RootState) => state.handbooks.data.positions);
  const handbooksDataWorkObjects = useSelector((state: RootState) => state.handbooks.data.workObjects);
  const trackers = useSelector((state: RootState) => state.tracker.trackers);

  const handbooks = {
    orgOptions: handbooksDataOrganizations.map(o => ({
      value: o.id,
      label: getTranslateFromLanguageKey(o.attributes.name, userLanguageKey),
    })),
    departmentOptions:
      handbooksDataOrganizations
        .find(o => o.id === organizationId)
        ?.relationships.departments?.data?.map(s => ({
          value: Number(s.id),
          label: getTranslateFromLanguageKey(s.name, userLanguageKey),
        })) ?? [],
    positionOptions: handbooksDataPositions.map(p => ({
      value: p.id,
      label: getTranslateFromLanguageKey(p.attributes.name, userLanguageKey),
    })),
    workObjOptions:
      handbooksDataWorkObjects?.map((w, i) => {
        return {
          value: i + 1,
          label: getTranslateFromLanguageKey(w.attributes.name as HandbookTranslatedFieldType, userLanguageKey),
          data: {
            id: w.id,
          },
        };
      }) ?? [],
    trackerOptions:
      chosenEmployee && chosenEmployee.relationships.tracker.data
        ? trackers
            .filter(t => t.id === Number(chosenEmployee.relationships.tracker.data!.id) || !t.attributes.isUsed)
            .map(t => ({ value: t.id, label: t.attributes.trackerNumber }))
        : trackers.filter(t => !t.attributes.isUsed).map(t => ({ value: t.id, label: t.attributes.trackerNumber })),
  };

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!lastName) {
      newInvalidFields.push('lastName');
    }
    if (!firstName) {
      newInvalidFields.push('firstName');
    }
    if (!secondName) {
      newInvalidFields.push('secondName');
    }
    if (!!simNumber && !validator.validPhone(simNumber)) {
      newInvalidFields.push('simNumber');
    }
    if (!organizationId) {
      newInvalidFields.push('organizationId');
    }
    if (!departmentId) {
      newInvalidFields.push('departmentId');
    }
    if (!positionId) {
      newInvalidFields.push('positionId');
    }
    if (!workObjects.length) {
      newInvalidFields.push('workObjects');
    }
    if (!issuePassDate) {
      newInvalidFields.push('issuePassDate');
    }
    if (!endPassDate) {
      newInvalidFields.push('endPassDate');
    }

    return newInvalidFields;
  };

  const handleOrgChange = (val: string) => {
    const id = parseInt(val);
    setOrganizationId(id);
  };
  const handleStructureChange = (val: string) => {
    const id = parseInt(val);
    setDepartmentId(id);
  };
  const handlePositionChange = (val: string) => {
    const id = parseInt(val);
    setPositionId(id);
  };
  const handleWorkObjectChange = (val: ValuesType) => {
    setWorkObjects(val.map(value => (value.data?.id as number) || -1));
  };

  const handleTrackerChange = (val: string) => {
    const nextTrackerId = parseInt(val) || null;
    setTrackerId(nextTrackerId);
  };

  const employeeValues: CreateEmployeeAttributes & { workObjects: number[]; trackerId: number | null } = {
    lastName,
    firstName,
    secondName,
    simNumber,
    organizationId,
    departmentId,
    positionId,
    passNumber,
    issuePassDate,
    endPassDate,
    workObjects,
    trackerId,
    icon,
  };

  const mainInfoHasChanges = () => {
    return !isEqual(employeeValues, {
      lastName: chosenEmployee?.attributes.lastName ?? '',
      firstName: chosenEmployee?.attributes.firstName ?? '',
      secondName: chosenEmployee?.attributes.secondName ?? '',
      simNumber: chosenEmployee?.attributes.simNumber ?? '',
      organizationId: chosenEmployee?.attributes.organizationId ?? 0,
      departmentId: chosenEmployee?.attributes.departmentId ?? 0,
      positionId: chosenEmployee?.attributes.positionId ?? 0,
      passNumber: chosenEmployee?.attributes.passNumber ?? '',
      issuePassDate: chosenEmployee?.attributes.issuePassDate ?? '',
      endPassDate: chosenEmployee?.attributes.endPassDate ?? '',
      workObjects: chosenEmployee?.relationships.workObjects.data.map(wObj => Number(wObj.id)) ?? [],
      trackerId: chosenEmployee?.relationships.tracker.data
        ? Number(chosenEmployee.relationships.tracker.data.id)
        : null,
      icon: chosenEmployee?.attributes.icon ?? null,
    });
  };

  const employeeRefs: EmployeeRefs = {
    issuePassDateRef,
    endPassDateRef,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const employeeHandlers: EmployeeTabInfoHandlers = {
    setLastName,
    setFirstName,
    setSecondName,
    handleOrgChange,
    setSimNumber,
    handleTrackerChange,
    handleStructureChange,
    handlePositionChange,
    handleWorkObjectChange,
    setPassNumber,
    setIssuePassDate,
    setEndPassDate,
    setIcon,
  };

  return {
    employeeValues,
    employeeRefs,
    employeeHandlers,
    validateRequiredFields,
    invalidFields,
    setInvalidFields,
    chosenEmployee,
    chosenEmployeeId,
    needUpdate,
    handbooks,
    mainInfoHasChanges,
    isEditing,
  };
}

export function useEmployeeAdditionalFields() {
  const chosenEmployeeData = useSelector((state: RootState) => state.employee.chosenEmployeeData);

  const employeeAdditionalFields = useMemo(
    () => chosenEmployeeData?.attributes.additionalFields ?? [],
    [chosenEmployeeData]
  );

  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>([]);

  useEffect(() => {
    if (employeeAdditionalFields.length) {
      setAdditionalFields(employeeAdditionalFields);
    }
  }, [employeeAdditionalFields]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setAdditionalFields([...arrayMove(additionalFields, oldIndex, newIndex)]);
  };

  const toggleVisible = (id: number) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, visible: !it.visible };
        }
        return it;
      })
    );
  };

  const addNewField = () => {
    setAdditionalFields([...additionalFields, { label: '', value: '', visible: true }]);
  };

  const handleChangeInput = (id: number, value: string) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, value };
        }
        return it;
      })
    );
  };

  const handleChangeLabel = (id: number, label: string) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, label };
        }
        return it;
      })
    );
  };

  const handleDeleteItem = (id: number) => {
    setAdditionalFields(additionalFields.filter((it, ind) => ind !== id));
  };

  const hasChanges = () => {
    return !isEqualArraysOfObjects(employeeAdditionalFields ?? [], additionalFields);
  };

  return {
    additionalFields,
    addNewField,
    handleChangeInput,
    handleChangeLabel,
    handleDeleteItem,
    onSortEnd,
    toggleVisible,
    hasChanges,
  };
}

export function useTransportInfo() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const isEditing = useSelector((state: RootState) => !!state.transport.chosenTransport);
  const chosenTransport = useSelector((state: RootState) => state.transport.chosenTransportData.attributes);
  const chosenTransportId = useSelector((state: RootState) => state.transport.chosenTransportData.id);
  const needUpdate = useSelector((state: RootState) => state.transport.needUpdate);
  const storeDriverNames = useSelector((state: RootState) => state.transport.includes.driverName);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  useEffect(() => {
    dispatch(fetchTrackers());
  }, [dispatch]);

  const [transportModelId, setTransportModelId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [regNumber, setRegNumber] = useState('');
  const [year, setYear] = useState('');
  const [organizationId, setOrganizationId] = useState(0);
  const [transportTypeId, setTransportTypeId] = useState(0);
  const [fuelTypeId, setFuelTypeId] = useState(0);
  const [driverNameId, setDriverNameId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [transportColorId, setTransportColorId] = useState(0);
  const [bearingCapacity, setBearingOpacity] = useState('');
  const [trackerId, setTrackerId] = useState<number | null>(null);
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    if (chosenTransport) {
      setTransportModelId(chosenTransport.transportModelId);
      setBrandId(chosenTransport.brandId);
      setRegNumber(chosenTransport.regNumber);
      setYear(chosenTransport.year);
      setOrganizationId(chosenTransport.organizationId);
      setTransportTypeId(chosenTransport.transportTypeId);
      setFuelTypeId(chosenTransport.fuelTypeId);
      setDriverNameId(chosenTransport.driverNameId);
      setDepartmentId(chosenTransport.departmentId);
      setTransportColorId(chosenTransport.transportColorId);
      setBearingOpacity(chosenTransport.bearingCapacity);
      setTrackerId(chosenTransport.trackerId);
      setIcon(chosenTransport.icon);
    }
  }, [chosenTransport]);

  const transportIncludes = useSelector((state: RootState) => state.transport.includes);
  const handbooksDataOrganizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const trackers = useSelector((state: RootState) => state.tracker.trackers);

  const handbooks = {
    modelOptions: transportIncludes.transportModel.map(m => ({
      value: m.id,
      label: getTranslateFromLanguageKey(m.name as HandbookTranslatedFieldType, userLanguageKey),
    })),
    brandOptions: transportIncludes.transportBrand.map(b => ({
      value: b.id,
      label: getTranslateFromLanguageKey(b.name as HandbookTranslatedFieldType, userLanguageKey),
    })),
    transportTypeOptions: transportIncludes.transportType.map(t => ({
      value: t.id,
      label: getTranslateFromLanguageKey(t.name as HandbookTranslatedFieldType, userLanguageKey),
    })),
    fuelTypeOptions: transportIncludes.fuelType.map(f => ({
      value: f.id,
      label: getTranslateFromLanguageKey(f.name as HandbookTranslatedFieldType, userLanguageKey),
    })),
    driverOptions: storeDriverNames.map(d => ({
      value: d.id,
      label:
        typeof d.name !== 'string'
          ? getTranslateFromLanguageKey(d.name as HandbookTranslatedFieldType, userLanguageKey)
          : d.name,
    })),
    colorOptions: transportIncludes.transportColor.map(c => ({
      value: c.id,
      label: getTranslateFromLanguageKey(c.name as HandbookTranslatedFieldType, userLanguageKey),
    })),

    orgOptions: handbooksDataOrganizations.map(o => ({
      value: o.id,
      label: getTranslateFromLanguageKey(o.attributes.name, userLanguageKey),
    })),
    departmentOptions:
      handbooksDataOrganizations
        .find(o => o.id === organizationId)
        ?.relationships.departments?.data?.map(s => ({
          value: Number(s.id),
          label: getTranslateFromLanguageKey(s.name, userLanguageKey),
        })) ?? [],

    trackerOptions:
      chosenTransport && chosenTransport.trackerId
        ? trackers
            .filter(t => t.id === chosenTransport.trackerId || !t.attributes.isUsed)
            .map(t => ({ value: t.id, label: t.attributes.trackerNumber }))
        : trackers.filter(t => !t.attributes.isUsed).map(t => ({ value: t.id, label: t.attributes.trackerNumber })),
  };

  const unUsedDrivers: Option[] = storeDriverNames
    .filter(drName => !drName.isUsed)
    .map(d => ({
      value: d.id,
      label:
        typeof d.name !== 'string'
          ? getTranslateFromLanguageKey(d.name as HandbookTranslatedFieldType, userLanguageKey)
          : d.name,
    }));

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!transportModelId) {
      newInvalidFields.push('transportModelId');
    }
    if (!brandId) {
      newInvalidFields.push('brandId');
    }
    if (!regNumber) {
      newInvalidFields.push('regNumber');
    }
    if (!year || !Number(year)) {
      newInvalidFields.push('year');
    }
    if (!organizationId) {
      newInvalidFields.push('organizationId');
    }
    if (!fuelTypeId) {
      newInvalidFields.push('fuelTypeId');
    }

    const driver = storeDriverNames.find(drName => drName.id === driverNameId);

    if (!driverNameId) {
      newInvalidFields.push('driverNameId');
    } else if (chosenTransport.driverNameId !== driverNameId && driver?.isUsed) {
      newInvalidFields.push('driverNameId');
      toast.error(`${driver.name} - ${t('toast.monitoring.add-transport.error.driver-is-busy.text')}`);
    }

    if (!departmentId) {
      newInvalidFields.push('departmentId');
    }
    if (!transportColorId) {
      newInvalidFields.push('transportColorId');
    }
    if (!transportTypeId) {
      newInvalidFields.push('transportTypeId');
    }

    if (year && !Number(year)) {
      toast.error(t('toast.monitoring.add-transport.error.year-no-number.text'));
    }
    if (!handbooks.driverOptions.length) {
      toast.error(t('toast.monitoring.add-transport.error.empty-driver-list.text'));
    }

    return newInvalidFields;
  };

  const handleModelChange = (val: string) => {
    const id = parseInt(val);
    setTransportModelId(id);
  };
  const handleBrandChange = (val: string) => {
    const id = parseInt(val);
    setBrandId(id);
  };
  const handleOrgChange = (val: string) => {
    const id = parseInt(val);
    setOrganizationId(id);
  };
  const handleTransportTypeChange = (val: string) => {
    const id = parseInt(val);
    setTransportTypeId(id);
  };
  const handleFuelTypeChange = (val: string) => {
    const id = parseInt(val);
    setFuelTypeId(id);
  };
  const handleDriverChange = (val: string) => {
    const id = parseInt(val);
    setDriverNameId(id);
  };
  const handleStructureChange = (val: string) => {
    const id = parseInt(val);
    setDepartmentId(id);
  };
  const handleColorChange = (val: string) => {
    const id = parseInt(val);
    setTransportColorId(id);
  };
  const handleTrackerChange = (val: string) => {
    const nextTrackerId = parseInt(val) || null;
    setTrackerId(nextTrackerId);
  };

  const transportValues: TransportAttributes = {
    transportModelId,
    brandId,
    regNumber,
    year,
    organizationId,
    departmentId,
    transportTypeId,
    fuelTypeId,
    driverNameId,
    transportColorId,
    bearingCapacity,
    trackerId,
    icon,
  };

  const mainInfoHasChanges = () => {
    return !isEqual(transportValues, {
      transportModelId: chosenTransport.transportModelId,
      brandId: chosenTransport.brandId,
      regNumber: chosenTransport.regNumber,
      year: chosenTransport.year,
      organizationId: chosenTransport.organizationId,
      departmentId: chosenTransport.departmentId,
      transportTypeId: chosenTransport.transportTypeId,
      fuelTypeId: chosenTransport.fuelTypeId,
      driverNameId: chosenTransport.driverNameId,
      transportColorId: chosenTransport.transportColorId,
      bearingCapacity: chosenTransport.bearingCapacity,
      trackerId: chosenTransport.trackerId,
      icon: chosenTransport.icon,
    });
  };

  const transportHandlers: TransportTabInfoHandlers = {
    setYear,
    setBearingOpacity,
    setRegNumber,
    handleModelChange,
    handleTrackerChange,
    handleTransportTypeChange,
    handleBrandChange,
    handleOrgChange,
    handleFuelTypeChange,
    handleDriverChange,
    handleStructureChange,
    handleColorChange,
    setIcon,
  };

  return {
    transportValues,
    transportHandlers,
    validateRequiredFields,
    invalidFields,
    setInvalidFields,
    chosenTransport,
    chosenTransportId,
    needUpdate,
    handbooks,
    additionalData: {
      unUsedDrivers,
    },
    mainInfoHasChanges,
    isEditing,
  };
}

export function useTransportAdditionalFields() {
  const transportAdditionalFields = useSelector(
    (state: RootState) => state.transport.chosenTransportData.attributes.additionalFields
  );
  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>(
    transportAdditionalFields ? transportAdditionalFields.map(a => ({ ...a })) : []
  );

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setAdditionalFields([...arrayMove(additionalFields, oldIndex, newIndex)]);
  };

  const toggleVisible = (id: number) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, visible: !it.visible };
        }
        return it;
      })
    );
  };

  const addNewField = () => {
    setAdditionalFields([...additionalFields, { label: '', value: '', visible: true }]);
  };

  const handleChangeInput = (id: number, value: string) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, value };
        }
        return it;
      })
    );
  };

  const handleChangeLabel = (id: number, label: string) => {
    setAdditionalFields(
      additionalFields.map((it, index) => {
        if (index === id) {
          return { ...it, label };
        }
        return it;
      })
    );
  };

  const handleDeleteItem = (id: number) => {
    setAdditionalFields(additionalFields.filter((it, ind) => ind !== id));
  };

  const hasChanges = () => {
    return !isEqualArraysOfObjects(additionalFields, transportAdditionalFields);
  };

  return {
    additionalFields,
    addNewField,
    handleChangeInput,
    handleChangeLabel,
    handleDeleteItem,
    onSortEnd,
    toggleVisible,
    hasChanges,
  };
}
