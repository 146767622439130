import { AdditionalOptionsType } from './interface';

export enum RequestHandlersMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const NOT_FOUND_ERROR_STATUS = 404;
export const UNAUTHORIZED_ERROR_STATUS = 401;

export const EXPIRED_AT_DELTA_SECONDS = 15;

export const CHECK_UPDATING_TOKEN_DELAY_MS = 100;

export const defaultAdditionalOptions: AdditionalOptionsType = {
  withToast: true,
  withCheckToken: true,
  hasSuccessResponseFields: false,
  hasBlobResponseData: false,
};
