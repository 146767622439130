import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { InjectIntlContext } from '@comparaonline/react-intl-hooks';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorBoundary } from 'components/errorBoundary/errorBoundary';

import { RootState } from 'reducers';

import messages, { getCurrentLocale, LANGUAGES_TO_TEXT_ENUM, DEFAULT_LANGUAGES } from 'translate';

import { PrivateRoute } from 'utils/routes';

import { REPORTS_MAP_ROUTE } from 'components/map/hooks/useReport';
import { AUTH_LOGIN_ROUTE } from 'components/auth/utils/consts';

import MainPage from './main/Main';
import LoginPage from './auth/Auth';
import ReportsMap from './reportsMap/reportsMap';

import { WebSocketProvider } from '../contexts/websocketContext';

const App: React.FC = () => {
  const { locale } = useSelector((state: RootState) => state.user.userPreferences);
  const currentLocale = getCurrentLocale(locale as LANGUAGES_TO_TEXT_ENUM);

  return (
    <IntlProvider locale={currentLocale} messages={messages[currentLocale]} defaultLocale={DEFAULT_LANGUAGES}>
      <InjectIntlContext>
        <ErrorBoundary>
          <Router>
            <Switch>
              <PrivateRoute exact path={REPORTS_MAP_ROUTE}>
                <ReportsMap />
              </PrivateRoute>
              <PrivateRoute exact path="/main/:id">
                <WebSocketProvider>
                  <MainPage />
                </WebSocketProvider>
              </PrivateRoute>
              <Route exact path={AUTH_LOGIN_ROUTE}>
                <LoginPage />
              </Route>
              <Redirect from="*" to="/main/monitoring" />
            </Switch>
          </Router>
          <ToastContainer />
        </ErrorBoundary>
      </InjectIntlContext>
    </IntlProvider>
  );
};

export default App;
