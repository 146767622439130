import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { Option } from 'components/common/select/select';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { REPORT_TABLE_OBJECT } from 'components/records/utils/consts';
import {
  MultipleSelectGroupOption,
  MultipleSelectGroupTranslate,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { addOneReport, setCreateReportDataValue, setIsReportsHistoryShow } from 'reducers/records';
import { fetchAccountsShort } from 'reducers/accounts';
import { fetchEmployees } from 'reducers/employees';
import { fetchTransports } from 'reducers/transports';
import { fetchTrackers } from 'reducers/trackers';
import { fetchPoi } from 'reducers/poi';
import { fetchHandbookData } from 'reducers/handbooks';
import { HandbookTranslatedFieldType } from 'reducers/handbooks/interface';

import { getCurrentLocale } from 'translate';

import AccessEntity from 'utils/accessEntity';

export default function useRecordsObjects() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const { createReportData, currentTemplateId } = useSelector((state: RootState) => state.records);
  const recordsPermissions = useSelector((state: RootState) => state.user.permissions.records);
  const recordsAccess = useMemo(() => new AccessEntity(recordsPermissions), [recordsPermissions]);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const { isShortsLoading: isAccountShortsLoading, accountsShortList } = useSelector(
    (state: RootState) => state.account
  );
  const { isLoading: isEmployeeLoading, employees } = useSelector((state: RootState) => state.employee);
  const { isDataLoading: isHandbooksLoading, data: handbooksData } = useSelector((state: RootState) => state.handbooks);
  const { isLoading: isTransportLoading, transports } = useSelector((state: RootState) => state.transport);
  const { isLoading: isTrackersLoading, trackers } = useSelector((state: RootState) => state.tracker);
  const { isDataLoading: isPoiLoading, data: poiData } = useSelector((state: RootState) => state.poi);

  const isDataLoading =
    isAccountShortsLoading ||
    isEmployeeLoading ||
    isTransportLoading ||
    isHandbooksLoading ||
    isTrackersLoading ||
    isPoiLoading;

  const periodFromRef = React.createRef();
  const periodToRef = React.createRef();

  const [typeIds, setTypeIds] = useState<number[]>([]);
  const [typesForSelect] = useState(
    Object.keys(REPORT_TABLE_OBJECT).map((key, i) => ({
      value: i + 1,
      label: t(
        `records.report-create.object-types.${REPORT_TABLE_OBJECT[
          key as keyof typeof REPORT_TABLE_OBJECT
        ].toLowerCase()}.text`
      ),
      data: {
        id: i + 1,
        name: REPORT_TABLE_OBJECT[key as keyof typeof REPORT_TABLE_OBJECT],
      },
    }))
  );

  const [objectsForSelect, setObjectsForSelect] = useState<MultipleSelectGroupOption[]>([]);
  const [objectsTranslatesForSelect] = useState<MultipleSelectGroupTranslate[]>(
    Object.keys(REPORT_TABLE_OBJECT).map(key => ({
      name: key,
      translate: t(
        `records.report-create.object-types.${REPORT_TABLE_OBJECT[
          key as keyof typeof REPORT_TABLE_OBJECT
        ].toLowerCase()}.text`
      ),
    }))
  );

  useEffect(() => {
    // users
    dispatch(fetchAccountsShort());
    // employees
    dispatch(fetchEmployees());
    // transports
    dispatch(fetchTransports());
    dispatch(fetchHandbookData([HANDBOOK_KEYS.transportBrands, HANDBOOK_KEYS.transportModels]));
    // trackers
    dispatch(fetchTrackers());
    // poi
    dispatch(fetchPoi());

    return () => {
      dispatch(setCreateReportDataValue({ key: 'fromDate', value: '' }));
      dispatch(setCreateReportDataValue({ key: 'toDate', value: '' }));
      dispatch(setCreateReportDataValue({ key: 'objects', value: [] }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!typeIds.length && createReportData.objects.length) {
      dispatch(setCreateReportDataValue({ key: 'objects', value: [] }));
    }
  }, [dispatch, typeIds, createReportData]);

  useEffect(() => {
    if (currentTemplateId) {
      if (!createReportData.fromDate || !createReportData.toDate) {
        setTypeIds([]);
      }
    } else {
      setTypeIds([]);
    }
  }, [currentTemplateId, createReportData]);

  useEffect(() => {
    if (typeIds.length) {
      const newObjectsForSelect: MultipleSelectGroupOption[] = [];

      typeIds.forEach(typeId => {
        const foundSelectOption = typesForSelect.find(selectOption => selectOption.value === typeId);

        if (foundSelectOption) {
          const type = foundSelectOption.data.name;

          switch (type) {
            // users
            case REPORT_TABLE_OBJECT.User:
              if (accountsShortList.length) {
                newObjectsForSelect.push({
                  name: type,
                  options: accountsShortList.map((acc, i) => ({
                    value: i + 1,
                    label: `${acc.attributes.firstName} ${acc.attributes.secondName} ${acc.attributes.lastName}`,
                    data: { id: acc.id },
                  })),
                });
              }
              break;

            // employees
            case REPORT_TABLE_OBJECT.Employee:
              if (employees.length) {
                newObjectsForSelect.push({
                  name: type,
                  options: employees.map((employee, i) => ({
                    value: i + 1,
                    label: `${employee.attributes.firstName} ${employee.attributes.secondName} ${employee.attributes.lastName}`,
                    data: { id: employee.id },
                  })),
                });
              }
              break;

            // transports
            case REPORT_TABLE_OBJECT.Transport:
              const transportBrands = handbooksData.transportBrands || [];
              const transportModels = handbooksData.transportModels || [];
              if (transports.length && transportBrands.length && transportModels.length) {
                const options: Option[] = [];

                transports.forEach((transport, i) => {
                  const transportBrand = transportBrands.find(trBrand => trBrand.id === transport.attributes.brandId);
                  const transportModel = transportModels.find(
                    trModel => trModel.id === transport.attributes.transportModelId
                  );
                  const brand = transportBrand
                    ? getTranslateFromLanguageKey(
                        transportBrand.attributes.name as HandbookTranslatedFieldType,
                        userLanguageKey
                      )
                    : '';
                  const model = transportModel
                    ? getTranslateFromLanguageKey(
                        transportModel.attributes.name as HandbookTranslatedFieldType,
                        userLanguageKey
                      )
                    : '';

                  options.push({ value: i + 1, label: `${brand} ${model}`, data: { id: i + 1 } });
                });

                newObjectsForSelect.push({
                  name: type,
                  options,
                });
              }
              break;

            // trackers
            case REPORT_TABLE_OBJECT.Tracker:
              if (trackers.length) {
                newObjectsForSelect.push({
                  name: type,
                  options: trackers.map((tracker, i) => ({
                    value: i + 1,
                    label: tracker.attributes.trackerNumber,
                    data: { id: tracker.id },
                  })),
                });
              }
              break;

            // poi
            case REPORT_TABLE_OBJECT.POIPoint:
              if (poiData.length) {
                newObjectsForSelect.push({
                  name: type,
                  options: poiData.map((poi, i) => ({
                    value: i + 1,
                    label: poi.attributes.name,
                    data: { id: poi.id },
                  })),
                });
              }
              break;

            default:
              break;
          }
        }
      });
      setObjectsForSelect(newObjectsForSelect);
    }
  }, [
    typesForSelect,
    typeIds,
    accountsShortList,
    employees,
    transports,
    handbooksData,
    userLanguageKey,
    trackers,
    poiData,
  ]);

  const [showLocalAlert, setShowLocalAlert] = useState(false);

  const handleChangeTypes = (values: ValuesType) => {
    setTypeIds(values.map(val => (val.value as number) || -1).filter(id => id > 0));
  };

  const handleChangeObjects = (values: MultipleSelectGroupOption[]) => {
    dispatch(
      setCreateReportDataValue({
        key: 'objects',
        value: values.map(val => ({
          type: val.name,
          entityIds: val.options.map(option => (option.data?.id as number) ?? -1).filter(id => id > 0),
        })),
      })
    );
  };

  const handleChangeDates = (fromDate: string, toDate: string) => {
    dispatch(setCreateReportDataValue({ key: 'fromDate', value: fromDate }));
    dispatch(setCreateReportDataValue({ key: 'toDate', value: toDate }));
  };

  const handleClickLastRecords = () => {
    // click last records
  };

  const handleClickGenerateReport = () => {
    if (recordsAccess.isAllowCreate() && currentTemplateId) {
      dispatch(addOneReport({ templateId: currentTemplateId, newReport: createReportData }));
    }
    setShowLocalAlert(true);
  };

  const handleClosePopup = () => {
    setShowLocalAlert(false);
  };

  const handleShowReportsHistory = () => {
    dispatch(setIsReportsHistoryShow(true));
  };

  return {
    states: {
      ...createReportData,
      typesForSelect,
      objectsForSelect,
      objectsTranslatesForSelect,
      showLocalAlert,
      typeIds,
      isDataLoading,
    },
    refs: {
      periodFromRef,
      periodToRef,
    },
    handlers: {
      handleChangeTypes,
      handleChangeObjects,
      handleChangeDates,
      handleClickLastRecords,
      handleClickGenerateReport,
      handleClosePopup,
      handleShowReportsHistory,
    },
  };
}
