export function saveToStorage(keyName: string, data: unknown) {
  try {
    if (window.localStorage) {
      window.localStorage.setItem(keyName, JSON.stringify(data));
    } else if (window.sessionStorage) {
      window.sessionStorage.setItem(keyName, JSON.stringify(data));
    } else {
      throw new Error("Error: localStorage and sessionStorage doesn't exist");
    }
  } catch (err) {
    console.error(err);
  }
}

export function getFromStorage(keyName: string) {
  try {
    let dataJSON: string | null = null;

    if (window.localStorage) {
      dataJSON = localStorage.getItem(keyName);
    } else if (window.sessionStorage) {
      dataJSON = sessionStorage.getItem(keyName);
    }
    if (dataJSON) {
      return JSON.parse(dataJSON);
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// TODO: пока так чтобы на показе ничего не cломать, после показа заменить предыдущую версию на эту и проверить что перестанет работать
export function getFromStorageNewVersion(keyName: string) {
  try {
    let dataJSON: string | null = null;

    if (window.localStorage) {
      dataJSON = localStorage.getItem(keyName);
    } else if (window.sessionStorage) {
      dataJSON = sessionStorage.getItem(keyName);
    } else {
      throw new Error("Error: localStorage and sessionStorage doesn't exist");
    }
    if (dataJSON) {
      return JSON.parse(dataJSON);
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function removeFromStorage(keyName: string) {
  try {
    if (window.localStorage) {
      window.localStorage.removeItem(keyName);
    } else if (window.sessionStorage) {
      window.sessionStorage.removeItem(keyName);
    } else {
      throw new Error("Error: localStorage and sessionStorage doesn't exist");
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}
