import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Switch from 'components/common/switch/switch';
import { RadioButton } from 'components/common/radioButton/radioButton';
import Button from 'components/common/button/button';
import Input from 'components/common/input/input';
import { DatesStatus } from 'components/notifications/utils/consts';
import { TimeProps } from 'components/notifications/utils/types';
import { SpeedButton } from './components/speedButton/speedButton';
import { IntervalRangeTimer } from './components/intervalRangeTimer/intervalRangeTimer';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../tabsModal.module.scss';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div
      className={cx(styles.datePicker, { [styles.intervalTime]: props.intervalTimeStyle })}
      onClick={props.onClick}
      ref={_ref}
    >
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        isRequired={props.isRequired}
        isValueError={props.isValueError}
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

export const TimeTab = ({ states, handlers, refs, invalidFields }: TimeProps) => {
  const t = useFormatMessage();

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalWrapperContent}>
        <div className={cx(styles.modalCol, styles.modalColTimeLeft)}>
          <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
            <div className={styles.modalCheckbox} onClick={handlers.handleCheckActionPeriod}>
              <Checkbox checked={states.isActionPeriodOn} />
              <span>{t('notifications.card.tabs.time.field.period-activity.text')}</span>
            </div>
          </div>
          {states.isActionPeriodOn && (
            <>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <DatePicker
                  dateFormat="dd.MM.yyyy, HH:mm"
                  selected={states.periodFrom ? new Date(states.periodFrom) : null}
                  onChange={(date: Date) => handlers.handleChangePeriodFrom(date)}
                  className={styles.tabInfoDatePicker}
                  placeholderText={'01.01.2020 00:00'}
                  customInput={
                    <DatePickerCustomInput
                      ref={refs.startPeriodRef}
                      label={t('notifications.card.tabs.time.field.period-activity-from.label')}
                      isValueError={invalidFields.includes('periodFromTime')}
                      isRequired={true}
                    />
                  }
                  openToDate={states.periodFrom ? new Date(states.periodFrom) : new Date()}
                  locale={getCurrentLocaleForDatePicker(locale)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={t('notifications.card.tabs.time.field.period-activity-from.time.header.text')}
                  disabled={false}
                  maxDate={states.periodTo ? new Date(states.periodTo) : null}
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={
                    !differenceInCalendarDays(new Date(states.periodFrom), new Date(states.periodTo))
                      ? setHours(
                          setMinutes(new Date(), getMinutes(new Date(states.periodTo))),
                          getHours(new Date(states.periodTo))
                        )
                      : setHours(setMinutes(new Date(), 59), 23)
                  }
                />
              </div>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <DatePicker
                  dateFormat="dd.MM.yyyy, HH:mm"
                  selected={states.periodTo ? new Date(states.periodTo) : null}
                  onChange={(date: Date) => handlers.handleChangePeriodTo(date)}
                  className={styles.tabInfoDatePicker}
                  placeholderText={'30.11.2020 23:59'}
                  customInput={
                    <DatePickerCustomInput
                      ref={refs.endPeriodRef}
                      label={t('notifications.card.tabs.time.field.period-activity-to.label')}
                      isValueError={invalidFields.includes('periodToTime')}
                      isRequired={true}
                    />
                  }
                  openToDate={states.periodTo ? new Date(states.periodTo) : new Date()}
                  locale={getCurrentLocaleForDatePicker(locale)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={t('notifications.card.tabs.time.field.period-activity-to.time.header.text')}
                  disabled={false}
                  minDate={states.periodFrom ? new Date(states.periodFrom) : null}
                  minTime={
                    !differenceInCalendarDays(new Date(states.periodFrom), new Date(states.periodTo))
                      ? setHours(
                          setMinutes(new Date(), getMinutes(new Date(states.periodFrom))),
                          getHours(new Date(states.periodFrom))
                        )
                      : setHours(setMinutes(new Date(), 0), 0)
                  }
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
              </div>
              <div className={cx(styles.modalRow, styles.modalRowFullWidth)}>
                <Input
                  label={t('notifications.card.tabs.time.field.max-triggering.label')}
                  placeholder={t('notifications.card.tabs.time.field.max-triggering.time.header.text')}
                  isValueError={invalidFields.includes('maxOperationsTime')}
                  customStyle={styles.inputWrap}
                  value={states.activationCountMax < 0 ? '' : String(states.activationCountMax)}
                  handleInputChange={value => handlers.handleChangeMaxOperationsValue(value)}
                />
              </div>
              <div
                className={cx(styles.modalRow, styles.modalRowCursorPointer)}
                onClick={handlers.handleChangeIntervalOn}
              >
                <Switch checked={states.isIntervalsOn} />
                <span>{t('notifications.card.tabs.time.field.interval-triggering.text')}</span>
              </div>
              {states.isIntervalsOn &&
                states.intervals.map((interval, i) => (
                  <IntervalRangeTimer
                    key={`interval-${i}`}
                    numInterval={i + 1}
                    fromStr={interval[0]}
                    toStr={interval[1]}
                    handlers={{
                      handleChangeStartTimeInterval: (i: number, date: string) =>
                        handlers.handleChangeStartTimeInterval(i, date),
                      handleChangeEndTimeInterval: (i: number, date: string) =>
                        handlers.handleChangeEndTimeInterval(i, date),
                    }}
                  />
                ))}
              <div className={cx(styles.modalRow, styles.btns, styles.btnsNoMargin)}>
                <Button
                  white
                  text={t('notifications.card.tabs.time.field.interval.add-btn.label')}
                  onClick={handlers.handleClickAddInterval}
                  disabled={!states.isIntervalsOn}
                />
              </div>
            </>
          )}
        </div>
        <div className={cx(styles.modalCol, styles.modalColTimeRight)}>
          {states.isActionPeriodOn && (
            <>
              <div
                className={cx(styles.modalRow, styles.modalRowCursorPointer)}
                onClick={handlers.handleChangePeriodLimitsOn}
              >
                <Switch checked={states.isPeriodLimitsOn} />
                <span>{t('notifications.card.tabs.time.field.limit-periods.text')}</span>
              </div>
              {states.isPeriodLimitsOn && (
                <>
                  <div className={styles.modalRow}>
                    <div className={styles.title}>{t('notifications.card.tabs.time.field.months.header.text')}</div>
                  </div>
                  <div className={styles.modalRow}>
                    <ul className={styles.selectableList}>
                      {states.datesSelected.months.map(monthValue => (
                        <li key={`selectableItem-month-${monthValue.text}`} className={styles.selectableItem}>
                          <SpeedButton
                            customStyle={styles.monthStyle}
                            text={t(monthValue.text)}
                            downed={monthValue.status === DatesStatus.selected}
                            disabled={monthValue.status === DatesStatus.disabled}
                            onClick={() => handlers.handleSelectMonths(monthValue.id)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles.radioGroup}>
                    <div className={styles.radioButtonRow} onClick={() => handlers.handleCheckChangeDaysOfMonth(false)}>
                      <RadioButton name="daysLimit" checked={states.isDaysOfWeekLimitOn} />
                      <div className={styles.title}>
                        {t('notifications.card.tabs.time.field.days-week.header.text')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.modalRow}>
                    <ul className={cx(styles.selectableList, styles.selectableListAutoHeight)}>
                      {states.datesSelected.daysOfWeek.map(dayValue => (
                        <li key={`selectableItem-dayOfWeek-${dayValue.text}`} className={styles.selectableItem}>
                          <SpeedButton
                            text={t(dayValue.text)}
                            disabled={states.isDaysOfMonthLimitOn}
                            downed={dayValue.status === DatesStatus.selected}
                            onClick={() => handlers.handleSelectDaysOfWeek(dayValue.id)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles.radioGroup}>
                    <div className={styles.radioButtonRow} onClick={() => handlers.handleCheckChangeDaysOfMonth(true)}>
                      <RadioButton name="daysLimit" checked={states.isDaysOfMonthLimitOn} />
                      <div className={styles.title}>
                        {t('notifications.card.tabs.time.field.days-months.header.text')}
                      </div>
                    </div>
                  </div>
                  <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
                    <div
                      className={styles.modalCheckbox}
                      onClick={() => states.isDaysOfMonthLimitOn && handlers.handleCheckEvenDays()}
                    >
                      <Checkbox
                        checked={states.datesSelected.evenDays.every(day => day.status === DatesStatus.selected)}
                        isDisabled={states.isDaysOfWeekLimitOn}
                        isIndeterminate={
                          states.datesSelected.evenDays.some(day => day.status === DatesStatus.no_selected) &&
                          states.datesSelected.evenDays.some(day => day.status === DatesStatus.selected)
                        }
                      />
                      <span>{t('notifications.card.tabs.time.field.days-months.odd-days.text')}</span>
                    </div>
                  </div>
                  <div className={styles.modalRow}>
                    <ul
                      className={cx(
                        styles.selectableList,
                        styles.selectableListNoWrap,
                        styles.selectableListAutoHeight
                      )}
                    >
                      {states.datesSelected.evenDays.map(dayValue => (
                        <li key={`selectableItem-evenDays-${dayValue.text}`} className={styles.selectableItem}>
                          <SpeedButton
                            rounded
                            text={Number(dayValue.text) < 10 ? '0' + dayValue.text : dayValue.text}
                            disabled={states.isDaysOfWeekLimitOn}
                            downed={dayValue.status === DatesStatus.selected}
                            onClick={() => handlers.handleSelectEvenDays(dayValue.id)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
                    <div
                      className={styles.modalCheckbox}
                      onClick={() => states.isDaysOfMonthLimitOn && handlers.handleCheckOddDays()}
                    >
                      <Checkbox
                        checked={states.datesSelected.oddDays.every(day => day.status === DatesStatus.selected)}
                        isDisabled={states.isDaysOfWeekLimitOn}
                        isIndeterminate={
                          states.datesSelected.oddDays.some(day => day.status === DatesStatus.no_selected) &&
                          states.datesSelected.oddDays.some(day => day.status === DatesStatus.selected)
                        }
                      />
                      <span>{t('notifications.card.tabs.time.field.days-months.even-days.text')}</span>
                    </div>
                  </div>
                  <div className={styles.modalRow}>
                    <ul
                      className={cx(
                        styles.selectableList,
                        styles.selectableListNoWrap,
                        styles.selectableListAutoHeight
                      )}
                    >
                      {states.datesSelected.oddDays.map(dayValue => (
                        <li key={`selectableItem-oddDays-${dayValue.text}`} className={styles.selectableItem}>
                          <SpeedButton
                            rounded
                            text={Number(dayValue.text) < 10 ? '0' + dayValue.text : dayValue.text}
                            disabled={states.isDaysOfWeekLimitOn}
                            downed={dayValue.status === DatesStatus.selected}
                            onClick={() => handlers.handleSelectOddDays(dayValue.id)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
