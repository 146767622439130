export default {
  en: {
    'input-icon-file.label': 'Icon',
    'input-icon-file.error.read.text': 'File read error',
    'input-icon-file.error.size.text': 'File is larger than 2 Mb',
    'input-icon-file.error.no-icon.text': 'Available file formats: png/jpg/svg/ico',
    'input-icon-file.error.bad-icon-size.text': 'Icon sizes is more than 128х128 pixels',
  },
  ru: {
    'input-icon-file.label': 'Иконка',
    'input-icon-file.error.read.text': 'Ошибка чтения файла',
    'input-icon-file.error.size.text': 'Файл больше 2 Мб',
    'input-icon-file.error.no-icon.text': 'Доступные форматы файлов: png/jpg/svg/ico',
    'input-icon-file.error.bad-icon-size.text': 'Размеры иконки больше 128х128 пикселей',
  },
  zh: {
    'input-icon-file.label': '图标',
    'input-icon-file.error.read.text': '文件读取错误',
    'input-icon-file.error.size.text': '超过2兆字节的文件',
    'input-icon-file.error.no-icon.text': '可用的文件格式：png/jpg/svg/ico',
    'input-icon-file.error.bad-icon-size.text': '图标大小超过128х128像素',
  },
  ar: {
    'input-icon-file.label': 'أيقونة',
    'input-icon-file.error.read.text': 'خطأ في قراءة الملف',
    'input-icon-file.error.size.text': 'حجم الملف أكبر من 2 ميغا بايت',
    'input-icon-file.error.no-icon.text': 'تنسيقات الملفات المتاحة: png / jpg / svg / ico',
    'input-icon-file.error.bad-icon-size.text': 'الرمز أكبر من 128 × 128 بكسل',
  },
  de: {
    'input-icon-file.label': 'Symbol',
    'input-icon-file.error.read.text': 'Fehler beim Lesen der Datei',
    'input-icon-file.error.size.text': 'Datei größer als 2 MB',
    'input-icon-file.error.no-icon.text': 'Verfügbare Dateiformate: png/jpg/svg/ico',
    'input-icon-file.error.bad-icon-size.text': 'Das Symbol ist größer als 128 x 128 Pixel',
  },
};
