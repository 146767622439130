export default {
  en: {
    'map-popup.videocamera.settings.header.label': 'Video camera card',
    'map-popup.videocamera.settings.field.name.label': 'Camera name',
    'map-popup.videocamera.settings.field.name.placeholder': 'Enter name',
    'map-popup.videocamera.settings.field.description.label': 'Description of the camera',
    'map-popup.videocamera.settings.field.description.placeholder': 'Enter a description',
    'map-popup.videocamera.settings.field.model.label': 'Model of the camera',
    'map-popup.videocamera.settings.field.model.placeholder': 'Select from a list',
    'map-popup.videocamera.settings.field.link.label': 'Link to video stream',
    'map-popup.videocamera.settings.field.link.placeholder': 'Insert link',
    'map-popup.videocamera.settings.field.coords.label': 'Location coordinates',
    'map-popup.videocamera.settings.field.coords.placeholder': 'Enter or mark on the map',
    'map-popup.videocamera.settings.field.angle.label': 'Camera angle, degrees',
    'map-popup.videocamera.settings.field.angle.placeholder': 'Enter camera angle',
    'map-popup.videocamera.settings.field.viewing-distance.label': 'Camera view range, meters',
    'map-popup.videocamera.settings.field.viewing-distance.placeholder': 'Enter camera view range',
    'map-popup.videocamera.settings.field.tracking-direction.label': 'Tracking direction, degrees',
    'map-popup.videocamera.settings.field.tracking-direction.placeholder': 'Specify tracking direction',
  },
  ru: {
    'map-popup.videocamera.settings.header.label': 'Карточка видеокамеры',
    'map-popup.videocamera.settings.field.name.label': 'Название камеры',
    'map-popup.videocamera.settings.field.name.placeholder': 'Введите название',
    'map-popup.videocamera.settings.field.description.label': 'Описание камеры',
    'map-popup.videocamera.settings.field.description.placeholder': 'Введите описание',
    'map-popup.videocamera.settings.field.model.label': 'Модель камеры',
    'map-popup.videocamera.settings.field.model.placeholder': 'Выберите из списка',
    'map-popup.videocamera.settings.field.link.label': 'Ссылка на видеопоток',
    'map-popup.videocamera.settings.field.link.placeholder': 'Вставьте ссылку',
    'map-popup.videocamera.settings.field.coords.label': 'Координаты местоположения',
    'map-popup.videocamera.settings.field.coords.placeholder': 'Введите или отметьте на карте',
    'map-popup.videocamera.settings.field.angle.label': 'Угол съемки, градусы',
    'map-popup.videocamera.settings.field.angle.placeholder': 'Введите угол съемки камеры',
    'map-popup.videocamera.settings.field.viewing-distance.label': 'Дальность обзора, метры',
    'map-popup.videocamera.settings.field.viewing-distance.placeholder': 'Введите дальность обзора',
    'map-popup.videocamera.settings.field.tracking-direction.label': 'Направление отслеживания, градусы',
    'map-popup.videocamera.settings.field.tracking-direction.placeholder': 'Введите направление отслеживания',
  },
  zh: {
    'map-popup.videocamera.settings.header.label': '相机地图',
    'map-popup.videocamera.settings.field.name.label': '名称',
    'map-popup.videocamera.settings.field.name.placeholder': '输入名称',
    'map-popup.videocamera.settings.field.description.label': '相机描述',
    'map-popup.videocamera.settings.field.description.placeholder': '输入描述',
    'map-popup.videocamera.settings.field.model.label': '模型',
    'map-popup.videocamera.settings.field.model.placeholder': '从列表中选择',
    'map-popup.videocamera.settings.field.link.label': '链接到数据流',
    'map-popup.videocamera.settings.field.link.placeholder': '输入链接',
    'map-popup.videocamera.settings.field.coords.label': '位置坐标',
    'map-popup.videocamera.settings.field.coords.placeholder': '在地图上输入或标记',
    'map-popup.videocamera.settings.field.angle.label': '拍摄角度，度数',
    'map-popup.videocamera.settings.field.angle.placeholder': '输入相机拍摄角度',
    'map-popup.videocamera.settings.field.viewing-distance.label': '摄像机视野范围，米',
    'map-popup.videocamera.settings.field.viewing-distance.placeholder': '输入相机视野范围',
    'map-popup.videocamera.settings.field.tracking-direction.label': '跟踪方向，度数',
    'map-popup.videocamera.settings.field.tracking-direction.placeholder': '输入跟踪方向',
  },
  ar: {
    'map-popup.videocamera.settings.header.label': 'بطاقة كاميرا الفيديو',
    'map-popup.videocamera.settings.field.name.label': 'اسم كاميرا الفيديو',
    'map-popup.videocamera.settings.field.name.placeholder': 'أكتب اسمًا',
    'map-popup.videocamera.settings.field.description.label': 'وصف الكاميرا',
    'map-popup.videocamera.settings.field.description.placeholder': 'أكتب وصفًا',
    'map-popup.videocamera.settings.field.model.label': 'موديل الكاميرا',
    'map-popup.videocamera.settings.field.model.placeholder': 'اختر من القائمة',
    'map-popup.videocamera.settings.field.link.label': 'رابط تدفق الفيديو',
    'map-popup.videocamera.settings.field.link.placeholder': 'أدخل الرابط',
    'map-popup.videocamera.settings.field.coords.label': 'إحداثيات الموقع',
    'map-popup.videocamera.settings.field.coords.placeholder': 'أكتب أو أشر على الخريطة',
    'map-popup.videocamera.settings.field.angle.label': 'زاوية التسجيل، درجات',
    'map-popup.videocamera.settings.field.angle.placeholder': 'أكتب زاوية تسجيل الكاميرا',
    'map-popup.videocamera.settings.field.viewing-distance.label': 'بعد الرؤية، أمتار',
    'map-popup.videocamera.settings.field.viewing-distance.placeholder': 'أكتب بعد الرؤية',
    'map-popup.videocamera.settings.field.tracking-direction.label': 'اتجاه التتبع، درجات',
    'map-popup.videocamera.settings.field.tracking-direction.placeholder': 'أكتب اتجاه التتبع',
  },
  de: {
    'map-popup.videocamera.settings.header.label': 'Camcorder-Karte',
    'map-popup.videocamera.settings.field.name.label': 'Kameraname',
    'map-popup.videocamera.settings.field.name.placeholder': 'Geben Sie den Titel ein',
    'map-popup.videocamera.settings.field.description.label': 'Kamerabeschreibung',
    'map-popup.videocamera.settings.field.description.placeholder': 'Geben Sie eine Beschreibung ein',
    'map-popup.videocamera.settings.field.model.label': 'Kameramodell',
    'map-popup.videocamera.settings.field.model.placeholder': 'Aus der Liste auswählen',
    'map-popup.videocamera.settings.field.link.label': 'Link zum Videostream',
    'map-popup.videocamera.settings.field.link.placeholder': 'Fügen Sie den Link ein',
    'map-popup.videocamera.settings.field.coords.label': 'Standortkoordinaten',
    'map-popup.videocamera.settings.field.coords.placeholder': 'Auf der Karte eingeben oder markieren',
    'map-popup.videocamera.settings.field.angle.label': 'Aufnahmewinkel, Grad',
    'map-popup.videocamera.settings.field.angle.placeholder': 'Geben Sie den Kamerawinkel ein',
    'map-popup.videocamera.settings.field.viewing-distance.label': 'Sichtweite, Meter',
    'map-popup.videocamera.settings.field.viewing-distance.placeholder': 'Geben Sie den Sichtbereich ein',
    'map-popup.videocamera.settings.field.tracking-direction.label': 'Tracking-Richtung, Grad',
    'map-popup.videocamera.settings.field.tracking-direction.placeholder': 'Tracking-Richtung eingeben',
  },
};
