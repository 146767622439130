import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers';

import { getCurrentTimeWithOffset } from 'utils/formatTime';

import styles from './times.module.scss';

const INTERVAL_TIMER = 1000;

export const Times = (): React.ReactElement => {
  const timezone = useSelector((state: RootState) => state.user.userPreferences.timezone);

  const [time, setTime] = useState(() => getCurrentTimeWithOffset(timezone));

  useEffect(() => {
    const timerInterval = setInterval(() => setTime(getCurrentTimeWithOffset(timezone)), INTERVAL_TIMER);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timezone]);

  return <div className={styles.timeWrapper}>{time}</div>;
};
