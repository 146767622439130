import { reqHandlers } from 'utils/api';
import { CreateEmployeeAttributes, UpdateEmployeeAttributes, EmployeeWorkObjectReqParams } from './interface';

export function getEmployees(string: string) {
  return reqHandlers.get(`/api/employees?string=${string}`);
}

export function createEmployee(data: CreateEmployeeAttributes) {
  return reqHandlers.post('/api/employees', data);
}

export function createWorkObject({ employeeId, workObjectId }: EmployeeWorkObjectReqParams) {
  return reqHandlers.post(`/api/employees/${employeeId}/workObjects`, { workObjectId });
}

export function deleteWorkObject({ employeeId, workObjectId }: EmployeeWorkObjectReqParams) {
  return reqHandlers.delete(`/api/employees/${employeeId}/workObjects/${workObjectId}`);
}

export function deleteEmployee(id: string) {
  return reqHandlers.delete(`/api/employees/${id}`);
}

export function getOneEmployee(id: string) {
  return reqHandlers.get(`/api/employees/${id}`);
}

export function getEmployeeIncludes() {
  return reqHandlers.get('/api/employees/includesInfo');
}

export function putEmployee(id: string, data: UpdateEmployeeAttributes) {
  return reqHandlers.put(`/api/employees/${id}`, data);
}
