import { GeozoneTypeOf, GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { NOTIFICATION_TYPES_ENUM } from 'components/notifications/utils/consts';

import { HandbookTranslatedFieldType } from 'reducers/handbooks/interface';

import { NewJSONApi } from 'utils/interfaces';

// Events types

export enum StatisticEventTypes {
  // auth
  LoginAuthEvent = 'LoginAuthEvent',

  // employee
  CreateEmployeeEvent = 'CreateEmployeeEvent',
  DeleteEmployeeEvent = 'DeleteEmployeeEvent',
  UpdateEmployeeEvent = 'UpdateEmployeeEvent',
  AssignFieldEmployeeEvent = 'AssignFieldEmployeeEvent',
  UpdateAssignedFieldsEmployeeEvent = 'UpdateAssignedFieldsEmployeeEvent',
  AssignWorkObjectEmployeeEvent = 'AssignWorkObjectEmployeeEvent',
  UnAssignWorkObjectEmployeeEvent = 'UnAssignWorkObjectEmployeeEvent',
  AssignTrackerEmployeeEvent = 'AssignTrackerEmployeeEvent',
  UnAssignTrackerEmployeeEvent = 'UnAssignTrackerEmployeeEvent',

  // geozones
  CreateGeozoneEvent = 'CreateGeozoneEvent',
  DeleteGeozoneEvent = 'DeleteGeozoneEvent',
  UpdateGeozoneEvent = 'UpdateGeozoneEvent',
  CreateGeozoneGroupEvent = 'CreateGeozoneGroupEvent',
  DeleteGeozoneGroupEvent = 'DeleteGeozoneGroupEvent',
  UpdateGeozoneGroupEvent = 'UpdateGeozoneGroupEvent',
  AssignGeozoneToGeozoneGroupEvent = 'AssignGeozoneToGeozoneGroupEvent',
  UnAssignGeozoneFromGeozoneGroupEvent = 'UnAssignGeozoneFromGeozoneGroupEvent',

  // handbooks
  CreateHandbookEvent = 'CreateHandbookEvent',
  UpdateHandbookEvent = 'UpdateHandbookEvent',

  // notifications
  AddedNotificationEvent = 'AddedNotificationEvent',

  // poi
  CreatePoiEvent = 'CreatePoiEvent',
  DeletePoiEvent = 'DeletePoiEvent',
  UpdatePoiEvent = 'UpdatePoiEvent',

  // report
  CreateReportTemplateEvent = 'CreateReportTemplateEvent',
  DeleteReportTemplateEvent = 'DeleteReportTemplateEvent',
  UpdateReportTemplateEvent = 'UpdateReportTemplateEvent',
  CreateReportEvent = 'CreateReportEvent',

  // roles
  CreateRolePermsEvent = 'CreateRolePermsEvent',
  DeleteRolePermsEvent = 'DeleteRolePermsEvent',
  UpdateRolePermsEvent = 'UpdateRolePermsEvent',

  // trackers
  CreateTrackerEvent = 'CreateTrackerEvent',
  DeleteTrackerEvent = 'DeleteTrackerEvent',
  UpdateTrackerEvent = 'UpdateTrackerEvent',

  // transport
  CreateTransportEvent = 'CreateTransportEvent',
  DeleteTransportEvent = 'DeleteTransportEvent',
  UpdateTransportEvent = 'UpdateTransportEvent',
  AssignFieldTransportEvent = 'AssignFieldTransportEvent',
  UpdateAssignedFieldsTransportEvent = 'UpdateAssignedFieldsTransportEvent',
  AssignTrackerTransportEvent = 'AssignTrackerTransportEvent',
  UnAssignTrackerTransportEvent = 'UnAssignTrackerTransportEvent',

  // user
  CreateUserDataEvent = 'CreateUserDataEvent',
  DeleteUserDataEvent = 'DeleteUserDataEvent',
  UpdateUserDataEvent = 'UpdateUserDataEvent',
  AssignRoleUserDataEvent = 'AssignRoleUserDataEvent',
  UnAssignRoleUserDataEvent = 'UnAssignRoleUserDataEvent',
}

// Events info
export type OldNewValues = {
  __old: string | null;
  __new: string | null;
};

type UpdateEventInfo = {
  [key: string]: OldNewValues;
};

export type UpdatedAssignedFields = string | OldNewValues;

// auth
export type LoginAuthEventInfo = {
  userId: number;
  userLogin: string;
  userName: string;
  ip: string;
};

// employee
export type CreateEmployeeEventInfo = {
  firstName: string;
  secondName: string;
  lastName: string;
  simNumber: string;
};
export type DeleteEmployeeEventInfo = CreateEmployeeEventInfo;
export type UpdateEmployeeEventInfo = UpdateEventInfo;
export type AssignFieldEmployeeEventInfo = CreateEmployeeEventInfo & {
  fieldName: string;
  fieldValue: string;
};
export type UpdateAssignedFieldsEmployeeEventInfo = CreateEmployeeEventInfo & {
  [key: string]: {
    label?: UpdatedAssignedFields;
    value?: UpdatedAssignedFields;
    visible?: UpdatedAssignedFields;
  };
};
export type AssignWorkObjectEmployeeEventInfo = CreateEmployeeEventInfo & {
  workObjectName: HandbookTranslatedFieldType;
};
export type UnAssignWorkObjectEmployeeEventInfo = AssignWorkObjectEmployeeEventInfo;
export type AssignTrackerEmployeeEventInfo = CreateEmployeeEventInfo & {
  trackerNumber: string;
  trackerModel: string;
};
export type UnAssignTrackerEmployeeEventInfo = AssignTrackerEmployeeEventInfo;

// geozones
export type CreateGeozoneEventInfo = {
  name: string;
  geometryType: GEOZONE_GEOMETRIC_TYPES;
  geometricType?: GEOZONE_GEOMETRIC_TYPES;
  geozoneType: GeozoneTypeOf;
};
export type DeleteGeozoneEventInfo = CreateGeozoneEventInfo;
export type UpdateGeozoneEventInfo = UpdateEventInfo;
export type CreateGeozoneGroupEventInfo = {
  name: string;
  parentGroupName: string;
};
export type DeleteGeozoneGroupEventInfo = CreateGeozoneGroupEventInfo;
export type UpdateGeozoneGroupEventInfo = UpdateEventInfo;
export type AssignGeozoneToGeozoneGroupEventInfo = {
  name: string;
  groupName: string;
};
export type UnAssignGeozoneFromGeozoneGroupEventInfo = AssignGeozoneToGeozoneGroupEventInfo;

// handbooks
export type CreateHandbookEventInfo = {
  type: HANDBOOK_KEYS;
  name: HandbookTranslatedFieldType;
};
export type UpdateHandbookEventInfo = UpdateEventInfo & {
  type: HANDBOOK_KEYS;
};

// notifications
export type AddedNotificationEventInfo = {
  type: NOTIFICATION_TYPES_ENUM;
  text: string;
};

// poi
export type CreatePoiEventInfo = {
  poiName: string;
};
export type DeletePoiEventInfo = CreatePoiEventInfo;
export type UpdatePoiEventInfo = UpdateEventInfo;

// report
export type CreateReportTemplateEventInfo = {
  templateName: string;
};
export type DeleteReportTemplateEventInfo = CreateReportTemplateEventInfo;
export type UpdateReportTemplateEventInfo = CreateReportTemplateEventInfo;
export type CreateReportEventInfo = {
  reportId: number;
  templateName: string;
};

// roles
export type CreateRolePermsEventInfo = {
  roleName: string;
};
export type DeleteRolePermsEventInfo = CreateRolePermsEventInfo;
export type UpdateRolePermsEventInfo = UpdateEventInfo & CreateRolePermsEventInfo;

// trackers
export type CreateTrackerEventInfo = {
  trackerNumber: string;
  trackerModelName: string;
};
export type DeleteTrackerEventInfo = CreateTrackerEventInfo;
export type UpdateTrackerEventInfo = UpdateEventInfo;

// transport
export type CreateTransportEventInfo = {
  brandName: HandbookTranslatedFieldType;
  modelName: HandbookTranslatedFieldType;
  regNumber: string;
};
export type DeleteTransportEventInfo = CreateTransportEventInfo;
export type UpdateTransportEventInfo = UpdateEventInfo;
export type AssignFieldTransportEventInfo = CreateTransportEventInfo & {
  fieldName: string;
  fieldValue: string;
};
export type UpdateAssignedFieldsTransportEventInfo = CreateTransportEventInfo & {
  [key: string]: {
    label?: UpdatedAssignedFields;
    value?: UpdatedAssignedFields;
    visible?: UpdatedAssignedFields;
  };
};
export type AssignTrackerTransportEventInfo = CreateTransportEventInfo & {
  trackerNumber: string;
  trackerModel: string;
};
export type UnAssignTrackerTransportEventInfo = AssignTrackerTransportEventInfo;

// user
export type CreateUserDataEventInfo = {
  login: string;
  firstName: string;
  secondName: string;
  lastName: string;
};
export type DeleteUserDataEventInfo = CreateUserDataEventInfo;
export type UpdateUserDataEventInfo = UpdateEventInfo & {
  login: string;
};
export type AssignRoleUserDataEventInfo = {
  login: string;
  roleName: string;
};
export type UnAssignRoleUserDataEventInfo = AssignRoleUserDataEventInfo;

type StatisticEventInfo =
  // auth
  | LoginAuthEventInfo
  // employee
  | CreateEmployeeEventInfo
  | DeleteEmployeeEventInfo
  | UpdateEmployeeEventInfo
  | AssignFieldEmployeeEventInfo
  | UpdateAssignedFieldsEmployeeEventInfo
  | AssignWorkObjectEmployeeEventInfo
  | UnAssignWorkObjectEmployeeEventInfo
  | AssignTrackerEmployeeEventInfo
  | UnAssignTrackerEmployeeEventInfo
  // geozones
  | CreateGeozoneEventInfo
  | DeleteGeozoneEventInfo
  | UpdateGeozoneEventInfo
  | CreateGeozoneGroupEventInfo
  | DeleteGeozoneGroupEventInfo
  | UpdateGeozoneGroupEventInfo
  | AssignGeozoneToGeozoneGroupEventInfo
  | UnAssignGeozoneFromGeozoneGroupEventInfo
  // handbooks
  | CreateHandbookEventInfo
  | UpdateHandbookEventInfo
  // notifications
  | AddedNotificationEventInfo
  // poi
  | CreatePoiEventInfo
  | DeletePoiEventInfo
  | UpdatePoiEventInfo
  // report
  | CreateReportTemplateEventInfo
  | DeleteReportTemplateEventInfo
  | UpdateReportTemplateEventInfo
  | CreateReportEventInfo
  // roles
  | CreateRolePermsEventInfo
  | DeleteRolePermsEventInfo
  | UpdateRolePermsEventInfo
  // trackers
  | CreateTrackerEventInfo
  | DeleteTrackerEventInfo
  | UpdateTrackerEventInfo
  // transport
  | CreateTransportEventInfo
  | DeleteTransportEventInfo
  | UpdateTransportEventInfo
  | AssignFieldTransportEventInfo
  | UpdateAssignedFieldsTransportEventInfo
  | AssignTrackerTransportEventInfo
  | UnAssignTrackerTransportEventInfo
  // user
  | CreateUserDataEventInfo
  | DeleteUserDataEventInfo
  | UpdateUserDataEventInfo
  | AssignRoleUserDataEventInfo
  | UnAssignRoleUserDataEventInfo;

interface StatisticAttributes {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  eventType: StatisticEventTypes;
  eventInfo: StatisticEventInfo;
  employeeId: number | null;
  userId: number;
}

export interface Statistic {
  type: string;
  id: string;
  attributes: StatisticAttributes;
}

export interface StatisticIcon {
  accompanyingText: string;
  base64: string;
}

export interface StatisticTableRow {
  date: string;
  time: string;
  eventType: string;
  eventDescription: string;
  base64Icons: {
    old: StatisticIcon;
    new: StatisticIcon;
  } | null;
}

export type StatisticFilter = {
  offset: number;
  limit: number;
  sortBy: string;
  sortType: string;
  dateStart?: string;
  dateEnd?: string;
};

interface StatisticMeta {
  total: number;
}

export interface StatisticState {
  isStatisticLoading: boolean;
  statistics: Statistic[];
  statisticTotal: number;
  statisticFilter: StatisticFilter;

  error: string | null;
}

export type EmployeeStatisticJSONApi = NewJSONApi<StatisticAttributes, unknown, StatisticMeta, unknown>;
export type UserStatisticJSONApi = NewJSONApi<StatisticAttributes, unknown, StatisticMeta, unknown>;
