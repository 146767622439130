import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as BatteryChargingTracker } from 'assets/img/battery_charging_tracker.svg';
import { ReactComponent as GpsDeviceOn } from 'assets/img/gps_device_on.svg';
import { ReactComponent as GpsDeviceOff } from 'assets/img/gps_device_off.svg';
import { ReactComponent as NetworkQualityTracker } from 'assets/img/network_quality_tracker.svg';
import { ReactComponent as NetworkStatusTracker } from 'assets/img/network_status_tracker.svg';
import { ReactComponent as EditIcon } from 'assets/img/gear.svg';

import { useSortingTrackers } from 'components/trackers/utils/useSorting';
import { Checkbox } from 'components/common/checkbox/checkbox';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import {
  selectOneTracker,
  unselectOneTracker,
  watchOneTracker,
  unwatchOneTracker,
  fetchOneTracker,
} from 'reducers/trackers';

import { TRACKER } from 'utils/consts';
import AccessEntity from 'utils/accessEntity';
import { findBatteryStatus } from 'utils/findBatteryStatus';

import styles from 'components/trackers/trackers.module.scss';

const cx = classNames.bind(styles);

const TrackersListItems: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { trackers: notSortedTrackers, selectedTrackers } = useSelector((state: RootState) => state.tracker);
  const permissions = useSelector((state: RootState) => state.user.permissions.trackers);

  const sortedTrackers = useSortingTrackers(notSortedTrackers);

  const trackersPermission = useMemo(() => {
    return new AccessEntity(permissions);
  }, [permissions]);

  const selectTracker = (id: number) => {
    const findedSelectedTracker = selectedTrackers.find(tr => tr.id === id);
    if (findedSelectedTracker) {
      return dispatch(unselectOneTracker(id));
    }
    return dispatch(selectOneTracker(id));
  };

  const openModal = () => {
    dispatch(showModal({ type: TRACKER, which: '' }));
  };

  const handleUpdateClick = (id: number) => {
    if (trackersPermission.isAllowUpdate()) {
      dispatch(fetchOneTracker(id));
      openModal();
    }
  };

  const watchTracker = (id: number) => {
    dispatch(watchOneTracker(id));
  };

  const unwatchTracker = (id: number) => {
    dispatch(unwatchOneTracker(id));
  };

  return (
    <Fragment>
      {sortedTrackers.map((item, index) => {
        return (
          <div key={index} className={styles.trackerWrapper}>
            <div className={styles.trackerInfo}>
              <Checkbox
                checked={selectedTrackers.some(tr => tr.id === item.id)}
                handleCheck={() => selectTracker(item.id)}
              />
              <div className={styles.id}>{item.attributes.trackerNumber}</div>
              <div className={styles.name}>{item.attributes.simNumber}</div>
            </div>
            <div className={cx(styles.trackerButtons, styles.trackerButtonsItem)}>
              <div className={styles.trackerButtonsIcon}>
                {selectedTrackers.find(e => e.id === item.id && e.watching) ? (
                  <GpsDeviceOn className={styles.iconGpsStateActive} onClick={() => unwatchTracker(item.id)} />
                ) : (
                  <GpsDeviceOff
                    className={
                      selectedTrackers.find(e => e.id === item.id) ? styles.iconGpsStateOn : styles.iconGpsStateOff
                    }
                    onClick={
                      selectedTrackers.find(e => e.id === item.id)
                        ? () => watchTracker(item.id)
                        : () => {
                            return;
                          }
                    }
                    data-tip
                    data-for={`watch_tracker_${item.id}`}
                  />
                )}
                <ReactTooltip
                  className={styles.customTooltip}
                  id={`watch_tracker_${item.id}`}
                  place="right"
                  type="light"
                  effect="solid"
                >
                  <span>{t('trackers.list.item.tooltip.follow.text')}</span>
                </ReactTooltip>
              </div>
              <div className={styles.trackerButtonsIcon}>
                <NetworkStatusTracker className={styles[`iconConnectionState${item.attributes.isActive}`]} />
              </div>
              <div className={styles.trackerButtonsIcon}>
                <NetworkQualityTracker className={styles[`trackerButtonsIcon${item.attributes.signalLevel}`]} />
              </div>
              <div className={styles.trackerButtonsIcon}>
                <BatteryChargingTracker
                  className={styles[`trackerButtonsIcon${findBatteryStatus(item.attributes.batteryCharge ?? 0)}`]}
                />
                <span className={styles.trackerButtonsIconText}>
                  {item.attributes.batteryCharge ? `${item.attributes.batteryCharge}%` : 'N/A'}
                </span>
              </div>

              <div
                className={cx(styles.trackerButtonsIcon, {
                  [styles.trackerButtonsIconDisabled]: !trackersPermission.isAllowUpdate(),
                })}
                onClick={() => handleUpdateClick(item.id)}
              >
                <EditIcon data-tip data-for={`edit_tracker_${item.id}`} />
                {trackersPermission.isAllowUpdate() && (
                  <ReactTooltip
                    className={styles.customTooltip}
                    id={`edit_tracker_${item.id}`}
                    place="right"
                    type="light"
                    effect="solid"
                  >
                    <span>{t('trackers.list.item.tooltip.battery.text')}</span>
                  </ReactTooltip>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default React.memo(TrackersListItems);
