import React from 'react';
import cs from 'classnames';

import { SortType } from 'utils/interfaces';

import styles from './withSortIcon.module.scss';

type Props = {
  className?: string;
  sortType: SortType;
  showSortIcon: boolean;
};

const WithSortIcon: React.FC<Props> = ({ className, showSortIcon, sortType, children }) => {
  return (
    <span
      className={cs(styles.container, className, {
        [styles.ascending]: showSortIcon && sortType === SortType.ASC,
        [styles.descending]: showSortIcon && SortType.DESC,
      })}
    >
      {children}
    </span>
  );
};

export default WithSortIcon;
