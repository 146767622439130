import { HandbookNewElement, HandbookTranslatedFieldType } from 'reducers/handbooks/interface';
import { DEFAULT_LANGUAGES, LanguagesKeysType } from 'translate';
import { HANDBOOK_KEYS } from './consts';

export function getDateFromStr(dateStr: string) {
  const date = dateStr ? new Date(dateStr) : new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minutes = date.getMinutes();

  const calculatedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  const calculatedTime = `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`;

  return `${calculatedDate} ${calculatedTime}`;
}

// формирование полей для создания нового элемента справочника
export function getFieldsForNewElements(keyName: string) {
  let newElement: HandbookNewElement = {
    name: {
      [DEFAULT_LANGUAGES]: '',
    },
  };

  switch (keyName) {
    case HANDBOOK_KEYS.organizations:
      newElement = {
        ...newElement,
        organizationForm: '',
        inn: '',
        departments: [],
        workObjects: [],
      };
      break;

    case HANDBOOK_KEYS.poiGasAnalyzerModels:
      newElement = {
        ...newElement,
        poiGasTypes: [],
        poiGasSensors: [],
      };
      break;

    case HANDBOOK_KEYS.poiGasTypes:
      newElement = {
        ...newElement,
        poiGasAnalyzerModelId: '',
      };
      break;

    case HANDBOOK_KEYS.poiGasSensors:
      newElement = {
        ...newElement,
        poiGasAnalyzerModelId: '',
      };
      break;

    default:
      break;
  }
  return newElement;
}

// получение ключей связанных справочников для существующего справочника,
// который зависит от связанных справочников
export function getBindedHandbookKeysForChosenElements(keyName: string) {
  switch (keyName) {
    case HANDBOOK_KEYS.organizations:
      return [HANDBOOK_KEYS.departments, HANDBOOK_KEYS.workObjects];

    case HANDBOOK_KEYS.poiGasAnalyzerModels:
      return [HANDBOOK_KEYS.poiGasTypes, HANDBOOK_KEYS.poiGasSensors];

    case HANDBOOK_KEYS.poiGasTypes:
      return [HANDBOOK_KEYS.poiGasAnalyzerModels];

    case HANDBOOK_KEYS.poiGasSensors:
      return [HANDBOOK_KEYS.poiGasAnalyzerModels];

    default:
      return [];
  }
}

export function getTranslateFromLanguageKey(
  data: HandbookTranslatedFieldType | undefined,
  currentLanguageKey: LanguagesKeysType
) {
  if (data) {
    return data[currentLanguageKey] ?? data[DEFAULT_LANGUAGES] ?? '';
  }
  return '';
}
