import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import Button from 'components/common/button/button';
import styles from './geozoneDeleteAlert.module.scss';
import { closeAlert } from 'reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { removeGeozone, removeSavedSelectedGeozone, setGeozoneCreationIsShow } from 'reducers/geozones';
import { RootState } from 'reducers';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function GeozoneDeleteAlert() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const chosenGeozone = useSelector((state: RootState) =>
    state.geozone.geozones.find(g => g.id === state.geozone.chosenGeozone)
  );

  const handleCancel = () => {
    dispatch(closeAlert());
  };

  const handleContinue = () => {
    if (chosenGeozone) {
      dispatch(removeGeozone(chosenGeozone.id));
      dispatch(removeSavedSelectedGeozone(chosenGeozone.id));
      dispatch(setGeozoneCreationIsShow(false));
      return handleCancel();
    }
  };
  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <div>{t('geozones.geozone-card.alert.header.label')}</div>
        <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={handleCancel} />
      </div>
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('geozones.geozone-card.alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('geozones.geozone-card.alert.info.text')}</p>
        <p className={styles.modalBodyDeleteText}>{t('geozones.geozone-card.alert.question.text')}</p>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('geozones.geozone-card.footer.btn.no.label')} onClick={handleCancel} />
        <Button blue text={t('geozones.geozone-card.footer.btn.yes.label')} onClick={handleContinue} />
      </div>
    </Fragment>
  );
}
