import { configureStore, Action } from '@reduxjs/toolkit';
import ReduxThunk, { ThunkAction } from 'redux-thunk';
// import { logger } from 'redux-logger';
import { Middleware } from 'redux';
import { RootState, rootReducer } from '../reducers';
import { checkAndUpdateTokenMiddleware } from './middleware/checkAndUpdateTokenMiddleware';
import { saveClientUserPreferencesMiddleware } from './middleware/saveClientUserPreferencesMiddleware';

const isDev = process.env.REACT_APP_ENV === 'development';

const customMiddlewares: Middleware[] = [checkAndUpdateTokenMiddleware, saveClientUserPreferencesMiddleware];

const devMiddlewares: Middleware[] = [/*logger,*/ ReduxThunk];
const buildMiddlewares: Middleware[] = [ReduxThunk];
const libraryMiddleWares = isDev ? devMiddlewares : buildMiddlewares;

const store = configureStore({
  reducer: rootReducer,
  middleware: [...customMiddlewares, ...libraryMiddleWares],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (isDev && (module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept('../reducers', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('../reducers').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
