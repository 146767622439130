import React, { forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { setHours, setMinutes } from 'date-fns';

import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { RadioButton } from 'components/common/radioButton/radioButton';
import MultipleSelectGroup, {
  getValuesForMultipleSelectGroup,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';
import { InfoElement } from 'components/common/infoElement/infoElement';
import { NOTIFICATION_POSITION_BY_GEOZONE } from 'components/notifications/utils/consts';
import { AlertProps } from 'components/notifications/utils/types';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../../tabsModal.module.scss';
import './commonLayoutStyles.scss';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div
      className={cx(styles.datePicker, {
        [styles.intervalTime]: props.intervalTimeStyle,
        [styles.fullWidth]: props.fullWidth,
      })}
      onClick={props.onClick}
      ref={_ref}
    >
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value[0] === '0' ? props.value.substring(1) : props.value}
        witchTimeIcon
        isRequired={props.isRequired}
        isValueError={props.isValueError}
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

export const AlertLayout = ({ states, handlers, refs, invalidFields, additionalFields }: AlertProps) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedSosFixing}>
            <Checkbox checked={states.isSosFixing} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-signal.text')}</span>
          </div>
          <InfoElement
            id={'sos_signal'}
            text={t('notifications.card.tabs.conditions.field.alert.rec-signal.tooltip.text')}
          />
        </div>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedFallFixing}>
            <Checkbox checked={states.isFallFixing} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-trackers.text')}</span>
          </div>
          <InfoElement
            id={'tracker_signal'}
            text={t('notifications.card.tabs.conditions.field.alert.rec-trackers.tooltip.text')}
          />
        </div>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedAreaLimited}>
            <Checkbox checked={states.isAreaLimited} />
            <span>{t('notifications.card.tabs.conditions.field.alert.limit-area.text')}</span>
          </div>
          <InfoElement
            id={'geozone_signal'}
            text={t('notifications.card.tabs.conditions.field.alert.limit-area.tooltip.text')}
          />
        </div>
        {states.isAreaLimited && (
          <>
            <div className={cx(styles.radioGroup, styles.inner)}>
              <div
                className={styles.radioButtonRow}
                onClick={() => handlers.handleCheckRadio(NOTIFICATION_POSITION_BY_GEOZONE.inside)}
              >
                <RadioButton
                  name="positionByGeozone"
                  checked={states.positionByGeozone === NOTIFICATION_POSITION_BY_GEOZONE.inside}
                />
                <span>{t('notifications.card.tabs.conditions.field.alert.limit-area.inside.text')}</span>
              </div>
              <div
                className={styles.radioButtonRow}
                onClick={() => handlers.handleCheckRadio(NOTIFICATION_POSITION_BY_GEOZONE.outside)}
              >
                <RadioButton
                  name="positionByGeozone"
                  checked={states.positionByGeozone === NOTIFICATION_POSITION_BY_GEOZONE.outside}
                />
                <span>{t('notifications.card.tabs.conditions.field.alert.limit-area.outside.text')}</span>
              </div>
            </div>
            <div className={cx(styles.modalRow, styles.modalRowSpaceBetween, styles.Inputs)}>
              <MultipleSelectGroup
                label={t('notifications.card.tabs.conditions.field.alert.geozone-list.label')}
                placeholder={t('notifications.card.tabs.conditions.field.alert.geozone-list.placeholder')}
                isValueError={invalidFields.includes('geozoneEntitiesSos')}
                isRequired={true}
                wrapperStyle={styles.inputWrap}
                withSearchIcon
                options={states.geozoneEntitiesForSelect}
                values={getValuesForMultipleSelectGroup({
                  options: states.geozoneEntitiesForSelect,
                  values: states.geozoneEntities.map(object => ({ name: object.type, ids: object.entityIds })),
                })}
                translateGroups={states.geozoneEntitiesTranslatesForSelect}
                handleChange={handlers.handleChangeGeozones}
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedFoulFixing}>
            <Checkbox checked={additionalFields.isFoulFixing} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-violation.text')}</span>
          </div>
          <InfoElement
            id={'violation'}
            text={t('notifications.card.tabs.conditions.field.alert.rec-violation.tooltip.text')}
          />
        </div>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedInactionFixing}>
            <Checkbox checked={states.isInactionFixing} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-inaction.text')}</span>
          </div>
          <InfoElement
            id={'inaction'}
            text={t('notifications.card.tabs.conditions.field.alert.rec-inaction.tooltip.text')}
          />
        </div>
        {states.isInactionFixing && (
          <div className={cx(styles.modalRow, styles.modalRowSpaceBetween, styles.Inputs, styles.Inputs)}>
            <DatePicker
              dateFormat={t('notifications.card.tabs.conditions.field.alert.wait-time.date-format.text')}
              selected={
                states.coordsChangingLimit ? setHours(setMinutes(new Date(), states.coordsChangingLimit / 60), 0) : null
              }
              onChange={(date: Date) => handlers.handleChangeInactionFixingValue(date)}
              className={styles.tabInfoDatePicker}
              placeholderText={t('notifications.card.tabs.conditions.field.alert.wait-time.placeholder')}
              customInput={
                <DatePickerCustomInput
                  ref={refs.coordsChangingLimitRef}
                  label={t('notifications.card.tabs.conditions.field.alert.wait-time.label')}
                  isValueError={invalidFields.includes('coordsChangingLimitSos')}
                  fullWidth
                />
              }
              openToDate={setHours(setMinutes(new Date(), 1), 0)}
              locale={getCurrentLocaleForDatePicker(locale)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat={t('notifications.card.tabs.conditions.field.alert.wait-time.time-format.text')}
              timeIntervals={1}
              timeCaption={t('notifications.card.tabs.conditions.field.alert.wait-time.picker.header.text')}
              minTime={setHours(setMinutes(new Date(), 1), 0)}
              maxTime={setHours(setMinutes(new Date(), 59), 0)}
              popperClassName="popperDatePicker"
            />
          </div>
        )}
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={cx(styles.modalCheckbox, styles.mBottom)} onClick={handlers.handleCheckedDisconnectionFixing}>
            <Checkbox checked={states.isDisconnectionFixing} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-loss.text')}</span>
          </div>
          <InfoElement
            id={'lostConnection'}
            text={t('notifications.card.tabs.conditions.field.alert.rec-loss.tooltip.text')}
          />
        </div>
        {states.isDisconnectionFixing && (
          <Fragment>
            <div className={cx(styles.modalRow, styles.modalRowSpaceBetween, styles.Inputs)}>
              <DatePicker
                dateFormat={t('notifications.card.tabs.conditions.field.alert.wait-time-receiving.date-format.text')}
                selected={
                  states.dataGettingLimit ? setHours(setMinutes(new Date(), states.dataGettingLimit / 60), 0) : null
                }
                onChange={(date: Date) => handlers.handleChangeDataGettingLimitValue(date)}
                className={styles.tabInfoDatePicker}
                placeholderText={t('notifications.card.tabs.conditions.field.alert.wait-time-receiving.placeholder')}
                customInput={
                  <DatePickerCustomInput
                    ref={refs.dataGettingLimitRef}
                    label={t('notifications.card.tabs.conditions.field.alert.wait-time-receiving.label')}
                    isValueError={invalidFields.includes('dataGettingLimitSos')}
                    fullWidth
                  />
                }
                openToDate={setHours(setMinutes(new Date(), 1), 0)}
                locale={getCurrentLocaleForDatePicker(locale)}
                showTimeSelect
                showTimeSelectOnly
                timeFormat={t('notifications.card.tabs.conditions.field.alert.wait-time-receiving.time-format.text')}
                timeIntervals={1}
                timeCaption={t('notifications.card.tabs.conditions.field.alert.wait-time-receiving.picker.header.text')}
                minTime={setHours(setMinutes(new Date(), 1), 0)}
                maxTime={setHours(setMinutes(new Date(), 59), 0)}
                popperClassName="popperDatePicker"
              />
              <div
                className={cx(styles.modalCheckbox, styles.inner)}
                onClick={handlers.handleCheckedRestoreConnectionFixing}
              >
                <Checkbox checked={states.isRestoreConnectionFixing} />
                <span>{t('notifications.card.tabs.conditions.field.alert.recovery-connection.text')}</span>
              </div>
            </div>
          </Fragment>
        )}
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween, styles.Inputs)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedGpsLost}>
            <Checkbox checked={states.isGnssLost} />
            <span>{t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.text')}</span>
          </div>
        </div>
        {states.isGnssLost && (
          <Fragment>
            <div className={cx(styles.modalRow, styles.modalRowSpaceBetween, styles.Inputs)}>
              <DatePicker
                dateFormat={t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.date-format.text')}
                selected={
                  states.gnssWaitingTime ? setHours(setMinutes(new Date(), states.gnssWaitingTime / 60), 0) : null
                }
                onChange={(date: Date) => handlers.handleChangeGpsWaitingTimeValue(date)}
                className={styles.tabInfoDatePicker}
                placeholderText={t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.placeholder')}
                customInput={
                  <DatePickerCustomInput
                    ref={refs.gpsWaitingTimeRef}
                    label={t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.label')}
                    isValueError={invalidFields.includes('gpsWaitingTimeSos')}
                    fullWidth
                  />
                }
                openToDate={setHours(setMinutes(new Date(), 1), 0)}
                locale={getCurrentLocaleForDatePicker(locale)}
                showTimeSelect
                showTimeSelectOnly
                timeFormat={t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.time-format.text')}
                timeIntervals={1}
                timeCaption={t('notifications.card.tabs.conditions.field.alert.rec-gps-loss.picker.header.text')}
                minTime={setHours(setMinutes(new Date(), 1), 0)}
                maxTime={setHours(setMinutes(new Date(), 59), 0)}
                popperClassName="popperDatePicker"
              />
              <div className={cx(styles.modalCheckbox, styles.inner)} onClick={handlers.handleCheckedGpsRestore}>
                <Checkbox checked={states.isGnssRestoreFixing} />
                <span>{t('notifications.card.tabs.conditions.field.alert.rec-gps-recovery.text')}</span>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};
