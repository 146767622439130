import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { fetchHandbookData } from 'reducers/handbooks';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';
import { PersonalDataHandlers, PersonalDataValues } from '../utils/types';
import isEqual from 'lodash.isequal';
import { getCurrentLocale } from 'translate';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

const mainTabRequiredFields: string[] = [
  'firstName',
  'lastName',
  'organizationId',
  'departmentId',
  'positionId',
  'login',
];

let initialValue: PersonalDataValues | null = null;

export function usePersonalData() {
  const dispatch = useDispatch();

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));
  const userData = useSelector((state: RootState) => state.user);

  const [firstName, setFirstName] = useState(userData.attributes.firstName ?? '');
  const [lastName, setLastName] = useState(userData.attributes.lastName ?? '');
  const [secondName, setSecondName] = useState(userData.attributes.secondName ?? '');
  const [simNumber, setSimNumber] = useState(userData.attributes.simNumber ?? '');
  const [smsNotified, setSmsNotified] = useState(userData.attributes.smsNotified ?? false);
  const [login, setLogin] = useState(userData.attributes.login ?? '');
  const [organizationId, setOrganizationId] = useState(userData.attributes.organizationId ?? 0);
  const [departmentId, setDepartmentId] = useState(userData.attributes.departmentId ?? 0);
  const [positionId, setPositionId] = useState(userData.attributes.positionId ?? 0);
  const [email, setEmail] = useState(userData.attributes.email ?? '');
  const [emailNotified, setEmailNotified] = useState(userData.attributes.emailNotified ?? false);
  const [password, setPassword] = useState('password');
  const [icon, setIcon] = useState<string | null>(userData.attributes.icon ?? null);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.positions]));
  }, [dispatch]);

  const { organizations = [], positions = [] } = useSelector((state: RootState) => state.handbooks.data);

  const organizationOptions = useMemo(
    () =>
      organizations.map(org => ({
        value: org.id,
        label: getTranslateFromLanguageKey(org.attributes.name, userLanguageKey),
      })),
    [organizations, userLanguageKey]
  );
  const departmentOptions = useMemo(() => {
    const org = organizations.find(org => org.id === organizationId);
    if (org) {
      return (
        org.relationships.departments?.data?.map(dep => ({
          value: Number(dep.id),
          label: getTranslateFromLanguageKey(dep.name, userLanguageKey),
        })) ?? []
      );
    }
    return [];
  }, [organizationId, organizations, userLanguageKey]);

  const positionOptions = useMemo(
    () =>
      positions.map(pos => ({
        value: pos.id,
        label: getTranslateFromLanguageKey(pos.attributes.name, userLanguageKey),
      })),
    [positions, userLanguageKey]
  );

  const handleSmsNotifyChange = () => {
    setSmsNotified(!smsNotified);
  };
  const handleEmailNotifyChange = () => {
    setEmailNotified(!emailNotified);
  };

  const handleOrgChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setOrganizationId(id);
    } else {
      setOrganizationId(0);
    }
  };
  const handleDepartmentChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setDepartmentId(id);
    } else {
      setDepartmentId(0);
    }
  };
  const handlePositionChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setPositionId(id);
    } else {
      setPositionId(0);
    }
  };

  const values: PersonalDataValues = {
    firstName,
    lastName,
    secondName,
    simNumber,
    smsNotified,
    login,
    organizationId,
    departmentId,
    positionId,
    email,
    emailNotified,
    password,
    icon,
    isBanned: userData.attributes.isBanned,
    employeeId: userData.attributes.employeeId,
  };

  if (initialValue === null) {
    initialValue = Object.assign({}, values);
  }

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    mainTabRequiredFields.forEach(field => {
      if (!values[field as keyof PersonalDataValues]) {
        newInvalidFields.push(field);
      }
    });

    setInvalidFields(newInvalidFields);
    return Boolean(newInvalidFields.length);
  };

  const handlers: PersonalDataHandlers = {
    setFirstName,
    setLastName,
    setSecondName,
    setSimNumber,
    handleSmsNotifyChange,
    setLogin,
    handleOrgChange,
    handleDepartmentChange,
    handlePositionChange,
    setEmail,
    handleEmailNotifyChange,
    setPassword,
    setIcon,
  };

  const findUniqueValues = () => {
    const res: { [key: string]: string | number | boolean | null | undefined } = {};

    for (const key in initialValue) {
      if (
        initialValue.hasOwnProperty(key) &&
        initialValue[key as keyof PersonalDataValues] !== values[key as keyof PersonalDataValues]
      ) {
        res[key] = values[key as keyof PersonalDataValues];
      }
    }

    return res;
  };

  const findDifference = () => {
    if (isEqual(initialValue, values)) {
      return null;
    }
    return values;
  };

  return {
    values,
    invalidFields,
    handlers,
    validateRequiredFields,
    organizationOptions,
    departmentOptions,
    positionOptions,
    findUniqueValues,
    findDifference,
  };
}
