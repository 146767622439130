import React, { Fragment } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import styles from './headerModal.module.scss';

type Props = {
  title: string;
  onCancel: () => void;
};

export const HeaderModal = ({ title, onCancel }: Props) => {
  return (
    <Fragment>
      <div>{title}</div>
      <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={onCancel} />
    </Fragment>
  );
};
