export async function getBase64FromImageSrc(src: string): Promise<string> {
  if (!src) {
    return Promise.resolve('');
  }

  return await new Promise((resolve, reject) => {
    const img = new Image();

    img.src = src;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      if (context) {
        context.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      } else {
        reject();
      }
    };
    img.onerror = reject;
  });
}
