import { LanguagesKeysType } from 'translate';
import { GeozoneNdData } from './interface';

const MOCK_ND_GEOZONE_DATA_RU: GeozoneNdData[] = [
  {
    numberND: 'РПО - 00340',
    workType: 'Работы повышенной опасности',
    workPlace: 'Цех №1',
    workArea: '№16',
    scopeOfWork: 'Плановый ремонт двигателя насоса',
    statusND: 'На согласовании',
    workStartDate: '2021-08-22T14:44:23.851Z',
    workEndDate: '2021-10-12T14:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Авинов В.Д.',
        },
        {
          position: 'Утверждающий',
          name: 'Ильин А.П.',
        },
        {
          position: 'Производитель работ',
          name: 'Подин П.Н.',
        },
        {
          position: 'Допускающий',
          name: 'Веров П.А.',
        },
      ],
      brigade: [
        {
          position: 'Монтажник',
          name: 'Петров А.А.',
        },
        {
          position: 'Машинист',
          name: 'Аверин Г.А.',
        },
      ],
    },
  },
  {
    numberND: 'РПО - 00345',
    workType: 'Работы повышенной опасности',
    workPlace: 'Цех №11',
    workArea: '№02',
    scopeOfWork: 'Обслуживание механизма подачи топлива',
    statusND: 'В работе',
    workStartDate: '2021-07-20T10:44:23.851Z',
    workEndDate: '2021-09-22T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Селевестров П.П.',
        },
        {
          position: 'Утверждающий',
          name: 'Бобров А.П.',
        },
        {
          position: 'Производитель работ',
          name: 'Дубин О.Н.',
        },
        {
          position: 'Допускающий',
          name: 'Перов П.А.',
        },
      ],
      brigade: [
        {
          position: 'Монтажник',
          name: 'Иванов И.В.',
        },
        {
          position: 'Слесарь',
          name: 'Глинин Е.Е.',
        },
        {
          position: 'Слесарь',
          name: 'Ежов П.П.',
        },
      ],
    },
  },
  {
    numberND: 'РПО - 00360',
    workType: 'Работы повышенной опасности',
    workPlace: 'Цех №3',
    workArea: '№08-1',
    scopeOfWork: 'Замена масла в маслообменнике',
    statusND: 'В работе',
    workStartDate: '2021-07-22T10:44:23.851Z',
    workEndDate: '2021-10-11T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Блинов Р.А.',
        },
        {
          position: 'Утверждающий',
          name: 'Воронов А.В.',
        },
        {
          position: 'Производитель работ',
          name: 'Круглов В.В.',
        },
        {
          position: 'Допускающий',
          name: 'Прорин Е.К.',
        },
      ],
      brigade: [
        {
          position: 'Слесарь',
          name: 'Димов Д.А.',
        },
        {
          position: 'Слесарь',
          name: 'Чернов К.У.',
        },
      ],
    },
  },
  {
    numberND: 'Огневые - 00015',
    workType: 'Огневые работы',
    workPlace: 'Цех №7',
    workArea: '№06',
    scopeOfWork: 'Демонтаж металлоконструкций',
    statusND: 'На утверждении',
    workStartDate: '2021-08-02T10:40:23.851Z',
    workEndDate: '2021-08-02T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Рыбин В.Д.',
        },
        {
          position: 'Утверждающий',
          name: 'Водный А.П.',
        },
        {
          position: 'Производитель работ',
          name: 'Куропаткин П.Н.',
        },
        {
          position: 'Допускающий',
          name: 'Кукушкин П.П.',
        },
      ],
      brigade: [
        {
          position: 'Сварщик',
          name: 'Невров А.А.',
        },
        {
          position: 'Мастер',
          name: 'Зверев Б.А.',
        },
      ],
    },
  },
  {
    numberND: 'Огневые - 00017',
    workType: 'Огневые работы',
    workPlace: 'Цех №8',
    workArea: '№02',
    scopeOfWork: 'Сварочные работы на газопроводе',
    statusND: 'На согласовании',
    workStartDate: '2021-07-28T10:40:23.851Z',
    workEndDate: '2021-07-29T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Львов Д.Д.',
        },
        {
          position: 'Утверждающий',
          name: 'Павшин Р.Р.',
        },
        {
          position: 'Производитель работ',
          name: 'Крулев А.В.',
        },
        {
          position: 'Допускающий',
          name: 'Зернов К.К.',
        },
      ],
      brigade: [
        {
          position: 'Сварщик',
          name: 'Мальцов К.У.',
        },
        {
          position: 'Монтажник',
          name: 'Кульков Н.Н.',
        },
      ],
    },
  },
  {
    numberND: 'Газоопасные - 00028',
    workType: 'Газоопасные работы',
    workPlace: 'Цех №15',
    workArea: '№09',
    scopeOfWork: 'Ремонт и пуск газа в газопровод',
    statusND: 'На продлении',
    workStartDate: '2021-08-05T10:40:23.851Z',
    workEndDate: '2021-08-05T15:40:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Согласующий',
          name: 'Кугин Е.Н.',
        },
        {
          position: 'Утверждающий',
          name: 'Четвыршев А.В.',
        },
        {
          position: 'Производитель работ',
          name: 'Березин Г.Е.',
        },
        {
          position: 'Допускающий',
          name: 'Кутепов И.И.',
        },
      ],
      brigade: [
        {
          position: 'Сварщик',
          name: 'Сергеев П.А.',
        },
        {
          position: 'Монтажник',
          name: 'Шапкин Е.М.',
        },
      ],
    },
  },
];

const MOCK_ND_GEOZONES_DATA_EN: GeozoneNdData[] = [
  {
    numberND: 'Hazardous - 00340',
    workType: 'Hazardous work',
    workPlace: 'Workshop №1',
    workArea: '№16',
    scopeOfWork: 'Scheduled repair of the pump motor',
    statusND: 'Approval',
    workStartDate: '2021-08-22T14:44:23.851Z',
    workEndDate: '2021-10-12T14:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Avinov V.D.',
        },
        {
          position: 'Approver',
          name: 'Ilyin A.P.',
        },
        {
          position: 'Work producer',
          name: 'Podin P.N.',
        },
        {
          position: 'Person allowing to work',
          name: 'Verov P.A.',
        },
      ],
      brigade: [
        {
          position: 'Mounter',
          name: 'Petrov A.A.',
        },
        {
          position: 'Machinist',
          name: 'Averin G.A.',
        },
      ],
    },
  },
  {
    numberND: 'Hazardous - 00345',
    workType: 'Hazardous work',
    workPlace: 'Workshop №11',
    workArea: '№02',
    scopeOfWork: 'Maintenance of the fuel supply mechanism',
    statusND: 'In process',
    workStartDate: '2021-07-20T10:44:23.851Z',
    workEndDate: '2021-09-22T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Seleverstov P.P.',
        },
        {
          position: 'Approver',
          name: 'Bobrov A.P.',
        },
        {
          position: 'Work producer',
          name: 'Dubin O.N.',
        },
        {
          position: 'Person allowing to work',
          name: 'Perov L.B.',
        },
      ],
      brigade: [
        {
          position: 'Mounter',
          name: 'Ivanov I.V.',
        },
        {
          position: 'Mechanic',
          name: 'Glinin Е.Е.',
        },
        {
          position: 'Mechanic',
          name: 'Ezhov P.P.',
        },
      ],
    },
  },
  {
    numberND: 'Hazardous - 00360',
    workType: 'Hazardous work',
    workPlace: 'Workshop №3',
    workArea: '№08-1',
    scopeOfWork: 'Changing the oil in the oil exchanger',
    statusND: 'In process',
    workStartDate: '2021-07-22T10:44:23.851Z',
    workEndDate: '2021-10-11T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Blinov R.А.',
        },
        {
          position: 'Approver',
          name: 'Voronov А.V.',
        },
        {
          position: 'Work producer',
          name: 'Kruglov V.V.',
        },
        {
          position: 'Person allowing to work',
          name: 'Pronin Е.К.',
        },
      ],
      brigade: [
        {
          position: 'Mechanic',
          name: 'Dimov D.A.',
        },
        {
          position: 'Mechanic',
          name: 'Chernov K.U.',
        },
      ],
    },
  },
  {
    numberND: 'Fire - 00015',
    workType: 'Work with fire',
    workPlace: 'Workshop №7',
    workArea: '№06',
    scopeOfWork: 'Dismantling of metal structures',
    statusND: 'Approval',
    workStartDate: '2021-08-02T10:40:23.851Z',
    workEndDate: '2021-08-02T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Rybin V.D.',
        },
        {
          position: 'Approver',
          name: 'Vodnyi A.P.',
        },
        {
          position: 'Work producer',
          name: 'Kuropatkin P.N.',
        },
        {
          position: 'Person allowing to work',
          name: 'Kukushkin P.P.',
        },
      ],
      brigade: [
        {
          position: 'Welder',
          name: 'Nevrov A.A.',
        },
        {
          position: 'Master',
          name: 'Zverev B.A.',
        },
      ],
    },
  },
  {
    numberND: 'Fire - 00017',
    workType: 'Work with fire',
    workPlace: 'Workshop №8',
    workArea: '№02',
    scopeOfWork: 'Welding work on the gas pipeline',
    statusND: 'Approval',
    workStartDate: '2021-07-28T10:40:23.851Z',
    workEndDate: '2021-07-29T15:44:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Lvov D.D.',
        },
        {
          position: 'Approver',
          name: 'Pavshin P. P.',
        },
        {
          position: 'Work producer',
          name: 'Krulev А.В.',
        },
        {
          position: 'Person allowing to work',
          name: 'Zernov К.К.',
        },
      ],
      brigade: [
        {
          position: 'Welder',
          name: 'Maltsov К.U.',
        },
        {
          position: 'Mounter',
          name: 'Kulkov N.N.',
        },
      ],
    },
  },
  {
    numberND: 'Gas - 00028',
    workType: 'Gas work',
    workPlace: 'Workshop №15',
    workArea: '№09',
    scopeOfWork: 'Repair and launch of gas into the pipeline',
    statusND: 'In process',
    workStartDate: '2021-08-05T10:40:23.851Z',
    workEndDate: '2021-08-05T15:40:23.851Z',
    persons: {
      responsible: [
        {
          position: 'Coordinator',
          name: 'Kugin Е.N.',
        },
        {
          position: 'Approver',
          name: 'Chertvyrshev А.V.',
        },
        {
          position: 'Work producer',
          name: 'Berezin G.Е.',
        },
        {
          position: 'Person allowing to work',
          name: 'Kutepov I.I.',
        },
      ],
      brigade: [
        {
          position: 'Welder',
          name: 'Sergeev P.A.',
        },
        {
          position: 'Mounter',
          name: 'Shapkin E.M.',
        },
      ],
    },
  },
];

export const MOCK_ND_GEOZONES_DATA: { [locale in LanguagesKeysType]: GeozoneNdData[] } = {
  ru: MOCK_ND_GEOZONE_DATA_RU,
  en: MOCK_ND_GEOZONES_DATA_EN,
  zh: MOCK_ND_GEOZONES_DATA_EN,
  ar: MOCK_ND_GEOZONES_DATA_EN,
  de: MOCK_ND_GEOZONES_DATA_EN,
};
