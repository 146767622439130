import { reqHandlers } from 'utils/api';
import { PoiCreate } from './interface';

export function getPoi() {
  return reqHandlers.get('/api/poi');
}

export function getOnePoi(id: number) {
  return reqHandlers.get(`/api/poi/${id}`);
}

export function createOnePoi(data: PoiCreate) {
  return reqHandlers.post(`/api/poi`, data);
}

export function putOnePoi(id: number, data: Record<string, unknown>) {
  return reqHandlers.put(`/api/poi/${id}`, data);
}

export function deleteOnePoi(id: number) {
  return reqHandlers.delete(`/api/poi/${id}`);
}
