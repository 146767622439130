import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as TrackableNumber } from 'assets/img/list_header_icons/number.svg';
import { ReactComponent as TrackableNumberAsc } from 'assets/img/list_header_icons/number-asc.svg';
import { ReactComponent as TrackableNumberDesc } from 'assets/img/list_header_icons/number-desc.svg';

import { ReactComponent as ListSort } from 'assets/img/list_sort.svg';
import { ReactComponent as GpsDeviceOn } from 'assets/img/gps_device_on.svg';

import { ReactComponent as NetworkStatus } from 'assets/img/list_header_icons/network_status.svg';
import { ReactComponent as NetworkStatusAsc } from 'assets/img/list_header_icons/network_status-asc.svg';
import { ReactComponent as NetworkStatusDesc } from 'assets/img/list_header_icons/network_status-desc.svg';

import { ReactComponent as NetworkQuality } from 'assets/img/list_header_icons/network_quality.svg';
import { ReactComponent as NetworkQualityAsc } from 'assets/img/list_header_icons/network_quality-asc.svg';
import { ReactComponent as NetworkQualityDesc } from 'assets/img/list_header_icons/network_quality-desc.svg';

import { ReactComponent as BatteryChargingStatus } from 'assets/img/list_header_icons/battery_charging_status.svg';
import { ReactComponent as BatteryChargingStatusAsc } from 'assets/img/list_header_icons/battery_charging_status-asc.svg';
import { ReactComponent as BatteryChargingStatusDesc } from 'assets/img/list_header_icons/battery_charging_status-desc.svg';

import { ReactComponent as EditIconHead } from 'assets/img/tool.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import { TransformedUnitsData } from 'components/monitoring/utils/types';
import { ButtonIconSorting } from './components/buttonIconSorting/buttonIconSorting';
import { ButtonIconSortingWithText } from './components/buttonIconSortingWithText/buttonIconSortingWithText';

import { RootState } from 'reducers';
import {
  setWatchAll,
  toggleSelectionOfUnits,
  setSort,
  setCloseAll,
  setOpenAll,
  unwatchAllUnits,
} from 'reducers/monitoring';
import { showModal } from 'reducers/modal';
import { SortingType } from 'reducers/monitoring/interface';
import { MONITORING_GROUPING, OBJECT_LIST, Sorting } from 'utils/consts';

import styles from './listHeader.module.scss';

const cx = classNames.bind(styles);

type Props = {
  monitoringUnitsTree: TransformedUnitsData;
  showExpand: boolean;
};

const ListHeader = ({ monitoringUnitsTree, showExpand }: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const isAllExpanded = useSelector((state: RootState) => state.monitoring.isAllExpanded);
  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);
  const watchingUnits = useSelector((state: RootState) => state.monitoring.watchingUnits);

  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);

  const headCheckboxStatus = selectedUnits.length === trackableUnits.length && trackableUnits.length > 0;

  const handleSort = (type: SortingType) => {
    dispatch(setSort(type));
  };

  const selectAllUnits = () => {
    const trackableUnitsWithTracker = trackableUnits.filter(u => u.attributes.trackerId);
    const checkboxStatusForItemsWithTracker = selectedUnits.length === trackableUnitsWithTracker.length;

    const unitIds = checkboxStatusForItemsWithTracker
      ? trackableUnits.map(u => u.id)
      : trackableUnitsWithTracker.map(u => u.id);

    dispatch(
      toggleSelectionOfUnits({
        currentStatus: checkboxStatusForItemsWithTracker,
        unitIds,
      })
    );
  };

  const watchAll = () => {
    if (watchingUnits.length !== selectedUnits.length) {
      dispatch(setWatchAll());
    } else {
      dispatch(unwatchAllUnits());
    }
  };

  const handleEditObjectsClick = () => {
    dispatch(showModal({ type: OBJECT_LIST, which: '' }));
  };

  const expandAll = () => {
    if (isAllExpanded) {
      return dispatch(setCloseAll());
    }

    let structureKeys: string[] = [];

    const getRecursiveStructuresKeys = (data: TransformedUnitsData) => {
      const dataKeys = Object.keys(data);
      if (dataKeys.length) {
        structureKeys = structureKeys.concat(dataKeys.map(strKey => data[strKey].uniqueKey));
        dataKeys.forEach(s => {
          return getRecursiveStructuresKeys(data[s].substructures);
        });
      } else {
        return;
      }
    };

    getRecursiveStructuresKeys(monitoringUnitsTree);

    return dispatch(setOpenAll(structureKeys));
  };

  const { trackableNumber, name, network, gps, battery } = Sorting;

  return (
    <Fragment>
      <div className={cx(styles.unitWrapper, styles.unitListHeader)}>
        <div className={styles.unitInfo}>
          {showExpand && <ExpandButton isOpen={isAllExpanded} onClick={expandAll} style={styles.unitInfoExpand} />}
          <Checkbox
            handleCheck={selectAllUnits}
            checked={headCheckboxStatus}
            isIndeterminate={selectedUnits.length > 0 && selectedUnits.length !== trackableUnits.length}
          />
          <div className={styles.nameTrackableNumber} data-tip data-for="trackableNumber">
            <ButtonIconSorting
              typeSorting={trackableNumber}
              onClick={() => handleSort(trackableNumber)}
              AscendingIcon={<TrackableNumberAsc />}
              DescendingIcon={<TrackableNumberDesc />}
            >
              <TrackableNumber />
            </ButtonIconSorting>
            <ReactTooltip
              className={styles.customTooltip}
              id="trackableNumber"
              place="right"
              type="light"
              effect="solid"
            >
              <span>{t('monitoring.units-list.header.number.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.nameHead}>
            <ButtonIconSortingWithText typeSorting={name} onClick={() => handleSort(name)}>
              <div>{t('monitoring.units-list.header.name.label')}</div>
            </ButtonIconSortingWithText>
          </div>
        </div>
        <div className={styles.headerButtons}>
          <div className={styles.headerButtonsIcon}>
            <ListSort onClick={handleEditObjectsClick} data-tip data-for="listSort" />
            <ReactTooltip className={styles.customTooltip} id="listSort" place="right" type="light" effect="solid">
              <span>{t('monitoring.units-list.header.manage-list.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon}>
            <GpsDeviceOn onClick={watchAll} data-tip data-for="watchAllTooltip" />
            <ReactTooltip
              className={styles.customTooltip}
              id="watchAllTooltip"
              place="right"
              type="light"
              effect="solid"
            >
              <span>{t('monitoring.units-list.header.follow-obj.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon} data-tip data-for="networkStatus">
            <ButtonIconSorting
              typeSorting={network}
              onClick={() => handleSort(network)}
              AscendingIcon={<NetworkStatusAsc />}
              DescendingIcon={<NetworkStatusDesc />}
            >
              <NetworkStatus />
            </ButtonIconSorting>
            <ReactTooltip className={styles.customTooltip} id="networkStatus" place="right" type="light" effect="solid">
              <span>{t('monitoring.units-list.header.connect-status.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon} data-tip data-for="satellites">
            <ButtonIconSorting
              typeSorting={gps}
              onClick={() => handleSort(gps)}
              AscendingIcon={<NetworkQualityAsc />}
              DescendingIcon={<NetworkQualityDesc />}
            >
              <NetworkQuality />
            </ButtonIconSorting>
            <ReactTooltip className={styles.customTooltip} id="satellites" place="right" type="light" effect="solid">
              <span>{t('monitoring.units-list.header.signal-quality.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon} data-tip data-for="batteryChargingStatus">
            <ButtonIconSorting
              typeSorting={battery}
              onClick={() => handleSort(battery)}
              AscendingIcon={<BatteryChargingStatusAsc />}
              DescendingIcon={<BatteryChargingStatusDesc />}
            >
              <BatteryChargingStatus />
            </ButtonIconSorting>
            <ReactTooltip
              className={styles.customTooltip}
              id="batteryChargingStatus"
              place="right"
              type="light"
              effect="solid"
            >
              <span>{t('monitoring.units-list.header.battery-level.tooltip.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.headerButtonsIcon} data-tip data-for="gropingList">
            <EditIconHead onClick={() => dispatch(showModal({ type: MONITORING_GROUPING, which: '' }))} />
            <ReactTooltip className={styles.customTooltip} id="gropingList" place="right" type="light" effect="solid">
              <span>{t('monitoring.units-list.header.list-group.tooltip.text')}</span>
            </ReactTooltip>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(ListHeader);
