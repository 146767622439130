import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import isEqual from 'lodash.isequal';

import { PositionsByGeozoneType, ObjectDistanceType } from 'components/notifications/utils/types';
import { defaultConditions, NOTIFICATION_GEOZONE_ENTITY } from 'components/notifications/utils/consts';
import {
  MultipleSelectGroupOption,
  MultipleSelectGroupTranslate,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';

import { RootState } from 'reducers';
import { ConditionsType, InterpositionConditions } from 'reducers/notifications/interface';

import AccessEntity from 'utils/accessEntity';

const { interposition: defaults } = defaultConditions;

type Params = {
  isFoulFixing: boolean;
  setIsFoulFixing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useInterpostionInfo(params: Params) {
  const t = useFormatMessage();

  const geozonesPermission = useSelector((state: RootState) => state.user.permissions.geozones);
  const geozonesAccess = useMemo(() => new AccessEntity(geozonesPermission), [geozonesPermission]);
  const { geozonesShort, groups: geozoneGroups } = useSelector((state: RootState) => state.geozone);
  const { chosenNotification, chosenNotificationType } = useSelector((state: RootState) => state.notifications);

  const [objectsDistance, setObjectsDistance] = useState(defaults.objectsDistance);
  const [radius, setRadius] = useState(defaults.radius);
  const [fixingTimeLimit, setFixingTimeLimit] = useState(defaults.fixingTimeLimit);
  const [isAreaLimited, setIsAreaLimited] = useState(defaults.isAreaLimited);
  const [positionByGeozone, setPositionByGeozone] = useState(defaults.positionByGeozone);
  const [geozoneEntities, setGeozoneEntities] = useState(defaults.geozones);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const [geozoneEntitiesTranslatesForSelect] = useState<MultipleSelectGroupTranslate[]>(
    Object.keys(NOTIFICATION_GEOZONE_ENTITY).map(key => ({
      name: key,
      translate: t(
        `notifications.card.tabs.conditions.field.geozone.geozone-list.translate.${NOTIFICATION_GEOZONE_ENTITY[
          key as keyof typeof NOTIFICATION_GEOZONE_ENTITY
        ].toLowerCase()}.text`
      ),
    }))
  );

  const geozoneEntitiesForSelect: MultipleSelectGroupOption[] = useMemo(() => {
    if (!geozonesAccess.isAllowRead()) {
      return [];
    }

    return [
      {
        name: NOTIFICATION_GEOZONE_ENTITY.GeozoneGroup,
        options: geozoneGroups.map(group => ({
          value: group.id,
          label: group.attributes.name,
          data: { ...group },
        })),
      },
      {
        name: NOTIFICATION_GEOZONE_ENTITY.Geozone,
        options: geozonesShort.map(geozone => ({
          value: geozone.id,
          label: geozone.attributes.name,
          data: { ...geozone },
        })),
      },
    ];
  }, [geozonesAccess, geozonesShort, geozoneGroups]);

  const fixingTimeLimitRef = React.createRef();

  useEffect(() => {
    let conditions: ConditionsType = null;

    if (chosenNotification && chosenNotificationType === chosenNotification.attributes.notificationType) {
      conditions = chosenNotification.attributes.conditions as InterpositionConditions;
    } else {
      conditions = defaults;
    }
    setObjectsDistance(conditions.objectsDistance);
    setRadius(conditions.radius);
    setFixingTimeLimit(conditions.fixingTimeLimit);
    setIsAreaLimited(conditions.isAreaLimited);
    setPositionByGeozone(conditions.positionByGeozone);
    setGeozoneEntities(conditions.geozones);
  }, [chosenNotification, chosenNotificationType]);

  const handleCheckedFoulFixing = () => params.setIsFoulFixing(!params.isFoulFixing);
  const handleCheckedFixation = (type: PositionsByGeozoneType) => setPositionByGeozone(type);
  const handleCheckedObjectsDistance = (type: ObjectDistanceType) => setObjectsDistance(type);
  const handleCheckedAreaLimited = () => setIsAreaLimited(!isAreaLimited);

  const handleChangeRadiusValue = (value: string) => {
    const nValue = Number(value);

    if (!isNaN(nValue) && nValue >= 1) {
      setRadius(nValue);
    } else if (!value) {
      setRadius(-1);
    }
  };
  const handleChangeFixingTimeLimitValue = (date: Date) => setFixingTimeLimit(date.getMinutes() * 60);

  const handleChangeGeozones = (values: MultipleSelectGroupOption[]) => {
    const newValues = values.map(groupValue => ({
      type: groupValue.name as NOTIFICATION_GEOZONE_ENTITY,
      entityIds: Array.from(new Set(groupValue.options.map(option => option.data?.id as string).filter(id => id))),
    }));

    setGeozoneEntities(newValues);
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (radius < 1) {
      newInvalidFields.push('radiusInterposition');
    }
    if (isAreaLimited && !geozoneEntities.length) {
      newInvalidFields.push('geozoneEntitiesInterposition');
    }
    if (params.isFoulFixing && fixingTimeLimit < defaults.fixingTimeLimit) {
      newInvalidFields.push('foulFixingInterposition');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    const currentInterpositionConditions =
      (chosenNotification?.attributes.conditions as InterpositionConditions) || defaults;

    if (
      objectsDistance !== currentInterpositionConditions.objectsDistance ||
      radius !== currentInterpositionConditions.radius ||
      fixingTimeLimit !== currentInterpositionConditions.fixingTimeLimit ||
      isAreaLimited !== currentInterpositionConditions.isAreaLimited ||
      positionByGeozone !== currentInterpositionConditions.positionByGeozone ||
      !isEqual(geozoneEntities, currentInterpositionConditions.geozones)
    ) {
      return true;
    }
    return false;
  };

  return {
    states: {
      objectsDistance,
      radius,
      fixingTimeLimit,
      isAreaLimited,
      positionByGeozone,
      geozoneEntities,
      geozoneEntitiesForSelect,
      geozoneEntitiesTranslatesForSelect,
    },
    handlers: {
      handleCheckedFoulFixing,
      handleCheckedFixation,
      handleCheckedObjectsDistance,
      handleCheckedAreaLimited,
      handleChangeRadiusValue,
      handleChangeFixingTimeLimitValue,
      handleChangeGeozones,
    },
    refs: {
      fixingTimeLimitRef,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
