import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Structure } from 'components/monitoring/utils/types';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { getUnitIdsByStructure } from 'components/monitoring/utils/getUnitIdsByStructure';
import { useSortingSubstructures, useSortingUnits } from 'components/monitoring/utils/useSorting';

import { RootState } from 'reducers';
import { Unit } from 'reducers/trackableUnits/interface';
import { toggleOpenStructure, toggleSelectionOfUnits } from 'reducers/monitoring';

import { UnitItem } from '../unitItem/unitItem';

import styles from './monitoringRow.module.scss';

type Props = {
  structure: Structure;
  deepLevel: number;
  isWide: boolean;
};

export function MonitoringRow({ structure, deepLevel, isWide }: Props) {
  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);
  const openedStructures = useSelector((state: RootState) => state.monitoring.openedStructures);
  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);

  const substructuresArr = useSortingSubstructures(structure.substructures);
  const unitsArr = useSortingUnits(structure.units);

  const dispatch = useDispatch();
  const isStructureOpened = openedStructures.includes(structure.uniqueKey);

  const structureUnitIds = getUnitIdsByStructure(structure);

  const isStructureSelected =
    structureUnitIds.length > 0 &&
    structureUnitIds.length === selectedUnits.filter(u => structureUnitIds.includes(u)).length;
  const isStructureIndeterminate =
    Boolean(structureUnitIds.filter(u => selectedUnits.includes(u)).length) &&
    structureUnitIds.length !== selectedUnits.filter(u => structureUnitIds.includes(u)).length;

  const handleExpandClick = () => {
    dispatch(toggleOpenStructure({ id: structure.uniqueKey, currentStatus: isStructureOpened }));
  };

  const handleCheckClick = () => {
    const idToTrackableUnitMap: { [id: string]: Unit } = {};

    for (const unit of trackableUnits) {
      idToTrackableUnitMap[unit.id] = unit;
    }

    const structureUnitsWithTrackerIds = structureUnitIds.filter(id => idToTrackableUnitMap[id].attributes.trackerId);
    const checkboxStatusForItemsWithTracker = structureUnitsWithTrackerIds.every(id => selectedUnits.includes(id));
    const unitIds = checkboxStatusForItemsWithTracker ? structureUnitIds : structureUnitsWithTrackerIds;

    dispatch(toggleSelectionOfUnits({ currentStatus: checkboxStatusForItemsWithTracker, unitIds }));
  };

  return (
    <div className={styles.structureList}>
      <div className={styles.structureItem} style={{ marginLeft: `${deepLevel * 15}px` }}>
        <ExpandButton isOpen={isStructureOpened} onClick={handleExpandClick} style={styles.structureItemExpand} />
        <div className={styles.structureItemCheckbox}>
          <Checkbox
            checked={isStructureSelected}
            isIndeterminate={isStructureIndeterminate}
            handleCheck={handleCheckClick}
          />
        </div>
        <div className={styles.structureItemName}>{structure.name}</div>
      </div>
      {isStructureOpened &&
        (structure.units.length
          ? unitsArr.map((unit, unitIndex) => {
              return (
                <UnitItem
                  key={`${structure.uniqueKey}-${structure.name}-${unitIndex}`}
                  unit={unit}
                  paddingLeft={(deepLevel + 1) * 15}
                  isWide={isWide}
                />
              );
            })
          : substructuresArr.map((substructureKey, substructureIndex) => {
              return (
                <MonitoringRow
                  key={`${structure.uniqueKey}-${structure.name}-${substructureIndex}`}
                  structure={structure.substructures[substructureKey]}
                  deepLevel={deepLevel + 1}
                  isWide={isWide}
                />
              );
            }))}
    </div>
  );
}
