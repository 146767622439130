import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { defaultStateMessageCreate as defaults } from 'components/messages/utils/consts';

import { RootState } from 'reducers';

export default function useMessagesCreate() {
  const { trackableUnits } = useSelector((state: RootState) => state.trackableUnit);

  const [objects, setObjects] = useState(defaults.objects);
  const [text, setText] = useState(defaults.text);

  const trackableUnitsForSelect: ValuesType = useMemo(
    () =>
      trackableUnits.map(unit => ({ value: unit.id, label: unit.attributes.aggregatedName, data: { id: unit.id } })),
    [trackableUnits]
  );

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const handleChangeObjects = (values: ValuesType) => {
    const valuesIds = values.map(val => val.value);
    const filteredUnits = trackableUnits.filter(unit => valuesIds.includes(unit.id)).map(unit => unit.id);

    setObjects(filteredUnits);
  };

  const handleChangeText = (value: string) => {
    setText(value);
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!objects.length) {
      newInvalidFields.push('messageTrackableUnits');
    }
    if (!text) {
      newInvalidFields.push('messageText');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    const changes = { text, objects };

    return !isEqual(changes, defaults);
  };

  return {
    states: {
      text,
      objects,
    },
    additionalStates: {
      trackableUnitsForSelect,
    },
    handlers: {
      handleChangeObjects,
      handleChangeText,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
