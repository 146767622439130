import { reqHandlers } from 'utils/api';
import { ChosenTrackerAttributes, CreateTracker } from './interface';

const PATH = '/api/trackers';

export function getTrackers() {
  return reqHandlers.get(PATH);
}

export function createTracker(data: CreateTracker) {
  return reqHandlers.post(PATH, data);
}

export function deleteTracker(id: number) {
  return reqHandlers.delete(`${PATH}/${id}`);
}

export function getOneTracker(id: number) {
  return reqHandlers.get(`${PATH}/${id}`);
}

export function putTracker(data: ChosenTrackerAttributes) {
  return reqHandlers.put(`${PATH}/${data.id}`, data);
}

export function searchTrackers(val: string) {
  return reqHandlers.get(`${PATH}/search?string=${val}`);
}
