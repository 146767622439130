import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PoiMarkerProperties } from 'components/map/map.types';
import { PoiTypesEnum } from 'components/poi/utils/consts';
import {
  PoiCardAccidentData,
  PoiCardEquipmentData,
  PoiCardFreePointData,
  PoiCardGazAnalyzerData,
  PoiCardSensorData,
  PoiCardVideoCameraData,
} from 'components/poi/utils/types';
import { deleteOnePoi, getOnePoi, getPoi, createOnePoi, putOnePoi } from './api';
import { ChosenPoiJSONApi, PoiCreate, PoiJSONApi, PoiPayloadStatusType, PoiState, PoiCardData, Poi } from './interface';

const initialState: PoiState = {
  data: [],
  isDataLoading: false,
  needUpdate: false,

  poiCardType: null,
  poiCardData: null,

  chosenPoi: null,
  isChosenPoiLoading: false,

  showingPoiIds: [],

  selectedPoi: [],

  chosenClusterPoiList: [],

  error: null,
};

export const fetchPoi = createAsyncThunk('poi/fetchPoi', async () => await getPoi());

export const fetchOnePoi = createAsyncThunk('poi/fetchOnePoi', async (id: number) => await getOnePoi(id));

export const addOnePoi = createAsyncThunk(
  'poi/addOnePoi',
  async ({ data }: { data: PoiCreate }) => await createOnePoi(data)
);

export const updateOnePoi = createAsyncThunk(
  'poi/updateOnePoi',
  async ({ id, data }: { id: number; data: Record<string, unknown> }) => await putOnePoi(id, data)
);

export const removeOnePoi = createAsyncThunk('poi/removeOnePoi', async (id: number) => await deleteOnePoi(id));

const poiSlice = createSlice({
  name: 'poiSlice',
  initialState,
  reducers: {
    setPoiData: (state, { payload }: { payload: Poi[] }) => {
      state.data = payload;
    },

    setPoiCardType: (state, { payload }: { payload: PoiTypesEnum | null }) => {
      state.poiCardType = payload;
    },

    setSelectedPoi: (state, { payload }: { payload: PoiPayloadStatusType }) => {
      payload.ids.forEach(id => {
        if (payload.status) {
          if (!state.selectedPoi.includes(id)) {
            state.selectedPoi.push(id);
          }
        } else {
          if (state.selectedPoi.includes(id)) {
            const index = state.selectedPoi.findIndex(item => item === id);
            if (index > -1) {
              state.selectedPoi.splice(index, 1);
            }
          }
        }
      });
    },
    setSelectedPoiDirectly: (state, { payload }: { payload: number[] }) => {
      state.selectedPoi = payload;
    },
    toggleCheckedAllPoi: (state, { payload }: { payload: boolean }) => {
      if (payload) {
        state.selectedPoi = state.data.map(poi => poi.id);
      } else {
        state.selectedPoi = [];
      }
    },

    setTurnedPoi: (state, { payload }: { payload: PoiPayloadStatusType }) => {
      state.data = state.data.map(item => {
        if (payload.ids.includes(item.id)) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              isOn: payload.status,
            },
          };
        }
        return item;
      });
    },
    toggleTurnAllPoi: (state, { payload }: { payload: boolean }) => {
      state.data = state.data.map(poi => ({
        ...poi,
        attributes: { ...poi.attributes, isOn: payload },
      }));
    },

    setChosenPoiAttributesData: (state, { payload }: { payload: { key: string; value: unknown } }) => {
      let newChosenPoi = state.chosenPoi;
      if (newChosenPoi) {
        newChosenPoi = {
          ...newChosenPoi,
          [payload.key]: payload.value,
        };
      }
      state.chosenPoi = newChosenPoi;
    },
    clearChosenPoi: state => {
      state.chosenPoi = null;
    },

    setPoiCardData: (state, { payload }: { payload: PoiCardData | null }) => {
      if (payload) {
        state.poiCardData = {
          ...state.poiCardData,
          ...payload,
        };
      } else {
        state.poiCardData = null;
      }
    },
    setPoiCardFieldData: (
      state,
      {
        payload,
      }: {
        payload: {
          key:
            | keyof PoiCardVideoCameraData
            | keyof PoiCardGazAnalyzerData
            | keyof PoiCardSensorData
            | keyof PoiCardEquipmentData
            | keyof PoiCardAccidentData
            | keyof PoiCardFreePointData;
          value: unknown;
        };
      }
    ) => {
      if (state.poiCardData) {
        state.poiCardData = {
          ...state.poiCardData,
          [payload.key]: payload.value,
        };
      }
    },

    setShowingPoiId: (state, { payload }: { payload: number | null }) => {
      if (payload) {
        state.showingPoiIds = [payload];
      } else {
        state.showingPoiIds = [];
      }
    },
    setAllShowingIds: state => {
      state.showingPoiIds = state.data.map(poi => poi.id);
    },

    setChosenClusterPoiList: (state, { payload }: { payload: PoiMarkerProperties[] }) => {
      state.chosenClusterPoiList = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPoi.pending, state => {
        state.error = null;
        state.isDataLoading = true;
      })
      .addCase(fetchPoi.fulfilled, (state, { payload }: { payload: PoiJSONApi }) => {
        state.data = payload.data;
        state.isDataLoading = false;
        state.needUpdate = false;
      })
      .addCase(fetchPoi.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isDataLoading = false;
      });
    builder
      .addCase(fetchOnePoi.pending, state => {
        state.isChosenPoiLoading = true;
      })
      .addCase(fetchOnePoi.fulfilled, (state, { payload }: { payload: ChosenPoiJSONApi }) => {
        state.chosenPoi = payload.data;
        state.isChosenPoiLoading = false;
      })
      .addCase(fetchOnePoi.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.chosenPoi = null;
        state.isChosenPoiLoading = false;
      });
    builder
      .addCase(addOnePoi.pending, state => {
        state.error = null;
      })
      .addCase(addOnePoi.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(addOnePoi.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateOnePoi.pending, state => {
        state.error = null;
      })
      .addCase(updateOnePoi.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(updateOnePoi.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeOnePoi.pending, state => {
        state.error = null;
      })
      .addCase(removeOnePoi.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(removeOnePoi.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
  },
});

export const {
  setPoiData,

  setPoiCardType,

  setSelectedPoi,
  setSelectedPoiDirectly,
  toggleCheckedAllPoi,

  setTurnedPoi,
  toggleTurnAllPoi,

  setChosenPoiAttributesData,
  clearChosenPoi,

  setPoiCardData,
  setPoiCardFieldData,

  setShowingPoiId,
  setAllShowingIds,

  setChosenClusterPoiList,
} = poiSlice.actions;

export default poiSlice.reducer;
