import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';

type Props = {
  onSave: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  deleteButtonText?: string;
  cancelButtonText?: string;
  saveButtonText?: string;
  withoutDelete?: boolean;
  withoutSave?: boolean;
  disabledSaveButton?: boolean;
  disabledDeleteButton?: boolean;
};

export const ModalControls = ({
  onSave,
  onCancel,
  onDelete,
  deleteButtonText,
  cancelButtonText,
  saveButtonText,
  withoutDelete,
  withoutSave,
  disabledSaveButton,
  disabledDeleteButton,
}: Props) => {
  const t = useFormatMessage();

  return (
    <>
      {!withoutDelete && (
        <Button
          red
          text={deleteButtonText ? deleteButtonText : t('monitoring.unit-card.footer.btn.delete.label')}
          onClick={onDelete}
          disabled={disabledDeleteButton}
        />
      )}
      <Button
        white
        text={cancelButtonText ? cancelButtonText : t('monitoring.unit-card.footer.btn.cancel.label')}
        onClick={onCancel}
      />
      {!withoutSave && (
        <Button
          blue
          text={saveButtonText ? saveButtonText : t('monitoring.unit-card.footer.btn.save.label')}
          onClick={onSave}
          disabled={disabledSaveButton}
        />
      )}
    </>
  );
};
