import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

import { RTSP_PROXY_PORT } from 'utils/consts';

type Props = {
  className: string;
  proxyPath: string;
};

const proxyBaseUrl = `${window.location.protocol}//${window.location.hostname}:${RTSP_PROXY_PORT}`;

const InternalHlsPlayer: React.FC<Props> = ({ className, proxyPath }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const hls = new Hls();

    hls.on(Hls.Events.ERROR, (evt, data) => {
      if (data.fatal) {
        hls.destroy();
      }
    });

    if (videoRef.current) {
      hls.loadSource(`${proxyBaseUrl}/${proxyPath}/index.m3u8`);
      hls.attachMedia(videoRef.current);
    }

    return () => {
      hls.destroy();
    };
  }, [proxyPath]);

  return <video ref={videoRef} className={className} autoPlay={true} controls />;
};

export default React.memo(InternalHlsPlayer);
