import React, { useEffect, useState } from 'react';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import styles from './textArea.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  label: string;
  placeholder: string;

  value?: string;

  isRequired?: boolean;
  isValueError?: boolean;

  autoSetHeight?: boolean;

  handleChange: (value: string) => void;
  containerStyle?: string;
  areaStyle?: string;
};

export const TextArea = (props: Props) => {
  const ref = React.createRef<HTMLTextAreaElement>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (props.autoSetHeight) {
      const textarea = ref.current;

      if (!textarea) {
        return;
      }
      const paddingTop = parseInt(window.getComputedStyle(textarea).paddingTop) || 0;
      textarea.style.height = '';
      textarea.style.height = textarea.scrollHeight - paddingTop + 'px';
    }
  }, [ref, props.autoSetHeight]);

  const containerClasses = cx(styles.container, {
    [styles.containerFocused]: isFocused,
    [props.containerStyle ?? '']: Boolean(props.containerStyle),
  });
  const areaClasses = cx(styles.textarea, {
    [styles.warning]: props.isValueError,
    [props.areaStyle ?? '']: Boolean(props.areaStyle),
  });
  const labelClasses = cx(styles.label, {
    [styles.labelFocused]: isFocused,
  });
  return (
    <div className={containerClasses}>
      {props.isRequired && <Warning className={styles.iconWarning} />}
      <div className={labelClasses}>{props.label}</div>
      <textarea
        className={areaClasses}
        placeholder={props.placeholder}
        onChange={ev => props.handleChange(ev.target.value)}
        value={props.value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
      />
    </div>
  );
};
