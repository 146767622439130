import React, { ChangeEvent, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as Warning } from 'assets/img/warning.svg';
import DefaultMarker from 'assets/img/marker.svg';

import { getBase64FromImageSrc } from 'utils/getBase64FromImageSrc';
import { MAX_UPLOAD_FILE_SIZE } from 'utils/consts';

import styles from './inputIconFile.module.scss';

export const MAX_ICON_SIZE = 128; // px

const cx = classNames.bind(styles);
const wrapperDefaultStyle = styles.wrapper;

interface InputProps {
  label?: string;

  handleChange?: (valueBase64: string) => void;

  defaultSrc?: string;
  iconStr: string | null;

  isRequired?: boolean;
  isValueError?: boolean;

  disabled?: boolean;

  customWrapperStyle?: string;
}

function InputIconFile(props: InputProps) {
  const t = useFormatMessage();

  const [isFocused, setIsFocused] = useState(false);
  const [imageSrc, setImageSrc] = useState(props.iconStr || props.defaultSrc || DefaultMarker);

  const [isDefaultImage, setIsDefaultImage] = useState(true);

  const wrapClasses = cx(wrapperDefaultStyle, props.customWrapperStyle, {
    [styles.wrapperWarning]: props.isValueError && isDefaultImage,
    [styles.wrapperFocused]: isFocused,
    [styles.wrapperDisabled]: props.disabled,
  });

  // определение фокуса
  useEffect(() => {
    function handler(evt: MouseEvent) {
      const target = evt.target as Element;

      if (target?.closest(`.${wrapperDefaultStyle}`)) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    }

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  // определение статуса дефолтной иконки
  useEffect(() => {
    if (props.iconStr) {
      getBase64FromImageSrc(props.iconStr).then(base64 => {
        if (props.iconStr === base64) {
          setIsDefaultImage(true);
        } else {
          setIsDefaultImage(false);
        }
      });
    }
  }, [props.iconStr]);

  const labelClasses = cx(styles.label, {
    [styles.labelFocused]: isFocused,
    [styles.labelDisabled]: props.disabled,
  });

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const input = ev.target;
    const files = input.files;

    if (files?.length) {
      const iconFile = files[0];

      if (iconFile.size > MAX_UPLOAD_FILE_SIZE) {
        return toast.warn(t('input-icon-file.error.size.text'));
      }
      if (!iconFile.type.match(/^image\//)) {
        return toast.warn(t('input-icon-file.error.no-icon.text'));
      }

      const fileReader = new FileReader();

      fileReader.readAsDataURL(iconFile);
      fileReader.onload = () => {
        const iconBase64 = fileReader.result;
        const tempImage = document.createElement('img');

        if (iconBase64) {
          tempImage.src = iconBase64 as string;
          tempImage.onload = () => {
            const maxValue = Math.max(tempImage.width, tempImage.height);

            if (maxValue > MAX_ICON_SIZE) {
              return toast.warn(t('input-icon-file.error.bad-icon-size.text'));
            }
            if (props.handleChange) {
              setImageSrc(iconBase64 as string);
              props.handleChange(iconBase64 as string);
            }
          };
        }
      };
      fileReader.onerror = () => {
        toast.error(t('input-icon-file.error.read.text'));
      };
    }
  };

  const handleReset = async () => {
    const newIconSrc = props.defaultSrc || DefaultMarker;
    const newIconBase64 = await getBase64FromImageSrc(newIconSrc);

    if (newIconBase64) {
      setImageSrc(newIconBase64);
      if (props.handleChange) {
        props.handleChange(newIconBase64);
      }
    }
  };

  return (
    <div className={wrapClasses}>
      {props.isRequired && isDefaultImage && <Warning className={styles.iconWarning} />}
      <label className={styles.inputWrapper}>
        <span className={labelClasses}>{props.label || t('input-icon-file.label')}</span>
        <img
          src={imageSrc}
          alt="img-icon"
          className={cx(styles.img, {
            [styles.imgDisabled]: props.disabled,
          })}
        />
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          className={styles.input}
          required={props.isRequired}
          disabled={props.disabled || false}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </label>
      {!isDefaultImage && <button type="button" className={styles.clearBtn} onClick={handleReset} />}
    </div>
  );
}

export default InputIconFile;
