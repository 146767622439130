import { useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { Notification, NotificationsGroup } from 'reducers/notifications/interface';

import { NOTIFICATION_SORTING_BY } from '../utils/consts';

const { name, triggered, objects } = NOTIFICATION_SORTING_BY;

type SortingType = {
  a: Notification;
  b: Notification;
  type: NOTIFICATION_SORTING_BY;
  ascending: boolean;
};

function sortingItems({ a, b, type, ascending }: SortingType) {
  const aName = a.attributes.name;
  const bName = b.attributes.name;
  const aMeta = a.meta;
  const bMeta = b.meta;

  switch (type) {
    case name:
      if (ascending) {
        return aName < bName ? -1 : 1;
      }
      return aName < bName ? 1 : -1;

    case triggered:
      if (ascending) {
        return aMeta.numberOfNotified < bMeta.numberOfNotified ? -1 : 1;
      }
      return aMeta.numberOfNotified < bMeta.numberOfNotified ? 1 : -1;

    case objects:
      if (ascending) {
        return aMeta.numberOfUnits < bMeta.numberOfUnits ? -1 : 1;
      }
      return aMeta.numberOfUnits < bMeta.numberOfUnits ? 1 : -1;

    default:
      break;
  }
  return 0;
}

// сортировка отдельных нотификаций
export function useSortingItems(items: Notification[]) {
  const { type, ascending } = useSelector((state: RootState) => state.notifications.sort);

  if (!items.length) {
    return [];
  }

  return [...items].sort((a, b) => sortingItems({ a, b, type, ascending }));
}

// сортировка групп нотификаций
export function useSortingGroupItems(groups: (NotificationsGroup | null)[]) {
  const { notifications, sort } = useSelector((state: RootState) => state.notifications);
  const { type, ascending } = sort;

  // сортировка если одно из значений null
  const checkGroups = groups.reduce((pred, cur) => {
    return cur ? pred + 1 : pred - 1;
  }, 0);

  if (checkGroups < 0) {
    return [];
  }

  function sortingGroups(a: NotificationsGroup | null, b: NotificationsGroup | null) {
    if (!a || !b) {
      return 0;
    }
    const aNIds = a.notificationsIds;
    const bNIds = b.notificationsIds;

    const aNotifications: Notification[] = [];
    const bNotifications: Notification[] = [];

    notifications.forEach(notification => {
      if (aNIds.includes(notification.id)) {
        aNotifications.push(notification);
      }
      if (bNIds.includes(notification.id)) {
        bNotifications.push(notification);
      }
    });

    if (aNotifications.length > 1) {
      const sort = aNotifications.sort((aNotify, bNotify) => sortingItems({ a: aNotify, b: bNotify, type, ascending }));

      a.notificationsIds = sort.map(notification => notification.id);
    }

    if (bNotifications.length > 1) {
      const sort = bNotifications.sort((aNotify, bNotify) => sortingItems({ a: aNotify, b: bNotify, type, ascending }));

      b.notificationsIds = sort.map(notification => notification.id);
    }

    switch (type) {
      case name:
        if (ascending) {
          return a.name < b.name ? -1 : 1;
        }
        return a.name < b.name ? 1 : -1;

      case triggered:
        if (ascending) {
          return a.numberOfNotified < b.numberOfNotified ? -1 : 1;
        }
        return a.numberOfNotified < b.numberOfNotified ? 1 : -1;

      case objects:
        if (ascending) {
          return a.numberOfUnits < b.numberOfUnits ? -1 : 1;
        }
        return a.numberOfUnits < b.numberOfUnits ? 1 : -1;

      default:
        break;
    }
    return 0;
  }

  if (!groups.length) {
    return [];
  }

  return [...groups].sort(sortingGroups);
}
