import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import { TabObjectStat } from './components/tabObjectStat/tabObjectStat';

import styles from '../tabsModal.module.scss';

export const StatsTab = () => {
  const chosenNotification = useSelector((state: RootState) => state.notifications.chosenNotification);

  return (
    <div className={styles.modalContent}>{!!chosenNotification && <TabObjectStat id={chosenNotification.id} />}</div>
  );
};
