import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TrackCreation from './components/trackCreation/trackCreation';
import TrackList from './components/trackList/trackList';
import { fetchGetTracksGroups, fetchGetTracks } from 'reducers/tracks';
import { RootState } from 'reducers';

const Tracks = () => {
  const dispatch = useDispatch();
  const { needUpdate } = useSelector((state: RootState) => state.track);

  const [showCreateTrack, setShowCreateTrack] = useState(false);

  useEffect(() => {
    dispatch(fetchGetTracksGroups());
    dispatch(fetchGetTracks());
  }, [dispatch]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(fetchGetTracksGroups());
      dispatch(fetchGetTracks());
    }
  }, [dispatch, needUpdate]);

  const handleClose = () => {
    setShowCreateTrack(false);
  };

  return (
    <>
      {showCreateTrack && <TrackCreation handleClose={handleClose} />}
      {!showCreateTrack && <TrackList handleOpenCreateTrack={setShowCreateTrack} />}
    </>
  );
};

export default Tracks;
