import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';
import UploadFile from 'components/common/uploadFile/uploadFile';

import { RootState } from 'reducers';
import { clearFileImportingResponse, exportElementsToFile, importElementsFromFile } from 'reducers/handbooks';
import { showModal } from 'reducers/modal';

import AccessEntity from 'utils/accessEntity';
import { STATISTIC_HANDBOOK, MAX_UPLOAD_FILE_SIZE } from 'utils/consts';

import styles from './handbooksElementsView.module.scss';

type Props = {
  accessEntity: AccessEntity;
  handleCreateClick: () => void;
};

const HandbooksElementsButtons: React.VFC<Props> = ({ accessEntity, handleCreateClick }) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { chosenHandbookName, isFileImporting, fileImportingResponse, isFileExporting } = useSelector(
    (state: RootState) => state.handbooks
  );

  useEffect(() => {
    if (!isFileImporting && fileImportingResponse) {
      const text = `
        ${
          fileImportingResponse.completed
            ? t('toast.handbooks.file-importing.success.text')
            : t('toast.handbooks.file-importing.fail.text')
        }!
        ${t('toast.handbooks.file-importing.total.text')}: ${fileImportingResponse.total},
        ${t('toast.handbooks.file-importing.limit.text')}: ${fileImportingResponse.limit}
      `;
      fileImportingResponse.completed ? toast.success(text) : toast.error(text);
      dispatch(clearFileImportingResponse());
    }
  }, [t, dispatch, isFileImporting, fileImportingResponse]);

  const handleUploadFile = (file: File) => {
    if (file.type !== 'text/csv') {
      return toast.warning(t('toast.handbooks.wrong-type-file.error.text'));
    }
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      return toast.warning(t('toast.handbooks.large-file-size.error.text'));
    }
    if (chosenHandbookName) {
      dispatch(importElementsFromFile({ key: chosenHandbookName.keyName, file }));
    }
  };

  const handleExportFile = () => {
    if (chosenHandbookName) {
      dispatch(exportElementsToFile(chosenHandbookName.keyName));
    }
  };

  const handleShowStatistic = () => {
    dispatch(showModal({ type: STATISTIC_HANDBOOK, which: '' }));
  };

  return (
    <div className={styles.buttons}>
      {(accessEntity.isAllowCreate() || accessEntity.isAllowUpdate()) && (
        <UploadFile onUpload={file => handleUploadFile(file)}>
          <Button white text={t('handbooks.column.elements.footer.btn.import.text')} disabled={isFileImporting} />
        </UploadFile>
      )}
      <Button
        white
        text={t('handbooks.column.elements.footer.btn.export.text')}
        onClick={handleExportFile}
        disabled={isFileExporting}
      />
      <Button white text={t('handbooks.column.elements.footer.btn.statistic.text')} onClick={handleShowStatistic} />
      {accessEntity.isAllowCreate() && (
        <Button blue text={t('handbooks.column.elements.footer.btn.create.text')} onClick={handleCreateClick} />
      )}
    </div>
  );
};

export default HandbooksElementsButtons;
