import React from 'react';
import { useSelector } from 'react-redux';
import DeleteTrackerModal from 'components/trackers/delete.modal';
import DeleteEmployeeModalForm from 'components/monitoring/employee/employee.delete';
import DeleteTransportModalForm from 'components/monitoring/transport/transport.delete';
import DeleteTrackModalForm from 'components/tracks/components/trackModals/trackDeleteAlert.modal';
import GeozoneDeleteAlert from 'components/geozones/components/geozoneDeleteAlert/geozoneDeleteAlert';
import GeozoneGroupMultipleDeleteAlert from 'components/geozones/components/geozoneGroupMultipleDeleteAlert/geozoneGroupMultipleDeleteAlert';
import HandbooksDeleteAlert from 'components/handbooks/components/handbooksTable/components/handbooksCard/components/defaultHandbookCard/handbooksDeleteAlert';
import styles from './modal.module.scss';
import { RootState } from 'reducers';
import {
  DELETE_TRACKER,
  DELETE_EMPLOYEE,
  DELETE_TRANSPORT,
  DELETE_GEOZONE,
  DELETE_GEOZONE_GROUP_MULTIPLE,
  DELETE_TRACK,
  DELETE_HANDBOOKS_NOTE,
} from 'utils/consts';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const AlertModal = () => {
  const { alertIsShown, alertType } = useSelector((state: RootState) => state.modal);

  if (!alertIsShown) {
    return <></>;
  }

  switch (alertType) {
    case DELETE_TRACKER: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <DeleteTrackerModal />
          </div>
        </div>
      );
    }
    case DELETE_EMPLOYEE: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <DeleteEmployeeModalForm />
          </div>
        </div>
      );
    }
    case DELETE_TRACK: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <DeleteTrackModalForm />
          </div>
        </div>
      );
    }
    case DELETE_TRANSPORT: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <DeleteTransportModalForm />
          </div>
        </div>
      );
    }
    case DELETE_GEOZONE: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <GeozoneDeleteAlert />
          </div>
        </div>
      );
    }
    case DELETE_GEOZONE_GROUP_MULTIPLE: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <GeozoneGroupMultipleDeleteAlert />
          </div>
        </div>
      );
    }
    case DELETE_HANDBOOKS_NOTE: {
      return (
        <div className={cx(styles.layout, styles.alert)}>
          <div className={cx(styles.default, styles.small)}>
            <HandbooksDeleteAlert />
          </div>
        </div>
      );
    }

    default: {
      return <></>;
    }
  }
};

export default AlertModal;
