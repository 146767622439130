export const MOCK_MARKERS = [
  {
    fioMock: 'Иванов А.И.',
    fio: 'Иванов А.И.',
    data: [79.9322632640255, 68.5041954357958],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Петров Е.А.',
    fio: 'Петров Е.А.',
    data: [79.9322192585272, 68.5041194044295],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Сидоров В.В.',
    fio: 'Сидоров В.В.',
    data: [79.9322226376274, 68.5031295961106],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Воронов С.Е.',
    fio: 'Воронов С.Е.',
    data: [79.9322394331565, 68.5037339012494],
    floor: 2,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Антонова А.А.',
    fio: 'Антонова А.А.',
    data: [79.9322509276449, 68.5040471571094],
    floor: 2,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Иванов Е.И.',
    fio: 'Иванов Е.И.',
    data: [79.93220542966681, 68.50452307438819],
    floor: 2,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: 'Власов А.И.',
    fio: 'Власов А.И.',
    data: [79.9322981933809, 68.5042991672893],
    floor: 2,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '142_Визитек',
    fio: '142_Визитек',
    data: [79.802836, 68.5028061667],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '325_Визитек',
    fio: '325_Визитек',
    data: [80.0065841667, 68.5211513333],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '167_Визитек',
    fio: '167_Визитек',
    data: [79.9310683333, 68.5237275],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '227_Визитек',
    fio: '227_Визитек',
    data: [79.7889648333, 68.5333613333],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '073_Визитек',
    fio: '073_Визитек',
    data: [79.8111933333, 68.4700783333],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '151_Визитек',
    fio: '151_Визитек',
    data: [80.0428178333, 68.554715],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
  {
    fioMock: '165_Визитек',
    fio: '165_Визитек',
    data: [79.7419191667, 68.4793725],
    floor: 1,
    contacts: [
      ['Иванов И.И.', '15 контактов'],
      ['Петров С.Е.', 'Совместная работа'],
      ['Курочкин В.В', '10 контаков'],
      ['Веселов К.Н.', '7 контактов'],
      ['Перепекин М.А.', '13 контаков'],
      ['Сидоров М.К.', '50 мин контакт'],
    ],
  },
];
