import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SignalLevel } from 'utils/consts';
import {
  getOneTransport,
  getTransports,
  createTransport,
  deleteTransport,
  putTransport,
  getTransportIncludes,
} from './api';
import { AttributesWithAdditional, TransportState, TransportJSONApi } from './interface';

const defaultChosenTransportData = {
  id: 0,
  attributes: {
    transportModelId: 0,
    brandId: 0,
    regNumber: '',
    year: '',
    trackerId: null,
    transportTypeId: 0,
    organizationId: 0,
    departmentId: 0,
    driverNameId: 0,
    transportColorId: 0,
    fuelTypeId: 0,
    bearingCapacity: '',
    additionalFields: [],
    icon: null,
  },
  relationships: {
    batteryCharge: 0,
    isActive: false,
    signalLevel: SignalLevel.NULL,
  },
};

const initialState: TransportState = {
  transports: [],
  isLoading: false,
  searchedTransports: [],
  needUpdate: false,
  chosenTransport: null,
  error: null,
  selectedTransports: [],
  isIncludesLoading: false,
  includes: {
    transportModel: [],
    transportBrand: [],
    transportType: [],
    transportColor: [],
    driverName: [],
    fuelType: [],
    departments: [],
  },
  isChosenTransportDataLoading: false,
  chosenTransportData: defaultChosenTransportData,
};

export const fetchTransports = createAsyncThunk('transportSlice/fetchTransports', async () => await getTransports(''));

export const searchTransports = createAsyncThunk(
  'transportSlice/searchTransports',
  async (string: string) => await getTransports(string)
);

export const addTransport = createAsyncThunk(
  'transportSlice/addTransport',
  async (data: AttributesWithAdditional) => await createTransport(data)
);

export const updateTransport = createAsyncThunk(
  'transportSlice/updateTransport',
  async (data: AttributesWithAdditional) => await putTransport(data)
);

export const removeTransport = createAsyncThunk(
  'transportSlice/removeTransport',
  async (id: number) => await deleteTransport(id)
);

export const fetchOneTransport = createAsyncThunk(
  'transportSlice/fetchOneTransport',
  async (id: number) => await getOneTransport(id)
);

export const fetchTransportIncludes = createAsyncThunk(
  'transportSlice/fetchTransportIncludes',
  async () => await getTransportIncludes()
);

const transportSlice = createSlice({
  name: 'transportSlice',
  initialState,
  reducers: {
    setChosenTransport: (state, { payload }: { payload: number }) => {
      state.chosenTransport = payload;
    },
    removeChosenTransport: state => {
      state.chosenTransport = null;
      state.chosenTransportData = defaultChosenTransportData;
    },
    selectOneTransport: (state, { payload }: { payload: number }) => {
      state.selectedTransports = [...state.selectedTransports, { id: payload, watching: false }];
    },
    selectBatchOfTransports: (state, { payload }: { payload: number[] }) => {
      state.selectedTransports = payload.map(p => ({ id: p, watching: false }));
    },
    unselectOneTransport: (state, { payload }: { payload: number }) => {
      state.selectedTransports = state.selectedTransports.filter(t => t.id !== payload);
    },
    selectAllTransports: state => {
      state.selectedTransports = state.transports.map(e => ({ id: e.id, watching: false }));
    },
    unselectAllTransports: state => {
      state.selectedTransports = [];
    },
    watchOneTransport: (state, { payload }: { payload: number }) => {
      state.selectedTransports = state.selectedTransports.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: true,
          };
        }
        return e;
      });
    },
    unwatchOneTransport: (state, { payload }: { payload: number }) => {
      state.selectedTransports = state.selectedTransports.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: false,
          };
        }
        return e;
      });
    },
    watchAllTransports: state => {
      state.selectedTransports = state.selectedTransports.map(e => ({
        ...e,
        watching: true,
      }));
    },
    unwatchAllTransports: state => {
      state.selectedTransports = state.selectedTransports.map(e => ({
        ...e,
        watching: false,
      }));
    },
    setNeedUpdateTransport: (state, { payload }: { payload: boolean }) => {
      state.needUpdate = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransports.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchTransports.fulfilled, (state, { payload }: { payload: TransportJSONApi }) => {
        state.transports = payload.data;
        state.needUpdate = false;
        state.isLoading = false;
        state.includes = payload.includes;
      })
      .addCase(fetchTransports.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(searchTransports.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(searchTransports.fulfilled, (state, { payload }: { payload: TransportJSONApi }) => {
        state.searchedTransports = payload.data;
        state.isLoading = false;
        state.includes = payload.includes;
      })
      .addCase(searchTransports.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(addTransport.pending, state => {
        state.isChosenTransportDataLoading = true;
        state.error = null;
      })
      .addCase(addTransport.fulfilled, state => {
        state.isChosenTransportDataLoading = false;
      })
      .addCase(addTransport.rejected, (state, action) => {
        state.isChosenTransportDataLoading = false;
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeTransport.pending, state => {
        state.isChosenTransportDataLoading = true;
        state.error = null;
      })
      .addCase(removeTransport.fulfilled, state => {
        state.chosenTransport = null;
        state.needUpdate = true;
        state.isChosenTransportDataLoading = false;
      })
      .addCase(removeTransport.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenTransportDataLoading = false;
      });
    builder
      .addCase(updateTransport.pending, state => {
        state.error = null;
        state.isChosenTransportDataLoading = true;
      })
      .addCase(updateTransport.fulfilled, state => {
        state.chosenTransport = null;
        state.needUpdate = true;
        state.isChosenTransportDataLoading = false;
      })
      .addCase(updateTransport.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenTransportDataLoading = false;
      });
    builder
      .addCase(fetchOneTransport.pending, state => {
        state.error = null;
        state.isChosenTransportDataLoading = true;
      })
      .addCase(fetchOneTransport.fulfilled, (state, action) => {
        state.chosenTransportData = action.payload.data;
        state.includes = action.payload.includes;
        state.isChosenTransportDataLoading = false;
      })
      .addCase(fetchOneTransport.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenTransportDataLoading = false;
      });
    builder
      .addCase(fetchTransportIncludes.pending, state => {
        state.error = null;
        state.isIncludesLoading = true;
      })
      .addCase(fetchTransportIncludes.fulfilled, (state, action) => {
        state.includes = action.payload.includes;
        state.isIncludesLoading = false;
      })
      .addCase(fetchTransportIncludes.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isIncludesLoading = false;
      });
  },
});

export const {
  setChosenTransport,
  removeChosenTransport,
  selectOneTransport,
  unselectOneTransport,
  selectAllTransports,
  unselectAllTransports,
  watchOneTransport,
  unwatchOneTransport,
  watchAllTransports,
  unwatchAllTransports,
  selectBatchOfTransports,
  setNeedUpdateTransport,
} = transportSlice.actions;

export default transportSlice.reducer;
