import { useEffect } from 'react';
import type { RefObject } from 'react';

export const useOutsideClickHandler = (ref: RefObject<HTMLElement>, handler: () => unknown) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    }

    if (document) {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [ref, handler]);
};
