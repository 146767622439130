import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/common/button/button';
import { Spinner } from 'components/common/spinner/spinner';
import { Checkbox } from 'components/common/checkbox/checkbox';
import NotificationPopup from 'components/map/components/notificationPopup/notificationPopup';
import { TablePagination } from './components/tablePagination/tablePagination';
import { NOTIFICATION_TYPES_ENUM, NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';

import { RootState } from 'reducers';
import {
  clearHistoryLogs,
  fetchNotificationHistoryLogs,
  setFilterFields,
  setIsTableHistoryLogsShow,
  setMarkerNotificationLog,
  setSelectedHistoryLogs,
} from 'reducers/notifications';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';

import styles from './table.module.scss';

const cx = classNames.bind(styles);

function NotificationHistoryTable() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { isHistoryLogsLoading, historyLogs, historyLogsFilter, selectedHistoryLogs, markerNotificationLog } =
    useSelector((state: RootState) => state.notifications);

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [popupCoords, setPopupCoords] = useState({
    mouseCoords: { x: 0, y: 0 },
    mapCoords: { x: 0, y: 0 },
  });

  const headCheckboxStatus = selectedHistoryLogs.length === historyLogs.length && historyLogs.length > 0;
  const headCheckboxStatusIndeterminate =
    selectedHistoryLogs.length > 0 && selectedHistoryLogs.length < historyLogs.length;

  const { page = 1, limit = 1 } = historyLogsFilter;

  useEffect(() => {
    dispatch(setSelectedHistoryLogs([]));
  }, [dispatch]);

  // получение информации о historyLogs при изменении фильтра
  useEffect(() => {
    dispatch(fetchNotificationHistoryLogs(historyLogsFilter));
  }, [dispatch, historyLogsFilter]);

  // показ попапа в случае изменения координат
  useEffect(() => {
    setIsPopupShow(true);
  }, [popupCoords.mouseCoords]);

  // обработчик выбора всех элементов history logs
  const handleCheckAll = () => {
    if (headCheckboxStatus) {
      dispatch(setSelectedHistoryLogs([]));
    } else {
      dispatch(setSelectedHistoryLogs(historyLogs.map(log => log.id)));
    }
  };

  // обработчик выбора элементов history logs
  const handleCheck = (id: number) => {
    const newSelectedHistoryLogs = [...selectedHistoryLogs];

    if (newSelectedHistoryLogs.includes(id)) {
      const foundIndex = newSelectedHistoryLogs.indexOf(id);

      if (foundIndex > -1) {
        newSelectedHistoryLogs.splice(foundIndex, 1);
      }
    } else {
      newSelectedHistoryLogs.push(id);
    }
    dispatch(setSelectedHistoryLogs(newSelectedHistoryLogs));
  };

  // обработчик клика по именованной ссылке элемента history logs
  const handleClickName = (evt: React.MouseEvent, id: number) => {
    evt.stopPropagation();
    setIsPopupShow(false);
    if (!selectedHistoryLogs.includes(id)) {
      handleCheck(id);
    }

    const foundLog = historyLogs.find(hLog => hLog.id === id);

    if (foundLog) {
      const coordinatesLog = foundLog.attributes.coords.split(',').map(coordsStr => parseFloat(coordsStr));
      const coords = {
        mouseCoords: { x: evt.clientX, y: evt.clientY },
        mapCoords: { x: coordinatesLog[0], y: coordinatesLog[1] },
      };

      dispatch(setMarkerNotificationLog(foundLog));
      setPopupCoords(coords);
    }
  };

  // обработчик закрытия таблицы
  const handleClose = () => {
    if (!markerNotificationLog) {
      dispatch(setSelectedHistoryLogs([]));
      dispatch(clearHistoryLogs());
    }
    dispatch(setIsTableHistoryLogsShow(false));
    dispatch(setFilterFields({ ...historyLogsFilter, page: 1 }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4 className={styles.headerTitle}>{t('notifications.history.table.header.label')}</h4>
      </div>
      <div className={styles.table}>
        {isHistoryLogsLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className={cx(styles.tableRow, styles.tableHeader)}>
              <Checkbox
                checked={headCheckboxStatus}
                isIndeterminate={headCheckboxStatusIndeterminate}
                handleCheck={handleCheckAll}
              />
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.number.label')}
              </div>
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.date.label')}
              </div>
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.name.label')}
              </div>
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.type.label')}
              </div>
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.object.label')}
              </div>
              <div className={cx(styles.tableColumn, styles.tableColumnBold)}>
                {t('notifications.history.table.header.status.label')}
              </div>
            </div>
            <ul className={styles.tableContent}>
              {historyLogs.map((log, i) => {
                const logDate = getFullDateFromStr(log.attributes.eventTime);

                return (
                  <li className={styles.tableRow} key={`table-notification-log-${i}`}>
                    <Checkbox checked={selectedHistoryLogs.includes(log.id)} handleCheck={() => handleCheck(log.id)} />
                    <div className={styles.tableColumn}>{i + 1 + (page - 1) * limit}</div>
                    <div className={styles.tableColumn}>
                      <div>{logDate.date}</div>
                      <div>{logDate.time}</div>
                    </div>
                    <div
                      className={cx(styles.tableColumn, styles.tableColumnName)}
                      onClick={evt => handleClickName(evt, log.id)}
                    >
                      {log.attributes.text}
                    </div>
                    <div className={styles.tableColumn}>
                      {t(NOTIFICATION_TYPES_ENUM_NAMES[NOTIFICATION_TYPES_ENUM[log.attributes.notificationType]])}
                    </div>
                    <div className={styles.tableColumn}>{log.attributes.aggregatedName}</div>
                    <div className={styles.tableColumn}>
                      {log.attributes.isDone
                        ? t('notifications.history.list.status.read.text')
                        : t('notifications.history.list.status.no-read.text')}
                    </div>
                  </li>
                );
              })}
            </ul>
            <TablePagination />
          </Fragment>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          white
          text={t('notifications.history.table.button.close.label')}
          onClick={handleClose}
          customStyle={cx(styles.button, styles.buttonCreate)}
        />
      </div>
      {isPopupShow && <NotificationPopup onCancel={() => setIsPopupShow(false)} markerCoords={popupCoords} />}
    </div>
  );
}

export default React.memo(NotificationHistoryTable);
