import React, { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, View } from 'ol';

import {
  MAP_CENTER,
  MAX_ZOOM_DEFAULT_VALUE,
  MIN_ZOOM_DEFAULT_VALUE,
  ZOOM_DEFAULT_VALUE,
  EMPLOYEE,
  TRANSPORT,
} from 'utils/consts';

import { SelectControls } from './components/selectControls/selectControls';
import { SocialContactsMap } from './components/socialContactsMap/socialContactsMap';
import EmployeePopup from './components/employeePopup/employeePopup';
import TransportPopup from './components/transportPopup/transportPopup';
import CisternPopup from './components/cisternPopup/cisternPopup';
import NotificationPopup from './components/notificationPopup/notificationPopup';
import PoiPopup from './components/poiPopup/poiPopup';
import GeozonePopup from './components/geozonePopup/geozonePopup';
import TrackableUnitsClusterPopup from './components/trackableUnitsClusterPopup/trackableUnitsClusterPopup';
import NotificationClusterPopup from './components/notificationClusterPopup/notificationClusterPopup';
import PoiClusterPopup from './components/poiClusterPopup/poiClusterPopup';

import { useMarkers } from './hooks/useMarkers';
import { useFetchedData } from './hooks/useFetchedData';
import { useSetMarkerType } from './hooks/useSetMarkerType';
import { useHandlers } from './hooks/useHandlers';
import { useFloors } from './hooks/useFloors';
import { useLayers } from './hooks/useLayers';
import { useContactsRoute } from './hooks/useContactsRoute';
import { useHeatMapObjects } from './hooks/useHeatMapObjects';
import { useMapSize } from './hooks/useMapSize';
import { useDrawGeozone } from './hooks/useDrawGeozone';
import { useSelectedGeozones } from './hooks/useSelectedGeozones';
import { useControls } from './hooks/useControls';
import { useHeatMapType } from './hooks/useHeatMapType';
import { useTrack } from './hooks/useTrack';
import { useNotificationsMarkers } from './hooks/useNotificationsMarkers';
import { useNotificationsHandlers } from './hooks/useNotificationsHandlers';
import { useTrackerMarkers } from './hooks/useTrackerMarkers';
import { useTrackerHandlers } from './hooks/useTrackerHandlers';
import { usePoiMarkers } from './hooks/usePoiMarkers';
import { usePoiHandlers } from './hooks/usePoiHandlers';
import { useClusterPointPopup } from './hooks/useClusterPointPopup';
import { useReport } from './hooks/useReport';
import { useGeozone } from './hooks/useGeozone';
import { useViewportInfo } from './hooks/useViewportInfo';
import { RootState } from 'reducers';

type Props = {
  hasNoControls?: boolean;
  isFullSize?: boolean;
};

const viewSettingsObjects = {
  projection: 'EPSG:3857',
  center: MAP_CENTER,
  zoom: ZOOM_DEFAULT_VALUE,
  minZoom: MIN_ZOOM_DEFAULT_VALUE,
  maxZoom: MAX_ZOOM_DEFAULT_VALUE,
};

const viewSettings = () => {
  return new View(viewSettingsObjects);
};

const map = new Map({
  view: viewSettings(),
});

function MapComponent(props: Props) {
  const usersMapInfo = useSelector((state: RootState) => state.clientUserPreferences.usersMapInfo);
  const { lastPosition, zoom } = usersMapInfo;
  const prevCoordsRef = useRef([0, 0]);

  useEffect(() => {
    if (prevCoordsRef.current[0] !== lastPosition[0] || prevCoordsRef.current[1] !== lastPosition[1]) {
      prevCoordsRef.current = lastPosition;
      viewSettingsObjects.center = lastPosition;
      viewSettingsObjects.zoom = zoom || ZOOM_DEFAULT_VALUE;
      map.setView(new View(viewSettingsObjects));
    }
  }, [lastPosition]);

  const { mapPopup } = useSelector((state: RootState) => state.modal);
  const { employeePopup, transportPopup, contactsPopup, cisternPopup, showFlags } = useHandlers(map);
  const { notificationPopup, showNotificationPopup } = useNotificationsHandlers(map);
  const { markers } = useMarkers(map);
  const { poiPopup, showPoiPopup } = usePoiHandlers(map);

  // Обновляем див в библиотеке, необходимо, чтобы карта появлялась вновь после скрытия компонента
  // (так как див react компонента биндится к глобальному объекту map)
  const mapDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    map.setTarget(mapDivRef.current || undefined);
  }, [mapDivRef]);

  const {
    showClusterPopup,
    onCancel: onCancelShowClusterPopup,
    markerCoords: markerCoordsClusterPopup,
  } = useClusterPointPopup(map);

  const { geozonePopup, showGeozonePopup } = useGeozone(map);

  const showEmployeePopup = useMemo(
    () => mapPopup === EMPLOYEE || showFlags.showEmployeePopup,
    [mapPopup, showFlags.showEmployeePopup]
  );
  const showTransportPopup = useMemo(
    () => mapPopup === TRANSPORT || showFlags.showTransportPopup,
    [mapPopup, showFlags.showTransportPopup]
  );

  useFetchedData();
  useSetMarkerType();

  useLayers(map);
  useFloors(map);
  useControls(map);

  useNotificationsMarkers(map);

  useTrackerMarkers(map);
  useTrackerHandlers(map);

  useContactsRoute(map, markers);
  useHeatMapObjects(map);
  useHeatMapType(map, markers);
  useMapSize(map);
  useDrawGeozone(map);
  useSelectedGeozones(map);
  useTrack(map);

  usePoiMarkers(map);
  useReport(map);
  useViewportInfo(map, mapDivRef);

  return (
    <Fragment>
      <div ref={mapDivRef} id="map" />
      {!props.hasNoControls && <SelectControls />}
      {showEmployeePopup && <EmployeePopup {...employeePopup} />}
      {showTransportPopup && <TransportPopup {...transportPopup} />}
      {showFlags.showCisternPopup && <CisternPopup {...cisternPopup} />}
      {showFlags.showContacts && <SocialContactsMap {...contactsPopup} />}
      {showNotificationPopup && <NotificationPopup {...notificationPopup} />}
      {showPoiPopup && <PoiPopup {...poiPopup} />}
      {showGeozonePopup && <GeozonePopup {...geozonePopup} />}
      {showClusterPopup.trackableUnits && (
        <TrackableUnitsClusterPopup
          onCancel={onCancelShowClusterPopup}
          markerCoords={markerCoordsClusterPopup}
          handleShowContacts={employeePopup.handleShowContacts}
          handleContactsData={contactsPopup.handleContactsData}
        />
      )}
      {showClusterPopup.notification && (
        <NotificationClusterPopup onCancel={onCancelShowClusterPopup} markerCoords={markerCoordsClusterPopup} />
      )}
      {showClusterPopup.poi && (
        <PoiClusterPopup onCancel={onCancelShowClusterPopup} markerCoords={markerCoordsClusterPopup} />
      )}
    </Fragment>
  );
}

export default memo(MapComponent);
