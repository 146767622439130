import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import styles from './headerModal.module.scss';

type Props = {
  title: string;
  onCancel: () => void;
};

export const HeaderModal = ({ title, onCancel }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <AiFillCloseCircle color="#999999" className={styles.closeIcon} onClick={onCancel} />
    </div>
  );
};
