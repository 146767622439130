import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as ErrorsIcon } from 'assets/img/notifications/phonelink_setup.svg';
import { ReactComponent as GeoIcon } from 'assets/img/notifications/place.svg';
import { ReactComponent as SocialIcon } from 'assets/img/notifications/people_footer.svg';
import { ReactComponent as SensorIcon } from 'assets/img/notifications/error_outline.svg';

import { RootState } from 'reducers';
import {
  fetchCountUnreadLogs,
  setSelectedNotifications,
  fetchNotifications,
  fetchNotificationUnreadLogs,
} from 'reducers/notifications';

import { NOTIFICATIONS_LOCATION_PATH, NOTIFICATION_TYPES_ENUM } from 'components/notifications/utils/consts';
import { Times } from './components/times/times';

import styles from './notificationIcons.module.scss';

type CountsType = {
  sos: number;
  geoZones: number;
  interceptionOfObjects: number;
  sensorValue: number;
};

const { alert, geoZones, interceptionOfObjects, sensorValue } = NOTIFICATION_TYPES_ENUM;

export const NotificationIcons = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { notifications, countUnreadLogs, needUpdate } = useSelector((state: RootState) => state.notifications);

  useEffect(() => {
    if (needUpdate) {
      dispatch(fetchNotifications());
      dispatch(fetchCountUnreadLogs());
      dispatch(fetchNotificationUnreadLogs());
    }
  }, [dispatch, needUpdate]);

  const counts: CountsType = useMemo(() => {
    return countUnreadLogs.reduce(
      (prev, curr) => {
        switch (curr.notificationType) {
          case alert:
            prev = { ...prev, sos: curr.count };
            break;

          case geoZones:
            prev = { ...prev, geoZones: curr.count };
            break;

          case interceptionOfObjects:
            prev = { ...prev, interceptionOfObjects: curr.count };
            break;

          case sensorValue:
            prev = { ...prev, sensorValue: curr.count };
            break;

          default:
            break;
        }
        return prev;
      },
      {
        sos: 0,
        geoZones: 0,
        interceptionOfObjects: 0,
        sensorValue: 0,
      }
    );
  }, [countUnreadLogs]);

  const handleClick = (type: NOTIFICATION_TYPES_ENUM) => {
    const filteredNotifications = notifications.filter(
      notification => notification.attributes.notificationType === type
    );

    if (!filteredNotifications.length) {
      return;
    }
    const ids = filteredNotifications.map(n => n.id);

    dispatch(setSelectedNotifications({ ids, status: true }));
    if (location.pathname !== NOTIFICATIONS_LOCATION_PATH) {
      history.push(NOTIFICATIONS_LOCATION_PATH);
    }
  };

  const MAX_VALUE = 999;
  const MAX_VALUE_TEXT = '999+';

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        <li className={styles.item} data-tip data-for="errorsIcon" onClick={() => handleClick(alert)}>
          <ErrorsIcon />
          <ReactTooltip className={styles.customTooltip} id="errorsIcon" place="left" type="light" effect="solid">
            <span>{t('common-info.footer.notifications.tooltip.alert.text')}</span>
          </ReactTooltip>
          <span className={styles.value}>{counts.sos > MAX_VALUE ? MAX_VALUE_TEXT : counts.sos}</span>
        </li>
        <li className={styles.item} data-tip data-for="geoIcon" onClick={() => handleClick(geoZones)}>
          <GeoIcon />
          <ReactTooltip className={styles.customTooltip} id="geoIcon" place="left" type="light" effect="solid">
            <span>{t('common-info.footer.notifications.tooltip.geozones.text')}</span>
          </ReactTooltip>
          <span className={styles.value}>{counts.geoZones > MAX_VALUE ? MAX_VALUE_TEXT : counts.geoZones}</span>
        </li>
        <li className={styles.item} data-tip data-for="socialIcon" onClick={() => handleClick(interceptionOfObjects)}>
          <SocialIcon />
          <ReactTooltip className={styles.customTooltip} id="socialIcon" place="left" type="light" effect="solid">
            <span>{t('common-info.footer.notifications.tooltip.intersection-objects.text')}</span>
          </ReactTooltip>
          <span className={styles.value}>
            {counts.interceptionOfObjects > MAX_VALUE ? MAX_VALUE_TEXT : counts.interceptionOfObjects}
          </span>
        </li>
        <li className={styles.item} data-tip data-for="sensorIcon" onClick={() => handleClick(sensorValue)}>
          <SensorIcon />
          <ReactTooltip className={styles.customTooltip} id="sensorIcon" place="left" type="light" effect="solid">
            <span>{t('common-info.footer.notifications.tooltip.sensors.text')}</span>
          </ReactTooltip>
          <span className={styles.value}>{counts.sensorValue > MAX_VALUE ? MAX_VALUE_TEXT : counts.sensorValue}</span>
        </li>
      </ul>
      <Times />
    </div>
  );
};
