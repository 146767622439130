import React from 'react';
import { AuthLogin } from 'components/auth/authLogin/authLogin';

const LoginPage: React.FC = () => {
  return (
    <>
      <AuthLogin />
    </>
  );
};

export default LoginPage;
