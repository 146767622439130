import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import DatePicker from 'react-datepicker';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';

import { RootState } from 'reducers';

import Input from 'components/common/input/input';
import Button from 'components/common/button/button';

import { ONE_DAY, ONE_MONTH, ONE_WEEK } from 'utils/consts';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from './datesRangeWithScripts.module.scss';

const cx = classNames.bind(styles);

type Props = {
  handleChange: (dateFrom: string, dateTo: string) => void;

  periodFrom: string;
  periodTo: string;

  periodFromRef: React.RefObject<unknown>;
  periodToRef: React.RefObject<unknown>;

  disabled?: boolean;

  dateFormat?: string;
  timeFormat?: string;

  labelFrom?: string;
  labelTo?: string;

  placeholderFrom?: string;
  placeholderTo?: string;

  wrapperClass?: string;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} onKeyUp={props.onKeyUp} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

function DatesRangeWithScripts(props: Props) {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  const handleClickYesterday = () => {
    const toDate = new Date(Date.now()).toISOString();
    const fromDate = new Date(Date.parse(toDate) - ONE_DAY).toISOString();

    // fromDate - вчерашняя дата с полуночи
    // toDate - сегодняшняя дата до полуночи
    props.handleChange(fromDate, toDate);
  };

  const handleClickToday = () => {
    const current = new Date(Date.now());
    const currentYear = current.getFullYear();
    const currentMonth = current.getMonth();
    const currentDay = current.getDate();

    // сегодняшняя дата с полуночи
    const fromDate = new Date(currentYear, currentMonth, currentDay).toISOString();
    // сегодняшняя дата до текущего времени
    const toDate = new Date(Date.now()).toISOString();

    props.handleChange(fromDate, toDate);
  };

  const handleClickWeek = () => {
    const toDate = new Date(Date.now()).toISOString();
    const fromDate = new Date(Date.parse(toDate) - ONE_WEEK).toISOString();

    // fromDate - вчерашняя дата (неделя) с полуночи
    // toDate - сегодняшняя дата до полуночи
    props.handleChange(fromDate, toDate);
  };

  const handleClickMonth = () => {
    const toDate = new Date(Date.now()).toISOString();
    const fromDate = new Date(Date.parse(toDate) - ONE_MONTH).toISOString();

    // fromDate - вчерашняя дата (месяц) с полуночи
    // toDate - сегодняшняя дата до полуночи
    props.handleChange(fromDate, toDate);
  };

  const handlePeriodFromDate = (date: Date) => {
    props.handleChange(date.toISOString(), props.periodTo);
  };

  const handlePeriodToDate = (date: Date) => {
    props.handleChange(props.periodFrom, date.toISOString());
  };

  const handleKeyUpInput = (evt: KeyboardEvent, type: 'from' | 'to') => {
    const key = evt.key;

    if (key === 'Backspace' || key === 'Delete') {
      switch (type) {
        case 'from':
          props.handleChange('', props.periodTo);
          break;
        case 'to':
          props.handleChange(props.periodFrom, '');
          break;

        default:
          break;
      }
    }
  };

  return (
    <div
      className={cx(styles.wrapper, props.wrapperClass, {
        [styles.wrapperDisabled]: props.disabled,
      })}
    >
      <div className={styles.controls}>
        <div className={cx(styles.row, styles.rowButtonsList)}>
          <Button
            white
            text={t('dates-range.with-scripts.period.btn.yesterday.text')}
            onClick={handleClickYesterday}
            customStyle={styles.rowButton}
          />
          <Button
            white
            text={t('dates-range.with-scripts.period.btn.btn.today.text')}
            onClick={handleClickToday}
            customStyle={styles.rowButton}
          />
          <Button
            white
            text={t('dates-range.with-scripts.period.btn.week.text')}
            onClick={handleClickWeek}
            customStyle={styles.rowButton}
          />
          <Button
            white
            text={t('dates-range.with-scripts.period.btn.month.text')}
            onClick={handleClickMonth}
            customStyle={styles.rowButton}
          />
        </div>
        <div className={cx(styles.row, styles.rowDatepickerList)}>
          <DatePicker
            dateFormat={props.dateFormat || 'dd.MM.yyyy HH:mm'}
            selected={props.periodFrom ? new Date(props.periodFrom) : null}
            onChange={(date: Date) => handlePeriodFromDate(date)}
            wrapperClassName={styles.rowDatepicker}
            popperClassName={styles.popperDatePicker}
            placeholderText={props.placeholderFrom || t('dates-range.with-scripts.period.field.from.placeholder')}
            customInput={
              <DatePickerCustomInput
                ref={props.periodFromRef}
                label={props.labelFrom || t('records.left-sb.period.field.from.label')}
                onKeyUp={(evt: KeyboardEvent) => handleKeyUpInput(evt, 'from')}
              />
            }
            openToDate={props.periodFrom ? new Date(props.periodFrom) : new Date()}
            locale={getCurrentLocaleForDatePicker(locale)}
            showTimeSelect
            timeFormat={props.timeFormat || 'HH:mm'}
            timeIntervals={15}
            timeCaption={t('dates-range.with-scripts.period.field.from.time-caption.text')}
            disabled={false}
            maxDate={props.periodTo ? new Date(props.periodTo) : null}
            minTime={setHours(setMinutes(new Date(), 0), 0)}
            maxTime={
              !differenceInCalendarDays(new Date(props.periodFrom), new Date(props.periodTo))
                ? setHours(
                    setMinutes(new Date(), getMinutes(new Date(props.periodTo))),
                    getHours(new Date(props.periodTo))
                  )
                : setHours(setMinutes(new Date(), 59), 23)
            }
          />
          <DatePicker
            dateFormat="dd.MM.yyyy HH:mm"
            selected={props.periodTo ? new Date(props.periodTo) : null}
            onChange={(date: Date) => handlePeriodToDate(date)}
            wrapperClassName={styles.rowDatepicker}
            popperClassName={styles.popperDatePicker}
            placeholderText={props.placeholderFrom || t('dates-range.with-scripts.period.field.to.placeholder')}
            customInput={
              <DatePickerCustomInput
                ref={props.periodToRef}
                label={props.labelTo || t('dates-range.with-scripts.period.field.to.label')}
                onKeyUp={(evt: KeyboardEvent) => handleKeyUpInput(evt, 'to')}
              />
            }
            openToDate={props.periodTo ? new Date(props.periodTo) : new Date()}
            locale={getCurrentLocaleForDatePicker(locale)}
            showTimeSelect
            timeFormat={props.timeFormat || 'HH:mm'}
            timeIntervals={15}
            timeCaption={t('dates-range.with-scripts.period.field.to.time-caption.text')}
            disabled={false}
            minDate={props.periodFrom ? new Date(props.periodFrom) : null}
            minTime={
              !differenceInCalendarDays(new Date(props.periodFrom), new Date(props.periodTo))
                ? setHours(
                    setMinutes(new Date(), getMinutes(new Date(props.periodFrom))),
                    getHours(new Date(props.periodFrom))
                  )
                : setHours(setMinutes(new Date(), 0), 0)
            }
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(DatesRangeWithScripts);
