export function printFileFromUrl(url: string) {
  if (!url) {
    return;
  }
  const iframe = document.createElement('iframe');

  function closePrint() {
    document.body.removeChild(iframe);
  }

  iframe.style.position = 'fixed';
  iframe.style.right = '0';
  iframe.style.bottom = '0';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = '0';
  iframe.style.display = 'none';
  iframe.src = url;

  document.body.appendChild(iframe);

  iframe.onload = () => {
    const contentWindow = iframe?.contentWindow;

    if (contentWindow) {
      if (contentWindow.matchMedia) {
        contentWindow.matchMedia('print').addListener(mql => {
          if (!mql.matches) {
            closePrint();
          }
        });
      } else {
        (contentWindow as Window).onafterprint = closePrint;
      }
      iframe.focus();
      contentWindow.print();
    }
  };
}
