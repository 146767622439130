import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';

import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { PhotoComponent } from 'components/common/photo/photo';

import { RootState } from 'reducers';
import { EmployeeInfo, EmployeeRefs } from 'reducers/employees/interface';

import { getCurrentLocaleForDatePicker } from 'translate';

import { EmployeeTabInfoHandlers } from '../utils/types';

import styles from '../monitoring.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const cx = classNames.bind(styles);

type Option = {
  value: string | number;
  label: string | number;
};

interface Props {
  fields: EmployeeInfo;
  refs: EmployeeRefs;
  handlers: EmployeeTabInfoHandlers;
  invalidFields: string[];
  handbooks: {
    trackerOptions: Option[];
    orgOptions: Option[];
    departmentOptions: Option[];
    positionOptions: Option[];
    workObjOptions: Option[];
  };
}

export const TabInfo = ({ fields, refs, handlers, invalidFields, handbooks }: Props) => {
  const t = useFormatMessage();

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name, @typescript-eslint/no-unused-vars
  const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
    return (
      <div className={styles.tabInfoDatePicker} onClick={props.onClick} ref={_ref}>
        <Input
          label={props.label}
          placeholder={props.placeholder}
          value={props.value}
          withCalendarIcon
          customStyle={styles.inputWrap}
          isRequired={props.isRequired}
          isValueError={props.isValueError}
        />
      </div>
    );
  });

  return (
    <div className={cx(styles.modalContent, styles.modalContentEmployee)}>
      <div className={styles.modalColSlim}>
        <div className={styles.photoWrap}>
          <PhotoComponent src={fields.icon} onSetIcon={handlers.setIcon} />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div>
          <Input
            label={t('monitoring.unit-card.employee.general.field.surname.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.surname.placeholder')}
            isValueError={invalidFields.includes('lastName')}
            isRequired={true}
            value={fields.lastName}
            handleInputChange={handlers.setLastName}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.employee.general.field.name.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.name.placeholder')}
            isValueError={invalidFields.includes('firstName')}
            isRequired={true}
            value={fields.firstName}
            handleInputChange={handlers.setFirstName}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.employee.general.field.patronymic.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.patronymic.placeholder')}
            isValueError={invalidFields.includes('secondName')}
            isRequired={true}
            value={fields.secondName}
            handleInputChange={handlers.setSecondName}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.employee.general.field.phone-number.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.phone-number.placeholder')}
            isValueError={invalidFields.includes('simNumber')}
            pattern="+7[0-9]{10}"
            value={fields.simNumber}
            handleInputChange={handlers.setSimNumber}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('monitoring.unit-card.employee.general.field.pass-number.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.pass-number.placeholder')}
            isValueError={invalidFields.includes('passNumber')}
            value={fields.passNumber}
            handleInputChange={handlers.setPassNumber}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.trackerOptions}
            label={t('monitoring.unit-card.employee.general.field.linked-tracker.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.linked-tracker.placeholder')}
            isValueError={invalidFields.includes('trackerId')}
            withSearchIcon
            value={fields.trackerId || ''}
            handleChange={handlers.handleTrackerChange}
            style={styles.inputWrap}
            disabled={!fields.trackersPermission.isAllowRead()}
          />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div>
          <CustomSelect
            options={handbooks.orgOptions}
            label={t('monitoring.unit-card.employee.general.field.company.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.company.placeholder')}
            isValueError={invalidFields.includes('organizationId')}
            isRequired={true}
            withSearchIcon
            value={fields.organizationId}
            handleChange={handlers.handleOrgChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.departmentOptions}
            label={t('monitoring.unit-card.employee.general.field.subdivision.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.subdivision.placeholder')}
            isValueError={invalidFields.includes('departmentId')}
            isRequired={true}
            withSearchIcon
            value={fields.departmentId}
            handleChange={handlers.handleStructureChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={handbooks.positionOptions}
            label={t('monitoring.unit-card.employee.general.field.position.label')}
            placeholder={t('monitoring.unit-card.employee.general.field.position.placeholder')}
            isValueError={invalidFields.includes('positionId')}
            isRequired={true}
            withSearchIcon
            value={fields.positionId}
            handleChange={handlers.handlePositionChange}
            style={styles.inputWrap}
          />
        </div>
        <div className={styles.dataPickerWrap}>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={fields.issuePassDate ? new Date(fields.issuePassDate) : null}
            onChange={(date: Date) => handlers.setIssuePassDate(date.toISOString())}
            className={styles.tabInfoDatePicker}
            placeholderText={t('monitoring.unit-card.employee.general.field.pass-start-date.placeholder')}
            customInput={
              <DatePickerCustomInput
                label={t('monitoring.unit-card.employee.general.field.pass-start-date.label')}
                isValueError={invalidFields.includes('issuePassDate')}
                isRequired={true}
                ref={refs.issuePassDateRef}
              />
            }
            maxDate={fields.endPassDate ? new Date(fields.endPassDate) : null}
            openToDate={fields.issuePassDate ? new Date(fields.issuePassDate) : new Date()}
            locale={getCurrentLocaleForDatePicker(locale)}
          />
        </div>
        <div className={styles.dataPickerWrap}>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={fields.endPassDate ? new Date(fields.endPassDate) : null}
            onChange={(date: Date) => handlers.setEndPassDate(date.toISOString())}
            placeholderText={t('monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder')}
            customInput={
              <DatePickerCustomInput
                label={t('monitoring.unit-card.employee.general.field.pass-expiration-date.label')}
                isValueError={invalidFields.includes('endPassDate')}
                isRequired={true}
                ref={refs.endPassDateRef}
              />
            }
            minDate={fields.issuePassDate ? new Date(fields.issuePassDate) : null}
            openToDate={fields.endPassDate ? new Date(fields.endPassDate) : new Date()}
            locale={getCurrentLocaleForDatePicker(locale)}
          />
        </div>
        <MultipleSelect
          options={handbooks.workObjOptions}
          label={t('monitoring.unit-card.employee.general.field.work-area.label')}
          placeholder={t('monitoring.unit-card.employee.general.field.work-area.placeholder')}
          isValueError={invalidFields.includes('workObjects')}
          isRequired={true}
          withSearchIcon
          values={fields.workObjects.map((id, i) =>
            getValueForMultipleSelect({
              i,
              id,
              selectValues: handbooks.workObjOptions,
            })
          )}
          wrapperStyle={styles.inputWrap}
          handleChangeMultiple={handlers.handleWorkObjectChange}
        />
      </div>
    </div>
  );
};
