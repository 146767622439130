import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import styles from './rolesView.module.scss';

type Props = {
  roles: string[];
};

const messages = {
  moreRoles: 'accounts.users-list-tree.item.roles.more.text',
};

const RolesView: React.FC<Props> = ({ roles }) => {
  const t = useFormatMessage();

  // first two roles
  const rolesToShow = roles.slice(0, 2);

  return (
    <div className={styles.container}>
      {rolesToShow.map((role, index) => (
        <div key={index}>- {role}</div>
      ))}
      {roles.length > 2 && <div>+ {t(messages.moreRoles, { count: roles.length - 2 })}</div>}
    </div>
  );
};

export default RolesView;
