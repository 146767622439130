import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers';

interface Show {
  show: boolean;
  alertIsShown: boolean;
}

interface Payload {
  type: string;
  which: string;
}

interface AlertPayload {
  alertType: string;
}

type ListOfMapPopupTypes = 'EMPLOYEE' | 'TRANSPORT' | '';

interface Coords {
  x: number;
  y: number;
}

interface TypesOfModalForShowingOnTheMap {
  mapPopup: ListOfMapPopupTypes;
  mapPopupCoords: Coords;
}

interface ShowMapPopup {
  type: ListOfMapPopupTypes;
  coordinates: Coords;
}

type Modal = Payload & Show & AlertPayload & TypesOfModalForShowingOnTheMap;

const initialState: Modal = {
  show: false,
  type: '',
  which: '',
  alertIsShown: false,
  alertType: '',
  mapPopup: '',
  mapPopupCoords: {
    x: 0,
    y: 0,
  },
};

type PickCoordinatesAndShowMapPopupArgs = {
  popupType: ListOfMapPopupTypes;
  deviceId: string | null;
};

export const pickCoordintesAndShowMapPopup = createAsyncThunk(
  'modalSlice/pickCoordintesAndShowMapPopup',
  ({ popupType, deviceId }: PickCoordinatesAndShowMapPopupArgs, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const unitsCoord = state.trackableUnit.trackableUnitsCoord;
    const x = (deviceId && unitsCoord[deviceId]?.coordinates[0]) || 0;
    const y = (deviceId && unitsCoord[deviceId]?.coordinates[1]) || 0;

    thunkAPI.dispatch(
      showMapPopup({
        type: popupType,
        coordinates: { x, y },
      })
    );
  }
);

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    showMapPopup(state, { payload }: { payload: ShowMapPopup }) {
      state.mapPopup = payload.type;
      state.mapPopupCoords = payload.coordinates;
    },
    showModal(state, action: PayloadAction<Payload>) {
      const { type, which } = action.payload;
      state.show = true;
      state.type = type;
      state.which = which;
    },
    closeModal(state) {
      state.show = false;
      state.type = '';
      state.which = '';
    },
    showAlert(state, action: PayloadAction<AlertPayload>) {
      const { alertType } = action.payload;
      state.alertIsShown = true;
      state.alertType = alertType;
    },
    closeAlert(state) {
      state.alertIsShown = false;
      state.alertType = '';
    },
  },
});

export const { showModal, closeModal, showAlert, closeAlert, showMapPopup } = modalSlice.actions;

export default modalSlice.reducer;
