import React, { FC, ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AUTH_LOGIN_ROUTE } from 'components/auth/utils/consts';

type Props = {
  children: ReactNode;
} & RouteProps;

export const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
  const isAuth = sessionStorage.getItem('accessToken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_LOGIN_ROUTE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
