import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import styles from './singleOperationAccessView.module.scss';

type Operation = 'read' | 'create' | 'update' | 'delete';

type Props = {
  data: string[];
  operation: Operation;
};

const messages = {
  moreRoles: 'accounts.roles-list-table.accessEntities.more.text',
  operationTitle: (operation: Operation) => `accounts.roles-list-table.accessEntities.operation-${operation}.text`,
};

const SingleOperationAccessView: React.FC<Props> = ({ data, operation }) => {
  const t = useFormatMessage();

  let message = '-';

  if (data.length) {
    const dataToShow = data.slice(0, 2);

    message = dataToShow.join(', ');

    if (data.length > 2) {
      message += t(messages.moreRoles, { count: data.length - 2 });
    }
  }

  return (
    <div className={styles.container}>
      <span className={styles.opeartionTitle}>{t(messages.operationTitle(operation))}:</span>&nbsp;
      <span>{message}</span>
    </div>
  );
};

export default SingleOperationAccessView;
