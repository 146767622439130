import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../poiPopup.module.scss';

const NORMAL_COLOR_STROKE_ARC = '#00BF78';
const DANGER_COLOR_STROKE_ARC = '#F44336';
const LIMIT_COLOR_STROKE_ARC = '#FF8F00';

const ARC_PATH_RADIUS = 34;
const ARC_PATH = `
  M 30 85
  A ${ARC_PATH_RADIUS} ${ARC_PATH_RADIUS} 0 1 1 70 85
`;

const LIMIT_ARC_X0 = 25;
const LIMIT_ARC_Y0 = 89;
const LIMIT_ARC_X1 = 73;
const LIMIT_ARC_Y1 = 89;
const LIMIT_ARC_PATH_RADIUS = 39;
const LIMIT_ARC_PATH = `
  M ${LIMIT_ARC_X0} ${LIMIT_ARC_Y0}
  A ${LIMIT_ARC_PATH_RADIUS} ${LIMIT_ARC_PATH_RADIUS} 0 1 1 ${LIMIT_ARC_X1} ${LIMIT_ARC_Y1}
`;

type Props = {
  title: string;
  measureUnit: string;
  range: {
    start: number;
    end: number;
  };
  value: number;
  limit: number;
};

export function PoiPopupCircleValue({ title, measureUnit, range, value, limit }: Props) {
  const [mainArcPathDasharray, setMainArcPathDasharray] = useState(0.01);
  const [mainArcPathDashoffset, setMainArcPathDashoffset] = useState(0.01);
  const mainArcRef = useRef<SVGPathElement>(null);

  const [limitArcPathDasharray, setLimitArcPathDasharray] = useState(0.01);
  const [limitArcPathDashoffset, setLimitArcPathDashoffset] = useState(0.01);
  const limitArcRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    if (mainArcRef) {
      const mainArcPath = mainArcRef.current;
      if (!mainArcPath) {
        return;
      }

      const mainArcPathLength = mainArcPath.getTotalLength();
      setMainArcPathDasharray(mainArcPathLength);
      setMainArcPathDashoffset(100 - (value * 100.0) / range.end);
    }
  }, [mainArcRef, value, range]);

  useEffect(() => {
    if (limitArcRef) {
      const limitArcPath = limitArcRef.current;
      if (!limitArcPath) {
        return;
      }

      const limitArcPathLength = limitArcPath.getTotalLength();
      setLimitArcPathDasharray(limitArcPathLength);
      setLimitArcPathDashoffset(limitArcPathLength - (limitArcPathLength * limit) / range.end);
    }
  }, [limitArcRef, limit, range]);

  const coordinateValueLimit = useMemo(() => {
    // common alpha angle (radians)
    const aRad = limitArcPathDasharray / LIMIT_ARC_PATH_RADIUS;
    // remainder of arc length
    const aDegree = 360 - (aRad * 180) / Math.PI;
    const alpha = (180 - aDegree) / 2;

    // offset alpha angle
    const aOffsetLimit = Math.round(
      (180 * (limitArcPathDasharray - limitArcPathDashoffset)) / (Math.PI * LIMIT_ARC_PATH_RADIUS)
    );

    // circle center
    const d = LIMIT_ARC_X1 - LIMIT_ARC_X0;
    const h = LIMIT_ARC_PATH_RADIUS * Math.sin(alpha);
    const centerCoords = {
      xCenter: LIMIT_ARC_X0 + d / 2,
      yCenter: LIMIT_ARC_Y0 * 2 - h - LIMIT_ARC_PATH_RADIUS * 2 - 1,
    };

    const offsetAngle = aDegree + 50; // угол смещения от начальной точки диаграммы (LIMIT_ARC_X0, LIMIT_ARC_Y0)
    const offsetBorder = 25; // для определения св-ва textAnchor у текста
    const offsetLengthFromCircle = 3; // чтобы текст не налазил на линию limit

    return {
      x:
        centerCoords.xCenter +
        (LIMIT_ARC_PATH_RADIUS + offsetLengthFromCircle) * Math.cos(((aOffsetLimit + offsetAngle) * Math.PI) / 180),
      y:
        centerCoords.yCenter +
        (LIMIT_ARC_PATH_RADIUS + offsetLengthFromCircle) * Math.sin(((aOffsetLimit + offsetAngle) * Math.PI) / 180),
      startBorderX: centerCoords.xCenter - offsetBorder,
      endBorderX: centerCoords.xCenter + offsetBorder,
    };
  }, [limitArcPathDasharray, limitArcPathDashoffset]);

  const textLimitAnchor = () => {
    if (coordinateValueLimit.x > coordinateValueLimit.endBorderX) {
      return 'start';
    }
    if (coordinateValueLimit.x < coordinateValueLimit.startBorderX) {
      return 'end';
    }
    return 'middle';
  };

  return (
    <div className={styles.svg}>
      <svg
        className={styles.svgWrapper}
        width="100%"
        height="100%"
        viewBox="0 0 105 105"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="transparent">
          <path
            d={ARC_PATH}
            stroke={value >= limit ? DANGER_COLOR_STROKE_ARC : NORMAL_COLOR_STROKE_ARC}
            strokeWidth="9.5"
          />
          <path
            d={ARC_PATH}
            stroke="#E4E4E4"
            strokeWidth="10.5"
            strokeDasharray={mainArcPathDasharray}
            strokeDashoffset={(mainArcPathDasharray * mainArcPathDashoffset) / 100 - mainArcPathDasharray}
            ref={mainArcRef}
            className={styles.svgMainArcPath}
          />

          <line x1="33" y1="81" x2="26" y2="91" stroke="black" strokeWidth="1.3" />
          <line x1="67" y1="81" x2="74" y2="91" stroke="black" strokeWidth="1.3" />

          <path d={LIMIT_ARC_PATH} stroke={LIMIT_COLOR_STROKE_ARC} strokeWidth="2.5" />
          <path
            d={LIMIT_ARC_PATH}
            stroke="white"
            strokeWidth="3.5"
            strokeDasharray={limitArcPathDasharray}
            strokeDashoffset={limitArcPathDashoffset}
            ref={limitArcRef}
            className={styles.svgLimitArcPath}
          />
        </g>

        <g>
          <text
            dx={coordinateValueLimit.x}
            dy={coordinateValueLimit.y}
            className={styles.svgLimit}
            textAnchor={textLimitAnchor()}
          >
            {limit}
          </text>
        </g>

        <g>
          <text x="50" y="10" className={styles.svgTitle} textAnchor="middle">
            {title}
          </text>
          <text x="50" y="50" className={styles.svgValue} textAnchor="middle">
            {value}
          </text>
          <text x="50" y="65" className={styles.svgMeasureUnit} textAnchor="middle">
            {measureUnit}
          </text>
          <text x="25" y="99" className={styles.svgRange} textAnchor="middle">
            {range.start}
          </text>
          <text x="75" y="99" className={styles.svgRange} textAnchor="middle">
            {range.end}
          </text>
        </g>
      </svg>
    </div>
  );
}
