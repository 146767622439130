export default {
  en: {
    'records.button-block.btn.label': 'Create',

    'records.left-sb.template.header.label': 'Report template',

    'records.left-sb.period.header.label': 'Report period',
    'records.left-sb.period.btn.today.text': 'Today',
    'records.left-sb.period.btn.yesterday.text': 'Yesterday',
    'records.left-sb.period.btn.week.text': 'Last week',
    'records.left-sb.period.btn.month.text': 'Last month',
    'records.left-sb.period.field.from.label': 'From',
    'records.left-sb.period.field.from.placeholder': 'Select time',
    'records.left-sb.period.field.from.time-caption.text': 'Time',
    'records.left-sb.period.field.to.label': 'To',
    'records.left-sb.period.field.to.placeholder': 'Select time',
    'records.left-sb.period.field.to.time-caption.text': 'Time',
    'records.left-sb.period.field.type.label': 'Object type',
    'records.left-sb.period.field.type.placeholder': 'Select from the list',
    'records.left-sb.period.field.name.label': 'Object name',
    'records.left-sb.period.field.name.placeholder': 'Select from the list',
    'records.left-sb.period.field.history.btn.label': 'Report history',
    'records.left-sb.period.field.history.btn.close.label': 'Close',
    'records.left-sb.period.field.generate.btn.label': 'Generate a report',

    'records.card.template-create.header.label': 'Creating a report template',
    'records.card.template-edit.header.label': 'Editing a report template',

    'records.card.template-create.tabs.general.text': 'General',
    'records.card.template-create.tabs.data.text': 'Data',
    'records.card.template-create.tabs.conditions.text': 'Conditions',

    'records.card.template-create.general.field.name.label': 'Template name',
    'records.card.template-create.general.field.name.placeholder': 'Enter name',
    'records.card.template-create.general.field.desc.label': 'Template description',
    'records.card.template-create.general.field.desc.placeholder': 'Enter description',
    'records.card.template-create.general.field.users.label': 'Users who will see the template',
    'records.card.template-create.general.field.users.placeholder': 'Select from the list',
    'records.card.template-create.general.field.users.tooltip':
      'If no users are selected, the report template will be visible to all users. You must have “read” rights to the list of users to select users. Contact your system administrator if necessary.',
    'records.card.template-create.general.field.template.label': 'Printable template',
    'records.card.template-create.general.field.template.placeholder': 'Select a printable template',
    'records.card.template-create.general.field.template.tooltip.text':
      'If the template is not specified, by default the report is displayed in a simple tabular form according to the structure specified on the "Data" and "Conditions" tab.',
    'records.card.template-create.general.field.new-template-pf.btn.text': 'PF new template',

    'records.card.template-create.data.available.header.text': 'Available properties',
    'records.card.template-create.data.select.header.text': 'Selected properties',
    'records.card.template-create.data.select.btn.text': 'Add a calculated column',
    'records.card.template-create.data.select.tooltip.text':
      'Allows you to add a column in which you can perform arithmetic operations on data from other columns. For example, entering the formula “A + B” will add data from two columns A and B and display the sum in a new column. If the data cannot be added (for example, text and numbers), an operation error message will be displayed.',
    'records.card.template-create.data.select.data.placeholder': 'Enter the formula to calculate',

    'records.card.template-create.data.available.group.employees.text': 'Employees',
    'records.card.template-create.data.available.group.transports.text': 'Transport',
    'records.card.template-create.data.available.group.geozone.text': 'Geofences',
    'records.card.template-create.data.available.group.users.text': 'Users',
    'records.card.template-create.data.available.group.trackers.text': 'Trackers',
    'records.card.template-create.data.available.group.poi.text': 'POI points',
    'records.card.template-create.data.available.group.notifications.text': 'Notifications and violations',
    'records.card.template-create.data.available.group.custom.text': 'Custom fields',

    'records.card.template-create.data.available.employees.field.fio.text': 'Employee full name',
    'records.card.template-create.data.available.employees.field.group.text': 'Employee group',
    'records.card.template-create.data.available.employees.field.organization.text': 'Employee Organization',
    'records.card.template-create.data.available.employees.field.department.text': `Employee's structural unit`,
    'records.card.template-create.data.available.employees.field.work-obj.text': 'Employee Work Object',
    'records.card.template-create.data.available.employees.field.position.text': 'Position of the employee',
    'records.card.template-create.data.available.employees.field.telephone.text': 'Employee phone number',
    'records.card.template-create.data.available.employees.field.pass.text': `Employee's pass number`,
    'records.card.template-create.data.available.employees.field.pass_start.text': 'Employee pass start date',
    'records.card.template-create.data.available.employees.field.pass_end.text': 'Employee pass expiration date',
    'records.card.template-create.data.available.employees.field.tracker.text': 'Associated employee tracker',
    'records.card.template-create.data.available.employees.field.coords.text': 'Current coordinates of the employee',

    'records.card.template-create.data.available.transports.field.brand.text': 'Vehicle brand',
    'records.card.template-create.data.available.transports.field.model.text': 'Vehicle model',
    'records.card.template-create.data.available.transports.field.reg_number.text': 'Vehicle registration number',
    'records.card.template-create.data.available.transports.field.production.text': 'Year of vehicle release',
    'records.card.template-create.data.available.transports.field.group.text': 'Vehicle Group',
    'records.card.template-create.data.available.transports.field.color.text': 'Vehicle color',
    'records.card.template-create.data.available.transports.field.capacity.text': 'Vehicle capacity',
    'records.card.template-create.data.available.transports.field.organization.text': 'Organization of the vehicle',
    'records.card.template-create.data.available.transports.field.department.text': 'TS structural unit',
    'records.card.template-create.data.available.transports.field.work-obj.text': 'Vehicle work execution object',
    'records.card.template-create.data.available.transports.field.driver.text': 'Full name of the vehicle driver',
    'records.card.template-create.data.available.transports.field.fuel_type.text': 'Vehicle fuel type',
    'records.card.template-create.data.available.transports.field.tracker.text': 'Linked tracker',
    'records.card.template-create.data.available.transports.field.coords.text': 'Current vehicle coordinates',

    'records.card.template-create.data.available.geofences.field.name.text': 'Geofence name',
    'records.card.template-create.data.available.geofences.field.group.text': 'Geofence group',
    'records.card.template-create.data.available.geofences.field.description.text': 'Geofence description',
    'records.card.template-create.data.available.geofences.field.coords.text': 'Geofence coordinates',
    'records.card.template-create.data.available.geofences.field.date_in.text': 'Geofence entry date and time',
    'records.card.template-create.data.available.geofences.field.date_out.text': 'Geofence exit date and time',
    'records.card.template-create.data.available.geofences.field.duration.text': 'Geofence duration',

    'records.card.template-create.data.available.users.field.fio.text': 'User’s full name',
    'records.card.template-create.data.available.users.field.organization.text': 'User Organization',
    'records.card.template-create.data.available.users.field.department.text': `User's structural unit`,
    'records.card.template-create.data.available.users.field.work-obj.text': 'User work object',
    'records.card.template-create.data.available.users.field.position.text': 'Position of the user',
    'records.card.template-create.data.available.users.field.telephone.text': 'User phone number',
    'records.card.template-create.data.available.users.field.email.text': 'User email',
    'records.card.template-create.data.available.users.field.login.text': 'User login',
    'records.card.template-create.data.available.users.field.date_in.text': 'User login date and time',
    'records.card.template-create.data.available.users.field.date_out.text': 'User logout date and time',
    'records.card.template-create.data.available.users.field.duration.text': `User's duration`,

    'records.card.template-create.data.available.tracks.field.number.text': 'Tracker number',
    'records.card.template-create.data.available.tracks.field.model.text': 'Tracker model',
    'records.card.template-create.data.available.tracks.field.imei.text': 'Tracker IMEI',
    'records.card.template-create.data.available.tracks.field.mac.text': 'MAC address of the tracker',
    'records.card.template-create.data.available.tracks.field.sim.text': 'SIM card number of the tracker',
    'records.card.template-create.data.available.tracks.field.server_address.text': 'Tracker server address',
    'records.card.template-create.data.available.tracks.field.state.text': 'Tracker state',
    'records.card.template-create.data.available.tracks.field.comment.text': 'Comment on tracker status',
    'records.card.template-create.data.available.tracks.field.linked_obj.text': 'Linked object to tracker',
    'records.card.template-create.data.available.tracks.field.coords.text': 'Current coordinates of the tracker',
    'records.card.template-create.data.available.tracks.field.current_battery_charge.text':
      'Current battery charge level',
    'records.card.template-create.data.available.tracks.field.battery_charge_time.text': 'Battery charge message time',

    'records.card.template-create.data.available.poi.field.type.text': 'POI type',
    'records.card.template-create.data.available.poi.field.videocamera_name.text': 'Camera name',
    'records.card.template-create.data.available.poi.field.videocamera_desc.text': 'Camera description',
    'records.card.template-create.data.available.poi.field.videocamera_model.text': 'Camera model',
    'records.card.template-create.data.available.poi.field.gazanlyzer_name.text': 'Gas analyzer name',
    'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text': 'Gas analyzer description',
    'records.card.template-create.data.available.poi.field.gazanlyzer_model.text': 'Gas analyzer model',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text': 'Gas Analyzer Sensors',
    'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text': 'Gas analyzer gases',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text': 'Units',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text': 'Value range',
    'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text': 'Limit value',
    'records.card.template-create.data.available.poi.field.gazanlyzer_current.text': 'Current value',
    'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text': 'Date of last check',
    'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text': 'Date of next check',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text': 'Sensor name',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text': 'Sensor description',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text': 'Sensor type',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text': 'Sensor model',
    'records.card.template-create.data.available.poi.field.equip_name.text': 'Equipment name',
    'records.card.template-create.data.available.poi.field.equip_desc.text': 'Equipment Description',
    'records.card.template-create.data.available.poi.field.equip_type.text': 'Equipment type',
    'records.card.template-create.data.available.poi.field.equip_model.text': 'Equipment Model',
    'records.card.template-create.data.available.poi.field.accident_name.text': 'Accident name',
    'records.card.template-create.data.available.poi.field.accident_desc.text': 'Accident description',
    'records.card.template-create.data.available.poi.field.accident_type.text': 'Accident type',
    'records.card.template-create.data.available.poi.field.free_point_name.text': 'Free point name',
    'records.card.template-create.data.available.poi.field.free_point_desc.text': 'Free point description',
    'records.card.template-create.data.available.poi.field.free_point_validity.text': 'Date of POI validity',
    'records.card.template-create.data.available.poi.field.free_point_coords.text': 'POI coordinates',

    'records.card.template-create.data.available.notifications.field.name.text': 'Notification name',
    'records.card.template-create.data.available.notifications.field.text.text': 'Notification text',
    'records.card.template-create.data.available.notifications.field.type.text': 'Notification type',
    'records.card.template-create.data.available.notifications.field.triggered_date.text':
      'Date and time the notification was triggered',
    'records.card.template-create.data.available.notifications.field.sos.text': 'SOS signal',
    'records.card.template-create.data.available.notifications.field.failing.text': 'Tracker fall',
    'records.card.template-create.data.available.notifications.field.inaction.text': 'Inaction',
    'records.card.template-create.data.available.notifications.field.loss_connection.text': 'Connection lost',
    'records.card.template-create.data.available.notifications.field.restoring_connection.text': 'Connection restoring',
    'records.card.template-create.data.available.notifications.field.violation.text': 'Violation',
    'records.card.template-create.data.available.notifications.field.geozone_in.text': 'Geofence entrance',
    'records.card.template-create.data.available.notifications.field.geozone_out.text': 'Exit the geofence',
    'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text':
      'No object in geofence',
    'records.card.template-create.data.available.notifications.field.geozone_long_in.text':
      'Long stay of an object in a geofence',
    'records.card.template-create.data.available.notifications.field.geozone_convergence.text': 'Convergence objects',
    'records.card.template-create.data.available.notifications.field.geozone_away.text': 'Moving objects away',
    'records.card.template-create.data.available.notifications.field.coords.text': 'Coordinates of the notification',

    'records.card.template-create.condition.grouping.label': 'Grouping',
    'records.card.template-create.condition.grouping.by-year.text': 'Year',
    'records.card.template-create.condition.grouping.by-month.text': 'Month',
    'records.card.template-create.condition.grouping.by-date.text': 'Day',
    'records.card.template-create.condition.grouping.by-dayofweek.text': 'Day of the week',
    'records.card.template-create.condition.grouping.by-objecttype.text': 'Object type',
    'records.card.template-create.condition.grouping.by-object.text': 'Object',
    'records.card.template-create.condition.grouping.tooltip.text': 'Grouping data in the report structure',
    'records.card.template-create.condition.map.label': 'Map',
    'records.card.template-create.condition.map.geofences.text': 'Display geofences',
    'records.card.template-create.condition.map.notificationmarkers.text': 'Display notification tokens',
    'records.card.template-create.condition.map.movementtracks.text': 'Display movement tracks',
    'records.card.template-create.condition.map.poipoints.text': 'Display point POI',
    'records.card.template-create.condition.map.heatmaps.text': 'Heatmaps',
    'records.card.template-create.condition.map.tooltip.text': 'Output of object map elements to the report',
    'records.card.template-create.condition.additional-fields.text': 'Additional report filters',
    'records.card.template-create.condition.additional-fields.tooltip.text': `Filter information output in the report by: Geofences, Notifications, Organizations, Structural divisions, Employee positions, User roles`,
    'records.card.template-create.condition.additional-fields.by-geozone.text': 'Geofences',
    'records.card.template-create.condition.additional-fields.by-geozonegroup.text': 'Geofences groups',
    'records.card.template-create.condition.additional-fields.by-notification.text': 'Notifications',
    'records.card.template-create.condition.additional-fields.by-organization.text': 'Organizations',
    'records.card.template-create.condition.additional-fields.by-department.text': 'Departments',
    'records.card.template-create.condition.additional-fields.by-position.text': 'Positions',
    'records.card.template-create.condition.additional-fields.by-role.text': 'Roles',
    'records.card.template-create.condition.additional-fields.object-name.label': 'Object names by selected filter',
    'records.card.template-create.condition.additional-fields.object-name.placeholder': 'Select objects',
    'records.card.template-create.condition.additional-fields.numbering-line.text': 'Line numbering',
    'records.card.template-create.condition.additional-fields.display-total.text': 'Output the string “Total”',

    'records.card.template-create.condition.additional-fields.notification.alert.text':
      'SOS, fall, loss of connection, inactivity',
    'records.card.template-create.condition.additional-fields.notification.geozone.text': 'Geofences',
    'records.card.template-create.condition.additional-fields.notification.interposition.text':
      'Interposition of objects',
    'records.card.template-create.condition.additional-fields.notification.sensor.text': 'Sensor value',

    'records.card.template-create.footer.btn.copy.label': 'Copy',
    'records.card.template-create.footer.btn.delete.label': 'Delete',
    'records.card.template-create.footer.btn.cancel.label': 'Cancel',
    'records.card.template-create.footer.btn.save.label': 'Save',
    'records.card.template-create.footer.btn.update.label': 'Update',

    'records.card.template-create.alert.title': 'Close',
    'records.card.template-create.alert.info.text': 'There is unsaved data on the form.',

    'records.card.template-list.alert.delete.title': 'Delete template',
    'records.card.template-list.alert.delete.text':
      'Deleted template cannot be restored. Are you sure you want to delete the template?',
    'records.report-history.list.alert.delete.title': 'Delete report',
    'records.report-history.list.alert.delete.text':
      'Deleting a report cannot be undone and the data cannot be recovered.',
    'records.report-create.object-types.user.text': 'Users',
    'records.report-create.object-types.employee.text': 'Employees',
    'records.report-create.object-types.transport.text': 'Transports',
    'records.report-create.object-types.tracker.text': 'Trackers',
    'records.report-create.object-types.poipoint.text': 'POI points',

    'records.report-create.popup.header.text': 'Generating report...',
    'records.report-create.popup.content.text1': 'It can take a long time to generate the report.',
    'records.report-create.popup.content.text2': 'Now you can close this window and continue working with the system.',
    'records.report-create.popup.content.text3':
      'The generated report can be accessed later by clicking the "Report History" button.',
    'records.report-create.popup.content.text4':
      'The last 10 generated reports are automatically saved in the "Report History".',
    'records.report-create.popup.footer.btn.close.label': 'Close',

    'records.report-history.header.text': 'Report history',
    'records.report-history.list.header.status.is-done.text': 'Done',
    'records.report-history.list.header.status.in-progress.text': 'In progress',
    'records.report-history.list.header.status.is-error.text': 'Error',
    'records.report-history.list.item.no-name.text': 'Without name',
    'records.report-history.list.item.delete.text': 'Delete',
    'records.report-history.list.footer.date.text': 'Report date',
    'records.report-history.list.footer.period.text': 'Report period',
    'records.report-history.list.footer.objects.text': 'Objects',

    'records.right-report-view.header.map.text': 'Map',
    'records.right-report-view.header.data.text': 'Data',
    'records.right-report-view.no-data.text': 'No data',
    'records.right-report-view.table.header.other.text': 'Other',
    'records.right-report-view.footer.btn.print.text': 'Print',
    'records.right-report-view.footer.btn.close.text': 'Close',
    'records.right-report-view.footer.btn.save.text': 'Save to file',
  },
  ru: {
    'records.button-block.btn.label': 'Создать',

    'records.left-sb.template.header.label': 'Шаблон отчета',

    'records.left-sb.period.header.label': 'Период отчета',
    'records.left-sb.period.btn.today.text': 'Сегодня',
    'records.left-sb.period.btn.yesterday.text': 'Вчера',
    'records.left-sb.period.btn.week.text': 'Неделя',
    'records.left-sb.period.btn.month.text': 'Месяц',
    'records.left-sb.period.field.from.label': 'От',
    'records.left-sb.period.field.from.placeholder': 'Выберите время',
    'records.left-sb.period.field.from.time-caption.text': 'Время',
    'records.left-sb.period.field.to.label': 'До',
    'records.left-sb.period.field.to.placeholder': 'Выберите время',
    'records.left-sb.period.field.to.time-caption.text': 'Время',
    'records.left-sb.period.field.type.label': 'Тип объекта',
    'records.left-sb.period.field.type.placeholder': 'Выберите из списка',
    'records.left-sb.period.field.name.label': 'Название объекта или группы объектов',
    'records.left-sb.period.field.name.placeholder': 'Выберите из списка',
    'records.left-sb.period.field.history.btn.label': 'История отчетов',
    'records.left-sb.period.field.history.btn.close.label': 'Закрыть',
    'records.left-sb.period.field.generate.btn.label': 'Сформировать отчет',

    'records.card.template-create.header.label': 'Создание шаблона отчета',
    'records.card.template-edit.header.label': 'Редактирование шаблона отчета',

    'records.card.template-create.tabs.general.text': 'Основное',
    'records.card.template-create.tabs.data.text': 'Данные',
    'records.card.template-create.tabs.conditions.text': 'Условия',

    'records.card.template-create.general.field.name.label': 'Название шаблона отчета',
    'records.card.template-create.general.field.name.placeholder': 'Введите название',
    'records.card.template-create.general.field.desc.label': 'Описание шаблона',
    'records.card.template-create.general.field.desc.placeholder': 'Введите описание',
    'records.card.template-create.general.field.users.label': 'Пользователи, которые будут видеть шаблон',
    'records.card.template-create.general.field.users.placeholder': 'Выберите из списка',
    'records.card.template-create.general.field.users.tooltip':
      'Если пользователи не выбраны, то шаблон отчета будет виден всем пользователям. Для выбора пользователей у Вас должны быть права чтения списка пользователей. При необходимости обратитесь к администратору системы.',
    'records.card.template-create.general.field.template.label': 'Шаблон печатной формы',
    'records.card.template-create.general.field.template.placeholder': 'Выберите шаблон печатной формы',
    'records.card.template-create.general.field.template.tooltip.text':
      'Если шаблон не задан, по умолчанию отчет выводится в простой табличной форме согласно структуре, заданной на вкладке “Данные” и “Условия”',
    'records.card.template-create.general.field.new-template-pf.btn.text': 'Новый шаблон ПФ',

    'records.card.template-create.data.available.header.text': 'Данные, доступные для добавления',
    'records.card.template-create.data.select.header.text': 'Выбранные данные, столбцы',
    'records.card.template-create.data.select.btn.text': 'Добавить столбец с расчетом',
    'records.card.template-create.data.select.tooltip.text':
      'Позволяет добавить столбец, в котором можно производить арифметические операции над данными из других столбцов. Например, ввод формулы “А+В”, позволит сложить данные из двух столбцов А и В и вывести сумму в новом столбце. Если данные будет невозможно сложить (например, текст и цифры), то будет выведено сообщение об ошибке операции.',
    'records.card.template-create.data.select.data.placeholder': 'Введите формулу для расчета',

    'records.card.template-create.data.available.group.employees.text': 'Сотрудники',
    'records.card.template-create.data.available.group.transports.text': 'Транспорт',
    'records.card.template-create.data.available.group.geozone.text': 'Геозоны',
    'records.card.template-create.data.available.group.users.text': 'Пользователи',
    'records.card.template-create.data.available.group.trackers.text': 'Трекеры',
    'records.card.template-create.data.available.group.poi.text': 'POI точки',
    'records.card.template-create.data.available.group.notifications.text': 'Уведомления и нарушения',
    'records.card.template-create.data.available.group.custom.text': 'Произвольные поля',

    'records.card.template-create.data.available.employees.field.fio.text': 'ФИО сотрудника',
    'records.card.template-create.data.available.employees.field.group.text': 'Группа сотрудников',
    'records.card.template-create.data.available.employees.field.organization.text': 'Организация сотрудника',
    'records.card.template-create.data.available.employees.field.department.text':
      'Структурное подразделение сотрудника',
    'records.card.template-create.data.available.employees.field.work-obj.text': 'Объект выполнения работ сотрудника',
    'records.card.template-create.data.available.employees.field.position.text': 'Должность сотрудника',
    'records.card.template-create.data.available.employees.field.telephone.text': 'Номер телефона сотрудника',
    'records.card.template-create.data.available.employees.field.pass.text': 'Номер пропуска сотрудника',
    'records.card.template-create.data.available.employees.field.pass_start.text': 'Дата начала пропуска сотрудника',
    'records.card.template-create.data.available.employees.field.pass_end.text': 'Дата окончания пропуска сотрудника',
    'records.card.template-create.data.available.employees.field.tracker.text': 'Привязанный трекер сотрудника',
    'records.card.template-create.data.available.employees.field.coords.text': 'Текущие координаты сотрудника',

    'records.card.template-create.data.available.transports.field.brand.text': 'Марка ТС',
    'records.card.template-create.data.available.transports.field.model.text': 'Модель ТС',
    'records.card.template-create.data.available.transports.field.reg_number.text': 'Регистрационный номер ТС',
    'records.card.template-create.data.available.transports.field.production.text': 'Год выпуска ТС',
    'records.card.template-create.data.available.transports.field.group.text': 'Группа транспорта',
    'records.card.template-create.data.available.transports.field.color.text': 'Цвет ТС',
    'records.card.template-create.data.available.transports.field.capacity.text': 'Грузоподъемность ТС',
    'records.card.template-create.data.available.transports.field.organization.text': 'Организация ТС',
    'records.card.template-create.data.available.transports.field.department.text': 'Структурное подразделение ТС',
    'records.card.template-create.data.available.transports.field.work-obj.text': 'Объект выполнения работ ТС',
    'records.card.template-create.data.available.transports.field.driver.text': 'ФИО водителя ТС',
    'records.card.template-create.data.available.transports.field.fuel_type.text': 'Вид топлива ТС',
    'records.card.template-create.data.available.transports.field.tracker.text': 'Привязанный трекер',
    'records.card.template-create.data.available.transports.field.coords.text': 'Текущие координаты транспорта',

    'records.card.template-create.data.available.geofences.field.name.text': 'Название геозоны',
    'records.card.template-create.data.available.geofences.field.group.text': 'Группа геозон',
    'records.card.template-create.data.available.geofences.field.description.text': 'Описание геозоны',
    'records.card.template-create.data.available.geofences.field.coords.text': 'Координаты геозоны',
    'records.card.template-create.data.available.geofences.field.date_in.text': 'Дата и время входа в геозону',
    'records.card.template-create.data.available.geofences.field.date_out.text': 'Дата и время выхода из геозоны',
    'records.card.template-create.data.available.geofences.field.duration.text': 'Длительность нахождения в геозоне',

    'records.card.template-create.data.available.users.field.fio.text': 'ФИО пользователя',
    'records.card.template-create.data.available.users.field.organization.text': 'Организация пользователя',
    'records.card.template-create.data.available.users.field.department.text': 'Структурное подразделение пользователя',
    'records.card.template-create.data.available.users.field.work-obj.text': 'Объект выполнения работ пользователя',
    'records.card.template-create.data.available.users.field.position.text': 'Должность пользователя',
    'records.card.template-create.data.available.users.field.telephone.text': 'Номер телефона пользователя',
    'records.card.template-create.data.available.users.field.email.text': 'Электронная почта пользователя',
    'records.card.template-create.data.available.users.field.login.text': 'Логин пользователя',
    'records.card.template-create.data.available.users.field.date_in.text': 'Дата и время входа пользователя',
    'records.card.template-create.data.available.users.field.date_out.text': 'Дата и время выхода пользователя',
    'records.card.template-create.data.available.users.field.duration.text': 'Длительность нахождения пользователя',

    'records.card.template-create.data.available.tracks.field.number.text': 'Номер трекера',
    'records.card.template-create.data.available.tracks.field.model.text': 'Модель трекера',
    'records.card.template-create.data.available.tracks.field.imei.text': 'IMEI трекера',
    'records.card.template-create.data.available.tracks.field.mac.text': 'MAC адрес трекера',
    'records.card.template-create.data.available.tracks.field.sim.text': 'Номер SIM карты трекера',
    'records.card.template-create.data.available.tracks.field.server_address.text': 'Адрес сервера трекера',
    'records.card.template-create.data.available.tracks.field.state.text': 'Состояние трекера',
    'records.card.template-create.data.available.tracks.field.comment.text': 'Комментарий по состоянию трекера',
    'records.card.template-create.data.available.tracks.field.linked_obj.text': 'Привязанный объект к трекеру',
    'records.card.template-create.data.available.tracks.field.coords.text': 'Текущие координаты трекера',
    'records.card.template-create.data.available.tracks.field.current_battery_charge.text':
      'Текущий заряд аккумулятора',
    'records.card.template-create.data.available.tracks.field.battery_charge_time.text':
      'Время сообщения о заряде аккумулятора',

    'records.card.template-create.data.available.poi.field.type.text': 'Тип POI точки',
    'records.card.template-create.data.available.poi.field.videocamera_name.text': 'Название видеокамеры',
    'records.card.template-create.data.available.poi.field.videocamera_desc.text': 'Описание видеокамеры',
    'records.card.template-create.data.available.poi.field.videocamera_model.text': 'Модель видеокамеры',
    'records.card.template-create.data.available.poi.field.gazanlyzer_name.text': 'Название газоанализатора',
    'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text': 'Описание газоанализатора',
    'records.card.template-create.data.available.poi.field.gazanlyzer_model.text': 'Модель газоанализатора',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text': 'Сенсоры газоанализатора',
    'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text': 'Газы газоанализатора',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text': 'Единицы измерения',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text': 'Диапазон значений',
    'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text': 'Предельное значение',
    'records.card.template-create.data.available.poi.field.gazanlyzer_current.text': 'Текущее значение',
    'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text': 'Дата последней поверки',
    'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text': 'Дата следующей поверки',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text': 'Название датчика',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text': 'Описание датчика',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text': 'Тип датчика',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text': 'Модель датчика',
    'records.card.template-create.data.available.poi.field.equip_name.text': 'Название оснащения',
    'records.card.template-create.data.available.poi.field.equip_desc.text': 'Описание оснащения',
    'records.card.template-create.data.available.poi.field.equip_type.text': 'Тип оснащения',
    'records.card.template-create.data.available.poi.field.equip_model.text': 'Модель оснащения',
    'records.card.template-create.data.available.poi.field.accident_name.text': 'Название происшествия',
    'records.card.template-create.data.available.poi.field.accident_desc.text': 'Описание происшествия',
    'records.card.template-create.data.available.poi.field.accident_type.text': 'Тип происшествия',
    'records.card.template-create.data.available.poi.field.free_point_name.text': 'Название свободной точки',
    'records.card.template-create.data.available.poi.field.free_point_desc.text': 'Описание свободной точки',
    'records.card.template-create.data.available.poi.field.free_point_validity.text': 'Дата действия POI точки',
    'records.card.template-create.data.available.poi.field.free_point_coords.text': 'Координаты POI точки',

    'records.card.template-create.data.available.notifications.field.name.text': 'Название уведомления',
    'records.card.template-create.data.available.notifications.field.text.text': 'Текст уведомления',
    'records.card.template-create.data.available.notifications.field.type.text': 'Тип уведомления',
    'records.card.template-create.data.available.notifications.field.triggered_date.text':
      'Дата и время срабатывания уведомления',
    'records.card.template-create.data.available.notifications.field.sos.text': 'SOS сигнал',
    'records.card.template-create.data.available.notifications.field.failing.text': 'Падение трекера',
    'records.card.template-create.data.available.notifications.field.inaction.text': 'Бездействие',
    'records.card.template-create.data.available.notifications.field.loss_connection.text': 'Потеря связи',
    'records.card.template-create.data.available.notifications.field.restoring_connection.text': 'Восстановление связи',
    'records.card.template-create.data.available.notifications.field.violation.text': 'Нарушение',
    'records.card.template-create.data.available.notifications.field.geozone_in.text': 'Вход в геозону',
    'records.card.template-create.data.available.notifications.field.geozone_out.text': 'Выход из геозоны',
    'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text':
      'Отсутствие объекта в геозоне',
    'records.card.template-create.data.available.notifications.field.geozone_long_in.text':
      'Долгое нахождение объекта в геозоне',
    'records.card.template-create.data.available.notifications.field.geozone_convergence.text': 'Сближение объектов',
    'records.card.template-create.data.available.notifications.field.geozone_away.text': 'Отдаление объектов',
    'records.card.template-create.data.available.notifications.field.coords.text': 'Координаты уведомления',

    'records.card.template-create.condition.grouping.label': 'Группировка',
    'records.card.template-create.condition.grouping.by-year.text': 'Год',
    'records.card.template-create.condition.grouping.by-month.text': 'Месяц',
    'records.card.template-create.condition.grouping.by-date.text': 'Число',
    'records.card.template-create.condition.grouping.by-dayofweek.text': 'День недели',
    'records.card.template-create.condition.grouping.by-objecttype.text': 'Тип объектов',
    'records.card.template-create.condition.grouping.by-object.text': 'Объект',
    'records.card.template-create.condition.grouping.tooltip.text': 'Группировка данных в структуре отчета',
    'records.card.template-create.condition.map.label': 'Карта',
    'records.card.template-create.condition.map.geofences.text': 'Вывести геозоны',
    'records.card.template-create.condition.map.notificationmarkers.text': 'Вывести маркеры уведомлений',
    'records.card.template-create.condition.map.movementtracks.text': 'Вывести треки передвижения',
    'records.card.template-create.condition.map.poipoints.text': 'Вывести POI точки',
    'records.card.template-create.condition.map.heatmaps.text': 'Тепловые карты',
    'records.card.template-create.condition.map.tooltip.text': 'Вывод в отчет элементов карты объекта',
    'records.card.template-create.condition.additional-fields.text': 'Дополнительные фильтры для отчета',
    'records.card.template-create.condition.additional-fields.tooltip.text': `Фильтровать вывод информации в отчете по: Геозонам, Уведомлениям, Организациям, Структурным подразделениям, Должностям сотрудников, Ролям пользователей`,
    'records.card.template-create.condition.additional-fields.by-geozone.text': 'Геозоны',
    'records.card.template-create.condition.additional-fields.by-geozonegroup.text': 'Группы геозон',
    'records.card.template-create.condition.additional-fields.by-notification.text': 'Уведомления',
    'records.card.template-create.condition.additional-fields.by-organization.text': 'Организации',
    'records.card.template-create.condition.additional-fields.by-department.text': 'Подразделения',
    'records.card.template-create.condition.additional-fields.by-position.text': 'Должности',
    'records.card.template-create.condition.additional-fields.by-role.text': 'Роли',
    'records.card.template-create.condition.additional-fields.object-name.label':
      'Названия объектов по выбранному фильтру',
    'records.card.template-create.condition.additional-fields.object-name.placeholder': 'Выберете объекты',
    'records.card.template-create.condition.additional-fields.numbering-line.text': 'Нумерация строк',
    'records.card.template-create.condition.additional-fields.display-total.text': 'Вывести строку “Итого”',

    'records.card.template-create.condition.additional-fields.notification.alert.text':
      'SOS, падение, потеря связи, бездействие',
    'records.card.template-create.condition.additional-fields.notification.geozone.text': 'Геозоны',
    'records.card.template-create.condition.additional-fields.notification.interposition.text':
      'Взаиморасположение объектов',
    'records.card.template-create.condition.additional-fields.notification.sensor.text': 'Значение датчика',

    'records.card.template-create.footer.btn.copy.label': 'Копировать',
    'records.card.template-create.footer.btn.delete.label': 'Удалить',
    'records.card.template-create.footer.btn.cancel.label': 'Отмена',
    'records.card.template-create.footer.btn.save.label': 'Сохранить',
    'records.card.template-create.footer.btn.update.label': 'Обновить',

    'records.card.template-create.alert.title': 'Закрыть',
    'records.card.template-create.alert.info.text': 'На форме остались несохраненные данные.',

    'records.card.template-list.alert.delete.title': 'Удаление шаблона',
    'records.card.template-list.alert.delete.text':
      'Удаленный шаблон нельзя будет восстановить. Вы действительно хотите удалить шаблон?',

    'records.report-create.object-types.user.text': 'Пользователи',
    'records.report-create.object-types.employee.text': 'Сотрудники',
    'records.report-create.object-types.transport.text': 'Транспорты',
    'records.report-create.object-types.tracker.text': 'Трекеры',
    'records.report-create.object-types.poipoint.text': 'POI точки',

    'records.report-create.popup.header.text': 'Идет формирование отчета...',
    'records.report-create.popup.content.text1': 'На формирование отчета может потребоваться значительное время.',
    'records.report-create.popup.content.text2': 'Сейчас Вы можете закрыть это окно и продолжить работу с системой.',
    'records.report-create.popup.content.text3':
      'Доступ к сформированному отчету можно получить позже, нажав кнопку “История отчетов”.',
    'records.report-create.popup.content.text4':
      'В “Истории отчетов” автоматически сохраняются последние 10 сформированных отчетов.',
    'records.report-create.popup.footer.btn.close.label': 'Закрыть',

    'records.report-history.header.text': 'История отчетов',
    'records.report-history.list.header.status.is-done.text': 'Готово',
    'records.report-history.list.header.status.in-progress.text': 'В работе',
    'records.report-history.list.header.status.is-error.text': 'Ошибка',
    'records.report-history.list.item.no-name.text': 'Без имени',
    'records.report-history.list.item.delete.text': 'Удалить',
    'records.report-history.list.alert.delete.title': 'Удаление отчета',
    'records.report-history.list.alert.delete.text':
      'Удаление отчета не может быть отменено и данные не могут быть восстановлены.',
    'records.report-history.list.footer.date.text': 'Дата отчета',
    'records.report-history.list.footer.period.text': 'Период отчета',
    'records.report-history.list.footer.objects.text': 'Объекты',

    'records.right-report-view.header.map.text': 'Карта',
    'records.right-report-view.header.data.text': 'Данные',
    'records.right-report-view.no-data.text': 'Нет данных',
    'records.right-report-view.table.header.other.text': 'Прочее',
    'records.right-report-view.footer.btn.print.text': 'Печать',
    'records.right-report-view.footer.btn.close.text': 'Закрыть',
    'records.right-report-view.footer.btn.save.text': 'Сохранить в файл',
  },
  zh: {
    'records.button-block.btn.label': '创建',
    'records.left-sb.template.header.label': '报告模板',
    'records.left-sb.period.header.label': '报告期',
    'records.left-sb.period.btn.today.text': '今天',
    'records.left-sb.period.btn.yesterday.text': '昨天',
    'records.left-sb.period.btn.week.text': '周',
    'records.left-sb.period.btn.month.text': '月',
    'records.left-sb.period.field.from.label': '从',
    'records.left-sb.period.field.from.placeholder': '选择时间',
    'records.left-sb.period.field.from.time-caption.text': '时间',
    'records.left-sb.period.field.to.label': '',
    'records.left-sb.period.field.to.placeholder': '选择时间',
    'records.left-sb.period.field.to.time-caption.text': '时间',
    'records.left-sb.period.field.type.label': '对象类型',
    'records.left-sb.period.field.type.placeholder': '从列表中选择',
    'records.left-sb.period.field.name.label': '对象或对象组名称',
    'records.left-sb.period.field.name.placeholder': '从列表中选择',
    'records.left-sb.period.field.history.btn.label': '报告历史记录',
    'records.left-sb.period.field.history.btn.close.label': '关闭',
    'records.left-sb.period.field.generate.btn.label': '生成报告',
    'records.card.template-create.header.label': '',
    'records.card.template-edit.header.label': '',
    'records.card.template-create.tabs.general.text': '基本',
    'records.card.template-create.tabs.data.text': '数据',
    'records.card.template-create.tabs.conditions.text': '条件',
    'records.card.template-create.general.field.name.label': '报表模板名称',
    'records.card.template-create.general.field.name.placeholder': '输入名称',
    'records.card.template-create.general.field.desc.label': '模板描述',
    'records.card.template-create.general.field.desc.placeholder': '输入描述',
    'records.card.template-create.general.field.users.label': '即将看到模板的用户',
    'records.card.template-create.general.field.users.placeholder': '从列表中选择',
    'records.card.template-create.general.field.users.tooltip': '如果未选择任何用户，则报告模板将对所有用户可见。',
    'records.card.template-create.general.field.template.label': '打印格式模板',
    'records.card.template-create.general.field.template.placeholder': '',
    'records.card.template-create.general.field.template.tooltip.text':
      '如没有模板，默认情况下，报表将根据“数据”和“条件”选项卡上指定的结构以简单的表格形式显示。',
    'records.card.template-create.general.field.new-template-pf.btn.text': '印刷版新模板',
    'records.card.template-create.data.available.header.text': '可用于添加的数据',
    'records.card.template-create.data.select.header.text': '选定的数据，列',
    'records.card.template-create.data.select.btn.text': '添加计算列',
    'records.card.template-create.data.select.tooltip.text':
      '允许您添加一列，您可以通过这列对其它列中的数据进行算术运算。例如，输入公式“ A + B”即A和B两列的数据相加，并在新列中显示总和。如果数据无法相加（例如，文本和数字），则会显示操作错误的消息。',
    'records.card.template-create.data.select.data.placeholder': '输入计算公式',
    'records.card.template-create.data.available.group.employees.text': '员工',
    'records.card.template-create.data.available.group.transports.text': '车辆',
    'records.card.template-create.data.available.group.geozone.text': '地理围栏',
    'records.card.template-create.data.available.group.users.text': '用户',
    'records.card.template-create.data.available.group.trackers.text': '跟踪器',
    'records.card.template-create.data.available.group.poi.text': 'POI兴趣点',
    'records.card.template-create.data.available.group.notifications.text': '',
    'records.card.template-create.data.available.group.custom.text': '',
    'records.card.template-create.data.available.employees.field.fio.text': '',
    'records.card.template-create.data.available.employees.field.group.text': '',
    'records.card.template-create.data.available.employees.field.organization.text': '单位',
    'records.card.template-create.data.available.employees.field.department.text': '下属部门',
    'records.card.template-create.data.available.employees.field.work-obj.text': '工作执行对象',
    'records.card.template-create.data.available.employees.field.position.text': '职位',
    'records.card.template-create.data.available.employees.field.telephone.text': '员工电话',
    'records.card.template-create.data.available.employees.field.pass.text': '员工通行证编号',
    'records.card.template-create.data.available.employees.field.pass_start.text': '通行证生效日期',
    'records.card.template-create.data.available.employees.field.pass_end.text': '通行证有效期截止日期',
    'records.card.template-create.data.available.employees.field.tracker.text': '连接的跟踪器',
    'records.card.template-create.data.available.employees.field.coords.text': '',
    'records.card.template-create.data.available.transports.field.brand.text': '车辆品牌',
    'records.card.template-create.data.available.transports.field.model.text': '车型',
    'records.card.template-create.data.available.transports.field.reg_number.text': '车辆登记号码',
    'records.card.template-create.data.available.transports.field.production.text': '车辆出厂年份',
    'records.card.template-create.data.available.transports.field.group.text': '',
    'records.card.template-create.data.available.transports.field.color.text': '车辆颜色',
    'records.card.template-create.data.available.transports.field.capacity.text': '车辆载重量',
    'records.card.template-create.data.available.transports.field.organization.text': '单位',
    'records.card.template-create.data.available.transports.field.department.text': '下属部门',
    'records.card.template-create.data.available.transports.field.work-obj.text': '工作执行对象',
    'records.card.template-create.data.available.transports.field.driver.text': '车辆驾驶员姓名',
    'records.card.template-create.data.available.transports.field.fuel_type.text': '车辆燃料类型',
    'records.card.template-create.data.available.transports.field.tracker.text': '连接的跟踪器',
    'records.card.template-create.data.available.transports.field.coords.text': '',
    'records.card.template-create.data.available.geofences.field.name.text': '地理围栏名称',
    'records.card.template-create.data.available.geofences.field.group.text': '地理围栏组',
    'records.card.template-create.data.available.geofences.field.description.text': '地理围栏描述',
    'records.card.template-create.data.available.geofences.field.coords.text': '地理围栏坐标',
    'records.card.template-create.data.available.geofences.field.date_in.text': '进入地理围栏的日期和时间',
    'records.card.template-create.data.available.geofences.field.date_out.text': '退出地理围栏的日期和时间',
    'records.card.template-create.data.available.geofences.field.duration.text': '在地理围栏的停留时间',
    'records.card.template-create.data.available.users.field.fio.text': '用户全名',
    'records.card.template-create.data.available.users.field.organization.text': '单位',
    'records.card.template-create.data.available.users.field.department.text': '下属部门',
    'records.card.template-create.data.available.users.field.work-obj.text': '工作执行对象',
    'records.card.template-create.data.available.users.field.position.text': '职位',
    'records.card.template-create.data.available.users.field.telephone.text': '用户的电话号码',
    'records.card.template-create.data.available.users.field.email.text': '电子邮箱',
    'records.card.template-create.data.available.users.field.login.text': '用户名',
    'records.card.template-create.data.available.users.field.date_in.text': '用户登录日期和时间',
    'records.card.template-create.data.available.users.field.date_out.text': '用户登出日期和时间',
    'records.card.template-create.data.available.users.field.duration.text': '用户停留时间',
    'records.card.template-create.data.available.tracks.field.number.text': '跟踪器号码',
    'records.card.template-create.data.available.tracks.field.model.text': '跟踪器型号',
    'records.card.template-create.data.available.tracks.field.imei.text': '跟踪器的IMEI号码',
    'records.card.template-create.data.available.tracks.field.mac.text': '追踪器MAC地址',
    'records.card.template-create.data.available.tracks.field.sim.text': '跟踪器SIM卡号码',
    'records.card.template-create.data.available.tracks.field.server_address.text': '跟踪器服务器地址',
    'records.card.template-create.data.available.tracks.field.state.text': '跟踪器状态',
    'records.card.template-create.data.available.tracks.field.comment.text': '跟踪器状态评论',
    'records.card.template-create.data.available.tracks.field.linked_obj.text': '连接对象',
    'records.card.template-create.data.available.tracks.field.coords.text': '跟踪器的当前坐标',
    'records.card.template-create.data.available.tracks.field.current_battery_charge.text': '蓄电池当前电量',
    'records.card.template-create.data.available.tracks.field.battery_charge_time.text': '蓄电池电量通知时间',
    'records.card.template-create.data.available.poi.field.type.text': '兴趣点类型',
    'records.card.template-create.data.available.poi.field.videocamera_name.text': '摄像机名称',
    'records.card.template-create.data.available.poi.field.videocamera_desc.text': '摄像机说明',
    'records.card.template-create.data.available.poi.field.videocamera_model.text': '摄像机型号',
    'records.card.template-create.data.available.poi.field.gazanlyzer_name.text': '气体分析仪名称',
    'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text': '气体分析仪说明',
    'records.card.template-create.data.available.poi.field.gazanlyzer_model.text': '气体分析仪型号',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text': '气体传感器',
    'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text': '气体',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text': '单位',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text': '值范围',
    'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text': '极限值',
    'records.card.template-create.data.available.poi.field.gazanlyzer_current.text': '目前价值',
    'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text': '上次检查日期',
    'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text': '下次检查日期',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text': '传感器名称',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text': '传感器描述',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text': '传感器类型',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text': '传感器型号',
    'records.card.template-create.data.available.poi.field.equip_name.text': '设备名称',
    'records.card.template-create.data.available.poi.field.equip_desc.text': '设备说明',
    'records.card.template-create.data.available.poi.field.equip_type.text': '设备类型',
    'records.card.template-create.data.available.poi.field.equip_model.text': '设备型号',
    'records.card.template-create.data.available.poi.field.accident_name.text': '事件名称',
    'records.card.template-create.data.available.poi.field.accident_desc.text': '事件描述',
    'records.card.template-create.data.available.poi.field.accident_type.text': '事件类型',
    'records.card.template-create.data.available.poi.field.free_point_name.text': '自由点名称',
    'records.card.template-create.data.available.poi.field.free_point_desc.text': '自由点说明',
    'records.card.template-create.data.available.poi.field.free_point_validity.text': '兴趣点有效期',
    'records.card.template-create.data.available.poi.field.free_point_coords.text': 'POI 坐标',
    'records.card.template-create.data.available.notifications.field.name.text': '通知名称',
    'records.card.template-create.data.available.notifications.field.text.text': '通知文本',
    'records.card.template-create.data.available.notifications.field.type.text': '通知类型',
    'records.card.template-create.data.available.notifications.field.triggered_date.text': '触发通知的日期和时间',
    'records.card.template-create.data.available.notifications.field.sos.text': '紧急求救',
    'records.card.template-create.data.available.notifications.field.failing.text': '追踪器的坠落',
    'records.card.template-create.data.available.notifications.field.inaction.text': '不作为',
    'records.card.template-create.data.available.notifications.field.loss_connection.text': '失去了连接',
    'records.card.template-create.data.available.notifications.field.restoring_connection.text': '连接恢复',
    'records.card.template-create.data.available.notifications.field.violation.text': '违反',
    'records.card.template-create.data.available.notifications.field.geozone_in.text': '地理围栏入口',
    'records.card.template-create.data.available.notifications.field.geozone_out.text': '退出地理围栏',
    'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text': '地理围栏中没有物体',
    'records.card.template-create.data.available.notifications.field.geozone_long_in.text':
      '物体在地理围栏内的长时间停留',
    'records.card.template-create.data.available.notifications.field.geozone_convergence.text': '对象的收敛',
    'records.card.template-create.data.available.notifications.field.geozone_away.text': '将物体移开',
    'records.card.template-create.data.available.notifications.field.coords.text': '通知坐标',
    'records.card.template-create.condition.grouping.label': '分组',
    'records.card.template-create.condition.grouping.by-year.text': '年',
    'records.card.template-create.condition.grouping.by-month.text': '月',
    'records.card.template-create.condition.grouping.by-date.text': '日',
    'records.card.template-create.condition.grouping.by-dayofweek.text': '周',
    'records.card.template-create.condition.grouping.by-objecttype.text': '对象类型',
    'records.card.template-create.condition.grouping.by-object.text': '对象',
    'records.card.template-create.condition.grouping.tooltip.text': '在报告结构中对数据进行分组',
    'records.card.template-create.condition.map.label': '地图',
    'records.card.template-create.condition.map.geofences.text': '显示地理围栏',
    'records.card.template-create.condition.map.notificationmarkers.text': '显示通知标记',
    'records.card.template-create.condition.map.movementtracks.text': '显示移动轨道',
    'records.card.template-create.condition.map.poipoints.text': '显示POI点',
    'records.card.template-create.condition.map.heatmaps.text': '显示热图',
    'records.card.template-create.condition.map.tooltip.text': '将对象地图的元素输出到报告',
    'records.card.template-create.condition.additional-fields.text': '报告的其他过滤器',
    'records.card.template-create.condition.additional-fields.tooltip.text':
      '按以下条件过滤报告中的信息显示：地理围栏、通知、组织、结构部门、员工、用户角色',
    'records.card.template-create.condition.additional-fields.by-geozone.text': '地理围栏',
    'records.card.template-create.condition.additional-fields.by-notification.text': '通知',
    'records.card.template-create.condition.additional-fields.by-organization.text': '公司',
    'records.card.template-create.condition.additional-fields.by-department.text': '部门',
    'records.card.template-create.condition.additional-fields.by-position.text': '职位',
    'records.card.template-create.condition.additional-fields.by-role.text': '角色',
    'records.card.template-create.condition.additional-fields.object-name.label': '所选过滤器的对象名称',
    'records.card.template-create.condition.additional-fields.object-name.placeholder': '选择对象',
    'records.card.template-create.condition.additional-fields.numbering-line.text': '行编号',
    'records.card.template-create.condition.additional-fields.display-total.text': '显示“总计”行',
    'records.card.template-create.condition.additional-fields.notification.alert.text': 'SOS，跌倒，失去连接，不活跃',
    'records.card.template-create.condition.additional-fields.notification.geozone.text': '地理围栏',
    'records.card.template-create.condition.additional-fields.notification.interposition.text': '物体的相对位置',
    'records.card.template-create.condition.additional-fields.notification.sensor.text': '传感器值',
    'records.card.template-create.footer.btn.copy.label': '复制',
    'records.card.template-create.footer.btn.delete.label': '删除',
    'records.card.template-create.footer.btn.cancel.label': '取消',
    'records.card.template-create.footer.btn.save.label': '保存',
    'records.card.template-create.footer.btn.update.label': '更新',
    'records.card.template-create.alert.title': '关闭',
    'records.card.template-create.alert.info.text': '表单上有未保存的数据。',
    'records.card.template-list.alert.delete.title': '消除模板',
    'records.card.template-list.alert.delete.text': '已消除的模板无法恢复。 您真的要消除模板吗？',
    'records.report-create.object-types.user.text': '用户 ',
    'records.report-create.object-types.employee.text': '工作人员',
    'records.report-create.object-types.transport.text': '运输工具',
    'records.report-create.object-types.tracker.text': '跟踪器',
    'records.report-create.object-types.poipoint.text': '点POI',
    'records.report-create.popup.header.text': '正在生成报告...',
    'records.report-create.popup.content.text1': '为生成报告需要很多时间。',
    'records.report-create.popup.content.text2': '现在您可以关闭这个窗口，继续系统的工作。',
    'records.report-create.popup.content.text3': '然后可以通过单击“报告历史”按钮访问生成的报告。',
    'records.report-create.popup.content.text4': '在“报告历史”自动保存最近 10 个生成的报告。',
    'records.report-create.popup.footer.btn.close.label': '关闭',
    'records.report-history.header.text': '报告历史',
    'records.report-history.list.header.status.is-done.text': '准备好',
    'records.report-history.list.header.status.in-progress.text': '工作中',
    'records.report-history.list.header.status.is-error.text': '错误',
    'records.report-history.list.item.no-name.text': '无名',
    'records.report-history.list.item.delete.text': '消除',
    'records.report-history.list.alert.delete.title': '消除报告',
    'records.report-history.list.alert.delete.text': '无法废止消除报告，也无法恢复数据。',
    'records.report-history.list.footer.date.text': '报告日期',
    'records.report-history.list.footer.period.text': '报告期限',
    'records.report-history.list.footer.objects.text': '对象',
    'records.right-report-view.header.map.text': '地图',
    'records.right-report-view.header.data.text': '数据',
    'records.right-report-view.no-data.text': '无数据',
    'records.right-report-view.table.header.other.text': '其他',
    'records.right-report-view.footer.btn.print.text': '印章',
    'records.right-report-view.footer.btn.close.text': '关闭',
    'records.right-report-view.footer.btn.save.text': '在文件保存',
  },
  ar: {
    'records.button-block.btn.label': 'إنشاء',
    'records.left-sb.template.header.label': 'قالب التقرير',
    'records.left-sb.period.header.label': 'فترة التقرير',
    'records.left-sb.period.btn.today.text': 'اليوم',
    'records.left-sb.period.btn.yesterday.text': 'أمس',
    'records.left-sb.period.btn.week.text': 'أسبوع',
    'records.left-sb.period.btn.month.text': 'شهر',
    'records.left-sb.period.field.from.label': 'من',
    'records.left-sb.period.field.from.placeholder': 'اختر الوقت',
    'records.left-sb.period.field.from.time-caption.text': 'وقت',
    'records.left-sb.period.field.to.label': 'حتى',
    'records.left-sb.period.field.to.placeholder': 'اختر الوقت',
    'records.left-sb.period.field.to.time-caption.text': 'وقت',
    'records.left-sb.period.field.type.label': 'نوع الكائن',
    'records.left-sb.period.field.type.placeholder': 'اختر من القائمة',
    'records.left-sb.period.field.name.label': 'اسم الكائن أو محموعة الكائنات',
    'records.left-sb.period.field.name.placeholder': 'اختر من القائمة',
    'records.left-sb.period.field.history.btn.label': 'تاريخ التقارير',
    'records.left-sb.period.field.history.btn.close.label': 'أغلق',
    'records.left-sb.period.field.generate.btn.label': 'توليد التقرير',
    'records.card.template-create.header.label': 'إنشاء قالب التقرير',
    'records.card.template-edit.header.label': 'تعديل قالب التقرير',
    'records.card.template-create.tabs.general.text': 'عامة',
    'records.card.template-create.tabs.data.text': 'بيانات',
    'records.card.template-create.tabs.conditions.text': 'شروط',
    'records.card.template-create.general.field.name.label': 'اسم القالب',
    'records.card.template-create.general.field.name.placeholder': 'أكتب اسمًا',
    'records.card.template-create.general.field.desc.label': 'وصف القالب',
    'records.card.template-create.general.field.desc.placeholder': 'أكتب وصفًا',
    'records.card.template-create.general.field.users.label': 'يتوفر القالب للمستخدمين',
    'records.card.template-create.general.field.users.placeholder': 'اختر من القائمة',
    'records.card.template-create.general.field.users.tooltip':
      'في حال عدم تحديد المستخدمين لرؤية القالب، فسيكون القالب مرئيًا لجميع المستخدمين.',
    'records.card.template-create.general.field.template.label': 'قالب للطباعة',
    'records.card.template-create.general.field.template.placeholder': 'اختر قالب للطباعة',
    'records.card.template-create.general.field.template.tooltip.text':
      'إذا لم يتم تحديد القالب ، فسيتم عرض التقرير افتراضيًا في نموذج جدولي بسيط وفقًا للبنية المحددة في علامتي التبويب "البيانات" و "الشروط".',
    'records.card.template-create.general.field.new-template-pf.btn.text': 'قالب طباعة جديد',
    'records.card.template-create.data.available.header.text': 'البيانات المتوفرة للإضافة',
    'records.card.template-create.data.select.header.text': 'البيانات/العواميد المحددة',
    'records.card.template-create.data.select.btn.text': 'إضافة عمود الحساب',
    'records.card.template-create.data.select.tooltip.text':
      'يتيح إضافة عمود حيث يمكن إجراء عمليات حسابية على بيانات من أعمدة أخرى. مثلاً، إدخال الصيغة "A+B" إيؤدي إلى حساب البيانات من عمودين A و B وعرض المجموع في عمود جديد. إذا تعذر حساب البيانات (في حال احتوت نصًا أو أرقام)، فسيتم عرض رسالة خطأ العملية.',
    'records.card.template-create.data.select.data.placeholder': 'أكتب صيغة الحساب',
    'records.card.template-create.data.available.group.employees.text': 'موظفون',
    'records.card.template-create.data.available.group.transports.text': 'وسائل النقل',
    'records.card.template-create.data.available.group.geozone.text': 'مناطق',
    'records.card.template-create.data.available.group.users.text': 'مستخدمون',
    'records.card.template-create.data.available.group.trackers.text': 'متتبعات',
    'records.card.template-create.data.available.group.poi.text': 'نقاط POI',
    'records.card.template-create.data.available.group.notifications.text': 'إشعارات وانتهاكات',
    'records.card.template-create.data.available.group.custom.text': 'الخانات المعتادة',
    'records.card.template-create.data.available.employees.field.fio.text': 'اسم الموظف الكامل',
    'records.card.template-create.data.available.employees.field.group.text': 'مجموعة ااموظفين',
    'records.card.template-create.data.available.employees.field.organization.text': 'مؤسسة',
    'records.card.template-create.data.available.employees.field.department.text': 'الشعبة',
    'records.card.template-create.data.available.employees.field.work-obj.text': 'مكان العمل',
    'records.card.template-create.data.available.employees.field.position.text': 'الوظيفة',
    'records.card.template-create.data.available.employees.field.telephone.text': 'رقم هاتف الموظف',
    'records.card.template-create.data.available.employees.field.pass.text': 'رقم التصريح للموظف',
    'records.card.template-create.data.available.employees.field.pass_start.text': 'تاريخ بدء سريان التصريح',
    'records.card.template-create.data.available.employees.field.pass_end.text': 'تاريخ انتهاء سريان التصريح',
    'records.card.template-create.data.available.employees.field.tracker.text': 'المتتبع المرتبط',
    'records.card.template-create.data.available.employees.field.coords.text': 'الإحداثيات الجارية للموظف',
    'records.card.template-create.data.available.transports.field.brand.text': 'طراز وسيلة النقل',
    'records.card.template-create.data.available.transports.field.model.text': 'طراز وسيلة النقل',
    'records.card.template-create.data.available.transports.field.reg_number.text': 'الرقم التسجيلي لوسيلة النقل',
    'records.card.template-create.data.available.transports.field.production.text': 'سنة الإصدار',
    'records.card.template-create.data.available.transports.field.group.text': 'مجموعة وسائل النقل',
    'records.card.template-create.data.available.transports.field.color.text': 'لون وسيلة النقل',
    'records.card.template-create.data.available.transports.field.capacity.text': 'سعة الحمولة',
    'records.card.template-create.data.available.transports.field.organization.text': '单位',
    'records.card.template-create.data.available.transports.field.department.text': 'الشعبة',
    'records.card.template-create.data.available.transports.field.work-obj.text': 'مكان العمل',
    'records.card.template-create.data.available.transports.field.driver.text': 'الاسم الكامل لسائق وسيلة النقل',
    'records.card.template-create.data.available.transports.field.fuel_type.text': 'نوع الوقود',
    'records.card.template-create.data.available.transports.field.tracker.text': 'المتتبع المرتبط',
    'records.card.template-create.data.available.transports.field.coords.text': 'الإحداثيات الجارية لوسيلة النقل',
    'records.card.template-create.data.available.geofences.field.name.text': 'اسم المنطقة الجغرافية',
    'records.card.template-create.data.available.geofences.field.group.text': 'مجموعة المناطق الجغرافية',
    'records.card.template-create.data.available.geofences.field.description.text': 'وصف المنطقة',
    'records.card.template-create.data.available.geofences.field.coords.text': 'إحداثيات المنطقة الجغرافية',
    'records.card.template-create.data.available.geofences.field.date_in.text':
      'تاريخ ووقت الدخول إلى المنطقة الجغرافية',
    'records.card.template-create.data.available.geofences.field.date_out.text':
      'تاريخ ووقت الخروج من المنطقة الجغرافية',
    'records.card.template-create.data.available.geofences.field.duration.text': 'مدة التواجد في المنطقة الجغرافية',
    'records.card.template-create.data.available.users.field.fio.text': 'اسم المستخدم الكامل',
    'records.card.template-create.data.available.users.field.organization.text': 'مؤسسة',
    'records.card.template-create.data.available.users.field.department.text': 'الشعبة',
    'records.card.template-create.data.available.users.field.work-obj.text': 'مكان العمل',
    'records.card.template-create.data.available.users.field.position.text': 'الوظيفة',
    'records.card.template-create.data.available.users.field.telephone.text': 'رقم هاتف الموظف',
    'records.card.template-create.data.available.users.field.email.text': 'بريد إلكتروني',
    'records.card.template-create.data.available.users.field.login.text': 'تسجيل دخول',
    'records.card.template-create.data.available.users.field.date_in.text': 'تاريخ ووقت دخول المستخدم',
    'records.card.template-create.data.available.users.field.date_out.text': 'تاريخ ووقت خروج المستخدم',
    'records.card.template-create.data.available.users.field.duration.text': 'مدة تواجد المستخدم',
    'records.card.template-create.data.available.tracks.field.number.text': 'اسم المتتبع',
    'records.card.template-create.data.available.tracks.field.model.text': 'طراز المتتبع',
    'records.card.template-create.data.available.tracks.field.imei.text': 'IMEI لرقم المتتبع',
    'records.card.template-create.data.available.tracks.field.mac.text': 'عنوان MAC للمتتبع',
    'records.card.template-create.data.available.tracks.field.sim.text': 'رقم شريحة SIM للمتتبع',
    'records.card.template-create.data.available.tracks.field.server_address.text': 'عنوان خادم المتتبع',
    'records.card.template-create.data.available.tracks.field.state.text': 'حالة المتتبع',
    'records.card.template-create.data.available.tracks.field.comment.text': 'تعليق على حالة المتتبع',
    'records.card.template-create.data.available.tracks.field.linked_obj.text': 'الكائن المرتبط',
    'records.card.template-create.data.available.tracks.field.coords.text': 'الإحداثيات الجارية للمتتبع',
    'records.card.template-create.data.available.tracks.field.current_battery_charge.text': 'الشحن الحالي للبطارية',
    'records.card.template-create.data.available.tracks.field.battery_charge_time.text':
      'وقت الإخطار الخاص بشحن البطارية',
    'records.card.template-create.data.available.poi.field.type.text': 'نوع نقطة POI',
    'records.card.template-create.data.available.poi.field.videocamera_name.text': 'اسم كاميرا الفيديو',
    'records.card.template-create.data.available.poi.field.videocamera_desc.text': 'وصف كاميرا الفيديو',
    'records.card.template-create.data.available.poi.field.videocamera_model.text': 'طراز كاميرا الفيديو',
    'records.card.template-create.data.available.poi.field.gazanlyzer_name.text': 'اسم محلل الغاز',
    'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text': 'وصف محلل الغاز',
    'records.card.template-create.data.available.poi.field.gazanlyzer_model.text': 'طراز محلل الغاز',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text': 'أجهزة استشعار الغاز',
    'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text': 'غازات',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text': 'وحدات القياس',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text': 'نطاق القيَّم',
    'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text': 'الحد',
    'records.card.template-create.data.available.poi.field.gazanlyzer_current.text': 'القيمة الجارية',
    'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text': 'تاريخ التفقد الأخير',
    'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text': 'تاريخ التفقد التالي',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text': 'اسم المستشعر',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text': 'وصف المستشعر',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text': 'نوع المستشعر',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text': 'طراز المستشعر',
    'records.card.template-create.data.available.poi.field.equip_name.text': 'اسم العدة',
    'records.card.template-create.data.available.poi.field.equip_desc.text': 'وصف العدة',
    'records.card.template-create.data.available.poi.field.equip_type.text': 'نوع العدة',
    'records.card.template-create.data.available.poi.field.equip_model.text': 'طراز العدة',
    'records.card.template-create.data.available.poi.field.accident_name.text': 'اسم الحدث',
    'records.card.template-create.data.available.poi.field.accident_desc.text': 'وصف الحدث',
    'records.card.template-create.data.available.poi.field.accident_type.text': 'نوع الحدث',
    'records.card.template-create.data.available.poi.field.free_point_name.text': 'اسم النقطة الحرة',
    'records.card.template-create.data.available.poi.field.free_point_desc.text': 'وصف النقطة الحرة',
    'records.card.template-create.data.available.poi.field.free_point_validity.text': 'تاريخ سريان نقطة POI',
    'records.card.template-create.data.available.poi.field.free_point_coords.text': 'إحداثيات نقطة POI',
    'records.card.template-create.data.available.notifications.field.name.text': 'اسم الإشعار',
    'records.card.template-create.data.available.notifications.field.text.text': 'نص الإشعار',
    'records.card.template-create.data.available.notifications.field.type.text': 'نوع الإشعار',
    'records.card.template-create.data.available.notifications.field.triggered_date.text': 'تاريخ ووقت تفعيل الإشعار',
    'records.card.template-create.data.available.notifications.field.sos.text': 'إنذار طلب النجدة',
    'records.card.template-create.data.available.notifications.field.failing.text': 'وقوع المتتبع',
    'records.card.template-create.data.available.notifications.field.inaction.text': 'عدم الاستجابة',
    'records.card.template-create.data.available.notifications.field.loss_connection.text': 'فقدان الاتصال',
    'records.card.template-create.data.available.notifications.field.restoring_connection.text': 'استعادة الاتصال',
    'records.card.template-create.data.available.notifications.field.violation.text': 'انتهاك',
    'records.card.template-create.data.available.notifications.field.geozone_in.text': 'دخول إلى المنطقة الجغرافية',
    'records.card.template-create.data.available.notifications.field.geozone_out.text': 'خروج من المنطقة الجغرافية',
    'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text':
      'عدم تواجد الكائن في المنطقة الجغرافية',
    'records.card.template-create.data.available.notifications.field.geozone_long_in.text':
      'تواجد الكائن في المنطقة الجغرافية للترة طويلة من الزمن',
    'records.card.template-create.data.available.notifications.field.geozone_convergence.text': 'اقتراب الكائنات',
    'records.card.template-create.data.available.notifications.field.geozone_away.text': 'ابتعاد الكائنات',
    'records.card.template-create.data.available.notifications.field.coords.text': 'إحداثيات الإشعار',
    'records.card.template-create.condition.grouping.label': 'فرز',
    'records.card.template-create.condition.grouping.by-year.text': 'سنة',
    'records.card.template-create.condition.grouping.by-month.text': 'شهر',
    'records.card.template-create.condition.grouping.by-date.text': 'يوم الشهر',
    'records.card.template-create.condition.grouping.by-dayofweek.text': 'يوم الأسبوع',
    'records.card.template-create.condition.grouping.by-objecttype.text': 'نوع الكائنات',
    'records.card.template-create.condition.grouping.by-object.text': 'الكائن',
    'records.card.template-create.condition.grouping.tooltip.text': 'فرز البيانات ببنية التقرير',
    'records.card.template-create.condition.map.label': 'خريطة',
    'records.card.template-create.condition.map.geofences.text': 'عرض المناطق',
    'records.card.template-create.condition.map.notificationmarkers.text': 'عرض معلّمات الإشعارات',
    'records.card.template-create.condition.map.movementtracks.text': 'عرض مسارات التنقل',
    'records.card.template-create.condition.map.poipoints.text': 'عرض POI',
    'records.card.template-create.condition.map.heatmaps.text': 'عرض الخريطة الحرارية',
    'records.card.template-create.condition.map.tooltip.text': 'استنطاق عناصر خريطة الكائن إلى التقرير',
    'records.card.template-create.condition.additional-fields.text': 'مرشحات إضافية للتقرير',
    'records.card.template-create.condition.additional-fields.tooltip.text':
      'ترشيح المعلومات في التبربر بحسب: مناطق جغرافية، إشعارات، مؤسسات، شُعب، وظائف الموظفين، أدوار المستخدمين',
    'records.card.template-create.condition.additional-fields.by-geozone.text': 'مناطق جغرافية',
    'records.card.template-create.condition.additional-fields.by-notification.text': 'إشعارات',
    'records.card.template-create.condition.additional-fields.by-organization.text': 'مؤسسات',
    'records.card.template-create.condition.additional-fields.by-department.text': 'شُعب',
    'records.card.template-create.condition.additional-fields.by-position.text': 'وظائف',
    'records.card.template-create.condition.additional-fields.by-role.text': 'صلاحيات',
    'records.card.template-create.condition.additional-fields.object-name.label': 'أسماء الكائنات بحسب المرشح المختار',
    'records.card.template-create.condition.additional-fields.object-name.placeholder': 'اختر الكائنات',
    'records.card.template-create.condition.additional-fields.numbering-line.text': 'ترقيم الـأسطر',
    'records.card.template-create.condition.additional-fields.display-total.text': 'عرض "إجمالي"',
    'records.card.template-create.condition.additional-fields.notification.alert.text':
      'طلب نجدة، وقوع، فقد الاتصال، عدم الاستجابة',
    'records.card.template-create.condition.additional-fields.notification.geozone.text': 'مناطق',
    'records.card.template-create.condition.additional-fields.notification.interposition.text':
      'تموضع الكائنات لبعضها البعض',
    'records.card.template-create.condition.additional-fields.notification.sensor.text': 'قيمة المستشعر',
    'records.card.template-create.footer.btn.copy.label': 'نسخ',
    'records.card.template-create.footer.btn.delete.label': 'حذف',
    'records.card.template-create.footer.btn.cancel.label': 'إلغاء',
    'records.card.template-create.footer.btn.save.label': 'حفظ',
    'records.card.template-create.footer.btn.update.label': 'تحديث',
    'records.card.template-create.alert.title': 'إغلاق',
    'records.card.template-create.alert.info.text': 'هناك بيانات غير محفوظة في النموذج.',
    'records.card.template-list.alert.delete.title': 'حذف القالب',
    'records.card.template-list.alert.delete.text': 'لا يمكن استعادة القالب المحذوف. هل أنت متأكد أنك تريد حذف القالب؟',
    'records.report-create.object-types.user.text': 'المستخدمون',
    'records.report-create.object-types.employee.text': 'الموظفين',
    'records.report-create.object-types.transport.text': 'النقل',
    'records.report-create.object-types.tracker.text': 'المتتبعون',
    'records.report-create.object-types.poipoint.text': 'نقاط POI',
    'records.report-create.popup.header.text': 'جاري إنشاء التقرير ...',
    'records.report-create.popup.content.text1': 'قد يستغرق إنشاء التقرير وقتًا طويلاً.',
    'records.report-create.popup.content.text2': 'يمكنك الآن إغلاق هذه النافذة ومتابعة العمل مع النظام.',
    'records.report-create.popup.content.text3':
      'يمكن الوصول إلى التقرير الذي تم إنشاؤه لاحقًا بالنقر فوق الزر "سجل التقارير".',
    'records.report-create.popup.content.text4': 'يتم حفظ آخر 10 تقارير تم إنشاؤها تلقائيًا في "سجل التقارير".',
    'records.report-create.popup.footer.btn.close.label': 'أغلق',
    'records.report-history.header.text': 'تاريخ التقارير',
    'records.report-history.list.header.status.is-done.text': 'جاهز',
    'records.report-history.list.header.status.in-progress.text': 'في العمل',
    'records.report-history.list.header.status.is-error.text': 'خطأ',
    'records.report-history.list.item.no-name.text': 'بدون أسم',
    'records.report-history.list.item.delete.text': 'حذف ',
    'records.report-history.list.alert.delete.title': 'حذف التقرير',
    'records.report-history.list.alert.delete.text': 'لا يمكن التراجع عن حذف تقرير ولا يمكن استعادة البيانات.',
    'records.report-history.list.footer.date.text': 'تاريخ التقرير',
    'records.report-history.list.footer.period.text': 'فترة التقرير',
    'records.report-history.list.footer.objects.text': 'موضوعات',
    'records.right-report-view.header.map.text': 'خريطة',
    'records.right-report-view.header.data.text': 'البيانات',
    'records.right-report-view.no-data.text': 'لا توجد بيانات',
    'records.right-report-view.table.header.other.text': 'أخرى',
    'records.right-report-view.footer.btn.print.text': 'طباعة',
    'records.right-report-view.footer.btn.close.text': 'أغلق',
    'records.right-report-view.footer.btn.save.text': 'الحفظ في ملف',
  },
  de: {
    'records.button-block.btn.label': 'Erstellen',
    'records.left-sb.template.header.label': 'Berichtsvorlage',
    'records.left-sb.period.header.label': 'Berichtszeitraum',
    'records.left-sb.period.btn.today.text': 'Heute',
    'records.left-sb.period.btn.yesterday.text': 'Gestern',
    'records.left-sb.period.btn.week.text': 'Woche',
    'records.left-sb.period.btn.month.text': 'Monat',
    'records.left-sb.period.field.from.label': 'Von',
    'records.left-sb.period.field.from.placeholder': 'Zeit wählen',
    'records.left-sb.period.field.from.time-caption.text': 'Zeit',
    'records.left-sb.period.field.to.label': 'bis zum',
    'records.left-sb.period.field.to.placeholder': 'Zeit wählen',
    'records.left-sb.period.field.to.time-caption.text': 'Zeit',
    'records.left-sb.period.field.type.label': 'Objekttyp',
    'records.left-sb.period.field.type.placeholder': 'Aus der Liste wählen',
    'records.left-sb.period.field.name.label': 'Name eines Objekts oder einer Gruppe von Objekten',
    'records.left-sb.period.field.name.placeholder': 'Aus der Liste wählen',
    'records.left-sb.period.field.history.btn.label': 'Berichtsverlauf',
    'records.left-sb.period.field.history.btn.close.label': 'Schließen',
    'records.left-sb.period.field.generate.btn.label': 'Generieren Sie einen Bericht',
    'records.card.template-create.header.label': 'Erstellen einer Berichtsvorlage',
    'records.card.template-edit.header.label': 'Bearbeiten einer Berichtsvorlage',
    'records.card.template-create.tabs.general.text': 'Allgemeines',
    'records.card.template-create.tabs.data.text': 'Daten',
    'records.card.template-create.tabs.conditions.text': 'Bedingungen',
    'records.card.template-create.general.field.name.label': 'Name der Berichtsvorlage',
    'records.card.template-create.general.field.name.placeholder': 'Geben Sie den Namen ein',
    'records.card.template-create.general.field.desc.label': 'Vorlagenbeschreibung',
    'records.card.template-create.general.field.desc.placeholder': 'Geben Sie eine Beschreibung ein',
    'records.card.template-create.general.field.users.label': 'Benutzer, die die Vorlage sehen',
    'records.card.template-create.general.field.users.placeholder': 'Aus der Liste wählen',
    'records.card.template-create.general.field.users.tooltip':
      'Wenn keine Benutzer ausgewählt sind, ist die Berichtsvorlage für alle Benutzer sichtbar.',
    'records.card.template-create.general.field.template.label': 'Druckbare Vorlage',
    'records.card.template-create.general.field.template.placeholder': 'Wählen Sie eine druckfähige Vorlage aus',
    'records.card.template-create.general.field.template.tooltip.text':
      'Wenn die Vorlage nicht angegeben ist, wird der Bericht standardmäßig in einfacher Tabellenform gemäß der auf den Registerkarten „Daten“ und „Bedingungen“ festgelegten Struktur angezeigt.',
    'records.card.template-create.general.field.new-template-pf.btn.text': 'neue druckbare Vorlage',
    'records.card.template-create.data.available.header.text': 'Daten zum Hinzufügen verfügbar',
    'records.card.template-create.data.select.header.text': 'Ausgewählte Daten, Spalten',
    'records.card.template-create.data.select.btn.text': 'Spalte mit Berechnung hinzufügen',
    'records.card.template-create.data.select.tooltip.text':
      'Ermöglicht das Hinzufügen einer Spalte, in der Sie arithmetische Operationen für Daten aus anderen Spalten ausführen können. Wenn Sie beispielsweise die Formel „A + B“ eingeben, werden Daten aus zwei Spalten A und B hinzugefügt und die Summe in einer neuen Spalte angezeigt. Wenn die Daten nicht hinzugefügt werden können (z. B. Text und Zahlen), wird eine Betriebsfehlermeldung angezeigt.',
    'records.card.template-create.data.select.data.placeholder': 'Geben Sie die zu berechnende Formel ein',
    'records.card.template-create.data.available.group.employees.text': 'Mitarbeiter',
    'records.card.template-create.data.available.group.transports.text': 'Transport',
    'records.card.template-create.data.available.group.geozone.text': 'Geofences',
    'records.card.template-create.data.available.group.users.text': 'Benutzer',
    'records.card.template-create.data.available.group.trackers.text': 'Tracker',
    'records.card.template-create.data.available.group.poi.text': 'POI-Punkte',
    'records.card.template-create.data.available.group.notifications.text': 'Hinweise und Verstöße',
    'records.card.template-create.data.available.group.custom.text': 'Benutzerdefinierte Felder',
    'records.card.template-create.data.available.employees.field.fio.text': 'Vollständiger Name des Mitarbeiters',
    'records.card.template-create.data.available.employees.field.group.text': 'Gruppe von Mitarbeitern',
    'records.card.template-create.data.available.employees.field.organization.text': 'Organisation',
    'records.card.template-create.data.available.employees.field.department.text': 'Strukturelle Niederlassung',
    'records.card.template-create.data.available.employees.field.work-obj.text': 'Gegenstand der Arbeit',
    'records.card.template-create.data.available.employees.field.position.text': 'Dienststelle',
    'records.card.template-create.data.available.employees.field.telephone.text': 'Telefonnummer des Mitarbeiters',
    'records.card.template-create.data.available.employees.field.pass.text': 'Mitarbeiterausweisnummer',
    'records.card.template-create.data.available.employees.field.pass_start.text': 'Startdatum des Passes',
    'records.card.template-create.data.available.employees.field.pass_end.text': 'Ablaufdatum des Passes',
    'records.card.template-create.data.available.employees.field.tracker.text': 'Gebundener Tracker',
    'records.card.template-create.data.available.employees.field.coords.text': 'Aktuelle Koordinaten des Mitarbeiters',
    'records.card.template-create.data.available.transports.field.brand.text': 'Fahrzeugmarke',
    'records.card.template-create.data.available.transports.field.model.text': 'Fahrzeugmodell',
    'records.card.template-create.data.available.transports.field.reg_number.text': 'Kfz-Kennzeichen',
    'records.card.template-create.data.available.transports.field.production.text': 'Herstellungsjahr des Fahrzeugs',
    'records.card.template-create.data.available.transports.field.group.text': 'Transportgruppe',
    'records.card.template-create.data.available.transports.field.color.text': 'Fahrzeugfarbe',
    'records.card.template-create.data.available.transports.field.capacity.text': 'Tragfähigkeit',
    'records.card.template-create.data.available.transports.field.organization.text': 'Organisation',
    'records.card.template-create.data.available.transports.field.department.text': 'Strukturelle Niederlassung',
    'records.card.template-create.data.available.transports.field.work-obj.text': 'Gegenstand der Arbeit',
    'records.card.template-create.data.available.transports.field.driver.text':
      'Vollständiger Name des Fahrzeugführers',
    'records.card.template-create.data.available.transports.field.fuel_type.text': 'Fahrzeugtreibstoffart',
    'records.card.template-create.data.available.transports.field.tracker.text': 'Gebundener Tracker',
    'records.card.template-create.data.available.transports.field.coords.text': 'Aktuelle Koordinaten des Transports',
    'records.card.template-create.data.available.geofences.field.name.text': 'Geofence-Name',
    'records.card.template-create.data.available.geofences.field.group.text': 'Geofence-Gruppe',
    'records.card.template-create.data.available.geofences.field.description.text': 'Geofence-Beschreibung',
    'records.card.template-create.data.available.geofences.field.coords.text': 'Geofence-Koordinaten',
    'records.card.template-create.data.available.geofences.field.date_in.text':
      'Datum und Uhrzeit des Betretens des Geofence',
    'records.card.template-create.data.available.geofences.field.date_out.text':
      'Datum und Uhrzeit des Verlassens des Geofence',
    'records.card.template-create.data.available.geofences.field.duration.text': 'Aufenthaltsdauer im Geofence',
    'records.card.template-create.data.available.users.field.fio.text': 'Vollständiger Name des Benutzers',
    'records.card.template-create.data.available.users.field.organization.text': 'Organisation',
    'records.card.template-create.data.available.users.field.department.text': 'Strukturelle Niederlassung',
    'records.card.template-create.data.available.users.field.work-obj.text': 'Gegenstand der Arbeit',
    'records.card.template-create.data.available.users.field.position.text': 'Dienststelle',
    'records.card.template-create.data.available.users.field.telephone.text': 'Benutzertelefonnummer',
    'records.card.template-create.data.available.users.field.email.text': 'E-Mail',
    'records.card.template-create.data.available.users.field.login.text': 'Login',
    'records.card.template-create.data.available.users.field.date_in.text': 'Datum und Uhrzeit der Benutzeranmeldung',
    'records.card.template-create.data.available.users.field.date_out.text': 'Datum und Uhrzeit der Benutzerabmeldung',
    'records.card.template-create.data.available.users.field.duration.text': 'Aufenthaltsdauer des Nutzers',
    'records.card.template-create.data.available.tracks.field.number.text': 'Tracker-Nummer',
    'records.card.template-create.data.available.tracks.field.model.text': 'Tracker-Modell',
    'records.card.template-create.data.available.tracks.field.imei.text': 'Geben Sie IMEI ein',
    'records.card.template-create.data.available.tracks.field.mac.text': 'Tracker-MAC-Adresse',
    'records.card.template-create.data.available.tracks.field.sim.text': 'Tracker-SIM-Nummer',
    'records.card.template-create.data.available.tracks.field.server_address.text': 'Tracker-Serveradresse',
    'records.card.template-create.data.available.tracks.field.state.text': 'Tracker-Status',
    'records.card.template-create.data.available.tracks.field.comment.text': 'Kommentar zum Status des Trackers',
    'records.card.template-create.data.available.tracks.field.linked_obj.text': 'Gebundenes Objekt',
    'records.card.template-create.data.available.tracks.field.coords.text': 'Aktuelle Koordinaten des Trackers',
    'records.card.template-create.data.available.tracks.field.current_battery_charge.text': 'Aktuelle Batterieladung',
    'records.card.template-create.data.available.tracks.field.battery_charge_time.text': 'Batteriewarnzeit',
    'records.card.template-create.data.available.poi.field.type.text': 'POI-Typ',
    'records.card.template-create.data.available.poi.field.videocamera_name.text': 'Name des Camcorders',
    'records.card.template-create.data.available.poi.field.videocamera_desc.text': 'Beschreibung des Camcorders',
    'records.card.template-create.data.available.poi.field.videocamera_model.text': 'Camcorder-Modell',
    'records.card.template-create.data.available.poi.field.gazanlyzer_name.text': 'Name des Gasanalysators',
    'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text': 'Beschreibung des Gasanalysators',
    'records.card.template-create.data.available.poi.field.gazanlyzer_model.text': 'Gasanalysator-Modell',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text': 'Sensoren',
    'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text': 'Gase',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text': 'Meßeinheit',
    'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text': 'Wertebereich',
    'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text': 'Grenze',
    'records.card.template-create.data.available.poi.field.gazanlyzer_current.text': 'Vorhandener Wert',
    'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text': 'Datum der letzten Überprüfung',
    'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text': 'Datum der nächsten Prüfung',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text': 'Sensorname',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text': 'Sensorbeschreibung',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text': 'Sensorart',
    'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text': 'Sensormodell',
    'records.card.template-create.data.available.poi.field.equip_name.text': 'Ausrüstungsname',
    'records.card.template-create.data.available.poi.field.equip_desc.text': 'Gerätebeschreibung',
    'records.card.template-create.data.available.poi.field.equip_type.text': 'Ausstattungsart',
    'records.card.template-create.data.available.poi.field.equip_model.text': 'Ausstattungsmodell',
    'records.card.template-create.data.available.poi.field.accident_name.text': 'Vorfallname',
    'records.card.template-create.data.available.poi.field.accident_desc.text': 'Beschreibung des Vorfalls',
    'records.card.template-create.data.available.poi.field.accident_type.text': 'Vorfalltyp',
    'records.card.template-create.data.available.poi.field.free_point_name.text': 'Kostenloser Punktname',
    'records.card.template-create.data.available.poi.field.free_point_desc.text': 'Kostenlose Punktbeschreibung',
    'records.card.template-create.data.available.poi.field.free_point_validity.text': 'Gültigkeitsdatum des POI',
    'records.card.template-create.data.available.poi.field.free_point_coords.text': 'POI-Koordinaten eines Punktes',
    'records.card.template-create.data.available.notifications.field.name.text': 'Name der Benachrichtigung',
    'records.card.template-create.data.available.notifications.field.text.text': 'Benachrichtigungstext',
    'records.card.template-create.data.available.notifications.field.type.text': 'Benachrichtigungstyp',
    'records.card.template-create.data.available.notifications.field.triggered_date.text':
      'Datum und Uhrzeit, zu der die Benachrichtigung ausgelöst wurde',
    'records.card.template-create.data.available.notifications.field.sos.text': 'SOS-Signal',
    'records.card.template-create.data.available.notifications.field.failing.text': 'Fallender Tracker',
    'records.card.template-create.data.available.notifications.field.inaction.text': 'Untätigkeit',
    'records.card.template-create.data.available.notifications.field.loss_connection.text': 'Verbindungsverlust',
    'records.card.template-create.data.available.notifications.field.restoring_connection.text':
      'Wiederherstellung der Kommunikation',
    'records.card.template-create.data.available.notifications.field.violation.text': 'Verstoß',
    'records.card.template-create.data.available.notifications.field.geozone_in.text': 'Geofence-Eingang',
    'records.card.template-create.data.available.notifications.field.geozone_out.text': 'Verlassen des Geofence',
    'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text':
      'Fehlen eines Objekts im Geofence',
    'records.card.template-create.data.available.notifications.field.geozone_long_in.text':
      'Längerer Aufenthalt eines Objekts in einem Geofence',
    'records.card.template-create.data.available.notifications.field.geozone_convergence.text':
      'Konvergenz von Objekten',
    'records.card.template-create.data.available.notifications.field.geozone_away.text': 'Gegenstände wegbewegen',
    'records.card.template-create.data.available.notifications.field.coords.text': 'Koordinaten der Benachrichtigung',
    'records.card.template-create.condition.grouping.label': 'Gruppierung',
    'records.card.template-create.condition.grouping.by-year.text': 'Jahr',
    'records.card.template-create.condition.grouping.by-month.text': 'Monat',
    'records.card.template-create.condition.grouping.by-date.text': 'Nummer',
    'records.card.template-create.condition.grouping.by-dayofweek.text': 'Wochentag',
    'records.card.template-create.condition.grouping.by-objecttype.text': 'Objekttyp',
    'records.card.template-create.condition.grouping.by-object.text': 'Objekt',
    'records.card.template-create.condition.grouping.tooltip.text': 'Gruppieren von Daten in der Berichtsstruktur',
    'records.card.template-create.condition.map.label': 'Karte',
    'records.card.template-create.condition.map.geofences.text': 'Geofences anzeigen',
    'records.card.template-create.condition.map.notificationmarkers.text': 'Benachrichtigungsmarkierungen anzeigen',
    'records.card.template-create.condition.map.movementtracks.text': 'Bewegungsstrecken anzeigen',
    'records.card.template-create.condition.map.poipoints.text': 'POI-Punkte anzeigen',
    'records.card.template-create.condition.map.heatmaps.text': 'Heatmap anzeigen',
    'records.card.template-create.condition.map.tooltip.text':
      'Ausgabe in den Bericht der Elemente der Objektzuordnung',
    'records.card.template-create.condition.additional-fields.text': 'Zusätzliche Filter für den Bericht',
    'records.card.template-create.condition.additional-fields.tooltip.text':
      'Filtern Sie die Anzeige von Informationen im Bericht nach: Geofences, Benachrichtigungen, Organisationen, Strukturabteilungen, Mitarbeiterpositionen, Benutzerrollen',
    'records.card.template-create.condition.additional-fields.by-geozone.text': 'Geofences',
    'records.card.template-create.condition.additional-fields.by-notification.text': 'Benachrichtigungen',
    'records.card.template-create.condition.additional-fields.by-organization.text': 'Organisation',
    'records.card.template-create.condition.additional-fields.by-department.text': 'Niederlassungen',
    'records.card.template-create.condition.additional-fields.by-position.text': 'Dienststellen',
    'records.card.template-create.condition.additional-fields.by-role.text': 'Rollen',
    'records.card.template-create.condition.additional-fields.object-name.label':
      'Objektnamen nach ausgewähltem Filter',
    'records.card.template-create.condition.additional-fields.object-name.placeholder': 'Objekte auswählen',
    'records.card.template-create.condition.additional-fields.numbering-line.text': 'Zeilennummerierung',
    'records.card.template-create.condition.additional-fields.display-total.text': 'Zeigen Sie die Zeile "Gesamt" an',
    'records.card.template-create.condition.additional-fields.notification.alert.text':
      'SOS, Sturz, Verbindungsverlust, Untätigkeit',
    'records.card.template-create.condition.additional-fields.notification.geozone.text': 'Geofences',
    'records.card.template-create.condition.additional-fields.notification.interposition.text':
      'Relative Position von Objekten',
    'records.card.template-create.condition.additional-fields.notification.sensor.text': 'Sensorwert',
    'records.card.template-create.footer.btn.copy.label': 'Kopieren',
    'records.card.template-create.footer.btn.delete.label': 'Löschen',
    'records.card.template-create.footer.btn.cancel.label': 'Zurücksetzen',
    'records.card.template-create.footer.btn.save.label': 'Speichern',
    'records.card.template-create.footer.btn.update.label': 'Aktualisieren',
    'records.card.template-create.alert.title': 'Schließen',
    'records.card.template-create.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'records.card.template-list.alert.delete.title': 'Vorlage löschen',
    'records.card.template-list.alert.delete.text':
      'Eine gelöschte Vorlage kann nicht wiederhergestellt werden. Möchten Sie die Vorlage wirklich löschen?',
    'records.report-create.object-types.user.text': 'Benutzer',
    'records.report-create.object-types.employee.text': 'Mitarbeiter',
    'records.report-create.object-types.transport.text': 'Transporte',
    'records.report-create.object-types.tracker.text': 'Tracker',
    'records.report-create.object-types.poipoint.text': 'POI-Punkte',
    'records.report-create.popup.header.text': 'Bericht wird erstellt…',
    'records.report-create.popup.content.text1': 'Das Erstellen eines Berichts kann lange dauern.',
    'records.report-create.popup.content.text2':
      'Jetzt können Sie dieses Fenster schließen und mit dem System weiterarbeiten.',
    'records.report-create.popup.content.text3':
      'Auf den generierten Bericht kann später zugegriffen werden, indem Sie auf die Schaltfläche „Berichtshistorie“ klicken.',
    'records.report-create.popup.content.text4':
      'Die „Berichtshistorie“ speichert automatisch die letzten 10 generierten Berichte.',
    'records.report-create.popup.footer.btn.close.label': 'Schließen',
    'records.report-history.header.text': 'Berichtshistorie',
    'records.report-history.list.header.status.is-done.text': 'Berichtshistorie',
    'records.report-history.list.header.status.in-progress.text': 'Fertig stellen',
    'records.report-history.list.header.status.is-error.text': 'Fehler',
    'records.report-history.list.item.no-name.text': 'ohne Name',
    'records.report-history.list.item.delete.text': 'Löschen',
    'records.report-history.list.alert.delete.title': 'Bericht löschen',
    'records.report-history.list.alert.delete.text':
      'Das Löschen eines Berichts kann nicht rückgängig gemacht werden und die Daten können nicht wiederhergestellt werden.',
    'records.report-history.list.footer.date.text': 'Berichtsdatum',
    'records.report-history.list.footer.period.text': 'Berichtszeitraum',
    'records.report-history.list.footer.objects.text': 'Objekte',
    'records.right-report-view.header.map.text': 'Karte',
    'records.right-report-view.header.data.text': 'Daten',
    'records.right-report-view.no-data.text': 'Keine Daten',
    'records.right-report-view.table.header.other.text': 'Sonstiges',
    'records.right-report-view.footer.btn.print.text': 'Druck',
    'records.right-report-view.footer.btn.close.text': 'Schließen',
    'records.right-report-view.footer.btn.save.text': 'Speichern unter',
  },
};
