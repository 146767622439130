import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import {
  DatesStatus,
  defaultTimeState,
  monthsNames,
  daysOfWeekNames,
  evenDaysNames,
  oddDaysNames,
} from 'components/notifications/utils/consts';

import { RootState } from 'reducers';
import { TimeSettings } from 'reducers/notifications/interface';

import { isEqualObjectsNoDeep } from 'utils/isEqualObjectsNoDeep';
import { DatesSelectedItemType } from '../utils/types';

export default function useInterpostionInfo() {
  const [isActionPeriodOn, setIsActionPeriodOn] = useState(defaultTimeState.isActionPeriodOn);
  const [periodFrom, setPeriodFrom] = useState(defaultTimeState.periodFrom);
  const [periodTo, setPeriodTo] = useState(defaultTimeState.periodTo);

  const [isIntervalsOn, setIsIntervalOn] = useState(defaultTimeState.isIntervalsOn);
  const [isPeriodLimitsOn, setIsPeriodLimitsOn] = useState(defaultTimeState.isPeriodLimitsOn);
  const [activationCountMax, setMaxOperations] = useState(defaultTimeState.activationCountMax);
  const [intervals, setIntervals] = useState(defaultTimeState.intervals);
  const [months, setMonths] = useState(defaultTimeState.months);
  const [daysOfWeek, setDaysOfWeek] = useState(defaultTimeState.daysOfWeek);

  const [isDaysOfWeekLimitOn, setIsDaysOfWeekLimitOn] = useState(defaultTimeState.isDaysOfWeekLimitOn);
  const [isDaysOfMonthLimitOn, setIsDaysOfMonthLimitOn] = useState(defaultTimeState.isDaysOfMonthLimitOn);

  const [evenDays, setEvenDays] = useState(defaultTimeState.evenDays);
  const [oddDays, setOddDays] = useState(defaultTimeState.oddDays);

  const startPeriodRef = React.createRef();
  const endPeriodRef = React.createRef();

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const { chosenNotification } = useSelector((state: RootState) => state.notifications);

  const [datesSelected, setDatesSelected] = useState(defaultTimeState.datesSelected);

  useEffect(() => {
    if (chosenNotification) {
      const { timeSettings } = chosenNotification.attributes;

      if (timeSettings) {
        setIsActionPeriodOn(timeSettings.isActionPeriodOn);
        setPeriodFrom(timeSettings.periodFrom);
        setPeriodTo(timeSettings.periodTo);
        setIsIntervalOn(timeSettings.isIntervalsOn);
        setIntervals(timeSettings.intervals);
        setIsPeriodLimitsOn(timeSettings.isPeriodLimitsOn);
        setMonths(timeSettings.months);
        setIsDaysOfWeekLimitOn(timeSettings.isDaysOfWeekLimitOn);
        setDaysOfWeek(timeSettings.daysOfWeek);
        setIsDaysOfMonthLimitOn(timeSettings.isDaysOfMonthLimitOn);
        setEvenDays(timeSettings.evenDays);
        setOddDays(timeSettings.oddDays);
        setMaxOperations(timeSettings.activationCountMax);
        setDatesSelected({
          months: monthsNames.map((month, i) => ({
            id: i + 1,
            text: month,
            status: timeSettings.months.includes(i) ? DatesStatus.selected : DatesStatus.disabled,
          })),
          daysOfWeek: daysOfWeekNames.map((day, i) => ({
            id: i + 1,
            text: day,
            status: timeSettings.daysOfWeek.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
          })),
          evenDays: evenDaysNames.map((day, i) => ({
            id: i + 1,
            text: String(day),
            status: timeSettings.evenDays.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
          })),
          oddDays: oddDaysNames.map((day, i) => ({
            id: i + 1,
            text: String(day),
            status: timeSettings.oddDays.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
          })),
        });
      }
    }
  }, [chosenNotification]);

  useEffect(() => {
    if (periodFrom && periodTo) {
      const periodFromDate = new Date(periodFrom);
      const periodToDate = new Date(periodTo);

      let monthValues = (periodToDate.getFullYear() - periodFromDate.getFullYear()) * 12;
      monthValues -= periodFromDate.getMonth() + 1;
      monthValues += periodToDate.getMonth() + 1;

      const diffMonths = monthValues <= 0 ? 0 : monthValues;

      if (diffMonths >= 12) {
        setDatesSelected(datesSelected => ({
          ...datesSelected,
          months: monthsNames.map((monthsName, i) => ({
            id: i + 1,
            text: monthsName,
            status: DatesStatus.selected,
          })),
        }));
      } else {
        setDatesSelected(datesSelected => ({
          ...datesSelected,
          months: monthsNames.map((monthsName, i) => {
            const fromMonth = periodFromDate.getMonth();
            const toMonth = periodToDate.getMonth();
            const fromYear = periodFromDate.getFullYear();
            const toYear = periodToDate.getFullYear();

            if (fromYear === toYear && i >= fromMonth && i <= toMonth) {
              if (months.length) {
                return {
                  id: i + 1,
                  text: monthsName,
                  status: months.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
                };
              } else {
                return {
                  id: i + 1,
                  text: monthsName,
                  status: DatesStatus.no_selected,
                };
              }
            }
            if (toYear - fromYear === 1) {
              return {
                id: i + 1,
                text: monthsName,
                status: i >= fromMonth || i <= toMonth ? DatesStatus.selected : DatesStatus.disabled,
              };
            }
            return {
              id: i + 1,
              text: monthsName,
              status: DatesStatus.disabled,
            };
          }),
        }));
      }
    }
  }, [periodFrom, periodTo, months]);

  function getNewDatesSelectedItem(item: DatesSelectedItemType, buttonId: number) {
    return item.map((day, i) => {
      if (day.id === buttonId) {
        if (day.status === DatesStatus.selected) {
          return {
            id: i + 1,
            status: DatesStatus.no_selected,
            text: day.text,
          };
        }
        if (day.status === DatesStatus.no_selected) {
          return {
            id: i + 1,
            status: DatesStatus.selected,
            text: day.text,
          };
        }
      }
      return day;
    });
  }

  const handleSelectMonths = (buttonId: number) => {
    if (buttonId) {
      setDatesSelected({
        ...datesSelected,
        months: getNewDatesSelectedItem(datesSelected.months, buttonId),
      });
    }
  };

  const handleSelectDaysOfWeek = (buttonId: number) => {
    if (buttonId) {
      setDatesSelected({
        ...datesSelected,
        daysOfWeek: getNewDatesSelectedItem(datesSelected.daysOfWeek, buttonId),
      });
    }
  };

  const handleSelectEvenDays = (buttonId: number) => {
    if (buttonId) {
      setDatesSelected({
        ...datesSelected,
        evenDays: getNewDatesSelectedItem(datesSelected.evenDays, buttonId),
      });
    }
  };

  const handleSelectOddDays = (buttonId: number) => {
    if (buttonId) {
      setDatesSelected({
        ...datesSelected,
        oddDays: getNewDatesSelectedItem(datesSelected.oddDays, buttonId),
      });
    }
  };

  const handleCheckActionPeriod = () => setIsActionPeriodOn(!isActionPeriodOn);
  const handleChangeIntervalOn = () => setIsIntervalOn(!isIntervalsOn);

  const handleChangePeriodLimitsOn = () => setIsPeriodLimitsOn(!isPeriodLimitsOn);

  const handleChangeMaxOperationsValue = (value: string) => {
    const nValue = Number(value);

    if (!isNaN(nValue) && nValue >= 0) {
      setMaxOperations(nValue);
    } else if (!value) {
      setMaxOperations(-1);
    }
  };

  const handleCheckChangeDaysOfMonth = (value: boolean) => {
    if (value) {
      setIsDaysOfWeekLimitOn(false);
      setIsDaysOfMonthLimitOn(true);
    } else {
      setIsDaysOfWeekLimitOn(true);
      setIsDaysOfMonthLimitOn(false);
    }
  };

  const handleCheckEvenDays = () => {
    if (datesSelected.evenDays.every(day => day.status === DatesStatus.selected)) {
      setDatesSelected({
        ...datesSelected,
        evenDays: datesSelected.evenDays.map(day => ({
          ...day,
          status: DatesStatus.no_selected,
        })),
      });
    } else {
      setDatesSelected({
        ...datesSelected,
        evenDays: datesSelected.evenDays.map(day => ({
          ...day,
          status: DatesStatus.selected,
        })),
      });
    }
  };
  const handleCheckOddDays = () => {
    if (datesSelected.oddDays.every(day => day.status === DatesStatus.selected)) {
      setDatesSelected({
        ...datesSelected,
        oddDays: datesSelected.oddDays.map(day => ({
          ...day,
          status: DatesStatus.no_selected,
        })),
      });
    } else {
      setDatesSelected({
        ...datesSelected,
        oddDays: datesSelected.oddDays.map(day => ({
          ...day,
          status: DatesStatus.selected,
        })),
      });
    }
  };

  const handleChangePeriodFrom = (date: Date) => setPeriodFrom(date.toISOString());
  const handleChangePeriodTo = (date: Date) => setPeriodTo(date.toISOString());
  const handleClickAddInterval = () => setIntervals([...intervals, ['', '']]);

  const handleChangeStartTimeInterval = (index: number, dateStr: string) => {
    setIntervals(prevIntervals => {
      return prevIntervals.map((interval, i) => {
        if (index === i) {
          return [dateStr, interval[1]];
        }
        return interval;
      });
    });
  };
  const handleChangeEndTimeInterval = (index: number, dateStr: string) => {
    setIntervals(prevIntervals => {
      return prevIntervals.map((interval, i) => {
        if (index === i) {
          return [interval[0], dateStr];
        }
        return interval;
      });
    });
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (isActionPeriodOn && !periodFrom) {
      newInvalidFields.push('periodFromTime');
    }
    if (isActionPeriodOn && !periodTo) {
      newInvalidFields.push('periodToTime');
    }
    if (isActionPeriodOn && activationCountMax < 0) {
      newInvalidFields.push('maxOperationsTime');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    let timeSettings: TimeSettings = {
      isActionPeriodOn: defaultTimeState.isActionPeriodOn,
      periodFrom: defaultTimeState.periodFrom,
      periodTo: defaultTimeState.periodTo,
      isIntervalsOn: defaultTimeState.isIntervalsOn,
      intervals: defaultTimeState.intervals,
      isPeriodLimitsOn: defaultTimeState.isPeriodLimitsOn,
      months: defaultTimeState.months,
      isDaysOfWeekLimitOn: defaultTimeState.isDaysOfWeekLimitOn,
      daysOfWeek: defaultTimeState.daysOfWeek,
      isDaysOfMonthLimitOn: defaultTimeState.isDaysOfMonthLimitOn,
      oddDays: defaultTimeState.oddDays,
      evenDays: defaultTimeState.evenDays,
      activationCountMax: defaultTimeState.activationCountMax,
    };
    let dates = defaultTimeState.datesSelected;

    if (chosenNotification?.attributes?.timeSettings) {
      timeSettings = chosenNotification.attributes.timeSettings;

      const periodFromDate = new Date(timeSettings.periodFrom);
      const periodToDate = new Date(timeSettings.periodTo);

      dates = {
        months: monthsNames.map((monthsName, i) => {
          const fromMonth = periodFromDate.getMonth();
          const toMonth = periodToDate.getMonth();
          const fromYear = periodFromDate.getFullYear();
          const toYear = periodToDate.getFullYear();

          if (fromYear === toYear && i >= fromMonth && i <= toMonth && months.length) {
            return {
              id: i + 1,
              text: monthsName,
              status: months.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
            };
          }
          if (toYear - fromYear === 1) {
            return {
              id: i + 1,
              text: monthsName,
              status: i >= fromMonth || i <= toMonth ? DatesStatus.selected : DatesStatus.disabled,
            };
          }
          return {
            id: i + 1,
            text: monthsName,
            status: DatesStatus.disabled,
          };
        }),
        daysOfWeek: daysOfWeekNames.map((day, i) => ({
          id: i + 1,
          text: day,
          status: timeSettings.daysOfWeek.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
        })),
        evenDays: evenDaysNames.map((day, i) => ({
          id: i + 1,
          text: String(day),
          status: timeSettings.evenDays.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
        })),
        oddDays: oddDaysNames.map((day, i) => ({
          id: i + 1,
          text: String(day),
          status: timeSettings.oddDays.includes(i) ? DatesStatus.selected : DatesStatus.no_selected,
        })),
      };
    }
    const chosenTimeSettings: TimeSettings = {
      isActionPeriodOn,
      periodFrom,
      periodTo,
      isIntervalsOn,
      intervals,
      isPeriodLimitsOn,
      months,
      isDaysOfWeekLimitOn,
      daysOfWeek,
      isDaysOfMonthLimitOn,
      oddDays,
      evenDays,
      activationCountMax,
    };

    const stateIsChanged = !isEqualObjectsNoDeep(
      timeSettings as unknown as Record<string, unknown>,
      chosenTimeSettings as unknown as Record<string, unknown>
    );
    const datesSelectedIsChanged = !isEqual(dates, datesSelected);

    return Boolean(stateIsChanged || datesSelectedIsChanged);
  };

  return {
    states: {
      isActionPeriodOn,
      periodFrom,
      periodTo,
      isIntervalsOn,
      isPeriodLimitsOn,
      intervals,
      months,
      daysOfWeek,
      isDaysOfWeekLimitOn,
      isDaysOfMonthLimitOn,
      evenDays,
      oddDays,
      activationCountMax,
      datesSelected,
    },
    handlers: {
      handleCheckActionPeriod,
      handleChangeIntervalOn,
      handleClickAddInterval,
      handleChangePeriodLimitsOn,
      handleCheckChangeDaysOfMonth,
      handleCheckEvenDays,
      handleCheckOddDays,
      handleChangePeriodFrom,
      handleChangePeriodTo,
      handleChangeStartTimeInterval,
      handleChangeEndTimeInterval,
      handleChangeMaxOperationsValue,
      handleSelectMonths,
      handleSelectDaysOfWeek,
      handleSelectEvenDays,
      handleSelectOddDays,
    },
    refs: {
      startPeriodRef,
      endPeriodRef,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
