import arrayMove from 'array-move';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import AccessEntity from 'utils/accessEntity';
import { TAB_PERMISSIONS } from 'utils/consts';
import { isEqualArraysOfObjects, isEqualArraysOfObjectsOnlyWithIsEqual } from 'utils/isEqualArraysOfObjects';

export function useTabsShowing() {
  const { permissions } = useSelector((state: RootState) => state.user);
  const { tabs } = useSelector((state: RootState) => state.user.userPreferences);

  const tabPreferences = useMemo(
    () =>
      tabs.filter(ut => {
        const totalAccess: boolean[] = [];
        const tab = TAB_PERMISSIONS[ut.keyName];

        for (let i = 0; i < tab.permissionsNames.length; i++) {
          const access = new AccessEntity(permissions[tab.permissionsNames[i]]);
          totalAccess.push(access.isAllowRead());
        }

        return totalAccess.every(a => a);
      }),
    [tabs, permissions]
  );

  const [localTabPrefs, setLocalTabPrefs] = useState(tabPreferences.map(t => ({ ...t })));

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setLocalTabPrefs([...arrayMove(localTabPrefs, oldIndex, newIndex)]);
  };

  const toggleVisible = (ind: number) => {
    setLocalTabPrefs(
      localTabPrefs.map((t, i) => {
        if (i === ind) {
          return {
            ...t,
            isVisible: !t.isVisible,
          };
        }
        return t;
      })
    );
  };

  const findDifference = () => {
    if (isEqualArraysOfObjects(localTabPrefs, tabPreferences)) {
      return null;
    }
    return localTabPrefs;
  };

  const findDifferenceUseEqual = () => {
    if (isEqualArraysOfObjectsOnlyWithIsEqual(localTabPrefs, tabPreferences)) {
      return null;
    }
    return localTabPrefs;
  };

  return {
    localTabPrefs,
    onSortEnd,
    toggleVisible,
    findDifference,
    findDifferenceUseEqual,
  };
}
