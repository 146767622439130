import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import DropButton from 'components/common/dropbutton/dropbutton';
import {
  HandbooksCardElementDataHandlers,
  HandbooksCardElementDataStates,
  MessagesType,
} from 'components/handbooks/utils/types';

import { DEFAULT_LANGUAGES, LanguagesKeysType } from 'translate';

import styles from './defaultHandbookCard.module.scss';

const cx = classNames.bind(styles);

const messages: MessagesType = {
  defaultsFields: {
    label: 'handbooks.column.elements-card.default.label',
    placeholder: 'handbooks.column.elements-card.default.placeholder',
  },
  name: {
    label: 'handbooks.column.elements-card.name.label',
    placeholder: 'handbooks.column.elements-card.name.placeholder',
  },
  organizationForm: {
    label: 'handbooks.column.elements-card.organization-form.label',
    placeholder: 'handbooks.column.elements-card.organization-form.placeholder',
  },
  inn: {
    label: 'handbooks.column.elements-card.inn.label',
    placeholder: 'handbooks.column.elements-card.inn.placeholder',
  },
  // TODO: удалить poiGasAnalyzerModelId после правок бекенда, т.к. это данные связанного справочника
  poiGasAnalyzerModelId: {
    label: 'handbooks.column.elements-card.gaz-analyzer-model.label',
    placeholder: 'handbooks.column.elements-card.gaz-analyzer-model.placeholder',
  },
};

type PropsType = {
  states: HandbooksCardElementDataStates;
  handlers: HandbooksCardElementDataHandlers;
  invalidFields: string[];
};

const HandbookElementData = ({ states, handlers, invalidFields }: PropsType) => {
  const t = useFormatMessage();

  return (
    <Fragment>
      {Object.keys(states.name)
        .sort(a => (a === DEFAULT_LANGUAGES ? -1 : 1))
        .map((nameKey, index) => (
          <Input
            key={`translateKey-input-${index}`}
            label={`${t(messages.name.label)} ${t(`handbooks.column.elements-card.name.${nameKey}.text`)}`}
            placeholder={t(messages.name.placeholder)}
            isValueError={invalidFields.includes('elementData_name')}
            isRequired={true}
            value={states.name[nameKey as LanguagesKeysType] ?? ''}
            customStyle={styles.row}
            handleInputChange={value => handlers.handleChangeName(nameKey as LanguagesKeysType, value)}
          />
        ))}
      <DropButton
        label={t('handbooks.add-translation.btn.label')}
        buttons={states.dropButtons}
        onClick={handlers.handleChooseTranslate}
        disabled={!states.dropButtons.length}
        customStyle={cx(styles.row, styles.rowDropButton)}
        noAdditionalSymbol
        optionsIsSmall
      />
      {Object.keys(states.attributesAdditionalFields).map((additionalFieldKey, i) => (
        <Input
          key={`additionalElementField-${i}`}
          label={t(messages[additionalFieldKey]?.label ?? messages.defaultsFields.label)}
          placeholder={t(messages[additionalFieldKey]?.placeholder ?? messages.defaultsFields.placeholder)}
          isValueError={invalidFields.includes(`elementData_${additionalFieldKey}`)}
          isRequired={true}
          value={String(states.attributesAdditionalFields[additionalFieldKey] ?? '')}
          customStyle={styles.row}
          handleInputChange={value => handlers.handleChangeAdditionalFields({ key: additionalFieldKey, value })}
        />
      ))}
    </Fragment>
  );
};

export default HandbookElementData;
