import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { setChosenRole } from 'reducers/roles';

import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';
import { getUserFullName } from 'utils/users/viewData';

import {
  createAccount,
  deleteAccount,
  deleteUserRole,
  getUsersList,
  putAccount,
  postUserRole,
  getUserListShortAll,
  putCurrentAccount,
  setAccountBann,
} from './api';
import {
  AccountAttributes,
  AccountsJsonApi,
  AccountState,
  CreateAccount,
  CreateUserPreferences,
  UserRoleReqParams,
  GetUsersListRequestData,
  RoleToUsersListItem,
  UserHasRoleMap,
} from './interface';

const initialState: AccountState = {
  accounts: [],
  error: '',
  chosenAccount: null,
  isLoading: false,
  updateCounter: 0,
  isShortsLoading: false,
  accountsShortList: [],
  chosenGroup: [],
  groups: [],
  accountsIncluded: [],
  rolesIncluded: {},
  roleToUsersAvailableItems: [],
  roleToUsersChosenItems: [],
  userHasRoleMap: {},
  setRoleToUsersIsLoading: false,
};

async function setRoles(accountId: string, roles: { delRoles: number[]; addRoles: number[] }) {
  const promises: ParallelItemInterface[] = [];

  roles.delRoles.forEach(delRole => {
    const args: UserRoleReqParams = {
      userId: accountId,
      roleId: delRole,
    };

    promises.push({ promiseMethod: deleteUserRole, args });
  });

  roles.addRoles.forEach(addRole => {
    const args: UserRoleReqParams = {
      userId: accountId,
      roleId: addRole,
    };

    promises.push({ promiseMethod: postUserRole, args });
  });

  await reqHandlers.allSettled(promises);
}

export const initializeRoleToUsersData = createAsyncThunk(
  'accountSlice/initializeRoleToUsersData',
  async (roleId: string | null, thunkAPI) => {
    const availableItems: RoleToUsersListItem[] = [];
    const chosenItems: RoleToUsersListItem[] = [];
    const userHasRoleMap: UserHasRoleMap = {};

    const state = thunkAPI.getState() as RootState;
    const { accounts } = state.account;

    if (roleId) {
      for (const account of accounts) {
        const userHasRole = account.relationships.roles.data.some(role => role.id === roleId);

        userHasRoleMap[account.id] = userHasRole;

        const item = {
          id: account.id,
          name: getUserFullName(account),
        };

        if (userHasRole) {
          chosenItems.push(item);
        } else {
          availableItems.push(item);
        }
      }

      thunkAPI.dispatch(setChosenRole(roleId));
    } else {
      for (const account of accounts) {
        availableItems.push({
          id: account.id,
          name: getUserFullName(account),
        });
      }
    }

    thunkAPI.dispatch(setRoleToUsersAvailableItems(availableItems));
    thunkAPI.dispatch(setRoleToUsersChosenItems(chosenItems));
    thunkAPI.dispatch(setUserHasRoleMap(userHasRoleMap));
  }
);

export const setRoleToUsers = createAsyncThunk('accountSlice/setRoleToUsers', async (roleId: string, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const { roleToUsersAvailableItems, roleToUsersChosenItems, userHasRoleMap } = state.account;

  const roleIdAsNumber = parseInt(roleId);

  if (Number.isNaN(roleId)) {
    return thunkAPI.rejectWithValue('roleId is incorrect');
  }

  const promises: ParallelItemInterface[] = [];

  for (const item of roleToUsersAvailableItems) {
    if (userHasRoleMap[item.id]) {
      promises.push({
        promiseMethod: deleteUserRole,
        args: {
          userId: item.id,
          roleId: roleIdAsNumber,
        },
      });
    }
  }

  for (const item of roleToUsersChosenItems) {
    if (!userHasRoleMap[item.id]) {
      promises.push({
        promiseMethod: postUserRole,
        args: {
          userId: item.id,
          roleId: roleIdAsNumber,
        },
      });
    }
  }

  return await reqHandlers.allSettled(promises);
});

export const fetchAccounts = createAsyncThunk(
  'accountSlice/fetchAccounts',
  async (data?: GetUsersListRequestData) => await getUsersList(data)
);

export const addAccount = createAsyncThunk(
  'accountSlice/addAccount',
  async (data: { account: CreateAccount; delRoles: number[]; addRoles: number[] }) => {
    const { account: newAccount } = data;
    // eslint-disable-next-line
    const { id, createdAt, updatedAt, deletedAt, userId, ...newUserPreferences } = newAccount.userPreferences; // exclude props: id, createdAt, updatedAt, deletedAt, userId
    const { tabs } = newUserPreferences;

    newAccount.userPreferences = {
      ...newUserPreferences,
      tabs: tabs.map(tab => ({
        isVisible: tab.isVisible,
        keyName: tab.keyName,
      })),
    };

    const acc = await createAccount(newAccount);

    return await setRoles(acc.data.id, { delRoles: data.delRoles, addRoles: data.addRoles });
  }
);

export const updateAccount = createAsyncThunk(
  'accountSlice/updateAccount',
  async (data: AccountAttributes & { id: string; delRoles: number[]; addRoles: number[] }) => {
    await setRoles(data.id, { delRoles: data.delRoles, addRoles: data.addRoles });
    return await putAccount(data);
  }
);

export const updateCurrentAccount = createAsyncThunk(
  'accountSlice/updateAccount',
  async (data: AccountAttributes & { id: string; userPreferences: CreateUserPreferences }) =>
    await putCurrentAccount(data)
);

export const updateAccountBannStatus = createAsyncThunk(
  'accountSlice/updateAccountBannStatus',
  async (data: { userId: string; status: boolean }) => {
    return await setAccountBann(data);
  }
);

export const removeAccount = createAsyncThunk(
  'accountSlice/removeAccount',
  async (id: string) => await deleteAccount(id)
);

export const fetchAccountsShort = createAsyncThunk(
  'accountSlice/fetchAccountsShort',
  async () => await getUserListShortAll()
);

const accountSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setChosenAccount: (state, { payload }: { payload: string | null }) => {
      state.chosenAccount = payload;
    },
    removeChosenAccount: state => {
      state.chosenAccount = null;
    },
    increaseUpdateCounter: state => {
      state.updateCounter += 1;
    },
    setRoleToUsersAvailableItems: (state, action: PayloadAction<RoleToUsersListItem[]>) => {
      state.roleToUsersAvailableItems = action.payload;
    },
    setRoleToUsersChosenItems: (state, action: PayloadAction<RoleToUsersListItem[]>) => {
      state.roleToUsersChosenItems = action.payload;
    },
    setUserHasRoleMap: (state, action: PayloadAction<UserHasRoleMap>) => {
      state.userHasRoleMap = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAccounts.pending, state => {
        state.error = '';
        state.isLoading = true;
      })
      .addCase(fetchAccounts.fulfilled, (state, { payload }: { payload: AccountsJsonApi }) => {
        state.isLoading = false;
        state.accounts = payload.data;
        state.accountsIncluded = payload.included ?? [];

        const rolesIncluded: { [id: string]: string } = {};

        if (payload.included) {
          for (const inlcudedItem of payload.included) {
            if (inlcudedItem.type === 'roles') {
              rolesIncluded[inlcudedItem.id] = inlcudedItem.attributes.name;
            }
          }
        }

        state.rolesIncluded = rolesIncluded;
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(addAccount.pending, state => {
        state.error = '';
      })
      .addCase(addAccount.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(addAccount.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateAccount.pending, state => {
        state.error = '';
      })
      .addCase(updateAccount.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateAccountBannStatus.pending, state => {
        state.error = '';
      })
      .addCase(updateAccountBannStatus.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(updateAccountBannStatus.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeAccount.pending, state => {
        state.error = '';
      })
      .addCase(removeAccount.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(removeAccount.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchAccountsShort.pending, state => {
        state.error = '';
        state.isShortsLoading = true;
      })
      .addCase(fetchAccountsShort.fulfilled, (state, { payload }: { payload: AccountsJsonApi }) => {
        state.isShortsLoading = false;
        state.accountsShortList = payload.data;
      })
      .addCase(fetchAccountsShort.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isShortsLoading = false;
      });
    builder
      .addCase(setRoleToUsers.pending, state => {
        state.setRoleToUsersIsLoading = true;
      })
      .addCase(setRoleToUsers.fulfilled, state => {
        state.updateCounter += 1;
        state.roleToUsersAvailableItems = [];
        state.roleToUsersChosenItems = [];
        state.setRoleToUsersIsLoading = false;
      })
      .addCase(setRoleToUsers.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.setRoleToUsersIsLoading = false;
      });
  },
});

export const {
  setChosenAccount,
  removeChosenAccount,
  increaseUpdateCounter,
  setRoleToUsersAvailableItems,
  setRoleToUsersChosenItems,
  setUserHasRoleMap,
} = accountSlice.actions;

export default accountSlice.reducer;
