import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EditIcon } from 'assets/img/gear.svg';
import { ReactComponent as Marker } from 'assets/img/poi/marker.svg';

import { RootState } from 'reducers';
import {
  fetchOnePoi,
  setSelectedPoi,
  setPoiCardType,
  setTurnedPoi,
  setPoiCardData,
  setShowingPoiId,
} from 'reducers/poi';
import { Poi } from 'reducers/poi/interface';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Switch from 'components/common/switch/switch';
import { getPoiCardData } from 'components/poi/utils/helpers';

import AccessEntity from 'utils/accessEntity';

import styles from './poiItem.module.scss';

const cx = classNames.bind(styles);

type Props = {
  poi: Poi;
};

export const PoiItem = ({ poi }: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const poiPermissions = useSelector((state: RootState) => state.user.permissions.poi);
  const poiAccess = useMemo(() => new AccessEntity(poiPermissions), [poiPermissions]);
  const { selectedPoi } = useSelector((state: RootState) => state.poi);

  const handleCheck = () => dispatch(setSelectedPoi({ ids: [poi.id], status: !selectedPoi.includes(poi.id) }));

  const handleTurn = () => dispatch(setTurnedPoi({ ids: [poi.id], status: !poi.attributes.isOn }));

  const handleChosePoi = async () => {
    if (poiAccess.isAllowUpdate()) {
      const poiCardType = poi.attributes.poiType;
      const poiCardData = getPoiCardData(poiCardType);

      dispatch(setPoiCardType(poiCardType));
      dispatch(setPoiCardData(poiCardData));
      await dispatch(fetchOnePoi(poi.id));
    }
  };

  const handleShowPoi = () => {
    dispatch(setShowingPoiId(poi.id));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.infoCheckbox}>
          <Checkbox checked={selectedPoi.includes(poi.id)} handleCheck={handleCheck} />
        </div>
        <div className={styles.infoName} title={poi.attributes.name}>
          {poi.attributes.name}
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonsIcon}>
          <Switch checked={poi.attributes.isOn} onChange={handleTurn} disabled={!poiAccess.isAllowUpdate()} />
        </div>
        <div className={styles.buttonsIcon} onClick={handleShowPoi}>
          <Marker data-tip data-for={`poiOnMap-${poi.attributes.name}`} />
          <ReactTooltip
            className={styles.customTooltip}
            id={`poiOnMap-${poi.attributes.name}`}
            place="right"
            type="light"
            effect="solid"
          >
            <span>{t('poi.list.header.tooltip.view.text')}</span>
          </ReactTooltip>
        </div>
        <div
          className={cx(styles.buttonsIcon, {
            [styles.buttonsIconDisabled]: !poiAccess.isAllowUpdate(),
          })}
          onClick={handleChosePoi}
        >
          <EditIcon data-tip data-for="editPoi" />
          {poiAccess.isAllowUpdate() && (
            <ReactTooltip className={styles.customTooltip} id="editPoi" place="right" type="light" effect="solid">
              <span>{t('poi.list.header.tooltip.change.text')}</span>
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
