import type { MouseEvent } from 'react';

/**
 * Handles list selection state changes with (or without) ctrl and shift keys
 */
export function getNextSelectionState<TItem extends { id: string }>(
  items: TItem[],
  prevState: Set<string>,
  e: MouseEvent<HTMLLIElement>,
  id: string,
  index: number
) {
  let resultState = new Set(prevState);

  if (e.ctrlKey || e.metaKey) {
    if (prevState.has(id)) {
      resultState.delete(id);
    } else {
      resultState.add(id);
    }
  } else if (e.shiftKey && prevState.size) {
    let lastSelectedIndex: number | null = null;

    for (let i = 0; i < index; i++) {
      if (prevState.has(items[i].id)) {
        lastSelectedIndex = i;
      }
    }

    if (lastSelectedIndex === null) {
      for (let i = index; i < items.length; i++) {
        if (prevState.has(items[i].id)) {
          break;
        }

        resultState.add(items[i].id);
      }
    } else {
      for (let i = lastSelectedIndex + 1; i <= index; i++) {
        resultState.add(items[i].id);
      }
    }
  } else {
    resultState = new Set([id]);
  }

  return resultState;
}
