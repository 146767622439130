import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Map, MapBrowserEvent } from 'ol';
import Feature, { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';

import { fetchMarkerLog, setTestMarkerNotificationLog } from 'reducers/notifications';
import { TEST_NOTIFICATION_ID } from 'utils/consts';

export function useNotificationsHandlers(map: Map) {
  const dispatch = useDispatch();

  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 }, mapCoords: { x: 0, y: 0 } });

  const clickHandler = useCallback(
    (evt: MapBrowserEvent<PointerEvent>) => {
      const { map, pixel } = evt;
      const mouseX = evt.originalEvent.clientX;
      const mouseY = evt.originalEvent.clientY;

      setShowNotificationPopup(false);

      map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
        const features = feature.get('features') as Feature<Point>[];

        if (features) {
          if (features.length === 1) {
            // клик по точке (маркеру), а не кластеру
            const values = features[0].getProperties();

            if (values.type === 'notificationMarker') {
              setMarkerCoords({
                mouseCoords: { x: mouseX, y: mouseY },
                mapCoords: { x: values.mapCoords[0], y: values.mapCoords[1] },
              });
              setShowNotificationPopup(true);
              if (values.info.logId !== TEST_NOTIFICATION_ID) {
                dispatch(fetchMarkerLog(values.info.logId));
              } else {
                dispatch(setTestMarkerNotificationLog());
              }
            }
          }
        }

        return feature;
      });
    },
    [dispatch]
  );

  useEffect(() => {
    map.on('click', clickHandler);

    return () => {
      map.un('click', clickHandler);
    };
  }, [map, clickHandler]);

  return {
    notificationPopup: {
      onCancel: () => setShowNotificationPopup(false),
      markerCoords,
    },
    showNotificationPopup,
  };
}
