import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Button from 'components/common/button/button';
import { closeModal } from 'reducers/modal';
import { setShowWithGroupsStatus } from 'reducers/geozones';
import { RootState } from 'reducers';

import { saveToStorage } from 'utils/workWithStorages';

import styles from './geozoneListSortingModal.module.scss';

export function GeozoneListSortingModal() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { showWithGroups } = useSelector((state: RootState) => state.geozone);
  const handleCheckSelect = () => {
    dispatch(setShowWithGroupsStatus(!showWithGroups));
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    saveToStorage('showWithGroups', showWithGroups);
    dispatch(closeModal());
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{t('geozones.geozone-list.header.tooltip.sorting.text')}</div>
        <div className={styles.headerCloseIcon}>
          <CloseIcon onClick={handleCancel} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.configItemWrapper}>
          <div className={styles.configItem}>
            <Checkbox checked={showWithGroups} handleCheck={handleCheckSelect} />
            <div className={styles.configItemName}>
              {t('geozones.geozone-sorting-modal.element-showWithGroups.label')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button text={t('monitoring.list-group-modal.footer.btn.cancel.label')} white onClick={handleCancel} />
        <Button text={t('monitoring.list-group-modal.footer.btn.apply.label')} blue onClick={handleSave} />
      </div>
    </div>
  );
}
