import React, { FC, useState, ReactNode, MouseEvent, useEffect, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';

import Input from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import Button from 'components/common/button/button';
import Search from 'components/common/search/search';
import { Alert } from 'components/common/alert/alert';

import { RootState } from 'reducers';
import {
  addGeozoneGroup,
  removeChosenGroup,
  updateGeozoneGroup,
  removeGeozoneGroup,
  selectAllSaved,
  clearSelected,
} from 'reducers/geozones';
import { closeModal } from 'reducers/modal';
import { AddGroupData, Geozone, GeozoneGroup, UpdateGroupData } from 'reducers/geozones/interface';

import styles from './groupCreation.module.scss';

const cx = classNames.bind(styles);

type SortableItemProps = {
  value: string;
  handleClick: (ev: MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
};

type SortableListProps = {
  children: ReactNode;
  style: string;
};

const SortableItem = SortableElement(({ value, handleClick, selected }: SortableItemProps) => (
  <li
    className={cx(styles.groupsListItem, {
      [styles.groupsListItemSelected]: selected,
    })}
    onClick={handleClick}
  >
    {value}
  </li>
));

const SortableList = SortableContainer(({ style, children }: SortableListProps) => {
  return <div className={style}>{children}</div>;
});

const GroupCreation: FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const groups = useSelector((state: RootState) => state.geozone.groups);
  const chosenGroupId = useSelector((state: RootState) => state.geozone.chosenGroup);

  const chosenGroup = groups.find(g => g.id === chosenGroupId);

  const [name, setName] = useState(chosenGroup ? chosenGroup.attributes.name : '');
  const [description, setDescription] = useState(chosenGroup ? chosenGroup.attributes.description : '');

  const [searchValue, setSearchValue] = useState('');
  const [selectedAvailable, setSelectedAvailable] = useState<string[]>([]);
  const [selectedChosen, setSelectedChosen] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const userId = useSelector((state: RootState) => state.user.id);

  const geozonesState = useSelector((state: RootState) => state.geozone.geozones);

  const geozones = useMemo(() => {
    const available = geozonesState
      .filter(g => !g.relationships.parentGroup.data)
      .map(g => ({ customId: `geozone-available-${g.id}`, data: g, isChosen: false }));

    const chosen = geozonesState
      .filter(g => g.relationships.parentGroup.data?.id === chosenGroupId && chosenGroup)
      .map(g => ({ customId: `geozone-chosen-${g.id}`, data: g, isChosen: true }));

    return [...available, ...chosen];
  }, [geozonesState, chosenGroupId, chosenGroup]);

  const geozonesGroupsState = useSelector((state: RootState) => state.geozone.groups);

  const geozonesGroups = useMemo(() => {
    const available = geozonesGroupsState
      .filter(
        g =>
          !g.relationships.parentGroup.data &&
          g.id !== chosenGroupId &&
          (!chosenGroupId ||
            !g.relationships.childrenGroups.data?.map(childrenGroup => childrenGroup.id).includes(chosenGroupId))
      )
      .map(g => ({
        customId: `group-available-${g.id}`,
        data: g,
        isChosen: false,
      }));

    const chosenGroups =
      geozonesGroupsState
        .find(g => g.id === chosenGroupId)
        ?.relationships.childrenGroups.data?.reduce(
          (acc, curr) => {
            const foundGeozoneGroup = geozonesGroupsState.find(group => group.id === curr.id);

            if (foundGeozoneGroup) {
              return [
                ...acc,
                {
                  customId: `group-chosen-${foundGeozoneGroup.id}`,
                  data: foundGeozoneGroup,
                  isChosen: true,
                },
              ];
            }
            return acc;
          },
          [] as {
            customId: string;
            data: GeozoneGroup;
            isChosen: boolean;
          }[]
        ) || [];

    return [...available, ...chosenGroups];
  }, [geozonesGroupsState, chosenGroupId]);

  const geozonesAndGroupsEntities = [...geozones, ...geozonesGroups];

  const [objects, setObjects] = useState(
    geozonesAndGroupsEntities.map(g => ({ ...g, data: { ...g.data, attributes: { ...g.data.attributes } } }))
  );
  const [showObjects, setShowObjects] = useState<typeof objects>([]);

  useEffect(() => {
    if (searchValue) {
      setShowObjects(objects.filter(g => g.data.attributes.name.includes(searchValue)));
    } else {
      setShowObjects(objects);
    }
  }, [searchValue, objects]);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!name) {
      newInvalidFields.push('name');
    }

    return newInvalidFields;
  };

  const handleClose = () => {
    dispatch(selectAllSaved());
    dispatch(clearSelected());
    dispatch(removeChosenGroup());

    return dispatch(closeModal());
  };

  const handleSave = () => {
    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      return setInvalidFields(validationResult);
    }

    if (chosenGroup) {
      const body: UpdateGroupData = {
        group: {
          name,
          description,
          userId: parseInt(userId),
          parentGroupId: Number(chosenGroup.relationships.parentGroup.data?.id) || null,
          id: Number(chosenGroup.id),
        },
        add: {
          groups: objects
            .filter(obj => obj.isChosen && obj.data.type === 'geozonesGroups')
            .map(obj => obj.data as GeozoneGroup),
          geozones: objects.filter(obj => obj.isChosen && obj.data.type === 'geozones').map(obj => obj.data as Geozone),
        },
        remove: {
          groups: objects
            .filter(
              obj =>
                !obj.isChosen &&
                obj.data.type === 'geozonesGroups' &&
                !!chosenGroup.relationships.childrenGroups.data.find(childrenGroup => childrenGroup.id === obj.data.id)
            )
            .map(obj => obj.data as GeozoneGroup),
          geozones: objects
            .filter(
              obj =>
                !obj.isChosen &&
                obj.data.type === 'geozones' &&
                !!chosenGroup.relationships.geozones.data.find(geozone => geozone.id === obj.data.id)
            )
            .map(obj => obj.data as Geozone),
        },
      };

      dispatch(updateGeozoneGroup(body));
    } else {
      const body: AddGroupData = {
        group: {
          name,
          description,
          userId: parseInt(userId),
        },
        groups: objects
          .filter(obj => obj.isChosen && obj.data.type === 'geozonesGroups')
          .map(obj => obj.data as GeozoneGroup),
        geozones: objects.filter(obj => obj.isChosen && obj.data.type === 'geozones').map(obj => obj.data as Geozone),
      };

      dispatch(addGeozoneGroup(body));
    }
    handleClose();
  };

  const onSortEnd = ({ collection }: { collection: number | string }) => {
    const newObjects = objects.map(obj => {
      if (obj.customId === collection) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
          data: {
            ...obj.data,
            attributes: {
              ...obj.data.attributes,
              parentGroupId: chosenGroup && !obj.isChosen ? chosenGroup.id : null,
            },
          },
        };
      }
      return obj;
    });
    setObjects(newObjects);
  };

  const handleMoveRight = () => {
    const newObjects = objects.map(obj => {
      if (selectedAvailable.includes(obj.customId)) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
          data: {
            ...obj.data,
            attributes: {
              ...obj.data.attributes,
              parentGroupId: chosenGroup ? chosenGroup.id : null,
            },
          },
        };
      }
      return obj;
    });
    setObjects(newObjects);
    setSelectedAvailable([]);
  };

  const handleMoveLeft = () => {
    const newObjects = objects.map(obj => {
      if (selectedChosen.includes(obj.customId)) {
        return {
          ...obj,
          isChosen: !obj.isChosen,
          data: {
            ...obj.data,
            attributes: {
              ...obj.data.attributes,
              parentGroupId: null,
            },
          },
        };
      }
      return obj;
    });
    setObjects(newObjects);
    setSelectedChosen([]);
  };

  const handleAvailableItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedAvailable.includes(id)) {
      return setSelectedAvailable(selectedAvailable.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedAvailable([...selectedAvailable, id]);
    } else if (ev.shiftKey && selectedAvailable.length) {
      const startInd = showObjects.findIndex(o => o.customId === selectedAvailable[0]);
      const newSelected = showObjects.slice(startInd, index + 1).map(o => o.customId);
      setSelectedAvailable(newSelected);
    } else {
      setSelectedAvailable([id]);
    }
    return setSelectedChosen([]);
  };

  const handleChosenItemClick = (ev: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    if (selectedChosen.includes(id)) {
      return setSelectedChosen(selectedChosen.filter(s => s !== id));
    }
    if (ev.ctrlKey || ev.metaKey) {
      setSelectedChosen([...selectedChosen, id]);
    } else if (ev.shiftKey && selectedChosen.length) {
      const startInd = showObjects.findIndex(o => o.customId === selectedChosen[0]);
      const newSelected = showObjects.slice(startInd, index + 1).map(o => o.customId);
      setSelectedChosen(newSelected);
    } else {
      setSelectedChosen([id]);
    }
    return setSelectedAvailable([]);
  };

  const selectAvailableAll = () => {
    const showObjectsIds = showObjects.filter(obj => !obj.isChosen).map(obj => obj.customId);
    setSelectedAvailable(showObjectsIds);
    setSelectedChosen([]);
  };

  const selectChosenAll = () => {
    const showObjectsIds = showObjects.filter(obj => obj.isChosen).map(obj => obj.customId);
    setSelectedChosen(showObjectsIds);
    setSelectedAvailable([]);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleContinueAlert = () => {
    if (chosenGroup) {
      dispatch(removeGeozoneGroup(chosenGroup.id));
      handleClose();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          {!chosenGroup
            ? t('geozones.geozone-group-card.header.label')
            : t('geozones.geozone-group-card.header.editLabel')}
        </div>
        <CloseIcon className={styles.headerCloseIcon} onClick={handleClose} />
      </div>

      <div className={styles.inputsGroup}>
        <div className={styles.inputAndSearchWrap}>
          <Input
            label={t('geozones.geozone-group-card.field.name.label')}
            placeholder={t('geozones.geozone-group-card.field.name.placeholder')}
            value={name}
            handleInputChange={setName}
            isValueError={invalidFields.includes('name')}
            isRequired={true}
            customStyle={styles.inputWrap}
          />
          <Search handleChange={val => setSearchValue(val)} style={styles.inputAndSearchWrapSearch} />
        </div>
        <TextArea
          label={t('geozones.geozone-group-card.field.description.label')}
          placeholder={t('geozones.geozone-group-card.field.description.placeholder')}
          value={description ?? ''}
          handleChange={setDescription}
          areaStyle={styles.inputsGroupArea}
          containerStyle={styles.inputsGroupAreaContainer}
        />
      </div>

      <div className={styles.modalBody}>
        <SortableList distance={20} onSortEnd={onSortEnd} lockAxis="x" style={styles.groupsList}>
          <div className={styles.groupsListDisabled}>
            <div className={styles.groupsListHeader}>
              {t('geozones.geozone-group-card.available-group.header.label')}
            </div>
            <ul className={styles.groupsListItemsWrap}>
              {showObjects.map((obj, index) => {
                if (!obj.isChosen) {
                  return (
                    <SortableItem
                      key={`available-item_${index}`}
                      index={index}
                      value={obj.data.attributes.name}
                      collection={obj.customId}
                      handleClick={(ev: MouseEvent<HTMLLIElement>) => handleAvailableItemClick(ev, obj.customId, index)}
                      selected={selectedAvailable.includes(obj.customId)}
                    />
                  );
                }
                return null;
              })}
            </ul>
            <Button
              white
              text={t('geozones.geozone-group-card.available-group.btn.select-all.label')}
              customStyle={styles.selectAllButton}
              onClick={selectAvailableAll}
            />
          </div>

          <div className={styles.moveButtons}>
            <div className={styles.moveButtonsBtn} onClick={handleMoveRight}>
              <ArrowIcon className={styles.moveButtonsBtnToRight} />
            </div>
            <div className={styles.moveButtonsBtn} onClick={handleMoveLeft}>
              <ArrowIcon className={styles.moveButtonsBtnToLeft} />
            </div>
          </div>

          <div className={styles.groupsListActive}>
            <div className={styles.groupsListHeader}>{t('geozones.geozone-group-card.chosen-group.header.label')}</div>
            <ul className={styles.groupsListItemsWrap}>
              {showObjects.map((obj, index) => {
                if (obj.isChosen) {
                  return (
                    <SortableItem
                      key={`chosen-item_${index}`}
                      index={index}
                      value={obj.data.attributes.name}
                      collection={obj.customId}
                      handleClick={(ev: MouseEvent<HTMLLIElement>) => handleChosenItemClick(ev, obj.customId, index)}
                      selected={selectedChosen.includes(obj.customId)}
                    />
                  );
                }
                return null;
              })}
            </ul>
            <Button
              white
              text={t('geozones.geozone-group-card.chosen-group.btn.select-all.label')}
              customStyle={styles.selectAllButton}
              onClick={selectChosenAll}
            />
          </div>
        </SortableList>
      </div>

      <div className={styles.modalFooter}>
        <div className={styles.modalFooterButtonsWrap}>
          {chosenGroup && (
            <Button
              red
              text={t('geozones.geozone-group-card.footer.btn.delete.label')}
              onClick={() => setShowAlert(true)}
            />
          )}
          <Button white text={t('geozones.geozone-group-card.footer.btn.cancel.label')} onClick={handleClose} />
          <Button blue text={t('geozones.geozone-group-card.footer.btn.save.label')} onClick={handleSave} />
        </div>
      </div>

      {showAlert && (
        <Alert
          title={t('geozones.geozone-group-card.alert.header.label')}
          infoText={t('geozones.geozone-group-card.alert.info.text')}
          handleCancel={handleCloseAlert}
          handleContinue={handleContinueAlert}
        />
      )}
    </div>
  );
};

export default GroupCreation;
