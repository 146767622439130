import React from 'react';
import { ReactComponent as DropArrow } from 'assets/img/button/drop-arrow.svg';
import { ReactComponent as Copy } from 'assets/img/notifications/copy.svg';
import styles from './button.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface ButtonProps {
  onClick?: (e: React.SyntheticEvent) => void;
  text: string;
  white?: boolean;
  red?: boolean;
  blue?: boolean;
  gray?: boolean;
  long?: boolean;
  longest?: boolean;
  withSymbol?: boolean;
  withDrop?: boolean;
  withCopy?: boolean;
  disabled?: boolean;
  customStyle?: string;
  'data-cy'?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      data-cy={props['data-cy']}
      onClick={props.onClick}
      disabled={props.disabled}
      className={cx(styles.main, props.customStyle, {
        white: props.white,
        red: props.red,
        blue: props.blue,
        gray: props.gray,
        long: props.long,
        longest: props.longest,
        disabled: props.disabled,
      })}
    >
      {props.withCopy && <Copy className={styles.copyIcon} />}
      <span className={cx({ 'span--plus': props.withSymbol, 'span--replacement': props.withDrop })}>{props.text}</span>
      {props.withDrop && <DropArrow className={styles.dropIcon} />}
    </button>
  );
};

export default Button;
