import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCloseCircle } from 'react-icons/ai';
import Button from 'components/common/button/button';
import { closeAlert } from 'reducers/modal';
import { RootState } from 'reducers';
import { fetchDeleteTracksGroupById, setChosenTrack } from 'reducers/tracks';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import classNames from 'classnames/bind';
import styles from '../../tracks.module.scss';
import AccessEntity from 'utils/accessEntity';

const cx = classNames.bind(styles);

const DeleteTrackModalForm = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();
  const tracksPermission = useSelector((state: RootState) => state.user.permissions.tracks);
  const tracksAccess = useMemo(() => {
    return new AccessEntity(tracksPermission);
  }, [tracksPermission]);

  const onCancel = () => {
    dispatch(closeAlert());
  };
  const { chosenTrackForDelete } = useSelector((state: RootState) => state.track);

  const onDelete = () => {
    if (chosenTrackForDelete && tracksAccess.isAllowDelete()) {
      dispatch(fetchDeleteTracksGroupById(chosenTrackForDelete));
    }
    dispatch(setChosenTrack(0));
    dispatch(closeAlert());
  };

  return (
    <>
      <div className={styles.modalHeader}>
        <div>{t('track.alert.header.label')}</div>
        <AiFillCloseCircle color="#999999" className={styles.closeModalIcon} onClick={onCancel} />
      </div>
      <div className={styles.modalBodyDelete}>
        <p className={cx(styles.modalBodyDeleteText, styles.modalBodyDeleteLabel)}>
          <Warning /> {t('track.alert.warning.text')}
        </p>
        <p className={styles.modalBodyDeleteText}>{t('track.alert.info.text')}</p>
        <p className={styles.modalBodyDeleteText}>{t('track.alert.delete.text')}</p>
      </div>
      <div className={styles.modalControls}>
        <Button white text={t('track.alert.btn.cancel.text')} onClick={onCancel} />
        <Button red text={t('track.alert.btn.delete.text')} onClick={onDelete} />
      </div>
    </>
  );
};

export default DeleteTrackModalForm;
