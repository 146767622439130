import React, { Fragment, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { toast } from 'react-toastify';

import { RootState } from 'reducers';
import { closeModal, showAlert } from 'reducers/modal/index';
import { addTransport, updateTransport, removeChosenTransport } from 'reducers/transports';
import { toggleSelectUnit } from 'reducers/monitoring';

import { Alert } from 'components/common/alert/alert';
import { Spinner } from 'components/common/spinner/spinner';
import { TabInfo } from './tabInfo.modal';
import { ModalHeader } from '../components/header.modal';
import { ModalControls } from '../components/controls.modal';
import { ModalTabs } from '../components/tabs.modal';
import TabAdditionalFields from '../components/tabAdditionalFields1/tabAdditionalFields1';

import AccessEntity from 'utils/accessEntity';
import { DELETE_TRANSPORT } from 'utils/consts';
import { useTransportInfo, useTransportAdditionalFields } from '../utils/hooks';
import { ActiveTab } from '../utils/types';

import styles from '../monitoring.module.scss';

const TransportModalForm = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const {
    transportValues,
    transportHandlers,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    chosenTransport,
    chosenTransportId,
    handbooks,
    mainInfoHasChanges,
    additionalData,
    isEditing,
  } = useTransportInfo();

  const isTrackersLoading = useSelector((state: RootState) => state.tracker.isLoading);
  const transports = useSelector((state: RootState) => state.transport.transports);
  const isTransportsLoading = useSelector((state: RootState) => state.transport.isLoading);
  const isIncludesLoading = useSelector((state: RootState) => state.transport.isIncludesLoading);
  const isChosenTransportDataLoading = useSelector((state: RootState) => state.transport.isChosenTransportDataLoading);
  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);
  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);

  const isLoading = isTrackersLoading || isTransportsLoading || isIncludesLoading || isChosenTransportDataLoading;
  const trLength = transports.length;

  const additionalFields = useTransportAdditionalFields();

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.info);
  const [showLocalAlert, setShowLocalAlert] = useState(false);
  const trackersPermission = useSelector((state: RootState) => state.user.permissions.trackers);
  const transportsPermission = useSelector((state: RootState) => state.user.permissions.transports);
  const trackersAccess = useMemo(() => new AccessEntity(trackersPermission), [trackersPermission]);
  const transportsAccess = useMemo(() => new AccessEntity(transportsPermission), [transportsPermission]);

  const handleCancel = () => {
    if (mainInfoHasChanges() || additionalFields.hasChanges()) {
      return setShowLocalAlert(true);
    }
    dispatch(removeChosenTransport());
    return dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    return setShowLocalAlert(false);
  };

  const handleAlertContinue = () => {
    setShowLocalAlert(true);
    dispatch(removeChosenTransport());
    return dispatch(closeModal());
  };

  const handleSave = async () => {
    if (trLength === 7) {
      return toast.error(t('toast.monitoring.add-transport.error.text'));
    }

    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      setInvalidFields(validationResult);
      return setActiveTab(ActiveTab.info);
    }

    if (chosenTransport.createdAt) {
      if (transportsAccess.isAllowUpdate()) {
        await dispatch(
          updateTransport({
            ...chosenTransport,
            ...transportValues,
            additionalFields: additionalFields.additionalFields,
            id: chosenTransportId,
          })
        );

        // If no trakcer and transport is selected then remove selection
        if (!transportValues.trackerId && chosenTransportId) {
          const transportTrackableUnit = trackableUnits.find(unit => unit.attributes.transportId === chosenTransportId);

          if (transportTrackableUnit && selectedUnits.includes(transportTrackableUnit.id)) {
            dispatch(toggleSelectUnit({ id: transportTrackableUnit.id, currentStatus: true }));
          }
        }
      }
    } else {
      if (transportsAccess.isAllowCreate()) {
        dispatch(
          await addTransport({
            ...transportValues,
            additionalFields: additionalFields.additionalFields,
            id: chosenTransportId,
          })
        );
      }
    }
    dispatch(closeModal());
  };

  const handleDelete = () => {
    if (chosenTransport) {
      return dispatch(showAlert({ alertType: DELETE_TRANSPORT }));
    }
    return handleCancel();
  };

  const disabledSaveButton = chosenTransport.createdAt
    ? !transportsAccess.isAllowUpdate()
    : !transportsAccess.isAllowCreate();
  const disabledDeleteButton = !transportsAccess.isAllowDelete();

  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <ModalHeader title={t('monitoring.unit-card.header.transport.label')} onCancel={handleCancel} />
      </div>
      <div className={styles.modalBody}>
        {isLoading ? (
          <div className={styles.modalInfoLoading}>
            <Spinner />
          </div>
        ) : (
          <ModalTabs showStat={isEditing} activeTab={activeTab} setActiveTab={setActiveTab}>
            {activeTab === ActiveTab.info && (
              <TabInfo
                handbooks={handbooks}
                chosenTransportId={chosenTransportId}
                fields={{ ...transportValues, trackersPermission: trackersAccess }}
                handlers={transportHandlers}
                invalidFields={invalidFields}
                additionalData={additionalData}
              />
            )}
            {activeTab === ActiveTab.additional && <TabAdditionalFields {...additionalFields} />}
          </ModalTabs>
        )}
      </div>
      <div className={styles.modalControls}>
        <ModalControls
          withoutDelete={!Boolean(chosenTransport?.createdAt)}
          withoutSave={
            chosenTransport?.createdAt ? !transportsAccess.isAllowUpdate() : !transportsAccess.isAllowCreate()
          }
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          disabledSaveButton={disabledSaveButton}
          disabledDeleteButton={disabledDeleteButton}
          saveButtonText={
            chosenTransport.createdAt
              ? t('monitoring.unit-card.footer.btn.update.label')
              : t('monitoring.unit-card.footer.btn.create.label')
          }
        />
      </div>

      {showLocalAlert && (
        <Alert
          title={t('monitoring.unit-card.close-alert.header.label')}
          infoText={t('monitoring.unit-card.close-alert.warning.text')}
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default TransportModalForm;
