import React from 'react';
import { useSelector } from 'react-redux';

import Pagination from 'components/common/pagination/pagination';
import { MessagesTablePaginationHandlers } from 'components/messages/utils/types';

import { RootState } from 'reducers';
import { defaultMessagePagination } from 'reducers/messages';

import styles from './tablePagination.module.scss';

type Props = {
  handlers: MessagesTablePaginationHandlers;
};

export const TablePagination = ({ handlers }: Props) => {
  const { pagination } = useSelector((state: RootState) => state.messages);

  return (
    <Pagination
      withSelect
      page={pagination.page ?? defaultMessagePagination.page}
      increasePage={handlers.handleIncreasePage}
      decreasePage={handlers.handleDecreasePage}
      limit={pagination.limit}
      setLimit={handlers.handleSetLimit}
      wrapperClass={styles.pagination}
    />
  );
};
