export function getQueryStrFromObjKeys<T extends Record<string, unknown>>(queryParams: T) {
  const queryParamsKeys = Object.keys(queryParams);
  const query: string[] = [];

  queryParamsKeys.forEach(paramKey => {
    const value = queryParams[paramKey];

    if (/* queryParams.hasOwnProperty(paramKey) &&  */ value) {
      if (Array.isArray(value)) {
        if ((value as []).length) {
          query.push(`${paramKey}=${(value as []).join(',')}`);
        }
      } else {
        query.push(`${paramKey}=${value}`);
      }
    }
  });

  return query.join('&');
}
