import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Select, { Option } from 'components/common/select/select';
import { generateTimezoneSelectOptions, TimezoneWithTranslation } from '../../utils/commonSettingsTab';
import { SUPPORTED_TIMEZONES } from 'utils/consts';

import styles from './commonSettingsTab.module.scss';

type Props = {
  values: {
    locale: string;
    metricSystem: string;
    timezone: string;
    dateFormat: string;
    timeFormat: string;
    gpsFormat: string;
  };
  additionalValues: {
    interfaceLanguageForSelect: Option[];
  };
  handlers: {
    handleChangeLng: (value: string) => void;
    handleChangeTimezone: (timezone: string) => void;
  };
};

export function CommonSettingsTab({ values, additionalValues, handlers }: Props) {
  const t = useFormatMessage();

  const timezoneSelectOptions = useMemo(() => {
    const timezonesWithTranslations: TimezoneWithTranslation[] = SUPPORTED_TIMEZONES.map(timezone => ({
      ianaName: timezone,
      translation: t(`timezones.${timezone}`),
    }));

    return generateTimezoneSelectOptions(timezonesWithTranslations);
  }, [t]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.lang.label')}
          placeholder={t('user-settings.modal.general.field.lang.placeholder')}
          value={additionalValues.interfaceLanguageForSelect.find(option => option.value === values.locale)?.value ?? 0}
          options={additionalValues.interfaceLanguageForSelect}
          isRequired={false}
          handleChange={value => handlers.handleChangeLng(value)}
        />
      </div>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.measurement.label')}
          placeholder={t('user-settings.modal.general.field.measurement.placeholder')}
          value={values.metricSystem}
          isRequired={false}
          options={[
            {
              value: values.metricSystem,
              label: values.metricSystem,
            },
          ]}
        />
      </div>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.timezone.label')}
          placeholder={t('user-settings.modal.general.field.timezone.placeholder')}
          value={values.timezone}
          isRequired={false}
          options={timezoneSelectOptions}
          handleChange={handlers.handleChangeTimezone}
        />
      </div>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.date-format.label')}
          placeholder={t('user-settings.modal.general.field.date-format.placeholder')}
          value={values.dateFormat}
          isRequired={false}
          options={[
            {
              value: values.dateFormat,
              label: values.dateFormat,
            },
          ]}
        />
      </div>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.time-format.label')}
          placeholder={t('user-settings.modal.general.field.time-format.placeholder')}
          value={values.timeFormat}
          isRequired={false}
          options={[
            {
              value: values.timeFormat,
              label: values.timeFormat,
            },
          ]}
        />
      </div>
      <div className={styles.item}>
        <Select
          label={t('user-settings.modal.general.field.gps-format.label')}
          placeholder={t('user-settings.modal.general.field.gps-format.placeholder')}
          value={values.gpsFormat}
          isRequired={false}
          options={[
            {
              value: values.gpsFormat,
              label: values.gpsFormat,
            },
          ]}
        />
      </div>
    </div>
  );
}
