export default {
  en: {
    'toast.handbooks.wrong-type-file.error.text': 'Only files in CSV format are available for import',
    'toast.handbooks.large-file-size.error.text': 'The file is larger than 10 MB',

    'toast.handbooks.file-importing.success.text': 'Success',
    'toast.handbooks.file-importing.fail.text': 'Fail',
    'toast.handbooks.file-importing.limit.text': 'Limit',
    'toast.handbooks.file-importing.total.text': 'Total imported',

    'toast.handbooks.element-card.name-existed.error.text': 'An element with the same name already exists',
  },
  ru: {
    'toast.handbooks.wrong-type-file.error.text': 'Только файлы в формате CSV доступны для импорта',
    'toast.handbooks.large-file-size.error.text': 'Файл превышает 10 Мб',

    'toast.handbooks.file-importing.success.text': 'Успешно',
    'toast.handbooks.file-importing.fail.text': 'Ошибка',
    'toast.handbooks.file-importing.limit.text': 'Лимит',
    'toast.handbooks.file-importing.total.text': 'Импортировано всего',

    'toast.handbooks.element-card.name-existed.error.text': 'Элемент с таким именем уже существует',
  },
  zh: {
    'toast.handbooks.wrong-type-file.error.text': '只能导入 CSV 文件',
    'toast.handbooks.large-file-size.error.text': '文件超过 10 MB',
    'toast.handbooks.file-importing.success.text': '成功地',
    'toast.handbooks.file-importing.fail.text': '错误',
    'toast.handbooks.file-importing.limit.text': '限制',
    'toast.handbooks.file-importing.total.text': '转移的项目数',
    'toast.handbooks.element-card.name-existed.error.text': '已存在同名元素',
  },
  ar: {
    'toast.handbooks.wrong-type-file.error.text': 'تتوفر فقط ملفات CSV للاستيراد',
    'toast.handbooks.large-file-size.error.text': 'حجم الملف يتجاوز 10 ميغا بايت',
    'toast.handbooks.file-importing.success.text': 'بنجاح',
    'toast.handbooks.file-importing.fail.text': 'خطأ',
    'toast.handbooks.file-importing.limit.text': 'حد',
    'toast.handbooks.file-importing.total.text': 'الإجمالي المستورد',
    'toast.handbooks.element-card.name-existed.error.text': 'يوجد عنصر بنفس الاسم بالفعل',
  },
  de: {
    'toast.handbooks.wrong-type-file.error.text': 'Für den Import sind nur CSV-Dateien verfügbar',
    'toast.handbooks.large-file-size.error.text': 'Die Datei überschreitet 10 MB',
    'toast.handbooks.file-importing.success.text': 'Erfolgreicher Betrieb',
    'toast.handbooks.file-importing.fail.text': 'Fehler',
    'toast.handbooks.file-importing.limit.text': 'Begrenzen',
    'toast.handbooks.file-importing.total.text': 'Importierte Gesamtmenge',
    'toast.handbooks.element-card.name-existed.error.text': 'Ein Element mit demselben Namen existiert bereits',
  },
};
