import React, { useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Cistern from 'assets/img/cistern-big.png';
import { ReactComponent as DropArrow } from 'assets/img/button/drop-arrow.svg';
import { ReactComponent as HeaderIcon } from 'assets/img/object-card-icon.svg';

import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { CisternState, CisternProperties } from 'components/map/types';

import { NA, HIDE_TEXT, MORE_TEXT, APP_CONTENT_ID } from 'utils/consts';

import styles from './cisternPopup.module.scss';

const cx = classNames.bind(styles);

interface Additional {
  date: string;
  text: string;
}

interface CisternPopupData {
  id: number;
  name: string;
  state: string;
  startYear: string;
  org: string;
  department: string;
  workObject: string;
  lastRepairDate: string;
  nextRepairDate: string;

  renovations: Additional[];
  violations: Additional[];
}

interface Props {
  data: CisternPopupData;
  properties: CisternProperties;
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
}

const INITIAL_POPUP_WIDTH = 400;
const INITIAL_POPUP_HEIGHT = 235;
const INITIAL_POPUP_FOOTER_HEIGHT = 115;

function CisternPopup({ data, properties, onCancel, markerCoords }: Props) {
  const t = useFormatMessage();

  const [showAdditional, setShowAdditional] = useState(false);

  const toggleAdditional = () => {
    setShowAdditional(!showAdditional);
  };

  const photoStyle = cx(styles.popupSidePhoto, {
    [styles.popupSidePhotoBad]: properties.level === CisternState.Bad,
    [styles.popupSidePhotoWarn]: properties.level === CisternState.Warn,
    [styles.popupSidePhotoNormal]: properties.level === CisternState.Normal,
  });
  const stateStyle = cx(styles.popupContentState, {
    [styles.popupContentStateBad]: properties.level === CisternState.Bad,
    [styles.popupContentStateWarning]: properties.level === CisternState.Warn,
    [styles.popupContentStateNormal]: properties.level === CisternState.Normal,
  });
  const nextDateStyle = cx(styles.popupContentNextDate, {
    [styles.popupContentNextDateBad]: properties.level === CisternState.Bad,
    [styles.popupContentNextDateWarning]: properties.level === CisternState.Warn,
  });

  // хедер попапа
  const headerPopup = (
    <div className={styles.popupHeader}>
      <HeaderIcon className={styles.popupHeader__icon} />
      {t('map-popup.cistern.header.text')}
    </div>
  );

  // контент попапа
  const contentPopup = (
    <div className={styles.popupMain}>
      <div className={styles.popupSide}>
        <div className={photoStyle}>
          <img className={styles.popupSidePhotoImage} src={Cistern} alt="Cistern" />
        </div>
        <div className={styles.popupSideButton} onClick={toggleAdditional}>
          <span className={styles.popupSideButtonText}>{showAdditional ? t(HIDE_TEXT) : t(MORE_TEXT)}</span>
          <DropArrow className={showAdditional ? styles.popupSideButtonIconUp : styles.popupSideButtonIconDown} />
        </div>
      </div>
      <div className={styles.popupContent}>
        <div className={styles.popupContentNameWrap}>
          <div className={styles.popupContentName}>{data.name}</div>
          <div className={styles.popupContentPosition}>
            {t('map-popup.cistern.state.text')} <span className={stateStyle}>{data.state}</span>
          </div>
          <div className={styles.popupContentStartYear}>
            {t('map-popup.cistern.year.text')} {data.startYear}
          </div>
        </div>
        <div className={styles.popupContentOrgWrap}>
          <div className={styles.popupContentOrg}>{data.org || `${t('map-popup.cistern.company.text')} ${NA}`}</div>
          <div className={styles.popupContentStructure}>
            {data.department || `${'map-popup.cistern.subdivision.text'} ${NA}`}
          </div>
          <div className={styles.popupContentWorkObject}>
            {data.workObject || `${'map-popup.cistern.object.text'} ${NA}`}
          </div>
        </div>

        <div className={styles.popupContentDatesWrap}>
          <div>
            {t('map-popup.cistern.date-last.text')}{' '}
            <span className={styles.popupContentPrevDate}>{data.lastRepairDate || NA}</span>
          </div>
          <div>
            {t('map-popup.cistern.date-next.text')} <span className={nextDateStyle}>{data.nextRepairDate || NA}</span>
          </div>
        </div>
      </div>
    </div>
  );

  // футер попапа
  const footerPopup = (
    <div
      className={cx(styles.popupAdditional, {
        [styles.popupAdditionalShow]: showAdditional,
        [styles.popupAdditionalHide]: !showAdditional,
      })}
    >
      <div className={styles.popupAdditionalWrap}>
        <div className={styles.popupAdditionalLabel}>{t('map-popup.cistern.current-coords.text')}</div>
        <div className={styles.popupAdditionalValue}>
          {t('map-popup.cistern.coords.text')} {markerCoords.mapCoords.x.toFixed(8)},
          {markerCoords.mapCoords.y.toFixed(8)}
        </div>
      </div>
      {data.renovations.length > 0 && (
        <div className={styles.popupAdditionalWrap}>
          <div className={styles.popupAdditionalLabel}>{t('map-popup.cistern.repair.text')}</div>
          {data.renovations.map((ren, ind) => (
            <Fragment key={`cistern-popup-renovation-${ind}`}>
              <div className={styles.popupAdditionalValue}>
                {ren.date}: {ren.text}
              </div>
            </Fragment>
          ))}
        </div>
      )}

      {data.violations.length > 0 && (
        <div className={styles.popupAdditionalWrap}>
          <div className={styles.popupAdditionalLabel}>{t('map-popup.cistern.violation.text')}</div>
          {data.violations.map((viol, ind) => (
            <Fragment key={`cistern-popup-violation-${ind}`}>
              <div className={styles.popupAdditionalValue}>
                {viol.date}: {viol.text}
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      content={contentPopup}
      footer={footerPopup}
      initialWidth={INITIAL_POPUP_WIDTH}
      initialHeight={INITIAL_POPUP_HEIGHT}
      initialFooterHeight={INITIAL_POPUP_FOOTER_HEIGHT}
      showFooter={showAdditional}
      initialPosition={markerCoords.mouseCoords}
      resizeAxis="y"
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(CisternPopup);
