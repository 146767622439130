import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Pagination from 'components/common/pagination/pagination';

import { RootState } from 'reducers';
import { setFilterField } from 'reducers/notifications';

import styles from './tablePagination.module.scss';

const DEFAULT_LIMIT = 5;
const DEFAULT_PAGE = 1;

export const TablePagination = () => {
  const dispatch = useDispatch();

  const { historyLogsFilter, historyLogsTotal } = useSelector((state: RootState) => state.notifications);

  const handleIncreasePage = () => {
    const { page = DEFAULT_PAGE, limit = DEFAULT_LIMIT } = historyLogsFilter;
    const pageLimit = Math.ceil(historyLogsTotal / limit);
    const inc = page ? page + 1 : 1;

    dispatch(
      setFilterField({
        key: 'page',
        value: inc <= pageLimit ? inc : 1,
      })
    );
  };

  const handleDecreasePage = () => {
    const dec = historyLogsFilter.page ? historyLogsFilter.page - 1 : 1;

    dispatch(
      setFilterField({
        key: 'page',
        value: dec > 1 ? dec : 1,
      })
    );
  };

  const handleSetLimit = (value: number) => {
    dispatch(
      setFilterField({
        key: 'limit',
        value: value,
      })
    );
  };

  return (
    <Pagination
      withSelect
      page={historyLogsFilter.page ?? DEFAULT_PAGE}
      increasePage={handleIncreasePage}
      decreasePage={handleDecreasePage}
      limit={historyLogsFilter.limit}
      setLimit={handleSetLimit}
      wrapperClass={styles.pagination}
    />
  );
};
