import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames/bind';

import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';
import { Spinner } from 'components/common/spinner/spinner';
import { Alert } from 'components/common/alert/alert';
import { MESSAGE_TYPES, MESSAGE_TYPES_TO_TRANSLATE } from 'components/messages/utils/consts';
import {
  MessagesLeftBarContentStates,
  MessagesLeftBarContentRefs,
  MessagesLeftBarContentHandlers,
} from 'components/messages/utils/types';
import MessagesPopup from 'components/map/components/messagesPopup/messagesPopup';

import { RootState } from 'reducers';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';

import styles from './messagesListContent.module.scss';

const cx = classNames.bind(styles);

type Props = {
  states: MessagesLeftBarContentStates;
  refs: MessagesLeftBarContentRefs;
  handlers: MessagesLeftBarContentHandlers;
};

function MessagesListContent({ states, refs, handlers }: Props) {
  const t = useFormatMessage();

  const { messages, included, isMessagesLoading, selectedMessages } = useSelector((state: RootState) => state.messages);

  return (
    <Fragment>
      <ul className={styles.list} ref={refs.messagesRef} onScroll={handlers.handleListScroll}>
        {messages.map((message, i) => {
          const attr = message.attributes;
          const trackableUnitsIds = message.relationships.trackableUnits.data.map(unit => unit.id);
          const trackableUnits = included.filter(unit => trackableUnitsIds.includes(unit.id));
          const fullDate = getFullDateFromStr(attr.createdAt);

          return (
            <li key={`message-item-container-${message.id}-${i}`} className={styles.item}>
              <div className={styles.header}>
                <div className={styles.side}>
                  <Checkbox
                    checked={selectedMessages.includes(message.id)}
                    handleCheck={() => handlers.handleCheckItem(message.id)}
                    wrapperStyle={styles.checkbox}
                  />
                  <div
                    className={styles.name}
                    onClick={e => handlers.handleNameClick(e, message.id)}
                    data-ellipsis
                    data-text={trackableUnits.map(unit => unit.attributes.aggregatedName).join(',')}
                  />
                </div>
                <div className={styles.side}>
                  {attr.messageType && (
                    <div
                      className={cx(styles.type, {
                        [styles.typeIncoming]: attr.messageType === MESSAGE_TYPES.INCOME,
                        [styles.typeOutgoing]: attr.messageType === MESSAGE_TYPES.OUTCOME,
                      })}
                    >
                      {t(MESSAGE_TYPES_TO_TRANSLATE[attr.messageType])}
                    </div>
                  )}
                  <div className={styles.deleteIcon}>
                    <DeleteIcon
                      className={styles.icon}
                      onClick={() => handlers.handleDeleteItem(message.id)}
                      data-tip
                      data-for={`message-item-${message.id}`}
                    />
                    <ReactTooltip
                      className={styles.customTooltip}
                      id={`message-item-${message.id}`}
                      place="right"
                      type="light"
                      effect="solid"
                    >
                      <span>{t('messages.left-bar.content.list.item.tooltip.delete.text')}</span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <ul className={styles.footer} ref={refs.footerListRef}>
                <li className={styles.footerRow}>
                  <span className={styles.footerRowDescription}>
                    {t('messages.left-bar.content.list.item.description.tracker-number.text')}
                    {': '}
                  </span>
                  <span className={styles.footerRowText}>
                    {trackableUnits.map(unit => unit.attributes.trackerNumber).join(',')}
                  </span>
                </li>
                <li className={styles.footerRow}>
                  <span className={styles.footerRowDescription}>
                    {t('messages.left-bar.content.list.item.description.date.text')}
                    {': '}
                  </span>
                  <span className={styles.footerRowText}>{fullDate.date} </span>
                  <span className={styles.footerRowText}>{fullDate.time}</span>
                </li>
                <li className={styles.footerRow}>
                  <span className={styles.footerRowDescription}>
                    {t('messages.left-bar.content.list.item.description.message.text')}
                    {': '}
                  </span>
                  <span className={styles.footerRowText} data-ellipsis data-text={attr.body} />
                </li>
              </ul>
            </li>
          );
        })}
        {isMessagesLoading && (
          <li className={styles.item}>
            <Spinner />
          </li>
        )}
      </ul>

      {!!states.deletedId && (
        <Alert
          title={t('messages.list.template-list.alert.delete.title')}
          infoText={t('messages.list.template-list.alert.delete.text')}
          handleCancel={handlers.handleDeleteAlertCancel}
          handleContinue={handlers.handleDeleteAlertContinue}
        />
      )}

      {states.isPopupShow && (
        <MessagesPopup
          onCancel={handlers.handleClosePopup}
          markerCoords={{ mapCoords: { x: 0, y: 0 }, mouseCoords: states.mouseCoords }}
        />
      )}
    </Fragment>
  );
}

export default MessagesListContent;
