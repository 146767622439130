import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { TrackersModalHeader } from './components/header1/header1';
import { TrackersModalControls } from './components/controls1/controls1';
import { TrackersModalTabsNav } from './components/tabsNav1/tabsNav1';
import { TrackersTabInfo } from './components/tabInfo1/tabInfo1';
import { Spinner } from 'components/common/spinner/spinner';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import { RootState } from 'reducers';
import { fetchHandbookData } from 'reducers/handbooks';
import { fetchTransportIncludes } from 'reducers/transports';
import { closeModal, showAlert } from 'reducers/modal';
import { addTracker, updateTracker, removeChosenTracker } from 'reducers/trackers';

import { DELETE_TRACKER } from 'utils/consts';
import { ActiveTab } from './utils/consts';
import AccessEntity from 'utils/accessEntity';

import styles from './trackers.module.scss';

const TrackerModalForm = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const trackersPermission = useSelector((state: RootState) => state.user.permissions.trackers);
  const trackersAccess = useMemo(() => new AccessEntity(trackersPermission), [trackersPermission]);

  const chosenTracker = useSelector((state: RootState) => state.tracker.chosenTracker);
  const chosenTrackerIsLoading = useSelector((state: RootState) => state.tracker.chosenTrackerIsLoading);
  const needUpdate = useSelector((state: RootState) => state.tracker.needUpdate);

  const trackerModel = useSelector((state: RootState) => state.tracker.includes.trackerModel);
  const trackerStatus = useSelector((state: RootState) => state.tracker.includes.trackerStatus);

  const trackerHandbooks = {
    models: trackerModel,
    states: trackerStatus,
  };

  const [trackerNumber, setTrackerNumber] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [simNumber, setSimNumber] = useState('');
  const [trackerModelId, setTrackerModel] = useState(0);
  const [anchorSimNumber, setAnchorSimNumber] = useState('');
  const [trackerStatusId, setTrackerStatusId] = useState(0);
  const [comment, setComment] = useState('');

  const [activeTab, setActiveTab] = useState(ActiveTab.info);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const transportIncludesInfo = useSelector((state: RootState) => state.transport.includes);
  const organizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const positions = useSelector((state: RootState) => state.handbooks.data.positions);
  const handbookDataLoading = useSelector((state: RootState) => state.handbooks.isDataLoading);

  useEffect(() => {
    if (!organizations?.length || !positions?.length) {
      dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.positions]));
    }
  }, [dispatch, organizations, positions]);

  useEffect(() => {
    if (!transportIncludesInfo.driverName.length) {
      dispatch(fetchTransportIncludes());
    }
  }, [dispatch, transportIncludesInfo]);

  useEffect(() => {
    if (chosenTracker) {
      const { attributes } = chosenTracker;
      setTrackerNumber(attributes.trackerNumber);
      setDeviceId(attributes.deviceId);
      setSimNumber(attributes.simNumber);
      setTrackerModel(attributes.trackerModelId);
      setAnchorSimNumber(attributes.anchorSimNumber);
      setTrackerStatusId(attributes.trackerStatusId);
      setComment(attributes.comment);
    }
  }, [chosenTracker]);

  const boundedObject = useMemo(() => {
    if (chosenTracker) {
      const { relationships } = chosenTracker;

      if (!!relationships) {
        return relationships.trackableUnit?.data.aggregatedName;
      }
    }
    return '';
  }, [chosenTracker]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(removeChosenTracker());
      dispatch(closeModal());
    }
  }, [needUpdate, dispatch]);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!trackerNumber) {
      newInvalidFields.push('trackerNumber');
    }
    if (!deviceId) {
      newInvalidFields.push('deviceId');
    }
    if (!simNumber) {
      newInvalidFields.push('simNumber');
    }
    if (!trackerModelId) {
      newInvalidFields.push('trackerModel');
    }
    if (!trackerStatusId) {
      newInvalidFields.push('trackerState');
    }
    if (!comment) {
      newInvalidFields.push('comment');
    }

    return newInvalidFields;
  };

  const handleSave = () => {
    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      return setInvalidFields(validationResult);
    }

    if (chosenTracker) {
      const tracker = {
        id: chosenTracker.id,
        trackerNumber,
        deviceId,
        simNumber,
        comment,
        anchorSimNumber,
        trackerModelId,
        trackerStatusId,
        isUsed: true,
      };
      if (trackersAccess.isAllowUpdate()) {
        dispatch(updateTracker(tracker));
      }
    } else {
      const tracker = {
        trackerNumber,
        simNumber,
        trackerModelId,
        deviceId,
        trackerStatusId,
        comment,
        isUsed: false,
      };
      if (trackersAccess.isAllowCreate()) {
        dispatch(addTracker(tracker));
      }
    }
  };

  const handleCancel = () => {
    dispatch(removeChosenTracker());
    return dispatch(closeModal());
  };

  const handleDelete = () => {
    if (chosenTracker) {
      return dispatch(showAlert({ alertType: DELETE_TRACKER }));
    }
    return handleCancel();
  };

  const handleTrackerModelChange = (value: string) => {
    const id = parseInt(value);
    setTrackerModel(id);
    const model = trackerHandbooks.models.find(m => m.id === id);
    if (model) {
      setAnchorSimNumber(model.anchorSimNumber);
      setDeviceId(model.deviceId);
    }
  };

  const handleTrackerStateChange = (value: string) => {
    const id = parseInt(value);
    setTrackerStatusId(id);
  };

  return (
    <Fragment>
      <TrackersModalHeader title={t('trackers.card.header.label')} onCancel={handleCancel} />
      <div className={styles.modalBody}>
        <div className={styles.modalWrapper}>
          {chosenTrackerIsLoading || handbookDataLoading ? (
            <div className={styles.modalSpinner}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              <TrackersModalTabsNav activeTab={activeTab} setActiveTab={setActiveTab} showStat={!!chosenTracker} />
              {activeTab === ActiveTab.info && (
                <TrackersTabInfo
                  invalidFields={invalidFields}
                  onDeviceIdChange={setDeviceId}
                  onModelChange={handleTrackerModelChange}
                  onNumberChange={setTrackerNumber}
                  onSimChange={setSimNumber}
                  onStateChange={handleTrackerStateChange}
                  onCommentChange={setComment}
                  onChangeBoundedObject={setAnchorSimNumber}
                  trackerNumber={trackerNumber}
                  deviceId={deviceId}
                  simNumber={simNumber}
                  trackerModel={trackerModelId}
                  trackerServer={deviceId}
                  trackerState={trackerStatusId}
                  boundedObject={boundedObject}
                  comment={comment}
                  modelOptions={trackerHandbooks.models.map(m => ({ value: m.id, label: m.name }))}
                  stateOptions={trackerHandbooks.states.map(s => ({ value: s.id, label: s.name }))}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
      <TrackersModalControls
        onSave={handleSave}
        onCancel={handleCancel}
        onDelete={handleDelete}
        saveButtonText={
          chosenTracker ? t('trackers.card.footer.btn.update.label') : t('trackers.card.footer.btn.create.label')
        }
        isAllowDelete={Boolean(chosenTracker) && trackersAccess.isAllowDelete()}
        isAllowCreate={trackersAccess.isAllowCreate()}
        isAllowUpdate={trackersAccess.isAllowUpdate()}
      />
    </Fragment>
  );
};

export default React.memo(TrackerModalForm);
