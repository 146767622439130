import React, { FC, useRef, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Button from 'components/common/button/button';

import { useSetTitleForWideListItem } from 'utils/useSetTitleForWideListItem';
import { useOutsideClickHandler } from 'utils/hooks/useOutsideClickHandler';

import styles from './dropbutton.module.scss';

const cx = classNames.bind(styles);

export type DropButtonsType = Array<{ label: string; value: string; dataCy?: string }>;

type DropButtonProps = {
  label: string;
  buttons: DropButtonsType;
  onClick: (value: string) => void;
  customStyle?: string;
  disabled?: boolean;
  noAdditionalSymbol?: boolean;
  optionsIsSmall?: boolean;
  optionsWidth?: number;
  noWrappedItems?: string[];
  'data-cy'?: string;
};

const DropButton: FC<DropButtonProps> = ({
  label,
  buttons,
  onClick,
  customStyle,
  disabled,
  noAdditionalSymbol,
  optionsIsSmall,
  optionsWidth,
  noWrappedItems,
  'data-cy': dataCy,
}: DropButtonProps) => {
  const [showButtons, setShowButtons] = useState(false);
  const t = useFormatMessage();

  const optionsListRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);

  const buttonWrapperWidth = buttonWrapperRef?.current?.getBoundingClientRect().width;
  const optionsListWidth = optionsWidth || buttonWrapperWidth;

  useOutsideClickHandler(containerRef, () => {
    if (showButtons) {
      setShowButtons(false);
    }
  });

  // установка троеточия и атрибута title для
  // элементов в списке опций, которые не умещаются
  useSetTitleForWideListItem({
    wrapperListRef: optionsListRef,
    excludeWrappedItems: noWrappedItems || [],
  });

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const handleDropButton = (value: string) => {
    onClick(value);
    toggleButtons();
  };

  return (
    <div
      ref={containerRef}
      className={cx(styles.wrapperDropdown, customStyle, {
        [styles.wrapperDropdownDisabled]: disabled,
      })}
    >
      <div className={styles.buttonWrapper} ref={buttonWrapperRef}>
        <Button
          data-cy={dataCy}
          text={label}
          blue
          long
          withSymbol={!noAdditionalSymbol}
          withDrop
          onClick={toggleButtons}
          disabled={disabled}
        />
      </div>

      {showButtons && (
        <ul
          className={cx(styles.dropdown, {
            [styles.dropdownSmall]: optionsIsSmall,
          })}
          style={{ width: optionsListWidth ? `${optionsListWidth}px` : `100%` }}
          ref={optionsListRef}
        >
          {buttons.map((button, ind) => (
            <li
              data-cy={button.dataCy}
              key={`drop-button-${ind}`}
              onClick={() => handleDropButton(button.value)}
              className={cx(styles.dropdownLi, {
                [styles.dropdownLiSmall]: optionsIsSmall,
              })}
            >
              {t(button.label)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropButton;
