export default {
  en: {
    'monitoring.button-block.drop-down-btn.label': 'Create',
    'monitoring.button-block.drop-down-btn.value.employee.text': 'Employee',
    'monitoring.button-block.drop-down-btn.value.vehicle.text': 'Vehicle',

    'monitoring.search.unit-type.transport.text': 'Vehicle',
    'monitoring.search.unit-type.employee.text': 'Employee',

    'monitoring.units-list.header.name.label': 'Name',
    'monitoring.units-list.header.number.tooltip.text': 'Tracker’s number',
    'monitoring.units-list.header.manage-list.tooltip.text': 'Manage your list',
    'monitoring.units-list.header.follow-obj.tooltip.text': 'Follow all',
    'monitoring.units-list.header.connect-status.tooltip.text': 'Connection status',
    'monitoring.units-list.header.signal-quality.tooltip.text': 'Signal quality',
    'monitoring.units-list.header.battery-level.tooltip.text': 'Battery charge level',
    'monitoring.units-list.header.list-group.tooltip.text': 'List alinement',

    'monitoring.units-list.unit.settings.tooltip.text': 'Settings',
    'monitoring.units-list.unit.follow.tooltip.text': 'Follow up',

    'monitoring.unit-card.header.object.label': 'Object card',
    'monitoring.unit-card.header.employee.label': 'Employee’s properties',
    'monitoring.unit-card.header.transport.label': 'Vehicle’s properties',

    'monitoring.unit-card.tab.general-info.label': 'General info',
    'monitoring.unit-card.tab.additional-fields.label': 'Additional fields',
    'monitoring.unit-card.tab.statistic.label': 'Object statistic',

    'monitoring.unit-card.employee.general.field.surname.label': 'Surname',
    'monitoring.unit-card.employee.general.field.surname.placeholder': 'Employee’s surname',
    'monitoring.unit-card.employee.general.field.name.label': 'Name',
    'monitoring.unit-card.employee.general.field.name.placeholder': 'Employee’s name',
    'monitoring.unit-card.employee.general.field.patronymic.label': 'Patronymic',
    'monitoring.unit-card.employee.general.field.patronymic.placeholder': 'Employee’s patronymic',
    'monitoring.unit-card.employee.general.field.phone-number.label': 'Employee’s phone number',
    'monitoring.unit-card.employee.general.field.phone-number.placeholder': 'Input format +7XXXXXXXXXX',
    'monitoring.unit-card.employee.general.field.pass-number.label': 'Pass number',
    'monitoring.unit-card.employee.general.field.pass-number.placeholder': 'Employee’s pass number',
    'monitoring.unit-card.employee.general.field.linked-tracker.label': 'Linked tracker',
    'monitoring.unit-card.employee.general.field.linked-tracker.placeholder': 'Select from the list',
    'monitoring.unit-card.employee.general.field.company.label': 'Company',
    'monitoring.unit-card.employee.general.field.company.placeholder': 'Select from the list',
    'monitoring.unit-card.employee.general.field.subdivision.label': 'Subdivision',
    'monitoring.unit-card.employee.general.field.subdivision.placeholder': 'Select from the list',
    'monitoring.unit-card.employee.general.field.position.label': 'Position',
    'monitoring.unit-card.employee.general.field.position.placeholder': 'Select from the list',
    'monitoring.unit-card.employee.general.field.work-area.label': 'Work area',
    'monitoring.unit-card.employee.general.field.work-area.placeholder': 'Select from the list',
    'monitoring.unit-card.employee.general.field.pass-start-date.label': 'Pass’ start date',
    'monitoring.unit-card.employee.general.field.pass-start-date.placeholder': 'Select date',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.label': 'Pass’ expiration date',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder': 'Select date',

    'monitoring.unit-card.transport.general.field.brand.label': 'Manufacturer',
    'monitoring.unit-card.transport.general.field.brand.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.model.label': 'Model',
    'monitoring.unit-card.transport.general.field.model.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.registration-number.label': 'Registration number',
    'monitoring.unit-card.transport.general.field.registration-number.placeholder': 'Enter registration number',
    'monitoring.unit-card.transport.general.field.model-year.label': 'Year of issue',
    'monitoring.unit-card.transport.general.field.model-year.placeholder': 'Enter year of issue',
    'monitoring.unit-card.transport.general.field.type.label': 'Vehicle’s type',
    'monitoring.unit-card.transport.general.field.type.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.linked-tracker.label': 'Linked tracker',
    'monitoring.unit-card.transport.general.field.linked-tracker.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.company.label': 'Company',
    'monitoring.unit-card.transport.general.field.company.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.subdivision.label': 'Subdivision',
    'monitoring.unit-card.transport.general.field.subdivision.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.driver-name.label': 'Driver',
    'monitoring.unit-card.transport.general.field.driver-name.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.color.label': 'Vehicle’s color',
    'monitoring.unit-card.transport.general.field.color.placeholder': 'Select from the list',
    'monitoring.unit-card.transport.general.field.carrying-capacity.label': 'Carrying capacity',
    'monitoring.unit-card.transport.general.field.carrying-capacity.placeholder': 'Enter carrying capacity',
    'monitoring.unit-card.transport.general.field.fuel-type.label': 'Fuel type',
    'monitoring.unit-card.transport.general.field.fuel-type.placeholder': 'Select from the list',

    'monitoring.unit-card.additional-fields.add-btn.label': 'Add a field',
    'monitoring.unit-card.additional-fields.delete-btn.label': 'Delete',
    'monitoring.unit-card.additional-fields.field.label': 'Field’s name',
    'monitoring.unit-card.additional-fields.field.placeholder': 'Field’s value',

    'monitoring.unit-card.footer.btn.delete.label': 'Delete',
    'monitoring.unit-card.footer.btn.cancel.label': 'Cancel',
    'monitoring.unit-card.footer.btn.update.label': 'Update',
    'monitoring.unit-card.footer.btn.create.label': 'Create',
    'monitoring.unit-card.footer.btn.save.label': 'Save',

    'monitoring.unit-card.close-alert.header.label': 'Closing the window',
    'monitoring.unit-card.close-alert.warning.text': 'There are unsaved data on the form.',

    'monitoring.unit-card.additional-fields-alert.header.label': 'Deleting a field',
    'monitoring.unit-card.additional-fields-alert.text':
      'Deleting a field will delete the information contained within the field, the data cannot be restored.',

    'monitoring.unit-card.delete-employee-alert.header.label': 'Deleting an employee',
    'monitoring.unit-card.delete-employee-alert.warning.text': 'Warning!',
    'monitoring.unit-card.delete-employee-alert.warning-desc.text':
      'Deleting an employee card cannot be canceled, data cannot be restored.',
    'monitoring.unit-card.delete-employee-alert.question.text': 'Are you sure you want to continue?',
    'monitoring.unit-card.delete-employee-alert.btn.delete.label': 'Delete',
    'monitoring.unit-card.delete-employee-alert.btn.cancel.label': 'Cancel',

    'monitoring.unit-card.delete-transport-alert.header.label': 'Deleting a vehicle',
    'monitoring.unit-card.delete-transport-alert.warning.text': 'Warning!',
    'monitoring.unit-card.delete-transport-alert.warning-desc.text':
      'Deleting an transport card cannot be canceled, data cannot be restored.',
    'monitoring.unit-card.delete-transport-alert.question.text': 'Are you sure you want to continue?',
    'monitoring.unit-card.delete-transport-alert.btn.delete.label': 'Delete',
    'monitoring.unit-card.delete-transport-alert.btn.cancel.label': 'Cancel',

    'monitoring.manage-list-card.header.label': 'Configuring the list of objects',
    'monitoring.manage-list-card.filter.employee.text': 'Employees',
    'monitoring.manage-list-card.filter.vehicle.text': 'Vehicle',
    'monitoring.manage-list-card.obj.available.text': 'Objects available for adding',
    'monitoring.manage-list-card.obj.available.select-btn.label': 'Select all',
    'monitoring.manage-list-card.obj.chosen.text': 'Objects in the list',
    'monitoring.manage-list-card.obj.chosen.select-btn.label': 'Select all',
    'monitoring.manage-list-card.footer.btn.cancel.label': 'Cancel',
    'monitoring.manage-list-card.footer.btn.save.label': 'Save',
    'monitoring.manage-list-card.alert.title.text': 'Form changes',
    'monitoring.manage-list-card.alert.info.text':
      'There are unsaved data on the form. If canceled, they will not take effect.',

    'monitoring.list-group-modal.header.label': 'Grouping objects',
    'monitoring.list-group-modal.footer.btn.cancel.label': 'Cancel',
    'monitoring.list-group-modal.footer.btn.apply.label': 'Apply',
    'monitoring.list-group-modal.list-config.company.text': 'By organization',
    'monitoring.list-group-modal.list-config.company.empty.text': 'Without organization',
    'monitoring.list-group-modal.list-config.subdivision.text': 'By subdivision',
    'monitoring.list-group-modal.list-config.subdivision.empty.text': 'Without subdivision',
    'monitoring.list-group-modal.list-config.position.text': 'By position',
    'monitoring.list-group-modal.list-config.position.empty.text': 'Without position',

    'monitoring.cluster.empty-data.text': 'No data',

    'monitoring.tab-statistic.table.header.date.text': 'Date',
    'monitoring.tab-statistic.table.header.time.text': 'Time',
    'monitoring.tab-statistic.table.header.event-type.text': 'Event type',
    'monitoring.tab-statistic.table.header.event-description.text': 'Event description',
  },
  ru: {
    'monitoring.button-block.drop-down-btn.label': 'Создать',
    'monitoring.button-block.drop-down-btn.value.employee.text': 'Сотрудник',
    'monitoring.button-block.drop-down-btn.value.vehicle.text': 'Транспорт',

    'monitoring.search.unit-type.transport.text': 'Транспорт',
    'monitoring.search.unit-type.employee.text': 'Сотрудник',

    'monitoring.units-list.header.name.label': 'Название',
    'monitoring.units-list.header.number.tooltip.text': 'Номер трекера',
    'monitoring.units-list.header.manage-list.tooltip.text': 'Управление списком',
    'monitoring.units-list.header.follow-obj.tooltip.text': 'Следить за всеми объектами',
    'monitoring.units-list.header.connect-status.tooltip.text': 'Статус подключения к сети',
    'monitoring.units-list.header.signal-quality.tooltip.text': 'Уровень сигнала',
    'monitoring.units-list.header.battery-level.tooltip.text': 'Уровень заряда АКБ',
    'monitoring.units-list.header.list-group.tooltip.text': 'Группировка списка',

    'monitoring.units-list.unit.settings.tooltip.text': 'Изменение объекта',
    'monitoring.units-list.unit.follow.tooltip.text': 'Следить за объектом',

    'monitoring.unit-card.header.object.label': 'Карточка объекта',
    'monitoring.unit-card.header.employee.label': 'Карточка сотрудника',
    'monitoring.unit-card.header.transport.label': 'Карточка транспорта',

    'monitoring.unit-card.tab.general-info.label': 'Основная информация',
    'monitoring.unit-card.tab.additional-fields.label': 'Дополнительные поля',
    'monitoring.unit-card.tab.statistic.label': 'Статистика объекта',

    'monitoring.unit-card.employee.general.field.surname.label': 'Фамилия',
    'monitoring.unit-card.employee.general.field.surname.placeholder': 'Фамилия сотрудника',
    'monitoring.unit-card.employee.general.field.name.label': 'Имя',
    'monitoring.unit-card.employee.general.field.name.placeholder': 'Имя сотрудника',
    'monitoring.unit-card.employee.general.field.patronymic.label': 'Отчество',
    'monitoring.unit-card.employee.general.field.patronymic.placeholder': 'Отчество сотрудника',
    'monitoring.unit-card.employee.general.field.phone-number.label': 'Номер телефона сотрудника',
    'monitoring.unit-card.employee.general.field.phone-number.placeholder': 'Формат ввода +7ХХХХХХХХХХ',
    'monitoring.unit-card.employee.general.field.pass-number.label': 'Номер пропуска',
    'monitoring.unit-card.employee.general.field.pass-number.placeholder': 'Номер пропуска сотрудника',
    'monitoring.unit-card.employee.general.field.linked-tracker.label': 'Привязанный трекер',
    'monitoring.unit-card.employee.general.field.linked-tracker.placeholder': 'Выберите из списка',
    'monitoring.unit-card.employee.general.field.company.label': 'Организация',
    'monitoring.unit-card.employee.general.field.company.placeholder': 'Выберите из списка',
    'monitoring.unit-card.employee.general.field.subdivision.label': 'Структурное подразделение',
    'monitoring.unit-card.employee.general.field.subdivision.placeholder': 'Выберите из списка',
    'monitoring.unit-card.employee.general.field.position.label': 'Должность',
    'monitoring.unit-card.employee.general.field.position.placeholder': 'Выберите из списка',
    'monitoring.unit-card.employee.general.field.work-area.label': 'Объект выполнения работ',
    'monitoring.unit-card.employee.general.field.work-area.placeholder': 'Выберите из списка',
    'monitoring.unit-card.employee.general.field.pass-start-date.label': 'Дата начала действия пропуска',
    'monitoring.unit-card.employee.general.field.pass-start-date.placeholder': 'Выберите дату',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.label': 'Дата окончания действия пропуска',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder': 'Выберите дату',

    'monitoring.unit-card.transport.general.field.brand.label': 'Марка ТС',
    'monitoring.unit-card.transport.general.field.brand.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.model.label': 'Модель ТС',
    'monitoring.unit-card.transport.general.field.model.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.registration-number.label': 'Регистрационный номер ТС',
    'monitoring.unit-card.transport.general.field.registration-number.placeholder': 'Введите регистрационный номер ТС',
    'monitoring.unit-card.transport.general.field.model-year.label': 'Год выпуска ТС',
    'monitoring.unit-card.transport.general.field.model-year.placeholder': 'Введите год выпуска ТС',
    'monitoring.unit-card.transport.general.field.type.label': 'Тип ТС',
    'monitoring.unit-card.transport.general.field.type.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.linked-tracker.label': 'Привязанный трекер',
    'monitoring.unit-card.transport.general.field.linked-tracker.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.company.label': 'Организация',
    'monitoring.unit-card.transport.general.field.company.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.subdivision.label': 'Структурное подразделение',
    'monitoring.unit-card.transport.general.field.subdivision.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.driver-name.label': 'ФИО водителя ТС',
    'monitoring.unit-card.transport.general.field.driver-name.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.color.label': 'Цвет ТС',
    'monitoring.unit-card.transport.general.field.color.placeholder': 'Выберите из списка',
    'monitoring.unit-card.transport.general.field.carrying-capacity.label': 'Грузоподъемность ТС',
    'monitoring.unit-card.transport.general.field.carrying-capacity.placeholder': 'Введите грузоподъемность ТС',
    'monitoring.unit-card.transport.general.field.fuel-type.label': 'Вид топлива ТС',
    'monitoring.unit-card.transport.general.field.fuel-type.placeholder': 'Выберите из списка',

    'monitoring.unit-card.additional-fields.add-btn.label': 'Добавить поле',
    'monitoring.unit-card.additional-fields.delete-btn.label': 'Удалить поле',
    'monitoring.unit-card.additional-fields.field.label': 'Название поля',
    'monitoring.unit-card.additional-fields.field.placeholder': 'Значение поля',

    'monitoring.unit-card.footer.btn.delete.label': 'Удалить',
    'monitoring.unit-card.footer.btn.cancel.label': 'Отмена',
    'monitoring.unit-card.footer.btn.update.label': 'Обновить',
    'monitoring.unit-card.footer.btn.create.label': 'Создать',
    'monitoring.unit-card.footer.btn.save.label': 'Сохранить',

    'monitoring.unit-card.close-alert.header.label': 'Закрытие окна',
    'monitoring.unit-card.close-alert.warning.text': 'На форме остались не сохранённые данные.',

    'monitoring.unit-card.additional-fields-alert.header.label': 'Удаление поля',
    'monitoring.unit-card.additional-fields-alert.text':
      'Удаление поля повлечет удаление информации, которая содержится внутри поля, данные не смогут быть восстановлены.',

    'monitoring.unit-card.delete-employee-alert.header.label': 'Удаление карточки сотрудника',
    'monitoring.unit-card.delete-employee-alert.warning.text': 'Внимание!',
    'monitoring.unit-card.delete-employee-alert.warning-desc.text':
      'Удаление карточки сотрудника не может быть отменено, данные не смогут быть восстановлены.',
    'monitoring.unit-card.delete-employee-alert.question.text': 'Вы действительно хотите продолжить?',
    'monitoring.unit-card.delete-employee-alert.btn.delete.label': 'Удалить',
    'monitoring.unit-card.delete-employee-alert.btn.cancel.label': 'Отмена',

    'monitoring.unit-card.delete-transport-alert.header.label': 'Удаление карточки транспорта',
    'monitoring.unit-card.delete-transport-alert.warning.text': 'Внимание!',
    'monitoring.unit-card.delete-transport-alert.warning-desc.text':
      'Удаление карточки транспорта не сможет быть отменено, данные не смогут быть восстановлены.',
    'monitoring.unit-card.delete-transport-alert.question.text': 'Вы действительно хотите продолжить?',
    'monitoring.unit-card.delete-transport-alert.btn.delete.label': 'Удалить',
    'monitoring.unit-card.delete-transport-alert.btn.cancel.label': 'Отмена',

    'monitoring.manage-list-card.header.label': 'Настройка списка объектов',
    'monitoring.manage-list-card.filter.employee.text': 'Сотрудники',
    'monitoring.manage-list-card.filter.vehicle.text': 'Транспорт',
    'monitoring.manage-list-card.obj.available.text': 'Объекты, доступные для добавления',
    'monitoring.manage-list-card.obj.available.select-btn.label': 'Выделить все',
    'monitoring.manage-list-card.obj.chosen.text': 'Объекты в списке',
    'monitoring.manage-list-card.obj.chosen.select-btn.label': 'Выделить все',
    'monitoring.manage-list-card.footer.btn.cancel.label': 'Отмена',
    'monitoring.manage-list-card.footer.btn.save.label': 'Сохранить',
    'monitoring.manage-list-card.alert.title.text': 'Изменения на форме',
    'monitoring.manage-list-card.alert.info.text':
      'На форме остались несохранённые данные. В случае отмены, они не вступят в силу.',

    'monitoring.list-group-modal.header.label': 'Группировка объектов',
    'monitoring.list-group-modal.footer.btn.cancel.label': 'Отмена',
    'monitoring.list-group-modal.footer.btn.apply.label': 'Применить',
    'monitoring.list-group-modal.list-config.company.text': 'По организациям',
    'monitoring.list-group-modal.list-config.company.empty.text': 'Без организации',
    'monitoring.list-group-modal.list-config.subdivision.text': 'По подразделениям',
    'monitoring.list-group-modal.list-config.subdivision.empty.text': 'Без подразделения',
    'monitoring.list-group-modal.list-config.position.text': 'По должностям',
    'monitoring.list-group-modal.list-config.position.empty.text': 'Без должности',

    'monitoring.cluster.empty-data.text': 'Нет данных',

    'monitoring.tab-statistic.table.header.date.text': 'Дата',
    'monitoring.tab-statistic.table.header.time.text': 'Время',
    'monitoring.tab-statistic.table.header.event-type.text': 'Тип события',
    'monitoring.tab-statistic.table.header.event-description.text': 'Описание события',
  },
  zh: {
    'monitoring.button-block.drop-down-btn.label': '创建',
    'monitoring.button-block.drop-down-btn.value.employee.text': '+员工',
    'monitoring.button-block.drop-down-btn.value.vehicle.text': '+车辆',
    'monitoring.search.unit-type.transport.text': '员工',
    'monitoring.search.unit-type.employee.text': '车辆',
    'monitoring.units-list.header.name.label': '名称',
    'monitoring.units-list.header.number.tooltip.text': '跟踪器号码',
    'monitoring.units-list.header.manage-list.tooltip.text': '列表管理',
    'monitoring.units-list.header.follow-obj.tooltip.text': '跟踪所有对象',
    'monitoring.units-list.header.connect-status.tooltip.text': '网络连接状态',
    'monitoring.units-list.header.signal-quality.tooltip.text': '信号强度',
    'monitoring.units-list.header.battery-level.tooltip.text': '蓄电池电量',
    'monitoring.units-list.header.list-group.tooltip.text': '分组列表',
    'monitoring.units-list.unit.settings.tooltip.text': '更改对象',
    'monitoring.units-list.unit.follow.tooltip.text': '跟随对象',
    'monitoring.unit-card.header.employee.label': '员工卡',
    'monitoring.unit-card.header.object.label': '對象卡',
    'monitoring.unit-card.header.transport.label': '交通卡',
    'monitoring.unit-card.tab.general-info.label': '基本信息',
    'monitoring.unit-card.tab.additional-fields.label': '其它领域',
    'monitoring.unit-card.tab.statistic.label': '对象统计',
    'monitoring.unit-card.employee.general.field.surname.label': '姓',
    'monitoring.unit-card.employee.general.field.surname.placeholder': '员工姓',
    'monitoring.unit-card.employee.general.field.name.label': '名',
    'monitoring.unit-card.employee.general.field.name.placeholder': '员工名',
    'monitoring.unit-card.employee.general.field.patronymic.label': '父称',
    'monitoring.unit-card.employee.general.field.patronymic.placeholder': '员工父称',
    'monitoring.unit-card.employee.general.field.phone-number.label': '员工电话',
    'monitoring.unit-card.employee.general.field.phone-number.placeholder': '输入格式+7XXXXXXXXXX',
    'monitoring.unit-card.employee.general.field.pass-number.label': '通行证编号',
    'monitoring.unit-card.employee.general.field.pass-number.placeholder': '员工通行证编号',
    'monitoring.unit-card.employee.general.field.linked-tracker.label': '连接的跟踪器',
    'monitoring.unit-card.employee.general.field.linked-tracker.placeholder': '从列表中选择',
    'monitoring.unit-card.employee.general.field.company.label': '单位',
    'monitoring.unit-card.employee.general.field.company.placeholder': '从列表中选择',
    'monitoring.unit-card.employee.general.field.subdivision.label': '下属部门',
    'monitoring.unit-card.employee.general.field.subdivision.placeholder': '从列表中选择',
    'monitoring.unit-card.employee.general.field.position.label': '职位',
    'monitoring.unit-card.employee.general.field.position.placeholder': '从列表中选择',
    'monitoring.unit-card.employee.general.field.work-area.label': '工作执行对象',
    'monitoring.unit-card.employee.general.field.work-area.placeholder': '从列表中选择',
    'monitoring.unit-card.employee.general.field.pass-start-date.label': '通行证生效日期',
    'monitoring.unit-card.employee.general.field.pass-start-date.placeholder': '选择日期',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.label': '通行证有效期截止日期',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder': '选择日期',
    'monitoring.unit-card.transport.general.field.brand.label': '车辆品牌',
    'monitoring.unit-card.transport.general.field.brand.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.model.label': '车型',
    'monitoring.unit-card.transport.general.field.model.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.registration-number.label': '车辆登记号码',
    'monitoring.unit-card.transport.general.field.registration-number.placeholder': '输入号码',
    'monitoring.unit-card.transport.general.field.model-year.label': '车辆出厂年份',
    'monitoring.unit-card.transport.general.field.model-year.placeholder': '输入出厂年份',
    'monitoring.unit-card.transport.general.field.type.label': '车辆类型',
    'monitoring.unit-card.transport.general.field.type.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.linked-tracker.label': '连接的跟踪器',
    'monitoring.unit-card.transport.general.field.linked-tracker.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.company.label': '单位',
    'monitoring.unit-card.transport.general.field.company.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.subdivision.label': '下属部门',
    'monitoring.unit-card.transport.general.field.subdivision.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.driver-name.label': '车辆驾驶员姓名',
    'monitoring.unit-card.transport.general.field.driver-name.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.color.label': '车辆颜色',
    'monitoring.unit-card.transport.general.field.color.placeholder': '从列表中选择',
    'monitoring.unit-card.transport.general.field.carrying-capacity.label': '车辆载重量',
    'monitoring.unit-card.transport.general.field.carrying-capacity.placeholder': '输入载重量',
    'monitoring.unit-card.transport.general.field.fuel-type.label': '车辆燃料类型',
    'monitoring.unit-card.transport.general.field.fuel-type.placeholder': '从列表中选择',
    'monitoring.unit-card.additional-fields.add-btn.label': '新增字段',
    'monitoring.unit-card.additional-fields.delete-btn.label': '删除字段',
    'monitoring.unit-card.additional-fields.field.label': '字段名称',
    'monitoring.unit-card.additional-fields.field.placeholder': '字段值',
    'monitoring.unit-card.footer.btn.delete.label': '删除',
    'monitoring.unit-card.footer.btn.cancel.label': '取消',
    'monitoring.unit-card.footer.btn.update.label': '更新',
    'monitoring.unit-card.footer.btn.create.label': '创建',
    'monitoring.unit-card.footer.btn.save.label': '保存',
    'monitoring.unit-card.close-alert.header.label': '窗口关闭',
    'monitoring.unit-card.close-alert.warning.text': '表单上有未保存的数据。',
    'monitoring.unit-card.additional-fields-alert.header.label': '删除字段',
    'monitoring.unit-card.additional-fields-alert.text': '删除字段将导致删除该字段中包含的信息，数据无法恢复。',
    'monitoring.unit-card.delete-employee-alert.header.label': '删除员工卡',
    'monitoring.unit-card.delete-employee-alert.warning.text': '注意！',
    'monitoring.unit-card.delete-employee-alert.warning-desc.text': '员工卡的删除无法取消，数据无法恢复。',
    'monitoring.unit-card.delete-employee-alert.question.text': '您确定要继续吗？',
    'monitoring.unit-card.delete-employee-alert.btn.delete.label': '删除',
    'monitoring.unit-card.delete-employee-alert.btn.cancel.label': '取消',
    'monitoring.unit-card.delete-transport-alert.header.label': '删除交通卡',
    'monitoring.unit-card.delete-transport-alert.warning.text': '注意！',
    'monitoring.unit-card.delete-transport-alert.warning-desc.text': '交通卡的删除无法取消，数据无法恢复。',
    'monitoring.unit-card.delete-transport-alert.question.text': '您确定要继续吗？',
    'monitoring.unit-card.delete-transport-alert.btn.delete.label': '删除',
    'monitoring.unit-card.delete-transport-alert.btn.cancel.label': '取消',
    'monitoring.manage-list-card.header.label': '设置对象列表',
    'monitoring.manage-list-card.filter.employee.text': '职员',
    'monitoring.manage-list-card.filter.vehicle.text': '车辆',
    'monitoring.manage-list-card.obj.available.text': '可添加的对象',
    'monitoring.manage-list-card.obj.available.select-btn.label': '全选',
    'monitoring.manage-list-card.obj.chosen.text': '列表中的对象',
    'monitoring.manage-list-card.obj.chosen.select-btn.label': '全选',
    'monitoring.manage-list-card.footer.btn.cancel.label': '取消',
    'monitoring.manage-list-card.footer.btn.save.label': '保存',
    'monitoring.manage-list-card.alert.title.text': '形式变化',
    'monitoring.manage-list-card.alert.info.text': '表单上有未保存的数据。',
    'monitoring.list-group-modal.header.label': '分组对象',
    'monitoring.list-group-modal.footer.btn.cancel.label': '取消',
    'monitoring.list-group-modal.footer.btn.apply.label': '申请',
    'monitoring.list-group-modal.list-config.company.text': '按组织',
    'monitoring.list-group-modal.list-config.company.empty.text': '没有组织',
    'monitoring.list-group-modal.list-config.subdivision.text': '按细分',
    'monitoring.list-group-modal.list-config.subdivision.empty.text': '无细分',
    'monitoring.list-group-modal.list-config.position.text': '按职位',
    'monitoring.list-group-modal.list-config.position.empty.text': '没有职位',
    'monitoring.cluster.empty-data.text': '无数据',
    'monitoring.tab-statistic.table.header.date.text': '数据',
    'monitoring.tab-statistic.table.header.time.text': '时间',
    'monitoring.tab-statistic.table.header.event-type.text': '事件种类',
    'monitoring.tab-statistic.table.header.event-description.text': '事件描述',
  },
  ar: {
    'monitoring.button-block.drop-down-btn.label': 'إنشاء',
    'monitoring.button-block.drop-down-btn.value.employee.text': '+موظف',
    'monitoring.button-block.drop-down-btn.value.vehicle.text': '+وسيلة نقل',
    'monitoring.search.unit-type.transport.text': 'موظف',
    'monitoring.search.unit-type.employee.text': 'وسيلة نقل',
    'monitoring.units-list.header.name.label': 'اسم',
    'monitoring.units-list.header.number.tooltip.text': 'اسم المتتبع',
    'monitoring.units-list.header.manage-list.tooltip.text': 'التحكم بالقائمة',
    'monitoring.units-list.header.follow-obj.tooltip.text': 'مراقبة كل الكائنات',
    'monitoring.units-list.header.connect-status.tooltip.text': 'حالة الاتصال بالشبكة',
    'monitoring.units-list.header.signal-quality.tooltip.text': 'قوة الإشارة',
    'monitoring.units-list.header.battery-level.tooltip.text': 'حالة شحن البطارية',
    'monitoring.units-list.header.list-group.tooltip.text': 'فرز القائمة',
    'monitoring.units-list.unit.settings.tooltip.text': 'تغيير الكائن',
    'monitoring.units-list.unit.follow.tooltip.text': 'مراقبة الكائن',
    'monitoring.unit-card.header.object.label': 'بطاقة الكائن',
    'monitoring.unit-card.header.employee.label': 'بطاقة موظف',
    'monitoring.unit-card.header.transport.label': 'بطاقة وسيلة النقل',
    'monitoring.unit-card.tab.general-info.label': 'معلومات عامة',
    'monitoring.unit-card.tab.additional-fields.label': 'خانات إضافية',
    'monitoring.unit-card.tab.statistic.label': 'إحصاء الكائن',
    'monitoring.unit-card.employee.general.field.surname.label': 'اللقب',
    'monitoring.unit-card.employee.general.field.surname.placeholder': 'لقب الموظف',
    'monitoring.unit-card.employee.general.field.name.label': 'اسم',
    'monitoring.unit-card.employee.general.field.name.placeholder': 'اسم الموظف',
    'monitoring.unit-card.employee.general.field.patronymic.label': 'اسم الأب',
    'monitoring.unit-card.employee.general.field.patronymic.placeholder': 'اسم أب الموظف',
    'monitoring.unit-card.employee.general.field.phone-number.label': 'رقم هاتف الموظف',
    'monitoring.unit-card.employee.general.field.phone-number.placeholder': 'صيغة الرقم +7XXXXXXXXXX',
    'monitoring.unit-card.employee.general.field.pass-number.label': 'رقم التصريح',
    'monitoring.unit-card.employee.general.field.pass-number.placeholder': 'رقم التصريح للموظف',
    'monitoring.unit-card.employee.general.field.linked-tracker.label': 'المتتبع المرتبط',
    'monitoring.unit-card.employee.general.field.linked-tracker.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.employee.general.field.company.label': 'مؤسسة',
    'monitoring.unit-card.employee.general.field.company.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.employee.general.field.subdivision.label': 'الشعبة',
    'monitoring.unit-card.employee.general.field.subdivision.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.employee.general.field.position.label': 'الوظيفة',
    'monitoring.unit-card.employee.general.field.position.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.employee.general.field.work-area.label': 'مكان العمل',
    'monitoring.unit-card.employee.general.field.work-area.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.employee.general.field.pass-start-date.label': 'تاريخ بدء سريان التصريح',
    'monitoring.unit-card.employee.general.field.pass-start-date.placeholder': 'اختر التاريخ',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.label': 'تاريخ انتهاء سريان التصريح',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder': 'اختر التاريخ',
    'monitoring.unit-card.transport.general.field.brand.label': 'طراز وسيلة النقل',
    'monitoring.unit-card.transport.general.field.brand.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.model.label': 'طراز وسيلة النقل',
    'monitoring.unit-card.transport.general.field.model.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.registration-number.label': 'الرقم التسجيلي لوسيلة النقل',
    'monitoring.unit-card.transport.general.field.registration-number.placeholder': 'أكتب رقم',
    'monitoring.unit-card.transport.general.field.model-year.label': 'سنة الإصدار',
    'monitoring.unit-card.transport.general.field.model-year.placeholder': 'أكتب سنة الإصدار',
    'monitoring.unit-card.transport.general.field.type.label': 'نوع وسيلة النقل',
    'monitoring.unit-card.transport.general.field.type.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.linked-tracker.label': 'المتتبع المرتبط',
    'monitoring.unit-card.transport.general.field.linked-tracker.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.company.label': 'مؤسسة',
    'monitoring.unit-card.transport.general.field.company.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.subdivision.label': 'الشعبة',
    'monitoring.unit-card.transport.general.field.subdivision.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.driver-name.label': 'الاسم الكامل لسائق وسيلة النقل',
    'monitoring.unit-card.transport.general.field.driver-name.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.color.label': 'لون وسيلة النقل',
    'monitoring.unit-card.transport.general.field.color.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.transport.general.field.carrying-capacity.label': 'سعة الحمولة',
    'monitoring.unit-card.transport.general.field.carrying-capacity.placeholder': 'أكتب سعة الحمولة',
    'monitoring.unit-card.transport.general.field.fuel-type.label': 'نوع الوقود',
    'monitoring.unit-card.transport.general.field.fuel-type.placeholder': 'اختر من القائمة',
    'monitoring.unit-card.additional-fields.add-btn.label': 'إضافة خانة',
    'monitoring.unit-card.additional-fields.delete-btn.label': 'حذف',
    'monitoring.unit-card.additional-fields.field.label': 'اسم الخانة',
    'monitoring.unit-card.additional-fields.field.placeholder': 'قيمة الخانة',
    'monitoring.unit-card.footer.btn.delete.label': 'حذف',
    'monitoring.unit-card.footer.btn.cancel.label': 'إلغاء',
    'monitoring.unit-card.footer.btn.update.label': 'تحديث',
    'monitoring.unit-card.footer.btn.create.label': 'إنشاء',
    'monitoring.unit-card.footer.btn.save.label': 'حفظ',
    'monitoring.unit-card.close-alert.header.label': 'إغلاق علامة التبويب',
    'monitoring.unit-card.close-alert.warning.text': 'يوجد بيانات غير محفوظة في النموذج. ',
    'monitoring.unit-card.additional-fields-alert.header.label': 'حذف الخانة',
    'monitoring.unit-card.additional-fields-alert.text': 'حذف الخانة يؤدي إلىحذف بيانات الخانة دون استرجاع.',
    'monitoring.unit-card.delete-employee-alert.header.label': 'حذف الموظف',
    'monitoring.unit-card.delete-employee-alert.warning.text': 'انتباه!',
    'monitoring.unit-card.delete-employee-alert.warning-desc.text':
      'لا يجوز التراجع عن حذف بطابة الموظف، ولا يمكن استرجاع البيانات.',
    'monitoring.unit-card.delete-employee-alert.question.text': 'هل حقًا ترغب بمتابعة؟',
    'monitoring.unit-card.delete-employee-alert.btn.delete.label': 'حذف',
    'monitoring.unit-card.delete-employee-alert.btn.cancel.label': 'إلغاء',
    'monitoring.unit-card.delete-transport-alert.header.label': 'حذف بطاقة وسيلة النقل',
    'monitoring.unit-card.delete-transport-alert.warning.text': 'انتباه!',
    'monitoring.unit-card.delete-transport-alert.warning-desc.text':
      'لا يجوز التراجع عن حذف بطاقة وسيلة النقل، ولا يمكن استرجاع البيانات ذات الصلة.',
    'monitoring.unit-card.delete-transport-alert.question.text': 'هل حقًا ترغب بمتابعة؟',
    'monitoring.unit-card.delete-transport-alert.btn.delete.label': 'حذف',
    'monitoring.unit-card.delete-transport-alert.btn.cancel.label': 'إلغاء',
    'monitoring.manage-list-card.header.label': 'ضبط قائمة الكائنات',
    'monitoring.manage-list-card.filter.employee.text': 'موظفون',
    'monitoring.manage-list-card.filter.vehicle.text': 'وسيلة نقل',
    'monitoring.manage-list-card.obj.available.text': 'كائنات متوفرة للإضافة',
    'monitoring.manage-list-card.obj.available.select-btn.label': 'تحديد الكل',
    'monitoring.manage-list-card.obj.chosen.text': 'الكائنات في القائمة',
    'monitoring.manage-list-card.obj.chosen.select-btn.label': 'تحديد الكل',
    'monitoring.manage-list-card.footer.btn.cancel.label': 'إلغاء',
    'monitoring.manage-list-card.footer.btn.save.label': 'حفظ',
    'monitoring.manage-list-card.alert.title.text': 'تغييرات في الشكل',
    'monitoring.manage-list-card.alert.info.text': 'يوجد بيانات غير محفوظة في النموذج. ',
    'monitoring.list-group-modal.header.label': 'فرز الكائنات',
    'monitoring.list-group-modal.footer.btn.cancel.label': 'إلغاء',
    'monitoring.list-group-modal.footer.btn.apply.label': 'تطبيق',
    'monitoring.list-group-modal.list-config.company.text': 'حسب مؤسسات',
    'monitoring.list-group-modal.list-config.company.empty.text': 'بدون مؤسسة',
    'monitoring.list-group-modal.list-config.subdivision.text': 'حسب شعب',
    'monitoring.list-group-modal.list-config.subdivision.empty.text': 'بدون شعب',
    'monitoring.list-group-modal.list-config.position.text': 'حسب وظائف',
    'monitoring.list-group-modal.list-config.position.empty.text': 'بدون وظيفة',
    'monitoring.cluster.empty-data.text': 'لا توجد بيانات',
    'monitoring.tab-statistic.table.header.date.text': 'التاريخ',
    'monitoring.tab-statistic.table.header.time.text': 'الوقت',
    'monitoring.tab-statistic.table.header.event-type.text': 'نوع الحدث',
    'monitoring.tab-statistic.table.header.event-description.text': 'وصف الحدث',
  },
  de: {
    'monitoring.button-block.drop-down-btn.label': 'Erstellen',
    'monitoring.button-block.drop-down-btn.value.employee.text': '+Mitarbeiter',
    'monitoring.button-block.drop-down-btn.value.vehicle.text': '+Transport',
    'monitoring.search.unit-type.transport.text': 'Mitarbeiter',
    'monitoring.search.unit-type.employee.text': 'Transport',
    'monitoring.units-list.header.name.label': 'Name',
    'monitoring.units-list.header.number.tooltip.text': 'Tracker-Nummer',
    'monitoring.units-list.header.manage-list.tooltip.text': 'Abonentennverwaltung',
    'monitoring.units-list.header.follow-obj.tooltip.text': 'Verfolgen Sie alle Objekte',
    'monitoring.units-list.header.connect-status.tooltip.text': 'Netzanschlußstatus',
    'monitoring.units-list.header.signal-quality.tooltip.text': 'Signalstärke',
    'monitoring.units-list.header.battery-level.tooltip.text': 'Batterieladezustand',
    'monitoring.units-list.header.list-group.tooltip.text': 'Listengruppierung',
    'monitoring.units-list.unit.settings.tooltip.text': 'Objekt ändern',
    'monitoring.units-list.unit.follow.tooltip.text': 'Objekt verfolgen',
    'monitoring.unit-card.header.object.label': 'Objektkarte',
    'monitoring.unit-card.header.employee.label': 'Mitarbeiterkarte',
    'monitoring.unit-card.header.transport.label': 'Transportkarte',
    'monitoring.unit-card.tab.general-info.label': 'Grundinformation',
    'monitoring.unit-card.tab.additional-fields.label': 'Weitere Felder',
    'monitoring.unit-card.tab.statistic.label': 'Objektstatistiken',
    'monitoring.unit-card.employee.general.field.surname.label': 'Familienname',
    'monitoring.unit-card.employee.general.field.surname.placeholder': 'Familienname des Mitarbeiters',
    'monitoring.unit-card.employee.general.field.name.label': 'Name',
    'monitoring.unit-card.employee.general.field.name.placeholder': 'Mitarbeitername',
    'monitoring.unit-card.employee.general.field.patronymic.label': 'Vatersname',
    'monitoring.unit-card.employee.general.field.patronymic.placeholder': 'Mitarbeitervatersname',
    'monitoring.unit-card.employee.general.field.phone-number.label': 'Telefonnummer des Mitarbeiters',
    'monitoring.unit-card.employee.general.field.phone-number.placeholder': 'Eingabeformat + 7XXXXXXXXXX',
    'monitoring.unit-card.employee.general.field.pass-number.label': 'Passnummer',
    'monitoring.unit-card.employee.general.field.pass-number.placeholder': 'Mitarbeiterausweisnummer',
    'monitoring.unit-card.employee.general.field.linked-tracker.label': 'Gebundener Tracker',
    'monitoring.unit-card.employee.general.field.linked-tracker.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.employee.general.field.company.label': 'Organisation',
    'monitoring.unit-card.employee.general.field.company.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.employee.general.field.subdivision.label': 'Strukturelle Niederlassung',
    'monitoring.unit-card.employee.general.field.subdivision.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.employee.general.field.position.label': 'Dienststelle',
    'monitoring.unit-card.employee.general.field.position.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.employee.general.field.work-area.label': 'Gegenstand der Arbeit',
    'monitoring.unit-card.employee.general.field.work-area.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.employee.general.field.pass-start-date.label': 'Startdatum des Passes',
    'monitoring.unit-card.employee.general.field.pass-start-date.placeholder': 'Datum wählen',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.label': 'Ablaufdatum des Passes',
    'monitoring.unit-card.employee.general.field.pass-expiration-date.placeholder': 'Datum wählen',
    'monitoring.unit-card.transport.general.field.brand.label': 'Fahrzeugmarke',
    'monitoring.unit-card.transport.general.field.brand.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.model.label': 'Fahrzeugmodell',
    'monitoring.unit-card.transport.general.field.model.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.registration-number.label': 'Kfz-Kennzeichen',
    'monitoring.unit-card.transport.general.field.registration-number.placeholder': 'Geben Sie die Nummer ein',
    'monitoring.unit-card.transport.general.field.model-year.label': 'Herstellungsjahr des Fahrzeugs',
    'monitoring.unit-card.transport.general.field.model-year.placeholder': 'Geben Sie das Herstellungsjahr ein',
    'monitoring.unit-card.transport.general.field.type.label': 'Fahrzeugtyp',
    'monitoring.unit-card.transport.general.field.type.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.linked-tracker.label': 'Gebundener Tracker',
    'monitoring.unit-card.transport.general.field.linked-tracker.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.company.label': 'Organisation',
    'monitoring.unit-card.transport.general.field.company.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.subdivision.label': 'Strukturelle Niederlassung',
    'monitoring.unit-card.transport.general.field.subdivision.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.driver-name.label': 'Vollständiger Name des Fahrzeugführers',
    'monitoring.unit-card.transport.general.field.driver-name.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.color.label': 'Fahrzeugfarbe',
    'monitoring.unit-card.transport.general.field.color.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.transport.general.field.carrying-capacity.label': 'Tragfähigkeit',
    'monitoring.unit-card.transport.general.field.carrying-capacity.placeholder': 'Hebekapazität eingeben',
    'monitoring.unit-card.transport.general.field.fuel-type.label': 'Fahrzeugtreibstoffart',
    'monitoring.unit-card.transport.general.field.fuel-type.placeholder': 'Aus der Liste wählen',
    'monitoring.unit-card.additional-fields.add-btn.label': 'Feld hinzufügen',
    'monitoring.unit-card.additional-fields.delete-btn.label': 'Feld löschen',
    'monitoring.unit-card.additional-fields.field.label': 'Feldname',
    'monitoring.unit-card.additional-fields.field.placeholder': 'Feldwert',
    'monitoring.unit-card.footer.btn.delete.label': 'Löschen',
    'monitoring.unit-card.footer.btn.cancel.label': 'Zurücksetzen',
    'monitoring.unit-card.footer.btn.update.label': 'Aktualisieren',
    'monitoring.unit-card.footer.btn.create.label': 'Erstellen',
    'monitoring.unit-card.footer.btn.save.label': 'Speichern',
    'monitoring.unit-card.close-alert.header.label': 'Fenster schließen',
    'monitoring.unit-card.close-alert.warning.text': 'Das Formular enthält nicht gespeicherte Daten. ',
    'monitoring.unit-card.additional-fields-alert.header.label': 'Feld entfernen',
    'monitoring.unit-card.additional-fields-alert.text':
      'Das Entfernen eines Feldes führt zum Löschen der im Feld enthaltenen Informationen. Die Daten können nicht wiederhergestellt werden.',
    'monitoring.unit-card.delete-employee-alert.header.label': 'Löschen einer Mitarbeiterkarte',
    'monitoring.unit-card.delete-employee-alert.warning.text': 'Beachtung!',
    'monitoring.unit-card.delete-employee-alert.warning-desc.text':
      'Das Löschen der Karte des Mitarbeiters kann nicht rückgängig gemacht werden, Daten können nicht wiederhergestellt werden.',
    'monitoring.unit-card.delete-employee-alert.question.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'monitoring.unit-card.delete-employee-alert.btn.delete.label': 'Löschen',
    'monitoring.unit-card.delete-employee-alert.btn.cancel.label': 'Zurücksetzen',
    'monitoring.unit-card.delete-transport-alert.header.label': 'Transportkarte entfernen',
    'monitoring.unit-card.delete-transport-alert.warning.text': 'Beachtung!',
    'monitoring.unit-card.delete-transport-alert.warning-desc.text':
      'Das Löschen der Transportkarte kann nicht rückgängig gemacht werden, die Daten können nicht wiederhergestellt werden.',
    'monitoring.unit-card.delete-transport-alert.question.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'monitoring.unit-card.delete-transport-alert.btn.delete.label': 'Löschen',
    'monitoring.unit-card.delete-transport-alert.btn.cancel.label': 'Zurücksetzen',
    'monitoring.manage-list-card.header.label': 'Einrichten der Objektliste',
    'monitoring.manage-list-card.filter.employee.text': 'Mitarbeiter',
    'monitoring.manage-list-card.filter.vehicle.text': 'Transport',
    'monitoring.manage-list-card.obj.available.text': 'Zum Hinzufügen verfügbare Objekte',
    'monitoring.manage-list-card.obj.available.select-btn.label': 'Wählen Sie Alle',
    'monitoring.manage-list-card.obj.chosen.text': 'Objekte in der Liste',
    'monitoring.manage-list-card.obj.chosen.select-btn.label': 'Wählen Sie Alle',
    'monitoring.manage-list-card.footer.btn.cancel.label': 'Zurücksetzen',
    'monitoring.manage-list-card.footer.btn.save.label': 'Speichern',
    'monitoring.manage-list-card.alert.title.text': 'Formularänderungen',
    'monitoring.manage-list-card.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten. ',
    'monitoring.list-group-modal.header.label': 'Objektgruppierung',
    'monitoring.list-group-modal.footer.btn.cancel.label': 'Zurücksetzen',
    'monitoring.list-group-modal.footer.btn.apply.label': 'Anwenden',
    'monitoring.list-group-modal.list-config.company.text': 'Nach Organisationen',
    'monitoring.list-group-modal.list-config.company.empty.text': 'Ohne Organisation',
    'monitoring.list-group-modal.list-config.subdivision.text': 'Nach Unterteilungen',
    'monitoring.list-group-modal.list-config.subdivision.empty.text': 'Ohne Unterteilung',
    'monitoring.list-group-modal.list-config.position.text': 'Nach Positionen',
    'monitoring.list-group-modal.list-config.position.empty.text': 'Ohne Stelle',
    'monitoring.cluster.empty-data.text': 'Keine Daten',
    'monitoring.tab-statistic.table.header.date.text': 'Datum',
    'monitoring.tab-statistic.table.header.time.text': 'Zeit',
    'monitoring.tab-statistic.table.header.event-type.text': 'Ereignisart',
    'monitoring.tab-statistic.table.header.event-description.text': 'Ereignisbeschreibung',
  },
};
