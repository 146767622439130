import React from 'react';
import styles from './checkbox.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface CheckboxProps {
  handleCheck?: () => void;
  checked?: boolean;
  isIndeterminate?: boolean;
  isNegative?: boolean;
  isDisabled?: boolean;
  cursorDefault?: boolean;
  wrapperStyle?: string;
  'data-cy'?: string;
}

export const Checkbox = ({
  handleCheck,
  checked,
  isIndeterminate,
  isNegative,
  cursorDefault,
  isDisabled,
  wrapperStyle,
  'data-cy': dataCy,
}: CheckboxProps) => {
  const setCheckboxRef = (checkbox: HTMLInputElement) => {
    if (checkbox) {
      checkbox.indeterminate = isIndeterminate ?? false;
    }
  };

  const onCheck = () => {
    if (!isDisabled && handleCheck) {
      handleCheck();
    }
  };

  const inputClasses = cx({
    [styles.checkboxInput]: !isNegative,
    [styles.negative]: isNegative,
    [styles.defaultCursor]: cursorDefault,
    [styles.disabled]: isDisabled,
  });

  return (
    <div className={cx(styles.checkbox, wrapperStyle)} onClick={onCheck}>
      <input
        data-cy={dataCy}
        className={inputClasses}
        type="checkbox"
        checked={checked ?? false}
        ref={setCheckboxRef}
        readOnly
      />
      <span className={styles.checkboxSpan} />
    </div>
  );
};
