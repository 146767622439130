import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useHistory } from 'react-router';
import { ReactComponent as Logo } from 'assets/img/visitech_logo.svg';
import { ReactComponent as PhoneIcon } from 'assets/img/auth/phone.svg';

import { RootState } from 'reducers';
import { fetchLogin } from 'reducers/auth';
import { setObjectFromGrafana } from 'reducers/map';
import { toggleSelectUnit, toggleWatchUnit } from 'reducers/monitoring';
import { setInterfaceLanguage, updateUserPreferences } from 'reducers/user';

import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';
import Button from 'components/common/button/button';
import Select from 'components/common/select/select';

import { getQS } from 'utils/getQS';
import { LANGUAGES_TO_TEXT_ENUM, LANGUAGES_TO_TEXT_TRANSLATES_ENUM } from 'translate';
import styles from './authLogin.module.scss';
import { BUILD_VERSION } from '../../../utils/consts';

export function AuthLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useFormatMessage();

  const { isAuth } = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.user);

  const interfaceLanguageForSelect = Object.keys(LANGUAGES_TO_TEXT_ENUM).map(lngKey => ({
    value: lngKey,
    label: LANGUAGES_TO_TEXT_TRANSLATES_ENUM[lngKey as keyof typeof LANGUAGES_TO_TEXT_TRANSLATES_ENUM],
  }));

  const [password, changePass] = useState('');
  const [username, changeUsername] = useState('');
  const [shouldRemember, setRemember] = useState(false);
  const [hidePass, setHidePass] = useState(true);

  useEffect(() => {
    const object = getQS('object');
    if (object) {
      const id = parseInt(object) + 2;
      dispatch(setObjectFromGrafana(id));
      dispatch(toggleSelectUnit({ id: String(id), currentStatus: false }));
      dispatch(toggleWatchUnit({ id: String(id), currentStatus: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuth && !user.userPreferences.userId) {
      (async () => {
        await dispatch(updateUserPreferences({ locale: user.userPreferences.locale }));
        history.push('/main');
      })();
    }
  }, [dispatch, history, isAuth, user]);

  const loginF = () => {
    if (password && username) {
      dispatch(
        fetchLogin({
          username,
          password,
        })
      );
      if (user.userPreferences.locale) {
        window.localStorage.setItem('locale', user.userPreferences.locale);
      }
    }
  };

  const loginOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && password && username) {
      dispatch(
        fetchLogin({
          username,
          password,
        })
      );
      if (user.userPreferences.locale) {
        window.localStorage.setItem('locale', user.userPreferences.locale);
      }
    }
  };

  const handleChangeLng = (value: string) => {
    const foundLngOption = interfaceLanguageForSelect.find(option => option.value === value);

    if (foundLngOption) {
      dispatch(
        setInterfaceLanguage(LANGUAGES_TO_TEXT_ENUM[foundLngOption.value as keyof typeof LANGUAGES_TO_TEXT_ENUM])
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <div className={styles.logoWrapper}>
            <Logo />
            <div className={styles.logoText}>
              <span className={styles.logoTextIsobr}>{user.userPreferences.locale === 'ru' ? 'ИСОБР' : 'ISSOW'}</span>
              <span className={styles.logoTextGis}>Visitech GIS</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.formLayout}>
        <div className={styles.form}>
          <div className={styles.formHeader}>{t('common-info.authorization.login.header.label')}</div>
          <div className={styles.formBody}>
            <Input
              data-cy="test_authorization_name_input"
              label={t('common-info.authorization.field.user-name.label')}
              placeholder={t('common-info.authorization.field.user-name.placeholder')}
              value={username}
              handleInputChange={val => changeUsername(val)}
              onKeyDown={loginOnKeyDown}
            />
            <Input
              data-cy="test_authorization_password_input"
              label={t('common-info.authorization.field.password.label')}
              placeholder={t('common-info.authorization.field.password.placeholder')}
              value={password}
              handleInputChange={val => changePass(val)}
              onKeyDown={loginOnKeyDown}
              isPassword={hidePass}
              withHideTextIcon
              iconClick={() => setHidePass(!hidePass)}
            />
            <Select
              options={interfaceLanguageForSelect}
              label={t('common-info.authorization.field.lng.label')}
              placeholder={''}
              withSearchIcon
              value={
                interfaceLanguageForSelect.find(option => option.value === user.userPreferences.locale)?.value ?? 0
              }
              isRequired={false}
              handleChange={value => handleChangeLng(value)}
              withoutCloseIcon
            />
            <div className={styles.formBodyCheckbox} onClick={() => setRemember(!shouldRemember)}>
              <Checkbox
                data-cy="test_authorization_remember_checkbox"
                checked={shouldRemember}
                handleCheck={() => setRemember(!shouldRemember)}
                wrapperStyle={styles.formCheckboxWrappedStyle}
              />
              <div className={styles.formBodyTextWrapper}>
                {t('common-info.authorization.checkbox.remember-me.text')}
              </div>
            </div>
            <Button
              data-cy="test_authorization_login_button"
              onClick={loginF}
              text={t('common-info.authorization.btn.text')}
              blue
            />
            <div className={styles.formBodyRef}>{t('common-info.authorization.link.cant-login.text')}</div>
          </div>
          <div className={styles.formFooter}>
            <div className={styles.formFooterIcon}>
              <PhoneIcon />
            </div>
            <div className={styles.formFooterInfo}>
              <a href="tel:88008008080">8-800-800-80-80</a>
              <a
                href={`mailto:helpdesk@visitech.ru?subject=${t(
                  'common-info.authorization.link.cant-login.subject.text'
                )}`}
              >
                helpdesk@visitech.ru
              </a>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.version}>
            <span>{`v: ${BUILD_VERSION}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
