import React from 'react';

import SingleOperationAccessView from './singleOperationAccessView';

export type AccessEntitiesViewData = {
  canRead: string[];
  canCreate: string[];
  canUpdate: string[];
  canDelete: string[];
};

type Props = {
  data: AccessEntitiesViewData;
};

const AccessEntitiesView: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SingleOperationAccessView data={data.canRead} operation="read" />
      <SingleOperationAccessView data={data.canCreate} operation="create" />
      <SingleOperationAccessView data={data.canUpdate} operation="update" />
      <SingleOperationAccessView data={data.canDelete} operation="delete" />
    </>
  );
};

export default AccessEntitiesView;
