import { CreateReport } from 'reducers/records/interface';
import { RecordsMainTabStates, RecordsConditionsTabStates, ConditionsTabCheckBoxAdditionalFiltersType } from './types';

export enum ACTIVE_TABS {
  main = 'main',
  data = 'data',
  condition = 'condition',
}

export enum ALERT_CONTINUE_STATUS {
  change = 'change',
  delete = 'delete',
}

// main tab

export const defaultMainTabState: RecordsMainTabStates = {
  name: '',
  description: '',
  file: null,
  chosenUsers: [],
  chosenUsersForSelect: [],
};

// data tab

export const defaultCreateReportObjects: CreateReport = {
  toDate: '',
  fromDate: '',
  objects: [],
};

export enum REPORT_CHOSEN_DATA_SORTING_MODE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum REPORT_CHOSEN_DATA_TYPES {
  Scalar = 'Scalar',
  Arithmetic = 'Arithmetic',
}

export enum REPORT_TEMPLATE_DATA_GROUP {
  employees = 'Employees',
  transports = 'Transports',
  geozone = 'GeoZone',
  users = 'Users',
  trackers = 'Trackers',
  poipoints = 'POIPoints',
  notifications = 'Notifications',
  custom = 'Custom',
}

export const REPORT_TEMPLATE_DATA_GROUPS_TRANSLATE = {
  employees: 'records.card.template-create.data.available.group.employees.text',
  transports: 'records.card.template-create.data.available.group.transports.text',
  geozone: 'records.card.template-create.data.available.group.geozone.text',
  users: 'records.card.template-create.data.available.group.users.text',
  trackers: 'records.card.template-create.data.available.group.trackers.text',
  poipoints: 'records.card.template-create.data.available.group.poi.text',
  notifications: 'records.card.template-create.data.available.group.notifications.text',
  custom: 'records.card.template-create.data.available.group.custom.text',
};

export const REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE = {
  employees: {
    FullNameOfTheEmployee: 'records.card.template-create.data.available.employees.field.fio.text',
    GroupOfEmployees: 'records.card.template-create.data.available.employees.field.group.text',
    EmployeeOrganization: 'records.card.template-create.data.available.employees.field.organization.text',
    StructuralUnitOfAnEmployee: 'records.card.template-create.data.available.employees.field.department.text',
    EmployeeWorkExecutionObject: 'records.card.template-create.data.available.employees.field.work-obj.text',
    EmployeePosition: 'records.card.template-create.data.available.employees.field.position.text',
    EmployeePhoneNumber: 'records.card.template-create.data.available.employees.field.telephone.text',
    EmployeeBadgeNumber: 'records.card.template-create.data.available.employees.field.pass.text',
    EmployeePassStartDate: 'records.card.template-create.data.available.employees.field.pass_start.text',
    EmployeePassExpirationDate: 'records.card.template-create.data.available.employees.field.pass_end.text',
    AssociatedEmployeeTracker: 'records.card.template-create.data.available.employees.field.tracker.text',
    EmployeeCurrentCoordinates: 'records.card.template-create.data.available.employees.field.coords.text',
  },
  transports: {
    VehicleBrand: 'records.card.template-create.data.available.transports.field.brand.text',
    VehicleModel: 'records.card.template-create.data.available.transports.field.model.text',
    VehicleRegistrationNumber: 'records.card.template-create.data.available.transports.field.reg_number.text',
    YearOfVehicleProduction: 'records.card.template-create.data.available.transports.field.production.text',
    TransportGroup: 'records.card.template-create.data.available.transports.field.group.text',
    VehicleColor: 'records.card.template-create.data.available.transports.field.color.text',
    CarryingCapacity: 'records.card.template-create.data.available.transports.field.capacity.text',
    OrganizationOfTheVehicle: 'records.card.template-create.data.available.transports.field.organization.text',
    StructuralSubdivisionOfTheTs: 'records.card.template-create.data.available.transports.field.department.text',
    ObjectOfPerformanceOfWorkOfTheVehicle: 'records.card.template-create.data.available.transports.field.work-obj.text',
    FullNameOfTheVehicleDriver: 'records.card.template-create.data.available.transports.field.driver.text',
    VehicleFuelType: 'records.card.template-create.data.available.transports.field.fuel_type.text',
    LinkedTracker: 'records.card.template-create.data.available.transports.field.tracker.text',
    CurrentTransportCoordinates: 'records.card.template-create.data.available.transports.field.coords.text',
  },
  geozone: {
    GeofenceName: 'records.card.template-create.data.available.geofences.field.name.text',
    GeofenceGroup: 'records.card.template-create.data.available.geofences.field.group.text',
    GeofenceDescription: 'records.card.template-create.data.available.geofences.field.description.text',
    GeofenceCoordinates: 'records.card.template-create.data.available.geofences.field.coords.text',
    DateAndTimeOfEnteringTheGeofence: 'records.card.template-create.data.available.geofences.field.date_in.text',
    DateAndTimeOfExitingTheGeofence: 'records.card.template-create.data.available.geofences.field.date_out.text',
    DurationOfStayInTheGeofence: 'records.card.template-create.data.available.geofences.field.duration.text',
  },
  users: {
    FullNameOfTheUser: 'records.card.template-create.data.available.users.field.fio.text',
    UserOrganization: 'records.card.template-create.data.available.users.field.organization.text',
    StructuralUnitOfTheUser: 'records.card.template-create.data.available.users.field.department.text',
    UserWorkExecutionObject: 'records.card.template-create.data.available.users.field.work-obj.text',
    UserPosition: 'records.card.template-create.data.available.users.field.position.text',
    UserSPhoneNumber: 'records.card.template-create.data.available.users.field.telephone.text',
    UserEmail: 'records.card.template-create.data.available.users.field.email.text',
    UserLogin: 'records.card.template-create.data.available.users.field.login.text',
    UserLoginDateAndTime: 'records.card.template-create.data.available.users.field.date_in.text',
    UserLogoutDateAndTime: 'records.card.template-create.data.available.users.field.date_out.text',
    TheDurationOfTheUserSStay: 'records.card.template-create.data.available.users.field.duration.text',
  },
  trackers: {
    TrackerNumber: 'records.card.template-create.data.available.tracks.field.number.text',
    TrackerModel: 'records.card.template-create.data.available.tracks.field.model.text',
    TrackerImei: 'records.card.template-create.data.available.tracks.field.imei.text',
    TrackerMacAddress: 'records.card.template-create.data.available.tracks.field.mac.text',
    TrackerSimCardNumber: 'records.card.template-create.data.available.tracks.field.sim.text',
    TrackerServerAddress: 'records.card.template-create.data.available.tracks.field.server_address.text',
    TrackerState: 'records.card.template-create.data.available.tracks.field.state.text',
    CommentaryOnTheStateOfTheTracker: 'records.card.template-create.data.available.tracks.field.comment.text',
    TheLinkedObjectToTheTracker: 'records.card.template-create.data.available.tracks.field.linked_obj.text',
    CurrentCoordinatesOfTheTracker: 'records.card.template-create.data.available.tracks.field.coords.text',
    CurrentBatteryCharge: 'records.card.template-create.data.available.tracks.field.current_battery_charge.text',
    BatteryChargeTime: 'records.card.template-create.data.available.tracks.field.battery_charge_time.text',
  },
  poipoints: {
    PoiType: 'records.card.template-create.data.available.poi.field.type.text',
    CamcorderName: 'records.card.template-create.data.available.poi.field.videocamera_name.text',
    DescriptionOfTheCamcorder: 'records.card.template-create.data.available.poi.field.videocamera_desc.text',
    CamcorderModel: 'records.card.template-create.data.available.poi.field.videocamera_model.text',
    GasAnalyzerName: 'records.card.template-create.data.available.poi.field.gazanlyzer_name.text',
    GasAnalyzerDescription: 'records.card.template-create.data.available.poi.field.gazanlyzer_desc.text',
    GasAnalyzerModel: 'records.card.template-create.data.available.poi.field.gazanlyzer_model.text',
    GasAnalyzerSensors: 'records.card.template-create.data.available.poi.field.gazanlyzer_sensors.text',
    GasAnalyzerGases: 'records.card.template-create.data.available.poi.field.gazanlyzer_gazes.text',
    Units: 'records.card.template-create.data.available.poi.field.gazanlyzer_meas_unit.text',
    RangeOfValues: 'records.card.template-create.data.available.poi.field.gazanlyzer_meas_range.text',
    LimitValue: 'records.card.template-create.data.available.poi.field.gazanlyzer_limit.text',
    PresentValue: 'records.card.template-create.data.available.poi.field.gazanlyzer_current.text',
    DateOfLastVerification: 'records.card.template-create.data.available.poi.field.gazanlyzer_last_check.text',
    DateOfNextVerification: 'records.card.template-create.data.available.poi.field.gazanlyzer_next_check.text',
    SensorName: 'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_name.text',
    SensorDescription: 'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_desc.text',
    SensorType: 'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_type.text',
    SensorModel: 'records.card.template-create.data.available.poi.field.gazanlyzer_sensor_model.text',
    EquipmentName: 'records.card.template-create.data.available.poi.field.equip_name.text',
    EquipmentDescription: 'records.card.template-create.data.available.poi.field.equip_desc.text',
    EquipmentType: 'records.card.template-create.data.available.poi.field.equip_type.text',
    EquipmentModel: 'records.card.template-create.data.available.poi.field.equip_model.text',
    IncidentName: 'records.card.template-create.data.available.poi.field.accident_name.text',
    DescriptionOfTheIncident: 'records.card.template-create.data.available.poi.field.accident_desc.text',
    IncidentType: 'records.card.template-create.data.available.poi.field.accident_type.text',
    FreePointName: 'records.card.template-create.data.available.poi.field.free_point_name.text',
    FreePointDescription: 'records.card.template-create.data.available.poi.field.free_point_desc.text',
    PoiValidityDate: 'records.card.template-create.data.available.poi.field.free_point_validity.text',
    PoiCoordinatesOfAPoint: 'records.card.template-create.data.available.poi.field.free_point_coords.text',
  },
  notifications: {
    NameOfNotification: 'records.card.template-create.data.available.notifications.field.name.text',
    NotificationText: 'records.card.template-create.data.available.notifications.field.text.text',
    NotificationType: 'records.card.template-create.data.available.notifications.field.type.text',
    DateAndTimeTheNotificationWasTriggered:
      'records.card.template-create.data.available.notifications.field.triggered_date.text',
    SosSignal: 'records.card.template-create.data.available.notifications.field.sos.text',
    FallingTracker: 'records.card.template-create.data.available.notifications.field.failing.text',
    Inaction: 'records.card.template-create.data.available.notifications.field.inaction.text',
    LossOfConnection: 'records.card.template-create.data.available.notifications.field.loss_connection.text',
    RestoringCommunication: 'records.card.template-create.data.available.notifications.field.restoring_connection.text',
    Violation: 'records.card.template-create.data.available.notifications.field.violation.text',
    GeofenceEntrance: 'records.card.template-create.data.available.notifications.field.geozone_in.text',
    ExitTheGeofence: 'records.card.template-create.data.available.notifications.field.geozone_out.text',
    TheAbsenceOfAnObjectInTheGeofence:
      'records.card.template-create.data.available.notifications.field.geozone_absensce_obj.text',
    LongStayOfAnObjectInAGeofence:
      'records.card.template-create.data.available.notifications.field.geozone_long_in.text',
    ConvergenceOfObjects: 'records.card.template-create.data.available.notifications.field.geozone_convergence.text',
    MovingObjectsAway: 'records.card.template-create.data.available.notifications.field.geozone_away.text',
    CoordinatesOfTheNotification: 'records.card.template-create.data.available.notifications.field.coords.text',
  },
  custom: {},
};

// преобразуем REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE в один объект
export const REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE_ALL = Object.values(
  REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE
).reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const ReportTemplateDataFieldsTranslate = Object.values(REPORT_TEMPLATE_DATA_GROUP_FIELDS_TRANSLATE)
  .filter(value => Object.keys(value).length)
  .reduce(
    (pred, valueObj) => ({
      ...pred,
      ...valueObj,
    }),
    {}
  );

// conditions tab

export enum REPORT_TEMPLATE_CONDITIONS_GROUPING {
  Year = 'Year',
  Month = 'Month',
  Date = 'Date',
  DayOfWeek = 'DayOfWeek',
  ObjectType = 'ObjectType',
  Object = 'Object',
}

export enum REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING {
  geofences = 'Geofences',
  notificationMarkers = 'NotificationMarkers',
  movementTracks = 'MovementTracks',
  poiPoints = 'POIPoints',
  heatmaps = 'Heatmaps',
}

export enum REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS {
  GeoZone = 'GeoZone',
  GeoZoneGroup = 'GeoZoneGroup',
  Notification = 'Notification',
  Organization = 'Organization',
  Department = 'Department',
  Position = 'Position',
  Role = 'Role',
}

export enum REPORT_TEMPLATE_NOTIFICATION_CONDITION {
  Alert = 'Alert',
  GeoZone = 'GeoZone',
  Interposition = 'Interposition',
  Sensor = 'Sensor',
}

export const REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_FROM_ID = Object.values(REPORT_TEMPLATE_NOTIFICATION_CONDITION);
export const REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_TO_ID = Object.values(
  REPORT_TEMPLATE_NOTIFICATION_CONDITION
).reduce(
  (pred, value, i) => ({
    ...pred,
    [value]: i,
  }),
  {}
);

export const defaultRecordsConditions: RecordsConditionsTabStates = {
  groupingList: Object.keys(REPORT_TEMPLATE_CONDITIONS_GROUPING).map(key => ({
    key: key as REPORT_TEMPLATE_CONDITIONS_GROUPING,
    text: `records.card.template-create.condition.grouping.by-${REPORT_TEMPLATE_CONDITIONS_GROUPING[
      key as keyof typeof REPORT_TEMPLATE_CONDITIONS_GROUPING
    ].toLowerCase()}.text`,
    checked: false,
  })),
  geofences: false,
  notificationMarkers: false,
  movementTracks: false,
  POIPoints: false,
  heatmaps: false,
  additionalFilters: Object.keys(REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS).reduce((pred, key) => {
    // исключаем группы геозон, т.к. все сущности (группы геозон и сами геозоны)
    // будут содержаться в REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZone
    if (key !== REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.GeoZoneGroup) {
      const filterValue = {
        key: key as REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS,
        text: `records.card.template-create.condition.additional-fields.by-${REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS[
          key as keyof typeof REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS
        ].toLowerCase()}.text`,
        checked: false,
      };

      pred.push(filterValue);
    }
    return pred;
  }, [] as ConditionsTabCheckBoxAdditionalFiltersType[]),
  limitListOfObjects: false,
  lineNumeration: false,
  totalColumn: false,
  objectIds: [],
};

export enum GENERATE_REPORT_STATUS {
  Error = 'Error',
  Done = 'Done',
  InProgress = 'InProgress',
}

export enum GENERATE_REPORT_MAP_PICTURE_TYPE {
  GeoZone = 'GeoZone',
  Notifications = 'Notifications',
  POIPoints = 'POIPoints',
  HeatMaps = 'HeatMaps',
  MovementTracks = 'MovementTracks',
}

export enum REPORT_TABLE_OBJECT {
  User = 'User',
  Employee = 'Employee',
  Transport = 'Transport',
  Tracker = 'Tracker',
  POIPoint = 'POIPoint',
}

export const DEFAULT_REPORTS_HISTORY_OFFSET = 0;
export const DEFAULT_REPORTS_HISTORY_LIMIT = 10;

export const defaultReportsHistoryFilter = {
  offset: DEFAULT_REPORTS_HISTORY_OFFSET,
  limit: DEFAULT_REPORTS_HISTORY_LIMIT,
};

export enum GENERATED_PRINTED_FORM_RESPONSE_STATUSES {
  SUCCESS = 201,
  IS_IN_COMPILATION = 202,
  NOT_FOUND = 404,
  REPORT_HAS_ERROR = 406,
  UNKNOWN = 500,
}

export const SupportedPrintableTemplateTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
];

export const UID_STR_PRINTABLE_TEMPLATE_ID_REGEXP =
  /^\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b-?/gi;

export const NO_HAS_REPORT_TEMPLATE_ID_ERROR = 1002;
