import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';

import Button from 'components/common/button/button';
import {
  defaultCreateReportObjects,
  defaultReportsHistoryFilter,
  GENERATE_REPORT_STATUS,
} from 'components/records/utils/consts';
import { RecordsHistoryHandlers, RecordsHistoryRefs, RecordsHistoryStates } from 'components/records/utils/types';
import { Spinner } from 'components/common/spinner/spinner';
import { Alert } from 'components/common/alert/alert';

import { RootState } from 'reducers';
import {
  clearReportsHistory,
  fetchReportsHistory,
  setCreateReportData,
  setCurrentTemplateId,
  handleIsReportInfoShow,
} from 'reducers/records';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import useSetEllipsisAmountsToText from 'utils/useSetEllipsisAmountsToText';
import useObserveToWidthLeftSideBar from 'utils/useObserveToWidthLeftSideBar';

import styles from './recordsHistory.module.scss';

const cx = classNames.bind(styles);

type Props = {
  states: RecordsHistoryStates;
  handlers: RecordsHistoryHandlers;
  refs: RecordsHistoryRefs;
};

export const RecordsHistory = ({ states, handlers, refs }: Props) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  // получаем список отчетов здесь, т.к. при unmount истории отчетов (данного компонента) необходимо
  // очистить данный компонент для получения ширины левого сайдбара и отрисовки списка с учетом данной ширины
  useEffect(() => {
    dispatch(setCurrentTemplateId(null));
    dispatch(setCreateReportData(defaultCreateReportObjects));
    dispatch(fetchReportsHistory(defaultReportsHistoryFilter));

    return () => {
      dispatch(clearReportsHistory());
      dispatch(handleIsReportInfoShow(false));
    };
  }, [dispatch]);

  const { isReportsHistoryLoading, reportsHistory } = useSelector((state: RootState) => state.records);

  // получим актуальную ширину левого сайдбара для правильного отображения текста в контейнере
  const width = useObserveToWidthLeftSideBar(refs.listRef);

  // навесим атрибут title на интересующие нас элементы (с атрибутами [data-ellipsis] и [data-amount]), которые
  // не помещаются по ширине в контейнере, а также добавим к ним троеточие или количество неотображаемых элементов
  useSetEllipsisAmountsToText({
    width,
    ref: refs.listRef,
    data: reportsHistory,
    ellipsisFromElementDelta: 185,
    amountFromElementDelta: 110,
  });

  const getReportStatusCreating = (status: GENERATE_REPORT_STATUS) => {
    switch (status) {
      case GENERATE_REPORT_STATUS.Done:
        return t('records.report-history.list.header.status.is-done.text');
      case GENERATE_REPORT_STATUS.InProgress:
        return t('records.report-history.list.header.status.in-progress.text');
      case GENERATE_REPORT_STATUS.Error:
        return t('records.report-history.list.header.status.is-error.text');

      default:
        return '';
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4 className={styles.title}>{t('records.report-history.header.text')}</h4>
      </div>
      <div className={styles.content}>
        <ul className={styles.historyList} onScroll={handlers.handleListScroll} ref={refs.listRef}>
          {reportsHistory.map((repHistory, i) => {
            const reportDate = getFullDateFromStr(repHistory.date);
            const reportFromDate = getFullDateFromStr(repHistory.fromDate);
            const reportToDate = getFullDateFromStr(repHistory.toDate);

            return (
              <li key={`report-history-${repHistory.id}`} className={styles.historyItem}>
                <div className={styles.historyNumber}>
                  <strong>{i + 1}</strong>
                </div>
                <div className={styles.historyContent}>
                  <div className={styles.historyContentHeader}>
                    <div className={styles.historyContentHeaderLeft}>
                      <div
                        className={cx(styles.historyName, {
                          [styles.historyNameDone]: repHistory.status === GENERATE_REPORT_STATUS.Done,
                          [styles.historyNameInProgress]: repHistory.status !== GENERATE_REPORT_STATUS.Done,
                        })}
                        onClick={
                          repHistory.status === GENERATE_REPORT_STATUS.Done
                            ? () => handlers.handleClickReportHistory(repHistory.id)
                            : undefined
                        }
                        data-ellipsis
                        data-text={repHistory.name || t('records.report-history.list.item.no-name.text')}
                      />
                    </div>
                    <div className={styles.historyContentHeaderRight}>
                      <div
                        className={cx(styles.historyStatus, {
                          [styles.historyStatusDone]: repHistory.status === GENERATE_REPORT_STATUS.Done,
                          [styles.historyStatusInProgress]: repHistory.status === GENERATE_REPORT_STATUS.InProgress,
                          [styles.historyStatusError]: repHistory.status === GENERATE_REPORT_STATUS.Error,
                        })}
                      >
                        {getReportStatusCreating(repHistory.status)}
                      </div>
                      <DeleteIcon
                        className={styles.historyDelete}
                        onClick={() => handlers.handleDeleteReportHistory(repHistory.id)}
                        data-tip
                        data-for={`delete-report-${repHistory.id}`}
                      />
                      <ReactTooltip
                        className={styles.customTooltip}
                        id={`delete-report-${repHistory.id}`}
                        place="right"
                        type="light"
                        effect="solid"
                      >
                        <span>{t('records.report-history.list.item.delete.text')}</span>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className={styles.historyContentFooter}>
                    <div className={styles.historyContentFooterRow}>
                      <span>
                        <strong>
                          {t('records.report-history.list.footer.date.text')}
                          {': '}
                        </strong>
                        {reportDate.date} {reportDate.time}
                      </span>
                    </div>
                    <div className={styles.historyContentFooterRow}>
                      <strong>
                        {t('records.report-history.list.footer.period.text')}
                        {': '}
                      </strong>
                      <span>
                        {reportFromDate.date} {reportFromDate.time} {'-'} {reportToDate.date} {reportToDate.time}
                      </span>
                    </div>
                    <div className={styles.historyContentFooterRow}>
                      <strong>
                        {t('records.report-history.list.footer.objects.text')}
                        {': '}
                      </strong>
                      <span
                        className={styles.historyObjects}
                        data-amount
                        data-text={repHistory.objectsNames.join(', ')}
                      />
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          {isReportsHistoryLoading && (
            <li className={cx(styles.historyItem, styles.historyItemSpinner)}>
              <Spinner />
            </li>
          )}
        </ul>
      </div>
      <div className={styles.footer}>
        <Button
          white
          text={t('records.left-sb.period.field.history.btn.close.label')}
          customStyle={styles.footerButton}
          onClick={handlers.handleClickHideHistory}
        />
      </div>
      {!!states.deleteReportId && (
        <Alert
          title={t('records.report-history.list.alert.delete.title')}
          infoText={t('records.report-history.list.alert.delete.text')}
          handleCancel={handlers.handleDeleteAlertCancel}
          handleContinue={handlers.handleDeleteAlertContinue}
        />
      )}
    </div>
  );
};
