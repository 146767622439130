import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { fetchRoles } from 'reducers/roles';
import { CreateAccount } from 'reducers/accounts/interface';

import { getCurrentLocale } from 'translate';

import {
  defaultMainTabState as defaultsMain,
  defaultRolesTabState as deafultRoles,
  defaultUserPreferences,
} from './consts';

import { AccountHandlers } from './types';

const mainTabRequiredFields: string[] = [
  'firstName',
  'lastName',
  'organizationId',
  'departmentId',
  'email',
  'positionId',
  'login',
];

export function useUserMainTab() {
  const accounts = useSelector((state: RootState) => state.account.accounts);
  const chosenAccount = useSelector((state: RootState) => state.account.chosenAccount);

  const chosenUser = accounts.find(acc => acc.id === chosenAccount);

  const [firstName, setFirstName] = useState(chosenUser?.attributes.firstName ?? defaultsMain.firstName);
  const [lastName, setLastName] = useState(chosenUser?.attributes.lastName ?? defaultsMain.lastName);
  const [secondName, setSecondName] = useState(chosenUser?.attributes.secondName ?? defaultsMain.secondName);
  const [simNumber, setSimNumber] = useState(chosenUser?.attributes.simNumber ?? defaultsMain.simNumber);
  const [smsNotified, setSmsNotified] = useState(chosenUser?.attributes.smsNotified ?? defaultsMain.smsNotified);
  const [login, setLogin] = useState(chosenUser?.attributes.login ?? defaultsMain.login);

  const [employeeId] = useState(defaultsMain.employeeId);

  const [organizationId, setOrganizationId] = useState(
    chosenUser?.attributes.organizationId ?? defaultsMain.organizationId
  );
  const [departmentId, setDepartmentId] = useState(chosenUser?.attributes.departmentId ?? defaultsMain.departmentId);
  const [positionId, setPositionId] = useState(chosenUser?.attributes.positionId ?? defaultsMain.positionId);
  const [email, setEmail] = useState(chosenUser?.attributes.email ?? defaultsMain.email);
  const [emailNotified, setEmailNotified] = useState(
    chosenUser?.attributes.emailNotified ?? defaultsMain.emailNotified
  );
  const [password, setPassword] = useState(defaultsMain.password);
  const [icon, setIcon] = useState<string | null>(chosenUser?.attributes.icon ?? null);

  const [isBanned, setIsBanned] = useState(chosenUser?.attributes.isBanned ?? defaultsMain.isBanned);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  // userPreferences are needed when creating new user.
  // When updating user userPreferences are ignored at backend.
  const userPreferences = defaultUserPreferences;

  const organizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const organizationOptions = organizations.map(org => ({
    value: org.id,
    label: getTranslateFromLanguageKey(org.attributes.name, userLanguageKey),
  }));

  const org = organizations.find(org => org.id === organizationId);
  const departmentOptions = org
    ? org.relationships?.departments?.data?.map(dep => ({
        value: dep.id,
        label: getTranslateFromLanguageKey(dep.name, userLanguageKey),
      })) ?? ''
    : [];

  const positions = useSelector((state: RootState) => state.handbooks.data.positions);
  const positionOptions = positions.map(pos => ({
    value: pos.id,
    label: getTranslateFromLanguageKey(pos.attributes.name, userLanguageKey),
  }));

  const handleSmsNotifyChange = () => {
    setSmsNotified(!smsNotified);
  };
  const handleEmailNotifyChange = () => {
    setEmailNotified(!emailNotified);
  };
  const handleBanStatusChange = () => {
    setIsBanned(!isBanned);
  };

  const handleOrgChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setOrganizationId(id);
    } else {
      setOrganizationId(0);
    }
  };
  const handleDepartmentChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setDepartmentId(id);
    } else {
      setDepartmentId(0);
    }
  };
  const handlePositionChange = (val: string) => {
    if (val) {
      const id = parseInt(val);
      setPositionId(id);
    } else {
      setPositionId(0);
    }
  };

  const accountValues: CreateAccount = {
    firstName,
    lastName,
    secondName,
    simNumber,
    smsNotified,
    login,
    organizationId,
    departmentId,
    positionId,
    email,
    emailNotified,
    password,
    isBanned,
    employeeId,
    userPreferences,
    icon,
  };

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    mainTabRequiredFields.forEach(field => {
      if (!accountValues[field]) {
        newInvalidFields.push(field);
      }
    });

    setInvalidFields(newInvalidFields);
    return Boolean(newInvalidFields.length);
  };

  const accountHandlers: AccountHandlers = {
    setFirstName,
    setLastName,
    setSecondName,
    setSimNumber,
    handleSmsNotifyChange,
    setLogin,
    handleOrgChange,
    handleDepartmentChange,
    handlePositionChange,
    setEmail,
    handleEmailNotifyChange,
    setPassword,
    handleBanStatusChange,
    setIcon,
  };

  const hasChanges = () => {
    let comparedObj = { ...defaultsMain, userPreferences };

    if (chosenUser) {
      comparedObj = {
        firstName: chosenUser.attributes.firstName,
        lastName: chosenUser.attributes.lastName,
        secondName: chosenUser.attributes.secondName,
        simNumber: chosenUser.attributes.simNumber,
        smsNotified: chosenUser.attributes.smsNotified,
        login: chosenUser.attributes.login,
        organizationId: chosenUser.attributes.organizationId ?? 0,
        departmentId: chosenUser.attributes.departmentId ?? 0,
        positionId: chosenUser.attributes.positionId ?? 0,
        email: chosenUser.attributes.email,
        emailNotified: chosenUser.attributes.emailNotified,
        isBanned: chosenUser.attributes.isBanned,
        employeeId,
        userPreferences,
        password,
      };
    }
    return !isEqual(accountValues, comparedObj);
  };

  return {
    accountValues,
    accountHandlers,
    invalidFields,
    validateRequiredFields,
    hasSelectedUser: Boolean(chosenUser),
    organizationOptions,
    departmentOptions,
    positionOptions,
    userId: chosenUser?.id,
    hasChanges,
  };
}

export function useUserRolesTab() {
  const dispatch = useDispatch();

  const accounts = useSelector((state: RootState) => state.account.accounts);
  const chosenAccount = useSelector((state: RootState) => state.account.chosenAccount);

  const chosenUserRoles = accounts
    .find(acc => acc.id === chosenAccount)
    ?.relationships.roles.data.map(r => parseInt(r.id));

  const roles = useSelector((state: RootState) => state.role.roles);
  const isLoading = useSelector((state: RootState) => state.role.isLoading);

  const [selectedRoles, setSelectedRoles] = useState<number[]>(chosenUserRoles ?? deafultRoles.selectedRoles);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const handleRoleSelection = (id: number) => {
    if (selectedRoles.includes(id)) {
      setSelectedRoles(selectedRoles.filter(r => r !== id));
    } else {
      setSelectedRoles([...selectedRoles, id]);
    }
  };

  const getRolesForUpdate = () => {
    const delRoles = chosenUserRoles ? chosenUserRoles.filter(userRole => !selectedRoles.includes(userRole)) : [];
    const addRoles = selectedRoles.filter(selRole => !chosenUserRoles?.includes(selRole));

    return { delRoles, addRoles };
  };

  const hasChanges = () => {
    if (!chosenUserRoles && !selectedRoles.length) {
      return false;
    }
    return !isEqual(chosenUserRoles, selectedRoles);
  };

  return {
    roles,
    isLoading,
    selectedRoles,
    handleRoleSelection,
    getRolesForUpdate,
    hasChanges,
  };
}
