import { reqHandlers } from 'utils/api';
import { getQueryStrFromObjKeys } from 'utils/getQueryStrFromObjKeys';
import {
  AccountAttributes,
  CreateAccount,
  CreateUserPreferences,
  GetUsersListRequestData,
  UserRoleReqParams,
} from './interface';

export function createAccount(data: CreateAccount) {
  return reqHandlers.post(`/api/users`, data);
}

export function putAccount(data: AccountAttributes & { id: string }) {
  return reqHandlers.put(`/api/users/${data.id}`, data);
}

export function setAccountBann(data: { userId: string; status: boolean }) {
  return reqHandlers.post(`/api/users/${data.userId}/ban`, { status: data.status });
}

export function putCurrentAccount(data: AccountAttributes & { id: string; userPreferences: CreateUserPreferences }) {
  return reqHandlers.put(`/api/users/${data.id}`, data);
}

export function deleteAccount(id: string) {
  return reqHandlers.delete(`/api/users/${id}`);
}

export function postUserRole({ userId, roleId }: UserRoleReqParams) {
  return reqHandlers.post(`/api/users/${userId}/roles/${roleId}`, {});
}

export function deleteUserRole({ userId, roleId }: UserRoleReqParams) {
  return reqHandlers.delete(`/api/users/${userId}/roles/${roleId}`);
}

export function getUsersList(data?: GetUsersListRequestData) {
  const queryString = data ? `?${getQueryStrFromObjKeys(data)}` : '';

  return reqHandlers.get('/api/users' + queryString);
}

export function postUserPreferences(data: CreateUserPreferences) {
  return reqHandlers.post('/api/userPreferences', data);
}

export function getUserListShortAll() {
  return reqHandlers.get('/api/users/shortAll');
}

export function getUser(id: string) {
  return reqHandlers.get(`/api/users/${id}`);
}
