import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'reducers';
import {
  increaseUpdateCounter as increaseAccountsUpdateCounter,
  initializeRoleToUsersData,
  setRoleToUsers,
} from 'reducers/accounts';
import { showModal } from 'reducers/modal';

import { ROLE } from 'utils/consts';

import { RoleState, Permissions } from './interface';
import { getRoles, putRole, createRole, deleteRole, getOneRole } from './api';

export const fetchRoles = createAsyncThunk('roleSlice/fetchRoles', async () => await getRoles());

export const addRole = createAsyncThunk('roleSlice/addRole', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  const { name, permissions } = state.role.roleForm;

  const response = await createRole({ name, ...permissions });

  await thunkAPI.dispatch(setRoleToUsers(response.data.id));

  thunkAPI.dispatch(increaseAccountsUpdateCounter());

  return response;
});

export const updateRole = createAsyncThunk('roleSlice/updateRole', async (roleId: string, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  const { name, permissions } = state.role.roleForm;

  const response = await putRole(roleId, { name, ...permissions });

  await thunkAPI.dispatch(setRoleToUsers(roleId));

  thunkAPI.dispatch(increaseAccountsUpdateCounter());

  return response;
});

export const removeRole = createAsyncThunk('roleSlice/removeRole', async (id: number) => await deleteRole(id));

export const fetchInitialRoleFormData = createAsyncThunk(
  'roleSlice/fetchInitialRoleFormData',
  async (id: string) => await getOneRole(id)
);

export const initializeRoleForm = createAsyncThunk(
  'roleSlice/initializeRoleForm',
  async (roleId: string | null, thunkAPI) => {
    thunkAPI.dispatch(resetRoleForm());
    thunkAPI.dispatch(setRoleFormIsInitialized(true));

    if (roleId) {
      thunkAPI.dispatch(setChosenRole(roleId));
      thunkAPI.dispatch(fetchInitialRoleFormData(roleId));
      await thunkAPI.dispatch(initializeRoleToUsersData(roleId));
    } else {
      await thunkAPI.dispatch(initializeRoleToUsersData(null));
    }

    thunkAPI.dispatch(showModal({ type: ROLE, which: '' }));
  }
);

const defaultPermissions: Permissions = {
  employees: '0000',
  transports: '0000',
  trackers: '0000',
  users: '0000',
  roles: '0000',
  geozones: '0000',
  tracks: '0000',
  reports: '0000',
  map: '0000',
  handbooks: '0000',
  buildings: '0000',
  notifications: '0000',
  records: '0000',
  poi: '0000',
  messages: '0000',
};

const initialState: RoleState = {
  roles: [],
  updateCounter: 0,
  chosenRole: null,
  initialFormDataIsLoading: false,
  roleFormIsInitialized: false,
  roleForm: {
    name: '',
    permissions: { ...defaultPermissions },
  },
  isLoading: false,
  error: null,
};

const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    setChosenRole: (state, { payload }: { payload: string }) => {
      state.chosenRole = payload;
    },
    removeChosenRole: state => {
      state.chosenRole = null;
    },
    setRoleForm: (state, action: PayloadAction<{ name: string; permissions: Permissions }>) => {
      state.roleForm = {
        name: action.payload.name,
        permissions: action.payload.permissions,
      };
    },
    resetRoleForm: state => {
      state.roleForm = {
        name: '',
        permissions: { ...defaultPermissions },
      };
    },
    setRoleFormIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.roleFormIsInitialized = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.roles = payload.data;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(addRole.pending, state => {
        state.error = null;
      })
      .addCase(addRole.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(addRole.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateRole.pending, state => {
        state.error = null;
      })
      .addCase(updateRole.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeRole.pending, state => {
        state.error = null;
      })
      .addCase(removeRole.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(removeRole.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchInitialRoleFormData.pending, state => {
        state.error = null;
        state.initialFormDataIsLoading = true;
      })
      .addCase(fetchInitialRoleFormData.fulfilled, (state, action) => {
        if (action.payload) {
          const permissions: { [key: string]: string } = {};

          for (const key in defaultPermissions) {
            if (action.payload.data.attributes[key]) {
              permissions[key] = action.payload.data.attributes[key];
            } else {
              permissions[key] = '0000';
            }
          }

          state.roleForm = {
            name: action.payload.data.attributes.name,
            permissions: permissions as Permissions,
          };

          state.initialFormDataIsLoading = false;
        }
      })
      .addCase(fetchInitialRoleFormData.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.initialFormDataIsLoading = false;
      });
  },
});

export const { setChosenRole, removeChosenRole, setRoleForm, resetRoleForm, setRoleFormIsInitialized } =
  roleSlice.actions;

export default roleSlice.reducer;
