import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Map, MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';

import { GEOZONE_FEATURE_TYPE } from 'components/geozones/utils/consts';

import { RootState } from 'reducers';
import { setPopupGeozoneParams } from 'reducers/geozones';

export function useGeozone(map: Map) {
  const dispatch = useDispatch();

  const { geozoneCreationIsShow } = useSelector((state: RootState) => state.geozone);

  const [showGeozonePopup, setShowGeozonePopup] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({ mouseCoords: { x: 0, y: 0 } });

  const clickHandler = useCallback(
    (evt: MapBrowserEvent<PointerEvent>) => {
      if (!geozoneCreationIsShow) {
        const { map, pixel } = evt;
        const mouseX = evt.originalEvent.clientX;
        const mouseY = evt.originalEvent.clientY;

        setShowGeozonePopup(false);

        map.forEachFeatureAtPixel(pixel, (feature: FeatureLike) => {
          const { type, id, centerPoint } = feature.getProperties();

          if (type === GEOZONE_FEATURE_TYPE && id && centerPoint?.length) {
            setMarkerCoords({
              mouseCoords: { x: mouseX, y: mouseY },
            });
            setShowGeozonePopup(true);
            dispatch(setPopupGeozoneParams({ id, centerPoint }));
          }
          return feature;
        });
      }
    },
    [dispatch, geozoneCreationIsShow]
  );

  useEffect(() => {
    map.on('click', clickHandler);

    return () => {
      map.un('click', clickHandler);
    };
  }, [map, clickHandler]);

  return {
    geozonePopup: {
      onCancel: () => setShowGeozonePopup(false),
      markerCoords,
    },
    showGeozonePopup,
  };
}
