import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HandbooksTable from './components/handbooksTable/handbooksTable';
import HandbooksSearch from './components/handbooksSearch/handbooksSearch';

import { setChosenElement, setChosenHandbook, setElements, setNewElement } from 'reducers/handbooks';

import styles from './handbooks.module.scss';

const Handbooks: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setChosenHandbook(null));
      dispatch(setElements([]));
      dispatch(setChosenElement(null));
      dispatch(setNewElement(null));
    };
  }, [dispatch]);

  return (
    <div className={styles.content}>
      <div className={styles.searchWrapper}>
        <HandbooksSearch />
      </div>
      <HandbooksTable />
    </div>
  );
};

export default Handbooks;
