import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import messages, { DEFAULT_LANGUAGES } from 'translate';

import { login } from './api';
import { initialStateInterface, LoginInterface, JWTAuthInterface } from './interface';
import { NOT_FOUND_ERROR_STATUS } from 'utils/api/consts';
import { reqHandlers } from 'utils/api';

const initialState: initialStateInterface = {
  isAuth: false,
  accessToken: null,

  isWialonLoggedIn: false,
  wialonSession: null,

  isUnauthorizedReq: false,

  error: null,
};

export const fetchLogin = createAsyncThunk(
  'LoginSlice/fetchLogin',
  async (loginData: LoginInterface) => await login(loginData)
);

const loginSlice = createSlice({
  name: 'LoginSlice',
  initialState,
  reducers: {
    setIsAuth: (state, action: { payload: boolean }) => {
      state.isAuth = action.payload;
    },
    setAccessToken: (state, { payload }: { payload: string | null }) => {
      state.accessToken = payload;
    },
    setIsWialonLoggedIn: (state, { payload }: { payload: boolean }) => {
      state.isWialonLoggedIn = payload;
    },
    setWialonSession: (state, { payload }: { payload: unknown }) => {
      state.wialonSession = payload;
    },
    setIsUnauthorizedReq: (state, action: { payload: boolean }) => {
      state.isUnauthorizedReq = action.payload;
    },
    logOut: state => {
      state.isAuth = false;
      state.accessToken = null;

      state.isWialonLoggedIn = false;
      state.wialonSession = null;

      state.isUnauthorizedReq = false;

      state.error = null;
      reqHandlers.reset();
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLogin.pending, state => {
      state.error = null;
      state.isAuth = false;
      sessionStorage.clear();
      reqHandlers.reset();
    });
    builder.addCase(fetchLogin.fulfilled, (state, { payload }: { payload: JWTAuthInterface }) => {
      const { accessToken, refreshToken, expiresIn } = payload;

      if (!!accessToken && !!refreshToken && expiresIn) {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('expiresAt', String(Date.now() + expiresIn * 1000));

        state.accessToken = accessToken;
        state.isAuth = true;
        reqHandlers.init();
      }
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      const locale = DEFAULT_LANGUAGES;

      if (action.error.code === String(NOT_FOUND_ERROR_STATUS)) {
        toast.error(messages[locale]['toast.auth.not-found.error.text']);
      }

      state.error = action.error.message ?? 'Error';
      state.isAuth = false;
      sessionStorage.clear();
    });
  },
});

export const { setIsAuth, setAccessToken, setIsWialonLoggedIn, setWialonSession, setIsUnauthorizedReq, logOut } =
  loginSlice.actions;

export default loginSlice.reducer;
