export default {
  en: {
    'dates-range.with-scripts.period.btn.yesterday.text': 'Yesterday',
    'dates-range.with-scripts.period.btn.btn.today.text': 'Today',
    'dates-range.with-scripts.period.btn.week.text': 'Week',
    'dates-range.with-scripts.period.btn.month.text': 'Month',

    'dates-range.with-scripts.period.field.from.label': 'From',
    'dates-range.with-scripts.period.field.from.placeholder': 'Select date from',
    'dates-range.with-scripts.period.field.to.label': 'Before',
    'dates-range.with-scripts.period.field.to.placeholder': 'Select date to',

    'dates-range.with-scripts.period.field.from.time-caption.text': 'Time',
    'dates-range.with-scripts.period.field.to.time-caption.text': 'Time',
  },
  ru: {
    'dates-range.with-scripts.period.btn.yesterday.text': 'Вчера',
    'dates-range.with-scripts.period.btn.btn.today.text': 'Сегодня',
    'dates-range.with-scripts.period.btn.week.text': 'Неделя',
    'dates-range.with-scripts.period.btn.month.text': 'Месяц',

    'dates-range.with-scripts.period.field.from.label': 'От',
    'dates-range.with-scripts.period.field.from.placeholder': 'Выберите дату от',
    'dates-range.with-scripts.period.field.to.label': 'До',
    'dates-range.with-scripts.period.field.to.placeholder': 'Выберите дату до',

    'dates-range.with-scripts.period.field.from.time-caption.text': 'Время',
    'dates-range.with-scripts.period.field.to.time-caption.text': 'Время',
  },
  zh: {
    'dates-range.with-scripts.period.btn.yesterday.text': '昨天',
    'dates-range.with-scripts.period.btn.btn.today.text': '今天',
    'dates-range.with-scripts.period.btn.week.text': '星期',
    'dates-range.with-scripts.period.btn.month.text': '月',

    'dates-range.with-scripts.period.field.from.label': '从',
    'dates-range.with-scripts.period.field.from.placeholder': '请选择日期从',
    'dates-range.with-scripts.period.field.to.label': '到',
    'dates-range.with-scripts.period.field.to.placeholder': '请选择日期到',

    'dates-range.with-scripts.period.field.from.time-caption.text': '时间',
    'dates-range.with-scripts.period.field.to.time-caption.text': '时间',
  },
  ar: {
    'dates-range.with-scripts.period.btn.yesterday.text': 'أمس',
    'dates-range.with-scripts.period.btn.btn.today.text': 'اليوم',
    'dates-range.with-scripts.period.btn.week.text': 'الاسبوع',
    'dates-range.with-scripts.period.btn.month.text': 'الشهر',

    'dates-range.with-scripts.period.field.from.label': 'من',
    'dates-range.with-scripts.period.field.from.placeholder': 'حدد تاريخًا من',
    'dates-range.with-scripts.period.field.to.label': '  قبل',
    'dates-range.with-scripts.period.field.to.placeholder': '  حدد تاريخًا من قبل',

    'dates-range.with-scripts.period.field.from.time-caption.text': '  زمن',
    'dates-range.with-scripts.period.field.to.time-caption.text': '  زمن',
  },
  de: {
    'dates-range.with-scripts.period.btn.yesterday.text': 'Gestern',
    'dates-range.with-scripts.period.btn.btn.today.text': 'Heute',
    'dates-range.with-scripts.period.btn.week.text': 'Woche',
    'dates-range.with-scripts.period.btn.month.text': 'Monat',

    'dates-range.with-scripts.period.field.from.label': 'Von',
    'dates-range.with-scripts.period.field.from.placeholder': 'Wählen Sie ein Datum von',
    'dates-range.with-scripts.period.field.to.label': 'Bis zum',
    'dates-range.with-scripts.period.field.to.placeholder': 'Wählen Sie ein Datum bis zum',

    'dates-range.with-scripts.period.field.from.time-caption.text': 'Zeit',
    'dates-range.with-scripts.period.field.to.time-caption.text': 'Zeit',
  },
};
