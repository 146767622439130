import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Pagination from 'components/common/pagination/pagination';

import { RootState } from 'reducers';
import { fetchHandbookStatistic, setFilterField } from 'reducers/handbooks';

import styles from './paginationFilterModal.module.scss';

const DEFAULT_LIMIT = 5;
const DEFAULT_PAGE = 1;

export const PaginationFilterModal = () => {
  const dispatch = useDispatch();
  const { statisticDataFilter, statisticTotal } = useSelector((state: RootState) => state.handbooks);

  const handleIncreasePage = () => {
    const pageLimit = Math.ceil(statisticTotal / (statisticDataFilter.limit ?? DEFAULT_LIMIT));
    const inc = statisticDataFilter.page ? statisticDataFilter.page + 1 : 1;

    dispatch(
      setFilterField({
        key: 'page',
        value: inc <= pageLimit ? inc : 1,
      })
    );
    dispatch(fetchHandbookStatistic());
  };

  const handleDecreasePage = () => {
    const dec = statisticDataFilter.page ? statisticDataFilter.page - 1 : 1;

    dispatch(
      setFilterField({
        key: 'page',
        value: dec > 1 ? dec : 1,
      })
    );
    dispatch(fetchHandbookStatistic());
  };

  const handleSetLimit = (value: number) => {
    dispatch(
      setFilterField({
        key: 'limit',
        value: value,
      })
    );
    dispatch(fetchHandbookStatistic());
  };

  return (
    <Pagination
      withSelect
      page={statisticDataFilter.page ?? DEFAULT_PAGE}
      increasePage={handleIncreasePage}
      decreasePage={handleDecreasePage}
      limit={statisticDataFilter.limit}
      setLimit={handleSetLimit}
      wrapperClass={styles.pagination}
    />
  );
};
