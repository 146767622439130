import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { GEOZONES_LOCATION_PATH } from 'components/geozones/utils/consts';

import { fetchGeozones, fetchGeozonesGroups } from 'reducers/geozones';
import { fetchCountUnreadLogs, fetchNotifications, fetchNotificationUnreadLogs } from 'reducers/notifications';
import { fetchUserData, fetchUserPreferences } from 'reducers/user';
import { fetchClientUserPreferences } from 'reducers/clientUserPreferences';

import { CommonState, ContentSize } from './interface';

const sliceName = 'commonSlice';

const initialState: CommonState = {
  appHeaderHeight: 0,
  appFooterHeight: 0,
  contentSize: {
    width: 0,
  },
  userDataIsLoaded: false,
};

export const fetchInitialData = createAsyncThunk(
  `${sliceName}/fetchInitialData`,
  async (location: string, thunkAPI) => {
    const { dispatch } = thunkAPI;

    // запрос о данных user-а
    // ждём ответ, так как данные нужные в других запросах
    await Promise.all([
      dispatch(fetchUserPreferences()),
      dispatch(fetchClientUserPreferences()),
      dispatch(fetchUserData()),
    ]);

    dispatch(setUserDataIsLoaded(true));

    // запрос за данными геозон
    if (location !== GEOZONES_LOCATION_PATH) {
      dispatch(fetchGeozones());
      dispatch(fetchGeozonesGroups());
    }

    // также дополнительно необходимо запросить нотификации и
    // их логи для отображения соответствующих счетчиков в футере (внизу справа)
    dispatch(fetchNotifications());
    dispatch(fetchCountUnreadLogs());
    dispatch(fetchNotificationUnreadLogs());
  }
);

const commonSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAppHeaderHeight: (state, action: PayloadAction<number>) => {
      state.appHeaderHeight = action.payload;
    },
    setAppFooterHeight: (state, action: PayloadAction<number>) => {
      state.appFooterHeight = action.payload;
    },
    setContentSize: (state, action: PayloadAction<ContentSize>) => {
      state.contentSize = action.payload;
    },
    setUserDataIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.userDataIsLoaded = action.payload;
    },
  },
});

export const { setContentSize, setUserDataIsLoaded, setAppHeaderHeight, setAppFooterHeight } = commonSlice.actions;

export default commonSlice.reducer;
