import React, { useState, Fragment, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import format from 'date-fns/format';

import { ReactComponent as HeaderIcon } from 'assets/img/object-card-icon.svg';
import Employee from 'assets/img/employee-big.png';
import { ReactComponent as DropArrow } from 'assets/img/button/drop-arrow.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';

import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { Spinner } from 'components/common/spinner/spinner';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { showModal, showMapPopup } from 'reducers/modal';
import { setChosenEmployee } from 'reducers/employees';

import { EMPLOYEE, NA, HIDE_TEXT, MORE_TEXT, APP_CONTENT_ID } from 'utils/consts';

import { getCurrentLocale } from 'translate';

import styles from './employeePopup.module.scss';

const cx = classNames.bind(styles);

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
  handleShowContacts: () => void;
}

const INITIAL_POPUP_WIDTH = 385;
const INITIAL_POPUP_HEIGHT = 235;
const INITIAL_POPUP_FOOTER_HEIGHT = 115;

// TODO: нужны ли отдельные попапы для транпорта и сотрудника

function EmployeePopup({ onCancel, markerCoords, handleShowContacts }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [showAdditional, setShowAdditional] = useState(false);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const isChosenEmployeeDataLoading = useSelector((state: RootState) => state.employee.isChosenEmployeeDataLoading);
  const isTrackerLoading = useSelector((state: RootState) => state.tracker.isLoading);
  const mapPopup = useSelector((state: RootState) => state.modal.mapPopup);
  const mapPopupCoords = useSelector((state: RootState) => state.modal.mapPopupCoords);

  const viewportInfo = useSelector((state: RootState) => state.map.viewportInfo);
  const { mountedNodeHeight, mountedNodeWidth } = viewportInfo;

  const emp = useSelector((state: RootState) => state.employee.chosenEmployeeData);
  const trackers = useSelector((state: RootState) => state.tracker.trackers);
  const handbooksDataPositions = useSelector((state: RootState) => state.handbooks.data.positions);
  const handbooksDataOrganizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const handbooksDataWorkObjects = useSelector((state: RootState) => state.handbooks.data.workObjects);

  const data = useMemo(() => {
    const pos = handbooksDataPositions?.find(p => p.id === emp?.attributes.positionId);
    const org = handbooksDataOrganizations?.find(o => o.id === emp?.attributes.organizationId);
    const dep = org?.relationships.departments?.data?.find(d => d.id === emp?.attributes.departmentId);
    const employeeWorkObjectsIds = emp?.relationships.workObjects.data.map(wObj => wObj.id) ?? [];
    const workObjects = handbooksDataWorkObjects.filter(hWObj => employeeWorkObjectsIds.includes(String(hWObj.id)));

    // No tracker message if tracker is null
    let trackerNumber = t('map-popup.employee.no-tracker.text');
    const trackerData = emp?.relationships.tracker.data;

    if (trackerData) {
      const tracker = trackers.find(tracker => trackerData.id === String(tracker.id));

      if (tracker) {
        trackerNumber = tracker.attributes.trackerNumber;
      }
    }

    return {
      id: emp?.id ?? '',
      simNumber: emp?.attributes.simNumber ?? '',
      passNumber: emp?.attributes.passNumber ?? '',
      firstName: emp?.attributes.firstName ?? '',
      secondName: emp?.attributes.secondName ?? '',
      lastName: emp?.attributes.lastName ?? '',
      position: pos
        ? getTranslateFromLanguageKey(pos.attributes.name, userLanguageKey)
        : t('map-popup.employee.no-position.text'),
      org: org
        ? getTranslateFromLanguageKey(org.attributes.name, userLanguageKey)
        : t('map-popup.employee.no-company.text'),
      department: dep
        ? getTranslateFromLanguageKey(dep.name, userLanguageKey)
        : t('map-popup.employee.no-subdivision.text'),
      workObjectNames: workObjects.length
        ? workObjects.map(wObj => getTranslateFromLanguageKey(wObj.attributes.name, userLanguageKey))
        : [],
      startDate: emp?.attributes.issuePassDate ? format(Date.parse(emp.attributes.issuePassDate), 'dd.MM.yyyy') : '',
      endDate: emp?.attributes.endPassDate ? format(Date.parse(emp.attributes.endPassDate), 'dd.MM.yyyy') : '',
      trackerNumber,
      additionalFields: emp?.attributes.additionalFields,
      showContactsRef: emp?.id === '1',
      iconSrc: emp?.attributes.icon ?? Employee,
    };
  }, [emp, trackers, handbooksDataPositions, handbooksDataOrganizations, handbooksDataWorkObjects, t, userLanguageKey]);

  const onCancelHandler = () => {
    dispatch(
      showMapPopup({
        type: '',
        coordinates: {
          x: 0,
          y: 0,
        },
      })
    );
    onCancel();
  };

  const openEditModal = () => {
    dispatch(setChosenEmployee(data.id));
    dispatch(showModal({ type: EMPLOYEE, which: '' }));
    onCancelHandler();
  };

  const toggleAdditional = () => {
    setShowAdditional(!showAdditional);
  };

  const openContactsTable = () => {
    handleShowContacts();
    onCancelHandler();
  };

  const mouseCoords = useMemo(
    () => (mapPopup === EMPLOYEE ? { x: mountedNodeWidth / 1.66, y: mountedNodeHeight / 2 } : markerCoords.mouseCoords),
    [mapPopup, markerCoords.mouseCoords, mountedNodeWidth, mountedNodeHeight]
  );

  const mapCoords = mapPopup === EMPLOYEE ? mapPopupCoords : markerCoords.mapCoords;

  // хедер попапа
  const headerPopup = (
    <div className={styles.popupHeader}>
      <HeaderIcon className={styles.popupHeaderIcon} />
      {t('monitoring.unit-card.header.object.label')}
    </div>
  );

  // контент попапа
  const contentPopup =
    isChosenEmployeeDataLoading || isTrackerLoading ? (
      <div className={styles.popupSpinner}>
        <Spinner />
      </div>
    ) : (
      <div className={styles.popupMain}>
        <div className={styles.popupSide}>
          <div className={styles.popupSidePhoto}>
            <img className={styles.popupSidePhotoImage} src={data.iconSrc} alt="Employee" />
            <div className={styles.popupSidePhotoTracker}>{data.trackerNumber}</div>
          </div>
          <div className={styles.popupSideButton} onClick={toggleAdditional}>
            <span className={styles.popupSideButtonText}>{showAdditional ? t(HIDE_TEXT) : t(MORE_TEXT)}</span>
            <DropArrow className={showAdditional ? styles.popupSideButtonIconUp : styles.popupSideButtonIconDown} />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div className={styles.popupContentNameWrap}>
            <div className={styles.popupContentName}>
              {data.lastName} {data.firstName} {data.secondName}
            </div>
            <div className={styles.popupContentPosition}>{data.position}</div>
            <div className={styles.popupContentPhone}>
              {data.simNumber || `${t('map-popup.employee.phone.text')} ${NA}`}
            </div>
          </div>
          <div className={styles.popupContentOrgWrap}>
            <div className={styles.popupContentOrg}>{data.org || `${t('map-popup.employee.company.text')} ${NA}`}</div>
            <div className={styles.popupContentStructure}>
              {data.department || `${t('map-popup.employee.subdivision.text')} ${NA}`}
            </div>
            <div className={styles.popupContentWorkObject}>
              {data.workObjectNames.length
                ? data.workObjectNames.map((wObjName, i) => <div key={`work-object-${wObjName}-${i}`}>{wObjName}</div>)
                : t('map-popup.employee.no-object.text')}
            </div>
          </div>

          <div className={styles.popupContentTicketWrap}>
            <div>
              {t('map-popup.employee.pass-number.text')}{' '}
              <span className={styles.popupContentTicket}>{data.passNumber || NA}</span>
            </div>
            <div>
              {t('map-popup.employee.issue.text')}{' '}
              <span className={styles.popupContentDate}>{data.startDate || NA}</span>{' '}
              {t('map-popup.employee.issue.pretext.text')}{' '}
              <span className={styles.popupContentDate}>{data.endDate || NA}</span>
            </div>
          </div>
        </div>
      </div>
    );

  // футер попапа
  const footerPopup = (
    <div
      className={cx(styles.popupAdditional, {
        [styles.popupAdditionalShow]: showAdditional,
        [styles.popupAdditionalHide]: !showAdditional,
      })}
    >
      <div className={styles.popupAdditionalWrap}>
        <div className={styles.popupAdditionalLabel}>{t('map-popup.employee.current-position.text')}</div>
        <div className={styles.popupAdditionalValue}>
          {t('map-popup.employee.coordinates.text')} {mapCoords.x.toFixed(8)}, {mapCoords.y.toFixed(8)}
        </div>
      </div>
      <div className={styles.popupAdditionalWrap}>
        <div className={styles.popupAdditionalLabel}>{t('map-popup.employee.temperature.text')}</div>
        <div className={styles.popupAdditionalValue}>N/A</div>
      </div>
      {data.showContactsRef && (
        <Fragment>
          <div className={styles.popupAdditionalWrap}>
            <div className={styles.popupAdditionalLabel}>{t('map-popup.employee.map-contacts-label.text')}</div>
            <div onClick={openContactsTable} className={cx(styles.popupAdditionalValue, styles.popupAdditionalRef)}>
              {t('map-popup.employee.map-contacts.text')}
            </div>
          </div>
          <div className={styles.popupAdditionalWrap}>
            <div className={styles.popupAdditionalLabel}>{t('map-popup.employee.outfit.text')}</div>
            <a
              className={cx(styles.popupAdditionalValue, styles.popupAdditionalRef)}
              href={'http://isobr.gis.visitech.local/nd/certificate/list?TASK_PROFILE=1'}
            >
              {t('map-popup.employee.go-outfit.text')}
            </a>
          </div>
        </Fragment>
      )}

      {data.additionalFields?.map((f, i) => {
        if (f.visible) {
          return (
            <div key={`additionalField-popup-${i}`} className={styles.popupAdditionalWrap}>
              <div className={styles.popupAdditionalLabel}>{f.label}</div>
              <div className={styles.popupAdditionalValue}>{f.value}</div>
            </div>
          );
        }
      })}
    </div>
  );

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      additionalHeaderButtons={[
        {
          name: 'openEmployeeParams',
          button: <OpenedEye onClick={openEditModal} className={styles.popupHeaderOpenModal} />,
        },
      ]}
      content={contentPopup}
      footer={footerPopup}
      initialWidth={INITIAL_POPUP_WIDTH}
      initialHeight={INITIAL_POPUP_HEIGHT}
      initialFooterHeight={INITIAL_POPUP_FOOTER_HEIGHT}
      showFooter={showAdditional}
      initialPosition={mouseCoords}
      resizeAxis="y"
      onCancel={onCancelHandler}
    />,
    appContentContainer
  );
}

export default React.memo(EmployeePopup);
