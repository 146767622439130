import React, { useState } from 'react';

import UsersList from './usersList/usersList';
import RolesList from './rolesList/rolesList';

import { AccountsPage } from './utils/types';

const Accounts: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<AccountsPage>(AccountsPage.Users);

  const changePage = (page: AccountsPage) => {
    setCurrentPage(page);
  };

  switch (currentPage) {
    case AccountsPage.Users:
      return <UsersList changePage={changePage} />;
    case AccountsPage.Roles:
      return <RolesList changePage={changePage} />;
    default:
      return null;
  }
};

export default Accounts;
