import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortEnd, SortEvent, SortStart } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { clearAvailableTemplateDataGroups, fetchDataGroups } from 'reducers/records';

import {
  AvailableChosenTemplateItem,
  AvailableDataTemplateType,
  ChosenDataTemplateType,
} from 'components/records/utils/types';
import { REPORT_CHOSEN_DATA_SORTING_MODE, REPORT_CHOSEN_DATA_TYPES } from 'components/records/utils/consts';
import { getCharFromCode } from 'components/records/utils/helpers';

import { ENTER_KEY_CODE } from 'utils/consts';

// используется для определения направления между двумя списками в handleCommonSortEnd
export enum SORTABLE_LIST_CONTAINER_DATA_SET {
  AVAILABLE = 'availableContainer',
  CHOSEN = 'chosenContainer',
}

export default function useDataTab() {
  const dispatch = useDispatch();

  const { templateWindow, chosenTemplate } = useSelector((state: RootState) => state.records);
  const { availableTemplateDataGroups } = templateWindow;

  useEffect(() => {
    if (!availableTemplateDataGroups.length) {
      dispatch(fetchDataGroups());
    }
    return () => {
      if (availableTemplateDataGroups.length) {
        dispatch(clearAvailableTemplateDataGroups());
      }
    };
  }, [dispatch, availableTemplateDataGroups]);

  // найденное поля
  const [searchValue, setSearchValue] = useState('');

  // флаг создания столбца с расчетом (Arithmetic type)
  const [isCreateNewChosenData, setIsCreateNewChosenData] = useState(false);

  // индексы (parent - категория и current - элемент категории) выбранных данных в списке слева (доступных для добавления)
  const [selectedAvailable, setSelectedAvailable] = useState<AvailableChosenTemplateItem>({
    parentId: -1,
    currentId: -1,
  });
  // индекс выбранного элемента данных в списке справа (выбранные данные)
  const [selectedChosen, setSelectedChosen] = useState(-1);

  // доступные данные
  const [availableData, setAvailableData] = useState<AvailableDataTemplateType[]>([]);
  // выбранные данные
  const [chosenData, setChosenData] = useState<ChosenDataTemplateType[]>([]);

  // тип контейнера, с которого производится перетаскивание при drag & drop
  const [fromContainer, setFromContainer] = useState<SORTABLE_LIST_CONTAINER_DATA_SET | null>(null);

  // input для столбца с расчетом
  const createNewChosenDataInputRef = useRef<HTMLInputElement>(null);

  // список required полей
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // инициализируем список доступных данных
  useEffect(() => {
    if (availableTemplateDataGroups.length) {
      setAvailableData(
        availableTemplateDataGroups.map(data => ({
          data,
          isExpanded: false,
          customId: `available-${data.id}`,
        }))
      );
    }
  }, [availableTemplateDataGroups]);

  // инициализируем списки доступных и выбранных данных при просмотре существующего шаблона
  useEffect(() => {
    if (chosenTemplate && availableTemplateDataGroups.length) {
      const { dataFields } = chosenTemplate.attributes;
      const newChosenData = dataFields.map((dataField, i) => {
        const parentIdx = availableTemplateDataGroups.findIndex(aData =>
          aData.fields.includes(dataField.dataType ?? '')
        );
        let parentSubIdx = 0;

        if (parentIdx > -1) {
          parentSubIdx = availableTemplateDataGroups[parentIdx].fields.findIndex(field => field === dataField.dataType);
        }

        return {
          ...dataField,
          customId: `chosen-${i}`,
          parentIdx,
          parentSubIdx,
        };
      });
      const dataTypes = dataFields.map(data => data.dataType);
      const newAvailableData = availableTemplateDataGroups.reduce((acc, curr) => {
        const filteredCurrentItem = curr.fields.filter(field => !dataTypes.includes(field));

        return [
          ...acc,
          {
            data: {
              ...curr,
              fields: filteredCurrentItem,
            },
            isExpanded: false,
            customId: `available-${curr.id}`,
          },
        ];
      }, [] as AvailableDataTemplateType[]);

      setChosenData(newChosenData);
      setAvailableData(newAvailableData);
    }
  }, [chosenTemplate, availableTemplateDataGroups]);

  // переводим фокус при наличии input с данными столбца с расчетом
  useEffect(() => {
    if (isCreateNewChosenData && createNewChosenDataInputRef) {
      const input = createNewChosenDataInputRef.current;
      if (input) {
        input.focus();
      }
    }
  }, [createNewChosenDataInputRef, isCreateNewChosenData]);

  // сортировка элементов в правом списке (выбранные данные)
  const handleSortEndChosenList = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const chosenDataMoved = [...arrayMove(chosenData, oldIndex, newIndex)].map((data, i) => ({
      ...data,
      mark: getCharFromCode(i),
    }));
    setChosenData(chosenDataMoved);
  };

  // поиск по доступным данным
  const handleSearch = (val: string) => setSearchValue(val);

  // обработчик развертывания/свертывания категорий в списке слева (доступные данные)
  const handleExpandAvailableData = (id: number) => {
    const foundIndex = availableData.findIndex(data => data.data.id === id);

    if (foundIndex >= 0) {
      const dataArr = [...availableData];
      const newData = {
        ...dataArr[foundIndex],
        isExpanded: !dataArr[foundIndex].isExpanded,
      };

      dataArr[foundIndex] = newData;
      setAvailableData([...dataArr]);
    }
  };

  // обработчик переноса данных из списка доступных в список выбранных
  const handleMoveRight = () => {
    if (selectedAvailable.parentId >= 0 && selectedAvailable.currentId >= 0) {
      const name = availableData[selectedAvailable.parentId].data.fields[selectedAvailable.currentId];
      const foundAvailableDataGroup = availableTemplateDataGroups.find(
        aTData => aTData.group === availableData[selectedAvailable.parentId].data.group
      );

      setChosenData([
        ...chosenData,
        {
          mark: getCharFromCode(chosenData.length),
          sort: null,
          type: REPORT_CHOSEN_DATA_TYPES.Scalar,
          dataType: name,
          customId: `chosen-${chosenData.length - 1}`,
          parentIdx: foundAvailableDataGroup ? foundAvailableDataGroup.id - 1 : selectedAvailable.currentId,
          parentSubIdx: foundAvailableDataGroup
            ? foundAvailableDataGroup.fields.indexOf(name)
            : selectedAvailable.parentId,
        },
      ]);

      const currentDataFields = [...availableData[selectedAvailable.parentId].data.fields];

      currentDataFields.splice(selectedAvailable.currentId, 1);

      const newAvailableData = availableData.map((aData, i) => {
        if (i === selectedAvailable.parentId) {
          return {
            ...aData,
            data: {
              ...aData.data,
              fields: currentDataFields,
            },
          };
        }
        return aData;
      });

      const filteredAvailableData = newAvailableData.filter(aData => !!aData.data.fields.length);

      if (newAvailableData.length !== filteredAvailableData.length) {
        setSelectedAvailable({ parentId: -1, currentId: -1 });
      }

      setAvailableData(filteredAvailableData);
    }
  };

  // обработчик переноса данных из списка выбранных в список доступных
  const handleMoveLeft = () => {
    if (selectedChosen >= 0) {
      const newChosenData = [...chosenData];

      const deletedChosen = newChosenData.splice(selectedChosen, 1)[0];

      if (deletedChosen?.parentIdx > -1) {
        const foundAvailableDataIndex = availableData.findIndex(aData => aData.data.id === deletedChosen.parentIdx + 1);

        if (foundAvailableDataIndex > -1) {
          const currentDataFields = [...availableData[foundAvailableDataIndex].data.fields];

          currentDataFields.splice(deletedChosen.parentSubIdx, 0, deletedChosen.dataType ?? '');

          const newAvailableData = availableData.map((aData, i) => {
            if (i === deletedChosen.parentIdx) {
              return {
                ...aData,
                data: {
                  ...aData.data,
                  fields: currentDataFields,
                },
              };
            }
            return aData;
          });

          setAvailableData(newAvailableData);
        } else {
          const foundAvailableDataGroup = availableTemplateDataGroups.find(
            aTData => aTData.id === deletedChosen?.parentIdx + 1
          );

          if (foundAvailableDataGroup) {
            const newAvailableDataItem: AvailableDataTemplateType = {
              data: {
                fields: [deletedChosen.dataType ?? ''],
                group: foundAvailableDataGroup.group,
                id: foundAvailableDataGroup.id,
              },
              isExpanded: false,
              customId: `available-${foundAvailableDataGroup.id}`,
            };
            const newAvailableData = [...availableData];

            newAvailableData.splice(deletedChosen.parentIdx, 0, newAvailableDataItem);
            setAvailableData(newAvailableData);
          }
        }
      }

      setChosenData(newChosenData.map((data, i) => ({ ...data, mark: getCharFromCode(i) })));
    }
  };

  // обработчик определения типа списка контейнера (доступные или выбранные) при перетаскивании элемента
  const handleCommonSortStart = (sortStart: SortStart, event: SortEvent) => {
    const fromContainer = (event.target as HTMLElement).closest('ul')?.dataset.sortcontainer;

    if (fromContainer) {
      setFromContainer(fromContainer as SORTABLE_LIST_CONTAINER_DATA_SET);
    }
  };

  // общий обработчик завершения перетаскивания элементов
  const handleCommonSortEnd = (sortEnd: SortEnd, event: SortEvent) => {
    const toContainer = (event.target as HTMLElement).closest('ul')?.dataset.sortcontainer;
    const { newIndex, oldIndex } = sortEnd;

    if (fromContainer && toContainer) {
      switch (fromContainer) {
        case SORTABLE_LIST_CONTAINER_DATA_SET.AVAILABLE:
          // available -> chosen
          if (toContainer === SORTABLE_LIST_CONTAINER_DATA_SET.CHOSEN) {
            return handleMoveRight();
          }
          break;

        case SORTABLE_LIST_CONTAINER_DATA_SET.CHOSEN:
          // chosen -> available
          if (toContainer === SORTABLE_LIST_CONTAINER_DATA_SET.AVAILABLE) {
            return handleMoveLeft();
          }
          // chosen -> chosen
          if (toContainer === SORTABLE_LIST_CONTAINER_DATA_SET.CHOSEN) {
            return handleSortEndChosenList({ oldIndex, newIndex });
          }
          break;

        default:
          break;
      }
    }
  };

  // обработчик установки выбранного элемента в списке слева (доступные данные)
  const handleMouseDownAvailableItem = (value: AvailableChosenTemplateItem) => {
    if (value.parentId !== selectedAvailable.parentId || value.currentId !== selectedAvailable.currentId) {
      setSelectedAvailable(value);
    }
  };

  // обработчик установки выбранного элемента в списке справа (выбранные данные)
  const handleMouseDownChosenItem = (index: number) => {
    setSelectedChosen(index);
  };

  // обработчик установки флага для добавления столбца с расчетом
  const handleIsCreateNewChosenData = () => {
    if (!isCreateNewChosenData) {
      setIsCreateNewChosenData(true);
    }
  };

  // функция добавления столбца с расчетом в список выбранных данных
  function addChosenData(e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) {
    const name = e.currentTarget.value;

    if (!name) {
      return setIsCreateNewChosenData(false);
    }

    const foundChosenItem = chosenData.find(data => data.dataType === name);

    if (foundChosenItem) {
      return setIsCreateNewChosenData(false);
    }
    setChosenData([
      ...chosenData,
      {
        mark: getCharFromCode(chosenData.length),
        type: REPORT_CHOSEN_DATA_TYPES.Arithmetic,
        expression: name,
        sort: null,
        customId: `chosen-${chosenData.length - 1}`,
        parentIdx: -1,
        parentSubIdx: -1,
      },
    ]);
    setIsCreateNewChosenData(false);
  }

  // обработчик добавления столбца с расчетом в список выбранных данных при нажатии <Enter>
  const handleKeyDownCreateNewChosenData = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      addChosenData(e);
    }
  };

  // обработчик отмены фокуса с input-поля столбца с расчетом
  const handleBlurCreateNewChosenData = (e: React.FocusEvent<HTMLInputElement>) => {
    addChosenData(e);
  };

  // обработчик установки сортировок столбцов данных в списке справа (выбранные данные)
  const handleClickChosenItemBtnSorting = (evt: React.MouseEvent, index: number) => {
    const newChosenData = [...chosenData];
    const currentSortingMode = newChosenData[index].sort;
    const foundAlreadySortingIndex = newChosenData.findIndex(data => data.sort);
    let newSortingMode = null;

    if (foundAlreadySortingIndex < 0 || foundAlreadySortingIndex === index) {
      switch (currentSortingMode) {
        case null:
          newSortingMode = REPORT_CHOSEN_DATA_SORTING_MODE.ASC;
          break;
        case REPORT_CHOSEN_DATA_SORTING_MODE.ASC:
          newSortingMode = REPORT_CHOSEN_DATA_SORTING_MODE.DESC;
          break;
        case REPORT_CHOSEN_DATA_SORTING_MODE.DESC:
          newSortingMode = null;
          break;
        default:
          break;
      }
    } else {
      newChosenData[foundAlreadySortingIndex].sort = null;
      newSortingMode = REPORT_CHOSEN_DATA_SORTING_MODE.ASC;
    }
    newChosenData[index].sort = newSortingMode;
    setChosenData(newChosenData);
  };

  // валидация введенных данных
  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!chosenData.length) {
      newInvalidFields.push('chosenData');
    }
    return newInvalidFields;
  };

  // определение измененных данных
  const hasChanges = () => {
    let comparedChosenData: ChosenDataTemplateType[] = [];

    if (chosenTemplate) {
      const { dataFields } = chosenTemplate.attributes;

      comparedChosenData = dataFields.map((dataField, i) => {
        const parentIdx = availableTemplateDataGroups.findIndex(aData =>
          aData.fields.includes(dataField.dataType ?? '')
        );
        let parentSubIdx = 0;

        if (parentIdx > -1) {
          parentSubIdx = availableTemplateDataGroups[parentIdx].fields.findIndex(field => field === dataField.dataType);
        }

        return {
          ...dataField,
          customId: `chosen-${i}`,
          parentIdx,
          parentSubIdx,
        };
      });
    }

    return !isEqual(chosenData, comparedChosenData);
  };

  return {
    states: {
      searchValue,
      selectedAvailable,
      selectedChosen,
      chosenData,
      availableData,
      isCreateNewChosenData,
    },
    handlers: {
      handleExpandAvailableData,
      handleMoveRight,
      handleMoveLeft,
      handleMouseDownAvailableItem,
      handleMouseDownChosenItem,
      handleClickChosenItemBtnSorting,
      handleIsCreateNewChosenData,
      handleKeyDownCreateNewChosenData,
      handleBlurCreateNewChosenData,
      handleSearch,
      handleSortEndChosenList,
      handleCommonSortEnd,
      handleCommonSortStart,
    },
    refs: {
      createNewChosenDataInputRef,
    },
    hasChanges,
    validateRequiredFields,
    setInvalidFields,
    invalidFields,
  };
}
