import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Rnd, RndResizeCallback, RndDragCallback } from 'react-rnd';
import cs from 'classnames';

import { ReactComponent as Close } from 'assets/img/button/popup_close.svg';

import { RootState } from 'reducers';

import { APP_CONTENT_ID } from 'utils/consts';
import { getInboundRndPosition } from 'utils/common/position';

import ResizableHandle from './components/resizableHandle/resizableHandle';

import styles from './markerPopup.module.scss';

type AdditionalButton = {
  name: string;
  button: React.ReactNode;
};

type Size = {
  width: number;
  height: number;
};

type Position = {
  x: number;
  y: number;
};

type Axis = 'x' | 'y' | 'both' | 'none';

type Props = Partial<{
  header: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
  additionalHeaderButtons: AdditionalButton[];
  initialWidth: number;
  initialHeight: number;
  initialFooterHeight: number;
  minWidth: number;
  minHeight: number;
  showFooter: boolean;
  initialPosition: Position;
  resizeAxis: Axis;
  customClassNames: Partial<{
    header: string;
    content: string;
    footer: string;
  }>;
  onCancel: () => void;
  getParams: (size: Size) => void;
}>;

const DEFAULT_POPUP_WIDTH = 300;

const hideDefaultResizeHandlerStyle: React.CSSProperties = {
  width: 0,
  height: 0,
  position: 'static',
};

const MarkerPopup: React.FC<Props> = ({
  header,
  content,
  footer,
  additionalHeaderButtons,
  initialWidth = DEFAULT_POPUP_WIDTH,
  minWidth,
  minHeight,
  initialHeight = 0,
  initialFooterHeight,
  showFooter,
  initialPosition,
  resizeAxis = 'none',
  customClassNames = {},
  onCancel,
  getParams,
}) => {
  const appHeaderHeight = useSelector((state: RootState) => state.common.appHeaderHeight);
  const appFooterHeight = useSelector((state: RootState) => state.common.appFooterHeight);

  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [size, setSize] = useState<Size>({ width: initialWidth, height: initialHeight });
  const [minConstraints, setMinConstraints] = useState<Size>({
    width: minWidth || initialWidth,
    height: minHeight || initialHeight,
  });

  const isFirstRender = useRef(true);

  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const getNextHeight = () => {
    let nextHeight = 0;

    if (initialHeight) {
      nextHeight += initialHeight;
    } else {
      if (headerRef.current) {
        nextHeight += headerRef.current.getBoundingClientRect().height;
      }

      if (contentRef.current) {
        nextHeight += contentRef.current.getBoundingClientRect().height;
      }
    }

    if (showFooter) {
      if (initialFooterHeight) {
        nextHeight += initialFooterHeight;
      } else if (footerRef.current) {
        nextHeight += footerRef.current.getBoundingClientRect().height;
      }
    }

    if (resizeAxis !== 'none') {
      nextHeight += 10;
    }

    return nextHeight;
  };

  // Compute initial height and position
  useEffect(() => {
    const nextHeight = getNextHeight();

    const { x, y } = getInboundRndPosition(
      initialPosition?.x || 0,
      initialPosition?.y || 0,
      nextHeight,
      initialWidth,
      appHeaderHeight,
      appFooterHeight
    );

    setSize({ width: initialWidth, height: nextHeight });
    setMinConstraints({ width: minWidth || initialWidth, height: minHeight || nextHeight });
    setPosition({ x, y });
  }, [initialPosition, initialWidth, appHeaderHeight, appFooterHeight, initialFooterHeight]);

  // Compute height and position after footer toggle
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const nextHeight = getNextHeight();
      const nextWidth = size.width;

      const { x, y } = getInboundRndPosition(
        position.x,
        position.y + appHeaderHeight,
        nextHeight,
        nextWidth,
        appHeaderHeight,
        appFooterHeight
      );

      setSize({ width: nextWidth, height: nextHeight });
      setMinConstraints({ width: minWidth || nextWidth, height: minHeight || nextHeight });
      setPosition({ x, y });
    }
  }, [showFooter]);

  const onResizeStop: RndResizeCallback = (e, dir, el, delta) => {
    const nextWidth = size.width + delta.width;
    const nextHeight = size.height + delta.height;

    setSize({
      width: nextWidth,
      height: nextHeight,
    });

    if (getParams) {
      getParams({
        width: nextWidth,
        height: nextHeight,
      });
    }
  };

  const onDragStop: RndDragCallback = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <Rnd
      className={styles.container}
      dragHandleClassName={styles.header__title}
      resizeHandleComponent={{
        bottomRight: resizeAxis === 'both' ? <ResizableHandle /> : undefined,
        bottom: resizeAxis === 'y' ? <ResizableHandle /> : undefined,
        right: resizeAxis === 'x' ? <ResizableHandle /> : undefined,
      }}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      position={position}
      size={size}
      enableResizing={{
        bottomRight: resizeAxis === 'both',
        bottom: resizeAxis === 'y',
        right: resizeAxis === 'x',
      }}
      resizeHandleStyles={{
        bottom: hideDefaultResizeHandlerStyle,
        right: hideDefaultResizeHandlerStyle,
        bottomRight: hideDefaultResizeHandlerStyle,
      }}
      minWidth={minConstraints.width}
      minHeight={minConstraints.height}
      bounds={`#${APP_CONTENT_ID}`}
    >
      {header && (
        <div className={cs(styles.header, customClassNames.header)} ref={headerRef}>
          <div className={styles.header__title}>{header}</div>
          <div className={styles.header__buttons}>
            {additionalHeaderButtons?.map(addBtn => (
              <div key={addBtn.name} className={styles.header__button}>
                {addBtn.button}
              </div>
            ))}

            <Close onClick={onCancel} className={styles.header__button} />
          </div>
        </div>
      )}

      {content && (
        <div className={cs(styles.content, customClassNames.content)} ref={contentRef}>
          {content}
        </div>
      )}

      {footer && showFooter && (
        <div className={cs(styles.footer, customClassNames.footer)} ref={footerRef}>
          {footer}
        </div>
      )}

      {resizeAxis !== 'none' && <div className={styles.spaceForResizeHandler} />}
    </Rnd>
  );
};

export default MarkerPopup;
