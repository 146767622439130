import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { NotificatedHowType } from 'components/notifications/utils/types';
import { Option } from 'components/common/select/select';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { defaultNotificationAttributes as defaults, NOTIFICATED_HOW } from 'components/notifications/utils/consts';
import { NOTIFICATION_TYPES_ENUM, NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';

import { RootState } from 'reducers';
import { fetchAccountsShort } from 'reducers/accounts';
import { fetchGeozonesGroups, fetchGeozonesShort } from 'reducers/geozones';
import { fetchUnitsShort } from 'reducers/trackableUnits';
import { ActiveUserType, ChosenNotificationAttributes } from 'reducers/notifications/interface';
import { setChosenNotificationType } from 'reducers/notifications';

import AccessEntity from 'utils/accessEntity';
import { isEqualObjectsNoDeep } from 'utils/isEqualObjectsNoDeep';
import { getTypeNotificationFromId } from '../utils/helpers';

type mainInfoStateType = {
  isActive: boolean;
  name: string;
  isPopupPushOn: boolean;
  isEmailPushOn: boolean;
  isSmsPushOn: boolean;
  notificationType: NOTIFICATION_TYPES_ENUM | null;
  text: string;
  isGenerateReport: boolean;
  reportTemplateId: number;
  reportStartDate: string;
  reportEndDate: string;
  isFoulFixing: boolean;
};

export default function useMainInfo() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [name, setName] = useState(defaults.name);
  const [typeNotification, setTypeNotification] = useState(defaults.notificationType);

  const [isActive, setIsActive] = useState(defaults.isActive);
  const [isPopupPushOn, setIsPopupPushOn] = useState(defaults.isEmailPushOn);
  const [isEmailPushOn, setIsEmailPushOn] = useState(defaults.isEmailPushOn);
  const [isSmsPushOn, setIsSmsPushOn] = useState(defaults.isSmsPushOn);

  const [chosenUsers, setChosenUsers] = useState<number[]>([]);
  const [activeUsers, setActiveUsers] = useState<number[]>([]);

  const [isGenerateReport, setIsGenerateReport] = useState(defaults.isGenerateReport);
  const [reportTemplateId, setReportTemplateId] = useState(defaults.reportTemplateId);
  const [reportStartDate, setReportStartDate] = useState(defaults.reportStartDate);
  const [reportEndDate, setReportEndDate] = useState(defaults.reportEndDate);

  const [text, setText] = useState(defaults.text);

  const [isFoulFixing, setIsFoulFixing] = useState(defaults.isFoulFixing);

  const chosenNotification = useSelector((state: RootState) => state.notifications.chosenNotification);

  const userPermission = useSelector((state: RootState) => state.user.permissions.users);
  const userAccess = useMemo(() => new AccessEntity(userPermission), [userPermission]);
  const accountsShortList = useSelector((state: RootState) => state.account.accountsShortList);

  const notificationTypesForSelect = Object.keys(NOTIFICATION_TYPES_ENUM_NAMES).map((typeKey, i) => ({
    value: i + 1,
    label: t(NOTIFICATION_TYPES_ENUM_NAMES[typeKey as keyof typeof NOTIFICATION_TYPES_ENUM_NAMES]),
  }));

  useEffect(() => {
    (async () => {
      await dispatch(fetchAccountsShort());
      await dispatch(fetchUnitsShort());
      await dispatch(fetchGeozonesShort());
      await dispatch(fetchGeozonesGroups());
    })();
  }, [dispatch]);

  useEffect(() => {
    if (chosenNotification) {
      const { relationships } = chosenNotification;
      setActiveUsers(relationships.activeUsers.map(unit => unit.id));
    }
  }, [chosenNotification]);

  useEffect(() => {
    dispatch(setChosenNotificationType(typeNotification));
  }, [dispatch, typeNotification]);

  useEffect(() => {
    if (chosenNotification) {
      const attr: ChosenNotificationAttributes = chosenNotification.attributes;
      const activeUsers: ActiveUserType[] = chosenNotification.relationships.activeUsers;

      setIsActive(attr.isActive);
      setName(attr.name);
      setIsPopupPushOn(attr.isPopupPushOn);
      setIsEmailPushOn(attr.isEmailPushOn);
      setIsSmsPushOn(attr.isSmsPushOn);
      setText(attr.text);
      setTypeNotification(attr.notificationType);
      setIsGenerateReport(attr.isGenerateReport);
      setReportTemplateId(attr.reportTemplateId);
      setReportStartDate(attr.reportStartDate);
      setReportEndDate(attr.reportEndDate);
      setIsFoulFixing(attr.isFoulFixing);
      if (accountsShortList.length && activeUsers.length) {
        setChosenUsers(activeUsers.map(user => user.id));
      }
    }
  }, [chosenNotification, accountsShortList]);

  const accountsShortListForSelect: Option[] = useMemo(() => {
    if (userAccess.isAllowRead() && accountsShortList.length) {
      return accountsShortList.map((acc, i) => ({
        value: i + 1,
        label: `${acc.attributes.firstName} ${acc.attributes.secondName} ${acc.attributes.lastName}`,
        data: { ...acc },
      }));
    }
    return [];
  }, [userAccess, accountsShortList]);

  const reportFromRef = React.createRef();
  const reportToRef = React.createRef();

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const changedItems: mainInfoStateType = {
    isActive,
    name,
    isPopupPushOn,
    isEmailPushOn,
    isSmsPushOn,
    notificationType: typeNotification,
    text,
    isGenerateReport,
    reportTemplateId,
    reportStartDate,
    reportEndDate,
    isFoulFixing,
  };

  const handleChangeStartDate = (date: Date) => setReportStartDate(date.toISOString());
  const handleChangeEndDate = (date: Date) => setReportEndDate(date.toISOString());

  const handleChangeName = (name: string) => setName(name);
  const handleChangeText = (text: string) => setText(text);

  const handleChangeTurn = () => setIsActive(!isActive);

  const handleChangeTypeTurnNotification = (type: NotificatedHowType) => {
    const { popup, email, sms } = NOTIFICATED_HOW;

    switch (type) {
      case popup:
        setIsPopupPushOn(!isPopupPushOn);
        break;
      case email:
        setIsEmailPushOn(!isEmailPushOn);
        break;
      case sms:
        setIsSmsPushOn(!isSmsPushOn);
        break;
      default:
        break;
    }
  };

  const handleChangeTypeNotification = (value: string) => {
    setTypeNotification(getTypeNotificationFromId(Number(value) ?? 0));
    setIsFoulFixing(false);
  };

  const handleChangeUsers = (values: ValuesType) => {
    setChosenUsers(values.map(value => (value.data?.id as number) || -1));
  };

  const handleChangeGeneratedReport = () => setIsGenerateReport(!isGenerateReport);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!name) {
      newInvalidFields.push('name');
    }
    if (!typeNotification) {
      newInvalidFields.push('typeNotification');
    }
    if ((isEmailPushOn || isSmsPushOn) && !chosenUsers.length) {
      newInvalidFields.push('chosenUsers');
    }
    if (!text) {
      newInvalidFields.push('text');
    }
    if (isGenerateReport && !reportTemplateId) {
      newInvalidFields.push('reportTemplateId');
    }
    if (isGenerateReport && !reportStartDate) {
      newInvalidFields.push('reportStartDate');
    }
    if (isGenerateReport && !reportEndDate) {
      newInvalidFields.push('reportEndDate');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    let attr = null;
    let changedItemsChosen: mainInfoStateType | null = null;
    if (chosenNotification) {
      attr = chosenNotification.attributes;
    } else {
      attr = defaults;
    }
    changedItemsChosen = {
      isActive: attr.isActive,
      name: attr.name,
      isEmailPushOn: attr.isEmailPushOn,
      isPopupPushOn: attr.isPopupPushOn,
      isSmsPushOn: attr.isSmsPushOn,
      notificationType: attr.notificationType,
      text: attr.text,
      isGenerateReport: attr.isGenerateReport,
      reportTemplateId: attr.reportTemplateId,
      reportStartDate: attr.reportStartDate,
      reportEndDate: attr.reportEndDate,
      isFoulFixing: attr.isFoulFixing,
    };
    if (chosenUsers.length !== activeUsers.length) {
      return true;
    }
    return !isEqualObjectsNoDeep(
      changedItemsChosen as unknown as Record<string, unknown>,
      changedItems as unknown as Record<string, unknown>
    );
  };

  return {
    states: {
      name,
      typeNotification,
      isActive,
      isPopupPushOn,
      isEmailPushOn,
      isSmsPushOn,
      chosenUsers,
      activeUsers,
      text,
      notificationTypesForSelect,
      accountsShortListForSelect,
      isGenerateReport,
      reportTemplateId,
      reportStartDate,
      reportEndDate,
    },
    refs: {
      reportFromRef,
      reportToRef,
    },
    handlers: {
      handleChangeStartDate,
      handleChangeEndDate,
      handleChangeName,
      handleChangeText,
      handleChangeTurn,
      handleChangeTypeTurnNotification,
      handleChangeTypeNotification,
      handleChangeUsers,
      handleChangeGeneratedReport,
    },
    isFoulFixing,
    setIsFoulFixing,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
    changedItems,
  };
}
