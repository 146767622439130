import { formatInTimeZone } from 'date-fns-tz';

const formatTime = (value: number) => {
  return value < 10 ? '0' + value : value;
};

const formatFullYear = (year: number) => {
  if (year < 2000) {
    return year;
  }

  return year - 2000;
};

export const formatToDDMMYYHHMM = (date: Date) =>
  `${date.getDate()}.${formatTime(date.getMonth() + 1)}.${formatFullYear(date.getFullYear())} ${formatTime(
    date.getHours()
  )}:${formatTime(date.getMinutes())}`;

/**
 * Converts timezone offset to string format
 * @param offset integer number in milliseconds (will be rounded to minutes)
 * @returns string in format '{+|-}hh:mm'
 */
export function timezoneOffsetToString(offset: number): string {
  const sign = offset < 0 ? '-' : '+';

  const totalMilliseconds = Math.abs(offset);
  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const hoursString = String(hours).padStart(2, '0');
  const minutesString = String(minutes).padStart(2, '0');

  return `${sign}${hoursString}:${minutesString}`;
}

/**
 * Returns current time for given timezone in format '00:00 (+00)'
 * or '00:00 (+0030)' if offset contains minutes
 * @param timezone
 * @returns string
 */
export function getCurrentTimeWithOffset(timezone: string): string {
  return formatInTimeZone(new Date(), timezone, 'HH:mm (x)');
}
