export default {
  en: {
    'map-popup.transport.header.text': 'Transport card',
    'map-popup.transport.no-company.text': 'Without organization',
    'map-popup.transport.no-subdivision.text': 'Without subdivision',
    'map-popup.transport.vehicle-type.text': 'Vehicle type:',
    'map-popup.transport.vehicle-color.text': 'Color:',
    'map-popup.transport.year-issue.text': 'Year of issue:',
    'map-popup.transport.company.text': 'Company:',
    'map-popup.transport.subdivision.text': 'Subdivision:',
    'map-popup.transport.driver.text': 'Driver:',
    'map-popup.transport.fuel-type.text': 'Fuel type:',
    'map-popup.transport.carrying-capacity.text': 'Carrying capacity, t:',
    'map-popup.transport.current-position.text': 'Current position',
    'map-popup.transport.coords.text': 'Coordinates:',
    'map-popup.transport.no-tracker.text': 'No tracker',
  },
  ru: {
    'map-popup.transport.header.text': 'Карточка транспорта',
    'map-popup.transport.no-company.text': 'Без организации',
    'map-popup.transport.no-subdivision.text': 'Без подразделения',
    'map-popup.transport.vehicle-type.text': 'Тип ТС:',
    'map-popup.transport.vehicle-color.text': 'Цвет:',
    'map-popup.transport.year-issue.text': 'Год выпуска:',
    'map-popup.transport.company.text': 'Организация:',
    'map-popup.transport.subdivision.text': 'Структурное подразделение:',
    'map-popup.transport.driver.text': 'Водитель:',
    'map-popup.transport.fuel-type.text': 'Вид топлива:',
    'map-popup.transport.carrying-capacity.text': 'Грузоподъемность, т:',
    'map-popup.transport.current-position.text': 'Текущее положение',
    'map-popup.transport.coords.text': 'Координаты:',
    'map-popup.transport.no-tracker.text': 'Без трекера',
  },
  zh: {
    'map-popup.transport.header.text': '交通卡',
    'map-popup.transport.no-company.text': '无组织',
    'map-popup.transport.no-subdivision.text': '无细分',
    'map-popup.transport.vehicle-type.text': '机器类型：',
    'map-popup.transport.vehicle-color.text': '颜色：',
    'map-popup.transport.year-issue.text': '发行年份：',
    'map-popup.transport.company.text': '公司：',
    'map-popup.transport.subdivision.text': '分割：',
    'map-popup.transport.driver.text': '司机：',
    'map-popup.transport.fuel-type.text': '汽油类型：',
    'map-popup.transport.carrying-capacity.text': '承载能力，吨：',
    'map-popup.transport.current-position.text': '当前位置',
    'map-popup.transport.coords.text': '协调：',
    'map-popup.transport.no-tracker.text': '没有跟踪器',
  },
  ar: {
    'map-popup.transport.header.text': 'بطاقة النقل',
    'map-popup.transport.no-company.text': 'بدون مؤسسة',
    'map-popup.transport.no-subdivision.text': 'بدون شعبة',
    'map-popup.transport.vehicle-type.text': 'نوع وسيلة النقل:',
    'map-popup.transport.vehicle-color.text': 'اللون:',
    'map-popup.transport.year-issue.text': 'سنة الإصدار:',
    'map-popup.transport.company.text': 'مؤسسة:',
    'map-popup.transport.subdivision.text': 'شعبة:',
    'map-popup.transport.driver.text': 'سائق:',
    'map-popup.transport.fuel-type.text': 'نوع الوقود:',
    'map-popup.transport.carrying-capacity.text': 'قدرة على الحمولة، طن:',
    'map-popup.transport.current-position.text': 'حالة جارية',
    'map-popup.transport.coords.text': 'الإحداثيات:',
    'map-popup.transport.no-tracker.text': 'بدون تعقب',
  },
  de: {
    'map-popup.transport.header.text': 'Transportkarte',
    'map-popup.transport.no-company.text': 'Ohne Organisation',
    'map-popup.transport.no-subdivision.text': 'Ohne Unterteilung',
    'map-popup.transport.vehicle-type.text': 'Fahrzeugtyp:',
    'map-popup.transport.vehicle-color.text': 'Farbe:',
    'map-popup.transport.year-issue.text': 'Baujahr:',
    'map-popup.transport.company.text': 'Organisation:',
    'map-popup.transport.subdivision.text': 'Strukturelle Unterteilung:',
    'map-popup.transport.driver.text': 'Treiber:',
    'map-popup.transport.fuel-type.text': 'Kraftstoffart:',
    'map-popup.transport.carrying-capacity.text': 'Tragfähigkeit, t:',
    'map-popup.transport.current-position.text': 'aktuelle Position',
    'map-popup.transport.coords.text': 'Koordinaten:',
    'map-popup.transport.no-tracker.text': 'Ohne Tracker',
  },
};
