import { Dispatch } from 'react';
import { HandbookEntity } from 'reducers/handbooks/interface';
import { Unit } from 'reducers/trackableUnits/interface';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';

export type Handlers = {
  [key: string]: Dispatch<string>;
};

export type EmployeeTabInfoHandlers = {
  handleOrgChange: (val: string) => void;
  handleStructureChange: (val: string) => void;
  handlePositionChange: (val: string) => void;
  handleWorkObjectChange: (value: ValuesType) => void;
  handleTrackerChange: (val: string) => void;
  setIcon: (val: string | null) => void;
} & { [key: string]: Dispatch<string> };

export type TransportTabInfoHandlers = {
  handleModelChange: (val: string) => void;
  handleTrackerChange: (val: string) => void;
  handleTransportTypeChange: (val: string) => void;
  handleBrandChange: (val: string) => void;
  handleOrgChange: (val: string) => void;
  handleFuelTypeChange: (val: string) => void;
  handleDriverChange: (val: string) => void;
  handleStructureChange: (val: string) => void;
  handleColorChange: (val: string) => void;
  setIcon: (val: string | null) => void;
} & { [key: string]: Dispatch<string> };

export enum ActiveTab {
  info = 'info',
  additional = 'additional',
  stat = 'stat',
}

export interface Field {
  label: string;
  value: string;
  visible: boolean;
}

export type TransformedUnits = {
  [key: string]: {
    name: string;
    departments: {
      [key: string]: {
        name: string;
        positions: {
          [key: string]: {
            name: string;
            units: Unit[];
          };
        };
      };
    };
  };
};

export type HierarchyItems = {
  organizations: HandbookEntity[];
  departments: HandbookEntity[];
  positions: HandbookEntity[];
};

export type Structure = {
  id: number;
  uniqueKey: string;
  name: string;
  units: Unit[];
  substructures: {
    [key: string]: Structure;
  };
};

export type TransformedUnitsData = {
  [key: string]: Structure;
};
