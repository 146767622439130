import React, { Dispatch, ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { ReactComponent as MainIcon } from 'assets/img/records/main_tab_icon.svg';
import { ReactComponent as DataIcon } from 'assets/img/records/data_tab_icon.svg';
import { ReactComponent as ConditionIcon } from 'assets/img/records/conditions_tab_icon.svg';

import { ACTIVE_TABS } from 'components/records/utils/consts';

import styles from './tabsModal.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface BodyProps {
  activeTab: ACTIVE_TABS;
  setActiveTab: Dispatch<ACTIVE_TABS>;
  children: ReactNode;
}

export const TabsModal = ({ activeTab, setActiveTab, children }: BodyProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <div
          className={activeTab === ACTIVE_TABS.main ? cx(styles.tab, styles.tabActive) : styles.tab}
          onClick={() => setActiveTab(ACTIVE_TABS.main)}
        >
          <MainIcon
            className={activeTab === ACTIVE_TABS.main ? cx(styles.tabIcon, styles.tabIconActive) : styles.tabIcon}
          />
          <span className={styles.TabLabel}>{t('records.card.template-create.tabs.general.text')}</span>
        </div>
        <div
          className={activeTab === ACTIVE_TABS.data ? cx(styles.tab, styles.tabActive) : styles.tab}
          onClick={() => setActiveTab(ACTIVE_TABS.data)}
        >
          <DataIcon
            className={cx(
              activeTab === ACTIVE_TABS.data ? cx(styles.tabIcon, styles.tabIconActive) : styles.tabIcon,
              styles.tabIconData
            )}
          />
          <span className={styles.TabLabel}>{t('records.card.template-create.tabs.data.text')}</span>
        </div>
        <div
          className={
            activeTab === ACTIVE_TABS.condition
              ? cx(styles.tab, styles.tabActive, styles.condition)
              : cx(styles.tab, styles.condition)
          }
          onClick={() => setActiveTab(ACTIVE_TABS.condition)}
        >
          <ConditionIcon
            className={
              activeTab === ACTIVE_TABS.condition
                ? cx(styles.tabIconCondition, styles.tabIconActiveCondition)
                : styles.tabIconCondition
            }
          />
          <span className={styles.tabLabel}>{t('records.card.template-create.tabs.conditions.text')}</span>
        </div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
