import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import turfBbox from '@turf/bbox';

import { Feature, Map } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Extent } from 'ol/extent';
import { easeOut } from 'ol/easing';

import { RootState } from 'reducers';
import { removeMarkerNotificationLog } from 'reducers/notifications';

import { SelectedNotificationMarker } from '../map.types';

import { MAP_LAYERS_Z_INDEX } from 'utils/consts';
import {
  createClusterSource,
  createGeoNotificationMarker,
  createNotificationVectorLayer,
  getIconByNotificationType,
  geoJSON,
} from '../utils';
import { REPORTS_MAP_ROUTE } from './useReport';
import { getFullDateFromStr } from '../../../utils/getFullDateFromStr';
import { getIdFromTypeNotification } from 'components/notifications/utils/helpers';

// создание списка маркеров для отображения
function makeGeoMarkers(markers: SelectedNotificationMarker[]) {
  const featuresNotifications: Feature[] = [];

  for (let i = 0; i < markers.length; i++) {
    if (!markers[i].data) {
      continue;
    }
    const notificationMarker: Feature = createGeoNotificationMarker(fromLonLat(markers[i].data));
    notificationMarker.setProperties({
      iconName: markers[i].iconName,
      info: markers[i].info,
      mapCoords: markers[i].data,
    });
    featuresNotifications.push(notificationMarker);
  }

  return featuresNotifications;
}

const vectorNotificationSourceMarkers: VectorSource = new VectorSource({});
const vectorNotificationCluster = createClusterSource(vectorNotificationSourceMarkers);
const notificationMarkersMapLayer = createNotificationVectorLayer(
  vectorNotificationCluster,
  MAP_LAYERS_Z_INDEX.NOTIFICATIONS
);

export const useNotificationsMarkers = (map: Map) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    notifications,
    selectedNotifications,
    notificationUnreadLogs,
    historyLogs,
    isTableHistoryLogsShow,
    selectedHistoryLogs,
  } = useSelector((state: RootState) => state.notifications);

  useEffect(() => {
    map.addLayer(notificationMarkersMapLayer);

    return () => {
      vectorNotificationSourceMarkers.clear();
      map.removeLayer(notificationMarkersMapLayer);
    };
  }, [map]);

  // создание свойств дял маркеров нотификаций по их логам
  const makeMapMarkers = useCallback(() => {
    const notificationMarkers = selectedNotifications.map(selNotification => {
      const notification = notifications.find(n => n.id === selNotification);

      if (!notification) {
        return [] as SelectedNotificationMarker[];
      }

      const unreadLogs = notificationUnreadLogs[Number(notification.id)];

      if (unreadLogs) {
        const markers: SelectedNotificationMarker[] = [];

        for (const uLog of unreadLogs) {
          const coords = uLog.attributes.coords.split(',').map(c => Number(c));
          if (coords.some(c => isNaN(c))) {
            continue;
          }
          markers.push({
            info: {
              logId: uLog.id,
              text: notification.attributes.name,
            },
            iconName: getIconByNotificationType(notification.attributes.notificationType),
            data: coords,
            notificationId: Number(notification.id),
          });
        }
        return markers;
      }
      return [] as SelectedNotificationMarker[];
    });
    return notificationMarkers.flat(1);
  }, [notifications, selectedNotifications, notificationUnreadLogs]);

  const notificationMarkers = useMemo(() => makeMapMarkers(), [makeMapMarkers]);

  useEffect(() => {
    if (selectedNotifications.length && !historyLogs.length) {
      dispatch(removeMarkerNotificationLog());
    }
  }, [dispatch, selectedNotifications, historyLogs]);

  // отображение на карте маркеров выбранных нотификаций (их логов)
  useEffect(() => {
    const showingFeatures = makeGeoMarkers(notificationMarkers);

    if (!showingFeatures.length || !selectedHistoryLogs.length) {
      vectorNotificationSourceMarkers.clear();
    }
    if (showingFeatures.length) {
      vectorNotificationSourceMarkers.addFeatures(showingFeatures);
    }
  }, [notificationMarkers, selectedHistoryLogs]);

  // центрирование карты на выбранных маркерах
  const viewFitExtent = useCallback(
    (duration: number) => {
      const bbox = turfBbox(geoJSON.writeFeaturesObject(vectorNotificationSourceMarkers.getFeatures()));
      const X1Y1 = fromLonLat([bbox[0], bbox[1]]);
      const X2Y2 = fromLonLat([bbox[2], bbox[3]]);

      const extent = [...X1Y1, ...X2Y2] as Extent;

      map.getView().fit(extent, {
        padding: [150, 150, 150, 150],
        duration,
        easing: easeOut,
      });
    },
    [map]
  );

  // отображение маркеров из истории нотификаций
  const makeHistoryMapMarkers = useCallback(() => {
    const markers: SelectedNotificationMarker[] = [];

    selectedHistoryLogs.forEach(selHistoryLog => {
      const log = historyLogs.find(hLog => hLog.id === selHistoryLog);

      if (log) {
        markers.push({
          info: {
            logId: log.id,
            text: log.attributes.aggregatedName,
          },
          iconName: getIconByNotificationType(log.attributes.notificationType),
          data: log.attributes.coords.split(',').map(coordsStr => parseFloat(coordsStr)),
          notificationId: log.attributes.notificationId,
        });
      }
    });
    return markers;
  }, [historyLogs, selectedHistoryLogs]);

  const notificationHistoryMarkers = makeHistoryMapMarkers();

  // флаг, разрешающий показ history logs
  const [isViewingHistoryLogsAllowed, setIsViewingHistoryLogsAllowed] = useState(true);

  useEffect(() => {
    if (historyLogs.length && selectedHistoryLogs.length && !isTableHistoryLogsShow) {
      vectorNotificationSourceMarkers.clear();
      setIsViewingHistoryLogsAllowed(true);
    }
  }, [historyLogs, selectedHistoryLogs, isTableHistoryLogsShow]);

  // отображение маркеров из истории нотификаций
  useEffect(() => {
    if (isViewingHistoryLogsAllowed && notificationHistoryMarkers.length) {
      const featuresNotifications: Feature[] = [];

      for (let i = 0; i < notificationHistoryMarkers.length; i++) {
        if (!notificationHistoryMarkers[i].data) {
          continue;
        }
        const marker = notificationHistoryMarkers[i];
        const notificationMarker: Feature = createGeoNotificationMarker(fromLonLat(marker.data));

        notificationMarker.setProperties({
          iconName: marker.iconName,
          info: marker.info,
          mapCoords: marker.data,
        });
        notificationMarker.setId(`history-notification-log-${i}-${marker.info.logId}`);
        featuresNotifications.push(notificationMarker);
      }

      if (featuresNotifications.length) {
        vectorNotificationSourceMarkers.addFeatures(featuresNotifications);
        if (history.location.pathname !== REPORTS_MAP_ROUTE) {
          viewFitExtent(2000);
        }
      }
      setIsViewingHistoryLogsAllowed(false);
    }
  }, [history, viewFitExtent, notificationHistoryMarkers, isViewingHistoryLogsAllowed]);
};
