import { PoiAnalyzedGases } from 'reducers/poi/interface';
import { addNHoursToDate } from './helpers';

export enum PoiTypesEnum {
  poiVideocameraPoint = 'poiVideocameraPoint',
  poiGasAnalyzerPoint = 'poiGasAnalyzerPoint',
  poiSensorPoint = 'poiSensorPoint',
  poiEquipmentPoint = 'poiEquipmentPoint',
  poiAccidentPoint = 'poiAccidentPoint',
  poiFreePoint = 'poiFreePoint',
}

export const defaultPoiVideoCameraState = {
  name: '',
  description: '',
  poiVideocameraModelId: 0,
  dataSourceUrl: '',
  coordinates: '0, 0',
  angle: 120, // deg
  distance: 10, // meters
  direction: 0, //deg
};

export const defaultPoiGazAnalyzerState = {
  name: '',
  description: '',
  poiGasAnalyzerModelId: 0,
  dataSourceUrl: '',
  coordinates: '0, 0',
  poiGasSensors: [] as number[],
  lastCheckDate: '',
  nextCheckDate: '',
  poiAnalyzedGases: [] as PoiAnalyzedGases[],
};

export const defaultPoiSensorState = {
  name: '',
  description: '',
  poiSensorTypeId: 0,
  poiSensorModelId: 0,
  dataSourceUrl: '',
  coordinates: '0, 0',
  measureRangeFrom: 0,
  measureRangeTo: 0,
  poiSensorMeasureUnitId: 0,
  measureLimit: 0,
  lastCheckDate: '',
  nextCheckDate: '',
};

export const defaultPoiEquipmentState = {
  name: '',
  description: '',
  poiEquipmentModelId: 0,
  poiEquipmentTypeId: 1, // === 1 для отображения на карте соответствующего маркера
  coordinates: '0, 0',
  lastCheckDate: '',
  nextCheckDate: '',
};

const DEFAULT_HOURS_SHIFT = 8;

export const defaultPoiAccidentState = {
  name: '',
  description: '',
  poiAccidentTypeId: 0,
  coordinates: '0, 0',
  activePeriod: addNHoursToDate(new Date(), DEFAULT_HOURS_SHIFT).toISOString(),
  isRemovedInTheEnd: true,
  createdDate: new Date().toISOString(),
};

export const defaultPoiFreePointState = {
  name: '',
  description: '',
  coordinates: '0, 0',
  activePeriod: addNHoursToDate(new Date(), DEFAULT_HOURS_SHIFT).toISOString(),
  isRemovedInTheEnd: true,
  createdDate: new Date().toISOString(),
};
