import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { Spinner } from 'components/common/spinner/spinner';
import Pagination from 'components/common/pagination/pagination';

import { RootState } from 'reducers';
import { fetchEmployeeStatistic } from 'reducers/personalStatistics';

import usePersonalStatisticRowsAndPagination from 'utils/useGetPersonalStatisticRows';

import styles from './tabStatistic.module.scss';

const cx = classNames.bind(styles);

export const TabStatistic = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const chosenEmployee = useSelector((state: RootState) => state.employee.chosenEmployee);
  const statisticFilter = useSelector((state: RootState) => state.personalStatistics.statisticFilter);

  useEffect(() => {
    if (chosenEmployee) {
      dispatch(fetchEmployeeStatistic({ id: chosenEmployee, ...statisticFilter }));
    }
  }, [dispatch, chosenEmployee, statisticFilter]);

  const stats = usePersonalStatisticRowsAndPagination();

  return (
    <Fragment>
      <div className={styles.table}>
        {stats.states.isLoading ? (
          <Spinner />
        ) : (
          <table className={styles.tableWrap}>
            <thead>
              <tr className={styles.tableHead}>
                <th className={cx(styles.tableHeadCell, styles.tableHeadCellFirst)}>
                  {t('monitoring.tab-statistic.table.header.date.text')}
                </th>
                <th className={cx(styles.tableHeadCell, styles.tableHeadCellSecond)}>
                  {t('monitoring.tab-statistic.table.header.time.text')}
                </th>
                <th className={styles.tableHeadCell}>{t('monitoring.tab-statistic.table.header.event-type.text')}</th>
                <th className={styles.tableHeadCell}>
                  {t('monitoring.tab-statistic.table.header.event-description.text')}
                </th>
              </tr>
            </thead>
            <tbody>
              {stats.states.rows.map((row, index) => (
                <tr key={`statistic-${row.eventDescription}-${index}`} className={styles.tableRow}>
                  <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.date}</td>
                  <td className={cx(styles.tableRowCell, styles.tableRowCellAlignCenter)}>{row.time}</td>
                  <td className={styles.tableRowCell}>{row.eventType}</td>
                  <td className={styles.tableRowCell}>
                    <span className={styles.tableRowCellDescription}>{row.eventDescription}</span>
                    {!!row.base64Icons && (
                      <div className={styles.tableRowBase64Icons}>
                        <div className={styles.tableRowCellIconWrapper}>
                          <span className={styles.tableRowCellIconDescription}>
                            {row.base64Icons?.old.accompanyingText}
                            {': '}
                          </span>
                          {!!row.base64Icons?.old.base64 ? (
                            <img src={row.base64Icons?.old.base64} alt="old_icon" className={styles.tableRowCellIcon} />
                          ) : (
                            `${t('personal-statistic.no.text')}, `
                          )}
                        </div>
                        <div className={styles.tableRowCellIconWrapper}>
                          <span className={styles.tableRowCellIconDescription}>
                            {row.base64Icons?.new.accompanyingText}
                            {': '}
                          </span>
                          {!!row.base64Icons?.new.base64 ? (
                            <img src={row.base64Icons?.new.base64} alt="new_icon" className={styles.tableRowCellIcon} />
                          ) : (
                            `${t('personal-statistic.no.text')}.`
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          increasePage={stats.handlers.handleIncreasePage}
          decreasePage={stats.handlers.handleDecreasePage}
          setLimit={stats.handlers.handleChangeLimit}
          limit={stats.states.limit}
          withSelect
          page={stats.states.page}
        />
      </div>
    </Fragment>
  );
};
