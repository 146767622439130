import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';
import Search from 'components/common/search/search';
import { RecordsObjects } from './components/recordsObjects/recordsObjects';
import { RecordsTemplates } from './components/recordsTemplates/recordsTemplates';
import { RecordsHistory } from './components/recordsHistory/recordsHistory';

import useRecordsObjects from './hooks/useRecordsObjects';
import useRecordsTemplates from './hooks/useRecordsTemplates';
import useRecordsHistory from './hooks/useRecordsHistory';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { setIsReportsHistoryShow } from 'reducers/records';

import AccessEntity from 'utils/accessEntity';
import { CREATE_TEMPLATE_RECORDS } from 'utils/consts';

import styles from './records.module.scss';

function Records() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const recordsPermissions = useSelector((state: RootState) => state.user.permissions.records);
  const recordsAccess = useMemo(() => new AccessEntity(recordsPermissions), [recordsPermissions]);
  const { isReportsHistoryShow } = useSelector((state: RootState) => state.records);

  const { states: objectsStates, handlers: objectsHandlers, refs: objectsRefs } = useRecordsObjects();
  const { states: templatesStates, handlers: templatesHandlers } = useRecordsTemplates();
  const { states: historyStates, handlers: historyHandlers, refs: historyRefs } = useRecordsHistory();

  useEffect(() => {
    return () => {
      if (isReportsHistoryShow) {
        dispatch(setIsReportsHistoryShow(false));
      }
    };
  }, [dispatch, isReportsHistoryShow]);

  const handleSearch = (value: string) => {
    // TODO: search
  };

  const handleShowModal = () => {
    if (recordsAccess.isAllowCreate()) {
      dispatch(showModal({ type: CREATE_TEMPLATE_RECORDS, which: '' }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.controlsSearch}>
          <Search handleChange={value => handleSearch(value)} />
        </div>
        {recordsAccess.isAllowCreate() && (
          <div className={styles.controlsCreate}>
            <Button
              blue
              withSymbol
              text={t('records.button-block.btn.label')}
              customStyle={styles.rowButton}
              onClick={handleShowModal}
            />
          </div>
        )}
      </div>
      {isReportsHistoryShow ? (
        <RecordsHistory states={historyStates} handlers={historyHandlers} refs={historyRefs} />
      ) : (
        <Fragment>
          <RecordsTemplates states={templatesStates} handlers={templatesHandlers} />
          <RecordsObjects states={objectsStates} handlers={objectsHandlers} refs={objectsRefs} />
        </Fragment>
      )}
    </div>
  );
}

export default Records;
