import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';
import {
  getEmployees,
  createEmployee,
  deleteEmployee,
  putEmployee,
  getOneEmployee,
  getEmployeeIncludes,
  createWorkObject,
  deleteWorkObject,
} from './api';
import {
  CreateEmployeeAttributes,
  EmployeesState,
  Data,
  EmployeeJSONApi,
  ChosenEmployeeJSONApi,
  EmployeeWorkObjectReqParams,
} from './interface';

const initialState: EmployeesState = {
  employees: [],
  isLoading: false,

  needUpdate: false,

  chosenEmployee: null,
  chosenEmployeeData: null,
  isChosenEmployeeDataLoading: false,
  chosenEmployeeDataIncluded: [],

  selectedEmployees: [],

  searchedEmployees: [],
  error: null,
};

/*eslint no-throw-literal: "off"*/
export const fetchEmployees = createAsyncThunk('employeeSlice/fetchEmployees', async () => await getEmployees(''));

export const searchEmployees = createAsyncThunk(
  'employeeSlice/searchEmployees',
  async (string: string) => await getEmployees(string)
);

export const addEmployee = createAsyncThunk(
  'employeeSlice/addEmployee',
  async ({ employeeData, workObjectIds }: { employeeData: CreateEmployeeAttributes; workObjectIds: number[] }) => {
    const promises: ParallelItemInterface[] = [];

    try {
      const { data }: { data: Data } = await createEmployee(employeeData);
      const { id } = data;

      workObjectIds.forEach(wObjId => {
        promises.push({
          promiseMethod: createWorkObject,
          args: { employeeId: id, workObjectId: wObjId } as EmployeeWorkObjectReqParams,
        });
      });
      return await reqHandlers.allSettled(promises);
    } catch (error) {
      throw {
        message: (error as ErrorEvent).message,
      };
    }
  }
);

export const updateEmployee = createAsyncThunk(
  'employeeSlice/updateEmployee',
  async (
    { employeeData, workObjectIds }: { employeeData: CreateEmployeeAttributes; workObjectIds: number[] },
    thunkApi
  ) => {
    const promises: ParallelItemInterface[] = [];
    const { employee } = thunkApi.getState() as RootState;
    const { chosenEmployeeData } = employee;

    try {
      if (chosenEmployeeData) {
        const {
          id: chosenId,
          relationships: { workObjects: chosenWorkObjects },
        } = chosenEmployeeData;
        const chosenWorkObjectsIds = chosenWorkObjects.data.map(wObj => Number(wObj.id));
        const allWorkObjectsIds = new Set([...workObjectIds, ...chosenWorkObjectsIds]);

        allWorkObjectsIds.forEach(wObjId => {
          if (!workObjectIds.includes(wObjId)) {
            promises.push({
              promiseMethod: deleteWorkObject,
              args: { employeeId: chosenId, workObjectId: wObjId } as EmployeeWorkObjectReqParams,
            });
          }
          if (!chosenWorkObjectsIds.includes(wObjId)) {
            promises.push({
              promiseMethod: createWorkObject,
              args: { employeeId: chosenId, workObjectId: wObjId } as EmployeeWorkObjectReqParams,
            });
          }
        });

        await putEmployee(chosenId, employeeData);
        await reqHandlers.allSettled(promises);
      }
    } catch (error) {
      throw {
        message: (error as ErrorEvent).message,
      };
    }
  }
);

export const removeEmployee = createAsyncThunk('employeeSlice/removeEmployee', async (id: string) => {
  return await deleteEmployee(id);
});

export const fetchOneEmployee = createAsyncThunk('employeeSlice/fetchOneEmployee', async (id: string) => {
  return await getOneEmployee(id);
});

export const fetchEmployeeIncludes = createAsyncThunk(
  'employeeSlice/fetchEmployeeIncludes',
  async () => await getEmployeeIncludes()
);

const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState,
  reducers: {
    setChosenEmployee: (state, { payload }: { payload: string }) => {
      state.chosenEmployee = payload;
    },
    removeChosenEmployee: state => {
      state.chosenEmployee = null;
      state.chosenEmployeeData = null;
      state.chosenEmployeeDataIncluded = [];
    },
    selectOneEmployee: (state, { payload }: { payload: string }) => {
      state.selectedEmployees = [...state.selectedEmployees, { id: payload, watching: false }];
    },
    selectBatchOfEmployees: (state, { payload }: { payload: string[] }) => {
      const newSelectedEmployees = payload.filter(emp => !state.selectedEmployees.some(sel => sel.id === emp));
      state.selectedEmployees = [
        ...state.selectedEmployees,
        ...newSelectedEmployees.map(p => ({ id: p, watching: false })),
      ];
    },
    unselectOneEmployee: (state, { payload }: { payload: string }) => {
      state.selectedEmployees = state.selectedEmployees.filter(t => t.id !== payload);
    },
    selectAllEmployees: state => {
      state.selectedEmployees = state.employees.map(e => ({ id: e.id, watching: false }));
    },
    unselectAllEmployees: state => {
      state.selectedEmployees = [];
    },
    watchOneEmployee: (state, { payload }: { payload: string }) => {
      state.selectedEmployees = state.selectedEmployees.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: true,
          };
        }
        return e;
      });
    },
    unwatchOneEmployee: (state, { payload }: { payload: string }) => {
      state.selectedEmployees = state.selectedEmployees.map(e => {
        if (e.id === payload) {
          return {
            ...e,
            watching: false,
          };
        }
        return e;
      });
    },
    watchAllEmployees: state => {
      state.selectedEmployees = state.selectedEmployees.map(e => {
        return {
          ...e,
          watching: true,
        };
      });
    },
    unwatchAllEmployees: state => {
      state.selectedEmployees = state.selectedEmployees.map(e => {
        return {
          ...e,
          watching: false,
        };
      });
    },
    setNeedUpdateEmployee: (state, { payload }: { payload: boolean }) => {
      state.needUpdate = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEmployees.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, { payload }: { payload: EmployeeJSONApi }) => {
        state.employees = payload.data;
        state.needUpdate = false;
        state.isLoading = false;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(searchEmployees.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(searchEmployees.fulfilled, (state, { payload }: { payload: EmployeeJSONApi }) => {
        state.searchedEmployees = payload.data;
        state.isLoading = false;
      })
      .addCase(searchEmployees.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(addEmployee.pending, state => {
        state.error = null;
        state.isChosenEmployeeDataLoading = true;
      })
      .addCase(addEmployee.fulfilled, state => {
        state.isChosenEmployeeDataLoading = false;
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenEmployeeDataLoading = false;
      });
    builder
      .addCase(removeEmployee.pending, state => {
        state.error = null;
        state.isChosenEmployeeDataLoading = true;
      })
      .addCase(removeEmployee.fulfilled, state => {
        state.chosenEmployee = null;
        state.needUpdate = true;
        state.isChosenEmployeeDataLoading = false;
      })
      .addCase(removeEmployee.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenEmployeeDataLoading = false;
      });
    builder
      .addCase(updateEmployee.pending, state => {
        state.error = null;
        state.isChosenEmployeeDataLoading = true;
      })
      .addCase(updateEmployee.fulfilled, state => {
        state.chosenEmployee = null;
        state.needUpdate = true;
        state.isChosenEmployeeDataLoading = false;
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenEmployeeDataLoading = false;
      });
    builder
      .addCase(fetchOneEmployee.pending, state => {
        state.error = null;
        state.isChosenEmployeeDataLoading = true;
      })
      .addCase(fetchOneEmployee.fulfilled, (state, { payload }: { payload: ChosenEmployeeJSONApi }) => {
        state.chosenEmployeeData = payload.data;
        state.chosenEmployeeDataIncluded = payload.included;
        state.isChosenEmployeeDataLoading = false;
      })
      .addCase(fetchOneEmployee.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenEmployeeDataLoading = false;
      });
  },
});

export const {
  setChosenEmployee,
  removeChosenEmployee,
  selectOneEmployee,
  unselectOneEmployee,
  selectAllEmployees,
  selectBatchOfEmployees,
  unselectAllEmployees,
  watchOneEmployee,
  unwatchOneEmployee,
  watchAllEmployees,
  unwatchAllEmployees,
  setNeedUpdateEmployee,
} = employeeSlice.actions;

export default employeeSlice.reducer;
