import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';

import { geoJSON } from 'components/map/utils';
import {
  GEOZONE_GEOMETRIC_TYPES,
  GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES,
  GeozoneTypeOf,
} from 'components/geozones/utils/consts';

import { RootState } from 'reducers';

import { reqHandlers } from 'utils/api';
import { ParallelItemInterface } from 'utils/api/interface';
import { Sorting } from 'utils/consts';
import { getFromStorageNewVersion } from 'utils/workWithStorages';

import {
  getGeozones,
  getGeozonesShort,
  createGeozone,
  deleteGeozone,
  putGeozone,
  getGeozonesGroups,
  createGeozonesGroups,
  deleteGeozonesGroups,
  putGeozonesGroups,
} from './api';
import {
  GeozonesState,
  GeozonesJsonApi,
  CreateGeozone,
  GeozonesGroupJsonApi,
  GeozoneAttributes,
  SortingType,
  GeozonesShortJsonApi,
  GeozonesGroupObjJsonApi,
  GeozoneGeometricType,
  Geozone,
  GeozonePopupParams,
  UpdateGroupData,
  AddGroupData,
  GeozonesEntityReqParams,
  GeozoneRelationshipsData,
  ConfigForGeozonesSorting,
} from './interface';
import { MOCK_ND_GEOZONES_DATA } from './mocks';
import { LanguagesKeysType } from 'translate';

type FetchGeozonesPayload = {
  geozones: GeozonesJsonApi;
  locale: LanguagesKeysType;
};

const showWithGroupsFromStorage = getFromStorageNewVersion('showWithGroups');
const showWithGroupsInitialValue = typeof showWithGroupsFromStorage === 'boolean' ? showWithGroupsFromStorage : false;

const initialState: GeozonesState = {
  geozones: [],
  geozonesShort: [],

  groups: [],

  geozonesGeometricTypes: null,

  updateCounter: 0,

  moveToGeozoneId: null,

  chosenGeozone: null,
  chosenGeozoneAttr: null,

  isLoading: false,
  isShortsLoading: false,

  chosenZone: null,
  chosenGroup: null,

  geozoneFeature: null,

  selectedGeozones: [],
  savedSelectedGeozones: [],
  selectedGroups: [],
  savedSelectedGroups: [],

  popupGeozoneParams: null,

  clearStorage: false,

  geozoneCreationIsShow: false,

  sort: {
    type: Sorting.name,
    ascending: true,
  },
  showWithGroups: showWithGroupsInitialValue,
  listForSorting: [
    {
      id: 1,
      name: 'geozones.geozone-sorting-modal.element-groups.label',
      isActive: true,
      keyName: 'groups',
      emptyTitle: '',
      idField: 'groups',
      childIndexes: [],
      parentIndexes: [],
    },
    {
      id: 2,
      name: 'geozones.geozone-sorting-modal.element-geozones.label',
      isActive: true,
      keyName: 'geozones',
      emptyTitle: '',
      idField: 'geozones',
      childIndexes: [],
      parentIndexes: [],
    },
  ],

  error: null,
};

export const fetchGeozones = createAsyncThunk('geozoneSlice/fetchGeozones', async (_, thunkAPI) => {
  const geozones = await getGeozones();

  const state = thunkAPI.getState() as RootState;
  const locale = state.user.userPreferences.locale;

  return {
    geozones,
    locale,
  };
});

export const fetchGeozonesShort = createAsyncThunk(
  'geozoneSlice/fetchGeozonesShort',
  async () => await getGeozonesShort()
);

export const fetchGeozonesGroups = createAsyncThunk(
  'geozoneSlice/fetchGeozonesGroups',
  async () => await getGeozonesGroups()
);

export const addGeozone = createAsyncThunk('geozoneSlice/addGeozone', async (data: CreateGeozone) =>
  createGeozone(data)
);

export const addGeozoneGroup = createAsyncThunk(
  'geozoneSlice/addGeozoneGroup',
  async (data: AddGroupData, thunkApi) => {
    const { getState } = thunkApi;
    const userId = (getState() as RootState).user.id;
    const res = await createGeozonesGroups(data.group);
    const promises: ParallelItemInterface[] = [];

    data.groups.forEach(g => {
      const args: GeozonesEntityReqParams = {
        id: g.id,
        name: g.attributes.name,
        description: g.attributes.description,
        parentGroupId: Number(res.data.id) || null,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozonesGroups, args });
    });

    data.geozones.forEach(g => {
      const args: GeozonesEntityReqParams = {
        id: g.id,
        name: g.attributes.name,
        description: g.attributes.description,
        parentGroupId: Number(res.data.id) || null,
        attributes: g.attributes,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozone, args });
    });
    if (promises.length) {
      await reqHandlers.allSettled(promises);
    }
    return res;
  }
);

export const updateGeozoneGroup = createAsyncThunk(
  'geozoneSlice/updateGeozoneGroup',
  async (data: UpdateGroupData, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const userId = state.user.id;
    const promises: ParallelItemInterface[] = [];

    promises.push({
      promiseMethod: putGeozonesGroups,
      args: { ...data.group, id: String(data.group.id) } as GeozonesEntityReqParams,
    });

    data.add.groups.forEach(group => {
      const args: GeozonesEntityReqParams = {
        id: group.id,
        name: group.attributes.name,
        description: group.attributes.description,
        parentGroupId: data.group.id,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozonesGroups, args });
    });
    data.add.geozones.forEach(geozone => {
      const args: GeozonesEntityReqParams = {
        id: geozone.id,
        name: geozone.attributes.name,
        description: geozone.attributes.description,
        parentGroupId: data.group.id,
        attributes: geozone.attributes,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozone, args });
    });

    data.remove.groups.forEach(group => {
      const args: GeozonesEntityReqParams = {
        id: group.id,
        name: group.attributes.name,
        description: group.attributes.description,
        parentGroupId: null,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozonesGroups, args });
    });
    data.remove.geozones.forEach(geozone => {
      const args: GeozonesEntityReqParams = {
        id: geozone.id,
        name: geozone.attributes.name,
        description: geozone.attributes.description,
        attributes: geozone.attributes,
        parentGroupId: null,
        userId: parseInt(userId),
      };

      promises.push({ promiseMethod: putGeozone, args });
    });

    return await reqHandlers.allSettled(promises);
  }
);

export const removeGeozone = createAsyncThunk(
  'geozoneSlice/removeGeozone',
  async (id: string) => await deleteGeozone(id)
);

export const removeGeozoneGroup = createAsyncThunk(
  'geozoneSlice/removeGeozoneGroup',
  async (id: string) => await deleteGeozonesGroups(id)
);

export const updateGeozone = createAsyncThunk(
  'geozoneSlice/updateGeozone',
  async (data: GeozoneAttributes & { id: string; parentGroupId: number | null }, thunkApi) => {
    const { getState } = thunkApi;
    const userId = (getState() as RootState).user.id;

    return await putGeozone({
      attributes: data,
      id: data.id,
      parentGroupId: data.parentGroupId,
      userId: parseInt(userId),
    });
  }
);

const geozoneSlice = createSlice({
  name: 'geozoneSlice',
  initialState,
  reducers: {
    setGeozones: (state, { payload }: { payload: Geozone[] }) => {
      state.geozones = payload;
    },
    setMoveToGeozoneId: (state, { payload }: { payload: string | null }) => {
      state.moveToGeozoneId = payload;
    },
    setChosenGeozone: (state, { payload }: { payload: string }) => {
      state.chosenGeozone = payload;
      const geozone = state.geozones.find(g => g.id === payload);

      if (geozone) {
        try {
          state.chosenGeozoneAttr = geozone.attributes;
          const parsedGeoJSON = geozone.attributes.geoJson ? JSON.parse(geozone.attributes.geoJson) : null;
          if (parsedGeoJSON && parsedGeoJSON.type === 'FeatureCollection') {
            state.geozoneFeature = geoJSON.readFeatures(parsedGeoJSON);
          } else if (parsedGeoJSON) {
            state.geozoneFeature = geoJSON.readFeature(parsedGeoJSON);
          } else {
            state.geozoneFeature = null;
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    removeChosenGeozone: state => {
      state.chosenGeozone = null;
      state.chosenGeozoneAttr = null;
      state.geozoneFeature = null;
    },
    setChosenGroup: (state, { payload }: { payload: string }) => {
      state.chosenGroup = payload;
    },
    removeChosenGroup: state => {
      state.chosenGroup = null;
    },
    setShowWithGroupsStatus: (state, { payload }: { payload: boolean }) => {
      state.showWithGroups = payload;
    },
    updateListForSorting: (state, { payload }: { payload: ConfigForGeozonesSorting[] }) => {
      state.listForSorting = payload;
    },
    setGeozoneFeature: (state, { payload }: { payload: Feature<Geometry> | Feature<Geometry>[] }) => {
      state.geozoneFeature = payload;
    },
    removeGeozoneFeature: state => {
      state.geozoneFeature = null;
    },
    addSelectedGeozone: (state, { payload }: { payload: string }) => {
      state.selectedGeozones.push(payload);
    },
    selectBatchOfGeozones: (state, { payload }: { payload: string[] }) => {
      state.selectedGeozones = payload;
    },
    removeSelectedGeozone: (state, { payload }: { payload: string }) => {
      state.selectedGeozones = state.selectedGeozones.filter(g => g !== payload);
    },
    addSelectedGroup: (state, { payload }: { payload: string }) => {
      const geozoneIds: string[] = [];
      const groupsIds: string[] = [];
      const filteredGeozones = state.geozones.filter(g => g.relationships.parentGroup.data?.id === payload);
      const filteredGeozoneGroups = state.groups.filter(g => g.relationships.parentGroup.data?.id === payload);
      geozoneIds.push(...filteredGeozones.map(g => g.id));
      groupsIds.push(...filteredGeozoneGroups.map(g => g.id));
      const getChildrenGroup = (gr: GeozoneRelationshipsData[]) => {
        gr.forEach(grRel => {
          groupsIds.push(grRel.id);
          const childGroup = state.groups.find(group => group.id === grRel.id);
          if (!!childGroup) {
            getChildrenGroup(childGroup.relationships.childrenGroups.data);
            childGroup.relationships.geozones.data.forEach(zone => {
              geozoneIds.push(zone.id);
            });
          }
        });
      };
      filteredGeozoneGroups.forEach(gr => {
        getChildrenGroup(gr.relationships.childrenGroups.data);
        gr.relationships.geozones.data.forEach(zone => {
          geozoneIds.push(zone.id);
        });
      });
      const selectedGroups = [payload, ...groupsIds];
      state.selectedGeozones = [...state.selectedGeozones, ...geozoneIds];
      state.selectedGroups.push(...selectedGroups);
    },
    selectBatchOfGeoGroups: (state, { payload }: { payload: string[] }) => {
      let geozonesFromGroups: string[] = [];
      payload.forEach(id => {
        const geozoneIds = state.geozones.filter(g => g.relationships.parentGroup.data?.id === id).map(g => g.id);
        geozonesFromGroups = [...geozonesFromGroups, ...geozoneIds];
      });
      state.selectedGeozones = [...state.selectedGeozones, ...geozonesFromGroups];
      state.selectedGroups = [...state.selectedGroups, ...payload];
    },
    removeSelectedGroup: (state, { payload }: { payload: string }) => {
      const geozoneIds: string[] = [];
      const groupsIds: string[] = [];
      const filteredGeozones = state.geozones.filter(g => g.relationships.parentGroup.data?.id === payload);
      const filteredGeozoneGroups = state.groups.filter(g => g.relationships.parentGroup.data?.id === payload);
      geozoneIds.push(...filteredGeozones.map(g => g.id));
      groupsIds.push(...filteredGeozoneGroups.map(g => g.id));
      const getChildrenGroup = (gr: GeozoneRelationshipsData[]) => {
        gr.forEach(grRel => {
          groupsIds.push(grRel.id);
          const childGroup = state.groups.find(group => group.id === grRel.id);
          if (!!childGroup) {
            getChildrenGroup(childGroup.relationships.childrenGroups.data);
            childGroup.relationships.geozones.data.forEach(zone => {
              geozoneIds.push(zone.id);
            });
          }
        });
      };
      filteredGeozoneGroups.forEach(gr => {
        getChildrenGroup(gr.relationships.childrenGroups.data);
        gr.relationships.geozones.data.forEach(zone => {
          geozoneIds.push(zone.id);
        });
      });
      const unSelectedGroups = [payload, ...groupsIds];
      state.selectedGeozones = state.selectedGeozones.filter(g => !geozoneIds.includes(g));
      state.selectedGroups = state.selectedGroups.filter(g => !unSelectedGroups.includes(g));
    },
    selectAll: state => {
      state.selectedGeozones = state.geozones.map(g => g.id);
      state.selectedGroups = state.groups.map(g => g.id);
    },
    saveSelected: state => {
      state.savedSelectedGeozones = state.selectedGeozones;
      state.savedSelectedGroups = state.selectedGroups;
      state.clearStorage = false;
    },
    clearSelected: state => {
      state.savedSelectedGeozones = [];
      state.savedSelectedGroups = [];
      state.clearStorage = true;
    },
    selectAllSaved: state => {
      state.selectedGeozones = state.savedSelectedGeozones;
      state.selectedGroups = state.savedSelectedGroups;
      state.clearStorage = false;
    },
    unselectAll: state => {
      state.selectedGeozones = [];
      state.selectedGroups = [];
    },
    setSort: (state, { payload }: { payload: SortingType }) => {
      const sort = state.sort;

      if (sort.type !== payload) {
        sort.type = payload;
        sort.ascending = false;
      } else {
        sort.ascending = !state.sort.ascending;
      }
    },
    removeSavedSelectedGeozone: (state, { payload }: { payload: string }) => {
      state.savedSelectedGeozones = state.savedSelectedGeozones.filter(g => g !== payload);
    },
    setGeozoneCreationIsShow: (state, { payload }: { payload: boolean }) => {
      state.geozoneCreationIsShow = payload;
    },
    setGeozoneGroupCreationIsShow: (state, { payload }: { payload: boolean }) => {
      state.geozoneCreationIsShow = payload;
    },
    setPopupGeozoneParams: (state, { payload }: { payload: GeozonePopupParams | null }) => {
      state.popupGeozoneParams = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGeozones.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGeozones.fulfilled, (state, { payload }: PayloadAction<FetchGeozonesPayload>) => {
        //TODO: после демо и правок бекенд убрать моки и сделать `state.geozones = payload.data;`
        const { locale, geozones } = payload;
        const mockedNdData = MOCK_ND_GEOZONES_DATA[locale];
        let index = 0;
        state.geozones = geozones.data.map(geozone => {
          if (geozone.attributes.type === GeozoneTypeOf.nd) {
            const modifiedGeozoneWithNd = {
              ...geozone,
              attributes: {
                ...geozone.attributes,
                ndData: mockedNdData[index],
              },
            };

            index++;
            if (index > mockedNdData.length - 1) {
              index = 0;
            }

            return modifiedGeozoneWithNd;
          }
          return geozone;
        });

        if (geozones.meta.geometricType) {
          state.geozonesGeometricTypes = Object.values(geozones.meta.geometricType).reduce((pred, value) => {
            return {
              ...pred,
              [value as GEOZONE_GEOMETRIC_TYPES]: {
                description:
                  GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES[value as keyof typeof GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES],
                olName: GEOZONE_GEOMETRIC_TYPES[value as keyof typeof GEOZONE_GEOMETRIC_TYPES],
              },
            };
          }, {} as GeozoneGeometricType);
        }
        state.isLoading = false;
      })
      .addCase(fetchGeozones.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(fetchGeozonesShort.pending, state => {
        state.isShortsLoading = true;
        state.error = null;
      })
      .addCase(fetchGeozonesShort.fulfilled, (state, { payload }: { payload: GeozonesShortJsonApi }) => {
        state.geozonesShort = payload.data;
        state.isShortsLoading = false;
      })
      .addCase(fetchGeozonesShort.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isShortsLoading = false;
      });
    builder
      .addCase(addGeozone.pending, state => {
        state.error = null;
      })
      .addCase(addGeozone.fulfilled, (state, { payload }) => {
        state.updateCounter += 1;
        state.selectedGeozones.push(payload.data.id);
      })
      .addCase(addGeozone.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeGeozone.pending, state => {
        state.error = null;
      })
      .addCase(removeGeozone.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(removeGeozone.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateGeozone.pending, state => {
        state.error = null;
      })
      .addCase(updateGeozone.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(updateGeozone.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchGeozonesGroups.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGeozonesGroups.fulfilled, (state, { payload }: { payload: GeozonesGroupJsonApi }) => {
        state.groups = payload.data;
        state.isLoading = false;
      })
      .addCase(fetchGeozonesGroups.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(addGeozoneGroup.pending, state => {
        state.error = null;
      })
      .addCase(addGeozoneGroup.fulfilled, (state, { payload }: { payload: GeozonesGroupObjJsonApi }) => {
        state.updateCounter += 1;
        state.selectedGroups.push(payload.data.id);
      })
      .addCase(addGeozoneGroup.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeGeozoneGroup.pending, state => {
        state.error = null;
      })
      .addCase(removeGeozoneGroup.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(removeGeozoneGroup.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateGeozoneGroup.pending, state => {
        state.error = null;
      })
      .addCase(updateGeozoneGroup.fulfilled, state => {
        state.updateCounter += 1;
      })
      .addCase(updateGeozoneGroup.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
  },
});

export const {
  setGeozones,
  setMoveToGeozoneId,
  setChosenGeozone,
  setChosenGroup,
  removeChosenGeozone,
  removeChosenGroup,
  setGeozoneFeature,
  removeGeozoneFeature,
  addSelectedGeozone,
  removeSelectedGeozone,
  addSelectedGroup,
  removeSelectedGroup,
  selectAll,
  unselectAll,
  selectBatchOfGeozones,
  selectBatchOfGeoGroups,
  setSort,
  saveSelected,
  clearSelected,
  selectAllSaved,
  removeSavedSelectedGeozone,
  setGeozoneCreationIsShow,
  setPopupGeozoneParams,
  setShowWithGroupsStatus,
  updateListForSorting,
} = geozoneSlice.actions;

export default geozoneSlice.reducer;
