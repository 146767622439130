import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';
import CustomSelect from 'components/common/select/select';
import { InfoElement } from 'components/common/infoElement/infoElement';
import { SensorProps } from 'components/notifications/utils/types';

import styles from '../../tabsModal.module.scss';

const cx = classNames.bind(styles);

export const ValuesSensorLayout = ({ states, handlers, invalidFields, additionalFields }: SensorProps) => {
  const t = useFormatMessage();

  return (
    <Fragment>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.Inputs, styles.InputsFullWidth)}>
          <CustomSelect
            options={states.notificationSensorIdForSelect}
            withSearchIcon
            label={t('notifications.card.tabs.conditions.field.sensor.type.label')}
            placeholder={t('notifications.card.tabs.conditions.field.sensor.type.placeholder')}
            isValueError={invalidFields.includes('notificationSensorId')}
            isRequired={true}
            value={states.notificationSensorIdForSelect.find(t => t.value === states.notificationSensorId)?.value || ''}
            handleChange={value => handlers.handleChangeTypeOfSensor(value)}
          />
        </div>
        <div className={cx(styles.modalRow, styles.Inputs, styles.InputsFullWidth)}>
          <Input
            label={t('notifications.card.tabs.conditions.field.sensor.name.label')}
            placeholder={t('notifications.card.tabs.conditions.field.sensor.name.placeholder')}
            isValueError={invalidFields.includes('nameOfSensor')}
            isRequired={true}
            value={states.nameOfSensor}
            handleInputChange={value => handlers.handleChangeNameOfSensor(value)}
          />
        </div>
        <div className={cx(styles.modalRow, styles.Inputs, styles.InputsFullWidth)}>
          <CustomSelect
            options={states.notificationActivationIdForSelect}
            label={t('notifications.card.tabs.conditions.field.sensor.triggered.label')}
            placeholder={t('notifications.card.tabs.conditions.field.sensor.triggered.placeholder')}
            isRequired={false}
            withSearchIcon
            value={states.notificationActivationId}
            style={styles.inputWrap}
            handleChange={value => handlers.handleChangeConditionOfActivation(value)}
          />
        </div>
        <div className={cx(styles.modalRow, styles.Inputs, styles.InputsMultiple)}>
          <Input
            label={t('notifications.card.tabs.conditions.field.sensor.value-from.label')}
            placeholder={t('notifications.card.tabs.conditions.field.sensor.value-from.placeholder')}
            isValueError={invalidFields.includes('valueFromSensor')}
            isRequired={true}
            customStyle={styles.input}
            value={states.valueFrom < 0 ? '' : String(states.valueFrom)}
            handleInputChange={value => handlers.handleChangeValueFromValue(value)}
          />
          <Input
            label={t('notifications.card.tabs.conditions.field.sensor.value-to.label')}
            placeholder={t('notifications.card.tabs.conditions.field.sensor.value-to.placeholder')}
            isValueError={invalidFields.includes('valueToSensor') || invalidFields.includes('valueToLessThanToSensor')}
            isRequired={true}
            customStyle={styles.input}
            value={states.valueTo < 0 ? '' : String(states.valueTo)}
            handleInputChange={value => handlers.handleChangeValueToValue(value)}
          />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div className={cx(styles.modalRow, styles.modalRowSpaceBetween)}>
          <div className={styles.modalCheckbox} onClick={handlers.handleCheckedFoulFixing}>
            <Checkbox checked={additionalFields.isFoulFixing} />
            <span>{t('notifications.card.tabs.conditions.field.sensor.rec-violation.text')}</span>
          </div>
          <InfoElement
            id={'violationFixedSensor'}
            text={t('notifications.card.tabs.conditions.field.sensor.rec-violation.tooltip.text')}
          />
        </div>
      </div>
    </Fragment>
  );
};
