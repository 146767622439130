import React, { FC, ReactNode } from 'react';
import styles from './modalLayout.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  small?: boolean;
  noFixedHeight?: boolean;
  children: ReactNode;
};

export const ModalLayout: FC<Props> = ({ small, noFixedHeight, children }: Props) => {
  const classNames = cx(styles.default, {
    [styles.small]: !!small,
    [styles.big]: !small,
    [styles.noFixedHeight]: noFixedHeight,
  });
  return (
    <div className={styles.layout}>
      <div className={classNames}>{children}</div>
    </div>
  );
};
