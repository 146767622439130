import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';

import Button from 'components/common/button/button';
import Input from 'components/common/input/input';
import { Checkbox } from 'components/common/checkbox/checkbox';

import { RootState } from 'reducers';
import { closeModal, showModal } from 'reducers/modal';
import { addRole, removeChosenRole, setRoleForm, setRoleFormIsInitialized, updateRole } from 'reducers/roles';

import AccessEntity from 'utils/accessEntity';
import { ROLE_TO_USER } from 'utils/consts';

import { permRows } from '../utils/consts';

import styles from './roleCreation.module.scss';

const RoleCreation = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const chosenRole = useSelector((state: RootState) => state.role.chosenRole);
  const { name, permissions } = useSelector((state: RootState) => state.role.roleForm);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!name) {
      newInvalidFields.push('name');
    }

    return newInvalidFields;
  };

  const handleChangeName = (value: string) => {
    dispatch(
      setRoleForm({
        name: value,
        permissions,
      })
    );
  };

  const handleChangePermission = (keyName: string, permInd: number) => {
    const newPermissions = { ...permissions };

    newPermissions[keyName] = newPermissions[keyName]
      .split('')
      .map((s, i) => (i === permInd ? (s === '0' ? '1' : '0') : s))
      .join('');

    dispatch(
      setRoleForm({
        name,
        permissions: newPermissions,
      })
    );
  };

  const handleClose = () => {
    dispatch(removeChosenRole());
    dispatch(setRoleFormIsInitialized(false));
    dispatch(closeModal());
  };

  const handleSave = () => {
    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      return setInvalidFields(validationResult);
    }

    if (chosenRole) {
      dispatch(updateRole(chosenRole));
    } else {
      dispatch(addRole());
    }

    handleClose();
  };

  const getCheckboxState = (permInd: number) => {
    for (const key in permissions) {
      if (permissions[key][permInd] === '0') {
        return false;
      }
    }
    return true;
  };

  const getCheckboxIndeterminateState = (permInd: number) => {
    let res = 0;
    for (const key in permissions) {
      res += parseInt(permissions[key][permInd]);
    }
    return res > 0 && res < 13;
  };

  const handleCheckAll = (permInd: number) => {
    const currentState = getCheckboxState(permInd);
    const newPermissions = { ...permissions };
    if (currentState) {
      for (const key in permissions) {
        newPermissions[key] = newPermissions[key]
          .split('')
          .map((s, i) => (i === permInd ? '0' : s))
          .join('');
      }
    } else {
      for (const key in permissions) {
        newPermissions[key] = newPermissions[key]
          .split('')
          .map((s, i) => (i === permInd ? '1' : s))
          .join('');
      }
    }

    dispatch(
      setRoleForm({
        name,
        permissions: newPermissions,
      })
    );
  };

  const handleSelectUser = () => {
    dispatch(showModal({ type: ROLE_TO_USER, which: name }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerText}>{t('accounts.card.roles.header.label')}</div>
        <CloseIcon className={styles.headerCloseIcon} onClick={handleClose} />
      </div>
      <div className={styles.body}>
        <div className={styles.inputGroup}>
          <Input
            label={t('accounts.card.roles.field.name.label')}
            placeholder={t('accounts.card.roles.field.name.placeholder')}
            value={name}
            handleInputChange={handleChangeName}
            isValueError={invalidFields.includes('name')}
            isRequired={true}
            customStyle={styles.inputWrap}
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>{t('accounts.card.roles.table.header.resource.label')}</th>
              <th className={styles.tableHeader}>
                <div className={styles.tableHeaderCellWrap}>
                  <div className={styles.tableHeaderCellLabel}>{t('accounts.card.roles.table.header.read.label')}</div>
                  <Checkbox
                    checked={getCheckboxState(3)}
                    isIndeterminate={getCheckboxIndeterminateState(3)}
                    handleCheck={() => handleCheckAll(3)}
                  />
                </div>
              </th>
              <th className={styles.tableHeader}>
                <div className={styles.tableHeaderCellWrap}>
                  <div className={styles.tableHeaderCellLabel}>
                    {t('accounts.card.roles.table.header.create.label')}
                  </div>
                  <Checkbox
                    checked={getCheckboxState(2)}
                    isIndeterminate={getCheckboxIndeterminateState(2)}
                    handleCheck={() => handleCheckAll(2)}
                  />
                </div>
              </th>
              <th className={styles.tableHeader}>
                <div className={styles.tableHeaderCellWrap}>
                  <div className={styles.tableHeaderCellLabel}>{t('accounts.card.roles.table.header.edit.label')}</div>
                  <Checkbox
                    checked={getCheckboxState(1)}
                    isIndeterminate={getCheckboxIndeterminateState(1)}
                    handleCheck={() => handleCheckAll(1)}
                  />
                </div>
              </th>
              <th className={styles.tableHeader}>
                <div className={styles.tableHeaderCellWrap}>
                  <div className={styles.tableHeaderCellLabel}>
                    {t('accounts.card.roles.table.header.delete.label')}
                  </div>
                  <Checkbox
                    checked={getCheckboxState(0)}
                    isIndeterminate={getCheckboxIndeterminateState(0)}
                    handleCheck={() => handleCheckAll(0)}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {permRows.map((row, ind) => {
              // TODO: вынести в отдельный массив сущностей доступа, чтобы не плодить их при перерисовке
              const perm = new AccessEntity(permissions[row.keyName]);

              return (
                <tr key={`role-creation_${ind}-${row.keyName}`}>
                  <td className={styles.tableBodyCell}>{t(row.label)}</td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={perm.isAllowRead()}
                        handleCheck={() => handleChangePermission(row.keyName, 3)}
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={perm.isAllowCreate()}
                        handleCheck={() => handleChangePermission(row.keyName, 2)}
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={perm.isAllowUpdate()}
                        handleCheck={() => handleChangePermission(row.keyName, 1)}
                      />
                    </div>
                  </td>
                  <td className={styles.tableBodyCell}>
                    <div className={styles.tableBodyCellCheckboxWrap}>
                      <Checkbox
                        checked={perm.isAllowDelete()}
                        handleCheck={() => handleChangePermission(row.keyName, 0)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.footerButtons}>
        <Button white text={t('accounts.card.roles.footer.btn.add-role.label')} onClick={handleSelectUser} />
        <Button white text={t('accounts.card.roles.footer.btn.cancel.label')} onClick={handleClose} />
        <Button blue text={t('accounts.card.roles.footer.btn.save.label')} onClick={handleSave} />
      </div>
    </div>
  );
};

export default RoleCreation;
