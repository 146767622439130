import { ObjectType } from 'utils/consts';

export const MOCK_EMPLOYEE_POPUP_DATA = {
  id: 1,
  firstName: 'Иванов',
  secondName: 'Александр',
  lastName: 'Алексеевич',
  position: 'Слесарь-монтажник',
  simNumber: '',
  org: 'ООО МонтажСпецСтрой',
  department: 'Цех производства аммиака',
  workObject: 'Участок ремонта',
  passNumber: '0123-79879-555',
  startDate: '01.01.2020',
  endDate: '31.12.2020',
  trackerNumber: '001',
  additionalFields: [
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Аттестация по охране труда',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: true,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: false,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: false,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: true,
    },
  ],
  showContactsRef: false,
};

export const MOCK_TRANSPORT_POPUP_DATA = {
  id: 2,
  brand: 'VOLVO',
  model: 'FMX 8x4',
  regNumber: 'Р676ОР197',
  year: '2017',
  type: 'Грузовой',
  color: 'Синий',
  org: 'ООО СпецСтрой',
  department: 'Структура',
  driver: 'Иванов Иван Иванович',
  fuel: 'Дизель',
  bearing: '100',
  trackerNumber: '003',
  additionalFields: [
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Медицинское заключение',
      value: 'Выдано с 01.01.2020 по 31.12.2020',
      visible: true,
    },
    {
      label: 'Аттестация по охране труда',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: true,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: false,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: false,
    },
    {
      label: 'Аттестация по электробезопасности',
      value: 'Пройдена 01.01.2020, действует по 01.10.2020',
      visible: true,
    },
  ],
};

export const MOCK_CISTERN_POPUP_DATA = {
  id: 0,
  name: 'Название',
  state: 'Состояние',
  startYear: '1900',
  org: 'Организация',
  department: 'Структурное подразделение',
  workObject: 'Объект',
  lastRepairDate: '1900',
  nextRepairDate: '1900',
  renovations: [],
  violations: [],
};

export const MOCK_OBJECTS = [
  {
    value: '001 Иванов Иван Иванович',
    id: '1',
    isActive: false,
    type: ObjectType.employee,
  },
  {
    value: '002 Петров Петр Петрович',
    id: '2',
    isActive: false,
    type: ObjectType.employee,
  },
  {
    value: '003 Сидоров Сидр Сидорович',
    id: '3',
    isActive: false,
    type: ObjectType.employee,
  },
  {
    value: '004 Volvo GRAND FX444F123',
    id: '4',
    isActive: false,
    type: ObjectType.transport,
  },
  {
    value: '005 Mercedes SUPER JN978J123',
    id: '5',
    isActive: false,
    type: ObjectType.transport,
  },
  {
    value: '006 BMW Class HGD765123',
    id: '6',
    isActive: false,
    type: ObjectType.transport,
  },
];

export const MOCK_CONTACTS_DATA = [
  {
    date: '2020-08-01T07:46:31.000Z',
    time: '08:00',
    trackerNumber: '032',
    fio: 'Петров Евгений Васильевич',
    zone: 'Участок ремонта №1',
    contactTime: '10:00',
    id: '4',
  },
  {
    date: '2020-08-01T07:46:31.000Z',
    time: '11:00',
    trackerNumber: '045',
    fio: 'Рыбин Олег Леонидович',
    zone: 'Участок ремонта №2',
    contactTime: '14:30',
    id: '5',
  },
  {
    date: '2020-08-01T07:46:31.000Z',
    time: '15:00',
    trackerNumber: '027',
    fio: 'Былин Петр Петрович',
    zone: 'Участок окисла',
    contactTime: '21:30',
    id: '6',
  },
  {
    date: '2020-08-01T07:46:31.000Z',
    time: '18:00',
    trackerNumber: '009',
    fio: 'Александров Дмитрий Юрьевич',
    zone: 'Участок ремонта №1',
    contactTime: '09:00',
    id: '7',
  },
];

export const HARITONOV_MOCK_ROUTE = [
  [79.93221409299463, 68.5041120711595], // Petrov

  [79.93522700391783, 68.5051226317301], // mock middle checkpoint
  [79.93522700391783, 68.5041226317301], // mock middle checkpoint
  [79.93722700391783, 68.5031226317301], // mock middle checkpoint

  [79.93222700391783, 68.5031226317301], // Rybin

  [79.93422700391783, 68.5051226317301], // mock middle checkpoint
  [79.93622700391783, 68.5041226317301], // mock middle checkpoint

  [79.93223146472621, 68.50373364586949], //Bylin

  [79.93523146472621, 68.50173364586949], // mock middle checkpoint
  [79.93123146472621, 68.50273364586949], // mock middle checkpoint

  [79.93224671322206, 68.504045576498], // Alexandrov
];
