import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as HeaderIcon } from 'assets/img/object-card-icon.svg';
import Transport from 'assets/img/transport-big.png';
import { ReactComponent as DropArrow } from 'assets/img/button/drop-arrow.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';

import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { Spinner } from 'components/common/spinner/spinner';

import { RootState } from 'reducers';
import { showMapPopup, showModal } from 'reducers/modal';
import { setChosenTransport } from 'reducers/transports';
import { HandbookTranslatedFieldType } from 'reducers/handbooks/interface';
import { Handbook } from 'reducers/transports/interface';

import { TRANSPORT, NA, HIDE_TEXT, MORE_TEXT, APP_CONTENT_ID } from 'utils/consts';

import { getCurrentLocale } from 'translate';

import styles from './transportPopup.module.scss';

const cx = classNames.bind(styles);

type Props = {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
};
const INITIAL_POPUP_WIDTH = 385;
const INITIAL_POPUP_HEIGHT = 235;
const INITIAL_POPUP_FOOTER_HEIGHT = 115;

// TODO: нужны ли отдельные попапы для транпорта и сотрудника

function TransportPopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [showAdditional, setShowAdditional] = useState(false);

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const isChosenTransportDataLoading = useSelector((state: RootState) => state.transport.isChosenTransportDataLoading);
  const isTrackerLoading = useSelector((state: RootState) => state.tracker.isLoading);
  const mapPopup = useSelector((state: RootState) => state.modal.mapPopup);
  const mapPopupCoords = useSelector((state: RootState) => state.modal.mapPopupCoords);

  const viewportInfo = useSelector((state: RootState) => state.map.viewportInfo);
  const { mountedNodeHeight, mountedNodeWidth } = viewportInfo;

  const chosenTransportData = useSelector((state: RootState) => state.transport.chosenTransportData);
  const trackers = useSelector((state: RootState) => state.tracker.trackers);
  const organizations = useSelector((state: RootState) => state.handbooks.data.organizations);
  const transportIncludes = useSelector((state: RootState) => state.transport.includes);

  const data = useMemo(() => {
    const tr = chosenTransportData;
    const org = organizations.find(o => o.id === tr.attributes.organizationId);
    const dep = org?.relationships.departments?.data?.find(d => d.id === tr.attributes.departmentId);
    const brand = transportIncludes.transportBrand.find(b => b.id === tr.attributes.brandId);
    const model = transportIncludes.transportModel.find(m => m.id === tr.attributes.transportModelId);
    const fuel = transportIncludes.fuelType.find(f => f.id === tr.attributes.fuelTypeId);
    const color = transportIncludes.transportColor.find(c => c.id === tr.attributes.transportColorId);
    const type = transportIncludes.transportType.find(t => t.id === tr.attributes.transportTypeId);
    const driver: Handbook | null = transportIncludes.driverName.find(d => d.id === tr.attributes.driverNameId) ?? null;

    // No tracker message if tracker is null
    let trackerNumber = t('map-popup.transport.no-tracker.text');
    const trackerId = tr?.attributes.trackerId;

    if (trackerId) {
      const tracker = trackers.find(tracker => trackerId === tracker.id);

      if (tracker) {
        trackerNumber = tracker.attributes.trackerNumber;
      }
    }

    return {
      id: tr?.id ?? 0,
      bearing: tr?.attributes.bearingCapacity ?? '',
      regNumber: tr?.attributes.regNumber ?? '',
      year: tr?.attributes.year ?? '',
      brand: brand ? getTranslateFromLanguageKey(brand.name as HandbookTranslatedFieldType, userLanguageKey) : '',
      model: model ? getTranslateFromLanguageKey(model.name as HandbookTranslatedFieldType, userLanguageKey) : '',
      type: type ? getTranslateFromLanguageKey(type.name as HandbookTranslatedFieldType, userLanguageKey) : '',
      color: color ? getTranslateFromLanguageKey(color.name as HandbookTranslatedFieldType, userLanguageKey) : '',
      org: org
        ? getTranslateFromLanguageKey(org.attributes.name, userLanguageKey)
        : t('map-popup.transport.no-company.text'),
      department: dep
        ? getTranslateFromLanguageKey(dep.name, userLanguageKey)
        : t('map-popup.transport.no-subdivision.text'),
      driver: driver ? (driver.name as string) : '',
      fuel: fuel ? getTranslateFromLanguageKey(fuel.name as HandbookTranslatedFieldType, userLanguageKey) : '',
      trackerNumber,
      additionalFields: tr?.attributes.additionalFields,
      iconSrc: tr?.attributes.icon ?? Transport,
    };
  }, [chosenTransportData, trackers, organizations, transportIncludes, t, userLanguageKey]);

  const openEditModal = () => {
    dispatch(setChosenTransport(data.id));
    dispatch(showModal({ type: TRANSPORT, which: '' }));
    onCancelHandler();
  };

  const toggleAdditional = () => {
    setShowAdditional(!showAdditional);
  };

  const onCancelHandler = () => {
    dispatch(
      showMapPopup({
        type: '',
        coordinates: {
          x: 0,
          y: 0,
        },
      })
    );
    onCancel();
  };

  const mouseCoords = useMemo(
    () =>
      mapPopup === TRANSPORT ? { x: mountedNodeWidth / 1.66, y: mountedNodeHeight / 2 } : markerCoords.mouseCoords,
    [mapPopup, markerCoords.mouseCoords, mountedNodeWidth, mountedNodeHeight]
  );

  const mapCoords = mapPopup === TRANSPORT ? mapPopupCoords : markerCoords.mapCoords;

  // хедер попапа
  const headerPopup = (
    <div className={styles.popupHeader}>
      <HeaderIcon className={styles.popupHeaderIcon} />
      {t('monitoring.unit-card.header.object.label')}
    </div>
  );
  // контент попапа
  const contentPopup =
    isChosenTransportDataLoading || isTrackerLoading ? (
      <div className={styles.popupSpinner}>
        <Spinner />
      </div>
    ) : (
      <div className={styles.popupMain}>
        <div className={styles.popupSide}>
          <div className={styles.popupSidePhoto}>
            <img className={styles.popupSidePhotoImage} src={data.iconSrc} alt="Transport" />
            <div className={styles.popupSidePhotoTracker}>{data.trackerNumber}</div>
          </div>
          <div className={styles.popupSideButton} onClick={toggleAdditional}>
            <span className={styles.popupSideButtonText}>{showAdditional ? t(HIDE_TEXT) : t(MORE_TEXT)}</span>
            <DropArrow
              className={cx({
                [styles.popupSideButtonIconUp]: showAdditional,
                [styles.popupSideButtonIconDown]: !showAdditional,
              })}
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div className={styles.popupContentNameWrap}>
            <div className={styles.popupContentName}>
              {data.brand} {data.model} {data.regNumber}
            </div>
            <div className={styles.popupContentPosition}>
              {data.type || `${t('map-popup.transport.vehicle-type.text')} ${NA}`}{' '}
              {data.color || `${t('map-popup.transport.vehicle-color.text')} ${NA}`}
            </div>
            <div className={styles.popupContentPhone}>
              {t('map-popup.transport.year-issue.text')} {data.year || NA}
            </div>
          </div>
          <div className={styles.popupContentOrgWrap}>
            <div className={styles.popupContentOrg}>{data.org || `${t('map-popup.transport.company.text')} ${NA}`}</div>
            <div className={styles.popupContentStructure}>
              {data.department || `${t('map-popup.transport.subdivision.text')} ${NA}`}
            </div>
            <div className={styles.popupContentWorkObject}>
              {t('map-popup.transport.driver.text')} {data.driver || NA}
            </div>
          </div>

          <div className={styles.popupContentTicketWrap}>
            <div className={styles.popupContentTicket}>
              {t('map-popup.transport.fuel-type.text')} {data.fuel || NA}
            </div>
            <div className={styles.popupContentDate}>
              {t('map-popup.transport.carrying-capacity.text')} {data.bearing || NA}
            </div>
          </div>
        </div>
      </div>
    );

  // футер попапа
  const footerPopup = (
    <div
      className={cx(styles.popupAdditional, {
        [styles.popupAdditionalShow]: showAdditional,
        [styles.popupAdditionalHide]: !showAdditional,
      })}
    >
      <div className={styles.popupAdditionalWrap}>
        <div className={styles.popupAdditionalLabel}>{t('map-popup.transport.current-position.text')}</div>
        <div className={styles.popupAdditionalValue}>
          {t('map-popup.transport.coords.text')} {mapCoords.x.toFixed(8)}, {mapCoords.y.toFixed(8)}
        </div>
      </div>
      {data.additionalFields
        .filter(f => f.visible)
        .map((f, i) => {
          return (
            <div key={`additionalField-popup-${i}`} className={styles.popupAdditionalWrap}>
              <div className={styles.popupAdditionalLabel}>{f.label}</div>
              <div className={styles.popupAdditionalValue}>{f.value}</div>
            </div>
          );
        })}
    </div>
  );

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      additionalHeaderButtons={[
        {
          name: 'openTransportParams',
          button: <OpenedEye onClick={openEditModal} className={styles.popupHeaderOpenModal} />,
        },
      ]}
      content={contentPopup}
      footer={footerPopup}
      showFooter={showAdditional}
      initialWidth={INITIAL_POPUP_WIDTH}
      initialHeight={INITIAL_POPUP_HEIGHT}
      initialFooterHeight={INITIAL_POPUP_FOOTER_HEIGHT}
      initialPosition={mouseCoords}
      resizeAxis="y"
      onCancel={onCancelHandler}
    />,
    appContentContainer
  );
}

export default React.memo(TransportPopup);
