import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { AiOutlineSearch } from 'react-icons/ai';

import styles from './search.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  handleChange: (value: string) => void;
  style?: string;
}

const Search = ({ handleChange, style = '' }: Props) => {
  const t = useFormatMessage();
  const searchStyle = cx(styles.wrapper, { [style]: !!style });

  return (
    <div className={searchStyle}>
      <input
        data-cy="test_search_input"
        onChange={ev => handleChange(ev.target.value)}
        type="text"
        placeholder={t('search.placeholder.text')}
        className={styles.input}
      />
      <AiOutlineSearch className={styles.searchIcon} />
    </div>
  );
};

export default Search;
