import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import {
  fetchOneReport,
  fetchReportsHistory,
  removeReport,
  setIsReportsHistoryShow,
  setReportHistoryTotal,
  setReportsHistory,
  handleIsReportInfoShow,
  clearReportInfoPdfUrl,
  setIsPrintableTemplateContained,
  clearChosenReport,
} from 'reducers/records';

import { defaultReportsHistoryFilter } from '../utils/consts';

export default function useRecordsHistory() {
  const dispatch = useDispatch();

  const { isReportsHistoryLoading, reportsHistory, reportsHistoryTotal, isReportInfoShow, reportInfoPdfUrl } =
    useSelector((state: RootState) => state.records);

  const [deleteReportId, setDeleteReportId] = useState<string | null>(null);

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const historyList = listRef?.current;

    if (historyList && !isReportsHistoryLoading && reportsHistory.length < reportsHistoryTotal) {
      const historyListHeight = historyList.getBoundingClientRect().height;
      const historyListScrollHeight = historyList.scrollHeight;
      const { limit } = defaultReportsHistoryFilter;
      const page = Math.ceil(reportsHistory.length / limit);

      if (historyListScrollHeight <= historyListHeight) {
        dispatch(
          fetchReportsHistory({
            offset: page * limit,
            limit,
          })
        );
      }
    }
  }, [dispatch, listRef, reportsHistory, reportsHistoryTotal, isReportsHistoryLoading]);

  const handleClickHideHistory = () => {
    dispatch(setIsReportsHistoryShow(false));
  };

  const handleClickReportHistory = (id: string) => {
    dispatch(clearChosenReport());
    dispatch(setIsPrintableTemplateContained(false));
    if (isReportInfoShow) {
      dispatch(handleIsReportInfoShow(false));
    }
    if (reportInfoPdfUrl) {
      dispatch(clearReportInfoPdfUrl());
    }
    dispatch(fetchOneReport({ id }));
    dispatch(handleIsReportInfoShow(true));
  };

  const handleDeleteAlertCancel = () => setDeleteReportId(null);

  const handleDeleteAlertContinue = () => {
    if (deleteReportId) {
      const newReportsHistory = [...reportsHistory];
      const foundReportIndex = reportsHistory.findIndex(report => report.id === deleteReportId);

      if (foundReportIndex >= 0) {
        dispatch(setReportHistoryTotal(reportsHistoryTotal - 1));
        newReportsHistory.splice(foundReportIndex, 1);
        dispatch(setReportsHistory(newReportsHistory));
        dispatch(removeReport(deleteReportId));
      }
    }
    setDeleteReportId(null);
  };

  const handleDeleteReportHistory = (id: string) => setDeleteReportId(id);

  const handleListScroll = (evt: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const historyList = evt.target as Element;
    const { limit } = defaultReportsHistoryFilter;
    const page = Math.ceil(reportsHistory.length / limit);

    if (historyList && reportsHistory.length < reportsHistoryTotal) {
      const historyListHeight = historyList.getBoundingClientRect().height;
      const historyListScrollHeight = historyList.scrollHeight;
      const historyListScrollTop = historyList.scrollTop;

      if (!isReportsHistoryLoading && historyListScrollTop >= historyListScrollHeight - historyListHeight) {
        dispatch(
          fetchReportsHistory({
            offset: page * limit,
            limit,
          })
        );
      }
    }
  };

  return {
    states: {
      deleteReportId,
    },
    handlers: {
      handleClickHideHistory,
      handleClickReportHistory,
      handleDeleteReportHistory,
      handleDeleteAlertCancel,
      handleDeleteAlertContinue,
      handleListScroll,
    },
    refs: {
      listRef,
    },
  };
}
