import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Logo } from 'assets/img/visitech_logo.svg';
import { ReactComponent as MonitoringIcon } from 'assets/img/header/monitoring.svg';
import { ReactComponent as AccountsIcon } from 'assets/img/header/accounts.svg';
import { ReactComponent as GeozonesIcon } from 'assets/img/header/geozones.svg';
import { ReactComponent as NotificationsIcon } from 'assets/img/header/notifications.svg';
import { ReactComponent as POIIcon } from 'assets/img/header/poi.svg';
import { ReactComponent as ReportsIcon } from 'assets/img/header/reports.svg';
import { ReactComponent as TrackersIcon } from 'assets/img/header/trackers.svg';
import { ReactComponent as TracksIcon } from 'assets/img/header/tracks.svg';
import { ReactComponent as HandBooksIcon } from 'assets/img/header/handbooks.svg';
import { ReactComponent as MessagesIcon } from 'assets/img/header/messages.svg';

import { LogOutBlock } from 'components/auth/auth';
import { TRACKERS_LOCATION_PATH } from 'components/trackers/utils/consts';
import { NOTIFICATIONS_LOCATION_PATH } from 'components/notifications/utils/consts';
import { GEOZONES_LOCATION_PATH } from 'components/geozones/utils/consts';
import { AccountSettingsKeysToNameTabsEnum } from 'components/accounts/utils/consts';

import { RootState } from 'reducers';
import { setAppHeaderHeight } from 'reducers/common';

import AccessEntity from 'utils/accessEntity';
import { TAB_PERMISSIONS } from 'utils/consts';

import styles from './header.module.scss';

const cx = classNames.bind(styles);

type SchemeItem = {
  to: string;
  icon: ReactNode;
  text: string;
  tabName: string;
  isHref?: boolean;
  dataCy?: string;
};

const LINKS_ITEM_DEFAULT_VALUE = 100;
const MAX_ELEM_WIDTH = LINKS_ITEM_DEFAULT_VALUE;

const SCHEME: SchemeItem[] = [
  {
    to: '/main/monitoring',
    icon: <MonitoringIcon />,
    text: AccountSettingsKeysToNameTabsEnum.monitoring,
    tabName: 'monitoring',
    dataCy: 'test_monitoring_tab',
  },
  {
    to: '/main/tracks',
    icon: <TracksIcon />,
    text: AccountSettingsKeysToNameTabsEnum.tracks,
    tabName: 'tracks',
    dataCy: 'test_tracks_tab',
  },
  {
    to: GEOZONES_LOCATION_PATH,
    icon: <GeozonesIcon />,
    text: AccountSettingsKeysToNameTabsEnum.geozones,
    tabName: 'geozones',
    dataCy: 'test_geozones_tab',
  },
  {
    to: NOTIFICATIONS_LOCATION_PATH,
    icon: <NotificationsIcon />,
    text: AccountSettingsKeysToNameTabsEnum.notifications,
    tabName: 'notifications',
    dataCy: 'test_notifications_tab',
  },
  {
    to: '/main/messages',
    icon: <MessagesIcon />,
    text: AccountSettingsKeysToNameTabsEnum.messages,
    tabName: 'messages',
    dataCy: 'test_message_tab',
  },
  {
    to: '/main/poi',
    icon: <POIIcon />,
    text: AccountSettingsKeysToNameTabsEnum.poi,
    tabName: 'poi',
    dataCy: 'test_poi_tab',
  },
  {
    to: '/main/handbooks',
    icon: <HandBooksIcon />,
    text: AccountSettingsKeysToNameTabsEnum.handbooks,
    tabName: 'handbooks',
    dataCy: 'test_handbooks_tab',
  },
  {
    to: TRACKERS_LOCATION_PATH,
    icon: <TrackersIcon />,
    text: AccountSettingsKeysToNameTabsEnum.trackers,
    tabName: 'trackers',
    dataCy: 'test_trackers_tab',
  },
  {
    to: '/main/accounts',
    icon: <AccountsIcon />,
    text: AccountSettingsKeysToNameTabsEnum.users,
    tabName: 'users',
    dataCy: 'test_users_tab',
  },
  {
    to: '/main/records',
    icon: <ReportsIcon />,
    text: AccountSettingsKeysToNameTabsEnum.records,
    tabName: 'records',
    dataCy: 'test_records_tab',
  },
  {
    to: 'http://report.messoyaha.visitech.local:3000/dashboard/d/p4I2xPlZk/vse-sotrudniki?orgId=1&search=open',
    icon: <ReportsIcon />,
    text: 'common-info.tabs.dashboards.name.text',
    tabName: 'reports',
    dataCy: 'test_reports_tab',
    isHref: true,
  },
  {
    to: 'http://172.29.55.3:10120',
    icon: <ReportsIcon />,
    text: 'common-info.tabs.videoanalytics.name.text',
    tabName: 'videoanalytics',
    dataCy: 'test_videoanalytics_tab',
    isHref: true,
  },
];

const Header: React.FC = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [elemWidth, setElemWidth] = useState(LINKS_ITEM_DEFAULT_VALUE);

  const linksRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const userPermissions = useSelector((state: RootState) => state.user.permissions);
  const userTabs = useSelector((state: RootState) => state.user.userPreferences.tabs);
  const userLng = useSelector((state: RootState) => state.user.userPreferences.locale);

  useEffect(() => {
    if (headerRef.current) {
      dispatch(setAppHeaderHeight(headerRef.current.getBoundingClientRect().height));
    }
  }, []);

  const findShowingRoutes = () => {
    const res: SchemeItem[] = [];
    userTabs?.forEach(ut => {
      if (ut.isVisible) {
        const totalAccess: boolean[] = [];
        const tab = TAB_PERMISSIONS[ut.keyName];
        for (let i = 0; i < tab.permissionsNames.length; i++) {
          const access = new AccessEntity(userPermissions[tab.permissionsNames[i]]);
          totalAccess.push(access.isAllowRead());
        }
        if (totalAccess.every(a => a)) {
          const route = SCHEME.find(r => r.tabName === ut.keyName);
          if (route) {
            return res.push(route);
          }
        }
      }
    });
    return res;
  };

  const showingRoutes = findShowingRoutes();

  useEffect(() => {
    function handleResize() {
      if (linksRef !== null && linksRef.current && linksRef.current.offsetWidth) {
        const elemWidth = linksRef.current.offsetWidth / showingRoutes.length;
        setElemWidth(elemWidth > MAX_ELEM_WIDTH ? MAX_ELEM_WIDTH : elemWidth);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showingRoutes.length]);

  return (
    <div ref={headerRef} className={styles.header}>
      <div className={styles.logo}>
        <div className={styles.logoWrapper}>
          <Logo />
          <div className={styles.logoText}>
            <span className={styles.logoTextIsobr}>{userLng === 'ru' ? 'ИСОБР' : 'ISSOW'}</span>
            <span className={styles.logoTextGis}>Visitech GIS</span>
          </div>
        </div>
      </div>
      <div className={styles.links} ref={linksRef}>
        {showingRoutes.map((item, index) => {
          if (item.isHref) {
            return (
              <div
                className={styles.linkWrapper}
                key={index}
                style={{
                  width: `${elemWidth}px`,
                }}
              >
                <a href={item.to} className={styles.link} target="_blank" rel="noopener noreferrer">
                  <div className={styles.linkContent}>
                    <div>{item.icon}</div>
                    <div data-cy={item.dataCy}>{t(item.text)}</div>
                  </div>
                </a>
              </div>
            );
          }
          return (
            <div
              className={styles.linkWrapper}
              key={index}
              style={{
                width: `${elemWidth}px`,
              }}
            >
              <NavLink to={item.to} className={styles.link} activeClassName={styles.selected}>
                <div className={styles.linkContent}>
                  <div>{item.icon}</div>
                  <div data-cy={item.dataCy}>{t(item.text)}</div>
                </div>
              </NavLink>
            </div>
          );
        })}
      </div>
      <div className={cx(styles.linkWrapper, styles.userMenu)}>
        <LogOutBlock />
      </div>
    </div>
  );
};

export default Header;
