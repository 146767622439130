import { RoleType } from 'reducers/roles/interface';
import { PermissionKeyName } from './consts';

export function findAccess(keyName: PermissionKeyName, selectedRoles: number[], roles: RoleType[]) {
  if (!selectedRoles.length) {
    return [0, 0, 0, 0];
  }

  const res = [0, 0, 0, 0];
  selectedRoles.forEach(roleId => {
    const role = roles.find(role => role.id === roleId);
    if (role) {
      role.attributes[keyName]
        .split('')
        .map((el: string) => parseInt(el))
        .forEach((perm, ind) => {
          res[ind] += perm;
        });
    }
  });
  return res;
}
