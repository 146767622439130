export default {
  en: {
    'handbooks.table.header.dictionaries.label': 'Directories',
    'handbooks.table.header.elements.label': 'Elements',
    'handbooks.table.header.elements-card.label': 'Element settings',

    'handbooks.column.dictionaries.name.default.text': 'Unnamed directory',
    'handbooks.column.dictionaries.name.organizations.text': 'Companies',
    'handbooks.column.dictionaries.name.departments.text': 'Subdivisions',
    'handbooks.column.dictionaries.name.positions.text': 'Positions',
    'handbooks.column.dictionaries.name.work-objects.text': 'Work areas',
    'handbooks.column.dictionaries.name.transport-brands.text': 'Vehicle manufacturers',
    'handbooks.column.dictionaries.name.transport-models.text': 'Vehicle models',
    'handbooks.column.dictionaries.name.transport-types.text': 'Vehicle types',
    'handbooks.column.dictionaries.name.transport-colors.text': 'Vehicle colors',
    'handbooks.column.dictionaries.name.transport-drivers.text': 'Vehicle drivers',
    'handbooks.column.dictionaries.name.fuel-types.text': 'Vehicle fuel types',
    'handbooks.column.dictionaries.name.poi-video-camera-models.text': 'POI: Camera models',
    'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text': 'POI: Gas analyzer models',
    'handbooks.column.dictionaries.name.poi-gas-sensors.text': 'POI: Types of gas sensors',
    'handbooks.column.dictionaries.name.poi-gas-measurements.text': 'POI: Gas units',
    'handbooks.column.dictionaries.name.poi-gas-types.text': 'POI: Gas types',
    'handbooks.column.dictionaries.name.poi-sensor-types.text': 'POI: Sensor types',
    'handbooks.column.dictionaries.name.poi-sensor-models.text': 'POI: Sensor models',
    'handbooks.column.dictionaries.name.poi-sensor-measure-units.text': 'POI: Sensor units',
    'handbooks.column.dictionaries.name.poi-equipment-types.text': 'POI: Equipment types',
    'handbooks.column.dictionaries.name.poi-equipment-models.text': 'POI: Equipment models',
    'handbooks.column.dictionaries.name.poi-accident-types.text': 'POI: Accident types',
    'handbooks.column.dictionaries.name.notification-sensors.text': 'Notifications: Sensors',
    'handbooks.column.dictionaries.name.notification-activations.text': 'Notifications: Activations',

    'handbooks.column.elements.table.header.name.label': 'Name',
    'handbooks.column.elements.table.header.creation-date.label': 'Date of creation',
    'handbooks.column.elements.table.header.change-date.label': 'Date of change',
    'handbooks.column.elements.footer.btn.import.text': 'Import',
    'handbooks.column.elements.footer.btn.export.text': 'Export',
    'handbooks.column.elements.footer.btn.statistic.text': 'Statistics',
    'handbooks.column.elements.footer.btn.create.text': 'Create',

    'handbooks.column.elements-card.placeholder.departments.text': 'Select subdivisions',
    'handbooks.column.elements-card.placeholder.positions.text': 'Select positions',
    'handbooks.column.elements-card.placeholder.objects.text': 'Select work areas',
    'handbooks.column.elements-card.placeholder.transport-brands.text': 'Select vehicle manufacturers',
    'handbooks.column.elements-card.placeholder.transport-models.text': 'Select vehicle models',
    'handbooks.column.elements-card.placeholder.transport-types.text': 'Select vehicle types',
    'handbooks.column.elements-card.placeholder.transport-colors.text': 'Select vehicle colors',
    'handbooks.column.elements-card.placeholder.fuel-types.text': 'Select vehicle fuel types',
    'handbooks.column.elements-card.placeholder.poi-video-camera-models.text': 'Select models',
    'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text': 'Select models',
    'handbooks.column.elements-card.placeholder.poi-gas-sensors.text': 'Select sensors',
    'handbooks.column.elements-card.placeholder.poi-gas-measurements.text': 'Select gas units',
    'handbooks.column.elements-card.placeholder.poi-gas-types.text': 'Select gas types',
    'handbooks.column.elements-card.placeholder.poi-sensors-types.text': 'Select sensors types',
    'handbooks.column.elements-card.placeholder.poi-sensors-models.text': 'Select sensors models',
    'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text': 'Select sensors units',
    'handbooks.column.elements-card.placeholder.poi-equipment-types.text': 'Select equipment types',
    'handbooks.column.elements-card.placeholder.poi-equipment-models.text': 'Select equipment models',
    'handbooks.column.elements-card.placeholder.poi-accident-types.text': 'Select accident types',
    'handbooks.column.elements-card.placeholder.notification-sensors.text': 'Select sensors',
    'handbooks.column.elements-card.placeholder.notification-activations.text': 'Select activations',
    'handbooks.column.elements-card.default.label': 'Data',
    'handbooks.column.elements-card.default.placeholder': 'No data to select',
    'handbooks.column.elements-card.name.label': 'Name in',
    'handbooks.column.elements-card.name.ru.text': 'russian',
    'handbooks.column.elements-card.name.en.text': 'english',
    'handbooks.column.elements-card.name.zh.text': 'chinese',
    'handbooks.column.elements-card.name.ar.text': 'arab',
    'handbooks.column.elements-card.name.de.text': 'german',
    'handbooks.column.elements-card.name.placeholder': 'Enter name',
    'handbooks.column.elements-card.organization-form.label': 'Company’s type',
    'handbooks.column.elements-card.organization-form.placeholder': 'Enter type',
    'handbooks.column.elements-card.inn.label': 'Organization’s TIN',
    'handbooks.column.elements-card.inn.placeholder': 'Enter TIN',
    'handbooks.column.elements-card.binded-handbook.text': 'Related directories',
    'handbooks.column.elements-card.footer.btn.cancel.text': 'Cancel',
    'handbooks.column.elements-card.footer.btn.delete.text': 'Delete',
    'handbooks.column.elements-card.footer.btn.save.text': 'Save',
    'handbooks.column.elements-card.footer.btn.update.text': 'Update',
    'handbooks.column.elements-card.alert.header.label': 'Close card',
    'handbooks.column.elements-card.alert.info.text': 'There is unsaved data on the form.',

    'handbooks.alert.header.label': 'Delete an entry in the directory',
    'handbooks.alert.warning.text': 'Warning',
    'handbooks.alert.info.text': 'Deleted entry cannot be recovered.',
    'handbooks.alert.question.text': 'Do you really want to continue?',
    'handbooks.alert.footer.btn.no.label': 'No',
    'handbooks.alert.footer.btn.yes.label': 'Yes',

    'handbooks.transport-drivers.form.lastName.label': '',
    'handbooks.transport-drivers.form.firstName.label': '',
    'handbooks.transport-drivers.form.secondName.label': '',
    'handbooks.transport-drivers.form.organization.label': '',
    'handbooks.transport-drivers.form.department.label': '',
    'handbooks.transport-drivers.form.position.label': '',

    'handbooks.statistic-modal.header.label': 'Directory statistics',
    'handbooks.statistic-modal.btn.update.label': 'Update',
    'handbooks.statistic-modal.btn.save.label': 'Save',
    'handbooks.statistic-modal.field.directory.label': 'Directory',
    'handbooks.statistic-modal.field.directory.placeholder': 'Select from a list',
    'handbooks.statistic-modal.field.period-start.label': 'Period start date',
    'handbooks.statistic-modal.field.period-start.placeholder': 'Select date',
    'handbooks.statistic-modal.field.period-end.label': 'Period end date',
    'handbooks.statistic-modal.field.period-end.placeholder': 'Select date',
    'handbooks.statistic-modal.btn.show.label': 'Display',
    'handbooks.statistic-modal.table.header.date.label': 'Date',
    'handbooks.statistic-modal.table.header.time.label': 'Time',
    'handbooks.statistic-modal.table.header.user.label': 'User',
    'handbooks.statistic-modal.table.header.username.label': 'Username',
    'handbooks.statistic-modal.table.header.message.label': 'Message',
    'handbooks.statistic-modal.table.pagination.text': 'Items per page',
    'handbooks.statistic-modal.table.row.create.text': 'Element added',
    'handbooks.statistic-modal.table.row.update.text': 'Element updated',
    'handbooks.statistic-modal.table.row.delete.text': 'Element deleted',
    'handbooks.statistic-modal.table.row.import-1.text': 'Imported',
    'handbooks.statistic-modal.table.row.import-2.text': 'elements',
    'handbooks.statistic-modal.footer.btn.close.label': 'Close',
    'handbooks.statistic-modal.footer.btn.save.label': 'Save as file',

    'handbooks.add-translation.btn.label': 'Add field translation',
    'handbooks.add-translation.option.text.ru': 'Russian',
    'handbooks.add-translation.option.text.en': 'English',
    'handbooks.add-translation.option.text.zh': 'Chinese',
    'handbooks.add-translation.option.text.ar': 'Arab',
    'handbooks.add-translation.option.text.de': 'German',
  },
  ru: {
    'handbooks.table.header.dictionaries.label': 'Справочники',
    'handbooks.table.header.elements.label': 'Элементы',
    'handbooks.table.header.elements-card.label': 'Карточка элемента',

    'handbooks.column.dictionaries.name.default.text': 'Безымянный справочник',
    'handbooks.column.dictionaries.name.organizations.text': 'Организации',
    'handbooks.column.dictionaries.name.departments.text': 'Подразделения',
    'handbooks.column.dictionaries.name.positions.text': 'Должности',
    'handbooks.column.dictionaries.name.work-objects.text': 'Объекты выполнения работ',
    'handbooks.column.dictionaries.name.transport-brands.text': 'Марки ТС',
    'handbooks.column.dictionaries.name.transport-models.text': 'Модели ТС',
    'handbooks.column.dictionaries.name.transport-types.text': 'Типы ТС',
    'handbooks.column.dictionaries.name.transport-colors.text': 'Цвета ТС',
    'handbooks.column.dictionaries.name.transport-drivers.text': 'Водители ТС',
    'handbooks.column.dictionaries.name.fuel-types.text': 'Виды топлива ТС',
    'handbooks.column.dictionaries.name.poi-video-camera-models.text': 'POI: Модели видеокамер',
    'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text': 'POI: Модели газанализаторов',
    'handbooks.column.dictionaries.name.poi-gas-sensors.text': 'POI: Типы датчиков газа',
    'handbooks.column.dictionaries.name.poi-gas-measurements.text': 'POI: Единицы измерения газа',
    'handbooks.column.dictionaries.name.poi-gas-types.text': 'POI: Типы газов',
    'handbooks.column.dictionaries.name.poi-sensor-types.text': 'POI: Типы датчиков',
    'handbooks.column.dictionaries.name.poi-sensor-models.text': 'POI: Модели датчиков',
    'handbooks.column.dictionaries.name.poi-sensor-measure-units.text': 'POI: Единицы измерения датчиков',
    'handbooks.column.dictionaries.name.poi-equipment-types.text': 'POI: Типы оснащения',
    'handbooks.column.dictionaries.name.poi-equipment-models.text': 'POI: Модели оснащения',
    'handbooks.column.dictionaries.name.poi-accident-types.text': 'POI: Типы аварий',
    'handbooks.column.dictionaries.name.notification-sensors.text': 'Уведомления: Датчики',
    'handbooks.column.dictionaries.name.notification-activations.text': 'Уведомления: Активации',

    'handbooks.column.elements.table.header.name.label': 'Название',
    'handbooks.column.elements.table.header.creation-date.label': 'Дата создания',
    'handbooks.column.elements.table.header.change-date.label': 'Дата изменения',
    'handbooks.column.elements.footer.btn.import.text': 'Импорт',
    'handbooks.column.elements.footer.btn.export.text': 'Экспорт',
    'handbooks.column.elements.footer.btn.statistic.text': 'Статистика',
    'handbooks.column.elements.footer.btn.create.text': 'Создать',

    'handbooks.column.elements-card.placeholder.departments.text': 'Выберите структурные подразделения',
    'handbooks.column.elements-card.placeholder.positions.text': 'Выберите должности',
    'handbooks.column.elements-card.placeholder.objects.text': 'Выберите объекты работ',
    'handbooks.column.elements-card.placeholder.transport-brands.text': 'Выберите марки ТС',
    'handbooks.column.elements-card.placeholder.transport-models.text': 'Выберите модели ТС',
    'handbooks.column.elements-card.placeholder.transport-types.text': 'Выберите типы ТС',
    'handbooks.column.elements-card.placeholder.transport-colors.text': 'Выберите цвета ТС',
    'handbooks.column.elements-card.placeholder.fuel-types.text': 'Выберите типы топлива',
    'handbooks.column.elements-card.placeholder.poi-video-camera-models.text': 'Выберите модели',
    'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text': 'Выберите модели',
    'handbooks.column.elements-card.placeholder.poi-gas-sensors.text': 'Выберите датчики',
    'handbooks.column.elements-card.placeholder.poi-gas-measurements.text': 'Выберите единицы измерения газа',
    'handbooks.column.elements-card.placeholder.poi-gas-types.text': 'Выберите типы газов',
    'handbooks.column.elements-card.placeholder.poi-sensors-types.text': 'Выберите типы датчиков',
    'handbooks.column.elements-card.placeholder.poi-sensors-models.text': 'Выберите модели датчиков',
    'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text': 'Выберите единицы измерений датчиков',
    'handbooks.column.elements-card.placeholder.poi-equipment-types.text': 'Выберите типы оснащения',
    'handbooks.column.elements-card.placeholder.poi-equipment-models.text': 'Выберите модели оснащения',
    'handbooks.column.elements-card.placeholder.poi-accident-types.text': 'Выберите типы аварий',
    'handbooks.column.elements-card.placeholder.notification-sensors.text': 'Выберите датчики уведомлений',
    'handbooks.column.elements-card.placeholder.notification-activations.text': 'Выберите активации уведомлений',
    'handbooks.column.elements-card.default.label': 'Данные',
    'handbooks.column.elements-card.default.placeholder': 'Нет данных для выбора',
    'handbooks.column.elements-card.name.label': 'Название на',
    'handbooks.column.elements-card.name.ru.text': 'русском',
    'handbooks.column.elements-card.name.en.text': 'английском',
    'handbooks.column.elements-card.name.zh.text': 'китайском',
    'handbooks.column.elements-card.name.ar.text': 'арабском',
    'handbooks.column.elements-card.name.de.text': 'немецком',
    'handbooks.column.elements-card.name.placeholder': 'Введите название',
    'handbooks.column.elements-card.organization-form.label': 'Форма организации',
    'handbooks.column.elements-card.organization-form.placeholder': 'Введите форму',
    'handbooks.column.elements-card.inn.label': 'ИНН организации',
    'handbooks.column.elements-card.inn.placeholder': 'Введите ИНН',
    'handbooks.column.elements-card.gaz-analyzer-model.label': 'Модель газанализатора',
    'handbooks.column.elements-card.gaz-analyzer-model.placeholder': 'Выберите модель газанализатора',
    'handbooks.column.elements-card.binded-handbook.text': 'Связанные справочники',
    'handbooks.column.elements-card.footer.btn.cancel.text': 'Отмена',
    'handbooks.column.elements-card.footer.btn.delete.text': 'Удалить',
    'handbooks.column.elements-card.footer.btn.save.text': 'Сохранить',
    'handbooks.column.elements-card.footer.btn.update.text': 'Обновить',
    'handbooks.column.elements-card.alert.header.label': 'Закрытие карточки',
    'handbooks.column.elements-card.alert.info.text': 'На форме остались несохраненные данные.',

    'handbooks.alert.header.label': 'Удаление записи в справочнике',
    'handbooks.alert.warning.text': 'Внимание',
    'handbooks.alert.info.text': 'Удаленную запись нельзя будет восстановить.',
    'handbooks.alert.question.text': 'Вы действительно хотите продолжить?',
    'handbooks.alert.footer.btn.no.label': 'Нет',
    'handbooks.alert.footer.btn.yes.label': 'Да',

    'handbooks.transport-drivers.form.lastName.label': 'Фамилия водителя',
    'handbooks.transport-drivers.form.firstName.label': 'Имя водителя',
    'handbooks.transport-drivers.form.secondName.label': 'Отчество водителя',
    'handbooks.transport-drivers.form.organization.label': 'Организация',
    'handbooks.transport-drivers.form.department.label': 'Структурное подразделение',
    'handbooks.transport-drivers.form.position.label': 'Должность',

    'handbooks.statistic-modal.header.label': 'Статистика справочника',
    'handbooks.statistic-modal.btn.update.label': 'Обновить',
    'handbooks.statistic-modal.btn.save.label': 'Сохранить',
    'handbooks.statistic-modal.field.directory.label': 'Cправочник',
    'handbooks.statistic-modal.field.directory.placeholder': 'Выберите из списка',
    'handbooks.statistic-modal.field.period-start.label': 'Дата начала периода',
    'handbooks.statistic-modal.field.period-start.placeholder': 'Выберите дату',
    'handbooks.statistic-modal.field.period-end.label': 'Дата конца периода',
    'handbooks.statistic-modal.field.period-end.placeholder': 'Выберите дату',
    'handbooks.statistic-modal.btn.show.label': 'Показать',
    'handbooks.statistic-modal.table.header.date.label': 'Дата',
    'handbooks.statistic-modal.table.header.time.label': 'Время',
    'handbooks.statistic-modal.table.header.user.label': 'Пользователь',
    'handbooks.statistic-modal.table.header.username.label': 'Логин',
    'handbooks.statistic-modal.table.header.message.label': 'Сообщение',
    'handbooks.statistic-modal.table.pagination.text': 'Элементов на странице',
    'handbooks.statistic-modal.table.row.create.text': 'Добавлен элемент',
    'handbooks.statistic-modal.table.row.update.text': 'Обновлен элемент',
    'handbooks.statistic-modal.table.row.delete.text': 'Удален элемент',
    'handbooks.statistic-modal.table.row.import-1.text': 'Импортировано',
    'handbooks.statistic-modal.table.row.import-2.text': 'элементов',
    'handbooks.statistic-modal.footer.btn.close.label': 'Закрыть',
    'handbooks.statistic-modal.footer.btn.save.label': 'Сохранить в файл',

    'handbooks.add-translation.btn.label': 'Добавить перевод поля',
    'handbooks.add-translation.option.text.ru': 'Русский',
    'handbooks.add-translation.option.text.en': 'Английский',
    'handbooks.add-translation.option.text.zh': 'Китайский',
    'handbooks.add-translation.option.text.ar': 'Арабский',
    'handbooks.add-translation.option.text.de': 'Немецкий',
  },
  zh: {
    'handbooks.table.header.dictionaries.label': '目录',
    'handbooks.table.header.elements.label': '元素',
    'handbooks.table.header.elements-card.label': '元素卡',
    'handbooks.column.dictionaries.name.default.text': '未命名目录',
    'handbooks.column.dictionaries.name.organizations.text': '单位',
    'handbooks.column.dictionaries.name.departments.text': '部门',
    'handbooks.column.dictionaries.name.positions.text': '职位',
    'handbooks.column.dictionaries.name.work-objects.text': '工作执行对象',
    'handbooks.column.dictionaries.name.transport-brands.text': '车辆品牌',
    'handbooks.column.dictionaries.name.transport-models.text': '车型',
    'handbooks.column.dictionaries.name.transport-types.text': '车辆类型',
    'handbooks.column.dictionaries.name.transport-colors.text': '车辆颜色',
    'handbooks.column.dictionaries.name.transport-drivers.text': 'Vehicle drivers',
    'handbooks.column.dictionaries.name.fuel-types.text': '车辆燃料类型',
    'handbooks.column.dictionaries.name.poi-video-camera-models.text': 'POI：相机型号',
    'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text': 'POI：气体分析仪型号',
    'handbooks.column.dictionaries.name.poi-gas-sensors.text': 'POI：气体传感器类型',
    'handbooks.column.dictionaries.name.poi-gas-measurements.text': 'POI：气体单位',
    'handbooks.column.dictionaries.name.poi-gas-types.text': 'POI：气体类型',
    'handbooks.column.dictionaries.name.poi-sensor-types.text': 'POI：传感器类型',
    'handbooks.column.dictionaries.name.poi-sensor-models.text': 'POI：传感器模型',
    'handbooks.column.dictionaries.name.poi-sensor-measure-units.text': 'POI：传感器的测量单位',
    'handbooks.column.dictionaries.name.poi-equipment-types.text': 'POI：设备类型',
    'handbooks.column.dictionaries.name.poi-equipment-models.text': 'POI：设备型号',
    'handbooks.column.dictionaries.name.poi-accident-types.text': 'POI：事件类型',
    'handbooks.column.dictionaries.name.notification-sensors.text': '通知：传感器',
    'handbooks.column.dictionaries.name.notification-activations.text': '通知：激活',
    'handbooks.column.elements.table.header.name.label': '名称',
    'handbooks.column.elements.table.header.creation-date.label': '创建日期',
    'handbooks.column.elements.table.header.change-date.label': '更改日期',
    'handbooks.column.elements.footer.btn.import.text': '导入',
    'handbooks.column.elements.footer.btn.export.text': '导出',
    'handbooks.column.elements.footer.btn.statistic.text': '统计',
    'handbooks.column.elements.footer.btn.create.text': '创建日期',
    'handbooks.column.elements-card.placeholder.departments.text': '选择细分',
    'handbooks.column.elements-card.placeholder.positions.text': '选择位置',
    'handbooks.column.elements-card.placeholder.objects.text': '选择工作区',
    'handbooks.column.elements-card.placeholder.transport-brands.text': '选择汽车制造商',
    'handbooks.column.elements-card.placeholder.transport-models.text': '选择车型',
    'handbooks.column.elements-card.placeholder.transport-types.text': '选择车型',
    'handbooks.column.elements-card.placeholder.transport-colors.text': '选择汽车颜色',
    'handbooks.column.elements-card.placeholder.fuel-types.text': '选择汽车的燃料类型',
    'handbooks.column.elements-card.placeholder.poi-video-camera-models.text': '选择您的型号',
    'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text': '选择您的型号',
    'handbooks.column.elements-card.placeholder.poi-gas-sensors.text': '选择传感器',
    'handbooks.column.elements-card.placeholder.poi-gas-measurements.text': '选择气体单位',
    'handbooks.column.elements-card.placeholder.poi-gas-types.text': '选择气体类型',
    'handbooks.column.elements-card.placeholder.poi-sensors-types.text': '选择传感器类型',
    'handbooks.column.elements-card.placeholder.poi-sensors-models.text': '选择传感器型号',
    'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text': '选择传感器单元',
    'handbooks.column.elements-card.placeholder.poi-equipment-types.text': '选择设备类型',
    'handbooks.column.elements-card.placeholder.poi-equipment-models.text': '选择设备型号',
    'handbooks.column.elements-card.placeholder.poi-accident-types.text': '选择事件类型',
    'handbooks.column.elements-card.placeholder.notification-sensors.text': '选择传感器',
    'handbooks.column.elements-card.placeholder.notification-activations.text': '选择激活通知',
    'handbooks.column.elements-card.default.label': '数据',
    'handbooks.column.elements-card.default.placeholder': '没有可供选择的数据',
    'handbooks.column.elements-card.name.label': '标题在',
    'handbooks.column.elements-card.name.ru.text': '俄语',
    'handbooks.column.elements-card.name.en.text': '英语',
    'handbooks.column.elements-card.name.zh.text': '中文',
    'handbooks.column.elements-card.name.ar.text': '阿拉伯',
    'handbooks.column.elements-card.name.de.text': '德语',
    'handbooks.column.elements-card.name.placeholder': '输入标题',
    'handbooks.column.elements-card.organization-form.label': '组织形式',
    'handbooks.column.elements-card.organization-form.placeholder': '输入表格',
    'handbooks.column.elements-card.inn.label': '组织的 TIN',
    'handbooks.column.elements-card.inn.placeholder': '输入 TIN',
    'handbooks.column.elements-card.binded-handbook.text': '相关参考',
    'handbooks.column.elements-card.footer.btn.cancel.text': '取消',
    'handbooks.column.elements-card.footer.btn.save.text': '保存',
    'handbooks.column.elements-card.alert.header.label': '关闭卡片',
    'handbooks.column.elements-card.alert.info.text': '表单上有未保存的数据。',
    'handbooks.alert.header.label': '消除手册记录',
    'handbooks.alert.warning.text': '注意',
    'handbooks.alert.info.text': '无法恢复已消除的记录。',
    'handbooks.alert.question.text': '您真的要继续吗?',
    'handbooks.alert.footer.btn.no.label': '不是',
    'handbooks.alert.footer.btn.yes.label': '是',

    'handbooks.transport-drivers.form.lastName.label': '',
    'handbooks.transport-drivers.form.firstName.label': '',
    'handbooks.transport-drivers.form.secondName.label': '',
    'handbooks.transport-drivers.form.organization.label': '',
    'handbooks.transport-drivers.form.department.label': '',
    'handbooks.transport-drivers.form.position.label': '',

    'handbooks.statistic-modal.header.label': '目录统计',
    'handbooks.statistic-modal.btn.update.label': '更新',
    'handbooks.statistic-modal.btn.save.label': '保存',
    'handbooks.statistic-modal.field.directory.label': '参考书',
    'handbooks.statistic-modal.field.directory.placeholder': '从列表中选择',
    'handbooks.statistic-modal.field.period-start.label': '期间开始日期',
    'handbooks.statistic-modal.field.period-start.placeholder': '选择日期',
    'handbooks.statistic-modal.field.period-end.label': '期间结束日期',
    'handbooks.statistic-modal.field.period-end.placeholder': '选择日期',
    'handbooks.statistic-modal.btn.show.label': '表演',
    'handbooks.statistic-modal.table.header.date.label': '日期',
    'handbooks.statistic-modal.table.header.time.label': '时间',
    'handbooks.statistic-modal.table.header.user.label': '用户',
    'handbooks.statistic-modal.table.header.username.label': '登录名',
    'handbooks.statistic-modal.table.header.message.label': '信息',
    'handbooks.statistic-modal.table.pagination.text': '每页项目',
    'handbooks.statistic-modal.table.row.create.text': '添加元素',
    'handbooks.statistic-modal.table.row.import-1.text': '进口',
    'handbooks.statistic-modal.table.row.import-2.text': '元素',
    'handbooks.statistic-modal.footer.btn.close.label': '关闭',
    'handbooks.statistic-modal.footer.btn.save.label': '保存到文件',
    'handbooks.add-translation.btn.label': '添加字段翻译',
    'handbooks.add-translation.option.text.ru': '俄语',
    'handbooks.add-translation.option.text.en': '英语',
    'handbooks.add-translation.option.text.zh': '中文',
    'handbooks.add-translation.option.text.ar': '阿拉伯',
    'handbooks.add-translation.option.text.de': '德语',
  },
  ar: {
    'handbooks.table.header.dictionaries.label': 'مراجع',
    'handbooks.table.header.elements.label': 'عناصر',
    'handbooks.table.header.elements-card.label': 'بطاقة العنصر',
    'handbooks.column.dictionaries.name.default.text': 'مرجع بدون اسم',
    'handbooks.column.dictionaries.name.organizations.text': 'مؤسسات',
    'handbooks.column.dictionaries.name.departments.text': 'شُعب',
    'handbooks.column.dictionaries.name.positions.text': 'وظائف',
    'handbooks.column.dictionaries.name.work-objects.text': 'مناطق العمل',
    'handbooks.column.dictionaries.name.transport-brands.text': 'ماركات وسائل النقل',
    'handbooks.column.dictionaries.name.transport-models.text': 'أطرزة وسائل النقل',
    'handbooks.column.dictionaries.name.transport-types.text': 'أنواع وسائل النقل',
    'handbooks.column.dictionaries.name.transport-colors.text': 'ألوان وسائل النقل',
    'handbooks.column.dictionaries.name.transport-drivers.text': 'Vehicle drivers',
    'handbooks.column.dictionaries.name.fuel-types.text': 'نوع الوقود لدى وسائل النقل',
    'handbooks.column.dictionaries.name.poi-video-camera-models.text': 'POI: موديلات كاميرات الفيديو',
    'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text': 'POI: موديلات محللات الغاز',
    'handbooks.column.dictionaries.name.poi-gas-sensors.text': 'POI: أنواع مستشعرات الغاز',
    'handbooks.column.dictionaries.name.poi-gas-measurements.text': 'POI: وحدات قياس الغاز',
    'handbooks.column.dictionaries.name.poi-gas-types.text': 'POI: أنواع الغازات',
    'handbooks.column.dictionaries.name.poi-sensor-types.text': 'POI: أنواع أجهزة الاستشعار',
    'handbooks.column.dictionaries.name.poi-sensor-models.text': 'POI: موديلات أجهزة الاستشعار',
    'handbooks.column.dictionaries.name.poi-sensor-measure-units.text': 'POI: وحدات قياس الغاز',
    'handbooks.column.dictionaries.name.poi-equipment-types.text': 'POI: أنواع العدة',
    'handbooks.column.dictionaries.name.poi-equipment-models.text': 'POI: موديلات العدة',
    'handbooks.column.dictionaries.name.poi-accident-types.text': 'POI: أنواع الأحداث',
    'handbooks.column.dictionaries.name.notification-sensors.text': 'الإشعارات: أجهزة الاستشعار',
    'handbooks.column.dictionaries.name.notification-activations.text': 'الإشعارات: عمليات التفعيل',
    'handbooks.column.elements.table.header.name.label': 'اسم',
    'handbooks.column.elements.table.header.creation-date.label': 'تاريخ الإنشاء',
    'handbooks.column.elements.table.header.change-date.label': 'تاريخ التعديل',
    'handbooks.column.elements.footer.btn.import.text': 'استيراد',
    'handbooks.column.elements.footer.btn.export.text': 'تصدير',
    'handbooks.column.elements.footer.btn.statistic.text': 'الإحصاء',
    'handbooks.column.elements.footer.btn.create.text': 'إنشاء',
    'handbooks.column.elements-card.placeholder.departments.text': 'اختر الشُعب',
    'handbooks.column.elements-card.placeholder.positions.text': 'اختر الوظائف',
    'handbooks.column.elements-card.placeholder.objects.text': 'اختر كائنات العمل',
    'handbooks.column.elements-card.placeholder.transport-brands.text': 'اختر ماركات وسائل النقل',
    'handbooks.column.elements-card.placeholder.transport-models.text': 'اختر أطرزة وسائل النقل',
    'handbooks.column.elements-card.placeholder.transport-types.text': 'اختر أنواع وسائل النقل',
    'handbooks.column.elements-card.placeholder.transport-colors.text': 'اختر ألوان وسائل النقل',
    'handbooks.column.elements-card.placeholder.fuel-types.text': 'اختر أنواع الوقود',
    'handbooks.column.elements-card.placeholder.poi-video-camera-models.text': 'اختر الأطرزة',
    'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text': 'اختر الموديلات',
    'handbooks.column.elements-card.placeholder.poi-gas-sensors.text': 'اختر أجهزة الاستشعار',
    'handbooks.column.elements-card.placeholder.poi-gas-measurements.text': 'اختر وحدات قياس الغاز',
    'handbooks.column.elements-card.placeholder.poi-gas-types.text': 'اختر أنواع الغاز',
    'handbooks.column.elements-card.placeholder.poi-sensors-types.text': 'اختر أنواع أجهزة الاستشعار',
    'handbooks.column.elements-card.placeholder.poi-sensors-models.text': 'اختر موديلات أجهزة الاستشعار',
    'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text': 'اختر وحدات قياس أجهزة الاستشعار',
    'handbooks.column.elements-card.placeholder.poi-equipment-types.text': 'اختر أنواع العدة',
    'handbooks.column.elements-card.placeholder.poi-equipment-models.text': 'اختر موديلات العدة',
    'handbooks.column.elements-card.placeholder.poi-accident-types.text': 'اختر أنواع الأحداث',
    'handbooks.column.elements-card.placeholder.notification-sensors.text': 'اختر مستشعرات الإشعارات',
    'handbooks.column.elements-card.placeholder.notification-activations.text': 'اختر تفعيلات الإشعارات',
    'handbooks.column.elements-card.default.label': 'بيانات',
    'handbooks.column.elements-card.default.placeholder': 'لا يوجد بيانات للاختيار',
    'handbooks.column.elements-card.name.label': 'الاسم باللغة',
    'handbooks.column.elements-card.name.ru.text': 'الروسية',
    'handbooks.column.elements-card.name.en.text': 'الإنجليزية',
    'handbooks.column.elements-card.name.zh.text': 'الصينية',
    'handbooks.column.elements-card.name.ar.text': 'العربية',
    'handbooks.column.elements-card.name.de.text': 'الألمانية',
    'handbooks.column.elements-card.name.placeholder': 'أكتب اسمًا',
    'handbooks.column.elements-card.organization-form.label': 'نوع الشركة',
    'handbooks.column.elements-card.organization-form.placeholder': 'أكتب نوعًا',
    'handbooks.column.elements-card.inn.label': 'الرقم الضريبي للمؤسسة',
    'handbooks.column.elements-card.inn.placeholder': 'أكتب الرقم الضريبي',
    'handbooks.column.elements-card.binded-handbook.text': 'مراجع ذات صلة',
    'handbooks.column.elements-card.footer.btn.cancel.text': 'إلغاء',
    'handbooks.column.elements-card.footer.btn.save.text': 'حفظ',
    'handbooks.column.elements-card.alert.header.label': 'شطب البطاقة',
    'handbooks.column.elements-card.alert.info.text': 'هناك بيانات غير محفوظة في النموذج.',
    'handbooks.alert.header.label': 'حذف السجلات في الدليل',
    'handbooks.alert.warning.text': 'انتباه',
    'handbooks.alert.info.text': 'لا يمكن استعادة السجل المحذوف.',
    'handbooks.alert.question.text': 'هل حقا تريد الاستمرار؟',
    'handbooks.alert.footer.btn.no.label': 'لا',
    'handbooks.alert.footer.btn.yes.label': 'نعم',

    'handbooks.transport-drivers.form.lastName.label': '',
    'handbooks.transport-drivers.form.firstName.label': '',
    'handbooks.transport-drivers.form.secondName.label': '',
    'handbooks.transport-drivers.form.organization.label': '',
    'handbooks.transport-drivers.form.department.label': '',
    'handbooks.transport-drivers.form.position.label': '',

    'handbooks.statistic-modal.header.label': 'إحصاء المرجع',
    'handbooks.statistic-modal.btn.update.label': 'تحديث',
    'handbooks.statistic-modal.btn.save.label': 'حفظ',
    'handbooks.statistic-modal.field.directory.label': 'مرجع',
    'handbooks.statistic-modal.field.directory.placeholder': 'اختر من القائمة',
    'handbooks.statistic-modal.field.period-start.label': 'تاريخ بدء المهلة',
    'handbooks.statistic-modal.field.period-start.placeholder': 'اختر التاريخ',
    'handbooks.statistic-modal.field.period-end.label': 'تاريخ انتهاء المهلة',
    'handbooks.statistic-modal.field.period-end.placeholder': 'اختر التاريخ',
    'handbooks.statistic-modal.btn.show.label': 'عرض',
    'handbooks.statistic-modal.table.header.date.label': 'التاريخ',
    'handbooks.statistic-modal.table.header.time.label': 'الوقت',
    'handbooks.statistic-modal.table.header.user.label': 'المستخدم',
    'handbooks.statistic-modal.table.header.username.label': 'اسم الدخول',
    'handbooks.statistic-modal.table.header.message.label': 'رسالة',
    'handbooks.statistic-modal.table.pagination.text': 'عناصر بصفحة',
    'handbooks.statistic-modal.table.row.create.text': 'تم إصافة العنصر',
    'handbooks.statistic-modal.table.row.import-1.text': 'المستوردة',
    'handbooks.statistic-modal.table.row.import-2.text': 'عناصر',
    'handbooks.statistic-modal.footer.btn.close.label': 'إغلاق',
    'handbooks.statistic-modal.footer.btn.save.label': 'حفظ كملف',
    'handbooks.add-translation.btn.label': 'إضافة ترجمة الخانة',
    'handbooks.add-translation.option.text.ru': 'الروسية',
    'handbooks.add-translation.option.text.en': 'الإنجليزية',
    'handbooks.add-translation.option.text.zh': 'الصينية',
    'handbooks.add-translation.option.text.ar': 'العربية',
    'handbooks.add-translation.option.text.de': 'الألمانية',
  },
  de: {
    'handbooks.table.header.dictionaries.label': 'Verzeichnisse',
    'handbooks.table.header.elements.label': 'Elemente',
    'handbooks.table.header.elements-card.label': 'Gegenstandskarte',
    'handbooks.column.dictionaries.name.default.text': 'Unbenanntes Verzeichnis',
    'handbooks.column.dictionaries.name.organizations.text': 'Organisationen',
    'handbooks.column.dictionaries.name.departments.text': 'Niederlassungen',
    'handbooks.column.dictionaries.name.positions.text': 'Dienststellungen',
    'handbooks.column.dictionaries.name.work-objects.text': 'Arbeitsgegenstände',
    'handbooks.column.dictionaries.name.transport-brands.text': 'Fahrzeugmarken',
    'handbooks.column.dictionaries.name.transport-models.text': 'Fahrzeugmodelle',
    'handbooks.column.dictionaries.name.transport-types.text': 'Fahrzeugtypen',
    'handbooks.column.dictionaries.name.transport-colors.text': 'Fahrzeugfarben',
    'handbooks.column.dictionaries.name.transport-drivers.text': 'Vehicle drivers',
    'handbooks.column.dictionaries.name.fuel-types.text': 'Arten von Fahrzeugkraftstoff',
    'handbooks.column.dictionaries.name.poi-video-camera-models.text': 'POI: Camcorder-Modelle',
    'handbooks.column.dictionaries.name.poi-gas-analyzer-models.text': 'POI: Modelle von Gasanalysatoren',
    'handbooks.column.dictionaries.name.poi-gas-sensors.text': 'POI: Arten von Gassensoren',
    'handbooks.column.dictionaries.name.poi-gas-measurements.text': 'POI: Gaseinheiten',
    'handbooks.column.dictionaries.name.poi-gas-types.text': 'POI: Gasarten',
    'handbooks.column.dictionaries.name.poi-sensor-types.text': 'POI: Sensortypen',
    'handbooks.column.dictionaries.name.poi-sensor-models.text': 'POI: Sensormodelle',
    'handbooks.column.dictionaries.name.poi-sensor-measure-units.text': 'POI: Maßeinheiten von Sensoren',
    'handbooks.column.dictionaries.name.poi-equipment-types.text': 'POI: Gerätetypen',
    'handbooks.column.dictionaries.name.poi-equipment-models.text': 'POI: Ausrüstungsmodelle',
    'handbooks.column.dictionaries.name.poi-accident-types.text': 'POI: Unfallarten',
    'handbooks.column.dictionaries.name.notification-sensors.text': 'Benachrichtigungen: Sensoren',
    'handbooks.column.dictionaries.name.notification-activations.text': 'Benachrichtigungen: Aktivierungen',
    'handbooks.column.elements.table.header.name.label': 'Name',
    'handbooks.column.elements.table.header.creation-date.label': 'Erstelldatum',
    'handbooks.column.elements.table.header.change-date.label': 'Datum der Änderung',
    'handbooks.column.elements.footer.btn.import.text': 'Import',
    'handbooks.column.elements.footer.btn.export.text': 'Export',
    'handbooks.column.elements.footer.btn.statistic.text': 'Statistiken',
    'handbooks.column.elements.footer.btn.create.text': 'Erstellen',
    'handbooks.column.elements-card.placeholder.departments.text': 'Strukturbereiche auswählen ',
    'handbooks.column.elements-card.placeholder.positions.text': 'Positionen auswählen',
    'handbooks.column.elements-card.placeholder.objects.text': 'Arbeitsobjekte auswählen',
    'handbooks.column.elements-card.placeholder.transport-brands.text': 'Fahrzeugmarken auswählen',
    'handbooks.column.elements-card.placeholder.transport-models.text': 'Fahrzeugmodelle auswählen',
    'handbooks.column.elements-card.placeholder.transport-types.text': 'Fahrzeugtypen auswählen',
    'handbooks.column.elements-card.placeholder.transport-colors.text': 'Fahrzeugfarben auswählen',
    'handbooks.column.elements-card.placeholder.fuel-types.text': 'Kraftstoffarten auswählen',
    'handbooks.column.elements-card.placeholder.poi-video-camera-models.text': 'Modelle auswählen',
    'handbooks.column.elements-card.placeholder.poi-gas-analyzer-models.text': 'Modelle auswählen',
    'handbooks.column.elements-card.placeholder.poi-gas-sensors.text': 'Sensoren auswählen',
    'handbooks.column.elements-card.placeholder.poi-gas-measurements.text': 'Gasgeräte auswählen',
    'handbooks.column.elements-card.placeholder.poi-gas-types.text': 'Gasarten auswählen',
    'handbooks.column.elements-card.placeholder.poi-sensors-types.text': 'Sensortypen auswählen',
    'handbooks.column.elements-card.placeholder.poi-sensors-models.text': 'Sensormodelle auswählen',
    'handbooks.column.elements-card.placeholder.poi-sensors-measure-units.text': 'Sensoreinheiten auswählen',
    'handbooks.column.elements-card.placeholder.poi-equipment-types.text': 'Gerätetypen auswählen',
    'handbooks.column.elements-card.placeholder.poi-equipment-models.text': 'Ausstattungsmodelle auswählen',
    'handbooks.column.elements-card.placeholder.poi-accident-types.text': 'Alarmtypen auswählen',
    'handbooks.column.elements-card.placeholder.notification-sensors.text': 'Benachrichtigungssensoren auswählen',
    'handbooks.column.elements-card.placeholder.notification-activations.text':
      'Aktivierungsbenachrichtigungen auswählen',
    'handbooks.column.elements-card.default.label': 'Daten',
    'handbooks.column.elements-card.default.placeholder': 'Keine Daten zur Auswahl',
    'handbooks.column.elements-card.name.label': 'Titel an',
    'handbooks.column.elements-card.name.ru.text': 'Russisch',
    'handbooks.column.elements-card.name.en.text': 'Englisch',
    'handbooks.column.elements-card.name.zh.text': 'Chinesisch',
    'handbooks.column.elements-card.name.ar.text': 'Arabisch',
    'handbooks.column.elements-card.name.de.text': 'Deutsche',
    'handbooks.column.elements-card.name.placeholder': 'Geben Sie den Titel ein',
    'handbooks.column.elements-card.organization-form.label': 'Organisationsform',
    'handbooks.column.elements-card.organization-form.placeholder': 'Geben Sie das Formular ein',
    'handbooks.column.elements-card.inn.label': 'TIN der Organisation',
    'handbooks.column.elements-card.inn.placeholder': 'TIN eingeben',
    'handbooks.column.elements-card.binded-handbook.text': 'Verwandte Referenzen',
    'handbooks.column.elements-card.footer.btn.cancel.text': 'Zurücksetzen',
    'handbooks.column.elements-card.footer.btn.save.text': 'Speichern',
    'handbooks.column.elements-card.alert.header.label': 'Kartenschließen ',
    'handbooks.column.elements-card.alert.info.text': 'Das Formular enthält nicht gespeicherte Daten.',
    'handbooks.alert.header.label': 'Verzeichniseintrag löschen',
    'handbooks.alert.warning.text': 'Beachtung',
    'handbooks.alert.info.text': 'Der gelöschte Eintrag kann nicht wiederhergestellt werden.',
    'handbooks.alert.question.text': 'Möchten Sie wirklich fortfahren?',
    'handbooks.alert.footer.btn.no.label': 'Nein',
    'handbooks.alert.footer.btn.yes.label': 'Ja',

    'handbooks.transport-drivers.form.lastName.label': '',
    'handbooks.transport-drivers.form.firstName.label': '',
    'handbooks.transport-drivers.form.secondName.label': '',
    'handbooks.transport-drivers.form.organization.label': '',
    'handbooks.transport-drivers.form.department.label': '',
    'handbooks.transport-drivers.form.position.label': '',

    'handbooks.statistic-modal.header.label': 'Verzeichnisstatistiken',
    'handbooks.statistic-modal.btn.update.label': 'Aktualisieren',
    'handbooks.statistic-modal.btn.save.label': 'Speichern',
    'handbooks.statistic-modal.field.directory.label': 'Nachschlagewerk',
    'handbooks.statistic-modal.field.directory.placeholder': 'Aus der Liste auswählen',
    'handbooks.statistic-modal.field.period-start.label': 'Startdatum der Periode',
    'handbooks.statistic-modal.field.period-start.placeholder': 'Wählen Sie ein Datum',
    'handbooks.statistic-modal.field.period-end.label': 'Enddatum des Zeitraums',
    'handbooks.statistic-modal.field.period-end.placeholder': 'Wählen Sie ein Datum',
    'handbooks.statistic-modal.btn.show.label': 'Zeigen',
    'handbooks.statistic-modal.table.header.date.label': 'Datum',
    'handbooks.statistic-modal.table.header.time.label': 'Zeit',
    'handbooks.statistic-modal.table.header.user.label': 'Benutzer',
    'handbooks.statistic-modal.table.header.username.label': 'Anmeldung',
    'handbooks.statistic-modal.table.header.message.label': 'Botschaft',
    'handbooks.statistic-modal.table.pagination.text': 'Objekte pro Seite',
    'handbooks.statistic-modal.table.row.create.text': 'Element hinzugefügt',
    'handbooks.statistic-modal.table.row.import-1.text': 'Importiert',
    'handbooks.statistic-modal.table.row.import-2.text': 'Elemente',
    'handbooks.statistic-modal.footer.btn.close.label': 'Schließen',
    'handbooks.statistic-modal.footer.btn.save.label': 'Speichern unter',
    'handbooks.add-translation.btn.label': 'Feldübersetzung hinzufügen',
    'handbooks.add-translation.option.text.ru': 'Russisch',
    'handbooks.add-translation.option.text.en': 'Englisch',
    'handbooks.add-translation.option.text.zh': 'Chinesisch',
    'handbooks.add-translation.option.text.ar': 'Arabisch',
    'handbooks.add-translation.option.text.de': 'Deutsche',
  },
};
