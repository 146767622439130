export default {
  en: {
    'toast.personal-statistic.error.parse-no-data.text': 'Data parsing error',
  },
  ru: {
    'toast.personal-statistic.error.parse-no-data.text': 'Ошибка парсинга данных',
  },
  zh: {
    'toast.personal-statistic.error.parse-no-data.text': '数据语法分析',
  },
  ar: {
    'toast.personal-statistic.error.parse-no-data.text': 'خطأ في تحليل البيانات',
  },
  de: {
    'toast.personal-statistic.error.parse-no-data.text': 'Fehler beim Datenparsing',
  },
};
