import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFloors } from './api';
import { FeatureCollection, Geometry } from 'geojson';

interface Floor {
  fio: string;
  data: number[];
}

type MarkerState = {
  floors: FeatureCollection<Geometry, Floor>[];
  error: string | null;
};

const initialState: MarkerState = {
  floors: [],
  error: null,
};

export const fetchFloors = createAsyncThunk('FloorsSlice/fetchFloors', async () => await getFloors());

const floorSlice = createSlice({
  name: 'FloorsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFloors.fulfilled, (state, action) => {
      state.floors = action.payload;
    });
  },
});

// export const { } = floorSlice.actions;

export default floorSlice.reducer;
