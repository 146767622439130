import React from 'react';
import styles from '../../poiPopup.module.scss';

type Props = {
  label: string;
  text: string;
};

export function PoiPopupInfoText({ label, text }: Props) {
  return (
    <div className={styles.info}>
      <div className={styles.infoLabel}>{label}</div>
      <div className={styles.infoText}>{text}</div>
    </div>
  );
}
