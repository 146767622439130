import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as GpsDeviceOff } from 'assets/img/gps_device_off.svg';
import { ReactComponent as GpsDeviceOn } from 'assets/img/gps_device_on.svg';
import { ReactComponent as NetworkStatusTracker } from 'assets/img/network_status_tracker.svg';

import { TRACKERS_LOCATION_PATH } from 'components/trackers/utils/consts';
import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { Spinner } from 'components/common/spinner/spinner';
import EmployeePopup from 'components/map/components/employeePopup/employeePopup';
import TransportPopup from 'components/map/components//transportPopup/transportPopup';
import { getTrackableUnitIcon } from 'components/map/utils';
import { ContactsRow } from '../socialContactsMap/socialContactsMap';

import { RootState } from 'reducers';
import { toggleWatchUnit, unSelectAllUnits } from 'reducers/monitoring';
import { setSelectedTrackersState, unwatchOneTracker, watchOneTracker } from 'reducers/trackers';
import { Unit } from 'reducers/trackableUnits/interface';
import { fetchUnits, removeChosenClusterTrackableUnitsInfo } from 'reducers/trackableUnits';
import { fetchOneEmployee } from 'reducers/employees';
import { fetchOneTransport } from 'reducers/transports';

import { zeroPad } from 'utils/zeroPad';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './trackableUnitsClusterPopup.module.scss';

const cx = classNames.bind(styles);

const CLUSTER_POPUP_WIDTH = 320;
const CLUSTER_POPUP_HEIGHT = 170;

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
  handleShowContacts: () => void;
  handleContactsData: (data: ContactsRow[]) => void;
}

function TrackableUnitsClusterPopup({ onCancel, markerCoords, handleShowContacts, handleContactsData }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();
  const history = useHistory();

  const { selectedTrackers } = useSelector((state: RootState) => state.tracker);
  const { selectedUnits, watchingUnits } = useSelector((state: RootState) => state.monitoring);
  const { trackableUnits, isLoading, chosenClusterTrackableUnitsInfo } = useSelector(
    (state: RootState) => state.trackableUnit
  );

  useEffect(() => {
    return () => {
      dispatch(removeChosenClusterTrackableUnitsInfo());
    };
  }, [dispatch]);

  // если сюда попали из вкладки "Трекеры" и данные по trackableUnits еще не загружены
  const [isTrackableUnitsLoaded, setIsTrackableUnitsLoaded] = useState(!!trackableUnits.length);

  useEffect(() => {
    if (history.location.pathname === TRACKERS_LOCATION_PATH) {
      if (!trackableUnits.length && !isTrackableUnitsLoaded) {
        dispatch(fetchUnits());
        setIsTrackableUnitsLoaded(true);
      } else if (trackableUnits.length && isTrackableUnitsLoaded && selectedTrackers.length) {
        dispatch(unSelectAllUnits());
      }
    }
  }, [history, dispatch, isTrackableUnitsLoaded, trackableUnits, selectedTrackers]);

  // список trackable Unit в попапе кластера
  const trackableUnitsList: Unit[] = useMemo(() => {
    if (trackableUnits.length) {
      const units: Unit[] = [];

      chosenClusterTrackableUnitsInfo.forEach(unitInfo => {
        const employeeId = unitInfo.employeeId;
        const transportId = unitInfo.transportId;
        let foundUnit: Unit | null = null;

        if (employeeId) {
          foundUnit = trackableUnits.find(unit => String(unit.attributes.employeeId) === employeeId) || null;
        }
        if (transportId) {
          foundUnit = trackableUnits.find(unit => String(unit.attributes.transportId) === transportId) || null;
        }

        if (foundUnit) {
          units.push(foundUnit);
        }
      });
      return units;
    }
    return [];
  }, [trackableUnits, chosenClusterTrackableUnitsInfo]);

  const [showItemPopup, setShowItemPopup] = useState<{ isShow: boolean; type: 'employee' | 'transport' | null }>({
    isShow: false,
    type: null,
  });
  const [markerCoordsItemPopup, setMarkerCoordsItemPopup] = useState({
    mouseCoords: { x: 0, y: 0 },
    mapCoords: { x: 0, y: 0 },
  });

  // обработчик кликов по определенному юниту в попапе
  const handleItemClick = (evt: React.MouseEvent<HTMLLIElement, MouseEvent>, unit: Unit) => {
    const isEmployeeUnit = !!unit.attributes.employeeId;
    const unitId = isEmployeeUnit ? unit.attributes.employeeId : unit.attributes.transportId;
    let coords = { x: 0, y: 0 }; // координаты юнита

    if (unitId) {
      if (isEmployeeUnit) {
        const foundEmployee = chosenClusterTrackableUnitsInfo.find(
          unitInfo => unitInfo.employeeId && unitInfo.employeeId === String(unit.attributes.employeeId)
        );

        if (foundEmployee) {
          const contactsData = foundEmployee.markerInfo.contactsData;
          const mapCoords = foundEmployee.markerInfo.mapCoords;

          coords = { x: mapCoords[0], y: mapCoords[1] };
          if (contactsData) {
            handleContactsData(contactsData);
          }
        }
        dispatch(fetchOneEmployee(String(unitId)));
      } else {
        const foundTransport = chosenClusterTrackableUnitsInfo.find(
          unitInfo => unitInfo.transportId && unitInfo.transportId === String(unit.attributes.transportId)
        );

        if (foundTransport) {
          const mapCoords = foundTransport.markerInfo.mapCoords;

          coords = { x: mapCoords[0], y: mapCoords[1] };
        }
        dispatch(fetchOneTransport(unitId));
      }
    }
    setMarkerCoordsItemPopup({
      mouseCoords: { x: evt.clientX, y: evt.clientY },
      mapCoords: coords,
    });
    setShowItemPopup({ isShow: true, type: isEmployeeUnit ? 'employee' : 'transport' });
  };

  // обработчик отслеживания юнита
  const handleWatchUnit = (evt: React.MouseEvent<SVGSVGElement, MouseEvent>, unitId: string) => {
    evt.stopPropagation();
    dispatch(setSelectedTrackersState(selectedTrackers.map(tracker => ({ ...tracker, watching: false }))));

    if (history.location.pathname === TRACKERS_LOCATION_PATH) {
      const watchingTrackers = selectedTrackers.filter(selTracker => selTracker.watching).map(tracker => tracker.id);
      const foundUnit = trackableUnits.find(unit => unit.id === unitId);

      if (foundUnit) {
        const trackerId = foundUnit.attributes.trackerId;

        if (trackerId) {
          if (watchingTrackers.includes(trackerId)) {
            dispatch(unwatchOneTracker(trackerId));
          } else {
            dispatch(watchOneTracker(trackerId));
          }
        }
      }
    } else {
      if (watchingUnits.includes(unitId)) {
        dispatch(toggleWatchUnit({ id: unitId, currentStatus: true }));
      } else {
        dispatch(toggleWatchUnit({ id: unitId, currentStatus: false }));
      }
    }
    onCancel();
  };

  // контент попапа
  const contentPopup = (
    <div className={styles.popupContent}>
      {isLoading ? (
        <div className={styles.popupSpinner}>
          <Spinner />
        </div>
      ) : (
        <ul className={styles.popupList}>
          {trackableUnitsList.length ? (
            trackableUnitsList.map(unit => {
              const unitTypeIcon = unit.attributes.transportId ? 'transportIcon' : 'employeeIcon';
              const iconSrc = getTrackableUnitIcon(unitTypeIcon);
              const [lastName = '', firstName = '', secondName = ''] = unit.attributes.aggregatedName.split(' ');
              let isUnitSelected = selectedUnits.includes(unit.id);
              let isUnitWatching = watchingUnits.includes(unit.id);

              if (history.location.pathname === TRACKERS_LOCATION_PATH) {
                isUnitSelected = !!selectedTrackers.length;
                isUnitWatching = !!selectedTrackers.filter(selTracker => selTracker.watching).length;
              }

              const trackerNumber = unit.attributes.trackerId ? zeroPad(unit.attributes.trackerId, 3) : '---';

              return (
                <li
                  className={styles.popupListItem}
                  key={`notification-log-${unit.id}-${unit.attributes.aggregatedName}`}
                  onClick={evt => handleItemClick(evt, unit)}
                >
                  <div className={styles.popupListItemTrackerNumber}>{trackerNumber}</div>
                  <div className={styles.popupListItemIcon}>
                    <img src={iconSrc} alt="icon" />
                  </div>
                  <div className={styles.popupListItemName}>
                    {unit.attributes.employeeId ? (
                      <Fragment>
                        <div>{lastName}</div>
                        <div>{`${firstName} ${secondName}`}</div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div>{`${lastName} ${firstName}`}</div>
                        <div>{secondName}</div>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.popupListItemGps}>
                    {isUnitSelected && isUnitWatching ? (
                      <GpsDeviceOn
                        className={styles.popupListItemGpsActive}
                        onClick={evt => handleWatchUnit(evt, unit.id)}
                      />
                    ) : (
                      <GpsDeviceOff
                        className={isUnitSelected ? styles.popupListItemGpsOn : styles.popupListItemGpsOff}
                        onClick={isUnitSelected ? evt => handleWatchUnit(evt, unit.id) : undefined}
                        data-tip
                        data-for={`watch_unit_cluster_${unit.id}`}
                      />
                    )}
                    <ReactTooltip
                      className={styles.customTooltip}
                      id={`watch_unit_cluster_${unit.id}`}
                      place="right"
                      type="light"
                      effect="solid"
                    >
                      <span>{t('monitoring.units-list.unit.follow.tooltip.text')}</span>
                    </ReactTooltip>
                  </div>
                  <div className={styles.popupListItemNetwork}>
                    <NetworkStatusTracker className={styles[`popupListItemNetwork${unit.relationships.isActive}`]} />
                  </div>
                </li>
              );
            })
          ) : (
            <li className={cx(styles.popupListItem, styles.popupListItemNoData)} onClick={() => onCancel()}>
              {t('monitoring.cluster.empty-data.text')}
            </li>
          )}
        </ul>
      )}
    </div>
  );

  // попап юнита
  const itemPopup = useMemo(() => {
    if (showItemPopup.isShow && showItemPopup.type) {
      if (showItemPopup.type === 'employee') {
        return (
          <EmployeePopup
            markerCoords={markerCoordsItemPopup}
            onCancel={onCancel}
            handleShowContacts={handleShowContacts}
          />
        );
      }
      return <TransportPopup markerCoords={markerCoordsItemPopup} onCancel={onCancel} />;
    }
    return null;
  }, [showItemPopup, markerCoordsItemPopup, onCancel, handleShowContacts]);

  if (showItemPopup.isShow) {
    return itemPopup;
  }

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      content={contentPopup}
      initialWidth={CLUSTER_POPUP_WIDTH}
      initialHeight={CLUSTER_POPUP_HEIGHT}
      initialPosition={markerCoords.mouseCoords}
      onCancel={onCancel}
      resizeAxis="y"
      customClassNames={{ content: styles.customContentStyles }}
    />,
    appContentContainer
  );
}

export default React.memo(TrackableUnitsClusterPopup);
