import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Search from 'components/common/search/search';

import { RootState } from 'reducers';
import { HandbookName } from 'reducers/handbooks/interface';
import { setElements, setChosenElement, setSearch, setSearchedHandbooks, setChosenHandbook } from 'reducers/handbooks';

import { debounce } from 'lodash';

import styles from './handbooksSearch.module.scss';

const CustomSpan = ({ text }: { text: string }) => <span style={{ fontWeight: 'bold', color: '#0066FF' }}>{text}</span>;

function getFormattedName(searchStrTrimmed: string, nameSearch: string) {
  let searchStrRegExp = /./;

  try {
    searchStrRegExp = new RegExp(searchStrTrimmed, 'gi');
  } catch (e) {
    const withNoSpecialCharactersStr = searchStrTrimmed.replace(/^[.*+?^${}()|[\]\\]+/g, '\\$&'); // экранирование спецсимволов RegExp
    searchStrRegExp = new RegExp(withNoSpecialCharactersStr, 'gi');
  }

  if (searchStrRegExp.test(nameSearch)) {
    const matchedStrs = nameSearch.match(searchStrRegExp);
    const textArray = nameSearch.split(searchStrRegExp);

    const result = textArray
      .map((substr, index) => {
        if (index !== textArray.length - 1 && matchedStrs?.length) {
          return (
            <Fragment key={`splitted-span-${index}`}>
              {substr}
              <CustomSpan text={matchedStrs[index]} />
            </Fragment>
          );
        }
        return <Fragment key={`splitted-${index}`}>{substr}</Fragment>;
      })
      .reduce((pred, curr) => (
        <Fragment>
          {pred}
          {curr}
        </Fragment>
      ));
    return result;
  }
  return null;
}

const HandbooksSearch = () => {
  const dispatch = useDispatch();
  const { handbooksNames, handbooksNamesIsLoading, search } = useSelector((state: RootState) => state.handbooks);

  const [isHideSearchContextMenu, setIsHideSearchContextMenu] = useState(false);
  const [searchedHandbooksLayout, setSearchedHandbooksLayout] = useState<{ id: number; name: JSX.Element | null }[]>(
    []
  );

  const searchStrTrimmed = search.trim();

  useEffect(() => {
    return () => {
      dispatch(setSearch(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (search && !handbooksNamesIsLoading) {
      setIsHideSearchContextMenu(false);
      dispatch(setChosenHandbook(null));
      dispatch(setChosenElement(null));
      dispatch(setElements([]));
      setSearchedHandbooksLayout(
        handbooksNames
          .map(handbookName => ({
            id: handbookName.id,
            name: getFormattedName(searchStrTrimmed, handbookName.name),
          }))
          .filter(result => result.name)
      );
    } else {
      setIsHideSearchContextMenu(true);
      dispatch(setSearchedHandbooks([]));
    }
  }, [dispatch, search, handbooksNamesIsLoading, handbooksNames, searchStrTrimmed]);

  useEffect(() => {
    const filteredHandbooks: HandbookName[] = searchedHandbooksLayout.reduce((pred, curr) => {
      const findedHandbook = handbooksNames.find(handbookName => handbookName.id === curr.id);
      if (findedHandbook) {
        pred.push(findedHandbook);
      }
      return pred;
    }, [] as HandbookName[]);
    if (filteredHandbooks.length) {
      dispatch(setSearchedHandbooks(filteredHandbooks));
    }
  }, [dispatch, handbooksNames, searchedHandbooksLayout]);

  const handleSelectHandbook = (searchedId: number) => {
    const handbook = handbooksNames.find(h => h.id === searchedId);
    if (handbook) {
      setIsHideSearchContextMenu(true);
      dispatch(setChosenHandbook(handbook));
    }
  };

  const handleSearchChange = debounce(function (val: string) {
    dispatch(setSearch(val));
  }, 500);

  return (
    <div className={styles.searchWrapper} onFocus={() => setIsHideSearchContextMenu(false)}>
      <Search handleChange={handleSearchChange} />
      {!isHideSearchContextMenu && searchStrTrimmed && !!handbooksNames.length && !!searchedHandbooksLayout.length && (
        <div className={styles.searchResult}>
          <div className={styles.searchResultWrapper}>
            <ul className={styles.searchResultList}>
              {searchedHandbooksLayout.map((handbook, index) => (
                <li
                  key={`searched-result_${index}`}
                  className={styles.searchResultItem}
                  onClick={() => handleSelectHandbook(handbook.id)}
                >
                  <div className={styles.searchResultName}>{handbook.name}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default HandbooksSearch;
