import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { isEqual } from 'lodash';

import { PositionsByGeozoneType } from 'components/notifications/utils/types';
import { defaultConditions, NOTIFICATION_GEOZONE_ENTITY } from 'components/notifications/utils/consts';
import {
  MultipleSelectGroupOption,
  MultipleSelectGroupTranslate,
} from 'components/common/multipleSelectWithGroupOptions/multipleSelectGroup';

import { RootState } from 'reducers';
import { ConditionsType, AlertConditions } from 'reducers/notifications/interface';

import AccessEntity from 'utils/accessEntity';

const { alert: defaults } = defaultConditions;

type Params = {
  isFoulFixing: boolean;
  setIsFoulFixing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useAlertInfo(params: Params) {
  const t = useFormatMessage();
  const [positionByGeozone, setPositionByGeozone] = useState(defaults.positionByGeozone);

  const [isSosFixing, setIsSosFixing] = useState(defaults.isSosFixing);
  const [isFallFixing, setIsFallFixing] = useState(defaults.isFallFixing);

  const [isAreaLimited, setIsAreaLimited] = useState(defaults.isAreaLimited);
  const [isInactionFixing, setIsInactionFixing] = useState(defaults.isInactionFixing);
  const [isDisconnectionFixing, setIsDisconnectionFixing] = useState(defaults.isDisconnectionFixing);

  const [coordsChangingLimit, setCoordsChangingLimit] = useState(defaults.coordsChangingLimit);
  const [dataGettingLimit, setDataGettingLimit] = useState(defaults.dataGettingLimit);
  const [isRestoreConnectionFixing, setIsRestoreConnectionFixing] = useState(defaults.isRestoreConnectionFixing);

  const [isGnssLost, setIsGnssLost] = useState(defaults.isGnssLost);
  const [gnssWaitingTime, setGnssWaitingTime] = useState(defaults.gnssWaitingTime);
  const [isGnssRestoreFixing, setIsGnssRestoreFixing] = useState(defaults.isGnssRestoreFixing);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const geozonesPermission = useSelector((state: RootState) => state.user.permissions.geozones);
  const geozonesAccess = useMemo(() => new AccessEntity(geozonesPermission), [geozonesPermission]);
  const { geozonesShort, groups: geozoneGroups } = useSelector((state: RootState) => state.geozone);
  const { chosenNotification, chosenNotificationType } = useSelector((state: RootState) => state.notifications);

  const [geozoneEntities, setGeozoneEntities] = useState(defaults.geozones);
  const [geozoneEntitiesTranslatesForSelect] = useState<MultipleSelectGroupTranslate[]>(
    Object.keys(NOTIFICATION_GEOZONE_ENTITY).map(key => ({
      name: key,
      translate: t(
        `notifications.card.tabs.conditions.field.geozone.geozone-list.translate.${NOTIFICATION_GEOZONE_ENTITY[
          key as keyof typeof NOTIFICATION_GEOZONE_ENTITY
        ].toLowerCase()}.text`
      ),
    }))
  );
  const geozoneEntitiesForSelect: MultipleSelectGroupOption[] = useMemo(() => {
    if (!geozonesAccess.isAllowRead()) {
      return [];
    }

    return [
      {
        name: NOTIFICATION_GEOZONE_ENTITY.GeozoneGroup,
        options: geozoneGroups.map(group => ({
          value: group.id,
          label: group.attributes.name,
          data: { ...group },
        })),
      },
      {
        name: NOTIFICATION_GEOZONE_ENTITY.Geozone,
        options: geozonesShort.map(geozone => ({
          value: geozone.id,
          label: geozone.attributes.name,
          data: { ...geozone },
        })),
      },
    ];
  }, [geozonesAccess, geozonesShort, geozoneGroups]);

  const coordsChangingLimitRef = React.createRef();
  const dataGettingLimitRef = React.createRef();
  const gpsWaitingTimeRef = React.createRef();

  useEffect(() => {
    let conditions: ConditionsType = null;

    if (chosenNotification && chosenNotificationType === chosenNotification.attributes.notificationType) {
      conditions = chosenNotification.attributes.conditions as AlertConditions;
    } else {
      conditions = defaults;
    }
    setIsSosFixing(conditions.isSosFixing);
    setIsFallFixing(conditions.isFallFixing);
    setIsAreaLimited(conditions.isAreaLimited);
    setIsInactionFixing(conditions.isInactionFixing);
    setIsDisconnectionFixing(conditions.isDisconnectionFixing);
    setCoordsChangingLimit(conditions.coordsChangingLimit);
    setDataGettingLimit(conditions.dataGettingLimit);
    setPositionByGeozone(conditions.positionByGeozone);
    setIsRestoreConnectionFixing(conditions.isRestoreConnectionFixing);
    setGeozoneEntities(conditions.geozones);
    setIsGnssLost(conditions.isGnssLost);
    setGnssWaitingTime(conditions.gnssWaitingTime);
    setIsGnssRestoreFixing(conditions.isGnssRestoreFixing);
  }, [chosenNotification, chosenNotificationType]);

  useEffect(() => {
    if (!isDisconnectionFixing) {
      setIsRestoreConnectionFixing(false);
    }
  }, [isDisconnectionFixing]);

  useEffect(() => {
    if (!isGnssLost) {
      setIsGnssRestoreFixing(false);
    }
  }, [isGnssLost]);

  const handleCheckedSosFixing = () => setIsSosFixing(!isSosFixing);
  const handleCheckedFallFixing = () => setIsFallFixing(!isFallFixing);
  const handleCheckedAreaLimited = () => setIsAreaLimited(!isAreaLimited);
  const handleCheckedFoulFixing = () => params.setIsFoulFixing(!params.isFoulFixing);
  const handleCheckedInactionFixing = () => setIsInactionFixing(!isInactionFixing);
  const handleCheckedDisconnectionFixing = () => setIsDisconnectionFixing(!isDisconnectionFixing);
  const handleCheckedRestoreConnectionFixing = () => setIsRestoreConnectionFixing(!isRestoreConnectionFixing);
  const handleCheckedGpsLost = () => setIsGnssLost(!isGnssLost);
  const handleCheckedGpsRestore = () => setIsGnssRestoreFixing(!isGnssRestoreFixing);

  const handleCheckRadio = (type: PositionsByGeozoneType) => setPositionByGeozone(type);

  const handleChangeGeozones = (values: MultipleSelectGroupOption[]) => {
    const newValues = values.map(groupValue => ({
      type: groupValue.name as NOTIFICATION_GEOZONE_ENTITY,
      entityIds: Array.from(new Set(groupValue.options.map(option => option.data?.id as string).filter(id => id))),
    }));

    setGeozoneEntities(newValues);
  };

  const handleChangeInactionFixingValue = (date: Date) => setCoordsChangingLimit(date.getMinutes() * 60);
  const handleChangeDataGettingLimitValue = (date: Date) => setDataGettingLimit(date.getMinutes() * 60);
  const handleChangeGpsWaitingTimeValue = (date: Date) => setGnssWaitingTime(date.getMinutes() * 60);

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (isAreaLimited && !geozoneEntities.length) {
      newInvalidFields.push('geozoneEntitiesSos');
    }
    if (isInactionFixing && coordsChangingLimit < defaults.coordsChangingLimit) {
      newInvalidFields.push('coordsChangingLimitSos');
    }
    if (isDisconnectionFixing && dataGettingLimit < defaults.dataGettingLimit) {
      newInvalidFields.push('dataGettingLimitSos');
    }
    if (isGnssLost && gnssWaitingTime < defaults.gnssWaitingTime) {
      newInvalidFields.push('gpsWaitingTimeSos');
    }
    return newInvalidFields;
  };

  const hasChanges = () => {
    const currentSosConditions =
      (chosenNotification?.attributes.conditions as AlertConditions) || defaultConditions.alert;

    if (
      isSosFixing !== currentSosConditions.isSosFixing ||
      isFallFixing !== currentSosConditions.isFallFixing ||
      isAreaLimited !== currentSosConditions.isAreaLimited ||
      isInactionFixing !== currentSosConditions.isInactionFixing ||
      isDisconnectionFixing !== currentSosConditions.isDisconnectionFixing ||
      coordsChangingLimit !== currentSosConditions.coordsChangingLimit ||
      dataGettingLimit !== currentSosConditions.dataGettingLimit ||
      positionByGeozone !== currentSosConditions.positionByGeozone ||
      isRestoreConnectionFixing !== currentSosConditions.isRestoreConnectionFixing ||
      isGnssLost !== currentSosConditions.isGnssLost ||
      gnssWaitingTime !== currentSosConditions.gnssWaitingTime ||
      isGnssRestoreFixing !== currentSosConditions.isGnssRestoreFixing ||
      !isEqual(geozoneEntities, currentSosConditions.geozones)
    ) {
      return true;
    }
    return false;
  };

  return {
    states: {
      isSosFixing,
      isFallFixing,
      isAreaLimited,
      isInactionFixing,
      isDisconnectionFixing,
      isRestoreConnectionFixing,
      coordsChangingLimit,
      dataGettingLimit,
      positionByGeozone,
      geozoneEntities,
      geozoneEntitiesForSelect,
      geozoneEntitiesTranslatesForSelect,
      isGnssLost,
      gnssWaitingTime,
      isGnssRestoreFixing,
    },
    handlers: {
      handleCheckedSosFixing,
      handleCheckedFallFixing,
      handleCheckedAreaLimited,
      handleCheckedFoulFixing,
      handleCheckedInactionFixing,
      handleCheckedDisconnectionFixing,
      handleCheckedRestoreConnectionFixing,
      handleCheckRadio,
      handleChangeGeozones,
      handleChangeInactionFixingValue,
      handleChangeDataGettingLimitValue,
      handleCheckedGpsLost,
      handleChangeGpsWaitingTimeValue,
      handleCheckedGpsRestore,
    },
    refs: {
      coordsChangingLimitRef,
      dataGettingLimitRef,
      gpsWaitingTimeRef,
    },
    hasChanges,
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
  };
}
