import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { TransformedUnitsData } from './types';
import { Unit } from 'reducers/trackableUnits/interface';
import { Structure } from 'components/monitoring/utils/types';
import { Sorting } from 'utils/consts';

// сортировка по Units
export function useSortingUnits(units: Unit[]) {
  const type = useSelector((state: RootState) => state.monitoring.sort.type);
  const ascending = useSelector((state: RootState) => state.monitoring.sort.ascending);

  const sortingUnits = useCallback(
    (a: Unit, b: Unit) => {
      const aAttributes = a.attributes;
      const bAttributes = b.attributes;
      const aRelationShips = a.relationships;
      const bRelationShips = b.relationships;

      switch (type) {
        case Sorting.network:
          if (aRelationShips.isActive === bRelationShips.isActive) {
            break;
          }
          if (ascending) {
            return aRelationShips.isActive ? 1 : -1;
          }
          return aRelationShips.isActive ? -1 : 1;

        case Sorting.gps:
          if (aRelationShips.signalLevel === bRelationShips.signalLevel) {
            break;
          }
          if (ascending) {
            return aRelationShips.signalLevel < bRelationShips.signalLevel ? -1 : 1;
          }
          return aRelationShips.signalLevel < bRelationShips.signalLevel ? 1 : -1;

        case Sorting.battery:
          if (aRelationShips.batteryCharge === bRelationShips.batteryCharge) {
            break;
          }
          if (ascending) {
            return aRelationShips.batteryCharge < bRelationShips.batteryCharge ? -1 : 1;
          }
          return aRelationShips.batteryCharge < bRelationShips.batteryCharge ? 1 : -1;

        case Sorting.trackableNumber:
          if (aAttributes.trackerId === bAttributes.trackerId) {
            return 0;
          }

          if (!aAttributes.trackerId) {
            return ascending ? -1 : 1;
          }

          if (!bAttributes.trackerId) {
            return ascending ? 1 : -1;
          }

          if (ascending) {
            return aAttributes.trackerId < bAttributes.trackerId ? 1 : -1;
          }
          return aAttributes.trackerId < bAttributes.trackerId ? -1 : 1;

        default:
          break;
      }

      if (type !== Sorting.name) {
        if (aAttributes.aggregatedName === bAttributes.aggregatedName) {
          return 0;
        }
        return aAttributes.aggregatedName < bAttributes.aggregatedName ? -1 : 1;
      }

      if (aAttributes.aggregatedName === bAttributes.aggregatedName) {
        return 0;
      }
      if (ascending) {
        return aAttributes.aggregatedName < bAttributes.aggregatedName ? -1 : 1;
      } else {
        return aAttributes.aggregatedName < bAttributes.aggregatedName ? 1 : -1;
      }
    },
    [type, ascending]
  );

  const sortedUnits = useMemo(() => [...units].sort(sortingUnits), [units, sortingUnits]);

  return sortedUnits;
}

// сортировка по Substructures и TransformedUnitsData
export function useSortingSubstructures(structure: TransformedUnitsData) {
  const type = useSelector((state: RootState) => state.monitoring.sort.type);
  const ascending = useSelector((state: RootState) => state.monitoring.sort.ascending);

  const sortingSubstructures = useCallback(
    (a: keyof Structure, b: keyof Structure) => {
      switch (type) {
        case Sorting.name:
          if (ascending) {
            return structure[a].name < structure[b].name ? -1 : 1;
          }
          return structure[a].name < structure[b].name ? 1 : -1;

        default:
          break;
      }

      return 0;
    },
    [type, ascending, structure]
  );

  const sortedSubstructures = useMemo(() => {
    const dataKeys = Object.keys(structure);

    if (!dataKeys.length) {
      return [];
    }

    return dataKeys.sort((a, b) => sortingSubstructures(a as keyof Structure, b as keyof Structure));
  }, [sortingSubstructures, structure]);

  return sortedSubstructures;
}
