import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import useTreeGroupConfig from 'components/common/treeGroupConfigView/hooks/useTreeGroupConfig';
import useVisibleColumnsConfig from 'components/common/visibleColumnsConfigView/hooks/useVisibleColumnsConfig';
import ModalTemplate from 'components/common/modal/modalTemplate/modalTemplate';
import TreeGroupConfigView from 'components/common/treeGroupConfigView/treeGroupConfigView';
import VisibleColumnsConfigView from 'components/common/visibleColumnsConfigView/visibleColumnsConfigView';
import HorizontalTabs from 'components/common/tabs/hosrizontalTabs/horizontalTabs';
import { TabData } from 'components/common/tabs/types/tabsTypes';
import { UsersListViewData } from '../../usersList';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal';
import { updateClientUserPreferences } from 'reducers/clientUserPreferences';

import styles from './usersConfigModal.module.scss';

enum UsersConfigTab {
  Grouping = 'grouping',
  Columns = 'columns',
}

const messages = {
  title: 'accounts.users-config-modal.title.text',
  okLabel: 'accounts.users-config-modal.ok-button.text',
  cancelLabel: 'accounts.users-config-modal.cancel-button.text',
  tabLabel: (tab: UsersConfigTab) => `accounts.users-config-modal.tab-${tab}.text`,
  groupByLabel: (groupBy: string) => `accounts.users-config-modal.group-by-${groupBy}.text`,
  columnLabel: (column: string) => `accounts.users-list-columns.column-${column}.text`,
};

const UsersConfigModal: React.FC = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<UsersConfigTab>(UsersConfigTab.Grouping);

  const initialGroupingConfig = useSelector((state: RootState) => state.clientUserPreferences.usersListGroupingConfig);

  const initialVisibleColumnsConfig = useSelector(
    (state: RootState) => state.clientUserPreferences.usersListVisibleColumnsConfig
  );

  const groupingConfigLabels = useMemo(
    () => initialGroupingConfig.map(item => t(messages.groupByLabel(item.groupBy))),
    [initialGroupingConfig]
  );

  const visibleColumnsConfigLabels = useMemo(
    () => initialVisibleColumnsConfig.map(item => t(messages.columnLabel(item.column))),
    [initialVisibleColumnsConfig]
  );

  const [
    groupingConfig,
    groupingConfigToSave,
    handleItemCheck,
    handleLeftClick,
    handleRightClick,
    handleGroupingSortEnd,
  ] = useTreeGroupConfig<UsersListViewData>(initialGroupingConfig, groupingConfigLabels);

  const [visibleColumnsConfig, visibleColumnsConfigToSave, handleIsVisibleClick, handleVisibleColumnsSortEnd] =
    useVisibleColumnsConfig<UsersListViewData>(initialVisibleColumnsConfig, visibleColumnsConfigLabels);

  const tabs: TabData<UsersConfigTab>[] = [
    { value: UsersConfigTab.Grouping, label: t(messages.tabLabel(UsersConfigTab.Grouping)) },
    { value: UsersConfigTab.Columns, label: t(messages.tabLabel(UsersConfigTab.Columns)) },
  ];

  const handleOkClick = () => {
    dispatch(
      updateClientUserPreferences({
        usersListGroupingConfig: groupingConfigToSave,
        usersListVisibleColumnsConfig: visibleColumnsConfigToSave,
      })
    );

    dispatch(closeModal());
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalTemplate
      title={t(messages.title)}
      okLabel={t(messages.okLabel)}
      cancelLabel={t(messages.cancelLabel)}
      handleOkClick={handleOkClick}
      handleClose={handleClose}
    >
      <HorizontalTabs<UsersConfigTab> tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className={styles.modalContent}>
        {activeTab === UsersConfigTab.Grouping && (
          <TreeGroupConfigView<UsersListViewData>
            config={groupingConfig}
            handleItemCheck={handleItemCheck}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            handleSortEnd={handleGroupingSortEnd}
          />
        )}

        {activeTab === UsersConfigTab.Columns && (
          <VisibleColumnsConfigView<UsersListViewData>
            config={visibleColumnsConfig}
            handleIsVisibleClick={handleIsVisibleClick}
            handleSortEnd={handleVisibleColumnsSortEnd}
          />
        )}
      </div>
    </ModalTemplate>
  );
};

export default UsersConfigModal;
