import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { fetchDangerZones } from 'reducers/addons';
import { fetchFloors } from 'reducers/floors';

export function useFetchedData() {
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state: RootState) => state.auth);
  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchDangerZones(locale));
    }
  }, [dispatch, isAuth, locale]);

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchFloors());
    }
  }, [dispatch, isAuth]);
}
