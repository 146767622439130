import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Tracker } from 'reducers/trackers/interface';
import { Sorting } from 'utils/consts';

// сортировка по Trackers
export function useSortingTrackers(trackers: Tracker[]) {
  const { type, ascending } = useSelector((state: RootState) => state.tracker.sort);
  const { trackableNumber, name, network, gps, battery } = Sorting;

  function sorting(a: Tracker, b: Tracker) {
    const aAttributes = a.attributes;
    const bAttributes = b.attributes;

    switch (type) {
      case network:
        if (aAttributes.isActive === bAttributes.isActive) {
          break;
        }
        if (ascending) {
          return aAttributes.isActive ? 1 : -1;
        }
        return aAttributes.isActive ? -1 : 1;

      case gps:
        if (aAttributes.signalLevel === bAttributes.signalLevel) {
          break;
        }
        if (ascending) {
          return aAttributes.signalLevel < bAttributes.signalLevel ? -1 : 1;
        }
        return aAttributes.signalLevel < bAttributes.signalLevel ? 1 : -1;

      case battery:
        if (aAttributes.batteryCharge === bAttributes.batteryCharge) {
          break;
        }
        if (ascending) {
          return aAttributes.batteryCharge < bAttributes.batteryCharge ? -1 : 1;
        }
        return aAttributes.batteryCharge < bAttributes.batteryCharge ? 1 : -1;

      case trackableNumber:
        const aNumber = Number(aAttributes.trackerNumber);
        const bNumber = Number(bAttributes.trackerNumber);

        if (aNumber === bNumber) {
          return 0;
        }
        if (ascending) {
          return aNumber < bNumber ? -1 : 1;
        }
        return aNumber < bNumber ? 1 : -1;

      default:
        break;
    }

    if (type !== name) {
      if (aAttributes.simNumber === bAttributes.simNumber) {
        return 0;
      }
      return aAttributes.simNumber < bAttributes.simNumber ? -1 : 1;
    }
    if (aAttributes.simNumber === bAttributes.simNumber) {
      return 0;
    }
    if (ascending) {
      return aAttributes.simNumber < bAttributes.simNumber ? -1 : 1;
    } else {
      return aAttributes.simNumber < bAttributes.simNumber ? 1 : -1;
    }
  }

  if (!trackers.length) {
    return [];
  }

  return [...trackers].sort(sorting);
}
