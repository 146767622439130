import React, { Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Resizable, ResizeCallback } from 're-resizable';
import classNames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';

import NotificationHistoryTable from 'components/notifications/components/notificationHistory/table/table';
import MessagesTable from 'components/messages/components/messagesTable/messagesTable';
import ReportInfo from 'components/records/components/recordsHistory/components/reportInfo/reportInfo';

import { RootState } from 'reducers';
import { setContentSize } from 'reducers/common';
import { toggleShowContent, setShowMap, setSizeCounter } from 'reducers/map';
import { setSelectedTrackersState } from 'reducers/trackers';
import { selectBatchOfGeoGroups, selectBatchOfGeozones } from 'reducers/geozones';
import { setUsersMonitoringState } from 'reducers/monitoring';
import { setUsersNotificationsState } from 'reducers/notifications';

import { Routes } from './routes';

import { getFromStorage, saveToStorage } from 'utils/workWithStorages';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './content.module.scss';

const cx = classNames.bind(styles);

const noMapPathNames = ['/main/accounts', '/main/handbooks'];

export const ROUTES_WRAPPER_ID = 'routesWrapper';

export const Content: React.FC = props => {
  const showContentData = useSelector((state: RootState) => state.map.showContentData);
  const showMap = useSelector((state: RootState) => state.map.showMap);

  const selectedTrackers = useSelector((state: RootState) => state.tracker.selectedTrackers);
  const selectedGeozones = useSelector((state: RootState) => state.geozone.selectedGeozones);
  const selectedGroups = useSelector((state: RootState) => state.geozone.selectedGroups);
  const monitoringStore = useSelector((state: RootState) => state.monitoring);
  const {
    isTableHistoryLogsShow,
    groups: notificationGroups,
    selectedNotifications,
  } = useSelector((state: RootState) => state.notifications);
  const { isTableMessagesShow } = useSelector((state: RootState) => state.messages);
  const { isReportInfoShow } = useSelector((state: RootState) => state.records);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (noMapPathNames.includes(pathname)) {
      dispatch(setShowMap(false));
    } else {
      dispatch(setShowMap(true));
    }
  }, [dispatch, pathname]);

  const toggleContentData = () => {
    dispatch(toggleShowContent());
  };

  useEffect(() => {
    const monitoring = getFromStorage('monitoring');
    if (monitoring) {
      dispatch(setUsersMonitoringState(monitoring));
    }

    const trackers = getFromStorage('trackers');
    if (trackers) {
      dispatch(setSelectedTrackersState(trackers.selectedTrackers));
    }

    const geozones = getFromStorage('geozones');
    if (geozones) {
      dispatch(selectBatchOfGeozones(geozones.selectedGeozones));
      dispatch(selectBatchOfGeoGroups(geozones.selectedGroups));
    }

    const notifications = getFromStorage('notifications');
    if (notifications) {
      dispatch(setUsersNotificationsState(notifications));
    }
  }, [dispatch]);

  useEffect(() => {
    saveToStorage('monitoring', {
      openedStructures: monitoringStore.openedStructures,
      selectedUnits: monitoringStore.selectedUnits,
    });
  }, [monitoringStore]);

  useEffect(() => {
    saveToStorage('notifications', {
      groups: notificationGroups,
      selectedNotifications: selectedNotifications,
    });
  }, [notificationGroups, selectedNotifications]);

  useEffect(() => {
    saveToStorage('trackers', { selectedTrackers });
  }, [selectedTrackers]);

  useEffect(() => {
    saveToStorage('geozones', { selectedGeozones, selectedGroups });
  }, [selectedGeozones, selectedGroups]);

  const contentClasses = cx(styles.contentData, {
    [styles.contentDataOpen]: showContentData,
    [styles.contentDataHide]: !showContentData,
    [styles.contentDataFull]: !showMap,
  });

  const resizableRef = useCallback(
    (element: Resizable | null) => {
      if (element && element.resizable) {
        const elementRect = element.resizable.getBoundingClientRect();

        dispatch(
          setContentSize({
            width: elementRect.width,
          })
        );
      }
    },
    [dispatch]
  );

  const handleResizeStop: ResizeCallback = (e, direction, elementRef) => {
    const elementRect = elementRef.getBoundingClientRect();

    dispatch(setSizeCounter());
    dispatch(
      setContentSize({
        width: elementRect.width,
      })
    );
  };

  const isTablesShow = isTableHistoryLogsShow || isTableMessagesShow;

  return (
    <div id={APP_CONTENT_ID} className={styles.body}>
      <Resizable
        className={contentClasses}
        enable={{
          right: showMap,
        }}
        handleStyles={{
          right: {
            right: -6,
          },
        }}
        minWidth={400}
        maxWidth={'100%'}
        ref={resizableRef}
        onResizeStop={handleResizeStop}
      >
        <div className={styles.routesWrapper} id={ROUTES_WRAPPER_ID}>
          <Routes />
        </div>
      </Resizable>

      {showMap && (
        <div className={showContentData ? styles.contentMap : styles.contentMapFull}>
          {isTableHistoryLogsShow && <NotificationHistoryTable />}
          {isTableMessagesShow && <MessagesTable />}
          {isReportInfoShow && <ReportInfo />}
          {!isTablesShow && (
            <Fragment>
              <div className={styles.toggleButton} onClick={toggleContentData}>
                <ArrowIcon className={showContentData ? styles.toggleButtonIconHide : styles.toggleButtonIconShow} />
              </div>
              {props.children}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default Content;
