import React, { Dispatch } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { ReactComponent as InfoIcon } from 'assets/img/main-information.svg';
import { ReactComponent as SensorIcon } from 'assets/img/sensors.svg';
import { ReactComponent as StatIcon } from 'assets/img/object-stat.svg';
import styles from './tabsNav1.module.scss';
import { ActiveTab } from '../../utils/consts';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface TabsProps {
  showStat: boolean;
  activeTab: ActiveTab;
  setActiveTab: Dispatch<ActiveTab>;
}

export const TrackersModalTabsNav = ({ showStat, activeTab, setActiveTab }: TabsProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalTabs}>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.info,
        })}
        onClick={() => setActiveTab(ActiveTab.info)}
      >
        <InfoIcon
          className={cx({
            [styles.modalTabIconActive]: activeTab === ActiveTab.info,
            [styles.modalTabIcon]: activeTab !== ActiveTab.info,
          })}
        />
        <span className={styles.modalTabLabel}>{t('trackers.card.tab.main.label')}</span>
      </div>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.sensors,
        })}
        onClick={() => setActiveTab(ActiveTab.sensors)}
      >
        <SensorIcon
          className={cx({
            [styles.modalTabIconActive]: activeTab === ActiveTab.sensors,
            [styles.modalTabIcon]: activeTab !== ActiveTab.sensors,
          })}
        />
        <span className={styles.modalTabLabel}>{t('trackers.card.tab.sensors.label')}</span>
      </div>
      {showStat && (
        <div
          className={cx(styles.modalTab, {
            [styles.modalTabActive]: activeTab === ActiveTab.stat,
          })}
          onClick={() => setActiveTab(ActiveTab.stat)}
        >
          <StatIcon
            className={cx({
              [styles.modalTabIconActive]: activeTab === ActiveTab.stat,
              [styles.modalTabIcon]: activeTab !== ActiveTab.stat,
            })}
          />
          <span className={styles.modalTabLabel}>{t('trackers.card.tab.statistic.label')}</span>
        </div>
      )}
    </div>
  );
};
