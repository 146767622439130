import React, { ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as ClosedEye } from 'assets/img/closed-eye.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';
import { ReactComponent as TwoLines } from 'assets/img/two-lines.svg';
import styles from './tabsShowingTab.module.scss';

const DragHandler = SortableHandle(() => <TwoLines className={styles.tabItemDragIcon} />);

interface SortableItemProps {
  item: {
    keyName: string;
    title: string;
    isVisible: boolean;
  };
  toggleVisible: () => void;
}

const SortableItem = SortableElement(({ item, toggleVisible }: SortableItemProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.tabItem}>
      <DragHandler />
      <div className={styles.tabItemName}>{t(item.title)}</div>
      {item.isVisible ? (
        <OpenedEye onClick={toggleVisible} className={styles.tabItemEyeIcon} />
      ) : (
        <ClosedEye className={styles.tabItemEyeIcon} onClick={toggleVisible} />
      )}
    </div>
  );
});

type SortableListProps = {
  children: ReactNode;
};

const SortableList = SortableContainer(({ children }: SortableListProps) => (
  <div className={styles.sortableListContainer}>{children}</div>
));

type TabProps = {
  localTabPrefs: {
    title: string;
    keyName: string;
    isVisible: boolean;
  }[];
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  toggleVisible: (ind: number) => void;
};

export function TabsShowingTab({ localTabPrefs, onSortEnd, toggleVisible }: TabProps) {
  return (
    <div className={styles.container}>
      <SortableList onSortEnd={onSortEnd} useDragHandle>
        {localTabPrefs.map((it, ind) => (
          <SortableItem
            key={`tab-config-sort-${ind}`}
            item={it}
            toggleVisible={() => {
              toggleVisible(ind);
            }}
            index={ind}
          />
        ))}
      </SortableList>
    </div>
  );
}
