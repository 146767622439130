import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { Checkbox } from 'components/common/checkbox/checkbox';
import Switch from 'components/common/switch/switch';
import { ExpandButton } from 'components/common/expandButton/expandButton';

import { RootState } from 'reducers';
import {
  turnNotificationInList,
  fetchChosenNotification,
  setTurnedNotifications,
  setSelectedNotifications,
  changeNotificationGroup,
} from 'reducers/notifications';
import { showModal } from 'reducers/modal';
import { NotificationsGroup } from 'reducers/notifications/interface';

import { NOTIFICATIONS } from 'utils/consts';
import AccessEntity from 'utils/accessEntity';

import { NotificationItem } from '../notificationItem/notificationItem';

import styles from './notificationGroup.module.scss';

const cx = classNames.bind(styles);

type NotificationGroupProps = {
  group: NotificationsGroup;
  disabledTurn: boolean;
};

export const NotificationGroup = ({ group, disabledTurn }: NotificationGroupProps) => {
  const dispatch = useDispatch();
  const { notifications, selectedNotifications } = useSelector((state: RootState) => state.notifications);
  const notificationsPermissions = useSelector((state: RootState) => state.user.permissions.notifications);
  const notificationsAccess = useMemo(() => new AccessEntity(notificationsPermissions), [notificationsPermissions]);

  const handleExpandGroup = () => {
    dispatch(
      changeNotificationGroup({
        type: group.notificationType,
        state: 'expanded',
      })
    );
  };

  const handleTurnGroup = () => {
    const newIsActive = group.isIndeterminate || !group.isActive;

    dispatch(
      setTurnedNotifications({
        ids: group.notificationsIds,
        status: newIsActive,
      })
    );
  };

  const handleCheckGroup = () => {
    dispatch(
      changeNotificationGroup({
        type: group.notificationType,
        state: 'checked',
      })
    );
    dispatch(
      setSelectedNotifications({
        ids: group.notificationsIds,
        status: !group.checked,
      })
    );
  };

  const handleTurnNotification = (id: string) => {
    if (notificationsAccess.isAllowUpdate() && !disabledTurn) {
      dispatch(turnNotificationInList(id));
    }
  };

  const handleChangeNotification = (id: string) => {
    if (notificationsAccess.isAllowUpdate()) {
      dispatch(fetchChosenNotification(id));
      dispatch(showModal({ type: NOTIFICATIONS, which: '' }));
    }
  };

  const groupNotifications = notifications.filter(n => group.notificationsIds.includes(n.id));

  const getCheckedGroup = () => {
    const isChecked = [];

    group.notificationsIds.forEach(nId => {
      if (selectedNotifications.includes(nId)) {
        isChecked.push(nId);
      }
    });
    return isChecked.length > 0;
  };

  const getIsIndeterminateCheckedGroup = () => {
    const isChecked = [];

    group.notificationsIds.forEach(nId => {
      if (selectedNotifications.includes(nId)) {
        isChecked.push(nId);
      }
    });
    return isChecked.length !== group.notificationsIds.length && isChecked.length > 0;
  };

  const groupNotified = group?.numberOfNotified || 0;
  const groupNumberOfUnits = group?.numberOfUnits || 0;

  return (
    <Fragment>
      <div className={styles.notificationGroup}>
        <div className={styles.notificationGroupInfo}>
          <ExpandButton isOpen={group.expanded} onClick={handleExpandGroup} style={styles.notificationGroupExpand} />
          <div className={styles.notificationGroupCheckbox}>
            <Checkbox
              checked={getCheckedGroup()}
              handleCheck={handleCheckGroup}
              isIndeterminate={getIsIndeterminateCheckedGroup()}
            />
          </div>
          <div className={styles.notificationGroupDescription}>
            <span className={styles.notificationGroupName}>{group.name} </span>
            <span>{`(${group.notificationsIds.length})`}</span>
          </div>
        </div>
        <div className={cx(styles.notificationButtons, styles.notificationButtonsItem)}>
          <div className={styles.notificationButtonsIcon}>
            <Switch
              checked={group.isActive}
              isIndeterminate={group.isIndeterminate}
              onChange={handleTurnGroup}
              disabled={!notificationsAccess.isAllowUpdate()}
            />
          </div>
          <div className={styles.notificationButtonsIcon} title={groupNotified > 999 ? String(groupNotified) : ''}>
            {groupNotified > 999 ? '999+' : groupNotified}
          </div>
          <div
            className={styles.notificationButtonsIcon}
            title={groupNumberOfUnits > 999 ? String(groupNumberOfUnits) : ''}
          >
            {groupNumberOfUnits > 999 ? '999+' : groupNumberOfUnits}
          </div>
          <div className={styles.notificationButtonsIcon}></div>
        </div>
      </div>
      {group.expanded && (
        <div className={styles.notificationGroupChildren}>
          {groupNotifications.map((notification, i) => (
            <NotificationItem
              key={`notification-group-item-${i}`}
              text={notification.attributes.name}
              isActive={notification.attributes.isActive}
              checked={selectedNotifications.includes(notification.id)}
              numberOfNotified={notification.meta.numberOfNotified}
              numberOfUnits={notification.meta.numberOfUnits}
              disabledTurn={disabledTurn}
              handleTurn={() => handleTurnNotification(notification.id)}
              handleChange={() => handleChangeNotification(notification.id)}
              notificationId={notification.id}
              paddingLeft={35}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};
