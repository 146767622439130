import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Map } from 'ol';
import { Vector as VectorSource } from 'ol/source';

import { FeatureCollection } from '@turf/helpers';

import { RootState } from 'reducers';

import { MAP_LAYERS_Z_INDEX } from 'utils/consts';
import { createVectorLayer, geoJSON } from '../utils';

const dangerZoneVectorSource: VectorSource = new VectorSource();

export function useHeatMapObjects(map: Map) {
  const { withHeatMap, dangerZonesData } = useSelector((state: RootState) => state.addons);

  useEffect(() => {
    if (withHeatMap) {
      const vectorLayerDangerZones = createVectorLayer(
        dangerZoneVectorSource,
        MAP_LAYERS_Z_INDEX.HEAT_MAP_OBJECTS,
        'dangerZone'
      );
      map.addLayer(vectorLayerDangerZones);

      return () => {
        map.removeLayer(vectorLayerDangerZones);
        vectorLayerDangerZones.dispose();
      };
    }
  }, [map, withHeatMap]);

  useEffect(() => {
    if (withHeatMap) {
      const dangerZonesFeatures = (dangerZonesData as FeatureCollection).features;
      if (dangerZonesFeatures) {
        dangerZoneVectorSource.addFeatures(geoJSON.readFeatures(dangerZonesData));
      }
      return () => {
        dangerZoneVectorSource.clear();
      };
    }
  }, [dangerZonesData, withHeatMap]);
}
