import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import Switch from 'components/common/switch/switch';
import styles from './personalDataTab.module.scss';
import { PersonalDataHandlers, PersonalDataValues, SelectOptions } from '../../utils/types';

type Props = {
  values: PersonalDataValues;
  handlers: PersonalDataHandlers;
  organizationOptions: SelectOptions;
  departmentOptions: SelectOptions;
  positionOptions: SelectOptions;
  invalidFields: string[];
};

export const PersonalDataTab = ({
  values,
  handlers,
  organizationOptions,
  departmentOptions,
  positionOptions,
  invalidFields,
}: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalCol}>
        <Input
          label={t('user-settings.modal.personal.field.second-name.label')}
          placeholder={t('user-settings.modal.personal.field.second-name.placeholder')}
          value={values.lastName}
          isValueError={invalidFields.includes('lastName')}
          isRequired={true}
          handleInputChange={handlers.setLastName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('user-settings.modal.personal.field.first-name.label')}
          placeholder={t('user-settings.modal.personal.field.first-name.placeholder')}
          value={values.firstName}
          isValueError={invalidFields.includes('firstName')}
          isRequired={true}
          handleInputChange={handlers.setFirstName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('user-settings.modal.personal.field.last-name.label')}
          placeholder={t('user-settings.modal.personal.field.last-name.placeholder')}
          value={values.secondName}
          handleInputChange={handlers.setSecondName}
          customStyle={styles.inputWrap}
        />
        <Input
          label={t('user-settings.modal.personal.field.telephone.label')}
          placeholder={t('user-settings.modal.personal.field.telephone.placeholder')}
          value={values.simNumber}
          handleInputChange={handlers.setSimNumber}
          customStyle={styles.inputWrapBottom}
        />
        <Switch
          checked={values.smsNotified}
          title={t('user-settings.modal.personal.field.sms.text')}
          containerStyle={styles.switchContainer}
          onChange={handlers.handleSmsNotifyChange}
        />
        <Input
          label={t('user-settings.modal.personal.field.login.label')}
          placeholder={t('user-settings.modal.personal.field.login.placeholder')}
          value={values.login}
          isValueError={invalidFields.includes('login')}
          isRequired={true}
          handleInputChange={handlers.setLogin}
          customStyle={styles.inputWrapBottom}
        />
      </div>
      <div className={styles.modalCol}>
        <CustomSelect
          label={t('user-settings.modal.personal.field.company.label')}
          placeholder={t('user-settings.modal.personal.field.company.placeholder')}
          value={values.organizationId}
          isValueError={invalidFields.includes('organizationId')}
          isRequired={true}
          options={organizationOptions}
          style={styles.inputWrap}
          handleChange={handlers.handleOrgChange}
        />
        <CustomSelect
          label={t('user-settings.modal.personal.field.subdivision.label')}
          placeholder={t('user-settings.modal.personal.field.subdivision.placeholder')}
          disabled={!values.organizationId}
          value={values.departmentId}
          isValueError={invalidFields.includes('departmentId')}
          isRequired={true}
          options={departmentOptions}
          style={styles.inputWrap}
          handleChange={handlers.handleDepartmentChange}
        />
        <CustomSelect
          label={t('user-settings.modal.personal.field.position.label')}
          placeholder={t('user-settings.modal.personal.field.position.placeholder')}
          value={values.positionId}
          isValueError={invalidFields.includes('positionId')}
          isRequired={true}
          options={positionOptions}
          style={styles.inputWrap}
          handleChange={handlers.handlePositionChange}
        />
        <Input
          label={t('user-settings.modal.personal.field.email.label')}
          placeholder={t('user-settings.modal.personal.field.email.placeholder')}
          value={values.email}
          isRequired={true}
          handleInputChange={handlers.setEmail}
          customStyle={styles.inputWrapBottom}
        />
        <Switch
          checked={values.emailNotified}
          title={t('user-settings.modal.personal.field.email.text')}
          containerStyle={styles.switchContainer}
          onChange={handlers.handleEmailNotifyChange}
        />
        <Input
          label={t('user-settings.modal.personal.field.pass.label')}
          placeholder={t('user-settings.modal.personal.field.pass.placeholder')}
          value={values.password}
          handleInputChange={handlers.setPassword}
          isPassword
          customStyle={styles.inputWrapBottom}
          isRequired={true}
        />
      </div>
    </div>
  );
};
