import { UID_STR_PRINTABLE_TEMPLATE_ID_REGEXP } from './consts';

export function getCharFromCode(code: number) {
  const startCharCode = 65; // A
  const endCharCode = 90; // Z
  const charCode = code + startCharCode;
  const diff = endCharCode - startCharCode + 1;

  if (code < 0) {
    return '';
  }

  if (charCode > endCharCode) {
    const quantity = Math.floor(code / diff);
    const remainder = code % diff;

    return String.fromCharCode(startCharCode).repeat(quantity) + String.fromCharCode(remainder + startCharCode);
  }

  if (charCode >= startCharCode && charCode <= endCharCode) {
    return String.fromCharCode(charCode);
  }

  return '';
}

export function getFileNameFromUid(uidStr?: string) {
  if (!uidStr) {
    return '';
  }
  try {
    return uidStr.replace(UID_STR_PRINTABLE_TEMPLATE_ID_REGEXP, '');
  } catch (error) {
    return '';
  }
}
