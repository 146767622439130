import { useDispatch } from 'react-redux';

import PoiVideoCameraMoveIcon from 'assets/img/poi/icons/videocamera/change_move_marker.svg';
import PoiVideoCameraAngleIcon from 'assets/img/poi/icons/videocamera/change_angle_marker.svg';
import PoiVideoCameraDirectionIcon from 'assets/img/poi/icons/videocamera/change_direction_marker.svg';
import PoiVideoCameraDistanceIcon from 'assets/img/poi/icons/videocamera/change_distance_marker.svg';

import { Map, Collection, Feature, MapBrowserEvent } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { MAP_LAYERS_Z_INDEX, MAP_LAYER_RENDER_BUFFER_VALUE } from 'utils/consts';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Icon, Style } from 'ol/style';
import { Modify } from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import { Geometry, Point, Polygon } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { easeOut } from 'ol/easing';

import turfBbox from '@turf/bbox';
import { point as turfPoint } from '@turf/helpers';
import turfDestination from '@turf/destination';
import turfDistance from '@turf/distance';
import turfTransformRotate from '@turf/transform-rotate';
import turfSector from '@turf/sector';

import { defaultPoiVideoCameraState as defaults, PoiTypesEnum } from 'components/poi/utils/consts';

import { setPoiCardFieldData } from 'reducers/poi';

import {
  POI_POINT_MARKER_TYPE,
  POI_VIDEOCAMERA_EDITOR_SECTOR_MARKER_TYPE,
  POI_VIDEOCAMERA_EDITOR_SEMI_SPHERE_MARKER_TYPE,
  POI_VIDEOCAMERA_EDITOR_VIEW_FIT_PAINT_DELAY,
  POI_VIDEOCAMERA_EDITOR_VIEW_FIT_EDIT_DELAY,
  POI_VIDEOCAMERA_EDITOR_POINT_IDS,
  POI_VIDEOCAMERA_EDITOR_INTERACTIONS,
} from './constants';
import {
  degToRad,
  radToDeg,
  getAngleBetweenRadiusVectors,
  getDirectionOfRotation,
  getBooleanPointInPolygon,
  getRotatedPointCoordinates,
  getRotatedPolygonCoordinates,
} from './helpers';
import { geoJSON, stylePoiSectorsFunction } from './utils';

export default class VideoCameraEditor {
  private vectorSource: VectorSource = new VectorSource({});
  private mapLayer: VectorLayer<VectorSource> = new VectorLayer({
    source: this.vectorSource,
    style: (feature, resolution) => stylePoiSectorsFunction(resolution, feature),
    zIndex: MAP_LAYERS_Z_INDEX.POI_VIDEO_CAMERA_EDITOR,
    renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
  });
  private modify: Modify = new Modify({
    source: this.vectorSource,
    hitDetection: this.mapLayer,
    style: [],
  });

  private mainCoordinates: Coordinate = defaults.coordinates.split(',').map(c => Number(c.trim())); // в системе lon lat

  private angle = defaults.angle; // внутренний угол сектора (degrees)
  private direction = defaults.direction; // угол поворота всего редактора (degrees)
  private distance = defaults.distance; // дистанция (meters)

  private movePoint: Feature = new Feature(); // центральная точка
  private anglePoint: Feature = new Feature(); // точка для изменения внутреннего угла (угла сектора)
  private directionPoint: Feature = new Feature(); // точка для изменения поворота всех features
  private distancePoint: Feature = new Feature(); // точка для изменения радиуса сектора
  private sectorFeature: Feature = new Feature(); // сектор

  // вспомогательные features
  private leftSemiSphere: Feature = new Feature(); // левое полушарие
  private rightSemiSphere: Feature = new Feature(); // правое полушарие

  private initialized = false; // статус initialized
  private dispatch: ReturnType<typeof useDispatch> = useDispatch; // dispatch-функция

  constructor(
    private readonly map: Map,
    poiVideoCameraFeature: Feature,
    params: { angle: number; direction: number; distance: number }
  ) {
    this.angle = params.angle;
    this.direction = params.direction;
    this.distance = params.distance;

    // set main coordinates
    const geometry = poiVideoCameraFeature.getGeometry();
    this.mainCoordinates = toLonLat((geometry as Point).getCoordinates());

    // set main properties to icon video camera point
    poiVideoCameraFeature.setProperties({
      ...poiVideoCameraFeature.getProperties(),
      poiSubTypeId: POI_VIDEOCAMERA_EDITOR_INTERACTIONS.MOVE,
      markerType: POI_POINT_MARKER_TYPE,
    });
    poiVideoCameraFeature.setStyle([
      new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: PoiVideoCameraMoveIcon,
          crossOrigin: 'anonymous',
          rotation: degToRad(-90 + this.direction),
        }),
      }),
    ]);
    poiVideoCameraFeature.setId(POI_VIDEOCAMERA_EDITOR_POINT_IDS.MOVE);
    this.movePoint = poiVideoCameraFeature;

    // create point features (Point)
    this.anglePoint = this.createPointFeature(this.anglePoint, POI_VIDEOCAMERA_EDITOR_INTERACTIONS.ANGLE);
    this.directionPoint = this.createPointFeature(this.directionPoint, POI_VIDEOCAMERA_EDITOR_INTERACTIONS.DIRECTION);
    this.distancePoint = this.createPointFeature(this.distancePoint, POI_VIDEOCAMERA_EDITOR_INTERACTIONS.DISTANCE);

    // create sector feature (Polygon)
    this.sectorFeature = this.createSectorFeature();

    // create semi spheres (Polygon)
    const semiSpheres = this.createSemiSpheresFeature();

    this.leftSemiSphere = semiSpheres.left;
    this.rightSemiSphere = semiSpheres.right;

    // create modify interaction
    this.modify = new Modify({
      source: this.vectorSource,
      hitDetection: this.mapLayer,
      style: [],
      features: new Collection([this.movePoint, this.anglePoint, this.directionPoint, this.distancePoint]),
    });
  }

  /**
   * Creating point feature
   */
  private createPointFeature(feature: Feature, interactionType: POI_VIDEOCAMERA_EDITOR_INTERACTIONS) {
    const rotateDistanceIconDeg = -30; // default rotate distance icon
    let rotate = 0;
    let pointId = '';
    let srcIcon = '';

    switch (interactionType) {
      case POI_VIDEOCAMERA_EDITOR_INTERACTIONS.ANGLE:
        rotate = -this.angle / 2 + this.direction;
        pointId = POI_VIDEOCAMERA_EDITOR_POINT_IDS.ANGLE;
        srcIcon = PoiVideoCameraAngleIcon;
        break;

      case POI_VIDEOCAMERA_EDITOR_INTERACTIONS.DIRECTION:
        rotate = this.direction;
        pointId = POI_VIDEOCAMERA_EDITOR_POINT_IDS.DIRECTION;
        srcIcon = PoiVideoCameraDirectionIcon;
        break;

      case POI_VIDEOCAMERA_EDITOR_INTERACTIONS.DISTANCE:
        rotate = this.angle / 2 + this.direction;
        pointId = POI_VIDEOCAMERA_EDITOR_POINT_IDS.DISTANCE;
        srcIcon = PoiVideoCameraDistanceIcon;
        break;

      default:
        break;
    }

    const markerType = POI_POINT_MARKER_TYPE;
    const destinationTurfPoint = turfDestination(this.mainCoordinates, this.distance, 0, {
      units: 'meters',
    });
    const rotatedTurfPoint = turfTransformRotate(destinationTurfPoint, rotate, { pivot: this.mainCoordinates });

    feature.setGeometry(new Point(fromLonLat(rotatedTurfPoint.geometry.coordinates)));
    feature.setId(pointId);
    feature.setProperties({
      poiType: PoiTypesEnum.poiVideocameraPoint,
      poiSubTypeId: interactionType,
      markerType,
    });
    feature.setStyle([
      new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: srcIcon,
          crossOrigin: 'anonymous',
        }),
      }),
    ]);

    const distancePointStyles = this.distancePoint.getStyle();
    if (Array.isArray(distancePointStyles) && distancePointStyles.length) {
      const distancePointImageStyle = distancePointStyles[0].getImage();
      distancePointImageStyle.setRotation(degToRad(rotateDistanceIconDeg));
    }

    return feature;
  }

  /**
   * Get sector feature data
   */
  private getSectorFeature() {
    const angleOffset = 0.0001; // смещение, необходимое для правильной отрисовки сектора при углах, равных [0, 90, 180, 270]
    const angle = [0, 90, 180, 270].some(a => a === this.angle) ? this.angle + angleOffset : this.angle;
    const bearing1 = -angle / 2 + this.direction;
    const bearing2 = angle / 2 + this.direction;
    const turfedSector = turfSector(this.mainCoordinates, this.distance, bearing1, bearing2, { units: 'meters' });

    return geoJSON.readFeature(turfedSector);
  }

  /**
   * Creating sector feature
   */
  private createSectorFeature() {
    const sectorFeature = this.getSectorFeature();

    sectorFeature.setId('sectorPolygon');
    sectorFeature.setProperties({
      markerType: POI_VIDEOCAMERA_EDITOR_SECTOR_MARKER_TYPE,
    });

    return sectorFeature;
  }

  /**
   * Get sectors (semi-spheres) features data
   */
  private getSemiSpheresFeatures(distance: number) {
    const overlapDistance = 1; // +1 метр для покрытия полусферами всех features (чтобы образовать круг с включенными внутрь features)
    const angleOffset = 0.001; // смещение радиусов полусфер для придания полной фигуры круга
    const bearing1 = -90 - angleOffset + this.direction;
    const bearing2 = 90 + angleOffset + this.direction;
    const leftSector = turfSector(this.mainCoordinates, distance + overlapDistance, bearing1, bearing2, {
      units: 'meters',
    });
    const rightSector = turfSector(this.mainCoordinates, distance + overlapDistance, bearing1, bearing2, {
      units: 'meters',
    });

    return {
      leftSectorFeature: geoJSON.readFeature(leftSector),
      rightSectorFeature: geoJSON.readFeature(rightSector),
    };
  }

  /**
   * Creating auxiliary sectors (semi-spheres) features with direction angle
   */
  private createSemiSpheresFeature() {
    const { leftSectorFeature, rightSectorFeature } = this.getSemiSpheresFeatures(this.distance);
    const leftSemiSphereGeometry = leftSectorFeature.getGeometry() as Polygon;
    const rightSemiSphereGeometry = rightSectorFeature.getGeometry() as Polygon;

    leftSemiSphereGeometry.setCoordinates(
      getRotatedPolygonCoordinates(leftSemiSphereGeometry, -90, { pivot: this.mainCoordinates })
    );
    rightSemiSphereGeometry.setCoordinates(
      getRotatedPolygonCoordinates(rightSemiSphereGeometry, 90, { pivot: this.mainCoordinates })
    );

    leftSectorFeature.setId('leftSemiSphere');
    leftSectorFeature.setProperties({
      markerType: POI_VIDEOCAMERA_EDITOR_SEMI_SPHERE_MARKER_TYPE,
    });
    rightSectorFeature.setId('rightSemiSphere');
    rightSectorFeature.setProperties({
      markerType: POI_VIDEOCAMERA_EDITOR_SEMI_SPHERE_MARKER_TYPE,
    });

    return {
      left: leftSectorFeature,
      right: rightSectorFeature,
    };
  }

  /**
   * Get extent
   */
  private getEditorExtent() {
    const extent = turfBbox(geoJSON.writeFeaturesObject(this.vectorSource.getFeatures()));
    const X1Y1 = fromLonLat([extent[0], extent[1]]);
    const X2Y2 = fromLonLat([extent[2], extent[3]]);

    return [...X1Y1, ...X2Y2] as Extent;
  }

  /**
   * Set view from extent
   */
  private viewFitExtent(duration: number) {
    const extent = this.getEditorExtent();

    this.map.getView().fit(extent, {
      size: this.map.getSize(),
      padding: [150, 150, 150, 150],
      duration,
      easing: easeOut,
    });
  }

  /**
   * Add features on map
   */
  private addFeaturesOnMap(features: Feature<Geometry>[]) {
    this.vectorSource.addFeatures(features);

    this.viewFitExtent(POI_VIDEOCAMERA_EDITOR_VIEW_FIT_PAINT_DELAY);
  }

  /**
   * Set modify interaction and add his on map
   */
  private addModifyInteraction() {
    if (this.initialized) {
      let currentPoint: FeatureLike | null = null; // текущая выбранная точка
      let isFeaturePointInLeftSphere = false; // для определения нахождения currentPoint в левой полусфере
      let typePoint: POI_VIDEOCAMERA_EDITOR_POINT_IDS | null = null; // тип выбранной точки

      // обработчик нажатия на точку
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.modify.handleDownEvent = (evt: MapBrowserEvent<UIEvent>) => {
        const feature = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
          return feature;
        });
        const type = feature?.getGeometry()?.getType();
        const properties = feature?.getProperties();
        let isPoint = false;

        if (feature && type === GeometryType.POINT && properties?.markerType === POI_POINT_MARKER_TYPE) {
          isPoint = true;
          currentPoint = feature;

          // define the belonging of a point to the left semi-sphere
          if (this.leftSemiSphere) {
            isFeaturePointInLeftSphere = getBooleanPointInPolygon(feature as Feature<Point>, this.leftSemiSphere);
          }
        }

        return isPoint;
      };

      // обработчик перемещения точки
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.modify.handleDragEvent = (evt: MapBrowserEvent<UIEvent>) => {
        if (currentPoint && evt.dragging) {
          const draggingCoordinates = evt.coordinate;
          const featureCoords = (currentPoint.getGeometry() as Point).getCoordinates();
          const mainCoords = fromLonLat(this.mainCoordinates);

          const pointId = currentPoint.getId();

          typePoint = pointId as POI_VIDEOCAMERA_EDITOR_POINT_IDS;

          switch (pointId) {
            case POI_VIDEOCAMERA_EDITOR_POINT_IDS.MOVE:
              {
                const deltaX = draggingCoordinates[0] - mainCoords[0];
                const deltaY = draggingCoordinates[1] - mainCoords[1];

                // repaint features
                this.changingMove(deltaX, deltaY);
                this.mainCoordinates = toLonLat(draggingCoordinates);
              }
              break;

            case POI_VIDEOCAMERA_EDITOR_POINT_IDS.ANGLE:
              {
                // set position angle point

                //vA - радиус-вектор А (feature), vB - радиус-вектор В (dragging)
                const vA = [featureCoords[0] - mainCoords[0], featureCoords[1] - mainCoords[1]];
                const vB = [draggingCoordinates[0] - mainCoords[0], draggingCoordinates[1] - mainCoords[1]];

                // определим угол поворота
                const cosAlpha = getAngleBetweenRadiusVectors(vA, vB);
                const alphaRad = Math.acos(cosAlpha);
                let alphaDeg = radToDeg(alphaRad);

                // определим направление поворота
                const edge = getDirectionOfRotation(mainCoords, featureCoords, draggingCoordinates);

                // в зависимости от направления определим знак угла поворота
                if (edge > 0) {
                  alphaDeg = -alphaDeg;
                }

                // repaint features
                this.changingAngle(alphaDeg);

                // set new angle and constraints angle

                //vC - радиус-вектор C (точка с 0-вым углом - distance point)
                const directionPointGeometry = this.directionPoint.getGeometry() as Point;
                const directionPointCoordinates = directionPointGeometry.getCoordinates();
                const vC = [directionPointCoordinates[0] - mainCoords[0], directionPointCoordinates[1] - mainCoords[1]];

                // определим ограничивающий угол поворота
                const cosBetta = getAngleBetweenRadiusVectors(vA, vC);
                const bettaRad = Math.acos(cosBetta);
                const bettaDeg = radToDeg(bettaRad);

                // set new angle
                this.angle = bettaDeg * 2; // умножаем на 2, т.к. bettaDeg - это угол между directionPoint и anglePoint, т.е. === углу между VC и distance point * 2
              }
              break;

            case POI_VIDEOCAMERA_EDITOR_POINT_IDS.DIRECTION:
              {
                // set position direction point

                //vA - радиус-вектор А (feature), vB - радиус-вектор В (dragging)
                const vA = [featureCoords[0] - mainCoords[0], featureCoords[1] - mainCoords[1]];
                const vB = [draggingCoordinates[0] - mainCoords[0], draggingCoordinates[1] - mainCoords[1]];

                // определим угол поворота
                const cosA = getAngleBetweenRadiusVectors(vA, vB);
                const angleRad = Math.acos(cosA);
                let angleDeg = radToDeg(angleRad);

                // определим направление движения
                const edge = getDirectionOfRotation(mainCoords, featureCoords, draggingCoordinates);

                // в зависимости от направления определим знак угла поворота
                if (edge > 0) {
                  angleDeg = -angleDeg;
                }

                // repaint features
                this.changingDirection(angleDeg);

                // set new direction angle

                const newDirectionAngle = this.direction + angleDeg;

                this.direction = newDirectionAngle % 360.0;
              }
              break;

            case POI_VIDEOCAMERA_EDITOR_POINT_IDS.DISTANCE:
              {
                // get new distance between distancePoint and mainPoint in meters
                const turfedDragFeature = turfPoint(toLonLat(draggingCoordinates));
                const newDistance = turfDistance(this.mainCoordinates, turfedDragFeature.geometry.coordinates, {
                  units: 'meters',
                });

                // repaint features
                this.changingDistance(newDistance, isFeaturePointInLeftSphere);
                // set new distance
                this.distance = newDistance;
              }
              break;

            default:
              break;
          }
        }
      };

      // обработчик отпускания точки
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.modify.handleUpEvent = () => {
        currentPoint = null;
        isFeaturePointInLeftSphere = false;

        // запишем данные в стор
        switch (typePoint) {
          case POI_VIDEOCAMERA_EDITOR_POINT_IDS.MOVE:
            const coordinates = toLonLat((this.movePoint.getGeometry() as Point).getCoordinates());

            this.dispatch(
              setPoiCardFieldData({
                key: 'coordinates',
                value: coordinates.join(', '),
              })
            );
            break;

          case POI_VIDEOCAMERA_EDITOR_POINT_IDS.ANGLE:
            this.dispatch(
              setPoiCardFieldData({
                key: 'angle',
                value: Math.round(this.angle % 360.0),
              })
            );
            break;

          case POI_VIDEOCAMERA_EDITOR_POINT_IDS.DIRECTION:
            this.dispatch(
              setPoiCardFieldData({
                key: 'direction',
                value: Math.round(this.direction % 360.0),
              })
            );
            break;

          case POI_VIDEOCAMERA_EDITOR_POINT_IDS.DISTANCE:
            this.dispatch(
              setPoiCardFieldData({
                key: 'distance',
                value: Math.round(this.distance),
              })
            );
            break;

          default:
            break;
        }

        return false;
      };

      this.map.addInteraction(this.modify);
    }
  }

  /**
   * Changing coordinates of the features used based on the coordinates of movePoint
   */
  private changingMove(deltaX: number, deltaY: number) {
    // points
    (this.movePoint.getGeometry() as Point)?.translate(deltaX, deltaY);
    (this.anglePoint.getGeometry() as Point)?.translate(deltaX, deltaY);
    (this.directionPoint.getGeometry() as Point)?.translate(deltaX, deltaY);
    (this.distancePoint.getGeometry() as Point)?.translate(deltaX, deltaY);
    // polygons
    (this.sectorFeature.getGeometry() as Polygon)?.translate(deltaX, deltaY);
    (this.leftSemiSphere.getGeometry() as Polygon)?.translate(deltaX, deltaY);
    (this.rightSemiSphere.getGeometry() as Polygon)?.translate(deltaX, deltaY);
  }

  /**
   * Changing angle of the features used based on the coordinates
   */
  private changingAngle(deltaAngleDeg: number) {
    const options = { pivot: this.mainCoordinates }; // опции - относительно какой точки вращать (pivot)

    // angle point
    const anglePointGeometry = this.anglePoint.getGeometry() as Point;
    const newAnglePointCoordinates = getRotatedPointCoordinates(anglePointGeometry, deltaAngleDeg, options);
    anglePointGeometry.setCoordinates(newAnglePointCoordinates);

    // distance point
    const distancePointGeometry = this.distancePoint.getGeometry() as Point;
    const newDistancePointCoordinates = getRotatedPointCoordinates(distancePointGeometry, -deltaAngleDeg, options);
    distancePointGeometry.setCoordinates(newDistancePointCoordinates);

    // sector
    const currentAngle = this.angle + deltaAngleDeg; // текущий угол сектора с учетом дельты deltaAngleDeg

    // если углы не нулевые, то перерисуем сектор с новым углом
    if (Math.round(currentAngle) && Math.round(currentAngle) !== 360) {
      const bearing1 = -currentAngle / 2 + this.direction;
      const bearing2 = currentAngle / 2 + this.direction;
      const newSector = turfSector(this.mainCoordinates, this.distance, bearing1, bearing2, { units: 'meters' });
      const newSectorCoordinates = [newSector.geometry.coordinates[0].map(coords => fromLonLat(coords))];

      const sectorGeometry = this.sectorFeature.getGeometry() as Polygon;
      sectorGeometry.setCoordinates(newSectorCoordinates);

      // rotate the distance icon around its axis
      const moveDistancePointStyles = this.distancePoint.getStyle();
      if (Array.isArray(moveDistancePointStyles) && moveDistancePointStyles.length) {
        const moveDistancePointImageStyle = moveDistancePointStyles[0].getImage();
        const moveDistancePointRotation = moveDistancePointImageStyle.getRotation();
        moveDistancePointImageStyle.setRotation(moveDistancePointRotation - degToRad(deltaAngleDeg));
      }
    }
  }

  /**
   * Changing angle rotate of the features used based on the coordinates
   */
  private changingDirection(deltaAngleDeg: number) {
    // опции - относительно какой точки вращать (pivot)
    const options = { pivot: this.mainCoordinates };

    // video camera point - вращается относительно своего центра
    const movePointStyles = this.movePoint.getStyle();

    // distance point - revolves around its center
    const moveDistancePointStyles = this.distancePoint.getStyle();

    if (Array.isArray(movePointStyles) && movePointStyles.length) {
      const movePointImageStyle = movePointStyles[0].getImage();
      const movePointRotation = movePointImageStyle.getRotation();

      movePointImageStyle.setRotation(movePointRotation + degToRad(deltaAngleDeg));
    }

    if (Array.isArray(moveDistancePointStyles) && moveDistancePointStyles.length) {
      const moveDistancePointImageStyle = moveDistancePointStyles[0].getImage();
      const moveDistancePointRotation = moveDistancePointImageStyle.getRotation();

      moveDistancePointImageStyle.setRotation(moveDistancePointRotation + degToRad(deltaAngleDeg));
    }

    // direction point
    const directionPointGeometry = this.directionPoint.getGeometry() as Point;
    directionPointGeometry.setCoordinates(getRotatedPointCoordinates(directionPointGeometry, deltaAngleDeg, options));
    // angle point
    const anglePointGeometry = this.anglePoint.getGeometry() as Point;
    anglePointGeometry.setCoordinates(getRotatedPointCoordinates(anglePointGeometry, deltaAngleDeg, options));
    // distance point
    const distancePointGeometry = this.distancePoint.getGeometry() as Point;
    distancePointGeometry.setCoordinates(getRotatedPointCoordinates(distancePointGeometry, deltaAngleDeg, options));

    // sector
    const sectorGeometry = this.sectorFeature.getGeometry() as Polygon;
    sectorGeometry.setCoordinates(getRotatedPolygonCoordinates(sectorGeometry, deltaAngleDeg, options));

    // semi-spheres
    const leftSemiSphereGeometry = this.leftSemiSphere.getGeometry() as Polygon;
    const rightSemiSphereGeometry = this.rightSemiSphere.getGeometry() as Polygon;

    leftSemiSphereGeometry.setCoordinates(getRotatedPolygonCoordinates(leftSemiSphereGeometry, deltaAngleDeg, options));
    rightSemiSphereGeometry.setCoordinates(
      getRotatedPolygonCoordinates(rightSemiSphereGeometry, deltaAngleDeg, options)
    );
  }

  /**
   * Changing coordinates of the features used based on the coordinates of movePoint and new distance value
   */
  private changingDistance(distance: number, isDistancePointInLeftSemiSphere: boolean) {
    // определим точку для получения координат на отдалении от move point
    const destinationTurfPoint = turfDestination(this.mainCoordinates, distance, 0, {
      units: 'meters',
    });
    // определим направление угла перемещения distance point
    // для случая, когда distance point и angle point поменялись местами
    const direction = isDistancePointInLeftSemiSphere ? -1 : 1;

    // distance point
    const distancePointRotate = (direction * this.angle) / 2 + this.direction;
    const distanceGeometry = this.distancePoint.getGeometry() as Point;
    const rotatedDistanceTurfPoint = turfTransformRotate(destinationTurfPoint, distancePointRotate, {
      pivot: this.mainCoordinates,
    });

    distanceGeometry.setCoordinates(fromLonLat(rotatedDistanceTurfPoint.geometry.coordinates));

    // angle point
    const anglePointRotate = -(direction * this.angle) / 2 + this.direction;
    const angleGeometry = this.anglePoint.getGeometry() as Point;
    const rotatedAngleTurfPoint = turfTransformRotate(destinationTurfPoint, anglePointRotate, {
      pivot: this.mainCoordinates,
    });

    angleGeometry.setCoordinates(fromLonLat(rotatedAngleTurfPoint.geometry.coordinates));

    // direction point
    const directionPointRotate = this.direction;
    const directionGeometry = this.directionPoint.getGeometry() as Point;
    const rotatedDirectionTurfPoint = turfTransformRotate(destinationTurfPoint, directionPointRotate, {
      pivot: this.mainCoordinates,
    });

    directionGeometry.setCoordinates(fromLonLat(rotatedDirectionTurfPoint.geometry.coordinates));

    // sector
    const bearing1 = -this.angle / 2 + this.direction;
    const bearing2 = this.angle / 2 + this.direction;
    const newSector = turfSector(this.mainCoordinates, distance, bearing1, bearing2, { units: 'meters' });
    const newSectorCoordinates = [newSector.geometry.coordinates[0].map(coords => fromLonLat(coords))];

    const sectorGeometry = this.sectorFeature.getGeometry() as Polygon;
    sectorGeometry.setCoordinates(newSectorCoordinates);

    // semi-spheres
    const { leftSectorFeature, rightSectorFeature } = this.getSemiSpheresFeatures(distance);

    const leftSectorRotatedCoordinates = getRotatedPolygonCoordinates(leftSectorFeature.getGeometry() as Polygon, -90, {
      pivot: this.mainCoordinates,
    });
    const rightSectorRotatedCoordinates = getRotatedPolygonCoordinates(
      rightSectorFeature.getGeometry() as Polygon,
      90,
      {
        pivot: this.mainCoordinates,
      }
    );

    const leftSemiSphereGeometry = this.leftSemiSphere.getGeometry() as Polygon;
    const rightSemiSphereGeometry = this.rightSemiSphere.getGeometry() as Polygon;

    leftSemiSphereGeometry.setCoordinates(leftSectorRotatedCoordinates);
    rightSemiSphereGeometry.setCoordinates(rightSectorRotatedCoordinates);
  }

  /**
   * Add points, sector, modify interaction and set view
   */
  init(dispatch: ReturnType<typeof useDispatch>) {
    const points = [this.movePoint, this.anglePoint, this.directionPoint, this.distancePoint];
    const polygons = [this.sectorFeature];

    this.addFeaturesOnMap([...points, ...polygons]);

    this.dispatch = dispatch; // required

    this.initialized = true;

    this.addModifyInteraction();

    this.map.addLayer(this.mapLayer);
  }

  /**
   * Get initialized status
   */
  get isInitialized() {
    return this.initialized;
  }

  /**
   * Update params and features
   */
  update(params: { angle: number; direction: number; distance: number; coordinates: { lon: number; lat: number } }) {
    this.angle = params.angle;
    this.direction = params.direction;
    this.distance = params.distance;
    this.mainCoordinates = [params.coordinates.lon, params.coordinates.lat];

    // points

    const movePointGeometry = this.movePoint.getGeometry() as Point;
    const anglePointGeometry = this.anglePoint.getGeometry() as Point;
    const directionPointGeometry = this.directionPoint.getGeometry() as Point;
    const distancePointGeometry = this.distancePoint.getGeometry() as Point;

    const anglePointRotate = -this.angle / 2 + this.direction;
    const directionPointRotate = this.direction;
    const distancePointRotate = this.angle / 2 + this.direction;

    const destinationTurfPoint = turfDestination(this.mainCoordinates, this.distance, 0, {
      units: 'meters',
    });

    const rotatedAngleTurfPoint = turfTransformRotate(destinationTurfPoint, anglePointRotate, {
      pivot: this.mainCoordinates,
    });
    const rotatedDirectionTurfPoint = turfTransformRotate(destinationTurfPoint, directionPointRotate, {
      pivot: this.mainCoordinates,
    });
    const rotatedDistanceTurfPoint = turfTransformRotate(destinationTurfPoint, distancePointRotate, {
      pivot: this.mainCoordinates,
    });

    const movePointStyles = this.movePoint.getStyle();

    if (Array.isArray(movePointStyles) && movePointStyles.length) {
      const movePointImageStyle = movePointStyles[0].getImage();

      movePointImageStyle.setRotation(degToRad(-90 + this.direction));
    }

    movePointGeometry.setCoordinates(fromLonLat(this.mainCoordinates));
    // закомментировал смену иконок дистанции и угла местами
    // anglePointGeometry.setCoordinates(fromLonLat(rotatedAngleTurfPoint.geometry.coordinates));
    directionPointGeometry.setCoordinates(fromLonLat(rotatedDirectionTurfPoint.geometry.coordinates));
    // distancePointGeometry.setCoordinates(fromLonLat(rotatedDistanceTurfPoint.geometry.coordinates));

    // sectors

    const sectorGeometry = this.sectorFeature.getGeometry() as Polygon;
    const sectorFeatureCoords = (this.getSectorFeature().getGeometry() as Polygon).getCoordinates();

    sectorGeometry.setCoordinates(sectorFeatureCoords);

    const leftSemiSphereGeometry = this.leftSemiSphere.getGeometry() as Polygon;
    const rightSemiSphereGeometry = this.rightSemiSphere.getGeometry() as Polygon;
    const { leftSectorFeature, rightSectorFeature } = this.getSemiSpheresFeatures(this.distance);

    leftSemiSphereGeometry.setCoordinates(
      getRotatedPolygonCoordinates(leftSectorFeature.getGeometry() as Polygon, -90, { pivot: this.mainCoordinates })
    );
    rightSemiSphereGeometry.setCoordinates(
      getRotatedPolygonCoordinates(rightSectorFeature.getGeometry() as Polygon, 90, { pivot: this.mainCoordinates })
    );

    this.viewFitExtent(POI_VIDEOCAMERA_EDITOR_VIEW_FIT_EDIT_DELAY);
  }

  /**
   * Clear map
   */
  clear() {
    this.vectorSource.clear();
  }

  /**
   * Delete unused data
   */
  dispose() {
    // remove interactions
    this.map.removeInteraction(this.modify);
    // remove layer
    this.map.removeLayer(this.mapLayer);
    this.vectorSource.dispose();
  }
}
