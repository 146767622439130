import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { lineString } from '@turf/helpers';
import Bbox from '@turf/bbox';

import { Map } from 'ol';
import { Extent, getSize } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { easeOut } from 'ol/easing';

import { RootState } from 'reducers';

import { HARITONOV_MOCK_ROUTE } from 'utils/mocks';
import { createRouteLayerGEOJSON } from '../utils';

import { SelectedEmployeeMarker, SelectedTransportMarker } from '../map.types';
import VectorSource from 'ol/source/Vector';

export function useContactsRoute(map: Map, markers: Array<SelectedEmployeeMarker | SelectedTransportMarker>) {
  const showRoute = useSelector((state: RootState) => state.map.showContactsRoute);

  const viewFitExtent = useCallback(
    (extent: Extent) => {
      map.getView().fit(extent, {
        size: getSize(extent),
        padding: [100, 100, 100, 100],
        duration: 2000,
        easing: easeOut,
      });
    },
    [map]
  );

  useEffect(() => {
    let routeLayer: VectorLayer<VectorSource>;
    if (showRoute) {
      routeLayer = createRouteLayerGEOJSON(HARITONOV_MOCK_ROUTE);
      const line = lineString(
        markers.map((m: SelectedEmployeeMarker | SelectedTransportMarker) => fromLonLat(m?.data))
      );
      const extent = Bbox(line);
      map.addLayer(routeLayer);
      viewFitExtent(extent as Extent);
    }

    return () => {
      if (routeLayer) {
        map.removeLayer(routeLayer);
        routeLayer.dispose();
      }
    };
  }, [map, showRoute, markers, viewFitExtent]);
}
