export default {
  en: {
    'geozones.button-block.drop-down-btn.label': 'Create',
    'geozones.button-block.drop-down-btn.value.geozone.text': 'Geofence',
    'geozones.button-block.drop-down-btn.value.geozone-group.text': 'Geofence group',

    'geozones.geozone-list.header.name.text': 'Name',

    'geozones.geozone-list.header.tooltip.delete.text': 'Delete',
    'geozones.geozone-list.header.tooltip.transport.text': 'Vehicles in area',
    'geozones.geozone-list.header.tooltip.employee.text': 'Employee’s in area',
    'geozones.geozone-list.header.tooltip.sorting.text': '',
    'geozones.geozone-list.item.tooltip.geozone.settings.text': 'Edit',
    'geozones.geozone-list.item.tooltip.geozone-group.settings.text': 'Edit',
    'geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text': 'Edit',

    'geozones.geozone-edit-modal.header.label': 'Geofence editing',
    'geozones.geozone-sorting-modal.element-showWithGroups.label': '',
    'geozones.geozone-sorting-modal.element-geozones.label': '',
    'geozones.geozone-sorting-modal.element-groups.label': '',

    'geozones.geozone-card.field.name.label': 'Geofence name',
    'geozones.geozone-card.field.name.placeholder': 'New geofence',
    'geozones.geozone-card.field.description.label': 'Geofence description',
    'geozones.geozone-card.field.description.placeholder': 'Enter description',
    'geozones.geozone-card.field.type.placeholder': 'Geofence type',
    'geozones.geozone-card.field.color-geozone.header.text': 'Geofence color',
    'geozones.geozone-card.field.color-title.header.text': 'Title color',
    'geozones.geozone-card.field.groups.placeholder': 'Geofence groups',
    'geozones.geozone-card.field.floor.placeholder': '',
    'geozones.geozone-card.field.width.label': 'Width, meters',
    'geozones.geozone-card.field.length.label': 'Length, meters',
    'geozones.geozone-card.field.radius.label': 'Radius, meters',

    'geozones.geozone-card.footer.btn.delete.label': 'Delete',
    'geozones.geozone-card.footer.btn.cancel.label': 'Cancel',
    'geozones.geozone-card.footer.btn.update.label': 'Update',
    'geozones.geozone-card.footer.btn.save.label': 'Save',

    'geozones.geozone-card.alert.header.label': 'Deleting a geofence',
    'geozones.geozone-card.alert.warning.text': 'Warning!',
    'geozones.geozone-card.alert.info.text':
      'A deleted geofence zone cannot be restored. Are you sure you want to remove the geofence?',
    'geozones.geozone-card.alert.question.text': 'Are you sure you want to continue?',
    'geozones.geozone-card.footer.btn.yes.label': 'Yes',
    'geozones.geozone-card.footer.btn.no.label': 'No',

    'geozones.geozone-group-card.header.label': 'Create group of geofences',
    'geozones.geozone-group-card.header.editLabel': 'Editing group of geofences',
    'geozones.geozone-group-card.field.name.label': 'Name of group',
    'geozones.geozone-group-card.field.name.placeholder': 'Enter name of group',
    'geozones.geozone-group-card.field.description.label': 'Description of group',
    'geozones.geozone-group-card.field.description.placeholder': 'Enter description of group',
    'geozones.geozone-group-card.available-group.header.label': 'Available geofences',
    'geozones.geozone-group-card.available-group.btn.select-all.label': 'Select all',
    'geozones.geozone-group-card.chosen-group.header.label': 'Selected geofences',
    'geozones.geozone-group-card.chosen-group.btn.select-all.label': 'Select all',

    'geozones.geozone-group-card.footer.btn.delete.label': 'Delete',
    'geozones.geozone-group-card.footer.btn.cancel.label': 'Cancel',
    'geozones.geozone-group-card.footer.btn.save.label': 'Save',

    'geozones.geozone-group-card.alert.header.label': 'Deleting a group of geofences',
    'geozones.geozone-group-card.alert.info.text':
      'A deleted geofence group cannot be restored. When deleting a group of geofences, all geofences included in it will remain. Are you sure you want to delete a geofence group?',

    'geozones.geozone-types.line.text': 'Line',
    'geozones.geozone-types.circle.text': 'Circle',
    'geozones.geozone-types.polygon.text': 'Polygon',
    'geozones.geozone-types.point.text': 'Point',

    'geozones.map-popup.title.text': 'Geofence card',
    'geozones.map-popup.name.text': 'Title',
    'geozones.map-popup.geozone-type.text': 'Geofense type',
    'geozones.map-popup.coords.text': 'Coordinates',
    'geozones.map-popup.description.text': 'Description',

    'geozones.map-popup.nd.title.text': 'Work permit card',
    'geozones.map-popup.nd.content.btn.module-nd.text': 'EWP Module',
    'geozones.map-popup.nd.content.number.text': 'Number of WP',
    'geozones.map-popup.nd.content.work-type.text': 'Type of work',
    'geozones.map-popup.nd.content.work-place.text': 'Location of works',
    'geozones.map-popup.nd.content.work-area.text': 'Area of works',
    'geozones.map-popup.nd.content.scope-of-work.text': 'Scope of work',
    'geozones.map-popup.nd.content.status.text': 'Status',
    'geozones.map-popup.nd.content.work-date-start.text': 'Start date of work',
    'geozones.map-popup.nd.content.work-date-end.text': 'End date of work',
    'geozones.map-popup.nd.content.responsible-persons.text': 'Responsible persons',
    'geozones.map-popup.nd.content.brigade-members.text': 'Brigade members',
  },
  ru: {
    'geozones.button-block.drop-down-btn.label': 'Создать',
    'geozones.button-block.drop-down-btn.value.geozone.text': 'Геозона',
    'geozones.button-block.drop-down-btn.value.geozone-group.text': 'Группа геозон',

    'geozones.geozone-list.header.name.text': 'Название',

    'geozones.geozone-list.header.tooltip.delete.text': 'Удалить',
    'geozones.geozone-list.header.tooltip.transport.text': 'Транспорта в геозоне',
    'geozones.geozone-list.header.tooltip.employee.text': 'Сотрудников в геозоне',
    'geozones.geozone-list.header.tooltip.sorting.text': 'Группировка',
    'geozones.geozone-list.item.tooltip.geozone.settings.text': 'Изменение геозоны',
    'geozones.geozone-list.item.tooltip.geozone-group.settings.text': 'Изменение группы геозон',
    'geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text': 'Изменение группы геозон',

    'geozones.geozone-edit-modal.header.label': 'Редактирование геозоны',

    'geozones.geozone-sorting-modal.element-showWithGroups.label': 'Группировать геозоны',
    'geozones.geozone-sorting-modal.element-geozones.label': 'Отображать геозоны',
    'geozones.geozone-sorting-modal.element-groups.label': 'Отображать группы геозон',

    'geozones.geozone-card.field.name.label': 'Название геозоны',
    'geozones.geozone-card.field.name.placeholder': 'Новая геозона',
    'geozones.geozone-card.field.description.label': 'Описание геозоны',
    'geozones.geozone-card.field.description.placeholder': 'Введите описание',
    'geozones.geozone-card.field.type.placeholder': 'Тип геозоны',
    'geozones.geozone-card.field.color-geozone.header.text': 'Цвет геозоны',
    'geozones.geozone-card.field.color-title.header.text': 'Цвет надписи',
    'geozones.geozone-card.field.groups.placeholder': 'Вхождение в группы геозон',
    'geozones.geozone-card.field.floor.placeholder': 'На каком этаже располагается',
    'geozones.geozone-card.field.width.label': 'Ширина, метров',
    'geozones.geozone-card.field.length.label': 'Длина, метров',
    'geozones.geozone-card.field.radius.label': 'Радиус, метров',

    'geozones.geozone-card.footer.btn.delete.label': 'Удалить',
    'geozones.geozone-card.footer.btn.cancel.label': 'Отмена',
    'geozones.geozone-card.footer.btn.update.label': 'Обновить',
    'geozones.geozone-card.footer.btn.save.label': 'Сохранить',

    'geozones.geozone-card.alert.header.label': 'Удаление геозоны',
    'geozones.geozone-card.alert.warning.text': 'Внимание!',
    'geozones.geozone-card.alert.info.text':
      'Удаленную геозону нельзя будет восстановить. Вы действительно хотите удалить геозону?',
    'geozones.geozone-card.alert.question.text': 'Вы действительно хотите продолжить?',
    'geozones.geozone-card.footer.btn.yes.label': 'Да',
    'geozones.geozone-card.footer.btn.no.label': 'Нет',

    'geozones.geozone-group-card-multiple.alert.header.label': 'Удаление геозоны',
    'geozones.geozone-group-card-multiple.alert.warning.text': 'Внимание!',
    'geozones.geozone-group-card-multiple.alert.info.text':
      'Удаление геозоны или группы геозон не может быть отменено и данные не могут быть восстановлены. Вы действительно хотите удалить геозону?',
    'geozones.geozone-group-card-multiple.alert.question.text': 'Вы действительно хотите продолжить?',
    'geozones.geozone-group-card-multiple.footer.btn.yes.label': 'Удалить',
    'geozones.geozone-group-card-multiple.footer.btn.no.label': 'Отмена',

    'geozones.geozone-group-card.header.label': 'Создание группы геозон',
    'geozones.geozone-group-card.header.editLabel': 'Редактирование группы геозон',
    'geozones.geozone-group-card.field.name.label': 'Название группы геозон',
    'geozones.geozone-group-card.field.name.placeholder': 'Введите название группы',
    'geozones.geozone-group-card.field.description.label': 'Описание группы геозон',
    'geozones.geozone-group-card.field.description.placeholder': 'Введите описание группы',
    'geozones.geozone-group-card.available-group.header.label': 'Доступные геозоны и группы',
    'geozones.geozone-group-card.available-group.btn.select-all.label': 'Выделить все',
    'geozones.geozone-group-card.chosen-group.header.label': 'Выбранные геозоны и группы',
    'geozones.geozone-group-card.chosen-group.btn.select-all.label': 'Выделить все',

    'geozones.geozone-group-card.footer.btn.delete.label': 'Удалить',
    'geozones.geozone-group-card.footer.btn.cancel.label': 'Отмена',
    'geozones.geozone-group-card.footer.btn.save.label': 'Сохранить',

    'geozones.geozone-group-card.alert.header.label': 'Удаление группы геозон',
    'geozones.geozone-group-card.alert.info.text':
      'Удаленную группу геозон нельзя будет восстановить. При удалении группы геозон, все входящие в нее геозоны останутся. Вы действительно хотите удалить группу геозон?',

    'geozones.geozone-types.line.text': 'Линия',
    'geozones.geozone-types.circle.text': 'Окружность',
    'geozones.geozone-types.polygon.text': 'Полигон',
    'geozones.geozone-types.point.text': 'Точка',

    'geozones.map-popup.title.text': 'Карточка геозоны',
    'geozones.map-popup.name.text': 'Название',
    'geozones.map-popup.geozone-type.text': 'Тип геозоны',
    'geozones.map-popup.coords.text': 'Координаты',
    'geozones.map-popup.description.text': 'Описание',

    'geozones.map-popup.nd.title.text': 'Карточка работы',
    'geozones.map-popup.nd.content.btn.module-nd.text': 'Модуль ЭНД',
    'geozones.map-popup.nd.content.number.text': 'Номер НД',
    'geozones.map-popup.nd.content.work-type.text': 'Вид работ',
    'geozones.map-popup.nd.content.work-place.text': 'Место проведения',
    'geozones.map-popup.nd.content.work-area.text': 'Участок работ',
    'geozones.map-popup.nd.content.scope-of-work.text': 'Содержание работ',
    'geozones.map-popup.nd.content.status.text': 'Статус',
    'geozones.map-popup.nd.content.work-date-start.text': 'Дата начала работ',
    'geozones.map-popup.nd.content.work-date-end.text': 'Дата окончания работ',
    'geozones.map-popup.nd.content.responsible-persons.text': 'Ответственные лица',
    'geozones.map-popup.nd.content.brigade-members.text': 'Члены бригады',
  },
  zh: {
    'geozones.button-block.drop-down-btn.label': '创建',
    'geozones.button-block.drop-down-btn.value.geozone.text': '地理围栏',
    'geozones.button-block.drop-down-btn.value.geozone-group.text': '地理围栏组',
    'geozones.geozone-list.header.name.text': '名称',
    'geozones.geozone-list.header.tooltip.delete.text': '删除',
    'geozones.geozone-list.header.tooltip.transport.text': '地理围栏中的车辆',
    'geozones.geozone-list.header.tooltip.employee.text': '地理围栏中的员工',
    'geozones.geozone-list.item.tooltip.geozone.settings.text': '更改地理围栏',
    'geozones.geozone-list.item.tooltip.geozone-group.settings.text': '更改地理围栏组',
    'geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text': '更改地理围栏组',
    'geozones.geozone-edit-modal.header.label': '地理围栏编辑',
    'geozones.geozone-card.field.name.label': '地理围栏名称',
    'geozones.geozone-card.field.name.placeholder': '新地理围栏',
    'geozones.geozone-card.field.description.label': '地理围栏描述',
    'geozones.geozone-card.field.description.placeholder': '输入描述',
    'geozones.geozone-card.field.type.placeholder': '地理围栏类型',
    'geozones.geozone-card.field.color-geozone.header.text': '地理围栏颜色',
    'geozones.geozone-card.field.color-title.header.text': '标记颜色',
    'geozones.geozone-card.field.groups.placeholder': '进入地理围栏组',
    'geozones.geozone-card.field.width.label': '宽度，米',
    'geozones.geozone-card.field.length.label': '长度，米',
    'geozones.geozone-card.field.radius.label': '半径，米',
    'geozones.geozone-card.footer.btn.delete.label': '删除',
    'geozones.geozone-card.footer.btn.cancel.label': '取消',
    'geozones.geozone-card.footer.btn.update.label': '更新',
    'geozones.geozone-card.footer.btn.save.label': '保存',
    'geozones.geozone-card.alert.header.label': '删除地理围栏',
    'geozones.geozone-card.alert.warning.text': '注意！',
    'geozones.geozone-card.alert.info.text': '删除的地理围栏组无法恢复。',
    'geozones.geozone-card.alert.question.text': '您确定要继续吗？',
    'geozones.geozone-card.footer.btn.yes.label': '是',
    'geozones.geozone-card.footer.btn.no.label': '否',
    'geozones.geozone-group-card.header.label': '创建地理围栏组',
    'geozones.geozone-group-card.field.name.label': '地理围栏组名称',
    'geozones.geozone-group-card.field.name.placeholder': '输入名称',
    'geozones.geozone-group-card.field.description.label': '地理围栏组描述',
    'geozones.geozone-group-card.field.description.placeholder': '输入描述',
    'geozones.geozone-group-card.available-group.header.label': '可用地理围栏',
    'geozones.geozone-group-card.available-group.btn.select-all.label': '全选',
    'geozones.geozone-group-card.chosen-group.header.label': '组中的地理围栏',
    'geozones.geozone-group-card.chosen-group.btn.select-all.label': '全选',
    'geozones.geozone-group-card.footer.btn.delete.label': '删除',
    'geozones.geozone-group-card.footer.btn.cancel.label': '取消',
    'geozones.geozone-group-card.footer.btn.save.label': '保存',
    'geozones.geozone-group-card.alert.header.label': '删除地理围栏组',
    'geozones.geozone-group-card.alert.info.text':
      '已删除的地理围栏组无法恢复。如果删除地理围栏组，组中包含的所有地理围栏将保留。',
    'geozones.geozone-types.line.text': '线',
    'geozones.geozone-types.circle.text': '圆形',
    'geozones.geozone-types.polygon.text': '多边形',
    'geozones.geozone-types.point.text': '点',
    'geozones.map-popup.title.text': '地理区卡',
    'geozones.map-popup.name.text': '名称',
    'geozones.map-popup.geozone-type.text': '地理区种类',
    'geozones.map-popup.coords.text': '坐标',
    'geozones.map-popup.description.text': '描述',
    'geozones.map-popup.nd.title.text': '工作卡',
    'geozones.map-popup.nd.content.btn.module-nd.text': '电子工作许可证模数',
    'geozones.map-popup.nd.content.number.text': '工作许可证号',
    'geozones.map-popup.nd.content.work-type.text': '工作种类',
    'geozones.map-popup.nd.content.work-place.text': '工作地点',
    'geozones.map-popup.nd.content.work-area.text': '工作区',
    'geozones.map-popup.nd.content.scope-of-work.text': '工作内容',
    'geozones.map-popup.nd.content.status.text': '地位',
    'geozones.map-popup.nd.content.work-date-start.text': '开始工作日期',
    'geozones.map-popup.nd.content.work-date-end.text': '完成工作日期',
    'geozones.map-popup.nd.content.responsible-persons.text': '负责人',
    'geozones.map-popup.nd.content.brigade-members.text': '队成员',
  },
  ar: {
    'geozones.button-block.drop-down-btn.label': 'إنشاء',
    'geozones.button-block.drop-down-btn.value.geozone.text': 'منطفة جغرافية',
    'geozones.button-block.drop-down-btn.value.geozone-group.text': 'مجموعة المناطق الجغرافية',
    'geozones.geozone-list.header.name.text': 'الاسم',
    'geozones.geozone-list.header.tooltip.delete.text': 'حذف',
    'geozones.geozone-list.header.tooltip.transport.text': 'وسائل النقل في المنطقة',
    'geozones.geozone-list.header.tooltip.employee.text': 'الموظفون في المنطقة',
    'geozones.geozone-list.item.tooltip.geozone.settings.text': 'تعديل',
    'geozones.geozone-list.item.tooltip.geozone-group.settings.text': 'تعديل',
    'geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text': 'تعديل',
    'geozones.geozone-edit-modal.header.label': 'تعديل المنطقة الجغرافية',
    'geozones.geozone-card.field.name.label': 'اسم المنطقة الجغرافية',
    'geozones.geozone-card.field.name.placeholder': 'منطفة جغرافية جديدة',
    'geozones.geozone-card.field.description.label': 'وصف المنطقة',
    'geozones.geozone-card.field.description.placeholder': 'أكتب الوصف',
    'geozones.geozone-card.field.type.placeholder': 'نوع المنطقة',
    'geozones.geozone-card.field.color-geozone.header.text': 'لون المنطقة',
    'geozones.geozone-card.field.color-title.header.text': 'لون العنوان',
    'geozones.geozone-card.field.groups.placeholder': 'دخول مجموعة المناطق',
    'geozones.geozone-card.field.width.label': 'العرض، مترًا',
    'geozones.geozone-card.field.length.label': 'الطول، مترًا',
    'geozones.geozone-card.field.radius.label': 'النطاق، مترًا',
    'geozones.geozone-card.footer.btn.delete.label': 'حذف',
    'geozones.geozone-card.footer.btn.cancel.label': 'إلغاء',
    'geozones.geozone-card.footer.btn.update.label': 'تحديث',
    'geozones.geozone-card.footer.btn.save.label': 'حفظ',
    'geozones.geozone-card.alert.header.label': 'حذف المنطقة؟',
    'geozones.geozone-card.alert.warning.text': 'انتباه!',
    'geozones.geozone-card.alert.info.text': 'لا يجوز استرجاع مجموعة المناطق بعد حذفها.',
    'geozones.geozone-card.alert.question.text': 'هل حقًا ترغب بمتابعة؟',
    'geozones.geozone-card.footer.btn.yes.label': 'نعم',
    'geozones.geozone-card.footer.btn.no.label': 'كلا',
    'geozones.geozone-group-card.header.label': 'إنشاء مجموعة المناطق',
    'geozones.geozone-group-card.field.name.label': 'اسم مجموعة المناطق',
    'geozones.geozone-group-card.field.name.placeholder': 'أكتب اسمًا',
    'geozones.geozone-group-card.field.description.label': 'وصف المجموعة',
    'geozones.geozone-group-card.field.description.placeholder': 'أكتب وصفًا لمجموعة المناطق',
    'geozones.geozone-group-card.available-group.header.label': 'مناطق متوفرة',
    'geozones.geozone-group-card.available-group.btn.select-all.label': 'تحديد الكل',
    'geozones.geozone-group-card.chosen-group.header.label': 'مناطق المجموعة',
    'geozones.geozone-group-card.chosen-group.btn.select-all.label': 'تحديد الكل',
    'geozones.geozone-group-card.footer.btn.delete.label': 'حذف',
    'geozones.geozone-group-card.footer.btn.cancel.label': 'إلغاء',
    'geozones.geozone-group-card.footer.btn.save.label': 'حفظ',
    'geozones.geozone-group-card.alert.header.label': 'حذف مجموعة المناطق',
    'geozones.geozone-group-card.alert.info.text':
      'لا يجوز استرجاع مجموعة المناطق بعد حذفها. سيتم إبقاء جميع المناطق الداخلة في المجموعة.',
    'geozones.geozone-types.line.text': 'خط',
    'geozones.geozone-types.circle.text': 'دائرة',
    'geozones.geozone-types.polygon.text': 'نطاق ',
    'geozones.geozone-types.point.text': 'النقطة',
    'geozones.map-popup.title.text': 'بطاقة السياج الجغرافي',
    'geozones.map-popup.name.text': 'الاسم',
    'geozones.map-popup.geozone-type.text': 'نوع السياج الجغرافي',
    'geozones.map-popup.coords.text': 'الإحداثيات',
    'geozones.map-popup.description.text': 'الوصف',
    'geozones.map-popup.nd.title.text': 'بطاقة العمل',
    'geozones.map-popup.nd.content.btn.module-nd.text': 'وحدة  الزي الإلكتروني المسموح',
    'geozones.map-popup.nd.content.number.text': 'رقم الزي المسموح',
    'geozones.map-popup.nd.content.work-type.text': 'نوع العمل',
    'geozones.map-popup.nd.content.work-place.text': 'موقع الأعمال',
    'geozones.map-popup.nd.content.work-area.text': 'منطقة الأعمال',
    'geozones.map-popup.nd.content.scope-of-work.text': 'محتوى الأعمال',
    'geozones.map-popup.nd.content.status.text': 'الحالة',
    'geozones.map-popup.nd.content.work-date-start.text': 'تاريخ بدء العمل',
    'geozones.map-popup.nd.content.work-date-end.text': 'تاريخ انتهاء العمل',
    'geozones.map-popup.nd.content.responsible-persons.text': 'الأشخاص المسؤولين',
    'geozones.map-popup.nd.content.brigade-members.text': 'أعضاء الفريق',
  },
  de: {
    'geozones.button-block.drop-down-btn.label': 'Erstellen ',
    'geozones.button-block.drop-down-btn.value.geozone.text': 'Geofence',
    'geozones.button-block.drop-down-btn.value.geozone-group.text': 'Geofence-Gruppe',
    'geozones.geozone-list.header.name.text': 'Name ',
    'geozones.geozone-list.header.tooltip.delete.text': 'Löschen',
    'geozones.geozone-list.header.tooltip.transport.text': 'Transport im Geofence',
    'geozones.geozone-list.header.tooltip.employee.text': 'Mitarbeiter im Geofence',
    'geozones.geozone-list.item.tooltip.geozone.settings.text': 'Geofence-Änderung',
    'geozones.geozone-list.item.tooltip.geozone-group.settings.text': 'Ändern einer Gruppe von Geofences',
    'geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text': 'Ändern einer Gruppe von Geofences',
    'geozones.geozone-edit-modal.header.label': 'Geofence-Bearbeitung',
    'geozones.geozone-card.field.name.label': 'Geofence-Name',
    'geozones.geozone-card.field.name.placeholder': 'Neuer Geofence',
    'geozones.geozone-card.field.description.label': 'Geofence-Beschreibung',
    'geozones.geozone-card.field.description.placeholder': 'Geben Sie eine Beschreibung ein',
    'geozones.geozone-card.field.type.placeholder': 'Geofence-Typ',
    'geozones.geozone-card.field.color-geozone.header.text': 'Geofence-Farbe',
    'geozones.geozone-card.field.color-title.header.text': 'Schriftfarbe',
    'geozones.geozone-card.field.groups.placeholder': 'Beitritt zu Geofence-Gruppen',
    'geozones.geozone-card.field.width.label': 'Breite, Meter',
    'geozones.geozone-card.field.length.label': 'Länge, Meter',
    'geozones.geozone-card.field.radius.label': 'Radius, Meter',
    'geozones.geozone-card.footer.btn.delete.label': 'Löschen',
    'geozones.geozone-card.footer.btn.cancel.label': 'Zurücksetzen',
    'geozones.geozone-card.footer.btn.update.label': 'Aktualisieren',
    'geozones.geozone-card.footer.btn.save.label': 'Speichern',
    'geozones.geozone-card.alert.header.label': 'Geofence löschen',
    'geozones.geozone-card.alert.warning.text': 'Beachtung!',
    'geozones.geozone-card.alert.info.text': 'Die gelöschte Geofence-Zone kann nicht wiederhergestellt werden.',
    'geozones.geozone-card.alert.question.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
    'geozones.geozone-card.footer.btn.yes.label': 'Ja',
    'geozones.geozone-card.footer.btn.no.label': 'Nein',
    'geozones.geozone-group-card.header.label': 'Erstellen der Geofence-Gruppe',
    'geozones.geozone-group-card.field.name.label': 'Name der Geofence-Gruppe',
    'geozones.geozone-group-card.field.name.placeholder': 'Geben Sie den Titel ein',
    'geozones.geozone-group-card.field.description.label': 'Beschreibung der Geofence-Gruppe',
    'geozones.geozone-group-card.field.description.placeholder': 'Geben Sie eine Beschreibung ein',
    'geozones.geozone-group-card.available-group.header.label': 'Verfügbare Geofences',
    'geozones.geozone-group-card.available-group.btn.select-all.label': 'Wählen Sie Alle',
    'geozones.geozone-group-card.chosen-group.header.label': 'Geofences in einer Gruppe',
    'geozones.geozone-group-card.chosen-group.btn.select-all.label': 'Wählen Sie Alle',
    'geozones.geozone-group-card.footer.btn.delete.label': 'Löschen',
    'geozones.geozone-group-card.footer.btn.cancel.label': 'Zurücksetzen',
    'geozones.geozone-group-card.footer.btn.save.label': 'Speichern',
    'geozones.geozone-group-card.alert.header.label': 'Löschen der Geofence-Gruppe ',
    'geozones.geozone-group-card.alert.info.text':
      'Eine gelöschte Geofence-Gruppe kann nicht wiederhergestellt werden. Beim Löschen der Geofence-Gruppe bleiben alle darin enthaltenen Geofences erhalten.',
    'geozones.geozone-types.line.text': 'Linie',
    'geozones.geozone-types.circle.text': 'Kreis',
    'geozones.geozone-types.polygon.text': 'Polygon',
    'geozones.geozone-types.point.text': 'Punkt',
    'geozones.map-popup.title.text': 'Geofence-Karte',
    'geozones.map-popup.name.text': 'Benennung',
    'geozones.map-popup.geozone-type.text': 'Geofence-Art',
    'geozones.map-popup.coords.text': 'Koordinaten',
    'geozones.map-popup.description.text': 'Beschreibung',
    'geozones.map-popup.nd.title.text': 'Projektkarte',
    'geozones.map-popup.nd.content.btn.module-nd.text': 'Elektronischer Erlaubnisschein-Modul',
    'geozones.map-popup.nd.content.number.text': 'Erlaubnisschein-Nummer',
    'geozones.map-popup.nd.content.work-type.text': 'Arbeitsart',
    'geozones.map-popup.nd.content.work-place.text': 'Veranstaltungsort',
    'geozones.map-popup.nd.content.work-area.text': 'Arbeitsbereich',
    'geozones.map-popup.nd.content.scope-of-work.text': 'Inhalt der Arbeiten',
    'geozones.map-popup.nd.content.status.text': 'Zustand',
    'geozones.map-popup.nd.content.work-date-start.text': 'Datum des Arbeitsbeginns',
    'geozones.map-popup.nd.content.work-date-end.text': 'Datum des Arbeitsabschlusses',
    'geozones.map-popup.nd.content.responsible-persons.text': 'Verantwortliche',
    'geozones.map-popup.nd.content.brigade-members.text': 'Teammitglieder',
  },
};
