import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { closeModal } from 'reducers/modal';
import { addOneTemplate, clearChosenTemplate, removeOneTemplate, updateOneTemplate } from 'reducers/records';
import { RecordTemplateCreate } from 'reducers/records/interface';

import { Spinner } from 'components/common/spinner/spinner';
import { Alert } from 'components/common/alert/alert';
import {
  ACTIVE_TABS,
  ALERT_CONTINUE_STATUS,
  REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS,
  REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_FROM_ID,
} from 'components/records/utils/consts';

import AccessEntity from 'utils/accessEntity';

import { ControlsModal } from './components/controlsModal/controlsModal';
import { HeaderModal } from './components/headerModal/headerModal';
import { TabsModal } from './components/tabsModal/tabsModal';
import { MainTab } from './components/tabsModal/components/mainTab/mainTab';
import { DataTab } from './components/tabsModal/components/dataTab/dataTab';
import { ConditionsTab } from './components/tabsModal/components/conditionsTab/conditionsTab';

import useMainTab from './components/hooks/useMainTab';
import useDataTab from './components/hooks/useDataTab';
import useConditionsTab from './components/hooks/useConditionsTab';

import styles from './recordsTemplateModal.module.scss';

export const RecordsTemplateModal = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const recordsPermissions = useSelector((state: RootState) => state.user.permissions.records);
  const recordsAccess = useMemo(() => new AccessEntity(recordsPermissions), [recordsPermissions]);
  const { isShortsLoading: userShortsIsLoading } = useSelector((state: RootState) => state.account);

  const { templateWindow, chosenTemplate, isChosenTemplateLoading } = useSelector((state: RootState) => state.records);

  const [alertContinueStatus, setAlertContinueStatus] = useState(ALERT_CONTINUE_STATUS.change);
  const [showLocalAlert, setShowLocalAlert] = useState(false);
  const [activeTab, setActiveTab] = useState<ACTIVE_TABS>(ACTIVE_TABS.main);

  useEffect(() => {
    return () => {
      if (chosenTemplate) {
        dispatch(clearChosenTemplate());
      }
    };
  }, [dispatch, chosenTemplate]);

  const {
    states: mainTabStates,
    handlers: mainTabHandlers,
    validateRequiredFields: mainTabValidateRequiredFields,
    setInvalidFields: mainTabSetInvalidFields,
    invalidFields: mainTabInvalidFields,
    hasChanges: mainTabHasChanges,
  } = useMainTab();
  const {
    states: dataTabStates,
    handlers: dataTabHandlers,
    refs: dataTabRefs,
    validateRequiredFields: dataTabValidateRequiredFields,
    setInvalidFields: dataTabSetInvalidFields,
    invalidFields: dataTabInvalidFields,
    hasChanges: dataTabHasChanges,
  } = useDataTab();
  const {
    states: conditionsTabStates,
    additionalData: conditionsTabAdditionalData,
    handlers: conditionsTabHandlers,
    validateRequiredFields: conditionsTabValidateRequiredFields,
    setInvalidFields: conditionsTabSetInvalidFields,
    invalidFields: conditionsTabInvalidFields,
    hasChanges: conditionsTabHasChanges,
  } = useConditionsTab();

  function getBodyModal(tab: ACTIVE_TABS) {
    switch (tab) {
      case ACTIVE_TABS.main:
        return <MainTab states={mainTabStates} handlers={mainTabHandlers} invalidFields={mainTabInvalidFields} />;
      case ACTIVE_TABS.data:
        return (
          <DataTab
            states={dataTabStates}
            handlers={dataTabHandlers}
            refs={dataTabRefs}
            invalidFields={dataTabInvalidFields}
          />
        );
      case ACTIVE_TABS.condition:
        return (
          <ConditionsTab
            states={conditionsTabStates}
            handlers={conditionsTabHandlers}
            invalidFields={conditionsTabInvalidFields}
            additionalData={conditionsTabAdditionalData}
          />
        );
      default:
        return null;
    }
  }

  const handleSave = () => {
    const validationResultMainInfo = mainTabValidateRequiredFields();
    const validationResultDataInfo = dataTabValidateRequiredFields();
    const validationResultConditionsInfo = conditionsTabValidateRequiredFields();

    if (validationResultMainInfo.length) {
      mainTabSetInvalidFields(validationResultMainInfo);
      return setActiveTab(ACTIVE_TABS.main);
    }
    if (validationResultDataInfo.length) {
      dataTabSetInvalidFields(validationResultDataInfo);
      return setActiveTab(ACTIVE_TABS.data);
    }
    if (validationResultConditionsInfo.length) {
      conditionsTabSetInvalidFields(validationResultConditionsInfo);
      return setActiveTab(ACTIVE_TABS.condition);
    }

    let data: RecordTemplateCreate = {
      name: mainTabStates.name,
      description: mainTabStates.description,
      users: mainTabStates.chosenUsers,

      dataFields: dataTabStates.chosenData.map(data => {
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { customId, parentIdx, parentSubIdx, ...sendedData } = data;
        return sendedData;
      }),

      lineNumeration: conditionsTabStates.lineNumeration,
      totalColumn: conditionsTabStates.totalColumn,
      geofences: conditionsTabStates.geofences,
      notificationMarkers: conditionsTabStates.notificationMarkers,
      movementTracks: conditionsTabStates.movementTracks,
      POIPoints: conditionsTabStates.POIPoints,
      heatmaps: conditionsTabStates.heatmaps,
      limitListOfObjects: conditionsTabStates.limitListOfObjects,
    };

    if (conditionsTabStates.groupingList.some(group => group.checked)) {
      data = {
        ...data,
        groupsBy: conditionsTabStates.groupingList
          .filter(group => group.checked)
          .map((group, i) => ({
            order: i,
            value: group.key,
          })),
      };
    }

    if (conditionsTabStates.limitListOfObjects) {
      const type = conditionsTabStates.additionalFilters.find(filter => filter.checked)?.key;

      if (type) {
        switch (type) {
          case REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification:
            data = {
              ...data,
              workObjectConstraints: conditionsTabStates.objectIds.map(objectId => ({
                type: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS.Notification,
                notifications:
                  objectId.ids?.map(id => REPORT_TEMPLATE_NOTIFICATION_CONDITIONS_FROM_ID[Number(id) - 1]) ??
                  ([] as string[]), // non zerobase option id for select
              })),
            };
            break;

          default:
            data = {
              ...data,
              workObjectConstraints: conditionsTabStates.objectIds.map(objectId => ({
                type,
                entityIds: objectId.ids?.map(id => Number(id)) ?? [],
              })),
            };
            break;
        }
      }
    }
    if (templateWindow.printableTemplateFileId) {
      data = {
        ...data,
        printableTemplateId: templateWindow.printableTemplateFileId,
      };
    }

    if (!chosenTemplate) {
      if (recordsAccess.isAllowCreate()) {
        dispatch(addOneTemplate(data));
      }
    } else {
      if (recordsAccess.isAllowUpdate()) {
        dispatch(updateOneTemplate({ id: chosenTemplate.id, data }));
      }
    }
    dispatch(closeModal());
  };

  const handleCancel = () => {
    const hasConditionsChanges = mainTabHasChanges() || dataTabHasChanges() || conditionsTabHasChanges();

    if (!hasConditionsChanges) {
      return dispatch(closeModal());
    }
    setShowLocalAlert(true);
  };

  const handleDelete = () => {
    setAlertContinueStatus(ALERT_CONTINUE_STATUS.delete);
    setShowLocalAlert(true);
  };

  const handleCopy = () => {
    if (chosenTemplate && recordsAccess.isAllowCreate()) {
      //TODO: copy
      dispatch(closeModal());
    }
  };

  const handleAlertCancel = () => {
    setAlertContinueStatus(ALERT_CONTINUE_STATUS.change);
    return setShowLocalAlert(false);
  };

  const handleAlertContinue = () => {
    setShowLocalAlert(true);

    switch (alertContinueStatus) {
      case ALERT_CONTINUE_STATUS.delete:
        if (chosenTemplate && recordsAccess.isAllowDelete()) {
          const { id } = chosenTemplate;

          dispatch(clearChosenTemplate());
          dispatch(removeOneTemplate(id));
          dispatch(closeModal());
        }
        break;

      default:
        break;
    }

    return dispatch(closeModal());
  };

  const isLoading = isChosenTemplateLoading || userShortsIsLoading;

  return (
    <div className={styles.wrapper}>
      <HeaderModal
        title={
          chosenTemplate ? t('records.card.template-edit.header.label') : t('records.card.template-create.header.label')
        }
        onCancel={handleCancel}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <TabsModal activeTab={activeTab} setActiveTab={setActiveTab}>
            {getBodyModal(activeTab)}
          </TabsModal>
          <ControlsModal
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onCopy={handleCopy}
            saveButtonText={
              chosenTemplate
                ? t('records.card.template-create.footer.btn.update.label')
                : t('records.card.template-create.footer.btn.save.label')
            }
            withoutCopy={!chosenTemplate || !recordsAccess.isAllowCreate()}
            withoutDelete={!chosenTemplate || !recordsAccess.isAllowDelete()}
          />
        </Fragment>
      )}

      {showLocalAlert && (
        <Alert
          title={
            alertContinueStatus === ALERT_CONTINUE_STATUS.delete
              ? t('records.card.template-list.alert.delete.title')
              : t('records.card.template-create.alert.title')
          }
          infoText={
            alertContinueStatus === ALERT_CONTINUE_STATUS.delete
              ? t('records.card.template-list.alert.delete.text')
              : t('records.card.template-create.alert.info.text')
          }
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </div>
  );
};
