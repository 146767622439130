import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { Option } from 'components/common/select/select';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import {
  ChosenPoi,
  PoiAnalyzedGases,
  PoiAnalyzedGasesRelationshipsData,
  PoiGasAnalyzerPointRelationshipsData,
  PoiGazSensorsRelationshipsData,
} from 'reducers/poi/interface';
import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';

import { URL_REGEXP } from 'utils/consts';
import { getCurrentLocale } from 'translate';

import { defaultPoiGazAnalyzerState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardGazAnalyzerData } from '../utils/types';

export default function usePoiGazAnalyzer(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardGazAnalyzerData);
  const { poiGasAnalyzerModelId = 0, poiAnalyzedGases } = poiCardData;
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const [poiAnalyzedGasesAddButtonIsHide, setPoiAnalyzedGasesAddButtonIsHide] = useState(false);
  const [poiGasAnalyzerModelIdForSelect, setPoiGasAnalyzerModelIdForSelect] = useState<Option[]>([]);
  const [poiGasSensorsForSelect, setPoiGasSensorsForSelect] = useState<Option[]>([]);
  const [poiAnalyzedGasesForSelect, setPoiAnalyzedGasesForSelect] = useState<{ gas: Option[]; measurement: Option[] }>({
    gas: [],
    measurement: [],
  });

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const lastVerificationDateRef = React.createRef();
  const nextVerificationDateRef = React.createRef();

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiGasAnalyzerPoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiGasAnalyzerPointData: PoiGasAnalyzerPointRelationshipsData =
        (relationships.poiGasAnalyzerPoint?.data as PoiGasAnalyzerPointRelationshipsData) ?? [];
      const poiGasSensorsData: PoiGazSensorsRelationshipsData[] =
        (relationships.poiGasSensors?.data as PoiGazSensorsRelationshipsData[]) ?? [];
      const poiAnalyzedGasesData: PoiAnalyzedGasesRelationshipsData[] =
        (relationships.poiAnalyzedGases?.data as PoiAnalyzedGasesRelationshipsData[]) ?? [];

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          poiGasAnalyzerModelId: poiGasAnalyzerPointData.poiGasAnalyzerModelId,
          dataSourceUrl: poiGasAnalyzerPointData.dataSourceUrl,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          poiGasSensors: poiGasSensorsData.map(sensor => sensor.id),
          lastCheckDate: poiGasAnalyzerPointData.lastCheckDate,
          nextCheckDate: poiGasAnalyzerPointData.nextCheckDate,
          poiAnalyzedGases: poiAnalyzedGasesData.map(gas => ({
            poiGasTypeId: gas.poiGasTypeId,
            poiGasMeasurementId: gas.poiGasMeasurementId,
            measureRangeFrom: gas.measureRangeFrom,
            measureRangeTo: gas.measureRangeTo,
            measureLimit: gas.measureLimit,
            isUsed: gas.isUsed,
          })),
        })
      );
    }
  }, [dispatch, chosenPoi]);

  // заполняем все опции селектов из справочников
  useEffect(() => {
    const handbookGasAnalyzerModels = handbookData?.poiGasAnalyzerModels;
    const handbookGasSensors = handbookData?.poiGasSensors;
    const handbookGasMeasurements = handbookData?.poiGasMeasurements;
    const handbookGasTypes = handbookData?.poiGasTypes;

    if (handbookGasAnalyzerModels && handbookGasSensors && handbookGasMeasurements && handbookGasTypes) {
      setPoiGasAnalyzerModelIdForSelect(
        handbookGasAnalyzerModels.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
      setPoiGasSensorsForSelect(
        handbookGasSensors.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
      setPoiAnalyzedGasesForSelect({
        gas: handbookGasTypes.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        })),
        measurement: handbookGasMeasurements.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        })),
      });
    }
  }, [handbookData, userLanguageKey]);

  // выбор модели газанализатора => в анализируемые газы
  // подставляется только те типы газов, которые привязаны к модели
  useEffect(() => {
    const handbookGasAnalyzerModels = handbookData?.poiGasAnalyzerModels;
    const handbookGasSensors = handbookData?.poiGasSensors;
    const handbookGasMeasurements = handbookData?.poiGasMeasurements;

    if (poiGasAnalyzerModelId && handbookGasAnalyzerModels && handbookGasMeasurements) {
      const foundModelHandbook = handbookGasAnalyzerModels.find(model => model.id === poiGasAnalyzerModelId);

      if (foundModelHandbook) {
        const { relationships: relationshipsHandbook } = foundModelHandbook;

        setPoiGasSensorsForSelect(
          relationshipsHandbook.poiGasSensors?.data.map((sensor, i) => ({
            value: i + 1,
            label: getTranslateFromLanguageKey(sensor.name, userLanguageKey),
            data: {
              id: sensor.id,
            },
          })) ?? []
        );

        dispatch(
          setPoiCardFieldData({
            key: 'poiGasSensors',
            value: relationshipsHandbook.poiGasSensors?.data.map(sensor => sensor.id) ?? [],
          })
        );

        if (chosenPoi) {
          const {
            relationships: { poiAnalyzedGases },
          } = chosenPoi;
          const { data: poiAnalyzedGasesData = [] } = poiAnalyzedGases || [];

          dispatch(
            setPoiCardFieldData({
              key: 'poiAnalyzedGases',
              value:
                relationshipsHandbook.poiGasTypes?.data.map((type, index) => {
                  return {
                    poiGasTypeId: type.id,
                    poiGasMeasurementId:
                      (poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[])[index]?.poiGasMeasurementId ?? 0,
                    measureRangeFrom:
                      (poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[])[index]?.measureRangeFrom ?? 0,
                    measureRangeTo:
                      (poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[])[index]?.measureRangeTo ?? 0,
                    measureLimit:
                      (poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[])[index]?.measureLimit ?? 0,
                    isUsed: (poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[])[index]?.isUsed ?? true,
                  };
                }) ?? [],
            })
          );

          if ((poiAnalyzedGasesData as PoiAnalyzedGasesRelationshipsData[]).some(gas => !gas.isUsed)) {
            setPoiAnalyzedGasesAddButtonIsHide(false);
          }
        } else {
          dispatch(
            setPoiCardFieldData({
              key: 'poiAnalyzedGases',
              value: relationshipsHandbook.poiGasTypes?.data.map(type => {
                return {
                  poiGasTypeId: type.id,
                  poiGasMeasurementId: 0,
                  measureRangeFrom: 0,
                  measureRangeTo: 0,
                  measureLimit: 0,
                  isUsed: true,
                };
              }),
            })
          );

          setPoiAnalyzedGasesAddButtonIsHide(true);
        }
      }
    } else {
      if (handbookGasSensors) {
        setPoiGasSensorsForSelect(
          handbookGasSensors.map((data, i) => ({
            value: i + 1,
            label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
            data: {
              id: data.id,
            },
          }))
        );
      }
      setPoiAnalyzedGasesAddButtonIsHide(false);
    }
  }, [dispatch, poiGasAnalyzerModelId, handbookData, chosenPoi, userLanguageKey]);

  // при изменении списка анализируемых газов прятать/показывать
  // кнопку в зависимости от количества привязанных газов
  useEffect(() => {
    if (poiGasAnalyzerModelId) {
      if (poiAnalyzedGases?.some(gas => !gas.isUsed)) {
        setPoiAnalyzedGasesAddButtonIsHide(false);
      } else {
        setPoiAnalyzedGasesAddButtonIsHide(true);
      }
    } else {
      setPoiAnalyzedGasesAddButtonIsHide(false);
    }
  }, [poiAnalyzedGases, poiGasAnalyzerModelId]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );

  const handleChangePoiGasAnalyzerModelId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiGasAnalyzerModelId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeDataSourceUrl = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'dataSourceUrl',
        value,
      })
    );

  const handleChangeCoordinates = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'coordinates',
        value,
      })
    );

  const handleChangePoiGasSensors = (values: ValuesType) => {
    dispatch(
      setPoiCardFieldData({
        key: 'poiGasSensors',
        value: values.map(value => (value.data?.id as number) || -1),
      })
    );
  };

  const handleChangeLastCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'lastCheckDate',
        value: date.toISOString(),
      })
    );

  const handleChangeNextCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'nextCheckDate',
        value: date.toISOString(),
      })
    );

  const handleChangePoiAnalyzedGases = (values: PoiAnalyzedGases[]) =>
    dispatch(
      setPoiCardFieldData({
        key: 'poiAnalyzedGases',
        value: values,
      })
    );

  const validateRequiredFields = () => {
    const handbookGasAnalyzerModels = handbookData?.poiGasAnalyzerModels;
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!poiCardData.dataSourceUrl || !URL_REGEXP.test(poiCardData.dataSourceUrl)) {
      newInvalidFields.push('dataSourceUrl');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    if (handbookGasAnalyzerModels.length && poiGasAnalyzerModelId) {
      const foundModel = handbookGasAnalyzerModels.find(model => model.id === poiGasAnalyzerModelId);
      // если выбрана модель и есть связанные типы газов, но не заполнены анализируемые газы (меньше 1)
      if (
        foundModel?.relationships.poiGasTypes?.data.length &&
        (!poiAnalyzedGases.length || poiAnalyzedGases.some(gaze => !gaze.poiGasMeasurementId))
      ) {
        newInvalidFields.push('analyzedGases');
      }
    } else {
      // если не выбрана модель и
      // нет анализируемых газов (меньше 1) и
      // не заполнены поля в анализируемых газах
      if (!poiAnalyzedGases.length || poiAnalyzedGases.some(gaze => !gaze.poiGasMeasurementId)) {
        newInvalidFields.push('analyzedGases');
      }
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiGasAnalyzerPointData: PoiGasAnalyzerPointRelationshipsData =
        (relationships.poiGasAnalyzerPoint?.data as PoiGasAnalyzerPointRelationshipsData) ?? [];
      const poiGasSensorsData: PoiGazSensorsRelationshipsData[] =
        (relationships.poiGasSensors?.data as PoiGazSensorsRelationshipsData[]) ?? [];
      const poiAnalyzedGazesData: PoiAnalyzedGasesRelationshipsData[] =
        (relationships.poiAnalyzedGases?.data as PoiAnalyzedGasesRelationshipsData[]) ?? [];

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        poiGasAnalyzerModelId: poiGasAnalyzerPointData.poiGasAnalyzerModelId,
        dataSourceUrl: poiGasAnalyzerPointData.dataSourceUrl,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        poiGasSensors: poiGasSensorsData.map(sensor => sensor.id),
        lastCheckDate: poiGasAnalyzerPointData.lastCheckDate,
        nextCheckDate: poiGasAnalyzerPointData.nextCheckDate,
        poiAnalyzedGases: poiAnalyzedGazesData.map(gas => ({
          poiGasTypeId: gas.poiGasTypeId,
          poiGasMeasurementId: gas.poiGasMeasurementId,
          measureRangeFrom: gas.measureRangeFrom,
          measureRangeTo: gas.measureRangeTo,
          measureLimit: gas.measureLimit,
          isUsed: gas.isUsed,
        })),
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      poiGasAnalyzerModelIdForSelect,
      poiGasSensorsForSelect,
      poiAnalyzedGasesForSelect,
      poiAnalyzedGasesAddButtonIsHide,
    },
    refs: {
      lastVerificationDateRef,
      nextVerificationDateRef,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePoiGasAnalyzerModelId,
      handleChangeDataSourceUrl,
      handleChangeCoordinates,
      handleChangePoiGasSensors,
      handleChangeLastCheckDate,
      handleChangeNextCheckDate,
      handleChangePoiAnalyzedGases,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
