import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import {
  clearChosenTemplate,
  fetchOneTemplate,
  fetchTemplates,
  removeOneTemplate,
  setCurrentTemplateId,
  setCreateReportData,
} from 'reducers/records';

import { defaultCreateReportObjects } from 'components/records/utils/consts';

import { CREATE_TEMPLATE_RECORDS } from 'utils/consts';
import AccessEntity from 'utils/accessEntity';

export default function useRecordsTemplates() {
  const dispatch = useDispatch();

  const { templates, needUpdate, currentTemplateId } = useSelector((state: RootState) => state.records);
  const recordsPermission = useSelector((state: RootState) => state.user.permissions.records);
  const recordsAccess = useMemo(() => new AccessEntity(recordsPermission), [recordsPermission]);

  const [templateDeleteId, setTemplateDeleteId] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchTemplates());

    return () => {
      dispatch(clearChosenTemplate());
    };
  }, [dispatch]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(fetchTemplates());
    }
  }, [dispatch, needUpdate]);

  const handleChooseTemplate = (id: string) => {
    dispatch(setCreateReportData(defaultCreateReportObjects));
    dispatch(setCurrentTemplateId(id));
  };

  const handleChangeTemplate = (id: string) => {
    if (recordsAccess.isAllowRead()) {
      dispatch(fetchOneTemplate(id));
      dispatch(showModal({ type: CREATE_TEMPLATE_RECORDS, which: '' }));
    }
  };

  const handleRemoveTemplate = (id: string) => {
    if (recordsAccess.isAllowDelete()) {
      setTemplateDeleteId(id);
      dispatch(removeOneTemplate(id));
    }
  };

  const handleSetTemplateDeleteId = (id: string | null) => {
    setTemplateDeleteId(id);
  };

  const handleDeleteAlertCancel = () => {
    setTemplateDeleteId(null);
  };

  const handleDeleteAlertContinue = () => {
    if (templateDeleteId && recordsAccess.isAllowDelete()) {
      handleRemoveTemplate(templateDeleteId);
    }
    setTemplateDeleteId(null);
  };

  return {
    states: {
      templates,
      currentTemplateId,
      templateDeleteId,
    },
    handlers: {
      handleChooseTemplate,
      handleChangeTemplate,
      handleDeleteAlertCancel,
      handleDeleteAlertContinue,
      handleSetTemplateDeleteId,
    },
  };
}
