import React from 'react';
import cs from 'classnames';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as CopyIcon } from 'assets/img/handbooks/copy.svg';

import { Spinner } from 'components/common/spinner/spinner';

import AccessEntity from 'utils/accessEntity';

import HandbooksElementsButtons from './handbooksElementsButtons';

import styles from './handbooksElementsView.module.scss';

export type ElementViewData = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

type Props = {
  isLoading: boolean;
  elements: ElementViewData[];
  chosenElementId: number | null;
  accessEntity: AccessEntity;
  handleCreateClick: () => void;
  handleElementClick: (elementId: number) => void;
  handleCopyElementClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, elementId: number) => void;
};

const HandbooksElementsView: React.VFC<Props> = ({
  isLoading,
  elements,
  chosenElementId,
  accessEntity,
  handleCreateClick,
  handleElementClick,
  handleCopyElementClick,
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.wrapper}>
      {isLoading && <Spinner />}

      {!isLoading && (
        <ul className={styles.list}>
          <li className={styles.listHeader}>
            <div className={styles.listItemName}>{t('handbooks.column.elements.table.header.name.label')}</div>
            <div className={cs(styles.dates, styles.datesHeader)}>
              <div className={styles.date}>{t('handbooks.column.elements.table.header.creation-date.label')}</div>
              <div className={styles.date}>{t('handbooks.column.elements.table.header.change-date.label')}</div>
            </div>
          </li>

          {elements.map((element, index) => (
            <li
              key={index}
              className={cs(styles.listItem, {
                [styles.listItemChosen]: chosenElementId === element.id,
              })}
              title={element.name}
              onClick={() => handleElementClick(element.id)}
            >
              <div className={styles.listItemName}>{element.name}</div>
              <div className={styles.dates}>
                <div className={styles.date}>{element.createdAt}</div>
                <div className={styles.date}>{element.updatedAt}</div>
                <div
                  className={cs(styles.copyBtn, {
                    [styles.copyBtnDisable]: !accessEntity.isAllowCreate(),
                  })}
                  onClick={ev => handleCopyElementClick(ev, element.id)}
                >
                  <CopyIcon className={styles.copyBtnIcon} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <HandbooksElementsButtons accessEntity={accessEntity} handleCreateClick={handleCreateClick} />
    </div>
  );
};

export default HandbooksElementsView;
