import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { AiFillCloseCircle } from 'react-icons/ai';

import styles from './popup.module.scss';

type Props = {
  header: React.ReactNode | null;
  content: React.ReactNode | null;
  footer: React.ReactNode | null;
  onClose?: () => void;
  hasCloseIconBtn?: boolean;
};

function Popup(props: Props) {
  const element = useRef(document.createElement('div'));

  useEffect(() => {
    const id = `popup-${Math.random()}`;
    const div = element.current;

    div.id = id;
    document.body.insertAdjacentElement('beforeend', div);

    return () => {
      div.parentNode?.removeChild(div);
    };
  }, [element]);

  const popupWrapper = (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>{props.header}</div>
          {props.hasCloseIconBtn && (
            <AiFillCloseCircle color="#999999" className={styles.headerClose} onClick={props.onClose} />
          )}
        </div>
        <div className={styles.content}>{props.content}</div>
        <div className={styles.footer}>{props.footer}</div>
      </div>
    </div>
  );

  return element ? ReactDOM.createPortal(popupWrapper, element.current) : null;
}

export default React.memo(Popup);
