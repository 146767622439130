import React, { Component, RefObject } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';
import { Alert } from 'components/common/alert/alert';
import { AdditionalField } from '../additionalField/additionalField';
import { ModalPagination } from '../pagination/pagination.modal';

import { RootState } from 'reducers';
import { AdditionalField as AddFieldType } from 'reducers/employees/interface';

import messages, { getCurrentLocale, LanguagesKeysType } from 'translate';

import { Field } from '../../utils/types';

import styles from './tabAdditionalFields.module.scss';

export interface SortableItemProps {
  index: number;
  value: Field;
  toggleVisible: () => void;
  deleteItem: () => void;
  handleChangeInput: (value: string) => void;
  handleChangeLabel: (label: string) => void;
}
export interface SortableListProps {
  items: Field[];
  toggleVisible: (id: number) => void;
  deleteItem: (id: number) => void;
  handleChangeInput: (id: number, value: string) => void;
  handleChangeLabel: (id: number, label: string) => void;
  refProp: RefObject<HTMLDivElement>;
}

interface ListContainerProps {
  locale: LanguagesKeysType;
  additionalFields: AddFieldType[];
  addNewField: () => void;
  handleChangeInput: (id: number, value: string) => void;
  handleChangeLabel: (id: number, label: string) => void;
  handleDeleteItem: (id: number) => void;
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  toggleVisible: (id: number) => void;
}

interface ListContainerState {
  showAlert: boolean;
  itemId: number | null;
}

const SortableItem = SortableElement(
  ({ value, toggleVisible, deleteItem, handleChangeInput, handleChangeLabel }: SortableItemProps) => {
    const t = useFormatMessage();

    return (
      <div className={styles.sortableItem}>
        <AdditionalField
          item={value}
          toggleVisible={toggleVisible}
          handleChangeInput={handleChangeInput}
          handleChangeLabel={handleChangeLabel}
        />
        <Button red text={t('monitoring.unit-card.additional-fields.delete-btn.label')} onClick={deleteItem} />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, toggleVisible, deleteItem, handleChangeInput, handleChangeLabel, refProp }: SortableListProps) => {
    return (
      <div className={styles.sortableList} ref={refProp}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            toggleVisible={() => toggleVisible(index)}
            deleteItem={() => deleteItem(index)}
            handleChangeInput={value => handleChangeInput(index, value)}
            handleChangeLabel={label => handleChangeLabel(index, label)}
          />
        ))}
      </div>
    );
  }
);

class TabAdditionalFields extends Component<ListContainerProps, ListContainerState> {
  listRef = React.createRef<HTMLDivElement>();

  state = {
    showAlert: false,
    itemId: null,
  };

  scrollToBottom = () => {
    if (this.listRef.current) {
      this.listRef.current.scrollTop = this.listRef.current.scrollHeight;
    }
  };

  addNewField = () => {
    this.props.addNewField();
    this.scrollToBottom();
  };

  handleStartDeleteItem = (id: number) => {
    this.setState({
      ...this.state,
      itemId: id,
      showAlert: true,
    });
  };

  handleContinueDeleteItem = () => {
    const itemId = this.state.itemId;
    if (itemId !== null && itemId >= 0) {
      this.props.handleDeleteItem(itemId);
      this.setState({ showAlert: false });
    }
  };

  render() {
    return (
      <>
        <SortableList
          items={this.props.additionalFields}
          onSortEnd={this.props.onSortEnd}
          toggleVisible={this.props.toggleVisible}
          deleteItem={this.handleStartDeleteItem}
          handleChangeInput={this.props.handleChangeInput}
          handleChangeLabel={this.props.handleChangeLabel}
          useDragHandle
          refProp={this.listRef}
        />
        <ModalPagination withAddButton page={1} onAddButtonClick={this.addNewField} />

        {this.state.showAlert && (
          <Alert
            title={
              messages[getCurrentLocale(this.props.locale)]['monitoring.unit-card.additional-fields-alert.header.label']
            }
            infoText={
              messages[getCurrentLocale(this.props.locale)]['monitoring.unit-card.additional-fields-alert.text']
            }
            handleCancel={() => this.setState({ showAlert: false })}
            handleContinue={this.handleContinueDeleteItem}
          />
        )}
      </>
    );
  }
}

export default connect((state: RootState) => ({
  locale: state.user.userPreferences.locale,
}))(TabAdditionalFields);
