export default {
  en: {
    'trackers.button-block.btn.label': 'Create',

    'trackers.list.header.sim-card.text': 'SIM-card',

    'trackers.list.header.tooltip.tracker_number.text': 'Tracker number',
    'trackers.list.header.tooltip.follow.text': 'Track all objects',
    'trackers.list.header.tooltip.connection.text': 'Connection status',
    'trackers.list.header.tooltip.signal.text': 'Signal quality',
    'trackers.list.header.tooltip.battery.text': 'Battery charge level',

    'trackers.list.item.tooltip.follow.text': 'Track the object',
    'trackers.list.item.tooltip.battery.text': 'Settings',

    'trackers.card.header.label': 'Tracker properties',

    'trackers.card.tab.main.label': 'General information',
    'trackers.card.tab.sensors.label': 'Sensors',
    'trackers.card.tab.statistic.label': 'Statistics',

    'trackers.card.tab.main.field.number.label': 'Tracker’s number',
    'trackers.card.tab.main.field.number.placeholder': 'Enter tracker’s number',
    'trackers.card.tab.main.field.identifier.label': 'Tracker’s identifier',
    'trackers.card.tab.main.field.identifier.placeholder': 'Enter tracker’s identifier',
    'trackers.card.tab.main.field.sim.label': 'Tracker’s SIM-card',
    'trackers.card.tab.main.field.sim.placeholder': 'Input format +7XXXXXXXXXX',
    'trackers.card.tab.main.field.model.label': 'Tracker’s model',
    'trackers.card.tab.main.field.model.placeholder': 'Select from the list',
    'trackers.card.tab.main.field.address.label': 'Server address',
    'trackers.card.tab.main.field.address.placeholder': 'Select from the list',
    'trackers.card.tab.main.field.linked.label': 'Linked object',
    'trackers.card.tab.main.field.linked.placeholder': 'Select from the list',
    'trackers.card.tab.main.field.condition.label': 'Tracker’s condition',
    'trackers.card.tab.main.field.condition.placeholder': 'Select from the list',
    'trackers.card.tab.main.field.comment.label': 'Comment on tracker’s state',
    'trackers.card.tab.main.field.comment.placeholder': 'Write your comment',

    'trackers.card.footer.btn.cancel.label': 'Cancel',
    'trackers.card.footer.btn.create.label': 'Create',
    'trackers.card.footer.btn.save.label': 'Save',
    'trackers.card.footer.btn.update.label': 'Update',
    'trackers.card.footer.btn.delete.label': 'Delete',

    'trackers.alert.header.label': 'Delete tracker’s card',
    'trackers.alert.tracker-warning': '',
    'trackers.alert.info.text': 'Deleting tracker’s card cannot be canceled, data cannot be recovered.',
    'trackers.alert.warning.text': 'Warning!',
    'trackers.alert.confirm.text': 'Are you sure you want to continue?',
  },
  ru: {
    'trackers.button-block.btn.label': 'Создать',

    'trackers.list.header.sim-card.text': 'SIM-карта',

    'trackers.list.header.tooltip.tracker_number.text': 'Номер трекера',
    'trackers.list.header.tooltip.follow.text': 'Следить за всеми объектами',
    'trackers.list.header.tooltip.connection.text': 'Статус подключения к сети',
    'trackers.list.header.tooltip.signal.text': 'Уровень сигнала',
    'trackers.list.header.tooltip.battery.text': 'Уровень заряда аккумулятора',

    'trackers.list.item.tooltip.follow.text': 'Следить за объектом',
    'trackers.list.item.tooltip.battery.text': 'Изменение объекта',

    'trackers.card.header.label': 'Карточка трекера',

    'trackers.card.tab.main.label': 'Основная информация',
    'trackers.card.tab.sensors.label': 'Датчики',
    'trackers.card.tab.statistic.label': 'Статистика',

    'trackers.card.tab.main.field.number.label': 'Номер трекера',
    'trackers.card.tab.main.field.number.placeholder': 'Введите порядковый номер трекера',
    'trackers.card.tab.main.field.identifier.label': 'Идентификатор трекера',
    'trackers.card.tab.main.field.identifier.placeholder': 'Введите идентификатор трекера',
    'trackers.card.tab.main.field.sim.label': 'Номер SIM-карты трекера',
    'trackers.card.tab.main.field.sim.placeholder': 'Формат ввода +7ХХХХХХХХХХ',
    'trackers.card.tab.main.field.model.label': 'Модель трекера',
    'trackers.card.tab.main.field.model.placeholder': 'Выберите из списка',
    'trackers.card.tab.main.field.address.label': 'Адрес сервера трекера',
    'trackers.card.tab.main.field.address.placeholder': 'Выберите из списка',
    'trackers.card.tab.main.field.linked.label': 'Привязанный объект',
    'trackers.card.tab.main.field.linked.placeholder': 'Выберите из списка',
    'trackers.card.tab.main.field.condition.label': 'Состояние трекера',
    'trackers.card.tab.main.field.condition.placeholder': 'Выберите из списка',
    'trackers.card.tab.main.field.comment.label': 'Комментарий по состоянию трекера',
    'trackers.card.tab.main.field.comment.placeholder': 'Введите комментарий',

    'trackers.card.footer.btn.cancel.label': 'Отмена',
    'trackers.card.footer.btn.create.label': 'Создать',
    'trackers.card.footer.btn.save.label': 'Сохранить',
    'trackers.card.footer.btn.update.label': 'Обновить',
    'trackers.card.footer.btn.delete.label': 'Удалить',

    'trackers.alert.header.label': 'Удаление карточки трекера',
    'trackers.alert.tracker-warning':
      'При удалении карточки трекера, если трекер был привязан к объекту мониторинга, то связь с объектом будет разорвана.',
    'trackers.alert.info.text':
      'Удаление карточки трекера не сможет быть отменено и данные не смогут быть восстановлены.',
    'trackers.alert.warning.text': 'Внимание!',
    'trackers.alert.confirm.text': 'Вы действительно хотите продолжить?',
  },
  zh: {
    'trackers.button-block.btn.label': '创建',
    'trackers.list.header.sim-card.text': 'SIM卡',
    'trackers.list.header.tooltip.tracker_number.text': '跟踪器号码',
    'trackers.list.header.tooltip.follow.text': '跟踪所有对象',
    'trackers.list.header.tooltip.connection.text': '网络连接状态',
    'trackers.list.header.tooltip.signal.text': '信号强度',
    'trackers.list.header.tooltip.battery.text': '蓄电池电量',
    'trackers.list.item.tooltip.follow.text': '跟踪对象',
    'trackers.list.item.tooltip.battery.text': '更改对象',
    'trackers.card.header.label': '追踪卡',
    'trackers.card.tab.main.label': '基本信息',
    'trackers.card.tab.sensors.label': '传感器',
    'trackers.card.tab.statistic.label': '统计',
    'trackers.card.tab.main.field.number.label': '跟踪器号码',
    'trackers.card.tab.main.field.number.placeholder': '跟踪器号码',
    'trackers.card.tab.main.field.identifier.label': '跟踪器的IMEI号码',
    'trackers.card.tab.main.field.identifier.placeholder': '输入IMEI',
    'trackers.card.tab.main.field.sim.label': '跟踪器SIM卡号码',
    'trackers.card.tab.main.field.sim.placeholder': '输入格式+7XXXXXXXXXX',
    'trackers.card.tab.main.field.model.label': '跟踪器型号',
    'trackers.card.tab.main.field.model.placeholder': '从列表中选择',
    'trackers.card.tab.main.field.address.label': '跟踪器服务器地址',
    'trackers.card.tab.main.field.address.placeholder': '从列表中选择',
    'trackers.card.tab.main.field.linked.label': '连接对象',
    'trackers.card.tab.main.field.linked.placeholder': '从列表中选择',
    'trackers.card.tab.main.field.condition.label': '跟踪器状态',
    'trackers.card.tab.main.field.condition.placeholder': '从列表中选择',
    'trackers.card.tab.main.field.comment.label': '跟踪器状态评论',
    'trackers.card.tab.main.field.comment.placeholder': '输入您的评论',
    'trackers.card.footer.btn.cancel.label': '取消',
    'trackers.card.footer.btn.create.label': '创建',
    'trackers.card.footer.btn.save.label': '保存',
    'trackers.card.footer.btn.update.label': '刷新',
    'trackers.card.footer.btn.delete.label': '删除',
    'trackers.alert.header.label': '删除跟踪卡',
    'trackers.alert.tracker-warning': '',
    'trackers.alert.info.text': '跟踪地图的删除无法撤销。',
    'trackers.alert.warning.text': '注意！',
    'trackers.alert.confirm.text': '您确定要继续吗？',
  },
  ar: {
    'trackers.button-block.btn.label': 'إنشاء',
    'trackers.list.header.sim-card.text': 'شريحة SIM',
    'trackers.list.header.tooltip.tracker_number.text': 'رقم المتتبع',
    'trackers.list.header.tooltip.follow.text': 'مراقبة كل الكائنات',
    'trackers.list.header.tooltip.connection.text': 'حالة الاتصال بالشبكة',
    'trackers.list.header.tooltip.signal.text': 'قوة الإشارة',
    'trackers.list.header.tooltip.battery.text': 'وضع شحن البطارية',
    'trackers.list.item.tooltip.follow.text': 'تتبع الكائن',
    'trackers.list.item.tooltip.battery.text': 'تغيير الكائن',
    'trackers.card.header.label': 'بطاقة المتتبع',
    'trackers.card.tab.main.label': 'معلومات عامة',
    'trackers.card.tab.sensors.label': 'أجهزة استشعار',
    'trackers.card.tab.statistic.label': 'الإحصاء',
    'trackers.card.tab.main.field.number.label': 'رقم المتتبع',
    'trackers.card.tab.main.field.number.placeholder': 'رقم المتتبع',
    'trackers.card.tab.main.field.identifier.label': 'IMEI لرقم المتتبع',
    'trackers.card.tab.main.field.identifier.placeholder': 'أكتب IMEI',
    'trackers.card.tab.main.field.sim.label': 'رقم شريحة SIM للمتتبع',
    'trackers.card.tab.main.field.sim.placeholder': '+7XXXXXXXXXX',
    'trackers.card.tab.main.field.model.label': 'طراز المتتبع',
    'trackers.card.tab.main.field.model.placeholder': 'اختر من القائمة',
    'trackers.card.tab.main.field.address.label': 'عنوان خادم المتتبع',
    'trackers.card.tab.main.field.address.placeholder': 'اختر من القائمة',
    'trackers.card.tab.main.field.linked.label': 'الكائن المرتبط',
    'trackers.card.tab.main.field.linked.placeholder': 'اختر من القائمة',
    'trackers.card.tab.main.field.condition.label': 'حالة المتتبع',
    'trackers.card.tab.main.field.condition.placeholder': 'اختر من القائمة',
    'trackers.card.tab.main.field.comment.label': 'تعليق على حالة المتتبع',
    'trackers.card.tab.main.field.comment.placeholder': 'أكتب تعليقًا',
    'trackers.card.footer.btn.cancel.label': 'إلغاء',
    'trackers.card.footer.btn.create.label': 'إنشاء',
    'trackers.card.footer.btn.save.label': 'حفظ',
    'trackers.card.footer.btn.update.label': 'تحديث',
    'trackers.card.footer.btn.delete.label': 'حذف',
    'trackers.alert.header.label': 'حذف بطاقة تعقب',
    'trackers.alert.tracker-warning': '',
    'trackers.alert.info.text': 'لا يمكن التراجع عن حذف بطاقة التعقب.',
    'trackers.alert.warning.text': 'انتباه!',
    'trackers.alert.confirm.text': 'هل حقًا ترغب بمتابعة؟',
  },
  de: {
    'trackers.button-block.btn.label': 'Erstellen',
    'trackers.list.header.sim-card.text': 'SIM Karte',
    'trackers.list.header.tooltip.tracker_number.text': 'Tracker-Nummer',
    'trackers.list.header.tooltip.follow.text': 'Verfolgen Sie alle Objekte',
    'trackers.list.header.tooltip.connection.text': 'Netzanschlußstatus',
    'trackers.list.header.tooltip.signal.text': 'Signalstärke',
    'trackers.list.header.tooltip.battery.text': 'Batterieladezustand',
    'trackers.list.item.tooltip.follow.text': 'Objekt verfolgen',
    'trackers.list.item.tooltip.battery.text': 'Objekt ändern',
    'trackers.card.header.label': 'Tracker-Karte',
    'trackers.card.tab.main.label': 'Grundinformation',
    'trackers.card.tab.sensors.label': 'Sensoren',
    'trackers.card.tab.statistic.label': 'Statistiken',
    'trackers.card.tab.main.field.number.label': 'Tracker-Nummer',
    'trackers.card.tab.main.field.number.placeholder': 'Geben Sie die Tracker-Nummer ein',
    'trackers.card.tab.main.field.identifier.label': 'Tracker IMEI-Nummer',
    'trackers.card.tab.main.field.identifier.placeholder': 'Geben Sie IMEI ein',
    'trackers.card.tab.main.field.sim.label': 'Tracker-SIM-Nummer',
    'trackers.card.tab.main.field.sim.placeholder': 'Eingabeformat + 7XXXXXXXXXX',
    'trackers.card.tab.main.field.model.label': 'Tracker-Modell',
    'trackers.card.tab.main.field.model.placeholder': 'Aus der Liste wählen',
    'trackers.card.tab.main.field.address.label': 'Tracker-Serveradresse',
    'trackers.card.tab.main.field.address.placeholder': 'Aus der Liste wählen',
    'trackers.card.tab.main.field.linked.label': 'Gebundenes Objekt',
    'trackers.card.tab.main.field.linked.placeholder': 'Aus der Liste wählen',
    'trackers.card.tab.main.field.condition.label': 'Tracker-Status',
    'trackers.card.tab.main.field.condition.placeholder': 'Aus der Liste wählen',
    'trackers.card.tab.main.field.comment.label': 'Kommentar zum Status des Trackers',
    'trackers.card.tab.main.field.comment.placeholder': 'Geben Sie Ihren Kommentar ein',
    'trackers.card.footer.btn.cancel.label': 'Stornierung',
    'trackers.card.footer.btn.create.label': 'Erstellen',
    'trackers.card.footer.btn.save.label': 'Speichern',
    'trackers.card.footer.btn.update.label': 'Aktualisierung',
    'trackers.card.footer.btn.delete.label': 'Löschen',
    'trackers.alert.header.label': 'Löschen einer Tracker-Karte',
    'trackers.alert.tracker-warning': '',
    'trackers.alert.info.text': 'Das Löschen einer Tracker-Karte kann nicht rückgängig gemacht werden.',
    'trackers.alert.warning.text': 'Beachtung',
    'trackers.alert.confirm.text': 'Sind Sie sicher, dass Sie fortfahren möchten?',
  },
};
