import React, { Dispatch, ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as InfoIcon } from 'assets/img/main-information.svg';
import { ReactComponent as ObjectIcon } from 'assets/img/notifications/objects_notifications.svg';
import { ReactComponent as ActionIcon } from 'assets/img/notifications/actions_notifications.svg';
import { ReactComponent as TimeIcon } from 'assets/img/notifications/times_notifications.svg';
import { ReactComponent as StatsIcon } from 'assets/img/notifications/stats_notifications.svg';

import { ActiveTab } from 'components/notifications/utils/consts';

import styles from './tabsModal.module.scss';

const cx = classNames.bind(styles);

interface BodyProps {
  activeTab: ActiveTab;
  setActiveTab: Dispatch<ActiveTab>;
  children: ReactNode;
  isShowStatistic: boolean;
  isVideoAnalytics: boolean;
}

export const TabsModal = ({ activeTab, setActiveTab, children, isShowStatistic, isVideoAnalytics }: BodyProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalTabs}>
        <div
          className={activeTab === ActiveTab.main ? cx(styles.modalTab, styles.modalTabActive) : styles.modalTab}
          onClick={() => setActiveTab(ActiveTab.main)}
        >
          <InfoIcon className={activeTab === ActiveTab.main ? styles.modalTabIconActive : styles.modalTabIcon} />
          <span className={styles.modalTabLabel}>{t('notifications.card.tabs.general.text')}</span>
        </div>
        {!isVideoAnalytics && (
          <>
            <div
              className={activeTab === ActiveTab.objects ? cx(styles.modalTab, styles.modalTabActive) : styles.modalTab}
              onClick={() => setActiveTab(ActiveTab.objects)}
            >
              <ObjectIcon
                className={activeTab === ActiveTab.objects ? styles.modalTabIconActive : styles.modalTabIcon}
              />
              <span className={styles.modalTabLabel}>{t('notifications.card.tabs.objects.text')}</span>
            </div>
            <div
              className={
                activeTab === ActiveTab.actions
                  ? cx(styles.modalTab, styles.modalTabActive, styles.Actions)
                  : cx(styles.modalTab, styles.Actions)
              }
              onClick={() => setActiveTab(ActiveTab.actions)}
            >
              <ActionIcon
                className={activeTab === ActiveTab.actions ? styles.modalTabIconActive : styles.modalTabIcon}
              />
              <span className={styles.modalTabLabel}>{t('notifications.card.tabs.conditions.text')}</span>
            </div>
            <div
              className={activeTab === ActiveTab.time ? cx(styles.modalTab, styles.modalTabActive) : styles.modalTab}
              onClick={() => setActiveTab(ActiveTab.time)}
            >
              <TimeIcon className={activeTab === ActiveTab.time ? styles.modalTabIconActive : styles.modalTabIcon} />
              <span className={styles.modalTabLabel}>{t('notifications.card.tabs.time.text')}</span>
            </div>
          </>
        )}
        {isShowStatistic ||
          (isVideoAnalytics && (
            <div
              className={activeTab === ActiveTab.stats ? cx(styles.modalTab, styles.modalTabActive) : styles.modalTab}
              onClick={() => setActiveTab(ActiveTab.stats)}
            >
              <StatsIcon className={activeTab === ActiveTab.stats ? styles.modalTabIconActive : styles.modalTabIcon} />
              <span className={styles.modalTabLabel}>{t('notifications.card.tabs.statistic.text')}</span>
            </div>
          ))}
      </div>
      {children}
    </div>
  );
};
