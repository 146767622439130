import React, { Fragment, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { toast } from 'react-toastify';

import { TabInfo } from './tabInfo.modal';
import { ModalHeader } from '../components/header.modal';
import { ModalControls } from '../components/controls.modal';
import { ModalTabs } from '../components/tabs.modal';
import TabAdditionalFields from '../components/tabAdditionalFields1/tabAdditionalFields1';
import { Alert } from 'components/common/alert/alert';
import { Spinner } from 'components/common/spinner/spinner';
import { TabStatistic } from '../components/tabStatistic/tabStatistic';

import { RootState } from 'reducers';
import { toggleSelectUnit } from 'reducers/monitoring';
import { closeModal, showAlert } from 'reducers/modal/index';
import { addEmployee, updateEmployee, removeChosenEmployee } from 'reducers/employees';

import AccessEntity from 'utils/accessEntity';
import { DELETE_EMPLOYEE } from 'utils/consts';
import { useEmployeeInfo, useEmployeeAdditionalFields } from '../utils/hooks';
import { ActiveTab } from '../utils/types';

import styles from '../monitoring.module.scss';

const EmployeeModalForm = () => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const {
    employeeValues,
    employeeRefs,
    employeeHandlers,
    invalidFields,
    validateRequiredFields,
    setInvalidFields,
    chosenEmployee,
    handbooks,
    mainInfoHasChanges,
    isEditing,
  } = useEmployeeInfo();

  const isTrackersLoading = useSelector((state: RootState) => state.tracker.isLoading);
  const employees = useSelector((state: RootState) => state.employee.employees);
  const isEmployeesLoading = useSelector((state: RootState) => state.employee.isLoading);
  const isChosenEmployeeDataLoading = useSelector((state: RootState) => state.employee.isChosenEmployeeDataLoading);
  const trackableUnits = useSelector((state: RootState) => state.trackableUnit.trackableUnits);
  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);

  const isLoading = isTrackersLoading || isEmployeesLoading || isChosenEmployeeDataLoading;
  const empLength = employees.length;

  const additionalFields = useEmployeeAdditionalFields();

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.info);
  const [showLocalAlert, setShowLocalAlert] = useState(false);
  const trackersPermission = useSelector((state: RootState) => state.user.permissions.trackers);
  const employeesPermission = useSelector((state: RootState) => state.user.permissions.employees);
  const trackersAccess = useMemo(() => new AccessEntity(trackersPermission), [trackersPermission]);
  const employeesAccess = useMemo(() => new AccessEntity(employeesPermission), [employeesPermission]);

  const handleCancel = () => {
    if (mainInfoHasChanges() || additionalFields.hasChanges()) {
      return setShowLocalAlert(true);
    }
    dispatch(removeChosenEmployee());
    return dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    dispatch(removeChosenEmployee());
    return setShowLocalAlert(false);
  };

  const handleAlertContinue = () => {
    setShowLocalAlert(true);
    dispatch(removeChosenEmployee());
    return dispatch(closeModal());
  };

  const handleDelete = () => {
    if (chosenEmployee) {
      return dispatch(showAlert({ alertType: DELETE_EMPLOYEE }));
    }
    return handleCancel();
  };

  const handleSave = async () => {
    if (empLength === 7) {
      return toast.error(t('toast.monitoring.add-employee.error.text'));
    }

    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      setInvalidFields(validationResult);
      return setActiveTab(ActiveTab.info);
    }

    const { workObjects: workObjectIds, ...employeeData } = employeeValues;

    if (chosenEmployee) {
      if (employeesAccess.isAllowUpdate()) {
        await dispatch(
          updateEmployee({
            employeeData: { ...employeeData, additionalFields: additionalFields.additionalFields },
            workObjectIds,
          })
        );

        // If no trakcer and employee is selected then remove selection
        if (!employeeData.trackerId) {
          const employeeId = parseInt(chosenEmployee.id);
          const employeeTrackableUnit = trackableUnits.find(unit => unit.attributes.employeeId === employeeId);

          if (employeeTrackableUnit && selectedUnits.includes(employeeTrackableUnit.id)) {
            dispatch(toggleSelectUnit({ id: employeeTrackableUnit.id, currentStatus: true }));
          }
        }

        dispatch(removeChosenEmployee());
      }
    } else {
      if (employeesAccess.isAllowCreate()) {
        await dispatch(
          addEmployee({
            employeeData: { ...employeeData, additionalFields: additionalFields.additionalFields },
            workObjectIds,
          })
        );
      }
    }
    dispatch(closeModal());
  };

  const disabledSaveButton = chosenEmployee ? !employeesAccess.isAllowUpdate() : !employeesAccess.isAllowCreate();
  const disabledDeleteButton = !employeesAccess.isAllowDelete();

  return (
    <Fragment>
      <div className={styles.modalHeader}>
        <ModalHeader title={t('monitoring.unit-card.header.employee.label')} onCancel={handleCancel} />
      </div>
      <div className={styles.modalBody}>
        {isLoading ? (
          <div className={styles.modalInfoLoading}>
            <Spinner />
          </div>
        ) : (
          <ModalTabs showStat={isEditing} activeTab={activeTab} setActiveTab={setActiveTab}>
            {activeTab === ActiveTab.info && (
              <TabInfo
                handbooks={handbooks}
                refs={employeeRefs}
                fields={{ ...employeeValues, trackersPermission: trackersAccess }}
                handlers={employeeHandlers}
                invalidFields={invalidFields}
              />
            )}
            {activeTab === ActiveTab.additional && <TabAdditionalFields {...additionalFields} />}
            {activeTab === ActiveTab.stat && <TabStatistic />}
          </ModalTabs>
        )}
      </div>
      <div className={styles.modalControls}>
        <ModalControls
          withoutDelete={!Boolean(chosenEmployee)}
          withoutSave={chosenEmployee ? !employeesAccess.isAllowUpdate() : !employeesAccess.isAllowCreate()}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          disabledSaveButton={disabledSaveButton}
          disabledDeleteButton={disabledDeleteButton}
          saveButtonText={
            chosenEmployee
              ? t('monitoring.unit-card.footer.btn.update.label')
              : t('monitoring.unit-card.footer.btn.create.label')
          }
        />
      </div>

      {showLocalAlert && (
        <Alert
          title={t('monitoring.unit-card.close-alert.header.label')}
          infoText={t('monitoring.unit-card.close-alert.warning.text')}
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default EmployeeModalForm;
