import cistern from './cistern';
import employee from './employee';
import notification from './notification';
import pagination from './pagination';
import transport from './transport';
import poi from './poi';
import socialContact from './socialcontact';
import videocamera from './videocamera';

export default {
  en: {
    ...cistern.en,
    ...employee.en,
    ...notification.en,
    ...pagination.en,
    ...transport.en,
    ...poi.en,
    ...socialContact.en,
    ...videocamera.en,
  },
  ru: {
    ...cistern.ru,
    ...employee.ru,
    ...notification.ru,
    ...pagination.ru,
    ...transport.ru,
    ...poi.ru,
    ...socialContact.ru,
    ...videocamera.ru,
  },
  zh: {
    ...cistern.zh,
    ...employee.zh,
    ...notification.zh,
    ...pagination.zh,
    ...transport.zh,
    ...poi.zh,
    ...socialContact.zh,
    ...videocamera.zh,
  },
  ar: {
    ...cistern.ar,
    ...employee.ar,
    ...notification.ar,
    ...pagination.ar,
    ...transport.ar,
    ...poi.ar,
    ...socialContact.ar,
    ...videocamera.ar,
  },
  de: {
    ...cistern.de,
    ...employee.de,
    ...notification.de,
    ...pagination.de,
    ...transport.de,
    ...poi.de,
    ...socialContact.de,
    ...videocamera.de,
  },
};
