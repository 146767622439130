import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { PoiPopupInfoText } from './components/poiPopupInfoText';
import { PoiPopupCircleValue } from './components/poiPopupCircleValue';

import styles from '../poiPopup.module.scss';

// для internationalizations
const mocks = {
  indications: {
    date: 'MOCK DATA',
  },
  circle1: {
    title: 'CH4',
    measureUnit: '% об. доли',
  },
  circle2: {
    title: 'CO',
    measureUnit: 'млн -1',
  },
  circle3: {
    title: 'H2',
    measureUnit: 'млн -1',
  },
  circle4: {
    title: 'CO2',
    measureUnit: '% об. доли',
  },
  notifications: {
    text: 'MOCK DATA',
  },
};

export function PoiGazAnalyzerPopup() {
  const t = useFormatMessage();

  return (
    <Fragment>
      <PoiPopupInfoText
        label={t('map-popup.poi.gaz-analyzer.indications.text')}
        text={`${t('map-popup.poi.gaz-analyzer.date.text')} ${mocks.indications.date}`}
      />
      <div className={styles.valuesWrapper}>
        <PoiPopupCircleValue
          title={mocks.circle1.title}
          measureUnit={mocks.circle1.measureUnit}
          range={{ start: 0, end: 50 }}
          value={20}
          limit={35}
        />
        <PoiPopupCircleValue
          title={mocks.circle2.title}
          measureUnit={mocks.circle2.measureUnit}
          range={{ start: 0, end: 1000 }}
          value={520}
          limit={750}
        />
        <PoiPopupCircleValue
          title={mocks.circle3.title}
          measureUnit={mocks.circle3.measureUnit}
          range={{ start: 0, end: 100 }}
          value={65}
          limit={80}
        />
        <PoiPopupCircleValue
          title={mocks.circle4.title}
          measureUnit={mocks.circle4.measureUnit}
          range={{ start: 0, end: 0.5 }}
          value={0.35}
          limit={0.3}
        />
      </div>
      <PoiPopupInfoText label={t('map-popup.poi.gaz-analyzer.notifications.text')} text={mocks.notifications.text} />
    </Fragment>
  );
}
