import React, { FC, useState, Fragment, useRef, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames/bind';

import { ReactComponent as EditIcon } from 'assets/img/gear.svg';
import { ReactComponent as EditIconHead } from 'assets/img/tool.svg';
import { ReactComponent as CarIcon } from 'assets/img/geozones/geozone-car-icon.svg';
import { ReactComponent as PeopleIcon } from 'assets/img/geozones/geozone-persons-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';

import Search from 'components/common/search/search';
import DropButton from 'components/common/dropbutton/dropbutton';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { ExpandButton } from 'components/common/expandButton/expandButton';
import { geoJSON } from 'components/map/utils';
import { GeozonesListHeader } from '../geozonesListHeader/geozonesListHeader';

import { quickSort } from 'sort-ts';

import { RootState } from 'reducers';
import {
  setMoveToGeozoneId,
  setChosenGeozone,
  setChosenGroup,
  addSelectedGeozone,
  removeSelectedGeozone,
  addSelectedGroup,
  removeSelectedGroup,
  selectAll,
  unselectAll,
} from 'reducers/geozones';
import { setDrawType, setDrawColor, setDrawLabelColor } from 'reducers/map';
import { Geozone, GeozoneGroup, GeozoneRelationshipsData } from 'reducers/geozones/interface';
import { showAlert, showModal } from 'reducers/modal';

import AccessEntity from 'utils/accessEntity';
import useSetEllipsisAmountsToText from 'utils/useSetEllipsisAmountsToText';
import useObserveToWidthLeftSideBar from 'utils/useObserveToWidthLeftSideBar';

import { pxToRem } from 'utils/pxToRemJs';
import { debounce } from 'lodash';
import { DELETE_GEOZONE_GROUP_MULTIPLE, GEOZONES_GROUPING } from 'utils/consts';

import styles from './geozonesList.module.scss';

const cx = classNames.bind(styles);

const PADDING_LEFT_FOR_WRAPPER = 34;

const dropButtons = [
  {
    label: 'geozones.button-block.drop-down-btn.value.geozone.text',
    value: 'geozone',
  },
  {
    label: 'geozones.button-block.drop-down-btn.value.geozone-group.text',
    value: 'groupGeozones',
  },
];

type Props = {
  openGeozoneCreation: () => void;
  openGroupCreation: () => void;
};

const GeozonesList: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const {
    geozones: allGeozones,
    geozonesGeometricTypes: drawTypes,
    groups,
    selectedGeozones,
    selectedGroups,
    sort,
    showWithGroups,
  } = useSelector((state: RootState) => state.geozone);
  const geozonesPermission = useSelector((state: RootState) => state.user.permissions.geozones);
  const geozonesAccess = useMemo(() => new AccessEntity(geozonesPermission), [geozonesPermission]);

  const compareByName = (a: Geozone | GeozoneGroup, b: Geozone | GeozoneGroup) =>
    a.attributes.name < b.attributes.name ? 1 : -1;
  const compareByNameAscending = (a: Geozone | GeozoneGroup, b: Geozone | GeozoneGroup) =>
    a.attributes.name < b.attributes.name ? -1 : 1;

  const allGeozonesLength = allGeozones.length;
  let selectedGroupsCounter = 0;
  const filteredGeozones = allGeozones.filter(g => {
    if (!!searchValue) {
      return g.attributes.name.indexOf(searchValue) !== -1 && !g.relationships.parentGroup.data;
    }
    return !g.relationships.parentGroup.data;
  });
  const filteredGeozonesGroups = groups
    .filter(gr => {
      let showGroup = false;
      let needToCheck = true;
      const checkGeozones = (zones: GeozoneRelationshipsData[]) => {
        return zones.filter(zone => {
          const geozone = allGeozones.find(g => g.id === zone.id);
          const geozoneName = geozone?.attributes.name ?? '';
          const res = geozoneName.indexOf(searchValue) !== -1;
          if (res) {
            showGroup = true;
            needToCheck = false;
          }
          return res;
        });
      };
      const checkChild = (childs: GeozoneRelationshipsData[]) => {
        childs.forEach(child => {
          if (needToCheck) {
            const childGroup = groups.find(group => group.id === child.id);
            if (!!childGroup) {
              const childGroupName = childGroup.attributes.name ?? '';
              if (childGroupName.indexOf(searchValue) !== -1) {
                showGroup = true;
                needToCheck = false;
              }
              if (!!childGroup.relationships.childrenGroups.data?.length) {
                checkChild(childGroup.relationships.childrenGroups.data);
              }
              if (!!childGroup.relationships.geozones.data?.length) {
                checkGeozones(childGroup.relationships.geozones.data);
              }
            }
          }
        });
      };
      if (!!searchValue) {
        if (needToCheck && gr.attributes.name.indexOf(searchValue) !== -1) {
          showGroup = true;
          needToCheck = false;
        }
        if (!!gr.relationships.childrenGroups.data.length && needToCheck) {
          checkChild(gr.relationships.childrenGroups.data);
        }
        if (!!gr.relationships.geozones.data.length) {
          checkGeozones(gr.relationships.geozones.data);
        }
      } else {
        showGroup = true;
      }
      return showGroup;
    })
    .slice();
  const geozonesSorted = quickSort(filteredGeozones, sort.ascending ? compareByNameAscending : compareByName);
  const groupsSorted = quickSort(filteredGeozonesGroups, sort.ascending ? compareByNameAscending : compareByName);
  const sortedAllGeozones = quickSort([...allGeozones], sort.ascending ? compareByNameAscending : compareByName);

  const geozoneEntitiesListRef = useRef<HTMLDivElement>(null);

  const leftSideBarWidth = useObserveToWidthLeftSideBar(geozoneEntitiesListRef);

  useSetEllipsisAmountsToText({
    width: leftSideBarWidth,
    ref: geozoneEntitiesListRef,
    data: [...allGeozones, groups],
    ellipsisFromElementDelta: 230,
    amountFromElementDelta: 110,
  });

  const handleSearchChange = debounce((val: string) => {
    setSearchValue(val);
  });
  const handleDropButton = (value: string) => {
    if (value === 'geozone') {
      return props.openGeozoneCreation();
    }
    if (value === 'groupGeozones') {
      return props.openGroupCreation();
    }
    return;
  };
  const handleEditGeozone = (geozoneId: string) => {
    const geozone = allGeozones.find(g => g.id === geozoneId);

    if (geozone && geozone.attributes.geoJson) {
      let geoFeatureProps;
      try {
        const parsedGeoJSON = JSON.parse(geozone.attributes.geoJson);

        if (parsedGeoJSON.type === 'FeatureCollection') {
          geoFeatureProps = geoJSON
            .readFeatures(JSON.parse(geozone.attributes.geoJson))
            .map(prop => prop.getProperties());
          if (geoFeatureProps.length) {
            dispatch(setDrawColor(geoFeatureProps[0].drawColor));
            dispatch(setDrawLabelColor(geoFeatureProps[0].drawLabelColor));
          }
        } else {
          geoFeatureProps = geoJSON.readFeature(parsedGeoJSON).getProperties();
          dispatch(setDrawColor(geoFeatureProps.drawColor));
          dispatch(setDrawLabelColor(geoFeatureProps.drawLabelColor));
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (geozone) {
      const draw = drawTypes ? drawTypes[geozone?.attributes.geometricType] : null;

      if (draw) {
        dispatch(setDrawType(draw.olName));
      }
    }

    dispatch(setChosenGeozone(geozoneId));
    return props.openGeozoneCreation();
  };
  const handleEditGroup = (groupId: string) => {
    dispatch(setChosenGroup(groupId));
    return props.openGroupCreation();
  };
  const handleExpandGroup = (groupId: string) => {
    if (openedGroups.includes(groupId)) {
      return setOpenedGroups(openedGroups.filter(g => g !== groupId));
    }
    return setOpenedGroups([...openedGroups, groupId]);
  };
  const handleExpandAll = () => {
    if (openedGroups.length === groups.length) {
      return setOpenedGroups([]);
    }
    return setOpenedGroups(groups.map(g => g.id));
  };
  const handleSelectAll = () => {
    if (selectedGeozones.length > 0 && selectedGeozones.length === allGeozonesLength) {
      return dispatch(unselectAll());
    }
    return dispatch(selectAll());
  };
  const handleSelectGeozone = (id: string) => {
    if (selectedGeozones.includes(id)) {
      return dispatch(removeSelectedGeozone(id));
    }
    return dispatch(addSelectedGeozone(id));
  };
  const handleSelectGroup = (groupId: string) => {
    if (selectedGroups.includes(groupId)) {
      return dispatch(removeSelectedGroup(groupId));
    }
    return dispatch(addSelectedGroup(groupId));
  };
  const isSelectedGeozonesGroups = () => {
    if (selectedGeozones.length || selectedGroups.length) {
      return dispatch(showAlert({ alertType: DELETE_GEOZONE_GROUP_MULTIPLE }));
    }
    return false;
  };

  groups.forEach(gr => {
    selectedGroupsCounter += gr.relationships.childrenGroups.data.length + 1;
  });
  const isIndeterminate =
    (selectedGeozones.length > 0 || selectedGroups.length > 0) &&
    (selectedGeozones.length !== allGeozones.length || selectedGroups.length !== selectedGroupsCounter);
  const allSelectedElements = selectedGroupsCounter + allGeozones.length;
  const headCheckboxStatus =
    selectedGeozones.length + selectedGroups.length === allSelectedElements &&
    (allGeozones.length > 0 || groups.length > 0);

  const onGeozoneTitleClick = (geozoneId: string) => {
    if (selectedGeozones.some(id => id === geozoneId)) {
      dispatch(setMoveToGeozoneId(geozoneId));
    }
  };

  const renderGeozone = (zone: Geozone, lvl: number) => {
    const paddingLeft = pxToRem(lvl * PADDING_LEFT_FOR_WRAPPER);
    return (
      <Fragment key={`geozone-${zone.id}`}>
        <div className={styles.geozoneWrapper} style={{ paddingLeft }}>
          <div className={styles.geoZonesListInfo}>
            <Checkbox checked={selectedGeozones.includes(zone.id)} handleCheck={() => handleSelectGeozone(zone.id)} />
            <div
              className={styles.geoZonesListInfoTitle}
              data-ellipsis
              data-text={zone.attributes.name}
              onClick={() => {
                onGeozoneTitleClick(zone.id);
              }}
            />
          </div>
          <div className={styles.geoZonesIcons}>
            <div className={styles.geoZonesIconsIcon}>
              <div className={styles.geoZonesIconsCount}>{0}</div>
            </div>
            <div className={styles.geoZonesIconsIcon}>
              <div className={styles.geoZonesIconsCount}>{0}</div>
            </div>
            <div className={styles.geoZonesIconsIcon}>
              <EditIcon
                data-tip
                data-for={`edit_unit_${zone.id}`}
                className={cx(styles.geoZonesIconsIconPointer, {
                  [styles.geoZonesIconsIconPointerDisabled]: !geozonesAccess.isAllowUpdate(),
                })}
                onClick={() => handleEditGeozone(zone.id)}
              />
              {geozonesAccess.isAllowUpdate() && (
                <ReactTooltip
                  className={styles.customTooltip}
                  id={`edit_unit_${zone.id}`}
                  place="right"
                  type="light"
                  effect="solid"
                >
                  <span>{t('geozones.geozone-list.item.tooltip.geozone.settings.text')}</span>
                </ReactTooltip>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderGroup = (group: GeozoneGroup, lvl: number) => {
    const paddingLeft = pxToRem(lvl * PADDING_LEFT_FOR_WRAPPER);
    const isIncludedInOpenedGroups = openedGroups.includes(group.id);
    const { childrenGroups, geozones } = group.relationships;
    const childrenGroupsReduced = childrenGroups.data?.reduce((acc, curr) => {
      const foundGeozoneGroup = groups.find(group => group.id === curr.id);
      if (foundGeozoneGroup) {
        return [...acc, foundGeozoneGroup];
      }
      return acc;
    }, [] as GeozoneGroup[]);
    const groupsReduced = geozones.data?.reduce((acc, curr) => {
      const foundGeozone = allGeozones.find(geozone => geozone.id === curr.id);

      if (foundGeozone) {
        return [...acc, foundGeozone];
      }
      return acc;
    }, [] as Geozone[]);

    const groupsSorted = quickSort(groupsReduced, sort.ascending ? compareByNameAscending : compareByName);
    const childrenGroupsSorted = quickSort(
      childrenGroupsReduced,
      sort.ascending ? compareByNameAscending : compareByName
    );
    return (
      <Fragment key={`group-${group.id}`}>
        <div className={styles.geozoneWrapper} style={{ paddingLeft }}>
          <div className={styles.geoZonesListInfo}>
            <ExpandButton
              isOpen={openedGroups.includes(group.id)}
              onClick={() => handleExpandGroup(group.id)}
              style={styles.geoZonesListInfoExpandButton}
            />
            <Checkbox checked={selectedGroups.includes(group.id)} handleCheck={() => handleSelectGroup(group.id)} />
            <div className={styles.geoZonesListInfoTitle} data-ellipsis data-text={group.attributes.name} />
          </div>
          <div className={styles.geoZonesIcons}>
            <div className={styles.geoZonesIconsIcon}>
              <div className={styles.geoZonesIconsCount}>{0}</div>
            </div>
            <div className={styles.geoZonesIconsIcon}>
              <div className={styles.geoZonesIconsCount}>{0}</div>
            </div>
            <div className={styles.geoZonesIconsIcon}>
              <EditIcon
                className={cx(styles.geoZonesIconsIconPointer, {
                  [styles.geoZonesIconsIconPointerDisabled]: !geozonesAccess.isAllowUpdate(),
                })}
                onClick={() => handleEditGroup(group.id)}
                data-tip
                data-for={`edit_unit_${group.id}`}
              />
              {geozonesAccess.isAllowUpdate() && (
                <ReactTooltip
                  className={styles.customTooltip}
                  id={`edit_unit_${group.id}`}
                  place="right"
                  type="light"
                  effect="solid"
                >
                  <span>{t('geozones.geozone-list.item.tooltip.geozone-opened-group.settings.text')}</span>
                </ReactTooltip>
              )}
            </div>
          </div>
        </div>
        {isIncludedInOpenedGroups && childrenGroupsSorted.map(item => renderGroup(item, lvl + 1))}
        {/*смещение на 1 шаге нужно на 1 больше для геозон */}
        {isIncludedInOpenedGroups && groupsSorted.map(g => renderGeozone(g, !lvl ? lvl + 2 : lvl + 1))}
      </Fragment>
    );
  };

  const renderContent = () => {
    if (showWithGroups) {
      return (
        <Fragment>
          {groupsSorted.map((group, groupIndex) => (
            <Fragment key={`group-${groupIndex}-${group.id}`}>
              {!group.relationships.parentGroup.data && renderGroup(group, 0)}
            </Fragment>
          ))}

          {geozonesSorted.map(item => renderGeozone(item, 1))}
        </Fragment>
      );
    } else {
      return <Fragment>{sortedAllGeozones.map(item => renderGeozone(item, 0))}</Fragment>;
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.buttonBlock}>
        <div className={styles.searchWrapper}>
          <Search handleChange={handleSearchChange} />
        </div>
        {geozonesAccess.isAllowCreate() && (
          <DropButton
            label={t('geozones.button-block.drop-down-btn.label')}
            buttons={dropButtons}
            onClick={handleDropButton}
            optionsWidth={140}
            customStyle={styles.geozoneCreateButton}
          />
        )}
      </div>
      <div ref={geozoneEntitiesListRef}>
        <div className={styles.geoZonesListHead}>
          <div className={styles.geoZonesListInfo}>
            {showWithGroups && (
              <ExpandButton
                isOpen={groups.length > 0 && openedGroups.length === selectedGroupsCounter}
                onClick={handleExpandAll}
                style={styles.geoZonesListInfoExpandButton}
              />
            )}
            <GeozonesListHeader
              handleCheck={handleSelectAll}
              isIndeterminate={isIndeterminate}
              headCheckboxStatus={headCheckboxStatus}
            />
          </div>
          <div className={styles.geoZonesIcons}>
            <div className={styles.geoZonesIconsIcon}>
              <DeleteIcon
                className={styles.icon}
                onClick={() => isSelectedGeozonesGroups()}
                data-tip
                data-for="geozoneGroupMultipleDelete"
              />
              <ReactTooltip
                className={styles.customTooltip}
                id="geozoneGroupMultipleDelete"
                place="right"
                type="light"
                effect="solid"
              >
                <span>{t('geozones.geozone-list.header.tooltip.delete.text')}</span>
              </ReactTooltip>
            </div>
            <div className={styles.geoZonesIconsIcon} data-tip data-for="carsCount">
              <CarIcon />
              <ReactTooltip className={styles.customTooltip} id="carsCount" place="right" type="light" effect="solid">
                <span>{t('geozones.geozone-list.header.tooltip.transport.text')}</span>
              </ReactTooltip>
            </div>
            <div className={styles.geoZonesIconsIcon} data-tip data-for="peopleCount">
              <PeopleIcon />
              <ReactTooltip className={styles.customTooltip} id="peopleCount" place="right" type="light" effect="solid">
                <span>{t('geozones.geozone-list.header.tooltip.employee.text')}</span>
              </ReactTooltip>
            </div>
            <div
              className={cx(styles.geoZonesIconsIcon, styles.geoZonesIconsIconPointer)}
              data-tip
              data-for="geozoneSorting"
            >
              <EditIconHead onClick={() => dispatch(showModal({ type: GEOZONES_GROUPING, which: '' }))} />
              <ReactTooltip
                className={styles.customTooltip}
                id="geozoneSorting"
                place="right"
                type="light"
                effect="solid"
              >
                <span>{t('geozones.geozone-list.header.tooltip.sorting.text')}</span>
              </ReactTooltip>
            </div>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default React.memo(GeozonesList);
