import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';

import Input from 'components/common/input/input';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../../../tabsModal.module.scss';
import '../commonLayoutStyles.scss';

const cx = classNames.bind(styles);

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div
      className={cx(styles.datePicker, { [styles.intervalTime]: props.intervalTimeStyle })}
      onClick={props.onClick}
      ref={_ref}
    >
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        witchTimeIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

type TimeIntervalPropsType = {
  numInterval: number;
  handlers: {
    handleChangeStartTimeInterval: (i: number, date: string) => void;
    handleChangeEndTimeInterval: (i: number, date: string) => void;
  };
  fromStr: string;
  toStr: string;
};

export const IntervalRangeTimer = ({ numInterval, handlers, fromStr, toStr }: TimeIntervalPropsType) => {
  const t = useFormatMessage();

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);

  const refFrom = React.createRef();
  const refTo = React.createRef();

  return (
    <div className={cx(styles.modalRow, styles.InputsMultiple)}>
      <DatePicker
        dateFormat="HH:mm"
        selected={fromStr ? new Date(fromStr) : null}
        onChange={(date: Date) => handlers.handleChangeStartTimeInterval(numInterval - 1, date.toISOString())}
        className={styles.tabInfoDatePicker}
        placeholderText={'00:00'}
        customInput={
          <DatePickerCustomInput
            ref={refFrom}
            label={`${t('notifications.card.tabs.time.field.interval.label')} ${numInterval}, ${t(
              'notifications.card.tabs.time.field.interval.from.label'
            )}`}
            placeholder={'00:00'}
            intervalTimeStyle={styles.intervalTime}
          />
        }
        showTimeSelectOnly
        openToDate={fromStr ? new Date(fromStr) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('notifications.card.tabs.time.field.interval.time-caption.text')}
        disabled={false}
        popperClassName="popperDatePicker"
        minTime={setHours(setMinutes(new Date(), 0), 0)}
        maxTime={
          toStr
            ? setHours(setMinutes(new Date(), getMinutes(new Date(toStr))), getHours(new Date(toStr)))
            : setHours(setMinutes(new Date(), 59), 23)
        }
      />
      <DatePicker
        dateFormat="HH:mm"
        selected={toStr ? new Date(toStr) : null}
        onChange={(date: Date) => handlers.handleChangeEndTimeInterval(numInterval - 1, date.toISOString())}
        className={styles.tabInfoDatePicker}
        placeholderText={'23:59'}
        customInput={
          <DatePickerCustomInput
            ref={refTo}
            label={`${t('notifications.card.tabs.time.field.interval.label')} ${numInterval}, ${t(
              'notifications.card.tabs.time.field.interval.to.label'
            )}`}
            placeholder={'23:59'}
            intervalTimeStyle
          />
        }
        showTimeSelectOnly
        openToDate={toStr ? new Date(toStr) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('notifications.card.tabs.time.field.interval.time-caption.text')}
        disabled={false}
        popperClassName="popperDatePicker"
        minTime={
          fromStr
            ? setHours(setMinutes(new Date(), getMinutes(new Date(fromStr))), getHours(new Date(fromStr)))
            : setHours(setMinutes(new Date(), 0), 0)
        }
        maxTime={setHours(setMinutes(new Date(), 59), 23)}
      />
    </div>
  );
};
