import { Account } from 'reducers/accounts/interface';

export function getUserFullName(account: Account): string {
  const { lastName, firstName, secondName } = account.attributes;

  return `${lastName} ${firstName} ${secondName}`;
}

/**
 * Compose full name string from name parts.
 * Empty string, null, undefined parts will be skiped.
 * @param nameParts any nubmber of name parts as argumnets (lastName, firstName, secondName)
 * @returns joined name parts in the same order
 */
export function composeFullName(...nameParts: Array<string | null | undefined>): string {
  return nameParts.filter(part => part).join(' ');
}
