import React, { Dispatch } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { ReactComponent as InfoIcon } from 'assets/img/main-information.svg';
import { ReactComponent as RoleIcon } from 'assets/img/role-tab-icon.svg';
import { ReactComponent as StatIcon } from 'assets/img/object-stat.svg';
import styles from './tabsNav.module.scss';
import { ActiveTab } from '../../utils/consts';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface TabsProps {
  showStat: boolean;
  activeTab: ActiveTab;
  setActiveTab: Dispatch<ActiveTab>;
}

export const TabsNav = ({ showStat, activeTab, setActiveTab }: TabsProps) => {
  const t = useFormatMessage();

  const infoTabStyle = cx(styles.modalTab, { [styles.modalTabActive]: activeTab === ActiveTab.info });
  const infoIconStyle = cx({
    [styles.modalTabIcon]: activeTab !== ActiveTab.info,
    [styles.modalTabIconActive]: activeTab === ActiveTab.info,
  });
  const roleTabStyle = cx(styles.modalTab, { [styles.modalTabActive]: activeTab === ActiveTab.roles });
  const roleIconStyle = cx({
    [styles.modalTabIconRoles]: activeTab !== ActiveTab.roles,
    [styles.modalTabIconRolesActive]: activeTab === ActiveTab.roles,
  });
  const statTabStyle = cx(styles.modalTab, { [styles.modalTabActive]: activeTab === ActiveTab.stat });
  const statIconStyle = cx({
    [styles.modalTabIcon]: activeTab !== ActiveTab.stat,
    [styles.modalTabIconActive]: activeTab === ActiveTab.stat,
  });

  return (
    <div className={styles.modalTabs}>
      <div className={infoTabStyle} onClick={() => setActiveTab(ActiveTab.info)}>
        <InfoIcon className={infoIconStyle} />
        <span className={styles.modalTabLabel}>{t('accounts.card.user.tabs.main.text')}</span>
      </div>
      <div className={roleTabStyle} onClick={() => setActiveTab(ActiveTab.roles)}>
        <RoleIcon className={roleIconStyle} />
        <span className={styles.modalTabLabel}>{t('accounts.card.user.tabs.roles.text')}</span>
      </div>
      {showStat && (
        <div className={statTabStyle} onClick={() => setActiveTab(ActiveTab.stat)}>
          <StatIcon className={statIconStyle} />
          <span className={styles.modalTabLabel}>{t('accounts.card.user.tabs.statistic.text')}</span>
        </div>
      )}
    </div>
  );
};
