import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  defaultCreateReportObjects,
  REPORT_TABLE_OBJECT,
  REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS,
  REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING,
  GENERATED_PRINTED_FORM_RESPONSE_STATUSES,
  NO_HAS_REPORT_TEMPLATE_ID_ERROR,
} from 'components/records/utils/consts';
import { REPORTS_MAP_ROUTE } from 'components/map/hooks/useReport';
import { ReportRenderFinishType, resultBeacon } from 'components/map/helpers';

import { RootState } from 'reducers';
import { getOnePoi } from 'reducers/poi/api';
import { getLog } from 'reducers/notifications/api';
import { getOneGeozone } from 'reducers/geozones/api';
import { Geozone, GeozonesJsonApiObj } from 'reducers/geozones/interface';
import { selectBatchOfGeozones, setGeozones } from 'reducers/geozones';
import { NotificationLog, NotificationLogJSONApi } from 'reducers/notifications/interface';
import { setHistoryLogs, setSelectedHistoryLogs } from 'reducers/notifications';
import { ChosenPoiJSONApi, Poi } from 'reducers/poi/interface';
import { setPoiData, setSelectedPoi } from 'reducers/poi';
import { getUser } from 'reducers/accounts/api';
import { getOneEmployee } from 'reducers/employees/api';
import { getOneTransport } from 'reducers/transports/api';
import { getOneTracker } from 'reducers/trackers/api';
import { AccountsJsonApiObj } from 'reducers/accounts/interface';
import { ChosenEmployeeJSONApi } from 'reducers/employees/interface';
import { TransportJSONApiObj } from 'reducers/transports/interface';
import { ChosenTrackerInfo } from 'reducers/trackers/interface';

import messages, { getCurrentLocale, LanguagesKeysType } from 'translate';

import { reqHandlers } from 'utils/api';
import { GetFileInfo, ParallelItemInterface, ProcessErrorPayloadInterface } from 'utils/api/interface';
import { downloadFileToDisk, downloadFileFromURIToDisk } from 'utils/downloadFiles';
import { objToCsv } from 'utils/objToCsvString';

import {
  RecordTemplateAvailableData,
  RecordsState,
  RecordTemplateDataGroupsJSONApi,
  RecordTemplateFileJSONApi,
  RecordWorkObject,
  RecordTemplateWorkObjectJSONApi,
  RecordTemplateCreate,
  RecordTemplateJSONApi,
  RecordChosenTemplateJSONApi,
  CreateReport,
  RecordChosenReportJSONApi,
  ReportHistory,
  ChosenReportMapData,
  ChosenReportMapDataPoints,
  ReportsJSONApi,
  ReportsHistoryFilter,
} from './interface';
import {
  createOneReport,
  createOneTemplate,
  deleteOneReport,
  deleteOneTemplate,
  getGroupFields,
  getGroups,
  getOneReport,
  getOneTemplate,
  getReports,
  getTemplates,
  getTemplateWorkObject,
  putOneTemplate,
  sendTemplateFile,
  reportGenerateTags,
  reportGeneratePrintedForm,
} from './api';

const initialState: RecordsState = {
  templates: [],
  isLoading: false,
  needUpdate: false,

  currentTemplateId: null,
  createReportData: defaultCreateReportObjects,

  templateWindow: {
    printableTemplateFileId: null,
    isPrintableTemplateFileLoading: false,

    availableTemplateDataGroups: [],
    isAvailableTemplateDataGroupsLoading: false,

    workObjects: {},
    isWorkObjectsLoading: false,
  },

  chosenTemplate: null,
  isChosenTemplateLoading: false,

  chosenReport: null,
  isChosenReportLoading: false,
  isChosenReportMapDataLoaded: false,
  chosenReportAbsenceEntitiesText: null,
  isPrintableTemplateContained: false,

  isReportsHistoryShow: false,
  isReportsHistoryLoading: false,
  reportsHistory: [],
  reportsHistoryTotal: 0,

  isReportInfoShow: false,
  isGenerateReportTagsLoading: false,
  isGenerateReportPrintedFormsLoading: false,
  reportInfoPdfUrl: null,
  isReportInfoPdfURISaving: false,

  error: null,
};

export const fetchTemplates = createAsyncThunk('records/fetchTemplates', async () => await getTemplates());

export const addOneTemplate = createAsyncThunk(
  'records/addOneTemplate',
  async (data: RecordTemplateCreate) => await createOneTemplate(data)
);

export const sendPrintableTemplateFile = createAsyncThunk(
  'records/sendPrintableTemplateFile',
  async ({ key, file }: { key: string; file: File }) => await sendTemplateFile(key, file)
);

export const fetchDataGroups = createAsyncThunk('records/fetchDataGroups', async () => {
  const promises: ParallelItemInterface[] = [];
  try {
    const groupsResults: RecordTemplateDataGroupsJSONApi = await getGroups();
    const { groups } = groupsResults.data.attributes;

    groups.forEach(groupName => {
      promises.push({ promiseMethod: getGroupFields, args: groupName });
    });

    const fieldsResults = await reqHandlers.allSettled(promises);
    const dbDataGroups: RecordTemplateAvailableData[] = [];

    fieldsResults.forEach((fieldResult, i) => {
      if (fieldResult.status === 'fulfilled') {
        const { fields } = (fieldResult.value as unknown as RecordTemplateDataGroupsJSONApi).data.attributes;
        dbDataGroups.push({
          id: i + 1,
          group: groups[i].toLowerCase() ?? '',
          fields,
        });
      }
    });

    return await Promise.resolve(dbDataGroups.filter(data => data.fields.length));
  } catch (error) {
    return await Promise.reject('Error');
  }
});

export const fetchWorkObjectFromType = createAsyncThunk(
  'records/fetchWorkObjectFromType',
  async (types: REPORT_TEMPLATE_CONDITIONS_ADDITIONAL_FILTERS[]) => {
    const promises: ParallelItemInterface[] = [];

    try {
      types.forEach(type => {
        promises.push({ promiseMethod: getTemplateWorkObject, args: type });
      });
      const dataResults = await reqHandlers.allSettled(promises);
      const workObjects: RecordWorkObject = {};

      dataResults.forEach((data, i) => {
        if (data.status === 'fulfilled') {
          const objects = (data.value as unknown as RecordTemplateWorkObjectJSONApi).data.attributes.workObjectNames;
          const name = types[i];

          workObjects[name] = objects;
        }
      });
      return await Promise.resolve(workObjects);
    } catch (error) {
      return await Promise.reject('Error');
    }
  }
);

export const fetchOneTemplate = createAsyncThunk(
  'records/fetchOneTemplate',
  async (id: string) => await getOneTemplate(id)
);

export const removeOneTemplate = createAsyncThunk(
  'records/removeOneTemplate',
  async (id: string) => await deleteOneTemplate(id)
);

export const updateOneTemplate = createAsyncThunk(
  'records/updateOneTemplate',
  async ({ id, data }: { id: string; data: RecordTemplateCreate }) => await putOneTemplate(id, data)
);

export const fetchOneReport = createAsyncThunk(
  'records/fetchOneReport',
  async ({ id, withMapData }: { id: string; withMapData?: boolean }, thunkApi) => {
    const report: RecordChosenReportJSONApi = await getOneReport(id, withMapData);
    const { getState, dispatch } = thunkApi;
    const localeFromState = (getState() as RootState).user.userPreferences.locale;
    const locale = getCurrentLocale(localeFromState);

    if (!report.data.attributes.reportTemplateId && window.location.pathname !== REPORTS_MAP_ROUTE) {
      return thunkApi.rejectWithValue({
        error: {
          code: String(NO_HAS_REPORT_TEMPLATE_ID_ERROR),
          message: '',
        } as ProcessErrorPayloadInterface,
        locale,
      });
    }

    const template: RecordChosenTemplateJSONApi = await getOneTemplate(String(report.data.attributes.reportTemplateId));

    if (!template.data.attributes.printableTemplateId) {
      dispatch(setIsPrintableTemplateContained(false));
    } else {
      dispatch(setIsPrintableTemplateContained(true));
    }

    return report;
  }
);

export const addOneReport = createAsyncThunk(
  'records/addOneReport',
  async ({ templateId, newReport }: { templateId: string; newReport: CreateReport }) =>
    await createOneReport(templateId, newReport)
);

export const fetchReportsHistory = createAsyncThunk(
  'records/fetchReportsHistory',
  async (queryParams: ReportsHistoryFilter, thunkApi) => {
    const { getState } = thunkApi;
    const handbooks = (getState() as RootState).handbooks;
    const transportBrands = handbooks.data?.transportBrands;
    const transportModels = handbooks.data?.transportModels;
    const localeFromState = (getState() as RootState).user.userPreferences.locale;
    const locale = getCurrentLocale(localeFromState);
    const t = messages[locale];

    try {
      const response: ReportsJSONApi = await getReports(queryParams);
      const { reports = {}, totalReports = 0 } = response.data.attributes;
      const reportsList: ReportHistory[] = [];

      type PromisesResult = {
        type: REPORT_TABLE_OBJECT;
        objectsNames: string[];
      };
      type PromisesResultsFromId = {
        [id: string]: PromisesResult[];
      };

      const objectPromisesResults: PromisesResultsFromId = {};

      for (const id of Object.keys(reports)) {
        const report = reports[id];

        for (const object of report.objects) {
          let results: PromiseSettledResult<void>[] = [];

          try {
            switch (object.type) {
              case REPORT_TABLE_OBJECT.User:
                results = await reqHandlers.allSettled(
                  object.entityIds.map(entityId => ({
                    promiseMethod: getUser,
                    args: Number(entityId),
                  })),
                  { withToast: false }
                );
                break;

              case REPORT_TABLE_OBJECT.Employee:
                results = await reqHandlers.allSettled(
                  object.entityIds.map(entityId => ({
                    promiseMethod: getOneEmployee,
                    args: entityId,
                  })),
                  { withToast: false }
                );
                break;

              case REPORT_TABLE_OBJECT.Transport:
                results = await reqHandlers.allSettled(
                  object.entityIds.map(entityId => ({
                    promiseMethod: getOneTransport,
                    args: Number(entityId),
                  })),
                  { withToast: false }
                );
                break;

              case REPORT_TABLE_OBJECT.POIPoint:
                results = await reqHandlers.allSettled(
                  object.entityIds.map(entityId => ({
                    promiseMethod: getOnePoi,
                    args: Number(entityId),
                  })),
                  { withToast: false }
                );
                break;

              case REPORT_TABLE_OBJECT.Tracker:
                results = await reqHandlers.allSettled(
                  object.entityIds.map(entityId => ({
                    promiseMethod: getOneTracker,
                    args: Number(entityId),
                  })),
                  { withToast: false }
                );
                break;

              default:
                break;
            }

            objectPromisesResults[id] = [
              ...(objectPromisesResults[id] ?? []),
              {
                type: object.type,
                objectsNames: results
                  .map(res => {
                    if (res.status === 'fulfilled') {
                      switch (object.type) {
                        case REPORT_TABLE_OBJECT.User: {
                          const value = res.value as unknown as AccountsJsonApiObj;
                          const attr = value.data.attributes;

                          return `${attr?.lastName} ${attr?.firstName} ${attr?.secondName}`;
                        }

                        case REPORT_TABLE_OBJECT.Employee: {
                          const value = res.value as unknown as ChosenEmployeeJSONApi;
                          const attr = value.data.attributes;

                          return `${attr?.lastName} ${attr?.firstName} ${attr?.secondName}`;
                        }

                        case REPORT_TABLE_OBJECT.Transport: {
                          const value = res.value as unknown as TransportJSONApiObj;
                          const attr = value.data.attributes;

                          const foundBrand = transportBrands.find(brand => brand.id === attr.brandId);
                          const foundModel = transportModels.find(model => model.id === attr.transportModelId);

                          if (foundBrand && foundModel) {
                            const brand = foundBrand.attributes.name[locale];
                            const model = foundModel.attributes.name[locale];

                            return `${brand} ${model} ${attr.regNumber}`;
                          }

                          return attr.regNumber;
                        }

                        case REPORT_TABLE_OBJECT.POIPoint: {
                          const value = res.value as unknown as ChosenPoiJSONApi;
                          const attr = value.data.attributes;

                          return `${attr.name}`;
                        }

                        case REPORT_TABLE_OBJECT.Tracker: {
                          const value = res.value as unknown as ChosenTrackerInfo;
                          const attr = value.data.attributes;

                          return `${attr.simNumber}`;
                        }

                        default:
                          return '';
                      }
                    }
                    return '';
                  })
                  .filter(str => !!str),
              },
            ];
          } catch (error) {
            continue;
          }
        }

        reportsList.push({
          ...report,
          id,
          objectsNames:
            Object.keys(objectPromisesResults).length && objectPromisesResults[id]
              ? objectPromisesResults[id].map(object => object.objectsNames.map(name => name)).flat()
              : [],
        });
      }

      return { reports: reportsList, totalReports };
    } catch (error) {
      toast.error(t['toast.records.reports-history.get-list.error.text']);
      return await Promise.reject('Error');
    }
  }
);

export const removeReport = createAsyncThunk('records/removeReport', async (id: string) => await deleteOneReport(id));

export const fetchReportMapData = createAsyncThunk(
  'records/fetchReportMapData',
  async (mapData: ChosenReportMapData[], thunkApi) => {
    const { dispatch } = thunkApi;
    const promisesObj: {
      [key in ChosenReportMapDataPoints]: ParallelItemInterface[];
    } = {
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints]: [],
    };
    const idsData: {
      [key in ChosenReportMapDataPoints]: number[];
    } = {
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers]: [],
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints]: [],
    };

    // установим необходимые промисы для соответствующих типов mapEnrichment
    mapData.forEach(data => {
      const { mapEnrichment, entityIds } = data;

      if (entityIds) {
        switch (mapEnrichment) {
          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences:
            promisesObj[mapEnrichment] = entityIds.map(entityId => ({
              promiseMethod: getOneGeozone,
              args: String(entityId),
            }));
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps:
            //TODO: пока нет необходимости
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers:
            promisesObj[mapEnrichment] = entityIds.map(entityId => ({
              promiseMethod: getLog,
              args: entityId,
            }));
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints:
            promisesObj[mapEnrichment] = entityIds.map(entityId => ({
              promiseMethod: getOnePoi,
              args: entityId,
            }));
            break;

          default:
            break;
        }
        if (mapEnrichment !== REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.movementTracks) {
          idsData[mapEnrichment] = entityIds;
        }
      }
    });

    // получим данные
    const fetchedData: {
      [key in ChosenReportMapDataPoints]: PromiseSettledResult<unknown>[];
    } = {
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences]: await reqHandlers.allSettled(
        promisesObj[REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences]
      ),
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps]: await reqHandlers.allSettled(
        promisesObj[REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps]
      ),
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers]: await reqHandlers.allSettled(
        promisesObj[REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers]
      ),
      [REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints]: await reqHandlers.allSettled(
        promisesObj[REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints]
      ),
    };

    // строка для отображения отсутствующих entities
    let absenceEntitiesText = '';

    // запишем полученные данные в соответствующие места в глобальном сторе
    Object.keys(fetchedData).forEach(key => {
      const values = fetchedData[key as ChosenReportMapDataPoints];

      if (values.length) {
        switch (key) {
          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.geofences:
            {
              const fulfilledData: Geozone[] = [];

              values.forEach((res, index) => {
                if (res.status === 'fulfilled') {
                  const value = res.value as GeozonesJsonApiObj;

                  fulfilledData.push(value.data);
                } else if (res.status === 'rejected') {
                  const currentId = idsData[key as ChosenReportMapDataPoints][index];

                  if (currentId) {
                    absenceEntitiesText += `No geofences data with id = ${currentId};\n`;
                  }
                }
              });
              dispatch(setGeozones(fulfilledData));
              dispatch(selectBatchOfGeozones(fulfilledData.map(data => data.id)));
            }
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.heatmaps:
            //TODO: пока нет необходимости
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.notificationMarkers:
            {
              const fulfilledData: NotificationLog[] = [];

              values.forEach((res, index) => {
                if (res.status === 'fulfilled') {
                  const value = res.value as NotificationLogJSONApi;

                  fulfilledData.push(value.data);
                } else if (res.status === 'rejected') {
                  const currentId = idsData[key as ChosenReportMapDataPoints][index];

                  if (currentId) {
                    absenceEntitiesText += `No notification data with id = ${currentId};\n`;
                  }
                }
              });
              dispatch(setHistoryLogs(fulfilledData));
              dispatch(setSelectedHistoryLogs(fulfilledData.map(data => data.id)));
            }
            break;

          case REPORT_TEMPLATE_CONDITIONS_MAP_SHOWING.poiPoints:
            {
              const fulfilledData: Poi[] = [];

              values.forEach((res, index) => {
                if (res.status === 'fulfilled') {
                  const value = res.value as ChosenPoiJSONApi;

                  fulfilledData.push(value.data);
                } else if (res.status === 'rejected') {
                  const currentId = idsData[key as ChosenReportMapDataPoints][index];

                  if (currentId) {
                    absenceEntitiesText += `No poi data with id = ${currentId};\n`;
                  }
                }
              });
              dispatch(setPoiData(fulfilledData));
              dispatch(
                setSelectedPoi({
                  ids: fulfilledData.map(data => data.id),
                  status: true,
                })
              );
            }
            break;

          default:
            break;
        }
      }
    });

    return absenceEntitiesText;
  }
);

export const generateTags = createAsyncThunk('records/generateTags', async () => await reportGenerateTags());

export const generatePrintedForm = createAsyncThunk(
  'records/generatePrintedForm',
  async ({ reportTemplateId, reportId }: { reportTemplateId: number; reportId: number }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const locale = state.user.userPreferences.locale;

    try {
      const result = await reportGeneratePrintedForm({ reportTemplateId, reportId });

      return { result, locale };
    } catch (error) {
      throw thunkApi.rejectWithValue({ error, locale });
    }
  }
);

export const saveToDiskPrintedForm = createAsyncThunk(
  'records/saveToDiskPrintedForm',
  async (fileName: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const uri = state.records.reportInfoPdfUrl;
    const locale = state.user.userPreferences.locale;

    if (uri) {
      await downloadFileFromURIToDisk(uri, fileName || 'report-printed-form.pdf');
      return { status: true, locale };
    }
    return { status: false, locale };
  }
);

export const saveToDiskChosenReportData = createAsyncThunk(
  'records/saveToDiskChosenReportData',
  async ({ data, fileName }: { data: Record<string, string[]>; fileName: string }) => {
    if (Object.keys(data).length) {
      const csvString = objToCsv(data, ';');

      const blob = await new Response(csvString).blob();

      downloadFileToDisk(blob, fileName);
    }
  }
);

const recordsSlice = createSlice({
  name: 'recordsSlice',
  initialState,
  reducers: {
    setCurrentTemplateId: (state, { payload }: { payload: string | null }) => {
      state.currentTemplateId = payload;
    },
    setCreateReportData: (state, { payload }: { payload: CreateReport }) => {
      state.createReportData = { ...payload };
    },
    setCreateReportDataValue: (state, { payload }: { payload: { key: string; value: unknown } }) => {
      const { key, value } = payload;
      state.createReportData = {
        ...state.createReportData,
        [key]: value,
      };
    },

    clearPrintableTemplateFileId: state => {
      state.templateWindow.printableTemplateFileId = null;
    },
    clearAvailableTemplateDataGroups: state => {
      state.templateWindow.availableTemplateDataGroups = [];
    },
    clearChosenTemplate: state => {
      state.chosenTemplate = null;
    },

    clearChosenReport: state => {
      state.chosenReport = null;
    },
    setIsPrintableTemplateContained: (state, { payload }: { payload: boolean }) => {
      state.isPrintableTemplateContained = payload;
    },

    setIsReportsHistoryShow: (state, { payload }: { payload: boolean }) => {
      state.isReportsHistoryShow = payload;
    },
    clearReportsHistory: state => {
      state.reportsHistory = [];
    },
    setReportsHistory: (state, { payload }: { payload: ReportHistory[] }) => {
      state.reportsHistory = payload;
    },
    addToReportsHistory: (state, { payload }: { payload: ReportHistory[] }) => {
      state.reportsHistory = [...state.reportsHistory, ...payload];
    },
    setReportHistoryTotal: (state, { payload }: { payload: number }) => {
      state.reportsHistoryTotal = payload;
    },

    handleIsReportInfoShow: (state, { payload }: { payload: boolean }) => {
      state.isReportInfoShow = payload;
    },
    clearReportInfoPdfUrl: state => {
      const url = state.reportInfoPdfUrl;

      if (url) {
        URL.revokeObjectURL(url);
        state.reportInfoPdfUrl = null;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTemplates.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.isLoading = true;
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload }: { payload: RecordTemplateJSONApi }) => {
        const reportTemplates = payload.data.attributes.reportTemplates;
        const templates = Object.keys(reportTemplates).map(key => ({
          id: key,
          name: reportTemplates[key as keyof typeof reportTemplates],
        }));

        state.templates = templates;
        state.isLoading = false;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isLoading = false;
      });
    builder
      .addCase(addOneTemplate.pending, state => {
        state.error = null;
      })
      .addCase(addOneTemplate.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(addOneTemplate.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(sendPrintableTemplateFile.pending, state => {
        state.error = null;
        state.templateWindow.isPrintableTemplateFileLoading = true;
      })
      .addCase(sendPrintableTemplateFile.fulfilled, (state, { payload }: { payload: RecordTemplateFileJSONApi }) => {
        state.templateWindow.printableTemplateFileId = payload.data.attributes.printableTemplateId;
        state.templateWindow.isPrintableTemplateFileLoading = false;
      })
      .addCase(sendPrintableTemplateFile.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.templateWindow.isPrintableTemplateFileLoading = false;
      });
    builder
      .addCase(fetchDataGroups.pending, state => {
        state.error = null;
        state.templateWindow.isAvailableTemplateDataGroupsLoading = true;
      })
      .addCase(fetchDataGroups.fulfilled, (state, { payload }: { payload: RecordTemplateAvailableData[] }) => {
        state.templateWindow.availableTemplateDataGroups = payload;
        state.templateWindow.isAvailableTemplateDataGroupsLoading = false;
      })
      .addCase(fetchDataGroups.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.templateWindow.isAvailableTemplateDataGroupsLoading = false;
      });
    builder
      .addCase(fetchWorkObjectFromType.pending, state => {
        state.error = null;
        state.templateWindow.isWorkObjectsLoading = true;
      })
      .addCase(fetchWorkObjectFromType.fulfilled, (state, { payload }: { payload: RecordWorkObject }) => {
        state.templateWindow.workObjects = payload;
        state.templateWindow.isWorkObjectsLoading = false;
      })
      .addCase(fetchWorkObjectFromType.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.templateWindow.isWorkObjectsLoading = false;
      });
    builder
      .addCase(fetchOneTemplate.pending, state => {
        state.error = null;
        state.isChosenTemplateLoading = true;
      })
      .addCase(fetchOneTemplate.fulfilled, (state, { payload }: { payload: RecordChosenTemplateJSONApi }) => {
        state.chosenTemplate = payload.data;
        state.isChosenTemplateLoading = false;
      })
      .addCase(fetchOneTemplate.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenTemplateLoading = false;
      });
    builder
      .addCase(removeOneTemplate.pending, state => {
        state.error = null;
      })
      .addCase(removeOneTemplate.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(removeOneTemplate.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(updateOneTemplate.pending, state => {
        state.error = null;
      })
      .addCase(updateOneTemplate.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(updateOneTemplate.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchOneReport.pending, state => {
        state.error = null;
        state.isChosenReportLoading = true;
      })
      .addCase(fetchOneReport.fulfilled, (state, { payload }: { payload: RecordChosenReportJSONApi }) => {
        let fetchedData = payload.data;
        let attributesData = fetchedData.attributes.data;

        try {
          if (attributesData && typeof attributesData === 'string') {
            attributesData = JSON.parse(attributesData as string);
            fetchedData = {
              ...fetchedData,
              attributes: {
                ...fetchedData.attributes,
                data: attributesData,
              },
            };
          }
        } catch (error) {
          console.error(error);
        }

        state.chosenReport = fetchedData;
        state.isChosenReportLoading = false;
      })
      .addCase(fetchOneReport.rejected, (state, { payload }) => {
        type PayloadErrorType = { error: ProcessErrorPayloadInterface; locale: LanguagesKeysType };

        const locale = (payload as PayloadErrorType).locale;
        const error = (payload as PayloadErrorType).error;

        const t = messages[locale];

        switch (Number(error?.code)) {
          case NO_HAS_REPORT_TEMPLATE_ID_ERROR:
            toast.warn(t['toast.records.get-report.status.no-report-has-template-id.error.text']);
            break;

          default:
            break;
        }

        state.error = error.message ?? 'Error';
        state.isChosenReportLoading = false;
      });
    builder
      .addCase(addOneReport.pending, state => {
        state.error = null;
      })
      .addCase(addOneReport.fulfilled, state => {
        state.needUpdate = true;
      })
      .addCase(addOneReport.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchReportsHistory.pending, state => {
        state.error = null;
        state.isReportsHistoryLoading = true;
      })
      .addCase(
        fetchReportsHistory.fulfilled,
        (state, { payload }: { payload: { reports: ReportHistory[]; totalReports: number } }) => {
          state.reportsHistory = [...state.reportsHistory, ...payload.reports];
          state.reportsHistoryTotal = payload.totalReports;
          state.isReportsHistoryLoading = false;
        }
      )
      .addCase(fetchReportsHistory.rejected, (state, action) => {
        state.isReportsHistoryLoading = false;
        state.reportsHistory = [];
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(fetchReportMapData.pending, state => {
        state.isChosenReportMapDataLoaded = false;
      })
      .addCase(fetchReportMapData.fulfilled, (state, { payload }: { payload: string }) => {
        state.chosenReportAbsenceEntitiesText = payload || null;
        state.isChosenReportMapDataLoaded = true;
      })
      .addCase(fetchReportMapData.rejected, (state, action) => {
        const errorMessage = action.error.message ?? 'Error';

        state.isChosenReportMapDataLoaded = false;
        state.error = errorMessage;
        resultBeacon(ReportRenderFinishType.error, errorMessage);
      });
    builder
      .addCase(generateTags.pending, state => {
        state.isGenerateReportTagsLoading = true;
      })
      .addCase(generateTags.fulfilled, (state, { payload }: { payload: GetFileInfo }) => {
        const additionalFields = payload.successResponseFields;

        if (additionalFields.type.match(/(?:office)|(?:word)/i)) {
          downloadFileToDisk(payload.data, additionalFields.disposition || `tags.docx`);
        }
        state.isGenerateReportTagsLoading = false;
      })
      .addCase(generateTags.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isGenerateReportTagsLoading = false;
      });
    builder
      .addCase(generatePrintedForm.pending, state => {
        state.isGenerateReportPrintedFormsLoading = true;
      })
      .addCase(
        generatePrintedForm.fulfilled,
        (state, { payload }: { payload: { result: GetFileInfo; locale: LanguagesKeysType } }) => {
          const { result, locale } = payload;
          const { status, type } = result.successResponseFields;
          const t = messages[locale];

          switch (Number(status)) {
            case GENERATED_PRINTED_FORM_RESPONSE_STATUSES.IS_IN_COMPILATION:
              toast.warn(t['toast.records.generated-printed-form.status.202.text']);
              break;

            default:
              break;
          }
          if (type.match('application/pdf')) {
            state.reportInfoPdfUrl = URL.createObjectURL(payload.result.data);
          }
          state.isGenerateReportPrintedFormsLoading = false;
        }
      )
      .addCase(generatePrintedForm.rejected, (state, { payload }) => {
        type PayloadErrorType = { error: ProcessErrorPayloadInterface; locale: LanguagesKeysType };

        const locale = (payload as PayloadErrorType).locale;
        let error = (payload as PayloadErrorType).error;

        if (Array.isArray(error)) {
          error = error[0];
        }
        if (error.code && locale) {
          const t = messages[locale];

          switch (Number(error.code)) {
            case GENERATED_PRINTED_FORM_RESPONSE_STATUSES.NOT_FOUND:
              toast.error(t['toast.records.generated-printed-form.status.404.text']);
              break;

            case GENERATED_PRINTED_FORM_RESPONSE_STATUSES.REPORT_HAS_ERROR:
              toast.error(t['toast.records.generated-printed-form.status.406.text']);
              break;

            default:
              toast.error(t['toast.records.generated-printed-form.status.500.text']);
              break;
          }
        }
        state.error = error?.message ?? 'Error';
        state.isGenerateReportPrintedFormsLoading = false;
      });
    builder
      .addCase(saveToDiskPrintedForm.pending, state => {
        state.isReportInfoPdfURISaving = true;
      })
      .addCase(
        saveToDiskPrintedForm.fulfilled,
        (state, { payload }: { payload: { status: boolean; locale: LanguagesKeysType } }) => {
          const t = messages[payload.locale];

          if (!payload.status) {
            toast.error(t['toast.records.generated-printed-form.na-file-name.text']);
          }
          state.isReportInfoPdfURISaving = false;
        }
      )
      .addCase(saveToDiskPrintedForm.rejected, (state, action) => {
        state.isReportInfoPdfURISaving = false;
        state.error = action.error?.message ?? 'Error';
      });
  },
});

export const {
  setCurrentTemplateId,
  setCreateReportData,
  setCreateReportDataValue,

  clearPrintableTemplateFileId,
  clearAvailableTemplateDataGroups,
  clearChosenTemplate,

  clearChosenReport,
  setIsPrintableTemplateContained,

  setIsReportsHistoryShow,
  setReportsHistory,
  clearReportsHistory,
  addToReportsHistory,
  setReportHistoryTotal,

  handleIsReportInfoShow,
  clearReportInfoPdfUrl,
} = recordsSlice.actions;

export default recordsSlice.reducer;
