import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { SortType } from 'utils/interfaces';
import { getClientUserPreferences } from './api';
import { ClientUserPreferencesState } from './interface';
import { ZOOM_DEFAULT_VALUE } from '../../utils/consts';

export const sliceName = 'clientUserPreferencesSlice';

const initialState: ClientUserPreferencesState = {
  usersListGroupingConfig: [
    { groupBy: 'organization', offset: 0, isActive: false },
    { groupBy: 'department', offset: 0, isActive: false },
    { groupBy: 'position', offset: 0, isActive: false },
  ],
  usersListVisibleColumnsConfig: [
    { column: 'fullName', isVisible: true },
    { column: 'login', isVisible: true },
    { column: 'organization', isVisible: true },
    { column: 'department', isVisible: true },
    { column: 'position', isVisible: true },
    { column: 'roles', isVisible: true },
  ],
  usersListExpandedGroupsState: {},
  usersListFilterConfig: {
    organizationsFilter: [],
    departmentsFilter: [],
    positionsFilter: [],
  },
  usersListSortConfig: {
    sortBy: 'fullName',
    sortType: SortType.ASC,
  },
  usersMapInfo: {
    lastPosition: [0, 0],
    zoom: ZOOM_DEFAULT_VALUE,
  },
};

export const fetchClientUserPreferences = createAsyncThunk(
  `${sliceName}/fetchClientUserPreferences`,
  async () => await getClientUserPreferences()
);

const clientUserPreferencesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // partially update state
    updateClientUserPreferences: (state, action: PayloadAction<Partial<ClientUserPreferencesState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchClientUserPreferences.fulfilled, (state, action) => {
      const { clientMeta } = action.payload;

      if (clientMeta) {
        try {
          const preferences = JSON.parse(clientMeta);

          return {
            ...state,
            ...preferences,
          };
        } catch (e) {}
      }
    });
  },
});

export const { updateClientUserPreferences } = clientUserPreferencesSlice.actions;

export default clientUserPreferencesSlice.reducer;
