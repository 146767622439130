export default {
  en: {
    'map-popup.notification.title.text': 'Notification:',
    'map-popup.notification.date.text': 'Date and time:',
    'map-popup.notification.type.text': 'Type:',
    'map-popup.notification.place.text': 'Place:',
    'map-popup.notification.notification-text.text': 'Notification text:',
    'map-popup.notification.delete-on-map.text': 'Remove from map',
    'map-popup.notification.show-on-map.text': 'View on map',
    'map-popup.notification.btn.close.text': 'Close',
    'map-popup.notification.btn.read.text': 'Read',

    'map-popup.notification.camera.name.text': 'Video camera name',
    'map-popup.notification.camera.people.text': 'List of employees near the camera',
  },
  ru: {
    'map-popup.notification.title.text': 'Уведомление:',
    'map-popup.notification.date.text': 'Дата и время:',
    'map-popup.notification.type.text': 'Тип:',
    'map-popup.notification.place.text': 'Место:',
    'map-popup.notification.notification-text.text': 'Текст уведомления:',
    'map-popup.notification.delete-on-map.text': 'Убрать с карты',
    'map-popup.notification.show-on-map.text': 'Посмотреть на карте',
    'map-popup.notification.btn.close.text': 'Закрыть',
    'map-popup.notification.btn.read.text': 'Прочитано',

    'map-popup.notification.camera.name.text': 'Название камеры',
    'map-popup.notification.camera.people.text': 'Список сотрудников рядом с камерой',
  },
  zh: {
    'map-popup.notification.title.text': '笔记：',
    'map-popup.notification.date.text': '日期和时间：',
    'map-popup.notification.type.text': '意见：',
    'map-popup.notification.place.text': '地方：',
    'map-popup.notification.notification-text.text': '通知文本：',
    'map-popup.notification.delete-on-map.text': '从卡中删除',
    'map-popup.notification.show-on-map.text': '在地图上查看',
    'map-popup.notification.btn.close.text': '故障',
    'map-popup.notification.btn.read.text': '阅读',

    'map-popup.notification.camera.name.text': '照相机名称',
    'map-popup.notification.camera.people.text': '照相机旁边工作人清单',
  },
  ar: {
    'map-popup.notification.title.text': 'إشعار:',
    'map-popup.notification.date.text': 'التاريخ والوقت:',
    'map-popup.notification.type.text': 'النوع:',
    'map-popup.notification.place.text': 'المكان:',
    'map-popup.notification.notification-text.text': 'نص الإشعار:',
    'map-popup.notification.delete-on-map.text': 'إزالة من الخريطة',
    'map-popup.notification.show-on-map.text': 'إظهار على الخريطة',
    'map-popup.notification.btn.close.text': 'إغلاق',
    'map-popup.notification.btn.read.text': 'تمت القراءة',

    'map-popup.notification.camera.name.text': 'اسم الكاميرا',
    'map-popup.notification.camera.people.text': 'قائمة العاملين بجانب الكاميرا',
  },
  de: {
    'map-popup.notification.title.text': 'Benachrichtigung:',
    'map-popup.notification.date.text': 'Datum (und Uhrzeit:',
    'map-popup.notification.type.text': 'Eine Art:',
    'map-popup.notification.place.text': 'Ein Ort:',
    'map-popup.notification.notification-text.text': 'Benachrichtigungstext:',
    'map-popup.notification.delete-on-map.text': 'Von der Karte entfernen',
    'map-popup.notification.show-on-map.text': 'Auf der Karte ansehen',
    'map-popup.notification.btn.close.text': 'Schließen',
    'map-popup.notification.btn.read.text': 'Lesen',

    'map-popup.notification.camera.name.text': 'Kameraname',
    'map-popup.notification.camera.people.text': 'Liste der Mitarbeiter neben der Kamera',
  },
};
