// добавляет необходимое кол-во (numZeros) нулей для числа (num)
export function zeroPad(num: number, numZeros: number) {
  const n = Math.abs(num);
  const zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  let zeroString = Math.pow(10, zeros).toString().substr(1);
  if (num < 0) {
    zeroString = '-' + zeroString;
  }

  return zeroString + n;
}
