import { SortType } from 'utils/interfaces';

/**
 * Converts ours sortType to lodash's sortType
 * @param sortType SortType enum
 * @returns 'asc' | 'desc'
 */
export function getLodashSortType(sortType: SortType): 'asc' | 'desc' {
  return sortType === SortType.ASC ? 'asc' : 'desc';
}
