/**
 * Adjust position of react-rnd element to fit app content container bounds
 * @param x current x coord of left top vertex
 * @param y current y coord of left top vertex
 * @param height height of element
 * @param width width of element
 * @param appHeaderHeight height of app header
 * @param appFooterHeight height of app footer
 * @returns x & y of new top left vertex
 */
export function getInboundRndPosition(
  x: number,
  y: number,
  height: number,
  width: number,
  appHeaderHeight: number,
  appFooterHeight: number
): { x: number; y: number } {
  const result = { x, y };

  if (typeof window === 'object') {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const elementRightX = x + width;
    const elementBottomY = y + height;

    const appFooterTopY = windowHeight - appFooterHeight;

    if (elementBottomY > appFooterTopY) {
      result.y -= elementBottomY - appFooterTopY;
    }

    if (result.y < appHeaderHeight) {
      result.y = appHeaderHeight;
    }

    if (elementRightX > windowWidth) {
      result.x = windowWidth - width;
    }

    if (result.x < 0) {
      result.x = 0;
    }
  }

  result.y -= appHeaderHeight;

  return {
    x: result.x,
    y: result.y,
  };
}
