import commonInfo from './commonInfo';
import monitoring from './monitoring';
import geozones from './geozones';
import notifications from './notifications';
import trackers from './trackers';
import accounts from './accounts';
import records from './records';
import tracks from './tracks';
import poi from './poi';
import handbooks from './handbooks';
import messages from './messages';

export default {
  en: {
    ...commonInfo.en,
    ...monitoring.en,
    ...geozones.en,
    ...notifications.en,
    ...trackers.en,
    ...accounts.en,
    ...records.en,
    ...tracks.en,
    ...poi.en,
    ...handbooks.en,
    ...messages.en,
  },
  ru: {
    ...commonInfo.ru,
    ...monitoring.ru,
    ...geozones.ru,
    ...notifications.ru,
    ...trackers.ru,
    ...accounts.ru,
    ...records.ru,
    ...tracks.ru,
    ...poi.ru,
    ...handbooks.ru,
    ...messages.ru,
  },
  zh: {
    ...commonInfo.zh,
    ...monitoring.zh,
    ...geozones.zh,
    ...notifications.zh,
    ...trackers.zh,
    ...accounts.zh,
    ...records.zh,
    ...tracks.zh,
    ...poi.zh,
    ...handbooks.zh,
    ...messages.zh,
  },
  ar: {
    ...commonInfo.ar,
    ...monitoring.ar,
    ...geozones.ar,
    ...notifications.ar,
    ...trackers.ar,
    ...accounts.ar,
    ...records.ar,
    ...tracks.ar,
    ...poi.ar,
    ...handbooks.ar,
    ...messages.ar,
  },
  de: {
    ...commonInfo.de,
    ...monitoring.de,
    ...geozones.de,
    ...notifications.de,
    ...trackers.de,
    ...accounts.de,
    ...records.de,
    ...tracks.de,
    ...poi.de,
    ...handbooks.de,
    ...messages.de,
  },
};
