import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationSortingType } from 'reducers/notifications/interface';
import { RootState } from 'reducers';
import styles from './buttonIconSorting.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type ButtonIconSortingProps = {
  typeSorting: NotificationSortingType;
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  customArrowStyle?: string;
};

export function ButtonIconSorting({ typeSorting, onClick, children, customArrowStyle }: ButtonIconSortingProps) {
  const { type: typeState, ascending } = useSelector((state: RootState) => state.notifications.sort);

  return (
    <div className={styles.buttonIcon} onClick={onClick}>
      {children && <div className={styles.buttonIconChildren}>{children}</div>}
      {typeSorting === typeState && (
        <div
          className={cx(styles.buttonIconArrow, customArrowStyle, {
            [styles.ascending]: ascending,
            [styles.descending]: !ascending,
          })}
        />
      )}
    </div>
  );
}
