import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { getDateFromStr } from 'components/handbooks/utils/helpers';
import { HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE, HANDBOOK_NAMES } from 'components/handbooks/utils/consts';

import { RootState } from 'reducers';

import styles from './tableModal.module.scss';

const cx = classNames.bind(styles);

export const TableModal = () => {
  const t = useFormatMessage();

  const { statistic } = useSelector((state: RootState) => state.handbooks);

  const tableData = useMemo(
    () =>
      statistic.map(({ attributes: stat }) => {
        const [date, time] = getDateFromStr(stat.updatedAt).split(' ');
        let text = '';

        switch (stat.eventType) {
          case HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE.create:
            text = `
              ${stat.handbookType ? t(HANDBOOK_NAMES[stat.handbookType as keyof typeof HANDBOOK_NAMES]) : ''}: ${t(
              'handbooks.statistic-modal.table.row.create.text'
            )} "${stat.handbookName}"
            `;
            break;

          case HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE.update:
            text = `
              ${stat.handbookType ? t(HANDBOOK_NAMES[stat.handbookType as keyof typeof HANDBOOK_NAMES]) : ''}: ${t(
              'handbooks.statistic-modal.table.row.update.text'
            )} "${stat.handbookName}"
            `;
            break;

          case HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE.delete:
            text = `
              ${stat.handbookType ? t(HANDBOOK_NAMES[stat.handbookType as keyof typeof HANDBOOK_NAMES]) : ''}: ${t(
              'handbooks.statistic-modal.table.row.delete.text'
            )} "${stat.handbookName}"
            `;
            break;

          case HANDBOOK_STATISTIC_ATTRIBUTES_EVENT_TYPE.import:
            text = `
                ${stat.handbookType ? t(HANDBOOK_NAMES[stat.handbookType as keyof typeof HANDBOOK_NAMES]) : ''}: ${t(
              'handbooks.statistic-modal.table.row.import-1.text'
            )} ${stat.count} ${t('handbooks.statistic-modal.table.row.import-2.text')}
              `;
            break;

          //TODO: добавить case для удаления после реализации на бекенде

          default:
            break;
        }

        return {
          date,
          time,
          user: stat.userName,
          login: stat.userLogin,
          text,
        };
      }),
    [t, statistic]
  );

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={cx(styles.tableHeader, styles.tableHeaderDate)}>
            {t('handbooks.statistic-modal.table.header.date.label')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderTime)}>
            {t('handbooks.statistic-modal.table.header.time.label')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderUser)}>
            {t('handbooks.statistic-modal.table.header.user.label')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderLogin)}>
            {t('handbooks.statistic-modal.table.header.username.label')}
          </th>
          <th className={cx(styles.tableHeader, styles.tableHeaderText)}>
            {t('handbooks.statistic-modal.table.header.message.label')}
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((data, index) => {
          return (
            <tr key={`notification-logs-${index}`}>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{data.date}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{data.time}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{data.user}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{data.login}</div>
              </td>
              <td className={styles.tableBodyCell}>
                <div className={styles.tableContent}>{data.text}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
