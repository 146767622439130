import React from 'react';
import styles from './expandButton.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  isOpen: boolean;
  onClick: () => void;
  style?: string;
};

export const ExpandButton = ({ isOpen, onClick, style = '' }: Props) => {
  const buttonStyle = cx(styles.container, { [style]: !!style });
  return (
    <div className={buttonStyle} onClick={onClick}>
      {isOpen ? '-' : '+'}
    </div>
  );
};
