import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { Checkbox } from 'components/common/checkbox/checkbox';
import { ButtonIconSorting } from './buttonIconSorting/buttonIconSorting';

import { setSort } from 'reducers/geozones';
import { SortingType } from 'reducers/geozones/interface';

import { Sorting } from 'utils/consts';

import styles from './geozonesListHeader.module.scss';

const cx = classNames.bind(styles);

type Props = {
  handleCheck: () => void;
  isIndeterminate: boolean;
  headCheckboxStatus: boolean;
};

export function GeozonesListHeader({ handleCheck, isIndeterminate, headCheckboxStatus }: Props) {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const handleSort = (type: SortingType) => {
    dispatch(setSort(type));
  };

  const { name } = Sorting;

  return (
    <Fragment>
      <div className={cx(styles.unitWrapper, styles.unitListHeader)}>
        <div className={styles.unitInfo}>
          <Checkbox handleCheck={handleCheck} checked={headCheckboxStatus} isIndeterminate={isIndeterminate} />
          <div className={styles.nameHead}>
            <ButtonIconSorting typeSorting={name} onClick={() => handleSort(name)}>
              <div>{t('geozones.geozone-list.header.name.text')}</div>
            </ButtonIconSorting>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
