import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { fetchPoi, setPoiCardType, setPoiCardData, setShowingPoiId } from 'reducers/poi';

import PoiCard from './components/poiCard/poiCard';
import PoiList from './components/poiList/poiList';
import PoiHeader from './components/poiHeader/poiHeader';

import styles from './poi.module.scss';

const Poi: React.FC = () => {
  const dispatch = useDispatch();

  const { needUpdate, poiCardType } = useSelector((state: RootState) => state.poi);

  useEffect(() => {
    dispatch(fetchPoi());
  }, [dispatch]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(fetchPoi());
    }
    return () => {
      dispatch(setPoiCardType(null));
    };
  }, [dispatch, needUpdate]);

  useEffect(() => {
    return () => {
      if (poiCardType) {
        dispatch(setPoiCardData(null));
        dispatch(setShowingPoiId(null));
      }
    };
  }, [dispatch, poiCardType]);

  if (poiCardType) {
    return <PoiCard poiType={poiCardType} onCancel={() => dispatch(setPoiCardType(null))} />;
  }

  return (
    <div className={styles.content}>
      <PoiHeader />
      <PoiList />
    </div>
  );
};

export default Poi;
