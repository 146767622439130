import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import DefaultMarker from 'assets/img/geozones/map/default-marker.svg';
import GeozoneMarkerND from 'assets/img/geozones/map/nd.svg';
import { ReactComponent as DropArrow } from 'assets/img/button/drop-arrow.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';

import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { GeozoneTypeOf, GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES, MODULE_ND_URL } from 'components/geozones/utils/consts';
import { geoJSON } from 'components/map/utils';

import { RootState } from 'reducers';
import {
  saveSelected,
  setChosenGeozone,
  setGeozoneCreationIsShow,
  setPopupGeozoneParams,
  unselectAll,
} from 'reducers/geozones';
import { setDrawColor, setDrawLabelColor, setDrawType } from 'reducers/map';

import { APP_CONTENT_ID, HIDE_TEXT, MORE_TEXT } from 'utils/consts';
import { getFullDateFromStr } from 'utils/getFullDateFromStr';

import styles from './geozonePopup.module.scss';

const cx = classNames.bind(styles);

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number } };
}

const POPUP_INTIAL_WIDTH = 410;
const POPUP_INTIAL_FOOTER_HEIGHT = 175;

function GeozonePopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [showAdditional, setShowAdditional] = useState(false);

  const {
    geozones,
    popupGeozoneParams,
    geozonesGeometricTypes: drawTypes,
  } = useSelector((state: RootState) => state.geozone);
  const currentGeozone = geozones.find(geozone => geozone.id === popupGeozoneParams?.id);

  useEffect(() => {
    return () => {
      dispatch(setPopupGeozoneParams(null));
    };
  }, [dispatch]);

  const openGeozoneCreation = () => {
    dispatch(saveSelected());
    dispatch(unselectAll());
    dispatch(setGeozoneCreationIsShow(true));
  };

  const openEditModal = () => {
    if (currentGeozone && currentGeozone.attributes.geoJson) {
      let geoFeatureProps;
      try {
        const parsedGeoJSON = JSON.parse(currentGeozone.attributes.geoJson);
        if (parsedGeoJSON.type === 'FeatureCollection') {
          geoFeatureProps = geoJSON
            .readFeatures(JSON.parse(currentGeozone.attributes.geoJson))
            .map(prop => prop.getProperties());
          if (geoFeatureProps.length) {
            dispatch(setDrawColor(geoFeatureProps[0].drawColor));
            dispatch(setDrawLabelColor(geoFeatureProps[0].drawLabelColor));
          }
        } else {
          geoFeatureProps = geoJSON.readFeature(parsedGeoJSON).getProperties();
          dispatch(setDrawColor(geoFeatureProps.drawColor));
          dispatch(setDrawLabelColor(geoFeatureProps.drawLabelColor));
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (currentGeozone) {
      const draw = drawTypes ? drawTypes[currentGeozone?.attributes.geometricType] : null;

      if (draw) {
        dispatch(setDrawType(draw.olName));
      }
      dispatch(setChosenGeozone(currentGeozone.id));
    }
    onCancel();
    openGeozoneCreation();
  };

  const toggleAdditional = () => {
    setShowAdditional(!showAdditional);
  };

  if (!currentGeozone) {
    return null;
  }

  // хедер попапа
  const headerPopup = (
    <div className={styles.popupHeader}>
      <div className={styles.popupHeaderIcon}>
        <img
          className={styles.popupHeaderIcon}
          src={currentGeozone?.attributes.type === GeozoneTypeOf.nd ? GeozoneMarkerND : DefaultMarker}
          alt="Geozone-popup-icon"
        />
      </div>
      {currentGeozone?.attributes.type === GeozoneTypeOf.nd
        ? t('geozones.map-popup.nd.title.text')
        : t('geozones.map-popup.title.text')}
    </div>
  );

  // Primary
  const description = currentGeozone.attributes.description.split('\n').map((str: string, ind: number) => {
    if (ind === 0) {
      return str;
    }
    return <p key={ind}>{str}</p>;
  });

  const contentPrimaryPopup = (
    <div className={styles.popupMain}>
      <div className={styles.popupSide}>
        <div className={styles.popupSideIcon}>
          <img
            className={styles.popupSideIconImage}
            src={currentGeozone?.attributes.icon || DefaultMarker}
            alt="Geozone"
          />
        </div>
      </div>
      <div className={styles.popupContent}>
        <div className={styles.popupContentText}>
          <div className={styles.popupContentRow}>
            {t('geozones.map-popup.name.text')}
            {': '}
            {currentGeozone.attributes.name}
          </div>
          <div className={styles.popupContentRow}>
            {t('geozones.map-popup.geozone-type.text')}
            {': '}
            {t(GEOZONE_GEOMETRIC_TYPES_TO_TRANSLATES[currentGeozone.attributes.geometricType])}
          </div>
          <div className={styles.popupContentRow}>
            {t('geozones.map-popup.coords.text')}
            {': '}
            {popupGeozoneParams?.centerPoint.join(', ')}
          </div>
          <div className={styles.popupContentRow}>
            {t('geozones.map-popup.description.text')}
            {': '}
            {description}
          </div>
        </div>
      </div>
    </div>
  );

  // ND

  const ndDates = {
    start: getFullDateFromStr(currentGeozone?.attributes.ndData?.workStartDate ?? ''),
    end: getFullDateFromStr(currentGeozone?.attributes.ndData?.workEndDate ?? ''),
  };

  const contentNDPopup = (
    <div className={styles.popupMain}>
      <div className={styles.popupSide}>
        <div className={cx(styles.popupSideIcon, styles.popupSideIconMargin)}>
          <img
            className={styles.popupSideIconImage}
            src={currentGeozone?.attributes.icon || DefaultMarker}
            alt="Geozone"
          />
        </div>
        <a
          href={MODULE_ND_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={cx(styles.popupSideButton, styles.popupSideButtonText)}
        >
          {t('geozones.map-popup.nd.content.btn.module-nd.text')}
        </a>
        <div className={styles.popupSideButton} onClick={toggleAdditional}>
          <span className={styles.popupSideButtonText}>{showAdditional ? t(HIDE_TEXT) : t(MORE_TEXT)}</span>
          <DropArrow className={showAdditional ? styles.popupSideButtonIconUp : styles.popupSideButtonIconDown} />
        </div>
      </div>
      {currentGeozone?.attributes.ndData && (
        <div className={styles.popupContent}>
          <div className={styles.popupContent}>
            <div className={styles.popupContentText}>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.number.text')}
                {': '}
                {currentGeozone?.attributes.ndData.numberND}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.work-type.text')}
                {': '}
                {currentGeozone?.attributes.ndData.workType}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.work-place.text')}
                {': '}
                {currentGeozone?.attributes.ndData.workPlace}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.work-area.text')}
                {': '}
                {currentGeozone?.attributes.ndData.workArea}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.scope-of-work.text')}
                {': '}
                {currentGeozone?.attributes.ndData.scopeOfWork}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.status.text')}
                {': '}
                {currentGeozone?.attributes.ndData.statusND}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.work-date-start.text')}
                {': '}
                {ndDates.start.date}
              </div>
              <div className={styles.popupContentRow}>
                {t('geozones.map-popup.nd.content.work-date-end.text')}
                {': '}
                {ndDates.end.date}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const footerNDPopup = showAdditional ? (
    <div
      className={cx(styles.popupAdditional, {
        [styles.popupAdditionalShow]: showAdditional,
        [styles.popupAdditionalHide]: !showAdditional,
      })}
    >
      <div className={styles.popupAdditionalWrap}>
        <div className={styles.popupAdditionalGroup}>
          <div className={styles.popupAdditionalLabel}>
            {t('geozones.map-popup.nd.content.responsible-persons.text')}
          </div>
          {currentGeozone?.attributes.ndData?.persons.responsible.map((person, i) => (
            <div key={`responsible-person-${i}`} className={styles.popupAdditionalValue}>
              <span>
                {person.position}
                {': '}
              </span>
              <span className={styles.popupAdditionalName}>{person.name}</span>
            </div>
          ))}
        </div>
        <div className={styles.popupAdditionalGroup}>
          <div className={styles.popupAdditionalLabel}>{t('geozones.map-popup.nd.content.brigade-members.text')}</div>
          {currentGeozone?.attributes.ndData?.persons.brigade.map((member, i) => (
            <div key={`brigade-member-${i}`} className={styles.popupAdditionalValue}>
              <span>
                {member.position}
                {': '}
              </span>
              <span className={styles.popupAdditionalName}>{member.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;

  const btnAdditional =
    currentGeozone.attributes.type === GeozoneTypeOf.nd
      ? []
      : [
          {
            name: 'openEmployeeParams',
            button: <OpenedEye onClick={openEditModal} className={styles.popupHeaderOpenModal} />,
          },
        ];

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      additionalHeaderButtons={btnAdditional}
      content={currentGeozone.attributes.type === GeozoneTypeOf.nd ? contentNDPopup : contentPrimaryPopup}
      footer={currentGeozone.attributes.type === GeozoneTypeOf.nd ? footerNDPopup : null}
      initialWidth={POPUP_INTIAL_WIDTH}
      showFooter={showAdditional}
      initialFooterHeight={POPUP_INTIAL_FOOTER_HEIGHT}
      initialPosition={markerCoords.mouseCoords}
      resizeAxis="both"
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(GeozonePopup);
