import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import MultipleSelect, { getValueForMultipleSelect } from 'components/common/multipleSelect/multipleSelect';
import { TextArea } from 'components/common/textArea/textArea';
import {
  MessagesCreateHandlers,
  MessagesCreateStates,
  MessagesCreateAdditionalStates,
} from 'components/messages/utils/types';

import styles from './contentModal.module.scss';

const cx = classNames.bind(styles);

type Props = {
  states: MessagesCreateStates;
  handlers: MessagesCreateHandlers;
  additionalStates: MessagesCreateAdditionalStates;
  invalidFields: string[];
};

export const ContentModal = ({ states, handlers, additionalStates, invalidFields }: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.wrapper}>
      <MultipleSelect
        options={additionalStates.trackableUnitsForSelect}
        label={t('messages.create-modal.content.object.label')}
        placeholder={t('messages.create-modal.content.object.placeholder')}
        isRequired={invalidFields.includes('messageTrackableUnits')}
        withSearchIcon
        wrapperStyle={styles.row}
        handleChangeMultiple={handlers.handleChangeObjects}
        values={states.objects.map((id, i) =>
          getValueForMultipleSelect({
            i,
            id,
            selectValues: additionalStates.trackableUnitsForSelect,
          })
        )}
      />
      <TextArea
        label={t('messages.create-modal.content.text.label')}
        placeholder={t('messages.create-modal.content.text.placeholder')}
        isRequired={invalidFields.includes('messageText')}
        value={states.text}
        containerStyle={cx(styles.row, styles.rowTextarea)}
        handleChange={handlers.handleChangeText}
        autoSetHeight
      />
    </div>
  );
};
