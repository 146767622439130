import {
  ru as datepickerLangRU,
  enUS as datepickerLangEN,
  zhCN as datepickerLangCN,
  arDZ as datepickerLangAR,
  de as datepickerLangDE,
} from 'date-fns/locale';
import { default as tabs } from './tabs';
import { default as components } from './components';
import { default as popups } from './map_popups';
import { default as toasts } from './toasts';
import { default as common } from './common';

type MessagesType = {
  [key in LanguagesKeysType]: {
    [key: string]: string;
  };
};

export enum LANGUAGES_TO_TEXT_ENUM {
  ru = 'ru',
  en = 'en',
  zh = 'zh',
  ar = 'ar',
  de = 'de',
}

export enum LANGUAGES_TO_TEXT_TRANSLATES_ENUM {
  ru = 'Русский',
  en = 'English',
  zh = '中國人',
  ar = 'عرب',
  de = 'Deutsche',
}

export type LanguagesKeysType = keyof typeof LANGUAGES_TO_TEXT_ENUM;

function getUserDefaultLanguage() {
  const DEFAULT_LANG: LanguagesKeysType = 'ru';
  let language = window.navigator?.language ?? DEFAULT_LANG;

  language = language.substr(0, 2).toLowerCase(); // получаем язык без учета региона

  if (!LANGUAGES_TO_TEXT_ENUM.hasOwnProperty(language)) {
    language = DEFAULT_LANG;
  }

  return language as LanguagesKeysType;
}

export const DEFAULT_LANGUAGES: LanguagesKeysType = getUserDefaultLanguage();

export function getCurrentLocale(locale: LanguagesKeysType): LanguagesKeysType {
  switch (locale) {
    case LANGUAGES_TO_TEXT_ENUM.ru:
      return 'ru';
    case LANGUAGES_TO_TEXT_ENUM.en:
      return 'en';
    case LANGUAGES_TO_TEXT_ENUM.zh:
      return 'zh';
    case LANGUAGES_TO_TEXT_ENUM.ar:
      return 'ar';
    case LANGUAGES_TO_TEXT_ENUM.de:
      return 'de';

    default:
      return DEFAULT_LANGUAGES;
  }
}

export function getCurrentLocaleForDatePicker(locale: LanguagesKeysType) {
  const DEFAULT_LANGUAGES_DATEPICKER = datepickerLangRU;

  switch (locale) {
    case LANGUAGES_TO_TEXT_ENUM.ru:
      return datepickerLangRU;
    case LANGUAGES_TO_TEXT_ENUM.en:
      return datepickerLangEN;
    case LANGUAGES_TO_TEXT_ENUM.zh:
      return datepickerLangCN;
    case LANGUAGES_TO_TEXT_ENUM.ar:
      return datepickerLangAR;
    case LANGUAGES_TO_TEXT_ENUM.de:
      return datepickerLangDE;

    default:
      return DEFAULT_LANGUAGES_DATEPICKER;
  }
}

const messages: MessagesType = {
  en: {
    ...tabs.en,
    ...components.en,
    ...toasts.en,
    ...popups.en,
    ...common.en,
  },
  ru: {
    ...tabs.ru,
    ...components.ru,
    ...toasts.ru,
    ...popups.ru,
    ...common.ru,
  },
  zh: {
    ...tabs.zh,
    ...components.zh,
    ...toasts.zh,
    ...popups.zh,
    ...common.zh,
  },
  ar: {
    ...tabs.ar,
    ...components.ar,
    ...toasts.ar,
    ...popups.ar,
    ...common.ar,
  },
  de: {
    ...tabs.de,
    ...components.de,
    ...toasts.de,
    ...popups.de,
    ...common.de,
  },
};

export default messages;
