import { reqHandlers } from 'utils/api';
import { CreateGeozone, CreateGeozoneGroup, GeozoneAttributes } from './interface';

export function getGeozones() {
  return reqHandlers.get(`/api/geozones`);
}

export function getOneGeozone(id: string) {
  return reqHandlers.get(`/api/geozones/${id}`);
}

export function getGeozonesShort() {
  return reqHandlers.get(`/api/geozones/shortAll`);
}

export function createGeozone(data: CreateGeozone) {
  return reqHandlers.post(`/api/geozones`, data);
}

export function putGeozone(data: {
  attributes: GeozoneAttributes;
  id?: string;
  parentGroupId: number | null;
  userId: number;
}) {
  return reqHandlers.put(`/api/geozones/${data.id}`, {
    ...data.attributes,
    userId: data.userId,
    parentGroupId: data.parentGroupId,
  });
}

export function deleteGeozone(id: string) {
  return reqHandlers.delete(`/api/geozones/${id}`);
}

export function getGeozonesGroups() {
  return reqHandlers.get(`/api/geozones/groups`);
}

export function createGeozonesGroups(data: CreateGeozoneGroup) {
  return reqHandlers.post(`/api/geozones/groups`, data);
}

export function putGeozonesGroups(data: CreateGeozoneGroup & { id: string }) {
  return reqHandlers.put(`/api/geozones/groups/${data.id}`, data);
}

export function deleteGeozonesGroups(id: string) {
  return reqHandlers.delete(`/api/geozones/groups/${id}`);
}
