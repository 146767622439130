import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from './common';
import MarkersReducer from './markers';
import FloorsReducer from './floors';
import AuthReducer from './auth';
import modalSlice from './modal';
import userSlice from './user';
import clientUserPreferencesReducer from './clientUserPreferences';
import trackerReducer from './trackers';
import employeeReducer from './employees';
import transportReducer from './transports';
import addonsReducer from './addons';
import mapReducer from './map';
import monitoringReducer from './monitoring';
import geoZoneReducer from './geozones';
import roleReducer from './roles';
import accountReducer from './accounts';
import trackReducer from './tracks';
import playerReducer from './player';
import trackableUnitSlice from './trackableUnits';
import onlineCounterReducer from './onlineCounter';
import notificationReducer from './notifications';
import handbooksReducer from './handbooks';
import recordsReducer from './records';
import poiReducer from './poi';
import personalStatisticsReducer from './personalStatistics';
import messagesReducer from './messages';

export const rootReducer = combineReducers({
  common: commonReducer,
  markers: MarkersReducer,
  floors: FloorsReducer,
  auth: AuthReducer,
  modal: modalSlice,
  user: userSlice,
  clientUserPreferences: clientUserPreferencesReducer,
  tracker: trackerReducer,
  employee: employeeReducer,
  transport: transportReducer,
  addons: addonsReducer,
  map: mapReducer,
  monitoring: monitoringReducer,
  geozone: geoZoneReducer,
  role: roleReducer,
  account: accountReducer,
  track: trackReducer,
  player: playerReducer,
  onlineCounter: onlineCounterReducer,
  trackableUnit: trackableUnitSlice,
  notifications: notificationReducer,
  handbooks: handbooksReducer,
  records: recordsReducer,
  poi: poiReducer,
  personalStatistics: personalStatisticsReducer,
  messages: messagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
