import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { ReactComponent as InfoIcon } from 'assets/img/info.svg';
import { ReactComponent as MockPersonIcon } from 'assets/img/mock-person-icon.svg';
import ReactTooltip from 'react-tooltip';
import styles from './objectsShowingTab.module.scss';
import { ObjectsShowingHandlers, ObjectsShowingValues } from '../../utils/types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

// const messages = {
//   title: 'Текущее представление иконок на карте',
//   tooltips: {
//     photo: 'Фотография отображается всегда',
//     trackerNumber: 'Отмеченный чекбокс выводит номер трекера',
//     fullName: 'Отмеченный чекбокс выводит подробное название объекта',
//     organization: 'Отмеченный чекбокс выводит название организации',
//     department: 'Отмеченный чекбокс выводит название подразделения',
//     positionOrFio: 'Отмеченный чекбокс выводит должность сотрудника или ФИО водителя транспорта',
//     objectInfo: 'Отмеченный чекбокс выводит подпись всегда, иначе выводит только при наведении курсора мыши на фото',
//   },
//   texts: {
//     trackerNumber: '№ трекера',
//     fullName: 'Полное название',
//     organization: 'Организация',
//     department: 'Подразделение',
//     positionOrFio: 'Должность/ ФИО водителя',
//   },
//   objectInfo: 'Выводить подпись всегда',
//   onRightSign: 'Подпись справа от фото',
//   hasBackground: 'Подпись на фоне',
// };

type Props = {
  values: ObjectsShowingValues;
  handlers: ObjectsShowingHandlers;
};

export function ObjectsShowingTab({ values, handlers }: Props) {
  const t = useFormatMessage();

  const exampleBlockClasses = cx(styles.showExampleBlock, {
    [styles.showExampleBlockRow]: values.onRightSign,
    [styles.showExampleBlockRowHasBackground]: values.hasBackground,
  });
  const checkboxTitleBoldBlueClasses = cx(styles.checkboxBoldBlueTitle, {
    [styles.checkboxBoldBlueTitleHasBackground]: values.hasBackground,
  });
  const checkboxTitleClasses = cx(styles.checkboxTitle, {
    [styles.checkboxTitleHasBackground]: values.hasBackground,
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('user-settings.modal.objects.header.text')}</div>

      <div className={exampleBlockClasses}>
        <div className={styles.personIcon}>
          <MockPersonIcon />
          <InfoIcon className={styles.personIconInfo} data-tip data-for="objectPhoto" />
          <ReactTooltip className={styles.customTooltip} id="objectPhoto" place="right" type="light" effect="solid">
            <span>{t('user-settings.modal.objects.tooltip.photo.text')}</span>
          </ReactTooltip>
        </div>

        <div className={styles.checkboxGroup}>
          <div
            className={styles.checkbox}
            onClick={() => handlers.setTrackerNumberIsShown(!values.trackerNumberIsShown)}
          >
            <Checkbox checked={values.trackerNumberIsShown} />
            <div
              className={cx(checkboxTitleBoldBlueClasses, {
                [styles.checkboxBoldBlueTitleHasBackgroundTopRadius]: values.hasBackground,
              })}
            >
              {t('user-settings.modal.objects.texts.tracker-number.text')}
            </div>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="trackerNumber" />
            <ReactTooltip className={styles.customTooltip} id="trackerNumber" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.tracker-number.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.checkbox} onClick={() => handlers.setFullNameIsShown(!values.fullNameIsShown)}>
            <Checkbox checked={values.fullNameIsShown} />
            <div className={checkboxTitleBoldBlueClasses}>{t('user-settings.modal.objects.texts.full-name.text')}</div>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="fullName" />
            <ReactTooltip className={styles.customTooltip} id="fullName" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.full-name.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.checkbox} onClick={() => handlers.setOrganizationIsShown(!values.organizationIsShown)}>
            <Checkbox checked={values.organizationIsShown} />
            <div className={checkboxTitleClasses}>{t('user-settings.modal.objects.texts.organization.text')}</div>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="organization" />
            <ReactTooltip className={styles.customTooltip} id="organization" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.organization.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.checkbox} onClick={() => handlers.setDepartmentIsShown(!values.departmentIsShown)}>
            <Checkbox checked={values.departmentIsShown} />
            <div className={checkboxTitleClasses}>{t('user-settings.modal.objects.texts.department.text')}</div>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="department" />
            <ReactTooltip className={styles.customTooltip} id="department" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.department.text')}</span>
            </ReactTooltip>
          </div>
          <div
            className={styles.checkbox}
            onClick={() => handlers.setPositionOrDriverIsShown(!values.positionOrDriverIsShown)}
          >
            <Checkbox checked={values.positionOrDriverIsShown} />
            <div
              className={cx(checkboxTitleClasses, {
                [styles.checkboxTitleHasBackgroundBottomRadius]: values.hasBackground,
              })}
            >
              {t('user-settings.modal.objects.texts.position.text')}
            </div>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="positionOrFio" />
            <ReactTooltip className={styles.customTooltip} id="positionOrFio" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.position.text')}</span>
            </ReactTooltip>
          </div>
        </div>
      </div>

      <div className={styles.checkboxesBlock}>
        <div className={styles.checkboxGroup}>
          <div className={styles.checkbox} onClick={() => handlers.handleAlwaysShow()}>
            <Checkbox checked={values.alwaysShow} />
            <span className={styles.checkboxTitle}>{t('user-settings.modal.objects.object-info.text')}</span>
            <InfoIcon className={styles.checkboxInfo} data-tip data-for="objectInfo" />
            <ReactTooltip className={styles.customTooltip} id="objectInfo" place="right" type="light" effect="solid">
              <span>{t('user-settings.modal.objects.tooltip.object-info.text')}</span>
            </ReactTooltip>
          </div>
          <div className={styles.checkbox} onClick={() => handlers.setOnRightSign(!values.onRightSign)}>
            <Checkbox checked={values.onRightSign} />
            <span className={styles.checkboxTitle}>{t('user-settings.modal.objects.on-right-side.text')}</span>
          </div>
          <div className={styles.checkbox} onClick={() => handlers.setHasBackground(!values.hasBackground)}>
            <Checkbox checked={values.hasBackground} />
            <span className={styles.checkboxTitle}>{t('user-settings.modal.objects.has-bg.text')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
