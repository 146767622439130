import React, { Dispatch } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Input from 'components/common/input/input';
import CustomSelect from 'components/common/select/select';
import { Spinner } from 'components/common/spinner/spinner';

import styles from './tabInfo1.module.scss';

interface Body {
  onNumberChange: Dispatch<string>;
  onDeviceIdChange: Dispatch<string>;
  onSimChange: Dispatch<string>;
  onModelChange: (val: string) => void;
  onStateChange: (val: string) => void;
  onCommentChange: Dispatch<string>;
  onChangeBoundedObject: Dispatch<string>;
  trackerNumber: string;
  deviceId: string;
  simNumber: string;
  trackerModel: number;
  trackerServer: string;
  boundedObject: string;
  trackerState: number;
  comment: string;
  invalidFields: string[];
  modelOptions: { value: string | number; label: string | number }[];
  stateOptions: { value: string | number; label: string | number }[];
  loading?: boolean;
}

export const TrackersTabInfo = ({
  onNumberChange,
  onDeviceIdChange,
  onSimChange,
  onModelChange,
  onStateChange,
  onCommentChange,
  trackerNumber,
  deviceId,
  simNumber,
  trackerModel,
  trackerServer,
  boundedObject,
  trackerState,
  comment,
  invalidFields,
  loading,
  modelOptions,
  stateOptions,
  onChangeBoundedObject,
}: Body) => {
  const t = useFormatMessage();

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalCol}>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.number.label')}
            placeholder={t('trackers.card.tab.main.field.number.placeholder')}
            isValueError={invalidFields.includes('trackerNumber')}
            isRequired={true}
            value={trackerNumber}
            handleInputChange={onNumberChange}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.identifier.label')}
            placeholder={t('trackers.card.tab.main.field.identifier.placeholder')}
            isValueError={invalidFields.includes('deviceId')}
            isRequired={true}
            value={deviceId}
            handleInputChange={onDeviceIdChange}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.sim.label')}
            placeholder={t('trackers.card.tab.main.field.sim.placeholder')}
            isValueError={invalidFields.includes('simNumber')}
            isRequired={true}
            value={simNumber}
            pattern="+7[0-9]{10}"
            handleInputChange={onSimChange}
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <CustomSelect
            options={modelOptions}
            label={t('trackers.card.tab.main.field.model.label')}
            placeholder={t('trackers.card.tab.main.field.model.placeholder')}
            isValueError={invalidFields.includes('trackerModel')}
            isRequired={true}
            withSearchIcon
            value={trackerModel}
            handleChange={onModelChange}
            style={styles.inputWrap}
          />
        </div>
      </div>
      <div className={styles.modalCol}>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.address.label')}
            placeholder={t('trackers.card.tab.main.field.address.placeholder')}
            value={trackerServer}
            disabled
            customStyle={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.linked.label')}
            placeholder={t('trackers.card.tab.main.field.linked.placeholder')}
            value={boundedObject}
            customStyle={styles.inputWrap}
            handleInputChange={onChangeBoundedObject}
          />
        </div>
        <div>
          <CustomSelect
            options={stateOptions}
            label={t('trackers.card.tab.main.field.condition.label')}
            placeholder={t('trackers.card.tab.main.field.condition.placeholder')}
            isValueError={invalidFields.includes('trackerState')}
            isRequired={true}
            withSearchIcon
            value={trackerState}
            handleChange={onStateChange}
            style={styles.inputWrap}
          />
        </div>
        <div>
          <Input
            label={t('trackers.card.tab.main.field.comment.label')}
            placeholder={t('trackers.card.tab.main.field.comment.placeholder')}
            value={comment}
            handleInputChange={onCommentChange}
            customStyle={styles.inputWrap}
            isValueError={invalidFields.includes('comment')}
            isRequired={true}
          />
        </div>
      </div>
    </div>
  );
};
