import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Map } from 'ol';
import { transformExtent } from 'ol/proj';
import { setViewportInfo } from 'reducers/map';
import { updateClientUserPreferences } from 'reducers/clientUserPreferences';

export const useViewportInfo = (map: Map, node: React.RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();

  const getViewportInfo = useCallback(() => {
    map.on('moveend', () => {
      const mapView = map.getView();

      const extent = mapView.calculateExtent(map.getSize());
      const transformedExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

      const x = (transformedExtent[2] + transformedExtent[0]) / 2;
      const y = (transformedExtent[3] + transformedExtent[1]) / 2;

      dispatch(
        setViewportInfo({
          center: [x, y],
          mountedNodeWidth: !!node && node.current ? node.current.offsetWidth : 0,
          mountedNodeHeight: !!node && node.current ? node.current.offsetHeight : 0,
        })
      );

      dispatch(
        updateClientUserPreferences({
          usersMapInfo: {
            lastPosition: mapView.getCenter() || [0, 0],
            zoom: mapView.getZoom(),
          },
        })
      );
    });
  }, [dispatch, map, node]);

  useEffect(() => {
    getViewportInfo();
  }, [getViewportInfo]);
};
