import { reqHandlers } from 'utils/api';

import { GetClientUserPreferencesResponse, PutClientUserPreferencesRequestData } from './interface';

const apiUrl = '/api/userPreferences/client';

export function getClientUserPreferences(): Promise<GetClientUserPreferencesResponse> {
  return reqHandlers.get(apiUrl);
}

export function putClientUserPreferences(data: PutClientUserPreferencesRequestData): Promise<void> {
  return reqHandlers.put(apiUrl, data);
}
