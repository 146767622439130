import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { Option } from 'components/common/select/select';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import { RootState } from 'reducers';
import { fetchHandbookData } from 'reducers/handbooks';

import { UNGROUPED_KEY } from 'utils/treeGroupBy/treeGroupBy';

const messages = {
  withoutNameLabel: 'accounts.users-list-tree.entity-without-name.text',
  ungroupedLabel: (entity: string) => `accounts.users-list-tree.${entity}-ungrouped.text`,
  selectAllLabel: (entity: string) => `accounts.users-list-filters.all-${entity}.text`,
};

export function useParseHandbooksData() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const locale = useSelector((state: RootState) => state.user.userPreferences.locale);
  const handbooksData = useSelector((state: RootState) => state.handbooks.data);

  useEffect(() => {
    dispatch(fetchHandbookData([HANDBOOK_KEYS.organizations, HANDBOOK_KEYS.departments, HANDBOOK_KEYS.positions]));
  }, []);

  const { organizations, departments, positions, organizationOptions, departmentOptions, positionOptions } =
    useMemo(() => {
      const withoutNameLabel = t(messages.withoutNameLabel);

      const organizations: { [id: string]: string } = {
        [UNGROUPED_KEY]: t(messages.ungroupedLabel('organization')),
      };
      const departments: { [id: string]: string } = {
        [UNGROUPED_KEY]: t(messages.ungroupedLabel('department')),
      };
      const positions: { [id: string]: string } = {
        [UNGROUPED_KEY]: t(messages.ungroupedLabel('position')),
      };

      const organizationOptions: Option[] = [
        {
          value: '',
          label: t(messages.selectAllLabel('organizations')),
        },
      ];
      const departmentOptions: Option[] = [
        {
          value: '',
          label: t(messages.selectAllLabel('departments')),
        },
      ];
      const positionOptions: Option[] = [
        {
          value: '',
          label: t(messages.selectAllLabel('positions')),
        },
      ];

      if (Array.isArray(handbooksData.organizations)) {
        for (const organization of handbooksData.organizations) {
          const organizationName = organization.attributes.name[locale] || withoutNameLabel;

          organizations[organization.id] = organizationName;
          organizationOptions.push({
            value: organization.id.toString(),
            label: organizationName,
          });
        }
      }

      if (Array.isArray(handbooksData.departments)) {
        for (const department of handbooksData.departments) {
          const departmentName = department.attributes.name[locale] || withoutNameLabel;

          departments[department.id] = departmentName;
          departmentOptions.push({
            value: department.id.toString(),
            label: departmentName,
          });
        }
      }

      if (Array.isArray(handbooksData.positions)) {
        for (const position of handbooksData.positions) {
          const positionName = position.attributes.name[locale] || withoutNameLabel;

          positions[position.id] = positionName;
          positionOptions.push({
            value: position.id.toString(),
            label: positionName,
          });
        }
      }

      return {
        organizations,
        departments,
        positions,
        organizationOptions,
        departmentOptions,
        positionOptions,
      };
    }, [handbooksData, locale]);

  return {
    organizations,
    departments,
    positions,
    organizationOptions,
    departmentOptions,
    positionOptions,
  };
}
