import React from 'react';
import styles from './spinner.module.css';

export const Spinner = () => {
  return (
    <div className={styles.load3}>
      <div className={styles.loader}></div>
    </div>
  );
};
