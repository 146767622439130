import React, { useEffect, useMemo, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import classnames from 'classnames/bind';

import SelectCustom, { Option } from 'components/common/select/select';

import styles from './multipleSelect.module.scss';

const cx = classnames.bind(styles);

export type ValuesMultipleType = (string | number)[];

export type ValuesType = Option[];

interface MultipleSelectProps {
  label: string;
  placeholder: string;

  options: Option[];
  values?: ValuesType;

  isRequired: boolean;
  isGood?: boolean;
  isValueError?: boolean;

  withSearchIcon?: boolean;
  withCalendarIcon?: boolean;

  disabled?: boolean;

  handleChange?: (value: string) => void;
  handleChangeMultiple?: (value: ValuesType) => void;

  customSelectStyle?: string;
  wrapperStyle?: string;
  optionsContainerStyle?: string;
  optionIsInline?: boolean;

  noWrappedItems?: string[];
}

// функция получения выбранных значений из опций
export function getValueForMultipleSelect({
  selectValues,
  id,
  i,
}: {
  selectValues: Option[];
  id: string | number;
  i: number;
}) {
  const option = selectValues.find(selectValue => {
    if (selectValue.data && selectValue.data.hasOwnProperty('id')) {
      return String(selectValue.data.id) === String(id);
    }
    return false;
  });
  if (option) {
    return { ...option };
  }
  return selectValues[i];
}

// функция фильтрации опций
function filteringOptions(options: Option[], values: ValuesType) {
  if (values.length) {
    const valuesValue = values.map(value => value?.value ?? 0);
    const newOptions: Option[] = [];

    options.forEach(option => {
      if (!valuesValue.includes(option.value)) {
        newOptions.push(option);
      }
    });
    return newOptions;
  }
  return options;
}

const MultipleSelect = (props: MultipleSelectProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  const label = useMemo(() => props.label, [props.label]);
  const placeholder = useMemo(() => props.placeholder, [props.placeholder]);

  const options = useMemo(() => props.options, [props.options]);
  const values = useMemo(() => props.values, [props.values]);

  const isRequired = useMemo(() => props.isRequired, [props.isRequired]);
  const isGood = useMemo(() => props.isGood, [props.isGood]);
  const isValueError = useMemo(() => props.isValueError, [props.isValueError]);

  const withSearchIcon = useMemo(() => props.withSearchIcon, [props.withSearchIcon]);
  const withCalendarIcon = useMemo(() => props.withCalendarIcon, [props.withCalendarIcon]);

  const disabled = useMemo(() => props.disabled, [props.disabled]);
  const handleChangeMultiple = useMemo(() => props.handleChangeMultiple, [props.handleChangeMultiple]);

  const customSelectStyle = useMemo(() => props.customSelectStyle, [props.customSelectStyle]);
  const wrapperStyle = useMemo(() => props.wrapperStyle, [props.wrapperStyle]);
  const optionsContainerStyle = useMemo(() => props.optionsContainerStyle, [props.optionsContainerStyle]);
  const optionIsInline = useMemo(() => props.optionIsInline, [props.optionIsInline]);

  // прокрутка выбранных значений в коней списка
  const valuesRef = React.createRef<HTMLUListElement>();

  useEffect(() => {
    if (valuesRef) {
      const valuesContainer = valuesRef.current;
      if (valuesContainer) {
        valuesContainer.scrollTop = valuesContainer.scrollHeight;
      }
    }
  }, [valuesRef, values]);

  // установка и фильтрация опций
  useEffect(() => {
    if (options?.length) {
      if (values?.length) {
        setSelectOptions(filteringOptions(options, values));
      } else {
        setSelectOptions(options);
      }
    } else {
      setSelectOptions([]);
    }
  }, [options, values]);

  const wrapperClasses = cx(styles.container, wrapperStyle, {
    [styles.containerWarning]: isValueError,
    [styles.containerFocused]: isFocused,
    [styles.containerDisabled]: disabled,
  });

  // обработчик выбора опций
  const handleChange = (value: string | number) => {
    const findedValue = options.find(item => String(item.value) === String(value));

    if (findedValue) {
      // если значения нет в props.values
      if (!values?.map(option => option?.value).filter(value => value === findedValue.value).length) {
        // то добавить
        handleChangeMultiple && handleChangeMultiple([...(values ?? []), findedValue]);
      }
    }
    setIsFocused(false);
  };

  // обработчик удаления выбранного значения
  const handleDeleteValue = <SVGElement, MouseEvent>(
    e: React.MouseEvent<SVGElement, MouseEvent>,
    value: string | number
  ) => {
    if (disabled) {
      return;
    }
    e.stopPropagation();
    setIsFocused(false);

    const newValues = values?.filter(item => {
      if (item) {
        return String(item.value) !== String(value);
      }
      return false;
    });

    handleChangeMultiple && handleChangeMultiple(newValues ?? []);
  };

  // обработчик клика по выбранному значению
  const handleValuesClick = () => {
    setIsFocused(true);
  };

  return (
    <div
      className={wrapperClasses}
      onFocus={() => setIsFocused(true)}
      onClick={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      // onMouseLeave={() => setIsFocused(false)}
    >
      <SelectCustom
        label={label}
        options={selectOptions}
        placeholder={placeholder}
        isValueError={isValueError}
        isRequired={isRequired}
        value={''}
        withSearchIcon={withSearchIcon}
        withCalendarIcon={withCalendarIcon}
        isGood={isGood}
        disabled={disabled}
        handleChange={handleChange}
        style={cx(styles.select, customSelectStyle)}
        optionsContainerStyle={cx(styles.selectOptionsContainer, optionsContainerStyle)}
        showOptions={isFocused}
      />
      <div className={styles.valuesWrapper} onClick={handleValuesClick}>
        <ul className={styles.valuesList} ref={valuesRef}>
          {values?.map((val, index) => {
            return (
              !!val && (
                <li
                  key={`multiple-values_${index}`}
                  className={cx(styles.valuesItem, {
                    [styles.valuesItemInline]: optionIsInline,
                    [styles.valuesItemDisabled]: disabled,
                  })}
                >
                  <div className={styles.value}>{val.label}</div>
                  <div className={styles.closeBtn}>
                    <AiFillCloseCircle
                      color="#999999"
                      className={cx(styles.closeModalIcon, {
                        [styles.closeModalIconDisabled]: disabled,
                      })}
                      onClick={e => handleDeleteValue(e, val.value)}
                    />
                  </div>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(MultipleSelect);
