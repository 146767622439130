import { FormatMessage } from '@comparaonline/react-intl-hooks';
import { Notification, NotificationsGroup } from 'reducers/notifications/interface';
import { NOTIFICATION_TYPES_ENUM, NOTIFICATION_TYPES_ENUM_NAMES } from './consts';

export class NotificationsTree {
  private groupsTree: (NotificationsGroup | null)[] = [];

  constructor(
    private notifications: Notification[],
    private selectedNotifications: string[],
    private tHook: FormatMessage
  ) {}

  setNotifications(notifications: Notification[]) {
    this.notifications = [...notifications];
  }

  createGroup({
    name,
    notifications,
    groupsStore,
    notificationType,
  }: {
    name: string;
    notifications: Notification[];
    groupsStore: NotificationsGroup[];
    notificationType: NOTIFICATION_TYPES_ENUM;
  }) {
    const activeNotificationsCount = notifications.filter(item => item.attributes.isActive).length;

    const newGroup: NotificationsGroup = {
      name,
      isActive: activeNotificationsCount > 0,
      isIndeterminate: activeNotificationsCount > 0 && activeNotificationsCount < notifications.length,
      expanded: groupsStore.find(group => group.notificationType === notificationType)?.expanded || false,
      checked: notifications.every(n => this.selectedNotifications.includes(n.id)),
      notificationsIds: notifications.map(n => n.id),
      numberOfUnits: notifications.reduce((pred, curr) => pred + Number(curr.meta.numberOfUnits), 0),
      numberOfNotified: notifications.reduce((pred, curr) => pred + Number(curr.meta.numberOfNotified), 0),
      notificationType,
    };
    return newGroup;
  }

  createGroups(groupsStore: NotificationsGroup[]) {
    const { alert, geoZones, interceptionOfObjects, sensorValue, videoAnalytics } = NOTIFICATION_TYPES_ENUM;
    const alertArr: Notification[] = [],
      geozoneArr: Notification[] = [],
      interpositionArr: Notification[] = [],
      sensorArr: Notification[] = [],
      videoArr: Notification[] = [];

    this.notifications.forEach(item => {
      switch (item.attributes.notificationType) {
        case alert:
          alertArr.push(item);
          break;
        case geoZones:
          geozoneArr.push(item);
          break;
        case interceptionOfObjects:
          interpositionArr.push(item);
          break;
        case sensorValue:
          sensorArr.push(item);
          break;
        case videoAnalytics:
          videoArr.push(item);
          break;

        default:
          return [];
      }
    });

    const alertGroupItem: NotificationsGroup = this.createGroup({
      name: this.tHook(NOTIFICATION_TYPES_ENUM_NAMES.alert),
      notifications: alertArr,
      groupsStore,
      notificationType: alert,
    });

    const geozoneGroupItem: NotificationsGroup = this.createGroup({
      name: this.tHook(NOTIFICATION_TYPES_ENUM_NAMES.geoZones),
      notifications: geozoneArr,
      groupsStore,
      notificationType: geoZones,
    });

    const interpositionGroupItem: NotificationsGroup = this.createGroup({
      name: this.tHook(NOTIFICATION_TYPES_ENUM_NAMES.interceptionOfObjects),
      notifications: interpositionArr,
      groupsStore,
      notificationType: interceptionOfObjects,
    });

    const sensorGroupItem: NotificationsGroup = this.createGroup({
      name: this.tHook(NOTIFICATION_TYPES_ENUM_NAMES.sensorValue),
      notifications: sensorArr,
      groupsStore,
      notificationType: sensorValue,
    });

    const videoAnalyticsGroupItem = this.createGroup({
      name: this.tHook(NOTIFICATION_TYPES_ENUM_NAMES.videoAnalytics),
      notifications: videoArr,
      groupsStore,
      notificationType: videoAnalytics,
    });

    this.groupsTree = [
      alertArr.length ? alertGroupItem : null,
      geozoneArr.length ? geozoneGroupItem : null,
      interpositionArr.length ? interpositionGroupItem : null,
      sensorArr.length ? sensorGroupItem : null,
      videoArr.length ? videoAnalyticsGroupItem : null,
    ];
  }

  getTree() {
    return this.groupsTree;
  }
}
