import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { Option } from 'components/common/select/select';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { RootState } from 'reducers';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';
import { ChosenPoi, PoiEquipmentPoint } from 'reducers/poi/interface';

import { getCurrentLocale } from 'translate';

import { defaultPoiEquipmentState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardEquipmentData } from '../utils/types';

export default function usePoiSensor(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardEquipmentData);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const [poiEquipmentTypeIdForSelect, setPoiEquipmentTypeIdForSelect] = useState<Option[]>([]);
  const [poiVideocameraModelIdForSelect, setPoiVideocameraModelIdForSelect] = useState<Option[]>([]);

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const lastCheckDateRef = React.createRef();
  const nextCheckDateRef = React.createRef();

  useEffect(() => {
    const handbookEquipmentModels = handbookData?.poiEquipmentModels;
    const handbookEquipmentTypes = handbookData?.poiEquipmentTypes;

    if (handbookEquipmentModels && handbookEquipmentTypes) {
      setPoiEquipmentTypeIdForSelect(
        handbookEquipmentTypes.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
      setPoiVideocameraModelIdForSelect(
        handbookEquipmentModels.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
    }
  }, [handbookData, userLanguageKey]);

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiEquipmentPoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiEquipmentPoint = relationships.poiEquipmentPoint.data as PoiEquipmentPoint;

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          poiEquipmentTypeId: poiFields.poiEquipmentTypeId,
          poiEquipmentModelId: poiFields.poiEquipmentModelId,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          lastCheckDate: poiFields.lastCheckDate,
          nextCheckDate: poiFields.nextCheckDate,
        })
      );
    }
  }, [dispatch, chosenPoi]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );

  const handleChangePoiEquipmentTypeId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiEquipmentTypeId',
          value: newValue,
        })
      );
    }
  };

  const handleChangePoiEquipmentModelId = (value: string) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiEquipmentModelId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeCoordinates = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'coordinates',
        value,
      })
    );
  const handleChangeLastCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'lastCheckDate',
        value: date.toISOString(),
      })
    );

  const handleChangeNextCheckDate = (date: Date) =>
    dispatch(
      setPoiCardFieldData({
        key: 'nextCheckDate',
        value: date.toISOString(),
      })
    );

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!poiCardData.poiEquipmentTypeId) {
      newInvalidFields.push('poiEquipmentTypeId');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiEquipmentPoint = relationships.poiEquipmentPoint.data as PoiEquipmentPoint;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        poiEquipmentTypeId: poiFields.poiEquipmentTypeId,
        poiEquipmentModelId: poiFields.poiEquipmentModelId,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        lastCheckDate: poiFields.lastCheckDate,
        nextCheckDate: poiFields.nextCheckDate,
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      poiEquipmentTypeIdForSelect,
      poiVideocameraModelIdForSelect,
    },
    refs: {
      lastCheckDateRef,
      nextCheckDateRef,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePoiEquipmentTypeId,
      handleChangePoiEquipmentModelId,
      handleChangeCoordinates,
      handleChangeLastCheckDate,
      handleChangeNextCheckDate,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
