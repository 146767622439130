import React, { Dispatch, ReactNode } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';

import { ReactComponent as InfoIcon } from 'assets/img/main-information.svg';
import { ReactComponent as FieldsIcon } from 'assets/img/additional-fields.svg';
import { ReactComponent as StatIcon } from 'assets/img/object-stat.svg';

import { ActiveTab } from '../utils/types';

import styles from '../monitoring.module.scss';

const cx = classNames.bind(styles);

interface BodyProps {
  showStat: boolean;
  activeTab: ActiveTab;
  setActiveTab: Dispatch<ActiveTab>;
  children: ReactNode;
}

export const ModalTabs = ({ showStat, activeTab, setActiveTab, children }: BodyProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalTabs}>
        <div
          className={cx(styles.modalTab, {
            [styles.modalTab]: activeTab === ActiveTab.info,
            [styles.modalTabActive]: activeTab === ActiveTab.info,
          })}
          onClick={() => setActiveTab(ActiveTab.info)}
        >
          <InfoIcon
            className={cx({
              [styles.modalTabIconActive]: activeTab === ActiveTab.info,
              [styles.modalTabIcon]: activeTab !== ActiveTab.info,
            })}
          />
          <span className={styles.modalTabLabel}>{t('monitoring.unit-card.tab.general-info.label')}</span>
        </div>
        <div
          className={cx(styles.modalTab, {
            [styles.modalTab]: activeTab === ActiveTab.additional,
            [styles.modalTabActive]: activeTab === ActiveTab.additional,
          })}
          onClick={() => setActiveTab(ActiveTab.additional)}
        >
          <FieldsIcon
            className={cx({
              [styles.modalTabIconActive]: activeTab === ActiveTab.additional,
              [styles.modalTabIcon]: activeTab !== ActiveTab.additional,
            })}
          />
          <span className={styles.modalTabLabel}>{t('monitoring.unit-card.tab.additional-fields.label')}</span>
        </div>

        {showStat && (
          <div
            className={cx(styles.modalTab, {
              [styles.modalTab]: activeTab === ActiveTab.stat,
              [styles.modalTabActive]: activeTab === ActiveTab.stat,
            })}
            onClick={() => setActiveTab(ActiveTab.stat)}
          >
            <StatIcon
              className={cx({
                [styles.modalTabIconActive]: activeTab === ActiveTab.stat,
                [styles.modalTabIcon]: activeTab !== ActiveTab.stat,
              })}
            />
            <span className={styles.modalTabLabel}>{t('monitoring.unit-card.tab.statistic.label')}</span>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
