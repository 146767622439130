import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Search from 'components/common/search/search';
import DropButton from 'components/common/dropbutton/dropbutton';
import Button from 'components/common/button/button';

import { AccountsPage } from '../utils/types';

import styles from './accountsHeader.module.scss';

type Props = {
  currentPage: AccountsPage;
  isAllowCreate: boolean;
  handleSearchChange: () => void;
  handleCreateClick: () => void;
  changePage: (page: AccountsPage) => void;
};

const messages = {
  createButton: 'accounts.button-block.btn.create.label',
  users: 'accounts.button-block.btn.drop-list.users.label',
  roles: 'accounts.button-block.btn.drop-list.roles.label',
};

const dropButtons = [
  {
    label: messages.users,
    value: AccountsPage.Users,
  },
  {
    label: messages.roles,
    value: AccountsPage.Roles,
  },
];

const AccountsHeader: React.FC<Props> = ({
  currentPage,
  isAllowCreate,
  handleSearchChange,
  handleCreateClick,
  changePage,
}) => {
  const t = useFormatMessage();

  const handleDropButtonChange = (value: string) => {
    changePage(value as AccountsPage);
  };

  const dropButtonLabel = currentPage === AccountsPage.Users ? t(messages.users) : t(messages.roles);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.searchContainer}>
        <Search handleChange={handleSearchChange} />
      </div>

      {isAllowCreate && (
        <Button customStyle={styles.createButton} text={t(messages.createButton)} onClick={handleCreateClick} blue />
      )}

      <DropButton
        label={dropButtonLabel}
        buttons={dropButtons}
        optionsWidth={130}
        onClick={handleDropButtonChange}
        noAdditionalSymbol
      />
    </div>
  );
};

export default AccountsHeader;
