import React, { createContext, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleUserCountEvent } from 'reducers/onlineCounter';
import { RootState } from 'reducers';
import { addLogToNotificationAndIncreaseCountArray } from 'reducers/notifications';
import { NotificationEntityWS } from 'reducers/notifications/interface';
import {
  LocationEvents,
  OnlineCountEvent,
  SingleTimeManyDevicesEvent,
  SingleTimeSingleDeviceEvent,
  SosDeviceEvent,
  WebSocketClientEvent,
  WebSocketServerEvent,
} from './interfaces';
import {
  setTrackableUnitsCoordinatesFromWS,
  setTrackableUnitsCoordinatesFromWSSinglePoint,
} from 'reducers/trackableUnits';
import { WS_BASE } from '../utils/consts';

interface ContextType {
  socket: WebSocket;
  sendMessage: (event: WebSocketClientEvent) => void;
}

const WebSocketContext = createContext<ContextType | null>(null);

interface PropsType {
  children: ReactElement;
}

const INTERVAL_UPDATE_VALUE = 5000;

const WebSocketProvider = ({ children }: PropsType) => {
  const dispatch = useDispatch();
  const [ws, setWs] = useState<ContextType | null>(null);

  const { isAuth } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!ws && isAuth) {
      const socket: WebSocket = new WebSocket(window.location.origin.replace(/^http/, 'ws') + WS_BASE);
      const sendMessage = (event: WebSocketClientEvent) => {
        socket.send(JSON.stringify(event));
      };

      let singleDevicePayloads: SingleTimeSingleDeviceEvent[] = [];
      let manyDevicesPayloads: SingleTimeManyDevicesEvent[] = [];
      let sosDevicePayloads: NotificationEntityWS[] = [];
      let userCountEvent = {};

      socket.onmessage = (msg: MessageEvent) => {
        const payload: WebSocketServerEvent = JSON.parse(msg.data);
        const { type } = payload as LocationEvents | OnlineCountEvent;
        const { deviceEvent, notificationEntity } = payload as SosDeviceEvent;

        if (type) {
          switch (type) {
            case 'SingleDeviceManyTimesEvent':
            case 'SingleTimeSingleDeviceEvent':
              singleDevicePayloads.push(payload as SingleTimeSingleDeviceEvent);
              break;
            case 'ManyDevicesManyTimes':
            case 'SingleTimeManyDevicesEvent':
              manyDevicesPayloads.push(payload as SingleTimeManyDevicesEvent);
              break;
            case 'OnlineCount':
              Object.assign(userCountEvent, payload);
              break;
          }
        }

        if (deviceEvent) {
          switch (deviceEvent.type) {
            case 'SosDeviceEvent':
              if (notificationEntity) {
                sosDevicePayloads.push(notificationEntity);
              }
              break;

            default:
              break;
          }
        }
      };

      setInterval(() => {
        dispatch(handleUserCountEvent(userCountEvent as OnlineCountEvent));
        dispatch(addLogToNotificationAndIncreaseCountArray(sosDevicePayloads));
        dispatch(setTrackableUnitsCoordinatesFromWSSinglePoint(singleDevicePayloads));
        dispatch(setTrackableUnitsCoordinatesFromWS(manyDevicesPayloads));
        userCountEvent = {};
        sosDevicePayloads = [];
        singleDevicePayloads = [];
        manyDevicesPayloads = [];
      }, INTERVAL_UPDATE_VALUE);

      setWs({
        socket: socket,
        sendMessage,
      });
    }
  }, [dispatch, ws, isAuth]);

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};

export { WebSocketProvider, WebSocketContext };
