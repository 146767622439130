import React, { ReactElement, PropsWithChildren } from 'react';
import { SortableElement, SortableContainer, SortableHandle, SortEndHandler } from 'react-sortable-hoc';

import { ReactComponent as DragIcon } from 'assets/img/three-lines-drag.svg';
import { ReactComponent as LeftArrow } from 'assets/img/monitoring/left-move-arrow.svg';
import { ReactComponent as RightArrow } from 'assets/img/monitoring/right-move-arrow.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';

import { TreeGroupConfigViewItem } from './hooks/useTreeGroupConfig';

import styles from './treeGroupConfigView.module.scss';

type Props<TItem extends Record<string, unknown>> = {
  config: TreeGroupConfigViewItem<TItem>[];
  handleItemCheck: (index: number) => void;
  handleLeftClick: (index: number) => void;
  handleRightClick: (index: number) => void;
  handleSortEnd: SortEndHandler;
};

type ConfigItemProps = {
  // we really don't care of item type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: TreeGroupConfigViewItem<any>;
  onCheck: () => void;
  onLeftClick: () => void;
  onRightClick: () => void;
};

const DragButton = SortableHandle(() => <DragIcon className={styles.dragIcon} />);

const ConfigItem = SortableElement(({ item, onLeftClick, onRightClick, onCheck }: ConfigItemProps) => {
  const marginLeft = item.offset > 1 ? (item.offset - 1) * 34 : 0;

  return (
    <div className={styles.configItemContainer} style={{ marginLeft: marginLeft + 'px' }}>
      {item.canLeft && (
        <div className={styles.arrowContainer} onClick={onLeftClick}>
          <LeftArrow />
        </div>
      )}

      <div className={styles.configItem}>
        <Checkbox checked={item.isActive} handleCheck={onCheck} />
        <DragButton />
        <div className={styles.configItemLabel}>{item.label}</div>
      </div>

      {item.canRight && (
        <div className={styles.arrowContainer} onClick={onRightClick}>
          <RightArrow />
        </div>
      )}
    </div>
  );
});

const ConfigContainer = SortableContainer(({ children }: PropsWithChildren<unknown>) => <div>{children}</div>);

function TreeGroupConfigView<TItem extends Record<string, unknown>>({
  config,
  handleItemCheck,
  handleLeftClick,
  handleRightClick,
  handleSortEnd,
}: Props<TItem>): ReactElement | null {
  return (
    <ConfigContainer onSortEnd={handleSortEnd} useDragHandle>
      {config.map((item, index) => (
        <ConfigItem
          key={index}
          index={index}
          item={item}
          onLeftClick={() => {
            handleLeftClick(index);
          }}
          onRightClick={() => {
            handleRightClick(index);
          }}
          onCheck={() => {
            handleItemCheck(index);
          }}
        />
      ))}
    </ConfigContainer>
  );
}

export default TreeGroupConfigView;
