import React, { useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as PlayButton } from 'assets/img/button/popup_video_play.svg';

import InternalHlsPlayer from 'components/common/internalHlsPlayer/internalHlsPlayer';

import styles from './poiVideoCameraPopup.module.scss';

type Props = {
  src: string;
  proxyPath: string | null;
};

const PoiVideoCameraPopup: React.VFC<Props> = ({ src, proxyPath }) => {
  const t = useFormatMessage();

  const [showPlayer, setShowPlayer] = useState(false);

  const renderPlayer = proxyPath ? (
    <InternalHlsPlayer className={styles.video} proxyPath={proxyPath} />
  ) : (
    <video className={styles.video} autoPlay={true} controls>
      <source src={src} />
    </video>
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('map-popup.poi.videocamera.video-stream.text')}</div>

      <div className={styles.videoContainer}>
        {!showPlayer && <PlayButton className={styles.playButton} onClick={() => setShowPlayer(true)} />}

        {showPlayer && renderPlayer}
      </div>
    </div>
  );
};

export default React.memo(PoiVideoCameraPopup);
