import React, { Fragment, forwardRef } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import Input from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import Select from 'components/common/select/select';
import {
  PoiCardEquipmentHandlers,
  PoiCardEquipmentRefs,
  PoiCardEquipmentData,
  PoiCardEquipmentAdditionalData,
} from 'components/poi/utils/types';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../poiCard.module.scss';

const cx = classNames.bind(styles);

interface Props {
  storeData: PoiCardEquipmentData;
  additionalData: PoiCardEquipmentAdditionalData;
  handlers: PoiCardEquipmentHandlers;
  refs: PoiCardEquipmentRefs;
  invalidFields: string[];
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const PoiEquipmentContent = ({ storeData, additionalData, refs, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <Fragment>
      <Input
        label={t('poi.card.equipment.field.name.label')}
        placeholder={t('poi.card.equipment.field.name.placeholder')}
        isValueError={invalidFields.includes('name')}
        isRequired={true}
        value={storeData.name}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeName(value)}
      />
      <TextArea
        label={t('poi.card.equipment.field.desc.label')}
        placeholder={t('poi.card.equipment.field.desc.placeholder')}
        isRequired={false}
        value={storeData.description}
        containerStyle={cx(styles.row, styles.rowTextarea)}
        handleChange={value => handlers.handleChangeDescription(value)}
        autoSetHeight
      />
      <Select
        options={additionalData.poiEquipmentTypeIdForSelect}
        label={t('poi.card.equipment.field.type.label')}
        placeholder={t('poi.card.equipment.field.type.placeholder')}
        isValueError={invalidFields.includes('poiEquipmentTypeId')}
        isRequired={false}
        withSearchIcon
        value={
          additionalData.poiEquipmentTypeIdForSelect.findIndex(val => val.data?.id === storeData.poiEquipmentTypeId) +
            1 || ''
        }
        containerStyle={styles.row}
        optionsContainerStyle={styles.rowSelect}
        disabled={false}
        handleChange={value => handlers.handleChangePoiEquipmentTypeId(value)}
      />
      <Select
        options={additionalData.poiVideocameraModelIdForSelect}
        label={t('poi.card.equipment.field.model.label')}
        placeholder={t('poi.card.equipment.field.model.placeholder')}
        isRequired={false}
        withSearchIcon
        value={
          additionalData.poiVideocameraModelIdForSelect.findIndex(
            val => val.data?.id === storeData.poiEquipmentModelId
          ) + 1 || ''
        }
        containerStyle={styles.row}
        optionsContainerStyle={styles.rowSelect}
        disabled={false}
        handleChange={value => handlers.handleChangePoiEquipmentModelId(value)}
      />
      <Input
        label={t('poi.card.equipment.field.coords.label')}
        placeholder={t('poi.card.equipment.field.coords.placeholder')}
        isValueError={invalidFields.includes('coordinates')}
        isRequired={true}
        value={storeData.coordinates}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeCoordinates(value)}
        withGeoIcon
      />
      <DatePicker
        dateFormat="dd.MM.yyyy, HH:mm"
        selected={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : null}
        onChange={(date: Date) => handlers.handleChangeLastCheckDate(date)}
        wrapperClassName={cx(styles.row, styles.rowDatepicker)}
        placeholderText={t('poi.card.equipment.field.last-date.placeholder')}
        customInput={
          <DatePickerCustomInput ref={refs.lastCheckDateRef} label={t('poi.card.equipment.field.last-date.label')} />
        }
        openToDate={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('poi.card.equipment.field.last-date.time.caption')}
        disabled={false}
        maxDate={storeData.nextCheckDate ? new Date(storeData.nextCheckDate) : null}
        minTime={setHours(setMinutes(new Date(), 0), 0)}
        maxTime={
          !differenceInCalendarDays(new Date(storeData.lastCheckDate), new Date(storeData.nextCheckDate))
            ? setHours(
                setMinutes(new Date(), getMinutes(new Date(storeData.nextCheckDate))),
                getHours(new Date(storeData.nextCheckDate))
              )
            : setHours(setMinutes(new Date(), 59), 23)
        }
      />
      <DatePicker
        dateFormat="dd.MM.yyyy, HH:mm"
        selected={storeData.nextCheckDate ? new Date(storeData.nextCheckDate) : null}
        onChange={(date: Date) => handlers.handleChangeNextCheckDate(date)}
        wrapperClassName={cx(styles.row, styles.rowDatepicker, styles.rowNobottommargin)}
        placeholderText={t('poi.card.equipment.field.next-date.placeholder')}
        customInput={
          <DatePickerCustomInput ref={refs.nextCheckDateRef} label={t('poi.card.equipment.field.next-date.label')} />
        }
        openToDate={new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('poi.card.equipment.field.next-date.time.caption')}
        disabled={false}
        minDate={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : null}
        minTime={
          !differenceInCalendarDays(new Date(storeData.lastCheckDate), new Date(storeData.nextCheckDate))
            ? setHours(
                setMinutes(new Date(), getMinutes(new Date(storeData.lastCheckDate))),
                getHours(new Date(storeData.lastCheckDate))
              )
            : setHours(setMinutes(new Date(), 0), 0)
        }
        maxTime={setHours(setMinutes(new Date(), 59), 23)}
      />
    </Fragment>
  );
};

export default PoiEquipmentContent;
