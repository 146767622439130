export default {
  en: {
    'search.placeholder.text': 'Search…',
    'search.more-result.btn.label': 'Show more results',
  },
  ru: {
    'search.placeholder.text': 'Поиск…',
    'search.more-result.btn.label': 'Показать больше результатов',
  },
  zh: {
    'search.placeholder.text': '搜索…',
    'search.more-result.btn.label': '顯示更多結果',
  },
  ar: {
    'search.placeholder.text': 'يبحث…',
    'search.more-result.btn.label': 'عرض المزيد من النتائج',
  },
  de: {
    'search.placeholder.text': 'Suche…',
    'search.more-result.btn.label': 'Weitere Ergebnisse anzeigen',
  },
};
