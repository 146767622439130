import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'reducers';

import { getMessages, getOneMessage, createOneMessage, deleteMessages } from './api';
import {
  MessagesState,
  Message,
  MessageFilter,
  MessagePagination,
  MessageQueryParams,
  MessageCreate,
  MessageJSONApi,
  MessageJSONApiObj,
} from './interface';

const defaultMessageFilter: MessageFilter = {
  dateStart: '',
  dateEnd: '',

  trackableUnitsIds: [],
  messageTypes: [],
};

export const defaultMessagePagination: MessagePagination = {
  page: 1,
  limit: 10,
  offset: 0,
};

const initialState: MessagesState = {
  messages: [],
  included: [],
  isMessagesLoading: false,
  needUpdate: false,

  filter: defaultMessageFilter,
  pagination: defaultMessagePagination,
  total: 0,

  isChosenMessageLoading: false,
  chosenMessage: null,
  selectedMessages: [],

  isTableMessagesShow: false,
  tableNeedUpdate: false,

  error: null,
};

function getMessageQueryParams(state: RootState) {
  const { filter, pagination } = state.messages;
  const queryParams: MessageQueryParams = {
    trackableUnitsIds: filter.trackableUnitsIds,
    messageTypes: filter.messageTypes,

    dateStart: filter.dateStart,
    dateEnd: filter.dateEnd,

    offset: pagination.offset,
    limit: pagination.limit,
  };

  return queryParams;
}

export const fetchMessages = createAsyncThunk('messages/fetchMessages', async (_, thunkApi) => {
  const { getState } = thunkApi;
  const queryParams = getMessageQueryParams(getState() as RootState);

  return await getMessages(queryParams);
});

export const fetchMessagesLazy = createAsyncThunk('messages/fetchMessagesLazy', async (_, thunkApi) => {
  const { getState } = thunkApi;
  const queryParams = getMessageQueryParams(getState() as RootState);

  return await getMessages(queryParams);
});

export const fetchOneMessage = createAsyncThunk(
  'messages/fetchOneMessage',
  async (id: string) => await getOneMessage(id)
);

export const addOneMessages = createAsyncThunk(
  'messages/addOneMessages',
  async (data: MessageCreate) => await createOneMessage(data)
);

export const removeMessages = createAsyncThunk(
  'messages/deleteOneMessage',
  async (messagesIds: number[]) => await deleteMessages(messagesIds)
);

const messagesSlice = createSlice({
  name: 'messagesSlice',
  initialState,
  reducers: {
    setMessages: (state, { payload }: { payload: Message[] }) => {
      state.messages = payload;
    },

    setMessagesNeedUpdate: (state, { payload }: { payload: boolean }) => {
      state.needUpdate = payload;
    },

    setIsMessagesTableShow: (state, { payload }: { payload: boolean }) => {
      state.isTableMessagesShow = payload;
    },
    setMessagesTableNeedUpdate: (state, { payload }: { payload: boolean }) => {
      state.tableNeedUpdate = payload;
    },

    setSelectedMessages: (state, { payload }: { payload: string[] }) => {
      state.selectedMessages = payload;
    },

    setMessagesFiltersField: (
      state,
      { payload }: { payload: { key: string; value: number | string | (number | string)[] | null } }
    ) => {
      state.filter = {
        ...state.filter,
        [payload.key]: payload.value,
      };
    },
    clearMessagesFilter: state => {
      state.filter = defaultMessageFilter;
    },

    setMessagesPaginationField: (
      state,
      { payload }: { payload: { key: string; value: number | string | string[] } }
    ) => {
      state.pagination = {
        ...state.pagination,
        [payload.key]: payload.value,
      };
    },
    clearMessagesPagination: state => {
      state.pagination = defaultMessagePagination;
    },

    removeChosenMessage: state => {
      state.chosenMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMessages.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.tableNeedUpdate = false;
        state.isMessagesLoading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, { payload }: { payload: MessageJSONApi }) => {
        state.messages = payload.data;
        state.included = payload.included || [];
        state.total = payload.meta.total;
        state.isMessagesLoading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isMessagesLoading = false;
      });
    builder
      .addCase(fetchMessagesLazy.pending, state => {
        state.error = null;
        state.needUpdate = false;
        state.tableNeedUpdate = false;
        state.isMessagesLoading = true;
      })
      .addCase(fetchMessagesLazy.fulfilled, (state, { payload }: { payload: MessageJSONApi }) => {
        state.messages = [...state.messages, ...payload.data];
        state.included = payload.included || [];
        state.total = payload.meta.total;
        state.isMessagesLoading = false;
      })
      .addCase(fetchMessagesLazy.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isMessagesLoading = false;
      });
    builder
      .addCase(fetchOneMessage.pending, state => {
        state.error = null;
        state.isChosenMessageLoading = true;
      })
      .addCase(fetchOneMessage.fulfilled, (state, { payload }: { payload: MessageJSONApiObj }) => {
        state.chosenMessage = payload;
        state.isChosenMessageLoading = false;
      })
      .addCase(fetchOneMessage.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
        state.isChosenMessageLoading = false;
      });
    builder
      .addCase(addOneMessages.pending, state => {
        state.error = null;
      })
      .addCase(addOneMessages.fulfilled, state => {
        if (state.isTableMessagesShow) {
          state.tableNeedUpdate = true;
        } else {
          state.needUpdate = true;
        }
      })
      .addCase(addOneMessages.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
    builder
      .addCase(removeMessages.pending, state => {
        state.error = null;
      })
      .addCase(removeMessages.fulfilled, state => {
        state.messages = [];
        state.selectedMessages = [];

        if (state.isTableMessagesShow) {
          state.tableNeedUpdate = true;
        } else {
          state.needUpdate = true;
        }
      })
      .addCase(removeMessages.rejected, (state, action) => {
        state.error = action.error.message ?? 'Error';
      });
  },
});

export const {
  setMessages,

  setMessagesNeedUpdate,

  setIsMessagesTableShow,
  setMessagesTableNeedUpdate,

  setSelectedMessages,

  setMessagesFiltersField,
  clearMessagesFilter,

  setMessagesPaginationField,
  clearMessagesPagination,

  removeChosenMessage,
} = messagesSlice.actions;

export default messagesSlice.reducer;
