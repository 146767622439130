import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch } from 'react-redux';
import { ButtonIconSorting } from './buttonIconSorting/buttonIconSorting';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { setSort } from 'reducers/tracks';
import { SortingType } from 'reducers/tracks/interface';
import { Sorting } from 'utils/consts';
import styles from './tracksListHeader.module.scss';
import { ReactComponent as PauseBody } from 'assets/img/player/pause-icon.svg';
import { ReactComponent as PlayHead } from 'assets/img/play-head.svg';

type Props = {
  handleCheck: () => void;
  checked: boolean;
  isIndeterminate: boolean;
  pause: boolean;
};

export function TracksListHeader({ handleCheck, checked, isIndeterminate, pause }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const handleSort = (type: SortingType) => {
    dispatch(setSort(type));
  };

  const { name } = Sorting;

  return (
    <Fragment>
      <Checkbox handleCheck={handleCheck} checked={checked} isIndeterminate={isIndeterminate} />
      {pause ? <PauseBody className={styles.headBlockIcon} /> : <PlayHead className={styles.headBlockIcon} />}
      <div className={styles.nameHead}>
        <ButtonIconSorting typeSorting={name} onClick={() => handleSort(name)}>
          <div>{t('track.list.header.track.label')}</div>
        </ButtonIconSorting>
      </div>
    </Fragment>
  );
}
