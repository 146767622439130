import { useLayoutEffect } from 'react';

import { Map } from 'ol';
import { MousePosition, ScaleLine, ZoomSlider } from 'ol/control';
import { createStringXY } from 'ol/coordinate';

export function useControls(map: Map) {
  useLayoutEffect(() => {
    map.addControl(
      new MousePosition({
        coordinateFormat: createStringXY(8),
        projection: 'EPSG:4326',
        undefinedHTML: '&nbsp;',
      })
    );
    map.addControl(
      new ScaleLine({
        units: 'metric',
      })
    );
    map.addControl(new ZoomSlider());
  }, [map]);
}
