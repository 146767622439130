// хук, добавляющий троеточие или количество неумещающихся элементов в текст
import { useEffect } from 'react';

import { getTextWidth } from './useSetTitleForWideListItem';

const DEFAULT_AMOUNT_ELEMENT_SEPARATOR = ', '; // разделитель для элементов в строке

type Options = {
  width: number; // изменяемая ширина, по которой выполняется логика добавления троеточия и количества неумещающихся элементов
  ref: React.RefObject<HTMLElement>; // ref на контейнер, содержащий обрабатываемые элементы с [data-ellipsis] и [data-amount]
  data: unknown[]; // данные, по которым обновляется текст в ref.current
  ellipsisFromElementDelta: number; // дельта в px, с учетом стоящих рядом элементов
  amountFromElementDelta: number; // дельта в px, с учетом стоящих рядом элементов
  amountSeparator?: string; // разделитель для элементов в строке
};

// Обрабатываемые элементы:
// 1) имеющие атрибуты [data-ellipsis] и [data-text] с оригинальным текстом - подставляется троеточие
// 2) имеющие атрибуты [data-amount] и [data-text] с оригинальным текстом - подставляется количество непомещающихся элементов

// ВАЖНО! textContent для данных элементов, имеющих атрибуты [data-ellipsis] и [data-amount],
// необходимо писать в аттрибуте [data-text], а само содержимое текста убрать, т.к.
// textContent зависит от [data-text] и при добавлении новых элементов либо удалении старых -
// будет на короткое время дергаться экран
function useSetEllipsisAmountsToText({
  width,
  ref,
  data,
  ellipsisFromElementDelta,
  amountFromElementDelta,
  amountSeparator = DEFAULT_AMOUNT_ELEMENT_SEPARATOR,
}: Options) {
  // добавим в списки элементов: троеточие либо количество неумещающихся в строке элементов
  // и установим в списки элементов: атрибут title, новое ограничение ширины
  useEffect(() => {
    // функция добавления максимальной ширины для определенного
    // элемента и, соответственно, троеточия
    function setEllipsis(element: Element) {
      const text = (element as HTMLElement).dataset.text;
      const font = window.getComputedStyle(element).font;
      const passWidth = Math.ceil(width - ellipsisFromElementDelta);

      if (text) {
        const widthElement = Math.ceil(getTextWidth(text, font));

        // если ширина искомого текста больше допустимой
        if (widthElement > passWidth) {
          // установка атрибута title
          !element.hasAttribute('title') && element.setAttribute('title', text);

          // получение и установка помещающегося текста
          let newText = '';

          for (let i = 0; i < text.length; i++) {
            const currentWidth = Math.ceil(getTextWidth(newText + '...', font));

            if (currentWidth > passWidth) {
              break;
            }
            newText += text[i];
          }
          element.textContent = newText + '...';
        } else {
          // либо показ всего текста
          element.hasAttribute('title') && element.removeAttribute('title');
          element.textContent = text;
        }
      }
    }

    // функция добавления максимальной ширины для определенного
    // элемента и, соответственно, количества неумещающихся элементов
    function setAmount(element: Element) {
      const text = (element as HTMLElement).dataset.text;
      const font = window.getComputedStyle(element).font;
      const passWidth = Math.ceil(width - amountFromElementDelta);

      if (text) {
        const dataList = text.split(amountSeparator).map(text => ({
          text,
          width: Math.ceil(getTextWidth(text.trim(), font)),
        }));

        // определим максимальный индекс элемента, в массиве dataList
        // который больше допустимой ширины
        let maxWidth = 0;
        let index = 0; // максимальный индекс в dataList, обозначающий содержание последнего умещающегося элемента

        for (index; index < dataList.length; index++) {
          const currentData = dataList[index];

          if (!currentData || maxWidth + currentData.width > passWidth) {
            break;
          }
          maxWidth += currentData.width;
        }

        if (index && index < dataList.length) {
          // если есть элементы в массиве, которые умещаются и меньше допустимой ширины
          const newText = dataList
            .slice(0, index)
            .map(data => data.text)
            .join(amountSeparator);

          !element.hasAttribute('title') && element.setAttribute('title', text);
          element.textContent = `${newText}, +${dataList.length - index}`;
        } else if (index && dataList.length && dataList[0].width > passWidth) {
          // если первый элемент массива больше допустимой ширины
          let newText = '';

          for (let i = 0; i < text.length; i++) {
            const currentWidth = Math.ceil(getTextWidth(`${newText}..., +${dataList.length - index}`, font));

            if (currentWidth > passWidth) {
              break;
            }
            newText += text[i];
          }
          element.setAttribute('title', text);
          element.textContent = `${newText}..., +${dataList.length - index}`;
        } else if (!index && dataList.length && dataList[0].width > passWidth) {
          // если единственный элемент массива больше допустимой ширины
          let newText = '';

          for (let i = 0; i < text.length; i++) {
            const currentWidth = Math.ceil(getTextWidth(`${newText}...`, font));

            if (currentWidth > passWidth) {
              break;
            }
            newText += text[i];
          }
          element.setAttribute('title', text);
          element.textContent = `${newText}...`;
        } else {
          // либо показ всего текста
          element.hasAttribute('title') && element.removeAttribute('title');
          element.textContent = text;
        }
      }
    }

    // если определен контейнер и есть данные
    if (ref && Array.isArray(data) ? !!data.length : !!data) {
      // определим контейнер, содержащий интересующие элементы с [data-ellipsis] и [data-amount]
      const container = ref?.current;

      if (container) {
        const ellipsisElements = container.querySelectorAll('[data-ellipsis]');
        const amountElements = container.querySelectorAll('[data-amount]');

        // обработаем элементы с учетом полученной ширины
        if (width) {
          ellipsisElements.forEach(setEllipsis);
          amountElements.forEach(setAmount);
        }
      }
    }
  }, [width, ref, data, ellipsisFromElementDelta, amountFromElementDelta, amountSeparator]);
}

export default useSetEllipsisAmountsToText;
