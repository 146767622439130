import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';

export function isEqualObjectsNoDeep(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  if (!obj1 && !obj2) {
    return true;
  }
  if ((!obj1 && obj2) || (obj1 && !obj2)) {
    return false;
  }

  const keysObj1 = Object.keys(obj1);
  const keysObj2 = Object.keys(obj2);
  if (keysObj1.length !== keysObj2.length) {
    return false;
  }

  const reskeys = differenceWith(keysObj1, keysObj2, isEqual);
  if (reskeys.length) {
    return false;
  }

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}
