import { MessagesCreateStates } from './types';

export enum MESSAGE_TYPES {
  INCOME = 'INCOME',
  OUTCOME = 'OUTCOME',
  COORDINATES = 'COORDINATES',
}

export enum MESSAGE_TYPES_TO_TRANSLATE {
  INCOME = 'messages.left-bar.content.list.item.type.incoming.text',
  OUTCOME = 'messages.left-bar.content.list.item.type.outgoing.text',
  COORDINATES = 'messages.left-bar.content.list.item.type.coordinates.text',
}

export enum MESSAGE_FORMATS {
  GPS = 'GPS',
  AmountSatellite = 'AmountSatellite',
  Height = 'Height',
  HDOP = 'HDOP',
}

export enum MESSAGE_FORMATS_TO_TRANSLATES {
  GPS = 'messages.left-bar.filter.type.gps.text',
  AmountSatellite = 'messages.left-bar.filter.type.amount-satellite.text',
  Height = 'messages.left-bar.filter.type.height.text',
  HDOP = 'messages.left-bar.filter.type.hdop.text',
}

// message create

export const defaultStateMessageCreate: MessagesCreateStates = {
  text: '',
  objects: [],
};
