import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import TransportDriverCard from './components/customHandbookCards/transportDriverCard/transportDriverCard';
import DefaultHandbooksCard from './components/defaultHandbookCard/defaultHandbookCard';

const HandbooksCard: React.VFC = () => {
  const chosenHandbookName = useSelector((state: RootState) => state.handbooks.chosenHandbookName);

  const handbookKeyName = chosenHandbookName?.keyName;

  switch (handbookKeyName) {
    case HANDBOOK_KEYS.transportDrivers:
      return <TransportDriverCard />;
    default:
      return <DefaultHandbooksCard />;
  }
};

export default HandbooksCard;
