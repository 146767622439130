import { useState } from 'react';
import { SortEndHandler } from 'react-sortable-hoc';
import arrayMove from 'array-move';

export interface VisibleColumnsConfigItem<TItem extends Record<string, unknown>> {
  column: keyof TItem;
  isVisible: boolean;
}

export interface VisibleColumnsConfigViewItem<TItem extends Record<string, unknown>>
  extends VisibleColumnsConfigItem<TItem> {
  label: string;
}

export default function useVisibleColumnsConfig<TItem extends Record<string, unknown>>(
  initialConfig: VisibleColumnsConfigItem<TItem>[],
  configLabels: string[]
) {
  const [config, setConfig] = useState<VisibleColumnsConfigViewItem<TItem>[]>(() =>
    initialConfig.map((item, index) => ({
      ...item,
      label: configLabels[index],
    }))
  );

  const configToSave: VisibleColumnsConfigItem<TItem>[] = config.map(item => ({
    column: item.column,
    isVisible: item.isVisible,
  }));

  const handleIsVisibleClick = (index: number) => {
    const nextConfig = [...config];

    nextConfig[index].isVisible = !nextConfig[index].isVisible;

    setConfig(nextConfig);
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const nextConfig = arrayMove(config, oldIndex, newIndex);

    setConfig(nextConfig);
  };

  return [config, configToSave, handleIsVisibleClick, handleSortEnd] as const;
}
