import React, { Fragment, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { Alert } from 'components/common/alert/alert';
import { Controls } from '../components/controls/controls';
import { Header } from '../components/header/header';
import { TabsNav } from '../components/tabsNav/tabsNav';
import { TabStat } from '../components/tabStat/tabStat';
import styles from './accountCreation.module.scss';
import { TabInfo } from '../components/tabInfo/tabInfo';
import { TabRoles } from '../components/tabRoles/tabRoles';

import { RootState } from 'reducers';
import { addAccount, removeAccount, setChosenAccount, updateAccount } from 'reducers/accounts';
import { closeModal } from 'reducers/modal';

import AccessEntity from 'utils/accessEntity';
import { ActiveTab } from '../utils/consts';
import { useUserMainTab, useUserRolesTab } from '../utils/hooks';

enum alertContinueStateEnum {
  none = '',
  delete = 'delete',
  cancel = 'cancel',
}

const AccountCreation = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const [activeTab, setActiveTab] = useState(ActiveTab.info);

  const [showLocalAlert, setShowLocalAlert] = useState(alertContinueStateEnum.none);

  const usersPermissions = useSelector((state: RootState) => state.user.permissions.users);
  const usersAccess = useMemo(() => new AccessEntity(usersPermissions), [usersPermissions]);

  const mainTab = useUserMainTab();
  const rolesTab = useUserRolesTab();

  const handleCancel = () => {
    const hasChanges = mainTab.hasChanges() || rolesTab.hasChanges();

    if (!hasChanges) {
      dispatch(setChosenAccount(null));
      return dispatch(closeModal());
    }
    setShowLocalAlert(alertContinueStateEnum.cancel);
  };

  const handleDelete = () => {
    if (mainTab.userId) {
      setShowLocalAlert(alertContinueStateEnum.delete);
    }
  };

  const handleSave = () => {
    if (mainTab.validateRequiredFields()) {
      if (activeTab !== ActiveTab.info) {
        return setActiveTab(ActiveTab.info);
      }
      return;
    }

    const roles = rolesTab.getRolesForUpdate();

    if (mainTab.hasSelectedUser) {
      if (!!mainTab.userId && usersAccess.isAllowUpdate()) {
        dispatch(updateAccount({ ...mainTab.accountValues, id: mainTab.userId, ...roles }));
        dispatch(setChosenAccount(null));
      }
    } else {
      if (usersAccess.isAllowCreate()) {
        dispatch(addAccount({ account: mainTab.accountValues, ...roles }));
      }
    }
    dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    setShowLocalAlert(alertContinueStateEnum.none);
  };

  const handleAlertContinue = () => {
    switch (showLocalAlert) {
      case alertContinueStateEnum.cancel:
        if (mainTab.hasSelectedUser) {
          dispatch(setChosenAccount(null));
        }
        break;

      case alertContinueStateEnum.delete:
        if (mainTab.userId && usersAccess.isAllowDelete()) {
          dispatch(removeAccount(mainTab.userId));
        }
        break;

      default:
        break;
    }
    return dispatch(closeModal());
  };

  return (
    <Fragment>
      <Header title={t('accounts.card.user.header.label')} onCancel={handleCancel} />
      <div className={styles.modalBody}>
        <div className={styles.modalWrapper}>
          <TabsNav activeTab={activeTab} setActiveTab={setActiveTab} showStat={mainTab.hasSelectedUser} />
          {activeTab === ActiveTab.info && (
            <TabInfo
              values={mainTab.accountValues}
              handlers={mainTab.accountHandlers}
              invalidFields={mainTab.invalidFields}
              showBanSwitch={true}
              organizationOptions={mainTab.organizationOptions}
              departmentOptions={mainTab.departmentOptions}
              positionOptions={mainTab.positionOptions}
            />
          )}
          {activeTab === ActiveTab.roles && (
            <TabRoles
              roles={rolesTab.roles}
              isLoading={rolesTab.isLoading}
              selectedRoles={rolesTab.selectedRoles}
              handleRoleSelection={rolesTab.handleRoleSelection}
            />
          )}
          {activeTab === ActiveTab.stat && <TabStat />}
        </div>
      </div>
      <Controls
        onSave={handleSave}
        onCancel={handleCancel}
        onDelete={handleDelete}
        saveButtonText={
          mainTab.hasSelectedUser
            ? t('accounts.card.user.footer.btn.update.label')
            : t('accounts.card.user.footer.btn.create.label')
        }
      />
      {showLocalAlert && (
        <Alert
          title={
            showLocalAlert === alertContinueStateEnum.delete
              ? t('accounts.card.alert.title.delete.text')
              : t('accounts.card.alert.title.text')
          }
          infoText={
            showLocalAlert === alertContinueStateEnum.delete
              ? t('accounts.card.alert.info.delete.text')
              : t('accounts.card.alert.info.text')
          }
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default AccountCreation;
