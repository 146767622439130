import React from 'react';
import { useSelector } from 'react-redux';

import TrackerModalForm from 'components/trackers/trackers.modal';
import EmployeeModalForm from 'components/monitoring/employee/employee.modal';
import TransportModalForm from 'components/monitoring/transport/transport.modal';
import { ObjectListModal } from 'components/monitoring/objectList/objectList.modal';
import GeozoneGroupModal from 'components/geozones/components/groupCreation/groupCreation';
import RoleCreation from 'components/accounts/roleCreation/roleCreation';
import RoleSelectUser from 'components/accounts/roleSelectAccount/roleSelectAccount';
import AccountCreation from 'components/accounts/accountCreation/accountCreation';
import VideoSettings from 'components/map/components/videoSettingsPopup/videoSettings';
import NotificationModalForm from 'components/notifications/components/notificationModal/notificationModal';
import HandbooksStatisticModal from 'components/handbooks/components/handbooksTable/components/handbooksStatisticModal/handbooksStatisticModal';
import { ModalLayout } from 'components/common/modalLayout/modalLayout';
import { ListConfigModal } from 'components/monitoring/listConfig/listConfig';
import { GeozoneListSortingModal } from 'components/geozones/components/geozoneListSortingModal/geozoneListSortingModal';
import { UserSettings } from 'components/userSettings/userSettings';
import { ListConfigGroupingModal } from 'components/notifications/components/listConfig/listConfig';
import { RecordsTemplateModal } from 'components/records/components/recordsTemplateModal/recordsTemplateModal';
import MessageCreatingModal from 'components/messages/components/messageCreatingModal/messageCreatingModal';
import UsersConfigModal from 'components/accounts/usersList/components/usersConfigModal/usersConfigModal';

import {
  EMPLOYEE,
  TRACKER,
  TRANSPORT,
  OBJECT_LIST,
  GEOZONE_GROUP,
  ROLE,
  ROLE_TO_USER,
  ACCOUNT,
  MONITORING_GROUPING,
  GEOZONES_GROUPING,
  USER_SETTINGS,
  VIDEO_SETTINGS,
  NOTIFICATIONS,
  NOTIFICATIONS_GROUPING,
  STATISTIC_HANDBOOK,
  CREATE_TEMPLATE_RECORDS,
  CREATE_MESSAGE,
  USERS_LIST_CONFIG,
} from 'utils/consts';

import { RootState } from 'reducers';

const Modal = () => {
  const { show, type, which } = useSelector((state: RootState) => state.modal);

  if (!show) {
    return <></>;
  }

  switch (type) {
    case TRACKER: {
      return (
        <ModalLayout>
          <TrackerModalForm />
        </ModalLayout>
      );
    }
    case EMPLOYEE: {
      return (
        <ModalLayout>
          <EmployeeModalForm />
        </ModalLayout>
      );
    }
    case TRANSPORT: {
      return (
        <ModalLayout>
          <TransportModalForm />
        </ModalLayout>
      );
    }
    case OBJECT_LIST: {
      return <ObjectListModal />;
    }
    case GEOZONE_GROUP: {
      return <GeozoneGroupModal />;
    }

    case ROLE: {
      return (
        <ModalLayout>
          <RoleCreation />
        </ModalLayout>
      );
    }

    case ROLE_TO_USER: {
      return (
        <ModalLayout>
          <RoleSelectUser name={which} />
        </ModalLayout>
      );
    }

    case ACCOUNT: {
      return (
        <ModalLayout>
          <AccountCreation />
        </ModalLayout>
      );
    }

    case MONITORING_GROUPING: {
      return <ListConfigModal />;
    }

    case GEOZONES_GROUPING: {
      return <GeozoneListSortingModal />;
    }

    case USER_SETTINGS: {
      return (
        <ModalLayout>
          <UserSettings />
        </ModalLayout>
      );
    }

    case VIDEO_SETTINGS: {
      return <VideoSettings which={which} />;
    }

    case NOTIFICATIONS: {
      return (
        <ModalLayout>
          <NotificationModalForm />
        </ModalLayout>
      );
    }

    case NOTIFICATIONS_GROUPING: {
      return <ListConfigGroupingModal />;
    }

    case STATISTIC_HANDBOOK: {
      return (
        <ModalLayout noFixedHeight>
          <HandbooksStatisticModal />
        </ModalLayout>
      );
    }

    case CREATE_TEMPLATE_RECORDS: {
      return (
        <ModalLayout>
          <RecordsTemplateModal />
        </ModalLayout>
      );
    }

    case CREATE_MESSAGE: {
      return (
        <ModalLayout>
          <MessageCreatingModal />
        </ModalLayout>
      );
    }

    case USERS_LIST_CONFIG: {
      return <UsersConfigModal />;
    }

    default: {
      return <></>;
    }
  }
};

export default React.memo(Modal);
