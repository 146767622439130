import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { fetchAccountsShort } from 'reducers/accounts';
import { AccountShort } from 'reducers/accounts/interface';
import { clearPrintableTemplateFileId, sendPrintableTemplateFile, generateTags } from 'reducers/records';

import { Option } from 'components/common/select/select';
import { ValuesType } from 'components/common/multipleSelect/multipleSelect';
import { defaultMainTabState as defaults, SupportedPrintableTemplateTypes } from 'components/records/utils/consts';

import AccessEntity from 'utils/accessEntity';
import { MAX_UPLOAD_FILE_SIZE } from 'utils/consts';

export default function useMainTab() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [name, setName] = useState(defaults.name);
  const [description, setDescription] = useState(defaults.description);
  const [file, setFile] = useState<File | null>(defaults.file);
  const [chosenUsers, setChosenUsers] = useState<number[]>(defaults.chosenUsers);

  const [chosenUsersForSelect, setChosenUsersForSelect] = useState<Option[]>(defaults.chosenUsersForSelect);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const userPermission = useSelector((state: RootState) => state.user.permissions.users);
  const userAccess = useMemo(() => new AccessEntity(userPermission), [userPermission]);
  const { accountsShortList }: { accountsShortList: AccountShort[] } = useSelector((state: RootState) => state.account);
  const { chosenTemplate } = useSelector((state: RootState) => state.records);

  useEffect(() => {
    (async () => {
      if (!accountsShortList.length) {
        await dispatch(fetchAccountsShort());
      }
    })();

    return () => {
      dispatch(clearPrintableTemplateFileId());
    };
  }, [dispatch, accountsShortList]);

  useEffect(() => {
    if (userAccess.isAllowRead() && accountsShortList.length && !chosenUsersForSelect.length) {
      setChosenUsersForSelect(
        accountsShortList.map((acc, i) => ({
          value: i + 1,
          label: `${acc.attributes.firstName} ${acc.attributes.secondName} ${acc.attributes.lastName}`,
          data: { ...acc },
        }))
      );
    }
  }, [userAccess, accountsShortList, chosenUsersForSelect]);

  useEffect(() => {
    if (chosenTemplate) {
      const attr = chosenTemplate.attributes;
      const activeUsers = attr.users;

      setName(attr.name);
      setDescription(attr.description);

      if (accountsShortList.length && activeUsers.length) {
        setChosenUsers(activeUsers);
      }
    }
  }, [chosenTemplate, accountsShortList]);

  const handleChangeName = (value: string) => setName(value);
  const handleChangeDescription = (value: string) => setDescription(value);
  const handleChangePrintableTemplateFile = (file: File) => {
    if (!SupportedPrintableTemplateTypes.includes(file.type)) {
      return toast.error(t('toast.records.template-modal.main.printable-template.file-type.error.text'));
    }
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      return toast.error(t('toast.records.template-modal.main.printable-template.file-size.error.text'));
    }
    setFile(file);
    dispatch(sendPrintableTemplateFile({ key: 'file', file }));
  };

  const handleClearPrintableTemplateFile = () => {
    setFile(null);
    dispatch(clearPrintableTemplateFileId());
  };

  const handleChangeUsers = (values: ValuesType) => {
    setChosenUsers(values.map(value => Number(value.data?.id) || -1));
  };

  const handleClickNewTemplatePF = () => {
    dispatch(generateTags());
  };

  const validateRequiredFields = () => {
    const newInvalidFields = [];

    if (!name || name.length < 3) {
      newInvalidFields.push('mainName');
    }
    if (name.length < 3) {
      toast.error(t('toast.records.template.name-less.error.text'));
    }
    // if (!file) newInvalidFields.push('mainAttachedFile');
    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = {
      name: defaults.name,
      description: defaults.description,
      chosenUsers: defaults.chosenUsers,
    };

    if (chosenTemplate) {
      const { attributes } = chosenTemplate;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        chosenUsers: attributes.users,
      };
    }
    return !isEqual(
      {
        name,
        description,
        chosenUsers,
      },
      comparedObj
    );
  };

  return {
    states: {
      name,
      description,
      chosenUsers,
      chosenUsersForSelect,
      file,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePrintableTemplateFile,
      handleClearPrintableTemplateFile,
      handleChangeUsers,
      handleClickNewTemplatePF,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
