export function findBatteryStatus(percentage: number) {
  if (percentage === 0) {
    return 0;
  }
  if (percentage > 0 && percentage < 26) {
    return 1;
  }
  if (percentage > 25 && percentage < 51) {
    return 2;
  }
  if (percentage > 50 && percentage < 76) {
    return 3;
  }

  return 4;
}
