import React, { ChangeEvent, useState, KeyboardEvent, useRef } from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as Warning } from 'assets/img/warning.svg';
import { ReactComponent as Search } from 'assets/img/search_page.svg';
import { ReactComponent as Calendar } from 'assets/img/calendar.svg';
import { ReactComponent as TimeIcon } from 'assets/img/time.svg';
import { ReactComponent as ClosedEye } from 'assets/img/closed-eye.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';
import { ReactComponent as GeoMarkerIcon } from 'assets/img/geomarker.svg';
import { ReactComponent as AttachFileIcon } from 'assets/img/attach_file_icon.svg';
import { ReactComponent as Clear } from 'assets/img/broom.svg';

import styles from './input.module.scss';

export enum InputsTypesEnum {
  COLOR = 'color',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  NUMBER = 'number',
  RESET = 'reset',
}

const cx = classNames.bind(styles);

interface InputProps {
  inputType?: InputsTypesEnum;

  label: string;
  placeholder: string;
  value: string | number;

  isRequired?: boolean;
  isValueError?: boolean;

  icon?: JSX.Element;
  maxLength?: number;
  pattern?: string;
  disabled?: boolean;
  isGood?: boolean;
  isPassword?: boolean;

  withSearchIcon?: boolean;
  withCalendarIcon?: boolean;
  witchTimeIcon?: boolean;
  withGeoIcon?: boolean;
  withHideTextIcon?: boolean;
  withAttachFileIcon?: boolean;
  withClearBtn?: boolean;

  iconClick?: () => void;
  handleInputChange?: (value: string) => void;
  handleFileChange?: (value: File) => void;
  onKeyDown?: (ev: KeyboardEvent<HTMLInputElement>) => void;
  handleClear?: () => void;

  customStyle?: string;
  'data-cy'?: string;
}

const Input = (props: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const wrapClasses = cx(styles.wrapper, props.customStyle, {
    [styles.wrapperWarning]: props.isValueError,
    [styles.wrapperFocused]: isFocused,
    [styles.wrapperDisabled]: props.disabled,
  });
  const inputWrapLabel = cx(styles.supportingLabel, {
    [styles.supportingLabelIsFileInput]: props.inputType && props.inputType === InputsTypesEnum.FILE,
    [styles.supportingLabelIsEstablishedValue]:
      props.inputType && props.inputType === InputsTypesEnum.FILE && props.value,
  });
  const inputClasses = cx(styles.input, {
    [styles.isGood]: props.isGood,
    [styles.isFileInput]: props.inputType && props.inputType === InputsTypesEnum.FILE,
  });
  const labelClasses = cx(styles.label, {
    [styles.labelFocused]: isFocused,
    [styles.labelDisabled]: props.disabled,
  });
  const iconClasses = cx(styles.iconBase, { [styles.iconBaseFocused]: isFocused });

  const defaultInputType = props.isPassword ? 'password' : 'text';
  const inputType = props.inputType || defaultInputType;

  const inputId = `input-${props.label}-${inputType}-${Math.random()}`;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (props.inputType && props.inputType === InputsTypesEnum.FILE && ev.target.files) {
      if (ev.target.files.length > 0) {
        if (props.handleFileChange) {
          return props.handleFileChange(ev.target.files[0]);
        }
      }
    }
    if (props.handleInputChange) {
      return props.handleInputChange(ev.target.value);
    }
  };

  const handleClick = () => {
    const input = inputRef?.current;

    if (input) {
      if (props.inputType !== InputsTypesEnum.FILE) {
        input.focus();
      }
    }
  };

  return (
    <div className={wrapClasses} onClick={handleClick}>
      {props.isRequired && <Warning className={styles.iconWarning} />}
      {props.withSearchIcon && <Search className={iconClasses} onClick={props.iconClick} />}
      {props.withCalendarIcon && <Calendar className={iconClasses} onClick={props.iconClick} />}
      {props.witchTimeIcon && <TimeIcon className={iconClasses} onClick={props.iconClick} />}
      {props.withHideTextIcon &&
        (props.isPassword ? (
          <ClosedEye className={iconClasses} onClick={props.iconClick} />
        ) : (
          <OpenedEye className={iconClasses} onClick={props.iconClick} />
        ))}
      {props.withGeoIcon && <GeoMarkerIcon className={iconClasses} onClick={props.iconClick} />}
      <div className={labelClasses}>{props.label}</div>
      <label
        className={inputWrapLabel}
        htmlFor={inputId}
        title={props.inputType && props.inputType === InputsTypesEnum.FILE ? String(props.value) : ''}
      >
        {props.value || props.placeholder}
        {props.withAttachFileIcon && <AttachFileIcon className={iconClasses} onClick={props.iconClick} />}
      </label>
      <div>
        <input
          ref={inputRef}
          id={inputId}
          data-cy={props['data-cy']}
          type={inputType}
          placeholder={props.placeholder}
          value={props.inputType && props.inputType === InputsTypesEnum.FILE ? '' : props.value ?? ''}
          className={inputClasses}
          required={props.isRequired}
          maxLength={props.maxLength || 128}
          pattern={props.pattern || undefined}
          disabled={props.disabled || false}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={props.onKeyDown}
        />
        {props.withClearBtn && (
          <button
            type="button"
            disabled={props.disabled}
            className={cx(iconClasses, styles.iconClear)}
            onClick={props.handleClear}
          >
            <Clear />
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
