import React, { useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { RootState } from 'reducers';

import { ReactComponent as EditIcon } from 'assets/img/gear.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';

import { RadioButton } from 'components/common/radioButton/radioButton';
import { RecordsTemplatesHandlers, RecordsTemplatesStates } from 'components/records/utils/types';
import { Alert } from 'components/common/alert/alert';

import AccessEntity from 'utils/accessEntity';

import styles from './recordsTemplates.module.scss';

const cx = classNames.bind(styles);

type Props = {
  states: RecordsTemplatesStates;
  handlers: RecordsTemplatesHandlers;
};

export const RecordsTemplates = ({ states, handlers }: Props) => {
  const t = useFormatMessage();
  const recordsPermissions = useSelector((state: RootState) => state.user.permissions.records);
  const recordsAccess = useMemo(() => new AccessEntity(recordsPermissions), [recordsPermissions]);

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{t('records.left-sb.template.header.label')}</h4>
      <ul className={styles.templatesList}>
        {recordsAccess.isAllowRead() &&
          states.templates.map((template, i) => (
            <li key={`template-row-${i}`} className={styles.template}>
              <div className={styles.templateLeftSide} onClick={() => handlers.handleChooseTemplate(template.id)}>
                <RadioButton name="recordsTemplate" checked={template.id === states.currentTemplateId} />
                <div className={styles.templateName} title={template.name}>
                  {template.name}
                </div>
              </div>
              <div className={styles.templateRightSide}>
                <EditIcon
                  className={cx(styles.templateIcon, {
                    [styles.templateIconDisabled]: !recordsAccess.isAllowUpdate(),
                  })}
                  onClick={() => handlers.handleChangeTemplate(template.id)}
                />
                <DeleteIcon
                  className={cx(styles.templateIcon, {
                    [styles.templateIconDisabled]: !recordsAccess.isAllowDelete(),
                  })}
                  onClick={() => handlers.handleSetTemplateDeleteId(template.id)}
                />
              </div>
            </li>
          ))}
      </ul>
      {!!states.templateDeleteId && (
        <Alert
          title={t('records.card.template-list.alert.delete.title')}
          infoText={t('records.card.template-list.alert.delete.text')}
          handleCancel={handlers.handleDeleteAlertCancel}
          handleContinue={handlers.handleDeleteAlertContinue}
        />
      )}
    </div>
  );
};
