import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './columnTable.module.scss';

const cx = classNames.bind(styles);

type PropsType = {
  title: string;
  customStyle?: string;
  withVerticalScroll?: boolean;
  resizable?: boolean;
  children: React.ReactNode;
};

const ColumnTable = ({ title, customStyle, withVerticalScroll, resizable = false, children }: PropsType) => {
  const [isResize, setIsResize] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleMouseUp(event: MouseEvent) {
      event.stopPropagation();
      if (isResize) {
        setIsResize(false);
      }
    }

    function handlerMouseMove(event: MouseEvent) {
      event.stopPropagation();
      const wrapper = wrapperRef?.current || null;

      if (wrapper && isResize) {
        const pageX = event.pageX; // координата Х мыши относительно левой границы документа
        if (pageX > window.innerWidth) {
          return;
        }

        const currentLeft = wrapper.offsetLeft; // количество px от левой границы документа до элемента
        const currentScrollLeft = wrapper.scrollLeft; // количество px прокрутки по горизонтали
        const currentWidth = wrapper.getBoundingClientRect().width; // текущая ширина wrapper
        const changedWidth = pageX - currentLeft + currentScrollLeft; // измененная (вычисленная) ширина wrapper
        const deltaWidth = changedWidth - currentWidth; // разница м/у текущей и измененной шириной wrapper
        const columnSibling = wrapper.nextElementSibling; // сосед для wrapper (чтобы у него изменить ширину на deltaWidth)

        wrapper.style.width = `${changedWidth}px`;
        if (columnSibling) {
          const columnSiblingWidth = columnSibling.getBoundingClientRect().width;
          columnSibling.setAttribute('style', `width: ${columnSiblingWidth - deltaWidth}px`);
        }
      }
    }

    if (resizable) {
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handlerMouseMove);
    }

    return () => {
      if (resizable) {
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handlerMouseMove);
      }
    };
  }, [wrapperRef, resizable, isResize]);

  const handleResizeColumnMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!resizable) {
      return;
    }
    event.stopPropagation();
    if (!isResize) {
      setIsResize(true);
    }
  };

  return (
    <div
      className={cx(styles.wrapper, customStyle, {
        [styles.isResize]: isResize,
      })}
      ref={wrapperRef}
    >
      <div className={styles.title}>{title}</div>
      <div
        className={cx(styles.children, {
          [styles.withVerticalScroll]: withVerticalScroll,
        })}
      >
        {children}
        {resizable && <div className={styles.resizabledItem} onMouseDown={handleResizeColumnMouseDown}></div>}
      </div>
    </div>
  );
};

export default ColumnTable;
