import React, { MouseEvent, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import sortBy from 'lodash/sortBy';

import { ReactComponent as CloseIcon } from 'assets/img/close-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/hide-arrow.svg';

import Button from 'components/common/button/button';
import Search from 'components/common/search/search';

import { RootState } from 'reducers';
import { closeModal, showModal } from 'reducers/modal';
import { removeChosenRole } from 'reducers/roles';
import { RoleToUsersListItem } from 'reducers/accounts/interface';
import { setRoleToUsers, setRoleToUsersAvailableItems, setRoleToUsersChosenItems } from 'reducers/accounts';

import { ROLE } from 'utils/consts';
import { getNextSelectionState } from 'utils/common/selectionState';

import styles from './roleSelectAccount.module.scss';

const cx = classNames.bind(styles);

interface Props {
  name: string;
}

const RoleSelectUser: React.FC<Props> = ({ name }: { name: string }) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const chosenRole = useSelector((state: RootState) => state.role.chosenRole);
  const roleFormIsInitialized = useSelector((state: RootState) => state.role.roleFormIsInitialized);

  const availableItems = useSelector((state: RootState) => state.account.roleToUsersAvailableItems);
  const chosenItems = useSelector((state: RootState) => state.account.roleToUsersChosenItems);

  // miss deps to save previous state for rollback
  const previousAvailableItems = useMemo(() => [...availableItems], []);
  const previousChosenItems = useMemo(() => [...chosenItems], []);

  const [searchValue, setSearchValue] = useState('');
  const [selectedAvailable, setSelectedAvailable] = useState<Set<string>>(() => new Set());
  const [selectedChosen, setSelectedChosen] = useState<Set<string>>(() => new Set());

  const visibleAvailableItems = useMemo(() => {
    const result = availableItems.filter(item =>
      item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );

    return sortBy(result, 'name');
  }, [availableItems, searchValue]);

  const visibleChosenItems = useMemo(() => {
    const result = chosenItems.filter(item => item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

    return sortBy(result, 'name');
  }, [chosenItems, searchValue]);

  const handleClose = () => {
    if (roleFormIsInitialized) {
      // rollback
      dispatch(setRoleToUsersAvailableItems(previousAvailableItems));
      dispatch(setRoleToUsersChosenItems(previousChosenItems));
      dispatch(showModal({ type: ROLE, which: '' }));
    } else {
      dispatch(removeChosenRole());
      dispatch(closeModal());
    }
  };

  const handleAvailableItemClick = (e: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    setSelectedAvailable(
      getNextSelectionState<RoleToUsersListItem>(visibleAvailableItems, selectedAvailable, e, id, index)
    );
    setSelectedChosen(new Set());
  };

  const handleChosenItemClick = (e: MouseEvent<HTMLLIElement>, id: string, index: number) => {
    setSelectedChosen(getNextSelectionState<RoleToUsersListItem>(visibleChosenItems, selectedChosen, e, id, index));
    setSelectedAvailable(new Set());
  };

  const handleMoveRight = () => {
    const nextAvailableIems: RoleToUsersListItem[] = [];
    const nextChosenItems: RoleToUsersListItem[] = [...chosenItems];

    for (const item of availableItems) {
      if (selectedAvailable.has(item.id)) {
        nextChosenItems.push(item);
      } else {
        nextAvailableIems.push(item);
      }
    }

    dispatch(setRoleToUsersAvailableItems(nextAvailableIems));
    dispatch(setRoleToUsersChosenItems(nextChosenItems));

    setSelectedAvailable(new Set());
  };

  const handleMoveLeft = () => {
    const nextAvailableIems: RoleToUsersListItem[] = [...availableItems];
    const nextChosenItems: RoleToUsersListItem[] = [];

    for (const item of chosenItems) {
      if (selectedChosen.has(item.id)) {
        nextAvailableIems.push(item);
      } else {
        nextChosenItems.push(item);
      }
    }

    dispatch(setRoleToUsersAvailableItems(nextAvailableIems));
    dispatch(setRoleToUsersChosenItems(nextChosenItems));

    setSelectedChosen(new Set());
  };

  const selectAvailableAll = () => {
    setSelectedAvailable(new Set(visibleAvailableItems.map(item => item.id)));
    setSelectedChosen(new Set());
  };

  const selectChosenAll = () => {
    setSelectedChosen(new Set(visibleChosenItems.map(item => item.id)));
    setSelectedAvailable(new Set());
  };

  const handleSave = () => {
    if (roleFormIsInitialized) {
      dispatch(showModal({ type: ROLE, which: '' }));
    } else if (chosenRole) {
      dispatch(setRoleToUsers(chosenRole));
      dispatch(removeChosenRole());
      dispatch(closeModal());
    } else {
      dispatch(closeModal());
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>{`${t('accounts.card.roles.assign-role.header.label')} ${name}`}</div>
        <CloseIcon className={styles.headerCloseIcon} onClick={handleClose} />
      </div>

      <div className={styles.inputsGroup}>
        <Search handleChange={setSearchValue} style={styles.inputAndSearchWrapSearch} />
      </div>

      <div className={styles.modalBody}>
        <div className={styles.groupsList}>
          <div className={styles.groupsListDisabled}>
            <div className={styles.groupsListHeader}>{t('accounts.card.roles.assign-role.available-users.text')}</div>
            <ul className={styles.groupsListItemsWrap}>
              {visibleAvailableItems.map((item, index) => (
                <li
                  key={item.id}
                  className={cx(styles.groupsListItem, {
                    [styles.groupsListItemSelected]: selectedAvailable.has(item.id),
                  })}
                  onClick={e => handleAvailableItemClick(e, item.id, index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            <Button
              white
              text={t('accounts.card.roles.assign-role.available-users.btn.text')}
              customStyle={styles.selectAllButton}
              onClick={selectAvailableAll}
            />
          </div>

          <div className={styles.moveButtons}>
            <div className={styles.moveButtonsBtn} onClick={handleMoveRight}>
              <ArrowIcon className={styles.moveButtonsBtnToRight} />
            </div>
            <div className={styles.moveButtonsBtn} onClick={handleMoveLeft}>
              <ArrowIcon className={styles.moveButtonsBtnToLeft} />
            </div>
          </div>

          <div className={styles.groupsListActive}>
            <div className={styles.groupsListHeader}>{`${t(
              'accounts.card.roles.assign-role.chosen-users.text'
            )} ${name}`}</div>
            <ul className={styles.groupsListItemsWrap}>
              {visibleChosenItems.map((item, index) => (
                <li
                  key={item.id}
                  className={cx(styles.groupsListItem, {
                    [styles.groupsListItemSelected]: selectedChosen.has(item.id),
                  })}
                  onClick={e => handleChosenItemClick(e, item.id, index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            <Button
              white
              text={t('accounts.card.roles.assign-role.chosen-users.btn.text')}
              customStyle={styles.selectAllButton}
              onClick={selectChosenAll}
            />
          </div>
        </div>
      </div>

      <div className={styles.modalFooter}>
        <div className={styles.modalFooterButtonsWrap}>
          <Button white text={t('accounts.card.roles.assign-role.footer.btn.cancel.label')} onClick={handleClose} />
          <Button blue text={t('accounts.card.roles.assign-role.footer.btn.save.label')} onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default RoleSelectUser;
