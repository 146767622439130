import React, { Fragment, forwardRef } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { differenceInCalendarDays, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import Input from 'components/common/input/input';
import { TextArea } from 'components/common/textArea/textArea';
import Select from 'components/common/select/select';
import RangeInputs from 'components/common/rangeInputs/rangeInputs';
import {
  PoiCardSensorHandlers,
  PoiCardSensorRefs,
  PoiCardSensorData,
  PoiCardSensorAdditionalData,
} from 'components/poi/utils/types';

import { RootState } from 'reducers';

import { getCurrentLocaleForDatePicker } from 'translate';

import styles from '../poiCard.module.scss';

const cx = classNames.bind(styles);

interface Props {
  storeData: PoiCardSensorData;
  additionalData: PoiCardSensorAdditionalData;
  handlers: PoiCardSensorHandlers;
  refs: PoiCardSensorRefs;
  invalidFields: string[];
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerCustomInput = forwardRef((props: any, _ref: any) => {
  return (
    <div className={styles.datePicker} onClick={props.onClick} ref={_ref}>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        withCalendarIcon
        customStyle={styles.datePickerInput}
        isRequired={props.isRequired}
        isValueError={props.isValueError}
      />
    </div>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const PoiSensorContent = ({ storeData, additionalData, refs, handlers, invalidFields }: Props) => {
  const t = useFormatMessage();

  const { locale } = useSelector((state: RootState) => state.user.userPreferences);

  return (
    <Fragment>
      <Input
        label={t('poi.card.sensor.field.name.label')}
        placeholder={t('poi.card.sensor.field.name.placeholder')}
        isValueError={invalidFields.includes('name')}
        isRequired={true}
        value={storeData.name}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeName(value)}
      />
      <TextArea
        label={t('poi.card.sensor.field.desc.label')}
        placeholder={t('poi.card.sensor.field.desc.placeholder')}
        isRequired={false}
        value={storeData.description}
        containerStyle={cx(styles.row, styles.rowTextarea)}
        handleChange={value => handlers.handleChangeDescription(value)}
        autoSetHeight
      />
      <Select
        options={additionalData.poiSensorTypeIdForSelect}
        label={t('poi.card.sensor.field.type.label')}
        placeholder={t('poi.card.sensor.field.type.placeholder')}
        isValueError={invalidFields.includes('poiSensorTypeId')}
        isRequired={true}
        withSearchIcon
        value={
          additionalData.poiSensorTypeIdForSelect.findIndex(val => val.data?.id === storeData.poiSensorTypeId) + 1 || ''
        }
        containerStyle={styles.row}
        optionsContainerStyle={styles.elementRowSelect}
        disabled={false}
        handleChange={value => handlers.handleChangePoiSensorTypeId(value)}
      />
      <Select
        options={additionalData.poiSensorModelIdForSelect}
        label={t('poi.card.sensor.field.model.label')}
        placeholder={t('poi.card.sensor.field.model.placeholder')}
        isRequired={false}
        withSearchIcon
        value={
          additionalData.poiSensorModelIdForSelect.findIndex(val => val.data?.id === storeData.poiSensorModelId) + 1 ||
          ''
        }
        containerStyle={styles.row}
        optionsContainerStyle={styles.elementRowSelect}
        disabled={false}
        handleChange={value => handlers.handleChangePoiSensorModelId(value)}
      />
      <Input
        label={t('poi.card.sensor.field.link.label')}
        placeholder={t('poi.card.sensor.field.link.placeholder')}
        isValueError={invalidFields.includes('dataSourceUrl')}
        isRequired={true}
        value={storeData.dataSourceUrl}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeDataSourceUrl(value)}
      />
      <Input
        label={t('poi.card.sensor.field.coords.label')}
        placeholder={t('poi.card.sensor.field.coords.placeholder')}
        isValueError={invalidFields.includes('coordinates')}
        isRequired={true}
        value={storeData.coordinates}
        customStyle={styles.row}
        disabled={false}
        handleInputChange={value => handlers.handleChangeCoordinates(value)}
        withGeoIcon
      />
      <div className={cx(styles.row, styles.rowSome)}>
        <RangeInputs
          label={t('poi.card.sensor.field.distance.label')}
          valueFrom={String(storeData.measureRangeFrom)}
          valueTo={String(storeData.measureRangeTo)}
          isValueError={invalidFields.includes('measureRanges')}
          handleInputFromChange={value => handlers.handleChangeMeasureRangeFrom(value)}
          handleInputToChange={value => handlers.handleChangeMeasureRangeTo(value)}
          disabled={false}
          placeholderFrom={t('poi.card.sensor.field.distance.placeholder.from.text')}
          placeholderTo={t('poi.card.sensor.field.distance.placeholder.to.text')}
          wrapperStyle={styles.rowSomeInput}
        />
        <Input
          label={t('poi.card.sensor.field.limit.label')}
          placeholder={t('poi.card.sensor.field.limit.placeholder')}
          isValueError={invalidFields.includes('measureLimit')}
          value={String(storeData.measureLimit)}
          disabled={false}
          handleInputChange={value => handlers.handleChangeMeasureLimit(value)}
          customStyle={cx(styles.rowSomeInput, styles.rowSomeInputFixWidth)}
          maxLength={10}
        />
      </div>
      <Select
        options={additionalData.poiSensorMeasureUnitIdForSelect}
        label={t('poi.card.sensor.field.unit-measurement.label')}
        placeholder={t('poi.card.sensor.field.unit-measurement.placeholder')}
        isValueError={invalidFields.includes('poiSensorMeasureUnitId')}
        isRequired={true}
        withSearchIcon
        value={
          additionalData.poiSensorMeasureUnitIdForSelect.findIndex(
            val => val.data?.id === storeData.poiSensorMeasureUnitId
          ) + 1 || ''
        }
        containerStyle={styles.row}
        optionsContainerStyle={styles.elementRowSelect}
        disabled={false}
        handleChange={value => handlers.handleChangePoiSensorMeasureUnitId(value)}
      />
      <DatePicker
        dateFormat="dd.MM.yyyy, HH:mm"
        selected={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : null}
        onChange={(date: Date) => handlers.handleChangeLastCheckDate(date)}
        wrapperClassName={cx(styles.row, styles.rowDatepicker)}
        placeholderText={t('poi.card.sensor.field.last-date.placeholder')}
        customInput={
          <DatePickerCustomInput
            ref={refs.lastCheckDateRef}
            label={t('poi.card.sensor.field.last-date.label')}
            isValueError={invalidFields.includes('lastCheckDate')}
          />
        }
        openToDate={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('poi.card.sensor.field.last-date.time.caption')}
        disabled={false}
        maxDate={storeData.nextCheckDate ? new Date(storeData.nextCheckDate) : null}
        minTime={setHours(setMinutes(new Date(), 0), 0)}
        maxTime={
          !differenceInCalendarDays(new Date(storeData.lastCheckDate), new Date(storeData.nextCheckDate))
            ? setHours(
                setMinutes(new Date(), getMinutes(new Date(storeData.nextCheckDate))),
                getHours(new Date(storeData.nextCheckDate))
              )
            : setHours(setMinutes(new Date(), 59), 23)
        }
      />
      <DatePicker
        dateFormat="dd.MM.yyyy, HH:mm"
        selected={storeData.nextCheckDate ? new Date(storeData.nextCheckDate) : null}
        onChange={(date: Date) => handlers.handleChangeNextCheckDate(date)}
        wrapperClassName={cx(styles.row, styles.rowDatepicker, styles.rowNobottommargin)}
        placeholderText={t('poi.card.sensor.field.next-date.placeholder')}
        customInput={
          <DatePickerCustomInput
            ref={refs.nextCheckDateRef}
            label={t('poi.card.sensor.field.next-date.label')}
            isValueError={invalidFields.includes('nextCheckDate')}
          />
        }
        openToDate={storeData.nextCheckDate ? new Date(storeData.nextCheckDate) : new Date()}
        locale={getCurrentLocaleForDatePicker(locale)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('poi.card.sensor.field.next-date.time.caption')}
        disabled={false}
        minDate={storeData.lastCheckDate ? new Date(storeData.lastCheckDate) : null}
        minTime={
          !differenceInCalendarDays(new Date(storeData.lastCheckDate), new Date(storeData.nextCheckDate))
            ? setHours(
                setMinutes(new Date(), getMinutes(new Date(storeData.lastCheckDate))),
                getHours(new Date(storeData.lastCheckDate))
              )
            : setHours(setMinutes(new Date(), 0), 0)
        }
        maxTime={setHours(setMinutes(new Date(), 59), 23)}
      />
    </Fragment>
  );
};

export default PoiSensorContent;
