import React, { Fragment, useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Marker } from 'assets/img/marker.svg';

import { Spinner } from 'components/common/spinner/spinner';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { NOTIFICATION_TYPES_ENUM, NOTIFICATION_TYPES_ENUM_NAMES } from 'components/notifications/utils/consts';
import NotificationPopup from 'components/map/components/notificationPopup/notificationPopup';

import { RootState } from 'reducers';
import {
  fetchNotificationHistoryLogs,
  fetchNotificationHistoryLogsLazy,
  setFilterField,
  setMarkerNotificationLog,
  setSelectedHistoryLogs,
} from 'reducers/notifications';

import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import usePrevious from 'utils/usePrevious';
import useObserveToWidthLeftSideBar from 'utils/useObserveToWidthLeftSideBar';
import useSetEllipsisAmountsToText from 'utils/useSetEllipsisAmountsToText';

import styles from './historyList.module.scss';

const cx = classNames.bind(styles);

const MAIN_HEADER_HEIGHT = 50;
const DEFAULT_LIMIT = 5;
const DEFAULT_PAGE = 1;

function NotificationHistoryList() {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const {
    isHistoryLogsLoading,
    historyLogs,
    selectedHistoryLogs,
    historyLogsTotal,
    historyLogsFilter,
    markerNotificationLog,
  } = useSelector((state: RootState) => state.notifications);

  const headCheckboxStatus = selectedHistoryLogs.length === historyLogs.length && historyLogs.length > 0;
  const headCheckboxStatusIndeterminate =
    selectedHistoryLogs.length > 0 && selectedHistoryLogs.length < historyLogs.length;

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [popupCoords, setPopupCoords] = useState({
    mouseCoords: { x: 0, y: 0 },
    mapCoords: { x: 0, y: 0 },
  });

  const prevFilter = usePrevious(historyLogsFilter);

  // получение информации о historyLogs при изменении фильтра
  useEffect(() => {
    if (
      prevFilter?.page !== historyLogsFilter.page ||
      (!isHistoryLogsLoading && !historyLogs.length && historyLogsTotal >= 0)
    ) {
      const { page = 1 } = historyLogsFilter;

      if (page === 1) {
        if (!markerNotificationLog) {
          dispatch(fetchNotificationHistoryLogs(historyLogsFilter));
        } else {
          dispatch(fetchNotificationHistoryLogsLazy(historyLogsFilter));
        }
      } else if (page > 1) {
        dispatch(fetchNotificationHistoryLogsLazy(historyLogsFilter));
      }
    }
  }, [
    dispatch,
    historyLogsFilter,
    markerNotificationLog,
    prevFilter,
    isHistoryLogsLoading,
    historyLogs,
    historyLogsTotal,
  ]);

  // показ попапа в случае изменения координат
  useEffect(() => {
    setIsPopupShow(true);
  }, [popupCoords.mouseCoords]);

  const listRef = useRef(null);

  // получим актуальную ширину левого сайдбара для правильного отображения текста в контейнере
  const width = useObserveToWidthLeftSideBar(listRef);

  // навесим атрибут title на интересующие нас элементы (с атрибутами [data-ellipsis] и [data-amount]), которые
  // не помещаются по ширине в контейнере, а также добавим к ним троеточие или количество неотображаемых элементов
  useSetEllipsisAmountsToText({
    width,
    ref: listRef,
    data: historyLogs,
    ellipsisFromElementDelta: 270,
    amountFromElementDelta: 0,
  });

  // обработчик клика по элементу массива history logs
  const handleItemClick = () => {
    setIsPopupShow(false);
  };

  // обработчик выбора всех элементов history logs
  const handleCheckAll = () => {
    if (headCheckboxStatus) {
      dispatch(setSelectedHistoryLogs([]));
    } else {
      dispatch(setSelectedHistoryLogs(historyLogs.map(log => log.id)));
    }
  };

  // обработчик выбора элементов history logs
  const handleCheck = (id: number) => {
    const newSelectedHistoryLogs = [...selectedHistoryLogs];

    if (newSelectedHistoryLogs.includes(id)) {
      const foundIndex = newSelectedHistoryLogs.indexOf(id);

      if (foundIndex > -1) {
        newSelectedHistoryLogs.splice(foundIndex, 1);
      }
    } else {
      newSelectedHistoryLogs.push(id);
    }
    dispatch(setSelectedHistoryLogs(newSelectedHistoryLogs));
  };

  // обработчик клика по именованной ссылке элемента history logs
  const handleClickName = (evt: React.MouseEvent, id: number) => {
    evt.stopPropagation();
    setIsPopupShow(false);
    if (!selectedHistoryLogs.includes(id)) {
      handleCheck(id);
    }

    const foundLog = historyLogs.find(hLog => hLog.id === id);

    if (foundLog) {
      const coordinatesLog = foundLog.attributes.coords.split(',').map(coordsStr => parseFloat(coordsStr));
      const coords = {
        mouseCoords: { x: evt.clientX, y: evt.clientY - MAIN_HEADER_HEIGHT },
        mapCoords: { x: coordinatesLog[0], y: coordinatesLog[1] },
      };

      dispatch(setMarkerNotificationLog(foundLog));
      setPopupCoords(coords);
    }
  };

  // обработчик скролла списка истории логов нотификаций
  const handleScroll = (evt: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const historyList = evt.target as Element;
    const { page = DEFAULT_PAGE, limit = DEFAULT_LIMIT } = historyLogsFilter;
    const allPages = Math.ceil(historyLogsTotal / limit);

    if (historyList && page && historyLogs.length) {
      const historyListHeight = historyList.getBoundingClientRect().height;
      const historyListScrollHeight = historyList.scrollHeight;
      const historyListScrollTop = historyList.scrollTop;

      if (historyListScrollTop >= historyListScrollHeight - historyListHeight && page < allPages) {
        dispatch(setFilterField({ key: 'page', value: page + 1 }));
      }
    }
  };

  return (
    <Fragment>
      <div className={styles.history}>
        <div className={styles.historyHeader}>
          <div className={styles.historyHeaderLeft}>
            <Checkbox
              checked={headCheckboxStatus}
              isIndeterminate={headCheckboxStatusIndeterminate}
              handleCheck={handleCheckAll}
              wrapperStyle={styles.historyHeaderCheckbox}
            />
            <div className={styles.historyHeaderNumber}>{t('notifications.history.list.header.number.text')}</div>
            <div className={styles.historyHeaderName}>{t('notifications.history.list.header.name.text')}</div>
          </div>
          <div className={styles.historyHeaderRight}>
            <div className={styles.historyHeaderStatus}>{t('notifications.history.list.header.status.text')}</div>
            <div className={styles.historyHeaderMap}>{t('notifications.history.list.header.map.text')}</div>
          </div>
        </div>
        <ul className={styles.historyList} onScroll={handleScroll} ref={listRef}>
          {historyLogs.map((log, i) => {
            const logDate = getFullDateFromStr(log.attributes.eventTime);

            return (
              <li className={styles.historyItem} key={`notification-log-${i}`} onClick={handleItemClick}>
                <div className={styles.historyItemHeader}>
                  <div className={styles.historyItemHeaderLeft} title={log.attributes.text}>
                    <Checkbox
                      checked={selectedHistoryLogs.includes(log.id)}
                      handleCheck={() => handleCheck(log.id)}
                      wrapperStyle={styles.historyItemHeaderCheckbox}
                    />
                    <div className={styles.historyItemHeaderNumber}>{i + 1}</div>
                    <div
                      className={styles.historyItemHeaderName}
                      onClick={evt => handleClickName(evt, log.id)}
                      data-ellipsis
                      data-text={log.attributes.text}
                    />
                  </div>
                  <div className={styles.historyItemHeaderRight}>
                    <div
                      className={cx(styles.historyItemHeaderStatus, {
                        [styles.historyItemHeaderStatusRead]: log.attributes.isDone,
                        [styles.historyItemHeaderStatusNoRead]: !log.attributes.isDone,
                      })}
                    >
                      {log.attributes.isDone
                        ? t('notifications.history.list.status.read.text')
                        : t('notifications.history.list.status.no-read.text')}
                    </div>
                    <Marker className={styles.historyItemHeaderMap} onClick={() => handleCheck(log.id)} />
                  </div>
                </div>
                <div className={styles.historyItemData}>
                  <div className={styles.historyHeaderCheckbox} />
                  <div className={styles.historyHeaderNumber} />
                  <div className={styles.historyItemDataDate}>
                    <span className={styles.historyItemDataBold}>
                      {t('notifications.history.list.data.date.text')}
                      {': '}
                    </span>
                    <span>
                      {logDate.date} {logDate.time}
                    </span>
                  </div>
                  <div className={styles.historyItemDataType}>
                    <span className={styles.historyItemDataBold}>
                      {t('notifications.history.list.data.type.text')}
                      {': '}
                    </span>
                    <span>
                      {t(NOTIFICATION_TYPES_ENUM_NAMES[NOTIFICATION_TYPES_ENUM[log.attributes.notificationType]])}
                    </span>
                  </div>
                  <div className={styles.historyItemDataObject}>
                    <span className={styles.historyItemDataBold}>
                      {t('notifications.history.list.data.object.text')}
                      {': '}
                    </span>
                    <span>{log.attributes.aggregatedName}</span>
                  </div>
                </div>
              </li>
            );
          })}
          {isHistoryLogsLoading && (
            <li className={styles.historyItem}>
              <Spinner />
            </li>
          )}
        </ul>
      </div>

      {isPopupShow && <NotificationPopup onCancel={() => setIsPopupShow(false)} markerCoords={popupCoords} />}
    </Fragment>
  );
}

export default React.memo(NotificationHistoryList);
