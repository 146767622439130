import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { RootState } from 'reducers';
import { ChosenPoi, PoiVideocameraPoint } from 'reducers/poi/interface';
import { setPoiCardData, setPoiCardFieldData } from 'reducers/poi';

import { Option } from 'components/common/select/select';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';

import { getCurrentLocale } from 'translate';
import { URL_REGEXP } from 'utils/consts';

import { defaultPoiVideoCameraState as defaults } from '../utils/consts';
import { validateCoordinates } from '../utils/helpers';
import { PoiCardVideoCameraData } from '../utils/types';

export default function usePoiVideoCamera(chosenPoi: ChosenPoi | null) {
  const dispatch = useDispatch();

  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);
  const poiCardData = useSelector((state: RootState) => state.poi.poiCardData as PoiCardVideoCameraData);
  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const [poiVideocameraModelIdForSelect, setPoiVideocameraModelIdForSelect] = useState<Option[]>([]);

  useEffect(() => {
    const handbookVideoCameraModels = handbookData?.poiVideoCameraModels;

    if (handbookVideoCameraModels) {
      setPoiVideocameraModelIdForSelect(
        handbookVideoCameraModels.map((data, i) => ({
          value: i + 1,
          label: getTranslateFromLanguageKey(data.attributes.name, userLanguageKey),
          data: {
            id: data.id,
          },
        }))
      );
    }
  }, [handbookData, userLanguageKey]);

  useEffect(() => {
    if (chosenPoi?.relationships.hasOwnProperty('poiVideocameraPoint')) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiVideocameraPoint = relationships.poiVideocameraPoint.data as PoiVideocameraPoint;

      dispatch(
        setPoiCardData({
          name: attributes.name,
          description: attributes.description,
          poiVideocameraModelId: poiFields.poiVideocameraModelId,
          dataSourceUrl: poiFields.dataSourceUrl,
          coordinates: `${attributes.lon}, ${attributes.lat}`,
          angle: poiFields.angle,
          distance: poiFields.distance,
          direction: poiFields.direction,
        })
      );
    }
  }, [dispatch, chosenPoi]);

  const handleChangeName = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'name',
        value,
      })
    );

  const handleChangeDescription = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'description',
        value,
      })
    );

  const handleChangePoiVideocameraModelId = (value: string) => {
    const newValue = Number(value);

    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'poiVideocameraModelId',
          value: newValue,
        })
      );
    }
  };

  const handleChangeDataSourceUrl = (value: string) =>
    dispatch(
      setPoiCardFieldData({
        key: 'dataSourceUrl',
        value,
      })
    );

  const handleChangeCoordinates = (value: string) => {
    const [lon, lat]: number[] = value.split(',').map(c => Number(c.trim()));

    if (!isNaN(lon) && !isNaN(lat)) {
      dispatch(
        setPoiCardFieldData({
          key: 'coordinates',
          value,
        })
      );
    }
  };

  const handleChangeAngle = (value: string) => {
    const newValue = Number(value);

    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'angle',
          value: newValue % 360.0,
        })
      );
    }
  };

  const handleChangeDistance = (value: string) => {
    const newValue = Number(value);

    if (newValue && !isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'distance',
          value: newValue >= 0 ? newValue : -newValue,
        })
      );
    }
  };

  const handleChangeDirection = (value: string) => {
    const newValue = Number(value);

    if (!isNaN(newValue)) {
      dispatch(
        setPoiCardFieldData({
          key: 'direction',
          value: newValue,
        })
      );
    }
  };

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const validateRequiredFields = () => {
    const newInvalidFields: string[] = [];

    if (!poiCardData.name) {
      newInvalidFields.push('name');
    }
    if (!poiCardData.dataSourceUrl || !URL_REGEXP.test(poiCardData.dataSourceUrl)) {
      newInvalidFields.push('dataSourceUrl');
    }
    if (!validateCoordinates(poiCardData.coordinates)) {
      newInvalidFields.push('coordinates');
    }

    return newInvalidFields;
  };

  const hasChanges = () => {
    let comparedObj = { ...defaults };

    if (chosenPoi) {
      const { attributes, relationships } = chosenPoi;
      const poiFields: PoiVideocameraPoint = relationships.poiVideocameraPoint.data as PoiVideocameraPoint;

      comparedObj = {
        name: attributes.name,
        description: attributes.description,
        poiVideocameraModelId: poiFields.poiVideocameraModelId,
        dataSourceUrl: poiFields.dataSourceUrl,
        coordinates: `${attributes.lon}, ${attributes.lat}`,
        angle: poiFields.angle,
        distance: poiFields.distance,
        direction: poiFields.direction,
      };
    }
    return !isEqual(poiCardData, comparedObj);
  };

  return {
    additionalData: {
      poiVideocameraModelIdForSelect,
    },
    handlers: {
      handleChangeName,
      handleChangeDescription,
      handleChangePoiVideocameraModelId,
      handleChangeDataSourceUrl,
      handleChangeCoordinates,
      handleChangeAngle,
      handleChangeDistance,
      handleChangeDirection,
    },
    invalidFields,
    setInvalidFields,
    validateRequiredFields,
    hasChanges,
  };
}
