import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import isEqual from 'lodash.isequal';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classnames from 'classnames/bind';

import { ReactComponent as Search } from 'assets/img/search_page.svg';
import { ReactComponent as Warning } from 'assets/img/warning.svg';
import { ReactComponent as Clear } from 'assets/img/broom.svg';

import { Option } from 'components/common/select/select';
import SelectOptionsPortal from 'components/common/select/components/selectOptions/selectOptions';

import usePrevious from 'utils/usePrevious';
import { useSetTitleForWideListGroupItem } from 'utils/useSetTitleForWideListItem';

import styles from './multipleSelectGroup.module.scss';

const cx = classnames.bind(styles);

export interface MultipleSelectGroupTranslate {
  name: string;
  translate: string;
}

export interface MultipleSelectGroupOption {
  name: string;
  options: Option[];
}

export interface MultipleSelectGroupValue {
  name: string;
  labels?: string[]; // для передачи строковых значений
  ids?: (number | string)[]; // для передачи ids
}

type SelectContainerType = {
  x: number;
  y: number;
  height: number;
  width: number;
};

interface MultipleSelectGroupProps {
  label: string;
  placeholder: string;

  options: MultipleSelectGroupOption[];
  translateGroups: MultipleSelectGroupTranslate[]; // для указания переводов групп в полях: опции и значения

  values?: MultipleSelectGroupOption[];

  isRequired: boolean;
  isValueError?: boolean;

  hideGroupNames?: boolean;
  withSearchIcon?: boolean;
  withClearBtn?: boolean;

  disabled?: boolean;
  optionIsInline?: boolean;

  noWrappedItems?: string[]; // определяет какие элементы опций не переносить на другую строку
  noWrappedNames?: string[]; // определяет какие имена групп опций не переносить на другую строку

  handleChange?: (values: MultipleSelectGroupOption[]) => void;

  wrapperStyle?: string;
  valuesWrapperStyle?: string;
}

// функция получения выбранных значений из опций (с учетом группы опций)
export function getValuesForMultipleSelectGroup({
  options,
  values,
}: {
  options: MultipleSelectGroupOption[];
  values: MultipleSelectGroupValue[];
}) {
  return values.reduce((pred, { name, labels = [], ids = [] }) => {
    const foundOptionObject = options.find(optionsObject => optionsObject.name === name);

    if (foundOptionObject) {
      const optionGroup = {
        name,
        options: ids.length
          ? ids.map((id, i) => ({
              value: i + 1,
              label: foundOptionObject.options.find(option => (option.data?.id as number) === id)?.label ?? '',
              data: { id },
            }))
          : labels
              .map((label, i) => {
                const found = foundOptionObject.options.find(option => option.label === label);

                if (found) {
                  return {
                    ...found, // + заполняется полем data, если оно есть в found
                    value: i + 1,
                    label: found.label,
                  };
                }
                return {
                  value: i + 1,
                  label: '',
                };
              })
              .filter(option => option.label),
      };
      if (optionGroup.options.length) {
        pred.push(optionGroup);
      }
    }
    return pred;
  }, [] as MultipleSelectGroupOption[]);
}

// функция фильтрации опций (с учетом группы опций)
function filteringGroupOptions(groupOptions: MultipleSelectGroupOption[], values: MultipleSelectGroupOption[]) {
  if (values.length) {
    const newGroupOptions: MultipleSelectGroupOption[] = [];

    groupOptions.forEach(groupOption => {
      const foundValueGroup = values.find(value => value.name === groupOption.name);

      if (foundValueGroup?.options.length) {
        const name = foundValueGroup.name;
        const options: Option[] = [];

        groupOption.options.forEach(option => {
          const foundValue = foundValueGroup.options.find(valueOption => valueOption.label === option.label);

          if (!foundValue) {
            options.push(option);
          }
        });
        newGroupOptions.push({ name, options });
      } else {
        newGroupOptions.push({ ...groupOption });
      }
    });
    return newGroupOptions;
  }
  return groupOptions;
}

const MultipleSelectGroup = (props: MultipleSelectGroupProps) => {
  const t = useFormatMessage();

  const [isFocused, setIsFocused] = useState(false);
  const [selectOptions, setSelectOptions] = useState<MultipleSelectGroupOption[]>([]);
  const [showOptions, setShowOptions] = useState(false);

  // -------- set scrolltop values container ----------

  const valuesRef = React.createRef<HTMLUListElement>();
  const prevValues = usePrevious<MultipleSelectGroupOption[]>(props.values ?? []);

  useEffect(() => {
    if (valuesRef) {
      const valuesContainer = valuesRef.current;
      const currentValues = props.values;

      if (valuesContainer && currentValues?.length && prevValues) {
        const isEqualValues = isEqual(currentValues, prevValues);

        if (!isEqualValues) {
          valuesContainer.scrollTop = valuesContainer.scrollHeight;
        }
      }
    }
  }, [valuesRef, props.values, prevValues]);

  // -------- options ----------

  const selectContainerRef = React.createRef<HTMLDivElement>(); // контейнер c input
  const optionsListRef = React.createRef<HTMLUListElement>(); // ul опций

  const [selectParams, setSelectParams] = useState<SelectContainerType | null>(null);

  const getSelectParams = useCallback(() => {
    if (selectContainerRef) {
      const selectContainer = selectContainerRef.current;

      if (selectContainer) {
        const { x, y, height, width } = selectContainer.getBoundingClientRect();

        setSelectParams({ x, y, height, width });
      }
    }
  }, [selectContainerRef]);

  // установка троеточия и атрибута title для элементов в
  // списке опций, которые не умещаются по ширине
  useSetTitleForWideListGroupItem({
    wrapperListGroupRef: optionsListRef,
    excludeWrappedItems: props.noWrappedItems || [],
    excludeWrappedGroupNames: props.noWrappedNames || [],
  });

  // -------- common ----------

  useEffect(() => {
    if (showOptions) {
      if (!selectParams) {
        getSelectParams();
      }
    } else {
      setSelectParams(null);
    }
  }, [getSelectParams, selectParams, showOptions]);

  useEffect(() => {
    if (props.options.length) {
      if (props.values?.length) {
        setSelectOptions(filteringGroupOptions(props.options, props.values));
      } else {
        setSelectOptions(props.options);
      }
    } else {
      setSelectOptions([]);
    }
  }, [props.options, props.values]);

  const wrapperClasses = cx(styles.container, props.wrapperStyle, {
    [styles.containerWarning]: props.isValueError,
    [styles.containerFocused]: (!props.disabled && showOptions) || isFocused,
    [styles.containerDisabled]: props.disabled,
  });

  // флаг показа названия групп в опциях
  const canShowNames = props.options.length > 1;

  const handleDeleteValue = <SVGElement, MouseEvent>(
    e: React.MouseEvent<SVGElement, MouseEvent>,
    obj: {
      name: string;
      option: Option;
    }
  ) => {
    if (props.disabled) {
      return;
    }
    e.stopPropagation();
    setIsFocused(false);

    if (props.values) {
      const { name, option } = obj;
      const newGroupValues = [...props.values];
      const foundValueGroupIndex = newGroupValues.findIndex(value => value.name === name);

      if (foundValueGroupIndex > -1) {
        const newOptions = newGroupValues[foundValueGroupIndex].options.filter(opt => opt.value !== option.value);

        newGroupValues[foundValueGroupIndex].options = newOptions;
      }
      if (props.handleChange) {
        props.handleChange(newGroupValues);
      }
    }
  };

  const handleWrapperClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsFocused(true);
    setShowOptions(!showOptions);
  };

  const handleValuesClick = () => {
    setIsFocused(true);
  };

  const handleClearValues = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.handleChange) {
      props.handleChange([]);
    }
  };

  const handleMouseLeave = () => {
    setIsFocused(false);
    setShowOptions(false);
  };

  // -------- select ----------

  const selectOptionsIsShow = showOptions && selectParams && !props.disabled && !!props.options.length;

  const selectWrapperClasses = cx(styles.selectWrapper, {
    [styles.selectWrapperDisabled]: props.disabled,
  });
  const selectButtonClasses = cx(styles.selectButtonBase, {
    [styles.selectButtonBaseDisabled]: props.disabled || !props.values?.length,
  });
  const selectIconClasses = cx(styles.selectIconBase, {
    [styles.selectIconBaseFocused]: showOptions,
    [styles.selectIconBaseDisabled]: props.disabled,
  });
  const selectLabelClasses = cx(styles.selectLabel, {
    [styles.selectLabelFocused]: showOptions && !props.disabled,
    [styles.selectLabelDisabled]: props.disabled,
  });

  // обработчик выбора опций
  const handleItemClick = (e: React.MouseEvent, groupIndex: number, chosenValue: Option) => {
    e.stopPropagation();
    if (props.handleChange && props.values) {
      const values = [...props.values];
      const nameInOptions = selectOptions[groupIndex]?.name;

      if (nameInOptions) {
        const foundIndexItemValues = values.findIndex(value => value.name === nameInOptions);

        if (foundIndexItemValues > -1) {
          values[foundIndexItemValues].options.push(chosenValue);
        } else {
          values.push({
            name: nameInOptions,
            options: [chosenValue],
          });
        }
        props.handleChange(values);
      }
    }
    setShowOptions(false);
  };

  return (
    <div
      className={wrapperClasses}
      onClick={handleWrapperClick}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.select}>
        <div className={selectWrapperClasses} ref={selectContainerRef}>
          {props.withClearBtn && (
            <button
              type="button"
              disabled={props.disabled || !props.values?.length}
              className={selectButtonClasses}
              onClick={handleClearValues}
            >
              <Clear />
            </button>
          )}
          {props.isRequired && <Warning className={styles.selectIconWarning} />}
          <Search className={selectIconClasses} />
          <div className={selectLabelClasses}>{props.label}</div>
          <input
            type="text"
            placeholder={props.placeholder}
            className={styles.selectInput}
            required={props.isRequired}
            disabled={props.disabled || false}
          />
        </div>

        {selectOptionsIsShow && (
          <SelectOptionsPortal
            coords={{ x: selectParams?.x ?? 0, y: (selectParams?.y ?? 0) + (selectParams?.height ?? 0) }}
            isShow={showOptions}
          >
            <ul
              className={styles.selectOptionsGroupList}
              style={{ width: selectParams ? `${selectParams.width}px` : `100%` }}
              ref={optionsListRef}
            >
              {selectOptions.map((group, groupIndex) => {
                const showGroupName =
                  props.options.length > 1 && !!group.options.length && !props.hideGroupNames && canShowNames;
                const foundTranslate =
                  props.translateGroups.find(translate => translate.name === group.name)?.translate ?? '';

                return (
                  <li
                    key={`group_option_item_${groupIndex}-${group.name}`}
                    className={styles.selectOptionsGroupListItem}
                  >
                    {showGroupName && <div className={styles.selectOptionName}>{foundTranslate}</div>}
                    <ul className={styles.selectOptionsList}>
                      {group.options.map((option, index) => (
                        <li
                          key={`option_item_${index}-${option.value}`}
                          className={cx(styles.selectOptionsListItem, {
                            [styles.selectOptionsListItemSub]: showGroupName,
                          })}
                          onMouseDown={e => handleItemClick(e, groupIndex, option)}
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </SelectOptionsPortal>
        )}
      </div>

      <div className={styles.values} onClick={handleValuesClick}>
        <ul className={cx(styles.valuesGroupList, props.valuesWrapperStyle)} ref={valuesRef}>
          {props.values?.map((group, groupIndex) => {
            const showGroupName =
              props.options.length > 1 && !!group.options.length && !props.hideGroupNames && canShowNames;
            const foundTranslate =
              props.translateGroups.find(translate => translate.name === group.name)?.translate ?? '';

            return (
              <li key={`group_value_item_${groupIndex}-${group.name}`} className={styles.valuesGroupListItem}>
                {showGroupName && <div className={styles.valuesGroupListName}>{foundTranslate}</div>}
                <ul className={styles.valuesList}>
                  {group.options.map((option, index) => {
                    const label = option.label;

                    return (
                      <li
                        key={`value_item_${index}-${option.value}`}
                        className={cx(styles.valuesListItem, {
                          [styles.valuesListItemSub]: showGroupName,
                          [styles.valuesListItemSubDisabled]: props.disabled,
                          [styles.valuesListItemInline]: props.optionIsInline,
                          [styles.valuesListItemDeleted]: !label,
                        })}
                        title={!label ? t('multiple-select-group.deleted-value.tooltip.text') : ''}
                      >
                        <div className={styles.valuesListItemLabel}>
                          {label || t('multiple-select-group.deleted-value.text')}
                        </div>
                        <div className={styles.valuesListItemCloseBtn}>
                          <AiFillCloseCircle
                            color="#999999"
                            className={cx(styles.closeModalIcon, {
                              [styles.closeModalIconDeleted]: !label,
                              [styles.closeModalIconDisabled]: props.disabled,
                            })}
                            onClick={e => handleDeleteValue(e, { name: group.name, option })}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(MultipleSelectGroup);
