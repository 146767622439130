import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Button from 'components/common/button/button';
import styles from './controls.module.scss';

interface Props {
  onDelete: () => void;
  onCancel: () => void;
  onSave?: () => void;
  saveButtonText?: string;
}

export const Controls = ({ onSave, onDelete, onCancel, saveButtonText }: Props) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalControls}>
      <Button red text={t('accounts.card.user.footer.btn.delete.label')} onClick={onDelete} />
      <Button white text={t('accounts.card.user.footer.btn.cancel.label')} onClick={onCancel} />
      {onSave && <Button blue text={saveButtonText ?? ''} onClick={onSave} />}
    </div>
  );
};
