import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'reducers';
import {
  setMessages,
  clearMessagesFilter,
  clearMessagesPagination,
  setIsMessagesTableShow,
  setMessagesNeedUpdate,
  removeMessages,
  setSelectedMessages,
  removeChosenMessage,
} from 'reducers/messages';

import AccessEntity from 'utils/accessEntity';

export default function useMessagesLeftBar() {
  const dispatch = useDispatch();

  const { selectedMessages } = useSelector((state: RootState) => state.messages);
  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);

  const [showPopup, setShowPopup] = useState(false);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      dispatch(setMessages([]));
      dispatch(clearMessagesFilter());
      dispatch(clearMessagesPagination());
    };
  }, [dispatch]);

  const handleShowTable = () => {
    dispatch(removeChosenMessage());
    dispatch(setSelectedMessages([]));
    dispatch(setIsMessagesTableShow(true));
  };

  const handleSave = () => {
    // TODO: after backend api
  };

  const handleDeleteItems = () => {
    setDeletedIds(selectedMessages);
  };

  const handleDeleteAlertCancel = () => {
    setDeletedIds([]);
  };

  const handleDeleteAlertContinue = () => {
    if (deletedIds.length && messagesAccess.isAllowDelete()) {
      dispatch(removeMessages(deletedIds.map(id => Number(id))));
    }
    setDeletedIds([]);
  };

  const handleForming = () => {
    dispatch(setMessages([]));
    dispatch(setMessagesNeedUpdate(true));
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return {
    states: {
      showPopup,
      deletedIds,
    },
    handlers: {
      handleShowTable,
      handleDeleteItems,
      handleDeleteAlertCancel,
      handleDeleteAlertContinue,
      handleSave,
      handleForming,
      handleClosePopup,
    },
  };
}
