import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Feature, Map } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { RootState } from 'reducers';

import { HEATMAP } from 'utils/consts';

import { SelectedEmployeeMarker, SelectedTransportMarker } from '../map.types';

const heatSource: VectorSource<Point> = new VectorSource();

export function useHeatMapType(map: Map, markers: Array<SelectedEmployeeMarker | SelectedTransportMarker>) {
  const heatMapType = useSelector((state: RootState) => state.map.heatMapType);
  const withHeatMap = useSelector((state: RootState) => state.addons.withHeatMap);

  useEffect(() => {
    if (withHeatMap) {
      const heatLayer = new HeatmapLayer({
        source: heatSource,
        radius: 40,
        blur: 30,
        opacity: 0.3,
      });
      map.addLayer(heatLayer);

      return () => {
        map.removeLayer(heatLayer);
        heatLayer.dispose();
      };
    }
  }, [map, withHeatMap]);

  useEffect(() => {
    if (withHeatMap) {
      let features: Feature<Point>[] = [];
      switch (heatMapType) {
        case HEATMAP.default:
          features = markers.map((item: SelectedEmployeeMarker | SelectedTransportMarker) => {
            return new Feature({
              geometry: item.data ? new Point(fromLonLat(item.data)) : new Point(fromLonLat([])),
            });
          });

          break;

        case HEATMAP.objects:
          features = [
            new Feature({
              geometry: new Point([8898938.193204563, 10598743.144403752]),
            }),
          ];
          break;

        case HEATMAP.all:
          features = markers.map((item: SelectedEmployeeMarker | SelectedTransportMarker) => {
            return new Feature({
              geometry: item.data ? new Point(fromLonLat(item.data)) : new Point(fromLonLat([])),
            });
          });

          features.push(
            new Feature({
              geometry: new Point([8898938.193204563, 10598743.144403752]),
            })
          );
          break;

        default:
          break;
      }
      heatSource.addFeatures(features);

      return () => {
        heatSource.clear();
      };
    }
  }, [markers, heatMapType, withHeatMap]);
}
