import { createSlice } from '@reduxjs/toolkit';

interface Payload {
  show: boolean;
  play: boolean;
  speed: number;
  track: number[][];
  width: number;
}

type Player = Payload;

const initialState: Player = {
  show: false,
  play: false,
  speed: 3,
  track: [],
  width: 0,
};

const playerSlice = createSlice({
  name: 'playerSlice',
  initialState,
  reducers: {
    hidePlayer(state) {
      const { show } = state;
      state.show = !show;
    },
    changeSpeed(state, action) {
      state.speed = action.payload;
    },
    playStatus(state) {
      const { play } = state;
      state.play = !play;
    },
    progressStatus(state, action) {
      state.width = action.payload;
    },
  },
});

export const { hidePlayer, changeSpeed, playStatus, progressStatus } = playerSlice.actions;

export default playerSlice.reducer;
