import { Icon } from 'ol/style';
import { Size } from 'ol/size';

type IconProperties = {
  src: string;
  width: number;
  height: number;
  scale?: Size;
};

export default function base64ImgToIconStyle(props: IconProperties) {
  // общая ширина и высота маркера/viewbox svg
  const mainWidth = props.width;
  const mainHeight = props.height;

  const svgContent = `
    <svg
      preserveAspectRatio="none"
      width="${mainWidth}" height="${mainHeight}" viewBox="0 0 ${mainWidth} ${mainHeight}" fill="none"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <foreignObject
        x="0" y="0"
        width="${mainWidth}" height="${mainHeight}"
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style="width: ${mainWidth}px; height: ${mainHeight}px; overflow: hidden;"
        >
          <img
            alt="marker_icon"
            src="${props.src}"
            style="width: 100%; height: 100%; object-fit: contain;"
          />
        </div>
      </foreignObject>
    </svg>
  `;

  const svgImage = new Image();
  svgImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgContent);

  return new Icon({
    scale: props.scale ?? [1, 1],
    imgSize: [mainWidth, mainHeight],
    img: svgImage,
  });
}
