import React, { FC, Fragment, useEffect, useState, useMemo } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCloseCircle } from 'react-icons/ai';
import { RootState } from 'reducers';
import { fetchHandbookData } from 'reducers/handbooks';
import { addOnePoi, clearChosenPoi, removeOnePoi, setSelectedPoiDirectly, updateOnePoi } from 'reducers/poi';
import {
  PoiVideocameraPoint,
  PoiSensorPoint,
  PoiEquipmentPoint,
  PoiAccidentPoint,
  PoiFreePoint,
  PoiCreate,
  PoiGasAnalyzerPoint,
} from 'reducers/poi/interface';
import { closeModal } from 'reducers/modal/index';

import { PoiTypesEnum } from 'components/poi/utils/consts';
import { getPoiCardTitle } from 'components/poi/utils/helpers';
import Button from 'components/common/button/button';
import { Spinner } from 'components/common/spinner/spinner';
import { Alert } from 'components/common/alert/alert';
import {
  PoiCardAccidentData,
  PoiCardEquipmentData,
  PoiCardFreePointData,
  PoiCardGazAnalyzerData,
  PoiCardSensorData,
  PoiCardVideoCameraData,
} from 'components/poi/utils/types';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import usePoiVideoCamera from 'components/poi/hooks/usePoiVideoCamera';
import usePoiGazAnalyzer from 'components/poi/hooks/usePoiGazAnalyzer';
import usePoiSensor from 'components/poi/hooks/usePoiSensor';
import usePoiEquipment from 'components/poi/hooks/usePoiEquipment';
import usePoiAccident from 'components/poi/hooks/usePoiAccident';
import usePoiFreePoint from 'components/poi/hooks/usePoiFreePoint';

import PoiVideoCameraContent from './components/poiVideoCameraContent';
import PoiGazAnalyzerContent from './components/poiGazAnalyzerContent';
import PoiSensorContent from './components/poiSensorContent';
import PoiEquipmentContent from './components/poiEquipmentContent';
import PoiAccidentContent from './components/poiAccidentContent';
import PoiFreePointContent from './components/poiFreePointContent';

import AccessEntity from 'utils/accessEntity';

import styles from './poiCard.module.scss';

enum alertContinueStateEnum {
  none = '',
  delete = 'delete',
  cancel = 'cancel',
}

type Props = {
  poiType: PoiTypesEnum;
  onCancel: () => void;
};

const PoiCard: FC<Props> = ({ poiType, onCancel }: Props) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const poiPermissions = useSelector((state: RootState) => state.user.permissions.poi);
  const poiAccess = useMemo(() => new AccessEntity(poiPermissions), [poiPermissions]);

  const { poiCardData, chosenPoi, isChosenPoiLoading, selectedPoi } = useSelector((state: RootState) => state.poi);
  const { data: handbookData } = useSelector((state: RootState) => state.handbooks);

  const [showLocalAlert, setShowLocalAlert] = useState(alertContinueStateEnum.none);

  // восстановление выбранных poi (чтобы не центрироваться на координате [0, 0] при заполнении данных)
  const [reservedSelectedPoi, setReservedSelectedPoi] = useState<number[]>([]);

  useEffect(() => {
    if (selectedPoi.length) {
      setReservedSelectedPoi(selectedPoi);
      dispatch(setSelectedPoiDirectly([]));
    }

    return () => {
      if (reservedSelectedPoi.length) {
        dispatch(setSelectedPoiDirectly(reservedSelectedPoi));
      }
    };
  }, [dispatch, selectedPoi, reservedSelectedPoi]);

  // заполнение данными справочников для poi card
  useEffect(() => {
    const {
      poiVideoCameraModels = [],
      poiGasAnalyzerModels = [],
      poiGasSensors = [],
      poiGasTypes = [],
      poiGasMeasurements = [],
      poiSensorTypes = [],
      poiSensorModels = [],
      poiSensorMeasureUnits = [],
      poiEquipmentTypes = [],
      poiEquipmentModels = [],
      poiAccidentTypes = [],
    } = handbookData;

    switch (poiType) {
      case PoiTypesEnum.poiVideocameraPoint:
        if (!poiVideoCameraModels.length) {
          dispatch(fetchHandbookData([HANDBOOK_KEYS.poiVideoCameraModels]));
        }
        break;

      case PoiTypesEnum.poiGasAnalyzerPoint:
        if (
          !poiGasAnalyzerModels.length ||
          !poiGasSensors.length ||
          !poiGasTypes.length ||
          !poiGasMeasurements.length
        ) {
          dispatch(
            fetchHandbookData([
              HANDBOOK_KEYS.poiGasAnalyzerModels,
              HANDBOOK_KEYS.poiGasSensors,
              HANDBOOK_KEYS.poiGasTypes,
              HANDBOOK_KEYS.poiGasMeasurements,
            ])
          );
        }
        break;

      case PoiTypesEnum.poiSensorPoint:
        if (!poiSensorTypes.length || !poiSensorModels.length || !poiSensorMeasureUnits.length) {
          dispatch(
            fetchHandbookData([
              HANDBOOK_KEYS.poiSensorTypes,
              HANDBOOK_KEYS.poiSensorModels,
              HANDBOOK_KEYS.poiSensorMeasureUnits,
            ])
          );
        }
        break;

      case PoiTypesEnum.poiEquipmentPoint:
        if (!poiEquipmentTypes.length || !poiEquipmentModels.length) {
          dispatch(fetchHandbookData([HANDBOOK_KEYS.poiEquipmentTypes, HANDBOOK_KEYS.poiEquipmentModels]));
        }
        break;

      case PoiTypesEnum.poiAccidentPoint:
        if (!poiAccidentTypes.length) {
          dispatch(fetchHandbookData([HANDBOOK_KEYS.poiAccidentTypes]));
        }
        break;

      default:
        break;
    }
  }, [dispatch, poiType, handbookData]);

  useEffect(() => {
    return () => {
      if (chosenPoi) {
        dispatch(clearChosenPoi());
      }
    };
  }, [dispatch, chosenPoi]);

  const {
    additionalData: poiVideoCameraAdditionalData,
    handlers: poiVideoCameraHandlers,
    invalidFields: poiVideoCameraInvalidFields,
    validateRequiredFields: poiVideoCameraValidateRequiredFields,
    setInvalidFields: poiVideoCameraSetInvalidFields,
    hasChanges: poiVideoCameraHasChanges,
  } = usePoiVideoCamera(chosenPoi);

  const {
    additionalData: poiGazAnalyzerAdditionalData,
    handlers: poiGazAnalyzerHandlers,
    refs: poiGazAnalyzerRefs,
    invalidFields: poiGazAnalyzerInvalidFields,
    validateRequiredFields: poiGazAnalyzerValidateRequiredFields,
    setInvalidFields: poiGazAnalyzerSetInvalidFields,
    hasChanges: poiGazAnalyzerHasChanges,
  } = usePoiGazAnalyzer(chosenPoi);

  const {
    additionalData: poiSensorAdditionalData,
    handlers: poiSensorHandlers,
    refs: poiSensorRefs,
    invalidFields: poiSensorInvalidFields,
    validateRequiredFields: poiSensorValidateRequiredFields,
    setInvalidFields: poiSensorSetInvalidFields,
    hasChanges: poiSensorHasChanges,
  } = usePoiSensor(chosenPoi);

  const {
    additionalData: poiEquipmentAdditionalData,
    handlers: poiEquipmentHandlers,
    refs: poiEquipmentRefs,
    invalidFields: poiEquipmentInvalidFields,
    validateRequiredFields: poiEquipmentValidateRequiredFields,
    setInvalidFields: poiEquipmentSetInvalidFields,
    hasChanges: poiEquipmentHasChanges,
  } = usePoiEquipment(chosenPoi);

  const {
    additionalData: poiAccidentAdditionalData,
    handlers: poiAccidentHandlers,
    refs: poiAccidentRefs,
    invalidFields: poiAccidentInvalidFields,
    validateRequiredFields: poiAccidentValidateRequiredFields,
    setInvalidFields: poiAccidentSetInvalidFields,
    hasChanges: poiAccidentHasChanges,
  } = usePoiAccident(chosenPoi);

  const {
    additionalData: poiFreePointAdditionalData,
    handlers: poiFreePointHandlers,
    refs: poiFreePointRefs,
    invalidFields: poiFreePointInvalidFields,
    validateRequiredFields: poiFreePointValidateRequiredFields,
    setInvalidFields: poiFreePointSetInvalidFields,
    hasChanges: poiFreePointHasChanges,
  } = usePoiFreePoint(chosenPoi);

  function contentLayout(poiType: PoiTypesEnum) {
    switch (poiType) {
      case PoiTypesEnum.poiVideocameraPoint:
        return (
          <PoiVideoCameraContent
            storeData={poiCardData as PoiCardVideoCameraData}
            additionalData={poiVideoCameraAdditionalData}
            handlers={poiVideoCameraHandlers}
            invalidFields={poiVideoCameraInvalidFields}
          />
        );
      case PoiTypesEnum.poiGasAnalyzerPoint:
        return (
          <PoiGazAnalyzerContent
            storeData={poiCardData as PoiCardGazAnalyzerData}
            additionalData={poiGazAnalyzerAdditionalData}
            handlers={poiGazAnalyzerHandlers}
            refs={poiGazAnalyzerRefs}
            invalidFields={poiGazAnalyzerInvalidFields}
          />
        );
      case PoiTypesEnum.poiSensorPoint:
        return (
          <PoiSensorContent
            storeData={poiCardData as PoiCardSensorData}
            additionalData={poiSensorAdditionalData}
            handlers={poiSensorHandlers}
            refs={poiSensorRefs}
            invalidFields={poiSensorInvalidFields}
          />
        );
      case PoiTypesEnum.poiEquipmentPoint:
        return (
          <PoiEquipmentContent
            storeData={poiCardData as PoiCardEquipmentData}
            additionalData={poiEquipmentAdditionalData}
            handlers={poiEquipmentHandlers}
            refs={poiEquipmentRefs}
            invalidFields={poiEquipmentInvalidFields}
          />
        );
      case PoiTypesEnum.poiAccidentPoint:
        return (
          <PoiAccidentContent
            storeData={poiCardData as PoiCardAccidentData}
            additionalData={poiAccidentAdditionalData}
            handlers={poiAccidentHandlers}
            refs={poiFreePointRefs}
            invalidFields={poiAccidentInvalidFields}
          />
        );
      case PoiTypesEnum.poiFreePoint:
        return (
          <PoiFreePointContent
            storeData={poiCardData as PoiCardFreePointData}
            additionalData={poiFreePointAdditionalData}
            handlers={poiFreePointHandlers}
            refs={poiAccidentRefs}
            invalidFields={poiFreePointInvalidFields}
          />
        );

      default:
        return null;
    }
  }

  const handleDelete = () => {
    setShowLocalAlert(alertContinueStateEnum.delete);
  };

  const handleCancel = (poiType: PoiTypesEnum) => {
    let hasConditionsChanges = false;
    switch (poiType) {
      case PoiTypesEnum.poiVideocameraPoint:
        hasConditionsChanges = poiVideoCameraHasChanges();
        break;
      case PoiTypesEnum.poiGasAnalyzerPoint:
        hasConditionsChanges = poiGazAnalyzerHasChanges();
        break;
      case PoiTypesEnum.poiSensorPoint:
        hasConditionsChanges = poiSensorHasChanges();
        break;
      case PoiTypesEnum.poiEquipmentPoint:
        hasConditionsChanges = poiEquipmentHasChanges();
        break;
      case PoiTypesEnum.poiAccidentPoint:
        hasConditionsChanges = poiAccidentHasChanges();
        break;
      case PoiTypesEnum.poiFreePoint:
        hasConditionsChanges = poiFreePointHasChanges();
        break;

      default:
        break;
    }
    if (!hasConditionsChanges) {
      onCancel();
    }
    return setShowLocalAlert(alertContinueStateEnum.cancel);
  };

  const handleSave = (poiType: PoiTypesEnum) => {
    if (!poiCardData) {
      return dispatch(closeModal());
    }

    const [lon, lat]: number[] = poiCardData.coordinates.split(',').map(c => Number(c.trim()));

    let changedData: Record<string, unknown> = {
      name: poiCardData.name,
      description: poiCardData.description,
      lon,
      lat,
      isOn: true,
    };
    let validationResult = [];

    switch (poiType) {
      case PoiTypesEnum.poiVideocameraPoint:
        {
          validationResult = poiVideoCameraValidateRequiredFields();
          if (validationResult.length) {
            return poiVideoCameraSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardVideoCameraData;

          changedData = {
            ...changedData,
            poiVideocameraPoint: {
              poiVideocameraModelId: states.poiVideocameraModelId || null,
              dataSourceUrl: states.dataSourceUrl,
              angle: states.angle,
              distance: states.distance,
              direction: states.direction,
            },
          };
        }
        break;

      case PoiTypesEnum.poiGasAnalyzerPoint:
        {
          validationResult = poiGazAnalyzerValidateRequiredFields();
          if (validationResult.length) {
            return poiGazAnalyzerSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardGazAnalyzerData;

          changedData = {
            ...changedData,
            poiGasAnalyzerPoint: {
              poiGasAnalyzerModelId: states.poiGasAnalyzerModelId || null,
              dataSourceUrl: states.dataSourceUrl,
              lastCheckDate: states.lastCheckDate || null,
              nextCheckDate: states.nextCheckDate || null,
              poiGasSensors: states.poiGasSensors,
              poiAnalyzedGases: states.poiAnalyzedGases,
            },
          };
        }
        break;

      case PoiTypesEnum.poiSensorPoint:
        {
          validationResult = poiSensorValidateRequiredFields();
          if (validationResult.length) {
            return poiSensorSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardSensorData;

          changedData = {
            ...changedData,
            poiSensorPoint: {
              poiSensorTypeId: states.poiSensorTypeId,
              poiSensorModelId: states.poiSensorModelId || null,
              dataSourceUrl: states.dataSourceUrl,
              lastCheckDate: states.lastCheckDate || null,
              nextCheckDate: states.nextCheckDate || null,
              measureRangeFrom: states.measureRangeFrom,
              measureRangeTo: states.measureRangeTo,
              measureLimit: states.measureLimit,
              poiSensorMeasureUnitId: states.poiSensorMeasureUnitId || null,
            },
          };
        }
        break;

      case PoiTypesEnum.poiEquipmentPoint:
        {
          validationResult = poiEquipmentValidateRequiredFields();
          if (validationResult.length) {
            return poiEquipmentSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardEquipmentData;

          changedData = {
            ...changedData,
            poiEquipmentPoint: {
              poiEquipmentTypeId: states.poiEquipmentTypeId || null,
              poiEquipmentModelId: states.poiEquipmentModelId || null,
              lastCheckDate: states.lastCheckDate || null,
              nextCheckDate: states.nextCheckDate || null,
            },
          };
        }
        break;

      case PoiTypesEnum.poiAccidentPoint:
        {
          validationResult = poiAccidentValidateRequiredFields();
          if (validationResult.length) {
            return poiAccidentSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardAccidentData;

          changedData = {
            ...changedData,
            poiAccidentPoint: {
              poiAccidentTypeId: (states as PoiCardAccidentData).poiAccidentTypeId,
              activePeriod: states.activePeriod,
              isRemovedInTheEnd: states.isRemovedInTheEnd,
            },
          };
        }
        break;

      case PoiTypesEnum.poiFreePoint:
        {
          validationResult = poiFreePointValidateRequiredFields();
          if (validationResult.length) {
            return poiFreePointSetInvalidFields(validationResult);
          }

          const states = { ...poiCardData } as PoiCardFreePointData;

          changedData = {
            ...changedData,
            poiFreePoint: {
              activePeriod: states.activePeriod,
              isRemovedInTheEnd: states.isRemovedInTheEnd,
            },
          };
        }
        break;

      default:
        return;
    }

    if (chosenPoi) {
      if (poiAccess.isAllowUpdate()) {
        return dispatch(updateOnePoi({ id: chosenPoi.id, data: changedData }));
      }
    } else {
      const [lon, lat]: number[] = poiCardData.coordinates.split(',').map(c => Number(c.trim()));
      const data: PoiCreate = {
        name: poiCardData.name,
        description: poiCardData.description,
        lon,
        lat,
        isOn: true,
        poiType,
        [poiType]: changedData[poiType] as
          | PoiVideocameraPoint
          | PoiGasAnalyzerPoint
          | PoiSensorPoint
          | PoiEquipmentPoint
          | PoiAccidentPoint
          | PoiFreePoint,
      };

      if (poiAccess.isAllowCreate()) {
        return dispatch(addOnePoi({ data }));
      }
    }

    return dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    return setShowLocalAlert(alertContinueStateEnum.none);
  };

  const handleAlertContinue = () => {
    switch (showLocalAlert) {
      case 'delete':
        if (chosenPoi && poiAccess.isAllowDelete()) {
          dispatch(removeOnePoi(chosenPoi.id));
        }
        break;

      case 'cancel':
        break;

      default:
        break;
    }
    setShowLocalAlert(alertContinueStateEnum.none);
    dispatch(closeModal());
    return onCancel();
  };

  const headerText = getPoiCardTitle(poiType) ?? '';

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h4 className={styles.headerTitle}>{headerText ? t(headerText) : ''}</h4>
          <AiFillCloseCircle color="#999999" className={styles.headerClosebtn} onClick={() => handleCancel(poiType)} />
        </div>
        {isChosenPoiLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className={styles.content}>{contentLayout(poiType)}</div>
            <div className={styles.footer}>
              {chosenPoi && <Button red text={t('poi.card.footer.btn.delete.label')} onClick={handleDelete} />}
              <Button white text={t('poi.card.footer.btn.cancel.label')} onClick={() => handleCancel(poiType)} />
              <Button
                blue
                text={chosenPoi ? t('poi.card.footer.btn.update.label') : t('poi.card.footer.btn.save.label')}
                onClick={() => handleSave(poiType)}
              />
            </div>
          </Fragment>
        )}
      </div>

      {showLocalAlert && (
        <Alert
          title={
            showLocalAlert === alertContinueStateEnum.delete
              ? t('poi.card.alert.header.delete.label')
              : t('poi.card.alert.header.label')
          }
          infoText={
            showLocalAlert === alertContinueStateEnum.delete ? t('poi.card.delete.text') : t('poi.card.info.text')
          }
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </Fragment>
  );
};

export default PoiCard;
