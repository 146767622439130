import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';

export function isEqualArraysOfObjects(arr1: unknown[], arr2: unknown[]) {
  const res = differenceWith(arr1, arr2, isEqual);
  return !res.length;
}

export function isEqualArraysOfObjectsOnlyWithIsEqual(oldArray: unknown[], newArray: unknown[]) {
  let isEqualArrays = true;
  if (oldArray.length !== newArray.length) {
    isEqualArrays = false;
  } else {
    for (let i = 0; i < oldArray.length; i++) {
      if (isEqualArrays) {
        isEqualArrays = isEqual(oldArray[i], newArray[i]);
      }
    }
  }
  return isEqualArrays;
}
