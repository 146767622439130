import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import turfBuffer from '@turf/buffer';

import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Feature, Map } from 'ol';
import { Draw, Modify, Snap } from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Circle, Geometry, LineString } from 'ol/geom';
import { getDistance } from 'ol/sphere';
import { toLonLat } from 'ol/proj';
import { easeOut } from 'ol/easing';
import { Extent } from 'ol/extent';
import { Stroke, Style } from 'ol/style';

import { GEOZONE_GEOMETRIC_TYPES } from 'components/geozones/utils/consts';
import { MAP_LAYERS_Z_INDEX, MAP_LAYER_RENDER_BUFFER_VALUE } from 'utils/consts';

import { RootState } from 'reducers';
import { setLineWidth } from 'reducers/map';
import { removeGeozoneFeature, setGeozoneFeature, setMoveToGeozoneId } from 'reducers/geozones';

import {
  createDraw,
  createDrawFeatureStyle,
  createModify,
  geoJSON,
  getCommonExtent,
  getFeaturesExtent,
  getOlRadius,
} from '../utils';

const newDrawSource: VectorSource = new VectorSource();
let draw: Draw;
let snap: Snap;
let modify: Modify;

let featuresArray: Feature[] = [];
let arrayOfCoord: number[][] = [];
let extents: Extent[] = [];

type FeatureOptionsType = {
  drawType: GEOZONE_GEOMETRIC_TYPES | null;
  drawColor: string;
  drawLabelColor: string;
  lineWidth: number;
};

function setFeatureParameters(feature: Feature<Geometry>, options: FeatureOptionsType) {
  const featureGeometry = feature.getGeometry();
  const { drawType, drawColor, drawLabelColor, lineWidth } = options;

  switch (drawType) {
    case GEOZONE_GEOMETRIC_TYPES.Circle:
      const circleGeometry = featureGeometry as Circle;
      const center = circleGeometry.getCenter();
      const lastCoordinate = circleGeometry.getLastCoordinate();
      const groundRadius = getDistance(toLonLat(center), toLonLat(lastCoordinate)).toFixed(0);
      feature.set('circleCenter', center);
      feature.set('circleRadius', groundRadius);
      feature.set('circleCenterLonLat', toLonLat(center));
      feature.set('drawType', drawType);
      feature.set('drawColor', drawColor);
      feature.set('drawLabelColor', drawLabelColor);
      break;

    case GEOZONE_GEOMETRIC_TYPES.Polygon:
      feature.set('drawType', drawType);
      feature.set('drawColor', drawColor);
      feature.set('drawLabelColor', drawLabelColor);
      break;

    case GEOZONE_GEOMETRIC_TYPES.Line:
      // https://stackoverflow.com/questions/52492093/typeerror-cannot-add-property-1-object-is-not-extensible-at-array-push-anon
      featuresArray = Object.assign([], featuresArray);
      const geom = feature.getGeometry();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const coord = geom.getCoordinates();

      featuresArray.push(feature);
      if (arrayOfCoord.length >= 1) {
        const newLine = new LineString([arrayOfCoord[arrayOfCoord.length - 1], coord]);
        const newFeature = new Feature({
          geometry: newLine,
        });
        featuresArray.push(newFeature);
      }
      arrayOfCoord.push(coord);
      const collection = geoJSON.writeFeaturesObject(featuresArray);
      const featureResult = geoJSON.readFeatures(turfBuffer(collection, lineWidth, { units: 'meters' }));

      featureResult.forEach((item, index) => {
        item.setProperties({
          drawType,
          drawColor,
          drawLabelColor,
          lineWidth,
          originalCoord: arrayOfCoord[index],
        });
      });
      return featureResult;

    default:
      break;
  }

  return feature;
}

export function useDrawGeozone(map: Map) {
  const drawType = useSelector((state: RootState) => state.map.drawType);
  const drawColor = useSelector((state: RootState) => state.map.drawColor);
  const drawLabelColor = useSelector((state: RootState) => state.map.drawLabelColor);
  const lineWidth = useSelector((state: RootState) => state.map.lineWidth);
  const { geozones, geozoneFeature, clearStorage, chosenGeozone, moveToGeozoneId } = useSelector(
    (state: RootState) => state.geozone
  );

  const chosenGeozoneText = useMemo(
    () => geozones.find(g => g.id === chosenGeozone)?.attributes.name ?? '',
    [geozones, chosenGeozone]
  );

  const dispatch = useDispatch();

  const handleDrawStart = useCallback(() => {
    if (newDrawSource.getFeatures().length) {
      newDrawSource.refresh();
      dispatch(removeGeozoneFeature());
    }
  }, [dispatch]);

  const handleDrawEnd = useCallback(
    (ev: DrawEvent) => {
      const feature = ev.feature;
      const featureResult = setFeatureParameters(feature, { drawType, drawColor, drawLabelColor, lineWidth });

      dispatch(setGeozoneFeature(featureResult));
    },
    [dispatch, drawColor, drawLabelColor, drawType, lineWidth]
  );

  const handleModifyEnd = useCallback(
    (ev: ModifyEvent) => {
      const features = ev.features;

      if (features.getLength()) {
        const feature = features.item(0);
        // TODO: https://github.com/openlayers/openlayers/issues/12497
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const featureResult = setFeatureParameters(feature, {
          drawType,
          drawColor,
          drawLabelColor,
          lineWidth,
        });

        dispatch(setGeozoneFeature(featureResult));
      }
    },
    [dispatch, drawColor, drawLabelColor, drawType, lineWidth]
  );

  const addInteractions = useCallback(() => {
    if (chosenGeozone) {
      if (drawType === GEOZONE_GEOMETRIC_TYPES.Point) {
        //TODO: переделать для редактирования точек для данного типа геозоны
        draw = createDraw(newDrawSource, drawType, drawColor ?? 'black');
        draw.on('drawstart', handleDrawStart);
        draw.on('drawend', handleDrawEnd);
        map.addInteraction(draw);
      } else {
        modify = createModify(newDrawSource, drawColor ?? 'black');
        modify.on('modifyend', handleModifyEnd);
        map.addInteraction(modify);
      }
    } else if (drawType) {
      draw = createDraw(newDrawSource, drawType, drawColor ?? 'black');
      draw.on('drawstart', handleDrawStart);
      draw.on('drawend', handleDrawEnd);
      map.addInteraction(draw);
    }
    snap = new Snap({ source: newDrawSource });
    map.addInteraction(snap);
  }, [map, drawType, drawColor, handleDrawStart, handleDrawEnd, handleModifyEnd, chosenGeozone]);

  const removeInteractions = useCallback(() => {
    map.removeInteraction(draw);
    map.removeInteraction(snap);
    map.removeInteraction(modify);
  }, [map]);

  const viewFitExtent = useCallback(
    (extent: Extent) => {
      map.getView().fit(extent, {
        size: map.getSize(),
        padding: [150, 150, 150, 150],
        duration: 2000,
        easing: easeOut,
      });
    },
    [map]
  );

  useEffect(() => {
    if (moveToGeozoneId) {
      const geozone = geozones.find(g => g.id === moveToGeozoneId);

      if (geozone && geozone.attributes.geoJson) {
        const features = geoJSON.readFeatures(geozone.attributes.geoJson);
        const featuresExtent = getFeaturesExtent(features);

        viewFitExtent(featuresExtent);
      }

      // reset value to be able to move to same geozone
      dispatch(setMoveToGeozoneId(null));
    }
  }, [moveToGeozoneId]);

  useEffect(() => {
    const newDrawLayer: VectorLayer<VectorSource> = new VectorLayer({
      source: newDrawSource,
      updateWhileInteracting: true,
      zIndex: MAP_LAYERS_Z_INDEX.GEOZONES,
      renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
    });
    map.addLayer(newDrawLayer);

    return () => {
      map.removeLayer(newDrawLayer);
      newDrawLayer.dispose();
    };
  }, [map]);

  useEffect(() => {
    newDrawSource.refresh();

    removeInteractions();
    if (drawType) {
      addInteractions();
    } else {
      dispatch(removeGeozoneFeature());
    }
  }, [dispatch, drawType, addInteractions, removeInteractions]);

  useEffect(() => {
    if (geozoneFeature) {
      if (newDrawSource.getFeatures().length) {
        newDrawSource.refresh();
      }
      if (!Array.isArray(geozoneFeature)) {
        const feature = geozoneFeature.clone();
        const featureProps = feature.getProperties();
        if (featureProps.geometry) {
          delete featureProps.geometry;
        }
        const drawColor = featureProps.drawColor;
        const drawLabelColor = featureProps.drawLabelColor;
        const style = createDrawFeatureStyle(drawColor ?? '#000000', drawLabelColor ?? '#000000');
        const strokeStyle = new Style({
          stroke: new Stroke({
            color: drawColor ?? '#000000',
            width: 3,
          }),
        });

        if (featureProps.drawType === GeometryType.CIRCLE) {
          const radius = featureProps.circleRadius;
          const center = featureProps.circleCenter;
          const olRadius = getOlRadius(center, Number(radius));
          const featureResult = new Feature(new Circle(center, olRadius));
          const geometry = featureResult.getGeometry();
          featureResult.setStyle([...style, strokeStyle]);
          featureResult.setProperties(featureProps);
          newDrawSource.addFeature(featureResult);
          if (geometry) {
            viewFitExtent(geometry.getExtent());
          }
        } else if (featureProps.drawType === GeometryType.LINE_STRING) {
          const lineWidth = featureProps.lineWidth;
          const featureResult = geoJSON.readFeature(
            turfBuffer(geoJSON.writeFeatureObject(feature), lineWidth, { units: 'meters' })
          );
          const geometry = featureResult.getGeometry();
          featureResult.setProperties(featureProps);
          featureResult.setStyle([...style, strokeStyle]);
          newDrawSource.addFeature(featureResult);
          if (geometry) {
            viewFitExtent(geometry.getExtent());
          }
        } else if (featureProps.drawType === GeometryType.POLYGON) {
          const geometry = feature.getGeometry();
          feature.setStyle([...style, strokeStyle]);
          newDrawSource.addFeature(feature);

          if (geometry) {
            viewFitExtent(geometry.getExtent());
          }
        }
      } else {
        let props = {};
        let style: Style[];

        if (!featuresArray.length) {
          featuresArray = [...geozoneFeature];
        }
        if (!arrayOfCoord.length) {
          dispatch(setLineWidth(geozoneFeature[0].getProperties().lineWidth));
          geozoneFeature.forEach(f => {
            const prop = f.getProperties();

            if (prop.originalCoord) {
              arrayOfCoord.push(prop.originalCoord);
            }
          });
        }

        const newFeatures = geozoneFeature.map((f: Feature) => {
          const feature = f.clone();
          const featureProps = feature.getProperties();

          if (featureProps.geometry) {
            delete featureProps.geometry;
          }
          props = { ...featureProps, lineWidth: featureProps.lineWidth / feature.getRevision() };

          const drawColor = featureProps.drawColor;
          const drawLabelColor = featureProps.drawLabelColor;

          style = createDrawFeatureStyle(drawColor ?? '#000000', drawLabelColor ?? '#000000');

          return feature;
        });
        const collection = geoJSON.writeFeaturesObject(newFeatures);
        const featureResult = geoJSON.readFeatures(turfBuffer(collection, lineWidth, { units: 'meters' }));

        featureResult.forEach(item => {
          const geometry = item.getGeometry();
          item.setProperties(props);
          item.setStyle(style);
          if (geometry) {
            extents.push(geometry.getExtent());
          }
        });

        newDrawSource.addFeatures(featureResult);

        if (extents.length && chosenGeozone) {
          const commonExtents = getCommonExtent(extents);
          viewFitExtent(commonExtents);
        }
      }
    }

    return () => {
      if (clearStorage) {
        featuresArray = [];
        arrayOfCoord = [];
        extents = [];
      }
    };
  }, [dispatch, geozoneFeature, viewFitExtent, chosenGeozoneText, lineWidth, clearStorage, chosenGeozone]);
}
