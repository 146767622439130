import React from 'react';
import { useSelector } from 'react-redux';
import { SortingType } from 'reducers/monitoring/interface';
import { RootState } from 'reducers';
import styles from './buttonIconSorting.module.scss';

type ButtonIconSortingProps = {
  typeSorting: SortingType;
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  AscendingIcon: React.ReactNode;
  DescendingIcon: React.ReactNode;
};

export function ButtonIconSorting({
  typeSorting,
  onClick,
  children,
  AscendingIcon,
  DescendingIcon,
}: ButtonIconSortingProps) {
  const { type: typeStore, ascending } = useSelector((state: RootState) => state.monitoring.sort);

  const getIcon = () => {
    if (typeSorting === typeStore) {
      return ascending ? AscendingIcon : DescendingIcon;
    }
    return children;
  };

  return (
    <div className={styles.buttonIcon} onClick={onClick}>
      {getIcon()}
    </div>
  );
}
