import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ContentModal } from './components/contentModal/contentModal';
import { ControlsModal } from './components/controlsModal/controlsModal';
import { HeaderModal } from './components/headerModal/headerModal';
import { Alert } from 'components/common/alert/alert';

import { closeModal } from 'reducers/modal/index';
import { addOneMessages, setMessages } from 'reducers/messages';

import useMessagesCreate from './hooks/useMessagesCreate';

import styles from './messageCreatingModal.module.scss';

function MessageCreatingModal() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [showLocalAlert, setShowLocalAlert] = useState(false);

  const { states, handlers, invalidFields, additionalStates, setInvalidFields, validateRequiredFields, hasChanges } =
    useMessagesCreate();

  const handleCancel = () => {
    const hasMessageChanges = hasChanges();

    if (!hasMessageChanges) {
      return dispatch(closeModal());
    }
    setShowLocalAlert(true);
  };

  const handleSend = () => {
    const validationResult = validateRequiredFields();

    if (validationResult.length) {
      return setInvalidFields(validationResult);
    }

    dispatch(setMessages([]));
    dispatch(
      addOneMessages({
        trackableUnitsIds: states.objects.map(object => Number(object)),
        body: states.text,
      })
    );

    dispatch(closeModal());
  };

  const handleAlertCancel = () => {
    setShowLocalAlert(false);
  };

  const handleAlertContinue = () => {
    setShowLocalAlert(true);
    dispatch(closeModal());
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <HeaderModal title={t('messages.create-modal.header.label')} onCancel={handleCancel} />
        </div>
        <div className={styles.modalBody}>
          <ContentModal
            states={states}
            handlers={handlers}
            additionalStates={additionalStates}
            invalidFields={invalidFields}
          />
        </div>
        <div className={styles.modalControls}>
          <ControlsModal onCancel={handleCancel} onSend={handleSend} />
        </div>
      </div>
      {showLocalAlert && (
        <Alert
          title={t('messages.create-modal.alert.title')}
          infoText={t('messages.create-modal.alert.info.text')}
          handleCancel={handleAlertCancel}
          handleContinue={handleAlertContinue}
        />
      )}
    </div>
  );
}

export default MessageCreatingModal;
