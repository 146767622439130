import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import turfBuffer from '@turf/buffer';
import turfCenterOfMass from '@turf/center-of-mass';
import turfMidPoint from '@turf/midpoint';
import { Position } from '@turf/helpers';

import { Feature, Map } from 'ol';
import { Circle, Point } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat, toLonLat } from 'ol/proj';

import DefaultMarker from 'assets/img/geozones/map/default-marker.svg';

import { GEOZONE_FEATURE_TYPE } from 'components/geozones/utils/consts';
import { MAP_LAYER_RENDER_BUFFER_VALUE } from 'utils/consts';

import { RootState } from 'reducers';

import { createDrawFeatureStyle, geoJSON, getGeozoneMarkerStyle, getOlRadius } from '../utils';

const drawSource: VectorSource = new VectorSource();
const drawLayer: VectorLayer<VectorSource> = new VectorLayer({
  source: drawSource,
  updateWhileInteracting: true,
  renderBuffer: MAP_LAYER_RENDER_BUFFER_VALUE,
});

export function useSelectedGeozones(map: Map) {
  const selectedGeozones = useSelector((state: RootState) =>
    state.geozone.geozones.filter(g => state.geozone.selectedGeozones.includes(g.id))
  );

  const floor = useSelector((state: RootState) => state.map.floor);

  useEffect(() => {
    map.addLayer(drawLayer);

    return () => {
      map.removeLayer(drawLayer);
    };
  }, [map]);

  useEffect(() => {
    selectedGeozones.forEach(zone => {
      const zoneFloor = zone.attributes.floor || 1;
      if (zoneFloor === floor && zone.attributes.geoJson) {
        try {
          const parsedGeoJSON = JSON.parse(zone.attributes.geoJson);

          if (parsedGeoJSON.type === 'FeatureCollection') {
            const firstLine = parsedGeoJSON.features[0];
            const originalCoord = firstLine.properties.originalCoord as number[][];
            const originalCoordLonLat: number[][] = !!originalCoord
              ? [toLonLat(originalCoord[0]), toLonLat(originalCoord[1])]
              : [];
            let geozoneIcon: Feature | null = null;
            let labelColor = '#000000';
            let midPoint: Position;

            parsedGeoJSON.features.forEach((f: Feature) => {
              const feature = geoJSON.readFeature(f);
              let featureResult = feature;
              const featureProps = feature.getProperties();
              const color = featureProps.drawColor;
              labelColor = featureProps.drawLabelColor;

              if (featureProps.drawType === GeometryType.CIRCLE) {
                const radius = featureProps.circleRadius;
                const center = featureProps.circleCenter;
                const olRadius = getOlRadius(center, radius);

                midPoint = center;
                featureResult = new Feature(new Circle(center, Number(olRadius)));
              } else if (
                featureProps.drawType === GeometryType.POLYGON ||
                featureProps.drawType === GeometryType.LINE_STRING ||
                featureProps.drawType === GeometryType.GEOMETRY_COLLECTION
              ) {
                const lineWidth = featureProps.lineWidth;
                const width = Number(lineWidth) ? Number(lineWidth) : 1;

                midPoint = turfCenterOfMass(geoJSON.writeFeatureObject(feature)).geometry.coordinates;
                featureResult = geoJSON.readFeature(
                  turfBuffer(geoJSON.writeFeatureObject(feature), width, { units: 'meters' })
                );
              }

              const style = createDrawFeatureStyle(color ?? '#000000', labelColor ?? '#000000');

              featureResult.setStyle(style);
              featureResult.setProperties({
                id: zone.id,
                type: GEOZONE_FEATURE_TYPE,
                centerPoint: midPoint,
              });

              drawSource.addFeature(featureResult);
            });

            if (originalCoordLonLat.length > 1) {
              midPoint = turfMidPoint(originalCoordLonLat[0], originalCoordLonLat[1]).geometry.coordinates;
              geozoneIcon = new Feature(new Point(fromLonLat(midPoint)));

              geozoneIcon.setStyle(
                getGeozoneMarkerStyle({
                  labelColor,
                  iconSrc: zone.attributes.icon || DefaultMarker,
                  geozoneName: zone.attributes.name,
                })
              );
              geozoneIcon.setProperties({
                id: zone.id,
                type: GEOZONE_FEATURE_TYPE,
                centerPoint: midPoint,
              });
            }

            if (geozoneIcon) {
              drawSource.addFeature(geozoneIcon);
            }
          } else {
            const feature = geoJSON.readFeature(parsedGeoJSON);
            let featureResult = feature;
            const featureProps = feature.getProperties();
            const color = featureProps.drawColor;
            const labelColor = featureProps.drawLabelColor;
            let centerPoint: number[] = [];

            switch (featureProps.drawType) {
              case GeometryType.CIRCLE:
                const radius = featureProps.circleRadius;
                const center = featureProps.circleCenter;
                const olRadius = getOlRadius(center, radius);

                featureResult = new Feature(new Circle(center, Number(olRadius)));
                centerPoint = featureProps.circleCenterLonLat;
                break;

              case GeometryType.LINE_STRING:
              case GeometryType.GEOMETRY_COLLECTION:
                const lineWidth = featureProps.lineWidth;
                const width = Number(lineWidth) ? Number(lineWidth) : 1;

                featureResult = geoJSON.readFeature(
                  turfBuffer(geoJSON.writeFeatureObject(feature), width, { units: 'meters' })
                );
                centerPoint = turfCenterOfMass(geoJSON.writeFeatureObject(feature)).geometry.coordinates;
                break;

              case GeometryType.POLYGON:
                featureResult = geoJSON.readFeature(
                  turfBuffer(geoJSON.writeFeatureObject(feature), 0, { units: 'meters' })
                );
                centerPoint = turfCenterOfMass(geoJSON.writeFeatureObject(feature)).geometry.coordinates;
                break;

              default:
                break;
            }
            const style = createDrawFeatureStyle(color ?? '#000000', labelColor ?? '#000000');

            featureResult.setStyle(style);
            featureResult.setProperties({
              id: zone.id,
              type: GEOZONE_FEATURE_TYPE,
              centerPoint,
            });

            const geozoneIcon = new Feature(new Point(fromLonLat(centerPoint)));

            geozoneIcon.setStyle(
              getGeozoneMarkerStyle({
                labelColor,
                iconSrc: zone.attributes.icon || DefaultMarker,
                geozoneName: zone.attributes.name,
              })
            );
            geozoneIcon.setProperties({
              id: zone.id,
              type: GEOZONE_FEATURE_TYPE,
              centerPoint,
            });

            drawSource.addFeatures([featureResult, geozoneIcon]);
          }
        } catch (err) {
          console.error(err);
        }
      }
    });

    return () => {
      drawSource.clear();
    };
  }, [selectedGeozones, floor]);
}
