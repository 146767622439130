import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';
import Popup from 'components/common/popup/popup';
import { Alert } from 'components/common/alert/alert';
import MessagesListFilter from './components/messagesListFilter/messagesListFilter';
import MessagesListHeader from './components/messagesListHeader/messagesListHeader';
import MessagesListContent from './components/messagesListContent/messagesListContent';

import useMessagesLeftBar from './hooks/useMessagesLeftBar';
import useMessagesFilter from './hooks/useMessagesFilter';
import useMessagesListContent from './hooks/useMessagesListContent';

import { RootState } from 'reducers';

import { MessagesLeftBarMainStates, MessagesLeftBarMainHandlers } from 'components/messages/utils/types';

import AccessEntity from 'utils/accessEntity';

import styles from './messagesLeftBar.module.scss';

function MessagesLeftBar() {
  const t = useFormatMessage();

  const { isTableMessagesShow, selectedMessages } = useSelector((state: RootState) => state.messages);
  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);

  const { states: filterStates, refs: filterRefs, handlers: filterHandlers } = useMessagesFilter();
  const { states: contentStates, refs: contentRefs, handlers: contentHandlers } = useMessagesListContent();
  const {
    states: mainStates,
    handlers: mainHandlers,
  }: { states: MessagesLeftBarMainStates; handlers: MessagesLeftBarMainHandlers } = useMessagesLeftBar();

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <MessagesListFilter states={filterStates} refs={filterRefs} handlers={filterHandlers} />
        <MessagesListHeader />
        {!isTableMessagesShow && (
          <MessagesListContent states={contentStates} refs={contentRefs} handlers={contentHandlers} />
        )}
        <div className={styles.footer}>
          {!isTableMessagesShow && (
            <Fragment>
              {messagesAccess.isAllowRead() && (
                <Button
                  white
                  text={t('messages.left-bar.footer.btn.table.text')}
                  customStyle={styles.footerButton}
                  onClick={mainHandlers.handleShowTable}
                />
              )}
              {messagesAccess.isAllowDelete() && (
                <Button
                  red
                  text={t('messages.left-bar.footer.btn.delete.text')}
                  customStyle={styles.footerButton}
                  onClick={mainHandlers.handleDeleteItems}
                  disabled={!selectedMessages.length}
                />
              )}
              {messagesAccess.isAllowUpdate() && (
                <Button
                  white
                  text={t('messages.left-bar.footer.btn.save.text')}
                  customStyle={styles.footerButton}
                  onClick={mainHandlers.handleSave}
                />
              )}
            </Fragment>
          )}
          {messagesAccess.isAllowRead() && (
            <Button
              blue
              text={t('messages.left-bar.footer.btn.form.text')}
              customStyle={styles.footerButton}
              onClick={mainHandlers.handleForming}
            />
          )}
        </div>
      </div>

      {mainStates.showPopup && (
        <Popup
          header={<h3 className={styles.popupHeader}>{t('messages.popup.header.text')}</h3>}
          content={
            <div className={styles.popupContent}>
              <div className={styles.popupContentText}>{t('messages.popup.content.text1')}</div>
              <div className={styles.popupContentText}>{t('messages.popup.content.text2')}</div>
            </div>
          }
          footer={
            <div className={styles.popupFooter}>
              <Button
                white
                text={t('messages.popup.button.text')}
                customStyle={styles.popupClose}
                onClick={mainHandlers.handleClosePopup}
              />
            </div>
          }
        />
      )}

      {!!mainStates.deletedIds.length && (
        <Alert
          title={t('messages.list.template-list.array.alert.delete.title')}
          infoText={t('messages.list.template-list.array.alert.delete.text')}
          handleCancel={mainHandlers.handleDeleteAlertCancel}
          handleContinue={mainHandlers.handleDeleteAlertContinue}
        />
      )}
    </Fragment>
  );
}

export default MessagesLeftBar;
