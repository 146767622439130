import React, { useContext, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as UserSettingIcon } from 'assets/img/user-setting-icon.svg';
import { ReactComponent as LogoutSettingIcon } from 'assets/img/logout-setting-icon.svg';
import Avatar from 'assets/img/avatar_mock.png';

import { WebSocketContext } from 'contexts/websocketContext';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { logOut } from 'reducers/auth';
import { clearUserData } from 'reducers/user';

import { reqHandlers } from 'utils/api';
import { USER_SETTINGS } from 'utils/consts';
import { AUTH_LOGIN_ROUTE } from './utils/consts';

import styles from './auth.module.scss';

export const LogOutBlock: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const ws = useContext(WebSocketContext);

  const { wialonSession } = useSelector((state: RootState) => state.auth);

  const logout = () => {
    if (ws) {
      ws.socket.close(1000);
    }
    if (wialonSession) {
      // eslint-disable-next-line
      // @ts-ignore
      wialonSession.logout();
    }
    dispatch(clearUserData());
    dispatch(logOut());
    sessionStorage.clear();
    history.push(AUTH_LOGIN_ROUTE);
    reqHandlers.reset();
  };

  const {
    id: userId,
    attributes: { firstName, secondName, lastName },
  } = useSelector((state: RootState) => state.user);

  const id = parseInt(userId);

  const [showMenu, showMenuHandler] = useState(false);

  const openUserSettings = () => {
    dispatch(showModal({ type: USER_SETTINGS, which: '' }));
  };

  const TEXT_USER_SETTINGS = 'common-info.submenu.user.settings.text';
  const MENU_ITEMS = [
    {
      to: '',
      text: TEXT_USER_SETTINGS,
      icon: <UserSettingIcon />,
      access: ['user'],
      handler: openUserSettings,
    },
    {
      to: '',
      text: 'common-info.submenu.user.exit.text',
      icon: <LogoutSettingIcon />,
      access: ['user', 'admin'],
      handler: logout,
    },
  ];

  return (
    <div className={styles.block} onMouseOver={() => showMenuHandler(true)} onMouseOut={() => showMenuHandler(false)}>
      <div className={styles.user}>
        <img src={Avatar} alt={`${firstName} ${secondName} ${lastName}`} className={styles.avatar} />
        <div className={styles.name}>{`${firstName} ${secondName} ${lastName}`}</div>
      </div>
      <div
        className={styles.menu}
        style={{
          display: showMenu ? 'flex' : '',
        }}
      >
        {MENU_ITEMS.map((item, index) => {
          if (id === 1 && item.text === TEXT_USER_SETTINGS) {
            return null;
          }
          return (
            <div key={index} className={styles.menuItem} onClick={item.handler && item.handler}>
              {item.icon}
              <span className={styles.menuItemTitle}>{t(item.text)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
