import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Button from 'components/common/button/button';
import styles from './controls1.module.scss';

interface Controls {
  onDelete: () => void;
  onCancel: () => void;
  onSave?: () => void;
  isAllowCreate: boolean;
  isAllowUpdate: boolean;
  isAllowDelete: boolean;
  saveButtonText?: string;
}

export const TrackersModalControls = ({
  onSave,
  onDelete,
  onCancel,
  saveButtonText,
  isAllowCreate,
  isAllowUpdate,
  isAllowDelete,
}: Controls) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalControls}>
      {isAllowDelete && <Button red text={t('trackers.card.footer.btn.delete.label')} onClick={onDelete} />}
      <Button white text={t('trackers.card.footer.btn.cancel.label')} onClick={onCancel} />
      {onSave && (
        <Button
          blue
          text={saveButtonText ?? ''}
          onClick={onSave}
          disabled={!Boolean(Number(isAllowCreate) | Number(isAllowUpdate))}
        />
      )}
    </div>
  );
};
