import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { getPoiFeatureIcon } from 'components/map/utils';
import { PoiMarkerProperties } from 'components/map/map.types';
import PoiPopup from '../poiPopup/poiPopup';

import { APP_CONTENT_ID } from 'utils/consts';

import { RootState } from 'reducers';
import { setChosenClusterPoiList } from 'reducers/poi';

import styles from './poiClusterPopup.module.scss';

const CLUSTER_POPUP_WIDTH = 320;
const CLUSTER_POPUP_HEIGHT = 170;

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
}

function PoiClusterPopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setChosenClusterPoiList([]));
    };
  }, [dispatch]);

  const { chosenClusterPoiList } = useSelector((state: RootState) => state.poi);

  const [showItemPopup, setShowItemPopup] = useState(false);
  const [markerCoordsItemPopup, setMarkerCoordsItemPopup] = useState({
    mouseCoords: { x: 0, y: 0 },
    mapCoords: { x: 0, y: 0 },
  });
  const [markerProperties, setMarkerProperties] = useState<PoiMarkerProperties | null>(null);

  const handleItemClick = (
    evt: React.MouseEvent<HTMLLIElement, MouseEvent>,
    markerPoiProperty: PoiMarkerProperties
  ) => {
    setMarkerCoordsItemPopup({
      mouseCoords: { x: evt.clientX, y: evt.clientY },
      mapCoords: { x: 0, y: 0 },
    });
    setMarkerProperties(markerPoiProperty);
    setShowItemPopup(true);
  };

  const contentPopup = (
    <div className={styles.popupContent}>
      <ul className={styles.popupList}>
        {chosenClusterPoiList.map(markerPoiProperty => {
          const iconSrc = getPoiFeatureIcon({
            poiType: markerPoiProperty.poiType,
            poiSubTypeId: markerPoiProperty.poiSubTypeId,
          });

          return (
            <li
              className={styles.popupListItem}
              key={`poi-cluster-marker-${markerPoiProperty.info.id}`}
              onClick={evt => handleItemClick(evt, markerPoiProperty)}
            >
              <div className={styles.popupListItemDescription}>
                <div className={styles.popupListItemDescriptionIcon}>
                  <img src={iconSrc} alt="poi-icon" />
                </div>
                <div className={styles.popupListItemDescriptionText}>{markerPoiProperty.info.name}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  if (showItemPopup) {
    return <PoiPopup onCancel={onCancel} markerCoords={markerCoordsItemPopup} markerProperties={markerProperties} />;
  }

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      content={contentPopup}
      initialWidth={CLUSTER_POPUP_WIDTH}
      initialHeight={CLUSTER_POPUP_HEIGHT}
      initialPosition={markerCoords.mouseCoords}
      resizeAxis="y"
      customClassNames={{ content: styles.popupContentNoPaddings }}
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(PoiClusterPopup);
