import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import Button from 'components/common/button/button';

type Props = {
  onCopy?: () => void;
  onDelete?: () => void;
  onCancel: () => void;
  onSave: () => void;
  onSaveTest?: () => void;
  withoutDelete?: boolean;
  withoutSave?: boolean;
  saveButtonText: string;
  withTestButton?: boolean;
};

export const ControlsModal = ({
  onCopy,
  onDelete,
  onCancel,
  onSave,
  onSaveTest,
  withoutDelete,
  withoutSave,
  saveButtonText,
  withTestButton,
}: Props) => {
  const t = useFormatMessage();

  return (
    <>
      {withTestButton && <Button white text={t('notifications.card.footer.btn.test.label')} onClick={onSaveTest} />}
      <Button white text={t('notifications.card.footer.btn.copy.label')} withCopy onClick={onCopy} />
      {!withoutDelete && <Button red text={t('notifications.card.footer.btn.delete.label')} onClick={onDelete} />}
      <Button white text={t('notifications.card.footer.btn.cancel.label')} onClick={onCancel} />
      {!withoutSave && <Button blue text={saveButtonText} onClick={onSave} />}
    </>
  );
};
