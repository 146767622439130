import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as TrackableNumber } from 'assets/img/list_header_icons/number.svg';
import { ReactComponent as TrackableNumberAsc } from 'assets/img/list_header_icons/number-asc.svg';
import { ReactComponent as TrackableNumberDesc } from 'assets/img/list_header_icons/number-desc.svg';

import { ReactComponent as NetworkStatus } from 'assets/img/list_header_icons/network_status.svg';
import { ReactComponent as NetworkStatusAsc } from 'assets/img/list_header_icons/network_status-asc.svg';
import { ReactComponent as NetworkStatusDesc } from 'assets/img/list_header_icons/network_status-desc.svg';

import { ReactComponent as NetworkQuality } from 'assets/img/list_header_icons/network_quality.svg';
import { ReactComponent as NetworkQualityAsc } from 'assets/img/list_header_icons/network_quality-asc.svg';
import { ReactComponent as NetworkQualityDesc } from 'assets/img/list_header_icons/network_quality-desc.svg';

import { ReactComponent as BatteryChargingStatus } from 'assets/img/list_header_icons/battery_charging_status.svg';
import { ReactComponent as BatteryChargingStatusAsc } from 'assets/img/list_header_icons/battery_charging_status-asc.svg';
import { ReactComponent as BatteryChargingStatusDesc } from 'assets/img/list_header_icons/battery_charging_status-desc.svg';

import { ReactComponent as GpsDeviceOn } from 'assets/img/gps_device_on.svg';

import { useSortingTrackers } from 'components/trackers/utils/useSorting';
import { ButtonIconSorting } from './buttonIconSorting/buttonIconSorting';
import { ButtonIconSortingWithText } from './buttonIconSortingWithText/buttonIconSortingWithText';
import { Checkbox } from 'components/common/checkbox/checkbox';

import { RootState } from 'reducers';
import { SortingType } from 'reducers/monitoring/interface';
import {
  selectBatchOfTrackers,
  unselectAllTrackers,
  watchAllTrackers,
  unwatchAllTrackers,
  setSort,
} from 'reducers/trackers';

import { Sorting } from 'utils/consts';

import styles from 'components/trackers/trackers.module.scss';

const cx = classNames.bind(styles);

const TrackerListHeader: React.FC = () => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const { trackers: notSortedTrackers, selectedTrackers } = useSelector((state: RootState) => state.tracker);

  const { trackableNumber, name, network, gps, battery } = Sorting;
  const sortedTrackers = useSortingTrackers(notSortedTrackers);

  const selectAll = () => {
    if (selectedTrackers.length === sortedTrackers.length) {
      return dispatch(unselectAllTrackers());
    }
    return dispatch(selectBatchOfTrackers(sortedTrackers.map(tracker => tracker.id)));
  };

  const handleWatchAll = () => {
    const watchingTrackers = selectedTrackers.filter(tr => tr.watching);
    if (watchingTrackers.length !== sortedTrackers.length) {
      dispatch(watchAllTrackers());
    } else {
      dispatch(unwatchAllTrackers());
    }
  };

  const handleSort = (type: SortingType) => {
    dispatch(setSort(type));
  };

  return (
    <div className={cx(styles.trackerWrapper, styles.trackerListHeader)}>
      <div className={styles.trackerInfo}>
        <Checkbox
          checked={sortedTrackers.length > 0 && selectedTrackers.length === sortedTrackers.length}
          handleCheck={selectAll}
          isIndeterminate={selectedTrackers.length > 0 && selectedTrackers.length < sortedTrackers.length}
        />
        <div className={styles.idHead} data-tip data-for="trackableNumber">
          <ButtonIconSorting
            typeSorting={trackableNumber}
            onClick={() => handleSort(trackableNumber)}
            AscendingIcon={<TrackableNumberAsc />}
            DescendingIcon={<TrackableNumberDesc />}
          >
            <TrackableNumber />
          </ButtonIconSorting>
          <ReactTooltip className={styles.customTooltip} id="trackableNumber" place="right" type="light" effect="solid">
            <span>{t('trackers.list.header.tooltip.tracker_number.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.nameHead}>
          <ButtonIconSortingWithText typeSorting={name} onClick={() => handleSort(name)}>
            <div>{t('trackers.list.header.sim-card.text')}</div>
          </ButtonIconSortingWithText>
        </div>
      </div>
      <div className={styles.trackerButtons}>
        <div className={styles.trackerButtonsIcon}>
          <GpsDeviceOn data-tip data-for="watchAllTrackersTooltip" onClick={handleWatchAll} />
          <ReactTooltip
            className={styles.customTooltip}
            id="watchAllTrackersTooltip"
            place="right"
            type="light"
            effect="solid"
          >
            <span>{t('trackers.list.header.tooltip.follow.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.trackerButtonsIcon} data-tip data-for="networkStatus">
          <ButtonIconSorting
            typeSorting={network}
            onClick={() => handleSort(network)}
            AscendingIcon={<NetworkStatusAsc />}
            DescendingIcon={<NetworkStatusDesc />}
          >
            <NetworkStatus />
          </ButtonIconSorting>
          <ReactTooltip className={styles.customTooltip} id="networkStatus" place="right" type="light" effect="solid">
            <span>{t('trackers.list.header.tooltip.connection.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.trackerButtonsIcon} data-tip data-for="satellites">
          <ButtonIconSorting
            typeSorting={gps}
            onClick={() => handleSort(gps)}
            AscendingIcon={<NetworkQualityAsc />}
            DescendingIcon={<NetworkQualityDesc />}
          >
            <NetworkQuality />
          </ButtonIconSorting>
          <ReactTooltip className={styles.customTooltip} id="satellites" place="right" type="light" effect="solid">
            <span>{t('trackers.list.header.tooltip.signal.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.trackerButtonsIcon} data-tip data-for="batteryChargingStatus">
          <ButtonIconSorting
            typeSorting={battery}
            onClick={() => handleSort(battery)}
            AscendingIcon={<BatteryChargingStatusAsc />}
            DescendingIcon={<BatteryChargingStatusDesc />}
          >
            <BatteryChargingStatus />
          </ButtonIconSorting>
          <ReactTooltip
            className={styles.customTooltip}
            id="batteryChargingStatus"
            place="right"
            type="light"
            effect="solid"
          >
            <span>{t('trackers.list.header.tooltip.battery.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.trackerButtonsIcon} />
      </div>
    </div>
  );
};

export default React.memo(TrackerListHeader);
