import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import EmployeeSmall from 'assets/img/employee-small.png';
import TransportSmall from 'assets/img/transport-small.png';
import { ReactComponent as BatteryChargingTracker } from 'assets/img/battery_charging_tracker.svg';
import { ReactComponent as NetworkQualityTracker } from 'assets/img/network_quality_tracker.svg';
import { ReactComponent as GpsDeviceOff } from 'assets/img/gps_device_off.svg';
import { ReactComponent as GpsDeviceOn } from 'assets/img/gps_device_on.svg';
import { ReactComponent as NetworkStatusTracker } from 'assets/img/network_status_tracker.svg';
import { ReactComponent as EditIcon } from 'assets/img/gear.svg';

import { Checkbox } from 'components/common/checkbox/checkbox';
import { TRACKABLE_UNIT_RELATIONSHIP_TYPES } from 'components/monitoring/utils/consts';

import { RootState } from 'reducers';
import { fetchOneEmployee, setChosenEmployee } from 'reducers/employees';
import { toggleSelectUnit, toggleWatchUnit } from 'reducers/monitoring';
import { fetchOneTransport } from 'reducers/transports';
import { showModal, pickCoordintesAndShowMapPopup } from 'reducers/modal';
import { ToggleUnitsPayload } from 'reducers/monitoring/interface';
import { Unit } from 'reducers/trackableUnits/interface';

import { findBatteryStatus } from 'utils/findBatteryStatus';
import AccessEntity from 'utils/accessEntity';
import { EMPLOYEE, TRANSPORT } from 'utils/consts';

import styles from './unitItem.module.scss';

const cx = classNames.bind(styles);

export const UnitItem = ({ unit, paddingLeft, isWide }: { unit: Unit; paddingLeft: number; isWide: boolean }) => {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const selectedUnits = useSelector((state: RootState) => state.monitoring.selectedUnits);
  const watchingUnits = useSelector((state: RootState) => state.monitoring.watchingUnits);
  const trackableUnitsIncluded = useSelector((state: RootState) => state.trackableUnit.trackableUnitsIncluded);
  const trackers = useSelector((state: RootState) => state.tracker.trackers);

  const employeePermissions = useSelector((state: RootState) => state.user.permissions.employees);
  const employeeAccess = useMemo(() => new AccessEntity(employeePermissions), [employeePermissions]);

  const transportPermissions = useSelector((state: RootState) => state.user.permissions.transports);
  const transportAccess = useMemo(() => new AccessEntity(transportPermissions), [transportPermissions]);

  const contactsIds = useSelector((state: RootState) => state.map.contactsIds);

  const unitHasTracker = Boolean(unit.attributes.trackerId);

  const isActive: boolean =
    (trackableUnitsIncluded.find(
      includedInfo =>
        unit.relationships.isActive.data.id === includedInfo.id &&
        includedInfo.type === TRACKABLE_UNIT_RELATIONSHIP_TYPES.isActive
    )?.attributes.value as boolean) ?? false;

  let batteryCharge = 0;
  let signalLevel = 0;

  if (unitHasTracker) {
    // Find battery charge status
    batteryCharge =
      (trackableUnitsIncluded.find(
        includedInfo =>
          unit.relationships.batteryCharge.data.id === includedInfo.id &&
          includedInfo.type === TRACKABLE_UNIT_RELATIONSHIP_TYPES.batteryCharge
      )?.attributes.value as number) ?? 0;

    // Find signal level status
    signalLevel =
      (trackableUnitsIncluded.find(
        includedInfo =>
          unit.relationships.signalLevel.data.id === includedInfo.id &&
          includedInfo.type === TRACKABLE_UNIT_RELATIONSHIP_TYPES.signalLevel
      )?.attributes.value as number) ?? 0;
  }

  const selectUnit = (data: ToggleUnitsPayload) => {
    // if deviceId is null and current status is not selected do nothing
    if (data.currentStatus || unit.attributes.deviceId) {
      dispatch(toggleSelectUnit(data));
    }
  };

  const watchUnit = (data: ToggleUnitsPayload) => {
    dispatch(toggleWatchUnit(data));
  };

  const handleUpdateClick = async (ids: { transportId: number | null; employeeId: number | null }) => {
    if (ids.employeeId && employeeAccess.isAllowUpdate()) {
      if (employeeAccess.isAllowRead()) {
        // TODO: убрать String() когда изменят тип id с number на string
        dispatch(setChosenEmployee(String(ids.employeeId)));
        dispatch(showModal({ type: EMPLOYEE, which: '' }));
        await dispatch(fetchOneEmployee(String(ids.employeeId)));
      }
    } else if (ids.transportId && transportAccess.isAllowUpdate()) {
      if (transportAccess.isAllowRead()) {
        dispatch(showModal({ type: TRANSPORT, which: '' }));
        await dispatch(fetchOneTransport(ids.transportId));
      }
    }
  };

  const isUnitSelected = selectedUnits.includes(unit.id);
  const isUnitWatching = watchingUnits.includes(unit.id);

  const [lastName = '', firstName = '', secondName = ''] = unit.attributes.aggregatedName.split(' ');

  const trackerNumber = trackers.find(tr => tr.id === unit.attributes.trackerId)?.attributes.trackerNumber ?? '---';

  const openEmployeePopup = () => {
    if (!unit.attributes.transportId) {
      dispatch(fetchOneEmployee(String(unit.attributes.employeeId)));
      dispatch(
        pickCoordintesAndShowMapPopup({
          popupType: EMPLOYEE,
          deviceId: unit.attributes.deviceId,
        })
      );
    } else {
      dispatch(fetchOneTransport(unit.attributes.transportId));
      dispatch(
        pickCoordintesAndShowMapPopup({
          popupType: TRANSPORT,
          deviceId: unit.attributes.deviceId,
        })
      );
    }
  };

  return (
    <div
      className={cx(styles.unitItem, { [styles.unitItemInGroup]: paddingLeft })}
      style={{ paddingLeft: `${paddingLeft}px` }}
    >
      <div className={styles.unitItemInfo}>
        <div className={styles.unitItemCheckbox}>
          <Checkbox
            checked={isUnitSelected || contactsIds.includes(unit.id)}
            handleCheck={() => selectUnit({ id: unit.id, currentStatus: isUnitSelected })}
          />
        </div>
        <span className={styles.unitItemTrackableNumber}>{trackerNumber}</span>
        <span className={styles.unitItemImage}>
          {unit.attributes.employeeId ? <img src={EmployeeSmall} alt="" /> : <img src={TransportSmall} alt="" />}
        </span>
        <div className={styles.unitItemTitle} title={unit.attributes.aggregatedName} onClick={openEmployeePopup}>
          {unit.attributes.employeeId ? (
            <div
              className={cx(styles.unitItemName, {
                [styles.unitItemNameRow]: isWide,
              })}
            >
              <span className={cx(styles.unitItemNameValueAbove)}>{lastName}</span>
              <span
                className={cx(styles.unitItemNameValue, styles.unitItemNameValueUnder, {
                  [styles.unitItemNameValueFixWidth]: !isWide,
                })}
              >{`${firstName} ${secondName}`}</span>
            </div>
          ) : (
            <div
              className={cx(styles.unitItemName, {
                [styles.unitItemNameRow]: isWide,
              })}
            >
              <span
                className={cx(styles.unitItemNameValue, styles.unitItemNameValueAbove)}
              >{`${lastName} ${firstName}`}</span>
              <span
                className={cx(styles.unitItemNameValue, styles.unitItemNameValueUnder, {
                  [styles.unitItemNameValueFixWidth]: !isWide,
                })}
              >
                {secondName}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={cx(styles.unitButtons, styles.unitButtonsItem)}>
        <div className={styles.unitButtonsIcon}>
          {unitHasTracker && isUnitSelected && isUnitWatching ? (
            <GpsDeviceOn
              className={styles.iconGpsStateActive}
              onClick={() => watchUnit({ id: unit.id, currentStatus: true })}
            />
          ) : (
            <GpsDeviceOff
              className={isUnitSelected ? styles.iconGpsStateOn : styles.iconGpsStateOff}
              onClick={
                isUnitSelected
                  ? () =>
                      watchUnit({
                        id: unit.id,
                        currentStatus: false,
                      })
                  : undefined
              }
              data-tip
              data-for={`watch_unit_${unit.id}`}
            />
          )}
          <ReactTooltip
            className={styles.customTooltip}
            id={`watch_unit_${unit.id}`}
            place="right"
            type="light"
            effect="solid"
          >
            <span>{t('monitoring.units-list.unit.follow.tooltip.text')}</span>
          </ReactTooltip>
        </div>
        <div className={styles.unitButtonsIcon}>
          <NetworkStatusTracker className={styles[`iconConnectionState${unitHasTracker && isActive}`]} />
        </div>
        <div className={styles.unitButtonsIcon}>
          <NetworkQualityTracker className={styles[`unitButtonsIcon${signalLevel}`]} />
        </div>
        <div className={styles.unitButtonsIcon}>
          <BatteryChargingTracker className={styles[`unitButtonsIcon${findBatteryStatus(batteryCharge)}`]} />
          <span className={styles.unitButtonsIconText}>{batteryCharge ? `${batteryCharge}%` : 'N/A'}</span>
        </div>
        <div
          className={cx(styles.unitButtonsIcon, {
            [styles.unitButtonsIconDisabled]: !!unit.attributes.employeeId
              ? !employeeAccess.isAllowUpdate()
              : !transportAccess.isAllowUpdate(),
          })}
          onClick={() =>
            handleUpdateClick({
              transportId: unit.attributes.transportId,
              employeeId: unit.attributes.employeeId,
            })
          }
        >
          <EditIcon data-tip data-for={`edit_unit_${unit.id}`} />
          {(!!unit.attributes.employeeId ? employeeAccess.isAllowUpdate() : transportAccess.isAllowUpdate()) && (
            <ReactTooltip
              className={styles.customTooltip}
              id={`edit_unit_${unit.id}`}
              place="right"
              type="light"
              effect="solid"
            >
              <span>{t('monitoring.units-list.unit.settings.tooltip.text')}</span>
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
