import { createSlice } from '@reduxjs/toolkit';
import { MOCK_MARKERS } from './mocks';

export interface Marker {
  floor: number;
  fioMock: string;
  fio: string;
  data: number[];
  contacts: string[][];
}

type MarkerState = {
  markers: Marker[];
  error: string | null;
};

const initialState: MarkerState = {
  markers: MOCK_MARKERS,
  error: null,
};

const markerSlice = createSlice({
  name: 'MarkersSlice',
  initialState,
  reducers: {},
});

// export const { } = markerSlice.actions;

export default markerSlice.reducer;
