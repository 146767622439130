import React from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { PoiPopupInfoText } from './components/poiPopupInfoText';

// для internationalizations
const mocks = {
  notifications: {
    text: 'MOCK DATA',
  },
};

export function PoiFreePointPopup() {
  const t = useFormatMessage();

  return <PoiPopupInfoText label={t('map-popup.poi.free-point.notifications.text')} text={mocks.notifications.text} />;
}
