import React, { Fragment } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';

type Props = {
  onCancel: () => void;
  onCopy?: () => void;
  onSend: () => void;
  withCopy?: boolean;
};

export const ControlsModal = (props: Props) => {
  const t = useFormatMessage();

  return (
    <Fragment>
      {!props.withCopy && (
        <Button white text={t('messages.create-modal.footer.btn.copy.text')} onClick={props.onCopy} withCopy />
      )}
      <Button white text={t('messages.create-modal.footer.btn.cancel.text')} onClick={props.onCancel} />
      <Button blue text={t('messages.create-modal.footer.btn.send.text')} onClick={props.onSend} />
    </Fragment>
  );
};
