import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'reducers';
import {
  clearMessagesPagination,
  fetchMessagesLazy,
  setSelectedMessages,
  removeMessages,
  defaultMessagePagination,
  setMessagesPaginationField,
  setMessagesNeedUpdate,
  removeChosenMessage,
  fetchOneMessage,
} from 'reducers/messages';

import useObserveToWidthLeftSideBar from 'utils/useObserveToWidthLeftSideBar';
import useSetEllipsisAmountsToText from 'utils/useSetEllipsisAmountsToText';
import AccessEntity from 'utils/accessEntity';

const MAIN_HEADER_HEIGHT = 50;

export default function useMessagesList() {
  const dispatch = useDispatch();

  const { messages, isMessagesLoading, selectedMessages, needUpdate, total } = useSelector(
    (state: RootState) => state.messages
  );

  const [deletedId, setDeletedId] = useState<string | null>(null);
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

  const messagesRef = useRef<HTMLUListElement>(null);
  const footerListRef = useRef<HTMLUListElement>(null);
  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);

  // получим актуальную ширину левого сайдбара для правильного отображения текста в контейнере
  const leftSideBarWidth = useObserveToWidthLeftSideBar(messagesRef);

  // навесим атрибут title на интересующие нас элементы (с атрибутами [data-ellipsis] и [data-amount]), которые
  // не помещаются по ширине в контейнере, а также добавим к ним троеточие или количество неотображаемых элементов

  // для общего списка
  useSetEllipsisAmountsToText({
    width: leftSideBarWidth,
    ref: messagesRef,
    data: messages,
    ellipsisFromElementDelta: 230,
    amountFromElementDelta: 110,
  });
  // для списка с текстом сообщения
  useSetEllipsisAmountsToText({
    width: leftSideBarWidth,
    ref: footerListRef,
    data: messages,
    ellipsisFromElementDelta: 130,
    amountFromElementDelta: 110,
  });

  useEffect(() => {
    dispatch(clearMessagesPagination());
    dispatch(fetchMessagesLazy());

    return () => {
      dispatch(setSelectedMessages([]));
      dispatch(removeChosenMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(fetchMessagesLazy());
    }
  }, [dispatch, needUpdate]);

  const handleCheckItem = (id: string) => {
    const newSelectedMessages = [...selectedMessages];

    if (newSelectedMessages.includes(id)) {
      const foundIndex = newSelectedMessages.indexOf(id);

      if (foundIndex > -1) {
        newSelectedMessages.splice(foundIndex, 1);
      }
    } else {
      newSelectedMessages.push(id);
    }
    dispatch(setSelectedMessages(newSelectedMessages));
  };

  const handleDeleteItem = (id: string) => {
    setDeletedId(id);
  };

  const handleDeleteAlertCancel = () => {
    setDeletedId(null);
  };

  const handleDeleteAlertContinue = () => {
    if (deletedId && messagesAccess.isAllowDelete()) {
      const numberId = Number(deletedId);

      if (!isNaN(numberId)) {
        dispatch(removeMessages([numberId]));
      }
    }
    setDeletedId(null);
  };

  const handleNameClick = (event: React.MouseEvent, id: string) => {
    handleCheckItem(id);
    setMouseCoords({ x: event.pageX, y: event.pageY - MAIN_HEADER_HEIGHT });
    if (!isPopupShow) {
      setIsPopupShow(true);
    }
    dispatch(fetchOneMessage(id));
  };

  const handleClosePopup = () => {
    setIsPopupShow(false);
    dispatch(removeChosenMessage());
  };

  const handleListScroll = (evt: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const messageList = evt.target as Element;
    const { limit } = defaultMessagePagination;
    const page = Math.ceil(messages.length / limit);

    if (messageList && messages.length < total) {
      const messageListHeight = messageList.getBoundingClientRect().height;
      const messageListScrollHeight = messageList.scrollHeight;
      const messageListScrollTop = messageList.scrollTop;

      if (!isMessagesLoading && messageListScrollTop >= messageListScrollHeight - messageListHeight) {
        dispatch(setMessagesPaginationField({ key: 'page', value: page }));
        dispatch(setMessagesPaginationField({ key: 'limit', value: limit }));
        dispatch(setMessagesPaginationField({ key: 'offset', value: page * messages.length }));
        dispatch(setMessagesNeedUpdate(true));
      }
    }
  };

  return {
    states: {
      deletedId,
      isPopupShow,
      mouseCoords,
    },
    refs: {
      messagesRef,
      footerListRef,
    },
    handlers: {
      handleCheckItem,
      handleDeleteItem,
      handleDeleteAlertCancel,
      handleDeleteAlertContinue,
      handleNameClick,
      handleListScroll,
      handleClosePopup,
    },
  };
}
