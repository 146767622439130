import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import Button from 'components/common/button/button';
import Search from 'components/common/search/search';
import MessagesLeftBar from './components/messagesLeftBar/messagesLeftBar';
import { HANDBOOK_KEYS } from 'components/handbooks/utils/consts';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { setIsMessagesTableShow } from 'reducers/messages';
import { fetchHandbookData } from 'reducers/handbooks';

import AccessEntity from 'utils/accessEntity';
import { CREATE_MESSAGE } from 'utils/consts';

import styles from './messages.module.scss';

function Messages() {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);
  const { isTableMessagesShow } = useSelector((state: RootState) => state.messages);

  useEffect(() => {
    dispatch(fetchHandbookData([HANDBOOK_KEYS.positions]));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (isTableMessagesShow) {
        dispatch(setIsMessagesTableShow(false));
      }
    };
  }, [dispatch, isTableMessagesShow]);

  const handleSearch = (value: string) => {
    // TODO: search
  };

  const handleShowModal = () => {
    if (messagesAccess.isAllowCreate()) {
      dispatch(showModal({ type: CREATE_MESSAGE, which: '' }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.controlsSearch}>
          <Search handleChange={value => handleSearch(value)} />
        </div>
        {messagesAccess.isAllowCreate() && (
          <div className={styles.controlsCreate}>
            <Button
              blue
              text={t('messages.button-block.btn.label')}
              customStyle={styles.rowButton}
              onClick={handleShowModal}
            />
          </div>
        )}
      </div>
      <div className={styles.header}>
        <h4 className={styles.title}>{t('messages.left-bar.header.text')}</h4>
      </div>
      <MessagesLeftBar />
    </div>
  );
}

export default Messages;
