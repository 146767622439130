export default {
  en: {
    'map-popup.employee.header.text': 'Employee card',
    'map-popup.employee.no-position.text': 'Without position',
    'map-popup.employee.no-company.text': 'Without organization',
    'map-popup.employee.no-subdivision.text': 'Without subdivision',
    'map-popup.employee.no-object.text': 'Without object',
    'map-popup.employee.phone.text': 'Phone:',
    'map-popup.employee.company.text': 'Organization:',
    'map-popup.employee.subdivision.text': 'Subdivision:',
    'map-popup.employee.pass-number.text': 'Pass №:',
    'map-popup.employee.issue.text': 'issued from',
    'map-popup.employee.issue.pretext.text': 'to',
    'map-popup.employee.coordinates.text': 'Coordinates:',
    'map-popup.employee.map-contacts-label.text': 'Social contacts map',
    'map-popup.employee.map-contacts.text': 'View social contacts map',
    'map-popup.employee.outfit.text': 'Electronic work permits',
    'map-popup.employee.go-outfit.text': 'Go to the module "Electronic work permit"',
    'map-popup.employee.current-position.text': 'Current position',
    'map-popup.employee.temperature.text': 'Body temperature',
    'map-popup.employee.no-tracker.text': 'No tracker',
  },
  ru: {
    'map-popup.employee.header.text': 'Карточка сотрудника',
    'map-popup.employee.no-position.text': 'Без должности',
    'map-popup.employee.no-company.text': 'Без организации',
    'map-popup.employee.no-subdivision.text': 'Без подразделения',
    'map-popup.employee.no-object.text': 'Без объекта',
    'map-popup.employee.phone.text': 'Телефон:',
    'map-popup.employee.company.text': 'Организация:',
    'map-popup.employee.subdivision.text': 'Структурное подразделение:',
    'map-popup.employee.pass-number.text': 'Пропуск №:',
    'map-popup.employee.issue.text': 'выдан с',
    'map-popup.employee.issue.pretext.text': 'по',
    'map-popup.employee.coordinates.text': 'Координаты:',
    'map-popup.employee.map-contacts-label.text': 'Карта контактов',
    'map-popup.employee.map-contacts.text': 'Посмотреть карту социальных контактов',
    'map-popup.employee.outfit.text': 'Электронные наряд-допуски',
    'map-popup.employee.go-outfit.text': 'Перейти в модуль “Электронный наряд-допуск”',
    'map-popup.employee.current-position.text': 'Текущее положение',
    'map-popup.employee.temperature.text': 'Температура тела',
    'map-popup.employee.no-tracker.text': 'Без трекера',
  },
  zh: {
    'map-popup.employee.header.text': '員工卡',
    'map-popup.employee.no-position.text': '没有位置',
    'map-popup.employee.no-company.text': '无组织的',
    'map-popup.employee.no-subdivision.text': '无细分',
    'map-popup.employee.no-object.text': '没有对象',
    'map-popup.employee.phone.text': '电话：',
    'map-popup.employee.company.text': '组织：',
    'map-popup.employee.subdivision.text': '结构分解：',
    'map-popup.employee.work-object.text': '工作目标：',
    'map-popup.employee.pass-number.text': '通行证号：',
    'map-popup.employee.issue.text': '自发布以来',
    'map-popup.employee.issue.pretext.text': '到',
    'map-popup.employee.coordinates.text': '协调：',
    'map-popup.employee.map-contacts-label.text': '對象卡',
    'map-popup.employee.map-contacts.text': '查看社交地图',
    'map-popup.employee.outfit.text': '电子工作许可证',
    'map-popup.employee.go-outfit.text': '转到模块“电子工作许可证”',
    'map-popup.employee.current-position.text': '当前位置',
    'map-popup.employee.temperature.text': '体温',
    'map-popup.employee.no-tracker.text': 'No tracker',
  },
  ar: {
    'map-popup.employee.header.text': 'بطاقة الموظف',
    'map-popup.employee.no-position.text': 'بدون وظيفة',
    'map-popup.employee.no-company.text': 'بدون مؤسسة',
    'map-popup.employee.no-subdivision.text': 'بدون شعبة',
    'map-popup.employee.no-object.text': 'بدون كائن',
    'map-popup.employee.phone.text': 'هاتف:',
    'map-popup.employee.company.text': 'مؤسسة:',
    'map-popup.employee.subdivision.text': 'شعبة:',
    'map-popup.employee.work-object.text': 'عملية تنفيذ العمل:',
    'map-popup.employee.pass-number.text': 'تصريح رقم:',
    'map-popup.employee.issue.text': 'جهة المنح',
    'map-popup.employee.issue.pretext.text': 'إلى',
    'map-popup.employee.coordinates.text': 'الإحداثيات:',
    'map-popup.employee.map-contacts-label.text': 'الاجتماعية خريطة جهات الاتصال',
    'map-popup.employee.map-contacts.text': 'عرض خريطة جهات لالاتثال الاجتماعية',
    'map-popup.employee.outfit.text': 'تصاريح عمل إلكترونية',
    'map-popup.employee.go-outfit.text': 'انتفال إلى موديول "تصريح عمل إلكتروني"',
    'map-popup.employee.current-position.text': 'حالة جارية',
    'map-popup.employee.temperature.text': 'درجة حرارة الجسم',
    'map-popup.employee.no-tracker.text': 'No tracker',
  },
  de: {
    'map-popup.employee.header.text': 'Mitarbeiterkarte',
    'map-popup.employee.no-position.text': 'Ohne Stelle',
    'map-popup.employee.no-company.text': 'Ohne Organisation',
    'map-popup.employee.no-subdivision.text': 'Ohne Unterteilung',
    'map-popup.employee.no-object.text': 'Ohne Objekt',
    'map-popup.employee.phone.text': 'Telefon:',
    'map-popup.employee.company.text': 'Organisation:',
    'map-popup.employee.subdivision.text': 'Strukturelle Unterteilung:',
    'map-popup.employee.work-object.text': 'Arbeitsgegenstand:',
    'map-popup.employee.pass-number.text': 'Passnummer:',
    'map-popup.employee.issue.text': 'ausgestellt seit',
    'map-popup.employee.issue.pretext.text': 'bis',
    'map-popup.employee.coordinates.text': 'Koordinaten:',
    'map-popup.employee.map-contacts-label.text': 'Soziale Kontaktkarte',
    'map-popup.employee.map-contacts.text': 'Soziale Karte anzeigen',
    'map-popup.employee.outfit.text': 'Elektronische Arbeitserlaubnis',
    'map-popup.employee.go-outfit.text': 'Gehe zum Modul "Elektronische Arbeitserlaubnis"',
    'map-popup.employee.current-position.text': 'aktuelle Position',
    'map-popup.employee.temperature.text': 'Körpertemperatur',
    'map-popup.employee.no-tracker.text': 'No tracker',
  },
};
