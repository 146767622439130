import React, { useMemo } from 'react';
import styles from '../../tracks.module.scss';
import Button from 'components/common/button/button';
import { TracksListHeader } from '../tracksListHeader/tracksListHeader';
import { ReactComponent as DeleteIcon } from 'assets/img/delete-cart-icon.svg';
import { Checkbox } from 'components/common/checkbox/checkbox';
import { ReactComponent as PlayBody } from 'assets/img/play-item.svg';
import { ReactComponent as EditIcon } from 'assets/img/gear.svg';
import { formatToDDMMYYHHMM } from 'utils/formatTime';
import { ReactComponent as PauseBody } from 'assets/img/player/pause-icon.svg';
import { ReactComponent as ColorRect } from 'assets/img/color-rect.svg';
import {
  fetchGetTracksGroupGeodata,
  playersStatus,
  selectAllTracks,
  selectTrack,
  setChosenTrack,
  setChosenTrackForDelete,
  tracesToShow,
  unSelectAllTracks,
  unselectTrack,
} from 'reducers/tracks';
import { showAlert } from 'reducers/modal';
import { DELETE_TRACK } from 'utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import AccessEntity from 'utils/accessEntity';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { Spinner } from 'components/common/spinner/spinner';
const cx = classNames.bind(styles);

interface TrackListProps {
  handleOpenCreateTrack: (arg0: boolean) => void;
}

const TrackList = ({ handleOpenCreateTrack }: TrackListProps) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const tracksPermissions = useSelector((state: RootState) => state.user.permissions.tracks);
  const tracksAccess = useMemo(() => new AccessEntity(tracksPermissions), [tracksPermissions]);

  const {
    selectedTracks,
    newTracksGroups,
    newTracks,
    newTracksMeta: { total },
    players,
    sort,
    isLoading,
  } = useSelector((state: RootState) => state.track);

  const handleSelect = (id: string) => {
    if (id === '-1') {
      if (selectedTracks.length !== newTracksGroups.length) {
        newTracks?.forEach(tr => {
          if (tr.id && tr.attributes.periodFrom && tr.attributes.periodTo) {
            dispatch(
              fetchGetTracksGroupGeodata({
                id: tr.id,
                from: tr.attributes.periodFrom,
                to: tr.attributes.periodTo,
              })
            );
          }
        });
        return dispatch(selectAllTracks(newTracksGroups.map(track => track.id)));
      } else {
        window.progress = 0;
        dispatch(
          playersStatus({
            id: window.activeId,
            play: false,
            pause: false,
            currentCoord: null,
            currentTime: null,
          })
        );
        window.currentCoord = null;
        window.currentTime = null;
        dispatch(unSelectAllTracks());
        return dispatch(tracesToShow());
      }
    }
    if (selectedTracks.includes(String(id))) {
      dispatch(unselectTrack(String(id)));
      dispatch(tracesToShow());
      window.progress = 0;
      dispatch(
        playersStatus({
          id: window.activeId,
          play: false,
          pause: false,
          currentCoord: null,
          currentTime: null,
        })
      );
      window.currentCoord = null;
      window.currentTime = null;
    } else if (!selectedTracks.includes(String(id))) {
      const tracks = newTracks.filter(tr => String(tr.attributes.trackGroupId) === id);
      if (tracks[0] && tracks[0].attributes.periodFrom && tracks[0].attributes.periodTo) {
        dispatch(
          fetchGetTracksGroupGeodata({
            id: id,
            from: tracks[0].attributes.periodFrom,
            to: tracks[0].attributes.periodTo,
          })
        );
        dispatch(selectTrack(String(id)));
      }
    }
  };

  const openTrackCreation = (id?: string) => {
    if ((!id && tracksAccess.isAllowCreate()) || tracksAccess.isAllowUpdate()) {
      dispatch(setChosenTrack(id));
      handleOpenCreateTrack(true);
    }
  };
  const handlePlay = (id: string) => {
    dispatch(
      playersStatus({
        id,
        // eslint-disable-next-line
        // @ts-ignore
        play: !players[id] ? true : !players[id].play,
        // eslint-disable-next-line
        // @ts-ignore
        pause: !players[id] ? false : !!players[id].play,
        speed: 3,
        show: true,
        mini: false,
        progress: 0,
      })
    );

    window.activeId = id;
  };
  const deleteTracker = (id: string) => {
    if (tracksAccess.isAllowDelete()) {
      dispatch(setChosenTrackForDelete(id));
      return dispatch(showAlert({ alertType: DELETE_TRACK }));
    }
  };

  const tracksGroupsInfoSorted = newTracksGroups.slice().sort((a, b) => {
    if (sort.ascending) {
      return a.attributes.name < b.attributes.name ? -1 : 1;
    }
    return a.attributes.name < b.attributes.name ? 1 : -1;
  });

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
      <div>
        {tracksAccess.isAllowCreate() && (
          <div className={styles.createButton}>
            <Button
              text={t('track.button-block.btn.label')}
              blue
              long
              withSymbol
              onClick={() => openTrackCreation('0')}
            />
          </div>
        )}
        <div
          className={cx(styles.headBlock, {
            [styles.headBlockMarginTop]: !tracksAccess.isAllowCreate(),
          })}
        >
          <TracksListHeader
            checked={selectedTracks.length === total}
            isIndeterminate={selectedTracks.length > 0 && selectedTracks.length !== total}
            handleCheck={() => handleSelect('-1')}
            // eslint-disable-next-line
            // @ts-ignore
            pause={!!window.activeId && !!players && !!players[window.activeId] && !!players[window.activeId].play}
          />
          <DeleteIcon
            className={cx(styles.headBlockDelete, {
              [styles.headBlockDeleteDisabled]: !tracksAccess.isAllowDelete(),
            })}
          />
        </div>
        <div>
          {tracksGroupsInfoSorted.map((group, index) => {
            const tracksFromGroup = newTracks.filter(tr => String(tr.attributes.trackGroupId) === group.id);
            const dateFrom = new Date(tracksFromGroup[0]?.attributes.periodFrom);
            const dateTo = new Date(tracksFromGroup[0]?.attributes.periodTo);
            const dateFromFormated = formatToDDMMYYHHMM(dateFrom);
            const dateToFormated = formatToDDMMYYHHMM(dateTo);
            return (
              <div key={`track-key-${index}`} className={styles.trackItem}>
                <div className={styles.trackItemMainRow}>
                  <Checkbox
                    checked={selectedTracks.includes(group.id)}
                    handleCheck={() => handleSelect(group.id)}
                    isDisabled={!group.relationships.track.data.length}
                  />

                  {window.activeId &&
                  players &&
                  // eslint-disable-next-line
                  // @ts-ignore
                  players[window.activeId] &&
                  // eslint-disable-next-line
                  // @ts-ignore
                  players[window.activeId].play &&
                  window.activeId === group.id ? (
                    <PauseBody
                      className={cx(styles.trackItemMainRowPlay, {
                        [styles.trackItemMainRowPlayDisabled]: !selectedTracks.includes(group.id),
                      })}
                      onClick={() => handlePlay(group.id)}
                    />
                  ) : (
                    <PlayBody
                      className={cx(styles.trackItemMainRowPlay, {
                        [styles.trackItemMainRowPlayDisabled]: !selectedTracks.includes(group.id),
                      })}
                      onClick={() => handlePlay(group.id)}
                    />
                  )}
                  <div className={styles.trackItemNameBlock}>
                    <span>
                      {t('track.list.track-name.text')} {group.id}
                    </span>
                    {dateFrom.isValid() && dateTo.isValid() && (
                      <span>
                        {dateFromFormated}&nbsp;&ndash;&nbsp;{dateToFormated}
                      </span>
                    )}
                  </div>
                  <div className={styles.trackItemIconsGroup}>
                    <EditIcon
                      className={cx(styles.trackEditIcon, {
                        [styles.trackEditIconDisabled]: !tracksAccess.isAllowUpdate(),
                      })}
                      onClick={() => openTrackCreation(group.id)}
                    />

                    <DeleteIcon
                      className={cx(styles.trackDeleteIcon, {
                        [styles.trackEditIconDisabled]: !tracksAccess.isAllowDelete(),
                      })}
                      onClick={() => deleteTracker(group.id)}
                    />
                  </div>
                </div>

                {tracksFromGroup.map((track, objInd) => (
                  <div key={`track-object-key-${objInd}`} className={styles.trackItemColorRow}>
                    <ColorRect fill={track.attributes.color} />
                    <div className={styles.trackItemColorRowName}>
                      <span>
                        {t('track.list.tracker-name.text')} {track.relationships.tracker.data?.id}
                      </span>
                      <span>{track.attributes.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TrackList;
