import { getTimezoneOffset } from 'date-fns-tz';
import { timezoneOffsetToString } from 'utils/formatTime';
import { Option } from 'components/common/select/select';

export interface TimezoneWithTranslation {
  ianaName: string;
  translation: string;
}

export function generateTimezoneSelectOptions(timezonesWithTranslations: TimezoneWithTranslation[]): Option[] {
  const offsets = timezonesWithTranslations.map(timezone => {
    const timezoneOffset = getTimezoneOffset(timezone.ianaName);
    const timezoneOffsetString = timezoneOffsetToString(timezoneOffset);

    return {
      value: timezone.ianaName,
      label: `(UTC ${timezoneOffsetString}) ${timezone.translation}`,
    };
  });

  offsets.sort((offset1, offset2) => getTimezoneOffset(offset1.value) - getTimezoneOffset(offset2.value));

  return offsets;
}
