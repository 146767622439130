import React, { Fragment, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import Employee from 'assets/img/employee-big.png';
import Transport from 'assets/img/transport-big.png';
import Sensor from 'assets/img/sensor-big.png';
import { ReactComponent as HeaderIcon } from 'assets/img/messages/popup-header-icon.svg';

import { Spinner } from 'components/common/spinner/spinner';
import Button from 'components/common/button/button';
import { getTranslateFromLanguageKey } from 'components/handbooks/utils/helpers';
import MarkerPopup from 'components/common/markerPopup/markerPopup';
import { MESSAGE_TYPES_TO_TRANSLATE } from 'components/messages/utils/consts';

import { RootState } from 'reducers';
import { removeChosenMessage, removeMessages } from 'reducers/messages';
import { TrackableUnitType } from 'reducers/trackableUnits/interface';

import { getCurrentLocale } from 'translate';

import AccessEntity from 'utils/accessEntity';
import { getFullDateFromStr } from 'utils/getFullDateFromStr';
import { APP_CONTENT_ID } from 'utils/consts';

import styles from './messagesPopup.module.scss';

const cx = classNames.bind(styles);

const getPhoto = (type: TrackableUnitType) => {
  switch (type) {
    case 'employee':
      return Employee;
    case 'transport':
      return Transport;
    case 'sensor':
      return Sensor;
    default:
      break;
  }
};

interface Props {
  onCancel: () => void;
  markerCoords: { mouseCoords: { x: number; y: number }; mapCoords: { x: number; y: number } };
}

const INITIAL_POPUP_WIDTH = 400;

function MessagesPopup({ onCancel, markerCoords }: Props) {
  const dispatch = useDispatch();
  const t = useFormatMessage();

  const userLanguageKey = useSelector((state: RootState) => getCurrentLocale(state.user.userPreferences.locale));

  const { data: handbooksData } = useSelector((state: RootState) => state.handbooks);
  const { isChosenMessageLoading, chosenMessage } = useSelector((state: RootState) => state.messages);
  const messagesPermissions = useSelector((state: RootState) => state.user.permissions.messages);
  const messagesAccess = useMemo(() => new AccessEntity(messagesPermissions), [messagesPermissions]);

  const handleDelete = (id: string) => {
    const numberId = Number(id);

    if (!isNaN(numberId)) {
      onCancel();
      dispatch(removeChosenMessage());
      dispatch(removeMessages([numberId]));
    }
  };

  const handleClosePopup = () => {
    dispatch(removeChosenMessage());
    onCancel();
  };

  if (!chosenMessage) {
    return null;
  }

  const chosenUnitsIds = chosenMessage.data.relationships.trackableUnits.data.map(unit => unit.id);
  const trackableUnits = chosenMessage.included.filter(unit => chosenUnitsIds.includes(unit.id));
  const chosenUnit = trackableUnits[0] || null;

  if (!chosenUnit) {
    return null;
  }

  const foundPosition = handbooksData.positions?.find(position => position.id === chosenUnit.attributes.positionId);
  const employeePosition = foundPosition
    ? getTranslateFromLanguageKey(foundPosition.attributes.name, userLanguageKey)
    : '';
  const createdDate = getFullDateFromStr(chosenMessage.data.attributes.createdAt);

  const headerPopup = (
    <div className={styles.popupHeader}>
      <div className={styles.popupHeaderIcon}>
        <HeaderIcon className={styles.popupHeaderImage} />
      </div>
      <h4 className={styles.popupHeaderTitle}>{t('messages.chosen-messages.popup.header.text')}</h4>
    </div>
  );

  const contentPopup = isChosenMessageLoading ? (
    <Spinner />
  ) : (
    <div>
      <div className={styles.popupInfo}>
        <div className={styles.popupInfoIcon}>
          <div className={styles.popupInfoIconImage}>
            <img
              alt="Message Icon"
              className={styles.popupInfoIconPhoto}
              src={getPhoto(chosenUnit.attributes.employeeId ? 'employee' : 'transport')}
            />
          </div>
          <div className={styles.popupInfoIconNumber}>{chosenUnit.attributes.trackerNumber}</div>
        </div>
        <div className={styles.popupInfoContent}>
          <div className={styles.popupInfoContentData}>
            <div className={styles.popupInfoContentDataName}>{chosenUnit.attributes.aggregatedName}</div>
            <div className={styles.popupInfoContentDataPosition}>{employeePosition}</div>
            <div className={styles.popupInfoContentDataNumber}></div>
          </div>
          <div className={cx(styles.popupInfoContentData, styles.NotificationData)}>
            <div className={styles.popupInfoContentDataType}>
              {t('messages.chosen-messages.popup.body.type.text')}
              {': '}
              {t(MESSAGE_TYPES_TO_TRANSLATE[chosenMessage.data.attributes.messageType])}
            </div>
            <div className={styles.popupInfoContentDataDate}>
              {`${t('messages.chosen-messages.popup.body.date.text')}: ${createdDate.date} - ${createdDate.shortTime}`}
            </div>
            <div className={styles.popupInfoContentDataPlace}>
              {`${t('messages.chosen-messages.popup.body.place.text')}: `}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.popupDescription}>
        <h5 className={styles.popupDescriptionTitle}>
          {t('messages.chosen-messages.popup.footer.label.text')}
          {': '}
        </h5>
        <div className={styles.popupDescriptionText}>{chosenMessage.data.attributes.body}</div>
      </div>

      <div className={styles.popupContentBtns}>
        {!isChosenMessageLoading && (
          <Fragment>
            {messagesAccess.isAllowDelete() && (
              <Button
                red
                text={t('messages.chosen-messages.popup.footer.btn.delete.text')}
                onClick={() => handleDelete(chosenMessage.data.id)}
                customStyle={styles.popupContentBtnsMaxWidth}
              />
            )}
            {messagesAccess.isAllowUpdate() && (
              <Button white text={t('messages.chosen-messages.popup.footer.btn.save.text')} />
            )}
          </Fragment>
        )}
        <Button white text={t('messages.chosen-messages.popup.footer.btn.close.text')} onClick={handleClosePopup} />
      </div>
    </div>
  );

  const appContentContainer = document.getElementById(APP_CONTENT_ID);

  if (!appContentContainer) {
    return null;
  }

  return createPortal(
    <MarkerPopup
      header={headerPopup}
      content={contentPopup}
      initialWidth={INITIAL_POPUP_WIDTH}
      initialPosition={markerCoords.mouseCoords}
      customClassNames={{ content: styles.customContentStyles }}
      onCancel={onCancel}
    />,
    appContentContainer
  );
}

export default React.memo(MessagesPopup);
