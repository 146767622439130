import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';

import { ReactComponent as ToolIcon } from 'assets/img/tool.svg';

import Select from 'components/common/select/select';
import { Spinner } from 'components/common/spinner/spinner';
import WithSortIcon from 'components/common/withSortIcon/withSortIcon';

import { RootState } from 'reducers';
import { fetchAccounts } from 'reducers/accounts';
import { showModal } from 'reducers/modal';
import { updateClientUserPreferences } from 'reducers/clientUserPreferences';
import { ExpandedGroupsState } from 'reducers/clientUserPreferences/interface';

import AccessEntity from 'utils/accessEntity';
import { ACCOUNT, USERS_LIST_CONFIG } from 'utils/consts';
import treeGroupBy from 'utils/treeGroupBy/treeGroupBy';
import { getUserFullName } from 'utils/users/viewData';
import { SortType } from 'utils/interfaces';

import AccountsHeader from '../accountsHeader/accountsHeader';
import { AccountsPage } from '../utils/types';
import UsersTree from './components/usersTree/usersTree';
import { useParseHandbooksData } from './hooks/usersListHooks';

import styles from './usersList.module.scss';

export type UsersListViewData = {
  id: string;
  fullName: string;
  login: string;
  organization: string | null;
  department: string | null;
  position: string | null;
  roles: string[];
  isBanned: boolean;
};

type Props = {
  changePage: (page: AccountsPage) => void;
};

type Entity = 'organizations' | 'departments' | 'positions';

const messages = {
  selectAllLabel: (entity: Entity) => `accounts.users-list-filters.all-${entity}.text`,
  selectLabel: (entity: Entity) => `accounts.users-list-filters.${entity}-label.text`,
  columnLabel: (column: string) => `accounts.users-list-columns.column-${column}.text`,
};

function convertIdToString(id: number | null): string | null {
  return id === null ? null : id.toString();
}

const UsersList: React.FC<Props> = ({ changePage }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const accounts = useSelector((state: RootState) => state.account.accounts);
  const rolesIncluded = useSelector((state: RootState) => state.account.rolesIncluded);

  const isLoading = useSelector((state: RootState) => state.account.isLoading);
  const updateCounter = useSelector((state: RootState) => state.account.updateCounter);

  const usersPermissions = useSelector((state: RootState) => state.user.permissions.users);
  const usersAccess = useMemo(() => new AccessEntity(usersPermissions), [usersPermissions]);

  const filterConfig = useSelector((state: RootState) => state.clientUserPreferences.usersListFilterConfig);
  const sortConfig = useSelector((state: RootState) => state.clientUserPreferences.usersListSortConfig);

  const groupingConfig = useSelector((state: RootState) => state.clientUserPreferences.usersListGroupingConfig);
  const visibleColumnsConfig = useSelector(
    (state: RootState) => state.clientUserPreferences.usersListVisibleColumnsConfig
  );
  const expandedGroupsState = useSelector(
    (state: RootState) => state.clientUserPreferences.usersListExpandedGroupsState
  );

  const { organizations, departments, positions, organizationOptions, departmentOptions, positionOptions } =
    useParseHandbooksData();

  const users: UsersListViewData[] = useMemo(
    () =>
      accounts.map(account => ({
        id: account.id,
        fullName: getUserFullName(account),
        login: account.attributes.login,
        organization: convertIdToString(account.attributes.organizationId),
        department: convertIdToString(account.attributes.departmentId),
        position: convertIdToString(account.attributes.positionId),
        roles: account.relationships.roles.data.map(role => rolesIncluded[role.id] || ''),
        isBanned: account.attributes.isBanned,
      })),
    [accounts, rolesIncluded]
  );

  const [groupedUsers, allGroupKeys] = useMemo(
    () => treeGroupBy<UsersListViewData>(users, groupingConfig),
    [users, groupingConfig]
  );

  const visibleColumns = useMemo(
    () => visibleColumnsConfig.filter(item => item.isVisible).map(item => item.column),
    [visibleColumnsConfig]
  );

  const expandState: ExpandedGroupsState = {};

  for (const key in allGroupKeys) {
    expandState[key] = Boolean(expandedGroupsState[key]);
  }

  useEffect(() => {
    dispatch(
      fetchAccounts({
        organizationFilter: filterConfig.organizationsFilter,
        departmentFilter: filterConfig.departmentsFilter,
        positionFilter: filterConfig.positionsFilter,
        sortBy: sortConfig.sortBy,
        sortType: sortConfig.sortType,
      })
    );
  }, [filterConfig, sortConfig, updateCounter]);

  const handleSearchChange = () => {
    // not implemented
  };

  const handleCreateClick = () => {
    dispatch(showModal({ type: ACCOUNT, which: '' }));
  };

  const handleOrganizationsFilterChange = (value: string) => {
    dispatch(
      updateClientUserPreferences({
        usersListFilterConfig: {
          ...filterConfig,
          organizationsFilter: value ? [value] : [],
        },
      })
    );
  };

  const handleDepartmentsFilterChange = (value: string) => {
    dispatch(
      updateClientUserPreferences({
        usersListFilterConfig: {
          ...filterConfig,
          departmentsFilter: value ? [value] : [],
        },
      })
    );
  };

  const handlePositionsFilterChange = (value: string) => {
    dispatch(
      updateClientUserPreferences({
        usersListFilterConfig: {
          ...filterConfig,
          positionsFilter: value ? [value] : [],
        },
      })
    );
  };

  const openConfigModal = () => {
    dispatch(showModal({ type: USERS_LIST_CONFIG, which: '' }));
  };

  const handleExpandClick = (groupKey: string) => {
    dispatch(
      updateClientUserPreferences({
        usersListExpandedGroupsState: {
          ...expandState,
          [groupKey]: !expandState[groupKey],
        },
      })
    );
  };

  const handleColumnHeaderClick = (column: keyof UsersListViewData) => {
    if (column !== 'roles') {
      dispatch(
        updateClientUserPreferences({
          usersListSortConfig: {
            sortBy: column,
            sortType:
              sortConfig.sortBy === column && sortConfig.sortType === SortType.ASC ? SortType.DESC : SortType.ASC,
          },
        })
      );
    }
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <AccountsHeader
          isAllowCreate={usersAccess.isAllowCreate()}
          currentPage={AccountsPage.Users}
          handleSearchChange={handleSearchChange}
          handleCreateClick={handleCreateClick}
          changePage={changePage}
        />

        <div className={styles.filtersContainer}>
          <Select
            label={t(messages.selectLabel('organizations'))}
            placeholder={t(messages.selectAllLabel('organizations'))}
            options={organizationOptions}
            value={filterConfig.organizationsFilter[0] || ''}
            containerStyle={styles.select}
            handleChange={handleOrganizationsFilterChange}
          />

          <Select
            label={t(messages.selectLabel('departments'))}
            placeholder={t(messages.selectAllLabel('departments'))}
            options={departmentOptions}
            value={filterConfig.departmentsFilter[0] || ''}
            containerStyle={styles.select}
            handleChange={handleDepartmentsFilterChange}
          />

          <Select
            label={t(messages.selectLabel('positions'))}
            placeholder={t(messages.selectAllLabel('positions'))}
            options={positionOptions}
            value={filterConfig.positionsFilter[0] || ''}
            containerStyle={styles.select}
            handleChange={handlePositionsFilterChange}
          />
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {visibleColumns.map(column => (
              <th key={column} onClick={() => handleColumnHeaderClick(column)}>
                <WithSortIcon sortType={sortConfig.sortType} showSortIcon={sortConfig.sortBy === column}>
                  {t(messages.columnLabel(column))}
                </WithSortIcon>
              </th>
            ))}

            <th>
              <ToolIcon onClick={openConfigModal} />
            </th>
          </tr>
        </thead>

        <tbody>
          {!isLoading && (
            <UsersTree
              nodes={groupedUsers}
              columns={visibleColumns}
              expandState={expandState}
              organizations={organizations}
              departments={departments}
              positions={positions}
              handleExpandClick={handleExpandClick}
            />
          )}
        </tbody>
      </table>

      {isLoading && <Spinner />}
    </div>
  );
};

export default UsersList;
