import React, { ReactElement } from 'react';
import cs from 'classnames';

import { TabData } from '../types/tabsTypes';

import styles from './horizontalTabs.module.scss';

// enum can be used as TValue
type Props<TValue extends string | number> = {
  className?: string;
  tabs: TabData<TValue>[];
  activeTab: TValue;
  setActiveTab: (tabValue: TValue) => void;
};

function HorizontalTabs<TValue extends string | number>({
  className,
  tabs,
  activeTab,
  setActiveTab,
}: Props<TValue>): ReactElement | null {
  return (
    <div className={cs(styles.container, className)}>
      {tabs.map(({ value, label, icon }) => (
        <div
          key={value.toString()}
          className={cs(styles.tab, { [styles.tab_active]: value === activeTab })}
          onClick={() => {
            setActiveTab(value);
          }}
        >
          {icon}
          <div className={styles.tabLabel}>{label}</div>
        </div>
      ))}
    </div>
  );
}

export default HorizontalTabs;
