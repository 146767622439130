import React, { Dispatch } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import classNames from 'classnames/bind';
import { ActiveTab } from 'components/userSettings/utils/consts';

import styles from './tabs.module.scss';

const cx = classNames.bind(styles);

interface TabsProps {
  activeTab: ActiveTab;
  setActiveTab: Dispatch<ActiveTab>;
}

export const UserSettingsTabsNav = ({ activeTab, setActiveTab }: TabsProps) => {
  const t = useFormatMessage();

  return (
    <div className={styles.modalTabs}>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.common,
        })}
        onClick={() => setActiveTab(ActiveTab.common)}
      >
        <span className={styles.modalTabLabel}>{t('user-settings.modal.tabs.general.text')}</span>
      </div>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.personal,
        })}
        onClick={() => setActiveTab(ActiveTab.personal)}
      >
        <span className={styles.modalTabLabel}>{t('user-settings.modal.tabs.data.text')}</span>
      </div>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.tabs,
        })}
        onClick={() => setActiveTab(ActiveTab.tabs)}
      >
        <span className={styles.modalTabLabel}>{t('user-settings.modal.tabs.tabs-settings.text')}</span>
      </div>
      <div
        className={cx(styles.modalTab, {
          [styles.modalTabActive]: activeTab === ActiveTab.objects,
        })}
        onClick={() => setActiveTab(ActiveTab.objects)}
      >
        <span className={styles.modalTabLabel}>{t('user-settings.modal.tabs.objects.text')}</span>
      </div>
    </div>
  );
};
