import { fromLonLat } from 'ol/proj';

//TODO: редактировать: структура не соответствует определению списка констант

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION ?? '';

export const RTSP_PROXY_PORT = '8888';

export const MAP_CENTER_DEFAULT = fromLonLat([56.15899, 57.87996]);
export const MAP_CENTER_OLD = fromLonLat([56.15899, 57.87996]);
export const MAP_CENTER = MAP_CENTER_DEFAULT;

export const MAPBOX_URL =
  'https://api.mapbox.com/styles/v1/usupovrush/cjx1u2x3605vr1cpf81za0sf9/tiles/256/{z}/{x}/{y}@2x?access_token=';
export const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoidXN1cG92cnVzaCIsImEiOiJjangwajA5cXUwMDJlNDlxa2Y5YzBmdXl3In0.P3s2Pk9KeXdXw0F2gKUFOw';

export enum LAYERS {
  OSM = 'osm',
  MAPBOX = 'mapbox',
}

export enum MARKERS {
  default = 'default',
  onmouseover = 'onmouseover',
}

export enum HEATMAP {
  none = 'none',
  default = 'default',
  objects = 'objects',
  all = 'all',
}

export const MAP_LAYER_RENDER_BUFFER_VALUE = 200;

export const MIN_ZOOM_DEFAULT_VALUE = 4;
export const MAX_ZOOM_DEFAULT_VALUE = 23;
export const ZOOM_DEFAULT_VALUE = 19;

export const MAP_LAYERS_Z_INDEX = {
  HEAT_MAP_OBJECTS: 1,
  FLOORS: 2,
  TRACKS: 3,
  GEOZONES: 4,
  NOTIFICATIONS: 5,
  TRACKERS: 6,
  MONITORING: 7,
  POI_VIDEO_CAMERA_SECTORS: 8,
  POI_VIDEO_CAMERA_EDITOR: 9,
  POI_MARKERS: 10,
};

export const EMPLOYEE = 'EMPLOYEE';
export const TRACKER = 'TRACKER';
export const TRANSPORT = 'TRANSPORT';
export const DELETE_TRACKER = 'DELETE_TRACKER';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_TRACK = 'DELETE_TRACK';
export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';
export const DELETE_GEOZONE = 'DELETE_GEOZONE';
export const DELETE_GEOZONE_GROUP_MULTIPLE = 'DELETE_GEOZONE_GROUP_MULTIPLE';
export const DELETE_HANDBOOKS_NOTE = 'DELETE_HANDBOOKS_NOTE';
export const OBJECT_LIST = 'OBJECT_LIST';
export const GEOZONE_GROUP = 'GEOZONE_GROUP';
export const ROLE = 'ROLE';
export const ROLE_TO_USER = 'ROLE_TO_USER';
export const ACCOUNT = 'ACCOUNT';
export const TRACK = 'TRACK';
export const MONITORING_GROUPING = 'MONITORING_GROUPING';
export const GEOZONES_GROUPING = 'GEOZONES_GROUPING';
export const USER_SETTINGS = 'USER_SETTINGS';
export const VIDEO_SETTINGS = 'VIDEO_SETTINGS';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATIONS_GROUPING = 'NOTIFICATIONS_GROUPING';
export const STATISTIC_HANDBOOK = 'STATISTIC_HANDBOOK';
export const CREATE_TEMPLATE_RECORDS = 'CREATE_TEMPLATE_RECORDS';
export const REPORT_INFO = 'REPORT_INFO';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USERS_LIST_CONFIG = 'USERS_LIST_CONFIG';

export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
export const TWO_WEEKS = 14 * 24 * 60 * 60 * 1000;
export const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;

export const NA = 'N/A';
export const HIDE_TEXT = 'common-info.map-popup.hide.text';
export const MORE_TEXT = 'common-info.map-popup.more.text';

export enum ObjectType {
  employee = 'employee',
  transport = 'transport',
}

export enum SignalLevel {
  NULL,
  BADDEST,
  BAD,
  MEDIUM,
  GOOD,
}

export enum GeoZones {
  DEFAULT = '',
  LINE = 'LINE',
  RECTANGLE = 'RECTANGLE',
  CIRCLE = 'CIRCLE',
  POLYGON = 'POLYGON',
}

export type PermissionKeyName =
  | 'trackers'
  | 'transports'
  | 'employees'
  | 'users'
  | 'roles'
  | 'geozones'
  | 'tracks'
  | 'reports'
  | 'map'
  | 'handbooks'
  | 'buildings'
  | 'notifications'
  | 'poi'
  | 'records'
  | 'messages';

export interface TabPermissions {
  [key: string]: {
    permissionsNames: string[];
  };
}

export const TAB_PERMISSIONS: TabPermissions = {
  monitoring: {
    permissionsNames: ['employees', 'transports'],
  },
  tracks: {
    permissionsNames: ['tracks'],
  },
  geozones: {
    permissionsNames: ['geozones'],
  },
  notifications: {
    permissionsNames: ['notifications'],
  },
  records: {
    permissionsNames: ['records'],
  },
  poi: {
    permissionsNames: ['poi'],
  },
  handbooks: {
    permissionsNames: ['handbooks'],
  },
  trackers: {
    permissionsNames: ['trackers'],
  },
  users: {
    permissionsNames: ['users'],
  },
  reports: {
    permissionsNames: ['reports'],
  },
  messages: {
    permissionsNames: ['messages'],
  },
  videoanalytics: {
    permissionsNames: ['reports'],
  },
};
export const WS_BASE = '/ws';

export enum Sorting {
  trackableNumber = 'trackableNumber',
  name = 'name',
  network = 'network',
  gps = 'gps',
  battery = 'battery',
}

const COLOR_WHITE = '#FFFFFF';
const COLOR_BLACK = '#000000';
const COLOR_DARK_GREY = '#4D4D4D';
const COLOR_GREY = '#999999';
const COLOR_BROWN = '#B84403';
const COLOR_DARK_GREEN = '#04B400';
const COLOR_AQUA = '#00FFFF';
const COLOR_DARK_BLUE = '#0F00BF';
const COLOR_RED = '#FF0F00';
const COLOR_ORANGE = '#FFB800';
const COLOR_GREEN_YELLOW = '#9ACD32';
const COLOR_DARK_YELLOW = '#DBFF00';
const COLOR_LIGHT_GREEN = '#00FF19';
const COLOR_DARK_PINK = '#FF0099';
const COLOR_DARK_VIOLET = '#7700FF';

export const geozoneColors = [
  COLOR_LIGHT_GREEN,
  COLOR_GREEN_YELLOW,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_DARK_PINK,
  COLOR_DARK_VIOLET,
  COLOR_DARK_BLUE,
  COLOR_AQUA,
  COLOR_DARK_GREEN,
  COLOR_BROWN,
  COLOR_WHITE,
  COLOR_GREY,
  COLOR_DARK_GREY,
  COLOR_BLACK,
];

export const brightGeozoneMarkerTextColors = [COLOR_WHITE, COLOR_LIGHT_GREEN, COLOR_AQUA, COLOR_DARK_YELLOW];

export const COLOR_DANGER_LEVEL_1 = COLOR_LIGHT_GREEN;
export const COLOR_DANGER_LEVEL_2 = COLOR_DARK_YELLOW;
export const COLOR_DANGER_LEVEL_3 = COLOR_RED;

export const pickerColors = [
  COLOR_LIGHT_GREEN,
  COLOR_BLACK,
  COLOR_WHITE,
  COLOR_RED,
  COLOR_DARK_BLUE,
  COLOR_AQUA,
  COLOR_DARK_PINK,
  COLOR_DARK_YELLOW,
];

export const labelColors = [
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_RED,
  COLOR_LIGHT_GREEN,
  COLOR_DARK_BLUE,
  COLOR_AQUA,
  COLOR_DARK_PINK,
  COLOR_DARK_YELLOW,
];

export const ENTER_KEY_CODE = 13;

const ONE_KILO_BYTE = 2 ** 10;
const ONE_MEGA_BYTE = ONE_KILO_BYTE ** 2;
export const MAX_UPLOAD_FILE_SIZE = ONE_MEGA_BYTE * 2; // 2 Mb

// eslint-disable-next-line
export const NUMBER_REGEXP = /^-?\d*[\.\,]?\d*$/i;
// eslint-disable-next-line
export const URL_REGEXP =
  /^(https?|rtsp|rtmp):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const URL_END_MODULE = 'http://isobr.gis.visitech.local/nd/';

export enum PERSONAL_STATISTIC_SORTING_MODE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const ALL_TABS = [
  'monitoring',
  'tracks',
  'geozones',
  'notifications',
  'trackers',
  'users',
  'reports',
  'records',
  'poi',
  'handbooks',
  'messages',
  'videoanalytics',
];

export const DEFAULT_DATE_FORMAT = 'dd.mm.yyyy';
export const DEFAULT_GPS_FORMAT = 'EPSG:3857';
export const DEFAULT_METRIC_SYSTEM = 'Метрическая';
export const DEFAULT_TIME_FORMAT = 'HH:MM';
export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const SUPPORTED_TIMEZONES = [
  'Pacific/Midway',
  'Pacific/Honolulu',
  'America/Juneau',
  'America/Boise',
  'America/Dawson',
  'America/Chihuahua',
  'America/Phoenix',
  'America/Chicago',
  'America/Regina',
  'America/Mexico_City',
  'America/Belize',
  'America/Detroit',
  'America/Bogota',
  'America/Caracas',
  'America/Santiago',
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Tijuana',
  'America/Montevideo',
  'America/Argentina/Buenos_Aires',
  'America/Godthab',
  'America/Los_Angeles',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Europe/London',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Africa/Casablanca',
  'Atlantic/Canary',
  'Europe/Belgrade',
  'Europe/Sarajevo',
  'Europe/Brussels',
  'Europe/Amsterdam',
  'Africa/Algiers',
  'Europe/Bucharest',
  'Africa/Cairo',
  'Europe/Helsinki',
  'Europe/Athens',
  'Asia/Jerusalem',
  'Africa/Harare',
  'Europe/Moscow',
  'Asia/Kuwait',
  'Africa/Nairobi',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Baku',
  'Asia/Kabul',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Kathmandu',
  'Asia/Dhaka',
  'Asia/Colombo',
  'Asia/Almaty',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Krasnoyarsk',
  'Asia/Shanghai',
  'Asia/Kuala_Lumpur',
  'Asia/Taipei',
  'Australia/Perth',
  'Asia/Irkutsk',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Asia/Vladivostok',
  'Pacific/Guam',
  'Asia/Magadan',
  'Asia/Kamchatka',
  'Pacific/Fiji',
  'Pacific/Auckland',
  'Pacific/Tongatapu',
];

export const TEST_NOTIFICATION_ID = 99999999;
export const TEST_NOTIFICATION_KEY_NAME = 'testNotification';

// HTML elements
export const APP_CONTENT_ID = 'app-content';
