export function getFullDateFromStr(dateStr: string) {
  if (!dateStr) {
    return {
      date: ``,
      shortTime: ``,
      time: ``,
    };
  }

  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return {
    date: `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`,
    shortTime: `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`,
    time: `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`,
  };
}
