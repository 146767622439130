export function getQS(name: string) {
  if (window.URLSearchParams) {
    return new window.URLSearchParams(window.location.href.split('?')[1]).get(name);
  } else {
    let result = null;
    window.location.search
      .substr(1)
      .split('&')
      .forEach(item => {
        const tmp = item.split('=');
        if (tmp[0] === name) {
          result = decodeURIComponent(tmp[1]);
        }
      });
    return result;
  }
}
