import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import orderBy from 'lodash/orderBy';

import { ReactComponent as GearIcon } from 'assets/img/gear.svg';

import { Spinner } from 'components/common/spinner/spinner';
import WithSortIcon from 'components/common/withSortIcon/withSortIcon';
import Button from 'components/common/button/button';

import { RootState } from 'reducers';
import { showModal } from 'reducers/modal';
import { fetchRoles, initializeRoleForm } from 'reducers/roles';
import { RoleAttributes } from 'reducers/roles/interface';
import { fetchAccounts, initializeRoleToUsersData } from 'reducers/accounts';
import { Account } from 'reducers/accounts/interface';

import AccessEntity from 'utils/accessEntity';
import { ROLE_TO_USER } from 'utils/consts';
import { SortType } from 'utils/interfaces';
import { getUserFullName } from 'utils/users/viewData';
import { getLodashSortType } from 'utils/common/sorting';

import AccountsHeader from '../accountsHeader/accountsHeader';
import { AccountsPage } from '../utils/types';
import AccessEntitiesView, { AccessEntitiesViewData } from './components/accessEntitiesView';

import styles from './rolesList.module.scss';

type Props = {
  changePage: (page: AccountsPage) => void;
};

type RolesListViewData = {
  id: string;
  name: string;
  createdBy: string;
  employeesCount: number;
  accessEntities: AccessEntitiesViewData;
};

type RolesSortConfig = {
  sortBy: keyof RolesListViewData;
  sortType: SortType;
};

const allRoleEntities: readonly (keyof RoleAttributes)[] = [
  'employees',
  'transports',
  'trackers',
  'users',
  'roles',
  'geozones',
  'tracks',
  'reports',
  'poi',
  'records',
  'map',
  'buildings',
  'notifications',
  'handbooks',
  'messages',
];

const columns: (keyof RolesListViewData)[] = ['name', 'createdBy', 'employeesCount', 'accessEntities'];

const messages = {
  assignRoleButtonLabel: 'accounts.roles-list-table.assign-role-button.text',
  columnLabel: (column: keyof RolesListViewData) => `accounts.roles-list-columns.column-${column}.text`,
  accessEntity: (entity: keyof RoleAttributes) => `accounts.roles-table.resource-column.row.${entity}.text`,
};

const CellWithButton: React.FC = ({ children }) => (
  <td className={styles.cellWithButton}>
    <div className={styles.cellButtonContainer}>{children}</div>
  </td>
);

const RolesList: React.FC<Props> = ({ changePage }) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState<RolesSortConfig>({
    sortBy: 'name',
    sortType: SortType.ASC,
  });

  const roles = useSelector((state: RootState) => state.role.roles);
  const isRolesLoading = useSelector((state: RootState) => state.role.isLoading);
  const rolesUpdateCounter = useSelector((state: RootState) => state.role.updateCounter);

  const accounts = useSelector((state: RootState) => state.account.accounts);
  const isAccountsLoading = useSelector((state: RootState) => state.account.isLoading);
  const setRoleToUsersIsLoading = useSelector((state: RootState) => state.account.setRoleToUsersIsLoading);
  const accountsUpdateCounter = useSelector((state: RootState) => state.account.updateCounter);

  const rolesPermissions = useSelector((state: RootState) => state.user.permissions.roles);
  const rolesAccess = useMemo(() => new AccessEntity(rolesPermissions), [rolesPermissions]);

  const idToUserMap: { [id: string]: Account } = useMemo(() => {
    const result: { [id: string]: Account } = {};

    for (const account of accounts) {
      result[account.id] = account;
    }

    return result;
  }, [accounts]);

  const rolesCounter = useMemo(() => {
    const counter: { [roleId: string]: number } = {};

    for (const account of accounts) {
      for (const role of account.relationships.roles.data) {
        if (typeof counter[role.id] !== 'number') {
          counter[role.id] = 0;
        }

        counter[role.id]++;
      }
    }

    return counter;
  }, [accounts]);

  const rolesList: RolesListViewData[] = useMemo(() => {
    const result = roles.map(role => {
      const canRead: string[] = [];
      const canCreate: string[] = [];
      const canUpdate: string[] = [];
      const canDelete: string[] = [];

      for (const entity of allRoleEntities) {
        if (role.attributes[entity]) {
          const accessEntity = new AccessEntity(role.attributes[entity] as string);
          const entityName = t(messages.accessEntity(entity));

          accessEntity.isAllowRead() && canRead.push(entityName);
          accessEntity.isAllowCreate() && canCreate.push(entityName);
          accessEntity.isAllowUpdate() && canUpdate.push(entityName);
          accessEntity.isAllowDelete() && canDelete.push(entityName);
        }
      }

      return {
        id: role.id.toString(),
        name: role.attributes.name,
        createdBy: getUserFullName(idToUserMap[role.attributes.createdBy]),
        employeesCount: rolesCounter[role.id] || 0,
        accessEntities: {
          canRead,
          canCreate,
          canUpdate,
          canDelete,
        },
      };
    });

    const lodashSortType = getLodashSortType(sortConfig.sortType);
    let sortBy = [sortConfig.sortBy];
    let sortType = [lodashSortType];

    // If sortBy is not 'name' make secondary sort by 'name' ascending
    // to make this sort stable
    if (sortConfig.sortBy !== 'name') {
      sortBy = [sortConfig.sortBy, 'name'];
      sortType = [lodashSortType, getLodashSortType(SortType.ASC)];
    }

    return orderBy(result, sortBy, sortType);
  }, [roles, rolesCounter, sortConfig, idToUserMap]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [rolesUpdateCounter]);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [accountsUpdateCounter]);

  const handleSearchChange = () => {
    // not implemented
  };

  const handleCreateClick = () => {
    dispatch(initializeRoleForm(null));
  };

  const handleColumnHeaderClick = (column: keyof RolesListViewData) => {
    if (column !== 'accessEntities') {
      setSortConfig({
        sortBy: column,
        sortType: sortConfig.sortBy === column && sortConfig.sortType === SortType.ASC ? SortType.DESC : SortType.ASC,
      });
    }
  };

  const handleAssignRoleClick = async (roleId: string) => {
    // await is must have here, because RoleSelectAccount saves
    // the initial state for rollback
    await dispatch(initializeRoleToUsersData(roleId));
    dispatch(showModal({ type: ROLE_TO_USER, which: '' }));
  };

  const handleEditClick = (roleId: string) => {
    dispatch(initializeRoleForm(roleId));
  };

  const isLoading = isRolesLoading || isAccountsLoading || setRoleToUsersIsLoading;

  return (
    <div>
      <AccountsHeader
        isAllowCreate={rolesAccess.isAllowCreate()}
        currentPage={AccountsPage.Roles}
        handleSearchChange={handleSearchChange}
        handleCreateClick={handleCreateClick}
        changePage={changePage}
      />

      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column} onClick={() => handleColumnHeaderClick(column)}>
                <WithSortIcon sortType={sortConfig.sortType} showSortIcon={sortConfig.sortBy === column}>
                  {t(messages.columnLabel(column))}
                </WithSortIcon>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {!isLoading &&
            rolesList.map(role => (
              <tr key={role.id}>
                <td>{role.name}</td>
                <td>{role.createdBy}</td>
                <td>{role.employeesCount}</td>
                <td>
                  <AccessEntitiesView data={role.accessEntities} />
                </td>
                <CellWithButton>
                  <Button
                    text={t(messages.assignRoleButtonLabel)}
                    onClick={() => handleAssignRoleClick(role.id)}
                    white
                  />
                </CellWithButton>
                <CellWithButton>
                  <GearIcon className={styles.cellButton} onClick={() => handleEditClick(role.id)} />
                </CellWithButton>
              </tr>
            ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}
    </div>
  );
};

export default RolesList;
