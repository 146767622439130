import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Map } from 'ol';

import { RootState } from 'reducers';

export function useMapSize(map: Map) {
  const showContentData = useSelector((state: RootState) => state.map.showContentData);
  const sizeCounter = useSelector((state: RootState) => state.map.updateSizeCounter);

  useEffect(() => {
    map.updateSize();
  }, [map, showContentData, sizeCounter]);
}
