import React, { ChangeEvent } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { SortableHandle } from 'react-sortable-hoc';

import { ReactComponent as ClosedEye } from 'assets/img/closed-eye.svg';
import { ReactComponent as OpenedEye } from 'assets/img/opened-eye.svg';
import { ReactComponent as TwoLines } from 'assets/img/two-lines.svg';

import GrowingTextArea from 'components/common/growingTextArea/growingTextArea';

import styles from './additionalField.module.scss';

interface Props {
  item: {
    label: string;
    value: string;
    visible: boolean;
  };
  toggleVisible: () => void;
  handleChangeInput: (value: string) => void;
  handleChangeLabel: (label: string) => void;
}

const DragHandler = SortableHandle(() => <TwoLines className={styles.twoLines} />);

export const AdditionalField = ({ item, toggleVisible, handleChangeInput, handleChangeLabel }: Props) => {
  const t = useFormatMessage();

  const onChangeLabel = (ev: ChangeEvent<HTMLInputElement>) => {
    handleChangeLabel(ev.target.value);
  };

  const onChangeInput = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    handleChangeInput(ev.target.value);
  };

  return (
    <div className={styles.fieldWrap}>
      <DragHandler />

      <div className={styles.inputsWrap}>
        <input
          className={styles.label}
          placeholder={t('monitoring.unit-card.additional-fields.field.label')}
          value={item.label}
          onChange={onChangeLabel}
        />

        <GrowingTextArea
          className={styles.input}
          placeholder={t('monitoring.unit-card.additional-fields.field.placeholder')}
          value={item.value}
          onChange={onChangeInput}
        />
      </div>

      {item.visible ? (
        <OpenedEye className={styles.eye} onClick={toggleVisible} />
      ) : (
        <ClosedEye className={styles.eye} onClick={toggleVisible} />
      )}
    </div>
  );
};
