export default {
  en: {
    'toast.auth.not-found.error.text': 'User not found, or wrong username / password',
    'toast.auth.auth.error.text': 'Authorization error or your session has expired',
  },
  ru: {
    'toast.auth.not-found.error.text': 'Пользователь не найден, либо неправильные логин/пароль',
    'toast.auth.auth.error.text': 'Ошибка авторизации или ваша сессия истекла',
  },
  zh: {
    'toast.auth.not-found.error.text': '未找到的用户或用户名/密码错误',
    'toast.auth.auth.error.text': '授权错误或您的会话已过期',
  },
  ar: {
    'toast.auth.not-found.error.text': 'لم يتم العثور على المستخدم أو اسم المستخدم / كلمة المرور غير صحيحة.',
    'toast.auth.auth.error.text': 'خطأ في التفويض أو أن جلستك قد انتهت.',
  },
  de: {
    'toast.auth.not-found.error.text': 'Der Benutzer wurde nicht gefunden oder Benutzername/Passwort waren falsch.',
    'toast.auth.auth.error.text': 'Autorisierungsfehler oder Ihre Sitzung ist abgelaufen.',
  },
};
